import React, { useRef, useState,useEffect, useLayoutEffect } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  Text,
  MenuList,
  MenuItem,
  Td,
  Tr,
  Divider,
  Card,
  CardBody
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SimpleTable from "../../components/simple-table";
import ModalRight from "../../components/modal-right";
import moment from "moment";
import { getLoginID, useScreenType ,getCurrency, formatToDecimal, actionAccessMain} from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { PrimaryInput, Toaster } from "../../components";
import { GetLabList,AddLabFee,UpdateLabFee, DeleteLabFee} from "../../middleware/services/billing/index";
import DynamicModal from "../../components/dynamic-modal";
import { useLocation, useNavigate } from "react-router-dom";





const LabPricingIndex = () => {
    const [actionAccess, setActionAccess] = useState(null);
  var [doctorid, setDoctorid] = useState(getLoginID());
  var [patientid, setPatientID] = useState("");
  var [lablist, setLabList] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  const [currency, setCurrency] = useState("");
  const [labamount, setLabAmount] = useState("");
  const [labsgst, setLabSGST] = useState("");
  const [labcgst, setLabCGST] = useState("");
  const [ID, setLabID] = useState("");
  const [description, setDescription] = useState("");
  const [labname, setLabName] = useState("");
  const addlabprice = useRef();
  const editlabprice = useRef();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [searchtext, setSerachText] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(0);
  const deletelabprice = useRef();
  let itemPerPageCommonValue = 50;

  let [pageno, setPageno] = useState(1);
  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);

  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);

  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  
  useEffect(() => {
    
    setActionAccess(actionAccessMain(12))
    fetchLabList(pageno);    
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }   
  }, [pageno]);
  const fetchLabList = async (page) => {
    setSerachText("");
  //  alert(countTo)
    showLoader()
    try {
        const result = await GetLabList({ 
          "doctorid":"",
          "type": "lab",
          "pageno":page,
          pagesize
        });
        if (result != null) {
          setLabList(result.output.data);
          if (result && result.output.count === 0 && result.output.pagesize === 0) {
            setTotalPages(1);
          } else {
            setTotalPages(Math.ceil(result.output.count / result.output.pagesize));
          }
          
          setTotalItemCount(result.output.count)
          if (result.output.count < itemPerPageCommonValue) {
           // console.log("if bolock " ,result.output.count + " < " + itemPerPageCommonValue )
            setCountTo(result.output.count);
            setTotalExceedsPageCount(true);
          }else if(result.output.count > itemPerPageCommonValue && page == 1) {
            
          //  console.log("else bolock " ,result.output.count + " > " + itemPerPageCommonValue + " page - "+ page)
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
        }else{
          setLabList(null)
          setTotalPages(1);
          dismissLoader();
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in home:", error);
      }
  };
  const handleNextClick = () => {
    // item count logic for next
    //here
   if(countTo + pagesize < totalItemCount){
    setCountTo(countTo + pagesize)
   }else{
     setCountTo(totalItemCount)
   }
   
   setCountFrom(countFrom + pagesize)

    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    fetchLabList(currentPage + 1);
    
      
   
  };

  const handlePreviousClick = () => {
      // item count logic for previous
     // setCurrentPage(currentPage - 1);
      
    //console.log(countTo +  " == " + totalItemCount + "| else count to -  " + pagesize)
      if(countTo == totalItemCount){
           //setCountTo(Math.floor(totalItemCount / 10) * 10)
         let updatedCountUnroundedValue  = totalItemCount - pagesize;
        setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )
      }else{
        setCountTo(countTo - pagesize)
      }
      
      setCountFrom(countFrom - pagesize)

    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    fetchLabList(currentPage - 1);
     // alert(currentPage - 1)
  
  };
  const TableHeader = [
    { key: "name", label: "Name" },
    { key: "sgst", label: "SGST" },
    { key: "cgst", label: "CGST" },
    { key: "amount", label: "Amount" },
    { key: "actions", label: "Actions" },

  ];
  const addLabtest = async() => {
    if(labname == ""){
      showErrorToast("Please specify the test name");
      return false;
    }
    else if (labamount == "") {
         showErrorToast("Please specify the amount");
         return false;
       }else if (labsgst == "") {
         showErrorToast("Please specify the sgst");
         return false;
       } else if (labcgst == "") {
           showErrorToast("Please specify the cgst");
           return false;
       }
       else {   
           let input = {
               "doctorid":"",
               "testname": labname,
               "testdescription": description,
               "totalamount": labamount,
               "sgst": labsgst,
               "cgst": labcgst,
               "islab":"1",
           }
           try {
             const response = await AddLabFee(input);
             if(response.output.message == "A lab Test with the same name already exists"){
              showErrorToast("Test name already exist")
             }else if(response){
               addlabprice.current.closeRight();
               fetchLabList();
               dismissLoader();
             }else{
               console.log("someting went wrong")
             }
             } catch (error) {
               console.log(error , " error in updating fee")
             }
          
       }
   }
  const editDetailsSetState = (item) =>{
    setLabID(item.id)
    setLabAmount(item.totalamount);
    setLabSGST(item.sgst)
    setLabCGST(item.cgst)
    setLabName(item.type)

  }
  const openEditModal = (item) => {
    editDetailsSetState(item)
    editlabprice.current.openRight();
  };
    const editLabtest = async() => {
      if(labname == ""){
        showErrorToast("Please specify the test name");
        return false;
      }
     else if (labamount == "") {
          showErrorToast("Please specify the total amount");
          return false;
        }else if (labsgst == "") {
          showErrorToast("Please specify the sgst");
          return false;
        } else if (labcgst == "") {
            showErrorToast("Please specify the cgst");
            return false;
        }
        else {   
            let input = {
                "doctorid":"",
                "testname": labname,
                "testdescription": description,
                "id": ID,
                "totalamount": labamount,
                "sgst": labsgst,
                "cgst": labcgst,
                "islab":"1",
            }
            showLoader()
            try {
              const response = await UpdateLabFee(input);
              // console.log('responseLabs: ', response)
              if(response.output.isexists == 1){
                showErrorToast('Test name already exists for the same doctor');
                return false;
              }
              if(response){
                showSuccessToast('Lab Test Updated Successfully  ')
                editlabprice.current.closeRight();
                // setCountTo(itemPerPageCommonValue)
                fetchLabList(pageno);
                    dismissLoader();
              }else{
                console.log("someting went wrong")
              }
              } catch (error) {
                console.log(error , " error in updating fee")
              }
           
        }
    }
    const openDeleteModal = (item) => {
      setLabID(item.id)
      setLabName(item.type)
      deletelabprice.current.openModal();
    };
    const deleteLab = async() => {
      showLoader();
            let input = {
                "doctorid":"",
                "testname": labname,
                "id": ID,
                "islab":"1",
            }
            try {
              const response = await DeleteLabFee(input);
              // console.log(response.output.result == "success", " deleted respose ")
              if(response.output.result == "success"){
                showErrorToast("Lab Test Deleted Successfully!")
                deletelabprice.current.closeModal();
                fetchLabList();
                    dismissLoader();
              }else{
                console.log("someting went wrong")
              }
              } catch (error) {
                console.log(error , " error in updating fee")
              }
              dismissLoader();
    }
    const searchanything = async (e) => {
         if (e.key === "Enter") {
          showLoader();
          try {
            setPageno(1);
            setPagesize(itemPerPageCommonValue);
            setCountFrom(1);
            setCountTo(itemPerPageCommonValue);
            setCurrentPage(1)

            const result = await GetLabList({
              "doctorid":"",
              "type": "lab",
              searchtext: searchtext,
              "pageno":"1",
              pagesize,
            });
            if (result != null) {
              setLabList(result.output.data);
              if (result && result.output.count === 0 && result.output.pagesize === 0) {
                setTotalPages(1);
              } else {
                setTotalPages(Math.ceil(result.output.count / result.output.pagesize));
              }
              //here
              
              setTotalItemCount(result.output.count)
              if (result.output.count < itemPerPageCommonValue) {
                setCountTo(result.output.count);
                setTotalExceedsPageCount(true);
              }else if(result.output.count > itemPerPageCommonValue && pageno == 1) {
                setCountTo(itemPerPageCommonValue);
                setTotalExceedsPageCount(false);
              }
            }else{
              setLabList(null)
              setTotalPages(1);
              dismissLoader();
            }
            dismissLoader();
          } catch (error) {
            console.error("Error in home:", error);
          }
         } 
      };
      const Filteranything = async (e) => {
          showLoader();
          try {
              setPageno(1);
             setPagesize(itemPerPageCommonValue);
             setCountFrom(1);
             setCountTo(itemPerPageCommonValue);
             setCurrentPage(1)

            const result = await GetLabList({
              "doctorid":"",
              "type": "lab",
              searchtext: searchtext,
              "pageno":"1",
               pagesize,
            });
            if (result != null) {
              setLabList(result.output.data);
              if (result && result.output.count === 0 && result.output.pagesize === 0) {
                setTotalPages(1);
              } else {
                setTotalPages(Math.ceil(result.output.count / result.output.pagesize));
              }
              //here
              console.log(result.output.count , " result.output.count")
              setTotalItemCount(result.output.count)
              if (result.output.count < itemPerPageCommonValue) {
                setCountTo(result.output.count);
                setTotalExceedsPageCount(true);
              }else if(result.output.count > itemPerPageCommonValue && pageno == 1) {
                setCountTo(itemPerPageCommonValue);
                setTotalExceedsPageCount(false);
              }
            }else{
              setLabList(null)
              setTotalPages(1);
              dismissLoader();
            }
            dismissLoader();
          } catch (error) {
            console.error("Error in home:", error);
          }
      };
      // const gobackfunction = ()=>{
      //   window.history.back();
      //  }

      const gobackfunction = () => {
        if (window.history.length > 2 && location.key !== "default") {
          window.history.back();
      
          // Fallback if the page does not navigate
          setTimeout(() => {
            if (document.referrer === "" || window.history.length <= 1) {
              navigate("/home");
            }
          }, 500);
        } else {
          navigate("/home");
        }
      };
       const openaddmodal = ()=>{
        setLabName("");
        setLabCGST("");
        setLabSGST("");
        setLabAmount("");
        setDescription("")
        addlabprice.current.openRight()
       }

       const resetFields = () => {
        setCurrentPage(1);
        setPageno(1);
        
        //clear states for correct item count
        setPagesize(itemPerPageCommonValue);
        setCountFrom(1)
        setCountTo(itemPerPageCommonValue)
        setCurrentPage(1)

        fetchLabList(1)
      };
      
  return (
    <>
      {screenType == 'web' ? (
        <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {isLoading && <Loader />}
          <Box w="100%">
            <SecondaryNav withBack="Lab Setup" goBack={gobackfunction} />
            <Box
              gap="10px"
              my="1rem"
              px="1rem"
              w="100%"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
            <Box w="100%" display="flex" justifyContent='space-between'>
              <Flex w='100%' gap='1rem'>
              <PrimaryInput
                  variant="fullRound"
                  inputPlace="Search"
                  // inputStyleCss={{h: "40px"}}
                  onKeyPress={searchanything}
                  maxLength={50}
                  onChange={(e) => setSerachText(e.target.value)}
                  inputValue={searchtext}
                />
                 <PrimaryButton
                  buttonText="Filter"
                  id="filterButton"
                  variant="mdBtn"
                  btnStyle={{w: "80px"}}
                  onClick={Filteranything}
                />
                 <PrimaryButton
                  buttonText="Reset"
                  id="resetButton"
                  variant="grayBtn"
                  btnStyle={{ color: "#AA2F27" }}
                  // onClick={()=>fetchLabList(1)}
                  onClick={resetFields}

                />
                 <PrimaryButton
                  buttonText="Add"
                  id="filterButton"
                  variant="mdBtn"
                  btnStyle={{w: "80px"}}
                  onClick={openaddmodal}
                  isDisabled={
                    actionAccess && !actionAccess.create
                      ? true
                      : false
                  }
                />
              </Flex>
            </Box>
            </Box>
            <Divider />
            {lablist && lablist.length > 0 ? (
                       <Box>
                       <Flex
                         w="100%"
                         flexDir="column"
                         gap="1rem"
                         p="1rem"
                         h="calc(100vh - 70px)"
                       >
                            <SimpleTable
                              headers={TableHeader}
                              isDataPresent={true}
                              ThStyle={{
                                bg: "#FAFAFA",
                              }}
                              footerStyle={{
                                pos: "fixed",
                                bottom: "0px",
                                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                                zIndex:'999',
                                w:"100%"
                              }}
                              tableStyle={{
                                minH: "calc(100vh - 240px)",
                                maxH: "calc(100vh - 240px)",
                                overflowY: "auto",
                                tableLayout: "fixed",
                                width: "100%",
                                border: "1px solid #E6E7E9",
                              }}
                              tableBody={
                                <>
                                  {lablist &&
                                    lablist.map((step, index) => (
                                      <Tr w="100%" key={index}>
                                        <Td w="100x">
                                        <Text fontSize=".875rem" fontWeight="500">
                                            {step.type? step.type:""}
                                          </Text>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                            {step.sgst || step.sgst == 0 ? step.sgst+"%":"-"}
                                            {/* {step.sgst ? step.sgst+"%":"-"} */}
                                          </Text>
                                        </Td>

                                        <Td>
                                          
                                            <Text
                                              fontSize=".875rem"
                                              fontWeight="500"
                                              //color="#109E6A"
                                            >
                                              {step.cgst || step.cgst == 0 ? step.cgst+"%": '-'}
                                              {/* {step.cgst ? step.cgst+"%": '-'} */}
                                            </Text>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {step.totalamount? currency+" " + formatToDecimal(step.totalamount):"0"}
                                          </Text>
                                        </Td>
                                        <Td pos='relative'>
                              <Menu isLazy>
                                <MenuButton as="button" zIndex={1}>
                                  <Image
                                    w="1.5rem"
                                    src="/assets/svgs/more-options.svg"
                                  />
                                </MenuButton>
                                <MenuList
                                  zIndex={2}
                                  pos="relative"
                                  right="100px"
                                  minW="125px"
                                >
                                  <MenuItem onClick={() => openEditModal(step)}
                                  isDisabled={ actionAccess && (!actionAccess.update) ? true : false} >
                                    Edit
                                  </MenuItem>
                                  <MenuItem onClick={() => openDeleteModal(step)}
                                  isDisabled={ actionAccess && (!actionAccess.delete) ? true : false} >
                                    Delete
                                  </MenuItem>
                                 </MenuList>
                              </Menu>
                            </Td>
                                       
                                      </Tr>
                                    ))}
                                </>
                              }
                              footerSection={
                                <>
                                  <Tr w='100%'>
                                    <Td colSpan={8} width='calc(100vw - 6.5rem)'>
                                      <Box
                                        w="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                         <Box 
                                      visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}
                                      >
                                         <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                                         </Box>
             
             
                                       <Box  
                                       gap="2.5rem"
                                        display="flex"
                                       justifyContent="space-between"
                                       alignItems="center" >
                                        <Box as="button" onClick={handlePreviousClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                            Previous
                                          </Box>
                                        <Box>
                                          <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                          Page {currentPage} of {totalPages}
                                          </Text>
                                        </Box>
                                        {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                                          <Box as="button" p="0.5rem 0.75rem" mr='2.5rem' border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick} 
                                          disabled={currentPage === totalPages} 
                                          visibility={currentPage === totalPages ? 'hidden' : 'visible'} 
                                          fontSize="1rem" fontWeight="500">
                                            Next
                                          </Box>
                                        }
                                      </Box>
                                      </Box>
                                    </Td>
                                  </Tr>
                                </>
                              }
                            />
                          </Flex>
                          </Box>
                      ) : (
                        <SimpleTable
                          headers={TableHeader}
                          // isDataPresent={false}
                        />
                      )}
          </Box>
        </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Lab Setup"
            handlebackarrow={false}
          />

        <Box
          w="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="1rem"
          gap='0.75rem'
        >
          <PrimaryInput
            variant="fullRound"
            inputPlace="Search"
            // inputStyleCss={{h: "40px"}}
            onKeyPress={searchanything}
            maxLength={50}
            onChange={(e) => setSerachText(e.target.value)}
            inputValue={searchtext}
          />
          <PrimaryButton
            buttonText="Filter"
            id="filterButton"
            variant="mdBtn"
            btnStyle={{w: "80px"}}
            onClick={Filteranything}
          />
          <PrimaryButton
            buttonText="Reset"
            id="resetButton"
            variant="grayBtn"
            btnStyle={{ color: "#AA2F27" }}
            // onClick={()=>fetchLabList(1)}
            onClick={resetFields}

          />
            <PrimaryButton
            buttonText="Add"
            id="filterButton"
            variant="mdBtn"
            btnStyle={{w: "80px"}}
            onClick={openaddmodal}
            isDisabled={
              actionAccess && !actionAccess.create
                ? true
                : false
            }
          />
        </Box>

        <Divider />
        <Flex w='100%' gap='1rem' p='1rem' flexDir='column' maxH="calc(100vh - 275px)" overflow='hidden auto'>
        {lablist && lablist.length > 0 ? (
          lablist.map((step, index) => (
          <Card
            borderRadius="12px"
            key={index}
            w="100%"
            border="1px solid #E6E7E9"
            boxShadow="0px 8px 20px 0px #E6E7E966"
          >
            <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >                  
                <Box cursor="pointer" display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Text fontSize="15px" fontWeight="600" className="textOverflow" title={step.type ? step.type : "-"} maxW={{base: "180px", xl:"110px"}}>
                    {step.type? step.type:""}
                    </Text>
                  </Box>
                </Box>

                  <Box display="flex" gap="10px">
                    <Menu isLazy>
                      <MenuButton as="button" zIndex={1}>
                        <Image
                          w="1rem"
                          src="/assets/svgs/more-options.svg"
                          transform='rotate(90deg)'
                        />
                      </MenuButton>
                      <MenuList
                        zIndex={9}
                        pos="relative"
                        right="0"
                        minW="125px"
                      >
                       <MenuItem onClick={() => openEditModal(step)}
                        isDisabled={ actionAccess && (!actionAccess.update) ? true : false} >
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => openDeleteModal(step)}
                        isDisabled={ actionAccess && (!actionAccess.delete) ? true : false} >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>    
                  </Box>
                  
              </Box>
              <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                <Box display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      SGST
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {step.sgst? step.sgst+"%":""}
                    </Text>
                    
                  </Box>
                  <Box display='flex' flexDir='column' alignItems="center">
                
                <Text fontSize="10px" color="gray.500">
                  CGST
                </Text>
                <Box
                    as="button"
                  >
                    <Text fontSize="12px" fontWeight="600">
                    {step.cgst ? step.cgst+"%": '-'}
                </Text>
                  </Box>
                
              
              </Box>
              <Box display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Amount
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {step.totalamount? currency+" " + formatToDecimal(step.totalamount):"0"}
                    </Text>
                    
                  </Box>
              </Flex>
              
             
            
            </CardBody>

          </Card>
        ))) : (
          <Text>No data available</Text>
        )}
        </Flex>
          
        </>
      )}
      <ModalRight
        ref={addlabprice}
        header="Add Test"
        modalWidth={{ base: "100%", lg: "calc(100vw - 60%)" }}
        body={
          <>
            <Box w="100%" mt="1rem">
              <Box w="100%" display="flex">
              <PrimaryInput
                  variant="rightSide"
                  inputPlace="Test Name*"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^[a-zA-Z0-9.'-\s]*$/.test(value)) {
                      setLabName(value);
                    }
                  }}
                   inputValue={labname}
                   defaultValue={labname}
                   maxLength={60}
                />
                 <PrimaryInput
                  variant="rightSide"
                  inputPlace="Description"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    // if (/^[a-zA-Z\s]*$/.test(value)) {
                      if (value) {
                      setDescription(value);
                    }
                  }}
                   inputValue={description}
                   defaultValue={description}
                   maxLength={200}
                />
                
               
              </Box>
              <Box w="100%" display="flex">
              <PrimaryInput
                  variant="leftDown"
                  inputPlace={"Amount* (" + (currency)+")"}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^\d*\.?\d*$/.test(value)) {
                      setLabAmount(value);
                    }
                  }}
                   inputValue={labamount}
                   defaultValue={labamount}
                   maxLength={7}
                />
                </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftDown"
                  inputPlace="SGST* %"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^\d*$/.test(value)) {
                      setLabSGST(value);
                    }
                  }}
                   inputValue={labsgst}
                   defaultValue={labsgst}
                   maxLength={3}
                />
                <PrimaryInput
                  variant="rightDown"
                  inputPlace="CGST* %"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^\d*$/.test(value)) {
                      setLabCGST(value);
                    }
                  }}
                   inputValue={labcgst}
                   defaultValue={labcgst}
                   maxLength={6}
                />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" gap="1rem">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                
                onClick={() => addlabprice.current.closeRight()}
              />

              <PrimaryButton
                buttonText="Save"
                onClick={() => addLabtest()}
              />
            </Box>
          </>
        }
      />
      <ModalRight
        ref={editlabprice}
        header="Edit Lab Pricing"
        modalWidth={{ base: "100%", lg: "calc(100vw - 60%)" }}
        body={
          <>
            <Box w="100%" mt="1rem">
            <Box w="100%" display="flex">
              <PrimaryInput
                  variant="rightSide"
                  inputPlace="Test Name*"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^[a-zA-Z0-9.'-\s]*$/.test(value)) {
                      setLabName(value);
                    }
                  }}
                   inputValue={labname}
                   defaultValue={labname}
                   maxLength={60}
                />
                 <PrimaryInput
                  variant="rightSide"
                  inputPlace="Description"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    // if (/^[a-zA-Z\s]*$/.test(value)) {
                      if (value) {
                      setDescription(value);
                    }
                  }}
                   inputValue={description}
                   defaultValue={description}
                   maxLength={200}
                />
                
               
              </Box>
              <Box w="100%" display="flex">
                 <PrimaryInput
                  variant="rightSide"
                  inputPlace={"Amount* (" + (currency)+")"}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^\d*\.?\d*$/.test(value)) {
                      setLabAmount(value);
                    }
                  }}
                   inputValue={labamount}
                   defaultValue={labamount}
                   maxLength={7}
                />
               
              </Box>
              <Box w="100%" display="flex">
                <PrimaryInput
                  variant="leftDown"
                  inputPlace="SGST* %"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^\d*$/.test(value)) {
                      setLabSGST(value);
                    }
                  }}
                   inputValue={labsgst}
                   defaultValue={labsgst}
                   maxLength={3}
                />
                <PrimaryInput
                  variant="rightDown"
                  inputPlace="CGST* %"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits (no decimals, no characters)
                    if (/^\d*$/.test(value)) {
                      setLabCGST(value);
                    }
                  }}
                   inputValue={labcgst}
                   defaultValue={labcgst}
                   maxLength={6}
                />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" gap="1rem">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                
                onClick={() => editlabprice.current.closeRight()}
              />

              <PrimaryButton
                buttonText="Save"
                onClick={() => editLabtest()}
              />
            </Box>
          </>
        }
      />
        <DynamicModal
        ref={deletelabprice}
        modalHeader="Delete Lab"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete <b>{labname}</b>?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteLab}
            />
          </>
        }
      />
    </>
  )
}

export default LabPricingIndex;
