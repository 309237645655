import {
  Box,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Stack,
  Switch,
  Td,
  Text,
  Textarea,
  Tr,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // core Swiper styles
import "swiper/css/navigation"; // navigation module styles
import SimpleTable from "../../components/simple-table";
import { COLORS } from "../../components/styles/colors";
import {
  FetchHomeData,
  FetchRecentActivity,
  updatedoctoravailability,
  AcceptVisit,
  FetchDoctorsByUser,
  GetSearchedPatientsList,
  ResheduleAppointmentByDoc
} from "../../middleware/services/home";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { actionAccessMain, calculateAgeWithYearMonth, formatToDecimal, formatToDecimalandThousandSeparated, getCurrency, getIsDoctor, getUserName, useScreenType } from "../../auth";
import moment from "moment";
import PrimaryButton from "../../components/primary-button";
import DynamicModal from "../../components/dynamic-modal";
import { addDoctorBadge, badgeAIPromptCall, getProfile, getProfileforhome, reGenerateBdgeTextCall, uploadImageInProfile } from "../../middleware/services/profile";
import AiModal from "../../components/ai-modal";
import TypingLoader from "../../components/typing-loader";
import TrendChart from "./linechart";
import { getLoginID, IsDoctorAvailable } from '../../auth'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { DropdownInput, PrimaryInput, PrimaryInputDropdown, Toaster } from "../../components";
import { NavLink, useNavigate } from "react-router-dom";
import MobileNavbar from "../../components/mobile-navbar";
import DatePicker from "../../components/date-picker";
import {
  CreateNewVisit,
  GetDoctorSlots,
  GetPatientStatus,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes.js";
import { FetchPatientDetails } from "../../middleware/services/messages/index.js";
import { FetchDoctorSlots } from "../../middleware/services/patients/index.js";
import { GetPatientHealthData, UpdateAppointmentStatus } from "../../middleware/services/visits/patient-overview/index.js";
import AssignVisit from "../../components/assignVisit/index.js";


const HomeIndex = () => {
  const swiperInstance = React.useRef(null);
  const swiperAiPrompt = React.useRef(null);
  const swiperUpcoming = React.useRef(null);
  var [recentactivity, setRecentActivity] = useState(null);
  var [doctorid, setDoctorid] = useState();
  var [data, setData] = useState(null);
  var [Greetings, setGreetings] = useState("Good morning");
  var [Greetingsicon, setGreetingsIcon] = useState("");
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const username = getUserName();
  const shareBadge = useRef();
  const [DoctorAvailability, setDoctorAvailability] = useState("")
  const AIChatModel = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [TotalCount, setTotalCount] = useState(0);
  const [doctorUniqueId, setDoctorUniqueId] = useState(null);

  const [personalData, setPersonalData] = useState("")
  const { showSuccessToast, showErrorToast } = Toaster();
  const [AvailabilityData, setAvailabilityData] = useState([])
  const [isDoctor, setIsDoctor] = useState(0)
  const [IsGlobalAdmin, setIsGlobalAdmin] = useState(0)


  const [videoVisits, setVideoVisits] = useState([])

  const [clinicvisit, setclinicVisitCheckbox] = useState(0);
  const [doctorvisit, setDoctorVisitCheckbox] = useState(0);

  const [badgeText, setBadgeText] = useState('');
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const navigate = useNavigate()

  const [lines, setLines] = useState([])
  const screenType = useScreenType()

  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessAppointments, setActionAccessAppointments] = useState(null);
  const [actionAccessVisits, setActionAccessVisits] = useState(null);
  const [actionAccessScreening, setActionAccessScreening] = useState(null);
  const [currency, setCurrency] = useState("");
  const [firstTruePage, setFirstTruePage] = useState(null);
  const [newvisitdisplaytext, setnewvisitdisplaytext] = useState("");
  let Currentloggedin = localStorage.getItem("usertypeid");
  const isavailable = IsDoctorAvailable();
  const [ShowNewVisit, setShowNewVisit] = useState(false);
  const [PatientSearchedList, setPatientSearchedList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [actionAccessAssigning, setActionAccessAssigning] = useState(null);

  useEffect(() => {
    setActionAccess(actionAccessMain(1))
    setActionAccessVisits(actionAccessMain(2))
    setActionAccessAppointments(actionAccessMain(15))
    setActionAccessScreening(actionAccessMain(24))
    setActionAccessAssigning(actionAccessMain(25))
    let pages = JSON.parse(localStorage.getItem("pagesAccess"));

    for (let key in pages) {
      if (pages[key] === false) {
        const pageName = key.replace('disable', '');
        //  const formattedPageName = pageName.charAt(0).toUpperCase() + pageName.slice(1).toLowerCase();
        setFirstTruePage(pageName);
        //  alert(formattedPageName)
        break;
      }
    }

    // getPagesAccessFromLocal();
  }, []);

  useEffect(() => {
    if (firstTruePage) {
      navigate(`/${firstTruePage.toLowerCase()}`);
    }
  }, [firstTruePage]);
  let doctorcountrycode = localStorage.getItem("countryid");
  let doctorcountryid = localStorage.getItem("countrycode");
  let rolename = localStorage.getItem("rolename");
  useEffect(() => {
    // Get the current local time
    const currentTime = moment();

    // Determine the time of day
    const currentHour = currentTime.hour();
    let greeting;
    let icon;
    if (currentHour >= 5 && currentHour < 12) {
      greeting = "Good morning";
      icon = "/assets/svgs/good-morning.svg"
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = "Good afternoon";
      icon = "/assets/svgs/good-evening.svg"
    } else if (currentHour >= 18 && currentHour < 21) {
      greeting = "Good evening";
      icon = "/assets/svgs/good-evening.svg"
    }
    else {
      greeting = "Good night";
      icon = "/assets/svgs/good-night.svg"
    }
    setGreetings(greeting);
    setGreetingsIcon(icon)
    fetchData();
    getDoctorProfile();
    const currency = getCurrency();
    if (currency == "USD") {
      const uscurrency = '$'
      setCurrency(uscurrency);
    } else {
      setCurrency(currency);
    }
    // setDoctorid(getLoginID());
    // getDoctorAvailability();
    // getDoctorUnique();
  }, []);
  const fetchData = async () => {
    showLoader()
    try {
      const result = await FetchHomeData({
        doctorid,
        pageno: "1",
        pagesize: "10"
      });
      if (result != null) {
        setIsDoctor(result.isdoctor)
        setIsGlobalAdmin(result.isglobaladmin)
      }
      // console.log(result, " result HOME page");
      const recentactivity = await FetchRecentActivity({
        doctorid,
      });
      if (recentactivity) {
        setRecentActivity(recentactivity);
      }
      if (result != null) {
        setData(result);
        setTotalCount(result && result.count);
        if (result && result.count === 0 && result.pagesize === 0) {
          setTotalPages(1);
        } else {
          setTotalPages(Math.ceil(result.count / result.pagesize));
        }
      } else {
      }
      dismissLoader()
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  const handleNavigation = (direction) => {
    if (swiperInstance.current && swiperInstance.current.swiper) {
      const swiper = swiperInstance.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        swiper.slidePrev();
      } else if (direction === "next" && swiper.slideNext) {
        swiper.slideNext();
      }
    }
  };

  const handleUpcoming = (direction) => {
    if (swiperUpcoming.current && swiperUpcoming.current.swiper) {
      const swiper = swiperUpcoming.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        swiper.slidePrev();
      } else if (direction === "next" && swiper.slideNext) {
        swiper.slideNext();
      }
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTimeVisit = (time) => {
    if (!time) return '';  // handle undefined or null time
    const [hour, minute] = time.split(':');
    return `${hour}:${minute}`;
  };
  const switchclicked = async (e) => {
    const isChecked = e.target.checked;
    let input = { doctorid: "", isavailable: "" }
    // Do something based on the checked status
    if (isChecked) {
      // Switch is checked
      input.isavailable = "1"
    } else {
      // Switch is not checked
      input.isavailable = "0"
    }
    const result = await updatedoctoravailability(input);
    if (result.output.success === 1) {
      getDoctorProfile();
      var doctorDetails = localStorage.getItem("LoginInfo")
      if (doctorDetails) {
        var formateddata = JSON.parse(doctorDetails)
        const isavail = parseInt(input.isavailable)
        const updatedDoctorDetails = { ...formateddata, isavailable: isavail };
        localStorage.setItem('LoginInfo', JSON.stringify(updatedDoctorDetails));
      }
    }
  }
  var inputForGet = {
  }
  const getDoctorProfile = async () => {
    showLoader()
    try {
      var result = await getProfileforhome(inputForGet);
      // setIsDoctor(result.isdoctor)
      if (result && result.ProfileOutput) {
        setDoctorAvailability(result.ProfileOutput.isavailable)
        setPersonalData(result.ProfileOutput)
      }
      dismissLoader();
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }

  const getDoctorUnique = async () => {
    try {
      var result = await getProfile(inputForGet);
      if (result && result.DoctorUniqueId) {
        setDoctorUniqueId(result.DoctorUniqueId)
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor unique id")
    }

  }
  const TableHeader = [
    { key: "patient", label: "Patient" },
    { key: "latestVisit", label: "Latest Visit" },
    { key: "repeatRate", label: "Repeat Rate" },
    { key: "totalRevenue", label: "Total Revenue" },
    { key: "totalVisits", label: "Total Visits" },
    { key: "action", label: "Actions" },
  ];

  const TableData = [
    {
      id: "NUAKZ23819313",
      profile: "/assets/imgs/doctor-image.png",
      patient: "Nuray Aksoy",
      latestVisit: "20 Apr, 2024",
      repeatRate: "+13%",
      totalRevenue: "$1200",
      totalVisits: 32,
    },
    {
      id: "NUAKZ23819313",
      profile: "/assets/imgs/doctor-image.png",
      patient: "Bessie Cooper",
      latestVisit: "12 Apr, 2024",
      repeatRate: "+13%",
      totalRevenue: "$800",
      totalVisits: 5,
    },
    {
      id: "NUAKZ23819313",
      profile: "/assets/imgs/doctor-image.png",
      patient: "Arlene McCoy",
      latestVisit: "11 Apr, 2024",
      repeatRate: "+13%",
      totalRevenue: "$900",
      totalVisits: 3,
    },
    {
      id: "NUAKZ23819313",
      profile: "/assets/imgs/doctor-image.png",
      patient: "Theresa Webb",
      latestVisit: "5 Apr, 2024",
      repeatRate: "+13%",
      totalRevenue: "$1400",
      totalVisits: 23,
    },
  ];

  const css = `
  .pendingVisits .swiper-container{
    height: 100%; 
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 100vw; 
    width: 100%;
    overflow: hidden;
  }
   
  .pendingVisits .swiper-slide{
    width: 285px!important;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  
  .pendingVisits .swiper {
    margin: 0!important;
  }
  
  .pendingVisits .swiper-wrapper{
    max-height: 100%;
    height: 100%t;
    display: flex;
    gap: 20px;
  }
  
  .upcomingVisits .swiper-slide{
    width: 285px!important;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }

  .upcomingVisits {
    overflow: visible;
  }

@media (max-width: 600px) {
  .upcomingVisits,
  .upcomingVisits .swiper-slide{
    width: 100%!important;
  }

  .pendingVisits .swiper-wrapper,
  .upcomingVisits .swiper-wrapper {
    gap: 0!important;
  }
  
  .pendingVisits .swiper-slide {
    width: 100%!important;
  }
  
  .pendingVisits .swiper-slide {
    gap: 0;
  }
}

  .upcomingVisits .swiper-wrapper{
    max-height: 100%;
    height: 100%t;
    display: flex;
    gap: 20px;
  }
  .pendingVisits .swiper {
    overflow: visible;
  }

  .upcomingVisits .swiper {
    overflow: visible;
  }
  `

  const swiperDocCSS = `
  .swiper-container{
    height: 100%; 
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 100vw; 
    width: 100%;
    overflow: hidden;
  }
   
  .swiper-slide{
    width: 100%!important;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }

  .swiper.swiper-initialized {
    margin: 0!important;
  }
  
  .swiper-wrapper{
    max-height: 100%;
    height: 100%;
    display: flex;
    gap: 0px;
  }

  .AiPrompt .swiper-slide {
    width: fit-content!important;
  }

  .AiPrompt .swiper-wrapper {
    gap: 10px;
  }
  `;

  const fetchpaginationData = async (page) => {
    showLoader();
    try {
      const result = await FetchHomeData({
        doctorid,
        pageno: page,
        pagesize: "5"
      });

      // console.log(result, " result HOME page");
      if (result != null) {
        setData(result);
        setTotalCount(result && result.count)
        if (result && result.count === 0 && result.pagesize === 0) {
          setTotalPages(1);
        } else {
          setTotalPages(Math.ceil(result.count / result.pagesize));
        }
      } else {
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    fetchpaginationData(currentPage + 1);
  };

  const handlePreviousClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    fetchpaginationData(currentPage - 1);
  };

  var inputForGet = {

  }

  const swiperDocCard = React.useRef(null);
  const [page, setPage] = useState("1")
  const badgeBox1 = useRef(null);
  const badgeBox2 = useRef(null);
  const [currentBadge, setCurrentBadge] = useState(badgeBox1);

  const AiPromptList = useRef();

  const [groupedVisits, setGroupedVisits] = useState([]);
  const getDoctorAvailability = async () => {
    try {
      var result = await getProfile(inputForGet);

      setDoctorUniqueId(result?.DoctorUniqueId)
      if (result && result.availabilitydata) {
        //  console.log(result.availabilitydata.availtimings.availablity.videovisit, " doctor availability videovisit")

        //   setVideoVisits(result.availabilitydata.availtimings.availablity.videovisit)


        // Share Badge Grouping of Availability --------- Functionality Start

        // const grouped = result.availabilitydata.availtimings.availablity.videovisit.reduce((acc, visit) => {
        //   const { fromtime, totime } = visit.timings[0];

        //   if (fromtime && totime) { // Only include if both fromtime and totime are not null
        //     const timeKey = `${fromtime}-${totime}`;
        //     if (!acc[timeKey]) {
        //       acc[timeKey] = { time: visit.timings[0], days: [] };
        //     }
        //     acc[timeKey].days.push(visit.day.substring(0,3));
        //   }

        //   return acc;
        // }, {});

        // const groupedArray = Object.values(grouped);
        // setGroupedVisits(groupedArray);

        const groupedClicinal = result?.availabilitydata?.availtimings?.availablity?.clinicalvisits?.reduce((acc, visit) => {
          if (visit.isavailable == 1 && visit.timings.length > 0) { // Ensure availability and valid timings array
            visit.timings.forEach(timing => {
              const { fromtime, totime } = timing;

              if (fromtime && totime) { // Only include if both fromtime and totime are not null
                const timeKey = `${fromtime}-${totime}`;

                if (!acc[timeKey]) {
                  acc[timeKey] = { time: timing, days: [] };
                }
                acc[timeKey].days.push(visit.day.substring(0, 3));
              }
            });
          }

          return acc;
        }, {});

        const groupedVideo = result?.availabilitydata?.availtimings?.availablity?.videovisit?.reduce((acc, visit) => {
          if (visit.isavailable == 1 && visit.timings.length > 0) { // Ensure availability and valid timings array
            visit.timings.forEach(timing => {
              const { fromtime, totime } = timing;

              if (fromtime && totime) { // Only include if both fromtime and totime are not null
                const timeKey = `${fromtime}-${totime}`;

                if (!acc[timeKey]) {
                  acc[timeKey] = { time: timing, days: [] };
                }
                acc[timeKey].days.push(visit.day.substring(0, 3));
              }
            });
          }

          return acc;
        }, {});

        if (Object.keys(groupedClicinal).length > 0) {
          const groupedArray = Object.values(groupedClicinal);
          setGroupedVisits(groupedArray);
        }
        else {

          const groupedArray = Object.values(groupedVideo);
          setGroupedVisits(groupedArray);
        }
        // Share Badge Grouping of Availability --------- Functionality End


        setAvailabilityData(result?.availabilitydata)
        setclinicVisitCheckbox(result?.availabilitydata?.doctortimingsetup && result?.availabilitydata?.doctortimingsetup?.isclinicalvisit);
        setDoctorVisitCheckbox(result?.availabilitydata?.doctortimingsetup && result?.availabilitydata?.doctortimingsetup?.isvideocall);

      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  // Share badge integration start
  const handleDoc = (direction) => {
    if (swiperDocCard.current && swiperDocCard.current.swiper) {
      const swiper = swiperDocCard.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        setPage("1")
        setCurrentBadge(badgeBox1)
        swiper.slidePrev();
      } else if (direction === "next" && swiper.slideNext) {
        setCurrentBadge(badgeBox2)
        setPage("2")
        swiper.slideNext();
      }
    }
  };
  const shareBadgeCall = async () => {
    showLoader();
    try {
      const dataUrl = await toPng(badgeBox1.current);

      // Download the image
      const link = document.createElement("a");
      link.download = "share_badge_1.png";
      link.href = dataUrl;
      // link.click();

      // Convert dataUrl to a file
      const file = dataURLtoFile(dataUrl, "share_badge_1.png");
      // Upload the file
      const resultUrlOfBadge1 = await uploadImageInProfile(file, file.name);

      const dataUrl2 = await toPng(badgeBox2.current);

      // link.click();

      // Convert dataUrl to a file
      const file2 = dataURLtoFile(dataUrl2, "share_badge_2.png");
      // Upload the file
      const resultUrlOfBadge2 = await uploadImageInProfile(file, file.name);

      let inputForAddDoctorBadge = {
        "badges": [
          {
            "badgeurl": resultUrlOfBadge1.name
          },
          {
            "badgeurl": resultUrlOfBadge2.name
          }
        ]
      }
      const finalResult = await addDoctorBadge(inputForAddDoctorBadge);
      if (finalResult.output.result == "success") {
        showSuccessToast("Shared Successfully")
        shareBadge.current.closeModal();
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (err) {
      console.log(err);
    }
    dismissLoader();
  };
  const downloadBadge = async () => {
    showLoader();
    toPng(currentBadge.current).then((dataUrl) => {
      const link = document.createElement("a");
      if (currentBadge.current.id == "badge1") {
        link.download = "share_badge_1.png";
      } else {
        link.download = "share_badge_2.png";
      }
      link.href = dataUrl;
      link.click();
    })
      .catch((err) => {
        console.log(err);
      });
    dismissLoader();
  };

  // Share badge Ai Prompt start
  const handleAiPrompt = (direction) => {
    if (swiperAiPrompt.current && swiperAiPrompt.current.swiper) {
      const swiper = swiperAiPrompt.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        swiper.slidePrev();
        //console.log('left clicked')
      } else if (direction === "next" && swiper.slideNext) {
        swiper.slideNext();
        //console.log('next clicked')
      }
    }
  };


  const handleAiNavigation = (direction) => {
    if (AiPromptList.current && AiPromptList.current.swiper) {
      const swiper = AiPromptList.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        swiper.slidePrev();
      } else if (direction === "next" && swiper.slideNext) {
        swiper.slideNext();
      }
    }
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const openDoctorWeb = async (e) => {
    e.preventDefault()
    try {
      var result = await getProfile(inputForGet);
      if (result && result.DoctorUniqueId) {
        const url = `${window.location.origin}/doctorwebsite?id=${result.DoctorUniqueId}`
        window.open(url, '_blank')
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor unique id")
    }
  }

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedTime = hours + ':' + formattedMinutes + ' ' + ampm;
    return formattedTime;
  };


  const formatTime = (time) => {
    if (!time) {
      return "";
    }

    const [hours, minutes] = time.split(':');

    const date = new Date();
    date.setHours(hours, minutes);

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };

  const acceptvisit = async (data) => {
    let input = { "doctorid": "", "patientid": data.patientid, "appointmentid": data.appointmentid, "completed": "1" }
    try {
      const result = await AcceptVisit(input);
      if (result != null) {
        fetchData();
        //getDoctorProfile();
        // getDoctorAvailability();
        // getDoctorUnique();
      } else {
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
  }
  const [textAreaText, setTextAreaText] = useState("")
  const setBadgeTextFunction = (e) => {
    setBadgeText(e.target.value)
    setTextAreaText(e.target.value)
    setTextareaHeight(e.target.value ? `${e.target.scrollHeight}px` : "auto");
  };

  const [placeholderText, setPlaceholderText] = useState("Click anywhere to start typing.")
  const onFocusFunc = (e) => {
    setPlaceholderText("")
  };
  const onBlurFunc = (e) => {
    setPlaceholderText("Click anywhere to start typing.")
  };
  const openShareBadgeModal = () => {
    setBadgeText("")
    setTextAreaText("")
    setLines([])
    setAIPromptArray([])
    setTextareaHeight("auto")

    getDoctorAvailability();
    shareBadge.current.openModal();
  }
  const [updateText, setUpdateText] = useState(false)
  const [AIPromptArray, setAIPromptArray] = useState([]);

  // useEffect(()=>{

  //   const text = badgeText;
  //   if(badgeText){
  //     const textWithLineBreaks = badgeText.replace(/(?<!\bDr|\bMr|\bMrs|\bMs|\bJr|\bSr|\d)\.\s/g, '.\n');
  //     setLines(textWithLineBreaks.split('\n'));
  //   }
  // },[updateText])
  const setLinesFunction = (text) => {
    if (text) {
      const textWithLineBreaks = text.replace(/(?<!\bDr|\bMr|\bMrs|\bMs|\bJr|\bSr|\d)\.\s/g, '.\n');
      setLines(textWithLineBreaks.split('\n'));
    }
  }
  const setTextFromPropmts = (objectText) => {
    showLoader();
    setTimeout(() => {

      setLinesFunction(objectText)
      dismissLoader()
    }, 700);
  }
  const useSameText = () => {
    if (textAreaText) {

      showLoader();
      setTimeout(() => {

        setLinesFunction(textAreaText)
        dismissLoader()
      }, 700);
    }
  }


  const regenerateFunction = async () => {
    showLoader()
    let inputToRegenerate = {
      "name": personalData && personalData.firstname + " " + personalData.lastname,
      "speciality": personalData.specality,
      "qualification": personalData.qualification,
      "text": badgeText
    }
    try {
      const result = await reGenerateBdgeTextCall(inputToRegenerate)
      if (result.badge_content) {
        // setBadgeText(result.badge_content)
        setLinesFunction(result.badge_content)
      } else {
        console.log(" someting went wrong")
      }
    } catch (error) {
      console.log(error, " error in regenerate call")
    }
    dismissLoader()
  }

  const rewriteWithAI = async (prompt) => {
    showLoader()
    let inputToRegenerate = {
      "name": personalData && personalData.firstname + " " + personalData.lastname,
      "speciality": personalData.specality,
      "qualification": personalData.qualification,
      "query": prompt
    }
    try {
      const result = await badgeAIPromptCall(inputToRegenerate)
      if (result) {

        if (result.generated_prompt) {
          setAIPromptArray(result.generated_prompt)
        }

      } else {
        console.log(" someting went wrong")
      }
    } catch (error) {
      console.log(error, " error in regenerate call")
    }
    dismissLoader()
  }

  // const [availableRescheduleSlots, setAvailableRescheduleSlots] = useState([]);

  const fetchRescheduleDoctorSlots = async () => {
    // console.log(appointmentRescheduleDate);
    showLoader();
    const inputToFetch = {
      input: {
        doctorid: getLoginID(),
        date: appointmentRescheduleDate
          ? moment(appointmentRescheduleDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
      },
    };
    // console.log(inputToFetch);
    
    try {
      const data = await FetchDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];
      // console.log("New Slots: ", slots);
      const currentDate = moment().format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        appointmentRescheduleDate
      )
        ? appointmentRescheduleDate
        : moment(appointmentRescheduleDate).format("DD-MM-YYYY");
      if (
        appointmentRescheduleDate &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("first case");
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        // setAvailableRescheduleSlots(filteredSlots);
        setAvailableSlots(filteredSlots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      
        dismissLoader();
      
     
    }
  };

  const SlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      setAppointmentRescheduleTime(slot.starttime);
      const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
      if (
        selectedTime >= new Date("1970-01-01T09:00:00") &&
        selectedTime < new Date("1970-01-01T12:00:00")
      ) {
        setTimePeriod("Morning");
      } else if (
        selectedTime >= new Date("1970-01-01T12:00:00") &&
        selectedTime < new Date("1970-01-01T18:00:00")
      ) {
        setTimePeriod("Afternoon");
      } else if (
        selectedTime >= new Date("1970-01-01T18:00:00") &&
        selectedTime < new Date("1970-01-01T21:00:00")
      ) {
        setTimePeriod("Evening");
      } else {
        setTimePeriod("Night");
      }
      // console.log(timePeriod);
    };

    return (
      <Box>
        {isLoading && <Loader/>}

        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots{" "}
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "white"
                  }
                  color={
                    appointmentRescheduleTime === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      appointmentRescheduleTime === slot.starttime
                        ? "blue.600"
                        : "gray.200",
                  }}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  //New Visit
  const newVisit = useRef();
  const startVisit = useRef();
  const reAssignModal = useRef();
  const rescheduleVisit = useRef();
  const cancelVisit = useRef();

  const newVisitPatientStatus = useRef();
  const newVisitPatientDetails = useRef();
  const [availableSlots, setAvailableSlots] = useState([]);
  var [DoctorList, setDoctorList] = useState([]);
  var [DoctorListForDropdown, setDoctorListForDropdown] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(doctorcountrycode);
  const [selectedCountryId, setSelectedCountryId] = useState(doctorcountryid);
  const [SelectedCountryForEmergency, setSelectedCountryForEmergency] = useState(doctorcountrycode);
  const [SelectedCountryIdForEmergency, setSelectedCountryIdForEmergency] = useState(doctorcountryid);
  const [timePeriod, setTimePeriod] = useState("Morning");
  const handleCountryChange = (country) => {
    setSelectedCountry(country.code);
    setSelectedCountryId(country.countryid);
    setSelectedCountryForEmergency(country.code);
    setSelectedCountryIdForEmergency(country.countryid);
    setNewVisitData({
      firstname: newVisitData.firstname,
      lastname: newVisitData.lastname,
      age: newVisitData.age,
      gender: newVisitData.gender,
      dob: newVisitData.dob,
      mobileno: newVisitData.mobileno,
      email: newVisitData.email,
      countryid: country.countryid,
      countrycode: country.code,
      emergencycontactcountryid: country.countryid,
      emergencycontactcountrycode: country.code,
      appointmentfor: "",
      appointmenttype_cd: 0,
      schedule_later: "Schedule Now",
      appointment_date: moment().format("DD-MM-YYYY"),
      appointment_time: new Date().toTimeString().substring(0, 5),
      createdby: newVisitData.createdby,
      // fees: newVisitData.fees,
      fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : newVisitData.fees),

      isfreevisit:  newVisitData?.isfreevisit,
      address: newVisitData.patientaddress,
      emergencycontactname: newVisitData.emergencycontactname,
      emergencycontactphone: newVisitData.emergencycontactphone,

    });
  };
  const handleCountryChangeForEmergency = (country) => {
    setSelectedCountryForEmergency(country.code);
    setSelectedCountryIdForEmergency(country.countryid);
  };
  var [DoctorFees, setDoctorFees] = useState(null);
  var [DoctorFeesStatic, setDoctorFeesStatic] = useState(null);
  var [Doctorid, setDoctorid] = useState(null);
  var [DefaultDoctor, setDefaultDoctor] = useState(null);
  const [newVisitData, setNewVisitData] = useState({
    firstname: "",
    lastname: "",
    age: null,
    month: null,
    gender: 2,
    dob: "",
    mobileno: "",
    email: "",
    countryid: doctorcountryid,
    countrycode: doctorcountrycode,
    emergencycontactcountryid: doctorcountryid,
    emergencycontactcountrycode: doctorcountrycode,
    appointmentfor: "",
    appointmenttype_cd: 0,
    schedule_later: "Schedule Now",
    appointment_date: "",
    appointment_time: "",
    createdby: "",
    fees: "",
    isfreevisit: "1",
    address: "",
    emergencycontactname: "",
    emergencycontactphone: "",
  });
  function getGender(value) {
    switch (value) {
      case 1:
      case "female":
        return "Female";
      case 2:
      case "male":
        return "Male";
      case 3:
      case "other":
        return "Other";
      default:
        return "Unknown"; // Fallback for unexpected or unsupported values
    }
  }

  const handleGenderChange = (value) => {
    let numericValue;
    switch (value) {
      case "Male":
        numericValue = 2;
        break;
      case "Female":
        numericValue = 1;
        break;
      case "Other":
        numericValue = 3;
        break;
      default:
        numericValue = null;
    }
    // console.log("Setting gender: ", value, numericValue);
    handleNewVisitChange("gender", numericValue);
  };
  const fetchDoctorSlots = async () => {
    const inputToFetch = {
      input: {
        doctorid: Doctorid,
        // doctorid: getLoginID(),
        date: newVisitData.appointment_date
          ? moment(newVisitData.appointment_date, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
          : null,
      },
    };
    //(inputToFetch);
    showLoader();
    try {
      const data = await GetDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];

      const currentDate = moment().format("DD-MM-YYYY");
      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedDate = isDateInCorrectFormat(newVisitData.appointment_date)
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (newVisitData.appointment_date && formattedDate === currentDate) {
        // console.log(newVisitData.appointment_date);
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };
  const openNewVisitForm = () => {
    setShowNewVisit(false)
    setIsButtonDisabled(false);
    setPatientSearchedList([])
    setshowClearBtn(false)
    setSearchPatient("")
    setAvailableSlots([]);
    setNewVisitData({
      createdby: "",
    });
    fetchDoctors();
    newVisit.current.openModal();
  };
  const fetchDoctors = async () => {
    showLoader();
    try {
      const result = await FetchDoctorsByUser({
        doctorid,
      });
      if (result.output.result == "success") {
        setDoctorList(result.output.data);
        const Doctors = result.output.data && result.output.data.map((step) => [
          step.fullname
        ]);
        setDoctorListForDropdown(Doctors);
        const currentdoctor = getIsDoctor();
        const loginid = getLoginID()
        let obj = {}
        if (currentdoctor == 1 && currentdoctor != null) {
          obj = result.output.data && result.output.data.filter(doctor => doctor.doctorid == loginid);
          if (obj[0]) {
            setDefaultDoctor(obj[0].fullname)
          
            setDoctorFeesStatic(obj[0].fee);
            if(newVisitData?.isfreevisit === "1"){

              setDoctorFees("0.00");

            }else{

            setDoctorFees(formatToDecimal(obj[0].fee));
            }
            setDoctorid(obj[0].doctorid);
            setNewVisitData({
              firstname: "",
              lastname: "",
              age: null,
              gender: 2,
              dob: "",
              mobileno: "",
              email: "",
              countryid: doctorcountryid,
              countrycode: doctorcountrycode,
              emergencycontactcountryid: doctorcountryid,
              emergencycontactcountrycode: doctorcountrycode,
              appointmentfor: "",
              appointmenttype_cd: 0,
              schedule_later: "Schedule Now",
              appointment_date: moment().format("DD-MM-YYYY"),
              appointment_time: new Date().toTimeString().substring(0, 5),
              createdby: obj[0].doctorid,
              // fees: obj[0].fee,
              // fees: newVisitData.isfreevisit != "1" ? obj[0].fee : "0.00",
              fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : obj[0].fee),

              isfreevisit:  newVisitData?.isfreevisit,
            });
          }
        } else {
          setDefaultDoctor("");
          setNewVisitData({
            firstname: "",
            lastname: "",
            age: null,
            gender: 2,
            dob: "",
            mobileno: "",
            email: "",
            countryid: doctorcountryid,
            countrycode: doctorcountrycode,
            emergencycontactcountryid: doctorcountryid,
            emergencycontactcountrycode: doctorcountrycode,
            appointmentfor: "",
            appointmenttype_cd: 0,
            schedule_later: "Schedule Now",
            appointment_date: moment().format("DD-MM-YYYY"),
            appointment_time: new Date().toTimeString().substring(0, 5),
            createdby: "",
            fees: "",
            isfreevisit:  newVisitData?.isfreevisit,
          });
        }
      } else {
        setDoctorList([]);
        dismissLoader();
      }
      dismissLoader();
    } catch (error) {
      console.error("Error", error);
    }
  };
  const HandleEditDoctor = (selectedOption) => {
    setDoctorFees(null)
    let obj = DoctorList.filter(doctor => doctor.fullname == selectedOption);
    // setDoctorFees(formatToDecimal(obj[0].fee));
    
    setDoctorFeesStatic(obj[0].fee);
    if(newVisitData?.isfreevisit === "1"){

      setDoctorFees("0.00");
    }else{

    setDoctorFees(formatToDecimal(obj[0].fee));
    }
    setDoctorid(obj[0].doctorid)
    setNewVisitData({
      firstname: newVisitData.firstname,
      lastname: newVisitData.lastname,
      age: newVisitData.age,
      month: newVisitData.month,
      gender: newVisitData.gender,
      dob: newVisitData.dob,
      mobileno: newVisitData.mobileno,
      email: newVisitData.email,
      countryid: newVisitData.countryid,
      countrycode: newVisitData.countrycode,
      emergencycontactcountryid: newVisitData.emergencycontactcountryid,
      emergencycontactcountrycode: newVisitData.emergencycontactcountrycode,
      appointmentfor: "",
      appointmenttype_cd: 0,
      schedule_later: "Schedule Now",
      appointment_date: moment().format("DD-MM-YYYY"),
      appointment_time: new Date().toTimeString().substring(0, 5),
      createdby: obj[0].doctorid,
      // fees: newVisitData.isfreevisit != "1" ? obj[0].fee : "0.00",
      fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : obj[0].fee),
      
      isfreevisit:  newVisitData?.isfreevisit,
      
    });
  }
  const handleNewVisitChange = (field, value) => {
    setNewVisitData((prev) => ({ ...prev, [field]: value }));
    // if (field === 'mobileno' && value.length === 10) {
    //   handleFetchPatientStatus(field, value); // Call immediately after 10 digits
    // }
    // if (field === 'email' && value.includes('@')) {
    //   handleFetchPatientStatus(field, value); // Call immediately after entering '@'
    // }
  };
  useEffect(() => {
    if (newVisitData.appointment_date != "") {
      fetchDoctorSlots();
    }
  }, [newVisitData.appointment_date, Doctorid]);
  const NewSlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const currentDate = moment().format("DD-MM-YYYY");
    const currentTime = new Date();

    const isSlotDisabled = (slot) => {
      const slotTime = new Date(`1970-01-01T${slot.starttime}`);
      const slotDate = moment(newVisitData.appointment_date).format(
        "DD-MM-YYYY"
      );

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        newVisitData.appointment_date
      )
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (
        newVisitData.appointment_date &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("Same day events")

        // If the slot is for today and its time has passed, disable it

        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);

        return (
          slotHours < currentHours ||
          (slotHours === currentHours && slotMinutes < currentMinutes)
        );
      }

      // If it's not today, don't disable
      return false;
    };

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      if (!isSlotDisabled(slot)) {
        // console.log("Selection made")
        setSelectedSlot(slot.starttime);
        handleNewVisitChange("appointment_time", slot.starttime);
        const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
        if (
          selectedTime >= new Date("1970-01-01T09:00:00") &&
          selectedTime < new Date("1970-01-01T12:00:00")
        ) {
          setTimePeriod("Morning");
        } else if (
          selectedTime >= new Date("1970-01-01T12:00:00") &&
          selectedTime < new Date("1970-01-01T18:00:00")
        ) {
          setTimePeriod("Afternoon");
        } else if (
          selectedTime >= new Date("1970-01-01T18:00:00") &&
          selectedTime < new Date("1970-01-01T21:00:00")
        ) {
          setTimePeriod("Evening");
        } else {
          setTimePeriod("Night");
        }
        // console.log(timePeriod);
      }
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={{ base: 4, md: 5 }} spacing={4} overflow='hidden auto' px='1rem' maxH='100px'>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  fontSize={{ base: "xs", md: "md" }}
                  textAlign="center"
                  bg={
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.500"
                      : isSlotDisabled(slot)
                        ? "gray.300"
                        : "white"
                  }
                  color={
                    newVisitData.appointment_time === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      newVisitData.appointment_time === slot.starttime
                        ? "blue.600"
                        : isSlotDisabled(slot)
                          ? "gray.300"
                          : "gray.200",
                  }}
                  disabled={isSlotDisabled(slot)}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const formatDOBDate = (date) => {
    // Check if the date is already in YYYY-MM-DD format
    if (moment(date, "YYYY-MM-DD", true).isValid()) {
      return date;
    }
    // If not, assume it's in DD-MM-YYYY format and convert it to YYYY-MM-DD
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  };
  function isAgeInRange(dob, age) {
    const birthDate = new Date(dob);
    const today = new Date();

    // Calculate the age based on the DOB
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();

    // Adjust if the birthday hasn't occurred yet this year
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      calculatedAge--;
    }

    // Check if the calculated age is within the range of ±1 year of the provided age
    return Math.abs(calculatedAge - age) <= 1;
  }
  const handleFetchPatientStatus = async (field, value) => {
    let inputToSave = "";
    if (field == "email") {
      inputToSave = {
        input: {
          mobileno: newVisitData.mobileno,
          email: value,
          type: "getuser",
        },
      };
    }
    else if (field == "mobileno") {
      inputToSave = {
        input: {
          mobileno: value,
          email: newVisitData.email,
          type: "getuser",
        },
      };
    } else {
      inputToSave = {
        input: {
          mobileno: newVisitData.mobileno || null,
          email: newVisitData.email || null,
          type: "getuser",
        },
      };
    }

    showLoader()
    try {
      const result = await GetPatientStatus(inputToSave.input);
      if (result?.output?.result === "failure") {
        // console.log("true");
        showSuccessToast("No existing record found. Please create a new patient profile.")
        setNewVisitData({
          firstname: "",
          lastname: "",
          age: 0,
          gender: 2,
          dob: "",
          mobileno: inputToSave.input.mobileno,
          email: inputToSave.input.email,
          countryid: newVisitData.countryid,
          countrycode: newVisitData.countrycode,
          emergencycontactcountryid: newVisitData.emergencycontactcountryid,
          emergencycontactcountrycode: newVisitData.emergencycontactcountrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: newVisitData.createdby,
          // fees: DoctorFees,
          // fees: newVisitData.isfreevisit != "1" ? DoctorFees : "0.00",
          fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : DoctorFees),

          isfreevisit:  newVisitData?.isfreevisit,
          address: "",
          emergencycontactname: "",
          emergencycontactphone: "",
        });
        // newVisitPatientDetails.current.closeModal();
        // newVisit.current.openModal();
      } else {
        showSuccessToast("Existing patient details found. Proceed to visit setup.")
        const patientInfo = result.output.data;
        const Age = patientInfo?.dob ? calculateAgeWithYearMonth(patientInfo.dob) : null
        setNewVisitData({
          firstname: patientInfo.firstname,
          lastname: patientInfo.lastname,
          age: Age.years?Age.years:0,
          month: Age.months?Age.months:0,
          gender: patientInfo.gender,
          dob: patientInfo.dob? moment(patientInfo.dob, "YYYY-MM-DD").format("DD-MM-YYYY"):"",
          mobileno: patientInfo.mobileno,
          email: patientInfo.email,
          countryid: patientInfo.countryid,
          countrycode: patientInfo.countrycode,
          emergencycontactcountryid: patientInfo.emergencycontactcountryid ? patientInfo.emergencycontactcountryid : patientInfo.countryid,
          emergencycontactcountrycode: patientInfo.emergencycontactcountrycode ? patientInfo.emergencycontactcountrycode : patientInfo.countrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: newVisitData.createdby,
          // fees: newVisitData.isfreevisit != "1" ? DoctorFees : "0.00",
          fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : DoctorFees),

          isfreevisit:  newVisitData?.isfreevisit,
          address: patientInfo.patientaddress,
          emergencycontactname: patientInfo.emergencycontactname,
          emergencycontactphone: patientInfo.emergencycontactphone,
        });

        // set default country code here as well

        setSelectedCountry(patientInfo?.countrycode);
        setSelectedCountryId(patientInfo?.countryid);
        if (
          patientInfo.emergencycontactcountrycode !== null &&
          patientInfo.emergencycontactcountrycode !== undefined
        ) {
          setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
          setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
        }

        // newVisitPatientStatus.current.closeModal();
        // newVisit.current.openModal();
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in create visit:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  function formatgender(value) {
    if (typeof value !== "string") {
      return value; // Return the value as is if it's not a string
    }

    switch (value.toLowerCase()) { // Optional: Make it case-insensitive
      case "female":
        return 1;
      case "male":
        return 2;
      case "other":
        return 3;
      default:
        return 2; // Default value for unexpected strings
    }
  }
  function calculateAge(dob) {
    // Check if dob is valid
    if (!dob || isNaN(new Date(dob).getTime())) {
      return { years: "", months: "" }; // Return empty values if dob is invalid
    }
  
    const dobDate = new Date(dob);
    const today = new Date();
  
    let years = today.getFullYear() - dobDate.getFullYear();
    let months = today.getMonth() - dobDate.getMonth();
    let days = today.getDate() - dobDate.getDate();
  
    // Adjust months and years if needed
    if (days < 0) {
      months--; // Borrow a month if days are negative
      const daysInPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
      days += daysInPreviousMonth; // Adjust days
    }
  
    if (months < 0) {
      years--; // Borrow a year if months are negative
      months += 12; // Adjust months
    }
  
    return { years, months }; // Return years and months as separate fields
  }
  const handleCreateVisitClick = async (IsAdvance) => {
    if (isButtonDisabled) return; // Prevent further clicks
    setIsButtonDisabled(true);
    const typeofschedule = newVisitData.schedule_later;
    const currentdoctor = getIsDoctor();
    let type = null;
    if (currentdoctor == 1 && currentdoctor != null) {
      if (typeofschedule === "Schedule Now") {
        type = "startvisit"
      }
    }

    const inputToSave = {
      input: {
        firstname: newVisitData.firstname,
        lastname: newVisitData.lastname,
        fullname: newVisitData.firstname + " " + newVisitData.lastname,
        gender: newVisitData.gender ? formatgender(newVisitData.gender) : 2,
        dob: newVisitData.dob ? formatDOBDate(newVisitData.dob) : null,
        age: newVisitData.age,
        month: newVisitData.month,
        mobileno: newVisitData.mobileno ? newVisitData.mobileno : null,
        email: newVisitData.email ? newVisitData.email : null,
        countryid: newVisitData.countryid ? newVisitData.countryid : "",
        countrycode: newVisitData.countrycode ? newVisitData.countrycode : "",
        emergencycontactcountryid: SelectedCountryIdForEmergency ? SelectedCountryIdForEmergency : "",
        emergencycontactcountrycode: SelectedCountryForEmergency ? SelectedCountryForEmergency : "",
        patientimage: null,
        doctorid: Doctorid,
        appointmentfor: null,
        appointmenttype_cd: 693,
        appointmentdate: moment(
          newVisitData.appointment_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        appointmenttime: newVisitData.appointment_time,
        createdby: newVisitData.createdby,
        // fees: newVisitData.fees,
        // fees: newVisitData.isfreevisit != "1" ? newVisitData.fees : "0.00",
        fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : newVisitData.fees),

        isfreevisit: newVisitData.isfreevisit,
        paymenttype: "cash",
        type: type,
        address: newVisitData.address,
        emergencycontactname: newVisitData.emergencycontactname,
        emergencycontactphone: newVisitData.emergencycontactphone,
        patientid: patientid
      },
    };
   // We assume inputToSave.input can have these properties:
   if (inputToSave.input.dob == null) {
    const rawAge = inputToSave.input.age;
    const rawMonth = inputToSave.input.month;
  
    // Convert to numbers (NaN if invalid or nullish)
    const ageYears = parseInt(rawAge, 10);
    const ageMonths = parseInt(rawMonth, 10);
  
    // 1) Only AGE is provided (month is null or invalid)
    if (!isNaN(ageYears) && (rawMonth == null || isNaN(ageMonths))) {
      // "Old" logic: subtract years only
      const currentYear = new Date().getFullYear();
      const yearOfBirth = currentYear - ageYears;
  
      // Fix date to January 2nd
      const birthYear = yearOfBirth;
      const birthMonth = 0; // January = 0
      const birthDay = 2;
  
      const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
  
      // Format as YYYY-MM-DD
      const year = calculatedDOB.getFullYear();
      const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
      const day = String(calculatedDOB.getDate()).padStart(2, '0');
      const formattedDob = `${year}-${month}-${day}`;
  
      inputToSave.input.dob = formattedDob;
    }
    // 2) Only MONTH is provided (age is null or invalid)
    else if (!isNaN(ageMonths) && (rawAge == null || isNaN(ageYears))) {
      // Subtract only months from today's date
      const now = new Date();
      let birthYear = now.getFullYear();
      let birthMonth = now.getMonth() - ageMonths; // 0-based month
      let birthDay = 2; // pick day = 2
  
      // If birthMonth is negative, wrap to previous year
      if (birthMonth < 0) {
        birthYear -= 1;
        birthMonth += 12;
      }
  
      const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
  
      // Format as YYYY-MM-DD
      const year = calculatedDOB.getFullYear();
      const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
      const day = String(calculatedDOB.getDate()).padStart(2, '0');
      const formattedDob = `${year}-${month}-${day}`;
  
      inputToSave.input.dob = formattedDob;
    }
    // 3) Both AGE and MONTH are provided
    else if (!isNaN(ageYears) && !isNaN(ageMonths)) {
      // Subtract years + months
      const now = new Date();
  
      let birthYear = now.getFullYear() - ageYears;
      let birthMonth = now.getMonth() - ageMonths;
      const birthDay = 2;
  
      if (birthMonth < 0) {
        birthYear -= 1;
        birthMonth += 12;
      }
  
      const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
  
      // Format as YYYY-MM-DD
      const year = calculatedDOB.getFullYear();
      const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
      const day = String(calculatedDOB.getDate()).padStart(2, '0');
      const formattedDob = `${year}-${month}-${day}`;
  
      inputToSave.input.dob = formattedDob;
    }
  }  
    // Validation checks
    if (inputToSave.input.createdby === "") {
      showErrorToast("Please select any doctor to proceed");
      setIsButtonDisabled(false);
      return;
    } else if (
      (!inputToSave.input.mobileno || inputToSave.input.mobileno.trim() === "") &&
      (!inputToSave.input.email || inputToSave.input.email.trim() === "")
    ) {
      showErrorToast("Please enter either a mobile number or an email.");
      setIsButtonDisabled(false);
      return;
    } else if (
      inputToSave.input.firstname === ""
    ) {
      showErrorToast("Please enter patient name");
      setIsButtonDisabled(false);
      return;
    } else if (
      inputToSave.input.mobileno !== "" &&
      inputToSave.input.mobileno !== null &&
      inputToSave.input.mobileno !== undefined
    ) {
      // Validate mobile number (numbers only and exactly 10 digits)
      const mobileRegex = /^[0-9]{9,10}$/;
      if (!mobileRegex.test(inputToSave.input.mobileno)) {
        showErrorToast(
          "Invalid mobile number."
        );
        // handleNewVisitChange("mobileno", "");
        setIsButtonDisabled(false);
        return;
      }
    } else if (
      inputToSave.input.email !== "" &&
      inputToSave.input.email !== null &&
      inputToSave.input.email !== undefined
    ) {
      // Validate email address if provided
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputToSave.input.email)) {
        showErrorToast("Invalid email address. Please enter a valid email.");
        // handleNewVisitChange("email", "");
        setIsButtonDisabled(false);
        return;
      }
    }
    // console.log(inputToSave.input);
    // console.log(inputToSave.input.dob);

    // if (
    //   inputToSave.input.dob !== "" &&
    //   inputToSave.input.dob !== null &&
    //   inputToSave.input.dob !== undefined &&
    //   inputToSave.input.age !== "" &&
    //   inputToSave.input.age !== null &&
    //   inputToSave.input.age !== undefined
    // ) {
    //   // console.log("Checking");
    //   if (!isAgeInRange(inputToSave.input.dob, inputToSave.input.age)) {
    //     showErrorToast("Age and DOB do not match.");
    //     return;
    //   }
    // }

    try {
      const result = await CreateNewVisit(inputToSave.input);
      if (result.output.result == "success") {
        if (IsAdvance === "true") {
          localStorage.removeItem('formState');
          localStorage.removeItem('saveStates');
          localStorage.removeItem('formData');
          localStorage.removeItem('answers')
          localStorage.removeItem('followUps')
          navigate("/advancedetails", { state: { input: inputToSave.input, patientid: result.output.patientid, appointmentid: result.output.appointmentid } })
        } else if (IsAdvance === "false") {
          const typeofschedule = newVisitData.schedule_later;
          if (currentdoctor == 1 && currentdoctor != null && result.output.patientid) {
            if (typeofschedule === "Schedule Later") {
              navigate(
                `/visitdetails?patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`
              );
            } else {
              navigate(`/startvisit?doctorId=${result.output.doctorid}&patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`)
            }
          }
          else if (result.output.patientid) {
            navigate(
               `/visitdetails?patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`
            );
          }
        }
        showSuccessToast("Visit scheduled successfully");
        newVisit.current.closeModal();
      } else {
        newVisit.current.closeModal();
      }
    } catch (error) {
      console.error("Error in create visit:", error);
      showErrorToast("Error Happened While Saving.");
    }finally{
      setIsButtonDisabled(false);
    }
  };
  //New Visit  from your patient

  var [DoctorList, setDoctorList] = useState([]);
  var [DoctorListForDropdown, setDoctorListForDropdown] = useState([]);
  var [DefaultDoctor, setDefaultDoctor] = useState(null);
  var [Doctorid, setDoctorid] = useState(null);
  var [patientid, setPatientID] = useState("");
  var [appointmentid, setAppointmentID] = useState("");
  var [SearchPatient, setSearchPatient] = useState("");
  var [showClearBtn, setshowClearBtn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchDropdownHeight, setSearchDropdownHeight] = useState('');

  const fetchDoctorSlotsforNewVisit = async () => {
    const inputToFetch = {
      input: {
        doctorid: Doctorid,
        date: newVisitData.appointment_date
          ? moment(newVisitData.appointment_date, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
          : null,
      },
    };
    //(inputToFetch);
    showLoader();
    try {
      const data = await GetDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];

      const currentDate = moment().format("DD-MM-YYYY");
      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedDate = isDateInCorrectFormat(newVisitData.appointment_date)
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (newVisitData.appointment_date && formattedDate === currentDate) {
        // console.log(newVisitData.appointment_date);
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };
  const openScheduleModal = async (ptntid, aptmntid) => {
    showLoader()
    setPatientID(ptntid)
    setAppointmentID(aptmntid);
    let doctorfee = null;
    let doctid = null;
    try {
      const resp = await FetchPatientDetails({
        patientid: ptntid,
      });

      const result = await FetchDoctorsByUser({
        doctorid,
      });
      if (result.output.result == "success") {
        setDoctorList(result.output.data);
        const Doctors = result.output.data && result.output.data.map((step) => [
          step.fullname
        ]);
        setDoctorListForDropdown(Doctors);
        const currentdoctor = getIsDoctor();
        const loginid = getLoginID()
        let obj = {}
        if (currentdoctor == 1 && currentdoctor != null) {
          obj = result.output.data && result.output.data.filter(doctor => doctor.doctorid == loginid);
          if (obj[0]) {
            setDefaultDoctor(obj[0].fullname)
            setDoctorid(obj[0].doctorid);
            setAvailableSlots([]);
            fetchDoctorSlotsforNewVisit();
            setTimePeriod("Morning");
            // setDoctorFees(obj[0].fee)
            
            setDoctorFeesStatic(obj[0].fee);
            if(newVisitData?.isfreevisit === "1"){

              setDoctorFees("0.00");
            }else{

            setDoctorFees(formatToDecimal(obj[0].fee));
            }
            doctorfee = obj[0].fee;
            doctid = obj[0].doctorid
          }
        } else {
          setDefaultDoctor("");
        }
      } else {
        setDoctorList([]);
        dismissLoader();
      }
      if (resp != null) {
        const patientInfo = resp.patientinfo;
        //console.log(patientInfo)
        const Age = patientInfo?.dob ? calculateAgeWithYearMonth(moment(patientInfo.dob, "Do MMMM, YYYY").format("YYYY-MM-DD")) : null
        setNewVisitData({
          firstname: patientInfo.firstname,
          lastname: patientInfo.lastname,
          age: Age.years?Age?.years:0,
          month: Age.months?Age?.months:0,
          gender: patientInfo.gender,
          dob: patientInfo?.dob ? moment(patientInfo?.dob, "Do MMMM, YYYY").format("DD-MM-YYYY") : "",
          mobileno: patientInfo.mobileno,
          email: patientInfo.email,
          countryid: patientInfo.countryid,
          countrycode: patientInfo.countrycode,
          emergencycontactcountryid: patientInfo.emergencycontactcountryid,
          emergencycontactcountrycode: patientInfo.emergencycontactcountrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: doctid,
          // fees: doctorfee,
          // fees: newVisitData.isfreevisit != "1" ? DoctorFees : "0.00",
          fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : DoctorFees),

          isfreevisit:  newVisitData?.isfreevisit,
          address: patientInfo.patientaddress,
          emergencycontactname: patientInfo.emergencycontactname,
          emergencycontactphone: patientInfo.emergencycontactphone,
        });
        if (
          patientInfo.countrycode !== null &&
          patientInfo.countrycode !== undefined
        ) {
          setSelectedCountry(patientInfo.countrycode);
          setSelectedCountryId(patientInfo.countryid);
        }
        if (
          patientInfo.emergencycontactcountrycode !== null &&
          patientInfo.emergencycontactcountrycode !== undefined
        ) {
          setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
          setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
        }
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
    newVisit.current.openModal();
    setShowNewVisit(true)
    dismissLoader()
  };
  const HandleSearchPatient = (e) => {
    setSearchPatient(e.target.value)
  }
  const HandleClearData = () => {
    setSearchPatient("");
    setPatientSearchedList([])
    setshowClearBtn(false)
    setnewvisitdisplaytext("")
  }
  const SearchPatientOnKeyPress = (event) => {
    if (event.key === "Enter") {
      HandleSearchPatientCLick();
    }
  }
  const HandleSearchPatientCLick = async () => {
    const input = { searchtext: SearchPatient };
    setshowClearBtn(true)
    showLoader()
    try {
      const result = await GetSearchedPatientsList(input);
      if (result?.output?.result === "failure") {
        showSuccessToast("No existing record found. Please create a new patient profile.")
        setnewvisitdisplaytext("No patient data matches your search input!")
      } else {
        showSuccessToast("Existing patient details found. Click on the patient to proceed")
        setPatientSearchedList(result?.output?.data)
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in create visit:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };
  const handlepatientSelected = (item) => {
    setPatientID(item.patientid);
    console.log(newVisitData , 'newVisitData')
    const patientInfo = item;
    const Age = patientInfo?.dob ? calculateAgeWithYearMonth(patientInfo.dob) : null
    setNewVisitData({
      firstname: patientInfo.firstname,
      lastname: patientInfo.lastname,
      age: Age.years?Age?.years:0,
      month: Age.months?Age?.months:0,
      gender: patientInfo.gender,
      dob: patientInfo.dob?  moment(patientInfo.dob, "YYYY-MM-DD").format("DD-MM-YYYY"):"",
      mobileno: patientInfo.mobileno,
      email: patientInfo.email,
      countryid: patientInfo.countryid,
      countrycode: patientInfo.countrycode,
      emergencycontactcountryid: patientInfo.emergencycontactcountryid ? patientInfo.emergencycontactcountryid : patientInfo.countryid,
      emergencycontactcountrycode: patientInfo.emergencycontactcountrycode ? patientInfo.emergencycontactcountrycode : patientInfo.countrycode,
      appointmentfor: "",
      appointmenttype_cd: 0,
      schedule_later: "Schedule Now",
      appointment_date: moment().format("DD-MM-YYYY"),
      appointment_time: new Date().toTimeString().substring(0, 5),
      createdby: newVisitData.createdby,
      // fees: DoctorFees,
      // fees: newVisitData.isfreevisit != "1" ? DoctorFees : "0.00",
      fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : DoctorFees),

      isfreevisit:  newVisitData?.isfreevisit,
      address: patientInfo.patientaddress,
      emergencycontactname: patientInfo.emergencycontactname,
      emergencycontactphone: patientInfo.emergencycontactphone,
    });
    setSelectedCountry(patientInfo?.countrycode);
    setSelectedCountryId(patientInfo?.countryid);
    if (
      patientInfo.emergencycontactcountrycode !== null &&
      patientInfo.emergencycontactcountrycode !== undefined
    ) {
      setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
      setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
    }
    setShowNewVisit(true);
  };
  const ShowVisitForm = () => {
    // setNewVisitData({
    //   fees: "0.00",
    //   isfreevisit:  "1",

    // });
    setShowNewVisit(true)
  }
  const TableHeaderPatients = [
    { key: "patient", label: "Patient" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile No" },
    { key: "address", label: "Address" },
  ];

  // View Profile Functionality
  const handleClick = async (appointment, patient) => {
    // if (appointment && patient) {
    
    localStorage.setItem("previousPage" , "home")
      navigate(
        `/patientprofile?id=${patient}&page=overview`
      );
      // navigate(
      //   `/visitdetails?patientId=${patient}&appointmentId=${appointment}`
      // );
    // } else {
    //   console.error("Error: Visit details are incomplete.");
    // }
  };

  // ---------------------------------------------------------------------
  // Reschedule Modal Functionality
    const [pageLoad, setPageLoad] = useState(true);
    const [appointmentRescheduleDate, setAppointmentRescheduleDate] = useState(
        new Date()
      );
      const [appointmentRescheduleTime, setAppointmentRescheduleTime] =
        useState(null);
  
  useEffect(() => {
      if(!pageLoad){
        fetchRescheduleDoctorSlots();
      }
      setPageLoad(false)
      
    }, [appointmentRescheduleDate]);

    const openRescheduleModal = async (ptntid, aptmntid) => {
      setPatientID(ptntid)
      setAppointmentID(aptmntid)
      // console.log("function called", availableSlots);
      // setAvailableRescheduleSlots([]);
      setAvailableSlots([])
      fetchRescheduleDoctorSlots();
      // fetchDoctorSlotsforNewVisit();
      setTimePeriod("Morning");
      setAppointmentRescheduleTime(null);
      setAppointmentRescheduleDate(new Date());
      rescheduleVisit.current.openModal();
    };
  
    const handleRescheduleClick = async () => {
      if (!appointmentRescheduleTime) {
        showErrorToast("Please select a slot before rescheduling");
      } else {
        const inputToSave = {
          input: {
            patientid: patientid,
            doctorid: getLoginID(),
            appointmentdate: moment(appointmentRescheduleDate).format(
              "YYYY-MM-DD"
            ),
            appointmenttime: appointmentRescheduleTime.substring(0, 5),
            // appointmentfor: "Follow-up consultation",
            familyid: null,
            appointmentid: appointmentid,
            reason: "Scheduling conflict",
            type: "reschedule",
          },
        };
  
        // console.log(inputToSave);
        try {
          const result = await ResheduleAppointmentByDoc(inputToSave.input);
          // console.log(result);
          if (result) {
            rescheduleVisit.current.closeModal();
            showSuccessToast("Visit rescheduled successfully");
            // navigate(`/visits`);
            // getFilteredResults();
            fetchpaginationData()
          }
        } catch (error) {
          console.error("Error in create visit:", error);
          showErrorToast("Error Happened While Saving.");
        }
      }
    };

  // ---------------------------------------------------------------
  // Cancel Visit Functionality
  
  const [fullName, setFullName] = useState('')
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [cancelApptmtID, setCancelApptmtID] = useState('')
  const [cancelPatientId, setCancelPatientID] = useState('')
  const openCancelModal = async (name, date, time, pid, aptmtid) => {
    // console.log('name: ', name)
    // console.log('date: ', date)
    // console.log('time: ', time)
    setFullName(name)
    setDate(formatDate(date))
    setTime(time)
    setCancelPatientID(pid)
    setCancelApptmtID(aptmtid)
    cancelVisit.current.openModal();
  };

  const handleConfirmCancel = async () => {
    // console.log("Visit Canceled");
    const inputForStart = {
      input: {
        doctorid: getLoginID(),
        patientid: cancelPatientId,
        appointmentid: cancelApptmtID,
        reason: "abc", // CHANGE IT -> TO an input box
        completed: "2",
      },
    };
    try {
      // console.log("input of cancel", inputForStart.input);
      const result = await ResheduleAppointmentByDoc(inputForStart.input);
      // console.log("result of cancel", result);
      cancelVisit.current.closeModal();

      showSuccessToast("Visit canceled successfully.");
      // navigate(`/visits`);
      fetchpaginationData();
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };


  // Start Visit Functionality

  const doctorId = getLoginID();
  const [appointmentStatus, setAppointmentStatus] = useState(null)
  const handleStartVisit = (docId, ptntId, apptmtId, aptmtStatus) => {
    setAppointmentStatus(aptmtStatus)
    setPatientID(ptntId)
    setAppointmentID(apptmtId)
    startVisit.current.openModal();
    // navigate(`/startvisit?doctorId=${docId}&patientId=${ptntId}&appointmentId=${apptmtId}`)
  }
   const [ShowAssignModal , SetShowAssignModal ] = useState(false)
   const [AppointmentIDForAssign , setAppointmentIDForAssign ] = useState(null)
   const handleReAssign = async (appointmentID) => {
     setAppointmentIDForAssign(appointmentID)
     SetShowAssignModal(false);
     setTimeout(() => SetShowAssignModal(true), 10);
   }
  const handleConfirmStart = async () => {
    const inputForStart = {
      input: {
        doctorid: doctorId,
        appointmentid: appointmentid,
        type: "start",
        time: new Date().toISOString(),
      },
    };
    try {
      const result = await UpdateAppointmentStatus(inputForStart.input);
      startVisit.current.closeModal();
      navigate(
        `/startvisit?doctorId=${doctorId}&patientId=${patientid}&appointmentId=${appointmentid}`
      );
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleAdvancedDetails = async (patientId, appointmentId, doctorId) => {
    
    showLoader();
    try {
      const result = await GetPatientHealthData({
        patientid: patientId,
        doctorid: doctorId,
      });
      if(result.patientinfo){
        // Create the input to save
        const Age = result?.patientinfo?.dob ? calculateAgeWithYearMonth(result?.patientinfo.dob) : null
      const inputToSave = {
        input: {
          firstname: result.patientinfo?.firstname,
          lastname: result.patientinfo?.lastname,
          fullname: result.patientinfo?.firstname + " " + result.patientinfo?.lastname,
          gender: result.patientinfo?.gender ? formatgender(result.patientinfo?.gender) : 2,
          dob: result.patientinfo?.dob ? formatDOBDate(result.patientinfo?.dob) : null,
          age: Age?.years?Age?.years:0,
          month: Age?.months?Age?.months:0,
          mobileno: result.patientinfo?.mobileno || null,
          email: result.patientinfo?.email || null,
          countryid: result.patientinfo?.countryid || "",
          countrycode: result.patientinfo?.countrycode || "",
          doctorid: doctorId,
          patientid: patientId,
        },
      };
  
    // Navigate to advanced details
    navigate("/advancedetails", {
      state: {
        input: inputToSave.input,
        patientid: patientId,
        appointmentid: appointmentId,
      },
    });
      } 
    } catch (error) {
      console.error("Error in fetching patient info:", error);
      return null; // Return null in case of an error
    } finally {
      dismissLoader();
    }   
}
  return (
    <>
      <style>{css}</style>
      <style>{swiperDocCSS}</style>
      {screenType == 'web' ? (
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            {isLoading && <Loader />}
            <Box w="100%">
              {/* Main container navbar start */}
              <Box w="100%" bg="#0B0B0D" pos="sticky" top="0">
                <Box
                  display="flex"
                  flexDir="column"
                  gap="0.8rem"
                  p="1.5rem"
                  pb="0"
                  alignItems="center"
                >
                  <Box display="flex" w="100%">
                    <Box
                      border="1px solid #232325"
                      bg="#151619"
                      w="3.5rem"
                      h="3.5rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="full"
                    >

                      <Image src={Greetingsicon ? Greetingsicon : "/assets/svgs/sun.svg"} />

                    </Box>
                    <Box ml="0.7rem">
                      <Text fontSize="1.25rem" fontWeight="600" color="white">
                        {Greetings}, {username}
                      </Text>
                      <Text fontSize="1rem" fontWeight="500" color="#CDCFD4">
                        You have{" "}
                        <strong>
                          {data && data.resultscheduledvisits} visits
                        </strong>{" "}
                        scheduled today.
                      </Text>
                    </Box>
                    <Spacer />
                    <Box
                      display="flex"
                      alignItems="center"
                      bg="transparent"
                      p="0.5rem 0.75rem"
                      h="2.5rem"
                      gap="0.625rem"
                      mr="1rem"
                      border="1px solid #232325"
                      borderRadius=".625rem"
                    >
                      <Image src="/assets/svgs/user-role.svg" width="14px" />
                      <Text fontSize="0.87rem" fontWeight="500" color="#E6E7E9">
                        {rolename}
                      </Text>
                    </Box>
                    {isDoctor && isDoctor == 1 && (
                      <Box
                        as="a"
                        display="flex"
                        alignItems="center"
                        bg="transparent"
                        p="0.5rem 0.75rem"
                        h="2.5rem"
                        gap="0.625rem"
                        mr="1rem"
                        border="1px solid #232325"
                        borderRadius=".625rem"
                        onClick={openDoctorWeb}
                        cursor="pointer"
                        target="_blank"
                      >
                        <Image src="/assets/svgs/website.svg" />
                        <Text fontSize="0.87rem" fontWeight="500" color="#E6E7E9">
                          My website
                        </Text>
                      </Box>
                    )}

                    {isDoctor && isDoctor == 1 && (
                      <Box
                        as="button"
                        display="flex"
                        alignItems="center"
                        bg="transparent"
                        p="0.5rem 0.75rem"
                        h="2.5rem"
                        gap="0.625rem"
                        border="1px solid #232325"
                        borderRadius=".625rem"
                      >
                        {isavailable === 1 ? (
                          <>
                            <Image src='/assets/svgs/online-circle.svg' />
                            <Text fontSize="0.87rem" fontWeight="500" color="#f5f8ff">Available</Text>
                            <Switch colorScheme="green" onChange={(e) => switchclicked(e)} defaultChecked />
                          </>
                        ) : (
                          <>
                            {/* <Text fontSize="0.87rem" fontWeight="500" color="#f5f8ff">Unavailable</Text> */}
                            <Switch colorScheme="green" onChange={(e) => switchclicked(e)} />
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Divider
                    borderBottom="1px solid #232325"
                    opacity="1"
                    mx="1.5rem"
                    my="1rem"
                  />
                </Box>
                {/* Main container navbar end */}
              </Box>

              {/* Main container start */}
              <Box
                w="calc(100vw - 5.5rem)"
              >
                {/* Charts section start */}
                {Currentloggedin != 4 && Currentloggedin != null &&
                  <Box w="100%" bg="#0B0B0D">
                    <Flex w="100%" p="1.5rem" pt="0" gap="1rem">
                      <Box
                        display="flex"
                        w="100%"
                        gap="0.8rem"
                        justifyContent="space-between"
                        alignItems="center"
                        pr="0.65rem"
                        borderRight="1px solid #232325"
                      >
                        <NavLink to="/totalrevenue">
                          <Box>
                            <Box>
                              <Text
                                textTransform="uppercase"
                                fontSize="0.75rem"
                                fontWeight="600"
                                color="#6A707D"
                              >
                                total revenue
                              </Text>

                            </Box>
                            <Box>
                              <Text fontSize="1.75rem" fontWeight="600" color="white">
                                ${data && formatToDecimalandThousandSeparated(data.resulttotalrevenue)}
                              </Text>
                              <Box display="flex" alignItems="center" gap="0.65rem">
                                <Text
                                  fontSize="0.75rem"
                                  borderRadius="full"
                                  fontWeight="600"
                                  color={data && data.totalrevenuelastmonthcomparison < 0 ? '#FA594E' : '#14C585'}
                                  p="0.25rem 0.5rem"
                                  bg={data && data.totalrevenuelastmonthcomparison < 0 ? '#F044383D' : '#032217'}
                                >
                                  {data && data.totalrevenuelastmonthcomparison ? data.totalrevenuelastmonthcomparison + "%" : "-"}
                                </Text>
                                <Text
                                  fontSize="0.75rem"
                                  fontWeight="600"
                                  color="#6A707D"
                                >
                                  compared to last month
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </NavLink>
                        <Box>
                          {/* <Image src="/assets/imgs/green-chart-home.png" /> */}
                          {data && data.resulttotalrevenueforcharts.length > 0 ? (
                            <TrendChart data={data.resulttotalrevenueforcharts} color={data && data.totalrevenuelastmonthcomparison < 0 ? '#F04438' : '#14C585'} />
                          ) : (
                            <Text></Text>
                          )}
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        w="100%"
                        gap="0.8rem"
                        justifyContent="space-between"
                        alignItems="center"
                        pr="0.65rem"
                        borderRight="1px solid #232325"
                      >
                        <NavLink to="/totalrevenue">
                          <Box>
                            <Box>
                              <Text
                                textTransform="uppercase"
                                fontSize="0.75rem"
                                fontWeight="600"
                                color="#6A707D"
                              >
                                Total visit hours
                              </Text>
                            </Box>
                            <Box>
                              <Text fontSize="1.75rem" fontWeight="600" color="white">
                                {data && data.resulttotalvisithours} hrs
                              </Text>
                              <Box display="flex" alignItems="center" gap="0.65rem">
                                <Text
                                  fontSize="0.75rem"
                                  borderRadius="full"
                                  fontWeight="600"
                                  color={data && data.totalvisithourslastmonthcomparison < 0 ? '#FA594E' : '#14C585'}
                                  p="0.25rem 0.5rem"
                                  bg={data && data.totalvisithourslastmonthcomparison < 0 ? '#F044383D' : '#032217'}
                                >
                                  {data && data.totalvisithourslastmonthcomparison ? data.totalvisithourslastmonthcomparison + "%" : "-"}
                                </Text>
                                <Text
                                  fontSize="0.75rem"
                                  fontWeight="600"
                                  color="#6A707D"
                                >
                                  compared to last month
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </NavLink>
                        <Box>
                          {/* <Image src="/assets/imgs/green-chart-home.png" /> */}
                          {data && data.resulttotalvisithoursforcharts.length > 0 ? (
                            <TrendChart data={data && data.resulttotalvisithoursforcharts} color={data && data.totalvisithourslastmonthcomparison < 0 ? '#F04438' : '#14C585'} />
                          ) : (
                            <Text></Text>
                          )}

                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        w="100%"
                        gap="0.8rem"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Box>
                            <Text
                              textTransform="uppercase"
                              fontSize="0.75rem"
                              fontWeight="600"
                              color="#6A707D"
                            >
                              Total visits
                            </Text>
                          </Box>
                          <Box>
                            <Text fontSize="1.75rem" fontWeight="600" color="white">
                              {data && data.resulttotalfollowupvisits}
                            </Text>
                            <Box display="flex" alignItems="center" gap="0.65rem">
                              <Text
                                fontSize="0.75rem"
                                borderRadius="full"
                                fontWeight="600"
                                color={data && data.totalfollowupvisitslastmonthcomparison < 0 ? '#FA594E' : '#14C585'}
                                p="0.25rem 0.5rem"
                                bg={data && data.totalfollowupvisitslastmonthcomparison < 0 ? '#F044383D' : '#032217'}
                              >
                                {data && data.totalfollowupvisitslastmonthcomparison ? data.totalfollowupvisitslastmonthcomparison + "%" : "-"}
                              </Text>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="600"
                                color="#6A707D"
                              >
                                compared to last month
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          {/* <Image src="/assets/imgs/red-chart-home.png" /> */}
                          {data && data.resulttotalfollowupvisitsforcharts.length > 0 ? (
                            <TrendChart data={data && data.resulttotalfollowupvisitsforcharts} color={data && data.totalfollowupvisitslastmonthcomparison < 0 ? '#F04438' : '#14C585'} />
                          ) : (
                            <Text></Text>
                          )}
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                }
                {/* Charts section end */}
                <Flex>
                  {/* Main container start */}
                  <Box w="85%" bg="#fafafa" p="1.5rem" pb="0" overflow="hidden auto" h={rolename == 'Receptionist' ? "calc(100vh - 125px)" : "calc(100vh - 230px)"}>
                    {/* Upcoming visits start  */}
                    {data && data.resultupcomingvisits.length > 0 && (
                      <Box w="100%" overflow="visible" mb="1rem">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          w="100%"
                          mb="0.75rem"
                        >
                          <Text fontSize="1rem" fontWeight="600" color="#061027">
                            Upcoming visits
                          </Text>
                          <Box display="flex" gap="0.65rem">
                            <Box
                              as="button"
                              onClick={() => handleNavigation("prev")}
                            >
                              <Image w="6px" src="/assets/imgs/left-icon.png" />
                            </Box>
                            <Box
                              as="button"
                              onClick={() => handleNavigation("next")}
                            >
                              <Image w="6px" src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          gap="1.25rem"
                          w="100%"
                          justifyContent="start"
                        >
                          <Swiper
                            onSwiper={handleNavigation}
                            slidesPerView={2}
                            ref={swiperInstance}
                            className="upcomingVisits"
                            style={{ position: 'relative', zIndex: 1 }}
                          >
                            {data.resultupcomingvisits.map((step, index) => (
                              <SwiperSlide style={{ position: "relative", zIndex: -1 }}>
                                <Box
                                  key={index}
                                  bg="#ffffff"
                                  border="1px solid #E6E7E9"
                                  borderRadius="0.75rem"
                                  p="1rem"
                                  gap="0.625rem"
                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                  w="100%"
                                >
                                  <Box
                                    display="flex"
                                    gap="0.5rem"
                                    w="100%"
                                    alignItems="center"
                                  >
                                    <Image
                                      borderRadius="full"
                                      w="2.75rem"
                                      h="2.75rem"
                                      objectFit='contain'
                                      src={step.patientimage ? 
                                        (step.patientimage.startsWith('http') ? step.patientimage : step.imagepath + step.patientimage) 
                                        : '/assets/imgs/no-image.png'}
                                      // src={step.patientimage ? step.imagepath + step.patientimage : "/assets/imgs/no-image.png"}
                                    />
                                    <Box maxW="60%">
                                      <Text
                                        fontSize="1rem"
                                        fontWeight="600"
                                        color="#061027"
                                        className="textOverflow"
                                        title={step.patientname ? step.patientname : "-"}
                                      >
                                        {step.patientname
                                          ? step.patientname
                                          : "-"}
                                      </Text>
                                      <Box
                                        display="flex"
                                        gap="0.25rem"
                                        alignItems="center"
                                      >
                                        <Image src="/assets/svgs/home-calendar.svg" />
                                        <Text
                                          fontSize="0.75rem"
                                          fontWeight="500"
                                          color="#384052"
                                          className="textOverflow"
                                          title={formatDate(step.appointmentdate) + " at " + formatTimeVisit(step.appointmenttime)}
                                        >
                                          {/* {formatDate(step.appointmentdate)} */}
                                          {formatDate(step.appointmentdate) + " at " + formatTimeVisit(step.appointmenttime)}
                                        </Text>
                                      </Box>
                                    </Box>
                                    <Spacer />
                                    <Menu pos='relative'>
                                      <MenuButton as='button'>
                                        <Image src="/assets/svgs/more-options.svg" />
                                      </MenuButton>
                                      <MenuList zIndex={9999} pos="absolute" right="-20px" minW="max-content">
                                        <MenuItem
                                          isDisabled={actionAccessVisits && actionAccessVisits.read ? false : true}
                                          onClick={() => navigate(`/visitdetails?patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}>View
                                          </MenuItem>
                                         {step.appointmentstatus === "pause" ? (

                                           <MenuItem
                                           isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                          //  onClick={() => navigate(`/startvisit?doctorId=${step.doctorid}&patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}
                                           onClick={() => handleStartVisit(step.doctorid, step.patientid, step.appointmentid, step.appointmentstatus)}
                                           >
                                            Resume Visit
                                           </MenuItem>
                                         ) : (
                                          <>
                                           <MenuItem
                                           isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                           onClick={() => handleStartVisit(step.doctorid, step.patientid, step.appointmentid, step.appointmentstatus)}
                                           >
                                            Start Visit
                                           </MenuItem>
                                           <MenuItem
                                              onClick={()=> handleAdvancedDetails(step.patientid, step.appointmentid, step.doctorid)}
                                              isDisabled={actionAccessScreening && (actionAccessScreening.create) ? false : true}
                                            >
                                              Advanced details
                                            </MenuItem>
                                            
                                          </>
                                         )}
                                         <MenuItem
                                              onClick={()=>handleReAssign(step.appointmentid)}
                                              isDisabled={actionAccessAssigning && actionAccessAssigning.create ? false : true}
                                            >
                                              Assign Appointment
                                         </MenuItem>
                                          
                                         
                                        {/* <MenuItem>Create a Copy</MenuItem>
                                      <MenuItem>Mark as Draft</MenuItem> */}
                                      </MenuList>
                                    </Menu>
                                  </Box>
                                  <Box mt="0.75rem" w="90%">
                                    <Text
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="#384052"
                                      className="textOverflow"
                                      title={step.chiefcomplaint ? step.chiefcomplaint : "-"}
                                    >
                                      {step.chiefcomplaint
                                        ? step.chiefcomplaint
                                        : "-"}
                                    </Text>
                                  </Box>
                                  {/* {step.appointmentstatus === "start" || step.appointmentstatus === null && */}
                                  <Divider my='1rem' />
                                  <Box>
                                    <PrimaryButton  buttonText={step.appointmentstatus ==='pause' ? 'Resume visit' : "Start visit"} variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                    // <PrimaryButton  buttonText="Start visit" variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                      // onClick={() => navigate(`/startvisit?doctorId=${step.doctorid}&patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}
                                      onClick={() => handleStartVisit(step.doctorid, step.patientid, step.appointmentid, step.appointmentstatus)}
                                      isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                    />
                                  </Box>
                                  {/* } */}
                                </Box>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Box>
                      </Box>
                    )}
                    {/* Upcoming visits end  */}
                    {/* pending visits start */}
                    {data && data.resultpendingvisits.length > 0 && (
                      <Box w="100%" overflow="visible">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          w="100%"
                          mb="0.75rem"
                        >
                          <Text fontSize="1rem" fontWeight="600" color="#061027">
                            Pending visits
                          </Text>
                          <Box display="flex" gap="0.65rem">
                            <Box
                              as="button"
                              onClick={() => handleUpcoming("prev")}
                            >
                              <Image w="6px" src="/assets/imgs/left-icon.png" />
                            </Box>
                            <Box
                              as="button"
                              onClick={() => handleUpcoming("next")}
                            >
                              <Image w="6px" src="/assets/imgs/right-icon.png" />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          gap="1rem"
                          w="100%"
                          justifyContent="start"
                        >
                          <Swiper
                            onSwiper={handleUpcoming}
                            slidesPerView={2}
                            ref={swiperUpcoming}
                            className="pendingVisits"
                            style={{ position: 'relative', zIndex: 1 }}
                          >
                            {data.resultpendingvisits.map((step, index) => (
                              <SwiperSlide style={{ position: "relative", zIndex: -1 }}>
                                <Box
                                  key={index}
                                  bg="#ffffff"
                                  border="1px solid #E6E7E9"
                                  borderRadius="0.75rem"
                                  gap="0.625rem"
                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                  w="100%"
                                >
                                  <Box
                                    display="flex"
                                    gap="0.5rem"
                                    w="100%"
                                    alignItems="center"
                                    p="1rem"
                                    pb="0"
                                  >
                                    <Image
                                      borderRadius="full"
                                      w="2.75rem"
                                      h="2.75rem"
                                      objectFit='contain'
                                      // src={step.patientimage ? step.imagepath + step.patientimage : "/assets/imgs/no-image.png"}
                                      src={step.patientimage ? 
                                        (step.patientimage.startsWith('http') ? step.patientimage : step.imagepath + step.patientimage) 
                                        : '/assets/imgs/no-image.png'}
                                    />
                                    <Box maxW="60%">
                                      <Text
                                        fontSize="1rem"
                                        fontWeight="600"
                                        color="#061027"
                                        className="textOverflow"
                                        title={step.patientname ? step.patientname : "-"}
                                      >
                                        {step.patientname
                                          ? step.patientname
                                          : "-"}
                                      </Text>
                                      <Box
                                        display="flex"
                                        gap="0.25rem"
                                        alignItems="center"
                                      >
                                        <Image src="/assets/svgs/home-calendar.svg" />
                                        <Text
                                          fontSize="0.75rem"
                                          fontWeight="500"
                                          color="#384052"
                                          className="textOverflow"
                                          title={`${formatDate(step.appointmentdate)} at ${step.appointmenttime}`}
                                        >
                                          {/* {formatDate(step.appointmentdate)}  */}
                                          {`${formatDate(step.appointmentdate)} at ${step.appointmenttime}`}
                                        </Text>
                                      </Box>
                                    </Box>
                                    <Spacer />
                                    <Menu>
                                      <MenuButton as='button'>
                                        <Image src="/assets/svgs/more-options.svg" />
                                      </MenuButton>
                                      <MenuList zIndex={9999} pos="absolute" right="-33px" minW="125px">
                                        <MenuItem
                                          isDisabled={actionAccessVisits && actionAccessVisits.read ? false : true}
                                          onClick={() => navigate(`/visitdetails?patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}>View</MenuItem>
                                        {/* <MenuItem>Create a Copy</MenuItem>
                                      <MenuItem>Mark as Draft</MenuItem> */}
                                      </MenuList>
                                    </Menu>
                                  </Box>
                                  <Box mt="0.75rem" w="100%" p="1rem" pt="0">
                                    <Text
                                      fontSize="0.875rem"
                                      fontWeight="500"
                                      color="#384052"
                                      className="textOverflow"
                                      title={step.chiefcomplaint ? step.chiefcomplaint : "-"}
                                    >
                                      {step.chiefcomplaint
                                        ? step.chiefcomplaint
                                        : "-"}
                                    </Text>
                                  </Box>
                                  <Box p="1rem" borderTop='1px solid #E6E7E9'>
                                    <PrimaryButton onClick={() => acceptvisit(step)} buttonText="Accept visit" variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                      isDisabled={actionAccessVisits && (actionAccessVisits.create) ? false : true}
                                    />
                                  </Box>
                                </Box>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Box>
                      </Box>
                    )}
                    {/* pending visits end  */}
                    {/* your patients start */}
                    {data && data.resultmypatients.length > 0 ? (
                      <Box my="1rem">
                        <Text
                          fontSize="1rem"
                          mb="0.75rem"
                          fontWeight="600"
                          color="#061027"
                        >
                          Your patients
                        </Text>

                        <Box>
                          <SimpleTable
                            headers={TableHeader}
                            isDataPresent={true}
                            isThCss={{px:'1rem'}}
                            tableStyle={{overflow: "hidden"}}
                            tableBody={
                              <>
                                {data &&
                                  data.resultmypatients.map((step, index) => (
                                    <Tr w="100%" key={index}>
                                      <Td w="100x" px='1rem'>
                                        <Box w="100%" display="flex" gap="0.5rem" alignItems="center"
                                        >                                     <Box boxSize="2.75rem">
                                          <Image boxSize="40px" h="40px" objectFit="cover" 
                                          src={step.patientimage ? 
                                          (step.patientimage.startsWith('http') ? step.patientimage : step.imagepath + step.patientimage) 
                                          : '/assets/imgs/no-image.png'}
                                          borderRadius="full" 
                                        /></Box>
                                          {/* <Image
                                          w="2.5rem"
                                          h="2.5rem"
                                          borderRadius="full"
                                          src={step.patientimage? step.imagepath+step.patientimage:"/assets/imgs/doctor-image.png"}
                                        /> */}
                                          <Box w="80%" maxW="8rem" isTruncated>
                                            <Text
                                              className="textOverflow"
                                              fontSize=".875rem"
                                              fontWeight="600"
                                              title={step.patientname}
                                            >
                                              {step.patientname
                                                ? step.patientname
                                                : "-"}
                                            </Text>
                                            {/* <Text
                                            fontSize=".75rem"
                                            fontWeight="400"
                                            color="#384052"
                                          >
                                            NUAK723819313
                                          </Text> */}
                                          </Box>
                                        </Box>
                                      </Td>

                                      <Td px='1rem'>
                                        <Text fontSize=".875rem" fontWeight="500">
                                          {formatDate(step.appointmentdate)}
                                        </Text>
                                      </Td>

                                      <Td px='1rem'>
                                        <Box
                                          p=".25rem .5rem"
                                          w="3rem"
                                          bg="#D0F3E7"
                                          borderRadius="full"
                                        >
                                          <Text
                                            fontSize=".75rem"
                                            fontWeight="600"
                                            color="#109E6A"
                                          >
                                            {step.repeatrate ? "+" + step.repeatrate + "%" : "-"}
                                          </Text>
                                        </Box>
                                      </Td>

                                      <Td px='1rem'>
                                        <Text fontSize=".875rem" fontWeight="500">
                                          {step.totalrevenue ? "$" + formatToDecimalandThousandSeparated(step.totalrevenue) : "-"}
                                        </Text>
                                      </Td>

                                      <Td px='1rem'>
                                        <Text fontSize=".875rem" fontWeight="500">
                                          {step.totalvisits ? step.totalvisits : "-"}
                                        </Text>
                                      </Td>
                                      <Td pos='relative' px='1rem' textAlign='center'>
                                        <Menu>
                                          <MenuButton as='button' zIndex={1}>
                                            <Image w="1.5rem" src="/assets/svgs/more-options.svg" />
                                          </MenuButton>
                                          <MenuList zIndex={9999} pos="relative" right="0" minW="125px">
                                            <MenuItem onClick={() => handleClick(step.appointmentid, step.patientid)}>
                                                View Profile
                                            </MenuItem>
                                            <MenuItem onClick={() => openScheduleModal(step.patientid, step.appointmentid)}
                                              isDisabled={
                                                actionAccessVisits && !actionAccessVisits.create ? true : false
                                              }>
                                              Schedule Visit
                                            </MenuItem>
                                            {(step.appointmentstatus == 0 || step.appointmentstatus == 1) && (
                                              <MenuItem onClick={() => openRescheduleModal(step.patientid, step.appointmentid)}
                                                isDisabled={
                                                  actionAccessVisits && !actionAccessVisits.update ? true : false
                                                }
                                              >
                                                Reschedule Visit
                                              </MenuItem>
                                            )}
                                            {(step.appointmentstatus == 0 || step.appointmentstatus == 1) && (
                                              <MenuItem
                                                // onClick={cancelVisit.current.openModal}
                                                onClick={() => openCancelModal(step.fullname, step.appointmentdate, step.appointmenttime, step.patientid, step.appointmentid)}
                                                isDisabled={
                                                  actionAccessVisits && !actionAccessVisits.delete ? true : false
                                                } >
                                                Cancel Visit
                                              </MenuItem>
                                            )}
                                          </MenuList>
                                        </Menu>
                                      </Td>
                                    </Tr>
                                  ))}
                              </>
                            }
                            footerSection={
                              <>
                                <Tr>
                                  <Td colSpan={6}>
                                    <Box
                                      w="100%"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      {/* {currentPage > 1 &&( */}
                                      <Box as="button" onClick={handlePreviousClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                        {/* <Image
                                          w="0.5rem"
                                          h="auto"
                                          src="/assets/imgs/left-icon.png"
                                          onClick={handlePreviousClick}
                                        /> */}
                                        Previous
                                      </Box>
                                      {/* )}   */}
                                      <Box>
                                        <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                          Page {currentPage} of {totalPages}
                                        </Text>

                                      </Box>
                                      {/* {currentPage < totalPages &&( */}
                                      <Box as="button" p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick} disabled={currentPage === totalPages} visibility={currentPage === totalPages ? 'hidden' : 'visible'} fontSize="1rem" fontWeight="500">
                                        {/* <Image
                                          w="0.5rem"
                                          h="auto"
                                          src="/assets/imgs/right-icon.png"
                                          onClick={handleNextClick}
                                        /> */}
                                        Next
                                      </Box>
                                      {/* )}                                     */}
                                      {/* <Box
                                      display="flex"
                                      gap="0.65rem"
                                      alignItems="center"
                                    >
                                      <Box>
                                        <Image
                                          w="0.5rem"
                                          h="auto"
                                          src="/assets/imgs/left-icon.png"
                                        />
                                      </Box>
                                      <Box
                                        as="button"
                                        boxSize="2rem"
                                        border="1px solid #E2E4E9"
                                        borderRadius="0.37rem"
                                        fontSize="0.87rem"
                                        fontWeight="500"
                                      >
                                        1
                                      </Box>
                                      <Box
                                        as="button"
                                        boxSize="2rem"
                                        border="1px solid #E2E4E9"
                                        borderRadius="0.37rem"
                                        fontSize="0.87rem"
                                        fontWeight="500"
                                      >
                                        2
                                      </Box>
                                      <Box
                                        as="button"
                                        boxSize="2rem"
                                        border="1px solid #E2E4E9"
                                        borderRadius="0.37rem"
                                        fontSize="0.87rem"
                                        fontWeight="500"
                                      >
                                        3
                                      </Box>
                                      <Box
                                        as="button"
                                        boxSize="2rem"
                                        border="1px solid #E2E4E9"
                                        borderRadius="0.37rem"
                                        fontSize="0.87rem"
                                        fontWeight="500"
                                      >
                                        ...
                                      </Box>
                                      <Box
                                        as="button"
                                        boxSize="2rem"
                                        border="1px solid #E2E4E9"
                                        borderRadius="0.37rem"
                                        fontSize="0.87rem"
                                        fontWeight="500"
                                      >
                                        9
                                      </Box>
                                      <Box>
                                        <Image
                                          w="0.5rem"
                                          h="auto"
                                          src="/assets/imgs/right-icon.png"
                                        />
                                      </Box>
                                    </Box> */}
                                    </Box>
                                  </Td>
                                </Tr>
                              </>
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <SimpleTable
                        headers={TableHeader}
                        footerVisible={false}
                        isDataPresent={false}
                      />
                    )}
                    {/* your patients end */}

                    {/* Get insight from Ai start */}
                    {/* <Box w="100%" py="0.625rem" mb="1.25rem">
                    <Box
                      display="flex"
                      mb="0.625rem"
                      justifyContent="space-between"
                    >
                      <Text fontSize="1rem" fontWeight="600" color="#061027">
                        Get insights on your performance with AI
                      </Text>
                      <Box display="flex" gap="1.25rem">
                        <Box
                          as="button"
                          onClick={() => handleNavigation("prev")}
                        >
                          <Image
                            w="0.375rem"
                            src="/assets/imgs/left-icon.png"
                          />
                        </Box>
                        <Box
                          as="button"
                          onClick={() => handleNavigation("next")}
                        >
                          <Image
                            w="0.375rem"
                            src="/assets/imgs/right-icon.png"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                      border="0.0625rem solid #E6E7E9"
                      borderRadius="0.75rem"
                      p="1.25rem"
                      overflowX="hidden"
                      bg="white"
                    >
                      <Flex gap="0.625rem">
                        <Swiper
                          onSwiper={handleNavigation}
                          slidesPerView={2}
                          ref={swiperInstance}
                        >
                          <SwiperSlide>
                            <Box
                              as="button"
                              bg="white"
                              p="0.5rem 0.625rem"
                              display="flex"
                              alignItems="center"
                              w="max-content"
                              border="0.0625rem solid #E6E7E9"
                              borderRadius="full"
                              boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                              onClick={() => AIChatModel.current.openRight()}
                            >
                              <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                              <Text
                                fontSize="0.75rem"
                                fontWeight="600"
                                color="#1C4EC5"
                              >
                                How many visits did i complete last year?
                              </Text>
                            </Box>
                          </SwiperSlide>
                        </Swiper>
                      </Flex>
                    </Box>
                  </Box> */}
                    {/* Get insight from Ai end */}

                    {/* Share badge start */}
                    <Box
                      w="100%"
                      p="2.5rem"
                      borderRadius="0.75rem"
                      bg={`url('/assets/imgs/gradient-circle.png'), ${COLORS.SHARE_BADGE_GRADIENT}`}
                      bgPos="right"
                      bgRepeat="no-repeat"
                      bgSize="contain"
                    >
                      <Flex alignItems="center">
                        <Box>
                          <Text
                            fontSize="1.25rem"
                            mb="0.5rem"
                            fontWeight="600"
                            color="white"
                          >
                            Boost your visibility
                          </Text>
                          <Text fontSize=".87rem" fontWeight="500" color="white">
                            Share Your Expertise, Expand Your Reach,
                            <br /> and Attract More Patients.
                          </Text>
                        </Box>

                        <Spacer />

                        {isDoctor && isDoctor == 1 && (
                          <Box
                            onClick={openShareBadgeModal}
                            as="button"
                            p="0.75rem 1.25rem"
                            border="1px solid #E6E7E9"
                            borderRadius=".625rem"
                            bg="white"
                            boxShadow="0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                            disabled={actionAccess && (!actionAccess.create || !actionAccess.update) ? true : false}
                          >
                            <Text fontSize=".87rem" fontWeight="600" opacity={actionAccess && (!actionAccess.create || !actionAccess.update) ? "0.25" : ""}
                            >
                              Share badge
                            </Text>
                          </Box>
                        )}
                      </Flex>
                    </Box>
                    {/* Share badge end */}

                    {/* footer section start */}
                    <Box
                      display="flex"
                      my="2rem"
                      alignItems="center"
                      w="100%"
                      justifyContent="center"
                      gap="10px"
                    >
                      <Text fontSize="0.625rem" fontWeight="400">
                        A PRODUCT OF
                      </Text>
                      <Image src="/assets/svgs/tci-logo.svg" />
                    </Box>
                    {/* footer section end */}
                  </Box>
                  {/* Main container end */}

                  {/* Recent Activity start */}

                  <Box w="23rem" h="100vh" borderLeft="1px solid #E6E7E9">
                    <Box w="100%">
                      {/* Title */}
                      <Box
                        as="h2"
                        p="1.5rem"
                        py="1rem"
                        fontSize="1.25rem"
                        fontWeight="600"
                        borderBottom="1px solid #E6E7E9"
                      >
                        Quick Links
                      </Box>

                      {/* Links Container */}
                      <Box px="1.5rem" py="1rem">
                        {/* Link 1 */}
                        {IsGlobalAdmin == 1 &&
                          <Box mb="1rem">
                            <a
                              href="/branches"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                fontSize: "1rem",
                                position: "relative",
                                display: "inline-block",
                                cursor: "pointer",
                                lineHeight: "1.5", // Aligns the link properly
                              }}
                              onClick={(e) => {
                                if (actionAccessVisits && !actionAccessVisits.create) {
                                  e.preventDefault(); // Prevent navigation if disabled
                                }
                              }}
                            >
                              Networked Care
                              <Box
                                as="span"
                                position="absolute"
                                left="0"
                                bottom="0"
                                height="1px"
                                width="100%"
                                bg="black"
                              />
                            </a>
                          </Box>
                        }


                        {/* Link 2 */}
                        {actionAccessVisits && actionAccessVisits.create &&
                          <Box>
                            <a
                              href="#"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                fontSize: "1rem",
                                position: "relative",
                                display: "inline-block",
                                cursor: "pointer",
                                lineHeight: "1.5", // Aligns the link properly
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default navigation
                                if (actionAccessVisits && actionAccessVisits.create) {
                                  openNewVisitForm(); // Call the function if allowed
                                }
                              }}
                            >
                              New visit
                              <Box
                                as="span"
                                position="absolute"
                                left="0"
                                bottom="0"
                                height="1px"
                                width="100%"
                                bg="black"
                              />
                            </a>
                          </Box>
                        }
                      </Box>
                    </Box>

                    <Box w="100%" mb="1.25rem">
                      <Box
                        as="h2"
                        p="1.5rem"
                        py="1rem"
                        fontSize="1.25rem"
                        fontWeight="600"
                        borderBottom="1px solid #E6E7E9"
                      >
                        Activity
                      </Box>
                      <VStack p="1.5rem" align="stretch" overflow="hidden auto" h="calc(100vh - 330px)">
                        <Stack direction="column" align="center" gap="0" w="100%">
                          {recentactivity && recentactivity.length > 0 ? (
                            recentactivity.map((step, index) => (
                              <Box
                                key={index}
                                display="flex"
                                flexDir="column"
                                alignItems="center"
                                h="auto"
                                w="100%"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                  w="100%"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    w="100%"
                                  >
                                    <Circle
                                      size="1rem"
                                      bg="white"
                                      border="2px solid #CDCFD4"
                                      borderRadius="full"
                                      color="black"
                                    />
                                    <Text
                                      fontSize="0.9rem"
                                      pl="0.75rem"
                                      fontWeight="500"
                                    >
                                      {step.description ? step.description : "-"}
                                    </Text>
                                  </Box>
                                </Box>
                                <Box display="flex" w="100%">
                                  <Center
                                    h="3.125rem"
                                    ml="0.45rem"
                                    alignItems="start"
                                    borderLeft="2px solid #E6E7E9"
                                  >
                                    <Text
                                      fontSize="0.75rem"
                                      pl="1.35rem"
                                      fontWeight="500"
                                    >
                                      {formatDate(step.date)}
                                    </Text>
                                  </Center>
                                </Box>
                              </Box>
                            ))
                          ) : (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              h="100px"
                              w="100%"
                            >
                              <Text>No records.</Text>
                            </Box>
                          )}

                          {/* <Box display="flex" flexDir="column" alignItems="center" h="auto"  w="100%">
                                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" w="100%">
                                        <Box display="flex" alignItems="center"  w="100%">
                                            <Circle
                                                size="1rem"
                                                bg="white"
                                                border="2px solid #CDCFD4"
                                                borderRadius="full"
                                                color="black"
                                                />
                                            <Text fontSize="0.9rem" pl="0.75rem" fontWeight="500">Sent a prescription to Adnan Jaweed</Text>
                                        </Box>

                                        </Box>
                                        <Box display="flex" w="100%">
                                        <Center h='3.125rem' ml="0.45rem" alignItems="start" borderLeft="2px solid #E6E7E9">
                                            <Text fontSize="0.75rem" pl="1.35rem" fontWeight="500">10th Jan, 2023</Text>
                                        </Center>
                                        
                                        </Box>
                                    </Box>

                                    <Box display="flex" flexDir="column" alignItems="center" h="auto"  w="100%">
                                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" w="100%">
                                        <Box display="flex" alignItems="center"  w="100%">
                                            <Circle
                                                size="1rem"
                                                bg="white"
                                                border="2px solid #CDCFD4"
                                                borderRadius="full"
                                                color="black"
                                                />
                                            <Text fontSize="0.9rem" pl="0.75rem" fontWeight="500">Sent a prescription to Adnan Jaweed</Text>
                                        </Box>

                                        </Box>
                                        <Box display="flex" w="100%">
                                        <Center h='3.125rem' ml="0.45rem" alignItems="start" borderLeft="2px solid #E6E7E9">
                                            <Text fontSize="0.75rem" pl="1.35rem" fontWeight="500">10th Jan, 2023</Text>
                                        </Center>
                                        
                                        </Box>
                                    </Box>

                                    <Box display="flex" flexDir="column" alignItems="center" h="auto"  w="100%">
                                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" w="100%">
                                        <Box display="flex" alignItems="center"  w="100%">
                                            <Circle
                                                size="1rem"
                                                bg="white"
                                                border="2px solid #CDCFD4"
                                                borderRadius="full"
                                                color="black"
                                                />
                                            <Text fontSize="0.9rem" pl="0.75rem" fontWeight="500">Sent a prescription to Adnan Jaweed</Text>
                                        </Box>

                                        </Box>
                                        <Box display="flex" w="100%">
                                        <Center h='3.125rem' ml="0.45rem" alignItems="start" flexDir="column" borderLeft="2px solid #E6E7E9">
                                            <Text fontSize="0.75rem" pl="1.35rem" mt="0.5rem" fontWeight="500">10th Jan, 2023</Text>
                                            <Box as='button' my="0.75rem">
                                                <Text fontSize="0.75rem" pl="1.35rem" fontWeight="700" textDecor="underline">View details</Text>
                                            </Box>
                                        </Center>
                                        
                                        </Box>
                                    </Box>

                                    <Box display="flex" flexDir="column" alignItems="center" h="auto"  w="100%">
                                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" w="100%">
                                        <Box display="flex" alignItems="center"  w="100%">
                                            <Circle
                                                size="1rem"
                                                bg="white"
                                                border="2px solid #CDCFD4"
                                                borderRadius="full"
                                                color="black"
                                                />
                                            <Text fontSize="0.9rem" pl="0.75rem" fontWeight="500">Sent a prescription to Adnan Jaweed</Text>
                                        </Box>

                                        </Box>
                                        <Box display="flex" w="100%">
                                        <Center h='3.125rem' ml="0.45rem" alignItems="start">
                                            <Text fontSize="0.75rem" pl="1.35rem" fontWeight="500">10th Jan, 2023</Text>
                                        </Center>
                                        
                                        </Box>
                                    </Box> */}
                        </Stack>
                      </VStack>
                    </Box>
                  </Box>
                  {/* Recent Activity end */}
                </Flex>
              </Box>
              {/* Main container start */}
            </Box>
          </Flex>
        </Box>
      ) : (
        //Mobile responsive
        <Box w="100%">
          {isLoading && <Loader />}
          <MobileNavbar />
          <Box display="flex" flexDir="column" p="1rem" w="100%" bg="#151619">
            <Box display="flex">
              <Box
                border="1px solid #232325"
                bg="#151619"
                w="3rem"
                h="3rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="full"
              >

                <Image w='1.5rem' src={Greetingsicon ? Greetingsicon : "/assets/svgs/sun.svg"} />

              </Box>
              <Box ml="0.7rem" display="flex" flexDir="column">
                <Text fontSize="1rem" fontWeight="600" color="white">
                  {Greetings},
                </Text>
                <Text fontSize="1rem" fontWeight="600" color="white">
                  {username}
                </Text>
              </Box>
              <Spacer />

              {isDoctor && isDoctor == 1 && (
                <Box
                  as="button"
                  display="flex"
                  alignItems="center"
                  bg="transparent"
                // p="0.5rem 0.75rem"
                // h="2.5rem"
                // gap="0.625rem"
                // border="1px solid #232325"
                // borderRadius=".625rem"
                >
                  {DoctorAvailability === 1 ? (
                    <>
                      {/* <Image src='/assets/svgs/online-circle.svg'/> */}
                      {/* <Text fontSize="0.87rem" fontWeight="500" color="#f5f8ff">Available</Text> */}
                      <Switch colorScheme="green" onChange={(e) => switchclicked(e)} defaultChecked />
                    </>
                  ) : (
                    <>
                      {/* <Text fontSize="0.87rem" fontWeight="500" color="#f5f8ff">Unavailable</Text> */}
                      <Switch colorScheme="green" onChange={(e) => switchclicked(e)} />
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box w='100%' mt='10px'>
              <Text fontSize="0.75rem" fontWeight="500" color="#CDCFD4">
                You have{" "}
                <strong>
                  {data && data.resultscheduledvisits} visits
                </strong>{" "}
                scheduled today.
              </Text>
            </Box>



            <Box display="flex" justifyContent="space-between" mt='10px' alignItems='center' w='100%'>
              <Box
                display="flex"
                alignItems="center"
                bg="transparent"
                p="0.5rem 0.75rem"
                h="2.5rem"
                gap="0.625rem"
                mr="1rem"
                border="1px solid #232325"
                borderRadius=".625rem"
              >
                <Image src="/assets/svgs/user-role.svg" width="14px" />
                <Text fontSize="0.87rem" fontWeight="500" color="#E6E7E9">
                  {rolename}
                </Text>
              </Box>

              <Box
                as="a"
                display="flex"
                alignItems="center"
                bg="transparent"
                p="0.5rem 0.75rem"
                h="2.5rem"
                gap="0.625rem"
                border="1px solid #232325"
                borderRadius=".625rem"
                onClick={openDoctorWeb}
                cursor="pointer"
                target="_blank"
              >
                <Image src="/assets/svgs/website.svg" />
              </Box>
            </Box>
          </Box>


          <Box
            w="100%"
            overflow="hidden auto"
            h="calc(100vh - 230px)"
          >
            {/* Charts section start */}
            <Box w="100%" bg="#0B0B0D">
              <Flex w="100%" flexDir="column" p="1rem" gap="0.5rem">
                <Box
                  display="flex"
                  w="100%"
                  gap="0.8rem"
                  justifyContent="space-between"
                  alignItems="center"
                  pb="0.5rem"
                  borderBottom="1px solid #232325"
                >
                  <NavLink to="/totalrevenue">
                    <Box>
                      <Box>
                        <Text
                          textTransform="uppercase"
                          fontSize="0.75rem"
                          fontWeight="600"
                          color="#6A707D"
                        >
                          total revenue
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="1.75rem" fontWeight="600" color="white">
                          ${data && formatToDecimal(data.resulttotalrevenue)}
                        </Text>
                        <Box display="flex" flexDir="column" alignItems="flex-start" gap="0.25rem">
                          <Text
                            fontSize="0.75rem"
                            borderRadius="full"
                            fontWeight="600"
                            color={data && data.totalrevenuelastmonthcomparison < 0 ? '#FA594E' : '#14C585'}
                            p="0.25rem 0.5rem"
                            bg={data && data.totalrevenuelastmonthcomparison < 0 ? '#F044383D' : '#032217'}
                          >
                            {data && data.totalrevenuelastmonthcomparison ? data.totalrevenuelastmonthcomparison + "%" : "-"}
                          </Text>
                          <Text
                            fontSize="0.75rem"
                            fontWeight="600"
                            color="#6A707D"
                          >
                            compared to last month
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </NavLink>
                  <Box>
                    {/* <Image src="/assets/imgs/green-chart-home.png" /> */}
                    {data && data.resulttotalrevenueforcharts.length > 0 ? (
                      <TrendChart data={data.resulttotalrevenueforcharts} color={data && data.totalrevenuelastmonthcomparison < 0 ? '#F04438' : '#14C585'} />
                    ) : (
                      <Text></Text>
                    )}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  w="100%"
                  gap="0.8rem"
                  justifyContent="space-between"
                  alignItems="center"
                  pb="0.5rem"
                  borderBottom="1px solid #232325"
                >
                  <NavLink to="/totalrevenue">
                    <Box>
                      <Box>
                        <Text
                          textTransform="uppercase"
                          fontSize="0.75rem"
                          fontWeight="600"
                          color="#6A707D"
                        >
                          Total visit hours
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize="1.75rem" fontWeight="600" color="white">
                          {data && data.resulttotalvisithours} hrs
                        </Text>
                        <Box display="flex" flexDir="column" alignItems="flex-start" gap="0.25rem">
                          <Text
                            fontSize="0.75rem"
                            borderRadius="full"
                            fontWeight="600"
                            color={data && data.totalvisithourslastmonthcomparison < 0 ? '#FA594E' : '#14C585'}
                            p="0.25rem 0.5rem"
                            bg={data && data.totalvisithourslastmonthcomparison < 0 ? '#F044383D' : '#032217'}
                          >
                            {data && data.totalvisithourslastmonthcomparison ? data.totalvisithourslastmonthcomparison + "%" : "-"}
                          </Text>
                          <Text
                            fontSize="0.75rem"
                            fontWeight="600"
                            color="#6A707D"
                          >
                            compared to last month
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </NavLink>
                  <Box>
                    {/* <Image src="/assets/imgs/green-chart-home.png" /> */}
                    {data && data.resulttotalvisithoursforcharts.length > 0 ? (
                      <TrendChart data={data && data.resulttotalvisithoursforcharts} color={data && data.totalvisithourslastmonthcomparison < 0 ? '#F04438' : '#14C585'} />
                    ) : (
                      <Text></Text>
                    )}

                  </Box>
                </Box>

                <Box
                  display="flex"
                  w="100%"
                  gap="0.8rem"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box>
                      <Text
                        textTransform="uppercase"
                        fontSize="0.75rem"
                        fontWeight="600"
                        color="#6A707D"
                      >
                        Total Visits
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="1.75rem" fontWeight="600" color="white">
                        {data && data.resulttotalfollowupvisits}
                      </Text>
                      <Box display="flex" flexDir="column" alignItems="flex-start" gap="0.25rem">
                        <Text
                          fontSize="0.75rem"
                          borderRadius="full"
                          fontWeight="600"
                          color={data && data.totalfollowupvisitslastmonthcomparison < 0 ? '#FA594E' : '#14C585'}
                          p="0.25rem 0.5rem"
                          bg={data && data.totalfollowupvisitslastmonthcomparison < 0 ? '#F044383D' : '#032217'}
                        >
                          {data && data.totalfollowupvisitslastmonthcomparison ? data.totalfollowupvisitslastmonthcomparison + "%" : "-"}
                        </Text>
                        <Text
                          fontSize="0.75rem"
                          fontWeight="600"
                          color="#6A707D"
                        >
                          compared to last month
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {/* <Image src="/assets/imgs/red-chart-home.png" /> */}
                    {data && data.resulttotalfollowupvisitsforcharts.length > 0 ? (
                      <TrendChart data={data && data.resulttotalfollowupvisitsforcharts} color={data && data.totalfollowupvisitslastmonthcomparison < 0 ? '#F04438' : '#14C585'} />
                    ) : (
                      <Text></Text>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
            {/* Charts section end */}
            <Flex flexDir='column'>
              {/* Main container start */}
              <Box w="100%" bg="#fafafa" p="1rem" pb="0">
                {/* Upcoming visits start  */}
                {data && data.resultupcomingvisits.length > 0 && (
                  <Box w="100%" overflow="visible" mb="1rem">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      w="100%"
                      mb="0.75rem"
                    >
                      <Text fontSize="1rem" fontWeight="600" color="#061027">
                        Upcoming visits
                      </Text>
                      <Box display="flex" gap="0.65rem">
                        <Box
                          as="button"
                          onClick={() => handleNavigation("prev")}
                        >
                          <Image w="6px" src="/assets/imgs/left-icon.png" />
                        </Box>
                        <Box
                          as="button"
                          onClick={() => handleNavigation("next")}
                        >
                          <Image w="6px" src="/assets/imgs/right-icon.png" />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      gap="1.25rem"
                      w="100%"
                      justifyContent="start"
                    >
                      <Swiper
                        onSwiper={handleNavigation}
                        slidesPerView={"auto"}
                        spaceBetween={10}
                        ref={swiperInstance}
                        className="upcomingVisits"
                        style={{ position: 'relative', zIndex: 1 }}
                      >
                        {data.resultupcomingvisits.map((step, index) => (
                          <SwiperSlide style={{ position: "relative", zIndex: -1 }}>
                            <Box
                              key={index}
                              bg="#ffffff"
                              border="1px solid #E6E7E9"
                              borderRadius="0.75rem"
                              p="1rem"
                              gap="0.625rem"
                              boxShadow="0px 8px 20px 0px #E6E7E966"
                              w="100%"
                            >
                              <Box
                                display="flex"
                                gap="0.5rem"
                                w="100%"
                                alignItems="center"
                              >
                                <Image
                                  borderRadius="full"
                                  w="2.75rem"
                                  h="2.75rem"
                                  objectFit='contain'
                                  src={step.patientimage ? step.imagepath + step.patientimage : "/assets/imgs/no-image.png"}
                                />
                                <Box maxW="60%">
                                  <Text
                                    fontSize="1rem"
                                    fontWeight="600"
                                    color="#061027"
                                    className="textOverflow"
                                    title={step.patientname}
                                  >
                                    {step.patientname
                                      ? step.patientname
                                      : "-"}
                                  </Text>
                                  <Box
                                    display="flex"
                                    gap="0.25rem"
                                    alignItems="center"
                                  >
                                    <Image src="/assets/svgs/home-calendar.svg" />
                                    <Text
                                      fontSize="0.75rem"
                                      fontWeight="500"
                                      color="#384052"
                                      className="textOverflow"
                                      title={formatDate(step.appointmentdate)}
                                    >
                                      {formatDate(step.appointmentdate)}
                                    </Text>
                                  </Box>
                                </Box>
                                <Spacer />
                                <Menu pos='relative'>
                                      <MenuButton as='button'>
                                        <Image src="/assets/svgs/more-options.svg" />
                                      </MenuButton>
                                      <MenuList zIndex={9999} pos="absolute" right="-20px" minW="max-content">
                                        <MenuItem
                                          isDisabled={actionAccessVisits && actionAccessVisits.read ? false : true}
                                          onClick={() => navigate(`/visitdetails?patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}>View
                                          </MenuItem>
                                         {step.appointmentstatus === "pause" ? (

                                           <MenuItem
                                           isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                          //  onClick={() => navigate(`/startvisit?doctorId=${step.doctorid}&patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}
                                           onClick={() => handleStartVisit(step.doctorid, step.patientid, step.appointmentid, step.appointmentstatus)}
                                           >
                                            Resume Visit
                                           </MenuItem>
                                         ) : (
                                          <>
                                           <MenuItem
                                           isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                           onClick={() => handleStartVisit(step.doctorid, step.patientid, step.appointmentid, step.appointmentstatus)}
                                           >
                                            Start Visit
                                           </MenuItem>
                                           <MenuItem
                                              onClick={()=> handleAdvancedDetails(step.patientid, step.appointmentid, step.doctorid)}
                                              isDisabled={actionAccessScreening && (actionAccessScreening.create) ? false : true}
                                            >
                                              Advanced details
                                            </MenuItem>
                                            {/* <MenuItem
                                              onClick={() => handleReAssign(step)}
                                              isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                            >
                                              Re-Assign Appointment
                                            </MenuItem> */}
                                          </>
                                         )
                                          }
                                         
                                        {/* <MenuItem>Create a Copy</MenuItem>
                                      <MenuItem>Mark as Draft</MenuItem> */}
                                      </MenuList>
                                    </Menu>
                              </Box>
                              <Box mt="0.75rem" w="90%">
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="500"
                                  color="#384052"
                                  className="textOverflow"
                                >
                                  {step.chiefcomplaint
                                    ? step.chiefcomplaint
                                    : "-"}
                                </Text>
                              </Box>
                              {/* {step.appointmentstatus === "start" &&
                                  <Box p="1rem" borderTop='1px solid #E6E7E9'>
                                    <PrimaryButton  buttonText="Start visit" variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                      onClick={() => navigate(`/startvisit?doctorId=${step.doctorid}&patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}
                                      isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                    />
                                  </Box>
                                  } */}
                              <Box>
                                <PrimaryButton  buttonText={step.appointmentstatus ==='pause' ? 'Resume visit' : "Start visit"} variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                // <PrimaryButton  buttonText="Start visit" variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                  // onClick={() => navigate(`/startvisit?doctorId=${step.doctorid}&patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}
                                  onClick={() => handleStartVisit(step.doctorid, step.patientid, step.appointmentid, step.appointmentstatus)}
                                  isDisabled={actionAccessAppointments && actionAccessAppointments.create ? false : true}
                                />
                              </Box>
                            </Box>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Box>
                  </Box>
                )}
                {/* Upcoming visits end  */}
                {/* pending visits start */}
                {data && data.resultpendingvisits.length > 0 && (
                  <Box w="100%" overflow="visible">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      w="100%"
                      mb="0.75rem"
                    >
                      <Text fontSize="1rem" fontWeight="600" color="#061027">
                        Pending visits
                      </Text>
                      <Box display="flex" gap="0.65rem">
                        <Box
                          as="button"
                          onClick={() => handleUpcoming("prev")}
                        >
                          <Image w="6px" src="/assets/imgs/left-icon.png" />
                        </Box>
                        <Box
                          as="button"
                          onClick={() => handleUpcoming("next")}
                        >
                          <Image w="6px" src="/assets/imgs/right-icon.png" />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      display="flex"
                      gap="1rem"
                      w="100%"
                      justifyContent="start"
                    >
                      <Swiper
                        onSwiper={handleUpcoming}
                        slidesPerView={1}
                        ref={swiperUpcoming}
                        className="pendingVisits"
                        style={{ position: 'relative', zIndex: 1 }}
                      >
                        {data.resultpendingvisits.map((step, index) => (
                          <SwiperSlide style={{ position: "relative", zIndex: -1 }}>
                            <Box
                              key={index}
                              bg="#ffffff"
                              border="1px solid #E6E7E9"
                              borderRadius="0.75rem"
                              gap="0.625rem"
                              boxShadow="0px 8px 20px 0px #E6E7E966"
                              w="100%"
                            >
                              <Box
                                display="flex"
                                gap="0.5rem"
                                w="100%"
                                alignItems="center"
                                p="1rem"
                                pb="0"
                              >
                                <Image
                                  borderRadius="full"
                                  w="2.75rem"
                                  h="2.75rem"
                                  objectFit='contain'
                                  src={step.patientimage ? step.imagepath + step.patientimage : "/assets/imgs/no-image.png"}
                                />
                                <Box>
                                  <Text
                                    fontSize="1rem"
                                    fontWeight="600"
                                    color="#061027"
                                    className="textOverflow"
                                  >
                                    {step.patientname
                                      ? step.patientname
                                      : "-"}
                                  </Text>
                                  <Box
                                    display="flex"
                                    gap="0.25rem"
                                    alignItems="center"
                                  >
                                    <Image src="/assets/svgs/home-calendar.svg" />
                                    <Text
                                      fontSize="0.75rem"
                                      fontWeight="500"
                                      color="#384052"
                                      className="textOverflow"
                                    >
                                      {/* {formatDate(step.appointmentdate)}  */}
                                      {`${formatDate(step.appointmentdate)} at ${step.appointmenttime}`}
                                    </Text>
                                  </Box>
                                </Box>
                                <Spacer />
                                <Menu>
                                  <MenuButton as='button'>
                                    <Image src="/assets/svgs/more-options.svg" />
                                  </MenuButton>
                                  <MenuList zIndex={9999} pos="absolute" right="-33px" minW="125px">
                                    <MenuItem
                                      isDisabled={actionAccessVisits && actionAccessVisits.read ? false : true}
                                      onClick={() => navigate(`/visitdetails?patientId=${step.patientid}&appointmentId=${step.appointmentid}`)}>View</MenuItem>
                                    {/* <MenuItem>Create a Copy</MenuItem>
                                <MenuItem>Mark as Draft</MenuItem> */}
                                  </MenuList>
                                </Menu>
                              </Box>
                              <Box mt="0.75rem" w="100%" p="1rem" pt="0">
                                <Text
                                  fontSize="0.875rem"
                                  fontWeight="500"
                                  color="#384052"
                                  className="textOverflow"
                                >
                                  {step.chiefcomplaint
                                    ? step.chiefcomplaint
                                    : "-"}
                                </Text>
                              </Box>
                              <Box p="1rem" borderTop='1px solid #E6E7E9'>
                                <PrimaryButton onClick={() => acceptvisit(step)} buttonText="Accept visit" variant="grayBtn" btnStyle={{ w: "100%", color: "#061027" }}
                                  isDisabled={actionAccessVisits && (actionAccessVisits.create) ? false : true}
                                />
                              </Box>
                            </Box>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Box>
                  </Box>
                )}
                {/* pending visits end  */}
                {/* your patients start */}
                {data && data.resultmypatients.length > 0 ? (
                  <Box my="1rem">
                    <Text
                      fontSize="1rem"
                      mb="0.75rem"
                      fontWeight="600"
                      color="#061027"
                    >
                      Your patients
                    </Text>

                    <Box>
                      <SimpleTable
                        headers={TableHeader}
                        isDataPresent={true}
                        tableBody={
                          <>
                            {data &&
                              data.resultmypatients.map((step, index) => (
                                <Tr w="100%" key={index}>
                                  <Td w="100x">
                                    <Box w="100%" display="flex" gap="0.5rem" alignItems="center"
                                    >                                     <Box boxSize="2.75rem"><Image boxSize="40px" h="40px" objectFit="cover" src={step.patientimage ? step.imagepath + step.patientimage : "/assets/imgs/no-image.png"} borderRadius="full" /></Box>
                                      {/* <Image
                                    w="2.5rem"
                                    h="2.5rem"
                                    borderRadius="full"
                                    src={step.patientimage? step.imagepath+step.patientimage:"/assets/imgs/doctor-image.png"}
                                  /> */}
                                      <Box w="80%" maxW="8rem" isTruncated>
                                        <Text
                                          className="textOverflow"
                                          fontSize=".875rem"
                                          fontWeight="600"
                                          title={step.patientname}
                                        >
                                          {step.patientname
                                            ? step.patientname
                                            : "-"}
                                        </Text>
                                        {/* <Text
                                      fontSize=".75rem"
                                      fontWeight="400"
                                      color="#384052"
                                    >
                                      NUAK723819313
                                    </Text> */}
                                      </Box>
                                    </Box>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                      {formatDate(step.appointmentdate)}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Box
                                      p=".25rem .5rem"
                                      w="3rem"
                                      bg="#D0F3E7"
                                      borderRadius="full"
                                    >
                                      <Text
                                        fontSize=".75rem"
                                        fontWeight="600"
                                        color="#109E6A"
                                      >
                                        {step.repeatrate ? "+" + step.repeatrate + "%" : "-"}
                                      </Text>
                                    </Box>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                      {step.totalrevenue ? "$" + formatToDecimal(step.totalrevenue) : "-"}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                      {step.totalvisits ? step.totalvisits : "-"}
                                    </Text>
                                  </Td>

                                  <Td pos='relative' px='1rem' textAlign='center'>
                                    <Menu>
                                      <MenuButton as='button' zIndex={1}>
                                        <Image w="1.5rem" src="/assets/svgs/more-options.svg" />
                                      </MenuButton>
                                      <MenuList zIndex={9999} pos="relative" right="0" minW="125px">
                                        <MenuItem onClick={() => handleClick(step.appointmentid, step.patientid)}>
                                            View Profile
                                        </MenuItem>
                                        <MenuItem onClick={() => openScheduleModal(step.patientid, step.appointmentid)}
                                          isDisabled={
                                            actionAccessVisits && !actionAccessVisits.create ? true : false
                                          }>
                                          Schedule Visit
                                        </MenuItem>
                                        {(step.appointmentstatus == 0 || step.appointmentstatus == 1) && (
                                          <MenuItem onClick={() => openRescheduleModal(step.patientid, step.appointmentid)}
                                            isDisabled={
                                              actionAccessVisits && !actionAccessVisits.update ? true : false
                                            }
                                          >
                                            Reschedule Visit
                                          </MenuItem>
                                        )}
                                        {(step.appointmentstatus == 0 || step.appointmentstatus == 1) && (
                                          <MenuItem
                                            // onClick={cancelVisit.current.openModal}
                                            onClick={() => openCancelModal(step.fullname, step.appointmentdate, step.appointmenttime, step.patientid, step.appointmentid)}
                                            isDisabled={
                                              actionAccessVisits && !actionAccessVisits.delete ? true : false
                                            } >
                                            Cancel Visit
                                          </MenuItem>
                                        )}
                                      </MenuList>
                                    </Menu>
                                  </Td>
                                </Tr>
                              ))}
                          </>
                        }
                        footerSection={
                          <>
                            <Tr>
                              <Td colSpan={6}>
                                <Box
                                  w="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {/* {currentPage > 1 &&( */}
                                  <Box as="button" onClick={handlePreviousClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                    {/* <Image
                                    w="0.5rem"
                                    h="auto"
                                    src="/assets/imgs/left-icon.png"
                                    onClick={handlePreviousClick}
                                  /> */}
                                    Previous
                                  </Box>
                                  {/* )}   */}
                                  <Box>
                                    <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                      Page {currentPage} of {totalPages}
                                    </Text>
                                  </Box>
                                  {/* {currentPage < totalPages &&( */}
                                  <Box as="button" p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick} disabled={currentPage === totalPages} visibility={currentPage === totalPages ? 'hidden' : 'visible'} fontSize="1rem" fontWeight="500">
                                    {/* <Image
                                    w="0.5rem"
                                    h="auto"
                                    src="/assets/imgs/right-icon.png"
                                    onClick={handleNextClick}
                                  /> */}
                                    Next
                                  </Box>
                                  {/* )}                                     */}
                                  {/* <Box
                                display="flex"
                                gap="0.65rem"
                                alignItems="center"
                              >
                                <Box>
                                  <Image
                                    w="0.5rem"
                                    h="auto"
                                    src="/assets/imgs/left-icon.png"
                                  />
                                </Box>
                                <Box
                                  as="button"
                                  boxSize="2rem"
                                  border="1px solid #E2E4E9"
                                  borderRadius="0.37rem"
                                  fontSize="0.87rem"
                                  fontWeight="500"
                                >
                                  1
                                </Box>
                                <Box
                                  as="button"
                                  boxSize="2rem"
                                  border="1px solid #E2E4E9"
                                  borderRadius="0.37rem"
                                  fontSize="0.87rem"
                                  fontWeight="500"
                                >
                                  2
                                </Box>
                                <Box
                                  as="button"
                                  boxSize="2rem"
                                  border="1px solid #E2E4E9"
                                  borderRadius="0.37rem"
                                  fontSize="0.87rem"
                                  fontWeight="500"
                                >
                                  3
                                </Box>
                                <Box
                                  as="button"
                                  boxSize="2rem"
                                  border="1px solid #E2E4E9"
                                  borderRadius="0.37rem"
                                  fontSize="0.87rem"
                                  fontWeight="500"
                                >
                                  ...
                                </Box>
                                <Box
                                  as="button"
                                  boxSize="2rem"
                                  border="1px solid #E2E4E9"
                                  borderRadius="0.37rem"
                                  fontSize="0.87rem"
                                  fontWeight="500"
                                >
                                  9
                                </Box>
                                <Box>
                                  <Image
                                    w="0.5rem"
                                    h="auto"
                                    src="/assets/imgs/right-icon.png"
                                  />
                                </Box>
                              </Box> */}
                                </Box>
                              </Td>
                            </Tr>
                          </>
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  <SimpleTable
                    headers={TableHeader}
                    footerVisible={false}
                    isDataPresent={false}
                  />
                )}
                {/* your patients end */}

                {/* Get insight from Ai start */}
                {/* <Box w="100%" py="0.625rem" mb="1.25rem">
              <Box
                display="flex"
                mb="0.625rem"
                justifyContent="space-between"
              >
                <Text fontSize="1rem" fontWeight="600" color="#061027">
                  Get insights on your performance with AI
                </Text>
                <Box display="flex" gap="1.25rem">
                  <Box
                    as="button"
                    onClick={() => handleNavigation("prev")}
                  >
                    <Image
                      w="0.375rem"
                      src="/assets/imgs/left-icon.png"
                    />
                  </Box>
                  <Box
                    as="button"
                    onClick={() => handleNavigation("next")}
                  >
                    <Image
                      w="0.375rem"
                      src="/assets/imgs/right-icon.png"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                border="0.0625rem solid #E6E7E9"
                borderRadius="0.75rem"
                p="1.25rem"
                overflowX="hidden"
                bg="white"
              >
                <Flex gap="0.625rem">
                  <Swiper
                    onSwiper={handleNavigation}
                    slidesPerView={2}
                    ref={swiperInstance}
                  >
                    <SwiperSlide>
                      <Box
                        as="button"
                        bg="white"
                        p="0.5rem 0.625rem"
                        display="flex"
                        alignItems="center"
                        w="max-content"
                        border="0.0625rem solid #E6E7E9"
                        borderRadius="full"
                        boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                        onClick={() => AIChatModel.current.openRight()}
                      >
                        <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                        <Text
                          fontSize="0.75rem"
                          fontWeight="600"
                          color="#1C4EC5"
                        >
                          How many visits did i complete last year?
                        </Text>
                      </Box>
                    </SwiperSlide>
                  </Swiper>
                </Flex>
              </Box>
            </Box> */}
                {/* Get insight from Ai end */}

                {/* Recent Activity start */}
                <Box w="100%" bg="white">
                <Box w="100%">
                      {/* Title */}
                      <Box
                        as="h2"
                        p="1.5rem"
                        py="1rem"
                        fontSize="1.25rem"
                        fontWeight="600"
                        borderBottom="1px solid #E6E7E9"
                      >
                        Quick Links
                      </Box>

                      {/* Links Container */}
                      <Box px="1.5rem" py="1rem">
                        {/* Link 1 */}
                        {IsGlobalAdmin == 1 &&
                          <Box mb="1rem">
                            <a
                              href="/branches"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                fontSize: "1rem",
                                position: "relative",
                                display: "inline-block",
                                cursor: "pointer",
                                lineHeight: "1.5", // Aligns the link properly
                              }}
                              onClick={(e) => {
                                if (actionAccessVisits && !actionAccessVisits.create) {
                                  e.preventDefault(); // Prevent navigation if disabled
                                }
                              }}
                            >
                              Networked Care
                              <Box
                                as="span"
                                position="absolute"
                                left="0"
                                bottom="0"
                                height="1px"
                                width="100%"
                                bg="black"
                              />
                            </a>
                          </Box>
                        }


                        {/* Link 2 */}
                        {actionAccessVisits && actionAccessVisits.create &&
                          <Box>
                            <a
                              href="#"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                fontSize: "1rem",
                                position: "relative",
                                display: "inline-block",
                                cursor: "pointer",
                                lineHeight: "1.5", // Aligns the link properly
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default navigation
                                if (actionAccessVisits && actionAccessVisits.create) {
                                  openNewVisitForm(); // Call the function if allowed
                                }
                              }}
                            >
                              New visit
                              <Box
                                as="span"
                                position="absolute"
                                left="0"
                                bottom="0"
                                height="1px"
                                width="100%"
                                bg="black"
                              />
                            </a>
                          </Box>
                        }
                      </Box>
                    {/* </Box> */}
                  {/* <Box w="100%" mb="1.25rem">
                    <Box p='1.5rem' pb='0' mx='auto'>
                      <PrimaryButton
                        buttonText="New visit"
                        variant='mdBtnFull'
                        iconSrc="/assets/imgs/add.png"
                        btnStyle={{
                          h: "2.5rem",
                          w: "100%",
                          p: "0",
                          bg: COLORS.PRIMARY_GRADIENT,
                          color: COLORS.PRIMARY_WHITE,
                          _hover: { bg: COLORS.PRIMARY_COLOR },
                        }}
                        onClick={openNewVisitForm}
                        isDisabled={actionAccessVisits && (actionAccessVisits.create) ? false : true}
                      />
                    </Box> */}
                    <Box
                      as="h2"
                      p="1.5rem"
                      ppl="1.875rem"
                      fontSize="1.25rem"
                      fontWeight="600"
                      borderBottom="1px solid #E6E7E9"
                    >
                      Activity
                    </Box>
                    <VStack p="1.5rem" align="stretch" overflow="hidden auto" h="calc(100vh - 330px)">
                      <Stack direction="column" align="center" gap="0" w="100%">
                        {recentactivity && recentactivity.length > 0 ? (
                          recentactivity.map((step, index) => (
                            <Box
                              key={index}
                              display="flex"
                              flexDir="column"
                              alignItems="center"
                              h="auto"
                              w="100%"
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                w="100%"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  w="100%"
                                >
                                  <Circle
                                    size="1rem"
                                    bg="white"
                                    border="2px solid #CDCFD4"
                                    borderRadius="full"
                                    color="black"
                                  />
                                  <Text
                                    fontSize="0.9rem"
                                    pl="0.75rem"
                                    fontWeight="500"
                                  >
                                    {step.description ? step.description : "-"}
                                  </Text>
                                </Box>
                              </Box>
                              <Box display="flex" w="100%">
                                <Center
                                  h="3.125rem"
                                  ml="0.45rem"
                                  alignItems="start"
                                  borderLeft="2px solid #E6E7E9"
                                >
                                  <Text
                                    fontSize="0.75rem"
                                    pl="1.35rem"
                                    fontWeight="500"
                                  >
                                    {formatDate(step.date)}
                                  </Text>
                                </Center>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            h="100px"
                            w="100%"
                          >
                            <Text>No records.</Text>
                          </Box>
                        )}


                      </Stack>
                    </VStack>
                  </Box>
                </Box>
                {/* Recent Activity end */}

                {/* Share badge start */}
                <Box
                  w="100%"
                  p="1.25rem"
                  borderRadius="0.75rem"
                  bg={`url('/assets/imgs/gradient-circle.png'), ${COLORS.SHARE_BADGE_GRADIENT}`}
                  bgPos="right"
                  bgRepeat="no-repeat"
                  bgSize="cover"
                >
                  <Flex flexDir="column" gap="0.5rem">
                    <Box>
                      <Text
                        fontSize="1.25rem"
                        fontWeight="600"
                        color="white"
                      >
                        Boost your visibility
                      </Text>
                      <Text fontSize=".87rem" fontWeight="500" color="white">
                        Share Your Expertise, Expand Your Reach, and Attract More Patients.
                      </Text>
                    </Box>

                    <Spacer />

                    <Box
                      onClick={openShareBadgeModal}
                      as="button"
                      p="0.75rem 1.25rem"
                      border="1px solid #E6E7E9"
                      borderRadius=".625rem"
                      bg="white"
                      boxShadow="0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                      disabled={actionAccess && (!actionAccess.create || !actionAccess.update) ? true : false}
                    >
                      <Text fontSize=".87rem" fontWeight="600" opacity={actionAccess && (!actionAccess.create || !actionAccess.update) ? "0.25" : ""}
                      >
                        Share badge
                      </Text>
                    </Box>
                  </Flex>
                </Box>
                {/* Share badge end */}

                {/* footer section start */}
                <Box
                  display="flex"
                  my="2rem"
                  alignItems="center"
                  w="100%"
                  justifyContent="center"
                  gap="10px"
                >
                  <Text fontSize="0.625rem" fontWeight="400">
                    A PRODUCT OF
                  </Text>
                  <Image src="/assets/svgs/tci-logo.svg" />
                </Box>
                {/* footer section end */}
              </Box>
              {/* Main container end */}


            </Flex>
          </Box>
        </Box>
      )}
      {/* below is original designed Share badge of home page */}
      {/* <DynamicModal
        ref={shareBadge}
        modalHeader="Share badge"
        modalBody={
          <>
            <Box>
              <Box
                mb="2rem"
                bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                boxShadow="0px 8px 24px 0px #B4B4B452"
                borderRadius="0.75rem"
                bgPos="inherit"
                bgRepeat="no-repeat"
                bgSize="cover"
              >
                <Box
                  borderRadius="0.75rem"
                  bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(251, 251, 251, 0.9976365546218487) 75%)"
                >
                  <Box display="flex" justifyContent="end" w="100%" p="1rem">
                    <Image src="/assets/svgs/midoc-logo-subtitle.svg" />
                  </Box>

                  <Box
                    display="flex"
                    p="1rem"
                    flexDir="column"
                    alignItems="start"
                    w="100%"
                  >
                    <Image
                      w="4.6rem"
                      h="auto"
                      borderRadius="full"
                      bg="#D2DCF3"
                      src="/assets/imgs/no-image.png"
                    />
                    <Text
                      fontSize="1.25rem"
                      display="inline-block"
                      fontWeight="600"
                      color="black"
                    >
                      {username}
                    </Text>
                    <Text fontSize="0.875rem" fontWeight="500" color="black">
                      Schedule a consultation with me on midoc.
                    </Text>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" gap="0.75rem">
                <PrimaryButton
                  variant="grayBtn"
                  buttonText={<Image src="/assets/svgs/fb.svg" />}
                />
                <PrimaryButton
                  variant="grayBtn"
                  buttonText={<Image src="/assets/svgs/instagram.svg" />}
                />
                <PrimaryButton
                  variant="grayBtn"
                  buttonText={<Image src="/assets/svgs/linkedin.svg" />}
                />
                <PrimaryButton
                  variant="grayBtn"
                  buttonText={<Image src="/assets/svgs/twitter.svg" />}
                />
                <PrimaryButton
                  variant="grayBtn"
                  buttonText={<Image src="/assets/svgs/whatsapp.svg" />}
                />
              </Box>

              <Box
                border="1px solid #E6E7E9"
                bg="white"
                mt="1rem"
                borderRadius="10px"
                w="100%"
                h="48px"
                px="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  isTruncated
                  maxWidth="calc(100% - 50px)"
                  title="midoc.co/AbraarParker17364"
                >
                  midoc.co/AbraarParker17364
                </Text>
                <Box as="button" pr="5px">
                  <Image src="/assets/svgs/content_copy.svg" />
                </Box>
              </Box>
            </Box>
          </>
        }
        hasFooter={false}
      /> */}

      {/* Share badge start */}
      <DynamicModal
        ref={shareBadge}
        modalWidth={{ base: "100%", lg: "55rem" }}
        // modalHeight="calc(100% - 10%)"
        modalHeader="Share badge"
        modalBody={
          <>
            <Flex w="100%" gap="1rem" flexWrap={{ base: "wrap", lg: "nowrap" }} h="100%">
              {/* Left area start */}
              <Box w={{ base: "100%", lg: "50%" }} h="100%">
                <Swiper
                  onSwiper={handleDoc}
                  slidesPerView={1}
                  ref={swiperDocCard}
                  style={{ height: '80%' }}
                >
                  {/* Doctor profile start */}
                  <SwiperSlide>

                    <Box

                      ref={badgeBox1}
                      // mb="2rem"
                      bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                      boxShadow="0px 8px 24px 0px #B4B4B452"
                      borderRadius="0.75rem"
                      bgPos="inherit"
                      bgRepeat="no-repeat"
                      bgSize="cover"
                      id="badge1"
                    >

                      <Box
                        bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                        boxShadow="0px 8px 24px 0px #B4B4B452"
                        borderRadius="0.75rem"
                        bgPos="inherit"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        h="80%"
                        display="flex"
                      // flexDirection="column"
                      ><Box display="flex" justifyContent="space-between" w="100%" bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.9976365546218487) 75%)">
                          <Box w='75%'>
                            <Box
                              // bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.9976365546218487) 75%)"
                              flex="1"
                              // p="0.5rem"
                              p="1.5rem 1rem"
                            >

                              <Image
                                w="4.6rem"
                                h="4.6rem"
                                borderRadius="full"
                                bg="#D2DCF3"
                                src={personalData.profilepictureurl ? personalData.imagepath + personalData.profilepictureurl : "/assets/imgs/no-image.png"}

                              />
                              <Text
                                fontSize="1.25rem"
                                display="inline-block"
                                fontWeight="600"
                                color="black"
                              >
                                {personalData.firstname ? personalData.firstname : " "}
                                {personalData.lastname ? personalData.lastname : ""}
                              </Text>
                              <Text fontSize="0.875rem" fontWeight="500" color="black">
                                {/* MBBS, MD (PGI) | Obstetrics & Gynaecology */}

                                {personalData.qualification ? personalData.qualification + " | " : ""}
                                {personalData.specialistname ? personalData.specialistname : ""}
                              </Text>
                            </Box>
                          </Box>
                          <Box>
                            <Box pt="1rem" pr="1rem">
                              <Image src="/assets/svgs/midoc-logo-subtitle.svg" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDir="column" gap="0.75rem" p="1.5rem 1rem" bg="white" borderTop="1px solid #E6E7E9">
                        <Box display="flex" gap="0.5rem" justifyContent="flex-start" alignItems="center">
                          <Box w="1.25rem" h="auto">
                            <Image src="/assets/imgs/call.png" />
                          </Box>
                          <Text fontSize="0.875rem" fontWeight="500" color="#061027">
                            {personalData.countrycode ? personalData.countrycode : ""} {personalData.mobileno ? personalData.mobileno : "-"}</Text>

                        </Box>
                        <Box
                          display="flex"
                          flexDir="column"
                          gap="0.75rem"
                          // p="1.5rem 1rem"
                          bg="white"
                          // borderTop="1px solid #E6E7E9"
                          flex="1"
                        >
                          <Box display="flex" gap="0.5rem" justifyContent="flex-start" alignItems="center">
                            <Box w="1.25rem" h="auto">
                              <Image src="/assets/imgs/location_on.png" />
                            </Box>
                            <Text fontSize="0.875rem" fontWeight="500" color="#061027">
                              {personalData.hospitalname ? personalData.hospitalname : "-"}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex" gap="0.5rem" justifyContent="flex-start" alignItems="start">
                          <Box w="1.25rem" h="auto">
                            <Image src="/assets/imgs/timing.png" />
                          </Box>
                          <Box display="flex" flexDir="column" >
                            {/* <Text fontSize="0.875rem" fontWeight="500" color="#061027">Mon, Tue, Wed  (7:00 AM - 8:00 PM)</Text>
                        <Text fontSize="0.875rem" fontWeight="500" color="#061027">Thu, Fri  (7:30 AM - 1:30 PM)</Text> */}
                            {/* {videoVisits.map((item, index)=>(
    <Text key={index} fontSize="0.875rem" fontWeight="500" color="#061027">{item.day}  ({item.timings[0].fromtime} - {item.timings[0].totime})</Text>
  ))} */}
                            {groupedVisits.length > 0 ? (groupedVisits.map((group, index) => (
                              <Text key={index} fontSize="0.875rem" fontWeight="500" color="#061027">
                                {group.days.join(', ')} ({group.time.fromtime} - {group.time.totime})
                              </Text>
                            ))) : (
                              <Text fontSize="0.875rem" fontWeight="500" color="#061027">
                                Not Available
                              </Text>
                            )}


                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDir="column" mt="auto" gap="0.75rem" p="1rem 1rem" bg="white" flex="1">
                        {/* <Box>
                        <Image src="/assets/svgs/quote.svg" />
                      </Box> */}
                        <Box>
                          {/* <Box as="p" fontSize="1rem" fontWeight="500">
                          {badgeText}
                        </Box> */}
                          <Text>
                            {lines.map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </Text>
                        </Box>
                      </Box>
                      <Box as="p" bg="#121316" fontSize="0.875rem" fontWeight="600" textAlign="center" p="0.75rem 0.625rem" color='white' borderRadius="0 0 0.75rem 0.75rem">
                        Schedule a visit with me on midoc!
                      </Box>
                    </Box>
                  </SwiperSlide>
                  {/* Doctor profile end */}

                  {/* Quote card start */}
                  {/* <SwiperSlide>

                <Box
                  // mb="2rem"
                  bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                  boxShadow="0px 8px 24px 0px #B4B4B452"
                  borderRadius="0.75rem"
                  bgPos="inherit"
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  ref={badgeBox2}
                >

                  <Box
                    bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                    boxShadow="0px 8px 24px 0px #B4B4B452"
                    borderRadius="0.75rem"
                    bgPos="inherit"
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    h="80%"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.9976365546218487) 75%)"
                      flex="1" minH="200px"></Box>
                    <Box display="flex" flexDir="column" mt="auto" gap="0.75rem" p="1rem 1rem" bg="white" flex="1">
                      <Box>
                        <Image src="/assets/svgs/quote.svg" />
                      </Box>
                      <Box>
                        <Box as="p" fontSize="1rem" fontWeight="500">
                          Did you know? By the third trimester, a baby can recognize their mother’s voice and even react to sounds like music or laughter!
                        </Box>
                      </Box>
                    </Box>
                    <Box as="p" bg="#121316" fontSize="0.875rem" fontWeight="600" textAlign="center" p="0.75rem 0.625rem" color='white' borderRadius="0 0 0.75rem 0.75rem">
                      Schedule a visit with me on midoc!
                    </Box>
                  </Box>
                </Box>
                </SwiperSlide> */}
                  {/* Quote card end */}
                </Swiper>
                {/* <Box w="100%">
                <Box display="flex" w="30%" mx="auto" my="0.75rem" justifyContent="space-between" alignItems="center">
                  <Box
                    as="button"
                    onClick={() => handleDoc("prev")}
                  >
                    <Image w="6px" src="/assets/imgs/left-icon.png" />
                  </Box>
                  <Text fontSize="0.75rem" fontWeight="500" color="#384052">Page {page} of 2</Text>
                  <Box
                    as="button"
                    onClick={() => handleDoc("next")}
                  >
                    <Image w="6px" src="/assets/imgs/right-icon.png" />
                  </Box>
                </Box>
              </Box> */}
              </Box>
              {/* Left area end */}

              {/* Right area start */}
              <Box w={{ base: "100%", lg: "50%" }} display='flex' flexDir='column' justifyContent='space-between'>
                <Box w="100%">
                  <Box p="1.5rem" minH="280px" bg="#FCFCFD" border="1px solid #E6E7E9" borderRadius="1rem 1rem 0 0">
                    <Textarea
                      border="none"
                      resize="none"
                      p="0.5rem"
                      pl="0"
                      minH="0"
                      borderRadius="1rem 1rem 0 0"
                      sx={{
                        _hover: { outline: "none !important", border: "none !important", borderColor: "transparent !important" },
                        _focus: { outline: "none !important", border: "none !important", boxShadow: "none !important", borderColor: "transparent !important" },
                        _active: { outline: "none !important", border: "none !important", borderColor: "transparent !important" },
                      }}
                      maxH="300px"
                      h={textareaHeight}
                      _placeholder={{ textAlign: 'center', fontSize: '12px' }}
                      placeholder={placeholderText}
                      onFocus={onFocusFunc}
                      onBlur={onBlurFunc}
                      // value={badgeText}
                      onChange={(e) => setBadgeTextFunction(e)}
                    />

                    <Box display="flex">
                      <Box as="button" mt="0.5rem" mx="0.5rem" display="flex" justifyContent="center" alignItems="center" w="max-content"
                        bg="transparent" border="1px solid #E6E7E9" borderRadius="full" p="0.5rem 0.75rem" gap="0.5rem"
                        onClick={regenerateFunction}
                      >
                        <Image boxSize="0.625rem" src="/assets/svgs/generate.svg" />
                        <Text fontSize="0.75rem" fontWeight="600" color="#384052"
                        >Regenerate</Text>
                      </Box>
                      <Box as="button" mt="0.5rem" mx="0.5rem" display="flex" justifyContent="center" alignItems="center" w="max-content"
                        bg="transparent" border="1px solid #E6E7E9" borderRadius="full" p="0.5rem 0.75rem" gap="0.5rem"
                        onClick={useSameText} disabled={textAreaText ? false : true}
                      >
                        {/* <Image boxSize="0.625rem" src="/assets/svgs/generate.svg" /> */}
                        <Text fontSize="0.75rem" fontWeight="600" color={textAreaText ? "#384052" : "#c5c5c5"}
                        >Use this text</Text>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="0.5rem" mt="0.5rem">
                      {AIPromptArray.map((step, index) => (
                        <>



                          <Box
                            // key={index}
                            as="button"
                            bg="white"
                            p="8px 10px"
                            display="flex"
                            alignItems="center"
                            w="100%"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                            onClick={() => setTextFromPropmts(step.value)}
                          >
                            <Image src="/assets/imgs/spark.png" mr="8px" />
                            <Text fontSize="11px" fontWeight="600" color="#1C4EC5"
                              textAlign="left"
                            >
                              {step.value ? step.value : "-"}
                            </Text>
                          </Box>
                        </>

                      ))}
                    </Box>
                    {/* {!badgeText && 
                <Text
                position="absolute"
                left="65%"
                top="22%"
                fontSize="12px"
                color="gray.500"
                display={{base: "none", lg: "block"}}
                >
                Click anywhere to start typing.
              </Text>
              } */}
                  </Box>
                  {/* <Box
                tabIndex="-1"
                w="100%"
                h="65%"
                bg="#FCFCFD"
                border="1px solid #E6E7E9"
                borderRadius="1rem 1rem 0 0"
              >
                <Box
                  p="1.5rem"
                  bg="#FCFCFD"
                  borderRadius="1rem"
                  h="100%"
                  maxH="300px"
                  overflowY="auto"
                  userSelect="text"
                  w="100%"
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                  role="textbox"
                  contentEditable
                  spellCheck="true"
                  aria-label="Type a message"
                  tabIndex={10}
                  display="flex"
                  flexDirection="column"
                  _focus={{ boxShadow: "none", outline: "none" }}
                >
                  <Box
                    as="p"
                    m={0}
                    flex="1"
                    w="100%"
                    textIndent="0px"
                    color="#061027"
                    fontSize="0.875rem"
                    fontWeight="500"
                  >
                    <br />
                    <Box as="button" mt="0.5rem" display="flex" justifyContent="center" alignItems="center" w="max-content" bg="transparent" border="1px solid #E6E7E9" borderRadius="full" p="0.5rem 0.75rem" gap="0.5rem">
                      <Image boxSize="0.625rem" src="/assets/svgs/generate.svg" />
                      <Text fontSize="0.75rem" fontWeight="600" color="#384052">Regenerate</Text>
                    </Box>
                  </Box>
                  <Text mx="auto" w="100%" textAlign="center" fontSize="0.75rem" fontWeight="500" color="#898D97">Click on the text to edit</Text>
                </Box>
              </Box> */}
                  <Box p="1.5rem" bg="#CDCFD426" overflow='hidden' borderRadius="0 0 1rem 1rem">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      w="100%"
                      mb="0.75rem"
                    >
                      <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                        Rewrite with AI
                      </Text>
                      <Box display="flex" gap="0.65rem">
                        <Box
                          as="button"
                          onClick={() => handleAiPrompt("prev")}
                        >
                          <Image w="6px" src="/assets/imgs/left-icon.png" />
                        </Box>

                        <Box
                          as="button"
                          onClick={() => handleAiPrompt("next")}
                        >
                          <Image w="6px" src="/assets/imgs/right-icon.png" />
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" gap="0.5rem" w="fit-content" overflow="hidden">
                      <Swiper
                        onSwiper={handleAiPrompt}
                        ref={swiperAiPrompt}
                        slidesPerView={2}
                        style={{ height: '80%' }}
                        className="AiPrompt"
                      >
                        <SwiperSlide>
                          <Box
                            as="button"
                            bg="white"
                            p="0.5rem 0.625rem"
                            display="flex"
                            alignItems="center"
                            w="max-content"
                            border="0.0625rem solid #E6E7E9"
                            borderRadius="full"
                            boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                            // onClick={() => AIChatModel.current.openRight()}

                            onClick={() => rewriteWithAI("This day in medical history")}
                          >
                            <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                            <Text
                              fontSize="0.75rem"
                              fontWeight="600"
                              color="#1C4EC5"
                            >
                              This day in medical history
                            </Text>
                          </Box>
                        </SwiperSlide>

                        <SwiperSlide>
                          <Box
                            as="button"
                            bg="white"
                            p="0.5rem 0.625rem"
                            display="flex"
                            alignItems="center"
                            w="max-content"
                            border="0.0625rem solid #E6E7E9"
                            borderRadius="full"
                            boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                            // onClick={() => AIChatModel.current.openRight()}

                            onClick={() => rewriteWithAI("Medical Fun Fact")}
                          >
                            <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                            <Text
                              fontSize="0.75rem"
                              fontWeight="600"
                              color="#1C4EC5"
                            >
                              Medical Fun Fact
                            </Text>
                          </Box>
                        </SwiperSlide>

                        <SwiperSlide>
                          <Box
                            as="button"
                            bg="white"
                            p="0.5rem 0.625rem"
                            display="flex"
                            alignItems="center"
                            w="max-content"
                            border="0.0625rem solid #E6E7E9"
                            borderRadius="full"
                            boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                            // onClick={() => AIChatModel.current.openRight()}
                            onClick={() => rewriteWithAI("Daily Health tip")}
                          >
                            <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                            <Text
                              fontSize="0.75rem"
                              fontWeight="600"
                              color="#1C4EC5"
                            >
                              Daily Health tip
                            </Text>
                          </Box>
                        </SwiperSlide>

                      </Swiper>
                    </Box>
                  </Box>
                </Box>
                <Box w="100%">
                  <PrimaryButton buttonText="Download badge"
                    // onClick={()=> downlaodBadge.current.openModal()} 
                    onClick={downloadBadge}
                    mt="1rem"
                  />
                </Box>
              </Box>


              {/* Right area end */}
            </Flex>

          </>
        }
        hasFooter={false}
        modalFooterBtn={
          <>
            <Box w="45%" mx="auto">
              {/* <PrimaryButton buttonText="Share badge" 
              // onClick={()=> downlaodBadge.current.openModal()} 
               onClick={shareBadgeCall} 
              /> */}
            </Box>
            <Box w="45%" mx="auto">
              <PrimaryButton buttonText="Download badge"
                // onClick={()=> downlaodBadge.current.openModal()} 
                onClick={downloadBadge}
              />
            </Box>
          </>
        }

      />
      {/* Share badge end */}

      <AiModal
        ref={AIChatModel}
        modalSize="420px"
        iconStyle={{ w: "7rem" }}
        icon="/assets/svgs/AI-Chatbot.svg"
        headerStyle={{ p: "24px 32px" }}
        closeBtnStyle={{ top: "18px" }}
        footerStyle={{
          maxH: "35%",
          overflow: "hidden auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        withoutBg={true}
        backDropBlur="10px"
        body={
          <>
            <Flex flexDirection="column" height="100%">
              <Box
                p="24px 32px"
                // ref={chatContainerRef}
                className="chat-container"
                style={{ overflowY: "auto" }}
              >
                <Box
                  // key={index}
                  as="div"
                  bg="#061027"
                  p="12px 16px"
                  w="fit-content"
                  float="right"
                  borderRadius="10px 10px 1px 10px"
                >
                  <Text fontSize="14px" fontWeight="500" color="white">
                    How many visits did i complete last year?
                  </Text>
                </Box>

                <Box
                  as="div"
                  my="20px"
                  w="fit-content"
                  display="flex"
                  alignItems="end"
                  float="left"
                >
                  <Image
                    bg="#061027"
                    p="4px"
                    mr="8px"
                    borderRadius="6px"
                    src="/assets/imgs/ai-chat-logo.png"
                  />
                  <Text
                    bg="#E6E7E980"
                    p="12px 16px"
                    fontSize="14px"
                    fontWeight="500"
                    color="#061027"
                    borderRadius="10px 10px 10px 1px"
                  >
                    You have completed 23 visits last year and made $40,321 in
                    revenue.
                    {/* <TypingLoader /> */}
                  </Text>
                </Box>
              </Box>
              <Spacer />
              <Box display="flex" flexDir="column" gap="1rem" p="24px 32px">
                <Box
                  as="button"
                  bg="white"
                  p="0.5rem 0.75rem"
                  display="flex"
                  alignItems="center"
                  w="max-content"
                  border="0.0625rem solid #E6E7E9"
                  borderRadius="full"
                  boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                >
                  <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    color="#1C4EC5"
                  >
                    How many visits did i complete last year?
                  </Text>
                </Box>
                <Box
                  as="button"
                  bg="white"
                  p="0.5rem 0.75rem"
                  display="flex"
                  alignItems="center"
                  w="max-content"
                  border="0.0625rem solid #E6E7E9"
                  borderRadius="full"
                  boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                >
                  <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    color="#1C4EC5"
                  >
                    When is my medical license expiring?
                  </Text>
                </Box>
                <Box
                  as="button"
                  bg="white"
                  p="0.5rem 0.75rem"
                  display="flex"
                  alignItems="center"
                  w="max-content"
                  border="0.0625rem solid #E6E7E9"
                  borderRadius="full"
                  boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                >
                  <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                  <Text
                    fontSize="0.75rem"
                    fontWeight="600"
                    color="#1C4EC5"
                  >
                    Which patient has followed-up multiple times?
                  </Text>
                </Box>
              </Box>
            </Flex>
          </>
        }
        footerActions={
          <>
            <Box p="1rem 1.5rem" display="flex" w="100%" h="100%">
              <Box
                tabIndex="-1"
                w="100%"
                bg="#FCFCFD"
                border="1px solid #E6E7E9"
                borderRadius="1rem"
              >
                <Box
                  p={2}
                  bg="#FCFCFD"
                  borderRadius="1rem"
                  minHeight="3rem"
                  maxHeight="11rem"
                  overflowY="auto"
                  userSelect="text"
                  w="100%"
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                  role="textbox"
                  contentEditable
                  spellCheck="true"
                  aria-label="Type a message"
                  tabIndex={10}
                  display="flex"
                  flexDirection="column"
                  _focus={{ boxShadow: "none", outline: "none" }}
                >
                  <Box
                    as="p"
                    m={0}
                    flex="1"
                    w="88%"
                    textIndent="0px"
                    color="#061027"
                    fontSize="0.875rem"
                    fontWeight="500"
                  >
                    <br />
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  bottom="25px"
                  right="24px"
                  p="10px"
                  w="2rem"
                  h="2rem"
                  borderRadius="full"
                  bg="#0B0B0D"
                  mr="20px"
                  userSelect="none"
                  contentEditable="false"
                >
                  <Image src="/assets/svgs/ai-send-button-white.svg" />
                </Box>
              </Box>
            </Box>
          </>
        }
      />

      {/* New Visit  */}
      {/* <DynamicModal
        ref={newVisitPatientStatus}
        modalHeader="New Visit"
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
              <Box display="flex" flexDir="column">
              <DropdownInput
                 variant="fullRound"
                 dropDownPlace="Doctor Name*"
                  options={DoctorListForDropdown}
                  id="Doctor Name"
                  onClick={HandleEditDoctor}
                  //defaultOption={defaulttest}
                  menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                  inputStyleCss={{w: "100%"}}
                 />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText={"Continue"}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={handleFetchPatientDetails}
            />
          </>
        }
      />
       <DynamicModal
        ref={newVisitPatientDetails}
        modalHeader="New Visit"
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
           
              <Box display="flex" flexDir="column">
                <PrimaryInputDropdown
                  variant="bothSide"
                  inputStyle={{ borderRadius: "0 0 10px 10px" }}
                  onCountryChange={handleCountryChange} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  onChange={(e) =>
                    handleNewVisitChange(
                      "mobileno",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.mobileno}
                />
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  width={"80%"}
                  variant={"bothSide"}
                  inputPlace="Email Address"
                  inputValue={newVisitData.email}
                  onChange={(e) =>
                    handleNewVisitChange("email", e.target.value)
                  }
                />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText={"Continue"}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={handleFetchPatientStatus}
            />
          </>
        }
      /> */}
      {/* New Visit  */}
      <DynamicModal
        ref={newVisit}
        modalHeader="New Visit"
        modalWidth={{ base: "100%", md: "60%" }}
        modalBody={
          <>
            <Box>
              {!ShowNewVisit &&
                <>
                  <Text fontSize='1rem'>
                    {"Please use the search option to look up for existing patient information"}
                  </Text>
                  <Box display='flex' gap='0.75rem' my='0.5rem'>

                    <Box w='100%' position='relative'>
                      <Box w='100%'>
                        <PrimaryInput
                          inputPlace='Search by name, email, mobile'
                          variant='fullRound'
                          inputValue={SearchPatient}
                          onKeyPress={SearchPatientOnKeyPress}
                          onChange={HandleSearchPatient}
                        />
                      </Box>
                    </Box>
                    <Flex gap='0.75rem'>
                      <PrimaryButton buttonText={<>
                        {screenType === 'mobile' ? <Image boxSize="25px" filter="brightness(0) invert(1)" src="/assets/svgs/search.svg" /> : 'Fetch'}</>} btnStyle={{w:{base: '50px', md: '100%'}, px:{base: '0', md: '1rem'}}} onClick={HandleSearchPatientCLick} />
                      {showClearBtn &&
                        <PrimaryButton buttonText={screenType === 'mobile' ? 'X' : 'Clear'} variant='grayBtn' onClick={HandleClearData} btnStyle={{ w: {base: '50px', md: '50%'}, h: '48px', color: 'black',px:{base: '0', md: '1rem'}, fontSize:{base:'18px',md:'0.875rem'} }} />
                      }
                      {PatientSearchedList && PatientSearchedList.length > 0 && screenType === 'web' || screenType === 'tablet' &&
                        <PrimaryButton buttonText='Add New Patient' onClick={ShowVisitForm} btnStyle={{ minW: 'unset' }} />
                      }
                    </Flex>
                  </Box>
                  {PatientSearchedList && PatientSearchedList.length > 0 &&
                    <Box
                      border="1px solid #E6E7E9"
                      mt='1rem'
                    >
                      <SimpleTable
                        headers={TableHeaderPatients}
                        className='tableContainer'
                        isDataPresent={true}
                        tableStyle={{ maxH: "calc(100vh - 320px)", overflowY: {base: "auto", md: "hidden auto"}, tableLayout: "fixed", width: "100%" }}
                        tableBody={
                          <>
                            {PatientSearchedList &&
                              PatientSearchedList.map((step, index) => (
                                <Tr cursor='pointer' w="100%" key={index} onClick={() => handlepatientSelected(step)}>
                                  <Td w="100x">
                                    <Box w="100%" display="flex" gap="0.5rem" alignItems="center">
                                      <Box boxSize="2.75rem">
                                        <Image
                                          boxSize="40px"
                                          h="40px"
                                          objectFit="cover"
                                          // src={
                                          //   step.patientimage? step.imagepath + step.patientimage:"/assets/imgs/no-image.png"
                                          // } 
                                          src={
                                            step.patientimage ?
                                              (step.patientimage.startsWith('http') ? step.patientimage : step.imagepath + step.patientimage)
                                              : '/assets/imgs/no-image.png'
                                          }
                                          borderRadius="full"
                                        />
                                      </Box>
                                      <Box w="80%" maxW="8rem" isTruncated>
                                        <Text
                                          className="textOverflow"
                                          fontSize=".875rem"
                                          fontWeight="600"
                                          title={step.fullname}
                                        >
                                          {step.fullname
                                            ? step.fullname
                                            : "-"}
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Td>

                                  <Td w="80%" maxW="8rem">
                                    <Text title={step.email ? step.email : "-"} isTruncated fontSize=".875rem" fontWeight="500">
                                      {step.email ? step.email : "-"}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Box
                                      p=".25rem .5rem"
                                      borderRadius="full"
                                    >
                                      <Text
                                        fontSize=".75rem"
                                        fontWeight="600"
                                      //color="#109E6A"
                                      >
                                        {step.mobileno ? ((step?.countrycode ? step?.countrycode + " " : "") + step.mobileno) : ('-')}
                                      </Text>
                                    </Box>
                                  </Td>

                                  <Td maxW="8rem">
                                    <Text fontSize=".875rem" fontWeight="500" title={step.patientaddress ? step.patientaddress : ""} isTruncated>
                                      {step.patientaddress ? step.patientaddress : "-"}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Image src='/assets/imgs/right-icon.png' />
                                  </Td>


                                </Tr>
                              ))}
                          </>
                        }
                      />

                    </Box>
                  }
                  {PatientSearchedList && PatientSearchedList.length > 0 && screenType === 'mobile' &&
                    <PrimaryButton buttonText='Add New Patient' onClick={ShowVisitForm} btnStyle={{ minW: 'unset', mt:'1rem' }} />
                  }
                  {PatientSearchedList && PatientSearchedList.length === 0 && (
                    <Flex w='100%' flexDir='column' h='200px' justifyContent='center' alignItems='center' my='1rem' gap='0.5rem'>
                      <Text fontSize='1rem'>
                        {newvisitdisplaytext ? newvisitdisplaytext : "Alternatively, you can use the button below to proceed with adding new patient information."}
                      </Text>
                      <PrimaryButton buttonText='Add New Patient' onClick={ShowVisitForm} variant='mdBtn' btnStyle={{ w: 'fit-content' }} />
                    </Flex>
                  )}
                </>
              }

              {ShowNewVisit &&
                <>
                  <Box display="flex" flexDir="column">
                    <DropdownInput
                      variant="fullRound"
                      dropDownPlace="Doctor Name*"
                      options={DoctorListForDropdown}
                      id="Doctor Name"
                      onClick={HandleEditDoctor}
                      defaultOption={DefaultDoctor}
                      menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                      inputStyleCss={{ w: "100%" }}
                    />
                  </Box>

                  <Box display="flex" alignItems='center' gap='1rem' flexDir={{ base: "column", md: "row" }}>
                    <PrimaryInputDropdown
                      variant="bothSide"
                      boxStyle={{ borderRadius: '0' }}
                      inputStyle={{ borderRadius: "0" }}
                      containerStyle={{ h: '48px' }}
                      onCountryChange={handleCountryChange} // for country codes
                      useLocalStorage={false} // Ensure this is set to false to use the callback
                      defaultCountryCode={parseInt(newVisitData.countryid)}
                      onChange={(e) =>
                        handleNewVisitChange(
                          "mobileno",
                          e.target.value.replace(/\D/g, "").slice(0, 10)
                        )
                      }
                      // onKeyPress={handleMobileKeyPress}
                      inputValue={newVisitData.mobileno}
                    />
                     <Text>or</Text>
                    {screenType == 'web' ? (
                      <>
                        <PrimaryInput
                          type="text"
                          mt={"8px"}
                          // width={"80%"}
                          inputStyleCss={{ w: '100%' }}
                          variant='bothSide'
                          inputPlace="Email Address*"

                          inputValue={newVisitData.email}
                          onChange={(e) =>
                            handleNewVisitChange("email", e.target.value)
                          }
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleFetchPatientStatus("email", e.target.value);
                        //   }
                        // }}
                        />
                        {/* <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "12%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "2px",
                    borderRadius: "0"
                  }}
                  onClick={() =>
                    handleFetchPatientStatus("both")
                  }
                  isDisabled={false}
                /> */}
                      </>
                    ) : (
                      <Box display='flex' w='100%'>
                        <PrimaryInput
                          type="text"
                          mt={"8px"}
                          // width={"80%"}
                          variant='defaultSide'
                          inputPlace="Email Address"
                          inputValue={newVisitData.email}
                          onChange={(e) =>
                            handleNewVisitChange("email", e.target.value)
                          }
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleFetchPatientStatus("email", e.target.value);
                        //   }
                        // }}
                        />
                        {/* <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "12%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "2px",
                    borderRadius: "0"
                  }}
                  onClick={() =>
                    handleFetchPatientStatus("both")
                  }
                  isDisabled={false}
                /> */}
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDir={{ base: 'column', md: 'row' }}>
                    <PrimaryInput
                      type="text"
                      variant={screenType == 'web' ? 'leftDown' : 'bothSide'}
                      inputPlace="First Name*"
                      inputValue={newVisitData.firstname}
                      onChange={(e) =>
                        handleNewVisitChange("firstname", e.target.value)
                      }
                      width={"30%"}
                    />
                    <Spacer />
                    <PrimaryInput
                      type="text"
                      variant={screenType == 'web' ? 'rightDown' : 'downSide'}
                      inputPlace="Last Name"
                      inputValue={newVisitData.lastname}
                      onChange={(e) =>
                        handleNewVisitChange("lastname", e.target.value)
                      }
                    />
                  </Box>
                  <Box display="flex" alignItems='center' gap='1rem' flexDir={{ base: 'column', md: 'row' }} mt="12px">
                    <DatePicker
                      value={newVisitData.dob ? newVisitData.dob : null}
                      clearValue={true}
                      disableFutureDates={true}
                      onDateChange={(selectedDateValue) => {
                        const formattedDob = moment(selectedDateValue).format("DD-MM-YYYY");
                        const formattedAge = moment(selectedDateValue).format("YYYY-MM-DD");
                        const age = calculateAgeWithYearMonth(formattedAge);
                        handleNewVisitChange('dob', formattedDob); // Set DOB
                        handleNewVisitChange('age', age.years); // Set Age
                        handleNewVisitChange('month', age.months); // Set Age
                      }}
                      placeholderProp={"Date of Birth"}
                      calendarStlye={{ left: "-18px", right: "unset" }}
                      datePickerInputStyle={{ borderRadius: {base:'10px', md:'10px 0 0 0px'} }}
                    />
                    <Text>or</Text>
                    <Box display='flex' w='100%'>
                      {/* <PrimaryInput
                        type="number"
                        variant={"bothSide"}
                        inputPlace="Age in Years"
                        inputValue={newVisitData.age}
                        //isDisabled={true}
                        onChange={(e) => {
                          const age = parseInt(e.target.value, 10); // Convert input to number
                          handleNewVisitChange("age", age);
                          handleNewVisitChange('dob', "");
                        }}
                      /> */}
                      <PrimaryInput
                        type="number"
                        variant={screenType === 'mobile' ? "leftSide" : "bothSide"}
                        inputPlace="Age in Years"
                        inputValue={newVisitData.age || ""} // Ensure the input value doesn't show NaN
                        onChange={(e) => {
                          const value = e.target.value; // Get the input value
                          const age = value === "" ? null : parseInt(value, 10); // Handle empty string
                          handleNewVisitChange("age", age);
                          handleNewVisitChange("dob", "");
                        }}
                      />
                      <PrimaryInput
                        type="number"
                        variant='bothSide'
                        inputPlace="Age in Months"
                        inputValue={newVisitData.month || ""} // Ensure the input value doesn't show NaN
                        onChange={(e) => {
                          const value = e.target.value; // Get the input value
                          const age = value === "" ? null : parseInt(value, 10); // Handle empty string
                          handleNewVisitChange("month", age);
                          handleNewVisitChange("dob", "");
                        }}
                      />
                      <DropdownInput
                        variant={screenType == 'web' ? "rightSide" : 'rightSide'}
                        dropDownPlace="Gender"
                        defaultOption={getGender(newVisitData.gender)}
                        options={["Male", "Female", "Other"]}
                        onClick={handleGenderChange}
                        menuList={{ pos: 'relative', right: { base: 'unset', md: '80%' } }}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" flexDir="column">
                    <Textarea
                      borderRadius='0'
                      placeholder="Address"
                      defaultValue={newVisitData.address}
                      onChange={(e) =>
                        handleNewVisitChange("address", e.target.value)
                      }
                    />
                  </Box>
                  <Box display="flex" flexDir={{ base: 'column', md: 'row' }}>
                    <PrimaryInput
                      type="text"
                      variant={screenType == 'web' ? 'leftDown' : 'bothSide'}
                      inputPlace="Emergency Contact Name"
                      inputValue={newVisitData.emergencycontactname}
                      onChange={(e) =>
                        handleNewVisitChange("emergencycontactname", e.target.value)
                      }
                      width={"30%"}
                    />
                    <Spacer />
                    <PrimaryInputDropdown
                      variant="bothSide"
                      boxStyle={{ borderRadius: { base: '0 0 10px 10px', md: '0 0 10px 0' }, h: '48px' }}
                      inputStyle={{ borderRadius: "0" }}
                      containerStyle={{ borderRadius: { base: '0 0 10px 10px', md: '0 0 10px 0' }, h: '48px' }}
                      placeholderProp="Emergency mobile"
                      onCountryChange={handleCountryChangeForEmergency} // for country codes
                      useLocalStorage={false} // Ensure this is set to false to use the callback
                      defaultCountryCode={parseInt(newVisitData.emergencycontactcountryid)}
                      onChange={(e) =>
                        handleNewVisitChange(
                          "emergencycontactphone",
                          e.target.value.replace(/\D/g, "").slice(0, 10)
                        )
                      }
                      // onKeyPress={handleMobileKeyPress}
                      inputValue={newVisitData.emergencycontactphone}
                    />
                    {/* <PrimaryInput
                  type="text"
                  variant='rightDown'
                  inputPlace="Emergency Contact Phone"
                  inputValue={newVisitData.emergencycontactphone}
                  onChange={(e) =>
                    handleNewVisitChange("emergencycontactphone", e.target.value)
                  }
                /> */}
                  </Box>
                  <RadioGroup
                    defaultValue={"Schedule Now"}
                    mt={"12px"}
                    onChange={(value) =>
                      handleNewVisitChange("schedule_later", value)
                    }
                  >
                    <Stack direction="row" gap={{ base: '12px', md: "70px" }} mx={{ base: '0', md: "20%" }}>
                      <Radio colorScheme="green" size={{base:'sm', md:'md'}} value={"Schedule Now"}>Schedule Now</Radio>
                      <Spacer />
                      <Radio colorScheme="green" size={{base:'sm', md:'md'}} value={"Schedule Later"}>Schedule Later</Radio>
                    </Stack>
                  </RadioGroup>
                  {/* Space to Schedule visit */}
                  {newVisitData.schedule_later === "Schedule Later" && (
                    <Box mt={"8px"}>
                      <DatePicker
                        defaultDate={newVisitData.appointment_date}
                        minDate={newVisitData.appointment_date}
                        // disableFutureDates={false}
                        onDateChange={(selectedDateValue) =>
                          handleNewVisitChange(
                            "appointment_date",
                            selectedDateValue
                          )
                        }
                        placeholderProp={"Select Date"}
                        calendarStlye={{ left: "-18px", right: "unset" }}
                        datePickerInputStyle={{ borderRadius: '10px' }}
                      />
                      <NewSlotSelector availableSlots={availableSlots} />

                    </Box>
                  )}
                  <Box display="flex" flexDir={{ base: 'column', md: 'row' }} alignItems={{ base: 'flex-start', md: 'center' }} gap='1rem' mt="12px">
                    <InputGroup>
                      <InputLeftAddon height="48px">{currency}</InputLeftAddon>
                      <PrimaryInput
                        type="number"
                        // inputStyleCss={{w:"50%"}}
                        variant={"bothSide"}
                        inputPlace="Fees"
                        inputValue={newVisitData.fees}
                        onChange={(e) => handleNewVisitChange("fees", e.target.value)}
                      />
                    </InputGroup>

                    <RadioGroup
                      defaultValue={"1"}
                      mt={"12px"}
                      w='100%'
                      onChange={(value) => {
                        handleNewVisitChange("isfreevisit", value);

                        // If value is '1', set fees to 0
                        if (value === "1") {
                          handleNewVisitChange("fees", "0.00"); // Set fees to 0
                        } else
                          handleNewVisitChange("fees", DoctorFeesStatic);
                      }}
                    >
                      <Stack direction="row" gap="1rem" >
                        <Radio colorScheme="green" value={"0"}>Cash</Radio>
                        {/* <Spacer /> */}
                        <Radio colorScheme="green" value={"1"}>Free visit</Radio>
                      </Stack>
                    </RadioGroup>

                  </Box>
                </>
              }
            </Box>
          </>
        }

        hasFooter={ShowNewVisit ? true : false}
        modalFooterBtn={
          ShowNewVisit ? (
            <Flex gap="1rem" w="100%">
              {/* Create Visit Button */}
              <PrimaryButton
                variant="grayBtn"
                buttonText={
                  newVisitData.schedule_later === "Schedule Now"
                    ? "Create Visit"
                    : "Create Visit"
                }
                btnStyle={{
                  h: "2.5rem",
                  w: "100%",
                  p: "0",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={() => handleCreateVisitClick("false")}
                isDisabled={isButtonDisabled}
              />

              {/* Advanced Details Button */}
              <PrimaryButton
                variant="grayBtn"
                buttonText="Advanced Details"
                btnStyle={{
                  h: "2.5rem",
                  w: "100%",
                  p: "0",
                  m: "0",
                  color: "#000",
                }}
                onClick={() => handleCreateVisitClick("true")}
                //isDisabled={actionAccessScreening && actionAccessScreening.create === false}
                isDisabled={isButtonDisabled}
              />
            </Flex>
          ) : null
        }
      />

      {/* Start Visit  */}
      <DynamicModal
        ref={startVisit}
        modalHeader={appointmentStatus === "pause" ? "Resume visit" : "Start visit"}
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                {appointmentStatus === "pause"
                  ? "Are you sure you want to resume this visit now?"
                  : "Are you sure you want to start this visit now?"}
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText={
                appointmentStatus === "pause" ? "Resume visit" : "Start visit"
              }
              onClick={handleConfirmStart}
            />
          </>
        }
      />

        {/* reAssign MOdal  */}
        <DynamicModal
        ref={reAssignModal}
        modalHeader="Re-Assign Appointment"
        modalBody={
          <>
            <Box>
            <DropdownInput
                      variant="fullSide"
                      dropDownPlace="Doctor Name*"
                      options={DoctorListForDropdown}
                      id="Doctor Name"
                      onClick={HandleEditDoctor}
                      defaultOption={DefaultDoctor}
                      menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                      inputStyleCss={{ w: "100%" }}
                    />
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText="Re-Assign"
              // onClick={}
            />
          </>
        }
      />

      {/* Reschedule Visit  */}
  <DynamicModal
        ref={rescheduleVisit}
        modalHeader="Reschedule Visit"
        modalWidth="40%"
        modalBody={
          <>
            <Box>
              <Box mt={"8px"}>
                <DatePicker
                  defaultDate={moment(new Date()).format("DD-MM-YYYY")}
                  minDate={moment(new Date()).format("DD-MM-YYYY")}
                  // disableFutureDates={false}
                  onDateChange={(selectedDateValue) =>
                    setAppointmentRescheduleDate(selectedDateValue)
                  }
                  placeholderProp={"Select Date"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <SlotSelector availableSlots={availableSlots} />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Reschedule"
              // onClick={() => rescheduleVisit.current.closeModal()}
              onClick={handleRescheduleClick}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </>
        }
      />

  {/* Mark as cancel Visit  */}
  <DynamicModal
        ref={cancelVisit}
        modalHeader="Cancel visit"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to cancel your visit with{" "}
                <Box as="span" fontWeight="500">
                  {fullName}
                  {/* {patientViewData.fullname} */}
                  {/* {patientViewData.patientinfo?.firstname +
                    patientViewData.patientinfo?.lastname} */}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                  {date} at{" "}
                  {time}?
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Cancel visit"
              onClick={handleConfirmCancel}
            />
          </>
        }
      />
       {ShowAssignModal &&<AssignVisit appointmentId={AppointmentIDForAssign}/>}
    </>
  );
};

export default HomeIndex;
