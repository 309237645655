export default function formAPImypatients({
    patientname,
    totalvisits,
    appointmentdate,
    totalrevenue,
    repeatrate,
    patientimage,
    imagepath,
    patientid,
    appointmentid,
    appointmentstatus,
    appointmenttime
  }) {
   
  
    return Object.freeze({
        patientname: patientname,
        totalvisits: totalvisits,
        appointmentdate:appointmentdate,
        totalrevenue:totalrevenue,
        repeatrate:repeatrate,
        patientimage,
    imagepath,
    patientid,
    appointmentid,
    appointmentstatus,
    appointmenttime
    });
  }
  