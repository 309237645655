import React, { useState, useEffect } from "react";
import { Box, Table, Tbody, Td, Tr, Text } from "@chakra-ui/react";
import { groupBy } from "lodash";
import moment from "moment";
import { GetPatientTabularLabData } from "../../../middleware/services/visits/patient-overview";

// Sample data
const seeddata = [
  {
    documentid: 7779,
    eventtitle: "PLATELET COUNT",
    verifieddate: "2021-10-12T00:00:00",
    resultvalue: "200",
    resultuom: "cumm",
    lower_bound: null,
    upper_bound: null,
    actualresultvalue: "200",
  },
  {
    documentid: 7779,
    eventtitle: "PLATELET COUNT",
    verifieddate: "2021-10-12T00:00:00",
    resultvalue: "315",
    resultuom: "cumm",
    lower_bound: null,
    upper_bound: null,
    actualresultvalue: "200",
  },
  {
    documentid: 7779,
    eventtitle: "PLATELET COUNT",
    verifieddate: "2021-10-12T00:00:00",
    resultvalue: "3156",
    resultuom: "cumm",
    lower_bound: null,
    upper_bound: null,
    actualresultvalue: "200",
  },
  {
    documentid: 7779,
    eventtitle: "PLATELET COUNT",
    verifieddate: "2021-10-12T00:00:00",
    resultvalue: "3156",
    resultuom: "cumm",
    lower_bound: null,
    upper_bound: null,
    actualresultvalue: "200",
  },
  {
    documentid: 7779,
    eventtitle: "PLATELET COUNT",
    verifieddate: "2021-10-12T00:00:00",
    resultvalue: "3156",
    resultuom: "cumm",
    lower_bound: null,
    upper_bound: null,
    actualresultvalue: "200",
  },
  {
    documentid: 7779,
    eventtitle: "RBC",
    verifieddate: "2021-10-12T00:00:00",
    resultvalue: "4.5",
    resultuom: "mill/cumm",
    lower_bound: "4.0",
    upper_bound: "6.0",
    actualresultvalue: "4.5",
  },
  // Additional data entries here...
];

// Helper function to determine color
const getColor = (actual, lower, upper) => {
  if (lower == null || upper == null) return "black";
  return actual >= lower && actual <= upper ? "black" : "red";
};

// Main Table Component
const ResultsTable = ({
  maxHeight = "500px",
  patientId,
  doctorId,
  appointmentId,
  showLoader,
  dismissLoader,
  showErrorToast,
  showSuccessToast,
}) => {
  const [labData, setLabData] = useState([]);

  const fetchPatientLabsData = async () => {
    const inputData = {
      patientid: patientId,
      type: "lab",
    };
    showLoader();
    try {
      const data = await GetPatientTabularLabData(inputData);
      // console.log(data);
      setLabData(data);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchPatientLabsData();
  }, []);

  // Group data by eventtitle and resultuom (case insensitive)
  const groupedData = groupBy(
    labData,
    (item) => item.eventtitle?.toLowerCase() + item.resultuom?.toLowerCase()
  );

  // Find the maximum number of values for any test
  const maxValues = Math.max(
    ...Object.values(groupedData).map((entries) => entries.length)
  );

  return (
    <Box overflowX="auto" overflowY="auto" maxH={maxHeight} mt="2rem">
      <Table
        variant="simple"
        size="sm"
        border="1px"
        borderColor="gray.200"
        // minW="1000px"
        w='max-content'
      >
        <Tbody>
          {Object.keys(groupedData).map((key) => {
            const entries = groupedData[key];
            const dynamicWidth = `${100 / Math.max(entries.length, maxValues)}%`;
            return (
              <Tr key={key}>
                {/* Render event title and result unit in the first cell */}
                <Td
                  border="1px"
                  borderColor="gray.200"
                  height="6rem"
                  width="170px"
                  maxW="170px"
                >
                  <Text fontWeight="bold" textAlign="center">
                    {entries[0].eventtitle}
                  </Text>
                  <Text
                    fontSize="sm"
                    color="gray.500"
                    textAlign="center"
                    mt="4px"
                  >
                    ({entries[0].resultuom})
                  </Text>
                </Td>
                {/* Render result values and dates in each subsequent cell */}
                {entries.map((entry, idx) => (
                  <Td
                    key={idx}
                    border="1px"
                    borderColor="gray.200"
                    textAlign="center"
                    width={entry ? 'max-content' : dynamicWidth}
                    maxW="140px"
                  >
                    <Text
                      color={getColor(
                        parseFloat(entry.actualresultvalue),
                        entry.lower_bound,
                        entry.upper_bound
                      )}
                    >
                      {entry.resultvalue ? entry.resultvalue : "-"}
                    </Text>
                    <Text fontSize="sm" color="gray.500" mt="4px">
                      {moment(entry.verifieddate).format("DD MMM, YYYY")}
                    </Text>
                  </Td>
                ))}
                {/* Fill remaining cells with empty boxes if entries are fewer than maxValues */}
                {Array.from({ length: maxValues - entries.length }).map(
                  (_, idx) => (
                    <Td
                      key={`empty-${idx}`}
                      border="1px"
                      borderColor="gray.200"
                      width={dynamicWidth}
                    />
                  )
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ResultsTable;
