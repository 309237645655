import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Flex,
  Box,
  Image,
  Spacer,
  Text,
  Checkbox,
  Button,
  SimpleGrid,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/primary-button";
import PrimaryButtonIcon from "../../components/primary-button-icon";
import { COLORS } from "../styles/colors";

import DynamicModal from "../../components/dynamic-modal";
import { UpdateAppointmentStatus } from "../../middleware/services/visits/patient-overview";
import DatePicker from "../date-picker";
import { updateDoctorNotes, updateTaskComplete } from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import PrimaryInput from "../primary-input";
import EndVisitDynamicModal from "../patient-health/doctor-notes/end-visit-modal";
import moment from "moment";
import { actionAccessMain, useScreenType } from "../../auth";

const PatientVisitNav = forwardRef(
  (
    {
      patientName,
      patientImage,
      patientInfoId,
      subHeader,
      pateintId,
      doctorId,
      appointmentId,
      hasImage,
      time,
      showLoader,
      dismissLoader,
      showSuccessToast,
      showErrorToast,
      virtualVisit,
      IsAssign,
    },
    ref
  ) => {
    const [runningTime, setRunningTime] = useState("00:00:00");
    const [isRunning, setIsRunning] = useState(false);
    const timerRef = useRef(null);
    const totalSeconds = useRef(0);
    const navigate = useNavigate();
    const pauseOrEndVisit = useRef();
    const interruptVisit = useRef();

    // other options in end visit
    const options = [
      { id: 1, text: "ECG", row: 1 },
      { id: 2, text: "LABs", row: 1 },
      { id: 3, text: "IV Fluids", row: 1 },
      { id: 4, text: "Nebulizations", row: 2 },
      { id: 5, text: "MOT Procedures", row: 2 },
      { id: 6, text: "Other Minor OT", row: 2 },
      { id: 7, text: "IP Cases", row: 3 },
      { id: 8, text: "Physiotherapy Care", row: 3 },
      { id: 9, text: "Ambulance Service", row: 3 },
      { id: 10, text: "Injectibles for Seasonal Diseases", row: 4 },
      { id: 11, text: "Nutrition packs for pregnant women", row: 4 },
      { id: 12, text: "USG Scanning for Ante Natal/others", row: 5 },
      { id: 13, text: "Referral to Higher Centers", row: 5 },
    ];
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (id) => {
      setCheckedItems((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    // Follow Up Code
    const endVisitFollowUp = useRef();
    const [isScheduleFollowUp, setIsScheduleFollowUp] = useState(false);
    const [CompleteDescription, setCompleteDescription] = useState(null);
    const [followUpDate, setFollowUpDate] = useState("");
    const [referralDoctor, setReferralDoctor] = useState("");
    const [isFreeVisit, setIsFreeVisit] = useState(false);
    const screenType = useScreenType();

    const [actionAccessAppointments, setActionAccessAppointments] =
      useState(null);
    const handlePreviewClick = () => {
      const data = {
        followUpDate: isScheduleFollowUp
          ? followUpDate
            ? moment(followUpDate).format("D MMM YYYY")
            : ""
          : "",
        referralDoctor: referralDoctor,
      };
      endVisitFollowUp.current.openSecondModal(data);
    };

    const handlePauseEndClick = () => {
      // The referral doctor and followup date states can be reset here, if required in future
      endVisitFollowUp.current.openModal();
    };

    const [interruptUrl, setInterruptUrl] = useState(null);

    const openInterruptModalWithUrl = (url) => {
      setInterruptUrl(url);
      if (interruptVisit.current) {
        interruptVisit.current.openModal(url);
      }
    };
    useEffect(() => {
      setActionAccessAppointments(actionAccessMain(15));
      if (time && time !== "null") {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        totalSeconds.current = hours * 3600 + minutes * 60 + seconds;
        setRunningTime(time);
      } else {
        totalSeconds.current = 0;
        setRunningTime("00:00:00");
      }
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }, [time]);

    useImperativeHandle(ref, () => ({
      start() {
        if (!isRunning) {
          setIsRunning(true);
          timerRef.current = setInterval(() => {
            totalSeconds.current += 1;
            const hours = String(
              Math.floor(totalSeconds.current / 3600)
            ).padStart(2, "0");
            const minutes = String(
              Math.floor((totalSeconds.current % 3600) / 60)
            ).padStart(2, "0");
            const seconds = String(totalSeconds.current % 60).padStart(2, "0");
            setRunningTime(`${hours}:${minutes}:${seconds}`);
          }, 1000);
        }
      },
      pause() {
        handlePauseVisit();
      },
      resume() {
        if (!isRunning) {
          setIsRunning(true);
          timerRef.current = setInterval(() => {
            totalSeconds.current += 1;
            const hours = String(
              Math.floor(totalSeconds.current / 3600)
            ).padStart(2, "0");
            const minutes = String(
              Math.floor((totalSeconds.current % 3600) / 60)
            ).padStart(2, "0");
            const seconds = String(totalSeconds.current % 60).padStart(2, "0");
            setRunningTime(`${hours}:${minutes}:${seconds}`);
          }, 1000);
        }
      },
      end() {
        handleEndVisit();
      },

      openInterruptModalWithUrl,
    }));

    const handlePauseVisit = async () => {
      if (isRunning) {
        setIsRunning(false);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        const inputForPause = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "pause",
            time: new Date().toISOString(), // current timestamp
          },
        };

        try {
          showLoader();
          const result = await UpdateAppointmentStatus(inputForPause.input);
          if (result.output.result === "Successfully Inserted") {
            showSuccessToast("Visit paused successfully");
            pauseOrEndVisit.current.closeModal();
            navigate(
              `/visitdetails?patientId=${pateintId}&appointmentId=${appointmentId}`
            );
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error pausing visit:", error);
          showErrorToast("An error occurred while pausing visit");
        } finally {
          dismissLoader();
        }
      }
    };

    const handlePauseVisitViaInterrupt = async () => {
      if (isRunning) {
        setIsRunning(false);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        const inputForPause = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "pause",
            time: new Date().toISOString(), // current timestamp
          },
        };

        try {
          showLoader();
          const result = await UpdateAppointmentStatus(inputForPause.input);
          if (result.output.result === "Successfully Inserted") {
            showSuccessToast("Visit paused successfully");
            pauseOrEndVisit.current.closeModal();
            // navigate(
            //   `/visitdetails?patientId=${pateintId}&appointmentId=${appointmentId}`
            // );
            navigate(interruptUrl);
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error pausing visit:", error);
          showErrorToast("An error occurred while pausing visit");
        } finally {
          dismissLoader();
        }
      }
    };

    const handleInterruptPauseVisit = async () => {
      // console.log("clicked");
      if (isRunning) {
        setIsRunning(false);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        const inputForPause = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "pause",
            time: new Date().toISOString(), // current timestamp
          },
        };

        try {
          showLoader();
          const result = await UpdateAppointmentStatus(inputForPause.input);
          if (result.output.result === "Successfully Inserted") {
            showSuccessToast("Visit paused successfully");
            interruptVisit.current.closeModal();
            navigate(interruptUrl);
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error pausing visit:", error);
          showErrorToast("An error occurred while pausing visit");
        } finally {
          dismissLoader();
        }
      }
    };

    const handleSaveAsDraft = async () => {
      if (isRunning) {
        setIsRunning(false);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        const inputForDraft = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "pause",
            time: new Date().toISOString(), // current timestamp
            savedasdraft: 1,
          },
        };

        // console.log(inputForDraft);

        try {
          showLoader();
          const result = await UpdateAppointmentStatus(inputForDraft.input);
          if (result.output.result === "Successfully Inserted") {
            showSuccessToast("Draft Saved successfully");
            pauseOrEndVisit.current.closeModal();
            navigate(
              `/visitdetails?patientId=${pateintId}&appointmentId=${appointmentId}`
            );
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error saving draft:", error);
          showErrorToast("An error occurred while pausing visit");
        } finally {
          dismissLoader();
        }
      }
    };

    const handleInterruptSaveAsDraft = async () => {
      if (isRunning) {
        setIsRunning(false);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        const inputForDraft = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "pause",
            time: new Date().toISOString(), // current timestamp
            savedasdraft: 1,
          },
        };

        // console.log(inputForDraft);

        try {
          showLoader();
          const result = await UpdateAppointmentStatus(inputForDraft.input);
          if (result.output.result === "Successfully Inserted") {
            showSuccessToast("Draft Saved successfully");
            interruptVisit.current.closeModal();
            navigate(interruptUrl);
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error saving draft:", error);
          showErrorToast("An error occurred while pausing visit");
        } finally {
          dismissLoader();
        }
      }
    };

    const handlePrintOrder = () => {
      const url = `/visitprescription?doctorId=${doctorId}&patientId=${pateintId}&appointmentId=${appointmentId}`;
      window.open(url, "_blank");
    };

    const handleProgresNotesClick = () => {
      // const url = `/visitsummary?doctorId=${doctorId}&patientId=${pateintId}&appointmentId=${appointmentId}&fromEndVisit=true`;
      const url = `/visitsummary?doctorId=${doctorId}&patientId=${pateintId}&appointmentId=${appointmentId}`;
      window.open(url, "_blank");
    };

    const handleEndVisit = async () => {
      if (isRunning) {
        setIsRunning(false);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }

        const inputForEnd = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "end",
            time: new Date().toISOString(), // current timestamp
          },
        };

        try {
          // showLoader();

          // console.log("change status to end", inputForEnd);
          const result = await UpdateAppointmentStatus(inputForEnd.input);
          if (result.output.result === "Successfully Inserted") {
            // showSuccessToast("Visit ended successfully");
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error ending visit:", error);
          showErrorToast("An error occurred while ending visit");
        } finally {
          // dismissLoader();
        }
      }
    };

    const sendFollowUp = async () => {
      const dynamicKeys = options.reduce((acc, option) => {
        const formattedKey = option.text
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "") // Create key from `text`, e.g., "ECG" -> "ecg"
          .replace(/[\s]+/g, ""); // Handle edge cases like multiple spaces
        acc[formattedKey] = checkedItems[option.id] ? 1 : 0;
        return acc;
      }, {});
      const inputToFollowUp = {
        input: {
          patientid: pateintId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          appointmentenddate: new Date().toISOString(), // current timestamp
          completed: "3",
          followup: {
            isfollowup: isScheduleFollowUp ? 1 : 0,
            followupdate:
              isScheduleFollowUp && followUpDate
                ? moment(followUpDate).format("YYYY-MM-DD")
                : null,
            isfreevisit: isFreeVisit ? 1 : 0,
            referraldoctor: referralDoctor,
            ...dynamicKeys, // Add dynamic keys based on `options` and `checkedItems`
          },
        },
      };

      try {
        showLoader();
        console.log("follow up", inputToFollowUp);
        handleEndVisit(); // update the appointment status
        const result = await updateDoctorNotes(inputToFollowUp.input);
        console.log(result);
        if (result) {
          showSuccessToast("Follow-up information sent successfully");
          // Open prescription page in a new tab before redirecting to visits
          const url = `/visitprescription?doctorId=${doctorId}&patientId=${pateintId}&appointmentId=${appointmentId}&fromEndVisit=${true}`;
          const url2 = `/visitsummary?doctorId=${doctorId}&patientId=${pateintId}&appointmentId=${appointmentId}&fromEndVisit=${true}`;
          window.open(url, "_blank");
          // window.open(url2, "_blank");
          navigate("/visits");
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in sendFollowUp:", error);
        showErrorToast("An error occurred while sending follow-up information");
      } finally {
        dismissLoader();
      }
    };

    const sendFollowUpViaInterrupt = async () => {
      const inputToFollowUp = {
        input: {
          patientid: pateintId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          appointmentenddate: new Date().toISOString(), // current timestamp
          completed: "3",
          followup: {
            isfollowup: isScheduleFollowUp ? 1 : 0,
            followupdate:
              isScheduleFollowUp && followUpDate
                ? moment(followUpDate).format("YYYY-MM-DD")
                : null,
            isfreevisit: isFreeVisit ? 1 : 0,
            referraldoctor: referralDoctor,
          },
        },
      };

      try {
        showLoader();
        // console.log("follow up", inputToFollowUp);
        handleEndVisit(); // update the appointment status
        const result = await updateDoctorNotes(inputToFollowUp.input);
        console.log(result);
        if (result) {
          showSuccessToast("Follow-up information sent successfully");
          // Open prescription page in a new tab before redirecting to visits
          const url = `/visitprescription?doctorId=${doctorId}&patientId=${pateintId}&appointmentId=${appointmentId}&fromEndVisit=${true}`;
          window.open(url, "_blank");
          // navigate("/visits");
          navigate(interruptUrl);
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in sendFollowUp:", error);
        showErrorToast("An error occurred while sending follow-up information");
      } finally {
        dismissLoader();
      }
    };

    
    const reAssignModal = useRef();
    function handleComplete (){
      reAssignModal.current.openModal();
    }
    const Submitcompleteusertask = async ()=>{
     let input = {
      "appointmentid": appointmentId,
      "doctorid": "",
      "status": 1,
      "comment": CompleteDescription
     }
     const inputForPause = {
      input: {
        doctorid: doctorId,
        appointmentid: appointmentId,
        type: "pause",
        time: new Date().toISOString(), // current timestamp
      },
    };
     try {
      showLoader();
      await UpdateAppointmentStatus(inputForPause.input);
      const result = await updateTaskComplete(input);
      console.log(result);
      if (result) {
        navigate("/visits");

      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in sendFollowUp:", error);
      showErrorToast("An error occurred while sending follow-up information");
    } finally {
      dismissLoader();
    }
    }
    return (
      <>
        {screenType == "web" ? (
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            bg="#FFFFFF"
            color="white"
            p="1rem 1.5rem"
            borderBottom="1px solid #E6E7E9"
            flexWrap='nowrap'
          >
            <Box display="flex" gap="0.5rem" alignItems="center">
              {hasImage ? (
                <Image
                  borderRadius="full"
                  w="2.75rem"
                  h="2.75rem"
                  src={patientImage}
                />
              ) : (
                <Image
                  borderRadius="full"
                  w="2.75rem"
                  h="2.75rem"
                  src="/assets/imgs/no-image.png"
                />
              )}
              <Box>
                <Text fontSize="1rem" fontWeight="600" color="black">
                  {patientName}
                </Text>
                {patientInfoId ? (
                  <Box display="flex" gap="0.25rem" alignItems="center">
                    {/* <Image src="/assets/svgs/home-calendar.svg" /> */}
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                      {patientInfoId} {subHeader}
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </Box>
            {/* <Spacer /> */}
            <Box display="flex" gap="1rem" alignItems="center">
              {IsAssign == 1 &&
                 <PrimaryButton
                 buttonText="Complete"
                 variant="grayBtn"
                 btnStyle={{
                   bg: COLORS.PRIMARY_GRADIENT,
                   color: COLORS.PRIMARY_WHITE,
                   _hover: { bg: COLORS.PRIMARY_COLOR },
                 }}
                 onClick={handleComplete}
               />
                }
           

              <PrimaryButtonIcon
                buttonText={runningTime}
                iconSrc="/assets/svgs/time-visit.svg"
                variant="grayBtn"
                btnStyle={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  h: "2.5rem",
                  w: "8rem", // Fixed width
                  p: "1rem",
                  m: "0",
                  ml: "1rem",
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Prevent overflow
                  textOverflow: "ellipsis", // Add ellipsis if text is too long
                }}
                textStyle={{
                  marginLeft: "auto", // Pushes the text to the right
                  marginRight: "auto", // Centers the text within the remaining space
                }}
              />

              <PrimaryButton
                buttonText={virtualVisit ? "Approve Visit" : "End/pause Visit"}
                variant="grayBtn"
                btnStyle={{ color: "red", px: "14" }}
                onClick={handlePauseEndClick}
                isDisabled={!actionAccessAppointments?.create}
              />
              {/* <PrimaryButton
                buttonText="Save As Draft"
                variant="grayBtn"
                btnStyle={{
                  h: "2.5rem",
                  w: "33%",
                  p: "0",
                  px: "6",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={handleSaveAsDraft}
              /> */}
              <PrimaryButton
                buttonText="Generate Prescription"
                variant="grayBtn"
                btnStyle={{
                  h: "2.5rem",
                  w: "33%",
                  p: "0",
                  px: "6",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={handlePrintOrder}
              />
              <PrimaryButton
                buttonText="Progress Notes"
                variant="grayBtn"
                btnStyle={{
                  h: "2.5rem",
                  w: "25%",
                  p: "0",
                  px: "6",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={handleProgresNotesClick}
              />
            </Box>
          </Flex>
        ) : (
          <Flex
            as="nav"
            wrap="wrap"
            gap="1rem"
            bg="#FFFFFF"
            color="white"
            p="1rem 1.5rem"
            borderBottom="1px solid #E6E7E9"
          >
            <Box display="flex" gap="0.5rem" alignItems="center" w="100%">
              {hasImage ? (
                <Image
                  borderRadius="full"
                  w="2.75rem"
                  h="2.75rem"
                  src={patientImage}
                />
              ) : (
                <Image
                  borderRadius="full"
                  w="2.75rem"
                  h="2.75rem"
                  src="/assets/imgs/no-image.png"
                />
              )}
              <Box>
                <Text fontSize="1rem" fontWeight="600" color="black">
                  {patientName}
                </Text>
                {patientInfoId ? (
                  <Box display="flex" gap="0.25rem" alignItems="center">
                    {/* <Image src="/assets/svgs/home-calendar.svg" /> */}
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                      {patientInfoId} {subHeader}
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </Box>

            <Box
              display="flex"
              gap="1rem"
              w="100%"
              flexDir="column"
              alignItems="center"
            >
              <Box display="flex" w="100%" gap="1rem">
                <PrimaryButton
                  variant="grayBtn"
                  buttonText={runningTime}
                  btnIcon="/assets/svgs/time-visit.svg"
                  btnStyle={{ color: "#1A202C" }}
                />

                <PrimaryButton
                  buttonText="End/pause Visit"
                  variant="grayBtn"
                  btnStyle={{ color: "red", px: "14" }}
                  onClick={handlePauseEndClick}
                  isDisabled={!actionAccessAppointments?.create}
                />
              </Box>
              {/* <PrimaryButton
                buttonText="Save As Draft"
                variant="grayBtn"
                btnStyle={{
                  h: "2.5rem",
                  w: "100%",
                  p: "0",
                  px: "6",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={handleSaveAsDraft}
              /> */}
            {IsAssign == 1 &&
              <PrimaryButton
                buttonText="Complete"
                variant="mdBtn"
                btnStyle={{
                  w:'100%',m:0
                }}
                onClick={handleComplete}
              />
              }
              <Flex gap='0.5rem' w='100%'>
              <PrimaryButton
                buttonText="Generate Prescription"
                variant="grayBtn"
                btnStyle={{
                  h: "2.5rem",
                  w: "100%",
                  p: "0",
                  px: "6",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={handlePrintOrder}
                />
              <PrimaryButton
                buttonText="Progress Notes"
                variant="grayBtn"
                btnStyle={{
                  h: "2.5rem",
                  w: "80%",
                  p: "0",
                  px: "6",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={handleProgresNotesClick}
                />
                </Flex>
            </Box>
          </Flex>
        )}

        {/* End/Pause Visit  */}
        <DynamicModal
          ref={pauseOrEndVisit}
          modalHeader="Pause/End Visit"
          modalBody={
            <>
              <Box>
                <Text fontSize="0.87rem" fontWeight="400">
                  Would you like to pause your visit or end it?{" "}
                  <Box as="span" fontWeight="500">
                    Pausing
                  </Box>{" "}
                  will allow you to continue your visit and fill in the details,
                  while{" "}
                  <Box as="span" fontWeight="500">
                    ending
                  </Box>{" "}
                  the visit will mark it as completed.
                </Text>
              </Box>
            </>
          }
          modalFooterBtn={
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width="100%"
            >
              <PrimaryButton
                buttonText="End visit"
                onClick={handleEndVisit}
                mb="1rem"
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.update
                    ? false
                    : true
                }
              />
              <PrimaryButton
                buttonText="Pause visit"
                variant="grayBtn"
                btnStyle={{ w: "100%", color: COLORS.PRIMARY_COLOR }}
                onClick={handlePauseVisit}
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.create
                    ? false
                    : true
                }
              />
            </Flex>
          }
        />

        {/* End Visit  */}
        <EndVisitDynamicModal
          modalWidth={{base:'100%', lg: "50vw"}}
          ref={endVisitFollowUp}
          modalHeader={virtualVisit ? "Approve Visit" : "End visit"}
          patientId={pateintId}
          doctorId={doctorId}
          appointmentId={appointmentId}
          followUpDateForPreview={followUpDate}
          referralDoctor={referralDoctor}
          modalBody={
            <Box>
              <Flex alignItems="center" mb="1rem">
                {hasImage ? (
                  <Image
                    borderRadius="full"
                    boxSize="50px"
                    w="2.75rem"
                    h="2.75rem"
                    mr="1rem"
                    src={patientImage}
                  />
                ) : (
                  <Image
                    borderRadius="full"
                    boxSize="50px"
                    w="2.75rem"
                    h="2.75rem"
                    mr="1rem"
                    src="/assets/imgs/no-image.png"
                  />
                )}
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    {patientName}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {patientInfoId}
                  </Text>
                </Box>
                <Spacer />
                <PrimaryButtonIcon
                  buttonText={runningTime}
                  iconSrc="/assets/svgs/time-visit.svg"
                  variant="grayBtn"
                  btnStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    h: "2.5rem",
                    w: "8rem", // Fixed width
                    p: "1rem",
                    m: "0",
                    ml: "1rem",
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Prevent overflow
                    textOverflow: "ellipsis", // Add ellipsis if text is too long
                  }}
                  textStyle={{
                    marginLeft: "auto", // Pushes the text to the right
                    marginRight: "auto", // Centers the text within the remaining space
                  }}
                />
              </Flex>
              <Divider my="12px" />
              <Box mb="1rem">
                {Array.from(new Set(options.map((o) => o.row))).map((row) => {
                  const rowOptions = options.filter(
                    (option) => option.row === row
                  );
                  // const columns = row < 4 ? 3 : 2;

                  return (
                    <SimpleGrid
                      key={row}
                      columns={{base:2, md:3}}
                      spacing="1rem"
                      mb="1rem"
                    >
                      {rowOptions.map((option) => (
                        <Checkbox
                          key={option.id}
                          isChecked={checkedItems[option.id] || false}
                          onChange={() => handleCheckboxChange(option.id)}
                        >
                          {option.text}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  );
                })}
              </Box>
              <Divider my="12px" />
              <Checkbox
                isChecked={isScheduleFollowUp}
                onChange={(e) => setIsScheduleFollowUp(e.target.checked)}
                mb="1rem"
              >
                Schedule a follow up visit
              </Checkbox>
              {isScheduleFollowUp && (
                <Box
                  border="1px solid #E6E7E9"
                  borderRadius="8px"
                  p="1rem"
                  mb="1rem"
                >
                  <Text fontSize="sm" mb="0.5rem">
                    Follow-up by
                  </Text>
                  <DatePicker
                    defaultDate={followUpDate}
                    onDateChange={(date) => setFollowUpDate(date)}
                    placeholderProp="Select a Date"
                    minDate={new Date().toISOString()}
                  />
                  <Checkbox
                    isChecked={isFreeVisit}
                    onChange={(e) => setIsFreeVisit(e.target.checked)}
                    mt="1rem"
                  >
                    Free visit
                  </Checkbox>
                </Box>
              )}
              <PrimaryInput
                type="text"
                mt={"8px"}
                width={"80%"}
                variant={"bothSide"}
                inputPlace="Referral Doctor"
                defaultValue={referralDoctor}
                onChange={(e) => setReferralDoctor(e.target.value)}
              />
            </Box>
          }
          modalFooterBtn={
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width="100%"
            >
              <PrimaryButton
                buttonText={virtualVisit ? "Approve Visit" : "End visit"}
                onClick={sendFollowUp}
                mb="1rem"
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.update
                    ? false
                    : true
                }
              />
              <PrimaryButton
                buttonText="Preview Order"
                variant="grayBtn"
                btnStyle={{ w: "100%", color: COLORS.PRIMARY_COLOR }}
                onClick={handlePreviewClick}
              />
              <>
                <Text
                  textAlign={"center"}
                  fontWeight={"semibold"}
                  mb={"12px"}
                  mt={"12px"}
                >
                  OR
                </Text>

                <PrimaryButton
                  buttonText="Pause visit"
                  variant="grayBtn"
                  btnStyle={{ w: "100%", color: COLORS.PRIMARY_COLOR }}
                  onClick={handlePauseVisit}
                  isDisabled={
                    actionAccessAppointments && actionAccessAppointments.create
                      ? false
                      : true
                  }
                />
              </>
            </Flex>
          }
        />

        {/* Interrupt Visit  */}
        {/* <DynamicModal
          ref={interruptVisit}
          modalHeader="Save your work"
          modalBody={
            <>
              <Box>
                <Text fontSize="0.87rem" fontWeight="400">
                  Would you like to pause your visit or save visit as a draft?{" "}
                </Text>
              </Box>
            </>
          }
          modalFooterBtn={
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              width="100%"
              gap="10px" // Add space between buttons
            >
              <PrimaryButton
                buttonText="Pause visit"
                onClick={handleInterruptPauseVisit}
                btnStyle={{ w: "60%", color: COLORS.PRIMARY_COLOR, ml: "2px" }}
                variant="grayBtn"
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.update
                    ? false
                    : true
                }
              />
              <PrimaryButton
                buttonText="Save as Draft"
                variant="grayBtn"
                btnStyle={{ w: "60%", color: COLORS.PRIMARY_COLOR, mr: "2px" }}
                onClick={handleInterruptSaveAsDraft}
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.update
                    ? false
                    : true
                }
              />
            </Flex>
          }
        /> */}
        <EndVisitDynamicModal
          ref={interruptVisit}
          modalHeader="End visit"
          patientId={pateintId}
          doctorId={doctorId}
          appointmentId={appointmentId}
          followUpDateForPreview={followUpDate}
          referralDoctor={referralDoctor}
          modalBody={
            <Box>
              <Flex alignItems="center" mb="1rem">
                {hasImage ? (
                  <Image
                    borderRadius="full"
                    boxSize="50px"
                    w="2.75rem"
                    h="2.75rem"
                    mr="1rem"
                    src={patientImage}
                  />
                ) : (
                  <Image
                    borderRadius="full"
                    boxSize="50px"
                    w="2.75rem"
                    h="2.75rem"
                    mr="1rem"
                    src="/assets/imgs/no-image.png"
                  />
                )}
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    {patientName}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {patientInfoId}
                  </Text>
                </Box>
                <Spacer />
                <PrimaryButtonIcon
                  buttonText={runningTime}
                  iconSrc="/assets/svgs/time-visit.svg"
                  variant="grayBtn"
                  btnStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    h: "2.5rem",
                    w: "8rem", // Fixed width
                    p: "1rem",
                    m: "0",
                    ml: "1rem",
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Prevent overflow
                    textOverflow: "ellipsis", // Add ellipsis if text is too long
                  }}
                  textStyle={{
                    marginLeft: "auto", // Pushes the text to the right
                    marginRight: "auto", // Centers the text within the remaining space
                  }}
                />
              </Flex>

              <Divider my="12px" />
              <Checkbox
                isChecked={isScheduleFollowUp}
                onChange={(e) => setIsScheduleFollowUp(e.target.checked)}
                mb="1rem"
              >
                Schedule a follow up visit
              </Checkbox>
              {isScheduleFollowUp && (
                <Box
                  border="1px solid #E6E7E9"
                  borderRadius="8px"
                  p="1rem"
                  mb="1rem"
                >
                  <Text fontSize="sm" mb="0.5rem">
                    Follow-up by
                  </Text>
                  <DatePicker
                    defaultDate={followUpDate}
                    onDateChange={(date) => setFollowUpDate(date)}
                    placeholderProp="Select a Date"
                    minDate={new Date().toISOString()}
                  />
                  <Checkbox
                    isChecked={isFreeVisit}
                    onChange={(e) => setIsFreeVisit(e.target.checked)}
                    mt="1rem"
                  >
                    Free visit
                  </Checkbox>
                </Box>
              )}
              <PrimaryInput
                type="text"
                mt={"8px"}
                width={"80%"}
                variant={"bothSide"}
                inputPlace="Referral Doctor"
                defaultValue={referralDoctor}
                onChange={(e) => setReferralDoctor(e.target.value)}
              />
            </Box>
          }
          modalFooterBtn={
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width="100%"
            >
              <PrimaryButton
                // buttonText="End visit"
                buttonText={virtualVisit ? "Approve Visit" : "End visit"}
                onClick={sendFollowUpViaInterrupt}
                mb="1rem"
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.update
                    ? false
                    : true
                }
              />
              <PrimaryButton
                buttonText="Preview Order"
                variant="grayBtn"
                btnStyle={{ w: "100%", color: COLORS.PRIMARY_COLOR }}
                onClick={handlePreviewClick}
              />

              <Text
                textAlign={"center"}
                fontWeight={"semibold"}
                mb={"12px"}
                mt={"12px"}
              >
                OR
              </Text>

              <PrimaryButton
                buttonText="Pause visit"
                variant="grayBtn"
                btnStyle={{ w: "100%", color: COLORS.PRIMARY_COLOR }}
                onClick={handlePauseVisitViaInterrupt}
                isDisabled={
                  actionAccessAppointments && actionAccessAppointments.create
                    ? false
                    : true
                }
              />
            </Flex>
          }
        />

             {/* reAssign MOdal  */}
             <DynamicModal
        ref={reAssignModal}
        modalHeader="Complete"
        modalBody={
          <>
            <Box>
            <Textarea
                      borderRadius='0'
                      placeholder="Comment"
                      defaultValue={CompleteDescription}
                      onChange={(e) =>
                        setCompleteDescription(e.target.value)
                      }
                    />
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText="Complete"
              onClick={Submitcompleteusertask}
            />
          </>
        }
      />
      </>
    );
  }
);

export default PatientVisitNav;
