import { Box, Text, Image, Flex } from "@chakra-ui/react";
import React from "react";

const Footer = ({normalFooter = true,footerStyle}) => {
  const date = new Date();
  return (
    <>
    {normalFooter ? (
      <Box
      as="footer"
      display="flex"
      my="2rem"
      alignItems="center"
      w="100%"
      justifyContent="center"
      gap="10px"
      pos='relative'
      sx={{...footerStyle}}
      >
        <Text fontSize="0.625rem" fontWeight="400">
          A PRODUCT OF
        </Text>
        <Image src="/assets/svgs/tci-logo.svg" />
      </Box>
      ) : (

        <Flex
        w="100%"
        h="58px"
        p="20px 32px"
        justifyContent="space-between"
        alignItems="center"
        borderTop="1px solid #E6E7E9"
        >
        <Box display="flex" alignItems="center" gap="10px">
          <Text fontSize="8px" fontWeight="400">
            A PRODUCT OF
          </Text>
          <Image src="/assets/svgs/tci-logo.svg" />
        </Box>
        <Box>
          <Text fontSize="10px" fontWeight="500">
            © {date.getFullYear()} midoc inc.
          </Text>
        </Box>
      </Flex>
      )}

    </>
  );
};

export default Footer;
