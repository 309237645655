import { Box, Center, Circle, Divider, Flex, Image, ListItem, Menu, MenuButton, Radio, RadioGroup, SimpleGrid, Spacer, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import GrayCard from '../../components/card'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FetchPatientDetails, AddVitalData } from '../../middleware/services/messages';
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import MobileSecondaryNav from '../../components/mobile-secondary-navbar';
import Vitalsmodal from "../../components/vitalsdata/vitalcomponent"
import { kgToLbConverter, lbToKgConverterUsingDBValue, ftToCmConverterBasic } from '../../auth';
import DatePicker from "../../components/date-picker";
import {
  PrimaryButton,
  PrimaryInput,
  Toaster,
} from "../../components";
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";
import { format, isValid, parse } from "date-fns";


const OverviewIndex = ({pid, backFunction}) => {

  const location = useLocation();
  const [patientId, setPatientId] = useState(pid);
  const [patienData, setPatienData] = useState();
  const [backState, setBackState] = useState(false);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [screenType, setScreenType] = useState('web');
  const navigate = useNavigate();
  const dataTable = {
      "White blood cells": "",
      Hemoglobin: "",
      Hematocrit: "",
      "Platelet count": "",
      Chloride: "",
      Sodium: "",
      Potassium: "",
      CO2: "",
      BUN: "",
      Creatinine: "",
      Glucose: "",
    };
  
    const dataEntries = Object.entries(dataTable);


    const formatDate = (dateString) => {
      if (!dateString) return "-";
      return moment(dateString).format("Do MMM, YYYY");
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setScreenType('mobile');
        } else {
          setScreenType('web');
        }
      };
  
      handleResize(); // Initial check
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    setPatientId(id);

    fetchpatientdata(id)   
  }, [location.search]);
  
  const fetchpatientdata = async (id) => {
    showLoader()
        try {
          const result = await FetchPatientDetails({
            patientid: id,
          });
          // console.log('patientdata BP Outside: ', result)


          if (result != null) {
            setPatienData(result);
          } else {
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      // console.log(patienData, "PATIENT DATAA")

  // useEffect(() => {
  //   const fetchpatientdata = async (id) => {
  //     showLoader()
  //         try {
  //           const result = await FetchPatientDetails({
  //             patientid: id,
  //           });

  //           console.log('patientdata BP: ', result)
  
  //           if (result != null) {
  //             setPatienData(result);
  //           } else {
  //           }
  //         } catch (error) {
  //           console.error("Error in home:", error);
  //         }
  //       };
        
  //       dismissLoader()
  //   fetchpatientdata();
  // }, []);

  useEffect(() => {
    if (patienData && patienData.patientinfo) {
      const { dob, gender } = patienData.patientinfo;
      const dataToStore = {
        dob,
        gender
      };

      localStorage.setItem('dobAndGenderData', JSON.stringify(dataToStore));
    }
  }, [patienData]);



  const calculateYearsDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    // Calculating differece in ms
    const diffInMs = endDate - startDate;

    // Milliseconds to years
    const msInYear = 1000 * 60 * 60 * 24 * 365.25;
    const diffInYears = diffInMs / msInYear;
  
    return diffInYears.toFixed(0);
  };
  
  const formatDate_yyyy = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format("yyyy");
  };
//Demographics update
const vitalsbmiModals = React.useRef();
const [weightvalue, setWeightValue] = useState("");
const [vitalunitweight, setVitalUnitWeight] = useState("");
const [vitalunitheight, setVitalUnitHeight] = useState("");
const [BMICalculatorType, setBMICalculatorType] = useState('standard');
const [date, setDate] = useState(moment().format("DD-MM-yyyy"));
const [time, setTime] = useState(moment().format("HH:mm"));
const [feetOptions, setFeetOptions] = useState([]);
const [inchesOptions, setInchesOptions] = useState([]);
const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
const [weightvalueInitial, setweightvalueInitial] = useState("");
const [showInchesInput , setShowInchesInput] = useState(true)
const [feetValue , setFeetValue] = useState("")
const [inchValue , setInchValue] = useState("")
const [inchValueToCalculateInitial, setInchValueToCalculateInitial] = useState(0);
const { showSuccessToast, showErrorToast } = Toaster();
const [vitalname, setVitalName] = useState("");
const [vitalvalue, setVitalValue] = useState("");
const [vitalunit, setVitalUnit] = useState("");
const [initailRender, setInitialRender] = useState(true);
const [heightvalue, setHeightValue] = useState("");
const [ weightInputChanged, setWeightInputChanged] = useState(false)
const [isSubmitting, setIsSubmitting] = useState(false);

useEffect(()=>{
  if(vitalunitheight == "ft"){
    setShowInchesInput(true)
   // alert(vitalunitheight)
  }else{
    setShowInchesInput(false)
  }
}, [vitalunitheight])
 //This is the click of BMI vital
 const openVitalBMIform = () => { 
  if(patienData?.demographics?.weightunit && patienData?.demographics?.weightunit == "kg"){
    var converterValue = kgToLbConverter(weightvalueInitial)
    setWeightValue(converterValue.value)
  }
  // if(heightUnitBMI && heightUnitBMI == "ft"){
  //   var converterValue = kgToLbConverter(weightvalue)
  //   setHeightValue(converterValue)
  // }
  setweightvalueInitial(patienData?.demographics?.weight);
  setWeightValue(patienData?.demographics?.weight);
  setHeightValue(patienData?.demographics?.height);
    if(patienData?.demographics?.height != null){
      let parts = patienData?.demographics?.height.toString().split('.');
      setFeetValue(parts[0])
      setInchValue(parts[1])
    }
  setBMICalculatorType("standard")
  setVitalUnitHeight("ft");
  setVitalUnitWeight("lbs");
  setDate(moment().format("DD-MM-yyyy"));
  setTime(moment().format("HH:mm"));
  vitalsbmiModals.current.openVitals();

  const tempOptions = [];
  const tempOptionsInches = [];
  const tempOptionsHeight = [];
  const tempOptionsWeightLB = [];
  for (let i = 1; i <= 10; i++) {
    tempOptions.push(i);
  }
  for (let i = 1; i <= 11; i++) {
    tempOptionsInches.push(i);
  }
  for (let i = 1; i <= 10; i++) {
    tempOptionsHeight.push(i);
     for (let j = 1; j <= 11; j++) {
      tempOptionsHeight.push((`${i}.${j}`));
          }
  }
  for (let i = 1; i <= 479; i++) {
    tempOptionsWeightLB.push(i);
  }
  setFeetOptions(tempOptions)
  setInchesOptions(tempOptionsInches)
  setHeightOptionsInBMI(tempOptionsHeight)
  setWeightOptionsInBMI(tempOptionsWeightLB)
};
const handledatechange = (selectedDate) => {
  const formattedDate = format(selectedDate, "yyyy-MM-dd");
  setDate(formattedDate);
};
const handleTimeChange = (event) => {
  const inputTime = event.target.value;
  const currentTime = new Date();
  const [inputHours, inputMinutes] = inputTime.split(":").map(Number);

  const inputDate = new Date();
  inputDate.setHours(inputHours);
  inputDate.setMinutes(inputMinutes);

  // Check if the input time is in the future
  if (inputDate > currentTime) {
    
    setTime(moment().format("HH:mm"));
    showErrorToast("Future time cannot be selected.");
  } else {
    setTime(inputTime);
  }
};
const switchBMICalculation = (value) => {
  const tempOptionsWeightLB = [];
  const tempOptionsHeight = [];
  setBMICalculatorType(value);
  if (value == "metric") {
   // setWeightValue("")
    setVitalUnitHeight("cms");
    setVitalUnit("cms");
    setVitalUnitWeight("kg");
    //here
    var converterValueW = lbToKgConverterUsingDBValue(weightvalue)
    setWeightValue(converterValueW.value)

    setVitalValue(converterValueW.value)

    var heightValueOfFeet = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
     //var heightValueOfFeet = feetValue + "." + inchValue;
    //  if(initailRender){
       var converterValueH = ftToCmConverterBasic(heightValueOfFeet )
       
    //  }else {

    //    var converterValueH = ftToCmConverter(feetValue ,inchValue )
    //  }
     setInitialRender(false)
   // alert(converterValueH)
    setHeightValue(converterValueH)
    setVitalValue(converterValueH)
    
    for (let i = 1; i <= 250; i++) {
      tempOptionsWeightLB.push(i);
    }
    for (let i = 30; i <= 303; i++) {
      tempOptionsHeight.push(i);
    }
  setHeightOptionsInBMI(tempOptionsHeight)
  setWeightOptionsInBMI(tempOptionsWeightLB)
  } else if (value == "standard") {
  //  setWeightValue("")
  var converterValue = kgToLbConverter(weightvalue)
  setWeightValue(converterValue.value)
  setVitalValue(converterValue.value)
    setVitalUnitHeight("ft");
    setVitalUnit("ft");
    setVitalUnitWeight("lbs");
    
    for (let i = 1; i <= 479; i++) {
      tempOptionsWeightLB.push(i);
    }
    for (let i = 1; i <= 10; i++) {
      tempOptionsHeight.push(i);
      for (let j = 1; j <= 9; j++) {
        tempOptionsHeight.push((`${i}.${j}`));
      }
    }
  setHeightOptionsInBMI(tempOptionsHeight)
  setWeightOptionsInBMI(tempOptionsWeightLB)
  } 
};

const setHeightValueInBMIDropDown = (value) =>{
  const stringValue = String(value); // Convert value to string
  setHeightValue(stringValue)
  setVitalValue(stringValue)
}
const setWeightvalueInBMIDropdown = (value) =>{
  const stringValue = String(value); // Convert value to string
  setWeightValue(stringValue)
  setVitalValue(stringValue)
}
const setInchValueInDropDown = (value) =>{
  const stringValue = String(value); // Convert value to string
  setInchValue(stringValue)
}
const handleBMIinchValue = (e)=>{
  /// alert(e.target.value)
   var value = e.target.value;
   const numbersRegex = /^[0-9]+$/;
   if (numbersRegex.test(value) || value === "") { // Also allow empty value
    setInchValue(value)
    setInchValueToCalculateInitial(value)
   }
   
 }
 const handleBMIFeetValue = (e)=>{
  var value = e.target.value;
  const numbersRegex = /^[0-9]+$/;
  if (numbersRegex.test(value) || value === "") { 
    setFeetValue(value)
  }
  
}
const setFeetValueInDropDown = (value) =>{
  const stringValue = String(value); // Convert value to string
  setFeetValue(stringValue)
}
const handlebmivitalvaluechange = (e) => {
  const { id, value } = e.target;
  const maxLength = 6;
  switch (id) {
    case "weightvalue":
      let inputValue = value;
     const inputText = e.target.value;
    // alert(inputText)
     setWeightInputChanged(true)
      if (inputText.length <= maxLength) {
        
      let cleanedValue = inputText.replace(/[^0-9.]/g, '');
      if(cleanedValue.startsWith(".")){
        cleanedValue = cleanedValue.substring(1)
      }
      if (cleanedValue.match(/[0-9]{4,}/)) {
        cleanedValue = cleanedValue.slice(0, -1);
      }
      const decimalIndex = cleanedValue.indexOf('.');
      if (decimalIndex !== -1) {
        const parts = cleanedValue.split('.');
        cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
      }
      setWeightValue(cleanedValue);
      setVitalValue(cleanedValue)
      }
      break;
    case "heightvalue":
      const input = e.target.value;
    
      if (input.length <= maxLength) {
        
      let cleanedValue = input.replace(/[^0-9.]/g, '');
      if(cleanedValue.startsWith(".")){
        cleanedValue = cleanedValue.substring(1)
      }
      if (cleanedValue.match(/[0-9]{4,}/)) {
        cleanedValue = cleanedValue.slice(0, -1);
      }
      const decimalIndex = cleanedValue.indexOf('.');
      if (decimalIndex !== -1) {
        const parts = cleanedValue.split('.');
        cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
      }
      setHeightValue(cleanedValue);
      setVitalValue(cleanedValue)
      }
      break;
     
    default:
      break;
  }
};
const SubmitBMIform = async () => {   
  let Weightinput = {
    patientid: patienData?.patientinfo?.patientid,
    vitals: [
      {
        vitalname: "Weight",
        vitalvalue: weightvalue,
        vitalunit: vitalunitweight,
        vitaltype_cd: "",
        vitaladdeddate: `${date} ${time}`,
      },
    ],
  };
  var customHeightValue;
  if(BMICalculatorType == "standard"){
    customHeightValue = feetValue;
    if(inchValue && feetValue){
      
      customHeightValue = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
    }
  }else{
    customHeightValue = heightvalue;
  }
  let Heightinput = {
    patientid: patienData?.patientinfo?.patientid,
    vitals: [
      {
        vitalname: "Height",
        vitalvalue:  customHeightValue,
        vitalunit: vitalunitheight,
        vitaltype_cd: "238",
        vitaladdeddate: `${date} ${time}`,
      },
    ],
  };
  if (moment(date, "DD-MM-YYYY", true).isValid()) {
    const formatteddate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    const vitaldate = formatteddate + " " + time;
    Weightinput.vitals[0].vitaladdeddate = vitaldate;
    Heightinput.vitals[0].vitaladdeddate = vitaldate;
  }
  setIsSubmitting(true);
  var value = document.getElementById("weightvalue");
  var weightinput = value.value;

    var value = document.getElementById("heightvalue");
    var heightinput = value.value;
    
  if (weightinput == "") {
    showErrorToast("Please enter weight value ");
    return false;
  } else if (BMICalculatorType == "standard" && (weightinput < 40 || weightinput > 479)) {
    showErrorToast("Please enter a valid weight value ");
    return false;
  }else if (BMICalculatorType == "metric" && (weightinput < 1 || weightinput > 250)) {
    showErrorToast("Please enter a valid weight value ");
    return false;
  } else if (heightinput == "") {
    showErrorToast("Please enter height value ");
    return false;
  } else if (BMICalculatorType == "standard" && (heightinput < 1 || heightinput > 12) ) {
    showErrorToast("Please enter a valid height value ");
    return false;
  }else if (BMICalculatorType == "standard" &&  (inchValue && ( inchValue < 0 || inchValue > 12)) ) {
    showErrorToast("Please enter a valid inch value");
    return false;
  }else if (BMICalculatorType == "metric" && (heightinput < 30 || heightinput > 303)) {
    showErrorToast("Please enter a valid height value ");
    return false;
  } else {
    try {
      showLoader();
      const response = await AddVitalData(Weightinput);
      
      if (response.output.success == 1) {
        const result = await AddVitalData(Heightinput);
        if (result.output.success == 1) {
          vitalsbmiModals.current.closeVitals();
        fetchpatientdata(patienData?.patientinfo?.patientid)
        dismissLoader();
      }
      } else {
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error in SubmitFunction:", error);
    }
  }
};
  return (
    <>
    {isLoading && <Loader />}
    {screenType == 'web' ? (
    <Box w="100%" display={{base: "none", lg: "flex"}} flexWrap="wrap" gap="1rem" h="100%">
        {/* Demographics */}
        <GrayCard
        //hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        cardHeader="Demographics"
        cardHeaderIcon="/assets/imgs/measure.png"
        bodyStyle={{ p: "0" }}
        cardBody={
            <>
            <Flex direction="row" justify="space-around" h="full">
                <Box p="10px" px="20px" display="flex" flexDir="column" h="100%" justifyContent="center" w="100%">
                <Box>
                    <Box
                        as="p"
                        display="inline-block"
                        fontSize="20px"
                        fontWeight="600"
                    >
                        {/* 170 */}
                        {patienData && patienData.demographics.height ?(  <Text fontWeight="600">{patienData.demographics.height}</Text>  
                        ): ('0')}
                    </Box>
                    <Box
                        display="inline-block"
                        as="span"
                        fontSize="12px"
                        fontWeight="400"
                        pl={"5px"}
                    >
                        {patienData && patienData.demographics.heightunit ? (<Text fontWeight="600">{patienData.demographics.heightunit}</Text>) : ('cms') }
                    </Box>
                </Box>
                <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                    Height
                </Box>
                </Box>

                <Box
                p="10px" px="20px" display="flex" flexDir="column" h="100%" justifyContent="center" w="100%"
                borderRight="1px solid #E6E7E9"
                borderLeft="1px solid #E6E7E9"
                _last={{ borderRight: 0 }}
                >
                <Box>
                <Box  as="p"
                        display="inline-block"
                        fontSize="20px"
                        fontWeight="600">
                    {/* 70 */}
                    {/* {patienData && patienData.demographics &&(
                        <div>
                            <p> {patienData.demographics.weight}</p>
                        </div>
                    )} */}
                    {patienData && patienData.demographics.weight ?(  <Text fontWeight="600" fontSize="20px">{patienData.demographics.weight}
                    
                    </Text>  
                        ): '0'} 
                </Box>
                <Box  display="inline-block"
                        as="span"
                        fontSize="12px"
                        fontWeight="400"
                        pl={"5px"}>
                          {patienData && patienData.demographics.weightunit ? patienData.demographics.weightunit : ('Kg') }
                        </Box>

                {/* <Box
                    display="inline-block"
                    as="p"
                    fontSize="15px"
                    fontWeight="400"
                    pl={"5px"}
                    >
                    
                </Box> */}
                </Box>
                <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                    Weight
                </Box>
                </Box>

                <Box
                p="10px" px="20px" display="flex" flexDir="column" h="100%" justifyContent="center" w="100%"
                borderRight="1px solid #E6E7E9"
                borderLeft="1px solid #E6E7E9"
                _last={{ borderRight: 0 }}
                >
                <Box>
                <Box
                    as="p"
                    display="inline-block"
                    fontSize="20px"
                    fontWeight="600"
                >
                    {/* 70 */}
                    {patienData && patienData.demographics.bmi ?(  <Text fontWeight="600">{patienData.demographics.bmi}</Text>  
                        ): '0'}
                </Box>

                
                </Box>
                <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                    BMI
                </Box>
               
                </Box>
                
            </Flex>
            </>
        }
        cardFooterText="Update Demographics"
        cardFooterClickEvent={
          ()=>openVitalBMIform(patienData && patienData.demographics?.weightunit, patienData && patienData.demographics?.weight ,patienData && patienData.demographics?.height, )
        }
        />

        {/* Chief Complaint */}
        {/* <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        cardHeader="chief complaint"
        cardHeaderIcon="/assets/imgs/cheif-complaint.png"
        cardBody={
                <>
                    {patienData && patienData.chiefcomplaint.length > 0 ? (
                        <Flex flexDir="column" justifyContent="space-between" h="100%">
                            <Text fontSize="0.875rem" fontWeight="500">
                                {patienData.chiefcomplaint.chiefcomplaint ? patienData.chiefcomplaint.chiefcomplaint : "0"}
                                
                            </Text>
                            <Spacer />
                            <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                {patienData.chiefcomplaint.date ? patienData.chiefcomplaint.date : "0"}
                            </Text>
                        </Flex>
                    ) : (
                        <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/cheif-complaint.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                </>
        }
        /> */}

        {/* Present Illness */}
        {/* <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        cardHeader="history of present illness"
        cardHeaderIcon="/assets/imgs/medical-history.png"
        cardBody={
            // <>
            // <Box
            //     display="flex"
            //     flexDirection="column"
            //     justifyContent="center"
            //     gap="10px"
            //     alignItems="center"
            // >
            //     <Text textAlign="left" fontSize="14px" fontWeight="500">
            //         Patient is an 83-year-old male with a history of Guillain-Barré, coronary disease, on metoprolol, history of cardiac bypass, A-fib presenting with feeling of unwell today with a low heart rate in the 40s at home as well as testicular swelling right greater than left for the last week. It is highly important that this patient seeks immediate care and gets admitted.
            //     </Text>
            // </Box>
            // </>

            <>
            {patienData && patienData.historyofpresentillness.length > 0 ? (
                <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
            >
                <Text textAlign="left" fontSize="14px" fontWeight="500">
                    {patienData.historyofpresentillness.length > 0  ? (
                        patienData.historyofpresentillness.historyofillness
                    ) : (
                        "0"
                    )}
                </Text>
            </Box>
            ) : (
<Box
  display="flex"
  flexDirection="column"
  justifyContent="center"
  gap="10px"
  alignItems="center"
>
  <Box textAlign="left" fontSize="16px" fontWeight="600">
    <Image
      w="26px"
      opacity="0.5"
      src="/assets/imgs/medical-history.png"
    />
  </Box>
  <Text textAlign="left" fontSize="12px" fontWeight="500">
    No Data available
  </Text>
</Box>                                )}
            
        </>
        }/> */}

        {/* Physical Exam */}
        {/* <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        cardHeader="physical exam"
        cardHeaderIcon="/assets/imgs/physical-exam.png"
        cardBody={
            // <>
            //     <Box mb="10px">
            //     <Text fontSize="12px" fontWeight="500" color="#0C7650">Skin</Text>
            //     <Text fontSize="16px" fontWeight="600">Lesion</Text>
            //     <Text fontSize="12px" fontWeight="500" color="#384052" mb="10px">Detected 2 weeks ago</Text>
            //     <Divider />
            //     </Box>
            //     <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            //     <Box>
            //         <Text fontSize="12px" fontWeight="500" color="#0C7650">Skin</Text>
            //         <Text fontSize="16px" fontWeight="600">Lesion</Text>
            //         <Text fontSize="12px" fontWeight="500" color="#384052">Detected 2 weeks ago</Text>
            //     </Box>
            //     <Box display="flex" alignItems="center" bg="#F04438" p="4px" px="6px" borderRadius="full">
            //         <Image w="12px" h="12px" src="/assets/imgs/error-white.png" alt="Critical" />
            //         <Text as="span" color="white" fontSize="10px" ml="2px">Critical</Text>
            //     </Box>
            // </Box>

            // </>

            <>
                {patienData && patienData.physicalexam.length> 0 ? (patienData.physicalexam.map((item, index) => (
                    <Box key={index} mb="10px">
                        <Text fontSize="12px" fontWeight="500" color="#0C7650">{item?.type}</Text>
                        {item.physicalexam.map((subItem, subIndex) => (
                            <Text key={subIndex} fontSize="16px" fontWeight="600">{subItem.value}</Text>
                        ))}
                        <Text fontSize="12px" fontWeight="500" color="#384052" mb="10px">{item.DATE ? formatDate(item.DATE) : "0"}</Text>
                        <Divider />
                    </Box>
                ))): <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
              >
                <Box textAlign="left" fontSize="16px" fontWeight="600">
                  <Image
                    w="26px"
                    opacity="0.5"
                    src="/assets/imgs/physical-exam.png"
                  />
                </Box>
                <Text textAlign="left" fontSize="12px" fontWeight="500">
                  No Data available
                </Text>
              </Box>
                }
            </>

        }
        /> */}

        {/* Doctor Assesment */}
        {/* <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        cardHeader="doctors assessment"
        bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
        cardHeaderIcon="/assets/imgs/doctor-tool.png"
        cardBody={
        <> */}
        {/* {patienData && patienData.treatmentplan.length > 0 ? (
            <Flex display="flex" flexDirection="column" justifyContent="space-between" gap="50px">
            <Box>
              <UnorderedList>
                <ListItem fontSize="14px" fontWeight="600">
                    {patienData.treatmentplan.diagnosis}
                    </ListItem>
                <ListItem fontSize="14px" fontWeight="600">
                    {patienData.treatmentplan.plan}

                    </ListItem>
              </UnorderedList>
            </Box>

          <Spacer />

            <Box borderTop="1px solid #E6E7E9" py="10px" display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Image w="40px" borderRadius="full" src="/assets/imgs/no-image.png" mr="3" />
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Text fontSize="12px" fontWeight="500" color="#384052">General physician</Text>
                  <Text fontSize="14px" fontWeight="600">Dr. Mahroos Ali</Text>
                </Box>
              </Box>
              <Text fontSize="12px" fontWeight="500" color="#384052">10th Jan, 2023</Text>
            </Box>

          </Flex>

        ) : (
            <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="10px"
            alignItems="center"
          >
            <Box textAlign="left" fontSize="16px" fontWeight="600">
              <Image
                w="26px"
                opacity="0.5"
                src="/assets/imgs/doctor-tool.png"
              />
            </Box>
            <Text textAlign="left" fontSize="12px" fontWeight="500">
              No Data available
            </Text>
          </Box>
        )} */}
        {/* {<Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="10px"
            alignItems="center"
          >
            <Box textAlign="left" fontSize="16px" fontWeight="600">
              <Image
                w="26px"
                opacity="0.5"
                src="/assets/imgs/doctor-tool.png"
              />
            </Box>
            <Text textAlign="left" fontSize="12px" fontWeight="500">
              No Data available
            </Text>
          </Box>}
            
          </>}/> */}
          
        {/* Current medication */}
        <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "120px" }}
        cardHeader="current medications"
        cardHeaderIcon="/assets/imgs/medication.png"
        cardBody={
            // <>
            // <Box
            //     display="flex"
            //     flexDirection="column"
            //     justifyContent="space-between"
            //     gap="50px"
            // >
            //     <Box>
            //     <UnorderedList>
            //         <ListItem fontSize="14px" fontWeight="600">
            //         Paracetamol
            //         </ListItem>
            //         <ListItem fontSize="14px" fontWeight="600">
            //         Ibuprofen
            //         </ListItem>
            //         <ListItem fontSize="14px" fontWeight="600">
            //         Vitamin D
            //         </ListItem>
            //     </UnorderedList>
            //     </Box>
            // </Box>
            // </>

            <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="50px"
            >
                <Box>
                        {patienData && patienData.medications.length> 0 ?
                        (patienData.medications.map((step, index) => (
                          <Box mb="0.75rem" key={index}>
                          <Text fontSize="14px" fontWeight="600">
                            {step.medicinename? <strong>●  {step.medicinename}</strong> :""}
                                
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                            {step.chemicalcomposition ? `${step.chemicalcomposition},  ${step.dosage}` : ""}
                            </Text>
                            </Box>
                        ))): ( <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/treatment-plan.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>)}
                </Box>
            </Box>
        </>

        }/>

        {/* Treatment Team */}
        <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
        cardHeader="Treatment Team"
        cardHeaderIcon="/assets/imgs/treatment-plan.png"
        cardBody={
            // <>
            // <Box>
            //     <Box
            //     borderBottom="1px solid #E6E7E9"
            //     py="0.875rem"
            //     display="flex"
            //     flexDir="column"
            //     gap="0.5rem"
            //     >
            //         <Text fontSize="16px" fontWeight="600">
            //         Mother
            //         </Text>
            //         <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">Plan: Basic metabolic panel, Magnesium</Box>
            //         <Text fontSize="12px" fontWeight="500" color="#384052">
            //         01/01/1950
            //         </Text>
            //     </Box>

            //     <Box
            //     borderBottom="1px solid #E6E7E9"
            //     py="0.875rem"
            //     display="flex"
            //     flexDir="column"
            //     gap="0.5rem"
            //     >
            //         <Text fontSize="16px" fontWeight="600">
            //         Mother
            //         </Text>
            //         <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">Plan: Basic metabolic panel, Magnesium</Box>
            //         <Text fontSize="12px" fontWeight="500" color="#384052">
            //         01/01/1950
            //         </Text>
            //     </Box>
            // </Box>
            // </>

            <>
            <Box>
                {patienData && patienData.treatmentteam.length>0 ? 
                (patienData.treatmentteam.map((item, index) => (
                    <Box key={index} borderBottom="1px solid #E6E7E9" py="0.875rem" display="flex" flexDir="column" gap="0.5rem">
                        <Text fontSize="16px" fontWeight="600">
                            {item.doctorname}
                        </Text>
                        <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">
                                Plan: {item.entitytype}
                            </Box>
                        {/* {item.entitytype === "Prescribed Medicine" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">
                                Plan: Basic metabolic panel, Magnesium
                            </Box>
                        )}
                        {item.entitytype === "Surgery" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #FF6B6B" fontSize="0.875rem" fontWeight="500">
                                Plan: Surgery Scheduled
                            </Box>
                        )}
                        {item.entitytype === "labs" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #6C5DD3" fontSize="0.875rem" fontWeight="500">
                                Plan: Lab Test Scheduled
                            </Box>
                        )} */}
                        <Text fontSize="12px" fontWeight="500" color="#384052">
                            {formatDate(item.date)}
                        </Text>
                    </Box>
                ))): (
                    <Box
  display="flex"
  flexDirection="column"
  justifyContent="center"
  gap="10px"
  alignItems="center"
>
  <Box textAlign="left" fontSize="16px" fontWeight="600">
    <Image
      w="26px"
      opacity="0.5"
      src="/assets/imgs/treatment-plan.png"
    />
  </Box>
  <Text textAlign="left" fontSize="12px" fontWeight="500">
    No Data available
  </Text>
</Box>
                )}
            </Box>
        </>

        }/>

        {/* Past Medical HIstory */}
        <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
        cardHeader="Past Medical History"
        cardHeaderIcon="/assets/imgs/medical-history.png"
        cardBody={
            // <>
            // <Box>
            //     <VStack align="stretch">
            //     <Stack direction="column" align="flex-start" gap="0">
            //         <Box display="flex" alignItems="center" gap="5px">
            //         <Circle
            //             size="12px"
            //             bg="white"
            //             border="2px solid gray"
            //             borderRadius="full"
            //             color="black"
            //         />
            //         <Text fontSize="16px" fontWeight="600">
            //             A-fib (CMS/HCC)
            //         </Text>
            //         </Box>
            //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
            //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
            //         <Text fontSize="12px" ml="7px" fontWeight="600">
            //             01/01/2021
            //         </Text>
            //         </Box>

            //         <Box display="flex" alignItems="center" gap="5px">
            //         <Circle
            //             size="12px"
            //             bg="white"
            //             border="2px solid gray"
            //             borderRadius="full"
            //             color="black"
            //         />
            //         <Text fontSize="16px" fontWeight="600">
            //             Arrhythmia
            //         </Text>
            //         </Box>
            //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
            //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
            //         <Text fontSize="12px" ml="7px" fontWeight="600">
            //             01/01/2021
            //         </Text>
            //         </Box>

            //         <Box display="flex" alignItems="center" gap="5px">
            //         <Circle
            //             size="12px"
            //             bg="white"
            //             border="2px solid gray"
            //             borderRadius="full"
            //             color="black"
            //         />
            //         <Text fontSize="16px" fontWeight="600">
            //         CHF (congestive heart failure)(CMS/HCC)
            //         </Text>
            //         </Box>
            //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
            //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
            //         <Text fontSize="12px" ml="7px" fontWeight="600">
            //             01/01/2021
            //         </Text>
            //         </Box>
            //     </Stack>
            //     </VStack>
            // </Box>
            // </>

            <>
                <Box>
                    <VStack align="stretch">
                        {patienData && patienData.medicalhistory.length > 0 ? (
                            patienData.medicalhistory.map((elem, index) => (
                            <Stack key={index} direction="column" align="flex-start" gap="0">
                                <Box display="flex" alignItems="center" gap="5px">
                                    <Circle
                                        size="12px"
                                        bg="white"
                                        border="2px solid gray"
                                        borderRadius="full"
                                        color="black"
                                    />
                                    <Text fontSize="16px" fontWeight="600">
                                        {elem.condition}
                                    </Text>
                                </Box>
                                <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                    <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                        {formatDate(elem.conditionidentifieddate)}
                                    </Text>
                                </Box>
                            </Stack>
                        ))): ( <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/medical-history.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>)
                    }
                    </VStack>
                </Box>
            </>

        }/>

        {/* Past Surgical HIstory */}
        <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
        cardHeader="Past Surgical History"
        cardHeaderIcon="/assets/imgs/surgeries.png"
        cardBody={
            // <>
            // <Box>
            // <VStack align="stretch">
            //     <Stack direction="column" align="flex-start" gap="0">
            //         <Box display="flex" alignItems="center" gap="5px">
            //         <Circle
            //             size="12px"
            //             bg="white"
            //             border="2px solid gray"
            //             borderRadius="full"
            //             color="black"
            //         />
            //         <Text fontSize="16px" fontWeight="600">
            //          Cardiac catheterization
            //         </Text>
            //         </Box>
            //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
            //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
            //         <Text fontSize="12px" ml="7px" fontWeight="600">
            //             01/01/2021
            //         </Text>
            //         </Box>

            //         <Box display="flex" alignItems="center" gap="5px">
            //         <Circle
            //             size="12px"
            //             bg="white"
            //             border="2px solid gray"
            //             borderRadius="full"
            //             color="black"
            //         />
            //         <Text fontSize="16px" fontWeight="600">
            //          Cardiac surgery
            //         </Text>
            //         </Box>
            //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
            //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
            //         <Text fontSize="12px" ml="7px" fontWeight="600">
            //             01/01/2021
            //         </Text>
            //         </Box>

            //         <Box display="flex" alignItems="center" gap="5px">
            //         <Circle
            //             size="12px"
            //             bg="white"
            //             border="2px solid gray"
            //             borderRadius="full"
            //             color="black"
            //         />
            //         <Text fontSize="16px" fontWeight="600">
            //         Coronary
            //         </Text>
            //         </Box>
            //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
            //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
            //         <Text fontSize="12px" ml="7px" fontWeight="600">
            //             01/01/2021
            //         </Text>
            //         </Box>
            //     </Stack>
            //     </VStack>
            // </Box>
            // </>

            <>
            <Box>
                <VStack align="stretch">
                    {patienData && patienData.surgicalhistory.length > 0 ?
                    (patienData.surgicalhistory.map((surgery, index) => (
                        <Stack key={index} direction="column" align="flex-start" gap="0">
                            <Box display="flex" alignItems="center" gap="5px">
                                <Circle
                                    size="12px"
                                    bg="white"
                                    border="2px solid gray"
                                    borderRadius="full"
                                    color="black"
                                />
                                <Text fontSize="16px" fontWeight="600">
                                    {surgery.proceduretitle}
                                </Text>
                            </Box>
                            <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                                <Text fontSize="12px" ml="7px" fontWeight="600">
                                    {formatDate(surgery.proceduredate)}
                                </Text>
                            </Box>
                        </Stack>
                    ))) : ( <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/surgeries.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>)}
                </VStack>
            </Box>
        </>

        }/>

        {/* Family History */}
        <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
        cardHeader="family history"
        cardHeaderIcon="/assets/imgs/family-history.png"
        cardBody={
            // <>
            // <Box display="flex" flexDirection="column" gap="10px">
            //     <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            //     <Box>
            //         <Text fontSize="16px" fontWeight="600">
            //         Mother
            //         </Text>
            //         <Text fontSize="12px" fontWeight="500" color="#384052">
            //         01/01/1950
            //         </Text>
            //     </Box>
            //     <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
            //         <Text as="span" color="#384052" fontSize="10px" ml="2px">Alive</Text>
            //     </Box>
            //     </Box>
            //     <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            //     <Box>
            //         <Text fontSize="16px" fontWeight="600">
            //         Father
            //         </Text>
            //         <Text fontSize="12px" fontWeight="500" color="#384052">
            //         05/10/1948
            //         </Text>
            //     </Box>
            //     <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
            //         <Text as="span" color="#384052" fontSize="10px" ml="2px">Deceased</Text>
            //     </Box>
            //     </Box>
            // </Box>
            // </>

            <>
            <Box display="flex" flexDirection="column" gap="10px">
                {patienData?.familyhistory?.length> 0 ? 
                (patienData.familyhistory.map((family, index) => (
                    <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box>
                        <Box display="flex" w='fit-content' alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                            <Text as="span" fontSize="10px" ml="2px">
                                {
                                  family.isdeceased == 0 ? (
                                    <Text color='rgb(0,151,0)'> Alive</Text>
                                  ) : (
                                    <Text color='rgb(254, 51, 51)'> Deceased</Text>
                                  )
                                }
                            </Text>
                        </Box>
                            <Text fontSize="16px" fontWeight="600">
                                {family.relation}
                            </Text>
                            <Text fontSize="12px" fontWeight="500" color="#384052">
                                {formatDate(family.dob)}
                            </Text>
                            <Box display="flex" alignItems="center" flexWrap='wrap'>
          {family.medicalcondition?.map((condition, index) => (
      <Box as="p" display="flex" alignItems="center" flexDir="row" fontSize="10px" key={index} title={family.medicalcondition.map(condition => condition.condition).join(", ")}>
        <Box as="span" fontSize="8px" mr="3px">● </Box> {condition.condition}
        {index < family.medicalcondition.length - 1 && ", "}
      </Box>
    ))}

        </Box>
                        </Box>
                        <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                            {/* <Text as="span" fontSize="10px" ml="2px">
                                {
                                  family.isdeceased == 0 ? (
                                    <Text color='rgb(0,151,0)'> Alive</Text>
                                  ) : (
                                    <Text color='rgb(254, 51, 51)'> Deceased</Text>
                                  )
                                }
                            </Text> */}
                        </Box>
                    </Box>
                ))) : (<Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/family-history.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>)}
            </Box>
        </>

        } />

        {/* Social History */}
        <GrayCard
        hasFooter={false}
        w={{lg: "100%", xl: "calc(50% - 10px)"}}
        minW={{lg: "100%", xl: "calc(50% - 10px)"}}
        maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
        bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
        cardHeader="social History"
        cardHeaderIcon="/assets/imgs/social-history-bar.png"
        cardBody={
        // <>
        //     <Box>
        //    <Box display="flex" flexDirection="column" justifyContent="space-around" gap="10px">
        //      <Box>
        //        <Text fontSize="16px" fontWeight="600">Alchohol</Text>
        //          <Box display="flex" flexDirection="row" alignItems="center">
        //            <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
        //            <Image w="13px" h="9px" mx="3px" src="/assets/imgs/red-chart.png" />
        //            <Text fontSize="12px" fontWeight="500" color="#F04438">2</Text>
        //          </Box>
        //      </Box>
        //      <Divider size="1px" colorScheme="#E6E7E9" />
        //      <Box >
        //        <Text fontSize="16px" fontWeight="600">Alchohol</Text>
        //          <Box display="flex" flexDirection="row" alignItems="center">
        //            <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
        //            <Image w="16px" h="14px" mx="3px" src="/assets/imgs/green-chart.png" />
        //            <Text fontSize="12px" fontWeight="500" color="#0C7650">2</Text>
        //          </Box>
        //      </Box>
        //  </Box>
        //    </Box>

        // </>

        <>
        <Box>
            {patienData && patienData.socialhistory.length > 0 ?  (patienData.socialhistory.map((history, index) => {
                const totalYears = calculateYearsDifference(history.fromdate, history.todate);
                var consumptionPreYear = (history.frequency / 7) * totalYears;
                const finalCalculation = consumptionPreYear.toFixed(2);
                return (
                <Box key={index} display="flex" flexDirection="column" justifyContent="space-around" gap="10px">
                    <Box>
                        <Text fontSize="16px" fontWeight="600">{history.socialhistory}</Text>
                        <Box display="flex" flexDirection="row" alignItems="center">
                        <Text fontSize="12px" fontWeight="bold" color="#384052">
                          {history.iscurrent == 1 ? " Current-" : 
                          history.iscurrent == 0 ? " Never-" : 
                          history.iscurrent == 2 ? " Former-" :"" }
                      </Text>
                      <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                          {history.frequency && history.frequency !== 0 ? `${history.frequency} 
                              ${history.socialhistory && history.socialhistory == "Smoking" ? "Packs": 
                                                      history.socialhistory == "Alcohol" ? "Drinks" :
                                                      history.socialhistory == "Smokeless" ? "Cans" :
                                                      history.socialhistory == "Illicit Drugs" ? "Times" 
                                                      : "Packs"} 
                              Per ${history.frequencytype}   
                              x ${totalYears == 1 ? totalYears + " year"  :
                                  totalYears > 1 ? totalYears + " years" : 
                                                                  "less than a year"}  
                            ` 
                              : ''}
                              {history.iscurrent == 2 && (
              <>
                {` Until ${history.todate ? formatDate_yyyy(history.todate) : ""}`}
              </>
            )}
                      </Text>
                            {/* <Text fontSize="12px" fontWeight="500" color="#384052">
                              Average {history.frequency}
                            </Text>
                            {history.frequency > 0 ? (
                                <Image w="13px" h="9px" mx="3px" src="/assets/imgs/red-chart.png" />
                            ) : (
                                <Image w="16px" h="14px" mx="3px" src="/assets/imgs/green-chart.png" />
                            )} */}
                            {/* <Text fontSize="12px" fontWeight="500" color={history.frequency > 0 ? "#F04438" : "#0C7650"}>{history.frequency}</Text> */}
                        </Box>
                    </Box>
                    <Divider size="1px" colorScheme="#E6E7E9" />
                </Box>
            )})) : ( <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
              >
                <Box textAlign="left" fontSize="16px" fontWeight="600">
                  <Image
                    w="26px"
                    opacity="0.5"
                    src="/assets/imgs/social-history-bar.png"
                  />
                </Box>
                <Text textAlign="left" fontSize="12px" fontWeight="500">
                  No Data available
                </Text>
              </Box>    )}
        </Box>
    </>

    }/>

        

        {/* Vitals */}
        <Box
        display="flex"
        justifyContent="space-between"
        gap="20px"
        w="100%"
        >
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
        >
            <GrayCard
            hasFooter={false}
            bodyStyle={{ p: "0" }}
            cardHeader="vitals"
            cardHeaderIcon="/assets/imgs/heart.png"
            cardBody={
                // <>
                // <Box width="100%" overflowX="auto">
                //     <SimpleGrid minChildWidth="8.75rem">
                //     <Box borderRight="1px solid #E6E7E9" p={4}>
                //         <Text
                //         fontSize="12px"
                //         fontWeight="500"
                //         color="#6A707D"
                //         >
                //         Body temperature
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="22px"
                //         fontWeight="600"
                //         >
                //         98.6
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="15px"
                //         pl={"5px"}
                //         fontWeight="400"
                //         >
                //         °F
                //         </Text>
                //         <Box>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#6A707D"
                //             >
                //             May 15, 2024
                //             </Text>
                //         </Box>
                //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                //             OK
                //             </Text>
                //         </Box>
                //     </Box>
                //     <Box borderRight="1px solid #E6E7E9" p={4}>
                //         <Text
                //         fontSize="12px"
                //         fontWeight="500"
                //         color="#6A707D"
                //         >
                //         Body temperature
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="22px"
                //         fontWeight="600"
                //         >
                //         98.6
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="15px"
                //         pl={"5px"}
                //         fontWeight="400"
                //         >
                //         °F
                //         </Text>
                //         <Box>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#6A707D"
                //             >
                //             May 15, 2024
                //             </Text>
                //         </Box>
                //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                //             OK
                //             </Text>
                //         </Box>
                //     </Box>
                //     <Box borderRight="1px solid #E6E7E9" p={4}>
                //         <Text
                //         fontSize="12px"
                //         fontWeight="500"
                //         color="#6A707D"
                //         >
                //         Body temperature
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="22px"
                //         fontWeight="600"
                //         >
                //         98.6
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="15px"
                //         pl={"5px"}
                //         fontWeight="400"
                //         >
                //         °F
                //         </Text>
                //         <Box>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#6A707D"
                //             >
                //             May 15, 2024
                //             </Text>
                //         </Box>
                //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                //             OK
                //             </Text>
                //         </Box>
                //     </Box>
                //     <Box borderRight="1px solid #E6E7E9" p={4}>
                //         <Text
                //         fontSize="12px"
                //         fontWeight="500"
                //         color="#6A707D"
                //         >
                //         Body temperature
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="22px"
                //         fontWeight="600"
                //         >
                //         98.6
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="15px"
                //         pl={"5px"}
                //         fontWeight="400"
                //         >
                //         °F
                //         </Text>
                //         <Box>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#6A707D"
                //             >
                //             May 15, 2024
                //             </Text>
                //         </Box>
                //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                //             OK
                //             </Text>
                //         </Box>
                //     </Box>
                //     <Box borderRight="1px solid #E6E7E9" p={4}>
                //         <Text
                //         fontSize="12px"
                //         fontWeight="500"
                //         color="#6A707D"
                //         >
                //         Body temperature
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="22px"
                //         fontWeight="600"
                //         >
                //         98.6
                //         </Text>
                //         <Text
                //         display="inline-block"
                //         fontSize="15px"
                //         pl={"5px"}
                //         fontWeight="400"
                //         >
                //         °F
                //         </Text>
                //         <Box>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#6A707D"
                //             >
                //             May 15, 2024
                //             </Text>
                //         </Box>
                //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                //             OK
                //             </Text>
                //         </Box>
                //     </Box>
                //     </SimpleGrid>
                // </Box>
                // </>

                <>
                <Box width="100%" overflowX="auto">
                    <SimpleGrid minChildWidth="8.75rem">
                        {patienData && patienData.vitals.length>0 ?
                        (patienData.vitals.map((vital, index) => (
                            <Box key={index} borderRight="1px solid #E6E7E9" p={4}>
                                <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                    {vital.vitalname}
                                </Text>
                                <Text display="inline-block" fontSize="22px" fontWeight="600">
                                    {vital.vitalvalue}
                                </Text>
                                <Text display="inline-block" fontSize="15px" pl={"5px"} fontWeight="400">
                                    {vital.vitalunit}
                                </Text>
                                <Box>
                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                        {formatDate(vital.vitaladdeddate)}
                                    </Text>
                                </Box>
                                <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                                    {/* <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                    <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                        {vital.status}
                                    </Text> */}
                                    {
vital.status === 'High' ? (
  <>
  <Image w="12px" h="auto" color='#F04438' src="/assets/imgs/arrow_upward.png" />
  <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#F04438'>{vital.status}</Text>
  </>
) : vital.status === 'Normal' ? (
  <>
  <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
  <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
  </>
) : vital.status === 'normal' ? (
  <>
  <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
  <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
  </>
) : vital.status === 'Low' ? (
  <>
  <Image w="12px" h="auto" color='#D46F11' src="/assets/svgs/arrow_upward.svg" />
  <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#D46F11'>{vital.status}</Text>
  </>
): ('')
}
                                </Box>
                            </Box>
                        ))): (
                          'no records found'
                        )}
                    </SimpleGrid>
                </Box>
            </>


            }/>
        </Box>
        </Box>
        
        {/* Investigations */}
        <Box
        display="flex"
        justifyContent="space-between"
        gap="20px"
        w="100%"
        >
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
        >
            <GrayCard
            hasFooter={false}
            // bodyStyle={{ p: "0" }}
            bodyStyle={{overflow: "hidden auto", maxH: "300px",p:0 }}
            cardHeader="Investigations"
            cardHeaderIcon="/assets/imgs/investigation.png"
            cardBody={
                <>

                <Box width="100%" overflowX="auto">
                    <SimpleGrid minChildWidth="8.7rem"  >
                {patienData && patienData.investigations.length>0 ? (
                    patienData.investigations.map((investigation, index) => (
                    <Box borderWidth="1px" p={4} key={index}>
                        <Text
                        fontSize="12px"
                        fontWeight="500"
                        color="#6A707D"
                        >
                        {/* White blood cells */}
                        {investigation.eventtitle ? (investigation.eventtitle): "Null"}
                        </Text>
                        <Text
                        display="inline-block"
                        fontSize={{lg:"18px", xl:"15px"}}
                        fontWeight="600"
                        className='textOverflow'
                        maxW="130px"
                        title={investigation?.actualresultvalue}
                        >
                        {investigation.actualresultvalue
                        ? investigation.actualresultvalue
                        : "-"}
                        </Text>
                        {investigation.resultuom && 
                        <Text display="inline-block" fontSize="15px" fontWeight="400">
                        ({investigation.resultuom? investigation.resultuom :""})
                        </Text>
                        }
                        <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        >
                        <Box>
                            <Text
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                            >
                            {/* May 15, 2024 */}
                            {formatDate(investigation.verifieddate)}
                            </Text>
                        </Box>
                        {investigation.status === 'low' && investigation.trendColor === 'red' ? (
                  <Box display="flex" alignItems="center" transform="scaleY(-1)">
                    <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                
                  </Box>
                ) : investigation.status === 'high' && investigation.trendColor === 'red' ? (
                  <Box display="flex" alignItems="center">
                    <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                    
                  </Box>
                ) : investigation.status === 'low' && investigation.trendColor === 'green' ? (
                  <Box display="flex" alignItems="center">
                    <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                
                  </Box>
                ) : investigation.status === 'high' && investigation.trendColor === 'green' ? (
                  <Box display="flex" alignItems="center" transform="scaleY(-1)">
                    <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                    
                  </Box>
                ) : null}
                        </Box>
                    </Box>

                    
                    ))
                ): ( 
                  <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        mt='1.5rem'
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/investigation.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                  // Previous code
                // <Flex
                //     direction="row"
                //     justify="space-around"
                //     h="full"
                //     w="100%"
                //     flexWrap="wrap"
                //     >
                //       {/* <Image src='/assets/imgs/investigation.png'/> */}
                //     <Text>No Data Available</Text>
                //     {/* {dataEntries.map((index) => (
                //         <SimpleTable NoDataHeader={index} />
                //     ))} */}
                //   </Flex>
                  )
                }
                    </SimpleGrid>
            </Box>  
                </>
            }
            />
        </Box>
        </Box>


        {/* Lab and Imaging */}
        <Box
        display="flex"
        justifyContent="space-between"
        gap="20px"
        w="100%"
        flexWrap={{lg: "wrap", xl: "nowrap"}}
        >
        <GrayCard
            hasFooter={false}
            w={{lg: "100%", xl: "calc(50% - 10px)"}}
            minW={{lg: "100%", xl: "calc(50% - 10px)"}}
            maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="labs"
            cardHeaderIcon="/assets/imgs/biotech.png"
            cardBody={
            // <>
            //     <Box>
            //     <Box
            //         display="flex"
            //         flexDirection="column"
            //         justifyContent="space-around"
            //         gap="20px"
            //     >
            //         <Box>
            //         <Box
            //             mb="3px"
            //             display="flex"
            //             flexDirection="row"
            //             justifyContent="space-between"
            //             alignItems="center"
            //         >
            //             <Text
            //             fontSize="16px"
            //             fontWeight="600"
            //             color="#061027"
            //             >
            //             Lab Event Title
            //             </Text>
            //             <Text
            //             fontSize="12px"
            //             fontWeight="500"
            //             color="#384052"
            //             >
            //             Verified Date
            //             </Text>
            //         </Box>
            //         <Text fontSize="14px" fontWeight="500">
            //             Lab Long Text
            //         </Text>
            //         </Box>
            //     </Box>
            //     </Box>
            // </>

            <>
            <Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    gap="20px"
                >
                    {patienData && patienData.labs.length>0 ? 
                    (patienData.labs.map((lab, index) => (
                        <Box key={index}>
                            <Box
                                mb="3px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="#061027"
                                >
                                    {lab.reportname}
                                </Text>
                                <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#384052"
                                >
                                    {/* {new Date(lab.verifieddate).toLocaleDateString()} */}
                                    {formatDate(lab.verifieddate)}
                                </Text>
                                <Image
                                cursor='pointer'
                              w="1.125rem"
                              // h="auto"
                              src="/assets/imgs/show-pass.png"
                              onClick={() => {window.open(
                                lab.imagepath + lab.documenturl, '_blank')}
                              }
                              />
                            </Box>
                            <Text fontSize="14px" isTruncated title={lab.reportdescription} fontWeight="500">
                                {lab.reportdescription}
                            </Text>
                    <Divider mt='10px' size="1px" colorScheme="#E6E7E9" />
                        </Box>
                    ))): ( <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/biotech.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>)}
                </Box>
            </Box>
        </>

            }/>

        <GrayCard
            hasFooter={false}
            w={{lg: "100%", xl: "calc(50% - 10px)"}}
            minW={{lg: "100%", xl: "calc(50% - 10px)"}}
            maxW={{lg: "100%", xl: "calc(50% - 10px)"}}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="IMAGING"
            cardHeaderIcon="/assets/imgs/hand_bones.png"
            cardBody={
            // <>
            //     <Box>
            //     <Box
            //         display="flex"
            //         flexDirection="column"
            //         justifyContent="space-around"
            //         gap="20px"
            //     >
            //         <Box>
            //         <Box
            //             mb="3px"
            //             display="flex"
            //             flexDirection="row"
            //             justifyContent="space-between"
            //             alignItems="center"
            //         >
            //             <Text
            //             fontSize="16px"
            //             fontWeight="600"
            //             color="#061027"
            //             >
            //             Imaging Event Title
            //             </Text>
            //             <Text
            //             fontSize="12px"
            //             fontWeight="500"
            //             color="#384052"
            //             >
            //             Verified Date
            //             </Text>
            //         </Box>
            //         <Text fontSize="14px" fontWeight="500">
            //             Imaging Long Text
            //         </Text>
            //         </Box>
            //     </Box>
            //     </Box>
            // </>

            <>
            <Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    gap="20px"
                >
                    {patienData && patienData.imaging.length>0 ? 
                    (patienData.imaging.map((image, index) => (
                        <Box key={index}>
                            <Box
                                mb="3px"
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="600"
                                    color="#061027"
                                >
                                    {image.reportname}
                                </Text>
                                <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#384052"
                                >
                                    {/* {new Date(image.verifieddate).toLocaleDateString()} */}
                                    {formatDate(image.verifieddate)}
                                </Text>
                                <Image
                              w="1.125rem"
                              cursor='pointer'
                              // h="auto"
                              src="/assets/imgs/show-pass.png"
                              onClick={() => {window.open(
                                image.imagepath + image.documenturl, '_blank')}
                              }
                              />
                            </Box>
                            <Text fontSize="14px" title={image.reportdescription} isTruncated fontWeight="500">
                                {image.reportdescription}
                            </Text>

                            <Box
                                                      // bg="#F9FAFB"
                                                      // borderLeft="2px solid #14C585"
                                                      p="0.5rem"
                                                    >
                                                      <UnorderedList m='0' pl='10px'>

                                                      <ListItem m='0' pl='0'>
                                                      <Text
                                                        // fontSize="0.875rem"
                                                        fontSize="1rem"
                                                        fontWeight="600"
                                                        color="#384052"
                                                        >
                                                        Normal Results
                                                      </Text>
                                                      </ListItem>
                                                        </UnorderedList>
                                                      <Text
                                                        fontSize="0.875rem"
                                                        fontWeight="500"
                                                        color="#384052"
                                                        pl='10px'
                                                      >
                                                        {image.normalresults}
                                                      </Text>
                                                    </Box>

                            <Box
                                                      // bg="#F9FAFB"
                                                      // borderLeft="2px solid #14C585"
                                                      p="0.5rem"
                                                    >
                                                      <UnorderedList m='0' pl='10px'>

                                                      <ListItem m='0' pl='0'>
                                                      <Text
                                                        // fontSize="0.875rem"
                                                        fontSize="1rem"
                                                        fontWeight="600"
                                                        color="#384052"
                                                        >
                                                        Abnormal Results
                                                      </Text>
                                                      </ListItem>
                                                        </UnorderedList>
                                                      <Text
                                                        fontSize="0.875rem"
                                                        fontWeight="500"
                                                        color="#384052"
                                                        pl='10px'
                                                      >
                                                        {image.abnormalresults}
                                                      </Text>
                                                    </Box>
                            <Box
                                                      // bg="#F9FAFB"
                                                      // borderLeft="2px solid #14C585"
                                                      p="0.5rem"
                                                    >
                                                      <UnorderedList m='0' pl='10px'>

                                                      <ListItem m='0' pl='0'>
                                                      <Text
                                                        // fontSize="0.875rem"
                                                        fontSize="1rem"
                                                        fontWeight="600"
                                                        color="#384052"
                                                        >
                                                        Report Interpretition
                                                      </Text>
                                                      </ListItem>
                                                        </UnorderedList>
                                                      <Text
                                                        fontSize="0.875rem"
                                                        fontWeight="500"
                                                        color="#384052"
                                                        pl='10px'
                                                      >
                                                        {image.reportinterpretation}
                                                      </Text>
                                                    </Box>
                    <Divider mt='10px' size="1px" colorScheme="#E6E7E9" />
                        </Box>
                    ))): ( <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/hand_bones.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>)}
                </Box>
            </Box>
        </>

            }/>
        </Box>

      </Box>
    ):(
    <Box display={{base: "block", lg: "none"}} w="100%">
      <MobileSecondaryNav
        header="Overview"
        handlebackarrow={backFunction}
      />

    <Box w="100%" display='flex' flexWrap="wrap" gap="1rem" p="1rem" h="calc(100vh - 120px)" overflow="hidden auto">
            {/* Demographics */}
            <GrayCard
            //hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            cardHeader="Demographics"
            cardHeaderIcon="/assets/imgs/measure.png"
            bodyStyle={{ p: "0" }}
            cardBody={
                <>
                <Flex direction="row" justify="space-around" h="full">
                    <Box p="10px" px="20px" display="flex" flexDir="column" h="100%" justifyContent="center" w="100%">
                    <Box>
                        <Box
                            as="p"
                            display="inline-block"
                            fontSize="20px"
                            fontWeight="600"
                        >
                            {/* 170 */}
                            {patienData && patienData.demographics.height ?(  <Text fontWeight="600">{patienData.demographics.height}</Text>  
                            ): ('0')}
                        </Box>
                        <Box
                            display="inline-block"
                            as="span"
                            fontSize="12px"
                            fontWeight="400"
                            pl={"5px"}
                        >
                            {patienData && patienData.demographics.heightunit ? (<Text fontWeight="600">{patienData.demographics.heightunit}</Text>) : ('cms') }
                        </Box>
                    </Box>
                    <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                        Height
                    </Box>
                    </Box>

                    <Box
                    p="10px" px="20px" display="flex" flexDir="column" h="100%" justifyContent="center" w="100%"
                    borderRight="1px solid #E6E7E9"
                    borderLeft="1px solid #E6E7E9"
                    _last={{ borderRight: 0 }}
                    >
                    <Box>
                    <Box
                        as="p"
                        display="inline-block"
                        fontSize="20px"
                        fontWeight="600"
                    >
                        {/* 70 */}
                        {/* {patienData && patienData.demographics &&(
                            <div>
                                <p> {patienData.demographics.weight}</p>
                            </div>
                        )} */}
                        {patienData && patienData.demographics.weight ?(  <Text fontWeight="600">{patienData.demographics.weight}</Text>  
                            ): '0'}
                    </Box>

                    <Box
                        display="inline-block"
                        as="p"
                        fontSize="15px"
                        fontWeight="400"
                        pl={"5px"}
                        >
                        {patienData && patienData.demographics.weightunit ? (<Text>{patienData.demographics.weightunit}</Text>) : ('Kg') }
                    </Box>
                    </Box>
                    <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                        Weight
                    </Box>
                    </Box>

                    <Box
                    p="10px" px="20px" display="flex" flexDir="column" h="100%" justifyContent="center" w="100%"
                    borderRight="1px solid #E6E7E9"
                    borderLeft="1px solid #E6E7E9"
                    _last={{ borderRight: 0 }}
                    >
                    <Box>
                    <Box
                        as="p"
                        display="inline-block"
                        fontSize="20px"
                        fontWeight="600"
                    >
                        {/* 70 */}
                        {patienData && patienData.demographics.bmi ?(  <Text fontWeight="600">{patienData.demographics.bmi}</Text>  
                            ): '0'}
                    </Box>

                    
                    </Box>
                    <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                        BMI
                    </Box>
                    </Box>
                </Flex>
                </>
            }
            cardFooterText="Update Demographics"
            cardFooterClickEvent={
              ()=>openVitalBMIform(patienData && patienData.demographics?.weightunit, patienData && patienData.demographics?.weight ,patienData && patienData.demographics?.height, )
            }
            />

            

            {/* Treatment Team */}
            <GrayCard
            hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="Treatment Team"
            cardHeaderIcon="/assets/imgs/treatment-plan.png"
            cardBody={
                // <>
                // <Box>
                //     <Box
                //     borderBottom="1px solid #E6E7E9"
                //     py="0.875rem"
                //     display="flex"
                //     flexDir="column"
                //     gap="0.5rem"
                //     >
                //         <Text fontSize="16px" fontWeight="600">
                //         Mother
                //         </Text>
                //         <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">Plan: Basic metabolic panel, Magnesium</Box>
                //         <Text fontSize="12px" fontWeight="500" color="#384052">
                //         01/01/1950
                //         </Text>
                //     </Box>

                //     <Box
                //     borderBottom="1px solid #E6E7E9"
                //     py="0.875rem"
                //     display="flex"
                //     flexDir="column"
                //     gap="0.5rem"
                //     >
                //         <Text fontSize="16px" fontWeight="600">
                //         Mother
                //         </Text>
                //         <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">Plan: Basic metabolic panel, Magnesium</Box>
                //         <Text fontSize="12px" fontWeight="500" color="#384052">
                //         01/01/1950
                //         </Text>
                //     </Box>
                // </Box>
                // </>

                <>
                <Box>
                    {patienData && patienData.treatmentteam.length>0 ? 
                    (patienData.treatmentteam.map((item, index) => (
                        <Box key={index} borderBottom="1px solid #E6E7E9" py="0.875rem" display="flex" flexDir="column" gap="0.5rem">
                            <Text fontSize="16px" fontWeight="600">
                                {item.doctorname}
                            </Text>
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">
                                    Plan: {item.entitytype}
                                </Box>
                            {/* {item.entitytype === "Prescribed Medicine" && (
                                <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">
                                    Plan: Basic metabolic panel, Magnesium
                                </Box>
                            )}
                            {item.entitytype === "Surgery" && (
                                <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #FF6B6B" fontSize="0.875rem" fontWeight="500">
                                    Plan: Surgery Scheduled
                                </Box>
                            )}
                            {item.entitytype === "labs" && (
                                <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #6C5DD3" fontSize="0.875rem" fontWeight="500">
                                    Plan: Lab Test Scheduled
                                </Box>
                            )} */}
                            <Text fontSize="12px" fontWeight="500" color="#384052">
                                {formatDate(item.date)}
                            </Text>
                        </Box>
                    ))): (
                        <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="10px"
      alignItems="center"
    >
      <Box textAlign="left" fontSize="16px" fontWeight="600">
        <Image
          w="26px"
          opacity="0.5"
          src="/assets/imgs/treatment-plan.png"
        />
      </Box>
      <Text textAlign="left" fontSize="12px" fontWeight="500">
        No Data available
      </Text>
    </Box>
                    )}
                </Box>
            </>

            }/>

            {/* Past Medical HIstory */}
            <GrayCard
            hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="Past Medical History"
            cardHeaderIcon="/assets/imgs/medical-history.png"
            cardBody={
                // <>
                // <Box>
                //     <VStack align="stretch">
                //     <Stack direction="column" align="flex-start" gap="0">
                //         <Box display="flex" alignItems="center" gap="5px">
                //         <Circle
                //             size="12px"
                //             bg="white"
                //             border="2px solid gray"
                //             borderRadius="full"
                //             color="black"
                //         />
                //         <Text fontSize="16px" fontWeight="600">
                //             A-fib (CMS/HCC)
                //         </Text>
                //         </Box>
                //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                //         <Text fontSize="12px" ml="7px" fontWeight="600">
                //             01/01/2021
                //         </Text>
                //         </Box>

                //         <Box display="flex" alignItems="center" gap="5px">
                //         <Circle
                //             size="12px"
                //             bg="white"
                //             border="2px solid gray"
                //             borderRadius="full"
                //             color="black"
                //         />
                //         <Text fontSize="16px" fontWeight="600">
                //             Arrhythmia
                //         </Text>
                //         </Box>
                //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                //         <Text fontSize="12px" ml="7px" fontWeight="600">
                //             01/01/2021
                //         </Text>
                //         </Box>

                //         <Box display="flex" alignItems="center" gap="5px">
                //         <Circle
                //             size="12px"
                //             bg="white"
                //             border="2px solid gray"
                //             borderRadius="full"
                //             color="black"
                //         />
                //         <Text fontSize="16px" fontWeight="600">
                //         CHF (congestive heart failure)(CMS/HCC)
                //         </Text>
                //         </Box>
                //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                //         <Text fontSize="12px" ml="7px" fontWeight="600">
                //             01/01/2021
                //         </Text>
                //         </Box>
                //     </Stack>
                //     </VStack>
                // </Box>
                // </>

                <>
                    <Box>
                        <VStack align="stretch">
                            {patienData && patienData.medicalhistory.length > 0 ? (
                                patienData.medicalhistory.map((elem, index) => (
                                <Stack key={index} direction="column" align="flex-start" gap="0">
                                    <Box display="flex" alignItems="center" gap="5px">
                                        <Circle
                                            size="12px"
                                            bg="white"
                                            border="2px solid gray"
                                            borderRadius="full"
                                            color="black"
                                        />
                                        <Text fontSize="16px" fontWeight="600">
                                            {elem.condition}
                                        </Text>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                        <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                                        <Text fontSize="12px" ml="7px" fontWeight="600">
                                            {formatDate(elem.conditionidentifieddate)}
                                        </Text>
                                    </Box>
                                </Stack>
                            ))): ( <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="10px"
                                alignItems="center"
                              >
                                <Box textAlign="left" fontSize="16px" fontWeight="600">
                                  <Image
                                    w="26px"
                                    opacity="0.5"
                                    src="/assets/imgs/medical-history.png"
                                  />
                                </Box>
                                <Text textAlign="left" fontSize="12px" fontWeight="500">
                                  No Data available
                                </Text>
                              </Box>)
                        }
                        </VStack>
                    </Box>
                </>

            }/>

            {/* Past Surgical HIstory */}
            <GrayCard
            hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="Past Surgical History"
            cardHeaderIcon="/assets/imgs/surgeries.png"
            cardBody={
                // <>
                // <Box>
                // <VStack align="stretch">
                //     <Stack direction="column" align="flex-start" gap="0">
                //         <Box display="flex" alignItems="center" gap="5px">
                //         <Circle
                //             size="12px"
                //             bg="white"
                //             border="2px solid gray"
                //             borderRadius="full"
                //             color="black"
                //         />
                //         <Text fontSize="16px" fontWeight="600">
                //          Cardiac catheterization
                //         </Text>
                //         </Box>
                //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                //         <Text fontSize="12px" ml="7px" fontWeight="600">
                //             01/01/2021
                //         </Text>
                //         </Box>

                //         <Box display="flex" alignItems="center" gap="5px">
                //         <Circle
                //             size="12px"
                //             bg="white"
                //             border="2px solid gray"
                //             borderRadius="full"
                //             color="black"
                //         />
                //         <Text fontSize="16px" fontWeight="600">
                //          Cardiac surgery
                //         </Text>
                //         </Box>
                //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                //         <Text fontSize="12px" ml="7px" fontWeight="600">
                //             01/01/2021
                //         </Text>
                //         </Box>

                //         <Box display="flex" alignItems="center" gap="5px">
                //         <Circle
                //             size="12px"
                //             bg="white"
                //             border="2px solid gray"
                //             borderRadius="full"
                //             color="black"
                //         />
                //         <Text fontSize="16px" fontWeight="600">
                //         Coronary
                //         </Text>
                //         </Box>
                //         <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                //         <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                //         <Text fontSize="12px" ml="7px" fontWeight="600">
                //             01/01/2021
                //         </Text>
                //         </Box>
                //     </Stack>
                //     </VStack>
                // </Box>
                // </>

                <>
                <Box>
                    <VStack align="stretch">
                        {patienData && patienData.surgicalhistory.length > 0 ?
                        (patienData.surgicalhistory.map((surgery, index) => (
                            <Stack key={index} direction="column" align="flex-start" gap="0">
                                <Box display="flex" alignItems="center" gap="5px">
                                    <Circle
                                        size="12px"
                                        bg="white"
                                        border="2px solid gray"
                                        borderRadius="full"
                                        color="black"
                                    />
                                    <Text fontSize="16px" fontWeight="600">
                                        {surgery.proceduretitle}
                                    </Text>
                                </Box>
                                <Box display="flex" alignItems="flex-start" h="auto" gap="5px">
                                    <Center h="35px" ml="5px" borderLeft="1px dotted #CDCFD4"></Center>
                                    <Text fontSize="12px" ml="7px" fontWeight="600">
                                        {formatDate(surgery.proceduredate)}
                                    </Text>
                                </Box>
                            </Stack>
                        ))) : ( <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/surgeries.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>)}
                    </VStack>
                </Box>
            </>

            }/>

            {/* Family History */}
            <GrayCard
            hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="family history"
            cardHeaderIcon="/assets/imgs/family-history.png"
            cardBody={
                // <>
                // <Box display="flex" flexDirection="column" gap="10px">
                //     <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                //     <Box>
                //         <Text fontSize="16px" fontWeight="600">
                //         Mother
                //         </Text>
                //         <Text fontSize="12px" fontWeight="500" color="#384052">
                //         01/01/1950
                //         </Text>
                //     </Box>
                //     <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                //         <Text as="span" color="#384052" fontSize="10px" ml="2px">Alive</Text>
                //     </Box>
                //     </Box>
                //     <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                //     <Box>
                //         <Text fontSize="16px" fontWeight="600">
                //         Father
                //         </Text>
                //         <Text fontSize="12px" fontWeight="500" color="#384052">
                //         05/10/1948
                //         </Text>
                //     </Box>
                //     <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                //         <Text as="span" color="#384052" fontSize="10px" ml="2px">Deceased</Text>
                //     </Box>
                //     </Box>
                // </Box>
                // </>

                <>
                <Box display="flex" flexDirection="column" gap="10px">
                    {patienData?.familyhistory?.length> 0 ? 
                    (patienData.familyhistory.map((family, index) => (
                        <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Box>
                                <Text fontSize="16px" fontWeight="600">
                                    {family.relation}
                                </Text>
                                <Text fontSize="12px" fontWeight="500" color="#384052">
                                    {formatDate(family.dob)}
                                </Text>
                                <Box display="flex" alignItems="center" flexWrap='wrap'>
              {family.medicalcondition?.map((condition, index) => (
          <Box as="p" display="flex" alignItems="center" flexDir="row" fontSize="10px" key={index} title={family.medicalcondition.map(condition => condition.condition).join(", ")}>
            <Box as="span" fontSize="8px" mr="3px">● </Box> {condition.condition}
            {index < family.medicalcondition.length - 1 && ", "}
          </Box>
        ))}

            </Box>
                            </Box>
                            <Box display="flex" alignItems="center" border="1px solid #E6E7E966" bg="#E6E7E966" p="4px" px="6px" borderRadius="full">
                                <Text as="span" fontSize="10px" ml="2px">
                                    {
                                      family.isdeceased == 0 ? (
                                        <Text color='rgb(0,151,0)'> Alive</Text>
                                      ) : (
                                        <Text color='rgb(254, 51, 51)'> Deceased</Text>
                                      )
                                    }
                                </Text>
                            </Box>
                        </Box>
                    ))) : (<Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/family-history.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>)}
                </Box>
            </>

            } />

            {/* Social History */}
            <GrayCard
            hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="social History"
            cardHeaderIcon="/assets/imgs/social-history-bar.png"
            cardBody={
            // <>
            //     <Box>
            //    <Box display="flex" flexDirection="column" justifyContent="space-around" gap="10px">
            //      <Box>
            //        <Text fontSize="16px" fontWeight="600">Alchohol</Text>
            //          <Box display="flex" flexDirection="row" alignItems="center">
            //            <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
            //            <Image w="13px" h="9px" mx="3px" src="/assets/imgs/red-chart.png" />
            //            <Text fontSize="12px" fontWeight="500" color="#F04438">2</Text>
            //          </Box>
            //      </Box>
            //      <Divider size="1px" colorScheme="#E6E7E9" />
            //      <Box >
            //        <Text fontSize="16px" fontWeight="600">Alchohol</Text>
            //          <Box display="flex" flexDirection="row" alignItems="center">
            //            <Text fontSize="12px" fontWeight="500" color="#384052">Average 2 / per day</Text>
            //            <Image w="16px" h="14px" mx="3px" src="/assets/imgs/green-chart.png" />
            //            <Text fontSize="12px" fontWeight="500" color="#0C7650">2</Text>
            //          </Box>
            //      </Box>
            //  </Box>
            //    </Box>

            // </>

            <>
            <Box>
            {patienData && patienData.socialhistory.length > 0 ?  (patienData.socialhistory.map((history, index) => {
                const totalYears = calculateYearsDifference(history.fromdate, history.todate);
                var consumptionPreYear = (history.frequency / 7) * totalYears;
                const finalCalculation = consumptionPreYear.toFixed(2);
                return (
                <Box key={index} display="flex" flexDirection="column" justifyContent="space-around" gap="10px">
                    <Box>
                        <Text fontSize="16px" fontWeight="600">{history.socialhistory}</Text>
                        <Box display="flex" flexDirection="row" alignItems="center">
                        <Text fontSize="12px" fontWeight="bold" color="#384052">
                          {history.iscurrent == 1 ? " Current " : 
                          history.iscurrent == 0 ? " Never " : 
                          history.iscurrent == 2 ? " Former " :"" }
                      </Text>
                      <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                          {history.frequency && history.frequency !== 0 ? `${history.frequency} 
                              ${history.socialhistory && history.socialhistory == "Smoking" ? "Packs": 
                                                      history.socialhistory == "Alcohol" ? "Drinks" :
                                                      history.socialhistory == "Smokeless" ? "Cans" :
                                                      history.socialhistory == "Illicit Drugs" ? "Times" 
                                                      : "Packs"} 
                              Per ${history.frequencytype}   
                              x ${totalYears == 1 ? totalYears + " year"  :
                                  totalYears > 1 ? totalYears + " years" : 
                                                                  "less than a year"}  
                            ` 
                              : '-'}
                              {history.iscurrent == 2 && (
              <>
                {` Until ${history.todate ? formatDate_yyyy(history.todate) : ""}`}
              </>
            )}
                      </Text>
                            {/* <Text fontSize="12px" fontWeight="500" color="#384052">
                              Average {history.frequency}
                            </Text>
                            {history.frequency > 0 ? (
                                <Image w="13px" h="9px" mx="3px" src="/assets/imgs/red-chart.png" />
                            ) : (
                                <Image w="16px" h="14px" mx="3px" src="/assets/imgs/green-chart.png" />
                            )} */}
                            {/* <Text fontSize="12px" fontWeight="500" color={history.frequency > 0 ? "#F04438" : "#0C7650"}>{history.frequency}</Text> */}
                        </Box>
                    </Box>
                    <Divider size="1px" colorScheme="#E6E7E9" />
                </Box>
            )})) : ( <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
              >
                <Box textAlign="left" fontSize="16px" fontWeight="600">
                  <Image
                    w="26px"
                    opacity="0.5"
                    src="/assets/imgs/social-history-bar.png"
                  />
                </Box>
                <Text textAlign="left" fontSize="12px" fontWeight="500">
                  No Data available
                </Text>
              </Box>    )}
            </Box>
        </>

        }/>

            {/* Current medication */}
            <GrayCard
            hasFooter={false}
            w="100%"
            minW="100%"
            maxW="100%"
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "120px" }}
            cardHeader="current medications"
            cardHeaderIcon="/assets/imgs/medication.png"
            cardBody={
                // <>
                // <Box
                //     display="flex"
                //     flexDirection="column"
                //     justifyContent="space-between"
                //     gap="50px"
                // >
                //     <Box>
                //     <UnorderedList>
                //         <ListItem fontSize="14px" fontWeight="600">
                //         Paracetamol
                //         </ListItem>
                //         <ListItem fontSize="14px" fontWeight="600">
                //         Ibuprofen
                //         </ListItem>
                //         <ListItem fontSize="14px" fontWeight="600">
                //         Vitamin D
                //         </ListItem>
                //     </UnorderedList>
                //     </Box>
                // </Box>
                // </>

                <>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    gap="50px"
                >
                    <Box>
                            {patienData && patienData.medications.length> 0 ?
                            (patienData.medications.map((step, index) => (
                              <Box mb="0.75rem" key={index}>
                              <Text fontSize="14px" fontWeight="600">
                                {step.medicinename? <strong>●  {step.medicinename}</strong> :""}
                                    
                                </Text>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                {step.chemicalcomposition ? `${step.chemicalcomposition},  ${step.dosage}` : ""}
                                </Text>
                                </Box>
                            ))): ( <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="10px"
                                alignItems="center"
                              >
                                <Box textAlign="left" fontSize="16px" fontWeight="600">
                                  <Image
                                    w="26px"
                                    opacity="0.5"
                                    src="/assets/imgs/treatment-plan.png"
                                  />
                                </Box>
                                <Text textAlign="left" fontSize="12px" fontWeight="500">
                                  No Data available
                                </Text>
                              </Box>)}
                    </Box>
                </Box>
            </>

            }/>

{/* Vitals */}
            <Box
            display="flex"
            justifyContent="space-between"
            gap="20px"
            w="100%"
            >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
            >
                <GrayCard
                hasFooter={false}
                bodyStyle={{ p: "0" }}
                cardHeader="vitals"
                cardHeaderIcon="/assets/imgs/heart.png"
                cardBody={
                    // <>
                    // <Box width="100%" overflowX="auto">
                    //     <SimpleGrid minChildWidth="8.75rem">
                    //     <Box borderRight="1px solid #E6E7E9" p={4}>
                    //         <Text
                    //         fontSize="12px"
                    //         fontWeight="500"
                    //         color="#6A707D"
                    //         >
                    //         Body temperature
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="22px"
                    //         fontWeight="600"
                    //         >
                    //         98.6
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="15px"
                    //         pl={"5px"}
                    //         fontWeight="400"
                    //         >
                    //         °F
                    //         </Text>
                    //         <Box>
                    //             <Text
                    //             fontSize="12px"
                    //             fontWeight="500"
                    //             color="#6A707D"
                    //             >
                    //             May 15, 2024
                    //             </Text>
                    //         </Box>
                    //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                    //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                    //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                    //             OK
                    //             </Text>
                    //         </Box>
                    //     </Box>
                    //     <Box borderRight="1px solid #E6E7E9" p={4}>
                    //         <Text
                    //         fontSize="12px"
                    //         fontWeight="500"
                    //         color="#6A707D"
                    //         >
                    //         Body temperature
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="22px"
                    //         fontWeight="600"
                    //         >
                    //         98.6
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="15px"
                    //         pl={"5px"}
                    //         fontWeight="400"
                    //         >
                    //         °F
                    //         </Text>
                    //         <Box>
                    //             <Text
                    //             fontSize="12px"
                    //             fontWeight="500"
                    //             color="#6A707D"
                    //             >
                    //             May 15, 2024
                    //             </Text>
                    //         </Box>
                    //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                    //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                    //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                    //             OK
                    //             </Text>
                    //         </Box>
                    //     </Box>
                    //     <Box borderRight="1px solid #E6E7E9" p={4}>
                    //         <Text
                    //         fontSize="12px"
                    //         fontWeight="500"
                    //         color="#6A707D"
                    //         >
                    //         Body temperature
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="22px"
                    //         fontWeight="600"
                    //         >
                    //         98.6
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="15px"
                    //         pl={"5px"}
                    //         fontWeight="400"
                    //         >
                    //         °F
                    //         </Text>
                    //         <Box>
                    //             <Text
                    //             fontSize="12px"
                    //             fontWeight="500"
                    //             color="#6A707D"
                    //             >
                    //             May 15, 2024
                    //             </Text>
                    //         </Box>
                    //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                    //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                    //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                    //             OK
                    //             </Text>
                    //         </Box>
                    //     </Box>
                    //     <Box borderRight="1px solid #E6E7E9" p={4}>
                    //         <Text
                    //         fontSize="12px"
                    //         fontWeight="500"
                    //         color="#6A707D"
                    //         >
                    //         Body temperature
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="22px"
                    //         fontWeight="600"
                    //         >
                    //         98.6
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="15px"
                    //         pl={"5px"}
                    //         fontWeight="400"
                    //         >
                    //         °F
                    //         </Text>
                    //         <Box>
                    //             <Text
                    //             fontSize="12px"
                    //             fontWeight="500"
                    //             color="#6A707D"
                    //             >
                    //             May 15, 2024
                    //             </Text>
                    //         </Box>
                    //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                    //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                    //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                    //             OK
                    //             </Text>
                    //         </Box>
                    //     </Box>
                    //     <Box borderRight="1px solid #E6E7E9" p={4}>
                    //         <Text
                    //         fontSize="12px"
                    //         fontWeight="500"
                    //         color="#6A707D"
                    //         >
                    //         Body temperature
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="22px"
                    //         fontWeight="600"
                    //         >
                    //         98.6
                    //         </Text>
                    //         <Text
                    //         display="inline-block"
                    //         fontSize="15px"
                    //         pl={"5px"}
                    //         fontWeight="400"
                    //         >
                    //         °F
                    //         </Text>
                    //         <Box>
                    //             <Text
                    //             fontSize="12px"
                    //             fontWeight="500"
                    //             color="#6A707D"
                    //             >
                    //             May 15, 2024
                    //             </Text>
                    //         </Box>
                    //         <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                    //             <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                    //             <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                    //             OK
                    //             </Text>
                    //         </Box>
                    //     </Box>
                    //     </SimpleGrid>
                    // </Box>
                    // </>

                    <>
                    <Box width="100%" overflowX="auto">
                        <SimpleGrid minChildWidth="8.75rem">
                            {patienData && patienData.vitals.length>0 ?
                            (patienData.vitals.map((vital, index) => (
                                <Box key={index} borderRight="1px solid #E6E7E9" p={4}>
                                    <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                        {vital.vitalname}
                                    </Text>
                                    <Text display="inline-block" fontSize="22px" fontWeight="600">
                                        {vital.vitalvalue}
                                    </Text>
                                    <Text display="inline-block" fontSize="15px" pl={"5px"} fontWeight="400">
                                        {vital.vitalunit}
                                    </Text>
                                    <Box>
                                        <Text fontSize="12px" fontWeight="500" color="#6A707D">
                                            {formatDate(vital.vitaladdeddate)}
                                        </Text>
                                    </Box>
                                    <Box display="flex" alignItems="center" mt="0.5rem" gap="0.25rem">
                                        {/* <Image w="12px" h="auto" src="/assets/imgs/check.png" />
                                        <Text as="span" fontSize="12px" fontWeight="600" ml="2px">
                                            {vital.status}
                                        </Text> */}
                                        {
    vital.status === 'High' ? (
      <>
      <Image w="12px" h="auto" color='#F04438' src="/assets/imgs/arrow_upward.png" />
      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#F04438'>{vital.status}</Text>
      </>
    ) : vital.status === 'Normal' ? (
      <>
      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
      </>
    ) : vital.status === 'normal' ? (
      <>
      <Image w="12px" h="auto" color='#6A707D' src="/assets/imgs/check.png" />
      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#6A707D'>{vital.status}</Text>
      </>
    ) : vital.status === 'Low' ? (
      <>
      <Image w="12px" h="auto" color='#D46F11' src="/assets/svgs/arrow_upward.svg" />
      <Text as="span" fontSize="12px" fontWeight="600" ml="2px" color='#D46F11'>{vital.status}</Text>
      </>
    ): ('')
    }
                                    </Box>
                                </Box>
                            ))): (
                              'no records found'
                            )}
                        </SimpleGrid>
                    </Box>
                </>


                }/>
            </Box>
            </Box>
            
            {/* Investigations */}
            <Box
            display="flex"
            justifyContent="space-between"
            gap="20px"
            w="100%"
            >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="20px"
                w="100%"
            >
                <GrayCard
                hasFooter={false}
                // bodyStyle={{ p: "0" }}
                bodyStyle={{overflow: "hidden auto", maxH: "300px",p:0 }}
                cardHeader="Investigations"
                cardHeaderIcon="/assets/imgs/investigation.png"
                cardBody={
                    <>

                    <Box width="100%" overflowX="auto">
                        <SimpleGrid minChildWidth="8.7rem"  >
                    {patienData && patienData.investigations.length>0 ? (
                        patienData.investigations.map((investigation, index) => (
                        <Box borderWidth="1px" p={4} key={index}>
                            <Text
                            fontSize="12px"
                            fontWeight="500"
                            color="#6A707D"
                            >
                            {/* White blood cells */}
                            {investigation.eventtitle ? (investigation.eventtitle): "Null"}
                            </Text>
                            <Text
                            display="inline-block"
                            fontSize={{lg:"18px", xl:"22px"}}
                            fontWeight="600" 
                            className='textOverflow'
                            maxW="130px"
                            title={investigation?.actualresultvalue}
                            >
                            {investigation.actualresultvalue
                            ? investigation.actualresultvalue
                            : "-"}
                            </Text>
                            {investigation.resultuom && 
                            <Text display="inline-block" fontSize="15px" fontWeight="400">
                            ({investigation.resultuom? investigation.resultuom :""})
                            </Text>
                            }
                            <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            >
                            <Box>
                                <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                                >
                                {/* May 15, 2024 */}
                                {formatDate(investigation.verifieddate)}
                                </Text>
                            </Box>
                            {investigation.status === 'low' && investigation.trendColor === 'red' ? (
                      <Box display="flex" alignItems="center" transform="scaleY(-1)">
                        <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                    
                      </Box>
                    ) : investigation.status === 'high' && investigation.trendColor === 'red' ? (
                      <Box display="flex" alignItems="center">
                        <Image w="12px" h="auto" src="/assets/imgs/red-chart.png" alt="Normal" />
                        
                      </Box>
                    ) : investigation.status === 'low' && investigation.trendColor === 'green' ? (
                      <Box display="flex" alignItems="center">
                        <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                    
                      </Box>
                    ) : investigation.status === 'high' && investigation.trendColor === 'green' ? (
                      <Box display="flex" alignItems="center" transform="scaleY(-1)">
                        <Image w="12px" h="auto" src="/assets/imgs/green-chart.png" alt="Normal" />
                        
                      </Box>
                    ) : null}
                            </Box>
                        </Box>

                        
                        ))
                    ): ( <Flex
                        direction="row"
                        justify="space-around"
                        h="full"
                        w="100%"
                        flexWrap="wrap"
                        >
                        <Text>No Data Available</Text>
                        {/* {dataEntries.map((index) => (
                            <SimpleTable NoDataHeader={index} />
                        ))} */}
                      </Flex>)
                    }
                        </SimpleGrid>
                </Box>  
                    </>
                }
                />
            </Box>
            </Box>


            {/* Lab and Imaging */}
            <Box
            display="flex"
            // justifyContent="space-between"
            gap="20px"
            w="100%"
            flexWrap='wrap'
            >
            <GrayCard
                hasFooter={false}
                w="100%"
                minW="100%"
                maxW="100%"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="labs"
                cardHeaderIcon="/assets/imgs/biotech.png"
                cardBody={
                // <>
                //     <Box>
                //     <Box
                //         display="flex"
                //         flexDirection="column"
                //         justifyContent="space-around"
                //         gap="20px"
                //     >
                //         <Box>
                //         <Box
                //             mb="3px"
                //             display="flex"
                //             flexDirection="row"
                //             justifyContent="space-between"
                //             alignItems="center"
                //         >
                //             <Text
                //             fontSize="16px"
                //             fontWeight="600"
                //             color="#061027"
                //             >
                //             Lab Event Title
                //             </Text>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#384052"
                //             >
                //             Verified Date
                //             </Text>
                //         </Box>
                //         <Text fontSize="14px" fontWeight="500">
                //             Lab Long Text
                //         </Text>
                //         </Box>
                //     </Box>
                //     </Box>
                // </>

                <>
                <Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-around"
                        gap="20px"
                    >
                        {patienData && patienData.labs.length>0 ? 
                        (patienData.labs.map((lab, index) => (
                            <Box key={index}>
                                <Box
                                    mb="3px"
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                        color="#061027"
                                    >
                                        {lab.reportname}
                                    </Text>
                                    <Text
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#384052"
                                    >
                                        {/* {new Date(lab.verifieddate).toLocaleDateString()} */}
                                        {formatDate(lab.verifieddate)}
                                    </Text>
                                </Box>
                                <Text fontSize="14px" fontWeight="500">
                                    {lab.reportdescription}
                                </Text>
                        <Divider mt='10px' size="1px" colorScheme="#E6E7E9" />
                            </Box>
                        ))): ( <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/biotech.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>)}
                    </Box>
                </Box>
            </>

                }/>

            <GrayCard
                hasFooter={false}
                w="100%"
                minW="100%"
                maxW="100%"
                bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
                cardHeader="IMAGING"
                cardHeaderIcon="/assets/imgs/hand_bones.png"
                cardBody={
                // <>
                //     <Box>
                //     <Box
                //         display="flex"
                //         flexDirection="column"
                //         justifyContent="space-around"
                //         gap="20px"
                //     >
                //         <Box>
                //         <Box
                //             mb="3px"
                //             display="flex"
                //             flexDirection="row"
                //             justifyContent="space-between"
                //             alignItems="center"
                //         >
                //             <Text
                //             fontSize="16px"
                //             fontWeight="600"
                //             color="#061027"
                //             >
                //             Imaging Event Title
                //             </Text>
                //             <Text
                //             fontSize="12px"
                //             fontWeight="500"
                //             color="#384052"
                //             >
                //             Verified Date
                //             </Text>
                //         </Box>
                //         <Text fontSize="14px" fontWeight="500">
                //             Imaging Long Text
                //         </Text>
                //         </Box>
                //     </Box>
                //     </Box>
                // </>

                <>
                <Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-around"
                        gap="20px"
                    >
                        {patienData && patienData.imaging.length>0 ? 
                        (patienData.imaging.map((image, index) => (
                            <Box key={index}>
                                <Box
                                    mb="3px"
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                        color="#061027"
                                    >
                                        {image.reportname}
                                    </Text>
                                    <Text
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#384052"
                                    >
                                        {/* {new Date(image.verifieddate).toLocaleDateString()} */}
                                        {formatDate(image.verifieddate)}
                                    </Text>
                                </Box>
                                <Text fontSize="14px" fontWeight="500">
                                    {image.reportdescription}
                                </Text>
                        <Divider mt='10px' size="1px" colorScheme="#E6E7E9" />
                            </Box>
                        ))): ( <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/hand_bones.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>)}
                    </Box>
                </Box>
            </>

                }/>
            </Box>

      </Box>
    </Box>
    )}
     <Vitalsmodal
          ref={vitalsbmiModals}
          withBackBtn={true}
          modalSize={{ w: "350px!important" }}
          //modalHeaderIcon="/assets/imgs/monitor_weight-blue.png"
          header="Update Demographics"
          body={
            <>
              <Box>
                <Box display="flex" py="20px">

                  <DatePicker
                    onDateChange={(selectedDateValue) =>
                      handledatechange(selectedDateValue)
                    }
                    defaultDate={date}
                    disableFutureDates={true}
                    placeholderProp="Date"
                    calendarStlye={{left: "-18px",  right: "unset"}}
                  />
                  <PrimaryInput
                    variant="fullRight"
                    id="vitaltime"
                    inputType="time"
                    inputPlace="Time"
                    pr="10px"
                    defaultValue={time}
                    inputValue={time}
                    onChange={handleTimeChange }
                  />
                </Box>
                <Divider />
                <RadioGroup 
                 onChange={switchBMICalculation}
                 value={BMICalculatorType}
                >
                    <Box display="flex" justifyContent="space-around" py="20px">
                      <Radio value="standard" colorScheme="green">
                        Standard
                      </Radio>
                     
                      <Radio value="metric" colorScheme="green">
                        Metric
                      </Radio>

                    </Box>
                  </RadioGroup>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py="20px"
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                      Weight
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                          <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitweight}{" "}
                           
                            </MenuButton>
                           
                          </Menu>
                      </>
                  </Box>
                     <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Enter Value"
                options={weightOptionsInBMI}
                onClick={setWeightvalueInBMIDropdown}
             onChange={handlebmivitalvaluechange}
               readOnly=""
               id="weightvalue"
                inputValueProp={weightvalue}
              />
                </Box>

                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py="20px"
                >
                  <Box fontSize="14px" fontWeight="600" w="100%">
                      Height
                      <Box
                        as="span"
                        color="#384052"
                        fontSize="12px"
                        fontWeight="500"
                      >
  
                      </Box>
                      <>
                      <Menu>
                            <MenuButton
                              sx={{
                                color: "#1C4EC5",
                                display: "flex",
                                alignItems: "center",
                                cursor:"default"
                              }}
                            >
                              {vitalunitheight}{" "}
                             
                            </MenuButton>
                          </Menu>
                      </>
                  </Box>
                  {!showInchesInput && 
              
                  <DropdownInputForVitals
                  variant="bothSide"
                  dropDownPlace="Enter value"
                  options={heightOptionsInBMI}
                  onClick={setHeightValueInBMIDropDown}
                onChange={handlebmivitalvaluechange}
                 readOnly=""
                 id="heightvalue"
                 inputValueProp={heightvalue}
                />
                  }
                  
                  {showInchesInput && 
                  <>
                   
                    <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Feet"
                options={feetOptions}
                onClick={setFeetValueInDropDown}
                  onChange={handleBMIFeetValue}
               readOnly=""
               id="heightvalue"
                 inputValueProp={feetValue}
                maxLength={1}
              />

                    <DropdownInputForVitals
                variant="bothSide"
                dropDownPlace="Inches"
                options={inchesOptions}
                onClick={setInchValueInDropDown}
              onChange={handleBMIinchValue}
               readOnly=""
               id="heightvalue"
               inputValueProp={inchValue}
                maxLength={2}
              />
                  </>
                  }
                </Box>
              </Box>
            </>
          }
          footerStyle={{
            bg: "white",
            boxShadow: "0px -8px 20px 0px #E6E7E966",
          }}
          footerActions={
            <>
              <Box w="100%">
                <PrimaryButton
                  buttonText="Update Demographics"
                  onClick={SubmitBMIform}
                />
              </Box>
            </>
          }
        />
    </>
    
  );
};

export default OverviewIndex;
