import Prescription from "../../components/patient-health/doctor-notes/prescription-page";
import React, { useRef, useState, useEffect } from "react";
import { Box, VStack, HStack } from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  GetDoctorNotes,
  UploadPrescription,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import { PrimaryButton } from "../../components";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import { COLORS } from "../../components/styles/colors";
import {
  extractDiagnosis,
  formatMedicalHistory,
  transformVitalData,
  calculateAge,
  formatMedicines,
  formatOrders,
  formatGeneralNotes,
  formatDieticianNotes,
} from "../../middleware/domains/visits/patient-health/prescriptions";
import { uploadImageCommonFunction } from "../../auth";
import { useNavigate } from "react-router-dom";

const VisitPrescription = () => {
  //-------Fetch Parameters

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const patientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = params.get("doctorId");
  const fromEndVisit = Boolean(params.get("fromEndVisit"));

  // console.log(fromEndVisit);

  const [inputToPrescription, setInputToPrescription] = useState(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();
  //--------------------
  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      // Sleep for 10 seconds
      // await new Promise((resolve) => setTimeout(resolve, 15000));

      const response = await GetDoctorNotes(inputToFetch.input);
      const data = response?.output?.data;

      const addressDetails = data?.info?.hospitaladdress;
      let fullAddress = '-';

      if (addressDetails) {
        const {
          address,
          address2,
          city,
          STATE,
          postalcode,
          country
        } = addressDetails;

        if (address || address2 || city || STATE || postalcode || country) {
          fullAddress = [
            address && `${address}`,
            address2 && `${address2}`,
            city && `${city}`,
            STATE && `${STATE}`,
            postalcode && `${postalcode}`,
            country && `${country}`
          ]
            .filter(Boolean)
            .join(', ');
        }
      }

      const fetchedData = {
        drName: data?.info?.drname,
        hospitalLogo: data?.info?.hospitallogo,
        doctorQualification: data?.info?.doctorqualification,
        doctorSpeciality: data?.info?.doctorspeciality,
        doctorPhone: data?.info?.doctorphone,
        doctorPhoneCountryCode: data?.info?.doctorcountrycode,
        doctorMail: data?.info?.doctormail,
        // hospitalAddress: data?.info?.hospitaladdress?.address,
        hospitalAddress: fullAddress,
        patientBookingId: data?.info?.bookingid,
        patientName: data?.info?.patientname,
        patientGender: data?.info?.patientgender
          ? data.info.patientgender.charAt(0).toUpperCase() +
          data.info.patientgender.slice(1)
          : null,
        patientAge: calculateAge(data?.info?.patientage),
        patientPhone: data?.info?.patientphone,
        patientPhoneCountryCode: data?.info?.patientcountrycode,
        appointmentDate: data?.info?.appointmentdate
          ? moment(data?.info?.appointmentdate).format("D MMM YYYY")
          : "-",
        vitalData: transformVitalData(data.vitals || []),
        chiefcomplaint: data?.chiefcomplaint?.chiefcomplaint,
        patientdiagnosis: data?.patientdiagnosis,
        diagnosisData: extractDiagnosis(data.doctorsassessment || []),
        medicalHistory: formatMedicalHistory(data.medicalhistory || []),
        prescribedMedicines: formatMedicines(data.orders.medication || []),
        scaleOut:
          formatMedicines(data.orders.medication || []).length > 5
            ? true
            : false,
        recommendedTests: formatOrders(data.orders || []),
        followUpDate: data?.info?.followupdate
          ? moment(data.info.followupdate).format("D MMM YYYY")
          : "-",
        referralDoctor: data.info.referraldoctor
          ? data?.info?.referraldoctor
          : "-",
        referralDoctorSpeciality: data.info.referraldoctorSpeciality
          ? data?.info?.referraldoctorSpeciality
          : "-",
        doctorSignImage: null,
        followupDetail: data.followupdetail,
        generalnotes: formatGeneralNotes(data.generalnotes || []),
        dieticiannotes: formatDieticianNotes(data.dieticiannote || []),
      };
      setInputToPrescription(fetchedData);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotes();
  }, [patientId, doctorId, appointmentId]);

  const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   pageStyle: `
  //     @page {
  //       size: A4;
  //       margin: 0;
  //     }
  //     @media print {
  //       body {
  //         -webkit-print-color-adjust: exact;
  //         margin: 0;
  //       }
  //       .page-break {
  //         display: block;
  //         page-break-before: always;
  //       }
  //     }
  //   `,
  // });

  const handlePrint = async () => {
    const elements = document.querySelectorAll(".printPdf"); // Select all elements with the specified class
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      // Generate a canvas for the current element
      const canvas = await html2canvas(element, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 1);

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale the height proportionally

      if (i > 0) {
        pdf.addPage(); // Add a new page for every div after the first
      }

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    }

    // Get the PDF as a Blob URL
    const pdfBlobUrl = pdf.output("bloburl");

    // Open the print dialog for the generated PDF
    const printWindow = window.open(pdfBlobUrl);
    if (printWindow) {
      printWindow.focus();
      printWindow.print();
    }
  };
  const handleDownloadLocal = async () => {
    const elements = document.querySelectorAll(".printPdf"); // Select all elements with the specified class
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      // Generate a canvas for the current element
      const canvas = await html2canvas(element, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 1);

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale the height proportionally

      if (i > 0) {
        pdf.addPage(); // Add a new page for every div after the first
      }

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    }

    pdf.save("download.pdf");
  };

  const handleDownload = async () => {
    const elements = document.querySelectorAll(".printPdf"); // Select all elements with the specified class
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      // Generate a canvas for the current element
      const canvas = await html2canvas(element, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 1); // Use high-quality JPEG

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale the height proportionally

      if (i > 0) {
        pdf.addPage(); // Add a new page for every div after the first
      }

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
    }

    const pdfBlob = pdf.output("blob"); // Generate the PDF as a Blob
    return pdfBlob; // Return the Blob
  };

  const savePrescriptionUpload = async (fileName) => {
    const inputToSave = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
        prescriptionurl: fileName,
      },
    };
    // console.log(inputToSave);
    try {
      const response = await UploadPrescription(inputToSave.input);
      // console.log(response);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const uploadFile = async () => {
    try {
      const pdfBlob = await handleDownload();
      const file = new File([pdfBlob], "download.pdf", {
        type: "application/pdf",
      });
      const response = await uploadImageCommonFunction(file, "download.pdf");
      // console.log(response);
      if (response) {
        savePrescriptionUpload(response.name);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // prescriptionForAppointmentId is stored to disable resubmitting on refresh
  // useEffect(() => {
  //   if (fromEndVisit) {
  //     const storedAppointmentId = localStorage.getItem(
  //       "prescriptionForAppointmentId"
  //     );
  //     if (storedAppointmentId !== appointmentId) {
  //       setTimeout(() => {
  //         // console.log("Upload called");
  //         uploadFile();
  //         localStorage.setItem("prescriptionForAppointmentId", appointmentId);
  //       }, 2000); // Delay of 2 seconds
  //     }
  //   }
  // }, [fromEndVisit, appointmentId]);

  // useEffect(() => {
  //   if (fromEndVisit) {
  //     const storedAppointmentId = localStorage.getItem(
  //       "prescriptionForAppointmentId"
  //     );
  //     if (storedAppointmentId !== appointmentId) {
  //       const uploadAfterFetch = async () => {
  //         await fetchDoctorNotes();
  //         console.log("Upload called");
  //         uploadFile();
  //         localStorage.setItem("prescriptionForAppointmentId", appointmentId);
  //       };
  //       uploadAfterFetch();
  //     }
  //   }
  // }, [fromEndVisit, appointmentId]);

  useEffect(() => {
    if (fromEndVisit) {
      const storedAppointmentId = localStorage.getItem(
        "prescriptionForAppointmentId"
      );
      if (storedAppointmentId !== appointmentId) {
        const uploadAfterFetch = async () => {
          await fetchDoctorNotes();
        };
        uploadAfterFetch();
      }
    }
  }, [fromEndVisit, appointmentId]);

  useEffect(() => {
    if (fromEndVisit && inputToPrescription) {
      const storedAppointmentId = localStorage.getItem(
        "prescriptionForAppointmentId"
      );
      if (storedAppointmentId !== appointmentId) {
        // console.log("Upload called");
        uploadFile();
        localStorage.setItem("prescriptionForAppointmentId", appointmentId);
      }
    }
  }, [fromEndVisit, appointmentId, inputToPrescription]);

  const navigate = useNavigate();

  const handleProgressNotesClick = () => {
    const url = `/visitsummary?doctorId=${doctorId}&patientId=${patientId}&appointmentId=${appointmentId}&fromEndVisit=true`;
    // navigate(url);
    window.open(url, "_blank");
  };

  return (
    <Box height="100vh" overflowY="scroll">
      {isLoading && <Loader />}
      <VStack spacing={4} justify="center" align="center" p={4}>
        <HStack spacing={4}>
          <PrimaryButton
            buttonText="Print"
            // variant="grayBtn"
            // btnStyle={{ w: "12rem", h: "3rem", color: COLORS.PRIMARY_COLOR }}
            btnStyle={{ w: "12rem", h: "3rem" }}
            onClick={handlePrint}
          />
          <PrimaryButton
            buttonText="Download"
            onClick={handleDownloadLocal}
            btnStyle={{ w: "12rem", h: "3rem" }}
          />
          {fromEndVisit && <PrimaryButton
            buttonText="Progress Notes"
            // variant="grayBtn"
            // btnStyle={{ w: "12rem", h: "3rem", color: COLORS.PRIMARY_COLOR }}
            btnStyle={{ w: "12rem", h: "3rem" }}
            onClick={handleProgressNotesClick}
          />}

        </HStack>
        <VStack align="center">
          <Box ref={componentRef} width="100%">
            <Prescription {...inputToPrescription} />
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default VisitPrescription;
