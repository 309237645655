import {
  Box,
  Button,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import React,{useState,useEffect} from "react";
import PrimaryButton from "../../components/primary-button";
import { saveToken,savePatientDetails,saveFirstName,saveLastName,saveLoginID, savePatientImage, savePatientdob, savePatientgender, savecurrency, saveIsDoctor, saveLoginDetails } from "../../auth";
import {
  FetchMobileLogin,
  generateOtp,
} from "../../middleware/services/login/index";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import {
  Toaster,
} from "../../components";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { ValidatedOtp } from "../../middleware/services/login";
import NewPassword from "./newpassword";




const OtpIndexForgotPassword = ({otp, mobile, countrycode}) => {
  console.log(countrycode)
  const type = mobile.includes('@');
  const contactType = type ? 'email' : 'mobile'
  const [countdown, setCountdown] = useState(30);
  const [resendClicked, setResendClicked] = useState(false);
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [Showpassword, setShowpassword] = useState(false);

  const [enteredOTP , setEnteredOTP] = useState("")

  const [pinValues, setPinValues] = useState(["", "", "", ""]);
  const handlePin = (value)=>{
    setPinValues(value.split(''));
  }
  useEffect(()=>{
    const otpEnteredValue = pinValues.join('');
    setEnteredOTP(otpEnteredValue);
    console.log(otpEnteredValue , " otpEnteredValue ")
   
  },[pinValues])


  useEffect(() => {

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);
  
    setTimeout(() => {
      
    var pin1 = document.getElementById("pin1-0");
    pin1.focus();
    }, 10);
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [resendClicked]); 
  const handlebackbutton =()=>{
    window.location.href("/login");    
  }
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const handleResendClick = () => {
    resendOTPFunction();
    setTimeout(() => {
    setCountdown(120);
    setResendClicked(true);
    }, 1000);
    
  };
  var countrycode = localStorage.getItem("countryid")
  let countryid = "";
  if(countrycode === "+91"){
    countryid = "95";
  }else if(countrycode === "+1"){
    countryid = "229";
  }else if (countrycode == "+93") {
    countryid = "1";
  }

  var inputForValidateOTPMobile = {
    "validateotp": enteredOTP,
    "mobile": mobile.trim(),
    "countryid": countryid,
    "countrycode": countrycode,
  }
  
  const handleLoginSubmit = async () =>{
    showLoader();
     var pin1 = document.getElementById("pin1-0")
     var pin2 = document.getElementById("pin1-1")
     var pin3 = document.getElementById("pin1-2")
     var pin4 = document.getElementById("pin1-3")
     var pin1value = pin1.value;
     var pin2value = pin2.value;
     var pin3value = pin3.value;
     var pin4value = pin4.value;
     var otpEnteredValue = pin1value + pin2value + pin3value + pin4value;
     if(pin1value == "" || pin2value == "" || pin3value == "" || pin4value == "" ){      
     dismissLoader();
     showErrorToast("Please Enter the OTP")
      return false;
     }
    //  else if(enteredOTP !== otp){ 
    //   showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    //  setTimeout(() => {
    //   dismissLoader();     
    //  }, 1000);
    //    return false;
    //   }
      else {
    const response = await FetchMobileLogin(inputForValidateOTPMobile);
    if (response.output.result == "faliure") {
      setRedirectToHome(false);
      showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    } else {
      //console.log("Login successful:", response.output.data);

      if(response && response.output && response.output.data){
        localStorage.setItem("usertypeid" ,response?.output.data?.usertypeid);
        localStorage.setItem("rolename" ,response?.output.data?.rolename);
        localStorage.setItem("countrycode" ,response?.output?.data?.countryid);
        localStorage.setItem("countryid" ,response?.output?.data?.countrycode);
      if (response.output.data.isverified == 0) {
        navigate("/request-submit")
      }else{
      if (response.output.data.firstname) {
        saveFirstName(response.output.data.firstname);
        saveLastName(response.output.data.lastname)
      }
      if(response.data.currencyname){
        savecurrency(response.data.currencyname)
      }
      // Save the token
      if (response.output.token) {
        saveToken(response.output.token);
      }
      if (response.output.data.loginid) {
        saveLoginID(response.output.data.loginid);
      }
      if (response.data.isdoctor) {
        saveIsDoctor(response.data.isdoctor);
      }
      if (response?.data) {
        saveLoginDetails(response?.data);
      }
      // if(response.output.data.firsttimelogin == 1){
        
      //   navigate("/verification-success")
      // }else{
        navigate("/home");
      //}
      
    }
    }
      //navigate("/home");
      // Set the state to redirect to the dashboard
      //setRedirectToDashboard(true);
    }
  }
  dismissLoader();
  }
  var inputForValidateOTPForgot = {
  //  "validateotp": enteredOTP,
  "otp":enteredOTP,
    "email": mobile.trim(),
    "countryid": "1",
    "type":"forgotpassword",
    "patientid":null,
    "activeuserid":null
  }
  const handleForgotValidateOTP = async () =>{
    showLoader();
     var pin1 = document.getElementById("pin1-0")
     var pin2 = document.getElementById("pin1-1")
     var pin3 = document.getElementById("pin1-2")
     var pin4 = document.getElementById("pin1-3")
     var pin1value = pin1.value;
     var pin2value = pin2.value;
     var pin3value = pin3.value;
     var pin4value = pin4.value;
     var otpEnteredValue = pin1value + pin2value + pin3value + pin4value;
     if(pin1value == "" || pin2value == "" || pin3value == "" || pin4value == "" ){      
      dismissLoader();
     showErrorToast("Please Enter the OTP")
      return false;
     }
    //  else if(otpEnteredValue !== otp){   
    //   dismissLoader();   
    //   showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
    //    return false;
      
    //   }
      else {

    const response = await ValidatedOtp(inputForValidateOTPForgot);
    if (response.output.result == "failure") {
      setRedirectToHome(false);
      showErrorToast("Uh-oh! The code that you have entered is incorrect, please try again...")
     // setShowpassword(false);
    } else {
     setShowpassword(true);
    }
  }
  dismissLoader();
  }

  const resendOTPFunction = async () => {
   
    try {
      const response = await generateOtp({
       "email": mobile.trim(),
       // otp: "1234",
        "countrycode":"1",
        "type":"forgotpassword",
        "language":"en",
        "countryid":1,
        "ispatient":0
      });
      
    } catch (error) {
      console.error("Login failed:", error.response.data);
    }

  };
 
  return (
    <>
    {!Showpassword &&
      <VStack
        spacing={4}
        p={8}
        m={4}
        width="400px"
        gap="0"
        display="flex"
        alignItems="center"
      >
              {isLoading && <Loader />}

         {/* <Box
            as="button"
            bg="white"
            pos="relative"
            right="45%"
            top="3%"
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="10px 16px"
            border="1px solid #E6E7E9"
            borderRadius="full"
            w="64px"
            boxShadow="0px 8px 20px 0px #E6E7E966"
            onClick={handlebackbutton}
          >
            <Image w="20px" src="/assets/imgs/back-arrow.png" />
          </Box> */}
        <Box mb="15px">
          <Image
            src="/assets/svgs/otp-icon.svg"
            w="48px"
            h="auto"
            borderRadius="10px"
          />
        </Box>
        <Box as="h1">Enter OTP</Box>
        <Box mb="20px">
          <Text fontSize="14px" textAlign="center" fontWeight="400">
            Enter the OTP that was sent to your
            <br />
            {contactType === "mobile" ? 
              `phone number: ${countrycode} ${mobile}` : 
              `email address: ${mobile}`
            }            
          </Text>
        </Box>

        <HStack justifyContent="space-between" w="100%">
          <PinInput id="pin1" placeholder="-" size="lg" onChange={handlePin}>
            <PinInputField
              sx={{ width: "72px", height: "72px", fontSize: "2xl" }}
            />
            <PinInputField
              sx={{ width: "72px", height: "72px", fontSize: "2xl" }}
            />
            <PinInputField
              sx={{ width: "72px", height: "72px", fontSize: "2xl" }}
            />
            <PinInputField
              sx={{ width: "72px", height: "72px", fontSize: "2xl" }}
            />
          </PinInput>
        </HStack>

        {countdown > 0 && (
<Button
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
            >
            
        Resend OTP in: {formatTime(countdown)}
            </Button>
            )}
            {countdown <= 0 && (
              <div style={{display:"flex", justifyContent:"space-between"}}>
              <Button
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
              
            onClick={handleResendClick}
            >
              Didnt Receive OTP?
            </Button>

              <Button
              
            onClick={handleResendClick}
              sx={{
                bg: "none",
                color: "#6A707D",
                fontSize: "12px",
                fontWeight: "600",
                _hover: { bg: "none" },
              }}
            >
              Resend OTP
            </Button>
              </div>
            )}
          {contactType === "mobile" ? 
              <PrimaryButton
              colorScheme="blue"
              width="full"
              buttonText="Login"
              isDisabled={false}
              onClick={handleLoginSubmit}
            /> : 
            <PrimaryButton
            colorScheme="blue"
            width="full"
            buttonText="Verify"
            isDisabled={false}
            onClick={handleForgotValidateOTP}
          />
            }   
       
      </VStack>
    }
      {Showpassword && <NewPassword email={mobile}/>}
    </>
  );
};

export default OtpIndexForgotPassword;
