import moment from "moment";
export default function formAPIMedication({
  doctorname,
  documentcount,
  documents,
  dosage,
  dosageunit,
  frequency,
  id,
  imagepath,
  iscurrentmedicine,
  medicationenddate,
  medicationstartdate,
  medicationstatus_cd,
  medicineid,
  medicinename,
  medicinetype,
  medicineunit,
  patientid,
  prescribedby,
  prescribeddate,
  prescribedhospitalname,
  specialization,
  bookingid,
  chemicalcomposition,
  morning,
  evening,
  night,
  medicineexpirydate,
  afternoon,

    // new states of medicine input start ||----
    routeofadministration,
    timingofinjection,
    quantitytobeapplied,
    areaofapplication,
    diluent,
    infusionduration,
    additives,
    duration,
    rateofinfusionvalue,
    rateofinfusionunit,
     // new states of medicine input End ----||
   

})
{
  return Object.freeze({
    doctorname,
    documentcount,
    documents,
    dosage,
    dosageunit,
    frequency,
    id,
    imagepath,
    iscurrentmedicine,
    medicationenddate,
    medicationstartdate,
    medicationstatus_cd,
    medicineid,
    medicinename,
    medicinetype,
    medicineunit,
    patientid,
    prescribedby,
    prescribeddate,
    prescribedhospitalname,
    specialization,
    bookingid,
    chemicalcomposition,
    morning,
    evening,
    night,
    medicineexpirydate,
    afternoon,
      // new states of medicine input start ||----
      routeofadministration,
      timingofinjection,
      quantitytobeapplied,
      areaofapplication,
      diluent,
      infusionduration,
      additives,
      duration,
      rateofinfusionvalue,
      rateofinfusionunit,
       // new states of medicine input End ----||
   
  });
}