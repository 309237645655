import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Image,
  Checkbox,
  RadioGroup,
  Radio,
  Heading,
  Stack,
  Flex,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import moment from "moment";
import ReportViewCard from "../../report-card";
import { GetDoctorMasterData } from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { updateCondition } from "../../../middleware/services/visits/patient-overview/updatehealth";
import DynamicModal from "../../dynamic-modal";
import ModalRight from "../../modal-right";
import PrimaryInput from "../../primary-input";
import SearchBar from "../../search-bar";
import DropdownInput from "../../dropdown-input";
import PrimaryButton from "../../primary-button";

import { format } from "date-fns";
import Toaster from "../../toaster";
import DatePicker from "../../date-picker";
import useLoader from "../../loader/loaderstates";
import ModalRightForTwo from "../doctor-notes/modal-right-for-two";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const FamilyHistory = ({
  familydata,
  doctorId,
  patientId,
  appointmentId,
  fetchPatientHealthData,
}) => {
  var [data, setData] = useState(null);
  var [medicaldata, setMedicalData] = useState([]);
  var [relationdata, setRelationData] = useState([]);
  var [martialstatusdata, setMartialstatusData] = useState([]);
  var [martialstatuscomplete, setMartialstatusCompleteData] = useState([]);
  var [relationscomplete, setRelationsComplete] = useState([]);
  var [patientid, setPatientid] = useState();
  const dynamicModal = React.useRef();
  const AddFamily = React.useRef();
  const EditFamily = React.useRef();
  const Medicalconditionsmodel = useRef();
  const [searchValue, setSearchValue] = useState("");
  const [dateofbirth, setDate] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [relationship_cd, setRelationshipID] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [maritalstatus, setMaritalstatus] = useState("");
  const [maritalstatus_cd, setMaritalstatusID] = useState(null);
  const [adopted, setAdoptedcheckbox] = useState(false);
  const [deceased, setdeceasedcheckbox] = useState(false);
  const [id, setID] = useState("");
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState("");
  const [gender_cd, setGenderCD] = useState("");
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [heightunit_cd, setHeightunit_cd] = useState("151");
  const [weightunit_cd, setWeightunit_cd] = useState("154");
  const [familyId, setFamilyId] = useState("");

  const [isAdd, setIsAdd] = useState(true);

  const [typedCondition, setTypedCondition] = useState("");

  const [email, setEmail] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [bloodtype_cd, setBloodtype_cd] = useState("");
  var [allergiesdata, setAllergiesData] = useState(null);

  const [selectedAllergies, setSelectedAllergies] = useState([]);

  const [medicationname, setMedicationname] = useState("");
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const allergiesModal = useRef();

  // State to manage the selected medical conditions
  const [selectedConditions, setSelectedConditions] = useState([]);
  // Function to add a medical condition
  const addCondition4 = (condition) => {
    setSelectedConditions([...selectedConditions, condition]);
  };

  // Function to remove a medical condition
  const removeTag = (index) => {
    const updatedConditions = selectedConditions.filter((_, i) => i !== index);
    setSelectedConditions(updatedConditions);
  };

  // Medical Conditions checkbox selection
  const handleCheckboxChange = (condition) => {
    const isSelected = selectedConditions.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      // If already selected, remove it from the array
      setSelectedConditions((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      // If not selected, add it to the array
      setSelectedConditions([...selectedConditions, condition]);
    }
  };
  const handleRelationshipInput = (selectedOption) => {
    setRelationship(selectedOption[0]);
    var foundObject =
      relationscomplete &&
      relationscomplete.find((x) => x.condition == selectedOption);

    if (foundObject) {
      setRelationshipID(foundObject.condition_cd);
    }
  };
  const handleMartitalstatusinput = (selectedOption) => {
    var foundObject =
      martialstatuscomplete &&
      martialstatuscomplete.find((x) => x.condition == selectedOption);

    if (foundObject) {
      setMaritalstatusID(foundObject.condition_cd);
    }
  };
  const handleAdoptedCheckboxChange = (e) => {
    setAdoptedcheckbox(e.target.checked);
  };
  const handledeceasedCheckboxChange = (e) => {
    setdeceasedcheckbox(e.target.checked);
  };

  const SubmitEditForm = async () => {
    let EditInput = {
      doctorid: doctorId,
      patientid: patientId,
      appointmentid: appointmentId,
      type: "familyhistory",
      familyid: id,
      fullname: name + lastName,
      firstname: name,
      lastname: lastName,
      dob: dateofbirth,
      // relation: relationship,
      relation_cd: relationship_cd,
      medicalcondition: selectedConditions.map((condition, index) => ({
        conditiontype_cd: index + 1, // Assuming index + 1 is the desired value
        condition_cd: condition.condition_cd,
        nomenclatureid: condition.condition_cd, // You may adjust this value accordingly
        conditionidentifieddate: new Date().toISOString(), // Current date as an example

        condition: condition.condition,
        conditiontype: condition?.category?.conditiontype,
      })),
      // allergies: selectedAllergies?.map((allergy, index) => ({
      //   allergytype_cd: allergy.condition_cd,
      //   allergy_cd: allergy.condition_cd,
      //   nomenclatureid: allergy.condition_cd, // You may adjust this value accordingly
      //   allergyidentifieddate: new Date().toISOString(), // Current date as an example
      // })),
    };
    // console.log(EditInput);
    if (moment(EditInput.dob, "DD-MM-YYYY", true).isValid()) {
      EditInput.dob = moment(EditInput.dob, "DD-MM-YYYY").format("YYYY-MM-DD");
    }

    if (name == "") {
      showErrorToast("Please enter first name");
      return false;
    } else if (lastName == "" || lastName == null) {
      showErrorToast("Please enter last name");
      return false;
    } else if (relationship == "") {
      showErrorToast("Please select any relation");
      return false;
    } else {
      showLoader();
      const response = await updateCondition(EditInput);

      // console.log(response);

      if (response) {
        showSuccessToast("Family history updated successfully");

        // var patientDetails = localStorage.getItem("patientDetails");
        // if (patientDetails) {
        //   var formateddata = JSON.parse(patientDetails);
        //   var activeUserId = formateddata.activeuserid;
        //   if (activeUserId != null && activeUserId == familyId) {
        //     localStorage.setItem("username", name + " " + lastName);
        //   }
        // }

        fetchPatientHealthData();
        EditFamily.current.closeRight();

        // window.location.reload();
      } else {
      }
      dismissLoader();
    }
  };

  const handleCondition = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;

    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);
    }
  };
  const AddMedicalConditions = () => {
    if (typedCondition !== "") {
      const newSelectedCondition = {
        category: {
          conditiontype: "other",
          conditiontype_cd: null,
        },
        condition: typedCondition,
        condition_cd: null,
      };
      if (isAdd) {
        setSelectedConditions((prevConditions) => {
          // Filter out any existing condition with the same type
          // console.log(prevConditions);
          const filteredConditions = prevConditions.filter(
            (item) => item?.category?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }

      if (!isAdd) {
        setSelectedConditions((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }
    }
    Medicalconditionsmodel.current.closeRight();
  };

  function clearState() {
    setID("");
    setName("");
    setLastName("");
    setRelationship("");
    setRelationshipID(null);
    setMaritalstatus("");
    setDate("");
    setSelectedConditions([]);
    setdeceasedcheckbox(false);
    setAdoptedcheckbox(false);
    setHeight(null);
    setWeight(null);
    setMobileno("");
    setSelectedAllergies([]);
    setFamilyId("");
  }
  const handleFamilyEdit = async (item) => {
    clearState();
    // console.log(item);
    setID(item.familyid);
    setGenderCD(item.gender_cd);
    setName(item.firstname);
    setLastName(item.lastname);
    setRelationship(item.relation);
    setRelationshipID(item.relation_cd);
    setMaritalstatus(item.maritalstatus);
    setMaritalstatusID(item.maritalstatus_cd);
    setFamilyId(item.familyid);
    if (item.dob !== null) {
      const formattedDate = moment(item.dob, "YYYY-MM-DD").format("DD-MM-yyyy");
      setDate(formattedDate);
    } else {
      setDate(null);
    }
    setSelectedConditions(item.medicalcondition);
    setSelectedAllergies(item.allergies);
    // console.log(item.allergies  , " item.allergies")
    setdeceasedcheckbox(item.isdeceased === 0 ? false : true);
    setAdoptedcheckbox(item.isadopted === 0 ? false : true);
    setEmail(item.email);
    setMobileno(item.mobileno);
    setBloodtype_cd(item.bloodtype_cd);
    setHeight(item.height);
    setWeight(item.weight);
    setGender(item.gender_cd);
    // handleRelationshipInput(item.relation);
    // handleMartitalstatusinput(item.maritalstatus);
    try {
      const result = await GetDoctorMasterData({
        type: "healthcondition",
      });
      //console.log(result);
      if (result != null) {
        setMedicalData(result);
      }
    } catch (error) {
      console.error("Error", error);
    }
    //Relations
    try {
      const result = await GetDoctorMasterData({
        type: "relation",
      });
      //console.log(result,"relations");
      if (result != null) {
        const formattedOptions = result[0].data.map((option) => [
          option.condition,
        ]);
        setRelationData(formattedOptions);
      }
      setRelationsComplete(result[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    //Martial status
    // try {
    //   const result = await getmartialstatus({
    //     type: "maritalstatus",
    //   });
    //   //console.log(result,"status");
    //   if (result != null) {
    //     const formattedOptions = result.output.data[0].data.map((option) => [
    //       option.condition,
    //     ]);
    //     setMartialstatusData(formattedOptions);
    //   }
    //   setMartialstatusCompleteData(result.output.data[0].data);
    // } catch (error) {
    //   console.error("Error", error);
    // }
    EditFamily.current.openRight();
  };

  const openAddConditionModal = () => {
    setSearchValue("");
    // setSelectedConditions([]);
    Medicalconditionsmodel.current.openRight();
  };
  const handleDateChange = (selecteddate) => {
    const formattedDate = format(selecteddate, "yyyy-MM-dd");
    setDate(formattedDate);
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const selectGender = (selectedOption) => {
    if (selectedOption == "Male") {
      setGender("2");
    } else if (selectedOption == "Female") {
      setGender("1");
    } else if (selectedOption == "Other") {
      setGender("3");
    }
  };

  const handleSetHeight = (event) => {
    let cleanedValue = event.target.value.replace(/[^0-9.]/g, "");

    if (cleanedValue.match(/[0-9]{4,}/)) {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    const decimalIndex = cleanedValue.indexOf(".");
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split(".");
      cleanedValue = parts[0] + "." + parts[1].slice(0, 2);
    }

    setHeight(cleanedValue);
  };

  const handleChangeHeightUnit = (selectedOption) => {
    setHeightunit_cd(selectedOption.value);
  };

  const handleSetWeight = (event) => {
    let cleanedValue = event.target.value.replace(/[^0-9.]/g, "");

    if (cleanedValue.match(/[0-9]{4,}/)) {
      cleanedValue = cleanedValue.slice(0, -1);
    }
    const decimalIndex = cleanedValue.indexOf(".");
    if (decimalIndex !== -1) {
      const parts = cleanedValue.split(".");
      cleanedValue = parts[0] + "." + parts[1].slice(0, 2);
    }

    setWeight(cleanedValue);
  };
  const handleChangeWeightUnit = (selectedOption) => {
    setWeightunit_cd(selectedOption.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMobile = (e) => {
    const inputText = e.target.value;
    const maxLength = 10;

    if (inputText.length <= maxLength) {
      setMobileno(inputText);
    }
  };
  const selectBloodType = (selectedOption) => {
    if (selectedOption == "A+") {
      setBloodtype_cd("158");
    } else if (selectedOption == "A-") {
      setBloodtype_cd("159");
    } else if (selectedOption == "B+") {
      setBloodtype_cd("160");
    } else if (selectedOption == "B-") {
      setBloodtype_cd("161");
    } else if (selectedOption == "AB+") {
      setBloodtype_cd("162");
    } else if (selectedOption == "AB-") {
      setBloodtype_cd("163");
    } else if (selectedOption == "O+") {
      setBloodtype_cd("164");
    } else if (selectedOption == "O-") {
      setBloodtype_cd("165");
    }
  };
  const medicationsHandleChange = (event) => {
    setMedicationname(event.target.value);
  };

  const fetchAllergiesData = async () => {
    try {
      const response = await GetDoctorMasterData({
        type: "allergies",
      });
      if (response.output.result == "success") {
        setAllergiesData(response.output.data);
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const openAllergiesModal = () => {
    if (allergiesdata == null) {
      fetchAllergiesData();
    }
    allergiesModal.current.openRight();
  };
  //Allergies
  const handleAllergiesCheckboxChange = (condition) => {
    const isSelected = selectedAllergies?.some(
      (item) => item.condition_cd === condition.condition_cd
    );

    if (isSelected) {
      setSelectedAllergies((prevConditions) =>
        prevConditions.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedAllergies((prevConditions = []) => [
        ...prevConditions,
        condition,
      ]);
    }
  };
  const removeTagAllergiesFromEdit = (condition) => {
    setSelectedAllergies((prevTags) =>
      prevTags.filter((tag) => tag.condition_cd !== condition)
    );
  };
  const removeTagAllergiesFromAdd = (condition_cd) => {
    setSelectedAllergies((prevTags) =>
      prevTags.filter((tag) => tag.condition_cd !== condition_cd)
    );
  };
  const handleFirstName = (e) => {
    setName(e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 50));
  };
  const handleLastName = (e) => {
    setLastName(e.target.value.replace(/[^a-zA-Z\s]/g, "").slice(0, 50));
  };

  // console.log(data);

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} width="100%">
      {familydata.map((member, index) => (
        <Box key={index} flex="0 0 calc(50% - 10px)" mb="20px">
          {" "}
          {/* Adjusted width */}
          <ReportViewCard
            withBadge={
              <Text
                as="span"
                fontSize="10px"
                ml="2px"
                color={member.isdeceased === 1 ? "#fe3333" : "#009700"}
              >
                {member.isdeceased === 1 ? "Deceased" : "Alive"}
              </Text>
            }
            withBadgeStyle={{ pb: "0" }}
            editBtn={() => handleFamilyEdit(member)}
            // editDeleteBtn={() => console.log("Delete clicked")} // Placeholder function
            BtnRightIcon={{ display: "none" }}
            withBadgeHeader={member.firstname + " " + member.lastname}
            withBadgeSecondary={
              member.relation + " ● " + formatDate(member.dob)
            }
            deleteBtn={false}
            editDeleteBtn={false}
            keepDeleteButton={false}
            cardBtn={false}
            cardText={
              <Box gap="10px" width="100%" alignItems="left">
                {member.medicalcondition.map((condition, index) => (
                  <span
                    key={index}
                    title={member.medicalcondition
                      .map((condition) => condition.condition)
                      .join(", ")}
                  >
                    {condition.condition}
                    {index < member.medicalcondition.length - 1 && ", "}
                  </span>
                ))}
              </Box>
            }
          />
        </Box>
      ))}

      <ModalRightForTwo
        ref={EditFamily}
        bodyStyle={{ p: "24px 32px" }}
        modalWidth={"360px"}
        header="Edit Member"
        body={
          <>
            <Box display="flex" flexDir="column">
              <PrimaryInput
                variant="defaultSide"
                inputType="text"
                inputPlace="First Name*"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
              <PrimaryInput
                variant="bothSide"
                inputType="text"
                inputPlace="Last Name*"
                defaultValue={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {/* <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender"
                options={["Male", "Female", "Other"]}
                onClick={selectGender}
                defaultOption={
                  gender_cd == "2"
                    ? "Male"
                    : gender_cd == "1"
                    ? "Female"
                    : gender_cd == "3"
                    ? "Other"
                    : ""
                }
                inputValue={gender}
              /> */}
              {/* <Flex w="100%">
                <MiniDropdownInput
                  variant="bothSide"
                  options={[
                    { label: "cm", value: 151 },
                    { label: "ft", value: 152 },
                  ]}
                  placeholderProp="Height"
                  id="heightUnits"
                  defaultOptionProp={heightunit_cd}
                  onSelect={handleChangeHeightUnit}
                  defaultValue={height}
                  inputVal={height}
                  handleInputChange={handleSetHeight}
                  maxLength="6"
                />

                <MiniDropdownInput
                  variant="bothSide"
                  dropdownStyle={{ pl: "0" }}
                  placeholderProp="Weight"
                  options={[
                    { label: "kg", value: 154 },
                    { label: "lb", value: 155 },
                  ]}
                  id="weightUnits"
                  onSelect={handleChangeWeightUnit}
                  defaultOptionProp={weightunit_cd}
                  inputVal={weight}
                  defaultValue={weight}
                  handleInputChange={handleSetWeight}
                  maxLength="6"
                />
              </Flex> */}
              {/* <PrimaryInput
                  variant="bothSide"
                  inputType="date"
                  inputPlace="Date of birth"
                  onChange={(e) => setDate(e.target.value)}
                /> */}
              <DatePicker
                inputStyle={{ borderRadius: "0!important" }}
                disableFutureDates={true}
                defaultDate={dateofbirth}
                onDateChange={(selectedDateValue) =>
                  handleDateChange(selectedDateValue)
                }
                placeholderProp={"Date of birth*"}
              />
              <DropdownInput
                variant="downSide"
                dropDownPlace="Relationship*"
                options={relationdata}
                defaultOption={relationship}
                onClick={handleRelationshipInput}
              />
              {/* <PrimaryInput
                inputType="number"
                variant="bothSide"
                inputPlace="Mobile Number"
                onChange={handleMobile}
                defaultValue={mobileno}
                inputValue={mobileno}
              />
              <PrimaryInput
                inputType="email"
                variant="bothSide"
                inputPlace="Email Address"
                onChange={handleEmail}
                defaultValue={email}
              />
              <DropdownInput
                variant="bothSide"
                dropDownPlace="Blood Type"
                options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
                onClick={selectBloodType}
                defaultOption={
                  bloodtype_cd == "158"
                    ? "A+"
                    : bloodtype_cd == "159"
                    ? "A-"
                    : bloodtype_cd == "160"
                    ? "B+"
                    : bloodtype_cd == "161"
                    ? "B-"
                    : bloodtype_cd == "162"
                    ? "AB+"
                    : bloodtype_cd == "163"
                    ? "AB-"
                    : bloodtype_cd == "164"
                    ? "O+"
                    : bloodtype_cd == "165"
                    ? "O-"
                    : ""
                }
              />
              <DropdownInput
                variant="downSide"
                dropDownPlace="Marital status"
                options={martialstatusdata}
                defaultOption={maritalstatus}
                onClick={handleMartitalstatusinput}
              /> */}

              <Box mt="15px">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAddConditionModal}
                  tabIndex={0}
                  bg="white"
                  borderRadius="10px 10px 0 0"
                  border="1px solid #E6E7E9"
                  p={selectedConditions.length === 0 ? "8px 20px 8px 8px" : "2"}
                  w="inherit"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  flexWrap="wrap"
                  justifyContent="start"
                >
                  {selectedConditions.length === 0 && (
                    <>
                      <Text pl="1" color="gray.400" textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>
                        Medical Condition
                      </Text>

                      <Image src="/assets/imgs/right-icon.png" />
                    </>
                  )}
                  {selectedConditions.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                      maxW='240px'
                    >
                      <Text fontSize="sm" px="5px" title={tag.condition} whiteSpace='pre-wrap' WebkitLineClamp='2' lineHeight='normal' overflow='hidden'>
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* Allergies */}
                {/* <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAllergiesModal}
                  tabIndex={0}
                  bg="white"
                  borderRadius="0 0 10px 10px"
                  border="1px solid #E6E7E9"
                  p={selectedAllergies.length === 0 ? "8px 20px 8px 8px" : "2"}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  {selectedAllergies?.length === 0 && (
                    <>
                      <Text pl="1" color="gray.400">
                        Allergies
                      </Text>

                      <Image src="/assets/imgs/right-icon.png" />
                    </>
                  )}
                  {selectedAllergies?.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTagAllergiesFromEdit(tag.condition_cd);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box> */}
              </Box>

              {/* <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={() => Medicalconditionsmodel.current.openRight()}
                tabIndex={0}
                border="1px solid #E6E7E9"
                p="2"
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto" 
                minHeight="48px" 
                flexWrap="wrap"
              >
                 {selectedConditions.length === 0 && (
                  <Text pl="1" color="gray.400">Custom Condition</Text>
                )}
                {selectedConditions.map((tag, index) => (
                  <Box key={index} display="flex" alignItems="center" gap="1" bg="gray.100" borderRadius="full" p="1">
                    <Text fontSize="sm" px="5px">{tag.name}</Text>
                    <Box as="button" p="5px" onClick={(e) => { e.stopPropagation(); removeCondition(tag.id); }} aria-label="Remove condition">
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box> */}
            </Box>
            <Box py="20px">
              <Divider border="1px solid #E6E7E9" />
            </Box>
            {/* <Box display="flex" gap="30px">
              <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                defaultChecked={adopted === true}
                onChange={handleAdoptedCheckboxChange}
              >
                Adopted
              </Checkbox>
              <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                defaultChecked={deceased === true}
                onChange={handledeceasedCheckboxChange}
              >
                Deceased
              </Checkbox>
            </Box> */}
          </>
        }
        footerActions={
          <Box w="100%" display="flex" flexDirection="row" gap="15px">
            <PrimaryButton
              buttonText="Update Member"
              onClick={SubmitEditForm}
            />
          </Box>
        }
      />

      <ModalRightForTwo
        ref={Medicalconditionsmodel}
        header="Select Condition"
        modalWidth="360px"
        body={
          <>
            <SearchBar
              passInputPlace="Search condition"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {medicaldata && medicaldata.length > 0 ? (
                        medicaldata.some((category) =>
                          category.data.some((condition) =>
                            condition.condition
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                        ) ? (
                          medicaldata.map((category) => (
                            <React.Fragment key={category.category_id}>
                              {category.data
                                .filter((condition) =>
                                  condition.condition
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                                )
                                .map((condition) => (
                                  <label key={condition.condition_cd}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      borderBottom="1px solid #e2e8f0"
                                      // borderRadius="10px"
                                      p="15px"
                                      pl="5px"
                                      minH="50px"
                                      width="100%"
                                      sx={{
                                        _hover: {
                                          border: "2px solid #384052",
                                          borderRadius: "10px",
                                        },
                                      }}
                                    >
                                      <Box
                                        key={condition.condition_cd}
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text
                                          fontSize="16px"
                                          lineHeight="normal"
                                          fontWeight="600"
                                        >
                                          {condition.condition}
                                          <br />
                                          <Box
                                            as="span"
                                            fontSize="12px"
                                            fontWeight="500"
                                            color="#6A707D"
                                          ></Box>
                                        </Text>
                                      </Box>
                                      <Box display="flex" justifyContent="end">
                                        <Checkbox
                                          colorScheme="green"
                                          sx={{
                                            ".chakra-checkbox__control": {
                                              width: "20px",
                                              height: "20px",
                                              borderRadius: "5px",
                                            },
                                          }}
                                          onChange={() =>
                                            handleCheckboxChange(condition)
                                          }
                                          isChecked={
                                            selectedConditions &&
                                            selectedConditions.some(
                                              (item) =>
                                                item.condition_cd ===
                                                condition.condition_cd
                                            )
                                          }
                                        ></Checkbox>
                                      </Box>
                                    </Box>
                                  </label>
                                ))}
                            </React.Fragment>
                          ))
                        ) : (
                          <Box
                            p="15px"
                            fontSize="16px"
                            fontWeight="600"
                            textAlign="center"
                          >
                            No matching conditions found!
                          </Box>
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                      <Divider />
                      <br />
                      <Heading
                        as="h2"
                        fontSize="12px"
                        pl="5px"
                        textTransform="uppercase"
                        fontWeight="600"
                        my="10px"
                      >
                        Other Condition
                      </Heading>
                      <PrimaryInput
                        inputPlace="Condition"
                        variant="bothSide"
                        onChange={handleCondition}
                        inputValue={typedCondition}
                      />
                      <br />
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditions}
              />
            </Box>
          </>
        }
      />
    </SimpleGrid>
  );
};

export default FamilyHistory;
