import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  ChakraProvider,
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  AccordionIcon,
  Badge,
  Stack,
  RadioGroup,
  Image,
  InputGroup,
  Radio,
  CheckboxGroup,
  Heading,
  Text,
  Checkbox,
  Spacer,
  Divider,
  Textarea,
} from "@chakra-ui/react";

import ModalRight from "../../../components/modal-right";

import DatePicker from "../../../components/date-picker";
import {
  PrimaryButton,
  Toaster,
  PrimaryInput,
  SearchBar,
  DropdownInput,
} from "../../../components";

import {
  updateDoctorNotes,
  GetDoctorNotes,
  GetDoctorMasterData,
  GetDoctorFavMedicine,
  GetAIMedicineComposition,
  createAppointmentDieticianNotes,
  deleteAppointmentDieticianNotes,
  createGeneralAppointmentDieticianNotes,
  deleteGeneralAppointmentDieticianNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import CurrentOrderView from "./order-details";
import CurrentOrderMedications from "./order-medication-details";
import PrescriptionComponent from "./order-prescription";
import moment from "moment";
import { COLORS } from "../../styles/colors";
import DynamicModal from "../../dynamic-modal";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import {
  AiRecommendMedicines,
  FetchMedicineAIAnswer,
  FetchMedicineAIPrompts,
  FetchMyHealthAIData,
} from "../../../middleware/services/visits/aicalls";
import MiniDropdownInput from "../../mini-dropdown";
import TypingLoader from "../../typing-loader";
import AiModal from "../../ai-modal";
import DynamicModalForTwo from "./dynamic-modal-for-two";
import AICPUFeedbackFooter from "./ai-feedback/cpu-feedback";
import AIFeedbackFooter from "./ai-feedback";
import { actionAccessMain, validationOfNumberWithOnly1Decimal } from "../../../auth";

const DoctorNotesOrders = forwardRef(
  (
    {
      patientId,
      patientname,
      patientgender,
      patientage,
      doctorId,
      appointmentId,
      showSuccessToast,
      showErrorToast,
      showSubLoader,
      dismissSubLoader,
      searchRef,
      triggerFetchRecommendMedicines,
      fetchDoctorNotesEmptyIndication,
      fetchPatientHealthData,
    },
    ref
  ) => {
    useEffect(() => {
      if (triggerFetchRecommendMedicines) {
        fetchRecommendMedicines();
      }
    }, [triggerFetchRecommendMedicines]);

    const useGPUChatbot = !!navigator.gpu;
    // const useGPUChatbot = false;

    const [doctorNotes, setDoctorNotes] = useState({});
    const [recommendMedicinesAiResponse, setRecommendMedicinesAiResponse] =
      useState([]);
    const [recommendProceduresAiResponse, setRecommendProceduresAiResponse] =
      useState([]);
    const [recommendLabsAiResponse, setRecommendLabsAiResponse] = useState([]);
    const [recommendImagingAiResponse, setRecommendImagingAiResponse] =
      useState([]);

    const [combinedOrderAIResponses, setCombinedOrderAIResponses] =
      useState(null);

    const [
      selectedRecommendedMedicinesAiResponses,
      setSelectedRecommendedMedicinesAiResponses,
    ] = useState([]);

    const [selectedOrderAiResponses, setSelectedOrderAiResponses] = useState(
      []
    );

    const [inputProceduresMasterData, setInputProceduresMasterData] = useState(
      []
    );
    const [inputLabsMasterData, setInputLabsMasterData] = useState([]);
    const [inputImagingMasterData, setInputImagingMasterData] = useState([]);
    const [inputDoctorFavMedicineData, setInputDoctorFavMedicineData] =
      useState([]);
    const [addAppointmentDieticianNotes, setAddAppointmentDieticianNotes] = useState();
    const [dieticianNotes, setDieticianNotes] = useState([]);
    const [deleteDieticianId, setDeleteDieticianId] = useState(null);
    const [updateDieticianNotes, setUpdateDieticianNotes] = useState(null);

    const [addGeneralAppointmentDieticianNotes, setAddGeneralAppointmentDieticianNotes] = useState();
    const [generalDieticianNotes, setGeneralDieticianNotes] = useState([]);
    const [deleteGeneralDieticianId, setGeneralDeleteDieticianId] = useState(null);
    const [updateGeneralDieticianNotes, setUpdateGeneralDieticianNotes] = useState(null);

    const noAiResponseModal = useRef();
    const aiRecommendMedicineRef = useRef();
    const deleteDieticianNotesModal = useRef();
    const editDieticianNotesModal = useRef();

    const deleteGeneralDieticianNotesModal = useRef();
    const editGeneralDieticianNotesModal = useRef();

    useEffect(() => {
      // Create an async function to fetch data
      const fetchData = async () => {
        try {
          const labsData = await fetchMasterData("labs");
          const imagingData = await fetchMasterData("imaging");
          const proceduresData = await fetchMasterData("procedures");

          // Update state with fetched data
          setInputLabsMasterData(labsData);
          setInputImagingMasterData(imagingData);
          setInputProceduresMasterData(proceduresData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData(); // Call the async function
    }, []); // Empty dependency array to ensure it runs only once on mount

    useEffect(() => {
      // Create an async function to fetch data

      const fetchDoctorMedicines = async () => {
        // console.log("Function called");
        const inputToFetch = {
          input: {
            doctorid: doctorId,
          },
        };
        // showLoader();
        // console.log(inputToFetch);
        try {
          const data = await GetDoctorFavMedicine(inputToFetch.input);
          setInputDoctorFavMedicineData(data?.output?.data);
          // console.log(data);
        } catch (error) {
          console.error("Error fetching Recent Medications data:", error);
        } finally {
          // dismissLoader();
        }
      };
      fetchDoctorMedicines();
    }, []); // Empty dependency array to ensure it runs only once on mount

    //------------AI Medicine Recommendations

    const [editMedicine, setEditMedicine] = useState(null);

    const today = moment().format("DD-MM-YYYY");
    const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");
    const handleEditMedicine = (medicine) => {
      // const medicinetoedit = {
      //   id: medicine.id,
      //   medicinename: medicine.name,
      //   composition: medicine.composition,
      //   medicinetype: "Tablet",
      //   dosage: "",
      //   dosageunit: "mg",
      //   medicationstartdate: today, // SETTING DEFAULTS
      //   medicationenddate: fiveDaysFromToday, // SETTING DEFAULTS
      //   frequency: 1, // SETTING DEFAULTS
      //   morning: 1, // SETTING DEFAULTS
      //   evening: 0,
      //   night: 0,
      //   prescribedby: "",
      //   hospitalname: "",
      //   beforefood: 1, // SETTING DEFAULTS
      //   afterfood: 0,
      //   // issaved: false,
      // };
      setEditMedicine(medicine);
    };

    const handleEditMedicineChange = (field, value) => {
      setEditMedicine((prev) => ({ ...prev, [field]: value }));
    };

    const handleSaveEditMedicine = () => {
      if (editMedicine.medicinename === "") {
        showErrorToast("Please enter medicine name to continue");
        return;
      }
      setRecommendMedicinesAiResponse((prev) =>
        prev.map((m) => (m.id === editMedicine.id ? editMedicine : m))
      );

      // console.log(selectedMedicines);
      setEditMedicine(null);
    };

    const [compositionEditKey, setCompositionEditKey] = useState(0); // to rerender the composition input box

    const fetchCompositionForEdit = async (medicineName) => {
      if (!medicineName) {
        showErrorToast("Please enter medicine name first to fetch composition");
      } else {
        const inputData = {
          input: {
            name: medicineName,
          },
        };

        // console.log(inputData.input);

        try {
          // showLoader();
          // console.log(inputData);
          const result = await GetAIMedicineComposition(inputData.input);
          // console.log(result);
          if (result) {
            showSuccessToast("Fetched Medicine composition successfully");
            handleEditMedicineChange(
              "composition",
              result.chemical_composition
            );
            setCompositionEditKey((prevKey) => prevKey + 1);
            // handleNewMedicineChange("medicinetype", result.type);
          }
        } catch (error) {
          console.error("Error Fetching Medicine composition:", error);
          showErrorToast(
            "An error occurred while Fetching Medicine composition"
          );
        } finally {
          // dismissLoader();
        }

        // fetchDoctorNotes();

        // console.log("Clicked");
      }
    };

    //------------------Recommendation code

    // Function to get banner styles based on medicine.from value
    const getBannerStyles = (value) => {
      switch (value) {
        case "new":
          return { bg: "green.500", color: "white" };
        case "recent":
          return { bg: "blue.500", color: "white" };
        case "current":
          return { bg: "gray.500", color: "white" };
        default:
          return { bg: "gray.100", color: "black" }; // Default style
      }
    };

    // function for no data msg

    function checkIndividualFields(jsonInput) {
      if (typeof jsonInput !== "object" || jsonInput === null) {
        return null;
      }

      const {
        chiefcomplaint,
        historyofpresentillness,
        hpitext,
        physicalexamtext,
        rostext,
        physicalexam,
        reviewofsystem,
      } = jsonInput;

      const isArrayNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;
      const isChiefComplaintNotNull = (obj) => obj && obj.chiefcomplaint;

      const isHPITextNotNull = (obj) => obj && obj.description;
      const isPETextNotNull = (obj) => obj && obj.description;
      const isROSTextNotNull = (obj) => obj && obj.description;

      const fieldStatus = {
        chiefcomplaint: isChiefComplaintNotNull(chiefcomplaint),
        historyofpresentillness:
          isArrayNotEmpty(historyofpresentillness) || isHPITextNotNull(hpitext),
        physicalexam:
          isArrayNotEmpty(physicalexam) || isPETextNotNull(physicalexamtext),
        reviewofsystem:
          isArrayNotEmpty(reviewofsystem) || isROSTextNotNull(rostext),
      };

      const filledCount = Object.values(fieldStatus).filter(Boolean).length;

      return {
        fieldStatus,
        filledCount,
      };
    }

    function renderModalContent(jsonInput) {
      const { fieldStatus, filledCount } = checkIndividualFields(jsonInput);

      const fieldLabels = {
        chiefcomplaint: "Chief Complaint",
        historyofpresentillness: "History of Present Illness",
        physicalexam: "Physical Exam",
        reviewofsystem: "Review of Systems",
      };

      const getMessage = () => {
        if (filledCount >= 2) return null;
        if (filledCount === 1) return "Please fill in one more field.";
        return "Please fill in at least two fields.";
      };

      return (
        <>
          <Text fontSize="14px" fontWeight="600">
            Insufficient data is available to draw inferences. Please ensure
            that relevant notes are provided.
          </Text>
          <ul>
            {Object.entries(fieldStatus).map(([key, isFilled]) => (
              <li
                key={key}
                style={{ textDecoration: isFilled ? "line-through" : "none" }}
              >
                {fieldLabels[key]}
              </li>
            ))}
          </ul>
          {getMessage() && (
            <Text fontSize="14px" fontWeight="600">
              {getMessage()}
            </Text>
          )}
        </>
      );
    }

    //------------------------------AI Feedback

    const [aiInputForFeedback, setAiInputForFeedback] = useState({});
    const [aiOutputForFeedback, setAiOutputForFeedback] = useState({});

    //------------------------------

    function checkFields(jsonInput) {
      // Check if the input is an object
      if (typeof jsonInput !== "object" || jsonInput === null) {
        return false;
      }

      // Extract the necessary fields
      const {
        chiefcomplaint,
        historyofpresentillness,
        hpitext,
        physicalexamtext,
        rostext,
        physicalexam,
        reviewofsystem,
      } = jsonInput;

      // Helper function to check if an array is not empty
      const isArrayNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;

      // Helper function to check if the chief complaint is not null
      const isChiefComplaintNotNull = (obj) => obj && obj.chiefcomplaint;

      const isHPITextNotNull = (obj) => obj && obj.description;
      const isPETextNotNull = (obj) => obj && obj.description;
      const isROSTextNotNull = (obj) => obj && obj.description;

      // Count the non-null fields
      let nonNullCount = 0;
      if (isChiefComplaintNotNull(chiefcomplaint)) nonNullCount++;

      if (isHPITextNotNull(hpitext)) nonNullCount++;
      if (isPETextNotNull(physicalexamtext)) nonNullCount++;
      if (isROSTextNotNull(rostext)) nonNullCount++;
      if (isArrayNotEmpty(historyofpresentillness)) nonNullCount++;
      if (isArrayNotEmpty(physicalexam)) nonNullCount++;
      if (isArrayNotEmpty(reviewofsystem)) nonNullCount++;

      // Return true if at least two fields are non-null
      return nonNullCount >= 2;
    }

    const [AiInput, setAiInput] = useState(null);

    const fetchRecommendMedicines = async () => {
      showLoader();
      setAiInput(null);

      if (checkFields(doctorNotes) === true) {
        const result = await FetchMyHealthAIData(
          {},
          patientId,
          patientname,
          patientgender,
          patientage
        );
        const doctornotes = doctorNotes;
        const procedures_master_data = inputProceduresMasterData;
        const imaging_master_data = inputImagingMasterData;
        const labs_master_data = inputLabsMasterData;
        const doctor_medicines = inputDoctorFavMedicineData;
        let Aiprompts = {
          ...result,
          doctornotes,
          procedures_master_data,
          imaging_master_data,
          labs_master_data,
          doctor_medicines,
        };
        // console.log(Aiprompts);
        // console.log(doctor_medicines);

        setAiInput(Aiprompts);

        setAiInputForFeedback(Aiprompts);
        const response = await AiRecommendMedicines(Aiprompts);
        // console.log("response", response);
        setAiOutputForFeedback(response);
        if (
          response &&
          response?.medicines?.recommended_medicines &&
          response?.medicines?.recommended_medicines?.length > 0
        ) {
          // Adding defaults to ai response

          const formattedMedicines =
            response?.medicines?.recommended_medicines?.map(
              (medicine, index) => ({
                id: index, // Setting the id as the index of the object
                medicinename: medicine.name,
                composition: medicine.composition,
                medicinetype: "Tablet",
                dosage: "",
                dosageunit: "mg",
                medicationstartdate: today, // Default to today
                medicationenddate: fiveDaysFromToday, // Default to 5 days from today
                frequency: 1, // Default value
                morning: 1, // Default value
                evening: 0, // Default value
                night: 0, // Default value
                prescribedby: "",
                hospitalname: "",
                beforefood: 1, // Default value
                afterfood: 0, // Default value
                from: medicine.source,
                reason: medicine?.reason,
                // issaved: false, // Default value
              })
            );

          setRecommendMedicinesAiResponse(formattedMedicines);

          // setRecommendMedicinesAiResponse(response?.recommended_medicines);
          setSelectedRecommendedMedicinesAiResponses([]);
          setSelectedOrderAiResponses([]);

          setEditMedicine(null);
          aiRecommendMedicineRef.current.openModal();
        }
        if (
          response &&
          response?.labs?.recommended_lab &&
          response?.labs.recommended_lab?.length > 0
        ) {
          // recommended labs
          // const formattedLabs = response?.labs?.recommended_lab?.map(
          //   (item, index) => ({
          //     id: item.id,
          //     type: item.type,
          //     subtype: item.subtype,
          //     reason: item.reason,
          //     // category: "labs",
          //   })
          // );
          const formattedLabs = response?.labs?.recommended_lab?.map(
            (item, index) => {
              const { name, reason } = item;

              return {
                name: name,
                reason: reason,
                // category: "labs",
              };
            }
          );
          setRecommendLabsAiResponse(formattedLabs);
          setSelectedOrderAiResponses([]);
        }
        if (
          response &&
          response?.procedures?.recommended_procedures &&
          response?.procedures?.recommended_procedures?.length > 0
        ) {
          // recommended procedures
          // const formattedProcedures =
          //   response?.procedures?.recommended_procedures?.map(
          //     (item, index) => ({
          //       id: item.id,
          //       type: item.type,
          //       subtype: item.subtype,
          //       reason: item.reason,
          //       // category: "procedure",
          //     })
          //   );
          const formattedProcedures =
            response?.procedures?.recommended_procedures?.map((item) => {
              const { name, reason } = item;

              return {
                name: name,
                reason: reason,
              };
            });
          console.log(response?.procedures?.recommended_procedures);
          console.log(formattedProcedures);

          setRecommendProceduresAiResponse(formattedProcedures);
          setSelectedOrderAiResponses([]);
        }
        if (
          response &&
          response?.imaging?.recommended_imaging &&
          response?.imaging?.recommended_imaging?.length > 0
        ) {
          // const formattedImaging = response?.imaging?.recommended_imaging?.map(
          //   (item, index) => ({
          //     id: item.id,
          //     type: item.type,
          //     subtype: item.subtype,
          //     reason: item.reason,
          //     // category: "imaging",
          //   })
          // );
          const formattedImaging = response?.imaging?.recommended_imaging?.map(
            (item, index) => {
              const { name, reason } = item;

              return {
                name: name,
                reason: reason,
                // category: "imaging",
              };
            }
          );
          // recommended imaging
          setRecommendImagingAiResponse(formattedImaging);
          setSelectedOrderAiResponses([]);
        }

        if (
          response?.imaging?.recommended_imaging?.length > 0 ||
          response?.procedures?.recommended_procedures?.length > 0 ||
          response?.labs?.recommended_lab?.length > 0
        ) {
          // Combine all formatted arrays
          const combinedOrderResponses = [
            ...(response?.labs?.recommended_lab?.map((item, index) => ({
              name: item.name,
              reason: item.reason,
              category: "labs",
            })) || []),
            // ...(response?.procedures?.recommended_procedures?.map(
            //   (item, index) => ({
            //     id: item.id,
            //     type: item.type,
            //     subtype: item.subtype,
            //     reason: item.reason,
            //     category: "procedure",
            //   })
            // ) || []),
            ...(response?.procedures?.recommended_procedures?.map(
              (item, index) => ({
                name: item.name,
                reason: item.reason,
                category: "procedure",
              })
            ) || []),

            ...(response?.imaging?.recommended_imaging?.map((item, index) => ({
              name: item.name,
              reason: item.reason,
              category: "imaging",
            })) || []),
          ];
          // const combinedOrderResponses = [
          //   ...(recommendLabsAiResponse || []),
          //   ...(recommendProceduresAiResponse|| []),
          //   ...(recommendImagingAiResponse|| []),
          // ];
          setCombinedOrderAIResponses(combinedOrderResponses);
        } else {
          // noAiResponseModal.current.openModal();
          // setTimeout(() => {
          //   noAiResponseModal.current.closeModal();
          // }, 3000); // 3 seconds delay
          if (noAiResponseModal.current) {
            noAiResponseModal.current.openModal();
            setTimeout(() => {
              if (noAiResponseModal.current) {
                // Check again before closing
                noAiResponseModal.current.closeModal();
              }
            }, 3000); // 3 seconds delay
          }
        }
      } else {
        // noAiResponseModal.current.openModal();
        // setTimeout(() => {
        //   noAiResponseModal.current.closeModal();
        // }, 3000); // 3 seconds delay
        if (noAiResponseModal.current) {
          noAiResponseModal.current.openModal();
          setTimeout(() => {
            if (noAiResponseModal.current) {
              // Check again before closing
              noAiResponseModal.current.closeModal();
            }
          }, 3000); // 3 seconds delay
        }
      }

      dismissLoader();
    };

    // Toggle selection of AI responses
    const handleRecommendedMedicinesCheckboxChange = (index) => {
      setSelectedRecommendedMedicinesAiResponses((prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((i) => i !== index)
          : [...prevSelected, index]
      );
    };

    const handleRecommendedOrdersCheckboxChange = (index) => {
      setSelectedOrderAiResponses((prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((i) => i !== index)
          : [...prevSelected, index]
      );
    };

    const transformCheckedAIOrderStateToAPIFormat = async (selectedItems) => {
      // console.log(selectedItems);
      const transformedCategories = {};

      // Mapping for category name adjustments
      const categoryMappings = {
        labs: "lab",
        procedures: "procedure",
        imaging: "imaging",
      };

      selectedItems.forEach((item) => {
        let categoryKey = item.category;

        if (categoryMappings[item.category]) {
          categoryKey = categoryMappings[item.category];
        }

        if (!transformedCategories[categoryKey]) {
          transformedCategories[categoryKey] = [];
        }

        transformedCategories[categoryKey].push({
          // ordername_cd: item.id,
          // entitytype: item.type,
          id: null,
          entitytype: categoryKey,
          ordername: item.name,
        });
      });

      // console.log(transformedCategories);

      return transformedCategories;
    };

    const formatDate = (date) => {
      // Check if the date is already in YYYY-MM-DD format
      if (moment(date, "YYYY-MM-DD", true).isValid()) {
        return date;
      }
      // If not, assume it's in DD-MM-YYYY format and convert it to YYYY-MM-DD
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    };

    const saveSelectedRecommendedMedicinesToOrder = async () => {
      // console.log(selectedRecommendedMedicinesAiResponses); // list of index

      // fetch medicine stored at those index
      const medicinesSelected = selectedRecommendedMedicinesAiResponses.map(
        (index) => recommendMedicinesAiResponse[index]
      );

      const ordersSelected = selectedOrderAiResponses.map(
        (index) => combinedOrderAIResponses[index]
      );

      // console.log(medicinesSelected);

      const recommendedMedicinesToSave = medicinesSelected.map(
        (medicine, index) => ({
          id: null,
          isactive: 1,
          medicinename: medicine.medicinename,
          chemical_composition: medicine.composition,
          medicinetype: medicine.medicinetype,
          dosage: medicine.dosage,
          dosageunit: medicine.dosageunit,
          // startdate: moment(medicine.medicationstartdate).format("YYYY-MM-DD"),
          // enddate: moment(medicine.medicationenddate).format("YYYY-MM-DD"),
          startdate: formatDate(medicine.medicationstartdate),
          enddate: formatDate(medicine.medicationenddate),
          frequency: medicine.frequency,
          morning: medicine.morning,
          evening: medicine.evening,
          night: medicine.night,
          // prescribedby: "",
          // hospitalname: "",
          beforefood: medicine.beforefood,
          afterfood: medicine.afterfood,
        })
      );

      const inputData = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          // medicinedetail: recommendedMedicinesToSave,
          ...(recommendedMedicinesToSave.length > 0 && {
            medicinedetail: recommendedMedicinesToSave,
          }),

          // ...(await transformCheckedAIOrderStateToAPIFormat(ordersSelected)),
          ...(ordersSelected.length > 0 && {
            ...(await transformCheckedAIOrderStateToAPIFormat(ordersSelected)),
          }),
        },
      };

      // console.log(inputData);
      try {
        showLoader();
        const result = await updateDoctorNotes(inputData.input);
        // console.log(result);
        if (result) {
          showSuccessToast("Selected items saved to orders successfully");
          fetchDoctorNotesEmptyIndication();
          fetchDoctorNotes();
        }
      } catch (error) {
        console.error("Error adding medicine to orders:", error);
        showErrorToast("An error occurred while adding medicine to orders");
      } finally {
        dismissLoader();
      }
    };

    //-----------AI Insights on Recommendation modal
    const chatContainerRef = useRef(null);
    const [aianswers, setAIAnswers] = useState([]);
    const [remainingquestions, setRemainingQuestions] = useState([]);
    const [aiquestion, setAIAnalysis] = useState(null);
    const [AiInsightsInput, setAiInsightsInput] = useState(null);
    const [initialRender, setInitialRender] = useState(true);
    const [animate, setAnimate] = useState(false);
    const [showAIModal, setShowAIModal] = useState(true);

    const AIChatModel = useRef();

    useEffect(() => {
      if (animate) {
        const timer = setTimeout(() => {
          setAnimate(false);
        }, 500);
        return () => clearTimeout(timer);
      }
    }, [animate]);

    useEffect(() => {
      // Scroll to bottom whenever aianswers changes
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, [aianswers]);

    const fetchAiDoctorPrompts = async (medicinename) => {
      showLoader();
      setRemainingQuestions([]);
      setAiInsightsInput(null);
      setAIAnswers([]);
      setInitialRender(true);
      const result = await FetchMyHealthAIData(
        {},
        patientId,
        patientname,
        patientgender,
        patientage
      );
      // console.log(result);
      let Aiprompts = { ...result, medicinename };
      setAiInsightsInput(Aiprompts);
      const response = await FetchMedicineAIPrompts(Aiprompts);
      if (response.generated_question) {
        setAIAnalysis(response);
        setRemainingQuestions(response.generated_question);
        AIChatModel.current.openRight();
      }
      dismissLoader();
    };

    const GetAIAnswer = async (prompt) => {
      // Display question immediately
      const newQuestion = { question: prompt, answer: "" };
      setAIAnswers((prevState) => [...prevState, newQuestion]);

      // Remove the question from remaining questions immediately
      setRemainingQuestions((prevState) =>
        prevState.filter((question) => question.value !== prompt)
      );

      // Trigger animation
      setAnimate(true);

      // Set initialRender to false after the first question is clicked
      setInitialRender(false);
      let AichatInput = { ...AiInsightsInput, query: prompt };

      const response = await FetchMedicineAIAnswer(AichatInput);

      if (response) {
        // Replace "typing" with actual answer
        setAIAnswers((prevState) =>
          prevState.map((item) =>
            item.question === prompt && item.answer === ""
              ? { question: item.question, answer: response.answer }
              : item
          )
        );
      }
    };

    //-----------search overlay

    const [searchValue, setSearchValue] = useState("");

    const [searchMasterData, setSearchMasterData] = useState([]);
    const [labsMasterData, setLabsMasterData] = useState([]);
    const [imagingMasterData, setImagingMasterData] = useState([]);
    const [proceduresMasterData, setProceduresMasterData] = useState([]);

    const { isLoading, showLoader, dismissLoader } = useLoader();

    const dynamicModalPrescriptionDiscard = useRef();

    const fetchMasterData = async (category) => {
      try {
        const inputData = {
          input: {
            type: category,
            doctorid: doctorId,
            appointmentid: appointmentId,
          },
        };
        // console.log(inputData);
        const data = await GetDoctorMasterData(inputData.input);
        // Add "category" key to each object in the array
        const modifiedData = data.map((item) => {
          return { ...item, category: category };
        });
        // console.log(modifiedData);
        return modifiedData;
      } catch (error) {
        console.error("Error fetching Master data:", error);
        return [];
      }
    };

    // const [recentMedicines, setRecentMedicines] = useState([]);

    function processRecentMedicationsData(medicationArray) {
      if (!medicationArray || medicationArray.length === 0) {
        return [];
      }

      const today = moment().format("DD-MM-YYYY");
      const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");

      return medicationArray.map((med) => ({
        id: med.id,
        category: "medicine",
        type: med.medicinetype,
        subtype: med.medicinename,
        medicinename: med.medicinename,
        medicinetype: med.medicinetype,
        dosage: med.dosage,
        dosageunit: med.dosageunit,
        medicationstartdate: today,
        medicationenddate: fiveDaysFromToday,
        duration:
          Math.abs(
            new Date(med.medicationstartdate) - new Date(med.medicationenddate)
          ) /
            (1000 * 60 * 60 * 24) +
          " days",
        frequency: med.frequency,
        morning: med.morning,
        evening: med.evening,
        night: med.night,
        beforefood: med.beforefood,
        afterfood: med.afterfood,
        composition: med.chemicalcomposition,
      }));
    }

    const fetchRecentMedicines = async () => {
      // console.log("Function called");
      const inputToFetch = {
        input: {
          doctorid: doctorId,
        },
      };
      // showLoader();
      try {
        const data = await GetDoctorFavMedicine(inputToFetch.input);
        // console.log(data?.output?.data);
        // console.log(processRecentMedicationsData(data?.output?.data));
        return processRecentMedicationsData(data?.output?.data);
      } catch (error) {
        console.error("Error fetching Recent Medications data:", error);
      } finally {
        // dismissLoader();
      }
    };

    const fetchSearchPanelData = async () => {
      try {
        const labsData = fetchMasterData("labs");
        const imagingData = fetchMasterData("imaging");
        const proceduresData = fetchMasterData("procedures");
        const recentMedicines = fetchRecentMedicines();

        const [labs, imaging, procedures, medicines] = await Promise.all([
          labsData,
          imagingData,
          proceduresData,
          recentMedicines,
        ]);

        setLabsMasterData(labs);
        setImagingMasterData(imaging);
        setProceduresMasterData(procedures);

        const masterData = [
          ...labs,
          ...imaging,
          ...procedures,
          ...medicines,
        ].filter((item) => {
          if (!item.type) {
            // If item exists but doesn't have a type, set "No Type"
            item.type = "No Type";
          }

          // Return true if the type is not "other", otherwise false
          return item.type.toLowerCase() !== "other";
        });

        // console.log(masterData);

        setSearchMasterData(masterData);

        // console.log(searchMasterData);
      } catch (error) {
        console.error("Error fetching Search Master data:", error);
      }
    };

    const openSearch = useRef();
    useImperativeHandle(searchRef, () => ({
      openSearchOverlay: () => {
        fetchSearchPanelData();
        // console.log("Hello from Search!");
        openSearch.current.openRight();
      },
    }));

    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (item) => {
      setSelectedItems((prevSelectedItems) => {
        if (
          prevSelectedItems.some((selectedItem) => selectedItem.id === item.id)
        ) {
          // If the item is already selected, remove it from the selected items
          return prevSelectedItems.filter(
            (selectedItem) => selectedItem.id !== item.id
          );
        } else {
          // Otherwise, add the item to the selected items
          if (item.category === "medicine") {
            // console.log("case encountered");
            return [
              ...prevSelectedItems,
              {
                ...item,
                category: item.category,
                id: item.id,
                subtype: item.subtype,
              }, // Copy the entire 'item' object
            ];
          } else {
            return [
              ...prevSelectedItems,
              { category: item.category, id: item.id, subtype: item.subtype },
            ];
          }
        }
      });
    };

    const isChecked = (id) => {
      return selectedItems.some((selectedItem) => selectedItem.id === id);
    };

    function transformMedicineSearchStateToApiInput(med) {
      // console.log(med);
      const today = moment().format("YYYY-MM-DD");
      const fiveDaysFromToday = moment().add(5, "days").format("YYYY-MM-DD");

      const frequency =
        med.frequency ||
        (med.morning ? 1 : 0) + (med.evening ? 1 : 0) + (med.night ? 1 : 0);

      return {
        id: null,
        medicinename: med.medicinename,
        medicinetype: med.medicinetype,
        dosage: med.dosage,
        dosageunit: med.dosageunit,
        startdate: today,
        enddate: fiveDaysFromToday,
        frequency: frequency,
        morning: med.morning,
        evening: med.evening,
        night: med.night,
        beforefood: med.beforefood,
        afterfood: med.afterfood,
        chemical_composition: med.composition,
        isactive: 1,
      };
    }

    const transformSearchStateToAPIFormat = (selectedItems) => {
      const transformedCategories = {};

      // Mapping for category name adjustments
      const categoryMappings = {
        labs: "lab",
        procedures: "procedure",
        medicine: "medicinedetail",
      };

      selectedItems.forEach((item) => {
        let categoryKey = item.category;
        let entityType = item.category;

        if (categoryMappings[item.category]) {
          categoryKey = categoryMappings[item.category];
          entityType = categoryMappings[item.category];
        }

        if (!transformedCategories[categoryKey]) {
          transformedCategories[categoryKey] = [];
        }

        if (categoryKey === "medicinedetail") {
          // console.log(item);
          const transformedMedicine =
            transformMedicineSearchStateToApiInput(item);
          transformedCategories[categoryKey].push(transformedMedicine);
        } else {
          transformedCategories[categoryKey].push({
            id: item.id,
            entitytype: entityType,
            ordername: item.subtype,
          });
        }
      });

      return transformedCategories;
    };

    const handleSearchSaveButtonClick = async () => {
      if (selectedItems.length === 0) {
        showErrorToast("Please select any value to save");
        openSearch.current.closeRight();
        return false;
      }

      const inputToSave = {
        input: {
          doctorid: doctorId,
          patientid: patientId,
          appointmentid: appointmentId,
          ...transformSearchStateToAPIFormat(selectedItems),
        },
      };

      // console.log(inputToSave);

      try {
        const result = await updateDoctorNotes(inputToSave.input);
        if (result) {
          showSuccessToast("Order Saved successfully");
          fetchDoctorNotesEmptyIndication();

          setSelectedItems([]);
          setSearchValue("");

          openSearch.current.closeRight();
        }
      } catch (error) {
        console.error("Error in Update Doctor Notes:", error);
        showErrorToast("Error Happened While Saving.");
        openSearch.current.closeRight();
      }
      fetchDoctorNotes();
    };

    const openSearchSpecificOrder = useRef();

    const [specificSearchOrderData, setSpecificSearchOrderData] = useState([]);

    const openSpecificSearchOrderOverlay = async (category) => {
      let dataCategory = "";
      if (category === "lab") {
        dataCategory = "labs";
      } else if (category === "procedure") {
        dataCategory = "procedures";
      } else {
        dataCategory = category;
      }

      try {
        const result = await fetchMasterData(dataCategory); // Await the promise to get the resolved data
        const filteredResult = result.filter((item) => {
          if (!item.type) {
            // If item exists but doesn't have a type, set "No Type"
            item.type = "No Type";
          }

          // Return true if the type is not "other", otherwise false
          return item.type.toLowerCase() !== "other";
        });
        setSpecificSearchOrderData(filteredResult);
        addOrder.current.closeRight();
        openSearchSpecificOrder.current.openRight();
      } catch (error) {
        console.error("Error fetching specific search order data:", error);
        showErrorToast("Error fetching specific search order data.");
      }
    };

    const handleSpecificOrderSearchSaveButtonClick = async () => {
      const inputToSave = {
        input: {
          doctorid: doctorId,
          patientid: patientId,
          appointmentid: appointmentId,
          ...transformSearchStateToAPIFormat(selectedItems),
        },
      };

      // console.log(inputToSave);

      try {
        const result = await updateDoctorNotes(inputToSave.input);
        if (result) {
          showSuccessToast("Order Saved successfully");

          fetchDoctorNotesEmptyIndication();
          setSelectedItems([]);
          setSearchValue("");

          openSearchSpecificOrder.current.closeRight();
        }
      } catch (error) {
        console.error("Error in Update Doctor Notes:", error);
        showErrorToast("Error Happened While Saving.");
        openSearchSpecificOrder.current.closeRight();
        addOrder.current.closeRight();
      }
      fetchDoctorNotes();
    };

    // ---------------
    // Throughout Code, Order refers to Procedure, Labs & Imaging

    const addOrder = useRef(); // for Procedure, Labs and Imaging
    const addPrescription = useRef(); // for medication

    const prescriptionComponentRef = useRef(null);

    const handleSaveButtonClick = () => {
      if (prescriptionComponentRef.current) {
        prescriptionComponentRef.current.saveMedicineToOrders();
        prescriptionComponentRef.current.saveCurrentPatientMedicineToOrders();
      }
      // addPrescription.current.closeRight();
      fetchDoctorNotes();
      fetchPatientHealthData();
      fetchDoctorNotesEmptyIndication();
    };

    // states to have add orders functionality
    const [currentOrderType, setCurrentOrderType] = useState(""); // Procedure, Labs or Imaging
    const [currentOrderNames, setCurrentOrderNames] = useState([
      { id: 1, name: "" },
    ]);

    const [orderInputFields, setOrderInputFields] = useState([{ id: 1 }]);

    // states to store fetched doctor notes
    const [currentLabData, setCurrentLabData] = useState([]);
    const [currentImagingData, setCurrentImagingData] = useState([]);
    const [currentProcedureData, setCurrentProcedureData] = useState([]);
    const [currentMedicationData, setCurrentMedicationData] = useState([]);

    // State for disabling/enabling save button
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    const addOrderInputField = () => {
      const newId = orderInputFields.length + 1;
      setOrderInputFields([...orderInputFields, { id: newId }]);
      setCurrentOrderNames([...currentOrderNames, { id: newId, name: "" }]);
    };

    const deleteOrderInputField = (id) => {
      setOrderInputFields(orderInputFields.filter((field) => field.id !== id));
      setCurrentOrderNames(
        currentOrderNames.filter((order) => order.id !== id)
      );
    };

    const openAddOrder = (orderType) => {
      setOrderInputFields([{ id: 1 }]); // reset to one input box, whenever opened;
      setCurrentOrderType(orderType); // set the current order type
      setIsSaveButtonDisabled(false); // enable save button when modal opens
      if (addOrder.current) {
        addOrder.current.openRight();
      }
    };

    const openAddPrescription = () => {
      if (addPrescription.current) {
        addPrescription.current.openRight();
      }
    };

    const handleAddOrderName = (id, value) => {
      setCurrentOrderNames((prevNames) =>
        prevNames.map((order) =>
          order.id === id ? { ...order, name: value } : order
        )
      );
    };

    //   ---- Fetch Doctor Notes -----

    function processMedicationsData(medicationArray) {
      if (!medicationArray || medicationArray.length === 0) {
        return [];
      }

      return medicationArray.map((med) => ({
        id: med.id,
        name: med.medicinename,
        type: med.medicinetype,
        dosage: med.dosage,
        dosageunit: med.dosageunit,
        startdate: moment(med.medicationstartdate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
        enddate: moment(med.medicationenddate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        ),
        duration:
          Math.abs(
            new Date(med.medicationstartdate) - new Date(med.medicationenddate)
          ) /
            (1000 * 60 * 60 * 24) +
          1 +
          " days",
        frequency: med.frequency,
        inMorning: med.morning,
        inEvening: med.evening,
        inNight: med.night,
        beforefood: med.beforefood,
        afterfood: med.afterfood,
        chemicalcomposition: med.chemicalcomposition,

        // ----- new

        routeofadministration: med.routeofadministration,
        timingofinjection: med.timingofinjection,
        quantitytobeapplied: med.quantitytobeapplied,
        areaofapplication: med.areaofapplication,
        diluent: med.diluent,
        additives: med.additives,

        rateofinfusionvalue: med.rateofinfusionvalue,
        rateofinfusionunit: med.rateofinfusionunit,
        sos: med.sos,
        custommessage  : med.custommessage
      }));
    }

    const fetchDoctorNotes = async () => {
      const inputToFetch = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
        },
      };

      showLoader();
      try {
        const data = await GetDoctorNotes(inputToFetch.input);
        setDoctorNotes(data?.output?.data);
        setCurrentLabData(data.output.data?.orders?.lab);
        setCurrentImagingData(data.output.data?.orders?.imaging);
        setCurrentProcedureData(data.output.data?.orders?.procedure);
        setCurrentMedicationData(
          processMedicationsData(data.output.data?.orders?.medication)
        );
        setDieticianNotes(data.output.data?.dieticiannote)
        setGeneralDieticianNotes(data.output.data?.generalnotes)
      } catch (error) {
        console.error("Error fetching Doctor Notes data:", error);
      } finally {
        dismissLoader();
      }
    };
    // dietician notes start
    const addDieticianNotes = async () => {
      const inputToSave = {
        input: {
          appointmentid: appointmentId,
          doctorid: doctorId,
          dietnotedetail: [
            {
              dietnote: addAppointmentDieticianNotes
            }
          ]
        }
      }

        try {
          const result = await createAppointmentDieticianNotes(inputToSave.input);
          setAddAppointmentDieticianNotes('')
          fetchDoctorNotes()
          return result;
        } catch (error) {
          console.error("Error in Add Dietician Notes:", error);
          throw error;
        }
      };

      function openDieticianModal(id, operation, value){
        setDeleteDieticianId(id)
        if(operation === 'edit'){
          setUpdateDieticianNotes(value)
          editDieticianNotesModal.current.openModal()
        } else {
          deleteDieticianNotesModal.current.openModal()
        }
      }

      async function deleteDieticianNote(){
        const inputToDelete = {
            input: {
              appointmentid: appointmentId,
              doctorid: doctorId,
              id: deleteDieticianId
            }
        }

        try {
          const result = await deleteAppointmentDieticianNotes(inputToDelete.input);
          setDeleteDieticianId("")
          fetchDoctorNotes();
          return result;
        } catch (error) {
          console.log('Error deleting Ditiecian notes', error)
          throw error;
        }
      }

      async function editDieticianNotes(){
        const inputToUpdate = {
          input: {
            appointmentid: appointmentId,
            doctorid: doctorId,
            id: deleteDieticianId,
            dietnote: addAppointmentDieticianNotes
          }
        }

        try {
          const result = await createAppointmentDieticianNotes(inputToUpdate.input);
          setUpdateDieticianNotes('')
          setAddAppointmentDieticianNotes('')
          fetchDoctorNotes()
          return result;
        } catch (error) {
          console.log('Error in updating Dietician notes', error)
          throw error;
        }
      }

    // Dietiician notes end

    // general dietician notes start
    const addGeneralDieticianNotes = async () => {
      const inputToSave = {
        input: {
          appointmentid: appointmentId,
          doctorid: doctorId,
          generalnotedetail: [
            {
              generalnote: addGeneralAppointmentDieticianNotes
            }
          ]
        }
      }

        try {
          const result = await createGeneralAppointmentDieticianNotes(inputToSave.input);
          setAddGeneralAppointmentDieticianNotes('')
          fetchDoctorNotes()
          return result;
        } catch (error) {
          console.error("Error in Add Dietician Notes:", error);
          throw error;
        }
      };

      function openGeneralDieticianModal(id, operation, value){
        setGeneralDeleteDieticianId(id)
        if(operation === 'edit'){
          setUpdateGeneralDieticianNotes(value)
          editGeneralDieticianNotesModal.current.openModal()
        } else {
          deleteGeneralDieticianNotesModal.current.openModal()
        }
      }

      async function deleteGeneralDieticianNote(){
        const inputToDelete = {
            input: {
              appointmentid: appointmentId,
              doctorid: doctorId,
              id: deleteGeneralDieticianId
            }
        }

        try {
          const result = await deleteGeneralAppointmentDieticianNotes(inputToDelete.input);
          setGeneralDeleteDieticianId("")
          fetchDoctorNotes();
          return result;
        } catch (error) {
          console.log('Error deleting Ditiecian notes', error)
          throw error;
        }
      }

      async function editGeneralDieticianNotes(){
        const inputToUpdate = {
          input: {
            appointmentid: appointmentId,
            doctorid: doctorId,
            id: deleteGeneralDieticianId,
            generalnote: addGeneralAppointmentDieticianNotes
          }
        }

        try {
          const result = await createGeneralAppointmentDieticianNotes(inputToUpdate.input);
          setUpdateGeneralDieticianNotes('')
          setAddGeneralAppointmentDieticianNotes('')
          fetchDoctorNotes()
          return result;
        } catch (error) {
          console.log('Error in updating Dietician notes', error)
          throw error;
        }
      }

    // Dietiician notes end

    useEffect(() => {
      fetchDoctorNotes();
    }, []);

    useImperativeHandle(ref, () => ({
      updateOrderFromPatientMedication: fetchDoctorNotes,
    }));

    // ------- ADD ORDER --------------------

    const transformStateToAPIFormat = (currentOrderType, currentOrderNames) => {
      return currentOrderNames
        .filter((order) => order.name.trim() !== "") // Filter out empty strings
        .map((order) => ({
          id: null,
          entitytype: currentOrderType,
          ordername: order.name,
        }));
    };

    const AddOrder = async () => {
      const hasEmptyOrderName = currentOrderNames.some(
        (order) => order.name.trim() === ""
      );

      // if (hasEmptyOrderName) {
      //   showErrorToast(`${currentOrderType} name cannot be empty.`);
      //   return;
      // }
      const inputToSave = {
        input: {
          doctorid: doctorId,
          patientid: patientId,
          appointmentid: appointmentId,
          [currentOrderType]: transformStateToAPIFormat(
            currentOrderType,
            currentOrderNames
          ),
        },
      };
      // console.log(inputToSave);
      try {
        setIsSaveButtonDisabled(true); // disable save button on submit
        const result = await updateDoctorNotes(inputToSave.input);
        if (result) {
          showSuccessToast(`${currentOrderType} Saved successfully.`);
          fetchDoctorNotesEmptyIndication();
          // Reset order names and input fields
          setCurrentOrderNames([{ id: 1, name: "" }]);
          setOrderInputFields([{ id: 1 }]);

          setCurrentOrderType(""); // Reset order type

          // also fetch data here
          addOrder.current.closeRight();
        }
      } catch (error) {
        console.error("Error in Update Doctor Notes:", error);
        showErrorToast("Error Happened While Saving.");
        addOrder.current.closeRight();
      }
      fetchDoctorNotes();
    };

    const handleAddPrescription = (event) => {
      event.stopPropagation(); // To make sure that it does not expand when interacting with buttons
      openAddPrescription();
    };

    const handleAddProcedure = (event) => {
      event.stopPropagation(); // To make sure that it does not expand when interacting with buttons
      openAddOrder("procedure");
    };

    const handleAddLabs = (event) => {
      event.stopPropagation(); // To make sure that it does not expand when interacting with buttons
      openAddOrder("lab");
    };

    const handleAddImaging = (event) => {
      event.stopPropagation(); // To make sure that it does not expand when interacting with buttons
      openAddOrder("imaging");
    };

    // Index is based on position of these elements in UI
    // Whenever layout in UI changes, this should change as well.
    // const getInitialExpandedIndices = () => {
    //   const expandedIndices = [];
    //   if (currentMedicationData && currentMedicationData.length > 0)
    //     expandedIndices.push(0);
    //   if (currentProcedureData && currentProcedureData.length > 0)
    //     expandedIndices.push(1);
    //   if (currentLabData && currentLabData.length > 0) expandedIndices.push(2);
    //   if (currentImagingData && currentImagingData.length > 0)
    //     expandedIndices.push(3);
    //   return expandedIndices;
    // };

    const [expandedIndices, setExpandedIndices] = useState([]);
    // console.log(expandedIndices);

    // Index is based on position of these elements in UI
    // Whenever layout in UI changes, this should change as well.
    useEffect(() => {
      const expandedIndices = [];
      if (currentMedicationData && currentMedicationData.length > 0)
        expandedIndices.push(0);
      if (currentProcedureData && currentProcedureData.length > 0)
        expandedIndices.push(1);
      if (currentLabData && currentLabData.length > 0) expandedIndices.push(2);
      if (currentImagingData && currentImagingData.length > 0)
        expandedIndices.push(3);
      if(dieticianNotes && dieticianNotes.length > 0) expandedIndices.push(4);
      if(generalDieticianNotes && generalDieticianNotes.length > 0) expandedIndices.push(5);
      setExpandedIndices(expandedIndices);
    }, [
      currentMedicationData,
      currentProcedureData,
      currentLabData,
      currentImagingData,
    ]);

    return (
      <Box
        position="relative"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        {isLoading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="10"
            bg="rgba(255, 255, 255, 0.8)"
          >
            <Loader miniLoader={true} />
          </Box>
        )}
        <Box bg="white">
          <Accordion
            allowMultiple
            index={expandedIndices}
            onChange={(indices) => setExpandedIndices(indices)}
          >
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Prescription
                  </Box>
                  <>
                    {/* <Badge
                      onClick={() => fetchRecommendMedicines()}
                      cursor="pointer"
                      color="blue"
                      bg={"white"}
                      ml={2}
                      // {...(index === formInputs.length - 1
                      //   ? {}
                      //   : { display: "none" })}
                    >
                      AI Generate
                    </Badge> */}
                    <Badge
                      ml="1"
                      color="blue"
                      bg="white"
                      cursor="pointer"
                      onClick={handleAddPrescription}
                    >
                      {currentMedicationData.length > 0 ? "View" : "+ Add"}
                    </Badge>
                  </>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
                {/* Content for Prescription */}
                <Box>
                  <CurrentOrderMedications
                    medicines={currentMedicationData}
                    patientId={patientId}
                    patientname={patientname}
                    patientgender={patientgender}
                    patientage={patientage}
                    doctorId={doctorId}
                    appointmentId={appointmentId}
                    fetchDoctorNotes={fetchDoctorNotes}
                    showLoader={showSubLoader}
                    dismissLoader={dismissSubLoader}
                    showSuccessToast={showSuccessToast}
                    showErrorToast={showErrorToast}
                    fetchDoctorNotesEmptyIndication={
                      fetchDoctorNotesEmptyIndication
                    }
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Procedure
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={handleAddProcedure}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
                {/* Content for Procedure */}
                <Box>
                  {currentProcedureData &&
                    currentProcedureData.map((procedureData, index) => (
                      <CurrentOrderView
                        key={index}
                        orderId={procedureData.orderid}
                        entityType={procedureData.entitytype}
                        orderName={procedureData.ordername}
                        orderDate={procedureData.orderdate}
                        createdBy={procedureData.createdby}
                        patientId={patientId}
                        doctorId={doctorId}
                        appointmentId={appointmentId}
                        fetchDoctorNotes={fetchDoctorNotes}
                        showLoader={showSubLoader}
                        dismissLoader={dismissSubLoader}
                        showSuccessToast={showSuccessToast}
                        showErrorToast={showErrorToast}
                        scaleHeight="70px"
                        fetchDoctorNotesEmptyIndication={
                          fetchDoctorNotesEmptyIndication
                        }
                      />
                    ))}
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Labs
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={handleAddLabs}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
                {/* Content for Labs */}
                <Box>
                  {currentLabData &&
                    currentLabData.map((labData, index) => (
                      <CurrentOrderView
                        key={index}
                        orderId={labData.orderid}
                        entityType={labData.entitytype}
                        orderName={labData.ordername}
                        orderDate={labData.orderdate}
                        createdBy={labData.createdby}
                        patientId={patientId}
                        doctorId={doctorId}
                        appointmentId={appointmentId}
                        fetchDoctorNotes={fetchDoctorNotes}
                        showLoader={showSubLoader}
                        dismissLoader={dismissSubLoader}
                        showSuccessToast={showSuccessToast}
                        showErrorToast={showErrorToast}
                        scaleHeight="70px"
                        fetchDoctorNotesEmptyIndication={
                          fetchDoctorNotesEmptyIndication
                        }
                      />
                    ))}
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Imaging
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={handleAddImaging}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
                <Box>
                  {currentImagingData &&
                    currentImagingData.map((imagingData, index) => (
                      <CurrentOrderView
                        key={index}
                        orderId={imagingData.orderid}
                        entityType={imagingData.entitytype}
                        orderName={imagingData.ordername}
                        orderDate={imagingData.orderdate}
                        createdBy={imagingData.createdby}
                        patientId={patientId}
                        doctorId={doctorId}
                        appointmentId={appointmentId}
                        fetchDoctorNotes={fetchDoctorNotes}
                        showLoader={showSubLoader}
                        dismissLoader={dismissSubLoader}
                        showSuccessToast={showSuccessToast}
                        showErrorToast={showErrorToast}
                        scaleHeight="70px"
                        fetchDoctorNotesEmptyIndication={
                          fetchDoctorNotesEmptyIndication
                        }
                      />
                    ))}
                </Box>
              </AccordionPanel>
            </AccordionItem>
            
            {/* Dietitian start */}
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Dietician Notes 
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={()=>{
                      document.getElementById('dietician-notes').click()
                    }}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
              <Box display='flex' flexDir='column' gap='0.5rem'>
              {dieticianNotes &&
                    dieticianNotes.map((item, index) => (
                      <Box display="flex" w="100%" key={index}>
                        <Box
                          p="20px"
                          w="100%"
                          h="50px"
                          // ml={{base: 0, md: "10px"}}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          border="1px solid #E6E7E9"
                          borderRadius="12px"
                          backgroundColor={'white'}
                        >
                          <Box display="flex" justifyContent='space-between' flex='1' overflow='hidden' alignItems="center">
                              <Text
                                title={item.dietnote}
                                fontSize="16px"
                                textAlign="left"
                                fontWeight="600"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                w='100%'
                              >
                                {item.dietnote}
                              </Text>
                          </Box>
                          <Box display="flex" ml='0.5rem' alignItems="center">
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              onClick={() =>
                                openDieticianModal(item.id, 'edit', item.dietnote)
                              }
                            >
                              <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              ml="10px"
                              onClick={() =>
                                openDieticianModal(item.id, 'delete')
                              }
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
                <Box mt='0.5rem' display='flex' flexDir='column' gap='1rem'>
                  <Textarea id='dietician-notes' placeholder="Add Dietician Note" value={addAppointmentDieticianNotes} onChange={(e)=> setAddAppointmentDieticianNotes(e.target.value)}/>
                  <PrimaryButton onClick={addDieticianNotes} buttonText='Add Dietician Note' variant='mdBtn' btnStyle={{w:'100%'}} />
                </Box>
              </AccordionPanel>
            </AccordionItem>
            {/* Dietitian end */}

             {/* General Dietitian start */}
             <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    General Notes 
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={()=>{
                      document.getElementById('general-notes').focus()
                    }}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
              <Box display='flex' flexDir='column' gap='0.5rem'>
              {generalDieticianNotes &&
                generalDieticianNotes.map((item, index) => (
                  <Box display="flex" w="100%" key={index}>
                    <Box
                      p="20px"
                      w="100%"
                      h="50px"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      border="1px solid #E6E7E9"
                      borderRadius="12px"
                      backgroundColor="white"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flex="1" // Dynamically takes available space
                        overflow="hidden" // Ensures text and buttons don't overflow
                      >
                        <Text
                          title={item.generalnote}
                          fontSize="16px"
                          textAlign="left"
                          fontWeight="600"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          w="100%" // Ensures the text takes available space
                        >
                          {item.generalnote}
                        </Text>
                      </Box>
                      <Box display="flex" alignItems="center" ml="10px">
                        <Box
                          as="button"
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          w="36px"
                          h="auto"
                          p="10px"
                          onClick={() =>
                            openGeneralDieticianModal(item.id, 'edit', item.generalnote)
                          }
                        >
                          <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                        </Box>
                        <Box
                          as="button"
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          w="36px"
                          h="auto"
                          p="10px"
                          ml="10px"
                          onClick={() =>
                            openGeneralDieticianModal(item.id, 'delete')
                          }
                        >
                          <Image w="13px" src="/assets/imgs/delete.png" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
                </Box>
                <Box mt='0.5rem' display='flex' flexDir='column' gap='1rem'>
                  <Textarea id="general-notes" placeholder="Add General Notes" value={addGeneralAppointmentDieticianNotes} onChange={(e)=> setAddGeneralAppointmentDieticianNotes(e.target.value)}/>
                  <PrimaryButton onClick={addGeneralDieticianNotes} buttonText='Add General Notes' variant='mdBtn' btnStyle={{w:'100%'}} />
                </Box>
              </AccordionPanel>
            </AccordionItem>
            {/* General Dietitian end */}
          </Accordion>

          {/* Modal To ADD orders - procedures, labs and imaging  */}
          <ModalRight
            ref={addOrder}
            header={`Add ${currentOrderType}`}
            body={
              <>
                <Box display="flex" flexDir="column" gap="20px">
                  <PrimaryButton
                    buttonText={`Search ${currentOrderType}`}
                    variant="grayBtn"
                    btnStyle={{ w: "100%", color: COLORS.PRIMARY_COLOR }}
                    // isDisabled={isSaveButtonDisabled}
                    onClick={() =>
                      openSpecificSearchOrderOverlay(currentOrderType)
                    }
                  />

                  {orderInputFields.map((field, index) => (
                    <Box
                      key={field.id}
                      display="flex"
                      alignItems="center"
                      gap="10px"
                    >
                      <PrimaryInput
                        variant="bothSide"
                        inputType="text"
                        inputPlace={`${currentOrderType} name`}
                        onChange={(event) =>
                          handleAddOrderName(field.id, event.target.value)
                        }
                      />
                      <Box
                        as="button"
                        border="1px solid #E6E7E9"
                        borderRadius="full"
                        w="36px"
                        h="36px"
                        p="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => deleteOrderInputField(field.id)}
                      >
                        <Image w="13px" src="/assets/imgs/delete.png" />
                      </Box>
                      {/* {index < orderInputFields.length - 1 && (
                        <Divider marginTop="20px" />
                      )} */}
                    </Box>
                  ))}
                  <Divider />
                  <Box marginTop="12px" textAlign="left">
                    <Button
                      onClick={addOrderInputField}
                      bg="#fcfcfd"
                      color="blue"
                      w="90%" // Make the button full width
                      textAlign="left" // Align the text to the left
                    >
                      {`+ Add ${currentOrderType}`}
                    </Button>
                  </Box>
                </Box>
              </>
            }
            footerActions={
              <>
                <Box w="100%" display="flex" flexDirection="row" gap="15px">
                  <PrimaryButton
                    buttonText={`Save ${currentOrderType}`}
                    isDisabled={
                      isSaveButtonDisabled || !actionAccessMain(22)?.create
                    }
                    onClick={AddOrder}
                  />
                </Box>
              </>
            }
          />

          {/* Modal To ADD Orders Via Common Search  */}
          <ModalRight
            ref={openSearch}
            modalWidth={"25%"}
            header={"Search"}
            body={
              <>
                <SearchBar
                  passInputPlace="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <RadioGroup>
                  <Stack direction="column" gap="0">
                    {searchMasterData
                      .filter((item) =>
                        item.subtype
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map((item) => (
                        <label key={item.id}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid #e2e8f0"
                            p="15px"
                            pl="5px"
                            minH="50px"
                            width="100%"
                            sx={{
                              _hover: {
                                border: "2px solid #384052",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                fontSize="16px"
                                lineHeight="normal"
                                fontWeight="600"
                              >
                                {`${
                                  item.category.charAt(0).toUpperCase() +
                                  item.category.slice(1)
                                }: ${item.subtype}`}
                              </Text>
                            </Box>
                            <Box display="flex" justifyContent="end">
                              <Checkbox
                                colorScheme="green"
                                sx={{
                                  ".chakra-checkbox__control": {
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "5px",
                                  },
                                }}
                                onChange={() => handleCheckboxChange(item)}
                                isChecked={isChecked(item.id)}
                              ></Checkbox>
                            </Box>
                          </Box>
                        </label>
                      ))}
                    {searchMasterData.every(
                      (item) =>
                        !item.subtype
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    ) && (
                      <Box
                        p="15px"
                        fontSize="16px"
                        fontWeight="600"
                        textAlign="center"
                      >
                        No matching results found!
                      </Box>
                    )}
                  </Stack>
                </RadioGroup>
              </>
            }
            footerActions={
              <>
                <Box w="100%" display="flex" flexDirection="row">
                  <PrimaryButton
                    buttonText={"Save To Orders"}
                    isDisabled={false}
                    onClick={handleSearchSaveButtonClick}
                  />
                </Box>
              </>
            }
          />

          {/* 2nd Modal To Search Specific Order  */}
          <ModalRight
            ref={openSearchSpecificOrder}
            // modalWidth={"25%"}
            header={"Search"}
            body={
              <>
                <SearchBar
                  passInputPlace="Search"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <RadioGroup>
                  <Stack direction="column" gap="0">
                    {specificSearchOrderData
                      .filter((item) =>
                        item.subtype
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map((item) => (
                        <label key={item.id}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            borderBottom="1px solid #e2e8f0"
                            p="15px"
                            pl="5px"
                            minH="50px"
                            width="100%"
                            sx={{
                              _hover: {
                                border: "2px solid #384052",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <Text
                                fontSize="16px"
                                lineHeight="normal"
                                fontWeight="600"
                              >
                                {`${
                                  item.category.charAt(0).toUpperCase() +
                                  item.category.slice(1)
                                }: ${item.subtype}`}
                              </Text>
                            </Box>
                            <Box display="flex" justifyContent="end">
                              <Checkbox
                                colorScheme="green"
                                sx={{
                                  ".chakra-checkbox__control": {
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "5px",
                                  },
                                }}
                                onChange={() => handleCheckboxChange(item)}
                                isChecked={isChecked(item.id)}
                              ></Checkbox>
                            </Box>
                          </Box>
                        </label>
                      ))}
                    {specificSearchOrderData.every(
                      (item) =>
                        !item.subtype
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    ) && (
                      <Box
                        p="15px"
                        fontSize="16px"
                        fontWeight="600"
                        textAlign="center"
                      >
                        No matching results found!
                      </Box>
                    )}
                  </Stack>
                </RadioGroup>
              </>
            }
            footerActions={
              <>
                <Box w="100%" display="flex" flexDirection="row">
                  <PrimaryButton
                    buttonText={"Save To Orders"}
                    isDisabled={false}
                    onClick={handleSpecificOrderSearchSaveButtonClick}
                  />
                </Box>
              </>
            }
          />

          {/* Modal To ADD Prescription  */}
          <ModalRight
            ref={addPrescription}
            closeAction={() =>
              dynamicModalPrescriptionDiscard.current.openModal()
            }
            modalWidth={"100%"}
            header={"Add medication"}
            bodyStyle={{ p: { base: "12px" } }}
            body={
              <>
                <PrescriptionComponent
                  patientId={patientId}
                  patientname={patientname}
                  patientgender={patientgender}
                  patientage={patientage}
                  doctorId={doctorId}
                  appointmentId={appointmentId}
                  showSuccessToast={showSuccessToast}
                  showErrorToast={showErrorToast}
                  fetchDoctorNotes={fetchDoctorNotes}
                  ref={prescriptionComponentRef}
                  addPrescription={addPrescription}
                  fetchPatientHealthData={fetchPatientHealthData}
                />
              </>
            }
            footerActions={
              <>
                <Box
                  w={{ base: "100%", lg: "20%" }}
                  display="flex"
                  flexDirection="row"
                  gap="15px"
                >
                  <PrimaryButton
                    buttonText={`Save Prescription`}
                    isDisabled={false || !actionAccessMain(22)?.create}
                    onClick={handleSaveButtonClick}
                  />
                </Box>
              </>
            }
          />

          {/* DynamicModal for Close Prescription */}
          <DynamicModal
            ref={dynamicModalPrescriptionDiscard}
            modalHeader={`Discard Changes?`}
            modalBody={
              <>
                <Text fontSize="14px" fontWeight="600">
                  Are you sure you want to discard these medications? Click
                  "Save & Exit" to save your changes before exiting.
                </Text>
              </>
            }
            modalFooterBtn={
              <>
                <PrimaryButton
                  buttonText="Save & Exit"
                  variant="grayBtn"
                  btnStyle={{
                    w: "45%",
                    color: COLORS.PRIMARY_COLOR,
                    h: "2.5rem",
                  }}
                  onClick={() => {
                    handleSaveButtonClick();
                    dynamicModalPrescriptionDiscard.current.closeModal();
                  }}
                />
                <Spacer />
                <PrimaryButton
                  variant="deleteBtn"
                  buttonText="Discard"
                  btnStyle={{
                    w: "45%",
                    h: "2.5rem",
                  }}
                  onClick={() => {
                    addPrescription.current.closeRight();
                    dynamicModalPrescriptionDiscard.current.closeModal();
                  }} // Close Prescriptions Modal and this modal
                />
              </>
            }
          />
        </Box>
          {/* Delete dietician notes */}
          <DynamicModal
            ref={deleteDieticianNotesModal}
            modalHeader={`Delete Dietician Notes?`}
            modalBody={
              <>
                <Text fontSize="14px" fontWeight="600">
                  Are you sure you want to Delete Dietician Notes
                </Text>
              </>
            }
            modalFooterBtn={
              <>
                <PrimaryButton
                  variant="deleteBtn"
                  buttonText="Delete"
                  btnStyle={{
                    w: "100%",
                    h: "2.5rem",
                  }}
                  onClick={() => {
                    deleteDieticianNote();
                    deleteDieticianNotesModal.current.closeModal();
                  }}
                />
              </>
            }
          />
          
          {/* Update dietician notes */}
          <DynamicModal
            ref={editDieticianNotesModal}
            modalHeader={`Update Dietician Notes`}
            modalBody={
              <>
                <Textarea placeholder="Update Dietitian Note" defaultValue={updateDieticianNotes} onChange={(e)=> setAddAppointmentDieticianNotes(e.target.value)}/>
              </>
            }
            modalFooterBtn={
              <>
                <PrimaryButton
                  // variant="deleteBtn"
                  buttonText="Update dietician notes"
                  btnStyle={{
                    w: "100%",
                    h: "2.5rem",
                  }}
                  onClick={() => {
                    editDieticianNotes();
                    editDieticianNotesModal.current.closeModal();
                  }}
                />
              </>
            }
          />
          
          
          {/* Delete general dietician notes */}
          <DynamicModal
            ref={deleteGeneralDieticianNotesModal}
            modalHeader={`Delete Dietician Notes?`}
            modalBody={
              <>
                <Text fontSize="14px" fontWeight="600">
                  Are you sure you want to Delete General Dietician Notes
                </Text>
              </>
            }
            modalFooterBtn={
              <>
                <PrimaryButton
                  variant="deleteBtn"
                  buttonText="Delete"
                  btnStyle={{
                    w: "100%",
                    h: "2.5rem",
                  }}
                  onClick={() => {
                    deleteGeneralDieticianNote();
                    deleteGeneralDieticianNotesModal.current.closeModal();
                  }}
                />
              </>
            }
          />
          
          {/* Update general dietician notes */}
          <DynamicModal
            ref={editGeneralDieticianNotesModal}
            modalHeader={`Update Dietician Notes`}
            modalBody={
              <>
                <Textarea placeholder="Update Dietitian Note" defaultValue={updateGeneralDieticianNotes} onChange={(e)=> setAddGeneralAppointmentDieticianNotes(e.target.value)}/>
              </>
            }
            modalFooterBtn={
              <>
                <PrimaryButton
                  // variant="deleteBtn"
                  buttonText="Update dietician notes"
                  btnStyle={{
                    w: "100%",
                    h: "2.5rem",
                  }}
                  onClick={() => {
                    editGeneralDieticianNotes();
                    editGeneralDieticianNotesModal.current.closeModal();
                  }}
                />
              </>
            }
          />

        <AiModal
          ref={AIChatModel}
          modalSize="420px"
          //icon="/assets/imgs/ai-doctor.png"
          header={
            <>
              <Box display="flex" flexDir="column">
                <Text
                  fontSize="1rem"
                  fontWeight="700"
                  backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                  color="transparent"
                  backgroundClip="text"
                >
                  Aira - AI
                </Text>
                <Text fontSize="0.75rem" fontWeight="400">
                  Chat with your AI health guide
                </Text>
              </Box>
            </>
          }
          headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
          closeBtnStyle={{ top: "18px" }}
          footerStyle={{
            maxH: "35%",
            overflow: "hidden auto",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
          withoutBg={true}
          backDropBlur="10px"
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box
                  p="24px 32px"
                  ref={chatContainerRef}
                  className="chat-container"
                  style={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 160px)",
                  }}
                >
                  {initialRender && remainingquestions.length > 0 && (
                    <Box>
                      {remainingquestions.map((step, index) => (
                        <Box
                          key={index}
                          as="button"
                          w="100%"
                          p="16px"
                          mb="16px"
                          fontSize="14px"
                          fontWeight="600"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          borderRadius="10px"
                          border="1px solid #7795DC"
                          bg="#EAEDF5"
                          color="#163E9E"
                          onClick={() => GetAIAnswer(step.value)}
                          className={`question-box ${
                            animate ? "slide-in" : ""
                          }`}
                        >
                          {step.value ? step.value : "-"}
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {aianswers.map((step, index) => (
                    <React.Fragment key={index}>
                      <Box
                        as="div"
                        bg="#061027"
                        p="12px 16px"
                        w="fit-content"
                        float="right"
                        borderRadius="10px 10px 1px 10px"
                        className={`answer-box ${animate ? "slide-in" : ""}`}
                      >
                        <Text fontSize="14px" fontWeight="500" color="white">
                          {step.question ? step.question : "-"}
                        </Text>
                      </Box>

                      <Box
                        as="div"
                        my="20px"
                        w="fit-content"
                        display="flex"
                        alignItems="end"
                        float="left"
                        className={`answer-box ${animate ? "slide-in" : ""}`}
                      >
                        <Image
                          bg="#061027"
                          p="4px"
                          mr="8px"
                          borderRadius="6px"
                          src="/assets/imgs/ai-chat-logo.png"
                        />
                        <Text
                          bg="#E6E7E980"
                          p="12px 16px"
                          fontSize="14px"
                          fontWeight="500"
                          color="#061027"
                          borderRadius="10px 10px 10px 1px"
                        >
                          {step.answer ? step.answer : <TypingLoader />}
                        </Text>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              </Flex>
            </>
          }
          footerActions={
            !initialRender && (
              <Box
                p="24px 32px"
                display="flex"
                w="100%"
                h="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box w="100%">
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? "slide-in" : ""}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          }
        />

        {/* DynamicModal for Recommended Prescription */}
        <DynamicModalForTwo
          ref={aiRecommendMedicineRef}
          modalWidth="75%"
          modalHeader={`Aira - AI: Recommended Prescription`}
          modalBody={
            <>
              {recommendMedicinesAiResponse.map((medicine, index) => (
                <Box key={medicine.id}>
                  <Box
                    p="10px"
                    border="1px solid gray"
                    borderRadius="10px"
                    mt="10px"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      // p="10px"
                      // border="1px solid gray"
                      // borderRadius="10px"
                      // mt="10px"
                    >
                      <Checkbox
                        isChecked={selectedRecommendedMedicinesAiResponses.includes(
                          index
                        )}
                        onChange={() =>
                          handleRecommendedMedicinesCheckboxChange(index)
                        }
                        mr={4}
                        alignSelf="center" // Vertically center the checkbox
                      ></Checkbox>
                      <Box width="42%">
                        <Box fontWeight={"semibold"}>
                          {medicine.medicinename}
                          <Box
                            display="inline-block"
                            verticalAlign="bottom"
                            fontSize="10px"
                            fontWeight="600"
                            bg="#E6E7E966"
                            border="1px solid #E6E7E9"
                            p="5px 10px"
                            borderRadius="full"
                            w="fit-content"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {medicine.medicinetype
                              ? medicine.medicinetype
                              : "-"}
                            {/* Tablet */}
                          </Box>
                        </Box>
                        <Box fontSize={"xs"}>
                          Composition: {medicine.composition}
                        </Box>
                      </Box>
                      <Box width="8%">
                        {medicine.morning}-{medicine.evening}-{medicine.night}
                        {/* 1-0-0 */}
                      </Box>
                      <Box width="35%">
                        {medicine.beforefood === 1 &&
                          medicine.afterfood === 1 && <>Before & After Food</>}
                        {medicine.beforefood === 1 &&
                          (medicine.afterfood === 0 ||
                            medicine.afterfood === null) && <>Before Food</>}
                        {(medicine.beforefood === 0 ||
                          medicine.beforefood === null) &&
                          medicine.afterfood === 1 && <>After Food</>}{" "}
                        {(medicine.beforefood === 1 ||
                          medicine.afterfood === 1) &&
                          medicine.frequency &&
                          "-"}
                        {medicine.frequency &&
                          `${medicine.frequency} x per day`}
                      </Box>

                      <Box>
                        {/* Banner */}
                        <Box
                          bg={getBannerStyles(medicine.from).bg}
                          color={getBannerStyles(medicine.from).color}
                          h="24px" // Fixed height
                          p="0 4px" // Adjust padding if needed
                          borderRadius="md"
                          mb="2px"
                          fontSize="sm"
                          fontWeight="bold"
                          textAlign="center"
                          boxShadow="md"
                          display="flex"
                          alignItems="center" // Center text vertically
                          justifyContent="center" // Center text horizontally
                        >
                          {medicine.from}
                        </Box>

                        {/* Buttons */}
                        <Box display="flex" justifyContent="space-between">
                          <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() =>
                              fetchAiDoctorPrompts(medicine.medicinename)
                            }
                          >
                            <Image
                              w="26px"
                              src="/assets/svgs/ai-star-outline.svg"
                            />
                          </Box>
                          <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => handleEditMedicine(medicine)}
                          >
                            <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Reason Box */}
                    <Box
                      as="p"
                      p="2px"
                      bg="#F9FAFB"
                      w="auto"
                      borderLeft="2px solid #14C585"
                      fontSize="0.875rem"
                      fontWeight="500"
                      mt="2px"
                      ml="3rem"
                    >
                      {medicine?.reason}{" "}
                      {/* Use medicine.reason instead of response.reason */}
                    </Box>
                  </Box>

                  {editMedicine && editMedicine.id === medicine.id && (
                    <Box
                      p="20px"
                      border="1px solid gray"
                      borderRadius="10px"
                      mt="10px" // Add some margin-top to separate from the above row
                    >
                      <Stack spacing={4}>
                        <InputGroup>
                          <PrimaryInput
                            type="text"
                            variant="bothSide"
                            inputPlace="Medicine name*"
                            defaultValue={editMedicine.medicinename}
                            onChange={(e) =>
                              handleEditMedicineChange(
                                "medicinename",
                                e.target.value
                              )
                            }
                          />
                          <PrimaryInput
                            key={compositionEditKey}
                            type="text"
                            variant="bothSide"
                            inputPlace="Medicine Composition"
                            defaultValue={editMedicine.composition}
                            onChange={(e) =>
                              handleEditMedicineChange(
                                "composition",
                                e.target.value
                              )
                            }
                          />{" "}
                          <PrimaryButton
                            buttonText="Fetch"
                            verticalAlign="bottom"
                            variant="grayBtn"
                            btnStyle={{
                              w: "15%",
                              color: COLORS.PRIMARY_COLOR,
                              verticalAlign: "bottom",
                              ml: "4px",
                              mb: "0px",
                              h: "2.8rem",
                            }}
                            onClick={() =>
                              fetchCompositionForEdit(editMedicine.medicinename)
                            }
                            isDisabled={false}
                          />
                        </InputGroup>
                        <RadioGroup
                          defaultValue={editMedicine.medicinetype}
                          onChange={(value) => {
                            handleEditMedicineChange("medicinetype", value);

                            if (value === "Syrup") {
                              handleEditMedicineChange("dosageunit", "ml");
                            } else if (value === "Tablet") {
                              handleEditMedicineChange("dosageunit", "mg");
                            }
                          }}
                        >
                          <Stack direction="row" gap="20px">
                            <Radio value="Tablet">Tablet</Radio>
                            <Radio value="Syrup">Syrup</Radio>
                          </Stack>
                        </RadioGroup>
                        <Divider />
                        <Box>
                          <Box display="flex" flexDir="row">
                            <MiniDropdownInput
                              variant="bothSide"
                              options={[
                                { label: "mg", value: "mg" },
                                { label: "ml", value: "ml" },
                              ]}
                              placeholderProp="Dosage"
                              defaultValue={editMedicine.dosage}
                              defaultOptionProp={editMedicine.dosageunit}
                              onSelect={(value) =>
                                handleEditMedicineChange(
                                  "dosageunit",
                                  value.label
                                )
                              }
                              handleInputChange={(event) =>
                                handleEditMedicineChange(
                                  "dosage",
                                  event.target.value
                                )
                              }
                              maxLength="6"
                            />
                            <DropdownInput
                              variant="bothSide"
                              dropDownPlace="Consumption frequency per day"
                              options={["1", "2", "3", "4"]}
                              onClick={(value) =>
                                handleEditMedicineChange("frequency", value)
                              }
                              defaultOption={editMedicine.frequency}
                            />
                          </Box>
                          <Box display="flex" flexDir="row">
                            <DatePicker
                              // disableFutureDates={true}
                              // for default date format it in correct order
                              defaultDate={moment(
                                editMedicine.medicationstartdate,
                                ["YYYY-MM-DD", "DD-MM-YYYY"]
                              ).format("DD-MM-YYYY")}
                              minDate={editMedicine.medicationstartdate}
                              onDateChange={(selectedDateValue) =>
                                handleEditMedicineChange(
                                  "medicationstartdate",
                                  moment(selectedDateValue).format("YYYY-MM-DD")
                                )
                              }
                              placeholderProp={"Start Date"}
                              calendarStlye={{
                                left: "-18px",
                                right: "unset",
                              }}
                            />
                            <DatePicker
                              // defaultDate={editMedicine.medicationenddate}
                              defaultDate={moment(
                                editMedicine.medicationenddate,
                                ["YYYY-MM-DD", "DD-MM-YYYY"]
                              ).format("DD-MM-YYYY")}
                              minDate={editMedicine.medicationstartdate}
                              onDateChange={(selectedDateValue) =>
                                handleEditMedicineChange(
                                  "medicationenddate",
                                  moment(selectedDateValue).format("YYYY-MM-DD")
                                )
                              }
                              placeholderProp={"End Date"}
                              calendarStlye={{
                                transform: "scale(0.9)",
                                right: "-22px",
                                top: "30px",
                              }}
                            />
                          </Box>

                          <CheckboxGroup colorScheme="green">
                            <Stack spacing={2} pt="20px" direction="row">
                              <Checkbox
                                isChecked={editMedicine.morning === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "morning",
                                    editMedicine.morning === 1 ? 0 : 1
                                  )
                                }
                              >
                                Morning
                              </Checkbox>
                              <Checkbox
                                isChecked={editMedicine.evening === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "evening",
                                    editMedicine.evening === 1 ? 0 : 1
                                  )
                                }
                              >
                                Evening
                              </Checkbox>
                              <Checkbox
                                isChecked={editMedicine.night === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "night",
                                    editMedicine.night === 1 ? 0 : 1
                                  )
                                }
                              >
                                Night
                              </Checkbox>
                              <Spacer />{" "}
                              {/* Add a spacer to push the next checkboxes to the right */}
                              <Checkbox
                                isChecked={editMedicine.beforefood === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "beforefood",
                                    editMedicine.beforefood === 1 ? 0 : 1
                                  )
                                }
                              >
                                Before Food
                              </Checkbox>
                              <Checkbox
                                isChecked={editMedicine.afterfood === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "afterfood",
                                    editMedicine.afterfood === 1 ? 0 : 1
                                  )
                                }
                              >
                                After Food
                              </Checkbox>
                            </Stack>
                          </CheckboxGroup>
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap="10px"
                        >
                          {/* <Button onClick={() => setEditMedicine(null)}>
                            Cancel
                          </Button> */}
                          <PrimaryButton
                            buttonText="Cancel"
                            variant="grayBtn"
                            btnStyle={{
                              w: "15%",
                              color: COLORS.PRIMARY_COLOR,
                              h: "2.5rem",
                            }}
                            onClick={() => setEditMedicine(null)}
                            isDisabled={false}
                          />
                          {/* <Button
                            colorScheme="blue"
                            onClick={handleSaveEditMedicine}
                          >
                            Save
                          </Button> */}
                          <PrimaryButton
                            buttonText="Save"
                            onClick={handleSaveEditMedicine}
                            isDisabled={false}
                            btnStyle={{
                              w: "25%",
                              //   color: COLORS.PRIMARY_COLOR,
                              h: "2.5rem",
                            }}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  )}
                </Box>
              ))}

              <Flex wrap="wrap" justify="space-between">
                {combinedOrderAIResponses &&
                  combinedOrderAIResponses.map((response, index) => (
                    <Box
                      key={index}
                      borderWidth="1px"
                      border="1px solid gray"
                      borderRadius="10px"
                      overflow="hidden"
                      h={"auto"}
                      mt="10px"
                      p={"10px"}
                      // mb={"10px"}
                      display="flex"
                      width="calc(50% - 8px)" // Ensure two boxes in a row with space between
                      onClick={() =>
                        handleRecommendedOrdersCheckboxChange(index)
                      }
                      alignItems="center"
                    >
                      <Checkbox
                        isChecked={selectedOrderAiResponses.includes(index)}
                        onChange={() =>
                          handleRecommendedOrdersCheckboxChange(index)
                        }
                        mr={4}
                        alignSelf="center" // Vertically center the checkbox
                      ></Checkbox>
                      <Box
                        // borderBottom="1px solid #E6E7E9"
                        // py="0.875rem"
                        display="flex"
                        flexDir="column"
                        w={"100%"}
                        gap="0.5rem"
                      >
                        {/* <Text fontSize="16px" fontWeight="600">
                          {response.type}: {response.subtype}
                        </Text> */}
                        {`${response.category
                          .charAt(0)
                          .toUpperCase()}${response.category.slice(1)}${
                          ["labs", "imaging"].includes(response.category)
                            ? " Test"
                            : ""
                        }: ${response.name}`}
                        <Box
                          as="p"
                          p="2px"
                          bg="#F9FAFB"
                          w={"100%"}
                          borderLeft="2px solid #14C585"
                          fontSize="0.875rem"
                          fontWeight="500"
                        >
                          {response.reason}
                          {/* {`${response.category
                            .charAt(0)
                            .toUpperCase()}${response.category.slice(1)}${
                            ["labs", "imaging"].includes(response.category)
                              ? " Test"
                              : ""
                          }: ${response.type}, ${response.subtype}`} */}
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Flex>

              {/* {recommendMedicinesAiResponse.map((response, index) => (
                <Box
                  key={index}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  mb={4}
                  display="flex"
                  // as="button"
                  onClick={() =>
                    handleRecommendedMedicinesCheckboxChange(index)
                  }
                  alignItems="center"
                >
                  <Checkbox
                    isChecked={selectedRecommendedMedicinesAiResponses.includes(
                      index
                    )}
                    onChange={() =>
                      handleRecommendedMedicinesCheckboxChange(index)
                    }
                    mr={4}
                    alignSelf="center" // Vertically center the checkbox
                  ></Checkbox>
                  <Box
                    key={index}
                    borderBottom="1px solid #E6E7E9"
                    py="0.875rem"
                    display="flex"
                    flexDir="column"
                    gap="0.5rem"
                  >
                    <Text fontSize="16px" fontWeight="600">
                      {response.name}
                    </Text>
                    <Box
                      as="p"
                      p="0.5rem"
                      bg="#F9FAFB"
                      borderLeft="2px solid #14C585"
                      fontSize="0.875rem"
                      fontWeight="500"
                    >
                      Composition: {response.composition}
                    </Box>
                  </Box>
                </Box>
              ))} */}

              {useGPUChatbot ? (
                <AIFeedbackFooter
                  disclaimer={
                    "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
                  }
                  doctorId={doctorId}
                  patientId={patientId}
                  appointmentId={appointmentId}
                  aiInput={aiInputForFeedback}
                  aiOutput={aiOutputForFeedback}
                  featureType={"Assist Orders"}
                />
              ) : (
                <AICPUFeedbackFooter
                  disclaimer={
                    "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
                  }
                  doctorId={doctorId}
                  patientId={patientId}
                  appointmentId={appointmentId}
                  aiInput={aiInputForFeedback}
                  aiOutput={aiOutputForFeedback}
                  featureType={"Assist Orders"}
                />
              )}
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Discard"
                btnStyle={{
                  w: "48%",
                  h: "2.5rem",
                }}
                onClick={() => {
                  setEditMedicine(null);
                  aiRecommendMedicineRef.current.closeModal();
                }}
              />

              <Spacer />

              <PrimaryButton
                buttonText="Save selected items"
                variant="grayBtn"
                btnStyle={{
                  w: "48%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                isDisabled={
                  !(
                    selectedRecommendedMedicinesAiResponses.length > 0 ||
                    selectedOrderAiResponses.length > 0
                  )
                }
                onClick={() => {
                  setEditMedicine(null);
                  saveSelectedRecommendedMedicinesToOrder();
                  aiRecommendMedicineRef.current.closeModal();
                }}
              />
            </>
          }
        />

        {/* DynamicModal for No AI response */}
        {/* <DynamicModal
          ref={noAiResponseModal}
          modalHeader={`Aira - AI: Recommended Prescription`}
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Insufficient data is available to draw inferences. Please ensure
                that relevant notes are provided.
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  noAiResponseModal.current.closeModal();
                }}
              />
            </>
          }
        /> */}

        <DynamicModal
          ref={noAiResponseModal}
          modalHeader={`Aira - AI: Recommended Prescription`}
          modalBody={renderModalContent(doctorNotes)}
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  noAiResponseModal.current.closeModal();
                }}
              />
            </>
          }
        />
      </Box>
    );
  }
);

export default DoctorNotesOrders;
