import React, { useRef, useState, useEffect } from "react";
import useLoader from "../../components/loader/loaderstates";
import MiniSideBar from "../../components/mini-sidebar";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import SimpleTable from "../../components/simple-table";
import { PrimaryInput, Toaster } from "../../components";
import { COLORS } from "../../components/styles/colors";
import DynamicModal from "../../components/dynamic-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchOrderDetails, FetchMedicineList, SaveGenerateInvoice } from "../../middleware/services/billing/index";
import { uploadImageInProfile } from "../../middleware/services/profile";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getAddressInfo, getCurrency, getDoctorName,getMobile, getEmail, getLogo, getHospitalName } from "../../auth";
import { useReactToPrint } from "react-to-print";

const initialRow = {
  medicinename: '',
  medicinenamedisplay: '',
  batchnumber: '',
  mrp: '',
  quantity: '',
  totalamount: '',
  inventoryid: '',
  prescriptionid: '',
  remainingquantity: '',
  totalCgst:'',
  totalSgst:'',
  sgst:'',
  cgst:'',
  QuantityAffected:'',
  QuantityToBind:'',
  PrescribedQty:'',
};

// const mapDataToRows = (data) => {
//   return data.map(item => ({
//     medicinename: item.medicinename || '',
//     medicinenamedisplay: item.medicinename || '',
//     batchnumber: item.batchnumber || '',
//     mrp: item.costperstrip || '',
//     quantity: item.quantity || '',
//     totalamount: item.finalprice || '',
//     inventoryid: item.prescribedmedicineid || '',
//     sgst: item.sgst || '',
//     cgst: item.cgst || '',
//   }));
// };
const calculateDaysDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  // Calculate the difference in time and convert to days
  const timeDiff = end - start;
  const dayDiff = timeDiff / (1000 * 3600 * 24) + 1; // Adding 1 to include both start and end dates
  return dayDiff;
};

const calculateQuantity = (days, frequency, morning, evening, night) => {
  // Calculate the total number of times the medicine is taken per day
  const timesPerDay = (morning ? 1 : 0) + (evening ? 1 : 0) + (night ? 1 : 0);
  
  // Multiply by the frequency and number of days
  return days * timesPerDay * frequency;
};

const calculateTotalAmount = (costPerStrip, quantity, quantityInStrip) => {
  // Calculate total amount using the formula
  const totalAmount = (costPerStrip / quantityInStrip) * quantity;
  
  // Check if totalAmount is NaN, if so return 0, else return the fixed value
  return isNaN(totalAmount) ? '0.00' : totalAmount.toFixed(2);
};

const mapDataToRows = (data) => {
  return data.map(item => {
    let quantity = item.quantity;
    let totalAmount = item.costperstrip;
    let qtyAfftected = false;
    let QuantityAfter = '';
    let PrescribedQty = '';

    if (!quantity) {
      // If quantity is null, calculate based on dates and times
      const days = calculateDaysDifference(item.medicationstartdate, item.medicationenddate);
      const frequency = parseInt(item.frequency || 1, 10);
      const morning = item.morning || 0;
      const evening = item.evening || 0;
      const night = item.night || 0;
      const remainqty = item.remainingquantity || null;
      // Calculate quantity
      quantity = calculateQuantity(days, frequency, morning, evening, night);
      console.log(quantity,"quantity")
      console.log(remainqty,"remainqty")
      if (remainqty !== null && remainqty !== undefined && quantity > remainqty) {
        PrescribedQty  = quantity
        qtyAfftected = true;
        QuantityAfter = remainqty; // Set QuantityAfter to remainqty if the condition is met
        quantity = remainqty
      }
      // Calculate total amount
      totalAmount = calculateTotalAmount(item.costperstrip, quantity, item.quantityineachstrip);
    }

    return {
      medicinename: item.medicinename ? item.medicinename + (item.remainingquantity ? " (" + item.remainingquantity + " Qty)" : "") : "",
      medicinenamedisplay: item.medicinename || '',
      batchnumber: item.batchnumber || '',
      mrp: item.costperstrip || '',
      quantity: quantity || '',
      totalamount: totalAmount || '',
      inventoryid: item.prescribedmedicineid || '',
      remainingquantity : item.remainingquantity || '',
      sgst: item.sgst || '',
      cgst: item.cgst || '',
      totalSgst: item.sgst || '',
      totalCgst: item.cgst || '',
      QuantityAffected : qtyAfftected || '',
      QuantityToBind: QuantityAfter || '',
      PrescribedQty: PrescribedQty || '',    
    };
  });
};

const EditOrder = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const location = useLocation();
  const { entityid, entitytype, patientid, appointmentid } = location.state || {};
  const [doctorid, setDoctorid] = useState();
  const [data, setData] = useState(null);
  const [patientdata, setPatientData] = useState(null);
  const [medicineList, setMedicineList] = useState([]);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [suggestions, setSuggestions] = useState([]);
  const [showMedicineNamesDropdown, setShowMedicineNamesDropdown] = useState(-1);
  const deleteRowModal = useRef();
  const generatepopup = useRef();
  const [rows, setRows] = useState([initialRow]);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [QuantityInStrip, setQuantityInStrip] = useState(0);
  const [isprintclicked, setIsPrintClicked] = useState("false");
  const [boolValForSuggestions, setBoolValForSuggestions] = useState(true);
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const suggestionsMenuRef = useRef(null);
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = Toaster();
  const [ inputId , setInputId ] = useState("");
  const [currency, setCurrency] = useState("");
  const [Address, setAddressDetails] = useState([]);
  const [doctorname, SetDoctorName] = useState(getDoctorName());
  const printRef = useRef();
  const [hospitallogo, setHospitalLogo] = useState("");
  const [hospitalname, setHospitalName] = useState("");



  useEffect(() => {
    if (entityid) {
      fetchData();
    }
    fetchMedicines();
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }
    const add = getAddressInfo();
    setAddressDetails(add);
    var hospitallogo = getLogo();
    //var imagepath = "https://midocfileserver.blob.core.windows.net/midocdev/";
    if(hospitallogo){
      setHospitalLogo(hospitallogo)
    }
    var hospitalname = getHospitalName();
    if(hospitalname){
      setHospitalName(hospitalname)
    }
  }, []);

  const fetchData = async () => {
    showLoader();
    try {
      const result = await FetchOrderDetails({
        doctorid,
        entityid: entityid,
        entitytype: entitytype,
        patientid: patientid,
        appointmentid: appointmentid,
      });
      if (result != null) {
        setData(result.data);
        setPatientData(result.patientdetail);
        console.log(result)
        if(result.data.length > 0){
          setRows(mapDataToRows(result.data));
        }else{
          setRows([initialRow]);
        }
        
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
    dismissLoader();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("ddd, D MMM YYYY");
  };

  const fetchMedicines = async () => {
    try {
      const result = await FetchMedicineList({ 
        doctorid,
        "saletype":"Prescription sale"
      });
      if (result.output.data.length > 0) {
        setMedicineList(result.output.data);
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const filterMedicineNames = (medicine) => {
    const filtered = medicineList.filter((obj) =>
      obj.productname?.toLowerCase().includes(medicine.toLowerCase())
    );
    setSuggestions(filtered);
    setBoolValForSuggestions(!boolValForSuggestions);
  };
  const handleMedicineNameChange = (id, name, index) => {
    setInputId(index)
    const newRows = [...rows];
    newRows[index].medicinename = name;
    if (!name) {
      newRows[index] = { ...initialRow }; // Reset the entire row if medicine name is cleared
    }
    setRows(newRows);
    if (name.length >= 3) {
      filterMedicineNames(name);
      setShowMedicineNamesDropdown(index);
      setBoolValForSuggestions(!boolValForSuggestions);
    } else {
      setSuggestions([]);
      setBoolValForSuggestions(!boolValForSuggestions);
      setShowMedicineNamesDropdown(-1);
    }
  };

  const handleBatchNumberChange = (batch, index) => {
    const newRows = [...rows];
    newRows[index].batchnumber = batch;
    setRows(newRows);
  };

  const handleMRPChange = (mrp, index) => {
    const newRows = [...rows];
    newRows[index].mrp = mrp;
    setRows(newRows);
    calculateTotalAmount(index);
  };
  const handleQuantityChange = (quantity, index) => {
    // console.log('quantity: ', quantity)
    // console.log('index: ', index)
    const newRows = [...rows];
    if (newRows[index].remainingquantity != '' && quantity > newRows[index].remainingquantity) {
      showErrorToast("Quantity should be less than the stock quantity ("+newRows[index].remainingquantity +")")
      newRows[index].quantity = newRows[index].remainingquantity;
    } else {
      newRows[index].quantity = quantity;
    }
    setRows(newRows);
    calculateTotalAmount(index);
    //calculateGST(index);
    calculateTotalCgstSumForDisplay();
    calculateTotalSgstSumForDisplay();
  };
  const handleTotalAmountChange = (amount, index) => {
    const newRows = [...rows];
    newRows[index].totalamount = parseFloat(amount).toFixed(2);
    setRows(newRows);
  };

  // Previous existing Code
  // const calculateTotalAmount = (index) => {
  //   const newRows = [...rows];
  //   // console.log('newRows AmountChage: ', newRows)
  //   if (QuantityInStrip && newRows[index].mrp) {
  //     newRows[index].totalamount = ((newRows[index].mrp / QuantityInStrip) * newRows[index].quantity).toFixed(2);
  //   } else {
  //     newRows[index].totalamount = ((newRows[index].mrp / 10) * newRows[index].quantity).toFixed(2);
  //   }
  //   setRows(newRows);
  // };

  const calculateTotalAmount = (index) => {
    const newRows = [...rows];
    const row = newRows[index];
  
    // console.log("Calculating Total Amount for Row:", row);
  
    // Parse values to ensure valid numeric inputs
    const quantity = parseFloat(row.quantity) || 0;
    const mrp = parseFloat(row.mrp) || 0; 
    const divisor = QuantityInStrip && parseFloat(QuantityInStrip) > 0 
      ? parseFloat(QuantityInStrip) 
      : 1; 
  
    // Ensure valid divisor and perform calculation
    if (mrp && quantity && divisor > 0) {
      row.totalamount = ((mrp / divisor) * quantity).toFixed(2);
    } else {
      row.totalamount = "0.00"; 
    }
  
    // console.log("Updated Row with Total Amount:", row);
    setRows(newRows);
  };

  // const calculateGST = (index) => {
  //   const newRows = [...rows];
  //   const quantityInStrip = QuantityInStrip || 10; // Fallback to 10 if QuantityInStrip is not defined

  //   // Calculate SGST and CGST per unit
  //   const sgstPerUnit = (newRows[index].sgst / quantityInStrip).toFixed(2);
  //   const cgstPerUnit = (newRows[index].cgst / quantityInStrip).toFixed(2);

  //   // Calculate the total SGST and CGST based on the updated quantity
  //   const totalSgst = (sgstPerUnit * newRows[index].quantity).toFixed(2);
  //   const totalCgst = (cgstPerUnit * newRows[index].quantity).toFixed(2);

  //   // Update the row with the calculated GST
  //   newRows[index].totalSgst = totalSgst;
  //   newRows[index].totalCgst = totalCgst;
  //   // Update the rows state with the new GST values
  //   setRows(newRows);
  // };
  const calculateGST = (index) => {
    const newRows = [...rows];
    const quantityInStrip = QuantityInStrip || 10; // Fallback to 10 if QuantityInStrip is not defined
  
    // Get the total amount for the row
    const totalAmount = parseFloat(newRows[index].totalamount || 0); // assuming totalAmount exists in each row
    // Calculate SGST and CGST as percentages of the total amount
    const totalSgst = ((newRows[index].sgst / 100) * totalAmount).toFixed(2);
    const totalCgst = ((newRows[index].cgst / 100) * totalAmount).toFixed(2);
  
    // Calculate the total SGST and CGST based on the updated quantity
    // const totalSgst = (sgstPerUnit * newRows[index].quantity).toFixed(2);
    // const totalCgst = (cgstPerUnit * newRows[index].quantity).toFixed(2);
  
    // Update the row with the calculated GST
    newRows[index].totalSgst = totalSgst;
    newRows[index].totalCgst = totalCgst;
  
    // Update the rows state with the new GST values
    setRows(newRows);
  };
  
  
  const handleSelect = (suggestion, index) => {
    const newRows = [...rows];
    newRows[index].medicinename = suggestion.productname  + " ("+suggestion.remainingquantity+" Qty)";
    newRows[index].medicinenamedisplay = suggestion.productname;
    newRows[index].batchnumber = suggestion.batchnumber;
    newRows[index].mrp = suggestion.costperstrip;
    newRows[index].inventoryid = suggestion.inventoryid;
    newRows[index].remainingquantity = suggestion.remainingquantity;
    newRows[index].sgst = suggestion.sgst;
    newRows[index].cgst = suggestion.cgst;
    setQuantityInStrip(suggestion.quantityineachstrip);
    calculateTotalAmount(index);
    setRows(newRows);
    setSuggestions([]);
    setBoolValForSuggestions(!boolValForSuggestions);
    setShowMedicineNamesDropdown(-1);
  };

  const AddAnotherRow = () => {
    const initialRow = {
      medicinename: '',
      batchnumber: '',
      mrp: '',
      quantity: '',
      totalamount: '',
      inventoryid: '',
      prescriptionid: ''
    };
    setRows((prevRows) => [...prevRows, { ...initialRow }]);
  };

  const deleteRowHandler = () => {
    const newRows = rows.filter((_, i) => i !== rowToDelete);
    setRows(newRows);
    deleteRowModal.current.closeModal();
  };

  const confirmDeleteRow = (index) => {
    setRowToDelete(index);
    deleteRowModal.current.openModal();
  };
  const calculateTotalAmountSum = () => {
    const total = rows.reduce((total, row) => total + parseFloat(row.totalamount || 0), 0);
    return total.toFixed(2); 
  };
  // const calculateTotalCgstSum = () => {
  //   const totalCgst = rows.reduce((total, row) => total + parseFloat(row.totalCgst || 0), 0);
  //   return totalCgst.toFixed(2);
  // };
  // const calculateTotalSgstSum = () => {
  //   const totalSgst = rows.reduce((total, row) => total + parseFloat(row.totalSgst || 0), 0);
  //   return totalSgst.toFixed(2);
  // };
  const calculateTotalCgstSum = () => {
    const totalCgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const cgstPercentage = parseFloat(row.cgst || 0);
      const cgstValue = (cgstPercentage / 100) * totalAmount;
      return total + cgstValue;
    }, 0);
    return totalCgst.toFixed(2);
  };
  
  const calculateTotalSgstSum = () => {
    const totalSgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const sgstPercentage = parseFloat(row.sgst || 0);
      const sgstValue = (sgstPercentage / 100) * totalAmount;
      return total + sgstValue;
    }, 0);
    return totalSgst.toFixed(2);
  };
  
  const calculateGrandTotal = () => {
    const grandTotal = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const totalSgst = parseFloat(row.sgst || 0);
      const totalCgst = parseFloat(row.cgst || 0);
  
      // Calculate SGST and CGST as percentages of the total amount
      const sgstAmount = (totalSgst / 100) * totalAmount;
      const cgstAmount = (totalCgst / 100) * totalAmount;
  
      // Add totalAmount and calculated GST values
      return total + totalAmount + sgstAmount + cgstAmount;
    }, 0);

    return grandTotal.toFixed(2);
  };
  // This is to calculate and display in UI
  const calculateTotalAmountSumForDisplay = () => {
    const total = rows.reduce((total, row) => total + parseFloat(row.totalamount || 0), 0);
    return `${currency} ${total.toFixed(2)}`;
  };
  const calculateTotalSgstSumForDisplay = () => {
    //const totalSgst = rows.reduce((total, row) => total + parseFloat(row.totalSgst || 0), 0);
    const totalSgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const sgstPercentage = parseFloat(row.sgst || 0);
      const sgstValue = (sgstPercentage / 100) * totalAmount;
      return total + sgstValue;
    }, 0);
    return `${currency} ${totalSgst.toFixed(2)}`;
  };

  const calculateTotalCgstSumForDisplay = () => {
    //const totalCgst = rows.reduce((total, row) => total + parseFloat(row.totalCgst || 0), 0);
    const totalCgst = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const cgstPercentage = parseFloat(row.cgst || 0);
      const cgstValue = (cgstPercentage / 100) * totalAmount;
      return total + cgstValue;
    }, 0);
    return `${currency} ${totalCgst.toFixed(2)}`;
  };
  const calculateGrandTotalForDisplay = () => {
    const grandTotal = rows.reduce((total, row) => {
      const totalAmount = parseFloat(row.totalamount || 0);
      const totalSgst = parseFloat(row.sgst || 0);
      const totalCgst = parseFloat(row.cgst || 0);
  
      // Calculate SGST and CGST as percentages of the total amount
      const sgstAmount = (totalSgst / 100) * totalAmount;
      const cgstAmount = (totalCgst / 100) * totalAmount;
  
      // Add totalAmount and calculated GST values
      return total + totalAmount + sgstAmount + cgstAmount;
    }, 0);
  
    return `${currency} ${grandTotal.toFixed(2)}`;
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'ArrowDown') {
      setFocusedItemIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'ArrowUp') {
      setFocusedItemIndex((prevIndex) =>
        prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
      );
      const itemHeight = suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
      if (suggestionsMenuRef.current) {
        suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
      }
    } else if (e.key === 'Enter' && focusedItemIndex !== -1) {
      const selectedItem = suggestions[focusedItemIndex];
      handleSelect(selectedItem, index);
    }
  };
  const prepareInvoiceInput = async () => {
    showLoader();
    const isValidRows = (rows, fieldNames) => {
      for (const row of rows) {
        for (const fieldName of fieldNames) {
          if (!row[fieldName]) {
            dismissLoader();
            // Assuming you have a function to show toast messages
            showErrorToast(`Please fill in the ${fieldName}`);
            generatepopup.current.closeModal();
            return false;
          }
        }
      }
      return true;
    };
    if (!isValidRows(rows, ['medicinename','quantity'])) return;  
    let invoiceInput = {
      loginid: "",
      invoiceurl: "",
      subtotal: calculateTotalAmountSum(),
      tax: "0.00",
      cgst:calculateTotalCgstSum(),
      sgst:calculateTotalSgstSum(),
      totalamount: calculateGrandTotal(),
      saletype: "Doctor sale",
      subtype:"prescription",
      patientid: patientid,
      doctorid: "",
      appointmentid: appointmentid,
      invoicenumber: patientdata && patientdata.invoicenumber,
      details: rows.map((row) => ({
        inventoryid: row.inventoryid,
        productname: row.medicinename,
        numberoftabletssold: row.quantity,
        finalprice: parseFloat(row.totalamount || 0).toFixed(2),
      })),
      "currency":getCurrency(),
    };
  
    const generatePNG = async () => {
      const input = printRef.current;
        // Apply manual styles directly to the `input` or wrap in a container with these styles
      input.style.padding = '10mm'; // Simulating margin as @page is not supported
      input.style.width = '210mm'; // A4 page width in mm
      input.style.height = '297mm'; 
      const canvas = await html2canvas(input, { scale: 4, useCORS: true });
      const imgData = canvas.toDataURL("image/jpeg", 4);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
  
      const pdfBlob = pdf.output("blob");
      // Upload the PNG image
      const result = await uploadImageInProfile(pdfBlob);
      invoiceInput.invoiceurl = result.name;

      return imgData; // Return the base64 image data
    };
    const saveAndPrint = async (imgData) => {
      const response = await SaveGenerateInvoice(invoiceInput);
      if (response.output.result === "success") {
        dismissLoader();
        handlePrint();
        navigate("/billing");
      }

    };

    generatePNG()
      .then(imgData => saveAndPrint(imgData))
      .catch(error => console.error("Error generating invoice:", error));
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 5mm;
      }
    `,
  });
  const gobackfunction = ()=>{
    window.history.back();
   }  
   const middleSectionHeaderWidth = 20;
   const scaleOut = rows.length > 5 ?  true:false;
  return (
    <>
      <Flex>
        <Box w="6rem" className="no-print">
          <MiniSideBar />
        </Box>
        {isLoading && <Loader />}
        <Box w="calc(100vw - 6.25rem)" display="flex" flexDir="column" id="print-content">
          <SecondaryNav withBack="Edit prescription order" goBack={gobackfunction} className="no-print" />
          <Flex w="100%" p="1rem 1.5rem" id="printableDiv1">
            <Box w="30%" display="flex">
              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem">Patient name:</Text>
                <Text fontSize="0.875rem">Patient Mobile no:</Text>
                <Text fontSize="0.875rem">Patient email:</Text>
              </Flex>

              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                  {patientdata && patientdata.patientname? patientdata.patientname:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.mobileno? patientdata.mobileno:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.email?patientdata.email:"-"}
                </Text>
              </Flex>
            </Box>

            <Spacer />

            <Box w="30%" display="flex">
              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem">Invoice:</Text>
                <Text fontSize="0.875rem">Date</Text>
                <Text fontSize="0.875rem">Preferred by:</Text>
              </Flex>

              <Flex w="100%" flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.invoicenumber ? "#"+patientdata.invoicenumber : "-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.doctorname?patientdata.doctorname:"-"}
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Divider />

          <Box
          id="printableDiv2"
            w="100%"
            p="1rem 1.5rem"
            minH="calc(100vh - 340px)"
            maxH="calc(100vh - 340px)"
            overflow="hidden auto"
          >
            <SimpleTable
              isTh={false}
              // headers={TableHeader}
              isDataPresent={true}
              ThStyle={{ textAlign: "center", bg: "#FAFAFA" }}
              footerStyle={{
                pos: "sticky",
                bottom: "-1px",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              }}
              tableStyle={{
                tableLayout: "fixed",
                width: "100%",
                border: "1px solid #E6E7E9",
                borderBottom: "none",
              }}
              tableHeaders={
                <Tr>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Medicine name
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Batch no.
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    MRP ({currency}) (Per Strip)
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Quantity (In Units)
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    Amount ({currency})
                  </Th>
                  <Th fontSize="0.87rem" fontWeight="500" pl="10px">
                    <Flex w="100%" justifyContent="center" alignItems="center">
                      <PrimaryButton
                        btnIcon="/assets/svgs/add-white.svg"
                        iconSrc={true}
                        btnStyle={{ w: "25px", h: "25px", p: "0" }}
                        iconSrcStyle={{ mr: "0", w: "10px" }}
                        onClick={AddAnotherRow}
                      />
                    </Flex>
                  </Th>
                </Tr>
              }
              tableBody={
                <>
                  {rows.map((row, index) => (
                    <Tr w="100%" key={index}>
                      <Td p="0">
                        <Box w="100%" pos="relative" cursor="pointer">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace="Medicine name*"
                            inputValue={row.medicinename}
                            onChange={(e) =>
                              handleMedicineNameChange(row.inventoryid,e.target.value, index)
                            }
                            //inputIdProp={"MedicineNameInAdd" + index}
                            inputIdProp={inputId == index ? "Active" : null}
                            boolProp={boolValForSuggestions}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            autoFocus={showMedicineNamesDropdown === index}
                            autocomplete="off"
                            inputCssStyle={{pr:"36px",_hover:{border: "1px solid", borderRadius: "10px"}}}
                            maxLength={50}
                          />
                          <Image
                            top="33%"
                            right="6.5%"
                            w="15px"
                            pos="absolute"
                            src="/assets/imgs/search.png"
                          />
                        </Box>
                        {showMedicineNamesDropdown === index && suggestions.length > 0 && (
                          <Menu isOpen={true}>
                            <MenuButton
                              as={Box}
                              w="100%"
                              display="block"
                              pos="relative"
                              zIndex="999"
                            >
                              <Box
                                pos="absolute"
                                top="100%"
                                left="0"
                                w="100%"
                                bg="white"
                                borderTop="none"
                                border="1px solid #E6E7E9"
                                borderRadius="0 0 12px 12px"
                              />
                            </MenuButton>
                            <MenuList
                              w="100%"
                              borderRadius="0 0 12px 12px"
                              zIndex="999"
                              bg="white"
                              maxH="150px"
                              overflow="hidden auto"
                              ref={suggestionsMenuRef}
                            >
                              {suggestions.map((suggestion, sIndex) => (
                                <MenuItem
                                  key={sIndex}
                                  onClick={() => handleSelect(suggestion, index)}
                                  cursor="pointer"
                                  p="1rem"
                                  py="0.5rem"
                                  borderBottom="1px solid #E6E7E9"
                                  bg={focusedItemIndex === sIndex ? "#f0f0f0" : "white"} 
                                >
                                  <Text fontSize="0.875rem" fontWeight="500">
                                    {suggestion.productname+ " ("+suggestion.remainingquantity+" Qty)"}
                                  </Text>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        )}
                      </Td>

                      <Td p="0">
                        <PrimaryInput
                          variant="bothSide"
                          inputPlace="Batch No"
                          inputValue={row.batchnumber}
                          onChange={(e) =>
                            handleBatchNumberChange(e.target.value, index)
                          }
                          inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          //isDisabled={true}
                          maxLength={30}
                        />
                      </Td>
                      <Td p="0">
                        <PrimaryInput
                          variant="bothSide"
                          inputPlace={`MRP (${currency})`}
                          inputValue={row.mrp}
                          // onChange={(e) =>
                          //   handleMRPChange(e.target.value, index)
                          // }
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only numbers and one decimal point
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleMRPChange(value, index);
                            }
                          }}
                          inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          maxLength={7}
                        />
                      </Td>
                      <Td p="0" pos='relative'>
                        <PrimaryInput
                          variant="bothSide"
                          inputPlace="Quantity*"
                          // inputValue={row.QuantityAffected == true ? row.QuantityToBind: row.quantity}
                          inputValue={row.quantity}
                          // onChange={(e) =>
                          //   handleQuantityChange(e.target.value, index)
                          // }
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only digits (no decimals, no characters)
                            if (/^\d*$/.test(value)) {
                              handleQuantityChange(value, index);
                            }
                          }}
                          maxLength={7}
                          inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                        />
                        {row.QuantityAffected &&
                         <Tooltip label={"The prescribed medicine quantity is "+ row.PrescribedQty +", but due to limited inventory, only "+ row.QuantityToBind+" are being processed."} aria-label='A tooltip' placement='top-start'>
                            <Image src="/assets/svgs/info.svg" pos='absolute' cursor='pointer' w='14px' right='6px' top='3px' filter='brightness(0) saturate(100%) invert(17%) sepia(88%) saturate(5194%) hue-rotate(358deg) brightness(93%) contrast(123%)'/>
                          </Tooltip>
                        }
                      </Td>

                      <Td p="0">
                        <PrimaryInput
                          variant="bothSide"
                          inputPlace={`Total Amount (${currency})`}
                          inputValue={row.totalamount}
                          onChange={(e) =>
                            handleTotalAmountChange(e.target.value, index)
                          }
                          maxLength={7}
                          inputCssStyle={{_hover:{border: "1px solid", borderRadius: "10px"}}}
                          isDisabled={true}
                        />
                      </Td>

                      <Td p="0">
                        <Box
                          as="button"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          w="100%"
                          onClick={() => confirmDeleteRow(index)}
                        >
                          <Image src="/assets/svgs/delete.svg" opacity="0.7" />
                        </Box>
                      </Td>
                    </Tr>
                  ))}
                </>
              }
            />
          </Box>

          <Box
            w="100%"
            px="1.5rem"
            boxShadow="rgba(0, 0, 0, 0.15) 0px -10px 11px -8px"
            zIndex="99"
          >
            {/* Footer area start */}
            <Box w="100%" display="flex" pt="1rem">
              <Spacer />

              <Box w="19%" display="flex" gap="2rem" id="printableDiv3">
                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" className="textOverflow">
                    Total Amount
                  </Text>
                  <Text fontSize="0.875rem">SGST</Text>
                  <Text fontSize="0.875rem">CGST</Text>
                  <Text fontSize="0.875rem">Total:</Text>
                </Flex>

                <Flex w="75px" flexDir="column" alignItems="start">
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalAmountSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalSgstSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateTotalCgstSumForDisplay()}
                  </Text>
                  <Text fontSize="0.875rem" fontWeight="600" whiteSpace='nowrap'>
                    {calculateGrandTotalForDisplay()}
                  </Text>
                </Flex>
              </Box>
            </Box>
            {/* Footer area end */}

            {/* Generate invoice buttons start */}
            <Flex w="100%" mt="1rem" className="no-print">
              <Spacer />

              <Box w="30%" display="flex" gap="1rem" justifyContent="end">
                <PrimaryButton
                  buttonText="Proforma invoice"
                  variant="grayBtn"
                  btnStyle={{ color: COLORS.PRIMARY_COLOR }}
                  onClick={handlePrint}
                />
                <PrimaryButton buttonText="Generate invoice" variant="mdBtn" onClick={()=> generatepopup.current.openModal()} />
              </Box>
            </Flex>
            {/* Generate invoice buttons end */}
          </Box>
        </Box>
      </Flex>
      {/* For Print */}
      {isprintclicked && 
       <Flex width="215mm" height="297mm" id="printinvoice" ref={printRef} direction="column">
       {/* Blue Strip */}
      <Box
        mt={"0px"}
        width={"100%"}
        top={0}
        left={0}
        right={0}
        height="8px"
        bg="blue.600"
        zIndex={1}
      />
      
      <Box w="100%" display="flex" flexDir="column" height="100%">
      <Box bg={"#fafafa"} p='0.75rem' display='flex' justifyContent='' gap='1.5rem' w='100%'>
        {/* Header */}
        <Box w='130%'>
        <HStack>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" fontSize="xl">
            { hospitalname?hospitalname: "-"}
            </Text>
          </VStack>
         
        </HStack>

        {/* Contact Information */}
        <HStack justify="flex-start" mt={1}>
          <HStack>
            <Image src="assets/svgs/call.svg" alt="Call" />
            <Text fontSize={"xs"}>
            {getMobile()}
            </Text>
          </HStack>
          <HStack>
            <Image src="assets/svgs/smallLinePhoneMail.svg" alt="Divider" />
            <Image src="assets/svgs/mail-gray.svg" alt="Mail" />
            <Text fontSize={"xs"}>{getEmail()}</Text>
          </HStack>
        </HStack>

        {/* Hospital Address */}
        <HStack justify="flex-start" mt={1}>
          <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
          <Text fontSize={"xs"}>
          {Address && Address.length > 0 ? 
              (Address[0].address1 ? Address[0].address1 : "") + ", " +
              (Address[0].address2 ? Address[0].address2 : "") + ", " +
              (Address[0].city ? Address[0].city : "") + ", " +
              (Address[0].state ? Address[0].state : "") + ", " +
              (Address[0].postalcode ? Address[0].postalcode : "") + ", " +
              (Address[0].country ? Address[0].country : "") 
              : "-"
            }          </Text>
        </HStack>
        </Box>

        <Box w='30%' mt='-1rem'>
            {hospitallogo != "" && (
               <Image
               src={hospitallogo}
               height="auto"
               w='100px'
               mr={"36px"}
             />
            )}
        </Box>
      </Box>
      <Divider my={scaleOut ? 1 : 4} />
        {/* Header Section */}
        <Box w="100%" display="flex" alignItems="flex-start" justifyContent="center">
          <Box w="100%" p="1rem">
            <Text fontSize="1.5rem" textAlign="center" fontWeight="700">
              Invoice
            </Text>
          </Box>
        </Box>
        <VStack align="flex-start" mt={scaleOut ? "1px" : "4px"}>
        <Flex w="100%" p="1rem" id="printableDiv1">
            <Box w="50%" gap='1rem' display="flex" justifyContent='normal'>
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Patient Name:</Text>
                <Text fontSize="0.875rem">Patient Mobile:</Text>
                <Text fontSize="0.875rem">Doctor Name:</Text>
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.patientname ? patientdata.patientname:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.mobileno?patientdata.mobileno:"-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.doctorname?patientdata.doctorname:"-"}
                </Text>
              </Flex>
            </Box>

            {/* <Spacer /> */}

            <Box w="50%" gap='1rem' display="flex">
              <Flex flexDir="column">
                <Text fontSize="0.875rem">Invoice Number:</Text>
                <Text fontSize="0.875rem">Date</Text>
                {/* <Text fontSize="0.875rem">Preferred by:</Text> */}
              </Flex>

              <Flex flexDir="column">
                <Text fontSize="0.875rem" fontWeight="600">
                {patientdata && patientdata.invoicenumber ? "#"+patientdata.invoicenumber : "-"}
                </Text>
                <Text fontSize="0.875rem" fontWeight="600">
                  {formatDate(today)}
                </Text>
                {/* <Text fontSize="0.875rem" fontWeight="600">

                </Text> */}
              </Flex>
            </Box>
        </Flex>

         <Divider my={scaleOut ? 1 : 4} />
       </VStack>

     
        {/* Content Section (Table) */}
        <Box w="93%" p="0.5rem 0.5rem" flex="1 0 auto" border="1px solid #E6E7E9" borderRadius="4px">
           <SimpleTable
             isTh={false}
             isDataPresent={true}
             ThStyle={{
               textAlign: "center",
               bg: "#FAFAFA",
               border: "1px solid #E6E7E9",
               padding: "4px 8px", // Adjusted padding for smaller header rows
               fontSize: "0.8rem", // Reduced font size for headers
             }}
             footerStyle={{
               pos: "sticky",
               bottom: "-1px",
               boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
             }}
             tableStyle={{
               tableLayout: "fixed",
               width: "100%",
               border: "1px solid #E6E7E9",
               borderBottom: "collapse",
             }}
             tableHeaders={
               <Tr>
                 <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                   Medicine name
                 </Th>
                 <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                   Batch no.
                 </Th>
                 <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                   MRP (Per Strip)
                 </Th>
                 <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                   Quantity (In Units)
                 </Th>
                 <Th fontSize="0.8rem" fontWeight="500" pl="8px" pr="8px">
                   Amount ({currency})
                 </Th>
               </Tr>
             }
             tableBody={
               <>
                 {rows.map((row, index) => (
                   <Tr w="100%" key={index}>
                     <Td border="1px solid #E6E7E9" padding="4px 8px"> {/* Adjusted padding for smaller row height */}
                       <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                         {row.medicinenamedisplay ? row.medicinenamedisplay : "-"}
                       </Text>
                     </Td>

                     <Td border="1px solid #E6E7E9" padding="4px 8px">
                       <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                         {row.batchnumber ? row.batchnumber : "-"}
                       </Text>
                     </Td>
                     <Td border="1px solid #E6E7E9" padding="4px 8px">
                       <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                         {row.mrp ? row.mrp : "0"}
                       </Text>
                     </Td>
                     <Td border="1px solid #E6E7E9" padding="4px 8px">
                       <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                         {row.quantity ? row.quantity : "0"}
                       </Text>
                     </Td>

                     <Td border="1px solid #E6E7E9" padding="4px 8px">
                       <Text w="100%" fontSize="0.8rem" pos="relative" cursor="pointer">
                         {row.totalamount ? currency + " " + row.totalamount : "0"}
                       </Text>
                     </Td>
                   </Tr>
                 ))}
               </>
             }
           />
       </Box>

      
        {/* Footer Section */}
        <Divider />
        
        <Box w="100%" display="flex" p="3rem 5rem" justifyContent="end">
          <Box w="100%" display="flex" gap="2rem" justifyContent="end">
            <Flex w="75px" flexDir="column" alignItems="start">
              <Text fontSize="0.875rem" className="textOverflow">Total Amount</Text>
              <Text fontSize="0.875rem">SGST</Text>
              <Text fontSize="0.875rem">CGST</Text>
              <Text fontSize="0.875rem">Total:</Text>
            </Flex>
    
            <Flex w="75px" flexDir="column" alignItems="start">
              <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                {calculateTotalAmountSumForDisplay()}
              </Text>
              <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                {calculateTotalSgstSumForDisplay()}
              </Text>
              <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                {calculateTotalCgstSumForDisplay()}
              </Text>
              <Text fontSize="0.875rem" fontWeight="600" whiteSpace="nowrap">
                {calculateGrandTotalForDisplay()}
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
      }
      <DynamicModal
        ref={deleteRowModal}
        modalHeader="Delete row"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deleteRowHandler}
            />
          </>
        }
      />
       <DynamicModal
        ref={generatepopup}
        modalHeader="Generate Invoice"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to generate invoice?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              // variant="deleteBtn"
              buttonText="Generate"
              onClick={prepareInvoiceInput}
            />
          </>
        }
      />
    </>
  );
};

export default EditOrder;
