import {
  Box,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  ListItem,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import GrayCard from "../../card";
import { GetPatientHealthData } from "../../../middleware/services/visits/patient-overview";
import { useLocation } from "react-router-dom";
import {
  getLoginID,
} from "../../../auth";
import DynamicModal from "../../dynamic-modal";
import PrimaryButton from "../../primary-button";
import { COLORS } from "../../styles/colors";

const CurrentVisitOverview = ({ patienData, displayOverview = false }) => {


  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const currentPatientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = getLoginID()

  const [patientVisitFormSection2, setPatientVisitFormSection2] =
  useState(null);
const [patientVisitFormSection3, setPatientVisitFormSection3] =
  useState(null);
const [patientVisitFormSection4, setPatientVisitFormSection4] =
  useState(null);
const [patientVisitFormSection5, setPatientVisitFormSection5] =
  useState(null);

const [inputData, setInputData] = useState({
  patientid: currentPatientId,
  appointmentid: appointmentId,
  doctorid: doctorId,
  type: "advancevisit",
});

const visitFormSection2 = useRef();
    const visitFormSection3 = useRef();
    const visitFormSection4 = useRef();
    const visitFormSection5 = useRef();

useEffect(() => {
  // Function to fetch patient entire health data and update state - FOR PATIENT CURRENT VISIT
  const fetchPatientHealthData = async () => {
    try {
      const data = await GetPatientHealthData(inputData);
      // console.log(data);
      setPatientVisitFormSection2(data?.visitformdata?.section_two);
      setPatientVisitFormSection3(data?.visitformdata?.section_three);
      setPatientVisitFormSection4(data?.visitformdata?.section_four);
      setPatientVisitFormSection5(data?.visitformdata?.section_five);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
    }
  };

  fetchPatientHealthData();
}, []);
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  const consumptionYears = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculating difference in ms
    const diffInMs = endDate - startDate;

    // Milliseconds to years
    const msInYear = 1000 * 60 * 60 * 24 * 365.25;
    const diffInYears = diffInMs / msInYear;

    if (diffInYears === 0) {
      return "Less than a year";
    }

    return diffInYears.toFixed(0) + " years";
  };

  const calculateYearsDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculating difference in ms
    const diffInMs = endDate - startDate;

    // Milliseconds to years
    const msInYear = 1000 * 60 * 60 * 24 * 365.25;
    const diffInYears = diffInMs / msInYear;

    // if (diffInYears === 0) {
    //   return "Less than a year";
    // }

    return diffInYears.toFixed(0);
  };
  const formatDate_yyyy = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("yyyy");
  };

  return (
    <>
      <Box
        w="100%"
        display="flex"
        flexWrap="wrap"
        gap="1rem"
        h="100%"
        py={"16px"}
      >
        {/* Demographics */}
        {displayOverview && (
          <GrayCard
            hasFooter={false}
            w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            cardHeader="Demographics"
            cardHeaderIcon="/assets/imgs/measure.png"
            bodyStyle={{ p: "0" }}
            cardBody={
              <>
                <Flex direction="row" justify="space-around" h="full">
                  <Box
                    p="10px"
                    px="20px"
                    display="flex"
                    flexDir="column"
                    h="100%"
                    justifyContent="center"
                    w="100%"
                  >
                    <Box>
                      <Box
                        as="p"
                        display="inline-block"
                        fontSize="20px"
                        fontWeight="600"
                      >
                        {patienData && patienData.demographics.height ? (
                          <Text fontWeight={"semibold"}>
                            {patienData.demographics.height}
                          </Text>
                        ) : (
                          "0"
                        )}
                      </Box>
                      <Box
                        display="inline-block"
                        as="span"
                        fontSize="12px"
                        fontWeight="400"
                        pl={"5px"}
                      >
                        {patienData && patienData.demographics.heightunit ? (
                          <Text>{patienData.demographics.heightunit}</Text>
                        ) : (
                          "cms"
                        )}
                      </Box>
                    </Box>
                    <Box
                      as="p"
                      fontSize="12px"
                      fontWeight="500"
                      color="#6A707D"
                    >
                      Height
                    </Box>
                  </Box>

                  <Box
                    p="10px"
                    px="20px"
                    display="flex"
                    flexDir="column"
                    h="100%"
                    justifyContent="center"
                    w="100%"
                    borderRight="1px solid #E6E7E9"
                    borderLeft="1px solid #E6E7E9"
                    _last={{ borderRight: 0 }}
                  >
                    <Box>
                      <Box
                        as="p"
                        display="inline-block"
                        fontSize="22px"
                        fontWeight="600"
                      >
                        {patienData && patienData.demographics.weight ? (
                          <Text fontWeight={"semibold"}>
                            {patienData.demographics.weight}
                          </Text>
                        ) : (
                          "0"
                        )}
                      </Box>

                      <Box
                        display="inline-block"
                        as="p"
                        fontSize="15px"
                        fontWeight="400"
                        pl={"5px"}
                      >
                        {patienData && patienData.demographics.weightunit ? (
                          <Text>{patienData.demographics.weightunit}</Text>
                        ) : (
                          "Kg"
                        )}
                      </Box>
                    </Box>
                    <Box
                      as="p"
                      fontSize="12px"
                      fontWeight="500"
                      color="#6A707D"
                    >
                      Weight
                    </Box>
                  </Box>

                  <Box
                    p="10px"
                    px="20px"
                    display="flex"
                    flexDir="column"
                    h="100%"
                    justifyContent="center"
                    w="100%"
                    borderRight="1px solid #E6E7E9"
                    borderLeft="1px solid #E6E7E9"
                    _last={{ borderRight: 0 }}
                  >
                    <Box>
                      <Box
                        as="p"
                        display="inline-block"
                        fontSize="22px"
                        fontWeight="600"
                      >
                        {patienData && patienData.demographics.bmi ? (
                          <Text fontWeight={"semibold"}>
                            {patienData.demographics.bmi}
                          </Text>
                        ) : (
                          "0"
                        )}
                      </Box>
                    </Box>
                    <Box
                      as="p"
                      fontSize="12px"
                      fontWeight="500"
                      color="#6A707D"
                    >
                      BMI
                    </Box>
                  </Box>
                </Flex>
              </>
            }
          />
        )}

        {/* Treatment Team */}
        <GrayCard
          hasFooter={false}
          w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Treatment Team"
          cardHeaderIcon="/assets/imgs/treatment-plan.png"
          cardBody={
            <>
              <Box>
                {patienData && patienData?.treatmentteam?.length > 0 ? (
                  patienData.treatmentteam.map((item, index) => (
                    <Box
                      key={index}
                      borderBottom="1px solid #E6E7E9"
                      py="0.875rem"
                      display="flex"
                      flexDir="column"
                      gap="0.5rem"
                    >
                      <Text fontSize="16px" fontWeight="600">
                        {item.doctorname}
                      </Text>
                      <Box
                        as="p"
                        p="0.5rem"
                        bg="#F9FAFB"
                        borderLeft="2px solid #14C585"
                        fontSize="0.875rem"
                        fontWeight="500"
                      >
                        Plan: {item.entitytype}
                      </Box>
                      {/* {item.entitytype === "Prescribed Medicine" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">
                                Plan: Basic metabolic panel, Magnesium
                            </Box>
                        )}
                        {item.entitytype === "Surgery" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #FF6B6B" fontSize="0.875rem" fontWeight="500">
                                Plan: Surgery Scheduled
                            </Box>
                        )}
                        {item.entitytype === "labs" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #6C5DD3" fontSize="0.875rem" fontWeight="500">
                                Plan: Lab Test Scheduled
                            </Box>
                        )} */}
                      <Text fontSize="12px" fontWeight="500" color="#384052">
                        {formatDate(item.date)}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/treatment-plan.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Past Medical HIstory */}
        <GrayCard
          hasFooter={false}
          w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Medical History"
          cardHeaderIcon="/assets/imgs/medical-history.png"
          cardBody={
            <>
              <Box>
                <VStack align="stretch">
                  {patienData && patienData.medicalhistory?.length > 0 ? (
                    patienData.medicalhistory.map((elem, index) => (
                      <Stack
                        key={index}
                        direction="column"
                        align="flex-start"
                        gap="0"
                      >
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                          <Text fontSize="16px" fontWeight="600">
                            {elem.condition}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          h="auto"
                          gap="5px"
                        >
                          <Center
                            h="35px"
                            ml="5px"
                            borderLeft="1px dotted #CDCFD4"
                          ></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(elem.conditionidentifieddate)}
                          </Text>
                        </Box>
                      </Stack>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      height={"220px"}
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/medical-history.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  )}
                </VStack>
              </Box>
            </>
          }
        />

        {/* Past Surgical HIstory */}
        <GrayCard
          hasFooter={false}
          w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Surgical History"
          cardHeaderIcon="/assets/imgs/surgeries.png"
          cardBody={
            <>
              <Box>
                <VStack align="stretch">
                  {patienData && patienData.surgicalhistory?.length > 0 ? (
                    patienData.surgicalhistory.map((surgery, index) => (
                      <Stack
                        key={index}
                        direction="column"
                        align="flex-start"
                        gap="0"
                      >
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                          <Text fontSize="16px" fontWeight="600">
                            {surgery.surgeryName}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          h="auto"
                          gap="5px"
                        >
                          <Center
                            h="35px"
                            ml="5px"
                            borderLeft="1px dotted #CDCFD4"
                          ></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(surgery.date)}
                          </Text>
                        </Box>
                      </Stack>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      height={"220px"}
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/surgeries.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  )}
                </VStack>
              </Box>
            </>
          }
        />

        {/* Family History */}
        <GrayCard
          hasFooter={false}
          w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Family history"
          cardHeaderIcon="/assets/imgs/family-history.png"
          cardBody={
            <>
              <Box display="flex" flexDirection="column" gap="10px">
                {patienData && patienData.familyhistory?.length > 0 ? (
                  patienData.familyhistory.map((family, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Text fontSize="16px" fontWeight="600">
                          {family.relation}
                        </Text>
                        <Text fontSize="12px" fontWeight="500" color="#384052">
                          {formatDate(family.dob)}
                        </Text>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          {family.medicalcondition &&
                            family.medicalcondition.map((condition, index) => (
                              <Box
                                as="p"
                                display="flex"
                                alignItems="center"
                                flexDir="row"
                                fontSize="10px"
                                key={index}
                                title={family.medicalcondition
                                  .map((condition) => condition.condition)
                                  .join(", ")}
                              >
                                <Box as="span" fontSize="8px" mr="3px">
                                  ●{" "}
                                </Box>{" "}
                                {condition.condition}
                                {index < family.medicalcondition.length - 1 &&
                                  ", "}
                              </Box>
                            ))}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        border="1px solid #E6E7E966"
                        bg="#E6E7E966"
                        p="4px"
                        px="6px"
                        borderRadius="full"
                      >
                        <Text
                          as="span"
                          color="#384052"
                          fontSize="10px"
                          ml="2px"
                        >
                          {family.isdeceased ? "Deceased" : "Alive"}
                        </Text>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/family-history.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Social History */}
        <GrayCard
          hasFooter={false}
          w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="social History"
          cardHeaderIcon="/assets/imgs/social-history-bar.png"
          cardBody={
            <>
              <Box>
                {patienData && patienData.socialhistory?.length > 0 ? (
                  patienData.socialhistory.map((history, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-around"
                      gap="10px"
                    >
                      <Box>
                        <Text fontSize="16px" fontWeight="600">
                          {history.type}
                        </Text>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Text
                            fontSize="12px"
                            fontWeight="500"
                            color="#384052"
                          >
                            <Box display="flex">
                              <Text
                                fontSize="0.75rem"
                                fontWeight="bold"
                                color="#384052"
                              >
                                Status:{" "}
                              </Text>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="500"
                                color="#384052"
                              >
                                {/* 5 years */}
                                {/* {`${social.fromdate} - ${social.todate}`} */}
                                {/* {`${totalYears} years`} */}
                                {history.iscurrent == 1
                                  ? " Current"
                                  : history.iscurrent == 0
                                  ? " Never"
                                  : history.iscurrent == 2
                                  ? " Former"
                                  : ""}
                              </Text>
                            </Box>
                            {/* {`${calculateYearsDifference(
                              history.fromdate,
                              history.todate
                            )}`}
                            : {history.frequency} x per {history.frequencytype} */}
                            <Text
                              fontSize="0.75rem"
                              fontWeight="500"
                              color="#384052"
                            >
                              {/* 5x per day */}
                              {/* {social.frequency}  {social.frequencytype} */}
                              {history.frequency && history.frequency !== 0
                                ? `${history.frequency} 
                              ${
                                history.type && history.type == "Smoking"
                                  ? "Packs"
                                  : history.type == "Alcohol"
                                  ? "Drinks"
                                  : history.type == "Smokeless"
                                  ? "Cans"
                                  : history.type == "Illicit Drugs"
                                  ? "Times"
                                  : "Packs"
                              } 
                              Per ${history.frequencytype}   
                              x ${
                                calculateYearsDifference(
                                  history.fromdate,
                                  history.todate
                                ) == 1
                                  ? calculateYearsDifference(
                                      history.fromdate,
                                      history.todate
                                    ) + " year"
                                  : calculateYearsDifference(
                                      history.fromdate,
                                      history.todate
                                    ) > 1
                                  ? calculateYearsDifference(
                                      history.fromdate,
                                      history.todate
                                    ) + " years"
                                  : "less than a year"
                              }  
                            `
                                : "-"}
                              {history.iscurrent == 2 && (
                                <>
                                  {` Until ${
                                    history.todate
                                      ? formatDate_yyyy(history.todate)
                                      : ""
                                  }`}
                                </>
                              )}
                            </Text>
                          </Text>
                          {/* {history.frequency > 0 ? (
                            <Image
                              w="13px"
                              h="9px"
                              mx="3px"
                              src="/assets/imgs/red-chart.png"
                            />
                          ) : (
                            <Image
                              w="16px"
                              h="14px"
                              mx="3px"
                              src="/assets/imgs/green-chart.png"
                            />
                          )} */}
                        </Box>
                      </Box>
                      <Divider size="1px" colorScheme="#E6E7E9" />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/social-history-bar.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Current medication */}
        <GrayCard
          hasFooter={false}
          w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
          bodyStyle={{ p: "2px 2px", overflow: "auto", maxH: "220px" }}
          cardHeader="current medications"
          cardHeaderIcon="/assets/imgs/medication.png"
          cardBody={
            <>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="50px"
              >
                <Box>
                  <UnorderedList>
                    {patienData &&
                    patienData.medicationForOverview?.length > 0 ? (
                      patienData.medicationForOverview.map(
                        (medication, index) => (
                          <ListItem
                            key={index}
                            fontSize="14px"
                            fontWeight="600"
                          >
                            {medication.medicinename}
                            {": "}
                            {medication.frequency
                              ? medication.frequency + "-per day"
                              : ""}
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              Composition:{" "}
                              {medication.chemicalcomposition
                                ? medication.chemicalcomposition
                                : "-/-"}
                            </Text>
                          </ListItem>
                        )
                      )
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        height={"220px"}
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/treatment-plan.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                  </UnorderedList>
                </Box>
              </Box>
            </>
          }
        />

        {/* Vitals */}
        <Box display="flex" justifyContent="space-between" gap="20px" w="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
          >
            <GrayCard
              hasFooter={false}
              bodyStyle={{ p: "0" }}
              cardHeader="vitals"
              cardHeaderIcon="/assets/imgs/heart.png"
              cardBody={
                <>
                  <Box width="100%" overflowX="auto">
                    <SimpleGrid minChildWidth="8.75rem">
                      {patienData && patienData.vitals?.length > 0
                        ? patienData.vitals.map((vital, index) => (
                            <Box
                              key={index}
                              borderRight="1px solid #E6E7E9"
                              p={4}
                            >
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                {vital.vitalName}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="22px"
                                fontWeight="600"
                              >
                                {vital.vitalValue}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="15px"
                                pl={"5px"}
                                fontWeight="400"
                              >
                                {vital.vitalUnit}
                              </Text>
                              <Box>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  {formatDate(vital.vitalDate)}
                                </Text>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mt="0.5rem"
                                gap="0.25rem"
                              >
                                {vital.vitalStatus === "High" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#F04438"
                                      src="/assets/imgs/arrow_upward.png"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#F04438"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : vital.vitalStatus === "Normal" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#6A707D"
                                      src="/assets/imgs/check.png"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#6A707D"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : vital.vitalStatus === "Ok" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#6A707D"
                                      src="/assets/imgs/check.png"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#6A707D"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : vital.vitalStatus === "Low" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#D46F11"
                                      src="/assets/svgs/arrow_upward.svg"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#D46F11"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
                          ))
                        : "no records found"}
                    </SimpleGrid>
                  </Box>
                </>
              }
            />
          </Box>
        </Box>

        {/* Investigations */}
        <Box display="flex" justifyContent="space-between" gap="20px" w="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
          >
            <GrayCard
              hasFooter={false}
              bodyStyle={{ p: "0" }}
              cardHeader="Investigations"
              cardHeaderIcon="/assets/imgs/investigation.png"
              cardBody={
                <>
                  <Box width="100%" overflowX="auto">
                    <SimpleGrid minChildWidth="8.7rem">
                      {patienData &&
                      patienData?.overviewinvestigations?.length > 0 ? (
                        patienData.overviewinvestigations.map(
                          (investigation, index) => (
                            <Box borderWidth="1px" p={4} key={index}>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                {/* White blood cells */}
                                {investigation.eventtitle
                                  ? investigation.eventtitle
                                  : "Null"}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize={{ lg: "18px", xl: "22px" }}
                                fontWeight="600"
                              >
                                {investigation.actualresultvalue
                                  ? investigation.actualresultvalue
                                  : "-"}
                              </Text>
                              {investigation.resultuom && (
                                <Text
                                  display="inline-block"
                                  fontSize="15px"
                                  fontWeight="400"
                                >
                                  (
                                  {investigation.resultuom
                                    ? investigation.resultuom
                                    : ""}
                                  )
                                </Text>
                              )}
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {/* May 15, 2024 */}
                                    {formatDate(investigation.verifieddate)}
                                  </Text>
                                </Box>
                                {investigation.status === "low" &&
                                investigation.trendColor === "red" ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    transform="scaleY(-1)"
                                  >
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/red-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : investigation.status === "high" &&
                                  investigation.trendColor === "red" ? (
                                  <Box display="flex" alignItems="center">
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/red-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : investigation.status === "low" &&
                                  investigation.trendColor === "green" ? (
                                  <Box display="flex" alignItems="center">
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/green-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : investigation.status === "high" &&
                                  investigation.trendColor === "green" ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    transform="scaleY(-1)"
                                  >
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/green-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          )
                        )
                      ) : (
                        <Flex
                          direction="row"
                          justify="space-around"
                          h="full"
                          w="100%"
                          flexWrap="wrap"
                        >
                          <Text>No Data Available</Text>
                          {/* {dataEntries.map((index) => (
                            <SimpleTable NoDataHeader={index} />
                        ))} */}
                        </Flex>
                      )}
                    </SimpleGrid>
                  </Box>
                </>
              }
            />
          </Box>
        </Box>

        {/* Lab and Imaging */}
        <Box display="flex" justifyContent="space-between" flexWrap={{base: "wrap", xl: "nowrap"}} gap="20px" w="100%">
          <GrayCard
            hasFooter={false}
            w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="labs"
            cardHeaderIcon="/assets/imgs/biotech.png"
            cardBody={
              <>
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    gap="20px"
                  >
                    {patienData && patienData.labs?.length > 0 ? (
                      patienData.labs.map((lab, index) => (
                        <Box key={index}>
                          <Box
                            mb="3px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              fontSize="16px"
                              fontWeight="600"
                              color="#061027"
                            >
                              {lab.name}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {/* {new Date(lab.verifieddate).toLocaleDateString()} */}
                              {formatDate(lab.date)}
                            </Text>
                          </Box>
                          <Text fontSize="14px" fontWeight="500">
                            {lab.summary}
                          </Text>
                          <Divider mt="10px" size="1px" colorScheme="#E6E7E9" />
                        </Box>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        height={"220px"}
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/biotech.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            }
          />

          <GrayCard
            hasFooter={false}
            w={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            minW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            maxW={{base: "100%", md: "100%", xl: "calc(50% - 10px)"}}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="IMAGING"
            cardHeaderIcon="/assets/imgs/hand_bones.png"
            cardBody={
              <>
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    gap="20px"
                  >
                    {patienData && patienData.imaging?.length > 0 ? (
                      patienData.imaging.map((image, index) => (
                        <Box key={index}>
                          <Box
                            mb="3px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              fontSize="16px"
                              fontWeight="600"
                              color="#061027"
                            >
                              {image.name}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {/* {new Date(image.verifieddate).toLocaleDateString()} */}
                              {formatDate(image.date)}
                            </Text>
                          </Box>
                          <Text fontSize="14px" fontWeight="500">
                            {image.summary}
                          </Text>
                          <Divider mt="10px" size="1px" colorScheme="#E6E7E9" />
                        </Box>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        height={"220px"}
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/hand_bones.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            }
          />

        </Box>
      {/* Advanced Details Screening Pages Code Start */}

        {/* Adult Health Screening */}
        {patientVisitFormSection2?.length > 0 && (
          <GrayCard
          w={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
          maxW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Adult Patient Screening"
            cardHeaderIcon="/assets/imgs/investigation.png"
            cardBody={
              <Box>
                {patientVisitFormSection2 &&
                  patientVisitFormSection2.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection2.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection2).length - 1 && (
                          <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                        )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Child Health Screening */}
        {patientVisitFormSection3?.length > 0 && (
          <GrayCard
          w={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
          maxW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Child Screening"
            cardHeaderIcon="/assets/imgs/investigation.png"
            cardBody={
              <Box>
                {patientVisitFormSection3 &&
                  patientVisitFormSection3.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection3.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection3).length - 1 && (
                          <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                        )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Women Health Screening */}
        {patientVisitFormSection4?.length > 0 && (
          <GrayCard
          w={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
          maxW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Women's Preventive Health Screening"
            cardHeaderIcon="/assets/imgs/investigation.png"
            cardBody={
              <Box>
                {patientVisitFormSection4 &&
                  patientVisitFormSection4.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection4.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection4).length - 1 && (
                          <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                        )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Mental Health Screening */}
        {patientVisitFormSection5?.length > 0 && (
          <GrayCard
          w={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
          maxW={{ base: "100%", md: "100%", xl: "100%", '2xl':"calc(50% - 10px)"  }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Lifestyle and Mental Health Screening"
            cardHeaderIcon="/assets/imgs/investigation.png"
            cardBody={
              <Box>
                {patientVisitFormSection5 &&
                  patientVisitFormSection5.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection5.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection5).length - 1 && (
                          <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                        )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Advanced Details Screening Pages Code End */}

        {/* Open Modal to view Questions start */}

        <DynamicModal
          ref={visitFormSection2}
          modalHeader={`Adult Patient Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection2 &&
                patientVisitFormSection2.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection2.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection2).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection2.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModal
          ref={visitFormSection3}
          modalHeader={`Child Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection3 &&
                patientVisitFormSection3.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection3.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection3).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection3.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModal
          ref={visitFormSection4}
          modalHeader={`Women's Preventive Health Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection4 &&
                patientVisitFormSection4.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection4.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection4).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection4.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModal
          ref={visitFormSection5}
          modalHeader={`Lifestyle and Mental Health Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection5 &&
                patientVisitFormSection5.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection5.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection5).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection5.current.closeModal();
                }}
              />
            </>
          }
        />
      </Box>
    </>
  );
};

export default CurrentVisitOverview;
