import React, { useEffect, useState } from 'react'
import { FetchFilteredInvoices, FetchInvoices } from '../../middleware/services/invoices'
import useLoader from '../../components/loader/loaderstates';
import { formatDate_YYYY_MM_DD, formatToDecimal, getLoginID, useScreenType } from '../../auth';
import MiniSideBar from '../../components/mini-sidebar';
import { Box, Collapse, Divider, Flex, Image, Card, CardBody, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import SecondaryNav from '../../components/seconday-nav';
import SimpleTable from '../../components/simple-table';
import MobileNavbar from '../../components/mobile-navbar';
import MobileSecondaryNav from '../../components/mobile-secondary-navbar';
import moment from 'moment';
import { DropdownInput, PrimaryButton, PrimaryInput, Toaster } from '../../components';
import { saveAs } from 'file-saver';
import DatePicker from '../../components/date-picker';
import { format } from 'date-fns';
import Loader from '../../components/loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLORS } from '../../components/styles/colors';

const InvoicesIndex = () => {
    var [doctorid, setDoctorid] = useState();
    var [invoiceData, setInvoiceData] = useState(null);
    const { isLoading, showLoader, dismissLoader } = useLoader();
    const {showSuccessToast, showErrorToast} = Toaster();
    const screenType = useScreenType();

    const [name, setName] = useState("");
    const [type, setType] = useState('');
    const [subtype, setSubType] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
  const [noData, setNoData] = useState(false);


  let itemPerPageCommonValue = 50;

  let [pageno, setPageno] = useState(1);
  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);

  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showFilterCount, setShowFilterCount] = useState(false);

  const [pageLoad, setPageLoad] = useState(true);
  
   const [ toDateMinMax , setToDateMinMax] = useState("")
   const [ fromDateMinMax , setFromDateMinMax] = useState("")
   const { isOpen, onToggle } = useDisclosure()
   const navigate = useNavigate()
    const TableHeader = [
      { key: "patient", label: "Patient Name" },
      { key: "email", label: "Patient Email" },
      { key: "createddate", label: "Created Date" },
      { key: "sale", label: 'Sale type'},
      { key: "subtype", label: 'Sub type'},
      { key: "invoice", label: 'Invoice Number'},
      { key: "amount", label: 'Total Amount'},
      { key: "actions", label: 'Actions'},
  ];

    // useEffect(() => {
    //     showLoader();
    //     const FetchInvoicesData = async () => {
    //         try{
    //             const response = await FetchInvoices({
    //                 doctorid
    //             });
    //             // console.log(response, 'response invoices');

    //             if(response != null) {
    //                 setInvoiceData(response)
    //             }
    //         } catch (error) {
    //             console.error('Error in invoice:', error);
    //         }
    //     };
    //     dismissLoader();
    //     FetchInvoicesData();
    // }, [])

  // ----------------------------------------------------------------------
  // Filtration Functionality

  const handleChangeName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z0-9\s@.]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setName(event.target.value);
  };
  const handleTypeChange = (value) => {
    setType(value);
  };
  const handleSubTypeChange = (value) => {
    setSubType(value);
  };
  const handleFromDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    console.log('formattedDAte: ', formattedDate)
    setFromDate(formattedDate);

    setFromDateMinMax(selectedDate);
  };
  const handleToDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setToDate(formattedDate);

    setToDateMinMax(selectedDate);
  };

  // --------------------------------------------------------------------
  // Pagination & Page load Functionality
  useEffect(() => {
    showLoader();
    var input = {
      // "doctorid": getLoginID(),
      "pageno": 1,
      pagesize
    }
    var getInvoicesList = async () => {
      try{
        var result = await FetchInvoices(input);

        if(result?.data){
          setInvoiceData(result.data);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);

          setTotalPages(totalPagesCount);

          setTotalItemCount(count)
          if(result && result.count  < itemPerPageCommonValue){
            setCountTo(result.count)
            setTotalExceedsPageCount(true)
          }else if(result.count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }

        } else{
          setInvoiceData([])
        }
      } catch (error) {
        console.error("Error in home:", error);
      }
      dismissLoader();
    };

    getInvoicesList();
  }, [refresh])

  const getFilteredResults = () => {
    // if(!name && !email && !specialization && (!status || status== '')){
    //       showErrorToast('Please enter the inputs to filter.')
    //       return;
    //     }
    const tableContainer = document.querySelector('.tableContainer');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
    showLoader();
    var input = {
      "doctorid": getLoginID(),
        "searchtext": name,
        "saletype": type,
        "subtype": subtype,
        "fromdate": formatDate_YYYY_MM_DD(fromDate),
        "todate":formatDate_YYYY_MM_DD(toDate),
       // "pageno":currentPage,
        // "pageno":1,
        "pageno": pageno,
        pagesize
    };
    var getInvoicesList = async () => {
      try {
        var result = await FetchInvoices(input);

        if (result?.data) {
          setInvoiceData(result.data);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          // alert(totalPages)
          setTotalPages(totalPagesCount);
          // setFilteredData(result.data);
          setTotalItemCount(result.count)
          // if(result && result.count  < itemPerPageCommonValue){
          //   setCountTo(result.count)
          //   setTotalExceedsPageCount(true)
          // }
          if (count < itemPerPageCommonValue) {
            setCountTo(count);
            setTotalExceedsPageCount(true);
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
        }else{
           setInvoiceData([])
        }
      } catch (error) {
        console.log(error, "error in getDoctorsList");
      }
      dismissLoader();
    };

    getInvoicesList();
  };

  const handleApplyFilters = () => {
    // if(!patientName && !email && !mobile && (!plan || plan== '')){
    //   showErrorToast('Please enter the inputs to filter.')
    //   return;
    // }
    setShowFilterCount(true);
    setPageno(1)
    setCurrentPage(1)
    getFilteredResults();
  };

  const resetFields = () => {
    setName('')
    setFromDate('')
    setToDate('')
    setType('')
    setSubType('')
    setCurrentPage(1);
    setPageno(1);
    // setPagesize(30);
    //clear states for correct item count
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1)
    setCountTo(itemPerPageCommonValue)
    setCurrentPage(1)
    setShowFilterCount(false);

    setRefresh(!refresh);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // console.log('Enter key pressed');
      setPageno(1);
      setPagesize(itemPerPageCommonValue);
      setCountFrom(1);
      setCountTo(itemPerPageCommonValue);
      setCurrentPage(1)

      handleApplyFilters();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // ApplyFunction();
  };


  // const ApplyFunction = async () => {
  //   if(!name &&!fromDate && !toDate){
  //     showErrorToast('Please enter the inputs to filter.')
  //     return;
  //   }
  //   showLoader();
  //   try {
  //     setPageno(1);
  //     setPagesize(itemPerPageCommonValue);
  //     setCountFrom(1);
  //     setCountTo(itemPerPageCommonValue);
  //     setCurrentPage(1)

  //     var inputForFilterDetails = {
  //       "doctorid": getLoginID(),
  //       "searchtext": name,
  //       "saletype": type,
  //       "subtype": subtype,
  //       "fromdate": formatDate_YYYY_MM_DD(fromDate),
  //       "todate":formatDate_YYYY_MM_DD(toDate),
  //      // "pageno":currentPage,
  //       "pageno":1,
  //       pagesize
  //     }

  //       const result = await FetchInvoices(inputForFilterDetails);
  //       // console.log(result, "APPly function result")
  //       if(result?.data){
  //         setInvoiceData(result.data);
  //         let count = result?.count;
  //         let pageno = result?.pageno;
  //         let pagesize = result?.pagesize;

  //         let totalPagesCalc = count / pagesize;
  //         let totalPagesCount = Math.ceil(totalPagesCalc);
  //         setTotalPages(totalPagesCount)
          
  //         setTotalItemCount(result?.count)
  //         // if(result && result?.count  < itemPerPageCommonValue){
  //         //   setCountTo(result?.count)
  //         //   setTotalExceedsPageCount(true)
  //         // }
  //        // console.log(count, " count")
  //        // console.log(itemPerPageCommonValue, " itemPerPageCommonValue")
  //         if (count < itemPerPageCommonValue) {
  //           setCountTo(count);
  //           setTotalExceedsPageCount(true);
  //         }else if(count > itemPerPageCommonValue && pageno == 1) {
  //           setCountTo(itemPerPageCommonValue);
  //           setTotalExceedsPageCount(false);
  //         }
  //       } else{
  //         setInvoiceData([])
  //       }
  //     } catch (error) {
  //       console.error('Error in ApplyFunction:', error);
  //     }
  //     dismissLoader();
  // };

  useEffect(()=>{
    if(pageno && !pageLoad){
      
       getFilteredResults();
       }
       setPageLoad(false);
  },[pageno])

  const handleNextClick = () => {
    let page = pageno +1 ;
  //  alert(page)
    setPageno(page);
    setCurrentPage(currentPage + 1);
   // getFilteredResults();
     
   // item count logic for next
   if(countTo + pagesize < totalItemCount){
    setCountTo(countTo + pagesize)
   }else{
     setCountTo(totalItemCount)
   }
   
   setCountFrom(countFrom + pagesize)
  };
  const handlePreviousClick = () => {
    let page = pageno -1 ;
    //alert(page)
    setPageno(page);
   // setPageno(pageno - 1);
    setCurrentPage(currentPage - 1);
   // getFilteredResults();
    // item count logic for previous
    
    setCurrentPage(currentPage - 1);
    if(countTo == totalItemCount){
         //setCountTo(Math.floor(totalItemCount / 10) * 10)
       let updatedCountUnroundedValue  = totalItemCount - pagesize;
      setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )

    }else{
      setCountTo(countTo - pagesize)
    }
    
    setCountFrom(countFrom - pagesize)
  };
  // --------------------------------------------------------------------

  

  // const ApplyFunction = async () => {
  //   if(!name && !type && !subtype && !fromDate && !toDate){
  //     showErrorToast('Please enter the inputs to filter.')
  //     return;
  //   }
  //   showLoader();
  //   try {
  //     // setPageno(1);
  //     // setPagesize(itemPerPageCommonValue);
  //      setCountFrom(1);
  //     // setCountTo(itemPerPageCommonValue);
  //      setCurrentPage(1)

  //     var inputForFilterDetails = {
  //       "doctorid": getLoginID(),
  //       "searchtext": name,
  //       "saletype": type,
  //       "subtype": subtype,
  //       "fromdate": formatDate_YYYY_MM_DD(fromDate),
  //       "todate":formatDate_YYYY_MM_DD(toDate),
  //      // "pageno":currentPage,
  //       "pageno":1,
  //       pagesize
  //     }

  //       const result = await FetchFilteredInvoices(inputForFilterDetails);
  //       // console.log(result, "APPly function result")
  //       if(result?.data){
  //         setInvoiceData(result.data);
  //         let count = result?.count;
  //         let pageno = result?.pageno;
  //         let pagesize = result?.pagesize;

  //         let totalPagesCalc = count / pagesize;
  //         let totalPagesCount = Math.ceil(totalPagesCalc);
  //         setTotalPages(totalPagesCount)
          
  //         setTotalItemCount(count)
  //         // if(result && result.count  < itemPerPageCommonValue){
  //         //   setCountTo(result.count)
  //         //   setTotalExceedsPageCount(true)
  //         // }
  //         if (count < itemPerPageCommonValue) {
  //           setCountTo(count);
  //           setTotalExceedsPageCount(true);
  //         }else if(count > itemPerPageCommonValue && pageno == 1) {
  //           setCountTo(itemPerPageCommonValue);
  //           setTotalExceedsPageCount(false);
  //         }
  //       }else{
  //         setInvoiceData(null)
  //       }
  //     } catch (error) {
  //       console.error('Error in ApplyFunction:', error);
  //     }
  //     dismissLoader();
  // };

  // const resetFields = () => {
  //   //clear states for correct item count
  //   setPagesize(itemPerPageCommonValue);
  //   setCountFrom(1)
  //   setCountTo(itemPerPageCommonValue)
  //   setCurrentPage(1)

  //   //window.location.reload();
  //   setName('');
  //   setType("");
  //   setSubType("");
  //   setFromDate(null)
  //   setToDate(null)
  //   setCurrentPage(1);
  //   fetchpaginationData()

  // };
  // ----------------------------------------------------------------------

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      const downloadDocument = async (url, name) => {
        try {
          // let testurl = "https://midocfileserver.blob.core.windows.net/midocdev/a2816fdf-2b18-4b75-932a-35361e988a11.jpg";
          // let testurl2 = "https://upload.wikimedia.org/wikipedia/en/6/6b/Hello_Web_Series_%28Wordmark%29_Logo.png";
          
          let filename = name;
          const response = await fetch(url, {
            mode: 'cors' 
          });
          // console.log(response, " fetch result")
          const blob = await response.blob();
          
          
          saveAs(blob, filename);
        } catch (error) {
          console.error('Failed to download the image:', error);
        }
      };
// ---------------------------------------------------------------------
    // Pagination Functionality

  //   const fetchpaginationData = async (page) => {
  //     showLoader();
  //     try {
  //       const result = await FetchInvoices({
  //         'pageno': page,
  //         pagesize
  //       });
  
  //       // console.log(result, " result HOME page");
  //       if (result != null) {
  //         const response = result.data;
  //         setInvoiceData(response);
  //         if (result && result.count === 0 && result.pagesize === 0) {
  //           setTotalPages(1);
  //           setTotalItemCount(result.count)
  //         } else {
  //           setTotalPages(Math.ceil(result.count / result.pagesize));
  //           setTotalItemCount(result.count)
  //         }
  //         if(result && result.count  < itemPerPageCommonValue){
  //           setCountTo(result.count)
  //           setTotalExceedsPageCount(true)
  //         }
  //       } else {
  //         setTotalPages(1);
  //       }
  //       dismissLoader();
  //     } catch (error) {
  //       console.error("Error in home:", error);
  //     }
  //   };
  
  //   useEffect(() => {
  //     fetchpaginationData(currentPage);
  //     const tableContainer = document.querySelector('.tableContainer');
  //     if (tableContainer) {
  //       tableContainer.scrollTop = 0;
  //     }
  //   }, [currentPage]);
  //   const handleNextClick = () => {
  //     setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  //     fetchpaginationData(currentPage + 1);

      
  //  // item count logic for next
  //  if(countTo + pagesize < totalItemCount){
  //   setCountTo(countTo + pagesize)
  //  }else{
  //    setCountTo(totalItemCount)
  //  }
   
  //  setCountFrom(countFrom + pagesize)
  //   };
  //   const handlePreviousClick = () => {
  //     setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  //     fetchpaginationData(currentPage - 1);

  //    // item count logic for previous
  //    setCurrentPage(currentPage - 1);
  //    if(countTo == totalItemCount){
  //         //setCountTo(Math.floor(totalItemCount / 10) * 10)
  //       let updatedCountUnroundedValue  = totalItemCount - pagesize;
  //      setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )
  //    }else{
  //      setCountTo(countTo - pagesize)
  //    }
     
  //    setCountFrom(countFrom - pagesize)
  //   };
  
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Enter') {
  //       // console.log('Enter key pressed');
  //       ApplyFunction();
  //     }
  //   };
  
  //   const handlePageChange = (page) => {
  //     setCurrentPage(page);
  //     ApplyFunction();
  //   };

    // useEffect(() => {
    //   if (!name && !fromDate && !toDate) {
    //     resetFields();
    //   }
    // }, [name, fromDate, toDate]);

    // const gobackfunction = ()=>{
    //   window.history.back();
    //  }
    const location = useLocation();
    const gobackfunction = () => {
      if (window.history.length > 2 && location.key !== "default") {
        window.history.back();
    
        // Fallback if the page does not navigate
        setTimeout(() => {
          if (document.referrer === "" || window.history.length <= 1) {
            navigate("/home");
          }
        }, 500);
      } else {
        navigate("/home");
      }
    };

  return (
    <>
      {screenType == 'web' ? (
      <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {isLoading && <Loader />}
          <Box w="100%">
            <SecondaryNav withBack="Sales" goBack={gobackfunction} />
            <Box gap='10px' my='1rem' pl='2rem' w='90vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
              <PrimaryInput
                variant="fullRound"
                title="Search by name, email, invoce number"
                inputPlace="Search by name, email"
                id="yourName"
                onKeyDown={handleKeyDown}
                onChange={handleChangeName}
                inputValue={name}
              />
               <DropdownInput
                variant="fullSide"
                dropDownPlace="Sale Type"
                options={["OTC Sale","Clinical Sale","Doctor Sale"]}
                id="type"
                onClick={handleTypeChange}
                readOnly={true}
                value={type}
                menuList={{ pos: "absolute", right: "-44px", minW: "201px" }}
              />
              <DropdownInput
                variant="fullSide"
                dropDownPlace="Type"
                options={["Prescription", "Lab", "Appointment"]}
                id="subtype"
                onClick={handleSubTypeChange}
                value={subtype}
                menuList={{ pos: "absolute", right: "-44px", minW: "201px" }}
              />
              <Box w='800px'>
              <DatePicker
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleFromDate(selectedDateValue)
                  }
                  placeholderProp="From Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={fromDate} 
                  clearValue={true}

                  
                  CUSTOM_DISABLE={true}
                  disableFutureDates={true}
                  maxDate={toDateMinMax}
                />
              </Box>
                <Box w='800px'>
              <DatePicker
                  onDateChange={(selectedDateValue) =>
                    handleToDate(selectedDateValue)
                  }
                  placeholderProp="To Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                  value={toDate} 
                  clearValue={true}

                  CUSTOM_DISABLE={true}
                  maxDate={new Date().toISOString()} 
                  minDate={fromDateMinMax}
                />
                </Box>

              <Box gap='10px' my='1rem' pl='2rem' w='70vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                <PrimaryButton buttonText="Filter" id="filterButton" onClick={handleApplyFilters} />
                <PrimaryButton buttonText="Reset" id="resetButton" variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} onClick={resetFields} />
              </Box>
              {noData && <p>No data available</p>}
              </Box>
            {invoiceData && invoiceData.length > 0 ? (
                        <Box my="1rem">
                          <Box
                          h="calc(100vh - 90px)"
                          overflow='hidden auto'
                          border="1px solid #E6E7E9"
                          >
                            <SimpleTable
                              headers={TableHeader}
                              className='tableContainer'
                              isDataPresent={true}
                              currentPage={currentPage} onPageChange={handlePageChange}
                              footerStyle={{pos: "fixed", bottom: "0",boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",width:"100%", zIndex:"99"}}
                              tableStyle={{maxH:"calc(100vh - 260px)",overflowY: "auto", tableLayout: "fixed", width: "100%"}}
                              tableBody={
                                <>
                                  {invoiceData &&
                                    invoiceData.map((step, index) => (
                                      <Tr w="100%" key={index}>
                                        <Td w="100x">
                                          <Box w="100%" display="flex" gap="0.5rem" alignItems="center">
                                            {/* <Box boxSize="2.75rem"><Image boxSize="40px" h="40px" objectFit="cover" src={step.patientimage? step.imagepath + step.patientimage:"/assets/imgs/no-image.png"} borderRadius="full" /></Box> */}
                                            <Box w="80%" maxW="8rem" isTruncated>
                                              <Text
                                              className="textOverflow"
                                                fontSize=".875rem"
                                                fontWeight="600"
                                                title={step.patientname}
                                              >
                                                {step.patientname
                                                  ? step.patientname
                                                  : "-"}
                                              </Text>
                                            </Box>
                                          </Box>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {step.patientemail
                                            ? step.patientemail
                                            : "-"}
                                          </Text>
                                        </Td>

                                        {/* <Td>
                                          <Box
                                            p=".25rem .5rem"
                                            borderRadius="full"
                                          >
                                            <Text
                                              fontSize=".75rem"
                                              fontWeight="600"
                                              color="#109E6A"
                                            >
                                              {step.patientmobile ? (step.patientmobile): ('-')}
                                            </Text>
                                          </Box>
                                        </Td> */}

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {step.createddate ? formatDate(step.createddate) : ('-')}
                                          </Text>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {step.saletype ? (step.saletype) : ('-')}
                                          </Text>
                                        </Td>
                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {/* {step.subtype ? (step.subtype) : ('-')} */}
                                          {step.subtype ? `${step.subtype.charAt(0).toUpperCase()}${step.subtype.slice(1)}` : "-"}
                                          </Text>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {step.invoicenumber ? "#"+(step.invoicenumber) : ('-')}
                                          </Text>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {step.totalamount ?  (step.currency ? step.currency : "") + "" + formatToDecimal(step.totalamount)  : ('-')}
                                          </Text>
                                        </Td>

                                        {/* <Td colSpan={1}> */}
                                        <Td>
                            <Box
                              as="button"
                              justifyContent="flex-start"
                              w="100%"
                              display="flex"
                              gap="0.85rem"
                            >
                              <Text
                                fontSize=".875rem"
                                textDecor="underline"
                                fontWeight="700"
                                // onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")}
                                onClick={() => {window.open(
                                    step.imagepath + step.invoiceurl, '_blank')}}
                              >
                                View
                              </Text>
                              <Image src="/assets/svgs/pdf-outline.svg"
                              onClick={()=>{
                                downloadDocument(
                                    step.imagepath + step.invoiceurl, "Invoice")
                                showSuccessToast(`${step.subtype} Invoice added to downloads.`)
                                }}  />
                            </Box>
                              {/* <Image src="/assets/svgs/download-white.svg" /> */}
                            
                          </Td>
                                      </Tr>
                                    ))}
                                </>
                              }
                              footerSection={
                                <>
                                  <Tr w='100%'>
                                    <Td colSpan={8} w="calc(100vw - 6.5rem)">
                                      <Box
                                        w="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                         <Box 
                                          visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}
                                          >
                                             <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                                             </Box>
                 
                 
                                           <Box  
                                           gap="2.5rem"
                                            display="flex"
                                           justifyContent="space-between"
                                           alignItems="center" >
                                        <Box as="button" onClick={handlePreviousClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                            Previous
                                          </Box>
                                        <Box>
                                          <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                          Page {currentPage} of {totalPages}
                                          </Text>
                                        </Box>
                                           {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                       
                                          <Box as="button" p="0.5rem 0.75rem" mr='2.5rem' border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick}
                                           disabled={currentPage === totalPages} 
                                           visibility={currentPage === totalPages ? 'hidden' : 'visible'} 
                                           fontSize="1rem" fontWeight="500">
                                            Next
                                          </Box>
            }
                                          </Box>
                                      </Box>
                                    </Td>
                                  </Tr>
                                </>
                              }
                            />
                          </Box>
                        </Box>
                      ) : (
                        <SimpleTable
                          headers={TableHeader}
                          footerVisible={false}
                          isDataPresent={false}
                        />
                      )}
          </Box>
        </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Sales"
            handlebackarrow={() => navigate(`/profile`)}
          />
        <Box
          w="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="1rem"
          gap='0.5rem'
        >
          <PrimaryInput
            variant="fullRound"
            title="Search by name, email, invoce number"
            inputPlace="Search by name, email"
            id="yourName"
            onKeyDown={handleKeyDown}
            onChange={handleChangeName}
            inputValue={name}
            inputStyleCss={{w:'60%'}}
          />
          <Box onClick={onToggle}>
            <Image
              w='25px'
              cursor='pointer'
              src={isOpen ? "assets/svgs/outline-f.svg" : "assets/svgs/filled-f.svg" }
            />
          </Box>
          <Box w="30%" display="flex" gap="10px">
            <PrimaryButton buttonText={<Image filter={COLORS.FILTER_WHITE} src='/assets/svgs/search.svg' />} id="filterButton" variant='mdBtn' onClick={handleApplyFilters} btnStyle={{p:'0'}} />
            <PrimaryButton buttonText={<Image src='/assets/svgs/refresh.svg' />} id="resetButton" variant="grayBtn" btnStyle={{ color: "#AA2F27",p:'0' }} onClick={resetFields} />
          </Box>
        </Box>
        {/* {filterOpen && (
          <> */}
          <Collapse in={isOpen} animateOpacity style={{overflow:'visible'}}>
            <Box w="100%" display="flex" p='1rem' pt='0' flexDir='column' gap="10px">
              <DropdownInput
                variant="fullSide"
                dropDownPlace="Sale Type"
                options={["OTC Sale","Clinical Sale","Doctor Sale"]}
                id="type"
                onClick={handleTypeChange}
                readOnly={true}
                value={type}
                menuList={{ pos: "absolute", right: "-44px", minW: "201px" }}
              />
              <DropdownInput
                variant="fullSide"
                dropDownPlace="Type"
                options={["Prescription", "Lab", "Appointment"]}
                id="subtype"
                onClick={handleSubTypeChange}
                value={subtype}
                menuList={{ pos: "absolute", right: "-44px", minW: "201px" }}
              />

                <DatePicker
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleFromDate(selectedDateValue)
                  }
                  placeholderProp="From Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={fromDate} 
                  clearValue={true}

                  
                  CUSTOM_DISABLE={true}
                  disableFutureDates={true}
                  maxDate={toDateMinMax}
                />
                <DatePicker
                  onDateChange={(selectedDateValue) =>
                    handleToDate(selectedDateValue)
                  }
                  placeholderProp="To Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                  value={toDate} 
                  clearValue={true}

                  CUSTOM_DISABLE={true}
                  maxDate={new Date().toISOString()} 
                  minDate={fromDateMinMax}
                />
            </Box>
            <Box w="100%" p='1rem' pt='0' display="flex" gap="10px">
              <PrimaryButton buttonText="Filter" id="filterButton" onClick={handleApplyFilters} />
              <PrimaryButton buttonText="Reset" id="resetButton" variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} onClick={resetFields} />
            </Box>
          </Collapse>
          {/* </>
        )} */}
        <Divider />

        <Flex w='100%' gap='1rem' p='1rem' flexDir='column' maxH="calc(100vh - 275px)" overflow='hidden auto'>
        {invoiceData && invoiceData.length > 0 ? (
          invoiceData.map((step, index) => (
          <Card
            borderRadius="12px"
            key={index}
            w="100%"
            border="1px solid #E6E7E9"
            boxShadow="0px 8px 20px 0px #E6E7E966"
          >
            <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >                  
                <Box cursor="pointer" display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" justifyContent="center">
                  <Text
                    className="textOverflow"
                    fontSize=".875rem"
                    fontWeight="600"
                    title={step.patientname}
                  >
                    {step.patientname
                      ? step.patientname
                      : "-"}
                  </Text>
                  </Box>
                </Box>

                  <Box display="flex" gap="10px">
                  <Image src="/assets/svgs/pdf-outline.svg"
                    onClick={()=>{
                      downloadDocument(
                          step.imagepath + step.invoiceurl, "Invoice")
                      showSuccessToast(`${step.subtype} Invoice added to downloads.`)
                      }}
                  />
                  </Box>
                  
              </Box>
              <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                <Box display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Email
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {step.patientemail ? step.patientemail : "-"}
                    </Text>
                    
                  </Box>
                  <Box display='flex' flexDir='column' alignItems="end">
                
                <Text fontSize="10px" color="gray.500">
                  Created Date
                </Text>
                <Box
                    as="button"
                  >
                <Text fontSize="12px" fontWeight="600">
                    {step.createddate ? formatDate(step.createddate) : ('-')}
                </Text>
                  </Box>
                
              
              </Box>
              </Flex>
              
              <Flex w='100%' mb='0.75rem'>
                <Box w='100%' display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Sale Type
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {step.saletype ? (step.saletype) : ('-')}
                    </Text>
                    
                  </Box>
                  

                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                    <Text fontSize="10px" color="gray.500">
                      Sub Type
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                      {step.subtype ? `${step.subtype.charAt(0).toUpperCase()}${step.subtype.slice(1)}` : "-"}
                    </Text>
                  </Box>
              </Flex>
              
              <Flex w='100%'>
                <Box w='100%' display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Invoice No.
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {step.invoicenumber ? "#"+(step.invoicenumber) : ('-')}
                    </Text>
                    
                  </Box>
                  

                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                    <Text fontSize="10px" color="gray.500">
                      Total amount
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {step.totalamount ?  (step.currency ? step.currency : "") + "" + formatToDecimal(step.totalamount)  : ('-')}
                    </Text>
                  </Box>
              </Flex>
            
            </CardBody>

          </Card>
        ))) : (
          <Text>No data available</Text>
        )}
        </Flex>
        </>
      )}
    </>
  )
}

export default InvoicesIndex