import {
  Box,
  Flex,
  Tr,
  Td,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Card,
  CardHeader,
  Spacer,
  Switch,
  CardBody,
  Stack,
  FormLabel,
  SimpleGrid,
  Radio,
  RadioGroup,
  Textarea,
  useTab,
  
  Table,
  Thead,
  Tbody,
  Tfoot,
  Th,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import SecondaryNav from "../../components/seconday-nav";
import SimpleTable from "../../components/simple-table";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import PrimaryButton from "../../components/primary-button";
import ModalRight from "../../components/modal-right";
import SearchBar from "../../components/search-bar";
import PrimaryInput from "../../components/primary-input";
import DoctorCard from "../../components/doctor-card";
import DynamicModal from "../../components/dynamic-modal";

import { Toaster } from "../../components";
import { createDoctorRole, deleteDoctorRole, getDoctorRole, getDoctorRoleDetail, getDoctorScreenList, updateDoctorRole } from "../../middleware/services/roles";
import { actionAccessMain, capitalizeFirstLetter, formatDate_Do_MMM_YYYY, handleAlphaNumericDotAndSpace, useScreenType } from "../../auth";
import { css, Global } from "@emotion/react";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import { useLocation, useNavigate } from "react-router-dom";

const checkboxLabelStyles = {
  ".chakra-checkbox__label": {
    // targeting the label part of the checkbox
    fontSize: "14px",
    color: "#061027",
    fontWeight: "600",
  },
};

const RolesIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const addNewRole = useRef();
  const editNewRole = useRef();
  const viewNewRole = useRef();
  const deleteRole = useRef();
  const navigate = useNavigate();
  const location = useLocation(); 

  const TableHeader = [
    { key: "patient", label: "Patient" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile No" },
    { key: "appointmentDate", label: "Appointment Date" },
    { key: "actions", label: "Actions" },
  ];



  const [refresh, setRefresh] = useState(false)

  const [data, setData] = useState([]);
  const [roleDetailData, setRoleDetailData] = useState("");
  
  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessUsers, setActionAccessUsers] = useState(null);

  const [doctorId, setDoctorId] = useState(null);
  const [rolename, setRolename] = useState(null);
  const [roledescription, setRoledescription] = useState(null);
  const [country, setCountry] = useState(null);


  
  const [pages, setPages] = useState([]);
  const [pagesDB, setPagesDB] = useState([]);
  

  const [homePage, setHomePage] = useState("")
  const [visitsPage, setVisitsPage] = useState("")

  const [searchValue, setSearchValue] = useState("")
  const [search, setSearch] = useState(false)

  const [filteredData, setFilteredData] = useState([]);

  const [showFilteration, setshowFilteration] = useState(false);
  
  const [editId, setEditId] = useState("")
  
  const [deleteId, setDeleteId] = useState("")
  const [userType, setUserType] = useState("4")
  const screenType = useScreenType()

  const [ viewData, setViewData] = useState([])

  //   getDoctorRole   initial call  ------------- bind in main page ----
  useEffect(() => {
    
    setActionAccess(actionAccessMain(9))
    setActionAccessUsers(actionAccessMain(10))
    showLoader();
    var input = {};
    var getDoctorRoleFunction = async () => {
      try {
        var result = await getDoctorRole(input)
        getDoctorScreenListFunction();
        if (result) {
          setData(result)
          const filtered = data.filter(item =>
            item.rolename?.toLowerCase().includes(searchValue.toLowerCase())
          );
          setFilteredData(filtered);
        } else if (result && result.output && result.output.message == "No roles are available for this doctorid") {
          setData("")
          //  showErrorToast("Something went wrong!")
        } else {
          setData("")
          //  showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      dismissLoader();
    }

    getDoctorRoleFunction();

  }, [refresh]);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredData(data);
    } else {
      if(data.length > 0){
        const filtered = data.filter(item =>
          item.rolename?.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredData(filtered);
      }else{
        setFilteredData([]);
      }
    }
  }, [search, data, searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredData(data);
      setshowFilteration(false)
    }
  }, [searchValue]);


  const clearState = () => {
    setRolename(null)
    setRoledescription(null)
    setCountry(null)
    if(pagesDB){

      setPages(pagesDB);
    }
    setUserType("4")
  }


  //   createDoctorRole    ------------------------------------ in create page  ----

  const openAddModal = () => {
    clearState();
    getDoctorScreenListFunction();
   
    addNewRole.current.openRight()
  }
  const addUserInNewTab = (id) => {
    window.open("/users", "_blank");
    localStorage.setItem("addUserFromRoles", true)
    localStorage.setItem("selectedRoleId", id)
  }

  const [screenList, setScreenList] = useState([])
 

  const getDoctorScreenListFunction = async ()=>{
    const inputForUpdateDoctorRole = {};
    try {
      showLoader();
      //const result = true;
     const result = await getDoctorScreenList(inputForUpdateDoctorRole);
    // console.log(result , " result")
      // if (result?.output?.message == "No roles are available for this doctorid") {
      //   showErrorToast("No roles are available for this doctorid")
      //   dismissLoader();
      //   return false;
      // }
      if (result) {
        //setRefresh(!refresh)
        //here
        //console.log(result, " result screen list")
       // setScreenList(result)

        // Mapping over the result and setting screenList
  const updatedScreenList = result.map(item => {
    // Extract existing actions from screendetails
    const existingActions = item?.screendetails?.map(detail => detail.name.toLowerCase());

    // Clone the current item to modify it
    const newItem = { ...item };

    // Conditionally set actions based on screendetails
    if (existingActions?.includes("create")) {
      newItem.create = 0;
    }
    if (existingActions?.includes("update")) {
      newItem.update = 0;
    }
    if (existingActions?.includes("read")) {
      newItem.read = 0;
    }
    if (existingActions?.includes("delete")) {
      newItem.delete = 0;
    }

    return newItem;
  });

  // Setting the modified screenList
  setScreenList(updatedScreenList);
        
        if (result && Array.isArray(result)) {
          const pagesFromResult = result.map((screen, index) => ({
            name: screen.name,
            description: screen.description,
            create: 0,
            update: 0,
            read: 0,
            delete: 0,
            masterscreenid: screen.masterscreenid,
          }));
          setPagesDB(pagesFromResult)
          // Set the pages state with the structured data from result
          setPages(pagesFromResult);
        }
       // addNewRole.current.closeRight()
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error updateDoctorRoleFunction")

    }
    dismissLoader();
  }

  const closeAddModal = () => {
    clearState();
    addNewRole.current.closeRight()
  }

  const switchFunctionAdd = (e, page, action) => {

    const isChecked = e.target.checked;

    // setPages(prev =>
    //   prev.map(item =>
    //     item.screenname == page ? { ...item, [action]: isChecked ? 1 : 0 } : item
    //   )
    // )
    setPages(prev =>
      prev.map(item => {
        if (item.name === page) {
          if (action === "read" && !isChecked) {
            // Prevent unchecking "read" if any other action is checked
            if (item.create || item.update || item.delete) {
              return item; // No changes made
            }
          }

          const newItem = { ...item, [action]: isChecked ? 1 : 0 };

          // Ensure "read" is checked if any other actions are checked
          if (action !== "read" && isChecked) {
            newItem.read = 1;
          }

          return newItem;
        }
        return item;
      })
    );
  }

  const CheckUncheckInAdd = (e, screen, action) => {
    const isChecked = e.target.checked;
  
  
    setScreenList(prev =>
      prev.map(item => {
        if (item.name === screen.name) {
          if (action === "read" && !isChecked) {
            // Prevent unchecking "read" if any other action is checked
            if (item.create || item.update || item.delete) {
              return item; // No changes made
            }
          }
  
          const newItem = { ...item, [action]: isChecked ? 1 : 0 };
  
          // Ensure "read" is checked if any other actions are checked
          if (action !== "read" && isChecked) {
            newItem.read = 1;
          }
  
          return newItem;
        }
        return item;
      })
    );
  };

  const CheckUncheckInUpdate = (e, screen, action) => {
    const isChecked = e.target.checked;
  
  
    setViewData(prev =>
      prev.map(item => {
        if (item.masterscreenid === screen.masterscreenid) {
          // Prevent unchecking "read" if any other action is checked
          if (action === "read" && !isChecked) {
            if (item.create || item.update || item.delete) {
              return item; // No changes made
            }
          }
  
          const newItem = { ...item, [action]: isChecked ? 1 : 0 };
  
          // Ensure "read" is checked if any other actions are checked
          if (action !== "read" && isChecked) {
            newItem.read = 1;
          }
  
          return newItem;
        }
        return item;
      })
    );
  };
  

  const createDoctorRoleFunction = async () => {

    const inputForCreateDoctorRole = {
      rolename: rolename,
      roledescription: roledescription,
      country: country,
      usertypeid:userType,
      "pages": screenList.map(page => ({
        screenname: page.name,
        create: page.create,
        update: page.update,
        read: page.read,
        delete: page.delete,
        masterscreenid: page.masterscreenid,
      }))
    };
    const allActionsZero = screenList.every(page => 
      page.read === 0 
    );
  
    if(!rolename){
      showErrorToast("Please Enter Role Name");
      return false;
    }if(!userType){
      showErrorToast("Please Select User Type!");
      return false;
    }else  if (allActionsZero) {
      showErrorToast("Please assign at least one permission!");
      return false;
    }{
    try {
      showLoader();
      const result = await createDoctorRole(inputForCreateDoctorRole);
      if (result?.output?.message == "The role name already exists") {
        showErrorToast("The role name already exists")
        dismissLoader();
        return false;
      }
      if (result.output.result == "success") {
        setRefresh(!refresh)
        showSuccessToast("Role Created Successfully!")
        addNewRole.current.closeRight()
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error createDoctorRoleFunction")

    }
    
   }
    dismissLoader();
  }


  // ------------------
  const openEditModal = (id) => {
    setEditId(id)
    getDoctorRoleDetailFunction(id)
  }

  const closeEditModal = () => {
    clearState();
    editNewRole.current.closeRight()
  }

  const updateDoctorRoleFunction = async () => {
    const inputForUpdateDoctorRole = {
      "id": editId,
      rolename: rolename,
      roledescription: roledescription,
      country: country,
      usertypeid:userType,
      "pages": viewData.map(page => ({
        screenname: page.name,
        create: Number(page.create),
        update: Number(page.update),
        read: Number(page.read),
        delete: Number(page.delete),
        masterscreenid: page.masterscreenid,
      }))
    };

    const allActionsZero = viewData.every(page => 
      page.read === 0 
    );

    if(!rolename){
      showErrorToast("Please Enter Role Name");
      return false;
    }if(!userType){
      showErrorToast("Please Select User Type!");
      return false;
    }else if(allActionsZero) {
      showErrorToast("Please assign at least one permission!");
      return false;
    }
    try {
      showLoader();
      const result = await updateDoctorRole(inputForUpdateDoctorRole);
      if (result?.output?.message == "The role name already exists") {
        showErrorToast("The role name already exists")
        dismissLoader();
        return false;
      }
      if (result.output.result == "success") {
        setRefresh(!refresh)
        showSuccessToast("Role Updated Successfully!")
        //here
        editNewRole.current.closeRight()
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error updateDoctorRoleFunction")

    }
    dismissLoader();
  }

  const openDeleteModal = (id) => {
    setDeleteId(id)
    deleteRole.current.openModal()
  }
  const deleteDoctorRoleFunction = async () => {
    let inputForDeleteDoctorRole = {
      "id": deleteId
    };
    try {
      showLoader();
      const result = await deleteDoctorRole(inputForDeleteDoctorRole);
      if(result.output.message == "Unable to delete as the role have active users"){
        showErrorToast("Unable to delete role, as the role have active users");
        deleteRole.current.closeModal()
        dismissLoader();  
        return false;
      }
      if (result.output.result == "success") {
        setRefresh(!refresh)
        deleteRole.current.closeModal()
        showErrorToast("Role Deleted Successfully")
      } else {
        deleteRole.current.closeModal()
        dismissLoader(); 
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error deleteDoctorRoleFunction")

    }
    dismissLoader();
  }


  var getDoctorRoleDetailFunction = async (id) => {
    clearState();
    var inputForDoctorRoleDetail = {
      id
    };
    try {
      var result = await getDoctorRoleDetail(inputForDoctorRoleDetail)
      if (result?.output?.message == "No roles are available for this doctorid") {
        showErrorToast("No roles available!")

      } else {

        // setRoleDetailData(result.data)
        // doctorRoleDetailSetStateFunction(result.data , result.roleData)

        if (result.roleData) {
          setDoctorId(result.roleData[0].doctorid);
          setRolename(result.roleData[0].rolename);
          setRoledescription(result.roleData[0].roledescription);
          setCountry(result.roleData[0].country);
          setViewData(result?.data)
          
          // const reorderedPages = result.data.sort((a, b) => a.name.localeCompare(b.name));

          // console.log(reorderedPages , " reorderedPages")
          // setViewData(reorderedPages)
        let userId = result?.roleData[0]?.usertypeid;
        if(userId){
          let userIdString = userId.toString()
          setUserType(userIdString)
        }
        }
        // if (result.data) {
          //console.log(result.data , " result . data ")
          // setPages([])
          // setPages(result?.data)
          // const reorderedPages = result.data.sort((a, b) => a.name.localeCompare(b.name));

          // console.log(reorderedPages , " reorderedPages")
          // setPages(reorderedPages)

          // setPages(prevPages =>
          //   prevPages.map(page => {
          //     const updatedPage = result.data && result.data.find(p => p.masterscreenid === page.masterscreenid);
              
          //     return updatedPage ? { ...page, ...updatedPage } : page;
          //   })
          // );
          //     var homepage = result.data && result.data.find(page => page.masterscreenid === 1);
          //     var visitspage = result.data && result.data.find(page => page.masterscreenid === 2);
          //     console.log(homepage , 'homepage')
          //  setHomePage(homepage)
          //  setVisitsPage(visitspage)

          // }
          editNewRole.current.openRight()
      }

    } catch (error) {
      console.log(error, "error getDoctorRoleDetailFunction")
    }
    dismissLoader();
  }


  const clearFilters = () => {
    setSearchValue("")
    setSearch(!search)
  }

  const searchFunction = () => {
    setSearch(!search)
    setshowFilteration(true)
  }

  // const handleUserType = (value)=>{
  //   setUserType(value)
  //   if(value == 1){
  //     const updatedPages = pages.map(page => ({
  //       ...page,
  //       create: 1,
  //       update: 1,
  //       read: 1,
  //       delete: 1
  //     }));
  //     setPages(updatedPages);
  //   }else{
      
  //     setPages(pagesDB);
  //   }
  // }
  const handleUserTypeInAdd = (value)=>{
    setUserType(value)
      
    setScreenList(prev =>
      prev.map(item => {
          const existingActions = item.screendetails.map(detail => detail.name.toLowerCase());
  
          const newItem = { ...item };
  
          if (existingActions.includes("create")) {
            newItem.create = (value == 1 ? 1 : 0);
          }
          if (existingActions.includes("update")) {
            newItem.update = (value == 1 ? 1 : 0);
          }
          if (existingActions.includes("read")) {
            newItem.read =  (value == 1 ? 1 : 0);
          }
          if (existingActions.includes("delete")) {
            newItem.delete = (value == 1 ? 1 : 0);
          }
  
          return newItem;
      })
    );
    
    
  }
  
  const handleUserTypeInUpdate = (value)=>{
    setUserType(value)
    const updatedPages = viewData.map(page => {
      // Create a new object based on the existing page
      const updatedPage = { ...page };
      
      // If the user type is 1, set relevant actions to 1
      if (value == 1) {
        Object.keys(updatedPage).forEach(action => {
          if (['create', 'update', 'read', 'delete'].includes(action)) {
            updatedPage[action] = 1; // Set the action to 1 if it exists
          }
        });
      } else {
        // If the user type is not 1, set relevant actions to 0
        Object.keys(updatedPage).forEach(action => {
          if (['create', 'update', 'read', 'delete'].includes(action)) {
            updatedPage[action] = 0; // Set the action to 0 if it exists
          }
        });
      }
      
      return updatedPage; // Return the updated page object
    });
   setViewData(updatedPages);
  }


  //--------------------View role details ------------------------
  const openViewModal = (id) => {
    setEditId(id)
    getDoctorRoleDetailFunctionView(id)
  }

  const [ userDetailData, setUserDetailData] = useState([])
  const [activeTab , setActiveTab] = useState("permissions")
  var getDoctorRoleDetailFunctionView = async (id) => {
    clearState();
    var inputForDoctorRoleDetail = {
      id
    };
    try {
      var result = await getDoctorRoleDetail(inputForDoctorRoleDetail)
      if (result?.output?.message == "No roles are available for this doctorid") {
        console.log("No roles available!")

      } else {

          if (result.roleData) {
            setDoctorId(result.roleData[0].doctorid);
            setRolename(result.roleData[0].rolename);
            setRoledescription(result.roleData[0].roledescription);
            setCountry(result.roleData[0].country);
          }
             setViewData(result?.data)
            setUserDetailData(result?.roleData[0]?.userdetail)
            setActiveTab("permissions")
        viewNewRole.current.openRight()
        }
      

    } catch (error) {
      console.log(error, "error getDoctorRoleDetailFunction")
    }
    dismissLoader();
  }
  const handleActiveTab = (tab) =>{
    setActiveTab(tab)
  }
  // const gobackfunction = ()=>{
  //   window.history.back();
  //  }
  // const gobackfunction = () => {
  //   if (window.history.length > 1) {
  //     window.history.back();
  //     setTimeout(() => {
  //       if (document.referrer === "") {
  //         // If there's no referring page, navigate to '/home'
  //         navigate("/home");
  //       }
  //     }, 500); // Delay to allow history.back() to resolve
  //   }else {
  //     navigate("/home");
  //   }
  // };

  const gobackfunction = () => {
    if (window.history.length > 2 && location.key !== "default") {
      window.history.back();
  
      // Fallback if the page does not navigate
      setTimeout(() => {
        if (document.referrer === "" || window.history.length <= 1) {
          navigate("/home");
        }
      }, 500);
    } else {
      navigate("/home");
    }
  };

  return (
    <>
    <Global
        styles={css`
          .activeTab .whiteBorder::after {
            content: '';
            position: absolute;
            left: 0%;
            transform: translateX(-20%);
            transform: translateY(440%);
            border-radius: 5px 5px 0 0;
            top: 100%;
            background: #0B1F4F;
            height: 3px;
            width: 100%;
            z-index: 1;
          }
        `}
      />
      {screenType == "web" ? (
        <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {isLoading && <Loader />}
          <Box w="100%" display="flex" flexDir="column">
            <SecondaryNav withBack="Roles" goBack={gobackfunction}   />
            <Box
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1rem"
            >
              <SearchBar
                passInputPlace="Search roles"
                searchStyle={{ w: "25%" }}
                onChange={(e) => setSearchValue(e.target.value)}
                handleClick={() => searchFunction()}
                searchValue={searchValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(!search);
                    setshowFilteration(true)
                  }
                }}
              />
              <Box display='flex' gap='10px'>
                <PrimaryButton
                  onClick={()=>navigate('/users')}
                  variant="mdBtn"
                  btnStyle={{ w: "100px", float: "right" }}
                  buttonText="Users"
                  btnIcon='/assets/svgs/new-tab.svg'
                  iconSrc={true}
                  iconSrcStyle={{w:'14px'}}
                  isDisabled={ actionAccessUsers && (!actionAccessUsers.read) ? true : false} 
                  />
                  
                <PrimaryButton
                  onClick={openAddModal}
                  variant="mdBtn"
                  btnStyle={{ w: "130px", float: "right" }}
                  buttonText="Add New Role"
                  
                  isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
                />
              </Box>
            </Box>
            {/* <Divider />
            {searchValue &&
              <Box
                w="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="1rem"
              >
                <Text fontSize="0.875rem" fontWeight="600">
                  {filteredData.length > 1 ? filteredData.length + " Roles found" :
                  filteredData.length == 1 ? filteredData.length + " Role found" :
                     "No Role found"}
                </Text>
                <Text fontSize="0.875rem" color="red" fontWeight="600" onClick={clearFilters}>
                  Clear Filters
                </Text>
              </Box>
            } */}
            <Box
              h="calc(100vh - 145px)"
              overflow="hidden auto"
              border="1px solid #E6E7E9"
            >
              {filteredData && filteredData.length > 0 ?
                <Box w="100%">
                  <SimpleGrid columns={{md:2,lg:3,xl:4}} p="1rem" gap='1rem'>
                    {filteredData.map((item, index) => (
                      <Card
                        borderRadius="12px"
                        key={index}
                        w="100%"
                        border="1px solid #E6E7E9"
                        boxShadow="0px 8px 20px 0px #E6E7E966"
                      >
                        <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}} display='flex' flexDir='column'>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >                  
                            <Box cursor="pointer" display="flex" alignItems="center">
                              <Box display="flex" flexDirection="column" justifyContent="center">
                                <Text fontSize="15px" fontWeight="600" className="textOverflow" title={item.rolename ? item.rolename : "-"} maxW={{base: "180px", xl:"140px", '2xl':'175px'}}>
                                {item.rolename ? item.rolename : "-"}
                                </Text>
                              </Box>
                              <Box
                                    as="button"
                                    onClick={() => openViewModal(item.id ? item.id : "")}
                                    disabled={ actionAccess && (!actionAccess.read) ? true : false} 
                                   // border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    // p="10px"
                                    ml="5px"
                                  >
                                    <Image w="13px" src="/assets/svgs/info.svg"
                                    opacity={ actionAccess && (!actionAccess.read) ? "0.5" : ""} 
                                     />
                                  </Box>
                            </Box>
        
                              <Box display="flex" gap="10px">
                             
                                  <Box
                                    as="button"
                                    onClick={() => openEditModal(item.id ? item.id : "")}
                                    disabled={ actionAccess && (!actionAccess.update) ? true : false} 
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    p="10px"
                                  >
                                    <Image w="13px" src="/assets/imgs/edit-gray.png"
                                    opacity={ actionAccess && (!actionAccess.update) ? "0.5" : ""} 
                                     />
                                  </Box>
        
                                  <Box
                                    as="button"
                                    // onClick={editDeleteBtn}
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    p="10px"
                                    onClick={() => openDeleteModal(item.id ? item.id : "")}
                                    disabled={ actionAccess && (!actionAccess.delete) ? true : false} 
                                  >
                                    <Image w="13px" src="/assets/imgs/delete.png"
                                    
                                    opacity={ actionAccess && (!actionAccess.delete) ? "0.5" : ""}  />
                                  </Box>
                                </Box>
                              
                          </Box>
                          <Box w='100%' mt={item.roledescription ? "0.75rem" : "28px"}>
                            <Text fontSize="12px" fontWeight="500">{item.roledescription ? item.roledescription : ""}</Text>
                          </Box>
                              <Divider my="10px" />
                              <Flex w='100%'>
                                <Box w='100%' display='flex' flexDir='column'>
                               
                                    <Text fontSize="10px" color="gray.500">
                                      User type
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600">
                                      {item.usertypeid == 1 ? "Super Admin" :
                                      item.usertypeid == 2 ? "Admin" :
                                      item.usertypeid == 3 ? "Doctor" : "Other"
                                      }
                                    </Text>
                                   
                                  </Box>
                                  <Box w='100%' display='flex' flexDir='column' alignItems="center">
                               
                               <Text fontSize="10px" color="gray.500">
                                 User Count
                               </Text>
                               <Box
                                    as="button"
                                  //  onClick={() => openEditModal(item.id ? item.id : "")}
                                 //   disabled={ actionAccess && (!actionAccess.update) ? true : false} 
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="27px"
                                    h="27px"
                                  >
                                    <Text fontSize="12px" fontWeight="600">
                               {item.usercount}
                               </Text>
                                  </Box>
                               
                              
                             </Box>
                                  

                                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                                    <Text fontSize="10px" color="gray.500">
                                      Created Date
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600" whiteSpace='nowrap'>
                                    {item.createddate ? formatDate_Do_MMM_YYYY(item.createddate) : ""}
                                    </Text>
                                  </Box>
                              </Flex>

                              <Flex w="100%" paddingTop="10px" mt='auto' >
                              <Box w='100%' display='flex' justifyContent="right" >
                                {/* add new role */}
                                <PrimaryButton
                                  onClick={()=>addUserInNewTab(item.id)}
                                  variant="grayBtn"
                                  btnStyle={{ w:'100%',fontSize:"13px", h:"30px", fontWeight:"600",color:'#061027' }}
                                  buttonText="Add New User"
                                  
                                  isDisabled={ actionAccessUsers && (!actionAccessUsers.create) ? true : false} 
                                />
                                </Box>
                              </Flex>
                          
                        </CardBody>
        
                      </Card>
                    ))}

                  </SimpleGrid>
                </Box>

                :
                <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                  <Image src="/assets/svgs/roles-inactive.svg" opacity='0.25' />
                  <Text>No Roles</Text>
                </Box>
              }


             
            </Box>
          </Box>
        </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Roles"
            handlebackarrow={() => navigate(`/doctorprofile`)}
          />
          <Box w="100%">
        <Flex>
          {isLoading && <Loader />}
          <Box w="100%" display="flex" flexDir="column">
            <Box
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1rem"
              gap='0.75rem'
            >
              <SearchBar
                passInputPlace="Search roles"
                // searchStyle={{ w: "25%" }}
                onChange={(e) => setSearchValue(e.target.value)}
                handleClick={() => searchFunction()}
                searchValue={searchValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(!search);
                    setshowFilteration(true)
                  }
                }}
              />
              <PrimaryButton
                onClick={openAddModal}
                variant="mdBtn"
                // btnStyle={{ w: "10%", float: "right" }}
                buttonText="Add New Role"
                
                isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
              />
            </Box>
            <Box
              h="calc(100vh - 210px)"
              overflow="hidden auto"
              border="1px solid #E6E7E9"
            >
              {filteredData && filteredData.length > 0 ?
                <Box w="100%">
                  <SimpleGrid columns={1} p="1rem" gap='1rem'>
                    {filteredData.map((item, index) => (
                      <Card
                        borderRadius="12px"
                        key={index}
                        w="100%"
                        border="1px solid #E6E7E9"
                        boxShadow="0px 8px 20px 0px #E6E7E966"
                      >
                        <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >                  
                            <Box cursor="pointer" display="flex" alignItems="center">
                              <Box display="flex" flexDirection="column" justifyContent="center">
                                <Text fontSize="15px" fontWeight="600" className="textOverflow" title={item.rolename ? item.rolename : "-"} maxW={{base: "180px", xl:"110px"}}>
                                {item.rolename ? item.rolename : "-"}
                                </Text>
                              </Box>
                              <Box
                                    as="button"
                                    onClick={() => openViewModal(item.id ? item.id : "")}
                                    disabled={ actionAccess && (!actionAccess.read) ? true : false} 
                                   // border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    // p="10px"
                                    ml="5px"
                                  >
                                    <Image w="13px" src="/assets/svgs/info.svg"
                                    opacity={ actionAccess && (!actionAccess.read) ? "0.5" : ""} 
                                     />
                                  </Box>
                            </Box>
        
                              <Box display="flex" gap="10px">
                             
                                  <Box
                                    as="button"
                                    onClick={() => openEditModal(item.id ? item.id : "")}
                                    disabled={ actionAccess && (!actionAccess.update) ? true : false} 
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    p="10px"
                                  >
                                    <Image w="13px" src="/assets/imgs/edit-gray.png"
                                    opacity={ actionAccess && (!actionAccess.update) ? "0.5" : ""} 
                                     />
                                  </Box>
        
                                  <Box
                                    as="button"
                                    // onClick={editDeleteBtn}
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="36px"
                                    h="auto"
                                    p="10px"
                                    onClick={() => openDeleteModal(item.id ? item.id : "")}
                                    disabled={ actionAccess && (!actionAccess.delete) ? true : false} 
                                  >
                                    <Image w="13px" src="/assets/imgs/delete.png"
                                    
                                    opacity={ actionAccess && (!actionAccess.delete) ? "0.5" : ""}  />
                                  </Box>
                                </Box>
                              
                          </Box>
                          <Box w='100%' mt={item.roledescription ? "0.75rem" : "28px"}>
                            <Text fontSize="12px" fontWeight="500">{item.roledescription ? item.roledescription : ""}</Text>
                          </Box>
                              <Divider my="10px" />
                              <Flex w='100%'>
                                <Box w='100%' display='flex' flexDir='column'>
                               
                                    <Text fontSize="10px" color="gray.500">
                                      User type
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600">
                                      {item.usertypeid == 1 ? "Super Admin" :
                                      item.usertypeid == 2 ? "Admin" :
                                      item.usertypeid == 3 ? "Doctor" : "Other"
                                      }
                                    </Text>
                                   
                                  </Box>
                                  <Box w='100%' display='flex' flexDir='column' alignItems="center">
                               
                               <Text fontSize="10px" color="gray.500">
                                 User Count
                               </Text>
                               <Box
                                    as="button"
                                  //  onClick={() => openEditModal(item.id ? item.id : "")}
                                 //   disabled={ actionAccess && (!actionAccess.update) ? true : false} 
                                    border="1px solid #E6E7E9"
                                    borderRadius="full"
                                    w="27px"
                                    h="27px"
                                  >
                                    <Text fontSize="12px" fontWeight="600">
                               {item.usercount}
                               </Text>
                                  </Box>
                               
                              
                             </Box>
                                  

                                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                                    <Text fontSize="10px" color="gray.500">
                                      Created Date
                                    </Text>
                                    <Text fontSize="12px" fontWeight="600" whiteSpace='nowrap'>
                                    {item.createddate ? formatDate_Do_MMM_YYYY(item.createddate) : ""}
                                    </Text>
                                  </Box>
                              </Flex>
                          
                        </CardBody>
        
                      </Card>
                    ))}

                  </SimpleGrid>
                </Box>

                :
                <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                  <Image src="/assets/svgs/roles-inactive.svg" opacity='0.25' />
                  <Text>No Roles</Text>
                </Box>
              }


             
            </Box>
          </Box>
        </Flex>
        </Box>
        </>
      )}
      

      <ModalRight
        ref={addNewRole}
        header="Add New Role"
        modalWidth={{ base: "100%", lg: "calc(100vw - 30%)" }}
        body={
          <>
            <Box w="100%" py="1rem">
              <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}} gap='1rem'>
                  <PrimaryInput inputPlace="Enter Role Name*" variant="fullRound" inputStyleCss={{w: {base:"100%", md:"50%"}}}
                    inputValue={rolename}   maxLength={60} 
                    onChange={(e) => setRolename(handleAlphaNumericDotAndSpace(e.target.value))}
                     />

                <Box w={{base:"100%", md:'50%'}}>
                  <Text fontSize="0.875rem" fontWeight="500">
                    User type*
                  </Text>

                  <RadioGroup onChange={handleUserTypeInAdd} value={userType}   mt='0.25rem'>
                    <Box w='100%' display='flex' justifyContent='space-between' gap='0.5rem' flexWrap={{base:'wrap', md:"nowrap"}}>
                      <Radio value="1" colorScheme='green'>Super Admin</Radio>
                      <Radio value="2" colorScheme='green'>Admin</Radio>
                      <Radio value="3" colorScheme='green'>Doctor</Radio>
                      <Radio value="4" colorScheme='green'>Other</Radio>
                    </Box>
                  </RadioGroup>
                </Box>
                {/* <PrimaryInput
                  inputPlace="Enter Short description"
                  variant="fullRight"
                  inputValue={roledescription} onChange={(e) => setRoledescription(e.target.value)}
                /> */}
              </Box>
              
              <Divider my="1rem" />
              
              <Textarea placeholder="Enter Short description" w='100%' border="1px solid #E6E7E9" bg='white' sx={{}}
                value={roledescription} onChange={(e) => setRoledescription(e.target.value)}
                >
              </Textarea>

              <Divider my="1rem" />

              <Text fontSize="0.875rem" fontWeight="500">
                Configure permissions for the role:
              </Text>

              <Box
                w="100%"
                bg="white"
                mt="1rem"
                borderRadius="xl"
                border="1px solid #E6E7E9"
              >

{screenList && screenList.map((screen, outerIndex )  =>
                  <>
                   <Card borderRadius="10px" bg="none" shadow="none" key={outerIndex}>
                  <CardBody display="flex" justifyContent="space-between" flexDir={{base:"column", md:"row"}} gap='1rem'>
                    <Box w={{base:"100%", md:"50%"}}>

                    <Text fontSize="14px" fontWeight="600">
                    {screen.name}
                    </Text>
                   {/* Display page description if available */}
          {screen.description && (
            <Text fontSize="12px" fontWeight="600" >
              {screen.description}
            </Text>
          )}
                    </Box>
                
        <Stack spacing={2} w={{base:"100%", md:"50%"}} direction="column">
        {
            ["create", "update", "read", "delete"].map((action, index) => {
              const roleDetail = screen.screendetails.find(detail => detail.name.toLowerCase() === action); // Find the role detail by action name
              return roleDetail && (
                <Box display="flex" alignItems="center" gap="0.5rem" key={action}>
                  <Switch size="sm" colorScheme="green"
                    id={screen.name + action}
                  //  defaultChecked={pages[outerIndex] && pages[outerIndex][action] == 1} // Check if the switch should be checked
                   /// isChecked={pages[outerIndex] && pages[outerIndex][action] == 1} // Check if the switch should be checked
                   isChecked={screen[action] === 1} // Use isChecked directly from screen
          onChange={(e) => CheckUncheckInAdd(e, screen, action)} />
                
                <Box>
                    <FormLabel m="0" fontSize='12px' htmlFor={screen.name + action}>
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                  </FormLabel>

                    {/* Bind the description from roledetails */}
                  <Text fontSize='12px'>
                      {roleDetail.description}
                  </Text>
                </Box>
              </Box>
            );
          })
        }
          
        </Stack>
                  </CardBody>
                </Card>

                <Divider />
                  </>
                )}
              
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Flex gap="1.5rem" w="100%">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                onClick={closeAddModal}
              />
              <PrimaryButton buttonText="Save" onClick={createDoctorRoleFunction} />
            </Flex>
          </>
        }
      />
      <ModalRight
        ref={editNewRole}
        header="Edit Role"
        modalWidth={{ base: "100%", lg: "calc(100vw - 30%)" }}
        body={
          <>
            <Box w="100%" py="1rem">
              <Box w="100%" display="flex" flexDir={{base:"column", md:"row"}} gap='1rem'>
                  <PrimaryInput inputPlace="Enter Role Name*" variant="fullRound" inputStyleCss={{w: {base:"100%", md:"50%"}}}
                  defaultValue={rolename}  inputValue={rolename} 
                  onChange={(e) => setRolename(handleAlphaNumericDotAndSpace(e.target.value))}
                  maxLength={60} />

                <Box w={{base:"100%", md:'50%'}}>
                  <Text fontSize="0.875rem" fontWeight="500">
                    User type*
                  </Text>

                  <RadioGroup onChange={handleUserTypeInUpdate} value={userType}  mt='0.25rem'>
                    <Box w='100%' display='flex' gap='1rem'justifyContent='space-between' flexWrap={{base:'wrap', md:"nowrap"}}>
                      <Radio value="1" colorScheme='green'>Super Admin</Radio>
                      <Radio value="2" colorScheme='green'>Admin</Radio>
                      <Radio value="3" colorScheme='green'>Doctor</Radio>
                      <Radio value="4" colorScheme='green'>Other</Radio>
                    </Box>
                  </RadioGroup>
                </Box>
                {/* <PrimaryInput
                  inputPlace="Enter Short description"
                  variant="fullRight"
                  inputValue={roledescription} onChange={(e) => setRoledescription(e.target.value)}
                /> */}
              </Box>
              
              <Divider my="1rem" />
              
              <Textarea placeholder="Enter Short description" w='100%' border="1px solid #E6E7E9" bg='white' sx={{}}
               value={roledescription} onChange={(e) => setRoledescription(e.target.value)}
               defaultValue={roledescription}>
              </Textarea>

              <Divider my="1rem" />

              <Text fontSize="0.875rem" fontWeight="500">
                Configure permissions for the role:
              </Text>

              <Box
                w="100%"
                bg="white"
                mt="1rem"
                borderRadius="xl"
                border="1px solid #E6E7E9"
              >

                {viewData && viewData.map((screen, outerIndex )  =>
                  <>
                   <Card borderRadius="10px" bg="none" shadow="none" key={screen.masterscreenid}>
                  <CardBody display="flex" justifyContent="space-between" flexDir={{base:"column", md:"row"}} gap='1rem'>
                    <Box w={{base:"100%", md:'50%'}}>

                    <Text fontSize="14px" fontWeight="600">
                    {screen.name}
                    </Text>
          {screen.description && (
            <Text fontSize="12px" fontWeight="600" >
              {screen.description}
            </Text>
          )}
                    </Box>
                
        <Stack spacing={2} w={{base:"100%", md:'50%'}} direction="column">
          { ['read', 'create', 'update', 'delete'].map((action) => {
          const roleDetail = screen.roledetails.find(detail => detail.name.toLowerCase() === action); // Find the role detail by action name
         
              return roleDetail && (
                <Box display="flex" alignItems="center" gap="0.5rem" key={action}>
                  <Switch size="sm" colorScheme="green"
                    id={screen.name + action}
                    defaultChecked={screen[action] === 1} // Default state based on initial value from viewData
                    isChecked={screen[action] === 1} // Controlled state for the switch
                    onChange={(e) => CheckUncheckInUpdate(e, screen, action)} // Use the new function
                     />
                  
                  <Box>
                    <FormLabel m="0" fontSize='12px' htmlFor={screen.name + action}>
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </FormLabel>
                    
                    <Text fontSize='12px'>
                      {roleDetail.description}
                    </Text>
                  </Box>
                </Box>
              );
            })
          }
        </Stack>
                  </CardBody>
                </Card>

                <Divider />
                  </>
                )}
                <Divider />
              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Flex gap="1.5rem" w="100%">
              <PrimaryButton
                buttonText="Cancel"
                variant="grayBtnFull"
                btnStyle={{ color: "#AA2F27" }}
                onClick={closeEditModal}
              />
              <PrimaryButton buttonText="Save" onClick={updateDoctorRoleFunction} />
            </Flex>
          </>
        }
      />


      <ModalRight
        ref={viewNewRole}
        header={rolename + " -  Role"}
        modalWidth={{ base: "100%", lg: "calc(100vw - 50%)" }}
        body={
          <>
            <Box w="100%" py="1rem" borderBottom="1px solid #E6E7E9">
              <Box display="flex" gap='1rem' >
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" 
                  className={activeTab == "permissions" ? "activeTab" : ""} fontSize="0.87rem"
                  onClick={()=>handleActiveTab("permissions")} 
                  color={activeTab == "permissions" ? "#061027" : "#898D97"}>
                  <Text fontWeight={activeTab == "permission" ? "700" : "600"}>Permissions</Text>
                  <Box as="span" className="whiteBorder"/>
                </Box>
                
                <Box as="button" display="flex" justifyContent="center" alignItems="center" pos="relative" 
                  className={activeTab == "assigned" ? "activeTab" : ""} fontSize="0.87rem"
                  onClick={()=>handleActiveTab("assigned")} 
                  color={activeTab == "assigned" ? "#061027" : "#898D97"}>
                  <Text fontWeight={activeTab == "assigned" ? "700" : "600"}>Assigned</Text>
                  <Box as="span" className="whiteBorder"/>
                </Box>
{/* 
                  <Box 
                  as="button" 
                  onClick={()=>handleActiveTab("permissions")}
                  >
                  <Text fontSize="0.875rem" fontWeight="500"
                  color={activeTab == "permissions" ? "green" : "" }>
                    Permissions
                  </Text> 
                  </Box> */}

                  {/* <Box 
                  as="button" 
                  onClick={()=>handleActiveTab("assigned")}
                  >
                   <Text fontSize="0.875rem" fontWeight="500"
                    color={activeTab == "assigned" ? "green" : "" }>
                    Assigned
                  </Text> */}

                  
                  </Box>
              </Box>
              
              {/* <Divider my="1rem" /> */}
              
              {activeTab == "assigned" && 
              <Box >
              {userDetailData ? 
                <Flex
                w="100%"
                flexDir="column"
                gap="1rem"
                mt='1rem'
                // h="calc(100vh - 70px)"
              >
                <SimpleTable
                  isTh={false}
                  tableHeaders={<>
                    <Tr>
                        <Th fontSize="0.87rem" fontWeight="500">Name</Th>
                        <Th fontSize="0.87rem" fontWeight="500">Email</Th>
                        <Th fontSize="0.87rem" fontWeight="500">Mobile</Th>
                        <Th fontSize="0.87rem" fontWeight="500">Status</Th>
                    </Tr>
                  </>}
                  // headers={["Name", "Mobile", "Status"]}
                  isDataPresent={true}
                  ThStyle={{
                    bg: "#FAFAFA",
                  }}
                  tableStyle={{
                    maxH: "calc(100vh - 170px)",
                    overflowY: "auto",
                    tableLayout: "fixed",
                    width: "100%",
                    border: "1px solid #E6E7E9",
                  }}
                  tableBody={
                    <>
                    {userDetailData.map((user, index) => (
                      <Tr key={index}>
                        <Td fontSize='14px'>{user.fullname}</Td>
                        <Td fontSize='14px'>{user.email}</Td>
                        <Td fontSize='14px'>{user.mobileno}</Td>
                        <Td>
                          <Box w="fit-content" p='0.3rem 0.5rem' borderRadius='full' textAlign='center' bg={user.isactive == 1 ? "#D0F3E7" : "rgba(230, 231, 233, 0.4)"}>
                            <Text fontSize='14px' mt='-3px' color={user.isactive == 1 ? "#109E6A" : "red"}>
                              {user.isactive == 1 ? "Active" : "Inactive"}
                            </Text>
                          </Box>
                        </Td> 
                      </Tr>
                    ))}
                    </>
                  }
                />
              </Flex>
                
                : <>
                <Box display='flex' justifyContent='center' alignItems='center' h='calc(100vh - 160px)'>
                  <Text fontSize='1rem' fontWeight='600'>No Active Users </Text>
                </Box>
                </>



                }
                
              </Box>
              }


                 

            {activeTab == "permissions" &&

            <>
            <Box mt="1rem">
              <Text fontSize="14px" fontWeight='600'>Short Description</Text>
              <Text fontSize="13px">{roledescription ?roledescription : "-"}</Text>
            </Box>
              
  <Text mt="1rem" fontWeight='600'>Permissions</Text>
  <Box
    w="100%"
    bg="white"
   // mt="1rem"
    borderRadius="xl"
    border="1px solid #E6E7E9"
  >
    {viewData && viewData.filter(page => page.create || page.read || page.update || page.delete).map(screen => (
  <>
    <Card borderRadius="10px" bg="none" shadow="none" key={screen.masterscreenid}>
      <CardBody>
        <Box w="100%" mb="15px">
          <Text fontSize="14px" fontWeight="600">
            {screen.name}
          </Text>
          {/* Display page description if available */}
          {screen.description && (
            <Text fontSize="12px" fontWeight="500" >
              {screen.description}
            </Text>
          )}
        </Box>

        {/* Create a grid that allows only 2 items per row */}
        <Box display="flex" flexWrap="wrap" gap="1rem">
          {screen.roledetails.map(role => {
            if (role.isactive) {
              return (
                <Box
                  key={role.id}
                  display="flex"
                  alignItems="flex-start"
                  gap="0.5rem"
                  flexBasis="calc(50% - 0.5rem)" // 50% width for each item with spacing
                
                >
                  <Box w="16px" h="16px" mt="6px">
                    <Image src="/assets/svgs/check.svg" />
                  </Box>

                  <Box maxW="90%">
                    <FormLabel m="0" fontSize="12px" htmlFor={screen.name + role.name}>
                      {capitalizeFirstLetter(role.name)}
                    </FormLabel>
                    {/* Display action description below the action name */}
                    <Text fontSize="12px" >
                      {role.description}
                    </Text>
                  </Box>

                </Box>
              );
            }
            return null;
          })}
        </Box>
      </CardBody>
    </Card>
    <Divider />
  </>
))}

  </Box>
  
  </>
}

            {/* </Box> */}
          </>
        }
        //footerActions={
          // <>
          //   <Flex gap="1.5rem" w="100%">
          //     <PrimaryButton
          //       buttonText="Cancel"
          //       variant="grayBtnFull"
          //       btnStyle={{ color: "#AA2F27" }}
          //       onClick={closeEditModal}
          //     />
          //     <PrimaryButton buttonText="Save" onClick={updateDoctorRoleFunction} />
          //   </Flex>
          // </>
        //}
      />
      <DynamicModal
        ref={deleteRole}
        modalHeader="Delete Role"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete role?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton variant="deleteBtn" buttonText="Delete" onClick={deleteDoctorRoleFunction} />
          </>
        }
      />
    </>
  );
};

export default RolesIndex;
