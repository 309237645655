import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Flex,
  Image,
  ModalHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import Footer from "../../components/footer";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import React, { useState, useEffect, useRef } from "react";
import { acceptAppointment, FetchNotifications, virtualAppointmentDetailById} from "../../middleware/services/notifications/index";
import { actionAccessMain, useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components";
import { FaCloudShowersWater } from "react-icons/fa6";
import ModalRight from "../../components/modal-right";
import { calculateAge } from "../../middleware/domains/visits/patient-health/prescriptions";





const NotificationsIndex = () => {
  var [doctorid, setDoctorid] = useState();
  var [data, setData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType();
  const navigate = useNavigate();
  const [actionAccessVisits , setactionAccessVisits] = useState(null)
  const [actionAccessMessages , setactionAccessMessages] = useState(null)
  const [actionAccessAppointments, setActionAccessAppointments] = useState(null);

  const acceptAppointmentModal = useRef();

  useEffect(() => {
  showLoader();
  const fetchData = async () => {
    setactionAccessVisits(actionAccessMain(2))
    setactionAccessMessages(actionAccessMain(3))
   setActionAccessAppointments(actionAccessMain(15))
    try {
      const result = await FetchNotifications({
        doctorid,
      });

      //console.log(result, " result notifications");
    
      if (result != null) {
        setData(result);
      } else {
      }
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  dismissLoader();
  fetchData();
}, []);
  const Redirect = (item)=>{
    if(item.notificationfor == 'chat'){
      // navigate("")
      const url = `/messages`;
      window.open(url, '_blank');
    } else if(item.notificationfor == 'appointment'){
      // navigate("/visits")
      const url = `/visits`;
      window.open(url, '_blank');
    }
  }

  const navigateToStartVisit = async (step)=>{
    showLoader();
    // console.log(step , " step")
    let appointmentId = step.entityid;
    let doctorId =  step.doctorid;
    let patientId =  step.patientid;

    let input = {
      "appointmentid":appointmentId
    }
    let result = await acceptAppointment(input);
    if(result.output.result == "success"){
      
        const url = `/startvisit?doctorId=${doctorId}&patientId=${patientId}&appointmentId=${appointmentId}&virtualVisit=${true}`
      navigate(url)
     
    }
    dismissLoader();
  }

  const [patienInfo, setpatienInfo] = useState({});

   const [chatHistory, setChatHistory] = useState([])
  
  const [patientID , setPatientID] = useState(null)
  const [appointmentInfo , setAppointmentInfo] = useState(null)
  async function openAcceptModal (step){



      var input = {
        "appointmentid":step.entityid
      };
        try {
          var result = await virtualAppointmentDetailById(input)
          if (result) {
            // console.log(result ,' Result of virtualvisit detail')
            if(result?.output?.data?.patientinfo){
              setpatienInfo(result?.output?.data?.patientinfo);
              //  setPatientID(result?.output?.data?.patientinfo?.id)
            }else {
              setpatienInfo({})
            }
            if(result?.output?.data?.virtualappointmentchatresults){
              setChatHistory(result?.output?.data?.virtualappointmentchatresults)
            }else {
              setChatHistory([])
            }
          }  else {
            console.log('Something went wrong!')
          }
    
        } catch (error) {
          console.log(error, "error in fetching virtualAppointmentDetailById")
        }
        dismissLoader();

    // console.log(step , " step")
    setAppointmentInfo(step)
    acceptAppointmentModal.current.openRight();

  } 

  const [subHeader , setSubHeader] = useState("")
  const [patientInfoId , setPatientInfoId] = useState("")
  useEffect(() => {
      if (patienInfo) {
        const {
          gender,
          dob,
          bloodtype,
          countrycode,
          mobileno,
          height,
          heightunit,
          weight,
          weightunit,
          bmi,
        } = patienInfo;
  
        const formattedGender = gender
          ? gender.charAt(0).toUpperCase() + gender.slice(1)
          : null;
        const dobInYears = dob ? calculateAge(dob) : null;
        const formattedBloodType = bloodtype || null;
        const formattedCountryCode = countrycode || null;
        const formattedMobileNo = mobileno || null;
        const formattedHeight =
          height && heightunit ? `${height} ${heightunit}` : null;
        const formattedWeight =
          weight && weightunit ? `${weight} ${weightunit}` : null;
        const formattedBMI = bmi || null;
  
        const overviewData = [
          formattedGender,
          dobInYears ? `${dobInYears} years` : null,
          formattedBloodType,
          formattedCountryCode && formattedMobileNo
            ? `(${formattedCountryCode}) ${formattedMobileNo}`
            : null,
          formattedHeight,
          formattedWeight,
          formattedBMI ? `${formattedBMI} BMI` : null,
        ]
          .filter((item) => item !== null)
          .join(", ");
  
          setSubHeader(overviewData);

          let patientUniqueId =  patienInfo?.uniqueid
                         ? "#" + patienInfo?.uniqueid
                          : "";
                          setPatientInfoId(patientUniqueId)
          
      }
     
    }, [patienInfo]);

    const navigateToViewProfile = async ( appointmentInfo) => {
      // localStorage.setItem("previousPage" , "patients")
      let patientid = appointmentInfo.patientid;
        // navigate(
        //   `/patientprofile?id=${patientid}&page=overview`
        // );
        const url = `/patientprofile?id=${patientid}&page=overview`;
        window.open(url, '_blank');
    };

  return (
    <>
      {isLoading && <Loader />}

      {screenType == 'web' ? (
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            <Box w="100%">
              <SecondaryNav customHeader="Notifications" />

              {/* Main container start */}
              <Flex w="calc(100vw - 6rem)" h='calc(100vh - 130px)'>
                <Box
                  p="1.5rem 8.25rem"
                  h="calc(100vh - 130px)"
                  overflow="hidden auto"
                  w="100%"
                >
                  <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                      <Box display="flex" flexDir="column" gap="1rem">
                      {data && data.length > 0 ? (
                        data.map((step, index) => (
                            <Flex key={index} alignItems="center" w="100%" gap="0.5rem" borderBottom="1px solid #E6E7E9" pb="1rem">
                                <Box display="flex" justifyContent="center" alignItems="center" boxSize="2.75rem" border="1px solid #E6E7E9" borderRadius="full">
                                    <Image src="/assets/svgs/calendar-edit.svg" />
                                </Box>
                                                    
                                <Box display="flex" flexDir="column" w="100%">
                                    <Box display="flex" justifyContent="space-between">
                                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                        {step.notificationfor 
                                        ? step.notificationfor.charAt(0).toUpperCase() + step.notificationfor.slice(1)
                                        : "-"}
                                        </Text>
                                        <Box display="flex" gap="0.25rem" alignItems="center">
                                            <Box boxSize="4px" bg="#1C4EC5" borderRadius="full"></Box>
                                            <Text fontSize="0.625rem" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                                        </Box>
                                    </Box>
                                                    
                                    <Box display="flex" justifyContent="space-between" flexWrap={step.virtualappointmentstatus == 2 ? "wrap" : "nowrap"} alignItems="center">
                                        <Text fontSize="0.875rem" fontWeight="500" color="#384052">{step.notificationcontent}</Text>
                                         {step.virtualappointmentstatus == 2 &&
                                          <Box  marginLeft="110px" >
                                                 <PrimaryButton
                                                                         onClick={()=>openAcceptModal(step)}
                                                                        variant="grayBtn"
                                                                        btnStyle={{ w:'100%',fontSize:"13px", h:"30px", fontWeight:"600",color:'#061027', }}
                                                                        buttonText="Accept Appointment"
                                                                        
                                                                         isDisabled={ actionAccessAppointments && (!actionAccessAppointments.create) ? true : false} 
                                                                      />
                                           </Box>
                                           } 
                                                                    {step.virtualappointmentstatus != 2 &&                 
                                        <Box as="button"  opacity={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                    (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                     ? 1 : 0.5}
                                                          disabled={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                      (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                       ? false : true}                    
                                                                    >

                                        <Image src="/assets/imgs/right-icon.png" w={{base:'1rem', md:'unset'}} h='auto' cursor='pointer' onClick={()=>Redirect(step)}  />
                                        </Box>
                                                 }
                                    </Box>
                                </Box>
                            </Flex>
                        ))
                      ) : (
                        <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                        <Image src="/assets/svgs/notifications.svg"/>
                        <Text>No Notifications</Text>
                      </Box>
                      )}

                      </Box>
                    
                  </Box>
                </Box>
              </Flex>
              {/* Footer start */}
              <Footer footerStyle={{m:0,p:'1.5rem',bg:'white',zIndex:'999'}} />
              {/* Footer end */}
              {/* Main container end */}
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box w="100%">
          <MobileNavbar />
          <MobileSecondaryNav
            header="Profile"
            handlebackarrow={false}
          />

          <Box
            p="1.5rem"
            h="calc(100vh - 73px)"
            overflow="hidden auto"
            w="100%"
          >
            <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                <Box display="flex" flexDir="column" gap="1rem">
                {data && data.length > 0 ? (
                  data.map((step, index) => (
                      <Flex key={index} alignItems="center" w="100%" gap="0.5rem" borderBottom="1px solid #E6E7E9" pb="1rem">
                          <Box display="flex" justifyContent="center" alignItems="center" boxSize="2.75rem" border="1px solid #E6E7E9" borderRadius="full">
                              <Image src="/assets/svgs/calendar-edit.svg" />
                          </Box>
                                              
                          <Box display="flex" flexDir="column" w="100%">
                              <Box display="flex" justifyContent="space-between">
                                  <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                  {step.notificationfor 
                                        ? step.notificationfor.charAt(0).toUpperCase() + step.notificationfor.slice(1)
                                        : "-"}
                                  </Text>
                                  <Box display="flex" gap="0.25rem" alignItems="center">
                                      <Box boxSize="4px" bg="#1C4EC5" borderRadius="full"></Box>
                                      <Text fontSize="0.625rem" fontWeight="400" color="#384052">{step.timeDifference}</Text>
                                  </Box>
                              </Box>
                                              
                              <Box display="flex" justifyContent="space-between" gap={step.virtualappointmentstatus == 2 ? "10px" : "0px"} flexWrap={step.virtualappointmentstatus == 2 ? "wrap" : "nowrap"} alignItems="center">
                                  <Text fontSize="0.875rem" fontWeight="500" color="#384052">{step.notificationcontent}</Text>
                                  {step.virtualappointmentstatus == 2 &&
                                          <Box>
                                                 <PrimaryButton
                                                                        //  onClick={()=>navigateToStartVisit(step)}
                                                                        
                                                                        onClick={()=>openAcceptModal(step)}
                                                                        variant="grayBtn"
                                                                        btnStyle={{ w:'100%',fontSize:"13px", h:"30px", fontWeight:"600",color:'#061027' }}
                                                                        buttonText="Accept Appointment"
                                                                        
                                                                         isDisabled={ actionAccessAppointments && (!actionAccessAppointments.create) ? true : false} 
                                                                      />
                                           </Box>
                                          }
                                                                    {step.virtualappointmentstatus != 2 &&   
                                  <Box as="button"  opacity={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                    (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                     ? 1 : 0.5}
                                                          disabled={(step.notificationfor == "appointment" && actionAccessVisits?.read) || 
                                                                      (step.notificationfor == "chat" && actionAccessMessages?.read)    
                                                                       ? false : true}                    
                                                                    >
                                                                      
                                  <Image src="/assets/imgs/right-icon.png" w={{base:'1rem', md:'unset'}} h='auto' cursor='pointer' onClick={()=>Redirect(step)} />
                                  </Box>
                                                 }
                              </Box>
                          </Box>
                      </Flex>
                  ))
                ) : (
                  <Box display="flex" justifyContent="center" flexDir="column" gap="10px" alignItems="center" h="100vh">
                  <Image src="/assets/svgs/notifications.svg"/>
                  <Text>No Notifications</Text>
                </Box>
                )}

                </Box>
              {/* Footer start */}
              <Footer />
              {/* Footer end */}
            </Box>
          </Box>
        </Box>
      )}


<ModalRight
          ref={acceptAppointmentModal}
          bodyStyle={{overflow:'hidden'}}
          modalWidth={{ base: "100%", lg: "calc(100vw - 50%)" }}
          body={
            <>
              <Box w="100%" py="1rem">
               <Box>
                 <Box display="flex" gap="0.5rem" alignItems="center">
                                <Image
                                  borderRadius="full"
                                  w="2.75rem"
                                  h="2.75rem"
                                  src={patienInfo?.patientimage ?  patienInfo?.imagepath + patienInfo?.patientimage : "/assets/imgs/no-image.png"}
                                />
                              <Box>
                                <Text fontSize="1rem" fontWeight="600" color="black">
                                  {patienInfo?.firstname + " " + patienInfo?.lastname}
                                </Text>
                               <Box display="flex" gap="0.25rem" alignItems="center">
                                                 {/* <Image src="/assets/svgs/home-calendar.svg" /> */}
                                                 <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                                                   {patientInfoId} {subHeader}
                                                 </Text>
                                               </Box>
                              </Box>
                            </Box>


                  {/* Virtual chat AIRA */}
                  <Box w="full" maxW="800px" mx="auto" bg="gray.50" borderRadius="lg" shadow="md"  marginTop="15px" >
                  <Text p={4} fontSize="1.125rem" paddingBottom="10px" mb="5px" fontWeight="600" borderBottom="1px solid #E6E7E9">Aira: Chat History</Text>
      <Box p={4}  overflowY="auto" height="calc(100vh - 230px)">
        {chatHistory.length > 0 ? (
 <VStack spacing={4} align="stretch">
 {chatHistory.map((message, index) => {
   return (
     <React.Fragment key={index}>
       {/* Question Section */}
       <Flex justify="flex-end">
         <Box
           bg="green.100"
           color="green.800"
           px={4}
           py={2}
           borderRadius="lg"
           maxW="70%"
           textAlign="left"
           ml="auto"
         >
           <Text fontSize="xs" color="green.500">AIRA</Text>
           <Text fontSize="sm">{message.question}</Text>
         </Box>
       </Flex>

       {/* Answer Section */}
       <Flex justify="flex-start">
         <Box
           bg="blue.100"
           color="blue.800"
           px={4}
           py={2}
           borderRadius="lg"
           maxW="70%"
           textAlign="left"
           mr="auto"
         >
           <Text fontSize="xs" color="blue.500">{patienInfo?.firstname + " " + patienInfo?.lastname}</Text>
           <Text fontSize="sm">{message.answer}</Text>
         </Box>
       </Flex>
     </React.Fragment>
   );
 })}
</VStack>
        ):(
          <Text fontSize="sm" textAlign="center" mt="30px">No chat history available!</Text>
       
        )}
     
      </Box>
    </Box>
               </Box>

  
              </Box>
              
            </>
          }
          footerActions={
            <>
              <Flex gap="1.5rem" w="100%">
               
                <PrimaryButton buttonText="Accept Appointment" 
                onClick={()=>navigateToStartVisit(appointmentInfo)} 
                />
                 <PrimaryButton
                  buttonText="View Patient Profile"
                  variant="grayBtnFull"
                  onClick={()=>navigateToViewProfile(appointmentInfo)}
                />
              </Flex>
            </>
          }
        />
    </>
  );
};

export default NotificationsIndex;
