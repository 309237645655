export default function formAPIsocialhistory({
    //duration,
    //durationtype,
    frequency,
    frequencytype,
    socialhistorytype,
    iscurrentstatus,
    fromdate,
    todate,
    socialhistory,
    iscurrent,

    duration,
    durationtype,
    durationtype_cd,
    frequencytype_cd,
    id,
    patientid,
    socialhistorytype_cd,
    smokelesstype,
    drugnames,
    exercisesdaily,
    drugtype
    
   

})
{
 
  return Object.freeze({
    //duration,
    //durationtype,
    // frequency: frequency + "x per " + frequencytype,
    // frequency,
    // frequencytype,

    socialhistorytype,
    iscurrentstatus: iscurrentstatus === 1 ? "Yes" : iscurrentstatus === 0 ? "Never" : "Quit",
    fromdate,
    todate,

    duration:duration,
    durationtype:durationtype?durationtype:"-",
    durationtype_cd:durationtype_cd,
    frequency:frequency,
    frequencytype:frequencytype?frequencytype:"-",
    frequencytype_cd:frequencytype_cd,
    id:id,
    iscurrent:iscurrent,
    patientid:patientid,
    socialhistory:socialhistory?socialhistory:"-",
    socialhistorytype_cd:socialhistorytype_cd,
    smokelesstype,
    drugnames: drugnames,
    exercisesdaily: exercisesdaily,
    drugtype
   
   
  });
}