import {
  Box,
  Checkbox,
  Divider,
  Heading,
  Image,
  RadioGroup,
  Stack,
  Circle,
  Link,
  List,
  ListItem,
  Center,
  Text,
  Button,
  Collapse,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  actionAccessMain,
  handleAlphaNumericDotAndSpace,
  handleAlphaDotAndSpace,
} from "../../../auth";
import ModalRight from "../../modal-right";
import { format } from "date-fns";
import SearchBar from "../../search-bar";
import moment from "moment";
import PrimaryButton from "../../primary-button";
import PrimaryInput from "../../primary-input";
import { uploadImageInProfile } from "../../../middleware/services/profile";
import {
  GetDoctorNotes,
  updateDoctorNotes,
  DeleteDoctorNotesDocuments,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { FetchDocumentLabels } from "../../../middleware/services/visits/aicalls";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import DynamicModal from "../../dynamic-modal";
import { COLORS } from "../../styles/colors";
import { UpdateAppointmentStatus } from "../../../middleware/services/visits/patient-overview";

import { useNavigate } from "react-router-dom";

const UploadDoctorDocuments = forwardRef(
  (
    {
      width,
      height,
      patientId,
      doctorId,
      appointmentId,
      showErrorToast,
      showSuccessToast,
    },
    ref
  ) => {
    const openFromStartVisitOverview = () => {
      console.log("Function called!");
      openSurgeriesModal();
    };

    const { isLoading, showLoader, dismissLoader } = useLoader();

    // useImperativeHandle(ref, () => ({
    //   openFromStartVisitOverview,
    // }));

    const [actionAccessPatients, setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4));
    }, []);

    const addCondition = React.useRef();
    const addSurgeriesModal = React.useRef();

    const indexMap = {};
    const [documentURLs, setDocumentURLs] = useState([]);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);

    const fileInputRef = useRef(null);
    const fileInputRefUpdate = useRef(null);
    const documentDeleteModal = useRef(null);

    useEffect(() => {
      setDocumentURLs(uploadedDocuments);
      // console.log("document urls state", documentURLs);
      // console.log("document url", uploadedDocuments);
    }, [uploadedDocuments]);

    const addSurgeriesUploadDocument = (fileName) => {
      // alert(condition.condition_cd)
      // if (condition.condition_cd) {
      //   fileInputRef.current.setAttribute(
      //     "data-surgery-cd",
      //     condition.condition_cd
      //   );
      //   fileInputRef.current.setAttribute("data-surgery", condition.condition);
      //   fileInputRef.current.click();
      // } else {
      //   fileInputRef.current.setAttribute("data-surgery-cd", null);
      //   fileInputRef.current.setAttribute("data-surgery", "other");
      //   fileInputRef.current.click();
      // }
      fileInputRef.current.setAttribute("document-type", null);
      fileInputRef.current.setAttribute("document-name", fileName);
      fileInputRef.current.click();
    };

    const handleDelete = (index) => {
      const updatedUrls = [...uploadedDocuments];
      updatedUrls.splice(index, 1);
      setUploadedDocuments(updatedUrls);
    };

    const [idToDelete, setIdToDelete] = useState(null);
    const [typeToDelete, setTypeToDelete] = useState(null);

    const handleDeleteReportId = (id, type) => {
      setIdToDelete(id);
      setTypeToDelete(type);
      if (documentDeleteModal.current) {
        documentDeleteModal.current.openModal();
      }
    };

    const deleteDocumentWithId = async () => {
      const inputForDelete = {
        input: {
          doctorid: doctorId,
          // patientid: patientId,
          reportid: idToDelete,
          type: typeToDelete,
          appointmentid: appointmentId,
        },
      };

      const deleteCondition = async () => {
        showLoader();
        try {
          const result = await DeleteDoctorNotesDocuments(inputForDelete.input);
          // console.log(result)
          if (result) {
            showSuccessToast(typeToDelete + " has been deleted");
            fetchDoctorNotes();
            documentDeleteModal.current.closeModal();
          } else {
            console.log(result.output.result);
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error in delete documents:", error);
        }
        dismissLoader();
      };
      deleteCondition();
    };

    const handleSurgeriesUpload = async (e) => {
      e.preventDefault();
      showLoader();
      if (e.target.files.length == 0) {
        dismissLoader();
        return;
      }
      const file2 = e.target.files[0];
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/webp",
      ];
      if (!allowedTypes.includes(file2.type)) {
        showErrorToast(
          "Invalid file type. Please select a PNG, JPG or JPEG file."
        );
        setTimeout(() => {
          dismissLoader();
        }, 1000);
        return false;
      }
      const condition = e.target.getAttribute("document-type");
      let condition_cd = e.target.getAttribute("document-name");
      if (condition_cd === "null") {
        condition_cd = "Document";
      }

      const reader = new FileReader();
      reader.readAsDataURL(file2);
      const result = await uploadImageInProfile(file2, file2.name);
      const blobfilename = result.name;
      // ai labelling
      const input_to_save = {
        input: {
          filename:
            "https://midocfileserverdev.blob.core.windows.net/midocdev/" +
            blobfilename,
        },
      };
      // console.log("input send for labelling", input_to_save);
      showLoader();
      const ai_result = await FetchDocumentLabels(input_to_save);
      let label_document_name = "Document";
      // console.log(ai_result);
      if (ai_result?.type) {
        // condition_cd = ai_result?.type
        //   ? ai_result?.type + " Document"
        //   : "Document";

        const documentlabel = ai_result?.type;

        if (documentlabel === "Lab") {
          // console.log("lab case");
          label_document_name = "Lab Report";
        } else if (documentlabel === "Imaging") {
          // console.log("imaging case");
          label_document_name = "Imaging Report";
        } else if (documentlabel === "Prescription") {
          // console.log("prescription case");
          label_document_name = "Prescription";
        } else {
          // console.log("other case");
          label_document_name = (documentlabel || "") + " Document";
        }
      }
      // console.log("saved name", condition_cd, label_document_name);
      dismissLoader();
      setUploadedDocuments((prevDocuments) => [
        ...prevDocuments,
        { documentname: label_document_name, documenturl: blobfilename },
      ]);
      e.target.value = "";
      dismissLoader();
    };

    const openSurgeriesModal = () => {
      setUploadedDocuments([]);
      addSurgeriesModal.current.openRight();
      // addCondition.current.openRight();
    };

    //------------------saving

    const [savedDocuments, setSavedDocuments] = useState([]); // stores data from getDoctorNotes

    var inputForAdd = {
      patientid: patientId,
      doctorid: doctorId,
      appointmentid: appointmentId,
      // documentdetail: uploadedDocuments,
      documentdetail: [...savedDocuments, ...uploadedDocuments],
    };

    const AddSurgeriesCall = () => {
      const addSurgeriesFunction = async () => {
        showLoader();
        try {
          // console.log(inputForAdd, " input for add documents");
          const result = await updateDoctorNotes(inputForAdd);
          // console.log(result);
          if (result.output.result == "success") {
            showSuccessToast("Document Saved Successfully.");
            setTimeout(() => {
              if (addSurgeriesModal?.current) {
                addSurgeriesModal.current.closeRight();
              }
              // if (addCondition?.current) {
              //   addCondition.current.closeRight();
              // }
            }, 700);
            // fetchPatientHealthData();
            // Fetch doctor notes here
            fetchDoctorNotes();
          } else {
            showErrorToast("Something went wrong!");
          }
          dismissLoader();
        } catch (error) {
          console.error("Error in add documents:", error);
        }
      };

      showLoader();
      addSurgeriesFunction();

      //}
    };

    // document index tracking
    const startValue = 0; // Your starting constant value
    let currentIndex = startValue;

    //-------------------------------------Displaying saved documents

    const [tests, setTests] = useState([]);
    const [baseURL, setBaseURL] = useState("");

    const formatDate = (dateString) => {
      if (!dateString) return "-";
      return moment(dateString).format("Do MMM, YYYY");
    };

    const fileTypeMappings = {
      pdf: "/assets/svgs/pdf-icon.svg",
      png: "/assets/imgs/png-icon.png",
      jpg: "/assets/imgs/jpg-icon.png",
      jpeg: "/assets/imgs/jpg-icon.png",
    };

    const getFileIcon = (fileName) => {
      // console.log(fileName);
      const extension = fileName.split(".").pop().toLowerCase();
      return fileTypeMappings[extension] || "/assets/svgs/file-icon.svg"; // fallback image
    };

    function transformFetchedDocuments(inputArray) {
      return inputArray.map(({ documentname, documenturl }) => ({
        documentname,
        documenturl,
      }));
    }

    const [labsData, setLabsData] = useState({});
    const [medicineData, setMedicineData] = useState({});

    const fetchDoctorNotes = async () => {
      const inputToFetch = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
        },
      };
      // console.log(inputToFetch)

      showLoader();
      try {
        const data = await GetDoctorNotes(inputToFetch.input);
        setBaseURL(data?.output?.data?.imagepath);
        setTests(transformDocuments(data?.output?.data?.documentdetail));
        // console.log(transformDocuments(data?.output?.data?.documentdetail))
        setSavedDocuments(
          transformFetchedDocuments(data?.output?.data?.documentdetail || [])
        );
        setLabsData(
          data?.output?.data?.labresults
            ? groupLabResultsByReportId(data?.output?.data?.labresults)
            : {}
        );
        setMedicineData(
          data?.output?.data?.prescriptionresult
            ? groupMedicinesByReportId(data?.output?.data?.prescriptionresult)
            : {}
        );
      } catch (error) {
        console.error("Error fetching Doctor Notes data:", error);
      } finally {
        dismissLoader();
      }
    };

    useEffect(() => {
      fetchDoctorNotes();
    }, []);

    function transformDocuments(input) {
      // Base URL for the document links
      // const baseUrl =
      //   "https://midocfileserverdev.blob.core.windows.net/midocdev/";

      // Return an empty array if input is null or empty
      if (!Array.isArray(input) || input.length === 0) {
        return [];
      }

      // Map each document in the input to the required structure
      const transformedData = input.map((document) => {
        const documentUrl = document.documenturl;
        const link = `${baseURL}${documentUrl}`;

        return {
          id: document.reportid,
          // name:
          //   document.reporttype.toUpperCase() + ": " + document.reportname ||
          //   "",
          name:
            document.reporttype.toUpperCase() +
              ": " +
              (document.reportname === "prescription" ||
              document.reportname === "other"
                ? "Document"
                : document.reportname) || "",
          type: document.reporttype || "",
          interpretation: document.reportinterpretation || "",
          normal: document.normalresults || "",
          abnormal: document.abnormalresults || "",
          summary: "", // Summary is empty as requested
          date: document.verifieddate || "", // Use the document's date
          documenturl: documentUrl || "", // Use the document's URL
          attachments: [
            {
              id: document.reportid,
              link: document?.documenturl ? document?.imagepath + document?.documenturl : null,
              type: document.reporttype || "",
              name:
                document.reporttype.toUpperCase() +
                  ": " +
                  document.reportname || "",
              filename: document.documenturl || "", // Use the document's name
            },
          ],
        };
      });

      return transformedData;
    }

    //--------------------lab results formatter
    function groupLabResultsByReportId(labResults) {
      return labResults.reduce((acc, result) => {
        const reportId = result.reportid;
        // Initialize the report object if it doesn't exist
        if (!acc[reportId]) {
          acc[reportId] = [];
        }
        // Push the formatted subobject into the report array
        acc[reportId].push({
          name: result.eventtitle,
          value: `${result.resultvalue} ${result.resultuom}`,
          range: `${result.lower_bound} - ${result.upper_bound}`,
        });
        return acc;
      }, {});
    }

    //--------------------medicine results formatter
    function groupMedicinesByReportId(medResults) {
      return medResults.reduce((acc, result) => {
        const reportId = result.reportid;
        // Initialize the report object if it doesn't exist
        if (!acc[reportId]) {
          acc[reportId] = [];
        }
        // Push the formatted subobject into the report array
        acc[reportId].push({
          name: result?.medicinename,
          type: result?.medicinetype,
          composition: result?.chemicalcomposition
            ? result.chemicalcomposition
            : "-/-",
        });
        return acc;
      }, {});
    }

    //------------------------Delete functionality
    const removeDocumentAtIndex = async (indexToRemove) => {
      // setSavedDocuments((prevDocuments) =>
      //   prevDocuments.filter((_, index) => index !== indexToRemove)
      // );

      var inputForAdd = {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
        documentdetail: savedDocuments.filter(
          (_, index) => index !== indexToRemove
        ),
      };

      showLoader();
      try {
        // console.log(inputForAdd, " input to delete documents");
        const result = await updateDoctorNotes(inputForAdd);
        // console.log(result);
        if (result.output.result == "success") {
          showSuccessToast("Document Deleted Successfully.");
          // fetchPatientHealthData();
          // Fetch doctor notes here
          fetchDoctorNotes();
        } else {
          showErrorToast("Something went wrong!");
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in add documents:", error);
      }
    };

    // Multi Document Upload

    var navigate = useNavigate();

    const [fileNameGathered, setFileNameGathered] = useState(null);
    const [resultGathered, setResultGathered] = useState(null);
    const [allFilesGathered, setAllFilesGathered] = useState(null);

    const GenericUpload = async (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      const allFiles = e.target.files;
      console.log(allFiles, "allFiles from index");
      const fileCount = allFiles.length || 0;
      if (fileCount > 0) {
        localStorage.setItem("totalDocumentsSelected", fileCount);
        localStorage.setItem("remainingDocuments", fileCount);

        // start visit and continue visit related vars
        localStorage.setItem("runningVisitPatientId", patientId);
        localStorage.setItem("runningVisitDoctorId", doctorId);
        localStorage.setItem("runningVisitAppointmentId", appointmentId);
        localStorage.setItem("runningStartVisit", true);

        // pause visit
        const inputForPause = {
          input: {
            doctorid: doctorId,
            appointmentid: appointmentId,
            type: "pause",
            time: new Date().toISOString(), // current timestamp
          },
        };

        try {
          // showLoader();
          const result = await UpdateAppointmentStatus(inputForPause.input);
        } catch (error) {
          console.error("Error pausing visit:", error);
          showErrorToast("An error occurred while pausing visit");
        } finally {
          // dismissLoader();
        }
      } else {
        localStorage.removeItem("remainingDocuments");
        localStorage.removeItem("totalDocumentsSelected");
      }

      showLoader();
      navigate("/docupload", { state: { file, allFiles } });
      dismissLoader();
    };

    return (
      <Box h="100%">
        {isLoading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="10"
            // bg="rgba(255, 255, 255, 0.8)"
          >
            <Loader miniLoader={true} />
          </Box>
        )}
        <Box
          bg="#fcfcfd"
          width={width}
          height={height}
          // display="flex"
          // justifyContent="center"
          // alignItems="center"
          // flexDir="column"
          h="100%"
          gap="1rem"
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            mt="1rem"
            width="100%"
            mx="auto"
          >
            {/* <PrimaryButton
              fontSize="14px"
              variant="mdBtn"
              btnIcon="/assets/imgs/add.png"
              buttonText="Add Document"
              onClick={openSurgeriesModal}
              isDisabled={actionAccessPatients?.create ? false : true}
            /> */}
            <input
              type="file"
              id="genericupload"
              style={{ display: "none" }}
              accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
              onChange={GenericUpload}
              multiple
            />
            <PrimaryInput
              inputType="file"
              uploadText="Upload document"
              id="genericupload"
              docBtnWidth="195px"
              docBtnColor="#1C4EC5"
              iconImg="/assets/svgs/prescriptions.svg"
              iconImgCss={{
                width: "30px",
                height: "auto",
                color: "#1C4EC5",
                filter:
                  "brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2247%) hue-rotate(215deg) brightness(83%) contrast(96%)",
              }}
              onChange={GenericUpload}
              variant="grayBtnFull"
              //  disableFileInput={actionAccessPatients?.create ? false : true}
            />
          </Box>
          {/* <Divider /> */}
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            w="95%"
            mt="0.25rem"
            ml="0.5rem"
          >
            {tests.map((test, index) => (
              <Box
                key={index}
                display="flex"
                flexDir="column"
                alignItems="center"
                w="100%"
                mt="4px"
              >
                <Box display="flex" alignItems="center" w="100%">
                  <Circle
                    size="1rem"
                    bg="white"
                    border="2px solid #CDCFD4"
                    borderRadius="full"
                    color="black"
                  />
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    textAlign="left"
                    color="#384052"
                    ml="0.5rem"
                  >
                    {formatDate(test.date)}
                  </Text>
                </Box>
                <Box display="flex" w="100%" mt="10px">
                  <Center
                    w="100%"
                    h="auto"
                    ml="0.45rem"
                    alignItems="start"
                    borderLeft="2px solid #E6E7E9"
                  >
                    <Box w="100%">
                      <Box
                        p="20px"
                        w="100%"
                        ml="10px"
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        justifyContent="space-between"
                        border="1px solid #E6E7E9"
                        borderRadius="12px"
                        backgroundColor={"white"}
                      >
                        <Box display="flex" alignItems="center" w="100%">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            flex="1"
                          >
                            <Text
                              title={test.name}
                              fontSize="16px"
                              textAlign="left"
                              fontWeight="600"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {test.name}
                            </Text>
                          </Box>
                          <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            m4="10px"
                            onClick={() =>
                              handleDeleteReportId(test.id, test.type)
                            }
                          >
                            <Image w="13px" src="/assets/imgs/delete.png" />
                          </Box>
                        </Box>
                        {/* <Box display="flex" alignItems="center" w="100%">
                        <Box
                          mt="3px"
                          display="flex"
                          alignItems="center"
                          backgroundColor="#f9fafb"
                          borderLeft="3px solid #14c585"
                          w="100%"
                        >
                          <Text
                            fontSize="14px"
                            fontWeight="500"
                            color="#384052"
                            pl="4px"
                          >
                            {test.summary}
                          </Text>
                        </Box>
                      </Box> */}
                        <Divider my="10px" />
                        <Box display="flex" w="100%" flexWrap="wrap">
                          {test.attachments.map(
                            (attachment, index) =>
                              test?.documenturl && (
                                <Box
                                  key={index}
                                  display="flex"
                                  alignItems="center"
                                  border="1px solid #E6E7E9"
                                  borderRadius="8px"
                                  p="5px 10px"
                                  mr="10px"
                                  backgroundColor="white"
                                >
                                  <Box bg="#f9f9f9" p="5px" borderRadius="8px">
                                    <Image
                                      src={getFileIcon(attachment.filename)}
                                      w="16px"
                                    />
                                  </Box>
                                  <Link
                                    href={attachment.link}
                                    isExternal
                                    fontSize="14px"
                                    color="#2B6CB0"
                                    ml="5px"
                                  >
                                    {/* {attachment.name} */}
                                    View
                                  </Link>
                                </Box>
                              )
                          )}
                        </Box>
                        {/* Conditional view for "labs" */}
                        {test.type === "labs" && labsData?.[test.id] && (
                          <>
                            <Divider my="10px" />
                            <Accordion width={"100%"}>
                              <AccordionItem>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    Lab Results
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <Table variant="striped" colorScheme="gray">
                                    <Thead>
                                      <Tr>
                                        <Th>Name</Th>
                                        <Th>Value</Th>
                                        <Th>Range</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {labsData[test.id].map((result, idx) => (
                                        <Tr key={idx}>
                                          <Td>{result.name}</Td>
                                          <Td>{result.value}</Td>
                                          <Td>{result.range}</Td>
                                        </Tr>
                                      ))}
                                    </Tbody>
                                  </Table>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        )}
                        {/* Conditional view for "imaging" */}
                        {test.type === "imaging" && (
                          <>
                            <Divider my="10px" />
                            <Accordion width={"100%"}>
                              <AccordionItem>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    Imaging Results
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <Collapse in={true} animateOpacity>
                                    <Box
                                      p="15px"
                                      border="1px solid #E6E7E9"
                                      borderRadius="8px"
                                      bg="#f9fafb"
                                    >
                                      <Text
                                        fontSize="14px"
                                        fontWeight="600"
                                        color="#2b6cb0"
                                        mb="4px"
                                      >
                                        Interpretation:{" "}
                                        {test.interpretation || "N/A"}
                                      </Text>
                                      <Text
                                        fontSize="14px"
                                        fontWeight="600"
                                        color="#e53e3e"
                                        mb="4px"
                                      >
                                        Abnormal: {test.abnormal || "N/A"}
                                      </Text>
                                      <Text
                                        fontSize="14px"
                                        fontWeight="600"
                                        color="#38a169"
                                      >
                                        Normal: {test.normal || "N/A"}
                                      </Text>
                                    </Box>
                                  </Collapse>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </>
                        )}

                        {/* Conditional view for "imaging" */}
                        {test.type === "prescription" &&
                          medicineData?.[test.id] && (
                            <>
                              <Divider my="10px" />
                              <List>
                                {medicineData[test.id].map((result, idx) => (
                                  <ListItem key={idx}>
                                    <Text fontWeight="bold" display="inline">
                                      {result.type}:
                                    </Text>
                                    <Text display="inline">
                                      {result.name}, {result.composition}
                                    </Text>
                                  </ListItem>
                                ))}
                              </List>
                            </>
                          )}

                        {test.type === "other" && test?.documenturl && (
                          <>
                            <Divider my="10px" />
                            <Image
                              // w="calc(90% - 20px)"
                              w="auto"
                              src={baseURL + test?.documenturl}
                              h="auto"
                              objectFit="contain"
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Center>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <ModalRight
          ref={addSurgeriesModal}
          header="Add Document"
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                {documentURLs?.map((document, index) => {
                  // if (
                  //   document.condition_cd == condition.condition_cd ||
                  //   (document.condition_cd == null &&
                  //     condition.condition_cd == null)
                  // ) {
                  //   if (!indexMap[document.condition_cd]) {
                  //     indexMap[document.condition_cd] = 1;
                  //   }
                  // const docIndex = indexMap[document.condition_cd];
                  // indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                  const docIndex = currentIndex;
                  currentIndex++;

                  return (
                    <Box key={index} mb="2" mt="2">
                      <Button
                        as="a"
                        rel="noopener noreferrer"
                        w="100%"
                        h="48px"
                        borderRadius="10px"
                        border="1px solid #E6E7E9"
                        display="flex"
                        justifyContent="space-between"
                        bg="white"
                        color="#1C4EC5"
                        sx={{ _hover: { bg: "none" } }}
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          fontSize: "12px",
                        }}
                      >
                        Document {docIndex}
                        <Box as="span" onClick={() => handleDelete(index)}>
                          <Image src="/assets/imgs/delete.png" />
                        </Box>
                      </Button>
                    </Box>
                  );
                  // }
                })}
                <Box
                  onClick={() => addSurgeriesUploadDocument("Document")}
                  as="button"
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  mt="5px"
                  fontSize="14px"
                  fontWeight="600"
                  color="#1C4EC5"
                  textAlign="left"
                >
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>

                <input
                  type="file"
                  id="file-upload"
                  onChange={(e) => handleSurgeriesUpload(e)}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
              </Box>
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Save Document"
                  onClick={AddSurgeriesCall}
                  isDisabled={actionAccessPatients?.create ? false : true}
                />
              </Box>
            </>
          }
        />
        {/* DynamicModal for deletion */}
        <DynamicModal
          ref={documentDeleteModal}
          modalHeader="Delete report?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{typeToDelete}"?
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteDocumentWithId}
              />
            </>
          }
        />
      </Box>
    );
  }
);

export default UploadDoctorDocuments;
