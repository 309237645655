import  FetchData, { } from '../../client';
import {getBranchId, getLoginID}  from '../../../auth';
import formapimodalbilling from '../../domains/billing/billings';
import formapiorderdetailsdata from '../../domains/orderdetails/index';

//reac the file

const FetchBillingList = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdoctorbilling", inputData);
    //console.log(result,"Home db response")
    if (result.output.data) {
    var finalOutput = formapimodalbilling(result.output.data)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchOrderDetails = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctorbillingdetail", inputData);
      if (result.output) {
      var finalOutput = formapiorderdetailsdata(result.output)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchMedicineList = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getmedicine", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const SaveGenerateInvoice = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.doctorid = getLoginID();
      inputData.loginid = getLoginID();
      const result = await FetchData("midoc_saveprescriptioninvoice", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const GetMasterData = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getdefaultdata", inputData);
     
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
  const FetchDoctorsList = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getdoctoruserlist", inputData);
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
  const FetchInvoice = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getinvoicenumber", inputData);
     
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
  const GetLabList = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getlabtest", inputData);
     
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
  const AddLabFee = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_addlabtest", inputData);
     
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
  const UpdateLabFee = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_updatelabtest", inputData);
     
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
  const DeleteLabFee = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_deletelabtest", inputData);
     
      return result;
    } catch (error) {
      console.error("Error fetching data in forms.js:", error);
      throw error;
    }
  };
export {FetchBillingList, FetchOrderDetails, FetchMedicineList, SaveGenerateInvoice, GetMasterData, FetchDoctorsList, FetchInvoice,GetLabList, AddLabFee,UpdateLabFee, DeleteLabFee};
