import {Flex,  Box, Card, CardBody, Checkbox, Divider, Image, Radio, RadioGroup, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from "react";
import { AddSocialHistory, DeleteSocialHistory, FetchSocialHistoryDetails, GetSocialHistoryTypes, UpdateSocialHistory } from "../../middleware/services/messages";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation, useNavigate } from 'react-router-dom';
import MobileSecondaryNav from '../../components/mobile-secondary-navbar';
import { DropdownInput, PrimaryButton, PrimaryInput, SearchBar, Toaster } from '../../components';
import { format } from 'date-fns';
import ModalRight from '../../components/modal-right';
import DynamicModal from '../../components/dynamic-modal';
import { actionAccessMain, getLoginID } from '../../auth';

const SocialHistory = ({id,backFunction }) => {

  const location = useLocation();
    const [patientid, setPatientid] = useState("");
  const [socialData, setSocialData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  
  const [actionAccessPatients , setActionAccessPatients] = useState(null);

  useEffect(() => {
    
    setActionAccessPatients(actionAccessMain(4))
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const id = searchParams.get('id');
    setPatientid(id);
    fetchsocialdata(id)   
  }, []);
  
  const fetchsocialdata = async (id) => {
    showLoader()
        try {
          const result = await FetchSocialHistoryDetails({
            "loginid": id,
          });
          // console.log(result, "Social")

          if (result != null) {
            setSocialData(result);
          } else {
            setSocialData(null)
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "social IDDDDD")
      // console.log(socialData, "social Dataaaaaaa")

      const calculateYearsDifference = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
      
        // Calculating differece in ms
        const diffInMs = endDate - startDate;

        // Milliseconds to years
        const msInYear = 1000 * 60 * 60 * 24 * 365.25;
        const diffInYears = diffInMs / msInYear;
      
        return diffInYears.toFixed(0);
      };

      const formatDate_yyyy = (dateString) => {
        if (!dateString) return '-';
        return moment(dateString).format("yyyy");
      };



      let patientidFromLocal = localStorage.getItem("patientid")
      var [data, setData] = useState(null);
  var [typedata, setTypeData] = useState([]);
  var [socialtypedata, setSocialTypeData] = useState([]);
  
  var [id, setID] = useState();
  var [rowId , setRowId] = useState("")
  var [title, setTitle] = useState();
  const addsocialhistory = React.useRef();
  const editsocialhistory = React.useRef();
  const dynamicModal = React.useRef();
  const [type, setType] = useState('');
  const [duration, setDuration] = useState('');
  const [durationtimespan, setDurationTime] = useState('');
  const [frequency, setFrequency] = useState('');
  const [frequencytimespan, setFrequencyTime] = useState('');
  const [frequency_cd, setFrequencyTimeID] = useState('327');
  const [duration_cd, setDurationTimeID] = useState('');
  const [type_cd, setTypeID] = useState('');
  const [current, setCurrent] = useState(0);
  const [smokingSelected, setSmokingSelected] = useState(false);
  const [smokeLessSelected, setSmokeLessSelected] = useState(false);
  const [hideFrequency, setHideFrequency] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [yearsDiff, setYearsDiff] = useState('');
  const [showQuitCheckBox, setShowQuitCheckBox] = useState(false);
  const [showIsCurentCheckBox, setShowIsCurentCheckBox] = useState(false);
  const [text, setText] = useState("");
  const [sendSmoklessInInput, setSendSmoklessInInput] = useState(false);
  const [sendDrugTypeInInput, setSendDrugTypeInInput] = useState(false);
  const [smokelessType, setSmokelessType] = useState(null);
  const [drugType, setDrugType] = useState(null);
  const [drugsSelected, setDrugsSelected] = useState(false);
  const [activitiesSelected, setActivitiesSelected] = useState(false);
  const [showFrequencyCalculation, setShowFrequencyCalculation] = useState(false);

  const addCondition3 = React.useRef();
  const addCondition4 = React.useRef();
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState("");

  const [reasonsArray, setReasonsArray] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] = useState(true)
  const [activitiesContinueBtnDisabled, setActivitiesContinueBtnDisabled] = useState(true)
  const [allergyID, setAllergieID] = useState("");
  const [selectedReactions, setSelectedReactions] = useState([]);
  const floatLabel = { color: "#384052", fontSize: "0.775rem", position: "relative", top: "-4px", left: "-4px", opacity: "0.5", width: "100%" };

  var [fromYearOptions, setFromYearOptions] = useState([]);
  var [toYearOptions, setToYearOptions] = useState([]);
  var [showFrequencyTag, setShowFrequencyTag] = useState(true);
  const [radioValue, setRadioValue] = useState("");

  const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
  const [todate, setToDate] = useState("");
  const [showFrequencyInEditModal, setShowFrequencyInEditModal] = useState(true);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");

  const [fromdateYear, setFromDateYear] = useState("")
  const [toDateYear, setToDateYear] = useState("")

  const [fromYearForValidation, setFromYearForValidation] = useState("")
  const [toYearForValidation, setToYearForValidation] = useState("")

  const { showSuccessToast, showErrorToast } = Toaster();
  const [activeTab, setActiveTab] = useState(0);

  // useEffect(() => {
  //   fetchsocialdata(id) 
  // }, []);

  const OpenAddSocialHistory = async () => {
    clearState();
    const result = await GetSocialHistoryTypes({
      type: "socialhistorytype",
    });
    if (result) {
      // Iterate through the options and create an array of formatted strings
      const formattedOptions = result.output.data[0].data.map(option => (
        [option.condition]
      ));

      setTypeData(formattedOptions);
    }
    const currentYear = new Date().getFullYear();
    const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    const fromOptions = fromYears.map((year) => String(year));
    setFromYearOptions(fromOptions);

    const toYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    const toOptions = toYears.map((year) => String(year));
    setToYearOptions(toOptions);

    setSocialTypeData(result.output.data[0].data)
    setDuration("")
    setFrequency("")
    addsocialhistory.current.openRight()
  }
  useEffect(() => {
    setFromYearForValidation(fromdateYear)
    setToYearForValidation(toDateYear)

  }, [fromdateYear, toDateYear])
  useEffect(() => {
    if (fromYearForValidation) {
      const currentYear = new Date().getFullYear();
      const fromYear = parseInt(fromYearForValidation); // Convert to number
      const toYears = Array.from({ length: currentYear - fromYear + 1 }, (_, index) => fromYear + index);
      const toOptions = toYears.map((year) => String(year));
      setToYearOptions(toOptions);
    }
  }, [fromYearForValidation]);
  useEffect(() => {
    if (toYearForValidation) {
      const toYears = Array.from({ length: 101 }, (_, index) => toYearForValidation - index);
      const toOptions = toYears.map((year) => String(year));
      setFromYearOptions(toOptions);
    }
  }, [toYearForValidation, id])

  const clearState = () => {
    setTypeID('')
    setHideFrequency(false)
    setSmokeLessSelected(false)
    setSmokingSelected(false)

    setNeverSelected(false)
    setShowDateSelection(false)
    setShowQuitCheckBox(false)
    setShowIsCurentCheckBox(false)
    setShowQuitCheckBox(false)
    setYearsDiff("")
    setReasonsArray([])
    setActivityArray([])
    setDrugsSelected(false)
    setActivitiesSelected(false)
    setToDateYear("")
    setToDate("")
    setFromDateYear("")
    setFromDate("")
    setShowYearsDiffTag(false)
    setSmokelessType(null)
    setDrugType(null)
    setCalculatedConsumption("")
    setFrequency('')
  }

  var [reactionList, setReactionList] = useState(null);
  var [activityList, setActivityList] = useState(null);
  const getDrugList = async () => {
    const result = await GetSocialHistoryTypes({
      type: "drug",
    });
    setReactionList(result.output.data[0].data)

  }
  const getExerciseList = async () => {
    const result = await GetSocialHistoryTypes({
      type: "physicalactivity_cd",
    });
    setActivityList(result.output.data[0].data)

  }
  const handleTypeInputChange = (selectedOption) => {
    var foundObject = socialtypedata && socialtypedata.find(x => x.condition == selectedOption);

    clearState();
    setType(foundObject.condition)
    if (foundObject) {

      setRadioValue("")
      setShowQuitCheckBox(true)
      if (foundObject.condition == "Smoking") {
        setText("Packs")
        setSmokingSelected(true)
        setShowFrequencyCalculation(true)

        setSmokeLessSelected(false)
        setDrugsSelected(false)
      } else if (foundObject.condition == "Alcohol") {
        setText("Drinks")
        setSmokingSelected(true)
        setShowFrequencyCalculation(false)

        setDrugsSelected(false)

        setSmokeLessSelected(false)
      } else if (foundObject.condition == "Smokeless") {
        setText("Cans")
        setSmokingSelected(true)
        // setHideFrequency(true)
        setSmokeLessSelected(true)
        setSendSmoklessInInput(true)
        setShowFrequencyCalculation(false)

        setDrugsSelected(false)
      }
      else if (foundObject.condition == "Illicit Drugs") {
        //setText("drinks")

        setText("Times")
        setSmokingSelected(true)
        setDrugsSelected(true)
        setSmokeLessSelected(false)
        setShowFrequencyCalculation(false)
        setSendDrugTypeInInput(true)
        // getDrugList();
      }
      else if (foundObject.condition == "Physical Activity") {
        //setText("drinks")

        setText("Days")
        setSmokingSelected(true)
        // setDrugsSelected(true)
        setActivitiesSelected(true)
        setSmokeLessSelected(false)
        setShowFrequencyCalculation(false)
        setSendDrugTypeInInput(true)
        // getDrugList();
      }
      else {
        setSmokingSelected(false)
      }
      setTypeID(foundObject.condition_cd)
    }
  }
  const handledurationInputChange = (selectedOption) => {
    let value;
    switch (selectedOption) {
      case 'Hour':
        value = 325;
        break;
      case 'Day':
        value = 326;
        break;
      case 'Week':
        value = 327;
        break;
      case 'Month':
        value = 328;
        break;
      case 'Year':
        value = 329;
        break;
      default:
        value = 0; // Default value if no match is found
    }
    setDurationTimeID(value);
  }

  const handlefrequencyInputChange = (selectedOption) => {
    let value;
    switch (selectedOption) {
      case 'Hour':
        value = 325;
        break;
      case 'Day':
        value = 326;
        break;
      case 'Week':
        value = 327;
        break;
      case 'Month':
        value = 328;
        break;
      case 'Year':
        value = 329;
        break;
      default:
        value = 0; // Default value if no match is found
    }
    setFrequencyTimeID(value)
  }

  const handleCheckboxChange = (event) => {
    if (event.target.checked == true) {
      setCurrent(1);

    } else {
      setCurrent(0);

    }
  };
  const handleCheckboxChangeQuit = (event) => {
    if (event.target.checked == true) {
      setCurrent(0);

    }
  };

  const handleaddsocialhistory = async () => {
    //setCurrent(current === false ? 0 : 1);
    if (type_cd == "") {
      showErrorToast("Please select any type")
      return false;
    } else if (radioValue == "") {
      showErrorToast("Please select a value")
      return false;
    }
    //  else if(type_cd == 533){
    //   if(smokelessType == null){
    //   showErrorToast("Please select smokeless type")
    //   }

    // }
    else if (radioValue != "never" && type_cd == "322" && drugType == null) {
      showErrorToast("Please select drug type")
      return false;
    } else if (radioValue != "never" && type_cd == "322" && reasonsArray.length == 0) {
      showErrorToast("Please select a drug")
      return false;
    }
    else if (radioValue != "never" && type_cd == "533" && smokelessType == null) {
      showErrorToast("Please select smokeless type")
      return false;
    }
    else if (radioValue != "never" && fromdateYear == "") {
      showErrorToast("Please select start year")
      return false;
    } else if (radioValue != "never" && toDateYear == "" && todate == "") {
      showErrorToast("Please select end year")
      return false;
    }
    else if (radioValue != "never" && type_cd != 533 && frequency == "") {
      showErrorToast("Please enter frequency")
      return false;
    } else if (radioValue != "never" && frequency == "") {
      showErrorToast("Please enter frequency")
      return false;
    }

    //  else if (duration == "") {
    //   showErrorToast("Please enter the duration")
    //   return false;
    // }
    else {
      showLoader();
      const formData = {
        "loginid":patientidFromLocal,
        "doctorid":getLoginID(),
        socialhistory: [
          {
            "socialhistorytype_cd": type_cd,
            //"duration": duration,
            "frequency": frequency,
            "iscurrent": current,
            "frequencytype_cd": frequency_cd,
            //"durationtype_cd": duration_cd,
            "fromdate": fromdate ? fromdate : null,
            "todate": todate ? todate : null,
            ...(sendSmoklessInInput && { "smokelesstype": smokelessType }),
            ...(sendDrugTypeInInput && { "drugtype": drugType }),
            ...(reasonsArray.length > 0 && {
              drugnames: reasonsArray.map((reason) => ({
                name: reason.condition
              }))
            }),
            ...(activityArray.length > 0 && {
              exercisesdaily: activityArray.map((activity) => ({
                name: activity.condition
              }))
            })
          },
        ],
      };
      // if(sendSmoklessInInput){
      //   if (formData.socialhistory.length > 0) {
      //     alert("hit")
      //     formData.socialhistory[0].push("smokelessType");
      //   }
      // }
      //  console.log(formData, " input for add")
      const result = await AddSocialHistory(formData);


      if (result) {
        showSuccessToast("Social history added successfully");
        // fetchData(activeTab === 1 ? "0" : "1");
        fetchsocialdata(patientid) 
        //setActiveTab(0);
        //window.location.reload();
        addsocialhistory.current.closeRight();
      }
    }
    dismissLoader();
  }
  const Editsocialhistory = async () => {

    if (type == "") {
      showErrorToast("Please select any type")
      return false;
    } else if (duration == "") {
      showErrorToast("Please enter the duration")
      return false;
    } else if (radioValue != "never" && type_cd == "322" && reasonsArray.length == 0) {
      showErrorToast("Please select a drug")
      return false;
    } else if (radioValue != "never" && fromdateYear == "") {
      showErrorToast("Please select start year")
      return false;
    } else if (radioValue != "never" && toDateYear == "" && todate == "") {
      showErrorToast("Please select end year")
      return false;
    } else if (radioValue != "never" && frequency == "") {
      showErrorToast("Please enter frequency")
      return false;
    }
    else {
      const formData = {
        "loginid":patientidFromLocal,
        "doctorid":getLoginID(),
        "id": rowId,
        "socialhistorytype_cd": type_cd,
        // "duration": duration,
        "frequency": frequency,
        "iscurrent": current,
        "frequencytype_cd": frequency_cd,
        //"durationtype_cd": duration_cd,
        "fromdate": fromdate ? fromdate : null,
        "todate": todate ? todate : null,
        ...(sendSmoklessInInput && { "smokelesstype": smokelessType }),
        ...(sendDrugTypeInInput && { "drugtype": drugType }),
        ...(reasonsArray.length > 0 && {
          drugnames: reasonsArray.map((reason) => ({
            name: reason.condition
          }))
        }),
        ...(activityArray.length > 0 && {
          exercisesdaily: activityArray.map((activity) => ({
            name: activity.condition
          }))
        })
      };
      const result = await UpdateSocialHistory(formData);
      if (result) {
        showSuccessToast("Social history updated successfully");
        // fetchData(activeTab === 1 ? "2" : "1");
        fetchsocialdata(patientid) 
        //setActiveTab(0);
        //window.location.reload();
        editsocialhistory.current.closeRight();
      }
    }
  }

  const handleTabClick = (index) => {
    switch (index) {
      case 0:
        setActiveTab(0);
        // fetchData("1");
        
    fetchsocialdata(id) 
        break;
      case 1:
        setActiveTab(1);
        // fetchData("2");
        
    fetchsocialdata(id) 
        break;
      default:
        break;
    }
  };
  const deletesocialhistorymodel = (id, title) => {
    setRowId(id)
    setTitle(title)
    dynamicModal.current.openModal()
  }
  const deletesocialhistory = async () => {
    const result = await DeleteSocialHistory({
      
    "loginid":patientidFromLocal,
    "doctorid":getLoginID(),
      "id": rowId,
    });
    if (result) {
      showSuccessToast("Social history deleted successfully");
      // fetchData(activeTab === 1 ? "2" : "1");
      
    fetchsocialdata(patientid) 
      //setActiveTab(0);
      //window.location.reload();
      dynamicModal.current.closeModal();
    }
  }
  const handleeditsocialhistory = async (item, consumptionYears) => {
   console.log(item, " item sdf")
    //here
    setRowId(item.id);
    setType(item.socialhistory);
    setTypeID(item.socialhistorytype_cd);
    setDuration(item.duration);
    setDurationTime(item.durationtype);
    setDurationTimeID(item.durationtype_cd);
    setFrequency(item.frequency);
    if (item.iscurrent == 0) {
      setFrequency('')
    }
    setFrequencyTime(item.frequencytype);
    setFrequencyTimeID(item.frequencytype_cd);
    setCurrent(item.iscurrent);

    const currentYear = new Date().getFullYear();
    // const toYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    // const toOptions = toYears.map((year) => String(year));
    // setToYearOptions(toOptions);

    const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
    const fromOptions = fromYears.map((year) => String(year));
    setFromYearOptions(fromOptions);

    if (item.iscurrent == 1) {
      setShowIsCurentCheckBox(true)
      setShowQuitCheckBox(false)
      setNeverSelected(false)
      setRadioValue("yes")
    } else if (item.iscurrent == 2) {
      setShowIsCurentCheckBox(false)
      setShowQuitCheckBox(true)
      setNeverSelected(false)
      setRadioValue("currentlyNot")
    } else if (item.iscurrent == 0) {
      setNeverSelected(true)
      setShowQuitCheckBox(false)
      setShowIsCurentCheckBox(false)
      setRadioValue("never")
    }
    setSmokelessType(item.smokelesstype)
    setDrugType(item.drugtype)
    if (item.socialhistory == "Smoking") {
      setShowFrequencyCalculation(true)
    } else {

      setShowFrequencyCalculation(false)
    }
    if (item.smokelesstype || item.socialhistory == "Smokeless") {
      setSmokeLessSelected(true)
    } else {

      setSmokeLessSelected(false)
    }
    if (item.iscurrent == 0) {
      setSmokeLessSelected(false)
    }
    if (item.smokelesstype || item.socialhistory == "Illicit Drugs") {
      setSendDrugTypeInInput(true)
    } else {

      setSendDrugTypeInInput(false)
    }
    if (item.smokelesstype || item.socialhistory == "Physical Activity") {
      setSendDrugTypeInInput(true)
    } else {

      setSendDrugTypeInInput(false)
    }

    if (item.drugnames) {

      setReasonsArray([])
      item.drugnames.forEach((drug) => {
        // Assuming each drug has a "name" property
        const newReason = {
          condition: drug.name
        };
        setReasonsArray((prevArray) => [...prevArray, newReason]);
      });
      setDrugsSelected(true)
    } else {
      if (item.socialhistory == "Illicit Drugs" && item.drugtype) {
        setDrugsSelected(true)
      } else {
        setDrugsSelected(false)
      }
      setReasonsArray([])
    }

    if (item.exercisesdaily) {

      setActivityArray([])
      item.exercisesdaily.forEach((exercise) => {
        // Assuming each drug has a "name" property
        const newReason = {
          condition: exercise.name
        };
        setActivityArray((prevArray) => [...prevArray, newReason]);
      });
      setActivitiesSelected(true)
    } else {
      if (item.socialhistory == "Physical Activity") {
        setActivitiesSelected(true)
      } else {
        setActivitiesSelected(false)
      }
      setActivityArray([])
    }
    if (item.socialhistory == "Smoking") {
      setText("Packs")
    } else if (item.socialhistory == "Alcohol") {
      setText("Drinks")
    } else if (item.socialhistory == "Smokeless") {
      setText("Cans")
    } else if (item.socialhistory == "Illicit Drugs") {
      setText("Times")
    } else if (item.socialhistory == "Physical Activity") {
      setText("Days")
    } else {
      setText("Packs")
    }
    // if (item.socialhistory == "Smokeless") {
    //   setShowFrequencyInEditModal(false)
    // } else {
    //   setShowFrequencyInEditModal(true)
    // }

    setcalculatedYearsForEdit(consumptionYears)
    if (item.fromdate) {
      var formattedfromdate = format(item.fromdate, "yyyy")
      setFromDate(formattedfromdate);
      setFromDateYear(formattedfromdate)
    } else {
      setFromDate("");
    }
    if (item.todate) {
      var formattedToDate = format(item.todate, "yyyy")
      setToDate(formattedToDate);
      setToDateYear(formattedToDate)
    }

    if (item.fromdate && item.todate) {
      const diffYears = moment(item.todate).diff(moment(item.fromdate), 'years');


      if (diffYears > 0) {
        setYearsDiff(diffYears)
      } else {
        setYearsDiff("")
      }
    }

    const result = await GetSocialHistoryTypes({
      type: "socialhistorytype",
    });
    if (result) {
      // Iterate through the options and create an array of formatted strings
      const formattedOptions = result.output.data[0].data.map(option => (
        [option.condition]
      ));

      setTypeData(formattedOptions);
    }
    setSocialTypeData(result.output.data[0].data);

    editsocialhistory.current.openRight();
  }
  const handleDuration = (e) => {
    const inputText = e.target.value;
    const maxLength = 3;

    if (inputText.length <= maxLength) {
      setDuration(inputText);
    }
  }
  const handleFrequency = (e) => {
    let inputText = e.target.value;
    const maxLength = 3;
    const regex = /^\d+$/;

    inputText = inputText.replace(/\./g, '');
    if (inputText === '' || regex.test(inputText)) {
      if (inputText.length <= maxLength) {
        if (inputText.startsWith(0)) {
          inputText = inputText.substring(1);
        }
        setFrequency(inputText);
      }
    }
  }
  const [calculatedConsumption, setCalculatedConsumption] = useState("")
  const [calculatedYearsForEdit, setcalculatedYearsForEdit] = useState("")
  useEffect(() => {
    //var consumptionPreYear = (frequency / 7) * 365;
    //
    if (yearsDiff) {
      var consumptionPreYear = (frequency / 7) * yearsDiff;
      //var consumptionByYears = consumptionPreYear ;
      const finalCalculation = consumptionPreYear.toFixed(2);

      if (finalCalculation > 0) {

        setCalculatedConsumption(`${text} per year : ` + finalCalculation)
      } else {
        setCalculatedConsumption("")
      }

    }
  }, [frequency])

  // const handleYesSelected = () =>{
  //   setShowDateSelection(!showDateSelection)
  // }
  const [neverSelected, setNeverSelected] = useState(false);
  const handleYesSelected = (value) => {
  
    if (value == "yes") {
      setRadioValue("yes")
      setShowQuitCheckBox(false)
      setShowDateSelection(true)
      setShowIsCurentCheckBox(true)

      const currentYear = new Date().getFullYear();

      var janOne = "-01-01"
      var fullDate = currentYear + janOne
      setToDate(fullDate)
      setCurrent(1)
      setNeverSelected(false)
      setFrequencyTimeID("327")

      if (type == "Smokeless") {
        setSmokeLessSelected(true)
      }
      if (type == "Illicit Drugs" || type == "Physical Activity") {
        if(type == "Illicit Drugs"){
          setDrugsSelected(true)
        }else {
          setActivitiesSelected(true)
        }
      }else {
        setDrugsSelected(false)
        setActivitiesSelected(false)

      }
    } else if (value == "currentlyNot") {

      setRadioValue("currentlyNot")
      setShowQuitCheckBox(true)
      setShowDateSelection(true)
      // setFromDate("")
      // setFromDateYear("")
      const currentYear = new Date().getFullYear();
      var janOne = "-01-01"
      var fullDate = currentYear + janOne
      // setToDate(fullDate)
      setToDate("")

      setNeverSelected(false)
      setShowIsCurentCheckBox(false)
      setCurrent(2)

      setFrequencyTimeID("327")
      if (type == "Smokeless") {
        setSmokeLessSelected(true)
      }
      if (type == "Illicit Drugs" || type == "Physical Activity") {
        if(type == "Illicit Drugs"){
          setDrugsSelected(true)
        }else {
          setActivitiesSelected(true)
        }
      }else{
        setDrugsSelected(false)
        setActivitiesSelected(false)

      }
    } else if (value == "never") {
      setNeverSelected(true)
      setRadioValue("never")
      setShowDateSelection(false)
      setShowQuitCheckBox(false)
      setCurrent(0)
      setShowIsCurentCheckBox(false)
      setYearsDiff('')
      setToDate("")

      setFrequency("")
      setFrequencyTimeID("")
      setFromDateYear("")
      setFromDate("")

      setCalculatedConsumption("")
      setSmokeLessSelected(false)

      setDrugsSelected(false)
      setActivitiesSelected(false)
      setDrugType(null)
      setSendDrugTypeInInput(false)
      if (type == "Illicit Drugs" || type == "Physical Activity") {
        if(type =="Illicit Drugs"){
          setDrugsSelected(false)
        }else{
          setActivitiesSelected(false)
        }
        // setSendDrugTypeInInput(false)
        setDrugType(null)
        setReasonsArray([])
      setActivityArray([])
      }else{
        setDrugsSelected(false)
        setActivitiesSelected(false)
        
      }
    }
  };
  const handleSmokelessType = (value) => {

    if (value == "Chew") {
      setSmokelessType("chew")
    } else if (value == "Snuff") {
      setSmokelessType("snuff")
    }
  }
  const handleDrugTypeType = (value) => {

    if (value == "Smoke") {
      setDrugType("smoke")
    } else if (value == "Snort") {
      setDrugType("snort")
    } else if (value == "ivDrug") {
      setDrugType("ivDrug")
    }
  }
  const handleFromDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setFromDate(formattedDate);
  };
  const handleFromYearChange = (selecteddate) => {
    setFromDateYear(selecteddate)

    var janOne = "-01-01"
    var yearWithAddedDayAndMonth = selecteddate + janOne;
    setFromDate(yearWithAddedDayAndMonth);
  };

  const handleToDateChange = (selecteddate) => {
    const formattedDate = moment(selecteddate).format('YYYY-MM-DD');
    setToDate(formattedDate);
  };
  const handleToYearChange = (selecteddate) => {
    setToDateYear(selecteddate)

    var janOne = "-01-01"
    var yearWithAddedDayAndMonth = selecteddate + janOne;
    setToDate(yearWithAddedDayAndMonth);
  };
  const [showYearsDiffTag, setShowYearsDiffTag] = useState(false)
  useEffect(() => {
    if (fromdate && todate) {

      const diffYears = moment(todate).diff(moment(fromdate), 'years');


      if (diffYears > 0) {
        setcalculatedYearsForEdit("Consumption Years : " + diffYears)
        setYearsDiff(diffYears)
        // not showing the "Consuming Years:3years" tag in Add so commenting below line :
        // setShowYearsDiffTag(true)
      } else {

        setYearsDiff("")
        setShowYearsDiffTag(false)
      }
    }
  }, [todate, fromdate])
  const closeDrugsModal = () => {
    addCondition3.current.closeRight();
  };
  const closeActivitiesModal = () => {
    addCondition4.current.closeRight();
  };
  const openDrugListModal = () => {
    getDrugList();
    //here 
    setSearchValue("")
    addCondition3.current.openRight()
  };
  const openActivityListModal = () => {
    getExerciseList();
    //here 
    setSearchValue("")
    addCondition4.current.openRight()
  };

  useEffect(() => {
    if (reasonsArray.length === 0) {
      setReactionsContinueBtnDisabled(true);
    } else {
      setReactionsContinueBtnDisabled(false);

    }
  }, [reasonsArray]);

  useEffect(() => {
    if (activityArray.length === 0) {
      setActivitiesContinueBtnDisabled(true);
    } else {
      setActivitiesContinueBtnDisabled(false);

    }
  }, [activityArray]);

  const handleReactionCheckboxChange = (condition) => {
    const isSelected = reasonsArray.some(
      (item) =>
        item.condition === condition.condition
    );

    if (isSelected) {
      // If already selected, remove the item from reasonsArray
      setReasonsArray((prevConditions) =>
        prevConditions.filter(
          (item) =>
            item.condition !== condition.condition
        )
      );
    } else {
      // If not selected, add the item to reasonsArray
      setReasonsArray([...reasonsArray, { ...condition }]);
    }
  };
  const handleActivityCheckboxChange = (condition) => {
    const isSelected = activityArray.some(
      (item) =>
        item.condition === condition.condition
    );

    if (isSelected) {
      // If already selected, remove the item from reasonsArray
      setActivityArray((prevConditions) =>
        prevConditions.filter(
          (item) =>
            item.condition !== condition.condition
        )
      );
    } else {
      // If not selected, add the item to reasonsArray
      setActivityArray([...activityArray, { ...condition }]);
    }
  };
  const removeTag = (index) => {
    const updatedReasonsArray = [...reasonsArray];
    updatedReasonsArray.splice(index, 1);
    setReasonsArray(updatedReasonsArray);
  };
  const removeTagActivities = (index) => {
    const updatedActivityArray = [...activityArray];
    updatedActivityArray.splice(index, 1);
    setActivityArray(updatedActivityArray);
  };

  const css = `
  .socialBtn {
    right: 0;
  }
  `

  return (
    <>
    {isLoading && <Loader />}
    <Box w="100%" display={{base: "none", lg: "block"}}>
       <Box pos='sticky' p='1rem 1.5rem' alignItems='center' top='0' zIndex='999' bg='white' borderBottom='1px solid #E6E7E9' display="flex" justifyContent="space-between" mb="10px">
       <Text fontWeight='600'>Social History</Text>
       <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Add Social history"
            btnIcon="/assets/imgs/add.png"
            onClick={OpenAddSocialHistory}
            btnStyle={{w:"160px"}}
            
            isDisabled={actionAccessPatients?.create ? false : true}
          />
              
                  </Box>
      {socialData && socialData.length>0 ? (
          <SimpleGrid columns={{lg:2,"2xl":2}} minChildWidth='400px' spacing="1rem" w="100%" maxH='calc(100vh - 160px)' overflow='hidden auto'  padding='1rem 1.5rem'>
              {socialData.map((social,index) => {
                const totalYears = calculateYearsDifference(social.fromdate, social.todate);
                var consumptionPreYear = (social.frequency / 7) * totalYears;
                const finalCalculation = consumptionPreYear.toFixed(2);
                return(
              <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none" key={index} >
                  <CardBody display='flex' justifyContent='space-between' flexDir='column'>
                      <Flex w='100%' justifyContent='space-between' alignItems='center'>
                      <Text fontSize="1rem" fontWeight="600" color="#061027">
                          {/* Smoking */}
                          {social.socialhistory}
                      </Text>
                      
                        <Box display='flex'  gap='0.5rem' justifyContent='flex-end'>
                          <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                onClick={() => handleeditsocialhistory(social, social.consumptionYears)}
                                
                              disabled={actionAccessPatients?.update ? false : true}
                              opacity={actionAccessPatients?.update ? 1 : 0.5}
                              >
                                <Image w="13px" src="/assets/imgs/edit-gray.png" />
                              </Box>
                              <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                onClick={() => deletesocialhistorymodel(social.id, social.socialhistory)}
                                disabled={actionAccessPatients?.delete ? false : true}
                                opacity={actionAccessPatients?.delete ? 1 : 0.5}
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                        </Box>
                      </Flex> 
                      <Box display='flex'>
                      {/* <Text fontSize='0.75rem' fontWeight='bold' color="#384052">Status: </Text>
                      <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                          {social.iscurrent == 1 ? " Current" : 
                          social.iscurrent == 0 ? " Never" : 
                          social.iscurrent == 2 ? " Former" :"" }
                      </Text> */}
                      <Text fontWeight="bold" fontSize='0.75rem'>
                            {"Status: "}
                            <span style={{ fontWeight: "500" }}>
                              {social.iscurrent == 1 ? "Current" :
                                social.iscurrent == 0 ? "Never" :
                                  social.iscurrent == 2 ? "Former" : ""}</span>
                          </Text>
                      </Box>
                      <Divider my="0.75rem" />
                      <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                        {social.drugtype ? capitalizeFirstLetter(social.drugtype) : ""}
                      </Text>
                      <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                          {/* 5x per day */}
                          {/* {social.frequency}  {social.frequencytype} */}
                          {social.frequency && social.frequency !== 0 ? `${social.frequency} 
                              ${social.socialhistory && social.socialhistory == "Smoking" ? "Packs": 
                                                      social.socialhistory == "Alcohol" ? "Drinks" :
                                                      social.socialhistory == "Smokeless" ? "Cans" :
                                                      social.socialhistory == "Illicit Drugs" ? "Times" 
                                                      :
                                                      social.socialhistory == "Physical Activity"?
                                                      "Days"
                                                      : "Packs"} 
                              Per ${social.frequencytype}   
                              x ${totalYears == 1 ? totalYears + " year"  :
                                  totalYears > 1 ? totalYears + " years" : 
                                                                  "less than a year"}  
                            ` 
                              : '-'}
                              {social.iscurrent == 2 && (
              <>
                {` Until ${social.todate ? formatDate_yyyy(social.todate) : ""}`}
              </>
            )}
                      </Text>
                      <Box mt='20px' w='100%' h='65px' p='0.5rem 0.875rem' border='1px solid #E6E7E9' borderRadius='10px'>

                  {social.socialhistory == "Illicit Drugs" ?
                    <Box sx={{ display: "-webkit-box",whiteSpace: "normal", maxHeight: "40px", overflow: 'hidden', WebkitLineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis', wordBreak:'break-word' }}>
                      {social.drugnames && social.drugnames.map((item, index) => (
                        <Box as="span" fontSize='0.875rem' key={index} title={social.drugnames.map(item => item.name).join(", ")}>
                          {item.name}
                          {index < social.drugnames.length - 1 && ", "}
                        </Box>
                      ))}
                    </Box>
                    :
                    <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>

                    </div>
                  }

{social.socialhistory == "Physical Activity" ?
                    <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>
                      {social.exercisesdaily && social.exercisesdaily.map((item, index) => (
                        <span key={index} title={social.exercisesdaily.map(item => item.name).join(", ")}>
                          {item.name}
                          {index < social.exercisesdaily.length - 1 && ", "}
                        </span>
                      ))}
                    </div>
                    :
                    <div style={{ display: "inline-block", maxHeight: "40px", overflow: 'hidden' }}>

                    </div>
                  }
                      </Box>

                  </CardBody>
              </Card>
                )
              })}
          </SimpleGrid>

      ) : (
        <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              gap="10px"
                              alignItems="center"
                              h="calc(100vh - 100px)"
                              w="100%"
                            >
                              <Box textAlign="left" fontSize="16px" fontWeight="600">
                                <Image w="26px" opacity="0.5" src="/assets/imgs/social-history-bar.png"/>
                              </Box>
                              <Text textAlign="left" fontSize="12px" fontWeight="500">
                                No Data available
                              </Text>
                            </Box>
      )}
    </Box>


    <ModalRight
          ref={addsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Add Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide" dropDownPlace="Type*"
                  options={typedata}
                  onClick={handleTypeInputChange} />
                {smokingSelected &&
                  <RadioGroup onChange={handleYesSelected} value={radioValue}>
                    <Box display="flex" justifyContent="space-between" py="20px" >
                      <Radio value="yes" colorScheme="green" >
                        Current
                      </Radio>

                      <Radio value="currentlyNot" colorScheme="green">
                        Former
                      </Radio>

                      <Radio value="never" colorScheme="green">
                        Never
                      </Radio>
                    </Box>
                  </RadioGroup>
                }

                {
                  showDateSelection &&
                  <>
                    {
                      smokeLessSelected &&
                      <>
                        <Divider />
                        <RadioGroup
                          onChange={handleSmokelessType}
                        >
                          <Box display="flex" justifyContent="start" gap="15px" py="20px">
                            <Radio value="Chew" colorScheme="green">
                              Chew
                            </Radio>

                            <Radio value="Snuff" colorScheme="green">
                              Snuff
                            </Radio>

                          </Box>
                        </RadioGroup>
                      </>
                    }
                    {
                      drugsSelected &&
                      <>
                        <Divider />
                        <RadioGroup
                          onChange={handleDrugTypeType}
                        >
                          <Box display="flex" justifyContent="space-around" py="20px">
                            <Radio value="Smoke" colorScheme="green">
                              Smoke
                            </Radio>

                            <Radio value="Snort" colorScheme="green">
                              Snort
                            </Radio>
                            <Radio value="ivDrug" colorScheme="green">
                              IV Drug use
                            </Radio>

                          </Box>
                        </RadioGroup>
                      </>
                    }
                    {
                      drugsSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}
                        >
                          {reasonsArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Drugs<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {
                      activitiesSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openActivityListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={activityArray.length === 0 ? "space-between" : "start"}
                        >
                          {activityArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          ) : activityArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...activityArray.length === 0 ? {} : floatLabel }} >Type<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          )}
                          {activityArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {activityArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTagActivities(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {/* <Box display="flex" flexDir="row">
                      <DatePicker
                        disableFutureDates={true}
                        // defaultDate={fromdate} 
                        onDateChange={(selectedDateValue) =>
                          handleFromDateChange(selectedDateValue)
                        }
                        placeholderProp={"Start Date"}
                        //calendarStlye={{transform: "scale(0.9)", left:"-15px", top: "30px"}}
                        calendarStlye={{ left: "-18px", right: "unset" }}
                      />
                      <DatePicker
                        disableFutureDates={true}
                        //defaultDate={todate} 
                        onDateChange={(selectedDateValue) =>
                          handleToDateChange(selectedDateValue)
                        }
                        placeholderProp={"End Date"}
                        calendarStlye={{ transform: "scale(0.9)", right: "-22px", top: "30px" }}
                      />
                    </Box> */}
                    <Box display="flex" flexDir="row">
                      <DropdownInput
                        variant="defaultSide" dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        } />
                      {!showIsCurentCheckBox &&
                        <DropdownInput
                          variant="defaultSide" dropDownPlace="End year*"
                          options={toYearOptions}
                          onClick={(selectedDateValue) =>
                            handleToYearChange(selectedDateValue)
                          } />
                      }

                    </Box>

                    {showYearsDiffTag &&
                      <Box my="2px" >
                        <p>
                          Consuming Years : {yearsDiff}</p>

                      </Box>
                    }

                    {!hideFrequency &&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" inputPlace={`${text} per week*`}
                          onChange={handleFrequency} inputValue={frequency}
                        />
                        {/* <DropdownInput
                          variant="rightDown" dropDownPlace="Time Span" options={["Week"]}
                          defaultOption={"Week"}
                          onClick={handlefrequencyInputChange}
                        /> */}
                      </Box>
                    }
                    {showFrequencyCalculation &&
                      <Box my="2px" >

                        <p>{calculatedConsumption} </p>
                      </Box>
                    }

                    {showQuitCheckBox &&

                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 2}
                          isDisabled={true}
                        >
                          I Quit
                        </Checkbox>

                      </Box>
                    }

                    {showIsCurentCheckBox &&
                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 1}
                          isDisabled={true}
                        >
                          Current
                        </Checkbox>
                      </Box>

                    }

                  </>
                }
                {/* <Box display="flex" flexDir="row">
                    <PrimaryInput variant="bothSide" inputStyleCss={{w: "60%"}} inputType="number" inputPlace="Duration*"
                    onChange={handleDuration} inputValue={duration}/>
              <DropdownInput 
                    variant="bothSide" dropDownPlace="Time Span" inputStyleCss={{w: "40%"}} options={["Hour", "Day","Week","Month", "Year"]}
                    onClick={handledurationInputChange}/>
                    </Box>
                    <Box display="flex" flexDir="row">
                    <PrimaryInput variant="leftDown" inputType="number" inputStyleCss={{w: "60%"}} inputPlace="Frequency"
                    onChange={handleFrequency} inputValue={frequency}/>             
              <DropdownInput 
                    variant="rightDown" dropDownPlace="Time Span" inputStyleCss={{w: "40%"}} options={["Hour", "Day","Week","Month", "Year"]}
                    onClick={handlefrequencyInputChange}/>
                     </Box> */}
                {/* <Box display="flex" justifyContent="start" py="20px">
                  <Checkbox
                    colorScheme="green"
                    sx={{
                      ".chakra-checkbox__control": {
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                      },
                    }}
                    onChange={handleCheckboxChange}>
                    Current
                  </Checkbox>
                </Box> */}
                <Divider />
                {/* <Box as="button" display="flex" alignItems="center" gap="10px" py="20px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                    <Image w="11px" src="/assets/imgs/add-blue.png"/> Add social history</Box> */}
              </Box>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Add Social History"
                onClick={handleaddsocialhistory}
                
              isDisabled={actionAccessPatients?.create ? false : true}
              />
            </Box>
          </>}
        />
        <ModalRight
          ref={editsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Edit Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide" dropDownPlace="Type*"
                  options={typedata}
                  defaultOption={type}
                  onClick={handleTypeInputChange}

                  nonClickable={true} />
                {/* {smokingSelected && */}
                <RadioGroup onChange={handleYesSelected} value={current == "1" ? "yes" : current == "0" ? "never" : "currentlyNot"}>
                  <Box display="flex" justifyContent="space-between" py="20px" >
                    <Radio value="yes" colorScheme="green" >
                      Current
                    </Radio>

                    <Radio value="currentlyNot" colorScheme="green">
                      Former
                    </Radio>

                    <Radio value="never" colorScheme="green">
                      Never
                    </Radio>
                  </Box>
                </RadioGroup>
                {
                  smokeLessSelected &&
                  <>
                    <Divider />
                    <RadioGroup
                      onChange={handleSmokelessType}
                      value={smokelessType == "snuff" ? "Snuff" : smokelessType == "chew" ? "Chew" : ""}
                    >
                      <Box display="flex" justifyContent="start" gap="15px" py="20px">
                        <Radio value="Chew" colorScheme="green">
                          Chew
                        </Radio>

                        <Radio value="Snuff" colorScheme="green">
                          Snuff
                        </Radio>

                      </Box>
                    </RadioGroup>
                  </>
                }
                {
                  drugsSelected &&
                  <>
                    <Divider />
                    <RadioGroup
                      onChange={handleDrugTypeType}
                      value={drugType == "smoke" ? "Smoke" : drugType == "snort" ? "Snort" : drugType == "ivDrug" ? "ivDrug" : ""}

                    >
                      <Box display="flex" justifyContent="space-around" py="20px">
                        <Radio value="Smoke" colorScheme="green">
                          Smoke
                        </Radio>

                        <Radio value="Snort" colorScheme="green">
                          Snort
                        </Radio>
                        <Radio value="ivDrug" colorScheme="green">
                          IV Drug use
                        </Radio>

                      </Box>
                    </RadioGroup>
                  </>
                }

                {!neverSelected &&
                  <>
                    {
                      drugsSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}
                        >
                          {reasonsArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Drugs<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {/* <Box display="flex" flexDir="row">
                  <PrimaryInput variant="bothSide" defaultValue={duration} inputStyleCss={{ w: "60%" }} inputType="text" inputPlace="Duration*"
                    onChange={handleDuration} inputValue={duration} />
                  <DropdownInput
                    variant="bothSide" defaultOption={durationtimespan} dropDownPlace="Time Span" inputStyleCss={{ w: "40%" }} options={["Hour", "Day", "Week", "Month", "Year"]}
                    onClick={handledurationInputChange} />
                </Box> */}

                {
                      activitiesSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openActivityListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={activityArray.length === 0 ? "space-between" : "start"}
                        >
                          {activityArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          ) : activityArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...activityArray.length === 0 ? {} : floatLabel }} >Type<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          )}
                          {activityArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {activityArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTagActivities(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }


                    <Box display="flex" flexDir="row">
                      <DropdownInput

                        defaultOption={fromdateYear}
                        variant="defaultSide" dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        } />

                      {!showIsCurentCheckBox &&
                        <DropdownInput

                          defaultOption={toDateYear}
                          variant="defaultSide" dropDownPlace="End year*"
                          options={toYearOptions}
                          onClick={(selectedDateValue) =>
                            handleToYearChange(selectedDateValue)
                          } />
                      }
                    </Box>
                    {/*  not showing Consumption years in Edit as well , so commented below code -
                    <Box my="2px" >

                      <p>{calculatedYearsForEdit} </p>
                    </Box> */}
                    {showFrequencyInEditModal &&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" defaultValue={frequency} inputPlace={`${text} per week*`}
                          onChange={handleFrequency} inputValue={frequency} />
                        {/* <DropdownInput
                          variant="rightDown" defaultOption={frequencytimespan} dropDownPlace="Time Span" inputStyleCss={{ w: "40%" }} options={["Week"]}
                          onClick={handlefrequencyInputChange} /> */}
                      </Box>
                    }

                    {showFrequencyCalculation &&
                      <Box my="2px" >

                        <p>{calculatedConsumption} </p>
                      </Box>
                    }
                  </>
                }

                {showQuitCheckBox &&
                  <Box display="flex" justifyContent="start" py="20px">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      defaultChecked={current === 2}
                      isDisabled={true}
                      onChange={handleCheckboxChangeQuit}>
                      I Quit
                    </Checkbox>
                  </Box>
                }

                {showIsCurentCheckBox &&
                  <Box display="flex" justifyContent="start" py="20px">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      defaultChecked={current === 1}
                      isDisabled={true}

                      onChange={handleCheckboxChange}>
                      Current
                    </Checkbox>
                  </Box>

                }

                <Divider />
                {/* <Box as="button" display="flex" alignItems="center" gap="10px" py="20px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                    <Image w="11px" src="/assets/imgs/add-blue.png"/> Add social history</Box> */}
              </Box>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Update Social History"
                onClick={Editsocialhistory}
                isDisabled={false}
              />
            </Box>
          </>}
        />
        <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Social History?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete <br />
                {title ? title : "-"}
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletesocialhistory}
              />
            </>
          }
        />

<ModalRight
        ref={addCondition3}
        handlebackarrow={() => addCondition3.current.closeRight()}
        withBackBtn={true}
        withBackHeader="Drugs"
        body={
          <>
            <SearchBar
              passInputPlace="Search Drugs"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  {/* <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                    reactionList.map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.condition_cd ===
                                                  condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                
                              ) : (
                                <p>Loading...</p>
                              )}
                            </Stack>
                          </Box> */}
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {reactionList && reactionList.length > 0 ? (
                        reactionList
                          .flatMap((category) => category)
                          .filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          ).length === 0 ? (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching drugs found!
                          </Box>
                        ) : (
                          reactionList
                            .flatMap((category) => category)
                            .filter((condition) =>
                              condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  p="15px"
                                  height="50px"
                                  width="100%"
                                  sx={{
                                    _hover: {
                                      border: "2px solid #384052",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Box key={condition.condition_cd} display="flex" alignItems="center">
                                    <Text fontSize="16px" fontWeight="600">
                                      {condition.condition}
                                      <br />
                                      <Box
                                        as="span"
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      ></Box>
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() => handleReactionCheckboxChange(condition)}
                                      isChecked={reasonsArray.some(
                                        (item) => item.condition === condition.condition
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                    </Stack>

                  </Box>



                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Drugs"
                isDisabled={reactionsContinueBtnDisabled}
                onClick={() => closeDrugsModal()}
              />
            </Box>
          </>
        }
      />

<ModalRight
        ref={addCondition4}
        handlebackarrow={() => addCondition4.current.closeRight()}
        withBackBtn={true}
        withBackHeader="Activities"
        body={
          <>
            <SearchBar
              passInputPlace="Search Activity"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {activityList && activityList.length > 0 ? (
                        activityList
                          .flatMap((category) => category)
                          .filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          ).length === 0 ? (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching drugs found!
                          </Box>
                        ) : (
                          activityList
                            .flatMap((category) => category)
                            .filter((condition) =>
                              condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  p="15px"
                                  height="50px"
                                  width="100%"
                                  sx={{
                                    _hover: {
                                      border: "2px solid #384052",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Box key={condition.condition_cd} display="flex" alignItems="center">
                                    <Text fontSize="16px" fontWeight="600">
                                      {condition.condition}
                                      <br />
                                      <Box
                                        as="span"
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      ></Box>
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() => handleActivityCheckboxChange(condition)}
                                      isChecked={activityArray.some(
                                        (item) => item.condition === condition.condition
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                    </Stack>

                  </Box>



                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Activity"
                isDisabled={activitiesContinueBtnDisabled}
                onClick={() => closeActivitiesModal()}
              />
            </Box>
          </>
        }
      />





    
    {/* Mobile responsive */}
    <Box w="100%" display={{base: "block", lg: "none"}}>
      <MobileSecondaryNav
        header="Social history"
        handlebackarrow={backFunction}
      />
      <Box w="100%" h="calc(100vh - 120px)" overflow='hidden auto' p='1rem'>
        {socialData && socialData.length>0 ? (
            <SimpleGrid columns={1} spacing="1rem" w="100%">
                {socialData.map((social,index) => {
                  const totalYears = calculateYearsDifference(social.fromdate, social.todate);
                  var consumptionPreYear = (social.frequency / 7) * totalYears;
                  const finalCalculation = consumptionPreYear.toFixed(2);
                  return(
                <Card border="1px solid #E6E7E9" borderRadius="0.875rem" boxShadow="none" key={index} >
                    <CardBody>
                        <Text fontSize="1rem" fontWeight="600" color="#061027" mt="0.5rem">
                            {/* Smoking */}
                            {social.socialhistory}
                        </Text>
                        <Box display='flex'>
                        <Text fontSize='0.75rem' fontWeight='bold' color="#384052">Status: </Text>
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                            {/* 5 years */}
                            {/* {`${social.fromdate} - ${social.todate}`} */}
                            {/* {`${totalYears} years`} */}
                            {social.iscurrent == 1 ? " Current" : 
                            social.iscurrent == 0 ? " Never" : 
                            social.iscurrent == 2 ? " Former" :"" }
                        </Text>
                        </Box>
                        <Divider my="0.75rem" />
                        <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                            {/* 5x per day */}
                            {/* {social.frequency}  {social.frequencytype} */}
                            {social.frequency && social.frequency !== 0 ? `${social.frequency} 
                                ${social.socialhistory && social.socialhistory == "Smoking" ? "Packs": 
                                                        social.socialhistory == "Alcohol" ? "Drinks" :
                                                        social.socialhistory == "Smokeless" ? "Cans" :
                                                        social.socialhistory == "Illicit Drugs" ? "Times" 
                                                        : "Packs"} 
                                Per ${social.frequencytype}   
                                x ${totalYears == 1 ? totalYears + " year"  :
                                    totalYears > 1 ? totalYears + " years" : 
                                                                    "less than a year"}  
                              ` 
                                : '-'}
                                {social.iscurrent == 2 && (
                <>
                  {` Until ${social.todate ? formatDate_yyyy(social.todate) : ""}`}
                </>
              )}
                        </Text>
                    </CardBody>
                </Card>
                  )
                })}
            </SimpleGrid>

        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="10px"
            alignItems="center"
            h="calc(100vh - 100px)"
            w="100%"
          >
            <Box textAlign="left" fontSize="16px" fontWeight="600">
              <Image w="26px" opacity="0.5" src="/assets/imgs/social-history-bar.png"/>
            </Box>
            <Text textAlign="left" fontSize="12px" fontWeight="500">
              No Data available
            </Text>
          </Box>
        )}
      </Box>
    </Box>
    </>
  )
}

export default SocialHistory