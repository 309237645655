import {
  Box,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  ListItem,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
// import GrayCard from "../../card";
import GrayCard from "../overview/gray-header-card";
import DoctorCard from "../../doctor-card";
import {
  downloadDocument,
  formatDate_Do_MMM_YYYY,
  getLoginID,
} from "../../../auth";
import { GetPatientHealthData } from "../../../middleware/services/visits/patient-overview";
import { useLocation } from "react-router-dom";
import DynamicModal from "../../dynamic-modal";
import PrimaryButton from "../../primary-button";
import { COLORS } from "../../styles/colors";

const PastVisitOverview = ({
  patienData,
  displayChiefComplaint = true,
  displayHPI = true,
  displayPrescription = false,
}) => {
  // console.log(patienData);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const currentPatientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = getLoginID();

  const [patientVisitFormSection2, setPatientVisitFormSection2] =
    useState(null);
  const [patientVisitFormSection3, setPatientVisitFormSection3] =
    useState(null);
  const [patientVisitFormSection4, setPatientVisitFormSection4] =
    useState(null);
  const [patientVisitFormSection5, setPatientVisitFormSection5] =
    useState(null);

  const [inputData, setInputData] = useState({
    patientid: currentPatientId,
    appointmentid: appointmentId,
    doctorid: doctorId,
    type: "advancevisit",
  });

  const visitFormSection2 = useRef();
  const visitFormSection3 = useRef();
  const visitFormSection4 = useRef();
  const visitFormSection5 = useRef();

  useEffect(() => {
    // Function to fetch patient entire health data and update state - FOR PATIENT CURRENT VISIT
    const fetchPatientHealthData = async () => {
      try {
        const data = await GetPatientHealthData(inputData);
        // console.log(data);
        setPatientVisitFormSection2(data?.visitformdata?.section_two);
        setPatientVisitFormSection3(data?.visitformdata?.section_three);
        setPatientVisitFormSection4(data?.visitformdata?.section_four);
        setPatientVisitFormSection5(data?.visitformdata?.section_five);
      } catch (error) {
        console.error("Error fetching patient health data:", error);
      } finally {
      }
    };

    fetchPatientHealthData();
  }, []);
  const calculateConsumptionYears = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculating difference in ms
    const diffInMs = endDate - startDate;

    // Milliseconds to years
    const msInYear = 1000 * 60 * 60 * 24 * 365.25;
    const diffInYears = diffInMs / msInYear;

    if (diffInYears === 0) {
      return "Less than a year";
    }

    return diffInYears.toFixed(0) + " years";
  };

  const calculateYearsDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculating difference in ms
    const diffInMs = endDate - startDate;

    // Milliseconds to years
    const msInYear = 1000 * 60 * 60 * 24 * 365.25;
    const diffInYears = diffInMs / msInYear;

    // if (diffInYears === 0) {
    //   return "Less than a year";
    // }

    return diffInYears.toFixed(0);
  };
  const formatDate_yyyy = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("yyyy");
  };

  function calculateAge(dob) {
    if (!dob) {
      return "";
    }

    const dobDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }
  // console.log(patienData?.investigations);
  // console.log(patienData);

  // hpi formatting
  function generateHistoryOfPresentIllnessString(
    input,
    patientAge,
    patientGender
  ) {
    if (!Array.isArray(input) || input.length === 0) return ""; // Return an empty string if input is not an array or is empty

    const genderPronoun = patientGender === "male" ? "he" : "she";
    const patientDescription = `The patient is a ${patientAge}-year-old individual(${patientGender})`;

    const historyStrings = input.map((item) => {
      let painLocation = "";
      let onset = "";
      let chronicity = "";
      let onsetQuality = "";
      let frequency = "";
      let painRating = "";
      let associatedSymptoms = "";

      item.details.forEach((detail) => {
        switch (detail.attribute) {
          case "S - Site":
            painLocation = detail.values.map((val) => val.value).join(", ");
            break;
          case "Onset":
            onset = detail.value;
            break;
          case "Chronicity":
            chronicity = detail.value;
            break;
          case "Onset Quality":
            onsetQuality = detail.value;
            break;
          case "Frequency":
            frequency = detail.value;
            break;
          case "Rating Scale":
            painRating = detail.value;
            break;
          case "Associated Symptoms":
            associatedSymptoms = detail.values
              .map((symptom) => symptom.value)
              .join(", ");
            break;
          default:
            break;
        }
      });

      const painDescription = `presenting with ${item.type.toLowerCase()}.`;
      const onsetDescription = onset
        ? ` The ${item.type.toLowerCase()} problem started ${onset}`
        : "";
      const chronicityDescription = chronicity
        ? ` and is described as ${chronicity} in nature.`
        : " in nature.";
      const onsetQualityDescription = onsetQuality
        ? ` The onset of the ${item.type.toLowerCase()} was ${onsetQuality}.`
        : "";
      const frequencyDescription = frequency
        ? ` The ${item.type.toLowerCase()} is ${frequency}`
        : "";
      const ratingDescription = painRating
        ? ` and is rated as ${painRating} out of 10 in intensity.`
        : "";
      const associatedSymptomsDescription = associatedSymptoms
        ? ` Associated with ${associatedSymptoms}.`
        : "";

      return `${painDescription}${onsetDescription}${chronicityDescription}${onsetQualityDescription}${frequencyDescription}${ratingDescription}${associatedSymptomsDescription}`;
    });

    return `${patientDescription} ${historyStrings.join(" ")}`;
  }

  const generateDynamicText = (value) => {
    let outputTexts = [];

    value.forEach((item, index) => {
      // Extract patient details
      let gender = patienData?.patientinfo?.gender || "";
      let age = calculateAge(patienData?.patientinfo?.dob) || "";

      // Initialize attributes
      let attributes = {};
      item.details.forEach((attribute) => {
        switch (attribute.attribute) {
          case "Site":
            attributes.site = attribute.values.map((v) => v.value).join(", ");
            break;
          case "Onset":
            attributes.onset = attribute.value;
            break;
          case "Chronicity":
            attributes.chronicity = attribute.value;
            break;
          case "Onset Quality":
            attributes.onsetQuality = attribute.value;
            break;
          case "Frequency":
            attributes.frequency = attribute.value;
            break;
          case "Associated Symptoms":
            attributes.symptoms = attribute.values
              .map((v) => v.value)
              .join(", ");
            break;
          case "Rating Scale":
            attributes.ratingScale = attribute.value;
            break;
          case "Time":
            attributes.time = attribute.value;
            break;
          case "Time Value":
            attributes.timeValue = attribute.value;
            break;
          default:
            break;
        }
      });

      // Construct patient text for the first item
      let patientText =
        index === 0
          ? `The patient is a ${age}-year-old individual (${gender}) `
          : "";

      // Build template with conditional inclusion based on available data
      let template = `${patientText}`;

      if (attributes.site) {
        template += `presenting with ${attributes.site} ${item.type}. `;
      }

      if (attributes.onset) {
        template += `The ${item.type} started ${attributes.onset} and `;
        if (attributes.chronicity) {
          template += `is described as ${attributes.chronicity} in nature. `;
        }
      }

      if (attributes.onsetQuality) {
        template += `The onset of the ${item.type} was ${attributes.onsetQuality}. `;
      }

      if (attributes.frequency) {
        template += `The ${item.type} is ${attributes.frequency} and `;
      }

      if (attributes.ratingScale) {
        template += `is rated as ${attributes.ratingScale} out of 10 in intensity. `;
      }

      if (attributes.symptoms) {
        template += `Associated with ${attributes.symptoms}.`;
      }

      // Push the template to the outputTexts array
      outputTexts.push(template.trim());
    });

    return outputTexts;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };

  return (
    <>
      <Box
        w="100%"
        display="flex"
        flexWrap="wrap"
        gap="1rem"
        h="100%"
        py={"16px"}
      >
        {/* Demographics */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          cardHeader="Demographics"
          cardHeaderIcon="/assets/imgs/measure.png"
          bodyStyle={{ p: "0" }}
          cardBody={
            <>
              <Flex direction="row" justify="space-around" h="full">
                <Box
                  p="10px"
                  px="20px"
                  display="flex"
                  flexDir="column"
                  h="100%"
                  justifyContent="center"
                  w="100%"
                >
                  <Box>
                    <Box
                      as="p"
                      display="inline-block"
                      fontSize="20px"
                      fontWeight="600"
                    >
                      {/* 170 */}
                      {patienData && patienData.demographics.height ? (
                        <Text fontWeight={"semibold"}>
                          {patienData.demographics.height}
                        </Text>
                      ) : (
                        "0"
                      )}
                    </Box>
                    <Box
                      display="inline-block"
                      as="span"
                      fontSize="12px"
                      fontWeight="400"
                      pl={"5px"}
                    >
                      {patienData && patienData.demographics.heightunit ? (
                        <Text>{patienData.demographics.heightunit}</Text>
                      ) : (
                        "cms"
                      )}
                    </Box>
                  </Box>
                  <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                    Height
                  </Box>
                </Box>

                <Box
                  p="10px"
                  px="20px"
                  display="flex"
                  flexDir="column"
                  h="100%"
                  justifyContent="center"
                  w="100%"
                  borderRight="1px solid #E6E7E9"
                  borderLeft="1px solid #E6E7E9"
                  _last={{ borderRight: 0 }}
                >
                  <Box>
                    <Box
                      as="p"
                      display="inline-block"
                      fontSize="22px"
                      fontWeight="600"
                    >
                      {/* 70 */}

                      {patienData && patienData.demographics.weight ? (
                        <Text fontWeight={"semibold"}>
                          {patienData.demographics.weight}
                        </Text>
                      ) : (
                        "0"
                      )}
                    </Box>

                    <Box
                      display="inline-block"
                      as="p"
                      fontSize="15px"
                      fontWeight="400"
                      pl={"5px"}
                    >
                      {patienData && patienData.demographics.weightunit ? (
                        <Text>{patienData.demographics.weightunit}</Text>
                      ) : (
                        "Kg"
                      )}
                    </Box>
                  </Box>
                  <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                    Weight
                  </Box>
                </Box>

                <Box
                  p="10px"
                  px="20px"
                  display="flex"
                  flexDir="column"
                  h="100%"
                  justifyContent="center"
                  w="100%"
                  borderRight="1px solid #E6E7E9"
                  borderLeft="1px solid #E6E7E9"
                  _last={{ borderRight: 0 }}
                >
                  <Box>
                    <Box
                      as="p"
                      display="inline-block"
                      fontSize="22px"
                      fontWeight="600"
                    >
                      {/* 70 */}
                      {patienData && patienData.demographics.bmi ? (
                        <Text fontWeight={"semibold"}>
                          {patienData.demographics.bmi}
                        </Text>
                      ) : (
                        "0"
                      )}
                    </Box>
                  </Box>
                  <Box as="p" fontSize="12px" fontWeight="500" color="#6A707D">
                    BMI
                  </Box>
                </Box>
              </Flex>
            </>
          }
        />

        {/* Chief Complaint */}
        {displayChiefComplaint && (
          <GrayCard
            hasFooter={false}
            w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            cardHeader="chief complaint"
            cardHeaderIcon="/assets/imgs/cheif-complaint.png"
            cardBody={
              <>
                {patienData && patienData.chiefcomplaint.chiefcomplaint ? (
                  <Flex
                    flexDir="column"
                    justifyContent="space-between"
                    h="100%"
                  >
                    <Text fontSize="0.875rem" fontWeight="500">
                      {patienData.chiefcomplaint.chiefcomplaint}
                    </Text>
                    <Spacer />
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                      {/* 10th Jan, 2023 */}
                      {patienData.chiefcomplaint.date
                        ? moment(patienData.chiefcomplaint.date).format(
                            "Do MMM, YYYY"
                          )
                        : "-"}
                    </Text>
                  </Flex>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/cheif-complaint.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </>
            }
          />
        )}

        {/* Present Illness */}
        {displayHPI && (
          <GrayCard
            hasFooter={false}
            w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            cardHeader="history of present illness"
            cardHeaderIcon="/assets/imgs/medical-history.png"
            cardBody={
              <>
                {patienData && patienData.historyofpresentillness ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                  >
                    <Text textAlign="left" fontSize="14px" fontWeight="500">
                      {/* {patienData.historyofpresentillness
                      ? generateHistoryOfPresentIllnessString(
                          patienData?.historyofpresentillness || [],
                          calculateAge(patienData?.patientinfo?.dob),
                          patienData?.patientinfo?.gender
                        )
                      : "0"} */}
                      {generateDynamicText(
                        patienData && patienData.historyofpresentillness
                      ).map((text, index) => (
                        <React.Fragment key={index}>
                          {text}
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/medical-history.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </>
            }
          />
        )}

        {/* Physical Exam */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="physical exam"
          cardHeaderIcon="/assets/imgs/physical-exam.png"
          cardBody={
            <>
              {patienData && patienData.physicalexam.length > 0 ? (
                patienData.physicalexam.map((item, index) => (
                  <Box key={index} mb="10px">
                    <Text fontSize="12px" fontWeight="500" color="#0C7650">
                      {item?.type}
                    </Text>
                    {/* {item.physicalexam.map((subItem, subIndex) => (
                      <Text key={subIndex} fontSize="16px" fontWeight="600">
                        {subItem.value}
                      </Text>
                    ))} */}
                    <Text fontSize="16px" fontWeight="600">
                      {item.condition}
                    </Text>
                    <Text
                      fontSize="12px"
                      fontWeight="500"
                      color="#384052"
                      mb="10px"
                    >
                      {item.date ? formatDate(item.date) : "-"}
                    </Text>
                    <Divider />
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap="10px"
                  alignItems="center"
                  height={"220px"}
                >
                  <Box textAlign="left" fontSize="16px" fontWeight="600">
                    <Image
                      w="26px"
                      opacity="0.5"
                      src="/assets/imgs/physical-exam.png"
                    />
                  </Box>
                  <Text textAlign="left" fontSize="12px" fontWeight="500">
                    No Data available
                  </Text>
                </Box>
              )}
            </>
          }
        />

        {/* ROS */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="review of system"
          cardHeaderIcon="/assets/imgs/physical-exam.png"
          cardBody={
            <>
              {patienData && patienData.reviewofsystem.length > 0 ? (
                patienData.reviewofsystem.map((item, index) => (
                  <Box key={index} mb="10px">
                    <Text fontSize="12px" fontWeight="500" color="#0C7650">
                      {item?.type}
                    </Text>
                    {/* {item.physicalexam.map((subItem, subIndex) => (
                      <Text key={subIndex} fontSize="16px" fontWeight="600">
                        {subItem.value}
                      </Text>
                    ))} */}
                    <Text fontSize="16px" fontWeight="600">
                      {item.condition}
                    </Text>
                    <Text
                      fontSize="12px"
                      fontWeight="500"
                      color="#384052"
                      mb="10px"
                    >
                      {item.date ? formatDate(item.date) : "-"}
                    </Text>
                    <Divider />
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap="10px"
                  alignItems="center"
                  height={"220px"}
                >
                  <Box textAlign="left" fontSize="16px" fontWeight="600">
                    <Image
                      w="26px"
                      opacity="0.5"
                      src="/assets/imgs/physical-exam.png"
                    />
                  </Box>
                  <Text textAlign="left" fontSize="12px" fontWeight="500">
                    No Data available
                  </Text>
                </Box>
              )}
            </>
          }
        />

        {/* Visit Prescription  */}
        {displayPrescription && patienData?.orderprescription?.documents && (
          <GrayCard
            // key={index}
            w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="Doctor Notes"
            hasFooter={false}
            cardBody={
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  w="100%"
                  alignItems="flex-start"
                  mt="1rem"
                >
                  <Box display="flex" gap="0.75rem">
                    <Image
                      borderRadius="full"
                      w="2.75rem"
                      h="2.75rem"
                      src={
                        patienData?.orderprescription?.doctorimage
                          ? patienData?.orderprescription?.imagepath +
                            patienData?.orderprescription?.doctorimage
                          : "/assets/imgs/no-image.png"
                      }
                    />
                    <Box p="10px">
                      {/* <Text
                      className="textOverflow" w="167px"
                        fontSize="0.625rem"
                        fontWeight="500"
                        color="#384052"
                        title={patienData.orderprescription.specialization}
                      >
                        {patienData.orderprescription.specialization}
                      </Text> */}

                      <Text
                        fontSize="0.875rem"
                        fontWeight="600"
                        color="#061027"
                        title={patienData?.orderprescription?.doctorname}
                      >
                        {patienData?.orderprescription?.doctorname}
                      </Text>
                    </Box>
                  </Box>
                  <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                    {formatDate_Do_MMM_YYYY(
                      patienData?.orderprescription?.prescribeddate
                    )}
                  </Text>
                </Box>

                <Divider my="0.75rem" />
                <Flex gap="8px" align="center">
                  <Text fontSize="12px">
                    <Text as="b" display="inline">
                      Prescription:
                    </Text>{" "}
                    {patienData?.orderprescription?.bookingid
                      ? "#" + patienData?.orderprescription?.bookingid
                      : "-"}
                  </Text>
                </Flex>

                <Box w="100%">
                  <Flex gap="8px" align="center">
                    <Box
                      as="button"
                      onClick={() =>
                        window.open(
                          patienData?.orderprescription?.imagepath +
                            patienData?.orderprescription?.documents,
                          "_blank"
                        )
                      }
                      w="100%"
                      border="1px solid #E6E7E9"
                      p="10px"
                      borderRadius="10px"
                      mt="15px"
                      height="50px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        display="flex"
                        fontSize="12px"
                        flexDirection="column"
                        justifyContent="center"
                        fontWeight="500"
                      >
                        View
                      </Box>
                    </Box>
                    <Box
                      as="button"
                      onClick={() =>
                        downloadDocument(
                          patienData?.orderprescription?.imagepath +
                            patienData?.orderprescription?.documents,
                          "Order"
                        )
                      }
                      w="100%"
                      border="1px solid #E6E7E9"
                      p="10px"
                      borderRadius="10px"
                      mt="15px"
                      height="50px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        display="flex"
                        fontSize="12px"
                        flexDirection="column"
                        justifyContent="center"
                        fontWeight="500"
                      >
                        Download
                      </Box>
                      {/* <Box borderRadius="full" p="10px">
                      <Image w="8px" src="/assets/imgs/right-icon.png" />
                    </Box> */}
                    </Box>
                  </Flex>
                </Box>
                {patienData?.progressnotes?.documents && (
                  <>
                    <Divider my="0.75rem" />
                    {/* Progress notes  */}
                    <Flex gap="8px" align="center">
                      <Text fontSize="12px">
                        <Text as="b" display="inline">
                          Progress Notes:
                        </Text>{" "}
                        {patienData?.progressnotes?.bookingid
                          ? "#" + patienData?.progressnotes?.bookingid
                          : "-"}
                      </Text>
                    </Flex>
                    <Box w="100%" mb="1rem">
                      <Flex gap="8px" align="center">
                        <Box
                          as="button"
                          onClick={() =>
                            window.open(
                              patienData?.progressnotes?.imagepath +
                                patienData?.progressnotes?.documents,
                              "_blank"
                            )
                          }
                          w="100%"
                          border="1px solid #E6E7E9"
                          p="10px"
                          borderRadius="10px"
                          mt="15px"
                          height="50px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          // mb="1rem"
                        >
                          <Box
                            display="flex"
                            fontSize="12px"
                            flexDirection="column"
                            justifyContent="center"
                            fontWeight="500"
                          >
                            View
                          </Box>
                        </Box>
                        <Box
                          as="button"
                          onClick={() =>
                            downloadDocument(
                              patienData?.progressnotes?.imagepath +
                                patienData?.progressnotes?.documents,
                              "Order"
                            )
                          }
                          w="100%"
                          border="1px solid #E6E7E9"
                          p="10px"
                          borderRadius="10px"
                          mt="15px"
                          height="50px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            display="flex"
                            fontSize="12px"
                            flexDirection="column"
                            justifyContent="center"
                            fontWeight="500"
                          >
                            Download
                          </Box>
                          {/* <Box borderRadius="full" p="10px">
                      <Image w="8px" src="/assets/imgs/right-icon.png" />
                    </Box> */}
                        </Box>
                      </Flex>
                    </Box>
                  </>
                )}
              </>
            }
          />
        )}
        {/* Recommended Tests */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Recommended Tests"
          cardHeaderIcon="/assets/imgs/doctor-tool.png"
          cardBody={
            <>
              <Box>
                {patienData && patienData?.recommendedtests?.length > 0 ? (
                  patienData.recommendedtests.map((item, index) => (
                    <Box
                      key={index}
                      borderBottom="1px solid #E6E7E9"
                      py="0.875rem"
                      display="flex"
                      flexDir="column"
                      gap="0.5rem"
                    >
                      <Text fontSize="16px" fontWeight="600">
                        {item.ordertype}: {item.ordername}
                      </Text>
                      {/* <Box
                        as="p"
                        p="0.5rem"
                        bg="#F9FAFB"
                        borderLeft="2px solid #14C585"
                        fontSize="0.875rem"
                        fontWeight="500"
                      >
                        Plan: {item.entitytype}
                      </Box>
                      <Text fontSize="12px" fontWeight="500" color="#384052">
                        {formatDate(item.date)}
                      </Text> */}
                    </Box>
                  ))
                ) : (
                  // <Box
                  //   display="flex"
                  //   flexDirection="column"
                  //   justifyContent="center"
                  //   gap="10px"
                  //   alignItems="center"
                  // >
                  //   <Box textAlign="left" fontSize="16px" fontWeight="600">
                  //     <Image
                  //       w="26px"
                  //       opacity="0.5"
                  //       src="/assets/imgs/treatment-plan.png"
                  //     />
                  //   </Box>
                  //   <Text textAlign="left" fontSize="12px" fontWeight="500">
                  //     No Data available
                  //   </Text>
                  // </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height={"220px"}
                    gap="10px"
                    textAlign="center"
                  >
                    <Box fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/treatment-plan.png"
                      />
                    </Box>
                    <Text fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Doctor Assesment */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          cardHeader="doctors assessment"
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeaderIcon="/assets/imgs/doctor-tool.png"
          cardBody={
            <>
              <Box>
                {patienData && patienData.doctorsassessment?.length > 0 ? (
                  patienData.doctorsassessment.map((item, index) => (
                    <Box
                      key={index}
                      borderBottom="1px solid #E6E7E9"
                      py="0.875rem"
                      display="flex"
                      flexDir="column"
                      gap="0.5rem"
                    >
                      <Text fontSize="16px" fontWeight="600">
                        {item.diagnosis}
                      </Text>
                      <Box
                        as="p"
                        p="0.5rem"
                        bg="#F9FAFB"
                        borderLeft="2px solid #14C585"
                        fontSize="0.875rem"
                        fontWeight="500"
                      >
                        Plan: {item.plan}
                      </Box>
                      {/* {item.entitytype === "Prescribed Medicine" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #14C585" fontSize="0.875rem" fontWeight="500">
                                Plan: Basic metabolic panel, Magnesium
                            </Box>
                        )}
                        {item.entitytype === "Surgery" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #FF6B6B" fontSize="0.875rem" fontWeight="500">
                                Plan: Surgery Scheduled
                            </Box>
                        )}
                        {item.entitytype === "labs" && (
                            <Box as='p' p="0.5rem" bg="#F9FAFB" borderLeft="2px solid #6C5DD3" fontSize="0.875rem" fontWeight="500">
                                Plan: Lab Test Scheduled
                            </Box>
                        )} */}
                      <Text fontSize="12px" fontWeight="500" color="#384052">
                        {formatDate(item.date)}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    height={"220px"}
                    alignItems="center"
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/treatment-plan.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Treatment Team */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "0 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Treatment Team"
          cardHeaderIcon="/assets/imgs/treatment-plan.png"
          cardBody={
            <>
              <Box>
                {patienData && patienData?.treatmentteam?.length > 0 ? (
                  patienData.treatmentteam.map((item, index) => (
                    <Box
                      key={index}
                      borderBottom="1px solid #E6E7E9"
                      py="0.875rem"
                      display="flex"
                      flexDir="column"
                      gap="0.5rem"
                    >
                      <Text fontSize="16px" fontWeight="600">
                        {item.doctorname}
                      </Text>
                      <Box
                        as="p"
                        p="0.5rem"
                        bg="#F9FAFB"
                        borderLeft="2px solid #14C585"
                        fontSize="0.875rem"
                        fontWeight="500"
                      >
                        Plan: {item.entitytype}
                      </Box>
                      <Text fontSize="12px" fontWeight="500" color="#384052">
                        {formatDate(item.date)}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    height={"220px"}
                    alignItems="center"
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/treatment-plan.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Past Medical HIstory */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Past Medical History"
          cardHeaderIcon="/assets/imgs/medical-history.png"
          cardBody={
            <>
              <Box>
                <VStack align="stretch">
                  {patienData && patienData.medicalhistory.length > 0 ? (
                    patienData.medicalhistory.map((elem, index) => (
                      <Stack
                        key={index}
                        direction="column"
                        align="flex-start"
                        gap="0"
                      >
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                          <Text fontSize="16px" fontWeight="600">
                            {elem.condition}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          h="auto"
                          gap="5px"
                        >
                          <Center
                            h="35px"
                            ml="5px"
                            borderLeft="1px dotted #CDCFD4"
                          ></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(elem.conditionidentifieddate)}
                          </Text>
                        </Box>
                      </Stack>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      height={"220px"}
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/medical-history.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  )}
                </VStack>
              </Box>
            </>
          }
        />

        {/* Past Surgical HIstory */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="Past Surgical History"
          cardHeaderIcon="/assets/imgs/surgeries.png"
          cardBody={
            <>
              <Box>
                <VStack align="stretch">
                  {patienData && patienData.surgicalhistory.length > 0 ? (
                    patienData.surgicalhistory.map((surgery, index) => (
                      <Stack
                        key={index}
                        direction="column"
                        align="flex-start"
                        gap="0"
                      >
                        <Box display="flex" alignItems="center" gap="5px">
                          <Circle
                            size="12px"
                            bg="white"
                            border="2px solid gray"
                            borderRadius="full"
                            color="black"
                          />
                          <Text fontSize="16px" fontWeight="600">
                            {surgery.surgeryName}
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          h="auto"
                          gap="5px"
                        >
                          <Center
                            h="35px"
                            ml="5px"
                            borderLeft="1px dotted #CDCFD4"
                          ></Center>
                          <Text fontSize="12px" ml="7px" fontWeight="600">
                            {formatDate(surgery.date)}
                          </Text>
                        </Box>
                      </Stack>
                    ))
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      gap="10px"
                      alignItems="center"
                      height={"220px"}
                    >
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="26px"
                          opacity="0.5"
                          src="/assets/imgs/surgeries.png"
                        />
                      </Box>
                      <Text textAlign="left" fontSize="12px" fontWeight="500">
                        No Data available
                      </Text>
                    </Box>
                  )}
                </VStack>
              </Box>
            </>
          }
        />

        {/* Family History */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="family history"
          cardHeaderIcon="/assets/imgs/family-history.png"
          cardBody={
            <>
              <Box display="flex" flexDirection="column" gap="10px">
                {patienData && patienData.familyhistory.length > 0 ? (
                  patienData.familyhistory.map((family, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Text fontSize="16px" fontWeight="600">
                          {family.relation}
                        </Text>
                        <Text fontSize="12px" fontWeight="500" color="#384052">
                          {formatDate(family.dob)}
                        </Text>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          {family.medicalcondition &&
                            family.medicalcondition.map((condition, index) => (
                              <Box
                                as="p"
                                display="flex"
                                alignItems="center"
                                flexDir="row"
                                fontSize="10px"
                                key={index}
                                title={family.medicalcondition
                                  .map((condition) => condition.condition)
                                  .join(", ")}
                              >
                                <Box as="span" fontSize="8px" mr="3px">
                                  ●{" "}
                                </Box>{" "}
                                {condition.condition}
                                {index < family.medicalcondition.length - 1 &&
                                  ", "}
                              </Box>
                            ))}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        border="1px solid #E6E7E966"
                        bg="#E6E7E966"
                        p="4px"
                        px="6px"
                        borderRadius="full"
                      >
                        <Text
                          as="span"
                          color="#384052"
                          fontSize="10px"
                          ml="2px"
                        >
                          {family.isdeceased ? "Deceased" : "Alive"}
                        </Text>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/family-history.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Social History */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="social History"
          cardHeaderIcon="/assets/imgs/social-history-bar.png"
          cardBody={
            <>
              <Box>
                {patienData && patienData.socialhistory.length > 0 ? (
                  patienData.socialhistory.map((history, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-around"
                      gap="10px"
                    >
                      <Box>
                        <Text fontSize="16px" fontWeight="600">
                          {history.type}
                        </Text>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Text
                            fontSize="12px"
                            fontWeight="500"
                            color="#384052"
                          >
                            <Box display="flex">
                              <Text
                                fontSize="0.75rem"
                                fontWeight="bold"
                                color="#384052"
                              >
                                Status:{" "}
                              </Text>
                              <Text
                                fontSize="0.75rem"
                                fontWeight="500"
                                color="#384052"
                              >
                                {/* 5 years */}
                                {/* {`${social.fromdate} - ${social.todate}`} */}
                                {/* {`${totalYears} years`} */}
                                {history.iscurrent == 1
                                  ? " Current"
                                  : history.iscurrent == 0
                                  ? " Never"
                                  : history.iscurrent == 2
                                  ? " Former"
                                  : ""}
                              </Text>
                            </Box>
                            {/* {`${calculateYearsDifference(
                              history.fromdate,
                              history.todate
                            )}`}
                            : {history.frequency} x per {history.frequencytype} */}
                            <Text
                              fontSize="0.75rem"
                              fontWeight="500"
                              color="#384052"
                            >
                              {/* 5x per day */}
                              {/* {social.frequency}  {social.frequencytype} */}
                              {history.frequency && history.frequency !== 0
                                ? `${history.frequency} 
                              ${
                                history.type && history.type == "Smoking"
                                  ? "Packs"
                                  : history.type == "Alcohol"
                                  ? "Drinks"
                                  : history.type == "Smokeless"
                                  ? "Cans"
                                  : history.type == "Illicit Drugs"
                                  ? "Times"
                                  : "Packs"
                              } 
                              Per ${history.frequencytype}   
                              x ${
                                calculateYearsDifference(
                                  history.fromdate,
                                  history.todate
                                ) == 1
                                  ? calculateYearsDifference(
                                      history.fromdate,
                                      history.todate
                                    ) + " year"
                                  : calculateYearsDifference(
                                      history.fromdate,
                                      history.todate
                                    ) > 1
                                  ? calculateYearsDifference(
                                      history.fromdate,
                                      history.todate
                                    ) + " years"
                                  : "less than a year"
                              }  
                            `
                                : "-"}
                              {history.iscurrent == 2 && (
                                <>
                                  {` Until ${
                                    history.todate
                                      ? formatDate_yyyy(history.todate)
                                      : ""
                                  }`}
                                </>
                              )}
                            </Text>
                          </Text>
                          {/* {history.frequency > 0 ? (
                            <Image
                              w="13px"
                              h="9px"
                              mx="3px"
                              src="/assets/imgs/red-chart.png"
                            />
                          ) : (
                            <Image
                              w="16px"
                              h="14px"
                              mx="3px"
                              src="/assets/imgs/green-chart.png"
                            />
                          )} */}
                        </Box>
                      </Box>
                      <Divider size="1px" colorScheme="#E6E7E9" />
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap="10px"
                    alignItems="center"
                    height={"220px"}
                  >
                    <Box textAlign="left" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/social-history-bar.png"
                      />
                    </Box>
                    <Text textAlign="left" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
        />

        {/* Current medication */}
        <GrayCard
          hasFooter={false}
          w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
          bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
          cardHeader="current medications"
          cardHeaderIcon="/assets/imgs/medication.png"
          cardBody={
            <>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="50px"
              >
                <Box>
                  <UnorderedList>
                    {patienData &&
                    patienData.medicationForOverview?.length > 0 ? (
                      patienData.medicationForOverview.map(
                        (medication, index) => (
                          <ListItem
                            key={index}
                            fontSize="14px"
                            fontWeight="600"
                          >
                            {medication.medicinename}
                            {": "}
                            {medication.frequency
                              ? medication.frequency + "-per day"
                              : ""}
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              Composition:{" "}
                              {medication.chemicalcomposition
                                ? medication.chemicalcomposition
                                : "-/-"}
                            </Text>
                          </ListItem>
                        )
                      )
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        height={"220px"}
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/treatment-plan.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                  </UnorderedList>
                </Box>
              </Box>
            </>
          }
        />

        {/* Vitals */}
        <Box display="flex" justifyContent="space-between" gap="20px" w="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
          >
            <GrayCard
              hasFooter={false}
              bodyStyle={{ p: "0" }}
              cardHeader="vitals"
              cardHeaderIcon="/assets/imgs/heart.png"
              cardBody={
                <>
                  <Box width="100%" overflowX="auto">
                    <SimpleGrid minChildWidth="8.75rem">
                      {patienData && patienData.vitals.length > 0
                        ? patienData.vitals.map((vital, index) => (
                            <Box
                              key={index}
                              borderRight="1px solid #E6E7E9"
                              p={4}
                            >
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                {vital.vitalName}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="22px"
                                fontWeight="600"
                              >
                                {vital.vitalValue}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize="15px"
                                pl={"5px"}
                                fontWeight="400"
                              >
                                {vital.vitalUnit}
                              </Text>
                              <Box>
                                <Text
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#6A707D"
                                >
                                  {formatDate(vital.vitalDate)}
                                </Text>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                mt="0.5rem"
                                gap="0.25rem"
                              >
                                {vital.vitalStatus === "High" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#F04438"
                                      src="/assets/imgs/arrow_upward.png"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#F04438"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : vital.vitalStatus === "Ok" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#6A707D"
                                      src="/assets/imgs/check.png"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#6A707D"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : vital.vitalStatus === "Ok" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#6A707D"
                                      src="/assets/imgs/check.png"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#6A707D"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : vital.vitalStatus === "Low" ? (
                                  <>
                                    <Image
                                      w="12px"
                                      h="auto"
                                      color="#D46F11"
                                      src="/assets/svgs/arrow_upward.svg"
                                    />
                                    <Text
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="600"
                                      ml="2px"
                                      color="#D46F11"
                                    >
                                      {vital.vitalStatus}
                                    </Text>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
                          ))
                        : "no records found"}
                    </SimpleGrid>
                  </Box>
                </>
              }
            />
          </Box>
        </Box>

        {/* Investigations */}
        <Box display="flex" justifyContent="space-between" gap="20px" w="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            w="100%"
          >
            <GrayCard
              hasFooter={false}
              bodyStyle={{ p: "0" }}
              cardHeader="Investigations"
              cardHeaderIcon="/assets/imgs/investigation.png"
              cardBody={
                <>
                  <Box width="100%" overflowX="auto">
                    <SimpleGrid minChildWidth="8.7rem">
                      {patienData &&
                      patienData?.overviewinvestigations?.length > 0 ? (
                        patienData.overviewinvestigations.map(
                          (investigation, index) => (
                            <Box borderWidth="1px" p={4} key={index}>
                              <Text
                                fontSize="12px"
                                fontWeight="500"
                                color="#6A707D"
                              >
                                {/* White blood cells */}
                                {investigation.eventtitle
                                  ? investigation.eventtitle
                                  : "Null"}
                              </Text>
                              <Text
                                display="inline-block"
                                fontSize={{ lg: "18px", xl: "22px" }}
                                fontWeight="600"
                              >
                                {investigation.actualresultvalue
                                  ? investigation.actualresultvalue
                                  : "-"}
                              </Text>
                              {investigation.resultuom && (
                                <Text
                                  display="inline-block"
                                  fontSize="15px"
                                  fontWeight="400"
                                >
                                  (
                                  {investigation.resultuom
                                    ? investigation.resultuom
                                    : ""}
                                  )
                                </Text>
                              )}
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Box>
                                  <Text
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  >
                                    {/* May 15, 2024 */}
                                    {formatDate(investigation.verifieddate)}
                                  </Text>
                                </Box>
                                {investigation.status === "low" &&
                                investigation.trendColor === "red" ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    transform="scaleY(-1)"
                                  >
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/red-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : investigation.status === "high" &&
                                  investigation.trendColor === "red" ? (
                                  <Box display="flex" alignItems="center">
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/red-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : investigation.status === "low" &&
                                  investigation.trendColor === "green" ? (
                                  <Box display="flex" alignItems="center">
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/green-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : investigation.status === "high" &&
                                  investigation.trendColor === "green" ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    transform="scaleY(-1)"
                                  >
                                    <Image
                                      w="12px"
                                      h="auto"
                                      src="/assets/imgs/green-chart.png"
                                      alt="Normal"
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          )
                        )
                      ) : (
                        <Flex
                          direction="row"
                          justify="space-around"
                          h="full"
                          w="100%"
                          flexWrap="wrap"
                        >
                          <Text>No Data Available</Text>
                          {/* {dataEntries.map((index) => (
                            <SimpleTable NoDataHeader={index} />
                        ))} */}
                        </Flex>
                      )}
                    </SimpleGrid>
                  </Box>
                </>
              }
            />
          </Box>
        </Box>

        {/* Lab and Imaging */}
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap={{ base: "wrap", xl: "nowrap" }}
          gap="20px"
          w="100%"
        >
          <GrayCard
            hasFooter={false}
            w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="labs"
            cardHeaderIcon="/assets/imgs/biotech.png"
            cardBody={
              <>
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    gap="20px"
                  >
                    {patienData && patienData.labs.length > 0 ? (
                      patienData.labs.map((lab, index) => (
                        <Box key={index}>
                          <Box
                            mb="3px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              fontSize="16px"
                              fontWeight="600"
                              color="#061027"
                            >
                              {lab.name}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {/* {new Date(lab.verifieddate).toLocaleDateString()} */}
                              {formatDate(lab.date)}
                            </Text>
                          </Box>
                          <Text fontSize="14px" fontWeight="500">
                            {lab.summary}
                          </Text>
                          <Divider mt="10px" size="1px" colorScheme="#E6E7E9" />
                        </Box>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        height={"220px"}
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/biotech.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            }
          />

          <GrayCard
            hasFooter={false}
            w={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            minW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            maxW={{ base: "100%", md: "100%", xl: "calc(50% - 10px)" }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            cardHeader="IMAGING"
            cardHeaderIcon="/assets/imgs/hand_bones.png"
            cardBody={
              <>
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    gap="20px"
                  >
                    {patienData && patienData.imaging.length > 0 ? (
                      patienData.imaging.map((image, index) => (
                        <Box key={index}>
                          <Box
                            mb="3px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              fontSize="16px"
                              fontWeight="600"
                              color="#061027"
                            >
                              {image.name}
                            </Text>
                            <Text
                              fontSize="12px"
                              fontWeight="500"
                              color="#384052"
                            >
                              {/* {new Date(image.verifieddate).toLocaleDateString()} */}
                              {formatDate(image.date)}
                            </Text>
                          </Box>
                          <Text fontSize="14px" fontWeight="500">
                            {image.summary}
                          </Text>
                          <Divider mt="10px" size="1px" colorScheme="#E6E7E9" />
                        </Box>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                        alignItems="center"
                        height={"220px"}
                      >
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="26px"
                            opacity="0.5"
                            src="/assets/imgs/hand_bones.png"
                          />
                        </Box>
                        <Text textAlign="left" fontSize="12px" fontWeight="500">
                          No Data available
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            }
          />
        </Box>

        {/* Advanced Details Screening Pages Code Start */}

        {/* Adult Health Screening */}
        {patientVisitFormSection2?.length > 0 && (
          <GrayCard
            w={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            minW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            maxW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Adult Patient Screening"
            cardHeaderIcon="/assets/imgs/expand.png"
            onClick={() => {
              if (visitFormSection2.current) {
                visitFormSection2.current.openModal();
              }
            }}
            cardBody={
              <Box>
                {patientVisitFormSection2 &&
                patientVisitFormSection2.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection2.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection2).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Child Health Screening */}
        {patientVisitFormSection3?.length > 0 && (
          <GrayCard
            w={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            minW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            maxW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Child Screening"
            cardHeaderIcon="/assets/imgs/expand.png"
            onClick={() => {
              if (visitFormSection3.current) {
                visitFormSection3.current.openModal();
              }
            }}
            cardBody={
              <Box>
                {patientVisitFormSection3 &&
                patientVisitFormSection3.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection3.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection3).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Women Health Screening */}
        {patientVisitFormSection4?.length > 0 && (
          <GrayCard
            w={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            minW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            maxW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Women's Preventive Health Screening"
            cardHeaderIcon="/assets/imgs/expand.png"
            onClick={() => {
              if (visitFormSection4.current) {
                visitFormSection4.current.openModal();
              }
            }}
            cardBody={
              <Box>
                {patientVisitFormSection4 &&
                patientVisitFormSection4.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection4.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection4).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Mental Health Screening */}
        {patientVisitFormSection5?.length > 0 && (
          <GrayCard
            w={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            minW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            maxW={{
              base: "100%",
              md: "100%",
              xl: "100%",
              "2xl": "calc(50% - 10px)",
            }}
            bodyStyle={{ p: "10px 20px", overflow: "auto", maxH: "220px" }}
            hasFooter={false}
            cardHeader="Lifestyle and Mental Health Screening"
            cardHeaderIcon="/assets/imgs/expand.png"
            onClick={() => {
              if (visitFormSection5.current) {
                visitFormSection5.current.openModal();
              }
            }}
            cardBody={
              <Box>
                {patientVisitFormSection5 &&
                patientVisitFormSection5.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection5.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection5).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        )}

        {/* Advanced Details Screening Pages Code End */}

        {/* Open Modal to view Questions start */}

        <DynamicModal
          ref={visitFormSection2}
          modalHeader={`Adult Patient Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection2 &&
                patientVisitFormSection2.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection2.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection2).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection2.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModal
          ref={visitFormSection3}
          modalHeader={`Child Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection3 &&
                patientVisitFormSection3.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection3.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection3).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection3.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModal
          ref={visitFormSection4}
          modalHeader={`Women's Preventive Health Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection4 &&
                patientVisitFormSection4.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection4.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection4).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection4.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModal
          ref={visitFormSection5}
          modalHeader={`Lifestyle and Mental Health Screening`}
          modalWidth={"50vw"}
          modalBody={
            <>
              <Box height={"50vh"} overflow={"auto"}>
                {patientVisitFormSection5 &&
                patientVisitFormSection5.length > 0 ? (
                  Object.entries(
                    patientVisitFormSection5.reduce((acc, item) => {
                      acc[item.subsectionname] = acc[item.subsectionname] || [];
                      acc[item.subsectionname].push(item);
                      return acc;
                    }, {})
                  ).map(([heading, questions], index) => (
                    <Box key={index} mb={4}>
                      {/* Heading */}
                      <Text fontSize="16px" fontWeight="600" mb={2}>
                        {heading}
                      </Text>

                      {/* Group Questions by their main question */}
                      {Object.values(
                        questions.reduce((acc, q) => {
                          acc[q.question] = acc[q.question] || {
                            ...q,
                            subquestions: [],
                          };
                          if (q.subquestion) {
                            acc[q.question].subquestions.push({
                              subquestion: q.subquestion,
                              value: q.value,
                              description: q.description,
                            });
                          }
                          return acc;
                        }, {})
                      ).map((qna, idx) => (
                        <Box key={idx} mb={3}>
                          {/* Main Question */}
                          <Text
                            fontSize="0.875rem"
                            fontWeight="bold"
                            color="#384052"
                          >
                            Q:{" "}
                            <Text as="span" fontWeight="500">
                              {qna.question}
                            </Text>
                          </Text>

                          {/* If no subquestions, show the answer */}
                          {qna.subquestions.length === 0 ? (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="bold"
                              color="#384052"
                            >
                              A:{" "}
                              <Text as="span" fontWeight="500">
                                {qna.description
                                  ? `${qna.value}, ${qna.description}`
                                  : qna.value}
                              </Text>
                            </Text>
                          ) : (
                            /* Handle grouped subquestions */
                            // qna.subquestions.map((subq, subIdx) => (
                            //   <Box key={subIdx} ml={0} mt={2}>
                            //     <Text fontSize="0.875rem" fontWeight="bold" color="#384052">
                            //       - {subq.subquestion}: {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text>
                            //     {/* <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                            //        {subq.description
                            //         ? `${subq.value}, ${subq.description}`
                            //         : subq.value}
                            //     </Text> */}
                            //   </Box>
                            // ))
                            qna.subquestions
                              .filter((subq) => subq.value) // Show only if value is non-null and non-empty
                              .map((subq, subIdx) => (
                                <Box key={subIdx} ml={0} mt={2}>
                                  <Text
                                    fontSize="0.875rem"
                                    fontWeight="bold"
                                    color="#384052"
                                  >
                                    - {subq.subquestion}:{" "}
                                    <Text as="span" fontWeight="500">
                                      {subq.value}
                                    </Text>
                                    {subq.description && (
                                      <Text as="span" fontWeight="normal">
                                        , {subq.description}
                                      </Text>
                                    )}
                                  </Text>
                                </Box>
                              ))
                          )}
                        </Box>
                      ))}

                      {/* Divider after each heading */}
                      {index <
                        Object.keys(patientVisitFormSection5).length - 1 && (
                        <Divider size="1px" colorScheme="#E6E7E9" mt={4} />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="220px"
                  >
                    <Box textAlign="center" fontSize="16px" fontWeight="600">
                      <Image
                        w="26px"
                        opacity="0.5"
                        src="/assets/imgs/details.png"
                      />
                    </Box>
                    <Text textAlign="center" fontSize="12px" fontWeight="500">
                      No Data available
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  visitFormSection5.current.closeModal();
                }}
              />
            </>
          }
        />
      </Box>
    </>
  );
};

export default PastVisitOverview;
