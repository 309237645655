import { Box, Center, Flex, Image, Spacer, Text, VStack } from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'
import ServicesProvide from './servicesprovide'
import SetAvailability from './setavailability'
import ConfigureTimings from './configure-timings'
import Specialty from './speciality'
import ReviewProfile from './review-profile'
import { useNavigate } from "react-router-dom";



const FinalStepsIndex = () => {
  const [activeComponent, setActiveComponent] = useState('servicetype');
  const storedActiveComponent = localStorage.getItem('activeComponent'); 
  const navigate = useNavigate();
  const date = new Date();

  const [textColor, setTextColor] = useState({
    servicetype: 'white',
    workinghours: '#384052',
    slots: "#384052",
    // speciality:"#384052",
    review:"#384052",
  });
  const [circleColor, setcircleColor] = useState({
    servicetype: 'white',
    workinghours: '#151619',
    slots: "#151619",
    // speciality: "#151619",
    review: "#151619",
  });
  const [borderColor, setBorderColor] = useState({
    servicetype: '#232325',
    workinghours: '',
    slots: "",
    // speciality: "",
    review: "",
  });

  useEffect(() => {
    const storedActiveComponent = localStorage.getItem('activeComponent');
    
    if(storedActiveComponent){
      setActiveComponent(storedActiveComponent)
    
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors[storedActiveComponent] = 'white';
    
      return updatedColors;
    });
    setcircleColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#151619'; // Set default border color
      });
      updatedColors[storedActiveComponent] = 'white';
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = ''; // Set default border color
      });
      updatedColors[storedActiveComponent] = '#232325';
      return updatedColors;
    });

    setTimeout(() => {
      
    localStorage.removeItem('activeComponent');
    }, 2000);
  }else{
    
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors['personal'] = 'white';
    
      return updatedColors;
    });
  }
  }, []);

  const handleBoxClick = (component) => {
    localStorage.removeItem('activeComponent');
    setActiveComponent(component);
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors[component] = 'white';
    
      return updatedColors;
    });
    setcircleColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#151619'; // Set default border color
      });
      updatedColors[component] = 'white';
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = ''; // Set default border color
      });
      updatedColors[component] = '#232325';
      return updatedColors;
    });
  };
  const handleActiveComponentChange = (newActiveComponent) => {
    setTimeout(() => {
      
    setActiveComponent(newActiveComponent);
    setTextColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#686869';
      });
      updatedColors[newActiveComponent] = 'white';
    
      return updatedColors;
    });
    setcircleColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = '#151619'; // Set default border color
      });
      updatedColors[newActiveComponent] = 'white';
      return updatedColors;
    });
    setBorderColor((prevColors) => {
      const updatedColors = {};
      Object.keys(prevColors).forEach((key) => {
        updatedColors[key] = ''; // Set default border color
      });
      updatedColors[newActiveComponent] = '#232325';
      return updatedColors;
    });
    }, 1000);
  };
  const backFunction = ()=>{
     if(activeComponent == "servicetype"){
       navigate("/login")
     }else if(activeComponent == "workinghours"){
       handleActiveComponentChange("servicetype")
     }else if(activeComponent == "slots"){
       handleActiveComponentChange("workinghours")
     }
    //  else if(activeComponent == "speciality"){
    //    handleActiveComponentChange("slots")
    //  }
     else if(activeComponent == "review"){
      handleActiveComponentChange("slots")
    }
   }
  return (
    <>
    <Flex h="100vh" display={{base: "none", lg: "flex"}}>
      <VStack w="25%" bg="#121316" p={{base:"1rem", lg: "1.6rem", xl: "4rem 3.5rem"}} h="100vh" align="stretch">
        <Box mb="20px">
          <Image w="120px" src="/assets/imgs/midoc-logo.png" />
        </Box>
        <VStack gap={{lg: "0.5rem", xl: "1rem"}} mb="20px" align="start">
          <Text color="white" fontSize={{base: "1rem", xl: "1.25rem"}} mb="0.5rem" lineHeight="normal" fontWeight="600">
          Launch your profile with <br /> these final steps
          </Text>
         {/* Service Type */}
          <Box
            as="button"
            display="flex"
            alignItems="center"
            gap="10px"
            //bg="#232325"
            bg={borderColor.servicetype}
            borderRadius="16px"
            p="8px"
            w="100%"

            //  onClick={() => handleBoxClick('servicetype')}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #232325"
               bg={circleColor.servicetype}
              borderRadius="full"
              p="14px"
              h="18px"
              w="18px"
              color="#686869"
              fontSize="14px"
              fontWeight="600"

            >
              <Text>1</Text>
            </Box>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight="600"
              color={textColor.servicetype}
            //  color={textColor.personal} 
            >
              Service type
            </Text>
          </Box>
         {/* working hours */}
          <Box
            as="button"
            display="flex"
            alignItems="center"
            gap="10px"
            p="8px"
            w="100%"
            
            bg={borderColor.workinghours}
            borderRadius="16px"
            //  onClick={() => handleBoxClick('workinghours')}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #232325"
              borderRadius="full"
              p="14px"
              h="18px"
              w="18px"
              color="#686869"
             //bg="#151619"
              
               bg={circleColor.workinghours}
              fontSize="14px"
              fontWeight="600"
            >
              <Text>2</Text>
            </Box>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight="600"
             //color="#686869"
             color={textColor.workinghours} 
            >
              Working hours
            </Text>
          </Box>
         {/* slot setup */}
          <Box
            as="button"
            display="flex"
            alignItems="center"
            gap="10px"
            p="8px"
            w="100%"
            
             bg={borderColor.slots}
            borderRadius="16px"
            //  onClick={() => handleBoxClick('slots')}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #232325"
              borderRadius="full"
              p="14px"
              h="18px"
              w="18px"
              color="#686869"
             //bg="#151619"
             
              bg={circleColor.slots}
              fontSize="14px"
              fontWeight="600"
            >
              <Text>3</Text>
            </Box>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight="600"
             //color="#686869"
             
              color={textColor.slots} 
            >
              Slot setup
            </Text>
          </Box>
         {/* Speciality */}
          {/* <Box
            as="button"
            display="flex"
            alignItems="center"
            gap="10px"
            p="8px"
            w="100%"
            
            // bg={borderColor.subscription}
            borderRadius="16px"
            //  onClick={() => handleBoxClick('speciality')}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #232325"
              borderRadius="full"
              p="14px"
              h="18px"
              w="18px"
              color="#686869"
              //bg="#151619"
              
               bg={circleColor.speciality}
              fontSize="14px"
              fontWeight="600"
            >
              <Text>4</Text>
            </Box>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight="600"
             //color="#686869"
             
              color={textColor.speciality} 
            >
              Specialty & diagnose
            </Text>
          </Box> */}
          {/* Review */}
          <Box
            as="button"
            display="flex"
            alignItems="center"
            gap="10px"
            p="8px"
            w="100%"
            
             bg={borderColor.review}
            borderRadius="16px"
            //  onClick={() => handleBoxClick('review')}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px solid #232325"
              borderRadius="full"
              p="14px"
              h="18px"
              w="18px"
              color="#686869"
              //bg="#151619"
              
               bg={circleColor.review}
              fontSize="14px"
              fontWeight="600"
            >
              <Text>5</Text>
            </Box>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight="600"
             //color="#686869"
             
              color={textColor.review} 
            >
              Review
            </Text>
          </Box>
        </VStack>
        <Spacer />
        <Box as="p" fontSize="12px" color="#898D97">
          © {date.getFullYear()} midoc, Inc.
        </Box>
      </VStack>

      <Box
        w="75%"
        bg="#FCFCFD"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <Box w="100%" pos="relative" top="5%" left="3%">
          <Box
            as="button"
            bg="white"
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="10px 16px"
            border="1px solid #E6E7E9"
            borderRadius="full"
            w="64px"
            boxShadow="0px 8px 20px 0px #E6E7E966"
             onClick={backFunction}
          >
            <Image w="20px" src="/assets/imgs/back-arrow.png"  />
          </Box>
        </Box>

        {/* Main container Start */}
        <Flex h="100vh" flexDir="column" w="100%" overflow={activeComponent =="speciality" || activeComponent == "servicetype" ? "hidden auto" : "hidden"}>
          
        {activeComponent === 'servicetype' && <ServicesProvide onSubmit={handleActiveComponentChange}/>}
        {activeComponent === 'workinghours' && <SetAvailability onSubmit={handleActiveComponentChange}/>}
        {activeComponent === 'slots' && <ConfigureTimings onSubmit={handleActiveComponentChange}/>}
        {/* {activeComponent === 'speciality' && <Specialty onSubmit={handleActiveComponentChange}/>} */}
        {activeComponent === 'review' && <ReviewProfile onSubmit={handleActiveComponentChange}/>}

          {/* <SetAvailability /> */}

          {/* <ConfigureTimings /> */}

          {/* <Specialty /> */}

          {/* <ReviewProfilse /> */}
        </Flex>
        {/* Main container end */}
      </Box>
    </Flex>

    {/* Mobile responsive start */}
    <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
    <Box
        w="100%"
        bg="#FCFCFD"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <Box w="100%" pos="relative" my="1rem" ml="1rem" top="5%" left="3%">
          <Box
            as="button"
            bg="white"
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="10px 16px"
            border="1px solid #E6E7E9"
            borderRadius="full"
            w="64px"
            boxShadow="0px 8px 20px 0px #E6E7E966"
             onClick={backFunction}
          >
            <Image w="20px" src="/assets/imgs/back-arrow.png"  />
          </Box>
        </Box>

        {/* Main container Start */}
        <Flex h="calc(100vh - 130px)" flexDir="column" justifyContent="center" w="100%" overflow={activeComponent =="speciality" || activeComponent == "servicetype" ? "hidden auto" : "hidden"}>
          
        {activeComponent === 'servicetype' && <ServicesProvide onSubmit={handleActiveComponentChange}/>}
        {activeComponent === 'workinghours' && <SetAvailability onSubmit={handleActiveComponentChange}/>}
        {activeComponent === 'slots' && <ConfigureTimings onSubmit={handleActiveComponentChange}/>}
        {activeComponent === 'speciality' && <Specialty onSubmit={handleActiveComponentChange}/>}
        {activeComponent === 'review' && <ReviewProfile onSubmit={handleActiveComponentChange}/>}

          {/* <SetAvailability /> */}

          {/* <ConfigureTimings /> */}

          {/* <Specialty /> */}

          {/* <ReviewProfilse /> */}
        </Flex>
        {/* Main container end */}
      </Box>
    </Flex>
  </>
  )
}

export default FinalStepsIndex