import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Checkbox,
  CheckboxGroup,
  Stack,
  Heading,
  Textarea,
  IconButton,
  Image,
  InputGroup,
  Icon,
  VStack,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  Divider,
  Spacer,
} from "@chakra-ui/react";
import {
  addMedicalConditions,
  addSurgeries,
  addVaccination,
  addAllergies,
} from "../../../../middleware/services/messages";

import {
  GenerateDoctorNotesFromTranscripts,
  GenerateDoctorChatsFromTranscripts,
  GenerateDoctorPatientHistoryNotesFromTranscripts,
  ExtractAiRecommendMedicines,
  ExtractAiRecommendROS,
  ExtractAiRecommendOrders,
  ExtractAiPatientDataForAiraNotes,
  FetchMyHealthAIData,
} from "../../../../middleware/services/visits/aicalls";
import {
  GetAIMedicineComposition,
  GetDoctorMasterData,
  updateDoctorNotes,
  updateAIRAChatNotes,
  GetDoctorNotes,
} from "../../../../middleware/services/visits/patient-overview/updatedoctornotes";
import AIPhysicalExamCard from "./notes-cards/physical-exam-card";
import AiROSCard from "./notes-cards/ros-card";
import AiComplaintInputCard from "./notes-cards/complaint-card";
import AINotesAssessment from "./notes-cards/assessment-plans";
import AIHpiNotesCard from "./notes-cards/hpi-notes-card";
import useLoader from "../../../loader/loaderstates";
import Loader from "../../../loader/loader";
import AIFeedbackFooter from "../ai-feedback";
import AICPUFeedbackFooter from "../ai-feedback/cpu-feedback";
import ChatDisplay from "./chats-view";

import { CopyIcon } from "@chakra-ui/icons"; // Or import your custom icon if required
import { FaRegSave } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import { CgRemove } from "react-icons/cg";
import { COLORS } from "../../../styles/colors";
import PrimaryButton from "../../../primary-button";
import moment from "moment";
import DatePicker from "../../../date-picker";
import PrimaryInput from "../../../primary-input";
import DynamicModalForTwo from "../dynamic-modal-for-two";
import MiniDropdownInput from "../../../mini-dropdown";
import DropdownInput from "../../../dropdown-input";
import PrimaryAssessmentInput from "../assessment-input";
import AIRAVitalsForm from "./notes-cards/vital-card";
import { GetPatientHealthData } from "../../../../middleware/services/visits/patient-overview";
import { getProfile } from "../../../../middleware/services/profile";
import PrimaryButtonIcon from "../../../primary-button-icon";

const TranscriptNotesCard = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  transcriptionText,
  transcriptionArray,
}) => {
  const useGPUChatbot = !!navigator.gpu;
  // const useGPUChatbot = false;
  // console.log("Used transcription", transcriptionText);

  //------------------------------Doctor Profile

  const [visitDate, setVisitDate] = useState("");
  const [visitTime, setVisitTime] = useState("");

  const fetchDoctorNotesData = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);

      const doctorsassessment = data?.output?.data?.doctorsassessment || [];
      console.log(doctorsassessment);
      const visitdate = moment(
        data?.output?.data?.info?.appointmentdate
      ).format("Do MMM, YYYY");
      const formattedTime = moment(
        data?.output?.data?.info?.appointment_time,
        "HH:mm"
      ).format("hh:mm A");
      setVisitDate(visitdate);
      setVisitTime(formattedTime);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const [shareChatSummary, setShareChatSummary] = useState(true);
  const [shareChatHistory, setShareChatHistory] = useState(true);
  const [shareDoctorNotes, setShareDoctorNotes] = useState(true);
  const [sharePatientHistory, setSharePatientHistory] = useState(true);

  const [shareReportDoctorEmail, setShareReportDoctorEmail] = useState("");
  useEffect(() => {
    getDoctorProfile();
    fetchDoctorNotesData();
  }, []);

  const getDoctorProfile = async () => {
    showLoader();
    try {
      var result = await getProfile({});
      if (result && result.ProfileOutput) {
        setShareReportDoctorEmail(result.ProfileOutput?.email);
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile");
    } finally {
      dismissLoader();
    }
  };

  const shareNotesOnEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(shareReportDoctorEmail)) {
      showErrorToast("Invalid email address. Please enter a valid email.");
      return;
    }
    const inputToMail = {
      doctorid: doctorId,
      appointmentid: appointmentId,
      doctormail: shareReportDoctorEmail,
      patient_info: {
        name:
          patientHealthData?.patientinfo?.firstname +
          " " +
          patientHealthData?.patientinfo?.lastname,
        bmi: navHeaderOverviewData,
        appointementdate: visitDate,
        appointmenttime: visitTime,
      },
      patient_history: sharePatientHistory
        ? {
            medicalconditions: aiPatientMedicalConditions,
            medications: aiPatientMedications,
            surgeries: aiPatientSurgeries,
            allergies: aiPatientAllergies,
            vaccinations: aiPatientVaccinations,
            socialhistory: aiPatientSocialHistory,
          }
        : null,
      doctor_notes: shareDoctorNotes
        ? {
            chiefcomplaint: aiCc,
            hpi: aiHpiText,
            ros: aiRosText,
            physicalexam: aiPhysicalExamText,
            assessment_plan: aiAssessmentPlanArray,
            medicine: aiPrescribedMedicinesText,
            labs: aiPrescribedLabsText,
            imaging: aiPrescribedImagingText,
            procedures: aiPrescribedProceduresText,
            vitals: aiPatientVitals,
          }
        : null,
      chat_summary: shareChatSummary ? aiTranscriptSummary : null,
      chats: shareChatHistory ? aiTranscriptChats : null,
    };
    showLoader();
    try {
      // var result = await getProfile(inputToMail);
      console.log(inputToMail);
      var result = "";
      if (result) {
        showSuccessToast("Email sent!");
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile");
    } finally {
      dismissLoader();
    }
  };

  //------------------------------Patient BMI

  const [patientHealthData, setPatientHealthData] = useState(null);
  const [navHeaderOverviewData, setNavHeaderOverviewData] = useState(null);
  let overviewInfo = "";
  const [inputData, setInputData] = useState({
    patientid: patientId,
    // appointmentid: appointmentId,
    doctorid: doctorId,
  });
  const fetchPatientHealthData = async () => {
    showLoader();
    try {
      const data = await GetPatientHealthData(inputData);
      // console.log(data);
      setPatientHealthData(data);
      if (data) {
        const {
          gender,
          dob,
          bloodtype,
          countrycode,
          mobileno,
          height,
          heightunit,
          weight,
          weightunit,
          bmi,
        } = data.patientinfo;

        // console.log(patientHealthData.patientinfo)
        // console.log(patientHealthData)

        const formattedGender = gender
          ? gender.charAt(0).toUpperCase() + gender.slice(1)
          : null;
        const dobInYears = dob ? calculateAge(dob) : null;
        const formattedBloodType = bloodtype || null;
        const formattedCountryCode = countrycode || null;
        const formattedMobileNo = mobileno || null;
        const formattedHeight =
          height && heightunit ? `${height} ${heightunit}` : null;
        const formattedWeight =
          weight && weightunit ? `${weight} ${weightunit}` : null;
        const formattedBMI = bmi || null;

        const overviewData = [
          formattedGender,
          dobInYears ? `${dobInYears} years` : null,
          formattedBloodType,
          formattedCountryCode && formattedMobileNo
            ? `(${formattedCountryCode}) ${formattedMobileNo}`
            : null,
          formattedHeight,
          formattedWeight,
          formattedBMI ? `${formattedBMI} BMI` : null,
        ]
          .filter((item) => item !== null)
          .join(", ");

        setNavHeaderOverviewData(overviewData);
        // overviewInfo =overviewData
        // console.log(overviewData)
        // console.log(overviewInfo)
      }
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  /// Call fetchPatientHealthData function whenever inputData or formattedInput changes
  useEffect(() => {
    fetchPatientHealthData();
  }, [inputData]);

  function calculateAge(dob) {
    if (!dob) {
      return "";
    }

    const dobDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  //------------------------------Saving mechanism
  const UpdateChiefComplaint = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        chiefcomplaint: data,
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("Chief Complaint Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const UpdateHpiTextBox = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        hpitext: {
          description: data,
          type: "other",
        },
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("HPI Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const UpdatePhysicalExamTextBox = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        physicalexamtext: {
          description: data,
          type: "other",
        },
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("Physical Exam Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  function transformOrdersAIArray(type, inputArray) {
    if (!Array.isArray(inputArray)) {
      throw new Error("The second argument must be an array.");
    }

    return inputArray.map((item) => {
      if (!item.name) {
        throw new Error(
          "Each object in the array must have a 'name' property."
        );
      }

      return {
        id: null,
        entitytype: type,
        ordername: item.name,
      };
    });
  }

  const AddToOrder = async (type, list) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        [type]: transformOrdersAIArray(type, list),
      },
    };
    console.log(inputToSave);
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      console.log(result);
      if (result) {
        showSuccessToast(`${type} Saved successfully.`);
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  const fetchExtractRecommendedOrders = async (data, type) => {
    showLoader();
    const input = {
      orders_text: data,
      order_type: type,
    };
    const response = await ExtractAiRecommendOrders(input);
    console.log("extract order response", response);
    if (response && response?.orders && response?.orders?.length > 0) {
      await AddToOrder(type, response?.orders);
    }
    dismissLoader();
  };

  const aiRecommendMedicineRef = useRef();

  const [editMedicine, setEditMedicine] = useState(null);

  const [recommendMedicinesAiResponse, setRecommendMedicinesAiResponse] =
    useState([]);

  const [
    selectedRecommendedMedicinesAiResponses,
    setSelectedRecommendedMedicinesAiResponses,
  ] = useState([]);

  const today = moment().format("DD-MM-YYYY");
  const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");
  const handleEditMedicine = (medicine) => {
    // const medicinetoedit = {
    //   id: medicine.id,
    //   medicinename: medicine.name,
    //   composition: medicine.composition,
    //   medicinetype: "Tablet",
    //   dosage: "",
    //   dosageunit: "mg",
    //   medicationstartdate: today, // SETTING DEFAULTS
    //   medicationenddate: fiveDaysFromToday, // SETTING DEFAULTS
    //   frequency: 1, // SETTING DEFAULTS
    //   morning: 1, // SETTING DEFAULTS
    //   evening: 0,
    //   night: 0,
    //   prescribedby: "",
    //   hospitalname: "",
    //   beforefood: 1, // SETTING DEFAULTS
    //   afterfood: 0,
    //   // issaved: false,
    // };
    setEditMedicine(medicine);
  };

  const handleEditMedicineChange = (field, value) => {
    setEditMedicine((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveEditMedicine = () => {
    if (editMedicine.medicinename === "") {
      showErrorToast("Please enter medicine name to continue");
      return;
    }
    setRecommendMedicinesAiResponse((prev) =>
      prev.map((m) => (m.id === editMedicine.id ? editMedicine : m))
    );

    // console.log(selectedMedicines);
    setEditMedicine(null);
  };

  const [compositionEditKey, setCompositionEditKey] = useState(0); // to rerender the composition input box

  const fetchCompositionForEdit = async (medicineName) => {
    if (!medicineName) {
      showErrorToast("Please enter medicine name first to fetch composition");
    } else {
      const inputData = {
        input: {
          name: medicineName,
        },
      };

      // console.log(inputData.input);

      try {
        // showLoader();
        // console.log(inputData);
        const result = await GetAIMedicineComposition(inputData.input);
        // console.log(result);
        if (result) {
          showSuccessToast("Fetched Medicine composition successfully");
          handleEditMedicineChange("composition", result.chemical_composition);
          setCompositionEditKey((prevKey) => prevKey + 1);
          // handleNewMedicineChange("medicinetype", result.type);
        }
      } catch (error) {
        console.error("Error Fetching Medicine composition:", error);
        showErrorToast("An error occurred while Fetching Medicine composition");
      } finally {
        // dismissLoader();
      }

      // fetchDoctorNotes();

      // console.log("Clicked");
    }
  };

  const fetchExtractRecommendedMedicines = async (data) => {
    showLoader();
    const input = {
      medicine_text: data,
    };
    const response = await ExtractAiRecommendMedicines(input);
    console.log("extract medicine response", response);
    if (response && response?.medicines && response?.medicines?.length > 0) {
      // Adding defaults to ai response

      const formattedMedicines = response?.medicines?.map(
        (medicine, index) => ({
          id: index, // Setting the id as the index of the object
          medicinename: medicine.name,
          composition: medicine.composition,
          medicinetype: "Tablet",
          dosage: "",
          dosageunit: "mg",
          medicationstartdate: today, // Default to today
          medicationenddate: fiveDaysFromToday, // Default to 5 days from today
          frequency: 1, // Default value
          morning: 1, // Default value
          evening: 0, // Default value
          night: 0, // Default value
          prescribedby: "",
          hospitalname: "",
          beforefood: 1, // Default value
          afterfood: 0, // Default value
          from: medicine?.source,
          reason: medicine?.reason,
          // issaved: false, // Default value
        })
      );

      setRecommendMedicinesAiResponse(formattedMedicines);

      // setRecommendMedicinesAiResponse(response?.recommended_medicines);
      setSelectedRecommendedMedicinesAiResponses([]);

      setEditMedicine(null);
      aiRecommendMedicineRef.current.openModal();
    }

    dismissLoader();
  };

  // Toggle selection of AI responses
  const handleRecommendedMedicinesCheckboxChange = (index) => {
    setSelectedRecommendedMedicinesAiResponses((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  const formatDate = (date) => {
    // Check if the date is already in YYYY-MM-DD format
    if (moment(date, "YYYY-MM-DD", true).isValid()) {
      return date;
    }
    // If not, assume it's in DD-MM-YYYY format and convert it to YYYY-MM-DD
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  };

  const saveSelectedRecommendedMedicinesToOrder = async () => {
    // console.log(selectedRecommendedMedicinesAiResponses); // list of index

    // fetch medicine stored at those index
    const medicinesSelected = selectedRecommendedMedicinesAiResponses.map(
      (index) => recommendMedicinesAiResponse[index]
    );

    // console.log(medicinesSelected);

    const recommendedMedicinesToSave = medicinesSelected.map(
      (medicine, index) => ({
        id: null,
        isactive: 1,
        medicinename: medicine.medicinename,
        chemical_composition: medicine.composition,
        medicinetype: medicine.medicinetype,
        dosage: medicine.dosage,
        dosageunit: medicine.dosageunit,
        // startdate: moment(medicine.medicationstartdate).format("YYYY-MM-DD"),
        // enddate: moment(medicine.medicationenddate).format("YYYY-MM-DD"),
        startdate: formatDate(medicine.medicationstartdate),
        enddate: formatDate(medicine.medicationenddate),
        frequency: medicine.frequency,
        morning: medicine.morning,
        evening: medicine.evening,
        night: medicine.night,
        // prescribedby: "",
        // hospitalname: "",
        beforefood: medicine.beforefood,
        afterfood: medicine.afterfood,
      })
    );

    const inputData = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
        // medicinedetail: recommendedMedicinesToSave,
        ...(recommendedMedicinesToSave.length > 0 && {
          medicinedetail: recommendedMedicinesToSave,
        }),
      },
    };

    // console.log(inputData);
    try {
      showLoader();
      const result = await updateDoctorNotes(inputData.input);
      // console.log(result);
      if (result) {
        showSuccessToast("Selected items saved to orders successfully");
      }
    } catch (error) {
      console.error("Error adding medicine to orders:", error);
      showErrorToast("An error occurred while adding medicine to orders");
    } finally {
      dismissLoader();
    }
  };

  const SaveIndividualAssessmentPlan = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        doctorsassessment: [
          {
            diagnosis: data.diagnosis,
            plan: data.plan,
            diagnosisid: null, // Use saved ID if it exists
          },
        ],
      },
    };
    showLoader();

    try {
      console.log("input send", inputToSave.input);
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("Assessments Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const [aiRosResponse, setAiRosResponse] = useState({});

  const aiROSReviewRef = useRef();

  const fetchExtractRos = async (data) => {
    showLoader();
    const input = {
      ros_text: data,
      ros: inputROSMasterData,
    };
    const response = await ExtractAiRecommendROS(input);
    console.log("extract order response", response);
    if (response && response?.ros) {
      setAiRosResponse(response);
      aiROSReviewRef.current.openModal();
    }
    dismissLoader();
  };

  const UpdateROSTextBox = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        rostext: {
          description: data,
          type: "other",
        },
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("ROS Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  //------------------------------Save Patient Data

  const [patientPastData, setPatientPastData] = useState(null);

  useEffect(() => {
    const fetchHealthData = async () => {
      try {
        const result = await FetchMyHealthAIData(
          {},
          patientId,
          "unavailable", // Setting patientname to "unavailable"
          "unavailable", // Setting patientgender to "unavailable"
          "unavailable" // Setting patientage to "unavailable"
        );
        setPatientPastData(result);
      } catch (error) {
        console.error("Error fetching health data:", error);
      }
    };

    fetchHealthData();
  }, [patientId]);

  function transformMedicalConditionsArray(inputArray) {
    return inputArray.map((item) => ({
      conditiontype_cd: null,
      condition_cd: null,
      nomenclatureid: null,
      conditionidentifieddate: item.date,
      condition: item.name,
      conditiontype: "other",
    }));
  }

  const addMedicalCondition = async (input_text) => {
    showLoader();

    const input = {
      type: "medical_condition",
      text: input_text,
      patient_data: patientPastData,
    };
    const response = await ExtractAiPatientDataForAiraNotes(input);
    const inputForAdd = {
      patientid: patientId,
      doctorid: doctorId,
      medicalcondition: transformMedicalConditionsArray(
        response?.medical_conditions ? response?.medical_conditions : []
      ),
    };
    try {
      const result = await addMedicalConditions(inputForAdd);
      if (result.output.result == "success") {
        showSuccessToast("Successfully Added Condition");
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in Condition:", error);
    }
    dismissLoader();
  };

  function transformProceduresArray(inputArray) {
    return inputArray.map((item) => ({
      proceduredate: item.date,
      proceduretype_cd: "1",
      procedure_cd: null,
      proceduretitle: item.name,
      nomenclatureid: null,
      hospitalid: "1",
      hospitalname: "",
      surgeonname: "",
      documenttype_cd: "",
      documentcategory_cd: "",
      documentname: "",
      documentsize: "",
      documenturl: "www.google.com",
      documents: [],
      proceduretype: "other",
    }));
  }

  const addProcedures = async (input_text) => {
    showLoader();

    const input = {
      type: "surgery",
      text: input_text,
      patient_data: patientPastData,
    };
    const response = await ExtractAiPatientDataForAiraNotes(input);
    const inputForAdd = {
      patientid: patientId,
      doctorid: doctorId,
      procedures: transformProceduresArray(
        response?.surgeries ? response?.surgeries : []
      ),
    };
    try {
      const result = await addSurgeries(inputForAdd);
      if (result.output.result == "Successfully Inserted") {
        showSuccessToast("Successfully Added Procedure");
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in Procedure:", error);
    }
    dismissLoader();
  };

  function transformToAllergyArray(inputArray) {
    return inputArray.map((item) => ({
      condition: item.name,
      condition_cd: null,
      allergytype_cd: null,
      allergyidentifieddate: item.date,
      conditiontype: "other",
      reactions: [
        {
          reaction_cd: null,
          reaction: "No reactions",
        },
      ],
      documents: [],
    }));
  }

  const addAllergicConditions = async (input_text) => {
    showLoader();

    const input = {
      type: "allergy",
      text: input_text,
      patient_data: patientPastData,
    };
    const response = await ExtractAiPatientDataForAiraNotes(input);
    const inputForAdd = {
      patientid: patientId,
      doctorid: doctorId,
      allergy: transformToAllergyArray(
        response?.allergies ? response?.allergies : []
      ),
    };
    try {
      const result = await addAllergies(inputForAdd);
      if (result.output.result == "success") {
        showSuccessToast("Successfully Added Allergies");
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in allergies:", error);
    }
    dismissLoader();
  };

  function transformToVaccineArray(inputArray) {
    return inputArray.map((item) => ({
      vaccine_cd: null,
      vaccinename: item.name,
      admindate: item.date,
      duedate: null,
    }));
  }

  const addVaccinations = async (input_text) => {
    showLoader();

    const input = {
      type: "vaccination",
      text: input_text,
      patient_data: patientPastData,
    };
    const response = await ExtractAiPatientDataForAiraNotes(input);
    const inputForAdd = {
      patientid: patientId,
      doctorid: doctorId,
      vaccination: transformToVaccineArray(
        response?.vaccinations ? response?.vaccinations : []
      ),
    };
    try {
      const result = await addVaccination(inputForAdd);
      if (result.output.result == "success") {
        showSuccessToast("Successfully Added Vaccination");
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in Vaccination:", error);
    }
    dismissLoader();
  };

  function transformVitals(inputArray) {
    // Map of vital names to their corresponding type code and unit
    const vitalMap = {
      "Body Temperature": { vitaltype_cd: 232, vitalunit: "°C" },
      "Respiratory Rate": { vitaltype_cd: 235, vitalunit: "bpm" },
      Pulse: { vitaltype_cd: 233, vitalunit: "bpm" },
      "Systolic Blood Pressure": { vitaltype_cd: 234, vitalunit: "mmHg" },
      "Diastolic Blood Pressure": { vitaltype_cd: 234, vitalunit: "mmHg" },
      "Oxygen Saturation": { vitaltype_cd: 236, vitalunit: "%" },
      "Fasting Blood Glucose": { vitaltype_cd: 0, vitalunit: "mg/dL" },
      "Postprandial Blood Glucose": { vitaltype_cd: 0, vitalunit: "mg/dL" },
    };

    // Current timestamp
    const currentTimestamp = new Date().toISOString();

    // Check if input is empty
    if (!inputArray || inputArray.length === 0) {
      return null;
    }

    // Transform the input array
    return inputArray
      .filter((item) => item.name && item.value)
      .map((item) => {
        const vitalInfo = vitalMap[item.name] || {};

        // Adjust temperature unit based on value
        let vitalunit = vitalInfo.vitalunit;
        if (item.name === "Body Temperature" && item.value) {
          const tempValue = parseFloat(item.value);
          if (!isNaN(tempValue)) {
            vitalunit = tempValue < 60 ? "°C" : "°F";
          }
        }

        return {
          vitalname: item.name,
          vitalvalue: item.value,
          vitalunit: vitalunit || null,
          vitaltype_cd: vitalInfo.vitaltype_cd || null,
          vitaladdeddate: currentTimestamp,
          createdby: null,
        };
      });
  }

  const aiVitalsReviewRef = useRef();
  const [aiVitalsResponse, setAiVitalsResponse] = useState({});

  const addVitals = async (input_text) => {
    showLoader();

    const input = {
      type: "vitals",
      text: input_text,
      patient_data: patientPastData,
    };
    const response = await ExtractAiPatientDataForAiraNotes(input);
    console.log(response);

    try {
      if (response) {
        setAiVitalsResponse(response);
        aiVitalsReviewRef.current.openModal();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in Vitals:", error);
    }
    dismissLoader();
  };

  //------------------------------AI Feedback

  const [aiInputForFeedback, setAiInputForFeedback] = useState({});
  const [aiOutputForFeedback, setAiOutputForFeedback] = useState({});

  //--------------------------------
  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [inputHPIMasterData, setInputHPIMasterData] = useState([]);
  const [inputROSMasterData, setInputROSMasterData] = useState([]);
  const [inputPhysicalExamMasterData, setInputPhysicalExamMasterData] =
    useState([]);
  const [aiResponse, setAiResponse] = useState(null);

  // Helper function to format time
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60); // Remove decimals
    return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  };

  const fetchMasterData = async (category) => {
    showLoader();
    try {
      const inputData = { input: { type: category } };
      const data = await GetDoctorMasterData(inputData.input);
      return data;
    } catch (error) {
      console.error("Error fetching Master data:", error);
      return [];
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all master data in parallel
        const [hpiData, rosData, physicalexamData] = await Promise.all([
          fetchMasterData("hpi"),
          fetchMasterData("ros"),
          fetchMasterData("physicalexam"),
        ]);

        // Update state with fetched data
        setInputHPIMasterData(hpiData);
        setInputROSMasterData(rosData);
        setInputPhysicalExamMasterData(physicalexamData);

        // Call fetchAiraGeneratedNotes once all data is fetched
        fetchAiraGeneratedNotes(hpiData, rosData, physicalexamData);
        fetchChatsAndSummary();
        fetchPatientHistoryData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [aiCc, setAiCc] = useState("");
  const [aiHpiText, setAiHpiText] = useState("");
  const [aiRosText, setAiRosText] = useState("");
  const [aiPhysicalExamText, setAiPhysicalExamText] = useState("");
  const [aiPrescribedMedicinesText, setAiPrescribedMedicinesText] =
    useState("");
  const [aiPrescribedProceduresText, setAiPrescribedProceduresText] =
    useState("");
  const [aiPrescribedLabsText, setAiPrescribedLabsText] = useState("");
  const [aiPrescribedImagingText, setAiPrescribedImagingText] = useState("");
  const [aiAssessmentPlanArray, setAiAssessmentPlanArray] = useState([]);

  function transformDiagnosisPlan(data) {
    return data.map((item) => ({
      merged_diagnosis: `${item.diagnosis}: ${item.plan}`,
      diagnosis: item.diagnosis,
      plan: item.plan,
      old_diagnosis: item.diagnosis,
      old_plan: item.plan,
    }));
  }

  const fetchAiraGeneratedNotes = async (
    hpiData,
    rosData,
    physicalexamData
  ) => {
    const inputToFetch = {
      input: {
        hpi: hpiData,
        ros: rosData,
        physicalexam: physicalexamData,
        transcription: transcriptionText,
      },
    };
    showLoader();
    // console.log(inputToFetch);
    setAiInputForFeedback(inputToFetch);
    try {
      const data = await GenerateDoctorNotesFromTranscripts(inputToFetch.input);
      setAiResponse(data);
      setAiCc(data?.chiefcomplaint);
      setAiHpiText(data?.hpi_text);
      setAiRosText(data?.ros_text);
      setAiPhysicalExamText(data?.physical_exam_text);
      setAiPrescribedMedicinesText(data?.prescribed_medicines);
      setAiPrescribedProceduresText(data?.prescribed_procedures);
      setAiPrescribedLabsText(data?.prescribed_labs);
      setAiPrescribedImagingText(data?.prescribed_imaging);
      setAiAssessmentPlanArray(transformDiagnosisPlan(data?.assessment_plan));
      setAiOutputForFeedback(data);
      // console.log(data);
    } catch (error) {
      console.error("Error fetching AIRA Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const [aiTranscriptChats, setAiTranscriptChats] = useState([]);

  const [aiTranscriptSummary, setAiTranscriptSummary] = useState("");

  //--------------saving chats to db

  function transformConversation(input) {
    if (!Array.isArray(input)) {
      throw new Error("Input must be an array.");
    }

    return input.map((item) => {
      if (typeof item !== "object" || item === null) {
        throw new Error("Each item in the array must be a non-null object.");
      }

      const [usertype, message] = Object.entries(item)[0] || [];
      if (!usertype || !message) {
        throw new Error(
          "Invalid object structure. Each object must have a single key-value pair."
        );
      }

      return {
        usertype,
        message,
      };
    });
  }

  const saveChatsAndSummary = async (aichats) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        chats: transformConversation(aichats),
      },
    };
    showLoader();
    console.log(inputToSave);
    try {
      const result = await updateAIRAChatNotes(inputToSave.input);
      console.log(result);
    } catch (error) {
      console.error("Error saving AIRA Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  //----------------ai chat call

  const fetchChatsAndSummary = async () => {
    const inputToFetch = {
      input: {
        transcription: transcriptionText,
      },
    };
    showLoader();
    // console.log(inputToFetch);
    try {
      const data = await GenerateDoctorChatsFromTranscripts(inputToFetch.input);
      setAiTranscriptChats(data?.chats);
      setAiTranscriptSummary(data?.summary);
      const result = await saveChatsAndSummary(data?.chats);
      // console.log(data);
    } catch (error) {
      console.error("Error fetching AIRA Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const [aiPatientMedications, setAiPatientMedications] = useState("");
  const [aiPatientSurgeries, setAiPatientSurgeries] = useState("");
  const [aiPatientAllergies, setAiPatientAllergies] = useState("");
  const [aiPatientMedicalConditions, setAiPatientMedicalConditions] =
    useState("");
  const [aiPatientSocialHistory, setAiPatientSocialHistory] = useState("");
  const [aiPatientVaccinations, setAiPatientVaccinations] = useState("");
  const [aiPatientVitals, setAiPatientVitals] = useState("");
  const [aiPatientResponse, setAiPatientResponse] = useState(null);

  const fetchPatientHistoryData = async () => {
    const inputToFetch = {
      input: {
        transcription: transcriptionText,
      },
    };
    showLoader();
    // console.log(inputToFetch);
    try {
      const data = await GenerateDoctorPatientHistoryNotesFromTranscripts(
        inputToFetch.input
      );
      setAiPatientResponse(data);
      setAiPatientMedications(data?.patient_medications);
      setAiPatientSurgeries(data?.patient_surgeries);
      setAiPatientAllergies(data?.patient_allergies);
      setAiPatientMedicalConditions(data?.patient_medical_conditions);
      setAiPatientSocialHistory(data?.patient_social_history);
      setAiPatientVaccinations(data?.patient_vaccinations);
      setAiPatientVitals(data?.patient_vitals);
      // console.log(data);
    } catch (error) {
      console.error("Error fetching AIRA Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const [selectedTab, setSelectedTab] = useState("doctor");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    // <>
    //   {isLoading && (
    //     <Box
    //       position="absolute"
    //       top="0"
    //       left="0"
    //       width="100%"
    //       height="100%"
    //       display="flex"
    //       alignItems="center"
    //       justifyContent="center"
    //       zIndex="10"
    //       bg="rgba(255, 255, 255, 0.8)"
    //     >
    //       <Loader miniLoader={true} />
    //     </Box>
    //   )}
    //   {/* DISPLAY TRANSCRIPTION CODE  */}
    //   {aiResponse && (
    //     <Box display="flex" justifyContent="center" alignItems="center">
    //       <Text
    //         textTransform="uppercase"
    //         textAlign="center"
    //         fontSize="2xl"
    //         fontWeight="bold"
    //         // color="teal.500"
    //       >
    //         Conversation History
    //       </Text>
    //     </Box>
    //   )}
    //   {aiResponse && (
    //     <Box
    //       mx="auto"
    //       mt={"1rem"}
    //       width="75%"
    //       h="240px"
    //       overflowY="auto"
    //       border="1px"
    //       borderRadius="lg"
    //       p={2}
    //     >
    //       {/* {transcriptionArray.map((chunk, index) => (
    //         <Box
    //           key={index}
    //           w="full"
    //           bg="white"
    //           // boxShadow="md"
    //           borderRadius="lg"
    //           p={4}
    //           mb={2}
    //           // Apply neon blue border only to the last card
    //           border={
    //             index === transcriptionArray.length - 1
    //               ? "2px solid #00FFFF"
    //               : "1px"
    //           }
    //           borderColor={
    //             index === transcriptionArray.length - 1 ? "#00FFFF" : "gray.200"
    //           }
    //           boxShadow={
    //             index === transcriptionArray.length - 1
    //               ? "0 0 8px #00FFFF"
    //               : "md"
    //           } // Neon glow
    //         >
    //           <Flex>
    //             {chunk.timestamp && chunk.timestamp.length === 2 ? (
    //               <Text fontWeight="bold" color="gray.500" w="150px">
    //                 {formatTime(chunk.timestamp[0])} -{" "}
    //                 {formatTime(chunk.timestamp[1])}
    //               </Text>
    //             ) : (
    //               <Text fontWeight="bold" color="gray.500" w="120px">
    //                 No timestamp
    //               </Text>
    //             )}
    //             <Text
    //               ml={4}
    //               textAlign="left"
    //               whiteSpace="normal" // Allows wrapping of long text
    //               wordBreak="break-word" // Breaks words if necessary
    //             >
    //               {chunk.text || "No text available"}
    //             </Text>
    //           </Flex>
    //         </Box>
    //       ))} */}
    //       <Text
    //         textAlign="left"
    //         fontSize="16px"
    //         whiteSpace="normal"
    //         wordBreak="break-word"
    //       >
    //         {transcriptionText}
    //       </Text>
    //     </Box>
    //   )}

    //   <ChatDisplay chatMessages={mychats} />

    //   {aiResponse && (
    //     <Box
    //       mx="auto"
    //       borderBottom="8px solid"
    //       borderColor="gray.500"
    //       width="75%"
    //       mt="2rem"
    //     />
    //   )}

    //   {/* AIRA AI NOTES CODE  */}

    //   {/* <div>Notes</div> */}
    //   {aiResponse && (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       mt="1rem"
    //     >
    //       <Text
    //         textTransform="uppercase"
    //         textAlign="center"
    //         fontSize="2xl"
    //         fontWeight="bold"
    //         // color="teal.500"
    //       >
    //         Chief Complaint
    //       </Text>
    //     </Box>
    //   )}
    //   <Box width={"75%"} mx="auto" mt="1rem">
    //     {aiResponse && aiResponse?.chiefcomplaint && (
    //       <AiComplaintInputCard
    //         patientId={patientId}
    //         doctorId={doctorId}
    //         appointmentId={appointmentId}
    //         showSuccessToast={showSuccessToast}
    //         showErrorToast={showErrorToast}
    //         AiChiefComplaint={aiResponse?.chiefcomplaint}
    //       />
    //     )}
    //   </Box>
    //   {/* <Divider w={"50%"} h={"0.3rem"} /> */}

    //   {aiResponse && (
    //     <Box
    //       mx="auto"
    //       borderBottom="8px solid"
    //       borderColor="gray.500"
    //       width="75%"
    //       mt="1rem"
    //     />
    //   )}

    //   {aiResponse && (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       mt="1rem"
    //     >
    //       <Text
    //         textTransform="uppercase"
    //         textAlign="center"
    //         fontSize="2xl"
    //         fontWeight="bold"
    //         // color="teal.500"
    //       >
    //         ROS
    //       </Text>
    //     </Box>
    //   )}
    //   <Box width={"75%"} mx="auto" mt="1rem">
    //     {aiResponse && aiResponse?.ros && (
    //       <AiROSCard
    //         patientId={patientId}
    //         doctorId={doctorId}
    //         appointmentId={appointmentId}
    //         showSuccessToast={showSuccessToast}
    //         showErrorToast={showErrorToast}
    //         aiResponseROS={aiResponse?.ros}
    //       />
    //     )}
    //   </Box>

    //   {aiResponse && (
    //     <Box
    //       mx="auto"
    //       borderBottom="8px solid"
    //       borderColor="gray.500"
    //       width="75%"
    //       mt="1rem"
    //     />
    //   )}

    //   {aiResponse && (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       mt="1rem"
    //     >
    //       <Text
    //         textTransform="uppercase"
    //         textAlign="center"
    //         fontSize="2xl"
    //         fontWeight="bold"
    //         // color="teal.500"
    //       >
    //         HPI
    //       </Text>
    //     </Box>
    //   )}
    //   <Box width={"75%"} mx="auto" mt="1rem" height={"auto"}>
    //     {aiResponse && aiResponse?.hpitext && (
    //       <AIHpiNotesCard
    //         patientId={patientId}
    //         doctorId={doctorId}
    //         appointmentId={appointmentId}
    //         showSuccessToast={showSuccessToast}
    //         showErrorToast={showErrorToast}
    //         AiHpi={aiResponse?.hpitext}
    //       />
    //     )}
    //   </Box>

    //   {aiResponse && (
    //     <Box
    //       mx="auto"
    //       borderBottom="8px solid"
    //       borderColor="gray.500"
    //       width="75%"
    //       mt="1rem"
    //     />
    //   )}
    //   {/* {aiResponse && (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       mt="1rem"
    //     >
    //       <Text
    //         textTransform="uppercase"
    //         textAlign="center"
    //         fontSize="2xl"
    //         fontWeight="bold"
    //         // color="teal.500"
    //       >
    //         Physical Exam
    //       </Text>
    //     </Box>
    //   )}
    //   <Box width={"75%"} mx="auto" mt="1rem">
    //     {aiResponse && aiResponse?.physical_exam && (
    //       <AIPhysicalExamCard
    //         patientId={patientId}
    //         doctorId={doctorId}
    //         appointmentId={appointmentId}
    //         showSuccessToast={showSuccessToast}
    //         showErrorToast={showErrorToast}
    //         aiResponsePhysicalExam={aiResponse?.physical_exam}
    //       />
    //     )}
    //   </Box> */}

    //   {/* {aiResponse && (
    //     <Box
    //       mx="auto"
    //       borderBottom="8px solid"
    //       borderColor="gray.500"
    //       width="75%"
    //       mt="1rem"
    //     />
    //   )} */}
    //   {aiResponse && (
    //     <Box
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       mt="1rem"
    //     >
    //       <Text
    //         textTransform="uppercase"
    //         textAlign="center"
    //         fontSize="2xl"
    //         fontWeight="bold"
    //         // color="teal.500"
    //       >
    //         Doctor Assessment
    //       </Text>
    //     </Box>
    //   )}
    //   <Box width={"75%"} mx="auto" mt="1rem">
    //     {aiResponse && aiResponse?.assessment_plan && (
    //       <AINotesAssessment
    //         patientId={patientId}
    //         doctorId={doctorId}
    //         appointmentId={appointmentId}
    //         showSuccessToast={showSuccessToast}
    //         showErrorToast={showErrorToast}
    //         AiDoctorAssessment={aiResponse?.assessment_plan}
    //       />
    //     )}
    //   </Box>

    //   {aiResponse &&
    //     (useGPUChatbot ? (
    //       <AIFeedbackFooter
    //         disclaimer={
    //           "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
    //         }
    //         doctorId={doctorId}
    //         patientId={patientId}
    //         appointmentId={appointmentId}
    //         aiInput={aiInputForFeedback}
    //         aiOutput={aiOutputForFeedback}
    //         featureType={"AIRA Transcription Notes"}
    //       />
    //     ) : (
    //       <AICPUFeedbackFooter
    //         disclaimer={
    //           "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
    //         }
    //         doctorId={doctorId}
    //         patientId={patientId}
    //         appointmentId={appointmentId}
    //         aiInput={aiInputForFeedback}
    //         aiOutput={aiOutputForFeedback}
    //         featureType={"AIRA Transcription Notes"}
    //       />
    //     ))}
    // </>
    <>
      {/* {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )} */}
      {(isLoading || aiPatientResponse === null || aiResponse === null) && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}

      <Flex height="86vh" overflowY={"hidden"} top="0" left="0">
        {/* Left Box: Conversation History and Chats */}
        <Box width="50vw" p={4} overflowY="auto">
          <Text fontSize="2xl" fontWeight="bold" mb={4}>
            Conversation History
          </Text>
          {patientHealthData && (
            <Box display="flex" gap="0.5rem" alignItems="center">
              {patientHealthData?.patientinfo?.patientimage ? (
                <Image
                  borderRadius="full"
                  w="2.75rem"
                  h="2.75rem"
                  src={
                    patientHealthData?.patientinfo?.imagepath +
                    patientHealthData?.patientinfo?.patientimage
                  }
                />
              ) : (
                <Image
                  borderRadius="full"
                  w="2.75rem"
                  h="2.75rem"
                  src="/assets/imgs/no-image.png"
                />
              )}
              <Box>
                <Text fontSize="1rem" fontWeight="600" color="black">
                  {patientHealthData?.patientinfo?.firstname +
                    " " +
                    patientHealthData?.patientinfo?.lastname}
                </Text>
                {(
                  patientHealthData.patientinfo.uniqueid
                    ? "#" + patientHealthData.patientinfo.uniqueid
                    : ""
                ) ? (
                  <Box display="flex" gap="0.25rem" alignItems="center">
                    {/* <Image src="/assets/svgs/home-calendar.svg" /> */}
                    <Text fontSize="0.75rem" fontWeight="500" color="#384052">
                      {patientHealthData.patientinfo.uniqueid
                        ? "#" + patientHealthData.patientinfo.uniqueid
                        : ""}{" "}
                      {navHeaderOverviewData}
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}
          <Box
            bg="white"
            p={3}
            mb={4}
            borderRadius="md"
            boxShadow="sm"
            border="1px solid"
            borderColor="gray.200"
            mt="1rem"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="semibold" mb={2} color="gray.800">
                Chat Summary
              </Text>
              <IconButton
                aria-label="Copy Chat Summary"
                icon={<CopyIcon />}
                onClick={() => copyToClipboard(aiTranscriptSummary)}
                variant="ghost"
              />
            </Flex>
            <Text
              fontSize="sm"
              color="gray.600"
              height={"120px"}
              overflowY={"auto"}
            >
              {aiTranscriptSummary ||
                "No summary available yet. Start a conversation to see a summary here."}
            </Text>
          </Box>
          <ChatDisplay chatMessages={aiTranscriptChats} />
        </Box>
        {/* Divider */}
        <Divider orientation="vertical" />
        {/* Right Box: Notes and AI Content */}
        <Box
          width="50vw"
          p={4}
          overflowY="scroll"
          sx={{
            "::-webkit-scrollbar": {
              display: "none", // Hide scrollbar
            },
            msOverflowStyle: "none", // For Internet Explorer
            scrollbarWidth: "none", // For Firefox
          }}
        >
          <Tabs>
            <TabList height={"3rem"} borderBottom={"1px solid gray"}>
              <Tab
                onClick={() => handleTabChange("doctor")}
                _selected={{ color: "black", borderBottom: "2px solid black" }}
                fontSize={{ base: "12px", lg: "initial" }}
              >
                Doctor Notes
              </Tab>
              <Tab
                onClick={() => handleTabChange("patient")}
                _selected={{ color: "black", borderBottom: "2px solid black" }}
                fontSize={{ base: "12px", lg: "initial" }}
              >
                Patient History
              </Tab>
              <Tab
                onClick={() => handleTabChange("share")}
                _selected={{ color: "black", borderBottom: "2px solid black" }}
                fontSize={{ base: "12px", lg: "initial" }}
              >
                Share Details
              </Tab>
            </TabList>
            <TabPanels
              height="calc(77vh - 2.5rem)"
              overflowY="auto"
              mt="2rem"
              sx={{
                "::-webkit-scrollbar": {
                  display: "none", // Hide scrollbar
                },
                msOverflowStyle: "none", // For Internet Explorer
                scrollbarWidth: "none", // For Firefox
              }}
            >
              <TabPanel p={{ base: "0" }}>
                {aiResponse && aiResponse?.chiefcomplaint && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Chief Complaint
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Chief Complaint"
                        icon={<FaRegSave />}
                        onClick={() => UpdateChiefComplaint(aiCc)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Chief Complaint"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiCc)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Chief Complaint"
                        icon={<CgRemove />}
                        onClick={() => setAiCc("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiCc}
                      onChange={(e) => setAiCc(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}

                {aiResponse?.hpi_text && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        History Of Present Illness
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save HPI Notes"
                        icon={<FaRegSave />}
                        onClick={() => UpdateHpiTextBox(aiHpiText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy HPI Notes"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiHpiText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear HPI Notes"
                        icon={<CgRemove />}
                        onClick={() => setAiHpiText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiHpiText}
                      onChange={(e) => setAiHpiText(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}

                {aiResponse?.ros_text && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Review Of System
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save ROS"
                        icon={<FaRegSave />}
                        // onClick={() => fetchExtractRos(aiRosText)} // AI Call for categorization
                        onClick={() => UpdateROSTextBox(aiRosText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy ROS"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiRosText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear ROS"
                        icon={<CgRemove />}
                        onClick={() => setAiRosText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiRosText}
                      onChange={(e) => setAiRosText(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}

                {aiResponse?.physical_exam_text && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Physical Exam
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Physical Exam Notes"
                        icon={<FaRegSave />}
                        onClick={() =>
                          UpdatePhysicalExamTextBox(aiPhysicalExamText)
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Physical Exam Notes"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiPhysicalExamText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Physical Exam Notes"
                        icon={<CgRemove />}
                        onClick={() => setAiPhysicalExamText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPhysicalExamText}
                      onChange={(e) => setAiPhysicalExamText(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}

                {aiResponse?.assessment_plan && (
                  <Box mb={4}>
                    <Heading as="h4" size="md" mb={2}>
                      Assessment & Plan
                    </Heading>
                    {aiAssessmentPlanArray.map((item, index) => (
                      <Box key={index} mb={4}>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Heading as="h5" size="sm">
                            Plan {index + 1}
                          </Heading>
                          <Spacer />
                          <IconButton
                            aria-label={`Save Assessment Plan ${index + 1}`}
                            icon={<FaRegSave />}
                            onClick={() => SaveIndividualAssessmentPlan(item)}
                            variant="ghost"
                          />
                          <IconButton
                            aria-label={`Copy Assessment Plan ${index + 1}`}
                            icon={<CopyIcon />}
                            onClick={() =>
                              copyToClipboard(`${item.diagnosis}: ${item.plan}`)
                            }
                            variant="ghost"
                          />
                          <IconButton
                            aria-label={`Clear Assessment Plan ${index + 1}`}
                            icon={<CgRemove />}
                            onClick={() =>
                              setAiAssessmentPlanArray((prev) =>
                                prev.map((prevItem, i) =>
                                  i === index
                                    ? { ...prevItem, diagnosis: "", plan: "" }
                                    : prevItem
                                )
                              )
                            }
                            variant="ghost"
                          />
                        </Flex>
                        {/* <Textarea
                          value={`${item.diagnosis}`}
                          onChange={(e) =>
                            setAiAssessmentPlanArray((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, diagnosis: e.target.value }
                                  : prevItem
                              )
                            )
                          }
                          resize="none"
                        />
                        <Textarea
                          value={`${item.plan}`}
                          onChange={(e) =>
                            setAiAssessmentPlanArray((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, plan: e.target.value }
                                  : prevItem
                              )
                            )
                          }
                          resize="none"
                        /> */}
                        <PrimaryAssessmentInput
                          textAreaHeight={"50px"}
                          id={`${index},${item.diagnosis}`}
                          variant="defaultSide"
                          inputType="text"
                          inputPlace={"Diagnosis"}
                          // inputPlace={formInputs[index]?.diagnosis}
                          // defaultValue={`${item.diagnosis}`}
                          inputValue={`${item.diagnosis}`}
                          onChange={(e) =>
                            setAiAssessmentPlanArray((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, diagnosis: e.target.value }
                                  : prevItem
                              )
                            )
                          }
                          wrap={true}
                          // defaultHeight={"10px"}
                        />
                        <PrimaryAssessmentInput
                          textAreaHeight={"100px"}
                          id={`${index},${item.plan}`}
                          variant="downSide"
                          inputType="text"
                          inputPlace="Plan"
                          // defaultValue={`${item.plan}`}
                          inputValue={`${item.plan}`}
                          onChange={(e) =>
                            setAiAssessmentPlanArray((prev) =>
                              prev.map((prevItem, i) =>
                                i === index
                                  ? { ...prevItem, plan: e.target.value }
                                  : prevItem
                              )
                            )
                          }
                          wrap={true}
                          // defaultHeight={"24px"}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {aiResponse?.prescribed_medicines && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Prescribed Medicines
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Prescribed Medicines"
                        icon={<FaRegSave />}
                        onClick={() =>
                          fetchExtractRecommendedMedicines(
                            aiPrescribedMedicinesText
                          )
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Prescribed Medicines"
                        icon={<CopyIcon />}
                        onClick={() =>
                          copyToClipboard(aiPrescribedMedicinesText)
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Prescribed Medicines"
                        icon={<CgRemove />}
                        onClick={() => setAiPrescribedMedicinesText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPrescribedMedicinesText}
                      onChange={(e) =>
                        setAiPrescribedMedicinesText(e.target.value)
                      } // Update state on change
                      resize="none"
                    />
                  </Box>
                )}
                {aiResponse?.prescribed_procedures && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Prescribed Procedures
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Prescribed Procedures"
                        icon={<FaRegSave />}
                        onClick={() =>
                          fetchExtractRecommendedOrders(
                            aiPrescribedProceduresText,
                            "procedure"
                          )
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Prescribed Procedures"
                        icon={<CopyIcon />}
                        onClick={() =>
                          copyToClipboard(aiPrescribedProceduresText)
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Prescribed Procedures"
                        icon={<CgRemove />}
                        onClick={() => setAiPrescribedProceduresText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPrescribedProceduresText}
                      onChange={(e) =>
                        setAiPrescribedProceduresText(e.target.value)
                      } // Update state on change
                      resize="none"
                    />
                  </Box>
                )}
                {aiResponse?.prescribed_labs && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Prescribed Lab Tests
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Prescribed Lab Tests"
                        icon={<FaRegSave />}
                        onClick={() =>
                          fetchExtractRecommendedOrders(
                            aiPrescribedLabsText,
                            "lab"
                          )
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Prescribed Lab Tests"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiPrescribedLabsText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Prescribed Lab Tests"
                        icon={<CgRemove />}
                        onClick={() => setAiPrescribedLabsText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPrescribedLabsText}
                      onChange={(e) => setAiPrescribedLabsText(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}
                {aiResponse?.prescribed_imaging && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Prescribed Imaging Tests
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Prescribed Imaging Tests"
                        icon={<FaRegSave />}
                        onClick={() =>
                          fetchExtractRecommendedOrders(
                            aiPrescribedImagingText,
                            "imaging"
                          )
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Imaging Tests"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiPrescribedImagingText)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Imaging Tests"
                        icon={<CgRemove />}
                        onClick={() => setAiPrescribedImagingText("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPrescribedImagingText}
                      onChange={(e) =>
                        setAiPrescribedImagingText(e.target.value)
                      } // Update state on change
                      resize="none"
                    />
                  </Box>
                )}

                <Text fontWeight="bold" color="black" fontSize="sm">
                  AIRA strives to be as accurate as possible, but occasional
                  errors may occur. Please verify its responses to ensure the
                  information meets your needs. Occasionally, it may provide
                  incorrect information or recommend something if required. Your
                  discretion will ensure the best experience.
                </Text>
              </TabPanel>
              <TabPanel p={{ base: "0" }}>
                {aiPatientResponse &&
                  aiPatientResponse?.patient_medical_conditions && (
                    <Box mb={4}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Heading as="h4" size="md">
                          Medical Conditions
                        </Heading>
                        <Spacer />
                        <IconButton
                          aria-label="Save Medical Conditions"
                          icon={<FaRegSave />}
                          onClick={() =>
                            addMedicalCondition(aiPatientMedicalConditions)
                          }
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Copy Medical Conditions"
                          icon={<CopyIcon />}
                          onClick={() =>
                            copyToClipboard(aiPatientMedicalConditions)
                          }
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Clear Medical Conditions"
                          icon={<CgRemove />}
                          onClick={() => setAiPatientMedicalConditions("")}
                          variant="ghost"
                        />
                      </Flex>
                      <Textarea
                        value={aiPatientMedicalConditions}
                        onChange={(e) =>
                          setAiPatientMedicalConditions(e.target.value)
                        } // Update state on change
                        resize="none"
                      />
                    </Box>
                  )}
                {aiPatientResponse &&
                  aiPatientResponse?.patient_medications && (
                    <Box mb={4}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Heading as="h4" size="md">
                          Medications
                        </Heading>
                        <Spacer />
                        <IconButton
                          aria-label="Save Medications"
                          icon={<FaRegSave />}
                          // onClick={() => addMedicalCondition(aiPatientMedicalConditions)}
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Copy Medications"
                          icon={<CopyIcon />}
                          onClick={() => copyToClipboard(aiPatientMedications)}
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Clear Medications"
                          icon={<CgRemove />}
                          onClick={() => setAiPatientMedications("")}
                          variant="ghost"
                        />
                      </Flex>
                      <Textarea
                        value={aiPatientMedications}
                        onChange={(e) =>
                          setAiPatientMedications(e.target.value)
                        } // Update state on change
                        resize="none"
                      />
                    </Box>
                  )}
                {aiPatientResponse && aiPatientResponse?.patient_surgeries && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Surgeries
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Surgeries"
                        icon={<FaRegSave />}
                        onClick={() => addProcedures(aiPatientSurgeries)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Surgeries"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiPatientSurgeries)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Surgeries"
                        icon={<CgRemove />}
                        onClick={() => setAiPatientSurgeries("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPatientSurgeries}
                      onChange={(e) => setAiPatientSurgeries(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}
                {aiPatientResponse && aiPatientResponse?.patient_vitals && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Vitals
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Vitals"
                        icon={<FaRegSave />}
                        onClick={() => addVitals(aiPatientVitals)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Vitals"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiPatientVitals)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Vitals"
                        icon={<CgRemove />}
                        onClick={() => setAiPatientVitals("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPatientVitals}
                      onChange={(e) => setAiPatientVitals(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}
                {aiPatientResponse && aiPatientResponse?.patient_allergies && (
                  <Box mb={4}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Heading as="h4" size="md">
                        Allergies
                      </Heading>
                      <Spacer />
                      <IconButton
                        aria-label="Save Allergies"
                        icon={<FaRegSave />}
                        onClick={() =>
                          addAllergicConditions(aiPatientAllergies)
                        }
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Copy Allergies"
                        icon={<CopyIcon />}
                        onClick={() => copyToClipboard(aiPatientAllergies)}
                        variant="ghost"
                      />
                      <IconButton
                        aria-label="Clear Allergies"
                        icon={<CgRemove />}
                        onClick={() => setAiPatientAllergies("")}
                        variant="ghost"
                      />
                    </Flex>
                    <Textarea
                      value={aiPatientAllergies}
                      onChange={(e) => setAiPatientAllergies(e.target.value)} // Update state on change
                      resize="none"
                    />
                  </Box>
                )}
                {aiPatientResponse &&
                  aiPatientResponse?.patient_vaccinations && (
                    <Box mb={4}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Heading as="h4" size="md">
                          Vaccinations
                        </Heading>
                        <Spacer />
                        <IconButton
                          aria-label="Save Vaccinations"
                          icon={<FaRegSave />}
                          onClick={() => addVaccinations(aiPatientVaccinations)}
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Copy Vaccinations"
                          icon={<CopyIcon />}
                          onClick={() => copyToClipboard(aiPatientVaccinations)}
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Clear Vaccinations"
                          icon={<CgRemove />}
                          onClick={() => setAiPatientVaccinations("")}
                          variant="ghost"
                        />
                      </Flex>
                      <Textarea
                        value={aiPatientVaccinations}
                        onChange={(e) =>
                          setAiPatientVaccinations(e.target.value)
                        } // Update state on change
                        resize="none"
                      />
                    </Box>
                  )}
                {aiPatientResponse &&
                  aiPatientResponse?.patient_social_history && (
                    <Box mb={4}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Heading as="h4" size="md">
                          Social History
                        </Heading>
                        <Spacer />
                        <IconButton
                          aria-label="Save Vaccinations"
                          icon={<FaRegSave />}
                          // onClick={() => addVaccinations(aiPatientVaccinations)}
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Copy Social History"
                          icon={<CopyIcon />}
                          onClick={() =>
                            copyToClipboard(aiPatientSocialHistory)
                          }
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Clear Social History"
                          icon={<CgRemove />}
                          onClick={() => setAiPatientSocialHistory("")}
                          variant="ghost"
                        />
                      </Flex>
                      <Textarea
                        value={aiPatientSocialHistory}
                        onChange={(e) =>
                          setAiPatientSocialHistory(e.target.value)
                        } // Update state on change
                        resize="none"
                      />
                    </Box>
                  )}

                <Text fontWeight="bold" color="black" fontSize="sm">
                  AIRA strives to be as accurate as possible, but occasional
                  errors may occur. Please verify its responses to ensure the
                  information meets your needs. Occasionally, it may provide
                  incorrect information or recommend something if required. Your
                  discretion will ensure the best experience.
                </Text>
              </TabPanel>

              <TabPanel p={{ base: "0" }}>
                {/* Share record options available here */}
                <Box mb={4}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Heading as="h4" size="md">
                      Email Information
                    </Heading>
                    <Spacer />
                    {/* <IconButton
                          aria-label="Save Email"
                          icon={<FaRegSave />}
                          // onClick={() => addVaccinations(aiPatientVaccinations)}
                          variant="ghost"
                        /> */}
                    <IconButton
                      aria-label="Copy Email"
                      icon={<CopyIcon />}
                      onClick={() => copyToClipboard(shareReportDoctorEmail)}
                      variant="ghost"
                    />
                    <IconButton
                      aria-label="Clear Email"
                      icon={<CgRemove />}
                      onClick={() => setShareReportDoctorEmail("")}
                      variant="ghost"
                    />
                  </Flex>
                  <Textarea
                    value={shareReportDoctorEmail}
                    onChange={(e) => setShareReportDoctorEmail(e.target.value)} // Update state on change
                    resize="none"
                  />
                  <Heading as="h4" size="md" mt={6}>
                    Details:
                  </Heading>
                  <Stack spacing={2} mt={2}>
                    <Checkbox
                      isChecked={shareChatSummary}
                      onChange={(e) => setShareChatSummary(e.target.checked)}
                    >
                      Share chat summary
                    </Checkbox>
                    <Checkbox
                      isChecked={shareChatHistory}
                      onChange={(e) => setShareChatHistory(e.target.checked)}
                    >
                      Share chat history
                    </Checkbox>
                    <Checkbox
                      isChecked={shareDoctorNotes}
                      onChange={(e) => setShareDoctorNotes(e.target.checked)}
                    >
                      Share Doctor Notes
                    </Checkbox>
                    <Checkbox
                      isChecked={sharePatientHistory}
                      onChange={(e) => setSharePatientHistory(e.target.checked)}
                    >
                      Share patient history
                    </Checkbox>
                  </Stack>
                  {/* <PrimaryButtonIcon
                  buttonText="Share"
                  iconSrc="/assets/imgs/email-icon.png"
                  btnStyle={{
                    h: "2.5rem",
                    w: "25%",
                    p: "1rem",
                    m: "0",
                    mt: "48px",
                    bg: COLORS.PRIMARY_GRADIENT,
                    color: COLORS.PRIMARY_WHITE,
                    _hover: { bg: COLORS.PRIMARY_COLOR },
                  }}
                  onClick={shareNotesOnEmail}
                /> */}
                  <Button
                    isDisabled={false} // Update with your `disabled` variable if needed
                    className="primary-button-icon" // Replace with your desired `btnClass`
                    onClick={shareNotesOnEmail}
                    sx={{
                      h: "2.5rem",
                      w: "8rem",
                      p: "1rem",
                      m: "0",
                      mt: "48px",
                      bg: COLORS.PRIMARY_GRADIENT,
                      color: COLORS.PRIMARY_WHITE,
                      _hover: { bg: COLORS.PRIMARY_COLOR },
                    }}
                  >
                    <Box className="icon-img" as="span" sx={{ mr: "0.5rem" }}>
                      {" "}
                      {/* Adjust `sx` as needed */}
                      <Image src="/assets/imgs/email-icon.png" h={"24px"} w={"24px"} />
                    </Box>
                    <Text>Share</Text>
                  </Button>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>

      {/* DynamicModal for Recommended Prescription */}
      <DynamicModalForTwo
        ref={aiRecommendMedicineRef}
        modalWidth="75%"
        modalHeader={`Aira - AI: Recommended Prescription`}
        modalBody={
          <>
            {recommendMedicinesAiResponse.map((medicine, index) => (
              <Box key={medicine.id}>
                <Box
                  p="10px"
                  border="1px solid gray"
                  borderRadius="10px"
                  mt="10px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    // p="10px"
                    // border="1px solid gray"
                    // borderRadius="10px"
                    // mt="10px"
                  >
                    <Checkbox
                      isChecked={selectedRecommendedMedicinesAiResponses.includes(
                        index
                      )}
                      onChange={() =>
                        handleRecommendedMedicinesCheckboxChange(index)
                      }
                      mr={4}
                      alignSelf="center" // Vertically center the checkbox
                    ></Checkbox>
                    <Box width="42%">
                      <Box fontWeight={"semibold"}>
                        {medicine.medicinename}
                        <Box
                          display="inline-block"
                          verticalAlign="bottom"
                          fontSize="10px"
                          fontWeight="600"
                          bg="#E6E7E966"
                          border="1px solid #E6E7E9"
                          p="5px 10px"
                          borderRadius="full"
                          w="fit-content"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {medicine.medicinetype ? medicine.medicinetype : "-"}
                          {/* Tablet */}
                        </Box>
                      </Box>
                      <Box fontSize={"xs"}>
                        Composition: {medicine.composition}
                      </Box>
                    </Box>
                    <Box width="8%">
                      {medicine.morning}-{medicine.evening}-{medicine.night}
                      {/* 1-0-0 */}
                    </Box>
                    <Box width="35%">
                      {medicine.beforefood === 1 &&
                        medicine.afterfood === 1 && <>Before & After Food</>}
                      {medicine.beforefood === 1 &&
                        (medicine.afterfood === 0 ||
                          medicine.afterfood === null) && <>Before Food</>}
                      {(medicine.beforefood === 0 ||
                        medicine.beforefood === null) &&
                        medicine.afterfood === 1 && <>After Food</>}{" "}
                      {(medicine.beforefood === 1 ||
                        medicine.afterfood === 1) &&
                        medicine.frequency &&
                        "-"}
                      {medicine.frequency && `${medicine.frequency} x per day`}
                    </Box>

                    <Box>
                      {/* Buttons */}
                      <Box display="flex" justifyContent="space-between">
                        {/* <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() =>
                              fetchAiDoctorPrompts(medicine.medicinename)
                            }
                          >
                            <Image
                              w="26px"
                              src="/assets/svgs/ai-star-outline.svg"
                            />
                          </Box> */}
                        <Box
                          as="button"
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          w="36px"
                          h="auto"
                          p="10px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => handleEditMedicine(medicine)}
                        >
                          <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Reason Box */}
                  {/* <Box
                    as="p"
                    p="2px"
                    bg="#F9FAFB"
                    w="auto"
                    borderLeft="2px solid #14C585"
                    fontSize="0.875rem"
                    fontWeight="500"
                    mt="2px"
                    ml="3rem"
                  >
                    {medicine?.reason}{" "}
                  </Box> */}
                </Box>

                {editMedicine && editMedicine.id === medicine.id && (
                  <Box
                    p="20px"
                    border="1px solid gray"
                    borderRadius="10px"
                    mt="10px" // Add some margin-top to separate from the above row
                  >
                    <Stack spacing={4}>
                      <InputGroup>
                        <PrimaryInput
                          type="text"
                          variant="bothSide"
                          inputPlace="Medicine name*"
                          defaultValue={editMedicine.medicinename}
                          onChange={(e) =>
                            handleEditMedicineChange(
                              "medicinename",
                              e.target.value
                            )
                          }
                        />
                        <PrimaryInput
                          key={compositionEditKey}
                          type="text"
                          variant="bothSide"
                          inputPlace="Medicine Composition"
                          defaultValue={editMedicine.composition}
                          onChange={(e) =>
                            handleEditMedicineChange(
                              "composition",
                              e.target.value
                            )
                          }
                        />{" "}
                        <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "2.8rem",
                          }}
                          onClick={() =>
                            fetchCompositionForEdit(editMedicine.medicinename)
                          }
                          isDisabled={false}
                        />
                      </InputGroup>
                      <RadioGroup
                        defaultValue={editMedicine.medicinetype}
                        onChange={(value) => {
                          handleEditMedicineChange("medicinetype", value);

                          if (value === "Syrup") {
                            handleEditMedicineChange("dosageunit", "ml");
                          } else if (value === "Tablet") {
                            handleEditMedicineChange("dosageunit", "mg");
                          }
                        }}
                      >
                        <Stack direction="row" gap="20px">
                          <Radio value="Tablet">Tablet</Radio>
                          <Radio value="Syrup">Syrup</Radio>
                        </Stack>
                      </RadioGroup>
                      <Divider />
                      <Box>
                        <Box display="flex" flexDir="row">
                          <MiniDropdownInput
                            variant="bothSide"
                            options={[
                              { label: "mg", value: "mg" },
                              { label: "ml", value: "ml" },
                            ]}
                            placeholderProp="Dosage"
                            defaultValue={editMedicine.dosage}
                            defaultOptionProp={editMedicine.dosageunit}
                            onSelect={(value) =>
                              handleEditMedicineChange(
                                "dosageunit",
                                value.label
                              )
                            }
                            handleInputChange={(event) =>
                              handleEditMedicineChange(
                                "dosage",
                                event.target.value
                              )
                            }
                            maxLength="6"
                          />
                          <DropdownInput
                            variant="bothSide"
                            dropDownPlace="Consumption frequency per day"
                            options={["1", "2", "3", "4"]}
                            onClick={(value) =>
                              handleEditMedicineChange("frequency", value)
                            }
                            defaultOption={editMedicine.frequency}
                          />
                        </Box>
                        <Box display="flex" flexDir="row">
                          <DatePicker
                            // disableFutureDates={true}
                            // for default date format it in correct order
                            defaultDate={moment(
                              editMedicine.medicationstartdate,
                              ["YYYY-MM-DD", "DD-MM-YYYY"]
                            ).format("DD-MM-YYYY")}
                            minDate={editMedicine.medicationstartdate}
                            onDateChange={(selectedDateValue) =>
                              handleEditMedicineChange(
                                "medicationstartdate",
                                moment(selectedDateValue).format("YYYY-MM-DD")
                              )
                            }
                            placeholderProp={"Start Date"}
                            calendarStlye={{
                              left: "-18px",
                              right: "unset",
                            }}
                          />
                          <DatePicker
                            // defaultDate={editMedicine.medicationenddate}
                            defaultDate={moment(
                              editMedicine.medicationenddate,
                              ["YYYY-MM-DD", "DD-MM-YYYY"]
                            ).format("DD-MM-YYYY")}
                            minDate={editMedicine.medicationstartdate}
                            onDateChange={(selectedDateValue) =>
                              handleEditMedicineChange(
                                "medicationenddate",
                                moment(selectedDateValue).format("YYYY-MM-DD")
                              )
                            }
                            placeholderProp={"End Date"}
                            calendarStlye={{
                              transform: "scale(0.9)",
                              right: "-22px",
                              top: "30px",
                            }}
                          />
                        </Box>

                        <CheckboxGroup colorScheme="green">
                          <Stack spacing={2} pt="20px" direction="row">
                            <Checkbox
                              isChecked={editMedicine.morning === 1}
                              onChange={() =>
                                handleEditMedicineChange(
                                  "morning",
                                  editMedicine.morning === 1 ? 0 : 1
                                )
                              }
                            >
                              Morning
                            </Checkbox>
                            <Checkbox
                              isChecked={editMedicine.evening === 1}
                              onChange={() =>
                                handleEditMedicineChange(
                                  "evening",
                                  editMedicine.evening === 1 ? 0 : 1
                                )
                              }
                            >
                              Evening
                            </Checkbox>
                            <Checkbox
                              isChecked={editMedicine.night === 1}
                              onChange={() =>
                                handleEditMedicineChange(
                                  "night",
                                  editMedicine.night === 1 ? 0 : 1
                                )
                              }
                            >
                              Night
                            </Checkbox>
                            <Spacer />{" "}
                            {/* Add a spacer to push the next checkboxes to the right */}
                            <Checkbox
                              isChecked={editMedicine.beforefood === 1}
                              onChange={() =>
                                handleEditMedicineChange(
                                  "beforefood",
                                  editMedicine.beforefood === 1 ? 0 : 1
                                )
                              }
                            >
                              Before Food
                            </Checkbox>
                            <Checkbox
                              isChecked={editMedicine.afterfood === 1}
                              onChange={() =>
                                handleEditMedicineChange(
                                  "afterfood",
                                  editMedicine.afterfood === 1 ? 0 : 1
                                )
                              }
                            >
                              After Food
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      </Box>
                      <Divider />
                      <Box display="flex" justifyContent="flex-end" gap="10px">
                        {/* <Button onClick={() => setEditMedicine(null)}>
                            Cancel
                          </Button> */}
                        <PrimaryButton
                          buttonText="Cancel"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            h: "2.5rem",
                          }}
                          onClick={() => setEditMedicine(null)}
                          isDisabled={false}
                        />
                        {/* <Button
                            colorScheme="blue"
                            onClick={handleSaveEditMedicine}
                          >
                            Save
                          </Button> */}
                        <PrimaryButton
                          buttonText="Save"
                          onClick={handleSaveEditMedicine}
                          isDisabled={false}
                          btnStyle={{
                            w: "25%",
                            //   color: COLORS.PRIMARY_COLOR,
                            h: "2.5rem",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Box>
            ))}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Discard"
              btnStyle={{
                w: "48%",
                h: "2.5rem",
              }}
              onClick={() => {
                setEditMedicine(null);
                aiRecommendMedicineRef.current.closeModal();
              }}
            />

            <Spacer />

            <PrimaryButton
              buttonText="Save selected items"
              variant="grayBtn"
              btnStyle={{
                w: "48%",
                color: COLORS.PRIMARY_COLOR,
                h: "2.5rem",
              }}
              isDisabled={!(selectedRecommendedMedicinesAiResponses.length > 0)}
              onClick={() => {
                setEditMedicine(null);
                saveSelectedRecommendedMedicinesToOrder();
                aiRecommendMedicineRef.current.closeModal();
              }}
            />
          </>
        }
      />

      <DynamicModalForTwo
        ref={aiROSReviewRef}
        hasFooter={false}
        modalWidth="75%"
        modalHeader={`Aira - AI: Review Of System`}
        modalBody={
          <>
            <Box width={"75%"} mx="auto" mt="1rem">
              {aiRosResponse && aiRosResponse?.ros && (
                <AiROSCard
                  patientId={patientId}
                  doctorId={doctorId}
                  appointmentId={appointmentId}
                  showSuccessToast={showSuccessToast}
                  showErrorToast={showErrorToast}
                  aiResponseROS={aiResponse?.ros}
                />
              )}
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Close"
              btnStyle={{
                w: "48%",
                h: "2.5rem",
              }}
              onClick={() => {
                aiROSReviewRef.current.closeModal();
              }}
            />
          </>
        }
      />

      <DynamicModalForTwo
        ref={aiVitalsReviewRef}
        hasFooter={false}
        modalWidth="75%"
        modalHeader={`Aira - AI: Review Of System`}
        modalBody={
          <>
            <Box width={"75%"} mx="auto" mt="1rem">
              {aiPatientResponse && aiPatientResponse?.patient_vitals && (
                <AIRAVitalsForm
                  patientId={patientId}
                  doctorId={doctorId}
                  appointmentId={appointmentId}
                  showSuccessToast={showSuccessToast}
                  showErrorToast={showErrorToast}
                  AiVitals={transformVitals(aiVitalsResponse?.vitals || [])}
                />
              )}
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Close"
              btnStyle={{
                w: "48%",
                h: "2.5rem",
              }}
              onClick={() => {
                aiVitalsReviewRef.current.closeModal();
              }}
            />
          </>
        }
      />
    </>
  );
};

export default TranscriptNotesCard;
