import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Badge,
  Image,
  VStack,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";

import {
  updateDoctorNotes,
  GetDoctorMasterData,
  GetDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import PrimaryButton from "../../primary-button";
import { COLORS } from "../../styles/colors";

import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import { actionAccessMain } from "../../../auth";
import { useTranscriber } from "./cheif-complaint-audio/useTranscriber";
import { ChatAudioManager } from "./cheif-complaint-audio/chatVoiceManager";

const DoctorROSCPUCard = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  fetchDoctorNotesEmptyIndication,
  submitOnTabSwitch
}) => {
  const [doctorMasterROSData, setDoctorMasterROSData] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [initiallySelectedConditions, setInitiallySelectedConditions] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchRosMasterData();
    fetchDoctorNotes();
  }, []);

  const fetchRosMasterData = async () => {
    showLoader();
    try {
      const inputData = {
        input: { type: "ros" },
      };
      const data = await GetDoctorMasterData(inputData.input);
      setDoctorMasterROSData(data);
    } catch (error) {
      console.error("Error fetching Master ROS data:", error);
    } finally {
      dismissLoader();
    }
  };

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      const reviewOfSystem = data?.output?.data?.reviewofsystem || [];
      const initialSelectedConditions = reviewOfSystem.reduce(
        (acc, { rosname, rosvalue }) => {
          acc[rosname] = rosvalue.reduce((acc, { value }) => {
            acc[value] = { condition: value }; // Use condition value as key
            return acc;
          }, {});
          return acc;
        },
        {}
      );
      setSelectedConditions(initialSelectedConditions);
      setInitiallySelectedConditions(initialSelectedConditions);
      setTextFieldValue(data?.output?.data?.rostext?.description);
      setDefaultText(data?.output?.data?.rostext?.description);
      setInitialLoad(false); // Mark the initial load as complete
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const transformStateToAPIFormat = (state) => {
    return Object.keys(state)
      .map((conditionType) => ({
        rosname: conditionType,
        rosvalue: Object.values(state[conditionType]).map((condition) => ({
          value: condition.condition,
        })),
      }))
      .filter((condition) => condition.rosvalue.length > 0); // Remove entries with empty rosvalue
  };

  const UpdateROS = async () => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        ros: transformStateToAPIFormat(selectedConditions),
      },
    };
    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("ROS Saved successfully");
        fetchDoctorNotesEmptyIndication();
        setChangesMade(false); // Reset changesMade state
        setSelectedConditions({}); // Reset selectedConditions
        fetchDoctorNotes();
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const handleConditionButtonClick = (type, condition) => {
    setSelectedConditions((prev) => {
      const currentTypeSelections = prev[type] || {};
      const isSelected = currentTypeSelections[condition.condition];

      let newState;
      if (isSelected) {
        const { [condition.condition]: removed, ...rest } =
          currentTypeSelections;
        newState = { ...prev, [type]: rest };
      } else {
        newState = {
          ...prev,
          [type]: {
            ...currentTypeSelections,
            [condition.condition]: condition,
          },
        };
      }

      const hasSelections = Object.values(newState).some(
        (conditions) => Object.keys(conditions).length > 0
      );

      setChangesMade(hasSelections);

      return newState;
    });
  };

  // Code to expand panels with content initially

  const [expandedIndices, setExpandedIndices] = useState([]);
  useEffect(() => {
    const getInitialExpandedIndices = () => {
      let expandedIndices = [];
      if (doctorMasterROSData && doctorMasterROSData.length > 0) {
        doctorMasterROSData.forEach((section, index) => {
          const hasData = section.data.some(
            (condition) => selectedConditions[section.conditiontype]
          );
          if (hasData) expandedIndices.push(index);
        });
      }
      // If no sections have data, default to indices 0 through 11
      if (expandedIndices.length === 0) {
        expandedIndices = Array.from({ length: 12 }, (_, i) => i);
      }
      // return expandedIndices;
      return (expandedIndices = Array.from({ length: 12 }, (_, i) => i));
    };

    setExpandedIndices(getInitialExpandedIndices());
  }, [doctorMasterROSData, selectedConditions]);

  //--------------------------Text box integration

  const [textFieldValue, setTextFieldValue] = useState("");
    const [defaultText, setDefaultText] = useState("");

  const UpdateROSTextBox = async () => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        rostext: {
          description: textFieldValue,
          type: "other",
        },
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("ROS Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const activeTranscriber = useTranscriber();

  const [typing, setTyping] = useState(false); // Typing indicator state

  const [placeholder, setPlaceholder] = useState(
    "Click anywhere to start typing...."
  );

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setTextFieldValue(activeTranscriber.output.text);
      setDefaultText(activeTranscriber.output.text);
      setPlaceholder("Click anywhere to start typing....");
      setTyping(false);
    }
  }, [activeTranscriber?.output]);

      // submit on tab switch and submit button is enalbed
      useEffect(() => {
        if (submitOnTabSwitch) {
          if (textFieldValue && textFieldValue != defaultText) {
            UpdateROSTextBox(); // Call the first save function if textFieldValue is not empty
          }
          const hasDifferentLength = (obj1, obj2) =>
            Object.keys(obj1).length !== Object.keys(obj2).length;
    
          const hasDifferentContent = (obj1, obj2) =>
            JSON.stringify(obj1) !== JSON.stringify(obj2);
    
          if (
            (hasDifferentLength(initiallySelectedConditions, selectedConditions) ||
              hasDifferentContent(initiallySelectedConditions, selectedConditions)) &&
            Object.keys(selectedConditions).length > 0
          ) {
            UpdateROS();
          }
        }
      }, [submitOnTabSwitch])
    
  return (
    <Box
      position="relative"
      height="100%"
      display="flex"
      flexDirection="column"
      overflowY={"auto"}
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Accordion
        allowMultiple
        index={expandedIndices}
        onChange={(indices) => setExpandedIndices(indices)}
        flex="1"
        overflowY="auto"
      >
        {doctorMasterROSData.map((section) => (
          <AccordionItem key={section.conditiontype}>
            <AccordionButton
              bg="white"
              fontSize="md"
              position="relative"
              _expanded={{ bg: "#e6e7e9", color: "black" }}
            >
              <Box flex="1" textAlign="left">
                {section.conditiontype}
                {selectedConditions[section.conditiontype] &&
                  Object.keys(selectedConditions[section.conditiontype])
                    .length > 0 && (
                    <Badge
                      color="gray"
                      position="absolute"
                      right="2.8rem"
                      top="50%"
                      transform="translateY(-50%)"
                      bg="white"
                    >
                      {
                        Object.keys(selectedConditions[section.conditiontype])
                          .length
                      }{" "}
                      Selected
                    </Badge>
                  )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} bg="#fcfcfd">
              {section.data.map((condition) => {
                const isSelected =
                  selectedConditions[section.conditiontype] &&
                  selectedConditions[section.conditiontype][
                    condition.condition
                  ];
                return (
                  <Button
                    key={condition.condition}
                    borderRadius="full"
                    onClick={() =>
                      handleConditionButtonClick(
                        section.conditiontype,
                        condition
                      )
                    }
                    bg={isSelected ? "black" : "white"}
                    color={isSelected ? "white" : "black"}
                    leftIcon={
                      isSelected ? (
                        <Image src="/assets/svgs/white-ok.svg" boxSize="1em" />
                      ) : null
                    }
                    variant="solid"
                    boxShadow={
                      isSelected ? "none" : "0px 2px 3px rgba(0, 0, 0, 0.2)"
                    }
                    _hover={{
                      bg: isSelected ? "black" : "white", // Darker when hovered
                    }}
                    _active={{
                      bg: isSelected ? "black" : "white", // Slightly dim when clicked
                    }}
                    border={isSelected ? "none" : "1px solid #E6E7E9"}
                    size="md"
                    m={1}
                  >
                    {condition.condition}
                  </Button>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <Box
        padding={{ base: "0", md: "4" }}
        width={{ base: "100%", md: "98%" }}
        height="30%"
        // position={{ base: "unset", md: "absolute" }}
        bg="white"
        pt={"2px"}
      >
        <VStack spacing="4" height="85%">
          <Box flex="1" width="100%" position="relative" bg={"#f9fafb"}>
            <Input
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              height="100%"
              padding="2"
              resize="none"
              as="textarea"
              disabled={typing} // Disable when AI typing is true
              style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}
            />
            <Text
              position="absolute"
              bottom="2"
              left="50%"
              transform="translateX(-50%)"
              fontSize="sm"
              color="gray.500"
              w={{ base: "100%", md: "unset" }}
              textAlign={{ base: "center", md: "unset" }}
            >
              {placeholder}
            </Text>
          </Box>
          <HStack p={2} bottom="0" width="100%" alignItems="flex-end">
            <ChatAudioManager
              transcriber={activeTranscriber}
              setPlaceholder={setPlaceholder}
              setTyping={setTyping}
            />
            <PrimaryButton
              buttonText="Save ROS"
              onClick={() => {
                if (textFieldValue) {
                  UpdateROSTextBox(); // Call the first save function if textFieldValue is not empty
                }
                if (selectedConditions) {
                  UpdateROS(); // Call the second save function based on its specific conditions
                }
              }}
              // isDisabled={
              //   initiallySelectedConditions === selectedConditions ||
              //   !selectedConditions.length ||
              //   !actionAccessMain(20)?.create
              // }
              btnStyle={{
                h: "2.5rem",
                w: "90%",
                p: "0",
                // mr: "12px",
                // ml: "12px",
                // position: "sticky",
                // bottom: "0",
                // mt: "3rem",
                mx: "2.5%",
                zIndex: "1",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </HStack>
        </VStack>
      </Box>

      {/* <Button
        position="sticky"
        bottom="0"
        width="95%"
        colorScheme="teal"
        onClick={UpdateROS}
        zIndex={1}
        isDisabled={!changesMade || initialLoad} // Disable if no changes or initial load not complete
        mx="auto"
      >
        Save ROS
      </Button> */}

      {/* <PrimaryButton
        buttonText="Save ROS"
        isDisabled={
          !changesMade || initialLoad || !actionAccessMain(18)?.create
        }
        onClick={UpdateROS}
        btnStyle={{
          h: "2.5rem",
          w: "95%",
          p: "0",
          mr: "12px",
          ml: "12px",
          position: "sticky",
          bottom: "0",
          zIndex: "1",
          mx: "auto",
          bg: COLORS.PRIMARY_GRADIENT,
          color: COLORS.PRIMARY_WHITE,
          _hover: { bg: COLORS.PRIMARY_COLOR },
        }}
      /> */}
    </Box>
  );
};

export default DoctorROSCPUCard;
