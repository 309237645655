import React, { useEffect, useState } from "react";
import { saveToken,savePatientDetails,saveUsername,saveFirstName,saveLastName,saveLoginID, savePatientImage, savePatientdob, savePatientgender, saveFirstTimeLogin, savecurrency, saveAddressInfo, saveEmail, saveMobile, saveLogo, saveHospitalName, saveIsDoctor, saveLoginDetails } from "../../auth";
import {
  FetchLogin,
  generateOtp,
  VerifyUser,
  FetchMobileLogin,
} from "../../middleware/services/login/index";
import {
  Flex,
  VStack,
  Box,
  Image,
  Text,
  Spacer,
  Center,
  InputGroup,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import {
  Toaster,
} from "../../components";
import PrimaryInput from "../../components/primary-input";
import PrimaryButton from "../../components/primary-button";
import PasswordInput from "../../components/password-input";
import PrimaryInputDropdown from "../../components/primary-input-dropdown";
import OtpIndexForgotPassword from "./otp";
import ForgotPassword from "./forgotpassword";
import NewPassword from "./newpassword";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";


const LoginIndex = () => {
  const { showSuccessToast, showErrorToast } = Toaster();
  const [isError, setIsError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPasswordButtonDisabled, setIsPasswordButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isMobileLoginClicked, setIsMobileLoginClicked] = useState(false);
  const [redirectToPassword, setRedirectTopassword] = useState(false);
  const [redirectToMobilePassword, setRedirectToMobilepassword] = useState(false);
  const [isEmailClicked, setIsEmailLoginClicked] = useState(true);
  const [showBackButton, setshowBackButton] = useState(false);
  const [ShowMobileOTP, setShowMobileOTP] = useState(false);
  const [GeneratedOTP, setGeneratedOTP] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [ShowForgotPassword, setShowForgotPassword] = useState(false);
  const [ShowNewPassword, setShowNewPassword] = useState(false);
  const emailRegex = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;
  var redirect = useNavigate();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [countrycode, setCountrycode] = useState('')

  const [isUser, setIsUser] = useState(false)
  const [userTypeId, setUserTypeId] = useState(null)
  const date = new Date();
  useEffect(()=>{
    localStorage.clear();
    sessionStorage.clear();
  },[])
  const handleEmailChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^\x00-\x7F]/g, '')
    setIsError(false);
      setEmail(sanitizedValue.slice(0, 50));

    const newEmail = e.target.value.replace(/[^\x00-\x7F]/g, '');
    setIsButtonDisabled(newEmail.trim() === "");
    if (newEmail.trim() === "") {
      setIsError(false);
    }
  };
  const handleMobileChange = (e) => {
    var countrycode = localStorage.getItem("countryid")
    setCountrycode(countrycode)

    setIsError(false);
    const inputText = e.target.value;
    const maxLength = 10;
  
    // Limit the input to a maximum length of 10 characters
    if (inputText.length <= maxLength) {
      setMobileNo(inputText);
    }

    setMobileNo(e.target.value);
    setMobileNo(e.target.value.replace(/\D/g, "").slice(0, 10)); // Remove non-digit characters
    const newMobile = e.target.value;
    setIsButtonDisabled(newMobile.trim() === "");
    if (newMobile.trim() === "") {
      setIsError(false);
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const newPassword = e.target.value;
    setIsPasswordButtonDisabled(newPassword.trim() === "");
    if (newPassword.trim() === "") {
      setIsError(false);
    }
  };
  const handleEmailpassKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLoginSubmit();
    }
  };

  const handleLoginSubmit = async () => {
    if (email === "") {
      showErrorToast("Email cannot be empty");
      setIsError(true);
      return;
    }
    else if (!emailRegex.test(email)) {
      showErrorToast("Please Enter Valid Email Address");
      setIsError(true);
      return;
    }
    else if (password === "") {
      showErrorToast("Password cannot be empty");
    }else{
    try {
      const response = await FetchLogin({
        email: email.trim(),
        mobile: mobile.trim(),
        password: password.trim(),
      });
      if (response.result == "success") {
        
        setUserTypeId(response?.data?.usertypeid)
        
        localStorage.setItem("usertypeid" ,response?.data?.usertypeid);
        localStorage.setItem("rolename" ,response?.data?.rolename);
        localStorage.setItem("countrycode" ,response?.data?.countryid);
        localStorage.setItem("countryid" ,response?.data?.countrycode);
         //console.log("Login successful:", response.output.data);
         const allPagesShowHideMainFunc = ()=>{
          if(!response?.data?.disablehome){
            navigate("/home");
          }else if(!response?.data?.disablevisits){
            navigate("/visits");
          }else if(!response?.data?.disablemessages){
            navigate("/messages");
          }else if(!response?.data?.disablepatients){
            navigate("/patients");
          }else if(!response?.data?.disableprescriptions){
            navigate("/prescriptions");
          }else if(!response?.data?.disablemedicines){
            navigate("/medicines");
          }else if(!response?.data?.disablereports){
            navigate("/reports");
          }else if(!response?.data?.disablenotifications){
            navigate("/notifications");
          }else if(!response?.data?.disableroles){
            navigate("/doctorprofile");
          }else if(!response?.data?.disableusers){
            navigate("/doctorprofile");
          }else if(!response?.data?.disableinventory){
            navigate("/doctorprofile");
          }else if(!response?.data?.disablefinance){
            navigate("/doctorprofile");
          }else if(!response?.data?.disableinvoices){
            navigate("/doctorprofile");
          }else{
            navigate("/doctorprofile");
          }
        }
          let setInlocal = () =>{
            // let pagesAccessObject = {
            //   disablehome: response.data.disablehome,
            //   disablevisits: response.data.disablevisits,
            //   disablemessages: response.data.disablemessages,
            //   disablepatients: response.data.disablepatients,
            //   disableprescriptions: response.data.disableprescriptions,
            //   disablemedicines: response.data.disablemedicines,
            //   disablereports: response.data.disablereports,
            //   disablenotifications: response.data.disablenotifications,
            //   disableroles: response.data.disableroles,
            //   disableusers: response.data.disableusers,
            //   disableinventory : response?.data?.disableinventory,
            //   disablefinance: response?.data?.disablefinance,
            //   disableinvoices: response?.data?.disableinvoices,
            // };
            let pagesAccessObject = {};

            if (response && response.data) {
              // Loop through the keys in the response.data object
              Object.keys(response.data).forEach(key => {
                if (key.startsWith('disable')) {
                  // Add each disable key to pagesAccessObject
                  pagesAccessObject[key] = response.data[key];
                }
              });
            }
          
            localStorage.setItem("pagesAccess" ,JSON.stringify(pagesAccessObject));
            localStorage.setItem("actionAccess" , JSON.stringify(response.data.screens));

      
         }
         if (!response.data.issuperuser && response.data.firsttimelogin == 1 
        //  &&  response.data.usertypeid != 3
            ){
          setRedirectToHome(false);
          redirectToNewPassword();
          setIsUser(true)
        }
        else{
          if (response.data.isverified === 2 && response.data.isdoctor == 1) {
              showErrorToast("Your account has been rejected!")
              return false;
            }
          // if (response.data.isverified === 0 && response.data.issuperuser == 1) {
          //   navigate("/request-submit")
          // }else{
            if (response.data.firstname) {
              saveFirstName(response.data.firstname);
              saveLastName(response.data.lastname)
            }
            if(response.data.currencyname){
              savecurrency(response.data.currencyname)
            }
            // Save the token
            if (response.token) {
              saveToken(response.token);
            }
            if (response.data.loginid) {
              saveLoginID(response.data.loginid);
            }

            if (response.data.mobileno) {
              saveMobile(response.data.countrycode +" "+ response.data.mobileno);
            }
            if (response.data.email) {
              saveEmail(response.data.email);
            }
            if (response.data.addressdetail) {
              saveAddressInfo(response.data.addressdetail);
            }
            if (response.data.hospitallogourl) {
              if(response.data.imagepath){
                saveLogo(response.data.imagepath + response.data.hospitallogourl);
              }
            }
            if (response.data.hospitalname) {
              saveHospitalName(response.data.hospitalname);
            }
            if (response.data.isdoctor) {
              saveIsDoctor(response.data.isdoctor);
            }
            if (response?.data) {
              saveLoginDetails(response?.data);
            }
              // if(response.data.firsttimelogin === 1){
                //if(response.data.isfinalstepcomplete === 0){
                //saveFirstTimeLogin(response.output.data.firsttimelogin)
                //navigate("/verification-success");
                //setInlocal()
                // allPagesShowHideMainFunc();
              //}else{
                allPagesShowHideMainFunc();
                setInlocal()
                //}
          // }
        }

       
         
        
        //navigate("/home");
        // Set the state to redirect to the dashboard
        //setRedirectToDashboard(true);
        
      }else if(response.failuretype === "2"){
        showErrorToast("The email you entered is incorrect.");
        return;
      }else if(response.failuretype === 1){
        showErrorToast("The password you entered is incorrect.");
        return;
      }
       else {
        setRedirectToHome(false);
        navigate("/signup")
        // let message = response.output.message; 
        // showErrorToast(message)
         showErrorToast("The email or password you entered is incorrect.")
      
      }
    } catch (error) {
      console.error("Login failed:", error.response.data);
    }
  }
  };
  //Verify User with Mobile
  const handleMobileSubmit = async () => {
    if (mobile === "") {
      showErrorToast("Mobile number cannot be empty");
      return;
    } else if (mobile.length < 10) {
      showErrorToast("Please Enter a Valid Mobile Number.");
      return;
    }
    else if (password === "") {
      showErrorToast("Password cannot be empty");
    }else{
      try {
        // var countrycode = localStorage.getItem("countryid")
        // let countryid = "";
        // if(countrycode === "+91"){
        //   countryid = "95";
        // }else if(countrycode === "+1"){
        //   countryid = "229";
        // }else if (countrycode == "+93") {
        //   countryid = "1";
        // }
        var countryCode = localStorage.getItem("countrycode")
        var countryId = localStorage.getItem("countryid")
       
        const response = await FetchMobileLogin({
          "mobile": mobile.trim(),
          "countryid": countryCode,
          "countrycode": countryId,
          "type": "login",
          "ispatient":"0",
          "password": password.trim()
          // "otp":"1234",
        });
        // const response = await generateOtp({
        //   "mobileno": mobile,
        //   "countryid": countryid,
        //   "countrycode": countrycode,
        //   "type": "login",
        //   "ispatient":"0",
        //   "otp":"1234",
        // });
      //   console.log(response, "otp resp")
      //   if (response.output.result === "success") {
      //     if(response.output.otp != null){
      //       setGeneratedOTP(response.output.otp);
      //       setMobileNumber(response.output.mobilenumber)
      //     }
      //     setShowMobileOTP(true);
      //     setIsEmailLoginClicked(false);
      //     setIsMobileLoginClicked(false);
      //     setshowBackButton(true);
         
      //   } else {
      //     showErrorToast("Uh-oh! The mobile number you have entered does not exist");
      //   }
      // } catch (error) {
      //   console.error("Error in fetching data");
      // }
        if (response.result === "success") {

          
        setUserTypeId(response?.data?.usertypeid);
        localStorage.setItem("usertypeid" ,response?.data?.usertypeid);
        localStorage.setItem("rolename" ,response?.data?.rolename);
        localStorage.setItem("countrycode" ,response?.data?.countryid);
        localStorage.setItem("countryid" ,response?.data?.countrycode);
          const allPagesShowHideMainFunc = ()=>{
            if(!response?.data?.disablehome){
              navigate("/home");
            }else if(!response?.data?.disablevisits){
              navigate("/visits");
            }else if(!response?.data?.disablemessages){
              navigate("/messages");
            }else if(!response?.data?.disablepatients){
              navigate("/patients");
            }else if(!response?.data?.disableprescriptions){
              navigate("/prescriptions");
            }else if(!response?.data?.disablemedicines){
              navigate("/medicines");
            }else if(!response?.data?.disablereports){
              navigate("/reports");
            }else if(!response?.data?.disablenotifications){
              navigate("/notifications");
            }else if(!response?.data?.disableroles){
              navigate("/doctorprofile");
            }else if(!response?.data?.disableusers){
              navigate("/doctorprofile");
            }else if(!response?.data?.disableinventory){
              navigate("/doctorprofile");
            }else if(!response?.data?.disablefinance){
              navigate("/doctorprofile");
            }else if(!response?.data?.disableinvoices){
              navigate("/doctorprofile");
            }else{
              navigate("/doctorprofile");
            }
          }
            let setInlocal = () =>{
              // let pagesAccessObject = {
              //   disablehome: response.data.disablehome,
              //   disablevisits: response.data.disablevisits,
              //   disablemessages: response.data.disablemessages,
              //   disablepatients: response.data.disablepatients,
              //   disableprescriptions: response.data.disableprescriptions,
              //   disablemedicines: response.data.disablemedicines,
              //   disablereports: response.data.disablereports,
              //   disablenotifications: response.data.disablenotifications,
              //   disableroles: response.data.disableroles,
              //   disableusers: response.data.disableusers,
              //   disableinventory : response?.data?.disableinventory,
              //   disablefinance: response?.data?.disablefinance,
              //   disableinvoices: response?.data?.disableinvoices,
              // };
              let pagesAccessObject = {};

              if (response && response.data) {
                // Loop through the keys in the response.data object
                Object.keys(response.data).forEach(key => {
                  if (key.startsWith('disable')) {
                    // Add each disable key to pagesAccessObject
                    pagesAccessObject[key] = response.data[key];
                  }
                });
              }
              
              localStorage.setItem("pagesAccess" ,JSON.stringify(pagesAccessObject));
              localStorage.setItem("actionAccess" , JSON.stringify(response.data.screens));
             
        
           }
           if (!response.data.issuperuser && response.data.firsttimelogin == 1 
            //  &&  response.data.usertypeid != 3
                ){
              setRedirectToHome(false);
              redirectToNewPassword();
              setIsUser(true)
            }

            if (response.data.isverified === 2 && response.data.isdoctor == 1) {
              showErrorToast("Your account has been rejected!")
              return false;
            }
          // if (response.data.isverified === 0  && response.data.issuperuser == 1) {
          //   navigate("/request-submit")
          // }else{
             if (response.data.firstname) {
              saveFirstName(response.data.firstname);
              saveLastName(response.data.lastname)
            }
            if(response.data.currencyname){
              savecurrency(response.data.currencyname)
            }
            // Save the token
            if (response.token) {
              saveToken(response.token);
            }
            if (response.data.loginid) {
              saveLoginID(response.data.loginid);
            }
            if (response.data.mobileno) {
              saveMobile(response.data.countrycode +" "+ response.data.mobileno);
            }
            if (response.data.email) {
              saveEmail(response.data.email);
            }
            if (response.data.addressdetail) {
              saveAddressInfo(response.data.addressdetail);
            }
            if (response.data.hospitallogourl) {
              if(response.data.imagepath){
                saveLogo(response.data.imagepath + response.data.hospitallogourl);
              }
            }
            if (response.data.hospitalname) {
              saveHospitalName(response.data.hospitalname);
            }
            if (response.data.isdoctor) {
              saveIsDoctor(response.data.isdoctor);
            }
            if (response?.data) {
              saveLoginDetails(response?.data);
            }
            //if(response.data.isfinalstepcomplete === 0){
              // if(response.data.firsttimelogin === 1){
                //saveFirstTimeLogin(response.output.data.firsttimelogin)
                //navigate("/verification-success")
              //}else{
                // navigate("/home");
                allPagesShowHideMainFunc();
                setInlocal()
              //}
          // }
          
        }else if(response.failuretype === "2"){
          showErrorToast("The mobile you entered is incorrect.");
          return;
        }else if(response.failuretype === 1){
          showErrorToast("The password you entered is incorrect.");
          return;
        } 
        else {
          setRedirectToHome(false);
          navigate("/signup")
          showErrorToast("The phone number you entered doesn't exists.")
        
        }
      } catch (error) {
        console.error("Login failed:", error.response.data);
      }
    }
  };
  const switchtomobile =() =>{
    setIsEmailLoginClicked(false);
    setIsMobileLoginClicked(true);
    setIsError(false);
    setMobileNo("")
  }
  const switchtoemail =() =>{
    setIsEmailLoginClicked(true);
    setIsMobileLoginClicked(false);
    setIsError(false);
    setMobileNo("")
  }
   // Enter Click Submit Mobile
   const handleMobileKeyPress = (e) => {
    if (e.key === "Enter") {
      handleMobileSubmit();
    }
  };
  const handlebackbutton =()=>{
    setShowForgotPassword(false);
    setshowBackButton(false);
    setIsEmailLoginClicked(true);
    setIsMobileLoginClicked(false);
    setShowMobileOTP(false)
  }
  const redirectToForgotPassword =()=>{
   
    localStorage.setItem("email" , email)
    setShowForgotPassword(true);
    setshowBackButton(true);
    setIsEmailLoginClicked(false);
    setIsMobileLoginClicked(false);
  }
  const redirectToNewPassword =()=>{
   
    localStorage.setItem("email" , email)
    setShowNewPassword(true)
    setShowForgotPassword(false);
    setshowBackButton(false);
    setIsEmailLoginClicked(false);
    setIsMobileLoginClicked(false);
  }
  const navigateToSignUp = () => {
    showLoader();
    navigate("/signup");
    dismissLoader();
};

  return (
    <>
      <Flex h="100vh" display={{base: "none", lg: "flex"}}>
        <VStack w="30%" bg="#151619" h="100vh" align="stretch">
          <Box h="100vh">
            <Image
              w="100%"
              h="100%"
              objectFit="cover"
              src="/assets/imgs/login-doctor.png"
            />
          </Box>

          <Box
            h="50%"
            p="3.5rem"
            pt="0px"
            boxShadow="0 -65px 68px 50px rgba(21,22,25,1)"
          >
            <Flex gap="0.625rem" w="50%">
              <Box h="4px" w="33.33%" borderRadius="full" bg="#FCFCFD1A">
                <Box bg="white" w="35%" h="100%" borderRadius="full"></Box>
              </Box>
              <Box h="4px" w="33.33%" borderRadius="full" bg="#FCFCFD1A"></Box>
              <Box h="4px" w="33.33%" borderRadius="full" bg="#FCFCFD1A"></Box>
            </Flex>

            <Box mt="1.875rem">
              <Text
                fontSize={{sm: "1.75rem", xl: "2rem"}}
                color="white"
                fontWeight="600"
                lineHeight="normal"
              >
                Seamlessly manage your appointments
              </Text>
              <Text
                mt="1.25rem"
                fontSize="1rem"
                color="#CDCFD4"
                fontWeight="400"
                lineHeight="normal"
              >
                Create your schedule and automatically take appointments through
                midoc.
              </Text>
            </Box>
          </Box>
        </VStack>
        {isLoading && <Loader />}

        <Box
          w="70%"
          bg="#FCFCFD"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          {!isEmailClicked && showBackButton &&(
          <Box
            as="button"
            bg="white"
            pos="relative"
            right="45%"
            top="3%"
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="10px 16px"
            border="1px solid #E6E7E9"
            borderRadius="full"
            w="64px"
            boxShadow="0px 8px 20px 0px #E6E7E966"
            onClick={handlebackbutton}
          >
            <Image w="20px" src="/assets/imgs/back-arrow.png" />
          </Box>
          )}
          <Center h="100vh">
            {/* Login with email start */}
            {!isMobileLoginClicked && isEmailClicked&&(
            <Flex flexDir="column" alignItems="center" w="400px">
              <Image
                h="2.5rem"
                w="auto"
                mb="2rem"
                src="/assets/imgs/midoc-black.png"
              />

              <Box as="h1" fontSize="1.5rem" fontWeight="600">
                Login
              </Box>
              <Box mb="1.25rem">
                <Text fontSize="0.875rem" fontWeight="400">
                  Enter your email address to continue
                </Text>
              </Box>
              <InputGroup>
                <PrimaryInput 
                inputPlace="Enter Email Address" 
                onChange={(e) => handleEmailChange(e)}               
                inputValue={email}
                autoFocus={true}
                />
                {isError && (
                  <InputRightElement h="65%" mr="5px" bg="white" top="10px">
                    <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                  </InputRightElement>
                )}
              </InputGroup>
              <InputGroup>
                <PasswordInput
                  passInputPlace="Enter your Password"                 
                  variant="downSide"
                  onChange={(e) => handlePasswordChange(e)}
                  onKeyPress={handleEmailpassKeyPress}
                />
                {isError && (
                  <InputRightElement h="100%">
                    <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                  </InputRightElement>
                )}
              </InputGroup>
              <Box
                as="button"
                textDecor="underline"
                fontSize="14px"
                fontWeight="700"
                my="20px"
                onClick={redirectToForgotPassword}
              >
                Forgot Password?
              </Box>
              <PrimaryButton 
              width="full" 
              buttonText="Continue"
              isDisabled={isPasswordButtonDisabled}
              onClick={() => {
                handleLoginSubmit();
              }}
              />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                w="100%"
                my="0.75rem"
              >
                <Divider opacity="1" w="50%" />
                <Box
                  as="span"
                  p="10px"
                  fontSize="12px"
                  fontWeight="700"
                  color="#6A707D"
                >
                  OR
                </Box>
                <Divider opacity="1" w="50%" />
              </Box>
              <PrimaryButton
                width="100%"
                variant="grayBtnFull"
                buttonText="Login with phone number"
                onClick={switchtomobile}
              />
              <Box fontSize="14px" mt="1.4rem" fontWeight="400">
                Don’t have an account?{" "}
                <Box as="button" fontWeight="600" textDecor="underline"
                onClick={navigateToSignUp}>
                  Signup now
                </Box>
              </Box>
            </Flex>
            )}
            {/* Login with email end */}

            {/* Login with mobile start */}
            {!isEmailClicked && isMobileLoginClicked &&(
            <Flex flexDir="column" alignItems="center" w="400px">
              <Image
                h="2.5rem"
                w="auto"
                mb="2rem"
                src="/assets/imgs/midoc-black.png"
              />

              <Box as="h1" fontSize="1.5rem" fontWeight="600">
                Login
              </Box>
              <Box mb="1.25rem">
                <Text fontSize="0.875rem" fontWeight="400">
                  Enter your mobile number to continue
                </Text>
              </Box>
              <InputGroup>
                <PrimaryInputDropdown
                  variant="defaultSide"
                  inputStyle={{borderRadius:"0 0 10px 10px"}}
                  onChange={(e) => handleMobileChange(e)}
                  onKeyPress={handleMobileKeyPress}
                  inputValue={mobile}
                />
                {isError && (
                  <InputRightElement h="100%">
                    <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                  </InputRightElement>
                )}
              </InputGroup>
              <InputGroup>
                <PasswordInput
                  passInputPlace="Enter your Password"                 
                  variant="downSide"
                  onChange={(e) => handlePasswordChange(e)}
                  onKeyPress={handleMobileKeyPress}
                />
                {isError && (
                  <InputRightElement h="100%">
                    <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                  </InputRightElement>
                )}
              </InputGroup>
              <Box
                as="button"
                textDecor="underline"
                fontSize="14px"
                fontWeight="700"
                my="20px"
                onClick={redirectToForgotPassword}
              >
                Forgot Password?
              </Box>
              <PrimaryButton
               width="full"
                buttonText="Login" 
                isDisabled={isButtonDisabled}
                onClick={() => {
                  handleMobileSubmit();
                }}
                />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                w="100%"
                my="0.75rem"
              >
                <Divider opacity="1" w="50%" />
                <Box
                  as="span"
                  p="10px"
                  fontSize="12px"
                  fontWeight="700"
                  color="#6A707D"
                >
                  OR
                </Box>
                <Divider opacity="1" w="50%" />
              </Box>
              <PrimaryButton
                width="100%"
                variant="grayBtnFull"
                buttonText="Login with email"
                onClick={switchtoemail}
              />
              <Box fontSize="14px" mt="55px" fontWeight="400">
                Don’t have an account?{" "}
                <Box as="button" fontWeight="600" textDecor="underline"
                onClick={navigateToSignUp}>
                  Signup now
                </Box>
              </Box>
            </Flex>
            )}
            {/* Login with mobile end */}

            {/* OTP component start*/}
            {/* {ShowMobileOTP && <OtpIndexForgotPassword otp={GeneratedOTP} mobile={MobileNumber} countrycode={countrycode}/>} */}
            {/* OTP component end */}

            {/* Forgot Password start */}
            {ShowForgotPassword && <ForgotPassword doctoremail={email}/>}
            {ShowNewPassword && <NewPassword  email={email} isUser={isUser} userTypeId={userTypeId} />}
            {/* Forgot Password end */}
          </Center>

          {/* Footer area */}
          <Flex
            w="100%"
            h="58px"
            p="20px 32px"
            justifyContent="space-between"
            alignItems="center"
            borderTop="1px solid #E6E7E9"
          >
            <Box display="flex" alignItems="center" gap="10px">
              <Text fontSize="8px" fontWeight="400">
                A PRODUCT OF
              </Text>
              <Image src="/assets/svgs/tci-logo.svg" />
            </Box>
            <Box>
              <Text fontSize="10px" fontWeight="500">
                © {date.getFullYear()} midoc inc.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>

      {/* Mobile responsive start */}
      <Flex flexDir="column" display={{base: "flex", lg: "none"}}>
      {isLoading && <Loader />}
      <Box
        w="100%"
        bg="#FCFCFD"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        {!isEmailClicked && showBackButton &&(
        <Box
          as="button"
          bg="white"
          pos="relative"
          right="35%"
          top="3%"
          mt="1rem"
          color="white"
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="10px 16px"
          border="1px solid #E6E7E9"
          borderRadius="full"
          w="64px"
          boxShadow="0px 8px 20px 0px #E6E7E966"
          onClick={handlebackbutton}
        >
          <Image w="20px" src="/assets/imgs/back-arrow.png" />
        </Box>
        )}
        <Center h="calc(100vh - 110px)" mt={ShowForgotPassword ? "-55px" : ""}>
          {/* Login with email start */}
          {!isMobileLoginClicked && isEmailClicked&&(
          <Flex flexDir="column" alignItems="center" w="100%">
            <Image
              h="2.5rem"
              w="auto"
              mb="2rem"
              src="/assets/imgs/midoc-black.png"
            />

            <Box as="h1" fontSize="1.5rem" fontWeight="600">
              Login
            </Box>
            <Box mb="1.25rem">
              <Text fontSize="0.875rem" fontWeight="400">
                Enter your email address to continue
              </Text>
            </Box>
            <InputGroup>
              <PrimaryInput 
              inputPlace="Enter Email Address" 
              onChange={(e) => handleEmailChange(e)}               
              inputValue={email}
              autoFocus={true}
              />
              {isError && (
                <InputRightElement h="65%" mr="5px" bg="white" top="10px">
                  <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                </InputRightElement>
              )}
            </InputGroup>
            <InputGroup>
              <PasswordInput
                passInputPlace="Enter your Password"                 
                variant="downSide"
                onChange={(e) => handlePasswordChange(e)}
                onKeyPress={handleEmailpassKeyPress}
              />
              {isError && (
                <InputRightElement h="100%">
                  <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                </InputRightElement>
              )}
            </InputGroup>
            <Box
              as="button"
              textDecor="underline"
              fontSize="14px"
              fontWeight="700"
              my="20px"
              onClick={redirectToForgotPassword}
            >
              Forgot Password?
            </Box>
            <PrimaryButton 
            width="full" 
            buttonText="Continue"
            isDisabled={isPasswordButtonDisabled}
            onClick={() => {
              handleLoginSubmit();
            }}
            />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              w="100%"
              my="0.75rem"
            >
              <Divider opacity="1" w="50%" />
              <Box
                as="span"
                p="10px"
                fontSize="12px"
                fontWeight="700"
                color="#6A707D"
              >
                OR
              </Box>
              <Divider opacity="1" w="50%" />
            </Box>
            <PrimaryButton
              width="100%"
              variant="grayBtnFull"
              buttonText="Login with phone number"
              onClick={switchtomobile}
            />
            <Box fontSize="14px" mt="1.4rem" fontWeight="400">
              Don’t have an account?{" "}
              <Box as="button" fontWeight="600" textDecor="underline"
              onClick={navigateToSignUp}>
                Signup now
              </Box>
            </Box>
          </Flex>
          )}
          {/* Login with email end */}

          {/* Login with mobile start */}
          {!isEmailClicked && isMobileLoginClicked &&(
          <Flex flexDir="column" alignItems="center" w="100%">
            <Image
              h="2.5rem"
              w="auto"
              mb="2rem"
              src="/assets/imgs/midoc-black.png"
            />

            <Box as="h1" fontSize="1.5rem" fontWeight="600">
              Login
            </Box>
            <Box mb="1.25rem">
              <Text fontSize="0.875rem" fontWeight="400">
                Enter your mobile number to continue
              </Text>
            </Box>
            <InputGroup>
              <PrimaryInputDropdown
                variant="roundSide"
                inputStyle={{borderRadius:"0 0 10px 10px"}}
                onChange={(e) => handleMobileChange(e)}
                onKeyPress={handleMobileKeyPress}
                inputValue={mobile}
              />
              {isError && (
                <InputRightElement h="100%">
                  <Image src="/assets/imgs/error.png" w="18px" h="auto" />
                </InputRightElement>
              )}
            </InputGroup>
            <Box
              as="button"
              textDecor="underline"
              fontSize="14px"
              fontWeight="700"
              my="20px"
              onClick={redirectToForgotPassword}
            >
              Forgot Password?
            </Box>
            <PrimaryButton
            width="full"
              buttonText="Login" 
              isDisabled={isButtonDisabled}
              onClick={() => {
                handleMobileSubmit();
              }}
              />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              w="100%"
              my="0.75rem"
            >
              <Divider opacity="1" w="50%" />
              <Box
                as="span"
                p="10px"
                fontSize="12px"
                fontWeight="700"
                color="#6A707D"
              >
                OR
              </Box>
              <Divider opacity="1" w="50%" />
            </Box>
            <PrimaryButton
              width="100%"
              variant="grayBtnFull"
              buttonText="Login with email"
              onClick={switchtoemail}
            />
            <Box fontSize="14px" mt="55px" fontWeight="400">
              Don’t have an account?{" "}
              <Box as="button" fontWeight="600" textDecor="underline"
              onClick={navigateToSignUp}>
                Signup now
              </Box>
            </Box>
          </Flex>
          )}
          {/* Login with mobile end */}

          {/* OTP component start*/}
          {ShowMobileOTP && <OtpIndexForgotPassword otp={GeneratedOTP} mobile={MobileNumber} countrycode={countrycode}/>}
          {/* OTP component end */}

          {/* Forgot Password start */}
          {ShowForgotPassword && <ForgotPassword doctoremail={email} />}
          {/* Forgot Password end */}
        </Center>

        {/* Footer area */}
        <Flex
          w="100%"
          h="58px"
          p="20px"
          justifyContent="space-between"
          alignItems="center"
          borderTop="1px solid #E6E7E9"
        >
          <Box display="flex" alignItems="center" gap="10px">
            <Text fontSize="8px" fontWeight="400">
              A PRODUCT OF
            </Text>
            <Image src="/assets/svgs/tci-logo.svg" />
          </Box>
          <Box>
            <Text fontSize="10px" fontWeight="500">
              © {date.getFullYear()} midoc inc.
            </Text>
          </Box>
        </Flex>
      </Box>
      </Flex>
    </>
  );
};

export default LoginIndex;
