import moment from "moment";
export default function formAPIModelpatientinfo({
    firstname,
    gender,
    email,
    dob,
    height,
    heightunit,
    lastname,
    mobileno,
    patientid,
    weight,
    weightunit,
    imagepath,
    patientimage,
    countrycode,
    countryid,
    lastupdatedate, 
    nextappointmentdate,
    nextappointmenttime,
    uniqueid,
    address,
    emergencycontactname,
    emergencycontactphone,
    emergencycontactcountryid,
    emergencycontactcountrycode,
    nextappointmentstatus,
})
{
  var formattedDate = null;
  if(dob){
    formattedDate = moment(dob).format("Do MMMM, YYYY");
  }
  return Object.freeze({
    firstname,
    gender,
    email,
    dob: formattedDate,
    "dobDbFormat":dob,
    height,
    heightunit,
    lastname,
    mobileno,
    patientid,
    weight,
    weightunit,
    imagepath,
    patientimage,
    countrycode,
    countryid,
    lastupdatedate,
    nextappointmentdate,
    nextappointmenttime,
    uniqueid,
    patientaddress:address,
    emergencycontactname,
    emergencycontactphone,
    emergencycontactcountryid,
    emergencycontactcountrycode,
    nextappointmentstatus
   
  });
}