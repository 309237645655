import React, { useRef, useState,useEffect, useLayoutEffect } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Card,
  CardFooter,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  InputGroup,
  Menu,
  MenuButton,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  MenuList,
  MenuItem,
  InputRightElement,
  Input,
  Td,
  Tr,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  Stepper,
  StepSeparator,
  UnorderedList,
  ListItem,
  CardBody,
  position,
  SimpleGrid,
  Radio,
  RadioGroup,
  InputLeftAddon,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import GrayCard from "../../components/card";
import SearchBar from "../../components/search-bar";
import TypingLoader from "../../components/typing-loader";
import {COLORS} from "../../components/styles/colors";
import ToggleTab from "../../components/accordion";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { Link, NavLink, useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SimpleTable from "../../components/simple-table";
import moment from "moment";
import {
  CreateNewVisit,
  GetDoctorSlots,
  GetPatientStatus,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes.js";

import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
} from "react-router-dom";
import { FetchDoctorSlots, FetchFilteredPatientsList, FetchPatientViewDetails, FetchPatientsForPatientView, ResheduleAppointmentByDoctor, ScheduleAppointmentByDoctor, UpdatePatientProfile } from "../../middleware/services/patients";
import { actionAccessMain,getCurrency, formatDate_YYYY_MM_DD, formatToDecimal, getIsDoctor, getLoginID, useScreenType, calculateAgeCommonFunc, calculateAgeWithYearMonth } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import {
  DropdownInput,
  PasswordInput,
  PrimaryInput,
  PrimaryInputDropdown,
  Toaster,
} from "../../components";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";
import {
  FetchDoctorsByUser,
} from "../../middleware/services/home";
import { FetchPatientDetails } from "../../middleware/services/messages/index.js";


const PatientViewIndex = () => {

  const newVisitPatientStatus = useRef();
  const scheduleVisit = useRef();
  const rescheduleVisit = useRef();
  const cancelVisit = useRef();

  const [appointmentRescheduleDate, setAppointmentRescheduleDate] = useState(
    new Date()
  );
  const [appointmentRescheduleTime, setAppointmentRescheduleTime] =
    useState(null);

  var [doctorid, setDoctorid] = useState();
  var [patientid, setPatientID] = useState("");
  var [appointmentid, setAppointmentID] = useState("");
  var [data, setData] = useState(null);
  var [patientViewData, setPatientViewData] = useState([]);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { showSuccessToast, showErrorToast } = Toaster();
  const screenType = useScreenType()
  const navigate = useNavigate();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [patientName, setPatientName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [patientPlan, setPatientPlan] = useState([])
  const [plan, setPlan] = useState([])
  const [noData, setNoData] = useState(false);

  const [name, setName] = useState("");
  // const [visitDate, setVisitDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  
  let itemPerPageCommonValue = 50;

  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);
  let [pageno, setPageno] = useState(1);

  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);

  const [showFilterCount, setShowFilterCount] = useState(false);
  
  const [ toDateMinMax , setToDateMinMax] = useState("")
  const [ fromDateMinMax , setFromDateMinMax] = useState("")
  
  const [actionAccessAppointments, setActionAccessAppointments] = useState(null);
  const [actionAccessVisits, setActionAccessVisits] = useState(null);

  const [pageLoad, setPageLoad] = useState(true);
  const [currency, setCurrency] = useState("");
  const { isOpen, onToggle } = useDisclosure()

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (time) => {
    if (!time) {
      return ""; 
    }

    const [hours, minutes] = time.split(':');
  
    const date = new Date();
    date.setHours(hours, minutes);
  
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  
    return formattedTime;
  };
  let doctorcountrycode =  localStorage.getItem("countryid");
  let doctorcountryid = localStorage.getItem("countrycode");
  const TableHeader = [
    { key: "patient", label: "Patient" },
    { key: "gender/age", label: "Gender/Age" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile No" },
    { key: "appointmentDateTime", label: "Last Appointment Date/Time" },
    { key: "appointmentStatus", label: "Last Appointment Status" },
    { key: "actions", label: "Actions" },
  ];

  // Pagination Functionality & Page Load

  const handleChangeName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z0-9\s@.]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setName(event.target.value);
  };


  const handleFromDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
   // console.log('formattedDAte: ', formattedDate)
    setFromDate(formattedDate);

    setFromDateMinMax(selectedDate);
  };
  const handleToDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setToDate(formattedDate);

    setToDateMinMax(selectedDate);
  };

  // const handleAppointmentDate = (selectedDate) => {
  //   const formattedDate = format(selectedDate, "dd-MM-yyyy");
  //   console.log('formattedDAte: ', formattedDate)
  //   setVisitDate(formattedDate);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // ApplyFunction();
    // handleApplyFilters()
  };

  useEffect(() => {
    setActionAccessAppointments(actionAccessMain(15))
    setActionAccessVisits(actionAccessMain(2))
    getPatientsList();
    const currency = getCurrency();
    if(currency == "USD"){
      const uscurrency = '$'
      setCurrency(uscurrency);
    }else{
    setCurrency(currency);
    }
  }, [refresh])
  var input = {
    "doctorid": getLoginID(),
    "pageno": 1,
    pagesize
  }
  const getPatientsList = async () => {
    showLoader()
    try{
      var result = await FetchPatientViewDetails(input);

      if(result?.data.length > 0){
        
        setPatientViewData(result.data);
        // setNoData(result.data.length === 0);
        let count = result?.count;
        let pageno = result?.pageno;
        let pagesize = result?.pagesize;
        setTotalItemCount(count)
        let totalPagesCalc = count / pagesize;
        let totalPagesCount = Math.ceil(totalPagesCalc);

        setTotalPages(totalPagesCount);

        // if(count < itemPerPageCommonValue){
        //   setCountTo(count)
        //   setTotalExceedsPageCount(true)
        // }else if(count > itemPerPageCommonValue && pageno == 1) {
        //   setCountTo(itemPerPageCommonValue);
        //   setTotalExceedsPageCount(false);
        // }

        setTotalItemCount(count)
        if(result && result.count  < itemPerPageCommonValue){
          setCountTo(result.count)
          setTotalExceedsPageCount(true)
        }else if(count > itemPerPageCommonValue && pageno == 1) {
          setCountTo(itemPerPageCommonValue);
          setTotalExceedsPageCount(false);
        }
      }else{
       setPatientViewData([])
      }
  
    } catch (error) {
      console.error("Error in home:", error);
    } finally{
      dismissLoader();
      // setTimeout(() => {
        
      // }, 2000);
    }
  };
  const getFilteredResults = () => {
    // if(!name && !email && !specialization && (!status || status== '')){
    //       showErrorToast('Please enter the inputs to filter.')
    //       return;
    //     }
    const tableContainer = document.querySelector('.tableContainer');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
    showLoader();
    var input = {
      "doctorid": getLoginID(),
        "searchtext": name.trim(),
        "startdate": formatDate_YYYY_MM_DD(fromDate),
        "enddate": formatDate_YYYY_MM_DD(toDate),
        "pageno": pageno,
        pagesize
    };
    var getPatientsList = async () => {
      
      try {
        var result = await FetchFilteredPatientsList(input);

        if (result?.data) {
          setPatientViewData(result.data);
          // setNoData(result.data.length === 0);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          // alert(totalPages)
          setTotalPages(totalPagesCount);
          // setFilteredData(result.data);

          // if (count < itemPerPageCommonValue) {
          //   setCountTo(count);
          //   setTotalExceedsPageCount(true);
          // }else if(count > itemPerPageCommonValue && pageno == 1) {
          //   setCountTo(itemPerPageCommonValue);
          //   setTotalExceedsPageCount(false);
          // }

          setTotalItemCount(count)
          if(result && result.count  < itemPerPageCommonValue){
            setCountTo(result.count)
            setTotalExceedsPageCount(true)
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
        }else{
          setPatientViewData([])
        }
      
      } catch (error) {
        console.log(error, "error in getDoctorsList");
      }
      dismissLoader();
    };

    getPatientsList();
  };

  const handleApplyFilters = () => {
    // if(!patientName && !email && !mobile && (!plan || plan== '')){
    //   showErrorToast('Please enter the inputs to filter.')
    //   return;
    // }
    setShowFilterCount(true);
    setPageno(1)
    setCurrentPage(1)
    getFilteredResults();
  };

  const resetFields = () => {
    setName('')
    setFromDate('')
    setToDate('')
    setPageno(1);
    setFromDateMinMax(null)
    setToDateMinMax(null)
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1)
    setCountTo(itemPerPageCommonValue)
    setCurrentPage(1)
    setShowFilterCount(false);

    setRefresh(!refresh);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // console.log('Enter key pressed');
      handleApplyFilters();
    }
  };

  useEffect(()=>{
    if(pageno && !pageLoad){
      
       getFilteredResults();
       }
       setPageLoad(false)
  },[pageno])

  const handleNextClick = () => {
    let page = pageno +1 ;
  //  alert(page)
    setPageno(page);
    setCurrentPage(currentPage + 1);
   // getFilteredResults();
   
   // item count logic for next
   if(countTo + pagesize < totalItemCount){
    setCountTo(countTo + pagesize)
   }else{
     setCountTo(totalItemCount)
   }
   
   setCountFrom(countFrom + pagesize)
  };
  const handlePrevClick = () => {
    let page = pageno -1 ;
    //alert(page)
    setPageno(page);
   // setPageno(pageno - 1);
    setCurrentPage(currentPage - 1);
   // getFilteredResults();
   // item count logic for previous
   setCurrentPage(currentPage - 1);
   if(countTo == totalItemCount){
        //setCountTo(Math.floor(totalItemCount / 10) * 10)
      let updatedCountUnroundedValue  = totalItemCount - pagesize;
      setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )

   }else{
     setCountTo(countTo - pagesize)
   }
   
   setCountFrom(countFrom - pagesize)
  };

  // View Profile Functionality
  const handleClick = async (appointment, patient) => {
    // if (appointment && patient) {
    
    localStorage.setItem("previousPage" , "patients")
      navigate(
        `/patientprofile?id=${patient}&page=overview`
      );
      // navigate(
      //   `/visitdetails?patientId=${patient}&appointmentId=${appointment}`
      // );
    // } else {
    //   console.error("Error: Visit details are incomplete.");
    // }
  };

  // ---------------------------------------------------------------------
  // Schedule Visit Functionality

  const [availableSlots, setAvailableSlots] = useState([]);

  const fetchDoctorSlots = async () => {
    // console.log(appointmentRescheduleDate);
    showLoader();
    const inputToFetch = {
      input: {
        // doctorid: getLoginID(),
        doctorid: Doctorid,
        date: appointmentRescheduleDate
          ? moment(appointmentRescheduleDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
      },
    };
    // console.log(inputToFetch);
    
    try {
      const data = await FetchDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];
      // console.log("New Slots: ", slots);
      const currentDate = moment().format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        appointmentRescheduleDate
      )
        ? appointmentRescheduleDate
        : moment(appointmentRescheduleDate).format("DD-MM-YYYY");
      if (
        appointmentRescheduleDate &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("first case");
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        setAvailableSlots(filteredSlots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      
        dismissLoader();
      
     
    }
  };

  // useEffect(() => {
  //   fetchDoctorSlots();
  // }, [appointmentRescheduleDate]);

  const [timePeriod, setTimePeriod] = useState("Morning");

  const SlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      setAppointmentRescheduleTime(slot.starttime);
      const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
      if (
        selectedTime >= new Date("1970-01-01T09:00:00") &&
        selectedTime < new Date("1970-01-01T12:00:00")
      ) {
        setTimePeriod("Morning");
      } else if (
        selectedTime >= new Date("1970-01-01T12:00:00") &&
        selectedTime < new Date("1970-01-01T18:00:00")
      ) {
        setTimePeriod("Afternoon");
      } else if (
        selectedTime >= new Date("1970-01-01T18:00:00") &&
        selectedTime < new Date("1970-01-01T21:00:00")
      ) {
        setTimePeriod("Evening");
      } else {
        setTimePeriod("Night");
      }
      // console.log(timePeriod);
    };

    return (
      <Box>
        {isLoading && <Loader/>}

        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots{" "}
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "white"
                  }
                  color={
                    appointmentRescheduleTime === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      appointmentRescheduleTime === slot.starttime
                        ? "blue.600"
                        : "gray.200",
                  }}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };
//Schedule Visit
const newVisit = useRef();
const editProfileModal = useRef();
var [DoctorList, setDoctorList] = useState([]);
var [DoctorListForDropdown, setDoctorListForDropdown] = useState([]);
var [DefaultDoctor, setDefaultDoctor] = useState(null);
const [selectedCountry, setSelectedCountry] = useState("+1");
const [selectedCountryId, setSelectedCountryId] = useState("");
const [SelectedCountryForEmergency, setSelectedCountryForEmergency] = useState("+1");
const [SelectedCountryIdForEmergency, setSelectedCountryIdForEmergency] = useState("");
const handleCountryChange = (country) => {
  setSelectedCountry(country.code);
  setSelectedCountryId(country.countryid);
  setSelectedCountryForEmergency(country.code);
  setSelectedCountryIdForEmergency(country.countryid);
  setNewVisitData({
    firstname: newVisitData.firstname,
        lastname: newVisitData.lastname,
        age: newVisitData.age,
        gender: newVisitData.gender,
        dob: newVisitData.dob,
        mobileno: newVisitData.mobileno,
        email: newVisitData.email,
        countryid: country.countryid,
        countrycode: country.code,
        emergencycontactcountryid:country.countryid,
        emergencycontactcountrycode:country.code,
        appointmentfor: "",
        appointmenttype_cd: 0,
        schedule_later: "Schedule Now",
        appointment_date: moment().format("DD-MM-YYYY"),
        appointment_time: new Date().toTimeString().substring(0, 5),
        createdby: newVisitData.createdby,
        // fees:newVisitData.fees,
        fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : newVisitData.fees),
        isfreevisit:  newVisitData?.isfreevisit,
  
  });
};
const handleCountryChangeForEmergency = (country) => {
  setSelectedCountryForEmergency(country.code);
  setSelectedCountryIdForEmergency(country.countryid);
};
var [DoctorFees, setDoctorFees] = useState(null);
var [DoctorFeesStatic, setDoctorFeesStatic] = useState(null);
var [Doctorid, setDoctorid] = useState(null);
const [newVisitData, setNewVisitData] = useState({
  firstname: "",
  lastname: "",
  age: null,
  month:null,
  gender: 2,
  dob: "",
  mobileno: "",
  email: "",
  countryid: "",
  countrycode: "",
  emergencycontactcountryid:"",
  emergencycontactcountrycode:"",
  appointmentfor: "",
  appointmenttype_cd: 0,
  schedule_later: "Schedule Now",
  appointment_date: "",
  appointment_time: "",
  createdby :"",
  fees:"",
  isfreevisit: "1",
  address:"",
  emergencycontactname:"",
  emergencycontactphone:"",
});
const fetchDoctorSlotsforNewVisit = async () => {
  const inputToFetch = {
    input: {
      doctorid: Doctorid,
      date: newVisitData.appointment_date
        ? moment(newVisitData.appointment_date, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
        : null,
    },
  };
  //(inputToFetch);
  showLoader();
  try {
    const data = await GetDoctorSlots(inputToFetch.input);
    const slots = data?.output?.data || [];

    const currentDate = moment().format("DD-MM-YYYY");
    function isDateInCorrectFormat(dateStr) {
      const regex = /^\d{2}-\d{2}-\d{4}$/;
      return regex.test(dateStr);
    }

    const formattedDate = isDateInCorrectFormat(newVisitData.appointment_date)
      ? newVisitData.appointment_date
      : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
    if (newVisitData.appointment_date && formattedDate === currentDate) {
      // console.log(newVisitData.appointment_date);
      // console.log(currentDate);
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const filteredSlots = slots.filter((slot) => {
        const [slotHours, slotMinutes] = slot.starttime
          .split(":")
          .map(Number);
        return (
          slotHours > currentHours ||
          (slotHours === currentHours && slotMinutes >= currentMinutes)
        );
      });
      // console.log(filteredSlots.length);
      // console.log(slots.length);
      setAvailableSlots(slots);
    }
    // console.log("New Slots: ", slots);
    else {
      // console.log("Other case");
      setAvailableSlots(slots);
    }
  } catch (error) {
    console.error("Error fetching Doctor Slots data:", error);
  } finally {
    dismissLoader();
  }
};
const HandleEditDoctor = (selectedOption)=>{
  let obj = DoctorList.filter(doctor => doctor.fullname == selectedOption);
  setDoctorid(obj[0].doctorid);
  // setDoctorFees(formatToDecimal(obj[0].fee));
    setDoctorFeesStatic(obj[0].fee);
              if(newVisitData?.isfreevisit === "1"){
  
                setDoctorFees("0.00");
  
              }else{
  
              setDoctorFees(formatToDecimal(obj[0].fee));
              }
  setAvailableSlots([]);
          fetchDoctorSlots();
          setTimePeriod("Morning");
          setAppointmentRescheduleTime(null);
          setAppointmentRescheduleDate(new Date());
          setNewVisitData({
            firstname: newVisitData.firstname,
                lastname: newVisitData.lastname,
                age: newVisitData.age,
                gender: newVisitData.gender,
                dob: newVisitData.dob,
                mobileno: newVisitData.mobileno,
                email: newVisitData.email,
                countryid: selectedCountryId,
                countrycode: selectedCountry,
                emergencycontactcountryid:doctorcountryid,
                emergencycontactcountrycode:doctorcountrycode,
                appointmentfor: "",
                appointmenttype_cd: 0,
                schedule_later: "Schedule Now",
                appointment_date: moment().format("DD-MM-YYYY"),
                appointment_time: new Date().toTimeString().substring(0, 5),
            createdby: obj[0].doctorid,
            // fees:obj[0].fee,
            fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : obj[0].fee),
            isfreevisit:  newVisitData?.isfreevisit,
          });
}
const handleNewVisitChange = (field, value) => {
  setNewVisitData((prev) => ({ ...prev, [field]: value }));
};
function getGender(value) {
  switch (value) {
    case "female":
      return "Female";
    case "male":
      return "Male";
    case "other":
      return "Other";
    default:
      return "Male"; // In case an unexpected value is passed
  }
}
function formatgender(value) {
  if (typeof value !== "string") {
    return value; // Return the value as is if it's not a string
  }

  switch (value.toLowerCase()) { // Optional: Make it case-insensitive
    case "female":
      return 1;
    case "male":
      return 2;
    case "other":
      return 3;
    default:
      return 2; // Default value for unexpected strings
  }
}
const handleGenderChange = (value) => {
  let numericValue;
  switch (value) {
    case "Male":
      numericValue = 2;
      break;
    case "Female":
      numericValue = 1;
      break;
    case "Other":
      numericValue = 3;
      break;
    default:
      numericValue = null;
  }
  // console.log("Setting gender: ", value, numericValue);
  handleNewVisitChange("gender", numericValue);
};
useEffect(() => {
  if(newVisitData.appointment_date != ""){
    fetchDoctorSlotsforNewVisit();
  }
}, [newVisitData.appointment_date, Doctorid]);
const NewSlotSelector = ({ availableSlots }) => {
  const [selectedSlot, setSelectedSlot] = useState(null);
  const currentDate = moment().format("DD-MM-YYYY");
  const currentTime = new Date();

  const isSlotDisabled = (slot) => {
    const slotTime = new Date(`1970-01-01T${slot.starttime}`);
    const slotDate = moment(newVisitData.appointment_date).format(
      "DD-MM-YYYY"
    );

    function isDateInCorrectFormat(dateStr) {
      const regex = /^\d{2}-\d{2}-\d{4}$/;
      return regex.test(dateStr);
    }

    const formattedRescheduleDate = isDateInCorrectFormat(
      newVisitData.appointment_date
    )
      ? newVisitData.appointment_date
      : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
    if (
      newVisitData.appointment_date &&
      formattedRescheduleDate === currentDate
    ) {
      // console.log("Same day events")

      // If the slot is for today and its time has passed, disable it

      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);

      return (
        slotHours < currentHours ||
        (slotHours === currentHours && slotMinutes < currentMinutes)
      );
    }

    // If it's not today, don't disable
    return false;
  };

  const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
  const timePeriodSlots = {
    Morning: availableSlots.filter(
      (slot) =>
        new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T09:00:00") &&
        new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T12:00:00")
    ),
    Afternoon: availableSlots.filter(
      (slot) =>
        new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T12:00:00") &&
        new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T18:00:00")
    ),
    Evening: availableSlots.filter(
      (slot) =>
        new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T18:00:00") &&
        new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T21:00:00")
    ),
    Night: availableSlots.filter(
      (slot) =>
        new Date(`1970-01-01T${slot.starttime}`) >=
        new Date("1970-01-01T21:00:00")
    ),
  };

  const handleTimePeriodChange = (direction) => {
    const currentIndex = timePeriods.indexOf(timePeriod);
    const newIndex =
      (currentIndex + direction + timePeriods.length) % timePeriods.length;
    setTimePeriod(timePeriods[newIndex]);
  };

  const handleSlotSelection = (slot) => {
    if (!isSlotDisabled(slot)) {
      // console.log("Selection made")
      setSelectedSlot(slot.starttime);
      handleNewVisitChange("appointment_time", slot.starttime);
      const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
      if (
        selectedTime >= new Date("1970-01-01T09:00:00") &&
        selectedTime < new Date("1970-01-01T12:00:00")
      ) {
        setTimePeriod("Morning");
      } else if (
        selectedTime >= new Date("1970-01-01T12:00:00") &&
        selectedTime < new Date("1970-01-01T18:00:00")
      ) {
        setTimePeriod("Afternoon");
      } else if (
        selectedTime >= new Date("1970-01-01T18:00:00") &&
        selectedTime < new Date("1970-01-01T21:00:00")
      ) {
        setTimePeriod("Evening");
      } else {
        setTimePeriod("Night");
      }
      // console.log(timePeriod);
    }
  };

 

  return (
    <Box>
      {isLoading && <Loader miniLoader={true} />}
      {!isLoading && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
              <Image src="/assets/svgs/left-tab-icon.svg" />
            </Box>
            <Text fontSize="xl" fontWeight="bold">
              {timePeriod}
              <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                {timePeriodSlots[timePeriod].length} slots
              </Text>
            </Text>
            <Box as="button" onClick={() => handleTimePeriodChange(1)}>
              <Image src="/assets/svgs/right-tab-icon.svg" />
            </Box>
          </Box>

          <SimpleGrid columns={5} spacing={4}>
            {timePeriodSlots[timePeriod].map((slot) => (
              <Box
                key={slot.starttime}
                as="button"
                onClick={() => handleSlotSelection(slot)}
                borderWidth="1px"
                borderRadius="md"
                p={2}
                textAlign="center"
                bg={
                  newVisitData.appointment_time === slot.starttime
                    ? "blue.500"
                    : isSlotDisabled(slot)
                    ? "gray.300"
                    : "white"
                }
                color={
                  newVisitData.appointment_time === slot.starttime
                    ? "white"
                    : "black"
                }
                borderColor={
                  newVisitData.appointment_time === slot.starttime
                    ? "blue.500"
                    : "gray.300"
                }
                _hover={{
                  bg:
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.600"
                      : isSlotDisabled(slot)
                      ? "gray.300"
                      : "gray.200",
                }}
                disabled={isSlotDisabled(slot)}
              >
                {slot.starttime.substring(0, 5)}
              </Box>
            ))}
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};
const formatDOBDate = (date) => {
  // Check if the date is already in YYYY-MM-DD format
  if (moment(date, "YYYY-MM-DD", true).isValid()) {
    return date;
  }
  // If not, assume it's in DD-MM-YYYY format and convert it to YYYY-MM-DD
  return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
};
function isAgeInRange(dob, age) {
  const birthDate = new Date(dob);
  const today = new Date();

  // Calculate the age based on the DOB
  let calculatedAge = today.getFullYear() - birthDate.getFullYear();

  // Adjust if the birthday hasn't occurred yet this year
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    calculatedAge--;
  }

  // Check if the calculated age is within the range of ±1 year of the provided age
  return Math.abs(calculatedAge - age) <= 1;
}
function parseDateString(dateString) {
  const [day, month, year] = dateString.split('-').map(Number); // Split by '-' and convert to numbers
  return new Date(year, month - 1, day); // Create a Date object (month is zero-indexed)
}
function calculateAge(dob) {
  // Check if dob is valid
  if (!dob || isNaN(new Date(dob).getTime())) {
    return ""; // Return empty if dob is invalid
  }

  const dobDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDifference = today.getMonth() - dobDate.getMonth();
  const dayDifference = today.getDate() - dobDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}
  const openScheduleModal = async (ptntid, aptmntid) => {
    showLoader()
    setIsButtonDisabled(false);
    setPatientID(ptntid)
    setAppointmentID(aptmntid);
    let doctorfee = null;
    let doctid = null;
    try {
      const resp = await FetchPatientDetails({
        patientid: ptntid,
      });

        const result = await FetchDoctorsByUser({
          doctorid,
        });
        if (result.output.result == "success") {
          setDoctorList(result.output.data);
          const Doctors = result.output.data && result.output.data.map((step) => [
            step.fullname
          ]);
          setDoctorListForDropdown(Doctors);
          const currentdoctor = getIsDoctor();
          const loginid = getLoginID()
          let obj = {}
          if(currentdoctor == 1 && currentdoctor != null){
            obj = result.output.data && result.output.data.filter(doctor => doctor.doctorid == loginid);
            if(obj[0]){
              setDefaultDoctor(obj[0].fullname)
              setDoctorid(obj[0].doctorid);
              setAvailableSlots([]);
              fetchDoctorSlotsforNewVisit();
              setTimePeriod("Morning");
              // setDoctorFees(obj[0].fee)  
                setDoctorFeesStatic(obj[0].fee);
                          if(newVisitData?.isfreevisit === "1"){
              
                            setDoctorFees("0.00");
              
                          }else{
              
                          setDoctorFees(formatToDecimal(obj[0].fee));
                          }
              doctorfee =  obj[0].fee;
              doctid = obj[0].doctorid   
            }
          }else{
            setDefaultDoctor("");
          }
        }else {
          setDoctorList([]);
          dismissLoader();
        }
      if (resp != null) {
        const patientInfo = resp.patientinfo;
        const Age = patientInfo?.dob ? calculateAgeWithYearMonth(moment(patientInfo.dob,"Do MMMM, YYYY").format("YYYY-MM-DD")) : null
        //const Age = patientInfo?.dob ? calculateAgeWithYearMonth(patientInfo.dob) : null
        setNewVisitData({
          firstname: patientInfo.firstname,
          lastname: patientInfo.lastname,
          age: Age.years?Age?.years:0,
          month: Age.months?Age?.months:0,
          gender: patientInfo.gender,
          dob: patientInfo?.dob? moment(patientInfo?.dob,"Do MMMM, YYYY").format("DD-MM-YYYY"):"",
          mobileno: patientInfo.mobileno,
          email: patientInfo.email,
          countryid: patientInfo.countryid,
          countrycode: patientInfo.countrycode,
          emergencycontactcountryid : patientInfo.emergencycontactcountryid,
        emergencycontactcountrycode: patientInfo.emergencycontactcountrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: doctid,
          // fees:doctorfee,
          // isfreevisit: "0",
          fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : doctorfee),
          isfreevisit:  newVisitData?.isfreevisit,
          address:patientInfo.patientaddress,
        emergencycontactname:patientInfo.emergencycontactname,
        emergencycontactphone:patientInfo.emergencycontactphone,
        });
        if (
          patientInfo.countrycode !== null &&
          patientInfo.countrycode !== undefined
        ) {
          setSelectedCountry(patientInfo.countrycode);
          setSelectedCountryId(patientInfo.countryid);
        }
        if (
          patientInfo.emergencycontactcountrycode !== null &&
          patientInfo.emergencycontactcountrycode !== undefined 
        ) {
          setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
          setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
        }
      } 
  } catch (error) {
      console.error("Error in home:", error);
    }
    newVisit.current.openModal();
    dismissLoader()
  };
  const handleScheduleClick = async () => {
    if (doctorid === "" || doctorid == null) {
      showErrorToast("Please select any doctor to proceed");
      return;
    }
    else if (!appointmentRescheduleTime) {
      showErrorToast("Please select a slot before rescheduling");
    } else {
      const inputToSave = {
        input: {
          patientid: patientid,
          doctorid: doctorid,
          appointmentdate: moment(appointmentRescheduleDate).format(
            "YYYY-MM-DD"
          ),
          appointmenttime: appointmentRescheduleTime.substring(0, 5),
          // appointmentfor: "Follow-up consultation",
          familyid: null,
          appointmentid: appointmentid,
          reason: "Scheduling conflict",
          type: "schedule",
          appointmenttype_cd: 693,
          // type: "reschedule",
        },
      };

      // console.log(inputToSave);
      try {
        const result = await ScheduleAppointmentByDoctor(inputToSave.input);
        // console.log(result);
        if (result) {
          scheduleVisit.current.closeModal();
          showSuccessToast("Visit Scheduled successfully");
          // navigate(`/visits`);
          getFilteredResults();
        }
      } catch (error) {
        console.error("Error in create visit:", error);
        showErrorToast("Error Happened While Saving.");
      }
    }
  };
  const handleCreateVisitClick = async (IsAdvance) => {
    if (isButtonDisabled) return; // Prevent further clicks
    setIsButtonDisabled(true);
    const typeofschedule = newVisitData.schedule_later;
    const currentdoctor = getIsDoctor();
    let type = null;
    if(currentdoctor == 1 && currentdoctor != null){
      if(typeofschedule === "Schedule Now"){
        type = "startvisit"
      }
    }
    const inputToSave = {
      input: {
        firstname: newVisitData.firstname,
        lastname: newVisitData.lastname,
        fullname: newVisitData.firstname + " " + newVisitData.lastname,
        gender: newVisitData.gender ? formatgender(newVisitData.gender) : null,
        dob: newVisitData.dob ? formatDOBDate(newVisitData.dob) : null,
        age: newVisitData.age,
        month: newVisitData.month,
        mobileno: newVisitData.mobileno,
        email: newVisitData.email,
        countryid: selectedCountryId ? selectedCountryId :"",
        countrycode: selectedCountry ? selectedCountry : "",
        emergencycontactcountryid: SelectedCountryIdForEmergency ? SelectedCountryIdForEmergency :"",
      emergencycontactcountrycode: SelectedCountryForEmergency ? SelectedCountryForEmergency : "",
        patientimage: null,
        doctorid: Doctorid,
        appointmentfor: null,
        appointmenttype_cd: 693,
        appointmentdate: moment(
          newVisitData.appointment_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        appointmenttime: newVisitData.appointment_time,
        createdby: newVisitData.createdby,
        // fees:newVisitData.fees,
        // isfreevisit: newVisitData.isfreevisit,
        fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : newVisitData.fees),
        isfreevisit:  newVisitData?.isfreevisit,
        
        paymenttype:"cash",
        type: type,
        address: newVisitData.address,
      emergencycontactname: newVisitData.emergencycontactname,
      emergencycontactphone: newVisitData.emergencycontactphone,
      patientid: patientid
      },
    };
    // We assume inputToSave.input can have these properties:
    if (inputToSave.input.dob == null) {
      const rawAge = inputToSave.input.age;
      const rawMonth = inputToSave.input.month;
    
      // Convert to numbers (NaN if invalid or nullish)
      const ageYears = parseInt(rawAge, 10);
      const ageMonths = parseInt(rawMonth, 10);
    
      // 1) Only AGE is provided (month is null or invalid)
      if (!isNaN(ageYears) && (rawMonth == null || isNaN(ageMonths))) {
        // "Old" logic: subtract years only
        const currentYear = new Date().getFullYear();
        const yearOfBirth = currentYear - ageYears;
    
        // Fix date to January 2nd
        const birthYear = yearOfBirth;
        const birthMonth = 0; // January = 0
        const birthDay = 2;
    
        const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
    
        // Format as YYYY-MM-DD
        const year = calculatedDOB.getFullYear();
        const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
        const day = String(calculatedDOB.getDate()).padStart(2, '0');
        const formattedDob = `${year}-${month}-${day}`;
    
        inputToSave.input.dob = formattedDob;
      }
      // 2) Only MONTH is provided (age is null or invalid)
      else if (!isNaN(ageMonths) && (rawAge == null || isNaN(ageYears))) {
        // Subtract only months from today's date
        const now = new Date();
        let birthYear = now.getFullYear();
        let birthMonth = now.getMonth() - ageMonths; // 0-based month
        let birthDay = 2; // pick day = 2
    
        // If birthMonth is negative, wrap to previous year
        if (birthMonth < 0) {
          birthYear -= 1;
          birthMonth += 12;
        }
    
        const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
    
        // Format as YYYY-MM-DD
        const year = calculatedDOB.getFullYear();
        const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
        const day = String(calculatedDOB.getDate()).padStart(2, '0');
        const formattedDob = `${year}-${month}-${day}`;
    
        inputToSave.input.dob = formattedDob;
      }
      // 3) Both AGE and MONTH are provided
      else if (!isNaN(ageYears) && !isNaN(ageMonths)) {
        // Subtract years + months
        const now = new Date();
    
        let birthYear = now.getFullYear() - ageYears;
        let birthMonth = now.getMonth() - ageMonths;
        const birthDay = 2;
    
        if (birthMonth < 0) {
          birthYear -= 1;
          birthMonth += 12;
        }
    
        const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
    
        // Format as YYYY-MM-DD
        const year = calculatedDOB.getFullYear();
        const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
        const day = String(calculatedDOB.getDate()).padStart(2, '0');
        const formattedDob = `${year}-${month}-${day}`;
    
        inputToSave.input.dob = formattedDob;
      }
    }
    // Validation checks
    if (inputToSave.input.createdby === "") {
      showErrorToast("Please select any doctor to proceed");
      setIsButtonDisabled(false);
      return;
    }else if (
      (!inputToSave.input.mobileno || inputToSave.input.mobileno.trim() === "") &&
      (!inputToSave.input.email || inputToSave.input.email.trim() === "")
    ) {
      showErrorToast("Please enter either a mobile number or an email.");
      setIsButtonDisabled(false);
      return;
    } else if (
      inputToSave.input.firstname === ""
    ) {
      showErrorToast("Please enter patient name");
      setIsButtonDisabled(false);
      return;
    }else if (
      inputToSave.input.mobileno !== "" &&
      inputToSave.input.mobileno !== null &&
      inputToSave.input.mobileno !== undefined
    ) {
      // Validate mobile number (numbers only and exactly 10 digits)
      const mobileRegex = /^[0-9]{9,10}$/;
      if (!mobileRegex.test(inputToSave.input.mobileno)) {
        showErrorToast(
          "Invalid mobile number."
        );
        // handleNewVisitChange("mobileno", "");
        setIsButtonDisabled(false);
        return;
      }
    } else if (
      inputToSave.input.email !== "" &&
      inputToSave.input.email !== null &&
      inputToSave.input.email !== undefined
    ) {
      // Validate email address if provided
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputToSave.input.email)) {
        showErrorToast("Invalid email address. Please enter a valid email.");
        // handleNewVisitChange("email", "");
        setIsButtonDisabled(false);
        return;
      }
    }
    // console.log(inputToSave.input);
    // console.log(inputToSave.input.dob);
  
    // if (
    //   inputToSave.input.dob !== "" &&
    //   inputToSave.input.dob !== null &&
    //   inputToSave.input.dob !== undefined &&
    //   inputToSave.input.age !== "" &&
    //   inputToSave.input.age !== null &&
    //   inputToSave.input.age !== undefined
    // ) {
    //   // console.log("Checking");
    //   if (!isAgeInRange(inputToSave.input.dob, inputToSave.input.age)) {
    //     showErrorToast("Age and DOB do not match.");
    //     return;
    //   }
    // }
    
    try {
      //console.log(inputToSave);
      const result = await CreateNewVisit(inputToSave.input);
      if (result.output.result == "success") {
        if(IsAdvance === "true"){
          localStorage.removeItem('formState');
          localStorage.removeItem('saveStates');
          localStorage.removeItem('formData');
          localStorage.removeItem('answers')
          localStorage.removeItem('followUps')
          navigate("/advancedetails", { state: { input: inputToSave.input, patientid: result.output.patientid, appointmentid: result.output.appointmentid }})
        }else if(IsAdvance === "false"){
        const typeofschedule = newVisitData.schedule_later;
        if(currentdoctor == 1 && currentdoctor != null && result.output.patientid){
          if(typeofschedule === "Schedule Later"){
            navigate(
              `/visitdetails?patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`
           );
          }else{
            navigate(`/startvisit?doctorId=${result.output.doctorid}&patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`)
          }
        }
        else if(result.output.patientid){
          navigate(
            `/visitdetails?patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`
          );
        }
      }
        showSuccessToast("Visit scheduled successfully");
        newVisit.current.closeModal();
      }else{
        newVisit.current.closeModal();
      }
    } catch (error) {
      console.error("Error in create visit:", error);
      showErrorToast("Error Happened While Saving.");
    }finally{
      setIsButtonDisabled(false);
    }
  };
  // ---------------------------------------------------------------------
  // Reschedule Visit Functionality
  // const [availableSlots, setAvailableSlots] = useState([]);

  // const fetchDoctorSlots = async () => {
  //   // console.log(appointmentRescheduleDate);
  //   const inputToFetch = {
  //     input: {
  //       doctorid: getLoginID(),
  //       date: appointmentRescheduleDate
  //         ? moment(appointmentRescheduleDate, "DD-MM-YYYY").format("YYYY-MM-DD")
  //         : null,
  //     },
  //   };
  //   // console.log(inputToFetch);
  //   showLoader();
  //   try {
  //     const data = await FetchDoctorSlots(inputToFetch.input);
  //     const slots = data?.output?.data || [];
  //     // console.log("New Slots: ", slots);
  //     const currentDate = moment().format("DD-MM-YYYY");

  //     function isDateInCorrectFormat(dateStr) {
  //       const regex = /^\d{2}-\d{2}-\d{4}$/;
  //       return regex.test(dateStr);
  //     }

  //     const formattedRescheduleDate = isDateInCorrectFormat(
  //       appointmentRescheduleDate
  //     )
  //       ? appointmentRescheduleDate
  //       : moment(appointmentRescheduleDate).format("DD-MM-YYYY");
  //     if (
  //       appointmentRescheduleDate &&
  //       formattedRescheduleDate === currentDate
  //     ) {
  //       // console.log("first case");
  //       // console.log(currentDate);
  //       const currentTime = new Date();
  //       const currentHours = currentTime.getHours();
  //       const currentMinutes = currentTime.getMinutes();
  //       const filteredSlots = slots.filter((slot) => {
  //         const [slotHours, slotMinutes] = slot.starttime
  //           .split(":")
  //           .map(Number);
  //         return (
  //           slotHours > currentHours ||
  //           (slotHours === currentHours && slotMinutes >= currentMinutes)
  //         );
  //       });
  //       // console.log(filteredSlots.length);
  //       // console.log(slots.length);
  //       setAvailableSlots(filteredSlots);
  //     }
  //     // console.log("New Slots: ", slots);
  //     else {
  //       // console.log("Other case");
  //       setAvailableSlots(slots);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Doctor Slots data:", error);
  //   } finally {
  //     dismissLoader();
  //   }
  // };

  useEffect(() => {
    if(!pageLoad){
      fetchDoctorSlots();
    }
    
  }, [appointmentRescheduleDate]);

  // const [timePeriod, setTimePeriod] = useState("Morning");

  // const SlotSelector = ({ availableSlots }) => {
  //   const [selectedSlot, setSelectedSlot] = useState(null);

  //   const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
  //   const timePeriodSlots = {
  //     Morning: availableSlots.filter(
  //       (slot) =>
  //         new Date(`1970-01-01T${slot.starttime}`) >=
  //           new Date("1970-01-01T09:00:00") &&
  //         new Date(`1970-01-01T${slot.starttime}`) <
  //           new Date("1970-01-01T12:00:00")
  //     ),
  //     Afternoon: availableSlots.filter(
  //       (slot) =>
  //         new Date(`1970-01-01T${slot.starttime}`) >=
  //           new Date("1970-01-01T12:00:00") &&
  //         new Date(`1970-01-01T${slot.starttime}`) <
  //           new Date("1970-01-01T18:00:00")
  //     ),
  //     Evening: availableSlots.filter(
  //       (slot) =>
  //         new Date(`1970-01-01T${slot.starttime}`) >=
  //           new Date("1970-01-01T18:00:00") &&
  //         new Date(`1970-01-01T${slot.starttime}`) <
  //           new Date("1970-01-01T21:00:00")
  //     ),
  //     Night: availableSlots.filter(
  //       (slot) =>
  //         new Date(`1970-01-01T${slot.starttime}`) >=
  //         new Date("1970-01-01T21:00:00")
  //     ),
  //   };

  //   const handleTimePeriodChange = (direction) => {
  //     const currentIndex = timePeriods.indexOf(timePeriod);
  //     const newIndex =
  //       (currentIndex + direction + timePeriods.length) % timePeriods.length;
  //     setTimePeriod(timePeriods[newIndex]);
  //   };

  //   const handleSlotSelection = (slot) => {
  //     setAppointmentRescheduleTime(slot.starttime);
  //     const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
  //     if (
  //       selectedTime >= new Date("1970-01-01T09:00:00") &&
  //       selectedTime < new Date("1970-01-01T12:00:00")
  //     ) {
  //       setTimePeriod("Morning");
  //     } else if (
  //       selectedTime >= new Date("1970-01-01T12:00:00") &&
  //       selectedTime < new Date("1970-01-01T18:00:00")
  //     ) {
  //       setTimePeriod("Afternoon");
  //     } else if (
  //       selectedTime >= new Date("1970-01-01T18:00:00") &&
  //       selectedTime < new Date("1970-01-01T21:00:00")
  //     ) {
  //       setTimePeriod("Evening");
  //     } else {
  //       setTimePeriod("Night");
  //     }
  //     // console.log(timePeriod);
  //   };

  //   return (
  //     <Box>
  //       {isLoading && <Loader miniLoader={true} />}

  //       {!isLoading && (
  //         <>
  //           <Box
  //             display="flex"
  //             justifyContent="space-between"
  //             alignItems="center"
  //             mb={4}
  //           >
  //             <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
  //               <Image src="/assets/svgs/left-tab-icon.svg" />
  //             </Box>
  //             <Text fontSize="xl" fontWeight="bold">
  //               {timePeriod}
  //               <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
  //                 {timePeriodSlots[timePeriod].length} slots{" "}
  //               </Text>
  //             </Text>
  //             <Box as="button" onClick={() => handleTimePeriodChange(1)}>
  //               <Image src="/assets/svgs/right-tab-icon.svg" />
  //             </Box>
  //           </Box>

  //           <SimpleGrid columns={5} spacing={4}>
  //             {timePeriodSlots[timePeriod].map((slot) => (
  //               <Box
  //                 key={slot.starttime}
  //                 as="button"
  //                 onClick={() => handleSlotSelection(slot)}
  //                 borderWidth="1px"
  //                 borderRadius="md"
  //                 p={2}
  //                 textAlign="center"
  //                 bg={
  //                   appointmentRescheduleTime === slot.starttime
  //                     ? "blue.500"
  //                     : "white"
  //                 }
  //                 color={
  //                   appointmentRescheduleTime === slot.starttime
  //                     ? "white"
  //                     : "black"
  //                 }
  //                 borderColor={
  //                   appointmentRescheduleTime === slot.starttime
  //                     ? "blue.500"
  //                     : "gray.300"
  //                 }
  //                 _hover={{
  //                   bg:
  //                     appointmentRescheduleTime === slot.starttime
  //                       ? "blue.600"
  //                       : "gray.200",
  //                 }}
  //               >
  //                 {slot.starttime.substring(0, 5)}
  //               </Box>
  //             ))}
  //           </SimpleGrid>
  //         </>
  //       )}
  //     </Box>
  //   );
  // };

  const openRescheduleModal = async (ptntid, aptmntid) => {
    setPatientID(ptntid)
    setAppointmentID(aptmntid)
    // console.log("function called", availableSlots);
    setAvailableSlots([]);
    fetchDoctorSlots();
    setTimePeriod("Morning");
    setAppointmentRescheduleTime(null);
    setAppointmentRescheduleDate(new Date());
    rescheduleVisit.current.openModal();
  };

  const handleRescheduleClick = async () => {
    if (!appointmentRescheduleTime) {
      showErrorToast("Please select a slot before rescheduling");
    } else {
      const inputToSave = {
        input: {
          patientid: patientid,
          doctorid: getLoginID(),
          appointmentdate: moment(appointmentRescheduleDate).format(
            "YYYY-MM-DD"
          ),
          appointmenttime: appointmentRescheduleTime.substring(0, 5),
          // appointmentfor: "Follow-up consultation",
          familyid: null,
          appointmentid: appointmentid,
          reason: "Scheduling conflict",
          type: "reschedule",
        },
      };

      // console.log(inputToSave);
      try {
        const result = await ResheduleAppointmentByDoctor(inputToSave.input);
        // console.log(result);
        if (result) {
          rescheduleVisit.current.closeModal();
          showSuccessToast("Visit rescheduled successfully");
          // navigate(`/visits`);
          getFilteredResults();
        }
      } catch (error) {
        console.error("Error in create visit:", error);
        showErrorToast("Error Happened While Saving.");
      }
    }
  };

  // ---------------------------------------------------------------
  // Cancel Visit Functionality
  const [fullName, setFullName] = useState('')
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [cancelApptmtID, setCancelApptmtID] = useState('')
  const [cancelPatientId, setCancelPatientID] = useState('')
  const openCancelModal = async (name, date, time, pid, aptmtid) => {
    // console.log('name: ', name)
    // console.log('date: ', date)
    // console.log('time: ', time)
    setFullName(name)
    setDate(formatDate(date))
    setTime(time)
    setCancelPatientID(pid)
    setCancelApptmtID(aptmtid)
    cancelVisit.current.openModal();
  };

  const handleConfirmCancel = async () => {
    // console.log("Visit Canceled");
    const inputForStart = {
      input: {
        doctorid: getLoginID(),
        patientid: cancelPatientId,
        appointmentid: cancelApptmtID,
        reason: "abc", // CHANGE IT -> TO an input box
        completed: "2",
      },
    };
    try {
      // console.log("input of cancel", inputForStart.input);
      const result = await ResheduleAppointmentByDoctor(inputForStart.input);
      // console.log("result of cancel", result);
      cancelVisit.current.closeModal();

      showSuccessToast("Visit canceled successfully.");
      // navigate(`/visits`);
      getFilteredResults();
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

// ---------------------------------------------------------------
  // Edit Patient Profile Functionality

  const [editPatientData, setEditPatientData] = useState({
    firstname: "",
    lastname: "",
    age: null,
    gender: 2,
    dob: "",
    mobileno: "",
    email: "",
    countryid: "",
    countrycode: "",
    emergencycontactcountryid:"",
    emergencycontactcountrycode:"",
    appointmentfor: "",
    appointmenttype_cd: 0,
    schedule_later: "Schedule Now",
    appointment_date: "",
    appointment_time: "",
    createdby :"",
    fees:"",
    isfreevisit: "0",
    address:"",
    emergencycontactname:"",
    emergencycontactphone:"",
  });

  const handleEditProfileChange = (field, value) => {
    setEditPatientData((prev) => ({ ...prev, [field]: value }));
  };

  const openEditProfileModal = async (ptntid, aptmntid) => {
    showLoader()
    setPatientID(ptntid)
    setAppointmentID(aptmntid);
    try {
      const resp = await FetchPatientDetails({
        patientid: ptntid,
      });

      if (resp != null) {
        const patientInfo = resp.patientinfo;
        //console.log(patientInfo)
        setEditPatientData({
          firstname: patientInfo.firstname,
          lastname: patientInfo.lastname,
          age: patientInfo?.dob ? calculateAge(moment(patientInfo.dob,"Do MMMM, YYYY").format("DD-MM-YYYY")): null,
          gender: patientInfo.gender,
          dob: patientInfo?.dob? moment(patientInfo?.dob,"Do MMMM, YYYY").format("DD-MM-YYYY"):"",
          mobileno: patientInfo.mobileno,
          email: patientInfo.email,
          countryid: patientInfo.countryid,
          countrycode: patientInfo.countrycode,
          emergencycontactcountryid : patientInfo.emergencycontactcountryid,
        emergencycontactcountrycode: patientInfo.emergencycontactcountrycode,
          address:patientInfo.patientaddress,
        emergencycontactname:patientInfo.emergencycontactname,
        emergencycontactphone:patientInfo.emergencycontactphone,
        });
        if (
          patientInfo.countrycode !== null &&
          patientInfo.countrycode !== undefined
        ) {
          setSelectedCountry(patientInfo.countrycode);
          setSelectedCountryId(patientInfo.countryid);
        }
        if (
          patientInfo.emergencycontactcountrycode !== null &&
          patientInfo.emergencycontactcountrycode !== undefined 
        ) {
          setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
          setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
        }
      } 
  } catch (error) {
      console.error("Error in home:", error);
    }
    editProfileModal.current.openModal();
    dismissLoader()
  };


  const handleEditProfileClick = async () => {
    
    const inputToSave = {
      input: {
        firstname: editPatientData.firstname,
        lastname: editPatientData.lastname,
        fullname: editPatientData.firstname + " " + editPatientData.lastname,
        gender: editPatientData.gender ? formatgender(editPatientData.gender) : null,
        dob: editPatientData.dob ? formatDOBDate(editPatientData.dob) : null,
        age: editPatientData.age,
        mobileno: editPatientData.mobileno,
        email: editPatientData.email,
        countryid: selectedCountryId ? selectedCountryId :"",
        countrycode: selectedCountry ? selectedCountry : "",
        emergencycontactcountryid: SelectedCountryIdForEmergency ? SelectedCountryIdForEmergency :"",
      emergencycontactcountrycode: SelectedCountryForEmergency ? SelectedCountryForEmergency : "",
        patientimage: null,
        doctorid: Doctorid,
        address: editPatientData.address,
      emergencycontactname: editPatientData.emergencycontactname,
      emergencycontactphone: editPatientData.emergencycontactphone,
      patientid: patientid
      },
    };
    // Validation checks
    if (inputToSave.input.createdby === "") {
      showErrorToast("Please select any doctor to proceed");
      return;
    }else if (
      (!inputToSave.input.mobileno || inputToSave.input.mobileno.trim() === "") &&
      (!inputToSave.input.email || inputToSave.input.email.trim() === "")
    ) {
      showErrorToast("Please enter either a mobile number or an email.");
      return;
    } else if (
      inputToSave.input.firstname === ""
    ) {
      showErrorToast("Please enter patient name");
      return;
    }else if (
      inputToSave.input.mobileno !== "" &&
      inputToSave.input.mobileno !== null &&
      inputToSave.input.mobileno !== undefined
    ) {
      // Validate mobile number (numbers only and exactly 10 digits)
      const mobileRegex = /^[0-9]{9,10}$/;
      if (!mobileRegex.test(inputToSave.input.mobileno)) {
        showErrorToast(
          "Invalid mobile number."
        );
        // handleNewVisitChange("mobileno", "");
        return;
      }
    } else if (
      inputToSave.input.email !== "" &&
      inputToSave.input.email !== null &&
      inputToSave.input.email !== undefined
    ) {
      // Validate email address if provided
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputToSave.input.email)) {
        showErrorToast("Invalid email address. Please enter a valid email.");
        // handleNewVisitChange("email", "");
        return;
      }
    }
    // console.log(inputToSave.input);
    // console.log(inputToSave.input.dob);
  
    // if (
    //   inputToSave.input.dob !== "" &&
    //   inputToSave.input.dob !== null &&
    //   inputToSave.input.dob !== undefined &&
    //   inputToSave.input.age !== "" &&
    //   inputToSave.input.age !== null &&
    //   inputToSave.input.age !== undefined
    // ) {
    //   // console.log("Checking");
    //   if (!isAgeInRange(inputToSave.input.dob, inputToSave.input.age)) {
    //     showErrorToast("Age and DOB do not match.");
    //     return;
    //   }
    // }
    
    try {
      //console.log(inputToSave);
      const result = await UpdatePatientProfile(inputToSave.input);
      if (result.output.result == "success") {
      //   if(IsAdvance === "true"){
      //     localStorage.removeItem('formState');
      //     localStorage.removeItem('saveStates');
      //     localStorage.removeItem('formData');
      //     localStorage.removeItem('answers')
      //     localStorage.removeItem('followUps')
      //     navigate("/advancedetails", { state: { input: inputToSave.input, patientid: result.output.patientid, appointmentid: result.output.appointmentid }})
      //   }else if(IsAdvance === "false"){
      //   const typeofschedule = newVisitData.schedule_later;
      //   if(currentdoctor == 1 && currentdoctor != null && result.output.patientid){
      //     if(typeofschedule === "Schedule Later"){
      //       navigate(
      //         `/patientprofile?id=${result.output.patientid}&page=overview`
      //       );
      //     }else{
      //       navigate(`/startvisit?doctorId=${result.output.doctorid}&patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`)
      //     }
      //   }
      //   else if(result.output.patientid){
      //     navigate(
      //       `/patientprofile?id=${result.output.patientid}&page=overview`
      //     );
      //   }
      // }
        showSuccessToast("Profile Updated successfully");
        getFilteredResults()
        editProfileModal.current.closeModal();
      }
      // else{
      //   newVisit.current.closeModal();
      // }
    } catch (error) {
      console.error("Error in Edit Profile:", error);
      showErrorToast("Error Happened While Saving.");
    }
  };

  function handle3Dots (){
    setNewVisitData({
      fees: "0.00",
      isfreevisit:  "1",

    });
  }

  return (
    <>
      {isLoading && <Loader />}
      {screenType == 'web' ? (
        <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          <Box w="100%">
            <SecondaryNav customHeader="Patient View" />
            <Box gap='10px' my='1rem' pl='2rem' w='90vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
              <PrimaryInput
                variant="fullRound"
                title="Search by name, email"
                inputPlace="Search by name, email"
                id="yourName"
                onKeyDown={handleKeyDown}
                onChange={handleChangeName}
                inputValue={name}
              />
              {/* <Box w='800px'>
              <DatePicker
                  disableFutureDates={true}
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleAppointmentDate(selectedDateValue)
                  }
                  placeholderProp="Appointment Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={visitDate} 
                  clearValue={true}
                />
              </Box>
                <Box w='800px'>
                </Box> */}

<Box w='800px'>
              <DatePicker
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleFromDate(selectedDateValue)
                  }
                  placeholderProp="From Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={fromDate} 
                  clearValue={true}

                  CUSTOM_DISABLE={true}
                  disableFutureDates={true}
                  maxDate={toDateMinMax}
                />
              </Box>
                <Box w='800px'>
              <DatePicker
                  onDateChange={(selectedDateValue) =>
                    handleToDate(selectedDateValue)
                  }
                  placeholderProp="To Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                  value={toDate} 
                  clearValue={true}
                  
                  
                  CUSTOM_DISABLE={true}
                  maxDate={new Date().toISOString()} 
                  minDate={fromDateMinMax}
                />
                </Box>

              <Box gap='10px' my='1rem' pl='2rem' w='70vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                <PrimaryButton buttonText="Filter" id="filterButton" onClick={handleApplyFilters}  />
                <PrimaryButton buttonText="Reset" id="resetButton" variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} onClick={resetFields} />
              </Box>
              {/* {noData && <p>No data available</p>} */}
              </Box>
            {patientViewData && patientViewData.length > 0 ? (
                        <Box my="1rem" w='100%'>
                          <Box
                          h="calc(100vh - 90px)"
                          overflow='hidden auto'
                          border="1px solid #E6E7E9"
                          >
                            <SimpleTable
                              headers={TableHeader}
                              className='tableContainer'
                              isDataPresent={true}
                              currentPage={currentPage} onPageChange={handlePageChange}
                              footerStyle={{pos: "fixed", bottom: "0px",boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px", zIndex: "99", w: "100%"}}
                              tableStyle={{minH:"calc(100vh - 260px)",maxH:"calc(100vh - 260px)",overflowY: "auto",overflowX: "hidden", tableLayout: "fixed", width: "100%"}}
                              tableBody={
                                <>
                                  {patientViewData &&
                                    patientViewData.map((step, index) => (
                                      <Tr w="100%" key={index}>
                                        <Td w="100x">
                                          <Box w="100%" display="flex" gap="0.5rem" alignItems="center">
                                            <Box boxSize="2.75rem">
                                              <Image 
                                                boxSize="40px" 
                                                h="40px" 
                                                objectFit="cover" 
                                                // src={
                                                //   step.patientimage? step.imagepath + step.patientimage:"/assets/imgs/no-image.png"
                                                // } 
                                                src={
                                                  step.patientimage ? 
                                                    (step.patientimage.startsWith('http') ? step.patientimage : step.imagepath + step.patientimage) 
                                                    : '/assets/imgs/no-image.png' 
                                                } 
                                                borderRadius="full" 
                                              />
                                            </Box>
                                            <Box w="80%" maxW="8rem" isTruncated>
                                              <Text
                                              className="textOverflow"
                                                fontSize=".875rem"
                                                fontWeight="600"
                                                title={step.patientname}
                                              >
                                                {step.fullname
                                                  ? step.fullname
                                                  : "-"}
                                              </Text>
                                            </Box>
                                          </Box>
                                        </Td>

                                        <Td w="80%" maxW="12rem">
                                          <Text fontSize=".875rem" isTruncated title={step.gender == 3 ? "Other" : step.gender == 1 ? "Female" : "Male" + " "+ calculateAgeCommonFunc(step.dob)} fontWeight="500">
                                            {step.gender == 3 ? "Other" : step.gender == 1 ? "F" : "M" }  
                                            {/* {step.dob ? `/ ${calculateAgeWithYearMonth(step.dob)}` : ""} */}

                                            {step.dob 
                                              ? `/ ${(() => {
                                                const { years, months } = calculateAgeWithYearMonth(step.dob);
                                                return `${years} Y ${months} M`;
                                              })()}`
                                            : ""}

                                          </Text>
                                        </Td>

                                        <Td w="80%" maxW="8rem">
                                          <Text fontSize=".875rem" isTruncated title={step.email} fontWeight="500">
                                            {step.email}
                                          </Text>
                                        </Td>

                                        <Td>
                                          <Box
                                            p=".25rem .5rem"
                                            borderRadius="full"
                                          >
                                            <Text
                                              fontSize=".75rem"
                                              fontWeight="600"
                                              //color="#109E6A"
                                            >
                                              {step.mobileno ? ((step?.countrycode ? step?.countrycode +  " " : "" ) + step.mobileno) : ('-')}
                                            </Text>
                                          </Box>
                                        </Td>

                                        <Td>
                                          <Text fontSize=".875rem" fontWeight="500">
                                          {/* {step.appointmentdate ? `${formatDate(step.appointmentdate)} ${formatTime(step.appointmenttime)}`: ('-')} */}

                                          {step.appointmentdate ? (
                                            <>
                                              {formatDate(step.appointmentdate)}
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              {formatTime(step.appointmenttime)}
                                            </>
                                          ) : (
                                            '-'
                                          )}
                                          </Text>
                                        </Td>

                                        <Td>
                                          {/* <Text fontSize=".875rem" fontWeight="500">
                                          {step.appointmentstatus === 0
                                            ? 'Pending'
                                            : step.appointmentstatus === 1
                                            ? 'Upcoming'
                                            : step.appointmentstatus === 2
                                            ? 'Cancelled'
                                            : step.appointmentstatus === 3
                                            ? 'Completed'
                                            : step.appointmentstatus === 4
                                            ? 'No-show'
                                            : '-'
                                          }
                                          </Text> */}

                                        <Box w='fit-content'>
                            {step.appointmentstatus == 0 ? (
                              <Text
                              fontSize="0.75rem"
                              borderRadius="full"
                              fontWeight="600"
                              p="0.25rem 0.5rem"
                              border="1px solid #E6E7E9"
                              // bg='#14C585'
                              bg="#E6E7E966"
                              // color='rgb(0,151,0)'
                              color='rgb(255,165,0)'
                            >
                              Pending
                            </Text>
                            ) : step.appointmentstatus === 1 ? (
                              <Text
                                fontSize="0.75rem"
                                borderRadius="full"
                                fontWeight="600"
                                p="0.25rem 0.5rem"
                                border="1px solid #E6E7E9"
                                // bg='#F044383D'
                                bg="#E6E7E966"
                                color='rgb(255,165,0)'
                                // color='#FA594E'
                                // bg='#032217' for approve
                                // color='#14C585' for approve
                              >
                                Upcoming                              
                              </Text>
                            ): step.appointmentstatus === 2 ? (
                              <Text
                                fontSize="0.75rem"
                                borderRadius="full"
                                fontWeight="600"
                                p="0.25rem 0.5rem"
                                border="1px solid #E6E7E9"
                                // bg="#E6E7E966"
                                // color='rgb(255,165,0)'
                                bg="#E6E7E966"
                                color='rgb(254, 51, 51)'
                              >
                                Cancelled                              
                              </Text>
                            ): step.appointmentstatus === 3 ? (
                              <Text
                                fontSize="0.75rem"
                                borderRadius="full"
                                fontWeight="600"
                                p="0.25rem 0.5rem"
                                border="1px solid #E6E7E9"
                                // bg="#E6E7E966"
                                // color='rgb(255,165,0)'
                                bg="#E6E7E966"
                                color='rgb(0,151,0)'
                              >
                                Completed                              
                              </Text>
                            ): step.appointmentstatus === 4? (
                              <Text
                                fontSize="0.75rem"
                                borderRadius="full"
                                fontWeight="600"
                                p="0.25rem 0.5rem"
                                border="1px solid #E6E7E9"
                                bg="#E6E7E966"
                                color='rgb(254, 51, 51)'
                              >
                                No-show
                              </Text>
                            ): ('-')}
                           </Box>
                                        </Td>

                                        <Td pos='relative'>
                            <Menu>
                              <MenuButton as='button'  zIndex={1} onClick={handle3Dots}>
                                <Image w="1.5rem" src="/assets/svgs/more-options.svg"  />
                              </MenuButton>
                              <MenuList zIndex={9999} pos="relative" right="-33px" minW="125px">
                                <MenuItem onClick={() => handleClick(step.appointmentid, step.patientid)}>
                                  View Profile
                                </MenuItem>
                                <MenuItem onClick={() => openEditProfileModal(step.patientid, step.appointmentid)}
                                >
                                  Edit Profile
                                </MenuItem>
                                <MenuItem onClick={() => openScheduleModal(step.patientid, step.appointmentid)}
                                isDisabled={
                                  actionAccessVisits && !actionAccessVisits.create ? true : false
                                }>
                                  Schedule Visit
                                </MenuItem>
                                {(step.appointmentstatus ==0 || step.appointmentstatus == 1)  && (
                                <MenuItem onClick={() => openRescheduleModal(step.patientid, step.appointmentid)}
                                isDisabled={
                                  actionAccessVisits && !actionAccessVisits.update ? true : false
                                }
                                >
                                  Reschedule Visit
                                </MenuItem>
                                )}
                                {(step.appointmentstatus ==0 || step.appointmentstatus == 1) && (
                                <MenuItem 
                                // onClick={cancelVisit.current.openModal}
                                onClick={() => openCancelModal(step.fullname, step.appointmentdate, step.appointmenttime, step.patientid, step.appointmentid)}
                                isDisabled={
                                  actionAccessVisits && !actionAccessVisits.delete ? true : false
                                } >
                                  Cancel Visit
                                </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          </Td>
                                      </Tr>
                                    ))}
                                </>
                              }
                              footerSection={
                                <>
                                  <Tr w='100%'>
                                    <Td colSpan={7} w="calc(100vw - 6.5rem)">
                                      <Box
                                        w="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                          <Box 
                         visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}
                         >
                            <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                            </Box>
                            <Box  
                          gap="2.5rem"
                           display="flex"
                          justifyContent="space-between"
                          alignItems="center" >
                                        <Box as="button" onClick={handlePrevClick} disabled={currentPage === 1} visibility={currentPage === 1 ? 'hidden' : 'visible'} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                                            Previous
                                          </Box>
                                        <Box>
                                          <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                                          Page {currentPage} of {totalPages}
                                          </Text>
                                        </Box>
                                        {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                                          <Box as="button" p="0.5rem 0.75rem" mr='2.5rem' border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" onClick={handleNextClick} 
                                          disabled={currentPage === totalPages} 
                                          visibility={currentPage === totalPages ? 'hidden' : 'visible'} 
                                          fontSize="1rem" fontWeight="500">
                                            Next
                                          </Box>
                                        }
                                          </Box>
                                      </Box>
                                    </Td>
                                  </Tr>
                                </>
                              }
                            />
                          </Box>
                        </Box>
                      ) : (
                        <SimpleTable
                          headers={TableHeader}
                          isDataPresent={false}
                        />
                      )}
          </Box>
        </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Patient View"
            handlebackarrow={false}
          />

          <Box
            w="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="1rem"
            gap='0.75rem'
          >
            <PrimaryInput
              variant="fullRound"
              title="Search name, email"
              inputPlace="Search name, email"
              id="yourName"
              onKeyDown={handleKeyDown}
              onChange={handleChangeName}
              inputValue={name}
            />
            <Box onClick={onToggle}>
              <Image
                w='50px'
                cursor='pointer'
                src={isOpen ? "assets/svgs/outline-f.svg" : "assets/svgs/filled-f.svg" }
              />
            </Box>
            <PrimaryButton 
              buttonText="Filter"
              id="filterButton"
              onClick={handleApplyFilters}
              btnStyle={{w:"80px"}}
              variant='mdBtn'
            />
            <PrimaryButton 
              buttonText="Reset"
              id="resetButton"
              variant="grayBtn"
              btnStyle={{ color: "#AA2F27",w:"80px" }}
              onClick={resetFields}
              />
          </Box>

          <Collapse in={isOpen} animateOpacity zIndex='9999' pos='relative' style={{overflow:'visible'}}>
            <Box w="100%" display="flex" p='1rem' pt='0' flexDir='column' zIndex='9999' pos='relative' gap="10px">
              <DatePicker
                // defaultDate={procedureDate}
                onDateChange={(selectedDateValue) =>
                  handleFromDate(selectedDateValue)
                }
                placeholderProp="From Date"
                datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                value={fromDate} 
                clearValue={true}

                CUSTOM_DISABLE={true}
                disableFutureDates={true}
                maxDate={toDateMinMax}
              />
              <DatePicker
                onDateChange={(selectedDateValue) =>
                  handleToDate(selectedDateValue)
                }
                placeholderProp="To Date"
                datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                value={toDate} 
                clearValue={true}
                
                
                CUSTOM_DISABLE={true}
                maxDate={new Date().toISOString()} 
                minDate={fromDateMinMax}
              />
            </Box>
          </Collapse>


          <Box
          h="calc(100vh - 200px)"
          overflow='hidden auto'
          border="1px solid #E6E7E9"
          p='1rem'
          display='flex'
          gap='0.75rem'
          flexDir='column'
          >
          {patientViewData &&
              patientViewData.map((step, index) => (
          <Card
            borderRadius="12px"
            key={index}
            w="100%"
            border="1px solid #E6E7E9"
            boxShadow="0px 8px 20px 0px #E6E7E966"
          >
            <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >                  
                <Box cursor="pointer" display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Text fontSize="15px" fontWeight="600" className="textOverflow" title={step.patientname ? step.patientname : "-"} maxW={{base: "180px", xl:"110px"}}>
                    {step.fullname
                                  ? step.fullname
                                  : "-"}
                    </Text>
                  </Box>
                </Box>

                  <Box display="flex" gap="10px">
                    <Menu isLazy>
                      <MenuButton as='button'  zIndex={1} onClick={handle3Dots}>
                        <Image w="1rem" src="/assets/svgs/more-options.svg" transform='rotate(90deg)' />
                      </MenuButton>
                      <MenuList zIndex={9999} pos="relative" right="-33px" minW="125px">
                        <MenuItem onClick={() => handleClick(step.appointmentid, step.patientid)}>
                          View Profile
                        </MenuItem>
                        <MenuItem onClick={() => openScheduleModal(step.patientid, step.appointmentid)}
                        isDisabled={
                          actionAccessVisits && !actionAccessVisits.create ? true : false
                        }>
                          Schedule Visit
                        </MenuItem>
                        {(step.appointmentstatus ==0 || step.appointmentstatus == 1)  && (
                        <MenuItem onClick={() => openRescheduleModal(step.patientid, step.appointmentid)}
                        isDisabled={
                          actionAccessVisits && !actionAccessVisits.update ? true : false
                        }
                        >
                          Reschedule Visit
                        </MenuItem>
                        )}
                        {(step.appointmentstatus ==0 || step.appointmentstatus == 1) && (
                        <MenuItem 
                        // onClick={cancelVisit.current.openModal}
                        onClick={() => openCancelModal(step.fullname, step.appointmentdate, step.appointmenttime, step.patientid, step.appointmentid)}
                        isDisabled={
                          actionAccessVisits && !actionAccessVisits.delete ? true : false
                        } >
                          Cancel Visit
                        </MenuItem>
                        )}
                      </MenuList>
                    </Menu>   
                  </Box>
                  
              </Box>
              <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                <Box display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                      Email
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {step.email}
                    </Text>
                    
                  </Box>
                  <Box display='flex' flexDir='column' alignItems="center">
                
                <Text fontSize="10px" color="gray.500">
                  Mobile number
                </Text>
                <Box
                    as="button"
                  >
                    <Text fontSize="12px" fontWeight="600">
                    {/* {step.mobileno ? (step.mobileno): ('-')} */}
                    {step.mobileno ? ((step?.countrycode ? step?.countrycode +  " " : "" ) + step.mobileno) : ('-')}
                                         
                </Text>
                  </Box>
                
              
              </Box>
              </Flex>
              
              <Flex w='100%'>
                <Box w='100%' display='flex' flexDir='column'>
                
                    <Text fontSize="10px" color="gray.500">
                     Last Appointment Date/Time
                    </Text>
                    <Text fontSize="12px" fontWeight="600">
                    {step.appointmentdate ? (
                      <>
                        {formatDate(step.appointmentdate)}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {formatTime(step.appointmenttime)}
                      </>
                    ) : (
                      '-'
                    )}
                    </Text>
                    
                  </Box>
                  
                  

                <Box w='100%' display='flex' flexDir='column' alignItems='flex-end'>
                    <Text fontSize="10px" color="gray.500">
                    Last Appointment status
                    </Text>
                    <Box w='fit-content'>
                      {step.appointmentstatus == 0 ? (
                        <Text
                        fontSize="0.75rem"
                        borderRadius="full"
                        fontWeight="600"
                        color='rgb(255,165,0)'
                      >
                        Pending
                      </Text>
                      ) : step.appointmentstatus === 1 ? (
                        <Text
                          fontSize="0.75rem"
                          borderRadius="full"
                          fontWeight="600"
                          color='rgb(255,165,0)'
                        >
                          Upcoming                              
                        </Text>
                      ): step.appointmentstatus === 2 ? (
                        <Text
                          fontSize="0.75rem"
                          borderRadius="full"
                          fontWeight="600"
                          color='rgb(254, 51, 51)'
                        >
                          Cancelled                              
                        </Text>
                      ): step.appointmentstatus === 3 ? (
                        <Text
                          fontSize="0.75rem"
                          borderRadius="full"
                          fontWeight="600"
                          color='rgb(0,151,0)'
                        >
                          Completed                              
                        </Text>
                      ): step.appointmentstatus === 4? (
                        <Text
                          fontSize="0.75rem"
                          borderRadius="full"
                          fontWeight="600"
                          color='rgb(254, 51, 51)'
                        >
                          No-show
                        </Text>
                      ): ('-')}
                      </Box>
                  </Box>
              </Flex>
            
            </CardBody>

          </Card>
          ))}
          </Box>
        </>
      )}


  {/* Schedule Visit  */}
  {/* <DynamicModal
        ref={newVisitPatientStatus}
        modalHeader="Schedule Visit"
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
              <Box display="flex" flexDir="column">
              <DropdownInput
                 variant="fullRound"
                 dropDownPlace="Doctor Name*"
                  options={DoctorListForDropdown}
                  id="Doctor Name"
                  onClick={HandleEditDoctor}
                  //defaultOption={defaulttest}
                  menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                  inputStyleCss={{w: "100%"}}
                 />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText={"Continue"}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={handleFetchPatientDetails}
            />
          </>
        }
      /> */}
  {/* <DynamicModal
        ref={scheduleVisit}
        modalHeader="Schedule Visit"
        modalWidth="40%"
        modalBody={
          <>
            <Box>
              <Box mt={"8px"}>
              <Box display="flex" flexDir="column">
              <DropdownInput
                 variant="fullRound"
                 dropDownPlace="Doctor Name*"
                  options={DoctorListForDropdown}
                  id="Doctor Name"
                  onClick={HandleEditDoctor}
                  defaultOption={DefaultDoctor}
                  menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                  inputStyleCss={{w: "100%"}}
                 />
              </Box>
                <DatePicker
                  defaultDate={moment(new Date()).format("DD-MM-YYYY")}
                  minDate={moment(new Date()).format("DD-MM-YYYY")}
                  // disableFutureDates={false}
                  onDateChange={(selectedDateValue) =>
                    setAppointmentRescheduleDate(selectedDateValue)
                  }
                  placeholderProp={"Select Date"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <SlotSelector availableSlots={availableSlots} />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Schedule"
              // onClick={() => rescheduleVisit.current.closeModal()}
              onClick={handleScheduleClick}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </>
        }
      /> */}
<DynamicModal
        ref={newVisit}
        modalHeader="New Visit"
        modalWidth="60%"
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
            <Box display="flex" flexDir="column">
              <DropdownInput
                 variant="fullRound"
                 dropDownPlace="Doctor Name*"
                  options={DoctorListForDropdown}
                  id="Doctor Name"
                  onClick={HandleEditDoctor}
                  defaultOption={DefaultDoctor}
                  menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                  inputStyleCss={{w: "100%"}}
                 />
              </Box>
              
              <Box display="flex" alignItems='center' gap='1rem' flexDir="row">
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0 0 0 10px'}}
                  inputStyle={{ borderRadius: "0 0 10px 10px" }}
                  onCountryChange={handleCountryChange} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={parseInt(newVisitData.countryid)}
                  onChange={(e) =>
                    handleNewVisitChange(
                      "mobileno",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.mobileno}
                />
                 <Text>or</Text>
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  width={"80%"}
                  variant='rightDown'
                  inputPlace="Email Address"
                  inputValue={newVisitData.email}
                  onChange={(e) =>
                    handleNewVisitChange("email", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant="leftSide"
                  inputPlace="First Name*"
                  inputValue={newVisitData.firstname}
                  onChange={(e) =>
                    handleNewVisitChange("firstname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInput
                  type="text"
                  variant='rightSide'
                  inputPlace="Last Name*"
                  inputValue={newVisitData.lastname}
                  onChange={(e) =>
                    handleNewVisitChange("lastname", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" alignItems='center' gap='1rem' flexDir="row" mt="12px">
              <DatePicker
                  value={newVisitData.dob? newVisitData.dob: null}
                  clearValue={true}
                  disableFutureDates={true}
                  onDateChange={(selectedDateValue) => {
                    const formattedDob = moment(selectedDateValue).format("DD-MM-yyyy");
                    const formattedAge = moment(selectedDateValue).format("YYYY-MM-DD");
                    // const age = calculateAge(formattedDob);
                    const age = calculateAgeWithYearMonth(formattedAge);
                    handleNewVisitChange('dob', formattedDob); // Set DOB
                    handleNewVisitChange('age', age.years); // Set Age
                    handleNewVisitChange('month', age.months);                    
                  }}
                  placeholderProp={"Date of Birth"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <Text>or</Text>
                <PrimaryInput
                  type="number"
                  variant={"bothSide"}
                  inputPlace="Age in Years"
                  inputValue={newVisitData.age}
                  isDisabled={true}
                  //onChange={(e) => handleNewVisitChange("age", e.target.value)}
                />
                    <PrimaryInput
                        type="number"
                        variant={screenType === 'mobile' ? "leftSide" : "bothSide"}
                        inputPlace="Age in Months"
                        inputValue={newVisitData.month || ""} // Ensure the input value doesn't show NaN
                        onChange={(e) => {
                          const value = e.target.value; // Get the input value
                          const age = value === "" ? null : parseInt(value, 10); // Handle empty string
                          handleNewVisitChange("month", age);
                          handleNewVisitChange("dob", "");
                        }}
                      />               
                <DropdownInput
                  variant="rightSide"
                  dropDownPlace="Gender"
                  defaultOption={getGender(newVisitData.gender)}
                  options={["Male", "Female", "Other"]}
                  onClick={handleGenderChange}
                />
              </Box>
              <Box display="flex" flexDir="column">
              <Textarea
                  borderRadius='0'
                  placeholder="Address"
                  defaultValue={newVisitData.address}
                  onChange={(e) =>
                    handleNewVisitChange("address", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant='leftDown'
                  inputPlace="Emergency Contact Name"
                  inputValue={newVisitData.emergencycontactname}
                  onChange={(e) =>
                    handleNewVisitChange("emergencycontactname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0 0 10px 0', h:'48px'}}
                  inputStyle={{ borderRadius: "0" }}
                  containerStyle={{borderRadius:'0 0 10px 0', h:'48px'}}
                  placeholderProp="Emergency mobile"
                  onCountryChange={handleCountryChangeForEmergency} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={parseInt(newVisitData.emergencycontactcountryid)}
                  onChange={(e) =>
                    handleNewVisitChange(
                      "emergencycontactphone",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={newVisitData.emergencycontactphone}
                />
              </Box>
              <RadioGroup
                defaultValue={"Schedule Now"}
                mt={"12px"}
                onChange={(value) =>
                  handleNewVisitChange("schedule_later", value)
                }
              >
                <Stack direction="row" gap="70px" ml="20%" mr="20%">
                  <Radio colorScheme="green" value={"Schedule Now"}>Schedule Now</Radio>
                  <Spacer />
                  <Radio colorScheme="green" value={"Schedule Later"}>Schedule Later</Radio>
                </Stack>
              </RadioGroup>
              {/* Space to Schedule visit */}
              {newVisitData.schedule_later === "Schedule Later" && (
                <Box mt={"8px"}>
                  <DatePicker
                    defaultDate={newVisitData.appointment_date}
                    minDate={newVisitData.appointment_date}
                    // disableFutureDates={false}
                    onDateChange={(selectedDateValue) =>
                      handleNewVisitChange(
                        "appointment_date",
                        selectedDateValue
                      )
                    }
                    placeholderProp={"Select Date"}
                    calendarStlye={{ left: "-18px", right: "unset" }}
                  />
                  <NewSlotSelector availableSlots={availableSlots} />
                
                </Box>
              )}
              <Box display="flex" alignItems='center' gap='1rem' mt="12px">
              <InputGroup>
              <InputLeftAddon height="48px">{currency}</InputLeftAddon>
              <PrimaryInput
                  type="number"
                  // inputStyleCss={{w:"50%"}}
                  variant={"bothSide"}
                  inputPlace="Fees"
                  inputValue={newVisitData.fees}
                  onChange={(e) => handleNewVisitChange("fees", e.target.value)}
                />
              </InputGroup>
                
                <RadioGroup
                defaultValue={"1"}
                mt={"12px"}
                w='100%'
                onChange={(value) => {
                  handleNewVisitChange("isfreevisit", value);
                  
                  // If value is '1', set fees to 0
                  if (value === "1") {
                    handleNewVisitChange("fees", "0.00"); // Set fees to 0
                  }else
                  handleNewVisitChange("fees", DoctorFeesStatic);
                }}
              >
                <Stack direction="row" gap="1rem" >
                  <Radio colorScheme="green" value={"0"}>Cash</Radio>
                  {/* <Spacer /> */}
                  <Radio colorScheme="green" value={"1"}>Free visit</Radio>
                </Stack>
              </RadioGroup>

              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
          <Flex gap='1rem' w='100%'>
            <PrimaryButton
              variant="grayBtn"
              buttonText={
                newVisitData.schedule_later === "Schedule Now"
                  ? "Create Visit"
                  : "Create Visit"
              }
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={()=>handleCreateVisitClick("false")}
              isDisabled={isButtonDisabled}
            />
             <PrimaryButton
              variant="grayBtn"
              buttonText="Advanced Details"
              btnStyle={{
                // h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                color:'#000',
              }}
              onClick={()=>handleCreateVisitClick("true")}
              isDisabled={isButtonDisabled}
            />
            </Flex>
          </>
        }
      />

  {/* Reschedule Visit  */}
  <DynamicModal
        ref={rescheduleVisit}
        modalHeader="Reschedule Visit"
        modalWidth="40%"
        modalBody={
          <>
            <Box>
              <Box mt={"8px"}>
                <DatePicker
                  defaultDate={moment(new Date()).format("DD-MM-YYYY")}
                  minDate={moment(new Date()).format("DD-MM-YYYY")}
                  // disableFutureDates={false}
                  onDateChange={(selectedDateValue) =>
                    setAppointmentRescheduleDate(selectedDateValue)
                  }
                  placeholderProp={"Select Date"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <SlotSelector availableSlots={availableSlots} />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Reschedule"
              // onClick={() => rescheduleVisit.current.closeModal()}
              onClick={handleRescheduleClick}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </>
        }
      />

  {/* Mark as cancel Visit  */}
  <DynamicModal
        ref={cancelVisit}
        modalHeader="Cancel visit"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to cancel your visit with{" "}
                <Box as="span" fontWeight="500">
                  {fullName}
                  {/* {patientViewData.fullname} */}
                  {/* {patientViewData.patientinfo?.firstname +
                    patientViewData.patientinfo?.lastname} */}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                  {date} at{" "}
                  {time}?
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Cancel visit"
              onClick={handleConfirmCancel}
            />
          </>
        }
      />

  {/* Edit Profile Modal */}
  <DynamicModal
        ref={editProfileModal}
        modalHeader="Edit Profile"
        modalWidth="60%"
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
              
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant="leftSide"
                  inputPlace="First Name*"
                  inputValue={editPatientData.firstname}
                  onChange={(e) =>
                    handleEditProfileChange("firstname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInput
                  type="text"
                  variant='rightSide'
                  inputPlace="Last Name*"
                  inputValue={editPatientData.lastname}
                  onChange={(e) =>
                    handleEditProfileChange("lastname", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" alignItems='center' gap='1rem' flexDir="row">
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0 0 0 10px'}}
                  inputStyle={{ borderRadius: "0 0 10px 10px" }}
                  onCountryChange={handleCountryChange} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={parseInt(editPatientData.countryid)}
                  onChange={(e) =>
                    handleEditProfileChange(
                      "mobileno",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={editPatientData.mobileno}
                />
                 <Text>or</Text>
                <PrimaryInput
                  type="text"
                  mt={"8px"}
                  width={"80%"}
                  variant='rightDown'
                  inputPlace="Email Address"
                  inputValue={editPatientData.email}
                  onChange={(e) =>
                    handleEditProfileChange("email", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" alignItems='center' gap='1rem' flexDir="row" mt="12px">
              <DatePicker
                  value={editPatientData.dob? editPatientData.dob: null}
                  clearValue={true}
                  disableFutureDates={true}
                  onDateChange={(selectedDateValue) => {
                    const formattedDob = moment(selectedDateValue).format("DD-MM-yyyy");
                    const formattedAge = moment(selectedDateValue).format("YYYY-MM-DD");
                    // const age = calculateAge(formattedDob);
                    const age = calculateAge(formattedAge);
                    handleEditProfileChange('dob', formattedDob); // Set DOB
                    handleEditProfileChange('age', age); // Set Age
                  }}
                  placeholderProp={"Date of Birth"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <Text>or</Text>
                <PrimaryInput
                  type="number"
                  variant={"bothSide"}
                  inputPlace="Age in Years"
                  inputValue={editPatientData.age}
                  isDisabled={true}
                  //onChange={(e) => handleEditProfileChange("age", e.target.value)}
                />
               
                <DropdownInput
                  variant="rightSide"
                  dropDownPlace="Gender"
                  defaultOption={getGender(editPatientData.gender)}
                  options={["Male", "Female", "Other"]}
                  onClick={handleGenderChange}
                />
              </Box>
              <Box display="flex" flexDir="column">
              <Textarea
                  borderRadius='0'
                  placeholder="Address"
                  defaultValue={editPatientData.address}
                  onChange={(e) =>
                    handleEditProfileChange("address", e.target.value)
                  }
                />
              </Box>
              <Box display="flex" flexDir="row">
                <PrimaryInput
                  type="text"
                  variant='leftDown'
                  inputPlace="Emergency Contact Name"
                  inputValue={editPatientData.emergencycontactname}
                  onChange={(e) =>
                    handleEditProfileChange("emergencycontactname", e.target.value)
                  }
                  width={"30%"}
                />
                <Spacer />
                <PrimaryInputDropdown
                  variant="bothSide"
                  boxStyle={{borderRadius:'0 0 10px 0', h:'48px'}}
                  inputStyle={{ borderRadius: "0" }}
                  containerStyle={{borderRadius:'0 0 10px 0', h:'48px'}}
                  placeholderProp="Emergency mobile"
                  onCountryChange={handleCountryChangeForEmergency} // for country codes
                  useLocalStorage={false} // Ensure this is set to false to use the callback
                  defaultCountryCode={parseInt(editPatientData.emergencycontactcountryid)}
                  onChange={(e) =>
                    handleEditProfileChange(
                      "emergencycontactphone",
                      e.target.value.replace(/\D/g, "").slice(0, 10)
                    )
                  }
                  // onKeyPress={handleMobileKeyPress}
                  inputValue={editPatientData.emergencycontactphone}
                />
              </Box>
              
              

            </Box>
          </>
        }
        modalFooterBtn={
          <>
          <Flex gap='1rem' w='100%'>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Edit"
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                m: "0",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
              // onClick={() => newVisit.current.closeModal()}
              onClick={()=>handleEditProfileClick()}
              // onClick={()=>handleCreateVisitClick("false")}
            />
             
            </Flex>
          </>
        }
      />

    </>
  )
}

export default PatientViewIndex;
