import moment from "moment";
export function formapimodalappointment({
  appointmentdate,
  appointmenttime,
  doctorname,
  patientname,
  patientimage,
  imagepath,
  patientid,
  appointmentid,
  appointmentcompleted,
  appointmentstatus,
  chiefcomplaint,
  appointmenttype,
  appointmentcurrentstatus,
  appointmentstatusname,
  isaasign,
  assignstatusname,
}) {
  // const formattedDate = moment
  //   .utc(appointmentdate)
  //   .local()
  //   .format("YYYY-MM-DD");
  // const formattedTime = formattedDate + "T" + appointmenttime; // set according to events
  const formattedDate = moment
  (appointmentdate)
  .format("YYYY-MM-DD");
const formattedTime = formattedDate + "T" + appointmenttime; 
  return Object.freeze({
    appointmentdate: formattedDate,
    appointmenttime: formattedTime,
    doctorname,
    patientname,
    patientimage,
    imagepath,
    patientid,
    appointmentid,
    appointmentcompleted,
    appointmentstatus,
    chiefcomplaint,
    appointmenttype,
    appointmentcurrentstatus,
    appointmentstatusname,
    isaasign,
    assignstatusname,
  });
}

export function formapimodalappointmentdetails({
  chiefcomplaint,
  historyofpresentillness,
  hpitext,
  patientinfo,
  previousvisits,
  appointmentid,
  appointmentdate,
  appointmenttime,
  bookingid,
  appointmentstatus,
  appointmentstatusname,
  appointmentstatusid,
  timestamp,
}) {
  const formattedPatientInfo = {
    firstname: patientinfo?.firstname,
    lastname: patientinfo?.lastname,
    height: patientinfo?.height,
    weight: patientinfo?.weight,
    weightunit: patientinfo?.weightunit,
    heightunit: patientinfo?.heightunit,
    gender: patientinfo?.gender,
    dob: patientinfo?.dob
      ? moment(patientinfo?.dob).format("D MMMM, YYYY")
      : null,
    mobileno: patientinfo?.mobileno,
    countrycode: patientinfo?.countrycode,
    flagimg: patientinfo?.flagimageurl,
    email: patientinfo?.email,
    patientid: patientinfo?.patientid,
    id: patientinfo?.id,
    // image:
    //   patientinfo?.imagepath && patientinfo?.patientimage
    //     ? patientinfo?.imagepath + patientinfo?.patientimage
    //     : null,
    image:
      patientinfo?.patientimage ? 
        (patientinfo?.patientimage.startsWith('http') ? patientinfo?.patientimage : patientinfo?.imagepath + patientinfo?.patientimage) 
        : '/assets/imgs/no-image.png',
  };

  const formattedPreviousVisits = (previousvisits || []).map((visit) => ({
    chiefcomplaint: visit.chiefcomplaint,
    appointmentid: visit.appointmentid,
    appointmentdate: moment(visit.appointmentdate).format("Do MMM, YYYY"),
    specality: visit.specality,
    doctorname: visit?.doctorname,
    bookingid: visit?.bookingid,
  }));

  formattedPreviousVisits.sort((a, b) => {
    const dateA = moment(a.appointmentdate, "Do MMM, YYYY");
    const dateB = moment(b.appointmentdate, "Do MMM, YYYY");
    return dateB.diff(dateA);
  });

  const formattedAppointmentTime = moment(appointmenttime, "HH:mm").format(
    "hh:mm A"
  );

  const formattedAppointmentDate = moment(appointmentdate).format("Do MMM, YYYY");
    // .utc(appointmentdate)
    // .local()
    // .format("ddd, D MMM");
     //appointmentdate: moment(visit.appointmentdate).format("Do MMM, YYYY"),

  return Object.freeze({
    chiefcomplaint,
    historyofpresentillness,
    hpitext,
    patientinfo: formattedPatientInfo,
    previousvisits: formattedPreviousVisits,
    appointmentid,
    appointmenttime: formattedAppointmentTime,
    appointmentdate: formattedAppointmentDate,
    bookingid,
    appointmentstatus,
    appointmentstatusname,
    appointmentstatusid,
    timestamp,
  });
}
