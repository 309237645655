import React, { useState, useEffect } from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import moment from "moment";
import TabSection from "../../tabs";
import DateRangeIndex from "./date-range";
import AreaPlotChart from "./areaplotchart";
import GroupedColumnRangePlot from "./columnrangechart";
import { FetchVitalsData } from "../../../middleware/services/vitalschart";
import GrayCard from "../../card";

const statusColorMap = {
  Ok: "green.500",
  Low: "orange.300",
  High: "red.500",
};

const statusIconMap = {
  Ok: "/assets/svgs/ok.svg",
  Normal: "/assets/svgs/ok.svg",
  Low: "/assets/svgs/low.svg",
  High: "/assets/svgs/high.svg",
};

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const VitalDisplayCard = ({ vitals, patientId, doctorId, appointmentId, showLoader, dismissLoader }) => {
  const [activeViewTab, setActiveViewTab] = useState("Recent");

  const handleViewTabClick = (index) => {
    switch (index) {
      case 0:
        setActiveViewTab("Recent");
        break;
      case 1:
        setActiveViewTab("History");
        break;

      default:
        break;
    }
  };

  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const thisweekstartdate = moment()
    .startOf("week")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const thismonthstartdate = moment()
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const thisyearstartdate = moment()
    .startOf("year")
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  // This is to by default set this week dates
  const [activeTab, setActiveTab] = useState(thisyearstartdate);

  const [data, setData] = useState(null);

  const [oxygensaturationlabel, setOxygenSaturationLabel] = useState(null);
  const [pulselabel, setPulseLabel] = useState(null);
  const [bodytemplabel, setBodyTempLabel] = useState(null);
  const [respiratorylabel, setRespiratoryLabel] = useState(null);
  const openCalendar = React.useRef(null);

  const [isCalendarVisible, setCalendarVisibility] = useState(false);

  const handleTabClick = (index) => {
    switch (index) {
      case 0:
        setActiveTab(today);
        fetchFilterData(today, "Today");
        break;
      case 1:
        setActiveTab(thisweekstartdate);
        fetchFilterData(thisweekstartdate, "Week");
        break;
      case 2:
        setActiveTab(thismonthstartdate);
        fetchFilterData(thismonthstartdate, "Month");
        break;
      case 3:
        setActiveTab(thisyearstartdate);
        fetchFilterData(thisyearstartdate, "Year");
        break;

      default:
        break;
    }
  };

  const handleCalendar = () => {
    setCalendarVisibility(!isCalendarVisible);
    // openCalendar.current.toggleCalendar();
  };

  const fetchFilterData = async (fromdate, type) => {
    showLoader();
    try {
      const result = await FetchVitalsData(
        {
          patientid: patientId,
          type: "",
          fromdate: fromdate,
          todate: today,
        },
        type
      );
      if (result != null) {
        setData(result);
        // if (result.weight.length > 0) {
        //   const weightobject = result.weight[result.weight.length - 1].value;
        //   setWeightLabel(weightobject);
        // }
        // if (result.height.length > 0) {
        //   const heightobject = result.height[result.height.length - 1].value;
        //   setHeightLabel(heightobject);

        //   const convertedValuesToNeumeric = result.height.map(item => ({
        //     ...item,
        //     value: formatNumber(item.value),
        // }));

        //   setHeightValuesToNumeric(convertedValuesToNeumeric)

        // }
        if (result.oxygensaturation.length > 0) {
          const oxygensaturationobject =
            result.oxygensaturation[result.oxygensaturation.length - 1].value;
          setOxygenSaturationLabel(oxygensaturationobject);
        }
        if (result.pulse.length > 0) {
          const pulseobject = result.pulse[result.pulse.length - 1].value;
          setPulseLabel(pulseobject);
        }
        if (result.bodytempurature.length > 0) {
          const bodytempuratureobject =
            result.bodytempurature[result.bodytempurature.length - 1].value;
          setBodyTempLabel(bodytempuratureobject);
        }
        if (result.respiratoryrate.length) {
          const respiratoryrateobject =
            result.respiratoryrate[result.respiratoryrate.length - 1].value;
          setRespiratoryLabel(respiratoryrateobject);
        }
      } else {
        // alert("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };

  useEffect(() => {
    fetchFilterData(thisyearstartdate, "Year");
  }, []);

  const handlerangedate = async (selecteddate) => {
    showLoader();
    try {
      const result = await FetchVitalsData({
        patientid: patientId,
        type: "",
        fromdate: selecteddate.startDate,
        todate: selecteddate.endDate,
      });
      if (result != null) {
        setData(result);
        // if (result.weight.length > 0) {
        //   const weightobject = result.weight[result.weight.length - 1].value;
        //   setWeightLabel(weightobject);
        // }
        // if (result.height.length > 0) {
        //   const heightobject = result.height[result.height.length - 1].value;
        //   setHeightLabel(heightobject);
        // }
        if (result.oxygensaturation.length > 0) {
          const oxygensaturationobject =
            result.oxygensaturation[result.oxygensaturation.length - 1].value;
          setOxygenSaturationLabel(oxygensaturationobject);
        }
        if (result.pulse.length > 0) {
          const pulseobject = result.pulse[result.pulse.length - 1].value;
          setPulseLabel(pulseobject);
        }
        if (result.bodytempurature.length > 0) {
          const bodytempuratureobject =
            result.bodytempurature[result.bodytempurature.length - 1].value;
          setBodyTempLabel(bodytempuratureobject);
        }
        if (result.respiratoryrate.length) {
          const respiratoryrateobject =
            result.respiratoryrate[result.respiratoryrate.length - 1].value;
          setRespiratoryLabel(respiratoryrateobject);
        }
      } else {
        console.error("No data");
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in DashboardIndex:", error);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" mx="auto" maxWidth="100%">
        <TabSection
          defaultIndex={0}
          tabs={["Recent", "History"]}
          onClickTab={handleViewTabClick}
          tabBtnStyle={{ minW: { lg: "120px", xl: "140px" } }}
        />
      </Box>
      {activeViewTab === "Recent" ? (
        <Box
          display="flex"
          flexWrap="wrap"
          gap="4"
          justifyContent="space-between"
          mt="2rem"
        >
          {vitals.map((vital, index) => {
            const statusColor = statusColorMap[vital.vitalStatus] || "gray.500";
            const statusIcon = statusIconMap[vital.vitalStatus] || "";

            return (
              <Box
                key={index}
                border="1px"
                borderColor="#E6E7E9"
                borderRadius="md"
                p={4}
                minW="8.75rem"
                maxW="48%"
                backgroundColor="white"
                flex="1 1 48%"
                mb={4}
              >
                <Text fontSize="12px" fontWeight="500" color="#6A707D">
                  {vital.vitalName}
                </Text>
                <Box display="flex" alignItems="baseline">
                  <Text
                    fontSize={{ base: "18px", md: "22px" }}
                    fontWeight="600"
                  >
                    {vital.vitalValue}
                  </Text>
                  <Text
                    fontSize={{ base: "12px", md: "15px" }}
                    pl="5px"
                    fontWeight="400"
                  >
                    {vital.vitalUnit}
                  </Text>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="0.5rem"
                >
                  <Text fontSize="12px" fontWeight="500" color="#6A707D">
                    {formatDate(vital.vitalDate)}
                  </Text>
                  <Box display="flex" alignItems="center" gap="0.25rem">
                    {statusIcon && <Image w="12px" h="auto" src={statusIcon} />}
                    <Text
                      as="span"
                      fontSize="12px"
                      fontWeight="600"
                      color={statusColor}
                    >
                      {vital.vitalStatus}
                    </Text>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <>
          <Box display="flex" w={"100%"}>
            <Box
              display="flex"
              gap="10px"
              zIndex="99"
              alignItems="center"
              px="10px"
              mx="unset"
              mt="2rem"
            >
              <TabSection
                defaultIndex={3}
                tabs={["Today", "This week", "This month", "This year"]}
                onClickTab={handleTabClick}
                tabBtnStyle={{minW: 'fit-content',whiteSpace:'nowrap', fontSize:{base:"0.875rem",md:"1rem"},lineHeight:'normal'}} 
                tabContainerStyle={{flexWrap: {base:'wrap', lg:'nowrap'},h:'100%'}} 
              />
              <Box
                as="button"
                bg="none"
                p="10px"
                border="1px solid #E6E7E9"
                borderRadius="10px"
                onClick={handleCalendar}
              >
                <Image w="18px" src="/assets/imgs/calendar.png" />
              </Box>
              {isCalendarVisible && (
                <DateRangeIndex
                  ref={openCalendar}
                  onDateChange={(selectedDateValue) =>
                    handlerangedate(selectedDateValue)
                  }
                />
              )}
            </Box>
          </Box>

          {/* <Box
            p={4}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              {data !== null && data.respiratoryrate.length > 0 ? (
                <GrayCard
                  cardHeader="Respiratory Rate"
                  cardHeaderIcon="/assets/imgs/lungs.png"
                  cardBody={
                    <>
                      <Box w="100%">
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="16px"
                          fontWeight="600"
                          pb="10px"
                        >
                          {respiratorylabel} bpm
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <AreaPlotChart
                            originalData={data.respiratoryrate}
                            colors="l(270) 0:#ffffff 0.5:#109E6A 1:#109E6A"
                            lineColor="#109E6A"
                            height="200px"
                            chartName="respChart"
                            dotColor="#109E6A"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="Respiratory Rate"
                  cardHeaderIcon="/assets/imgs/lungs.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/res-chart.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                />
              )}
            </Box>
            {data !== null && data.bodytempurature.length > 0 ? (
              <GrayCard
                cardHeader="body Temperature"
                cardHeaderIcon="/assets/imgs/thermo.png"
                cardBody={
                  <>
                    <Box w="100%">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {bodytemplabel} °C
                        </Box>
                        <AreaPlotChart
                          originalData={data.bodytempurature}
                          colors="l(270) 0:#ffffff 0.5:#1C4EC5 1:#1C4EC5"
                          lineColor="#1C4EC5"
                          height="200px"
                          chartName="bodyTempChart"
                          dotColor="#1C4EC5"
                        />
                      </Box>
                    </Box>
                  </>
                }
              />
            ) : (
              <GrayCard
                cardHeader="body Temperature"
                cardHeaderIcon="/assets/imgs/thermo.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="left"
                      gap="10px"
                    >
                      <Box
                        as="h1"
                        textAlign="left"
                        fontSize="28px"
                        fontWeight="600"
                      >
                        No data
                      </Box>
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="100%"
                          opacity="0.5"
                          src="/assets/imgs/temp-chart.png"
                        />
                      </Box>
                    </Box>
                  </>
                }
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            p={4}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              {data !== null && data.oxygensaturation.length > 0 ? (
                <GrayCard
                  cardHeader="Oxygen Saturation"
                  cardHeaderIcon="/assets/imgs/spo2.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {oxygensaturationlabel} %
                        </Box>
                        <AreaPlotChart
                          originalData={data.oxygensaturation}
                          colors="l(270) 0:#ffffff 0.5:#F04438 1:#F04438"
                          lineColor="#F04438"
                          height="290px"
                          chartName="oxySatChart"
                          dotColor="#F04438"
                        />
                      </Box>
                    </>
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="Oxygen Saturation"
                  cardHeaderIcon="/assets/imgs/spo2.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/oxygen-chart.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                />
              )}
            </Box>
            {data !== null && data.bloodpressure.length > 0 ? (
              <GrayCard
                cardHeader="Blood Pressure"
                cardHeaderIcon="/assets/imgs/blood_pressure.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="left"
                      gap="5px"
                    >
                      <GroupedColumnRangePlot
                        data={data.bloodpressure}
                        height={"250px"}
                      />
                    </Box>
                  </>
                }
              />
            ) : (
              <GrayCard
                cardHeader="Blood Pressure"
                cardHeaderIcon="/assets/imgs/blood_pressure.png"
                cardBody={
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="left"
                      gap="10px"
                    >
                      <Box
                        as="h1"
                        textAlign="left"
                        fontSize="28px"
                        fontWeight="600"
                      >
                        No data
                      </Box>
                      <Box textAlign="left" fontSize="16px" fontWeight="600">
                        <Image
                          w="100%"
                          opacity="0.5"
                          src="/assets/imgs/blood-pressure-charts.png"
                        />
                      </Box>
                    </Box>
                  </>
                }
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="20px"
            p={4}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              {data !== null && data.pulse.length > 0 ? (
                <GrayCard
                  cardHeader="heart rate"
                  cardHeaderIcon="/assets/imgs/heart-rate.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="16px"
                          fontWeight="600"
                        >
                          {pulselabel} bpm
                        </Box>
                        <AreaPlotChart
                          originalData={data.pulse}
                          colors="l(270) 0:#ffffff 0.5:#D80027 1:#D80027"
                          lineColor="#D80027"
                          height="200px"
                          chartName="redChart"
                          dotColor="#D80027"
                        />
                      </Box>
                    </>
                  }
                />
              ) : (
                <GrayCard
                  cardHeader="heart rate"
                  cardHeaderIcon="/assets/imgs/heart-rate.png"
                  cardBody={
                    <>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="10px"
                      >
                        <Box
                          as="h1"
                          textAlign="left"
                          fontSize="28px"
                          fontWeight="600"
                        >
                          No data
                        </Box>
                        <Box textAlign="left" fontSize="16px" fontWeight="600">
                          <Image
                            w="100%"
                            opacity="0.5"
                            src="/assets/imgs/heart-.png"
                          />
                        </Box>
                      </Box>
                    </>
                  }
                />
              )}
            </Box>
          </Box> */}

          <Box overflowY={"auto"} height={"auto"} mt="2rem" width={"100%"}>
            <Box
              p={4}
              display="flex"
              flexDirection="column" // Changed to column
              justifyContent="space-between"
              gap="20px"
              w="100%"
            >
              <Box
                display="flex"
                flexDirection="column" // Changed to column for inner boxes too
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.respiratoryrate.length > 0 ? (
                  <GrayCard
                    cardHeader="Respiratory Rate"
                    cardHeaderIcon="/assets/imgs/lungs.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box w="100%">
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                            pb="10px"
                          >
                            {respiratorylabel} bpm
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                          >
                            <AreaPlotChart
                              originalData={data.respiratoryrate}
                              colors="l(270) 0:#ffffff 0.5:#109E6A 1:#109E6A"
                              lineColor="#109E6A"
                              height="200px"
                              chartName="respChart"
                              dotColor="#109E6A"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Respiratory Rate"
                    cardHeaderIcon="/assets/imgs/lungs.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/res-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.bodytempurature.length > 0 ? (
                  <GrayCard
                    cardHeader="Body Temperature"
                    cardHeaderIcon="/assets/imgs/thermo.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box w="100%">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="left"
                            gap="10px"
                          >
                            <Box
                              as="h1"
                              textAlign="left"
                              fontSize="16px"
                              fontWeight="600"
                            >
                              {bodytemplabel} °C
                            </Box>
                            <AreaPlotChart
                              originalData={data.bodytempurature}
                              colors="l(270) 0:#ffffff 0.5:#1C4EC5 1:#1C4EC5"
                              lineColor="#1C4EC5"
                              height="200px"
                              chartName="bodyTempChart"
                              dotColor="#1C4EC5"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Body Temperature"
                    cardHeaderIcon="/assets/imgs/thermo.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/temp-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.oxygensaturation.length > 0 ? (
                  <GrayCard
                    cardHeader="Oxygen Saturation"
                    cardHeaderIcon="/assets/imgs/spo2.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {oxygensaturationlabel} %
                          </Box>
                          <AreaPlotChart
                            originalData={data.oxygensaturation}
                            colors="l(270) 0:#ffffff 0.5:#F04438 1:#F04438"
                            lineColor="#F04438"
                            height="290px"
                            chartName="oxySatChart"
                            dotColor="#F04438"
                          />
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Oxygen Saturation"
                    cardHeaderIcon="/assets/imgs/spo2.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/oxygen-chart.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.bloodpressure.length > 0 ? (
                  <GrayCard
                    cardHeader="Blood Pressure"
                    cardHeaderIcon="/assets/imgs/blood_pressure.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="5px"
                        >
                          <GroupedColumnRangePlot
                            data={data.bloodpressure}
                            height={"250px"}
                          />
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Blood Pressure"
                    cardHeaderIcon="/assets/imgs/blood_pressure.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="left"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/blood-pressure-charts.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                )}
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="20px"
                w="100%"
              >
                {data !== null && data.pulse.length > 0 ? (
                  <GrayCard
                    cardHeader="Heart Rate"
                    cardHeaderIcon="/assets/imgs/heart-rate.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            {pulselabel} bpm
                          </Box>
                          <AreaPlotChart
                            originalData={data.pulse}
                            colors="l(270) 0:#ffffff 0.5:#D80027 1:#D80027"
                            lineColor="#D80027"
                            height="200px"
                            chartName="redChart"
                            dotColor="#D80027"
                          />
                        </Box>
                      </>
                    }
                  />
                ) : (
                  <GrayCard
                    cardHeader="Heart Rate"
                    cardHeaderIcon="/assets/imgs/heart-rate.png"
                    hasFooter={false}
                    cardBody={
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="10px"
                        >
                          <Box
                            as="h1"
                            textAlign="left"
                            fontSize="28px"
                            fontWeight="600"
                          >
                            No data
                          </Box>
                          <Box
                            textAlign="left"
                            fontSize="16px"
                            fontWeight="600"
                          >
                            <Image
                              w="100%"
                              opacity="0.5"
                              src="/assets/imgs/heart-.png"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default VitalDisplayCard;
