import React, { useState,useEffect } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
  Image,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import {GetPartnerData} from "../../../middleware/services/visits/patient-overview/updatedoctornotes"; 
const Prescription = ({
  drName,
  hospitalLogo,
  doctorQualification,
  doctorSpeciality,
  doctorPhone,
  doctorPhoneCountryCode,
  doctorMail,
  hospitalAddress,
  patientBookingId,
  patientName,
  patientGender,
  patientAge,
  patientPhone,
  patientPhoneCountryCode,
  appointmentDate,
  patientdiagnosis,
  vitalData,
  chiefcomplaint,
  diagnosisData,
  medicalHistory,
  prescribedMedicines,
  scaleOut,
  recommendedTests,
  followUpDate,
  referralDoctor,
  referralDoctorSpeciality,
  doctorSignImage,
  followupDetail,
  generalnotes,
  dieticiannotes,
}) => {

  // const options = [
  //   "Referral to Higher Centers",
  //   "Nutrition packs for pregnant women",
  //   "ECG",
  //   "LABs",
  //   "USG Scanning for Ante Natal/others",
  //   "IV Fluids",
  //   "Injectibles for Seasonal Diseases",
  //   "Neubilizations",
  //   "MOT Procedures",
  //   "Other Minor OT",
  //   "IP Cases",
  //   "Physiotherapy Care",
  //   "Ambulance Service"
  // ];
  
  const [partnerData, setpartnerData] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const doctorId = params.get("doctorId");
  useEffect(() => {
    fetchPartnerDetials();
  }, []);
  const fetchPartnerDetials = async () => {
    const inputToFetch = {
      input: {
       doctorid: doctorId,
      
      },
    };
   
    try {
    

      const response = await GetPartnerData(inputToFetch.input);
     
      if(response.output.result == "success"){
        const data = response?.output?.data;
        console.log(data)
        setpartnerData(data);
      }
    
      console.log(response)

   
    }
    catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } 
  };
  
  const generateOptions = (followupDetail) => {
    // Ensure followupDetail is a valid object
    followupDetail = followupDetail || {};
  
    const mappings = {
      referraltohighercenters: "Referral to Higher Centers",
      nutritionpacksforpregnantwomen: "Nutrition packs for pregnant women",
      ecg: "ECG",
      labs: "LABs",
      usgscanningforantenatalothers: "USG Scanning for Ante Natal/others",
      ivfluids: "IV Fluids",
      injectiblesforseasonaldiseases: "Injectibles for Seasonal Diseases",
      nebulizations: "Nebulizations",
      motprocedures: "MOT Procedures",
      otherminorot: "Other Minor OT",
      ipcases: "IP Cases",
      physiotherapycare: "Physiotherapy Care",
      ambulanceservice: "Ambulance Service",
    };
  
    return Object.entries(mappings)
      .filter(([key]) => followupDetail[key] === 1)
      .map(([, value]) => value);
  };

  const options = generateOptions(followupDetail);

  const services = options?.length > 0;
  

  //----------------------------
  const chunkArray = (array, size) => {
    if (!array || array.length === 0) {
      return [];
    }
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const middleSectionHeaderWidth = 20;
  const calculateContentColumnWidth = (array, headerWidth) => {
    const columnCount = array.length;
    const remainingWidth = 100 - headerWidth;
    const columnWidth = remainingWidth / columnCount;
    return columnWidth;
  };

  const chunkedDiagnosisData = chunkArray(diagnosisData, scaleOut ? 2 : 3);

  const chunkedMedicalHistory = chunkArray(medicalHistory, 2);
  const chunkedRecommendedTests = chunkArray(recommendedTests, 2);
  const chunkedGeneralNotes = chunkArray(generalnotes, 1);
  const chunkedDieticianNotes = chunkArray(dieticiannotes, 1);
  const medicinesPerPage = 10;

  // Chunk the prescribed medicines into groups of 10
  const chunkedMedicines = chunkArray(prescribedMedicines, medicinesPerPage);
  return (
    <Box>
         {chunkedMedicines && chunkedMedicines.length > 0 ? (
          <Box>
    {chunkedMedicines.map((medicineChunk, pageIndex) => (
    <Box
      width="215mm" // A4 Dimension
      height="297mm" // A4 Dimension
      //   p={4}
      borderWidth="1px"
      borderColor="gray.200"
      position="relative" // Add this
      className="printPdf page-break"
    >
      {/* Blue Strip */}
      <Box
        // position="absolute"
        mt={"0px"}
        width={"100%"}
        top={0}
        left={0}
        right={0}
        height="8px"
        bg="blue.600"
        zIndex={1}
      />

      <Box bg={"#fafafa"}>
        {/* Header */}
        <HStack justify="space-between" pt={3} px={4}>
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="bold" fontSize="xl">
              {drName}
            </Text>
            <Text>
              {doctorQualification}, {doctorSpeciality}
            </Text>
          </VStack>
          <HStack spacing={4} px={4}>
          {partnerData.slice(0, 3).map((partner, index) => (
    <Box 
      key={index} 
      ml={index > 0 ? 2 : 0} 
      display="flex" 
      alignItems="center" 
      style={{ position: "relative", top: "-4px" }} // Moves the entire box (image + text) up
    >
      {partner.partnerlogo ? (
        <Image
          src={partner.imagepath + partner.partnerlogo}
          alt={`Partner Logo ${index + 1}`}
          height="40px"
          width="40px" 
          borderRadius="50%" 
          cursor="pointer"
        />
        
      ) : (
        <Box
          
          color="black"
          height="40px" // Adjust height as needed
          display="flex"
          alignItems="center"
          justifyContent="center"
        borderRadius="4px" // Optional for rounded corners
          cursor="pointer"
        >
          {partner.partnername.trim()}
        </Box>
      )}
    </Box>
  ))}
  <Box 
      ml='0'
      display="flex" 
      alignItems="center" 
      style={{ position: "relative", top: "-4px" }} // Moves the entire box (image + text) up
    >
            {hospitalLogo && (
              <img src={hospitalLogo} alt="Hospital Logo" height="40px" width="40px"  />
            )}
</Box>
            <Image
              src="assets/svgs/bigLineLogo.svg"
              alt="Big Line Logo"
              // height="40px"
            />
            <Image
              src="assets/svgs/miDocLogo.svg"
              alt="miDoc Logo"
              height="40px"
              mr={"36px"}
            />
          </HStack>
        </HStack>

        {/* Contact Information */}
        <HStack justify="flex-start" mt={1} px={4}>
          <HStack>
            <Image src="assets/svgs/call.svg" alt="Call" />
            <Text fontSize={"xs"}>
              {doctorPhoneCountryCode && `(${doctorPhoneCountryCode}) `}
              {doctorPhone}
            </Text>
          </HStack>
          <HStack>
            <Image src="assets/svgs/smallLinePhoneMail.svg" alt="Divider" />
            <Image src="assets/svgs/mail-gray.svg" alt="Mail" />
            <Text fontSize={"xs"}>{doctorMail}</Text>
          </HStack>
        </HStack>

        {/* Hospital Address */}
        <HStack justify="flex-start" mt={1} px={4}>
          <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
          <Text fontSize={"xs"}>{hospitalAddress}</Text>
        </HStack>
      </Box>

      {/* <Divider my={4} /> */}

      <Box p={scaleOut ? 1 : 4} px={scaleOut ? 4 : 4} zIndex={1}>
      {pageIndex === 0 && (
        <>
        <VStack align="flex-start" mt={scaleOut ? "1px" : "4px"}>
          <HStack width={"100%"}>
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              # {patientBookingId}
            </Text>
            <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "md"}>
              {patientName}
            </Text>
          </HStack>

          <HStack width={"100%"}>
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Details:
            </Text>
            <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "md"}>
              <Box as="span" mx={0}>
                {patientGender}
              </Box>
              <Box as="span" mx={patientGender ? 4 : 0}>
                {patientAge} years
              </Box>
              <Box as="span" mx={4}>
                {patientPhoneCountryCode && `(${patientPhoneCountryCode}) `}
                {patientPhone}
              </Box>

              <Box as="span" mx={4}>
                {appointmentDate}
              </Box>
            </Text>
          </HStack>

          {services && (
            <HStack width={"100%"} mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Services:
              </Text>
              <Text
                fontWeight="bold"
                fontSize={scaleOut ? "sm" : "md"}
                width={`${100 - middleSectionHeaderWidth}%`}
              >
                {options.join(", ")}
              </Text>
            </HStack>
          )}

          <Divider my={scaleOut ? 1 : 4} />

          {/* Vitals */}

          {vitalData && vitalData.length > 0 && (
            <HStack width={"100%"}>
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Vitals:
              </Text>
              {vitalData &&
                vitalData.map((vital, index) => (
                  <Text
                    key={index}
                    fontSize={
                      scaleOut || vitalData.length > 3 ? "sm" : undefined
                    }
                  >
                    <b>{vital.type}</b>: {vital.value} {vital.unit}
                  </Text>
                ))}
            </HStack>
          )}

          {/* <Divider my={scaleOut ? 1 : 4} /> */}

          {/* Chief Complaint */}
          {chiefcomplaint && (
            <HStack width={"100%"} mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Complaints:
              </Text>
              <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "md"}>
                {chiefcomplaint}
              </Text>
            </HStack>
          )}

          {/* <Divider my={scaleOut ? 1 : 4} /> */}

          {/* Diagnosis */}

          {/* {chunkedDiagnosisData && chunkedDiagnosisData.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Diagnosis:
              </Text>
              <HStack
                alignItems="flex-start"
                spacing={4}
                width={`${100 - middleSectionHeaderWidth}%`}
              >
                {chunkedDiagnosisData.map((chunk, colIndex) => (
                  <VStack
                    key={colIndex}
                    align="flex-start"
                    spacing={2}
                    width={`${calculateContentColumnWidth(
                      chunkedDiagnosisData,
                      middleSectionHeaderWidth
                    )}%`}
                  >
                    {chunk.map((diag, index) => (
                      <Text
                        key={index}
                        fontWeight="bold"
                        fontSize={scaleOut ? "sm" : "md"}
                      >
                        • {diag}
                      </Text>
                    ))}
                  </VStack>
                ))}
              </HStack>
            </HStack>
          )} */}

          {/* {chunkedDiagnosisData && chunkedDiagnosisData.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Diagnosis:
              </Text>
              <HStack
                alignItems="flex-start"
                spacing={4}
                width={`${100 - middleSectionHeaderWidth}%`}
              >
                {chunkedDiagnosisData.map((chunk, colIndex) => (
                  <VStack
                    key={colIndex}
                    align="flex-start"
                    spacing={2}
                    width={`${calculateContentColumnWidth(
                      chunkedDiagnosisData,
                      middleSectionHeaderWidth
                    )}%`}
                  >
                    {chunk.map((diag, index) => (
                      <Text
                        key={index}
                        fontWeight="bold"
                        fontSize={scaleOut ? "sm" : "md"}
                      >
                        • {diag}
                      </Text>
                    ))}
                  </VStack>
                ))}
              </HStack>
            </HStack>
          )} */}

          {(patientdiagnosis || chunkedDiagnosisData?.length > 0) && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Diagnosis:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {patientdiagnosis}
                {patientdiagnosis && chunkedDiagnosisData?.length > 0
                  ? ", "
                  : ""}
                {chunkedDiagnosisData?.flat()?.join(", ")}
                {/* Join the diagnoses with commas */}
              </Text>
            </HStack>
          )}

          {/* <Divider my={scaleOut ? 1 : 4} /> */}

          {/* Medical History */}
          {/* {chunkedMedicalHistory && chunkedMedicalHistory.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Medical History:
              </Text>
              <HStack
                alignItems="flex-start"
                spacing={4}
                width={`${100 - middleSectionHeaderWidth}%`}
              >
                {chunkedMedicalHistory.map((chunk, colIndex) => (
                  <VStack
                    key={colIndex}
                    align="flex-start"
                    spacing={2}
                    width={`${calculateContentColumnWidth(
                      chunkedMedicalHistory,
                      middleSectionHeaderWidth
                    )}%`}
                  >
                    {chunk.map((condition, index) => (
                      <Text
                        key={index}
                        fontWeight="bold"
                        fontSize={scaleOut ? "sm" : "md"}
                      >
                        • {condition}
                      </Text>
                    ))}
                  </VStack>
                ))}
              </HStack>
            </HStack>
          )} */}

          {chunkedMedicalHistory && chunkedMedicalHistory.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Medical History:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {chunkedMedicalHistory
                  .flat() // Flatten the nested arrays
                  .join(", ")}{" "}
                {/* Join the conditions with commas */}
              </Text>
            </HStack>
          )}

          {/* Recommended Tests by Doctor */}
          {/* {chunkedRecommendedTests && chunkedRecommendedTests.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Recommended Tests:
              </Text>
              <HStack
                alignItems="flex-start"
                spacing={4}
                width={`${100 - middleSectionHeaderWidth}%`}
              >
                {chunkedRecommendedTests.map((chunk, colIndex) => (
                  <VStack
                    key={colIndex}
                    align="flex-start"
                    spacing={2}
                    width={`${calculateContentColumnWidth(
                      chunkedRecommendedTests,
                      middleSectionHeaderWidth
                    )}%`}
                  >
                    {chunk.map((test, index) => (
                      <Text
                        key={index}
                        fontWeight="bold"
                        fontSize={scaleOut ? "sm" : "md"}
                      >
                        • {test}
                      </Text>
                    ))}
                  </VStack>
                ))}
              </HStack>
            </HStack>
          )} */}
          {chunkedRecommendedTests && chunkedRecommendedTests.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Recommended Tests:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {chunkedRecommendedTests
                  .flat() // Flatten the nested arrays
                  .join(", ")}{" "}
                {/* Join the tests with commas */}
              </Text>
            </HStack>
          )}
          
           {chunkedDieticianNotes && chunkedDieticianNotes.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Dietician Notes:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {chunkedDieticianNotes
                  .flat() // Flatten the nested arrays
                  .join(", ")}{" "}
                {/* Join the tests with commas */}
              </Text>
            </HStack>
          )}
           {chunkedGeneralNotes && chunkedGeneralNotes.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                General Notes:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {chunkedGeneralNotes
                  .flat() // Flatten the nested arrays
                  .join(", ")}{" "}
                {/* Join the tests with commas */}
              </Text>
            </HStack>
          )}
        </VStack>

        <Divider my={scaleOut ? 1 : 4} />

        {/* Prescription Header */}
        <HStack mt={4}>
          <Image
            src="assets/svgs/Rx.svg"
            alt="Rx"
            boxSize={scaleOut ? "12px" : "24px"}
          />
          <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "lg"}>
            Prescription
          </Text>
        </HStack>
        </>
 )}
        {/* Medicine Table */}
        <Table
          variant="simple"
          position={"absolute"}
          width={"95%"}
          size="sm"
          mt={2}
          border={"1px solid black"}
          borderBottomWidth={"1px"}
          borderRadius={"lg"}
          zIndex={"1"}
        >
          <Thead bg="#fafafa" height={scaleOut ? "24px" : "48px"}>
            <Tr>
              <Th>Medicine</Th>
              <Th>Dose</Th>
              <Th>Timing-Freq. - Duration</Th>
            </Tr>
          </Thead>
          <Tbody bg={"white"} opacity={"100%"}>
          {medicineChunk.map((med, index) => (
            <>
              <Tr key={index}>
                <Td
                  fontWeight="bold"
                  width={"50%"}
                  fontSize={scaleOut ? "sm" : "md"}
                >
                  {pageIndex * medicinesPerPage + index + 1}. {med.name}{" "}
                  {med.dosageforprescription ? (
                    <>({med.dosageforprescription})</>
                  ) : null}
                  <Text fontSize="xs" color="gray.500" pl={5}>
                    Composition:{" "}
                    {med.chemicalcomposition
                      ? med.chemicalcomposition
                      : "-/-"}
                  </Text>
                </Td>
                <Td>
                  {med.morning} - {med.evening} - {med.night}
                </Td>
                <Td>
                  {med.timing} - {med.frequency}{" "}
                  {med?.duration !== -1 ? (
                    <>({med.duration} days)</>
                  ) : (
                    "Regular"
                  )}
                </Td>
              </Tr>
              {med.customtext && (
                <Tr>
                  <Td colSpan={3} fontSize="sm" textAlign="left" pl={5}>
                    Description: {med.customtext}
                  </Td>
                </Tr>
              )}
            </>
              ))}
          </Tbody>
        </Table>

        <Divider my={scaleOut ? 1 : 4} />

        {/* Follow-up and Referral */}
        <HStack
          justify="space-between"
          width={"95%"}
          zIndex={"1"}
          position="absolute"
          bottom={"60px"}
        >
          <VStack
            align="flex-start"
            width={"65%"}
            border={"1px"}
            borderRadius={"lg"}
            height={scaleOut ? "3rem" : "5rem"}
            p={scaleOut ? "1px" : "8px"}
            px={scaleOut ? "8px" : "8px"}
            zIndex={"1"}
            bg={"white"}
            opacity={"100%"}
          >
            <Grid templateColumns="auto 1fr" gap={scaleOut ? 0 : 2}>
              {/* Follow-up */}
              <Text>Follow-up:</Text>
              <Text pl="48px">
                <b>{followUpDate}</b>
              </Text>

              {/* Referral */}
              <Text>Referral:</Text>
              <Text pl="48px">
                <b>{referralDoctor}</b>
              </Text>
            </Grid>
          </VStack>

          <VStack
            align="flex-start"
            width={"30%"}
            border={"1px"}
            borderRadius={"lg"}
            height={scaleOut ? "3rem" : "5rem"}
            p={"8px"}
            bg={"white"}
            opacity={"100%"}
          >
            <Text>Signature</Text>
            {doctorSignImage ? (
              <Image src={doctorSignImage} alt="Doctor's Signature" />
            ) : null}
          </VStack>
        </HStack>
      </Box>

      {/* Watermark Background */}
      <Box
        position="absolute"
        bottom={"40px"}
        width="100%"
        height="auto"
        zIndex={0}
      >
        <Box position="absolute" left="0" bottom="0" width="50%" height="auto">
          <img
            src="assets/imgs/PrescriptionVisualBg.png"
            alt="Prescription Background"
            style={{ width: "100%", opacity: 0.8 }}
          />
        </Box>
        <Box position="absolute" right="0" bottom="0" width="50%" height="auto">
          <img
            src="assets/imgs/PrescriptionVisualBg.png"
            alt="Prescription Background"
            style={{ width: "100%", opacity: 0.8 }}
          />
        </Box>
      </Box>

      <Box
  position="absolute"
  bottom={2}
  width="100%"
  height="40px" // Adjust height as needed
  bg="black"
  display="flex"
  justifyContent="center"
  alignItems="center"
  zIndex={"1"}
  opacity={"100%"}
  px={4} // Add padding for spacing
>
  {/* Left side: Single black background wrapping all partner logos/names */}
  {/* <Box display="flex" alignItems="center">
    {partnerData.slice(0, 3).map((partner, index) => (
      <Box key={index} ml={index > 0 ? 2 : 0} display="flex" alignItems="center">
        {partner.partnerlogo ? (
          <Image
            src={partner.imagepath + partner.partnerlogo}
            alt={`Partner Logo ${index + 1}`}
            height="24px" 
            width="24px" 
            borderRadius="50%" 
            cursor="pointer"
            onClick={() => console.log(`Partner ${partner.partnername} clicked`)}
          />
        ) : (
          <Box
            bg="black"
            color="white"
            height="24px" // Adjust height as needed
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={2}
            borderRadius="4px" // Optional for rounded corners
            cursor="pointer"
            onClick={() => console.log(`Partner ${partner.partnername} clicked`)}
          >
            {partner.partnername.trim()}
          </Box>
        )}
      </Box>
    ))}
  </Box> */}

  {/* Right side: Static images */}
  <Box display="flex" alignItems="center">
    <Image
      src="assets/svgs/website-globe.svg"
      alt="SVG 1"
      height="8px"
      cursor="pointer"
      onClick={() => (window.location.href = "https://midoc.ai")}
    />
    <Image
      src="assets/svgs/visit-midoc.svg"
      alt="SVG 2"
      height="8px"
      ml={2}
      cursor="pointer"
      onClick={() => (window.location.href = "https://midoc.ai")}
    />
  </Box>
</Box>
    </Box>
    ))}
    </Box>
  ) : (
    <Box
    width="215mm" // A4 Dimension
    height="297mm" // A4 Dimension
    //   p={4}
    borderWidth="1px"
    borderColor="gray.200"
    position="relative" // Add this
    className="printPdf page-break"
  >
    {/* Blue Strip */}
    <Box
      // position="absolute"
      mt={"0px"}
      width={"100%"}
      top={0}
      left={0}
      right={0}
      height="8px"
      bg="blue.600"
      zIndex={1}
    />

    <Box bg={"#fafafa"}>
      {/* Header */}
      <HStack justify="space-between" pt={3} px={4}>
        <VStack align="flex-start" spacing={1}>
          <Text fontWeight="bold" fontSize="xl">
            {drName}
          </Text>
          <Text>
            {doctorQualification}, {doctorSpeciality}
          </Text>
        </VStack>
        <HStack spacing={4} px={4}>
        {partnerData.slice(0, 3).map((partner, index) => (
    <Box 
      key={index} 
      ml={index > 0 ? 2 : 0} 
      display="flex" 
      alignItems="center" 
      style={{ position: "relative", top: "-4px" }} // Moves the entire box (image + text) up
    >
      {partner.partnerlogo ? (
        <Image
          src={partner.imagepath + partner.partnerlogo}
          alt={`Partner Logo ${index + 1}`}
          height="40px"
          width="40px" 
          borderRadius="50%" 
          cursor="pointer"
        />
        
      ) : (
        <Box
          
          color="black"
          height="40px" // Adjust height as needed
          display="flex"
          alignItems="center"
          justifyContent="center"
        borderRadius="4px" // Optional for rounded corners
          cursor="pointer"
        >
          {partner.partnername.trim()}
        </Box>
      )}
    </Box>
  ))}
  
  <Box 
      ml="0" 
      display="flex" 
      alignItems="center" 
      style={{ position: "relative", top: "-4px" }} // Moves the entire box (image + text) up
    >
          {hospitalLogo && (
            <img src={hospitalLogo} alt="Hospital Logo" height="40px" width="40px"  />
          )}
</Box>
          <Image
            src="assets/svgs/bigLineLogo.svg"
            alt="Big Line Logo"
            // height="40px"
          />
          <Image
            src="assets/svgs/miDocLogo.svg"
            alt="miDoc Logo"
            height="40px"
            mr={"36px"}
          />
        </HStack>
      </HStack>

      {/* Contact Information */}
      <HStack justify="flex-start" mt={1} px={4}>
        <HStack>
          <Image src="assets/svgs/call.svg" alt="Call" />
          <Text fontSize={"xs"}>
            {doctorPhoneCountryCode && `(${doctorPhoneCountryCode}) `}
            {doctorPhone}
          </Text>
        </HStack>
        <HStack>
          <Image src="assets/svgs/smallLinePhoneMail.svg" alt="Divider" />
          <Image src="assets/svgs/mail-gray.svg" alt="Mail" />
          <Text fontSize={"xs"}>{doctorMail}</Text>
        </HStack>
      </HStack>

      {/* Hospital Address */}
      <HStack justify="flex-start" mt={1} px={4}>
        <Image src="assets/svgs/hospital-location-on.svg" alt="Location" />
        <Text fontSize={"xs"}>{hospitalAddress}</Text>
      </HStack>
    </Box>

    {/* <Divider my={4} /> */}

    <Box p={scaleOut ? 1 : 4} px={scaleOut ? 4 : 4} zIndex={1}>
      <VStack align="flex-start" mt={scaleOut ? "1px" : "4px"}>
        <HStack width={"100%"}>
          <Text
            width={`${middleSectionHeaderWidth}%`}
            fontSize={scaleOut ? "sm" : "md"}
          >
            # {patientBookingId}
          </Text>
          <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "md"}>
            {patientName}
          </Text>
        </HStack>

        <HStack width={"100%"}>
          <Text
            width={`${middleSectionHeaderWidth}%`}
            fontSize={scaleOut ? "sm" : "md"}
          >
            Details:
          </Text>
          <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "md"}>
            <Box as="span" mx={0}>
              {patientGender}
            </Box>
            <Box as="span" mx={patientGender ? 4 : 0}>
              {patientAge} years
            </Box>
            <Box as="span" mx={4}>
              {patientPhoneCountryCode && `(${patientPhoneCountryCode}) `}
              {patientPhone}
            </Box>

            <Box as="span" mx={4}>
              {appointmentDate}
            </Box>
          </Text>
        </HStack>

        {services && (
          <HStack width={"100%"} mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Services:
            </Text>
            <Text
              fontWeight="bold"
              fontSize={scaleOut ? "sm" : "md"}
              width={`${100 - middleSectionHeaderWidth}%`}
            >
              {options.join(", ")}
            </Text>
          </HStack>
        )}

        <Divider my={scaleOut ? 1 : 4} />

        {/* Vitals */}

        {vitalData && vitalData.length > 0 && (
          <HStack width={"100%"}>
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Vitals:
            </Text>
            {vitalData &&
              vitalData.map((vital, index) => (
                <Text
                  key={index}
                  fontSize={
                    scaleOut || vitalData.length > 3 ? "sm" : undefined
                  }
                >
                  <b>{vital.type}</b>: {vital.value} {vital.unit}
                </Text>
              ))}
          </HStack>
        )}

        {/* <Divider my={scaleOut ? 1 : 4} /> */}

        {/* Chief Complaint */}
        {chiefcomplaint && (
          <HStack width={"100%"} mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Complaints:
            </Text>
            <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "md"}>
              {chiefcomplaint}
            </Text>
          </HStack>
        )}

        {/* <Divider my={scaleOut ? 1 : 4} /> */}

        {/* Diagnosis */}

        {/* {chunkedDiagnosisData && chunkedDiagnosisData.length > 0 && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Diagnosis:
            </Text>
            <HStack
              alignItems="flex-start"
              spacing={4}
              width={`${100 - middleSectionHeaderWidth}%`}
            >
              {chunkedDiagnosisData.map((chunk, colIndex) => (
                <VStack
                  key={colIndex}
                  align="flex-start"
                  spacing={2}
                  width={`${calculateContentColumnWidth(
                    chunkedDiagnosisData,
                    middleSectionHeaderWidth
                  )}%`}
                >
                  {chunk.map((diag, index) => (
                    <Text
                      key={index}
                      fontWeight="bold"
                      fontSize={scaleOut ? "sm" : "md"}
                    >
                      • {diag}
                    </Text>
                  ))}
                </VStack>
              ))}
            </HStack>
          </HStack>
        )} */}

        {/* {chunkedDiagnosisData && chunkedDiagnosisData.length > 0 && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Diagnosis:
            </Text>
            <HStack
              alignItems="flex-start"
              spacing={4}
              width={`${100 - middleSectionHeaderWidth}%`}
            >
              {chunkedDiagnosisData.map((chunk, colIndex) => (
                <VStack
                  key={colIndex}
                  align="flex-start"
                  spacing={2}
                  width={`${calculateContentColumnWidth(
                    chunkedDiagnosisData,
                    middleSectionHeaderWidth
                  )}%`}
                >
                  {chunk.map((diag, index) => (
                    <Text
                      key={index}
                      fontWeight="bold"
                      fontSize={scaleOut ? "sm" : "md"}
                    >
                      • {diag}
                    </Text>
                  ))}
                </VStack>
              ))}
            </HStack>
          </HStack>
        )} */}

        {(patientdiagnosis || chunkedDiagnosisData?.length > 0) && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Diagnosis:
            </Text>
            <Text
              width={`${100 - middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
              fontWeight="bold"
            >
              {patientdiagnosis}
              {patientdiagnosis && chunkedDiagnosisData?.length > 0
                ? ", "
                : ""}
              {chunkedDiagnosisData?.flat()?.join(", ")}
              {/* Join the diagnoses with commas */}
            </Text>
          </HStack>
        )}

        {/* <Divider my={scaleOut ? 1 : 4} /> */}

        {/* Medical History */}
        {/* {chunkedMedicalHistory && chunkedMedicalHistory.length > 0 && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Medical History:
            </Text>
            <HStack
              alignItems="flex-start"
              spacing={4}
              width={`${100 - middleSectionHeaderWidth}%`}
            >
              {chunkedMedicalHistory.map((chunk, colIndex) => (
                <VStack
                  key={colIndex}
                  align="flex-start"
                  spacing={2}
                  width={`${calculateContentColumnWidth(
                    chunkedMedicalHistory,
                    middleSectionHeaderWidth
                  )}%`}
                >
                  {chunk.map((condition, index) => (
                    <Text
                      key={index}
                      fontWeight="bold"
                      fontSize={scaleOut ? "sm" : "md"}
                    >
                      • {condition}
                    </Text>
                  ))}
                </VStack>
              ))}
            </HStack>
          </HStack>
        )} */}

        {chunkedMedicalHistory && chunkedMedicalHistory.length > 0 && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Medical History:
            </Text>
            <Text
              width={`${100 - middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
              fontWeight="bold"
            >
              {chunkedMedicalHistory
                .flat() // Flatten the nested arrays
                .join(", ")}{" "}
              {/* Join the conditions with commas */}
            </Text>
          </HStack>
        )}

        {/* Recommended Tests by Doctor */}
        {/* {chunkedRecommendedTests && chunkedRecommendedTests.length > 0 && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Recommended Tests:
            </Text>
            <HStack
              alignItems="flex-start"
              spacing={4}
              width={`${100 - middleSectionHeaderWidth}%`}
            >
              {chunkedRecommendedTests.map((chunk, colIndex) => (
                <VStack
                  key={colIndex}
                  align="flex-start"
                  spacing={2}
                  width={`${calculateContentColumnWidth(
                    chunkedRecommendedTests,
                    middleSectionHeaderWidth
                  )}%`}
                >
                  {chunk.map((test, index) => (
                    <Text
                      key={index}
                      fontWeight="bold"
                      fontSize={scaleOut ? "sm" : "md"}
                    >
                      • {test}
                    </Text>
                  ))}
                </VStack>
              ))}
            </HStack>
          </HStack>
        )} */}
        {chunkedRecommendedTests && chunkedRecommendedTests.length > 0 && (
          <HStack width="100%" alignItems="flex-start" mt="4px">
            <Text
              width={`${middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
            >
              Recommended Tests:
            </Text>
            <Text
              width={`${100 - middleSectionHeaderWidth}%`}
              fontSize={scaleOut ? "sm" : "md"}
              fontWeight="bold"
            >
              {chunkedRecommendedTests
                .flat() // Flatten the nested arrays
                .join(", ")}{" "}
              {/* Join the tests with commas */}
            </Text>
          </HStack>
        )}
        
          {chunkedDieticianNotes && chunkedDieticianNotes.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                Dietician Notes:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {chunkedDieticianNotes
                  .flat() // Flatten the nested arrays
                  .join(", ")}{" "}
                {/* Join the tests with commas */}
              </Text>
            </HStack>
          )}
           {chunkedGeneralNotes && chunkedGeneralNotes.length > 0 && (
            <HStack width="100%" alignItems="flex-start" mt="4px">
              <Text
                width={`${middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
              >
                General Notes:
              </Text>
              <Text
                width={`${100 - middleSectionHeaderWidth}%`}
                fontSize={scaleOut ? "sm" : "md"}
                fontWeight="bold"
              >
                {chunkedGeneralNotes
                  .flat() // Flatten the nested arrays
                  .join(", ")}{" "}
                {/* Join the tests with commas */}
              </Text>
            </HStack>
          )}
      </VStack>

      <Divider my={scaleOut ? 1 : 4} />

      {/* Prescription Header */}
      <HStack mt={4}>
        <Image
          src="assets/svgs/Rx.svg"
          alt="Rx"
          boxSize={scaleOut ? "12px" : "24px"}
        />
        <Text fontWeight="bold" fontSize={scaleOut ? "sm" : "lg"}>
          Prescription
        </Text>
      </HStack>

      {/* Medicine Table */}
      <Table
        variant="simple"
        position={"absolute"}
        width={"95%"}
        size="sm"
        mt={2}
        border={"1px solid black"}
        borderBottomWidth={"1px"}
        borderRadius={"lg"}
        zIndex={"1"}
      >
        <Thead bg="#fafafa" height={scaleOut ? "24px" : "48px"}>
          <Tr>
            <Th>Medicine</Th>
            <Th>Dose</Th>
            <Th>Timing-Freq. - Duration</Th>
          </Tr>
        </Thead>
        <Tbody bg={"white"} opacity={"100%"}>
          {prescribedMedicines &&
            prescribedMedicines.map((med, index) => (
              <>
              <Tr key={index}>
                <Td
                  fontWeight="bold"
                  width={"50%"}
                  fontSize={scaleOut ? "sm" : "md"}
                >
                  {index + 1}. {med.name}{" "}
                  {med.dosageforprescription ? (
                    <>({med.dosageforprescription})</>
                  ) : null}
                  <Text fontSize="xs" color="gray.500" pl={5}>
                    Composition:{" "}
                    {med.chemicalcomposition
                      ? med.chemicalcomposition
                      : "-/-"}
                  </Text>
                </Td>
                <Td>
                  {med.morning} - {med.evening} - {med.night}
                </Td>
                <Td>
                  {med.timing} - {med.frequency}{" "}
                  {med?.duration !== -1 ? (
                    <>({med.duration} days)</>
                  ) : (
                    "Regular"
                  )}
                </Td>
              </Tr>
              {med.customtext && (
                <Tr>
                  <Td colSpan={3} fontSize="sm" textAlign="left" pl={5}>
                    Description: {med.customtext}
                  </Td>
                </Tr>
              )}
              </>
            ))}
        </Tbody>
      </Table>

      <Divider my={scaleOut ? 1 : 4} />

      {/* Follow-up and Referral */}
      <HStack
        justify="space-between"
        width={"95%"}
        zIndex={"1"}
        position="absolute"
        bottom={"60px"}
      >
        <VStack
          align="flex-start"
          width={"65%"}
          border={"1px"}
          borderRadius={"lg"}
          height={scaleOut ? "3rem" : "5rem"}
          p={scaleOut ? "1px" : "8px"}
          px={scaleOut ? "8px" : "8px"}
          zIndex={"1"}
          bg={"white"}
          opacity={"100%"}
        >
          <Grid templateColumns="auto 1fr" gap={scaleOut ? 0 : 2}>
            {/* Follow-up */}
            <Text>Follow-up:</Text>
            <Text pl="48px">
              <b>{followUpDate}</b>
            </Text>

            {/* Referral */}
            <Text>Referral:</Text>
            <Text pl="48px">
              <b>{referralDoctor}</b>
            </Text>
          </Grid>
        </VStack>

        <VStack
          align="flex-start"
          width={"30%"}
          border={"1px"}
          borderRadius={"lg"}
          height={scaleOut ? "3rem" : "5rem"}
          p={"8px"}
          bg={"white"}
          opacity={"100%"}
        >
          <Text>Signature</Text>
          {doctorSignImage ? (
            <Image src={doctorSignImage} alt="Doctor's Signature" />
          ) : null}
        </VStack>
      </HStack>
    </Box>

    {/* Watermark Background */}
    <Box
      position="absolute"
      bottom={"40px"}
      width="100%"
      height="auto"
      zIndex={0}
    >
      <Box position="absolute" left="0" bottom="0" width="50%" height="auto">
        <img
          src="assets/imgs/PrescriptionVisualBg.png"
          alt="Prescription Background"
          style={{ width: "100%", opacity: 0.8 }}
        />
      </Box>
      <Box position="absolute" right="0" bottom="0" width="50%" height="auto">
        <img
          src="assets/imgs/PrescriptionVisualBg.png"
          alt="Prescription Background"
          style={{ width: "100%", opacity: 0.8 }}
        />
      </Box>
    </Box>

  

  <Box
  position="absolute"
  bottom={2}
  width="100%"
  height="40px" // Adjust height as needed
  bg="black"
  display="flex"
  justifyContent="center"
  alignItems="center"
  zIndex={"1"}
  opacity={"100%"}
  px={4} // Add padding for spacing
>
  {/* Left side: Single black background wrapping all partner logos/names */}
  {/* <Box display="flex" alignItems="center">
  {partnerData.slice(0, 3).map((partner, index) => (
    <Box 
      key={index} 
      ml={index > 0 ? 2 : 0} 
      display="flex" 
      alignItems="center" 
      style={{ position: "relative", top: "-4px" }} // Moves the entire box (image + text) up
    >
      {partner.partnerlogo ? (
        <Image
          src={partner.imagepath + partner.partnerlogo}
          alt={`Partner Logo ${index + 1}`}
          height="24px" 
          width="24px" 
          borderRadius="50%" 
          cursor="pointer"
        />
      ) : (
        <Box
          bg="black"
          color="white"
          height="24px" // Adjust height as needed
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={2}
          borderRadius="4px" // Optional for rounded corners
          cursor="pointer"
        >
          {partner.partnername.trim()}
        </Box>
      )}
    </Box>
  ))}
</Box> */}


  {/* Right side: Static images */}
  <Box display="flex" alignItems="center">
    <Image
      src="assets/svgs/website-globe.svg"
      alt="SVG 1"
      height="8px"
      cursor="pointer"
      onClick={() => (window.location.href = "https://midoc.ai")}
    />
    <Image
      src="assets/svgs/visit-midoc.svg"
      alt="SVG 2"
      height="8px"
      ml={2}
      cursor="pointer"
      onClick={() => (window.location.href = "https://midoc.ai")}
    />
  </Box>
</Box>





  </Box>

  )}
    </Box>
  );
};

export default Prescription;
