import { getBranchId, getDoctorId, getLoginID } from '../../../auth';
import  FetchData, { } from '../../client';
import formAPIModelSubscription from '../../domains/signupsubscription/subscription';
import formAPISubscriptions from '../../domains/signupsubscription/subscriptions';
// import {getLoginInfo, getUserID}  from '../../../auth';


//Sign Up 
const signUpPersonalDetails = async (inputData) => {
    try {
  
      const result = await FetchData("midoc_doctorsignup", inputData);
      return result;
  
    } catch (error) {
      console.error('Error fetching data from DB signup personal details:', error);
      throw error;
    }
  };


const signUpProfessionalDetails = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_adddoctorprofessionaldetail", inputData);

    //console.log(result , " db response sign up professional detaisl")
    return result;

  } catch (error) {
    console.error('Error fetching data from DB signup professional details', error);
    throw error;
  }
};
  
  

const getDoctorPlans = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    
   // inputData.doctorid = getDoctorId();
  //  console.log(inputData)
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdoctorplan", inputData);

   // console.log(result , " db response sign up - subscription plans")
    var finalOutput =  formAPISubscriptions(result.output.data)
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data from DB signup - subscription plans', error);
    throw error;
  }
};
const updateSubscriptionPlanforsignup = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    //console.log(inputData , " input of subscription db")
    const result = await FetchData("midoc_updatedoctorplan", inputData);

   //console.log(result , " db response sign up subscription")
    return result;

  } catch (error) {
    console.error('Error fetching data from DB signup professional details', error);
    throw error;
  }
};
const updateSubscriptionPlan = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    //console.log(inputData , " input of subscription db")
    const result = await FetchData("midoc_updatedoctorplan", inputData);

   //console.log(result , " db response sign up subscription")
    return result;

  } catch (error) {
    console.error('Error fetching data from DB signup professional details', error);
    throw error;
  }
};
  


  export {signUpPersonalDetails,signUpProfessionalDetails, getDoctorPlans, updateSubscriptionPlan, updateSubscriptionPlanforsignup};

  