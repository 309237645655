import { Box, Center, Circle, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, ListItem, Select, SimpleGrid, Spacer, Stack, Text, UnorderedList, VStack,useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import MiniSideBar from '../../components/mini-sidebar'
import SecondaryNav from '../../components/seconday-nav'
import SideBar from '../../components/sidebar'
import { COLORS } from "../../components/styles/colors";
import { useForm } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";


import GrayCard from '../../components/card'
import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
    useLocation,
  } from "react-router-dom";
import { AddVitalData, FetchPatientDetails } from '../../middleware/services/messages'
import { P } from '@antv/g2plot'
import moment from 'moment'
import SimpleTable from "../../components/simple-table";

import VisibleSidebar from '../../components/visible-sidebar'
import PatientVisitsIndex from './patientvisits'
import PatientVitalsIndex from './patientvitals'
import MedicalHistory from './medicalhistory'
import SurgicalHistory from './surgicalhistory'
import FamilyHistory from './familyhistory'
import SocialHistory from './socialhistory'
import PhysicalExam from './physicalexam'
// import TreatmentPlan from './treatmentplan'
import DoctorAssessment from './doctorassessment'
import ReviewSystem from './reviewsystem'
import Allergies from './allergies'
import Vaccinations from './vaccinations'
import Investigations from './investigations'
import LabTests from './labtest'
import OverviewIndex from './overview'
import ChiefComplaint from './chiefcomplaint'
import HistoryOfPresentIllness from './historyofpresentillness'
import OrdersIndex from './orders'
import MobileSecondaryNav from '../../components/mobile-secondary-navbar'
import MedicationIndex from './medications'
import Imaging from './imaging'
import { PrimaryButton, PrimaryInput, Toaster } from '../../components'
import useLoader from '../../components/loader/loaderstates'
import { actionAccessMain, calculateAgeCommonFunc, getLoginID } from '../../auth'
import ModalRight from '../../components/modal-right'
import DoctorVitalsFormData from '../../components/vital-data-patient-profile/vital-data'
import { GetPatientHealthData } from '../../middleware/services/visits/patient-overview'
import { GetDoctorNotes, updateDoctorNotes } from '../../middleware/services/visits/patient-overview/updatedoctornotes'


const PatientProfileIndex = () => {
  const navigate = useNavigate();
  const addVitalData = useRef();
  
  
  const location = useLocation();
    const [activeSection, setActiveSection] = useState(null)
    const [backNavigate, setBackNavigate] = useState(null)
    const [patientId, setPatientId] = useState(null);
    const [patienData, setPatienData] = useState(null);
    const [screenType, setScreenType] = useState('web');
    const [mobileActive, setMobileActive] = useState(null)

    const { isLoading, showLoader, dismissLoader } = useLoader();
    const {showSuccessToast, showErrorToast} = Toaster();
  const [actionAccessPatients , setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4))
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setScreenType('mobile');
        } else {
          setScreenType('web');
        }
      };
  
      handleResize(); // Initial check
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    

    const dataTable = {
        "White blood cells": "",
        Hemoglobin: "",
        Hematocrit: "",
        "Platelet count": "",
        Chloride: "",
        Sodium: "",
        Potassium: "",
        CO2: "",
        BUN: "",
        Creatinine: "",
        Glucose: "",
      };
    
      const dataEntries = Object.entries(dataTable);


      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };
      const formatTime = (time) => {
        if (!time) {
          return ""; 
        }

        const [hours, minutes] = time.split(':');
      
        const date = new Date();
        date.setHours(hours, minutes);
      
        const formattedTime = date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      
        return formattedTime;
      };


      const navItems = [
        {
          to: "overview",
          iconSrc: "/assets/imgs/overview.png",
          text: "Overview",
        },
        {
          to: "visits",
          iconSrc: "/assets/imgs/visit.png",
          text: "Visits",
        },
        {
          to: "chiefComplaint",
          iconSrc: "/assets/imgs/cheif-complaint.png",
          text: "Chief Complaint",
        },
        {
          to: "historyOfPresentIllness",
          iconSrc: "/assets/svgs/coronavirus.svg",
          text: "History of Present Illness",
        },
        {
          to: "orders",
          iconSrc: "/assets/svgs/clinical_notes.svg",
          text: "Orders",
        },
        {
          to: "medications",
          iconSrc: "/assets/svgs/clinical_notes.svg",
          text: "Medications",
        },
        {
          to: "vitals",
          iconSrc: "/assets/imgs/heart.png",
          text: "Vitals",
        },
        {
          to: "medicalHistory",
          iconSrc: "/assets/imgs/medical-history.png",
          text: "Medical history",
        },
        {
          to: "surgicalHistory",
          iconSrc: "/assets/imgs/surgeries.png",
          text: "Surgical history",
        },
        {
          to: "familyHistory",
          iconSrc: "/assets/imgs/family-history.png",
          text: "Family history",
        },
        {
          to: "socialHistory",
          iconSrc: "/assets/imgs/social-history.png",
          text: "Social history",
        },
        {
          to: "physicalExam",
          iconSrc: "/assets/imgs/physical-exam.png",
          text: "Physical exam",
        },
        // {
        //   to: "treatmentPlan",
        //   iconSrc: "/assets/imgs/treatment-plan.png",
        //   text: "Treatment plan",
        // },
        {
          to: "doctorAssessment",
          iconSrc: "/assets/imgs/treatment-plan.png",
          text: "Doctor Assessment",
        },
        {
          to: "reviewSystem",
          iconSrc: "/assets/imgs/protection.png",
          text: "Review of systems",
        },
        {
          to: "allergies",
          iconSrc: "/assets/imgs/allergy.png",
          text: "Allergies",
        },
        {
          to: "vaccinations",
          iconSrc: "/assets/imgs/syringe.png",
          text: "Vaccinations",
        },
        {
          to: "investigations",
          iconSrc: "/assets/imgs/investigation.png",
          text: "Investigations",
        },
        {
          to: "labTest",
          iconSrc: "/assets/imgs/biotech.png",
          text: "Lab tests",
        },
        {
          to: "imaging",
          iconSrc: "/assets/imgs/hand_bones.png",
          text: "Imaging",
        },
      ];
    const renderComponent = (sectionId)=> {
      // console.log('sectionID: ', sectionId)
      const tableContainer = document.querySelector(".renderContainer");
      tableContainer.scrollTop = 0;
      setActiveSection(sectionId)
    }
    
    useEffect(() => {
      const currentUrl = window.location.href
      const searchParams = new URLSearchParams(currentUrl.split('?')[1]);
      const id = searchParams.get('id');
      const pageUrl = searchParams.get('page');

      setPatientId(id);
      if(screenType == 'web'){
        setActiveSection(pageUrl)
      }
      fetchpatientdata(id)   
      localStorage.setItem("patientid" , id)
    }, []);
    
    const fetchpatientdata = async (id) => {
      setPatienData(null)
          try {
            const result = await FetchPatientDetails({
              patientid: id,
            });

            if (result != null) {
              setPatienData(result);
            } else {
              setPatienData(null)
            }
        } catch (error) {
            console.error("Error in home:", error);
          }
        };

        // console.log(patientId, "IDDD")
        // console.log(patienData, "DAAAAAAA")


        const formatDateTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
        
          let hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? 'PM' : 'AM';
        
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
        
          const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        
          const formattedTime = hours + ':' + formattedMinutes + ' ' + ampm;
          return formattedTime;
        };


        const formatDateAndTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
          
          const formattedTime = formatDateTime(dateTimeString);
          return ` ${formattedTime}`;
        };

        const backFunction = () => {
          setActiveSection(null); // Ensure the section resets
          navigate('/patientprofile?id=' + patientId);
        };

        const GenericUpload = async (e) => {
          e.preventDefault();
          const file = e.target.files[0];
          const allFiles = e.target.files;
          console.log(allFiles , "allFiles from index")
          const fileCount = allFiles.length || 0;
          if(fileCount > 0){
            
         localStorage.setItem("totalDocumentsSelected", fileCount); 
         localStorage.setItem("remainingDocuments", fileCount); 
        }else{
          localStorage.removeItem("remainingDocuments");
         localStorage.removeItem("totalDocumentsSelected"); 
        }
          
          showLoader();
          navigate("/docupload", { state: { file, allFiles } });
          dismissLoader();
        };


        const navigateBack =()=>{
          let previousPage = localStorage.getItem("previousPage");
          if(previousPage){
            navigate(`/${previousPage}`);
          }else{
            navigate("/patients");
          }
        };

// ----------------------------- Add Vitals Functionality Starts ----------------------------
    const [patientHealthData, setPatientHealthData] = useState(null);    
        
      const [tempUnit, setTempUnit] = useState( "°F"
      );
      const [tempValue, setTempValue] = useState(""
      );

      const [weightUnit, setWeightUnit] = useState( "lbs"
      );
      const [weightValue, setWeightValue] = useState(""
      );
      const [heightUnit, setHeightUnit] = useState("ft"
      );
      const [heightValue, setHeightValue] = useState(""
      );
      const [heightSubValue, setHeightSubValue] = useState( ""
      );

      const [bodyTemp, setBodyTemp] = useState('')
      const [heartRate, setHeartRate] = useState('')
      const [respRate, setRespRate] = useState('')
      const [systolic, setSystolic] = useState('')
      const [distolic, setDistolic] = useState('')
      const [spo2, setSpo2] = useState('')
      const [fastingBloodGlucose, setFastingBloodGlucose] = useState('')
      const [postBloodGlucose, setPostBloodGlucose] = useState('')

      const handleChangeBodyTemperature = (event) => {
        let inputValue = event.target.value;
        // inputValue = inputValue.replace(/[^A-Za-z0-9\s@.]/g, '').slice(0, 50);
        inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setTempValue(event.target.value);
      };
      const handleChangeHeartRate = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setHeartRate(event.target.value);
      };
      const handleChangeRespiratoryRate = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setRespRate(event.target.value);
      };
      const handleChangeSystolic = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setSystolic(event.target.value);
      };
      const handleChangeDistolic = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setDistolic(event.target.value);
      };
      const handleSP02 = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setSpo2(event.target.value);
      };
      const handleFastingBloodGlucose = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setFastingBloodGlucose(event.target.value);
      };
      const handlePostBloodGlucose = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 50);
        event.target.value = inputValue;
        setPostBloodGlucose(event.target.value);
      };

  //   const [inputData, setInputData] = useState({
  //     patientid: patientId,
  //     doctorid: getLoginID(),
  //   });
  
  // const fetchPatientHealthData = async () => {
  //   console.log('inputData :', inputData)
  //   showLoader();
  //   try {
  //     const data = await GetPatientHealthData(inputData);
  //     console.log("pateintHealthData", data);
  //     setPatientHealthData(data);
  //   } catch (error) {
  //     console.error("Error fetching patient health data:", error);
  //   } finally {
  //     dismissLoader();
  //   }
  // };
  /// Call fetchPatientHealthData function whenever inputData or formattedInput changes
    // useEffect(() => {
    //   fetchPatientHealthData();
    // }, []);
    const openVitalsModal = () => {
      setTempValue('')
      setHeartRate('')
      setSpo2('')
      setSystolic('')
      setDistolic('')
      setRespRate('')
      setFastingBloodGlucose('')
      setPostBloodGlucose('')
      setWeightValue('')
      setHeightValue('')
      addVitalData.current.openRight();
    }

    // const {
    //   control,
    //   register,
    //   handleSubmit,
    //   setValue,
    //   reset,
    //   formState: { isDirty, errors },
    // } = useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  
    // const temperatureUnit = useWatch({ control, name: "temperatureUnit" });
  
    // const fetchDoctorNotes = async () => {
    //   const inputToFetch = {
    //     input: {
    //       patientid: patientId,
    //       doctorid: getLoginID(),
    //       // appointmentid: appointmentId,
    //     },
    //   };
    //   showLoader();
    //   try {
    //     const data = await GetDoctorNotes(inputToFetch.input);
    //     const vitals = data?.output?.data?.vitals || [];
    //     const formValues = {};
    //     vitals.forEach((vital) => {
    //       let vitalName = vital.vitalname;
  
    //       // Check and adjust vital names if needed
    //       if (vitalName === "Body temperature") {
    //         vitalName = "Body Temperature";
    //       } else if (vitalName === "Respiratory rate") {
    //         vitalName = "Respiratory Rate";
    //       }
    //       formValues[vitalName] = vital.vitalvalue;
    //     });
    //     const temperatureUnit =
    //       vitals.find(
    //         (vital) =>
    //           vital.vitalname === "Body temperature" ||
    //           vital.vitalname === "Body Temperature"
    //       )?.vitalunit || "°F";
    //     formValues["temperatureUnit"] = temperatureUnit;
  
    //     // Reset form with fetched values
    //     reset(formValues);
  
    //     // console.log("Fetched form values: ", formValues);
    //   } catch (error) {
    //     console.error("Error fetching Doctor Notes data:", error);
    //   } finally {
    //     dismissLoader();
    //   }
    // };
  
    // const onSubmit = async (data) => {
    //   console.log('data; ', data)
    //   const vitals = [];
  
    //   const fields = [
    //     { name: "Systolic Blood Pressure", unit: "mmHg" },
    //     { name: "Diastolic Blood Pressure", unit: "mmHg" },
    //     { name: "Pulse", unit: "bpm" },
    //     { name: "Body Temperature", unit: data.temperatureUnit },
    //     { name: "Respiratory Rate", unit: "bpm" },
    //     { name: "Oxygen Saturation", unit: "%" },
    //     { name: "Fasting Blood Glucose", unit: "mg/dL" },
    //     { name: "Postprandial Blood Glucose", unit: "mg/dL" },
    //   ];
  
    //   fields.forEach((field) => {
    //     const value = data[field.name];
    //     const padZero = (num) => num.toString().padStart(2, "0");
  
    //     const now = new Date();
    //     const formattedDate = `${now.getFullYear()}-${padZero(
    //       now.getMonth() + 1
    //     )}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(
    //       now.getMinutes()
    //     )}:${padZero(now.getSeconds())}`;
  
    //     if (value !== undefined && value !== "") {
    //       vitals.push({
    //         vitalname: field.name,
    //         vitalvalue: value,
    //         vitalunit: field.unit,
    //         vitaltype_cd: "",
    //         // vitaladdeddate: new Date().toISOString(),
    //         vitaladdeddate: formattedDate,
    //       });
    //     }
    //   });
  
    //   const inputToSave = {
    //     input: {
    //       doctorid: getLoginID(),
    //       patientid: patientId,
    //       // appointmentid: appointmentId,
    //       vitals: vitals,
    //     },
    //   };
    //   console.log(inputToSave);
    //   showLoader();
    //   try {
    //     // const result = await updateDoctorNotes(inputToSave.input);
    //     // if (result) {
    //     //   showSuccessToast("Vitals saved successfully");
    //     //   fetchDoctorNotesEmptyIndication();
    //     //   setIsButtonDisabled(true); // Disable button after successful submission
    //     // }
    //     if (inputToSave.input.vitals && inputToSave.input.vitals.length > 0) {
    //       const result = await AddVitalData(inputToSave.input);
    //       if (result) {
    //         showSuccessToast("Vitals saved successfully");
    //         // fetchDoctorNotesEmptyIndication();
    //         setIsButtonDisabled(true); // Disable button after successful submission
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error in saving vitals:", error);
    //     showErrorToast("Error happened while saving vitals.");
    //   } finally {
    //     dismissLoader();
    //   }
    // };
  
    // useEffect(() => {
    //   fetchDoctorNotes();
    // }, []);
  
    // useEffect(() => {
    //   setIsButtonDisabled(!isDirty);
    // }, [isDirty]);
  
    //BMI
  
    // const [weightUnit, setWeightUnit] = useState(
    //   currentWeightUnit ? currentWeightUnit : "lbs"
    // );
    // const [weightValue, setWeightValue] = useState(
    //   currentWeightValue ? currentWeightValue : ""
    // );
    // const [heightUnit, setHeightUnit] = useState(
    //   currentHeightUnit ? currentHeightUnit : "ft"
    // );
    // const [heightValue, setHeightValue] = useState(
    //   currentHeightValue ? currentHeightValue : ""
    // );
    // const [heightSubValue, setHeightSubValue] = useState(
    //   currentHeightSubValue ? currentHeightSubValue : ""
    // );
  
    const handleTempUnitChange = (newUnit) => {
      if (newUnit === "°F") {
        if (tempValue) {
          if (tempUnit === "°F")
            setTempValue((prev) => (prev * 2.20462).toFixed(2));
        }
        
        setTempUnit("°F");
      } else if (newUnit === "°C") {
        if (tempValue) {
          if (tempUnit === "°C") {
            setTempValue((prev) => (prev / 2.20462).toFixed(2));
          }
        }
        
        setTempUnit("°C");
      }
    };

    const handleUnitChange = (newUnit) => {
      if (newUnit === "Standard") {
        if (weightValue) {
          if (weightUnit === "kg")
            setWeightValue((prev) => (prev * 2.20462).toFixed(2));
        }
        if (heightValue) {
          if (heightUnit === "cm") {
            setHeightValue(Math.floor(heightValue / 30.48));
            setHeightSubValue(Math.round((heightValue % 30.48) / 2.54));
          }
        }
        setWeightUnit("lbs");
        setHeightUnit("ft");
      } else if (newUnit === "Metric") {
        if (weightValue) {
          if (weightUnit === "lbs") {
            setWeightValue((prev) => (prev / 2.20462).toFixed(2));
          }
        }
        if (heightValue) {
          if (heightUnit === "ft") {
            const totalInches = heightValue * 12 + Number(heightSubValue);
            setHeightValue(Math.floor(totalInches * 2.54));
            setHeightSubValue(Math.round(((totalInches * 2.54) % 1) * 10));
          }
        }
        setWeightUnit("kg");
        setHeightUnit("cm");
      }
    };
  
    // const [date, setDate] = useState(moment().format("DD-MM-yyyy"));
    const [date, setDate] = useState(moment().format("yyyy-MM-DD"));
    const [time, setTime] = useState(moment().format("HH:mm"));
  
    const SubmitBMIform = async () => {
  
      const inputToSave = {
        input: {
          doctorid: getLoginID(),
          patientid: patientId,
          // appointmentid: appointmentId,
          // vitals: vitals,
          "vitals": [
            {
              vitalname: "Weight",
              vitalvalue: weightValue,
              vitalunit: weightUnit,
              vitaltype_cd: "",
              vitaladdeddate: `${date} ${time}`,
            },
            {
              vitalname: "Height",
              // vitalvalue: heightValue + "." + heightSubValue,
              vitalvalue: "124.2",
              // vitalunit: heightUnit,
              vitalunit: 'cm',
              vitaltype_cd: "238",
              vitaladdeddate: `${date} ${time}`,
            },
    {
      "vitalname": "Systolic Blood Pressure",
      "vitalvalue": systolic,
      "vitalunit": "mmHg",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Diastolic Blood Pressure",
      "vitalvalue": distolic,
      "vitalunit": "mmHg",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Pulse",
      "vitalvalue": heartRate,
      "vitalunit": "bpm",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Body Temperature",
      "vitalvalue": tempValue,
      "vitalunit": tempUnit,
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Respiratory Rate",
      "vitalvalue": respRate,
      "vitalunit": "bpm",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Oxygen Saturation",
      "vitalvalue": spo2,
      "vitalunit": "%",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Fasting Blood Glucose",
      "vitalvalue": fastingBloodGlucose,
      "vitalunit": "mg/dL",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    },
    {
      "vitalname": "Postprandial Blood Glucose",
      "vitalvalue": postBloodGlucose,
      "vitalunit": "mg/dL",
      "vitaltype_cd": "",
      // "vitaladdeddate": "2025-01-17 16:53:53"
      vitaladdeddate: `${date} ${time}`
    }
  ].filter((vital) => vital.vitalvalue !== ""), 
        },
      };
      // console.log(inputToSave);

      // let Weightinput = {
      //   patientid: patientId,
      //   // appointment_id: appointmentId,
      //   vitals: [
      //     {
      //       vitalname: "Weight",
      //       vitalvalue: weightValue,
      //       vitalunit: weightUnit,
      //       vitaltype_cd: "",
      //       vitaladdeddate: `${date} ${time}`,
      //     },
      //     {
      //       vitalname: "Height",
      //       vitalvalue: heightValue + "." + heightSubValue,
      //       vitalunit: heightUnit,
      //       vitaltype_cd: "238",
      //       vitaladdeddate: `${date} ${time}`,
      //     },
      //   ],
      // };
      // let Heightinput = {
      //   patientid: patientId,
      //   // appointment_id: appointmentId,
      //   vitals: [
      //     {
      //       vitalname: "Height",
      //       vitalvalue: heightValue + "." + heightSubValue,
      //       vitalunit: heightUnit,
      //       vitaltype_cd: "238",
      //       vitaladdeddate: `${date} ${time}`,
      //     },
      //   ],
      // };
      if (moment(date, "DD-MM-YYYY", true).isValid()) {
        const formatteddate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
        const vitaldate = formatteddate + " " + time;
        // Weightinput.vitals[0].vitaladdeddate = vitaldate;
        // Heightinput.vitals[0].vitaladdeddate = vitaldate;
      }
      showLoader();
      try {
        // showLoader();
        // if (weightValue) {
        //   console.log("updating weight");
        //   const response = await AddVitalData(Weightinput);
        // }
        // if (heightValue) {
        //   console.log("updating height");
        //   const result = await AddVitalData(Heightinput);
        // }
        // if (weightValue || heightValue) {
        //   // This block will execute if either var1 or var2 is truthy
        //   console.log("Refreshing patient data");
        //   // fetchPatientHealthDataOfStartVisit();
        // }

        if (inputToSave.input.vitals && inputToSave.input.vitals.length > 0) {
          const result = await AddVitalData(inputToSave.input);
          if (result && result.output.message === "Successfully Inserted/Updated") {
            addVitalData.current.closeRight();
            // fetchPatientHealthData()
            setActiveSection("vitals")
            //fetchpatientdata(patientId)
            showSuccessToast("Vitals saved successfully");
            // fetchDoctorNotesEmptyIndication();
            setIsButtonDisabled(true); // Disable button after successful submission
          }
          dismissLoader();
        }
        dismissLoader();
  
        // if (response.output.success == 1) {
        //   if (result.output.success == 1) {
        //     // dismissLoader();
        //   }
        // } else {
        //   showErrorToast("Error happened while saving demographics.");
        // }
      } catch (error) {
        console.error("Error in SubmitFunction:", error);
      }
    };
// ----------------------------- Add Vitals Functionality Ends ----------------------------

  return (
    <>
    {screenType == 'web' ? (

    
      <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          <Box w="100%">
            <SecondaryNav customHeader={<>
                <Box as='button' display="flex" alignItems="center" gap="1rem">
                    <Image src="/assets/imgs/back-arrow.png"
                    onClick={navigateBack}
                    />
                    <Text fontSize="1.25rem" fontWeight="600">Patient profile</Text>
                </Box>
            </>} />
            {/* Main container start */}
            <Flex w="calc(100vw - 6rem)">
              <Box
                // p="1.5rem 8.25rem"
                display="flex"
                // h="calc(100vh - 50px)"
                // overflow="hidden auto"
                w="100%"
              >
                 
                {/* Left area start */}
                <Box w={{lg: "13rem", xl: "16rem"}} h="calc(100vh - 75px)" overflow="hidden auto" borderRight="1px solid #E6E7E9">
                <VisibleSidebar navItems={navItems}  activeSection={activeSection} onNavItemClick={renderComponent} isLogout={false} visibleSidebarStyle={{minW: "unset", w: "100%"}} />
                </Box>
                {/* Left area end */}

                {/* Main container start */}
                <Box w={{lg: "calc(100vw - 28rem)", xl: "calc(100vw - 36rem)"}} bg="#ffffff" overflow="hidden">
                <Box flexDir="column" className='renderContainer' p={activeSection == 'orders' || activeSection == 'vaccinations' || activeSection == "socialHistory" || activeSection == 'allergies' || activeSection == "labTest" || activeSection == "medicalHistory" || activeSection == "surgicalHistory" || activeSection == "medications" || activeSection == "familyHistory" ? '0' : "1.5rem"} bg="#fcfcfd" h={activeSection == 'vaccinations' || activeSection == 'allergies' || activeSection == "labTest" || activeSection == "socialHistory" || activeSection == "medicalHistory" || activeSection == "surgicalHistory" || activeSection == "medications" || activeSection == "familyHistory" ? '' : "calc(100vh - 75px)"} w="100%" overflow="hidden auto">
                    <Flex>

                    {activeSection == "overview" && (
                        <OverviewIndex pid={patientId} />
                      )}
                        {activeSection == "visits" && (
                          <PatientVisitsIndex id={patientId} />
                        )}

                        {activeSection == "chiefComplaint" && (
                          <ChiefComplaint id={patientId} />
                        )}

                        {activeSection == "historyOfPresentIllness" && (
                          <HistoryOfPresentIllness id={patientId} />
                        )}

                        {activeSection == "orders" && (
                          <OrdersIndex id={patientId} />
                        )}

                        {activeSection == "medications" && (
                          <MedicationIndex id={patientId} />
                        )}

                        {activeSection == "vitals" && (
                          <PatientVitalsIndex id={patientId} />
                        )}

                        {activeSection == "medicalHistory" && (
                          <MedicalHistory id={patientId} />
                        )}

                        {activeSection == "surgicalHistory" && (
                          <SurgicalHistory id={patientId} />
                        )}

                        {activeSection == "familyHistory" && (
                          <FamilyHistory id={patientId} />
                        )}

                        {activeSection == "socialHistory" && (
                          <SocialHistory id={patientId} />
                        )}
                        
                        {activeSection == "physicalExam" && (
                          <PhysicalExam id={patientId} />
                        )}

                        {/* {activeSection == "treatmentPlan" && (
                          <TreatmentPlan id={patientId} />
                        )} */}
                        {activeSection == "doctorAssessment" && (
                          <DoctorAssessment id={patientId} />
                        )}

                        {activeSection == "reviewSystem" && (
                          <ReviewSystem id={patientId} />
                        )}
                        {activeSection == "allergies" && (
                          <Allergies id={patientId} />
                        )}
                        {activeSection == "vaccinations" && (
                          <Vaccinations id={patientId} />
                        )}
                        {activeSection == "investigations" && (
                          <Investigations id={patientId} />
                        )}
                        {activeSection == "labTest" && (
                          <LabTests id={patientId} />
                        )}
                        {activeSection == "imaging" && (
                          <Imaging id={patientId} />
                        )}

                    </Flex>
                </Box>
                </Box>
                {/* Main container end */}

                {/* Right area start */}
                    {/* Recent Activity start */}


                    <Box w={{lg: "15rem", xl: "20rem"}} borderLeft="1px solid #E6E7E9">
                    {
                        patienData && patienData.patientinfo && (

                        <VStack p="1.5rem" align="stretch" gap="1rem" overflow="hidden auto" bg="#fcfcfd" h="100vh">
                            <Box>
                                <Box display="flex" flexDir="column" gap="0.65rem">
                                    <Box display='flex' alignItems='center' gap='1rem'>
                                      <Box w="5rem" h="5rem">
                                        <Image 
                                        w="100%" 
                                        h="100%"
                                        borderRadius="full"
                                        // src={
                                        //     patienData.patientinfo.patientimage? 
                                        //     (`${patienData.patientinfo.imagepath}${patienData.patientinfo.patientimage}`) : ("/assets/imgs/no-image.png")
                                        // }
                                        src={patienData.patientinfo.patientimage ? 
                                          (patienData.patientinfo.patientimage.startsWith('http') ? patienData.patientinfo.patientimage : patienData.patientinfo.imagepath + patienData.patientinfo.patientimage) 
                                          : '/assets/imgs/no-image.png'}
                                            
                                        />
                                      </Box>
                                    
                                    <Flex flexDir='column' gap='3px'>
                                      <Box display="flex" alignItems="center">
                                        <Image src="/assets/svgs/gender.svg" />
                                        <Text fontSize="0.875rem" fontWeight="500" ml="10px">
                                            {/* Male */}
                                            {patienData.patientinfo.gender ? patienData.patientinfo.gender : ('-')}
                                            </Text>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                        <Image ml='4px' src="/assets/svgs/height.svg" />
                                        <Text fontSize="0.875rem" fontWeight="500" ml="1rem">
                                            {/* 6ft 3in */}
                                            {patienData.patientinfo.height? (patienData.patientinfo.height+ " " + patienData.patientinfo.heightunit): ('0') }
                                            </Text>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                        <Image src="/assets/svgs/weight.svg" />
                                        <Text fontSize="0.875rem" fontWeight="500" ml="10px">
                                            {/* 75 kg */}
                                            {/* {patienData.patientinfo.weight + " " + patienData.patientinfo.weightunit } */}
                                            {patienData.patientinfo.weight? (patienData.patientinfo.weight+ " " + patienData.patientinfo.weightunit): ('0') }
                                            </Text>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                          <Image ml='2px' src="/assets/svgs/phone-number.svg" />
                                          <Text fontSize="0.875rem" fontWeight="500" ml="12px">
                                              {/* +1 (647) 523-9797 */}
                                              {/* {`${patienData.patientinfo.countrycode} ${patienData.patientinfo.mobileno}`} */}
                                              {patienData &&
                                                patienData.patientinfo &&
                                                patienData.patientinfo.mobileno
                                                  ? (`${patienData.patientinfo.countrycode} ${"  "}${patienData.patientinfo.mobileno}`)
                                                  : "-"}
                                          </Text>
                                        </Box>
                                    </Flex>

                                    </Box>
                                    <Box display="flex" flexDir="column">
                                        <Text fontSize="1rem" fontWeight="600">
                                            {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                                            </Text>
                                        <Box display="flex" gap="0.5rem">
                                            <Image src="/assets/svgs/calendar-filled.svg" />
                                            <Text fontSize="0.75rem" fontWeight="400" color="#384052">
                                              {/* Upcoming: 10th Jan, 2024 at 9:30 AM */}

                                              {patienData.patientinfo.nextappointmentdate && patienData.patientinfo.nextappointmenttime && patienData.patientinfo.nextappointmentstatus ? (
                                              `${patienData.patientinfo.nextappointmentstatus}: ${formatDate(patienData.patientinfo.nextappointmentdate)} at ${formatTime(patienData.patientinfo.nextappointmenttime)}`
                                              ) : (
                                                "-"
                                              )}
                                              {/* {patienData.patientinfo.nextappointmentstatus !== 'Cancelled' ? (patienData.patientinfo.nextappointmentdate && patienData.patientinfo.nextappointmenttime && (
                                              `Upcoming: ${formatDate(patienData.patientinfo.nextappointmentdate)} at ${formatTime(patienData.patientinfo.nextappointmenttime)}`
                                              )) : (
                                                "-"
                                              )} */}

                                              {/* {`${formatDate(patienData.patientinfo.lastupdatedate)} at ${formatDateAndTime(patienData.patientinfo.lastupdatedate)}`} */}
                                              </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>   
                            <Divider />
                            <Stack direction="column" gap="0.75rem" w="100%">
                                {/* <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/name.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                                </Text>
                                </Box> */}

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/email.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* adnan@supastellar.co */}
                                    {patienData.patientinfo.email}
                                </Text>
                                </Box>

                               

                                
                                
                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/cake.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {/* 29 August, 1997 */}
                                    {/* {patienData.patientinfo.dob} */}
                                    {patienData &&
                              patienData.patientinfo &&
                              patienData.patientinfo.dob
                                ? (`${patienData.patientinfo.dob} ${calculateAgeCommonFunc(patienData.patientinfo.dobDbFormat)}`)
                                : "-"} 
                                    </Text>
                                </Box>

                                <Box display="flex" alignItems="center">
                                <Image src="/assets/svgs/name.svg" />
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                    {`${patienData.patientinfo.uniqueid}`}
                                </Text>
                                </Box>

                                <Box display="flex" alignItems="center" pr="20px" mt="15px"
               opacity={actionAccessPatients?.create ? 1 : 0.5}>

              <input
                type="file"
                id="genericupload"
                style={{ display: 'none' }}
                accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf" // Specify accepted file types
                onChange={GenericUpload}
                multiple
              />
              <PrimaryInput
               inputType="file"
               uploadText="Upload document"
               id="genericupload"
               docBtnWidth="195px"
               docBtnColor="#1C4EC5"
               iconImg="/assets/svgs/prescriptions.svg"
               iconImgCss={{width:"30px" , height:"auto" , color:"#1C4EC5" , 
                filter: "brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2247%) hue-rotate(215deg) brightness(83%) contrast(96%)"
                }}
               onChange={GenericUpload}
               variant="grayBtnFull"
               disableFileInput={actionAccessPatients?.create ? false : true}
             />
             
             </Box>

             <Box>
             <PrimaryButton
                buttonText="Add Vitals"
                // onClick={() => addVitalData.current.openRight()}
                onClick={openVitalsModal}
                variant="grayBtn"
                btnStyle={{ w: "80%" }}
                w='100%'
              />
              {/* <PrimaryButton
                    fontSize="14px"
                    variant="mdBtn"
                    buttonText="Add data"
                    btnIcon="/assets/imgs/add.png"
                    onClick={() => adddata.current.openRight()}
                  /> */}

             </Box>

                            </Stack>
                        </VStack>
                        ) 
                        // : ("No records found")
                    }
                    </Box>

                    {/* Recent Activity end */}
                {/* Right area end */}
              </Box>
            </Flex>
            {/* Main container end */}
          </Box>
        </Flex>
      </Box>
    ) : (
<>
    
      {/* Mobile responsive */}
      <Box w="100%">
        {activeSection == null && (
        <>
        <MobileSecondaryNav
          header="Patient profile"
          handlebackarrow={()=> navigate('/messages')}
        />
        <Box w="100%" h="calc(100vh - 120px)" overflow="hidden auto">
          {/* Patient details start */}
          <Box w="100%" borderLeft="1px solid #E6E7E9">
          {
              patienData && patienData.patientinfo && (

              <VStack p="1.5rem" align="stretch" gap="1rem"bg="white">
                  <Box>
                      <Box display="flex" flexDir="column" gap="0.65rem">
                          <Box w="5rem" h="5rem">
                              <Image 
                              w="100%" 
                              h="100%"
                              borderRadius="full"
                              src={
                                  patienData.patientinfo.patientimage? 
                                  (`${patienData.patientinfo.imagepath}${patienData.patientinfo.patientimage}`) : ("/assets/imgs/no-image.png")
                              }
                                  
                              />
                              </Box>
                          <Box display="flex" flexDir="column">
                              <Text fontSize="1rem" fontWeight="600">
                                  {/* Dr. Samantha Raulf */}
                                  {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                                  </Text>
                              <Box display="flex" gap="0.5rem">
                                  <Image src="/assets/svgs/calendar-filled.svg" />
                                  <Text fontSize="0.75rem" fontWeight="400" color="#384052">
                                    {/* Upcoming: 10th Jan, 2024 at 9:30 AM */}

                                    {patienData.patientinfo.nextappointmentdate && patienData.patientinfo.nextappointmenttime ? (
                                    `Upcoming: ${formatDate(patienData.patientinfo.nextappointmentdate)} at ${formatTime(patienData.patientinfo.nextappointmenttime)}`
                                    ) : (
                                      "-"
                                    )}

                                    {/* {`${formatDate(patienData.patientinfo.lastupdatedate)} at ${formatDateAndTime(patienData.patientinfo.lastupdatedate)}`} */}
                                    </Text>
                              </Box>
                          </Box>
                      </Box>
                  </Box>   
                  <Divider />
                  <Stack direction="column" gap="0.75rem" w="100%">
                      <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/name.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {/* Adnan Jaweed */}
                          {`${patienData.patientinfo.firstname}  ${patienData.patientinfo.lastname}`}
                      </Text>
                      </Box>

                      <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/email.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {/* adnan@supastellar.co */}
                          {patienData.patientinfo.email}
                      </Text>
                      </Box>

                      <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/phone-number.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                          {/* +1 (647) 523-9797 */}
                          {`${patienData.patientinfo.countrycode} ${patienData.patientinfo.mobileno}`}
                      </Text>
                      </Box>

                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box display="flex" w="50%">
                          <Image src="/assets/svgs/gender.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                            {patienData.patientinfo.gender}
                          </Text>
                        </Box>

                        <Box display="flex" w="50%">
                          <Image src="/assets/svgs/height.svg" />
                          <Text fontSize="0.875rem" fontWeight="500" ml="1rem">
                            {patienData.patientinfo.height? (patienData.patientinfo.height+ " " + patienData.patientinfo.heightunit): ('0') }
                          </Text>
                        </Box>
                      </Box>

                      <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                        <Box display="flex" w="50%">
                            <Image src="/assets/svgs/weight.svg" />
                            <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                              {patienData.patientinfo.weight? (patienData.patientinfo.weight+ " " + patienData.patientinfo.weightunit): ('0') }
                            </Text>
                        </Box>
                        
                        <Box display="flex" w="50%">
                            <Image src="/assets/svgs/cake.svg" />
                            <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                            {patienData.patientinfo.dob}
                            </Text>
                        </Box>
                        
                      </Box>

                  </Stack>
              </VStack>
              ) 
              // : ("No records found")
          }
          </Box>
          {/* Patient details end */}
          <Divider />
          {/* Menu section start */}
          <Box p="1.5rem" bg="white">
            <Box w="100%">
              <SimpleGrid columns={{base: 4, sm: 6}} spacing="1rem">
                {navItems.map((item, index)=> (
                  <Box key={index} zIndex="3" display="flex" justifyContent="center" alignItems="center" textAlign='center' flexDir='column' gap="0.5rem">
                  <Box as="button" boxSize="4rem"  onClick={()=> setActiveSection(item.to)} borderRadius="8px" bg="#F9FAFB" border="1px solid #0B1F4F4D" display="flex" justifyContent="center" alignItems="center">
                    <Image src={item.iconSrc} alt={item.text} />
                  </Box>
                  <Text fontSize="0.75rem" letterSpacing="-0.05rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" wordBreak="" w="-webkit-fill-available">{item.text}</Text>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          </Box>
          {/* Menu section end */}
          
          <Divider />

          {/* <Box w="100%">
            <Flex w="100%" flexDir='column'>
            {navItems.map((item, index)=> (
              <Box key={index} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image src={item.iconSrc} />
                <Text>{item.text}</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              ))}
            </Flex>
          </Box> */}


        </Box>
        </>
        )}
          <Flex>
            {activeSection && activeSection == "overview" && (
                <OverviewIndex pid={patientId} backFunction={backFunction} />
              )}
                {activeSection == "visits" && (
                  <PatientVisitsIndex id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "chiefComplaint" && (
                  <ChiefComplaint id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "historyOfPresentIllness" && (
                  <HistoryOfPresentIllness id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "orders" && (
                  <OrdersIndex id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "vitals" && (
                  <PatientVitalsIndex id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "medicalHistory" && (
                  <MedicalHistory id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "surgicalHistory" && (
                  <SurgicalHistory id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "familyHistory" && (
                  <FamilyHistory id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "socialHistory" && (
                  <SocialHistory id={patientId} backFunction={backFunction} />
                )}
                
                {activeSection == "physicalExam" && (
                  <PhysicalExam id={patientId} backFunction={backFunction} />
                )}

                {/* {activeSection == "treatmentPlan" && (
                  <TreatmentPlan id={patientId} backFunction={backFunction} />
                )} */}
                {activeSection == "doctorAssessment" && (
                  <DoctorAssessment id={patientId} backFunction={backFunction} />
                )}

                {activeSection == "reviewSystem" && (
                  <ReviewSystem id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "allergies" && (
                  <Allergies id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "vaccinations" && (
                  <Vaccinations id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "investigations" && (
                  <Investigations id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "labTest" && (
                  <LabTests id={patientId} backFunction={backFunction} />
                )}
                {activeSection == "imaging" && (
                  <Imaging id={patientId} backFunction={backFunction} />
                )}

            </Flex>
      </Box>
      </>
    )}

<ModalRight
        ref={addVitalData}
        header="Add Data"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="690px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
            {/* <DoctorVitalsFormData
                              patientId={patientId}
                              doctorId={getLoginID()}
                              // appointmentId={appointmentId}
                              // showSuccessToast={showSuccessToast}
                              // showErrorToast={showErrorToast}
                              // fetchDoctorNotesEmptyIndication={
                              //   fetchDoctorNotesEmptyIndication
                              // }
                              fetchPatientHealthDataOfStartVisit={fetchPatientHealthData}
                            />               */}

{/* <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}> */}
          <VStack spacing={4} align="stretch" height="100%">
            {/* Row 1 */}
            {/* <FormControl isInvalid={errors["Body Temperature"]}> */}
              {/* <Flex height="60px" align="center">
                <Flex direction="column" width={{ base: "50%", md: "100%" }}>

                  <FormLabel mb="0px">Body Temperature</FormLabel>
                  <Controller
                    name="temperatureUnit"
                    control={control}
                    defaultValue="°F"
                    render={({ field }) => (
                      <Select
                        {...field}
                        height="50%"
                        width={{ base: "5%", md: "45%" }}
                        fontSize={"xs"}
                        size={"xs"}
                        border={"none"}
                        // style={{ textAlignLast: "left", ml: "0px" }}
                        style={{
                          textAlignLast: "left",
                          marginLeft: "0px",
                          minWidth: "60px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // backgroundColor: "red",
                        }}
                      >
                        <option value="°F" style={{ textAlignLast: "left" }}>
                          °F
                        </option>
                        <option value="°C">°C</option>
                      </Select>
                    )}
                  />
                </Flex>
                <Box width={{ base: "50%", md: "100%" }} textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Body Temperature", {
                      maxLength: {
                        value: 6,
                        message: "Maximum 6 characters allowed",
                      },
                      pattern: {
                        value: /^(\d{1,3}(\.\d{0,2})?)$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const temp = parseFloat(value);
                          if (temperatureUnit === "°F") {
                            return (
                              (temp >= 77 && temp <= 108) ||
                              "Invalid input: Temperature should be between 77°F and 108°F"
                            );
                          } else {
                            return (
                              (temp >= 24 && temp <= 42) ||
                              "Invalid input: Temperature should be between 24°C and 42°C"
                            );
                          }
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    p="0 10px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />

                  <PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handleChangeBodyTemperature}
                                  inputValue={bodyTemp}
                                />
                  <FormErrorMessage>
                    {errors["Body Temperature"] &&
                      errors["Body Temperature"].message}
                  </FormErrorMessage>
                </Box>
              </Flex> */}

<Flex direction="row" align="center" width="100%" mb={4}>
                <Flex direction="column" width="100%">
                  <FormLabel mb="0" width={{ base: "20%", md: "100%" }}>
                    Body Temperature ({tempUnit})
                  </FormLabel>
                  <Flex direction="column" width="100%">
                    <Flex align="center" mb={1} gap={2}>
                      {/* <Box fontSize="xs" fontWeight="bold">
                        {tempUnit}
                      </Box> */}
                      <Select
                        // value={weightUnit === "lbs" ? "Standard" : "Metric"}
                        value={tempUnit === "°F" ? "°F" : "°C"}
                        onChange={(e) => handleTempUnitChange(e.target.value)}
                        fontSize={"xs"}
                        size={"xs"}
                        width={{ base: "80px", md: "100px" }}
                        border="none"
                        textAlignLast="left"
                      >
                        <option value="°F">°F</option>
                        <option value="°C">°C</option>
                      </Select>
                    </Flex>
                  </Flex>
                </Flex>
                <Box w="60%" textAlign="right">
                  {/* <Input
                    type="number"
                    value={tempValue}
                    placeholder={tempUnit}
                    onChange={(e) => setTempValue(e.target.value)}
                    // height="40px"
                    // p="0 10px"
                    // width={{ base: "100px", md: "150px" }}
                    height="48px"
                    width={"200px"}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                  /> */}
                  <PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handleChangeBodyTemperature}
                                  inputValue={tempValue}
                                  maxLength={3}
                                />
                </Box>
              </Flex>
            {/* </FormControl> */}
            <Divider />

            {/* Row 2 */}
            {/* <FormControl isInvalid={errors["Pulse"]}> */}
              <Flex height="60px" align="center">
                <Flex direction="column" width={{ base: "50%", md: "100%" }}>
                  <FormLabel width={{ base: "100%", md: "30%" }} mb="0px">
                    Heart Rate
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    beats per minute
                  </Box>
                </Flex>
                <Box width={{ base: "50%", md: "100%" }} textAlign="right">
                  {/* <Input
                    type="number"
                    {...register("Pulse", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const pulse = parseInt(value);
                          return (
                            (pulse >= 20 && pulse <= 300) ||
                            "Invalid input: Heart rate should be between 20 and 300 bpm"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                    p="0 10px"
                  /> */}

                  <PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handleChangeHeartRate}
                                  inputValue={heartRate}
                                  maxLength={3}
                                />
                  {/* <FormErrorMessage>
                    {errors["Pulse"] && errors["Pulse"].message}
                  </FormErrorMessage> */}
                </Box>
              </Flex>
            {/* </FormControl> */}
            <Divider />

            {/* Row 3 */}
            {/* <FormControl isInvalid={errors["Respiratory Rate"]}> */}
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width={{ base: "100%", md: "40%" }} mb="0px">
                    Respiratory Rate
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    breaths per minute
                  </Box>
                </Flex>
                <Box width="100%" textAlign="right">
                  {/* <Input
                    type="number"
                    {...register("Respiratory Rate", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9][0-9]{0,2}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const rate = parseInt(value);
                          return (
                            (rate >= 5 && rate <= 60) ||
                            "Invalid input: Respiratory rate should be between 5 and 60 breaths per minute"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  /> */}

<PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handleChangeRespiratoryRate}
                                  inputValue={respRate}
                                  maxLength={3}
                                />
                  {/* <FormErrorMessage>
                    {errors["Respiratory Rate"] &&
                      errors["Respiratory Rate"].message}
                  </FormErrorMessage> */}
                </Box>
              </Flex>
            {/* </FormControl> */}

            <Divider />

              <Flex height="60px" align="center">
                {/* Single Label for both Systolic and Diastolic */}
                <Flex direction="column" width="100%">
                  <FormLabel mb="0px">Blood Pressure</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>

                {/* Systolic and Diastolic Input Fields Aligned Right */}
                <Box width="100%" textAlign="right">
                  <Flex justify="flex-end" align="center">
                    {/* Systolic Input */}
                    {/* <Input
                      type="number"
                      {...register("Systolic Blood Pressure", {
                        maxLength: {
                          value: 3,
                          message: "Maximum 3 digits allowed",
                        },
                        pattern: {
                          value: /^[0-9]{1,3}$/,
                          message: "Invalid input",
                        },
                        validate: (value) => {
                          if (value) {
                            const systolic = parseInt(value);
                            return (
                              (systolic >= 60 && systolic <= 300) ||
                              "Invalid input: Systolic BP should be between 60 and 300 mmHg"
                            );
                          }
                        },
                      })}
                      onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                      placeholder="Systolic"
                      height="48px"
                      width={{ base: "90px", md: "90px" }}
                      bg={"white"}
                      textAlign={"center"}
                      p="0 10px"
                      onChange={() => setIsButtonDisabled(false)}
                    /> */}

<PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Systolic"
                                  id="yourName"
                                  onChange={handleChangeSystolic}
                                  inputValue={systolic}
                                  maxLength={3}
                                />

                    {/* Separator */}
                    <Box mx={2}>/</Box>

                    {/* Diastolic Input */}
                    {/* <Input
                      type="number"
                      {...register("Diastolic Blood Pressure", {
                        maxLength: {
                          value: 3,
                          message: "Maximum 3 digits allowed",
                        },
                        pattern: {
                          value: /^[0-9]{1,3}$/,
                          message: "Invalid input",
                        },
                        validate: (value) => {
                          if (value) {
                            const diastolic = parseInt(value);
                            return (
                              (diastolic >= 30 && diastolic <= 200) ||
                              "Invalid input: Diastolic BP should be between 30 and 200 mmHg"
                            );
                          }
                        },
                      })}
                      onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                      placeholder="Diastolic"
                      height="48px"
                      width={{ base: "90px", md: "90px" }}
                      bg={"white"}
                      textAlign={"center"}
                      p="0 10px"
                      onChange={() => setIsButtonDisabled(false)}
                    /> */}
  <PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Distolic"
                                  id="yourName"
                                  onChange={handleChangeDistolic}
                                  inputValue={distolic}
                                  maxLength={3}
                                />

                  </Flex>

                  {/* Error Messages */}
                  {/* <FormErrorMessage>
                    {errors["Systolic Blood Pressure"]?.message ||
                      errors["Diastolic Blood Pressure"]?.message}
                  </FormErrorMessage> */}
                </Box>
              </Flex>
            {/* </FormControl> */}

            <Divider />

            {/* Row 6 */}
            {/* <FormControl isInvalid={errors["Oxygen Saturation"]}> */}
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="30%" mb="0px">
                    SPO2
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    %
                  </Box>
                </Flex>
                <Box width="100%" textAlign="right">
                  {/* <Input
                    type="number"
                    step="0.01"
                    {...register("Oxygen Saturation", {
                      maxLength: {
                        value: 6, // Adjusted to accommodate decimal places
                        message: "Enter value between 0 to 100",
                      },
                      pattern: {
                        value: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
                        message: "Enter value between 0 to 100",
                      },
                      validate: (value) => {
                        if (value) {
                          const o2 = parseInt(value);
                          return (
                            (o2 >= 50 && o2 <= 100) ||
                            "Invalid input: SPO2 should be between 50 and 100 %"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  /> */}

<PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handleSP02}
                                  inputValue={spo2}
                                  maxLength={3}
                                />
                  {/* <FormErrorMessage mb={"1.5rem"}>
                    {errors["Oxygen Saturation"] &&
                      errors["Oxygen Saturation"].message}
                  </FormErrorMessage> */}
                </Box>
              </Flex>
            {/* </FormControl> */}

            <Divider />

            {/* Row 7 */}
            {/* <FormControl isInvalid={errors["Fasting Blood Glucose"]}> */}
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="60%" mb="0px">
                    Fasting Blood Glucose
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mg/dL
                  </Box>
                </Flex>
                <Box width="100%" textAlign="right">
                  {/* <Input
                    type="number"
                    step="0.01"
                    {...register("Fasting Blood Glucose", {
                      // maxLength: {
                      //   value: 6, // Adjusted to accommodate decimal places
                      //   message: "Enter value between 20 to 500",
                      // },
                      // pattern: {
                      //   value: /^(20(\.\d{1,2})?|[2-4]\d{1,2}(\.\d{1,2})?|500(\.0{1,2})?)$/,
                      //   message: "Enter value between 20 and 500 (up to 2 decimal places)",
                      // },
                      validate: (value) => {
                        if (value) {
                          const fbg = parseInt(value);
                          return (
                            (fbg >= 20 && fbg <= 500) ||
                            "Invalid input: Blood Glucose must be between 20 and 500 mg/dL"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  /> */}

<PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handleFastingBloodGlucose}
                                  inputValue={fastingBloodGlucose}
                                  maxLength={3}
                                />
                  {/* <FormErrorMessage mb={"1.5rem"}>
                    {errors["Fasting Blood Glucose"] &&
                      errors["Fasting Blood Glucose"].message}
                  </FormErrorMessage> */}
                </Box>
              </Flex>
            {/* </FormControl> */}
            {/* Row 8 */}
            {/* <FormControl isInvalid={errors["Postprandial Blood Glucose"]}> */}
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="60%" mb="0px">
                    Postprandial Blood Glucose
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mg/dL
                  </Box>
                </Flex>
                <Box width="100%" textAlign="right">
                  {/* <Input
                    type="number"
                    step="0.01"
                    {...register("Postprandial Blood Glucose", {
                      // maxLength: {
                      //   value: 6, // Adjusted to accommodate decimal places
                      //   message: "Enter value between 20 to 500",
                      // },
                      // pattern: {
                      //   value:
                      //     /^(20(\.\d{1,2})?|[2-4]\d{1,2}(\.\d{1,2})?|500(\.0{1,2})?)$/,
                      //   message:
                      //     "Enter value between 20 and 500 (up to 2 decimal places)",
                      // },
                      validate: (value) => {
                        if (value) {
                          const pbg = parseInt(value);
                          return (
                            (pbg >= 20 && pbg <= 500) ||
                            "Invalid input: Blood Glucose must be between 20 and 500 mg/dL"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  /> */}

<PrimaryInput
                                  variant="fullRound"
                                  title="Search by name"
                                  inputPlace="Enter value"
                                  id="yourName"
                                  onChange={handlePostBloodGlucose}
                                  inputValue={postBloodGlucose}
                                  maxLength={3}
                                />
                  {/* <FormErrorMessage mb={"1.5rem"}>
                    {errors["Postprandial Blood Glucose"] &&
                      errors["Postprandial Blood Glucose"].message}
                  </FormErrorMessage> */}
                </Box>
              </Flex>
            {/* </FormControl> */}
            <Divider />

            {/* <Flex direction="column" gap={4} mt="2px">
              <FormControl>
                <Flex align="center">
                  <Flex direction="column" width={{ base: "50%", md: "30%" }}>
                    <FormLabel mb="0px">Weight</FormLabel>
                    <Select
                      value={weightUnit === "lbs" ? "Standard" : "Metric"}
                      onChange={(e) => handleUnitChange(e.target.value)}
                      fontSize={"xs"}
                      size={"xs"}
                      border="none"
                      textAlignLast="left"
                      ml="0px"
                      minWidth="60px"
                    >
                      <option value="Standard">Standard</option>
                      <option value="Metric">Metric</option>
                    </Select>
                  </Flex>
                  <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                    <Input
                      type="number"
                      value={weightValue}
                      placeholder={weightUnit}
                      onChange={(e) => setWeightValue(e.target.value)}
                      height="48px"
                      p="0 10px"
                      width={{ base: "100px", md: "200px" }}
                      bg={"white"}
                      textAlign={"center"}
                      ml="auto"
                    />
                  </Box>
                </Flex>
              </FormControl>

              <FormControl>
                <Flex align="center">
                  <Flex direction="column" width={{ base: "50%", md: "30%" }}>
                    <FormLabel mb="0px">Height</FormLabel>
                    <Select
                      value={heightUnit === "ft" ? "Standard" : "Metric"}
                      onChange={(e) => handleUnitChange(e.target.value)}
                      fontSize={"xs"}
                      size={"xs"}
                      border="none"
                      textAlignLast="left"
                      ml="0px"
                      minWidth="60px"
                    >
                      <option value="Standard">Standard</option>
                      <option value="Metric">Metric</option>
                    </Select>
                  </Flex>
                  <Spacer />
                  <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                    <Flex align="center">
                      <Input
                        type="number"
                        value={heightValue}
                        placeholder={heightUnit}
                        onChange={(e) => setHeightValue(e.target.value)}
                        height="48px"
                        p="0 10px"
                        width={{ base: "70px", md: "100px" }}
                        bg={"white"}
                        textAlign={"center"}
                      />
                      <Box mx={2}>.</Box>
                      <Input
                        type="number"
                        value={heightSubValue}
                        placeholder={heightUnit === "ft" ? "in" : "mm"}
                        onChange={(e) => setHeightSubValue(e.target.value)}
                        height="48px"
                        p="0 10px"
                        width={{ base: "70px", md: "100px" }}
                        bg={"white"}
                        textAlign={"center"}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </FormControl>
            </Flex> */}

            {/* <FormControl> */}
              {/* Weight */}
              <Flex direction="row" align="center" width="100%" mb={4}>
                <Flex direction="column" width="100%">
                  <FormLabel mb="0" width={{ base: "20%", md: "100%" }}>
                    Weight ({weightUnit})
                  </FormLabel>
                  <Flex direction="column" width="100%">
                    <Flex align="center" mb={1} gap={2}>
                      {/* <Box fontSize="xs" fontWeight="bold">
                        {weightUnit}
                      </Box> */}
                      <Select
                        value={weightUnit === "lbs" ? "Standard" : "Metric"}
                        onChange={(e) => handleUnitChange(e.target.value)}
                        fontSize={"xs"}
                        size={"xs"}
                        width={{ base: "80px", md: "100px" }}
                        border="none"
                        textAlignLast="left"
                      >
                        <option value="Standard">Standard</option>
                        <option value="Metric">Metric</option>
                      </Select>
                    </Flex>
                  </Flex>
                </Flex>
                <Box w="60%" textAlign="right">
                  <Input
                    type="number"
                    value={weightValue}
                    placeholder={weightUnit}
                    onChange={(e) => setWeightValue(e.target.value)}
                    // height="40px"
                    // p="0 10px"
                    // width={{ base: "100px", md: "150px" }}
                    height="48px"
                    width={"200px"}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                  />
                </Box>
              </Flex>

              {/* Height */}
              <Flex direction="row" align="center" width="100%">
                <Flex direction="column" width="100%">
                  <FormLabel mb="0" width={{ base: "20%", md: "100%" }}>
                    Height ({heightUnit})
                  </FormLabel>
                  <Flex direction="column" width="100%">
                    <Flex align="center" mb={1} gap={2}>
                      {/* <Box fontSize="xs" fontWeight="bold">
                        {heightUnit}
                      </Box> */}
                      <Select
                        value={heightUnit === "ft" ? "Standard" : "Metric"}
                        onChange={(e) => handleUnitChange(e.target.value)}
                        defaultValue={heightUnit}
                        fontSize={"xs"}
                        size={"xs"}
                        width={{ base: "80px", md: "100px" }}
                        border="none"
                        textAlignLast="left"
                      >
                        <option value="Standard">Standard</option>
                        <option value="Metric">Metric</option>
                      </Select>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justify="flex-end" align="center" gap={2}>
                  <Input
                    type="number"
                    value={heightValue}
                    defaultValue={heightValue}
                    placeholder={heightUnit}
                    onChange={(e) => setHeightValue(e.target.value)}
                    height="48px"
                    p="0 10px"
                    width={"89px"}
                    bg={"white"}
                    textAlign={"center"}
                  />
                  <Box>.</Box>
                  <Input
                    type="number"
                    value={heightSubValue}
                    placeholder={heightUnit === "ft" ? "in" : "mm"}
                    onChange={(e) => setHeightSubValue(e.target.value)}
                    height="48px"
                    p="0 10px"
                    width={"89px"}
                    bg={"white"}
                    textAlign={"center"}
                  />
                </Flex>
              </Flex>
            {/* </FormControl> */}

            {/* <PrimaryButton
              type="submit"
              onClick={() => {
                // Call both functions in sequence
                // await onSubmit(data);  // Ensure that 'data' is available in the context
                SubmitBMIform();
              }}
              buttonText="Save Vitals"
              isDisabled={
                (isButtonDisabled && !heightValue && !weightValue) ||
                !actionAccessMain(19)?.create
              }
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                mr: "0px",
                ml: "0px",
                position: "sticky",
                bottom: "0",
                zIndex: "1",
                mx: "auto",
                mt: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            /> */}

            {/* <PrimaryButton
              type="submit"
              buttonText="Submit Vitals"
              isDisabled={isButtonDisabled}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                mr: "12px",
                ml: "12px",
                position: "sticky",
                bottom: "0",
                zIndex: "1",
                mx: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            /> */}
          </VStack>

        {/* </form> */}
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              {/* <PrimaryButton
                buttonText="Add Vitals"
                // onClick={addmedicine}
                isDisabled={false}
              /> */}

<PrimaryButton
              type="submit"
              onClick={() => {
                // Call both functions in sequence
                // await onSubmit(data);  // Ensure that 'data' is available in the context
                SubmitBMIform();
              }}
              buttonText="Save Vitals"
              isDisabled={
                //(isButtonDisabled && !heightValue && !weightValue) ||
                !actionAccessMain(19)?.create
              }
              // btnStyle={{
              //   h: "2.5rem",
              //   w: "100%",
              //   p: "0",
              //   mr: "0px",
              //   ml: "0px",
              //   position: "sticky",
              //   bottom: "0",
              //   zIndex: "1",
              //   mx: "auto",
              //   mt: "auto",
              //   bg: COLORS.PRIMARY_GRADIENT,
              //   color: COLORS.PRIMARY_WHITE,
              //   _hover: { bg: COLORS.PRIMARY_COLOR },
              // }}
            />
            </Box>
          </>
        }
      />
    </>
  )
}

export default PatientProfileIndex