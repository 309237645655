import Prescription from "../../components/patient-health/doctor-notes/prescription-page";
import React, { useRef, useState, useEffect } from "react";
import { Box, VStack, HStack } from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  GetDoctorNotes,
  UploadPrescription,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import { PrimaryButton } from "../../components";
import Loader from "../../components/loader/loader";
import useLoader from "../../components/loader/loaderstates";
import { COLORS } from "../../components/styles/colors";
import {
  extractDiagnosis,
  formatMedicalHistory,
  transformVitalData,
  calculateAge,
  formatMedicines,
  formatOrders,
} from "../../middleware/domains/visits/patient-health/prescriptions";
import { uploadImageCommonFunction } from "../../auth";
import VisitSummary from "../../components/patient-health/doctor-notes/summary-page";
import { GetPatientHealthData } from "../../middleware/services/visits/patient-overview";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";


const VisitSummaryDocument = () => {
  //-------Fetch Parameters

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const patientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = params.get("doctorId");
  const fromEndVisit = Boolean(params.get("fromEndVisit"));

  // console.log(fromEndVisit);

  const [doctorNotesForSummary, setDoctorNotesForSummary] = useState(null);
  const [patientHistoryForSummary, setPatientHistoryForSummary] =
    useState(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();
  //--------------------

  const fetchPatientHealthData = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: null,
        // patientid: 1866,
        // doctorid: 454,
        // appointmentid: null,
      },
    };
    showLoader();
    try {
      const data = await GetPatientHealthData(inputToFetch.input);
      // console.log(data);
      setPatientHistoryForSummary(data);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
        // patientid: 1866,
        // doctorid: 454,
        // appointmentid: 3110,
      },
    };
    showLoader();
    try {
      // Sleep for 10 seconds
      // await new Promise((resolve) => setTimeout(resolve, 15000));

      const response = await GetDoctorNotes(inputToFetch.input);
      const data = response?.output?.data;
      // console.log(data);
      setDoctorNotesForSummary(data);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotes();
    fetchPatientHealthData();
  }, [patientId, doctorId, appointmentId]);

  const componentRef = useRef();

  const downloadOptions = {
    filename: "Progress Notes.pdf",
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.LOW,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      // format: "letter",
      format: "A4",
      // default is 'portrait'
      // orientation: "landscape"
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };

  // you can also use a function to return the target element besides using React refs
  const getTargetElement = () => document.getElementById("container");

  const downloadPdf = () => generatePDF(getTargetElement, downloadOptions);

  const printOptions = {
    filename: "Progress Notes.pdf",
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.NORMAL,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      // format: "letter",
      format: "A4",
      // default is 'portrait'
      // orientation: "landscape"
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };
  const printPdf = () => generatePDF(getTargetElement, printOptions);

  const uploadOptions = {
    filename: "ProgressNotes.pdf",
    method: "build",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.LOW,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      // format: "letter",
      format: "A4",
      // default is 'portrait'
      // orientation: "landscape"
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };

  const saveProgressNotesUpload = async (fileName) => {
    const inputToSave = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
        documenturl: fileName,
        type: "progressnotes",
        branchid: null,
      },
    };
    // console.log(inputToSave);
    try {
      const response = await UploadPrescription(inputToSave.input);
      // console.log(response);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleGeneratePDFToUpload = async () => {
    const pdfInstance = await generatePDF(getTargetElement, uploadOptions);
    return new Blob([pdfInstance.output("blob")], {
      type: "application/pdf",
    });
  };

  const uploadFile = async () => {
    try {
      const pdfBlob = await handleGeneratePDFToUpload();
      const file = new File([pdfBlob], "progressnotes.pdf", {
        type: "application/pdf",
      });
      const response = await uploadImageCommonFunction(file, "progressnotes.pdf");
      // console.log(response);
      if (response) {
        saveProgressNotesUpload(response.name);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
      if (fromEndVisit) {
        const storedAppointmentId = localStorage.getItem(
          "progressNotesForAppointmentId"
        );
        if (storedAppointmentId !== appointmentId) {
          const uploadAfterFetch = async () => {
            await fetchDoctorNotes();
          };
          uploadAfterFetch();
        }
      }
    }, [fromEndVisit, appointmentId]);
  
    useEffect(() => {
      if (fromEndVisit && doctorNotesForSummary && patientHistoryForSummary) {
        const storedAppointmentId = localStorage.getItem(
          "progressNotesForAppointmentId"
        );
        if (storedAppointmentId !== appointmentId) {
          console.log("Upload called");
          uploadFile();
          localStorage.setItem("progressNotesForAppointmentId", appointmentId);
        }
      }
    }, [fromEndVisit, appointmentId, doctorNotesForSummary, patientHistoryForSummary]);

  return (
    <Box height="100vh" overflowY="scroll">
      {isLoading && <Loader />}
      <VStack spacing={4} justify="center" align="center" p={4}>
        <HStack spacing={4}>
          <PrimaryButton
            buttonText="Print"
            variant="grayBtn"
            btnStyle={{ w: "12rem", h: "3rem", color: COLORS.PRIMARY_COLOR }}
            onClick={printPdf}
          />
          <PrimaryButton
            buttonText="Download"
            onClick={downloadPdf}
            btnStyle={{ w: "12rem", h: "3rem" }}
          />
        </HStack>
        <VStack align="center">
          <Box
            ref={componentRef}
            width="100%"
            height={"80vh"}
            overflow={"auto"}
          >
            <div id="container">
              {doctorNotesForSummary && patientHistoryForSummary && (
                <VisitSummary
                  doctorNotes={doctorNotesForSummary}
                  patientHistory={patientHistoryForSummary}
                />
              )}
            </div>
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default VisitSummaryDocument;
