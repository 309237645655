export default function formAPIupcomingvisits({
    patientname,
    chiefcomplaint,
    appointmentdate,
    patientimage,
    imagepath,
    appointmentid,
    patientid,
    appointmenttime,
    appointmentstatus,
    doctorid,
    
  }) {
   
  
    return Object.freeze({
        patientname: patientname,
        chiefcomplaint: chiefcomplaint,
        appointmentdate:appointmentdate,
        patientimage,
        imagepath,
        appointmentid,
    patientid,
    appointmenttime,
    appointmentstatus,
    doctorid,
    });
  }
  