import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Badge,
  Text,
  VStack,
  Input,
  HStack
} from "@chakra-ui/react";
import ModalRight from "../../../components/modal-right";
import DoctorHPIAnalyzer from "./hpi-analyze";
import {
  GetDoctorMasterData,
  GetDoctorNotes,
  DeleteOrder,
  updateDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { formatHPIMasterData } from "../../../middleware/domains/visits/doctornotes";
import HPIViewCard from "./hpi-view-card";
import DynamicModal from "../../../components/dynamic-modal";
import PrimaryButton from "../../primary-button";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import { COLORS } from "../../styles/colors";
import { useGPUTranscriber } from "./cheif-complaint-audio/useGPUTranscriber";
import { GPUChatAudioManager } from "./cheif-complaint-audio/gpuChatVoiceManager";
import { actionAccessMain } from "../../../auth";


function HpiNotesCard({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  fetchDoctorNotesEmptyIndication,
  submitOnTabSwitch
}) {
  //----------------------------- Textual HPI
  const activeTranscriber = useGPUTranscriber();

  const [typing, setTyping] = useState(false); // Typing indicator state

  const [placeholder, setPlaceholder] = useState(
    "Click anywhere to start typing...."
  );

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setValue("textField", activeTranscriber.output.text);

      setPlaceholder("Click anywhere to start typing....");
      setTyping(false);
    }
  }, [activeTranscriber?.output]);

  const { register, handleSubmit, watch, setValue } = useForm();
  const textFieldValue = watch("textField", "");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [initialText, setInitialText] = useState("");

  const UpdateHpiTextBox = async (data) => {
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        hpitext: {
          description: data.textField,
          type: "other",
        },
      },
    };

    showLoader();
    try {
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("HPI Saved successfully");
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  const onSubmit = (data) => {
    setIsButtonDisabled(true); // Disable button on submit
    UpdateHpiTextBox(data);
    fetchDoctorNotesEmptyIndication();
  };

   // submit on tab switch and submit button is enalbed
    useEffect(()=>{
        if(!isButtonDisabled && submitOnTabSwitch){
        handleSubmit(onSubmit)();
        }
    },[submitOnTabSwitch])

  useEffect(() => {
    if (textFieldValue !== initialText) {
      setIsButtonDisabled(false); // Enable button if text field value changes
    } else {
      setIsButtonDisabled(true); // Disable button if text field value is unchanged
    }
  }, [textFieldValue, initialText]);

  //----------------------------- HPI Master Data
  const [doctorMasterHPIData, setDoctorMasterHPIData] = useState([]);
  const [doctorNotesHPIData, setDoctorNotesHPIData] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [onsetOptions, setOnsetOptions] = useState([]);
  const [chronicityOptions, setChronicityOptions] = useState([]);
  const [onsetQualityOptions, setOnsetQualityOptions] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [associatedSymptomsOptions, setAssociatedSymptomsOptions] = useState(
    []
  );
  const [additionalOptions, setAdditionalOptions] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editHPIData, setEditHPIData] = useState(null);

  const { isLoading, showLoader, dismissLoader } = useLoader();

  useEffect(() => {
    fetchHPIMasterData();
    fetchDoctorNotes();
  }, []);

  const fetchHPIMasterData = async () => {
    showLoader();
    try {
      const inputData = { input: { type: "hpi" } };
      const data = await GetDoctorMasterData(inputData.input);
      const formattedData = formatHPIMasterData(data);
      setDoctorMasterHPIData(formattedData);
    } catch (error) {
      console.error("Error fetching Master HPI data:", error);
    } finally {
      dismissLoader();
    }
  };

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      const hpi = data?.output?.data?.historyofpresentillness || [];
      const hpitext = data?.output?.data?.hpitext?.description || "";
      setInitialText(hpitext);
      setValue("textField", hpitext);
      setDoctorNotesHPIData(hpi);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  // ---------------- Add or Edit HPI -------------------

  const addHpi = useRef();
  const [addHPIType, setAaddHPIType] = useState("");

  const handleAddHPI = (event, type) => {
    event.stopPropagation();
    prepareAndOpenModal(type);
  };

  const prepareAndOpenModal = (type, editData = null) => {
    const selectedItem = doctorMasterHPIData.find((item) => item.type === type);
    if (selectedItem) {
      // S-Site Filter Master Data
      const filteredsiteOptions = selectedItem.value
        .filter((item) => item.type === "Site")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Filter Master Data
      const filteredOnsetOptions = selectedItem.value
        .filter((item) => item.type === "Onset")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Chronicity Filter Master Data
      const filteredChronicityOptions = selectedItem.value
        .filter((item) => item.type === "Chronicity")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Quality Filter Master Data
      const filteredOnsetQualityOptions = selectedItem.value
        .filter((item) => item.type === "Onset Quality")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Onset Filter Master Data
      const filteredFrequencyOptions = selectedItem.value
        .filter((item) => item.type === "Frequency")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // S-Site Filter Master Data
      const filteredAssociatedSymptomsOptions = selectedItem.value
        .filter((item) => item.type === "Associated Symptoms")
        .flatMap((item) => item.subtype.map((subItem) => subItem.value));

      // Create dynamic array for additional types
      const additionalTypes = {};
      selectedItem.value.forEach((item) => {
        if (
          item.type !== "Site" &&
          item.type !== "Onset" &&
          item.type !== "Chronicity" &&
          item.type !== "Onset Quality" &&
          item.type !== "Frequency" &&
          item.type !== "Rating Scale" &&
          item.type !== "Time" &&
          item.type !== "Associated Symptoms"
        ) {
          if (!additionalTypes[item.type]) {
            additionalTypes[item.type] = [];
          }
          const subtypes = item.subtype.map((subItem) => subItem.value);
          additionalTypes[item.type].push(...subtypes);
        }
      });

      // console.log(additionalTypes);
      setAdditionalOptions(additionalTypes);

      setSiteOptions(filteredsiteOptions);
      setOnsetOptions(filteredOnsetOptions);
      setChronicityOptions(filteredChronicityOptions);
      setOnsetQualityOptions(filteredOnsetQualityOptions);
      setFrequencyOptions(filteredFrequencyOptions);
      setAssociatedSymptomsOptions(filteredAssociatedSymptomsOptions);

      setAaddHPIType(type);

      setIsEditMode(!!editData);
      setEditHPIData(editData);

      // console.log(editData);

      // open Modal Call
      openEditModal();
    }
  };

  const openEditModal = () => {
    if (addHpi.current) {
      addHpi.current.openRight();
    }
  };

  const editHPI = (note) => {
    const type = note.type;
    prepareAndOpenModal(type, note);
  };

  //-------------------Delete HPI-------------------

  const dynamicModalDelteHPI = useRef();

  const [doctorNotesHPIDeleteId, setDoctorNotesHPIDeleteId] = useState("");

  const openDeleteModal = (orderId) => {
    setDoctorNotesHPIDeleteId(orderId);
    if (dynamicModalDelteHPI.current) {
      dynamicModalDelteHPI.current.openModal();
    }
  };

  const deleteSelectedHPI = async () => {
    const inputForDelete = {
      input: {
        id: doctorNotesHPIDeleteId,
        patientid: patientId,
        type: "hpi",
      },
    };

    const deleteOrder = async () => {
      try {
        const result = await DeleteOrder(inputForDelete.input);
        if (result.output.message === "Successfully Deleted") {
          showSuccessToast("Order has been deleted");
          fetchDoctorNotes();
          fetchDoctorNotesEmptyIndication();
          dynamicModalDelteHPI.current.closeModal();
        } else {
          console.log(result.output.message);
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error in deleteMedicalCondition:", error);
      }
    };
    deleteOrder();
  };

  //-------------Initial Expansion
  const [expandedIndices, setExpandedIndices] = useState([]);

  useEffect(() => {
    const getInitialExpandedIndices = () => {
      const expandedIndices = [];
      if (doctorNotesHPIData && doctorNotesHPIData.length > 0) {
        doctorMasterHPIData.forEach((item, index) => {
          const hasData = doctorNotesHPIData.some(
            (note) => note.type.toLowerCase() === item.type.toLowerCase()
          );
          if (hasData) expandedIndices.push(index);
        });
      }
      return expandedIndices;
    };

    setExpandedIndices(getInitialExpandedIndices());
  }, [doctorNotesHPIData, doctorMasterHPIData]);

  return (
    <Box
      position="relative"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Box bg="white" height="100%">
        <Accordion
          allowMultiple
          index={expandedIndices}
          onChange={(indices) => setExpandedIndices(indices)}
        >
          {doctorMasterHPIData.map((item) => (
            <AccordionItem key={item.type}>
              <h2>
                <AccordionButton>
                  <Box flex="1" fontSize='14px' textAlign="left">
                    {item.type.toUpperCase()}
                  </Box>
                  <Badge
                    ml="1"
                    color="blue"
                    bg="white"
                    cursor="pointer"
                    onClick={(event) => handleAddHPI(event, item.type)}
                  >
                    + Add
                  </Badge>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} bg="#f9fafb">
                {doctorNotesHPIData
                  .filter(
                    (note) =>
                      note.type.toLowerCase() === item.type.toLowerCase()
                  )
                  .map((note) => {
                    // Extract all other options that are not part of the explicitly handled attributes
                    const otherOptions = note.details.reduce((acc, detail) => {
                      if (
                        ![
                          "Site",
                          "Onset",
                          "Chronicity",
                          "Onset Quality",
                          "Frequency",
                          "Rating Scale",
                          "Associated Symptoms",
                          "Time",
                          "Time Value",
                        ].includes(detail.attribute)
                      ) {
                        acc[detail.attribute] = detail.values?.map(
                          (v) => v.value
                        ) || [detail.value];
                      }
                      return acc;
                    }, {});

                    return (
                      <Box p={{ base: "2", md: "4" }} key={note.id}>
                        <HPIViewCard
                          id={note.id}
                          site={
                            note.details
                              .find((detail) => detail.attribute === "Site")
                              ?.values.map((v) => v.value) || []
                          }
                          onset={
                            note.details.find(
                              (detail) => detail.attribute === "Onset"
                            )?.value || ""
                          }
                          chronicity={
                            note.details.find(
                              (detail) => detail.attribute === "Chronicity"
                            )?.value || ""
                          }
                          onsetQuality={
                            note.details.find(
                              (detail) => detail.attribute === "Onset Quality"
                            )?.value || ""
                          }
                          frequency={
                            note.details.find(
                              (detail) => detail.attribute === "Frequency"
                            )?.value || ""
                          }
                          ratingScale={
                            note.details.find(
                              (detail) => detail.attribute === "Rating Scale"
                            )?.value || ""
                          }
                          associatedSymptoms={
                            note.details
                              .find(
                                (detail) =>
                                  detail.attribute === "Associated Symptoms"
                              )
                              ?.values.map((v) => v.value) || []
                          }
                          time={
                            note.details.find(
                              (detail) => detail.attribute === "Time"
                            )?.value || ""
                          }
                          timeValue={
                            note.details.find(
                              (detail) => detail.attribute === "Time Value"
                            )?.value || ""
                          }
                          deleteHPI={() => openDeleteModal(note.id)}
                          editHPI={() => editHPI(note)}
                          otherOptions={otherOptions} // Pass the dynamically extracted options here
                        />
                      </Box>
                    );
                  })}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          padding={{ base: "0", md: "4" }}
          width={{ base: "100%", md: "98%" }}
          height="55%"
          position={{ base: "unset", md: "absolute" }}
          bg="white"
          pt={"2px"}
        >
          <VStack spacing="4" height="85%">
            <Box flex="1" width="100%" position="relative" bg={"#f9fafb"}>
              <Input
                {...register("textField")}
                height="100%"
                padding="2"
                resize="none"
                as="textarea"
                disabled={typing} // Disable when AI typing is true
                style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}
              />
              <Text
                position="absolute"
                bottom="2"
                left="50%"
                transform="translateX(-50%)"
                fontSize="sm"
                color="gray.500"
                w={{ base: "100%", md: "unset" }}
                textAlign={{ base: "center", md: "unset" }}
              >
                {placeholder}
              </Text>
            </Box>
            <HStack
              p={2}
              // position="absolute"
              bottom="0"
              width="100%"
              alignItems="flex-end"
            >
              <GPUChatAudioManager
                transcriber={activeTranscriber}
                setPlaceholder={setPlaceholder}
                setTyping={setTyping}
              />
              <PrimaryButton
                type="submit"
                buttonText="Save HPI Text"
                variant="grayBtn"
                isDisabled={isButtonDisabled || !textFieldValue || !actionAccessMain(17)?.create}
                btnStyle={{
                  h: "2.5rem",
                  w: "90%",
                  p: "0",
                  mr: "12px",
                  ml: "12px",
                  position: "sticky",
                  bottom: "0",
                  zIndex: "1",
                  mx: "auto",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
              />
            </HStack>
          </VStack>
        </Box>

        {/* Add or Edit HPI Functionality */}
        <ModalRight
          ref={addHpi}
          modalWidth={{ base: "100%", lg: "calc(100vw - 30%)" }}
          header={isEditMode ? "Edit HPI" : "Add HPI"}
          bodyStyle={{
            p: { base: "0.75rem", lg: "0.75rem 1.5rem" },
            overflow: "hidden auto",
          }}
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                <Box>
                  <DoctorHPIAnalyzer
                    patientId={patientId}
                    doctorId={doctorId}
                    appointmentId={appointmentId}
                    type={addHPIType}
                    siteOptions={siteOptions}
                    onsetOptions={onsetOptions}
                    chronicityOptions={chronicityOptions}
                    onsetQualityOptions={onsetQualityOptions}
                    frequencyOptions={frequencyOptions}
                    associatedSymptomsOptions={associatedSymptomsOptions}
                    otherOptions={additionalOptions}
                    fetchDoctorNotes={fetchDoctorNotes}
                    showSuccessToast={showSuccessToast}
                    showErrorToast={showErrorToast}
                    modalRef={addHpi}
                    editHPIData={editHPIData}
                    isEditMode={isEditMode}
                    fetchDoctorNotesEmptyIndication={
                      fetchDoctorNotesEmptyIndication
                    }
                  />
                </Box>
              </Box>
            </>
          }
        />

        {/* DynamicModal for deletion */}
        <DynamicModal
          ref={dynamicModalDelteHPI}
          modalHeader={`Delete HPI?`}
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete?
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteSelectedHPI}
              />
            </>
          }
        />
      </Box>
    </Box>
  );
}

export default HpiNotesCard;
