import  FetchData, { } from '../../client';
import {getBranchId, getLoginID}  from '../../../auth';
import formapimodalfinalsteps from '../../domains/finalsteps';


//reac the file

const SubmitServicetype = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
        inputData.branchid = getBranchId();
    const result = await FetchData("midoc_doctortimingsetup", inputData);
    //console.log(result,"Home db response")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const SubmitLastCallofFinalStep = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_updatedoctorprofile", inputData);
    //console.log(result,"Home db response")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchFinalStepsData = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdoctorprofilesetupreview", inputData);
    // if (result.output.data) {
    //   var finalOutput = formapimodalfinalsteps(result.output.data)
    //   }
    return result.output.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchDoctorSpecialities = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    // if (result.output.data) {
    //   var finalOutput = formapimodalfinalsteps(result.output.data)
    //   }
    return result.output.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const SubmitDoctorSpeciality = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_adddoctorspeciality", inputData);
    //console.log(result,"Home db response")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const SubmitAvailbility = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_updatedoctoravailability", inputData);
    //console.log(result,"Home db response")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchTimezones = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    //console.log(result,"Home db response")
    return result.output.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export {SubmitServicetype, SubmitLastCallofFinalStep, FetchFinalStepsData, FetchDoctorSpecialities,SubmitDoctorSpeciality, SubmitAvailbility, FetchTimezones};
