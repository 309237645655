import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Text,
  VStack,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";

import { useGPUTranscriber } from "../../../chatbot/audio/useGPUTranscriber";
import { CompatibilityGPUChatAudioManager } from "./compatibility-audio";

const AudioCompatibilityCheck = ({ modalRef }) => {
  const [step, setStep] = useState(0); // Tracks current step
  const [timer, setTimer] = useState(0); // Timer for countdown
  const [fiveSecDemoResponse, setFiveSecDemoResponse] = useState("");
  const [thirtySecDemoResponse, setThirtySecDemoResponse] = useState("");
  const [currentResponse, setCurrentResponse] = useState("");
  const [transcriptionTime, setTranscriptionTime] = useState(null);
  const [recordStartTime, setRecordStartTime] = useState(null);
  const [instructions, setInstructions] = useState(
    "To check device compatibility, please record two audio samples (5 seconds and 30 seconds). Press Mic Icon to begin."
  );
  const activeTranscriber = useGPUTranscriber();

  const gpuChatManagerRef = useRef(null); // Reference to GPUChatAudioManager

  const textSampleFor5Seconds =
    "Patient safety and well-being are at the heart of my practice every day.";
  const textSampleFor30Seconds =
    "As a healthcare professional, I am committed to delivering personalized medical care, tailoring treatments to each patient's unique needs. My approach involves thorough diagnostics and leveraging evidence-based practices to ensure the best outcomes. Communication with patients is crucial, and I strive to educate them about their health conditions and treatment plans. By prioritizing both preventive and curative care, my goal is to support my patients in leading healthier lives.";

  const startRecording = () => {
    setRecordStartTime(Date.now());
    setTimer(step === 0 ? 5 : 30); // Set timer based on step
    setInstructions(`Recording for ${step === 0 ? "5" : "30"} seconds...`);
    gpuChatManagerRef.current?.handleMicClick(); // Start recording
  };

  const stopRecording = () => {
    setTimer(0);
    gpuChatManagerRef.current?.handleMicClick(); // Stop recording
  };

  const handleTranscriptionComplete = () => {
    const transcriptionDuration = (Date.now() - recordStartTime) / 1000;
    setTranscriptionTime(transcriptionDuration);
    console.log(transcriptionDuration);

    if (step === 1 && thirtySecDemoResponse) {
      localStorage.setItem(
        "capableGPU",
        transcriptionDuration < 38 ? "true" : "false"
      );
      console.log(
        "This check should happen only once, when time is ",
        transcriptionDuration - 30
      );
      setInstructions("Thank you! Compatibility check complete.");
      if (modalRef?.current) {
        setTimeout(() => {
          modalRef.current.closeModal();
        }, 5000); // 5000ms = 5 seconds
      }
      setStep(2);
    } else if (step === 0) {
      setInstructions(
        "Ready for round two. This time, talk for 30 seconds. Press start to begin."
      );
      setStep(1);
    } else if (step === 2) {
      setInstructions("Thank you! Compatibility check complete.");
      // Close the modal if the ref exists
      // Wait for 5 seconds before closing the modal
      // console.log("closing")
      // if (modalRef?.current) {
      //   console.log("closing in 5")
      //   setTimeout(() => {
      //     modalRef.current.closeModal();
      //     console.log("closed")
      //   }, 5000); // 5000ms = 5 seconds
      // }
      // else {
      //   console.log("ref is null")
      // }
    }
  };

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    } else if (timer === 0 && recordStartTime) {
      stopRecording();
      const checkTranscriptionComplete = setInterval(() => {
        if (activeTranscriber?.output?.text) {
          console.log(activeTranscriber?.output?.text);
          if (step === 0) {
            setFiveSecDemoResponse(activeTranscriber?.output?.text);
          }
          if (step === 1) {
            setThirtySecDemoResponse(activeTranscriber?.output?.text);
          }
          setCurrentResponse(activeTranscriber?.output?.text);
          clearInterval(checkTranscriptionComplete);
          handleTranscriptionComplete();
        }
      }, 500);
    }
    return () => clearTimeout(countdown);
  }, [timer, recordStartTime, activeTranscriber]);

  return (
    <VStack spacing={6} p={6} align="center" bg="gray.100" borderRadius="md">
      <Box textAlign="center">
        <Text fontSize="xl" fontWeight="bold">
          Device Compatibility Check
        </Text>
        {(step === 0 || step === 1) && <Text mt={2}>{instructions}</Text>}
        {step === 2 && (
          <Text mt={2} fontSize="md" color="green.500">
            {instructions}
          </Text>
        )}
      </Box>

      {step < 2 && (
        <VStack>
          {timer > 0 && (
            <CircularProgress
              value={(timer / (step === 0 ? 5 : 30)) * 100}
              size="80px"
              color="blue.500"
            >
              <CircularProgressLabel>{timer}</CircularProgressLabel>
            </CircularProgress>
          )}
          <CompatibilityGPUChatAudioManager
            // ref={gpuChatManagerRef} // Pass reference
            transcriber={activeTranscriber}
            setPlaceholder={setInstructions}
            setTyping={() => {}}
            startRecording={startRecording}
            stopRecording={stopRecording}
            step={step}
          />
        </VStack>
      )}

      {step === 2 && transcriptionTime !== null && (
        <Text fontSize="md" color="green.500">
          Your transcription time: {Math.round(transcriptionTime - 30)}
          seconds.
          {/* Capable GPU set to {transcriptionTime < 38 ? "true" : "false"}. */}
        </Text>
      )}

      {step === 0 && (
        <VStack>
          <Text fontSize="md" color="blue.500">
            Once Recording is started please read the following text loudly:
          </Text>
          <Text
            fontSize="sm"
            color="green.500"
            maxWidth="50vw"
            textAlign="center"
            wordBreak="break-word"
          >
            {textSampleFor5Seconds}
          </Text>
        </VStack>
      )}

      {step === 1 && (
        <VStack>
          <Text fontSize="md" color="blue.500">
            Once Recording is started please read the following text loudly:
          </Text>
          <Text
            fontSize="sm"
            color="green.500"
            maxWidth="50vw"
            textAlign="center"
            wordBreak="break-word"
          >
            {textSampleFor30Seconds}
          </Text>
        </VStack>
      )}

      {/* <Button
        onClick={startRecording}
        isDisabled={timer > 0 || step === 2}
        colorScheme="blue"
      >
        {step === 0
          ? "Start 5-Second Test"
          : step === 1
            ? "Start 30-Second Test"
            : "Completed"}
      </Button> */}
      <Text fontWeight="bold">
        {step === 0
          ? "Click on Mic Icon to Start 5-Second Test"
          : step === 1
            ? "Click on Mic Icon to Start 30-Second Test"
            : "Completed"}
      </Text>
    </VStack>
  );
};

export default AudioCompatibilityCheck;
