import { Box, SimpleGrid, Flex, Image, Text, Spacer } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import MobileNavbar from '../../components/mobile-navbar'
import { useNavigate } from 'react-router-dom'
import { getProfile, logOutCall } from '../../middleware/services/profile'
import PrimaryButton from '../../components/primary-button'
import { Toaster } from '../../components'
import useLoader from '../../components/loader/loaderstates'
import Loader from '../../components/loader/loader'
import DynamicModal from '../../components/dynamic-modal'
import Footer from '../../components/footer'
import { actionAccessMain } from '../../auth'

const MoreIndex = () => {
  const navigate = useNavigate()
  const [personalData, setPersonalData] = useState("")
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [disablePages, setDisablePages] = useState("");
  // const [actionAccess, setActionAccess] = useState("");
  const logoutModal = useRef()

  useEffect(() => {
    // var docId = localStorage.getItem("loginid")
    // setDoctorId(docId)
    getDoctorProfile();
  }, [])

  useEffect(() => {
    let disablePages = JSON.parse(localStorage.getItem("pagesAccess"));
    setDisablePages(disablePages)
    // let actionAccessLocal = JSON.parse(localStorage.getItem("actionAccess"));
    // setActionAccess(actionAccessLocal)
  }, []);
  

  var inputForGet = {}
  const getDoctorProfile = async () => {
    try {
      var result = await getProfile(inputForGet);
      if (result && result.ProfileOutput) {
        setPersonalData(result.ProfileOutput)
        console.log(result.ProfileOutput, 'perosnal data check')
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  let inputForLogOut = {}
  const handleLogOut = async ()=>{
    try {
      showLoader();
      const result = await logOutCall(inputForLogOut);  
      //console.log(result , " log out result")
      if (result.output.result == "success") {
      //  setRefresh(!refresh)
        //showSuccessToast("Updated Successfully.")
        localStorage.clear()
        sessionStorage.clear()
        navigate("/login")
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in updating patient profile")

    }
    dismissLoader();
  }
  const navItems = [
    {
      to: "/patients",
      iconSrc: "/assets/svgs/patient.svg",
      text: "Patients",
    },
    {
      to: "/prescriptions",
      iconSrc: "/assets/svgs/prescriptions.svg",
      text: "Prescriptions",
    },
    {
      to: "/medicines",
      iconSrc: "/assets/svgs/medication.svg",
      text: "Medicines",
    },
    {
      to: "/notifications",
      iconSrc: "/assets/svgs/notifications-filled.svg",
      text: "Notifications",
    },
  ]

  const addInventory = ()=>{
    localStorage.setItem("openModal", true)
     navigate("/inventory")
   }

  const whiteColor = "brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(0%) hue-rotate(116deg) brightness(105%) contrast(100%)"
  const grayColor = "brightness(0) saturate(100%) invert(32%) sepia(2%) saturate(543%) hue-rotate(201deg) brightness(98%) contrast(81%)"
  return (
    <>
    {isLoading && <Loader />}
      <Box w="100%">
        <MobileNavbar />
        {/* User section start */}
        <Box bg="#0B0B0D">
          {/* <Box  top="0" zIndex="1" w="100%" h="100px" pos="absolute" /> */}
          <Flex w="100%" p="1.5rem" as='button' onClick={()=> navigate('/doctorprofile')} justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap="0.75rem" w="100%">
              <Box>
                <Image border="1px solid #E6E7E9" boxSize="64px" borderRadius="full" src={personalData ? personalData.imagepath + personalData.profilepictureurl : "/assets/imgs/no-image-icon.png"} />
              </Box>
              <Box display="flex" flexDir="column">
                <Text fontSize="1.25rem" fontWeight="600" color='white'>{personalData && personalData.firstname} {personalData && personalData.lastname}</Text>
                <Text fontSize="0.875rem" textAlign='left' fontWeight="400" color='white'>{personalData && personalData.email}</Text>
              </Box>
            </Box>
            <Image filter={whiteColor} src="/assets/imgs/right-icon.png" />
          </Flex>
        </Box>
        {/* User section end */}
        
        <Box w="100%" h='calc(100vh - 300px)' overflow="auto" bg="#FCFCFD">
          {/* Menu section start */}
          <Box w="100%">
            <Flex w="100%" flexDir='column'>
            {navItems.map((item, index)=> (
              <Box key={index} as="button" boxSize="4rem" onClick={()=> navigate(item.to)} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src={item.iconSrc} />
                <Text>{item.text}</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              ))}
               { (disablePages && 
              (!disablePages?.disableroles || !disablePages?.disableusers || actionAccessMain(9)?.read || actionAccessMain(10)?.read)) ? (
              <Box w='100%' p="1rem" textAlign='left'>
                <Text fontSize="14px" fontWeight='700'>User Management</Text>
              </Box>
              ): null }

              {disablePages &&  !disablePages?.disableroles && actionAccessMain(9)?.read ?
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/roles")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/roles-inactive.svg" />
                <Text>Roles</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              : null
              }

              {disablePages &&  !disablePages?.disableusers && actionAccessMain(10)?.read ?
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/users")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/users-inactive.svg" />
                <Text>Users</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              : null
              }
              {actionAccessMain(11)?.read ?
              <>
              <Box w='100%' p="1rem" textAlign='left'>
                <Text fontSize="14px" fontWeight='700'>Inventory Management</Text>
              </Box>
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/inventory")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/inventory.svg" />
                <Text>Inventory</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              {actionAccessMain(11)?.create ? (
              <Box as="button" boxSize="4rem" onClick={addInventory} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/add-inventory.svg" />
                <Text>Add Inventory</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              ):(null)
              }
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/inventorylogs")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/inventory-logs.svg" />
                <Text>Inventory Logs</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              </>
              : null
              }

              {actionAccessMain(12)?.read || actionAccessMain(13)?.read ?
              <>
              <Box w='100%' p="1rem" textAlign='left'>
                <Text fontSize="14px" fontWeight='700'>Billing Management</Text>
              </Box>
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/billing")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/billing.svg" />
                <Text>Sales-Pending</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              </>
               : null
              }

              { actionAccessMain(13)?.read ?
              <>
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/invoices")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/sales.svg" />
                <Text>Sales</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              </>
                 : null
                }

              { actionAccessMain(12)?.read ?
              <>
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/labpricing")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/lab-setup.svg" />
                <Text>Lab Setup</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              </>
                 : null
                }

              {actionAccessMain(10)?.read ?
              <>
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/imagepricing")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/imaging-setup.svg" />
                <Text>Imaging Setup</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              </>
               : null
              }

              {actionAccessMain(14)?.read ?
              <>
              <Box as="button" boxSize="4rem" onClick={()=> navigate("/totalrevenue")} w="100%" p="1rem" display='flex' borderBottom='1px solid #e2e8f0' gap='1rem' alignItems='center'>
                <Image boxSize='1.25rem' filter={grayColor} src="/assets/svgs/total-revenue.svg" />
                <Text>Revenue</Text>
                <Spacer />
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              </>
              : null
                }
            </Flex>
          </Box>
          {/* Menu section end */}

          {/* Bottom area start */}
          <Box w="100%" p="1rem">
            <Box pb="1rem">
              <Footer />
            </Box>

            <Box>
              <PrimaryButton onClick={()=> logoutModal.current.openModal()} btnIcon='/assets/svgs/logout-red.svg' btnStyle={{bg: "#D800270D", color: "#D80027", my: "0"}} buttonText="Logout" variant="deleteBtn" />
            </Box>
          </Box>
          {/* Bottom area end */}
        </Box>
      </Box>

      <DynamicModal
        ref={logoutModal}
        modalHeader="Logout"
        modalBody={
          <>
            <Text fontSize="0.875rem" fontWeight="400">Are you sure you want to logout?</Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Logout"
              onClick={handleLogOut}
            />
          </>
        }
      />
    </>
  )
}

export default MoreIndex