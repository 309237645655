import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Card,
  CardFooter,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  InputGroup,
  Menu,
  MenuButton,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  MenuList,
  MenuItem,
  InputRightElement,
  Input,
  Td,
  Tr,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  Stepper,
  StepSeparator,
  UnorderedList,
  ListItem,
  CardBody,
  Switch,
} from "@chakra-ui/react";
import {
  FetchPatientDetails,
  FetchPatients,
  FetchChatData,
  AddMessage,
  FetchPatientChatMedia,
  updatechatmessageread,
  requestPayment,
} from "../../../middleware/services/messages";
import { useNavigate } from "react-router-dom";
import {
  actionAccessMain,
  downloadDocument,
  formatToDecimal,
  getLoginID,
  uploadImageCommonFunction,
} from "../../../auth";
import Toaster from "../../toaster";
import moment from "moment";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import { useGPUTranscriber } from "../doctor-notes/cheif-complaint-audio/useGPUTranscriber";
import { GPUChatAudioManager } from "../doctor-notes/cheif-complaint-audio/gpuChatVoiceManager";
import DynamicModal from "../../dynamic-modal";
import PrimaryButton from "../../primary-button";


const GPUMessages = ({
  doctorId,
  patientId,
  appointmentId,
  isVirtualVisit,
}) => {
  //-----------------------------Voice To Text
  const activeTranscriber = useGPUTranscriber();

  const [typing, setTyping] = useState(false); // Typing indicator state

  const [placeholder, setPlaceholder] = useState("Type a message...");

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setMessageValue(activeTranscriber.output.text);

      setPlaceholder("Type a message...");
      setTyping(false);
    }
  }, [activeTranscriber?.output]);

  //----------------------------other functionality
  const [activeTab, setActiveTab] = useState("images");
  const manageModel = useRef();
  const cancelVisit = useRef();
  const scheduleVisit = useRef();
  const closeChat = useRef();
  const startVisit = useRef();
  const doctorProfile = useRef();
  const requestpaymentmodal = useRef();
  const disablechatmodal = useRef();
  const navigate = useNavigate();
  const chatContainerRef = React.useRef(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  var [doctorid, setDoctorid] = useState(getLoginID());
  var [patientid, setPatientID] = useState(patientId);
  var [data, setData] = useState(null);
  var [patientsdata, setPatientData] = useState(null);
  var [chatsdata, setChatData] = useState([]);
  var [ChatMedia, setChatMedia] = useState(null);
  var [ChatHeader, setChatHeader] = useState(null);
  var [ChatEnableDetails, setChatEnableDetails] = useState(null);
  var [DoctorCurrency, setDoctorCurrency] = useState("");
  var [doctormessage, setDoctorMessage] = useState("");
  var [activechatbox, setActivechatbox] = useState("");
  const [search, setSearch] = useState("");
  const lastMessageRef = useRef(null);
  const { showSuccessToast, showErrorToast } = Toaster();
  const messageRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const handlePageChange = () => {
    localStorage.setItem("previousPage", "messages");
    navigate("/patientprofile?id=" + patientid + "&page=overview");
  };
  const [isFocused, setIsFocused] = useState(false);
  const [chatInputHeight, setChatInputHeight] = useState(80);
  //   const location = useLocation();
  const { id } = patientId;
  const [isDoctorListVisible, setIsDoctorListVisible] = useState(true);
  const [screenType, setScreenType] = useState("web");

  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessVisits, setActionAccessVisits] = useState(null);
  const [actionAccessAppointment, setActionAccessAppointment] = useState(null);
  const [actionAccessPatients, setActionAccessPatients] = useState(null);
  const [messageValue, setMessageValue] = useState("");

  useEffect(() => {
    setActionAccess(actionAccessMain(3));
    setActionAccessVisits(actionAccessMain(2));
    setActionAccessAppointment(actionAccessMain(15));
    setActionAccessPatients(actionAccessMain(4));
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScreenType("mobile");
      } else {
        setScreenType("web");
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (chatContainerRef.current) {
        const chatSectionHeight =
          window.innerHeight - 73 - 91 - chatInputHeight;
        chatContainerRef.current.style.height = `${chatSectionHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [chatInputHeight]);

  useEffect(() => {
    fetchchatdata(patientId);
  }, []);

  const fetchchatdata = async (id) => {
    setChatData("");

    //showLoader()
    try {
      const result = await FetchChatData({
        doctorid: doctorId,
        patientid: id,
      });
      if (result != null) {
        setChatData(result.finalOutput);
        setChatHeader(result.chatheaderoutput);
        setChatEnableDetails(result.chatenablesettings);
        if (result?.chatenablesettings?.currencyname == "USD") {
          setDoctorCurrency("$");
        } else {
          setDoctorCurrency(result?.chatenablesettings?.currencyname);
        }
      } else {
      }
      //dismissLoader()
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return moment(dateString).format("Do MMM, YYYY");
  };
  const formatTime = (time) => {
    if (!time) {
      return "";
    }

    const [hours, minutes] = time.split(":");

    const date = new Date();
    date.setHours(hours, minutes);

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };
  const appointmentTime = ChatHeader && ChatHeader.appointmenttime;
  const formattedAppointmentTime = formatTime(appointmentTime);

  const handlemessageKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.altKey) {
        // Allow line break with Alt+Enter
        e.preventDefault();
        //console.log(e)
        document.execCommand("insertLineBreak");
      } else {
        // Prevent default behavior (line break) and send the message
        e.preventDefault();
        sendmessage();
      }
    }
  };

  const handleInputChange = (e) => {
    if (messageRef.current) {
      //const value = messageRef.current.textContent;
      setChatInputHeight(Math.min(Math.max(e.target.scrollHeight, 80), 100));
      //setIsEmpty(value.trim() === '');
    }
  };

  const handleInput = (e) => {
    setMessageValue(e.target.value);
  };

  const messagetext = (e) => {
    setDoctorMessage(e.target.value);
  };
  const sendmessage = async () => {
    try {
      //showLoader()
      //const value = messageRef.current.textContent;
      const todayDateTimeUTC = moment.utc();
      const todayDateTimeLocal = todayDateTimeUTC.local();
      const formattedTime = todayDateTimeLocal.format("hh:mm A");
      const fullDateTime = todayDateTimeLocal.format();
      if (messageValue === "") {
        // showErrorToast("Please type something")
        showErrorToast("Please Enter a message");
      } else {
        let newMessage;
        if (isVirtualVisit) {
          newMessage = {
            doctorid: doctorId,
            patientid: patientId,
            appointmentid: appointmentId,
            isvirtualvisit: 1,
            chat: messageValue,
            ispatient: "0",
            chattype: "text",
            formattedtime: formattedTime,
            createddate: fullDateTime,
          };
        } else {
          newMessage = {
            doctorid: doctorId,
            patientid: patientId,
            chat: messageValue,
            ispatient: "0",
            chattype: "text",
            formattedtime: formattedTime,
            createddate: fullDateTime,
          };
        }

        const result = await AddMessage(newMessage);
        //console.log(result)
        if (result) {
          // Update the chat state with the new message
          setChatData((prevChats) => {
            const updatedChats = prevChats
              ? [...prevChats, newMessage]
              : [newMessage];
            return updatedChats;
          });
          // setDoctorMessage("");
          lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
          scrollToBottom();
          messageRef.current.value = ""; // Clear the input after sending
          setMessageValue("");
          if (messageRef.current.value == "") {
            messageRef.current.style.height = chatInputHeight;
          }

          //setIsEmpty(true);
          // setIsFocused(false); // Reset focus state
        } else {
          console.error("Failed to send message. Result is null.");
        }
      }
      //dismissLoader()
    } catch (error) {
      console.error("Error in home:", error);
    }
  };
  //console.log(messageValue,"...")
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  useLayoutEffect(() => {
    scrollToBottom();
  }, [chatsdata, id]);

  const handleuploadImageAttachment = () => {
    document.getElementById("uploadimage").click();
  };
  const uploadimages = async (e) => {
    e.preventDefault();
    showLoader();
    if (e.target.files.length == 0) {
      dismissLoader();
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const result = await uploadImageCommonFunction(file, file.name);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const documenturl = result.name;

    if (result) {
      var fileSize = file.size / 1024;
      var docSize = fileSize.toFixed(2);
      if (file.size > maxSizeInBytes) {
        showErrorToast("File size exceeds 5MB. Please select a smaller file.");
      } else if (!allowedTypes.includes(file.type)) {
        showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG");
      } else {
        try {
          const result = await AddMessage({
            doctorid: doctorId,
            patientid: patientId,
            ispatient: "0",
            chattype: "image",
            chat: documenturl,
          });
          if (result != null) {
            fetchchatdata(patientid);
            setDoctorMessage("");
            lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
          } else {
          }
          //dismissLoader()
        } catch (error) {
          console.error("Error in home:", error);
        }
      }
    }
    e.target.value = "";
    dismissLoader();
  };

  const handleuploadDocumentAttachment = () => {
    document.getElementById("uploaddocument").click();
  };
  const uploaddocument = async (e) => {
    e.preventDefault();
    showLoader();
    if (e.target.files.length == 0) {
      dismissLoader();
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const result = await uploadImageCommonFunction(file, file.name);
    const allowedTypes = ["application/pdf"];
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const documenturl = result.name;

    if (result) {
      var fileSize = file.size / 1024;
      var docSize = fileSize.toFixed(2);
      if (file.size > maxSizeInBytes) {
        showErrorToast("File size exceeds 5MB. Please select a smaller file.");
      } else if (!allowedTypes.includes(file.type)) {
        showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG");
      } else {
        try {
          const result = await AddMessage({
            doctorid: doctorId,
            patientid: patientId,
            ispatient: "0",
            chattype: "document",
            chat: documenturl,
          });
          if (result != null) {
            fetchchatdata(patientid);
            setDoctorMessage("");
            lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
          } else {
          }
          //dismissLoader()
        } catch (error) {
          console.error("Error in home:", error);
        }
      }
    }
    e.target.value = "";
    dismissLoader();
  };

  const renderDate = (date) => {
    const messageDate = moment(date);
    const currentDate = moment();

    if (messageDate.isSame(currentDate, "day")) {
      return "Today";
    }

    if (messageDate.isSame(currentDate.subtract(1, "days"), "day")) {
      return "Yesterday";
    }

    return messageDate.format("DD/MM/YYYY");
  };

  function parseDateString(dateString) {
    let dateParts = "";
    if (dateString) {
      dateParts = dateString.replace(/[\.,]/g, "").split(" ");
    }
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0]);
      const month = dateParts[1];
      const year = parseInt(dateParts[2]);

      const monthIndex = new Date(`${month} 1, ${year}`).getMonth() + 1;
      const formattedDate = `${year}-${monthIndex < 10 ? "0" + monthIndex : monthIndex}-${day < 10 ? "0" + day : day}`;
      return formattedDate;
    }
    return dateString;
  }

  const RequestpaymentForPatient = async () => {
    let input = {
      patientid: patientid,
      doctorid: "",
      paymentrequested: "1",
      sessionstatus: "0",
    };
    showLoader();
    try {
      const result = await requestPayment(input);
      requestpaymentmodal.current.closeModal();
      dismissLoader();
      fetchchatdata(patientid);
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  const scrollLeft = useRef(0);

  const scrollRef = useRef(null);

  // Mouse dragging logic
  const isDragging = useRef(false);
  const startX = useRef(0);

  const handleMouseDown = (event) => {
    isDragging.current = true;
    startX.current = event.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDragging.current = false;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (event) => {
    if (!isDragging.current) return;
    event.preventDefault();
    const x = event.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5; // The multiplier increases the drag speed
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  return (
    <Box
      // w={{ lg: "calc(100vw - 38rem)", xl: "calc(100vw - 46rem)" }}
      bg="#ffffff"
      overflow="hidden"
    >
      {isLoading ? (
        <Box h={"100vh"}>{isLoading && <Loader miniLoader={true} />}</Box>
      ) : null}
      <Box flexDir="column" bg="#fcfcfd" w="100%" overflow="hidden">
        <Flex flexDir="column" h="calc(100vh - 75px)">
          <Box
            pos="sticky"
            top="0"
            p="1.5rem"
            display="flex"
            justifyContent="center"
            borderBottom="1px solid #E6E7E9"
            bg="white"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Box display="flex" flexDir="column">
                <Box
                  as="h2"
                  cursor="pointer"
                  //   onClick={openchatmedia}
                  color="#061027"
                  fontSize="1rem"
                  fontWeight="600"
                >
                  {ChatHeader && ChatHeader.patientname
                    ? ChatHeader.patientname
                    : "-"}
                </Box>
                <Box display="flex" alignItems="center" gap="0.25rem">
                  <Image src="/assets/svgs/home-calendar.svg" />
                  <Text
                    color="#384052"
                    fontSize="0.75rem"
                    textAlign="left"
                    fontWeight="500"
                  >
                    {`${formatDate(ChatHeader && ChatHeader.appointmentdate)} at ${formattedAppointmentTime}`}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            ref={chatContainerRef}
            overflowY="auto"
            p="24px"
            className="chat-section"
            h="calc(100vh - 75px)"
          >
            {chatsdata &&
              chatsdata.map((msg, index) => {
                const createdDate = moment(msg.createddate);
                const showDate =
                  index === 0 ||
                  !moment(chatsdata[index - 1].createddate).isSame(
                    msg.createddate,
                    "day"
                  );

                return (
                  <React.Fragment key={index}>
                    {showDate && (
                      <Text
                        borderRadius="full"
                        p="4px 12px"
                        w="90px"
                        my="10px"
                        textAlign="center"
                        zIndex="9"
                        mx="auto"
                        pos="sticky"
                        top="0"
                        bg="white"
                        fontSize="10px"
                        fontWeight="600"
                        border="1px solid #E6E7E9"
                      >
                        {renderDate(createdDate)}
                      </Text>
                      // <Text fontSize="12px" fontWeight="bold" color="#6A707D" textAlign="center" mb="4px">

                      // </Text>
                    )}
                    <Stack
                      direction="row"
                      justifyContent={
                        msg.ispatient && msg.ispatient === 1 ? "start" : "end"
                      }
                    >
                      <Box>
                        <Box
                          bg={
                            msg.ispatient && msg.ispatient === 1
                              ? "#E6E7E980"
                              : msg.chattype === "image"
                                ? ""
                                : "#061027"
                          }
                          maxW={msg.chattype === "image" ? "13.75rem" : "25rem"}
                          maxH={msg.chattype === "image" ? "13.75rem" : " "}
                          wordWrap="break-word"
                          p={msg.chattype === "document" ? "2px" : "12px 16px"}
                          w="fit-content"
                          borderRadius={
                            msg.ispatient && msg.ispatient === 1
                              ? "10px 10px 10px 1px"
                              : "10px 10px 1px 10px"
                          }
                        >
                          {msg.chattype === "image" ? (
                            <Card
                              w="13rem"
                              h="13rem"
                              overflow="hidden"
                              p="0"
                              justifyContent="center"
                            >
                              <Image
                                src={`${msg.imagepath}${msg.chat}`}
                                onClick={() =>
                                  window.open(
                                    msg.imagepath + msg.chat,
                                    "_blank"
                                  )
                                }
                                objectFit="cover"
                                borderRadius={
                                  msg.ispatient && msg.ispatient === 1
                                    ? "10px 10px 10px 1px"
                                    : "10px 10px 1px 10px"
                                }
                              />
                            </Card>
                          ) : msg.chattype === "document" ? (
                            <Card w="285px" h="194px" overflow="hidden" p="0">
                              <Box h="60%" mx="auto">
                                <Image
                                  src="/assets/imgs/pdf-image.jpeg"
                                  alt="Document"
                                  onClick={() =>
                                    window.open(
                                      msg.imagepath + msg.chat,
                                      "_blank"
                                    )
                                  }
                                  cursor="pointer"
                                  w="140px"
                                  h="125px"
                                />
                              </Box>
                              <CardFooter
                                h="40%"
                                justify="space-between"
                                bg={
                                  msg.ispatient && msg.ispatient === 1
                                    ? "#EBECED"
                                    : "#061027"
                                }
                                flexWrap="wrap"
                              >
                                <Box
                                  display="flex"
                                  w="100%"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box>
                                    <Text
                                      fontSize="14px"
                                      fontWeight="600"
                                      color={
                                        msg.ispatient && msg.ispatient === 1
                                          ? "#384052"
                                          : "#E6E7E9"
                                      }
                                    >
                                      Document.pdf
                                    </Text>
                                  </Box>
                                  <Box
                                    cursor="pointer"
                                    onClick={() =>
                                      downloadDocument(
                                        msg.imagepath + msg.chat,
                                        "Document.pdf"
                                      )
                                    }
                                    boxSize="36px"
                                    borderRadius="full"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bg={
                                      msg.ispatient && msg.ispatient === 1
                                        ? "white"
                                        : "#0B1F4F"
                                    }
                                  >
                                    <Image
                                      src={
                                        msg.ispatient && msg.ispatient === 1
                                          ? "/assets/imgs/download.png"
                                          : "/assets/imgs/download-white.png"
                                      }
                                    />
                                  </Box>
                                </Box>
                              </CardFooter>
                            </Card>
                          ) : (
                            <Text
                              color={msg.ispatient === 1 ? "#061027" : "white"}
                              fontSize="14px"
                              fontWeight="500"
                            >
                              {msg.chat ? msg.chat : ""}
                            </Text>
                          )}
                        </Box>
                        <Box
                          as="span"
                          fontSize="10px"
                          my="3px"
                          fontWeight="500"
                          color="#6A707D"
                          float={
                            msg.ispatient && msg.ispatient === 1
                              ? "left"
                              : "right"
                          }
                        >
                          {msg.formattedtime ? msg.formattedtime : ""}
                        </Box>
                      </Box>
                    </Stack>
                  </React.Fragment>
                );
              })}
            {/* Video Call Box */}
            {/* <Box maxW="345px" boxShadow="0px 8px 20px 0px #E6E7E966" bg="white" border="1px solid #E6E7E9" borderRadius="12px" p="16px">
                            <Flex gap="15px">
                            <Box>
                                <Image borderRadius="full" w="40px" src="/assets/imgs/no-image.png" />
                            </Box>
                            <Box display="flex" flexDir="column" textAlign="left">
                                <Text fontSize="14px" fontWeight="500" color="#061027">Join video consulation with</Text>
                                <Text fontSize="14px" fontWeight="500" color="#061027">Dr. Samantha Raulf</Text>
                                <Text fontSize="12px" fontWeight="500" color="#384052">10m 30s</Text>
                            </Box>
                            </Flex>
                            <PrimaryButton btnStyle={{m: "0", mt: "10px", boxShadow:"0px 8px 20px 0px #E6E7E966"}}  btnIcon="/assets/imgs/videocam.png" buttonText="Join call"/>
                            <PrimaryButton isDisabled={true} btnStyle={{m: "0", mt: "10px", boxShadow:"0px 8px 20px 0px #E6E7E966", _disabled: {color: "#898D97", bg: COLORS.BTN_DISABLE,_hover: {bg: COLORS.BTN_DISABLE}}}}  btnIcon="/assets/imgs/videocam.png" btnDisabledIcon="/assets/imgs/videocam-gray.png" buttonText="Call ended"/>
                        </Box> */}
          </Box>
          {/* <TypingLoader /> */}
          <Spacer />

          {/* Questions */}
          <Box
            className="buttonContainer"
            display="flex"
            gap="10px"
            bg="none"
            p="5px 24px"
            minH="55px"
            overflowX="auto"
            overflowY="hidden"
            whiteSpace="nowrap"
            ref={scrollRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {/* {ChatEnableDetails && ChatEnableDetails != null && ChatEnableDetails.paymentrequested != 1 && */}
            <Box
              onClick={() => requestpaymentmodal.current.openModal()}
              bg="#E6E7E9"
              as="button"
              border="1px solid #A5A5A6"
              borderRadius="full"
              p="8px 12px"
              whiteSpace="nowrap"
              color="#0B0B0D"
              fontSize="12px"
              fontWeight="600"
              h="35px"
            >
              Request payment
            </Box>
            {/* } */}
            {/* {ChatEnableDetails &&
              ChatEnableDetails != null &&
              ChatEnableDetails.sessionstatus == 1 &&
              ChatEnableDetails.paymentstatus == 1 && (
                <Box
                  onClick={() => disablechatmodal.current.openModal()}
                  bg="#E6E7E9"
                  display="flex"
                  alignItems="center"
                  as="button"
                  border="1px solid #A5A5A6"
                  borderRadius="full"
                  p="8px 12px"
                  whiteSpace="nowrap"
                  color="#0B0B0D"
                  fontSize="12px"
                  fontWeight="600"
                  h="35px"
                >
                  Disable Chat
                  <Switch
                    pl="3"
                    colorScheme="green"
                    size="md"
                    isChecked={true}
                    onChange={() => disablechatmodal.current.openModal()}
                  />
                </Box>
              )} */}
          </Box>
          {ChatEnableDetails &&
            ChatEnableDetails != null &&
            ChatEnableDetails.paymentrequested != 1 && (
              <Box p="0px 0px 2px 20px">
                <Text textAlign="left" fontSize="10px">
                  *Patient chat is disabled. Please request the payment.
                </Text>
              </Box>
            )}
          {ChatEnableDetails &&
            ChatEnableDetails != null &&
            ChatEnableDetails.paymentrequested == 1 &&
            ChatEnableDetails.sessionstatus == 0 && (
              <Box p="0px 0px 2px 24px">
                <Text textAlign="left" fontSize="10px">
                  *The payment is requested and pending for the patient to
                  complete.
                </Text>
              </Box>
            )}

          {/* Attachments */}
          <Box
            bg="white"
            minH="80px"
            maxH="7.5rem"
            borderTop="1px solid #E6E7E9"
          >
            <Flex
              w="100%"
              p="1rem 1.25rem"
              gap="15px"
              justifyContent="center"
              alignItems="center"
              maxH="8rem"
            >
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      as="button"
                      isActive={isOpen}
                      disabled={
                        actionAccess && !actionAccess.create ? true : false
                      }
                    >
                      <Box
                        as="button"
                        p="12px 18px"
                        w="3rem"
                        h="3rem"
                        bg="#E6E7E9"
                        display="flex"
                        alignItems="center"
                        border="1px solid #A5A5A6"
                        borderRadius="full"
                      >
                        <Image
                          w="11px"
                          opacity={
                            actionAccess && !actionAccess.create ? "0.25" : ""
                          }
                          src={
                            isOpen
                              ? "/assets/svgs/close.svg"
                              : "/assets/svgs/attach_file.svg"
                          }
                        />
                      </Box>
                    </MenuButton>
                    <MenuList
                      p="2px"
                      minW="160px"
                      mr="25px"
                      zIndex="overlay"
                      boxShadow="0px 8px 24px 0px #B4B4B452"
                    >
                      <MenuItem
                        onClick={handleuploadDocumentAttachment}
                        fontSize="0.875rem"
                        fontWeight="500"
                        color="#061027"
                        sx={{ _hover: { bg: "#E4E4E7" } }}
                      >
                        <Input
                          type="file"
                          accept=".pdf,.txt"
                          hidden
                          id="uploaddocument"
                          onChange={(e) => uploaddocument(e)}
                        />
                        <Box
                          border="1px solid #E6E7E9"
                          onClick={handleuploadDocumentAttachment}
                          id="uploaddocument"
                          w="1.75rem"
                          h="1.75rem"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="5px"
                          borderRadius="full"
                          p="5px"
                        >
                          <Image src="/assets/svgs/upload-document.svg" />
                        </Box>
                        Documents
                      </MenuItem>

                      <MenuItem
                        onClick={handleuploadImageAttachment}
                        fontSize="0.875rem"
                        fontWeight="500"
                        color="#061027"
                        sx={{ _hover: { bg: "#E4E4E7" } }}
                      >
                        <Input
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          hidden
                          id="uploadimage"
                          onChange={(e) => uploadimages(e)}
                        />
                        <Box
                          onClick={handleuploadImageAttachment}
                          id="uploadimage"
                          border="1px solid #E6E7E9"
                          w="1.75rem"
                          h="1.75rem"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="5px"
                          borderRadius="full"
                          p="5px"
                        >
                          <Image src="/assets/svgs/upload-image.svg" />
                        </Box>
                        Images
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
              <GPUChatAudioManager
                transcriber={activeTranscriber}
                setPlaceholder={setPlaceholder}
                setTyping={setTyping}
              />
              <Box w="100%">
                {/* <InputGroup> */}
                <Box
                  tabIndex="-1"
                  w="100%"
                  bg="#FCFCFD"
                  border="1px solid #E6E7E9"
                  borderRadius="1rem"
                  display="flex"
                  alignItems="flex-end"
                >
                  {/* <Box
                            key={activechatbox} // Use active chatbox ID as key
                            p={2}
                            bg="#FCFCFD"
                            borderRadius="1rem"
                            minHeight="3rem"
                            maxHeight="6.25rem"
                            overflow="hidden"
                            userSelect="text"
                            w="100%"
                            whiteSpace="pre-wrap"
                            wordBreak="break-word"
                            role="textbox"
                            contentEditable = {actionAccess && (!actionAccess.create) ? false : true}
                            spellCheck="true"
                            ref={messageRef}
                            aria-label="Type a message"
                            tabIndex={10}
                            display="flex"
                            flexDirection="column"
                            _focus={{ boxShadow: "none", outline: "none" }}
                            onKeyDown={handlemessageKeyPress}
                            onInput={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            color={!isFocused && isEmpty ? '#888888' : '#061027'}

                          cursor={ actionAccess && (!actionAccess.create) ? "not-allowed" : "text"} // Change cursor if disabled
                           >
                             {!isFocused && isEmpty && (
                                <span style={{ pointerEvents: 'none', color: '#888888' }}>
                                  Type a message...
                                </span>
                              )}
                          </Box> */}
                  <Textarea
                    key={activechatbox}
                    ref={messageRef}
                    value={messageValue}
                    onKeyDown={handlemessageKeyPress}
                    onInput={handleInputChange}
                    className="chatInput"
                    onChange={handleInput}
                    // height={chatInputHeight}
                    minH="unset"
                    maxH="100px"
                    borderRadius="1rem"
                    overflowY="auto"
                    border="none"
                    resize="none"
                    // color={!isFocused && isEmpty ? '#888888' : '#061027'}
                    _focus={{ boxShadow: "none", outline: "none" }}
                    placeholder={placeholder}
                    disabled={
                      typing || (actionAccess && !actionAccess.create)
                        ? true
                        : false
                    }
                  />
                  {/* <Box as="button"  disabled={actionAccess && (!actionAccess.create ) ? true : false} onClick={sendmessage}  
                          bg={actionAccess && (!actionAccess.create) ? "#E6E7E9" : "#0B0B0D"} w="2rem" h="2rem" borderRadius="full" 
                          position="unset" bottom="8px" right="-8px" p="10px" mr="10px" mb="8px" userSelect="none" contentEditable="false">
                            <Image src="/assets/svgs/send-button.svg" /> 
                          </Box> */}
                  <Box
                    as="button"
                    disabled={
                      actionAccess && !actionAccess.create ? true : false
                    }
                    onClick={sendmessage}
                    bg={
                      actionAccess && !actionAccess.create
                        ? "#E6E7E9"
                        : "#0B0B0D"
                    }
                    w="2rem"
                    h="2rem"
                    borderRadius="full"
                    p="10px"
                    m="5px 10px 5px 5px"
                    userSelect="none"
                  >
                    <Image src="/assets/svgs/send-button.svg" />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <DynamicModal
        ref={requestpaymentmodal}
        modalHeader="Request Payment"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to request payment of&nbsp;
                <strong>
                  {ChatEnableDetails && ChatEnableDetails.fee
                    ? "$" + formatToDecimal(ChatEnableDetails.fee)
                    : ""}
                </strong>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              onClick={RequestpaymentForPatient}
              buttonText="Request"
            />
          </>
        }
      />
    </Box>
  );
};

export default GPUMessages;
