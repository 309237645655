import { getBranchId } from "../../../auth";
import FetchData from "../../client";
import formapimodalvitals from "../../domains/vitalcharts/vitalcharts";

const FetchVitalsData = async (inputData, type) => {
  try {
    //console.log(inputData)
    inputData.branchid = getBranchId();
    const result = await FetchData(
      "midoc_getpatientvitalsfordoctor",
      inputData
    );
    console.log(result, "vitals db res");
    if (result.output.data) {
      var finalOutput = formapimodalvitals(result.output.data, type);
    }
    return finalOutput;
  } catch (error) {
    console.error("Error fetching data in forms.js:", error);
    throw error;
  }
};

export { FetchVitalsData };
