import React,{useEffect,useState} from "react";
import { COLORS } from "../../components/styles/colors";
import { Box, Center, Divider, Image, Text } from "@chakra-ui/react";
import { PrimaryButton, Toaster } from "../../components";
import { getProfileforhome} from "../../middleware/services/profile";
import { redirect, useLocation, useNavigate } from "react-router-dom";

const VerificationSuccess = () => {
  const [doctorphoto, setDoctorphoto] = useState("")
  const navigate = useNavigate();
  const date = new Date();
const inputForGet={
  
}
useEffect(() => {
  const getDoctorProfile = async () => {
    try {
      var result = await getProfileforhome(inputForGet);
      if (result && result.ProfileOutput) {
        setDoctorphoto(result.ProfileOutput)
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
   getDoctorProfile();
}, []);
const navigatetohome = ()=>{
  navigate("/finalsteps")
}

  return (
    <>
      <Box
        w="100vw"
        h="100vh"
        bg="#0B0B0D"
        bgImage="url('/assets/imgs/login-bg.png')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        
        <Box
          bgImage="url('/assets/imgs/login-bg.png')"
          boxShadow={COLORS.BOX_SHADOW_SUCCESS}
          bgPosition="center"
          bg="#061027"
          bgRepeat="no-repeat"
          bgSize="cover"
        ></Box>
        <Center
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box mb="50px">
            <Image src="/assets/imgs/midoc-logo.png" />
          </Box>
          <Box
            bg="#151619"
            border="1px solid #232325"
            borderRadius="12px"
            display="flex"
            justifyContent="center"
            flexDir="column"
            w="400px"
            p="20px"
          >
            <Box position="relative" p="3px" bgGradient="linear(to-b, transparent 50%, #14C585 100%)" display="flex" alignItems="center" justifyContent="center" borderRadius="full" mx="auto" w="5.25rem" h="5.25rem">
                <Image
                    borderRadius="full"
                    boxSize="5rem"
                    src={doctorphoto && doctorphoto.profilepictureurl? doctorphoto.imagepath + doctorphoto.profilepictureurl:"/assets/imgs/no-image.png"}
                    alt="Profile Picture"
                />
                <Box position="absolute" bottom="0" right="0" transform="translate(-120%, 40%)">
                    <Image src="/assets/svgs/verified-green.svg" boxSize="1.5rem" />
                </Box>
            </Box>

            <Box
              as="h1"
              color="white"
              fontSize="24px"
              textAlign="center"
              fontWeight="600"
              mt="20px"
            >
              Welcome to midoc!
            </Box>
            <Box color="white" textAlign="center" fontWeight="400">
              <Text fontSize="14px" fontWeight="400" color="#E6E7E9">
              {/* Your verification has been completed and you  <br /> can now start customising your profile. */}
              Customize your profile to get started.
              </Text>
            </Box>
            <Divider my="20px" />
            <Box>
              <PrimaryButton
                btnStyle={{
                  bg: "#FCFCFD",
                  color: "#061027",
                  fontSize: "14px",
                  fontWeight: "600",
                  boxShadow: "0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset",
                  _hover: {bg: COLORS.PRIMARY_WHITE}}}
                buttonText="Lets get started"
                onClick={navigatetohome}
              />
            </Box>
          </Box>
        <Box as="p" my="2rem" fontSize="14px" color="#898D97">
          © {date.getFullYear()} midoc, Inc.
        </Box>
        </Center>
        <Box
          position="absolute"
          bottom="0"
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="20px"
          gap="10px"
        >
          <Text
            fontSize="10px"
            fontWeight="500"
            letterSpacing="1px"
            color="#898D97"
          >
            A PRODUCT OF
          </Text>
          <Image src="/assets/svgs/tci-logo.svg" />
        </Box>
      </Box>
    </>
  )
}

export default VerificationSuccess