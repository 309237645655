import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Image,
  Textarea,
} from "@chakra-ui/react";
import { styles, variantStyles } from "./styles";
import { inputDesign } from "../styles/theme";
import React,{useEffect, useState} from "react";
import { COLORS } from "../styles/colors";

const PrimaryInput = ({
  label,
  inputType,
  inputPlace,
  inputStyleCss,
  inputValue,
  onChange,
  onClick,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  variant,
  defaultValue,
  inputCss,
  inputLabelCss,
  handleFileSelection,
  isDisabled,
  disableFileInput,
  uploadText,
  iconImg,
  docBtnWidth = "100%",
  docBtnColor = "black",
  id,
  maxLength,
  wrap = false,
  boolProp = false,
  inputIdProp,
  autocomplete,
  iconImgCss,
  autofocus,
  stepProp,
  inputCssStyle,
  onFocus,
  ...props
}) => {
  const variantStyle = variantStyles[variant] || variantStyles.defaultSide;
  const isFileInput = inputType === 'file';
  const [initialRender , setInitialRender] = useState(true)
  

useEffect(() => {
  let element = document.getElementById(inputIdProp);
  if (!initialRender && element) {
    element.focus();
    setTimeout(() => {
      element.focus();
    }, 10);
  } else if (autofocus && element) {
    element.focus();
  }
  setInitialRender(false);
}, [boolProp, autofocus, inputIdProp]);

  return (
    <FormControl sx={{...inputDesign.formStyles.container,...inputStyleCss}} {...inputStyleCss}>
      {isFileInput ? (
        <InputGroup
          boxShadow="0px 1px 3px #E6E7E9"
          borderRadius="10px"
          width={docBtnWidth}
          cursor="pointer"
        >
          <InputRightElement w="100%" h="100%">
            <Image src={iconImg} pos="relative" left="10px" pr="10px" {...iconImgCss} />
            <Box
              as="button"
              fontWeight="600"
              h="100%"
              color={docBtnColor}
              width="100%"
              bg="none"
              onClick={() => document.getElementById(id).click()}
              sx={{ _hover: { bg: "none" } }}
              disabled={disableFileInput}
              isDisabled={isDisabled}
            >
              {uploadText}
            </Box>
            <input
              type="file"
              id={id}
              style={{ display: "none" }}
              onChange={handleFileSelection}
            />
          </InputRightElement>
          <Input
            type="text"
            background="#FCFCFD"
            boxShadow="0px 1px 3px #E6E7E9"
            sx={{
              _hover: {
                backgroundImage:
                  "-webkit-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "-moz-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "-ms-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "-o-linear-gradient(top, #FCFCFD, #E6E7E9)",
                backgroundImage: "linear-gradient(to bottom, #FCFCFD, #E6E7E9)",
              },
              _disabled: {
                bg: COLORS.BTN_DISABLE,
                color: COLORS.BTN_DISABLE_TEXT,
                cursor: "not-allowed",
              },
            }}
            border="none"
            height="48px"
            borderRadius="10px"
            value={inputValue}
            placeholder={inputPlace}
            readOnly // Make the text field read-only for file type
          />
        </InputGroup>
      ) : wrap ? (
        <Textarea
          sx={{
            ...styles,
            ...variantStyle,
            ...inputDesign.formStyles.input,
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
          value={inputValue}
          defaultValue={defaultValue}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          placeholder=" "
          errorBorderColor="red.300"
          isDisabled={isDisabled}
          maxLength={maxLength}
          {...inputCss}
        />
      ) : (
        <Input
        id={inputIdProp ? inputIdProp : null}
          type={inputType}
          // step is to accept decimals , when input tpe is number  - step should be "any"
          step={stepProp}
          sx={{ ...styles, ...variantStyle, ...inputDesign.formStyles.input, ...inputCssStyle }}
          value={inputValue}
          defaultValue={defaultValue}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          placeholder=" "
          errorBorderColor="red.300"
          isDisabled={isDisabled}
          maxLength={maxLength}
          autoComplete={autocomplete}
          autoFocus={autofocus}
          onFocus={onFocus}
          {...inputCss}
          _placeholder={{whiteSpace:'nowrap'}}
        />
      )}
      <FormLabel whiteSpace='nowrap' sx={{ ...inputDesign.formStyles.label, ...inputLabelCss }}>
        {label || inputPlace}
      </FormLabel>
    </FormControl>
  );
};

export default PrimaryInput;
