import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import PrimaryButton from "../primary-button";
import DropdownInput from "../dropdown";
import DynamicModal from "../dynamic-modal";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { FetchUsersByClinic, updateAssignVisit } from "../../middleware/services/home";
import Toaster from "../toaster";
import {useNavigate } from "react-router-dom";

const AssignVisit = ({ appointmentId }) => {
  const reAssignModal = useRef();
  const [DoctorList, setDoctorList] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const { isLoading, showLoader, dismissLoader } = useLoader();
   const { showSuccessToast, showErrorToast } = Toaster();
   const navigate = useNavigate()

  // Fetch the users and group them by role
  useEffect(() => {
    GetUsersList();
  }, []);

  const GetUsersList = async () => {
    const result = await FetchUsersByClinic({
      doctorid: "",
      appointmentid: appointmentId,
    });

    if (result.output.result === "success") {
      const fetchedDoctors = result.output.data;
      setDoctorList(fetchedDoctors);
      groupDoctorsByRole(fetchedDoctors);
    }
    reAssignModal.current.openModal();
  };

  // const groupDoctorsByRole = (doctors) => {
  //   const groupedData = doctors.reduce((acc, doctor) => {
  //     const roleName = doctor.rolename || "Unknown Role"; // Fallback if no roleName
  //     if (!acc[roleName]) {
  //       acc[roleName] = {
  //         roleId: doctor.roleid || null, // Use roleid from API
  //         names: [],
  //         assignedTo: doctor.assignto || "",
  //         userid: doctor.doctorid || "",
  //         status: doctor.statusname || "Pending" // Default to the first assigned user for that role
  //       };
  //     }
  
  //     acc[roleName].names.push(doctor.fullname); // Push only the name into the group
  //     return acc;
  //   }, {});
  
  //   const transformedRolesData = Object.entries(groupedData).map(
  //     ([roleName, roleData]) => ({
  //       roleId: roleData.roleId, // Use roleid from API
  //       roleName: roleName,
  //       assignedTo: roleData.assignedTo, // Set the assignedTo value
  //       userID: roleData.userid, // Set the userID value
  //       status: roleData.status, // Status can be updated dynamically later
  //       options: roleData.names, // Direct array of names for the dropdown
  //     })
  //   );
  
  //   setRolesData(transformedRolesData);
  // };
  const groupDoctorsByRole = (doctors) => {
    // Group users by role
    const groupedData = doctors.reduce((acc, doctor) => {
      const roleName = doctor.rolename || "Unknown Role"; // Fallback if no roleName
      if (!acc[roleName]) {
        acc[roleName] = {
          roleId: doctor.roleid || null, // Use roleid from API
          names: [],
          assignedTo: "", // Default to empty
          status: "", // Default to empty
        };
      }
  
      // Add the user to the dropdown options
      acc[roleName].names.push(doctor.fullname);
  
      // If the user is assigned and status is available, set them
      if (doctor.assignedto === doctor.fullname) {
        acc[roleName].assignedTo = doctor.fullname;
        acc[roleName].status = doctor.statusname || "Pending";
      }
  
      return acc;
    }, {});
  
    // Transform the grouped data into the required rolesData format
    const transformedRolesData = Object.entries(groupedData).map(
      ([roleName, roleData]) => ({
        roleId: roleData.roleId, // Use roleid from API
        roleName: roleName,
        assignedTo: roleData.assignedTo, // Set the assignedTo value dynamically
        status: roleData.status, // Set the status dynamically
        options: roleData.names, // Direct array of names for the dropdown
      })
    );
  
    setRolesData(transformedRolesData);
  };
  
  

  // Handler to update assignment
  const handleAssigneeChange = (roleId, selectedOption) => {
    setRolesData((prev) =>
      prev.map((item) => {
        if (item.roleId === roleId) {
          return {
            ...item,
            assignedTo: selectedOption, // Use selected option directly
            // status: "pending",
          };
        }
        return item;
      })
    );
  };

  // Submit the updated roles data
  const handleSubmit = async () => {
    // Transform the rolesData into the desired format
    const appointmentAssignedTo = rolesData
      .filter((role) => role.assignedTo) // Include only roles with `assignedTo`
      .map((role) => {
      // Find the selected user's ID from the dropdown options
      const selectedUser = DoctorList.find((doctor) => doctor.fullname === role.assignedTo);
  
      return {
        assignto: selectedUser?.doctorid || "", // Use the unique ID of the selected user or empty string
        rolename: role.roleName,
        roleid: role.roleId, // Use roleId coming from the API
      };
    });
  
    let input = {
        appointmentid: appointmentId,
        doctorid: "", // Add doctor ID if required
        appointmentassignedto: appointmentAssignedTo,
    };
  
    //console.log("Prepared Input:", input);
    try {
      showLoader();
      const result = await updateAssignVisit(input);
      console.log(result);
      if (result?.output.result === "success") {
       //window.location.reload();
       reAssignModal.current.closeModal();
       //navigate('/visits')
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in sendFollowUp:", error);
      showErrorToast("An error occurred while sending follow-up information");
    } finally {
      dismissLoader();
    }
    // Call the API or perform further actions with the input
  };
  return (
    <>
    {isLoading && <Loader/>}
    <DynamicModal
      ref={reAssignModal}
      modalWidth="40rem"
      modalHeader="Assign Appointment"
      modalBody={
        <Box maxH="500px" overflow="hidden auto" px="1rem">
          {rolesData.map((role) => {
            const isAssigned = Boolean(role.assignedTo);

            return (
              <Box key={role.roleId} mb={5}>
                {/* Heading & Assigned Info */}
                <Flex
                  justify="space-between"
                  align="center"
                  p={2}
                  bg="blue.50"
                  borderRadius="md"
                >
                  <Text fontWeight="bold">{role.roleName}</Text>
                  {isAssigned ? (
                    <Text>
                      {role.status === "Completed"
                        ? `Assigned to: ${role.assignedTo}`
                        : `Currently assigned to: ${role.assignedTo}`}
                      {role.status ? ` (${role.status})` : ""}
                    </Text>
                  ) : (
                    <Text>Not assigned to anyone</Text>
                  )}

                </Flex>

                {/* Dropdown for role */}
                <Box p={2} mt={1} borderRadius="md">
                  <DropdownInput
                    variant="fullSide"
                    placeholder={`List of ${role.roleName}`}
                    options={role.options} // Direct array of names
                    menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                    inputStyleCss={{ w: "100%" }}
                    defaultOption={role.assignedTo|| ""}
                    onClick={(selectedOption) => {
                      handleAssigneeChange(role.roleId, selectedOption);
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      }
      modalFooterBtn={
        <PrimaryButton buttonText="Submit" onClick={handleSubmit} />
      }
    />
    </>
  );
};

export default AssignVisit;
