import { Box, Checkbox, Divider, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Global, css } from "@emotion/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FetchDoctorClinics, getProfile } from "../../middleware/services/profile";
import { actionAccessMain, getDoctorName, GetDoctorProfilePhoto, getLoginID } from "../../auth";
import useLoader from "../loader/loaderstates";
import Loader from "../loader/loader";


const MiniSideBar = ({ clickAction = false, invokeOnClick = () => {} }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [DoctorAvailability, setDoctorAvailability] = useState("");
  
  const [disablePages, setDisablePages] = useState("");
  // const [actionAccess, setActionAccess] = useState("");
  const profilephoto = GetDoctorProfilePhoto();
  const doctorName = getDoctorName();

  const [defaultCheckedState, setDefaultCheckedState] = useState(null)

  useEffect(() => {
    //getDoctorProfile();
    let disablePages = JSON.parse(localStorage.getItem("pagesAccess"));
    setDisablePages(disablePages)
    // let actionAccessLocal = JSON.parse(localStorage.getItem("actionAccess"));
    // setActionAccess(actionAccessLocal)
    let doctorid = getLoginID();
    const branchIdFromLocal = localStorage.getItem("branchid");
    if (branchIdFromLocal) {

      setDefaultCheckedState(branchIdFromLocal)
    } else {

      setDefaultCheckedState(doctorid)
    }
  }, []);

  // const getDoctorProfile = async () => {
  //   try {
  //     const result = await getProfile({});
  //     if (result && result.ProfileOutput) {
  //       setDoctorAvailability(result.ProfileOutput);
  //     }
  //   } catch (error) {
  //     console.log(error, "Something went wrong in doctor profile");
  //   }
  // };


  const [clinicList, setClinicList] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const FetchClinicsList = async ()=>{
    try {
      var result = await FetchDoctorClinics({
       "type":"getbranch",
       "doctorid": getLoginID()
      });
      // console.log('FetchSpecialities: ', result)
      if (result != null) {
        const clinicsResult = result.map((item) => item.clinicname)
        setClinicList(clinicsResult)
        setClinicData(result)
        // setClinicList(result)
      }
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  const navigatetohome = (event) => {
    if (clickAction) {
      event.preventDefault();
      invokeOnClick("/home");
    } else {
      navigate("/home");
    }
  };

  const linkStyle = {
    textDecoration: "none",
    color: "white",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    position: "relative",
    width: "100%",
  };
  const disabledStyle = {
    pointerEvents: "none",  // Prevent interaction
    opacity: 0.5,           // Make it look disabled
    cursor: "not-allowed",  // Change cursor to 'not-allowed'
  };
  const activeLinkStyle = {
    ...linkStyle,
    _after: {
      content: '""',
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: "5px",
      height: "100%",
      backgroundColor: "green",
      borderRadius: "5px 0 0 5px",
    },
  };

  const handleNavLinkClick = (url, event) => {
    if (clickAction) {
      event.preventDefault();
      invokeOnClick(url);
    } else {
      navigate(url);
    }
  };

  function handleSwitchBranchDropDown (){
    FetchClinicsList();
  }
  function handleCheckboxChange (branchid){
    // showLoader();
    setDefaultCheckedState(branchid)
    localStorage.setItem("branchid" ,branchid)
    // setTimeout(() => {
      window.location.reload();
    //   dismissLoader();
    // }, 1000);
  }
  return (
    <>
    
    {isLoading && <Loader />}
      <Global
        styles={css`
          .active.activeLink .greenBorder::after {
            content: "";
            position: absolute;
            right: -9px;
            top: 50%;
            transform: translateY(-50%);
            background: #14c585;
            width: 5px;
            height: 100%;
            border-radius: 5px 0 0 5px;
            z-index: 1;
          }
        `}
      />
      <Box w="100%" bg="#0B0B0D" h="100vh" borderRight="1px solid #232325">
        <Image
          src="/assets/svgs/midoc-logo-icon.svg"
          p={{ md: ".875rem 1.5rem", xl: "1.5rem" }}
          onClick={(event) => navigatetohome(event)}
        />
        <Flex
          flexDir="column"
          gap={{ md: "10px", xl: "15px" }}
          h="calc(100% - 6rem)"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
        >
          <Box display='flex' gap={{ md: "10px", xl: "15px" }} flexDir='column'>
          {disablePages && !disablePages?.disablehome  && actionAccessMain(1)?.read ?
           <NavLink
           to="/home"
           className={({ isActive }) =>
             isActive ? "active activeLink" : "noActiveLink"
           }
           style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
           onClick={(event) => handleNavLinkClick("/home", event)}
         >
           <Box
             display="flex"
             gap={{ md: "3px", xl: "8px" }}
             w="100%"
             justifyContent="center"
             alignItems="center"
             flexDir="column"
           >
             <Image
               w={{ md: "0.875rem", xl: "1rem" }}
               src={
                 location.pathname == "/home"
                   ? "/assets/svgs/home-white.svg"
                   : "/assets/svgs/home.svg"
               }
             />
             <Text
               fontSize="0.625rem"
               fontWeight="500"
               color={location.pathname == "/home" ? "white" : "#E6E7E9"}
             >
               Dashboard
             </Text>
           </Box>
           <Box as="span" className="greenBorder"></Box>
         </NavLink>
         : null
         }
         
         {disablePages &&  !disablePages?.disablevisits && actionAccessMain(2)?.read ?
          <NavLink
            to="/visits"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/visits", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "1rem" }}
                src={
                  location.pathname == "/visits"
                    ? "/assets/svgs/visits-white.svg"
                    : "/assets/svgs/calendar-visits.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={location.pathname == "/visits" ? "white" : "#E6E7E9"}
              >
                Visits
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
           : null
          }

        { disablePages &&  !disablePages?.disablemessages && actionAccessMain(3)?.read ?
          <NavLink
            to="/messages"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/messages", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "1rem" }}
                src={
                  location.pathname == "/messages"
                    ? "/assets/svgs/messages-white.svg"
                    : "/assets/svgs/messages-filled.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={location.pathname == "/messages" ? "white" : "#E6E7E9"}
              >
                Messages
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        }

        {disablePages &&  !disablePages?.disablepatients && actionAccessMain(4)?.read ?
           <NavLink
            to="/patients"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/patients", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.9rem", xl: "1.15rem" }}
                filter="brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)"
                src="/assets/svgs/patient.svg"
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={location.pathname == "/patients" ? "white" : "#E6E7E9"}
              >
                Patients
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
           : null
          }
         {disablePages &&  !disablePages?.disableprescriptions && actionAccessMain(5)?.read ?
          <NavLink
            to="/prescriptions"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/prescriptions", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "1rem" }}
                filter={
                  location.pathname == "/prescriptions"
                    ? "brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)"
                    : "brightness(0) saturate(100%) invert(43%) sepia(21%) saturate(242%) hue-rotate(183deg) brightness(95%) contrast(88%)"
                }
                src={
                  location.pathname == "/prescriptions"
                    ? "/assets/svgs/prescriptions.svg"
                    : "/assets/svgs/prescriptions.svg"
                }
                color={location.pathname == "/medicines" ? "white" : "#E6E7E9"}
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={
                  location.pathname == "/prescriptions" ? "white" : "#E6E7E9"
                }
              >
                Prescriptions
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        }

        {disablePages &&  !disablePages?.disablemedicines && actionAccessMain(6)?.read ?
          <NavLink
            to="/medicines"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/medicines", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "14px" }}
                filter={
                  location.pathname == "/medicines"
                    ? "brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)"
                    : ""
                }
                src={
                  location.pathname == "/medicines"
                    ? "/assets/svgs/medication.svg"
                    : "/assets/svgs/medication.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={location.pathname == "/medicines" ? "white" : "#E6E7E9"}
              >
                Medicines
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        }

        {disablePages &&  !disablePages?.disablereports && actionAccessMain(7)?.read ?
          <NavLink
            to="/reports"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/reports", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "14px" }}
                src={
                  location.pathname == "/reports"
                    ? "/assets/svgs/report-white.svg"
                    : "/assets/svgs/reports-filled.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={location.pathname == "/reports" ? "white" : "#E6E7E9"}
              >
                Reports
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        }

        {disablePages &&  !disablePages?.disablenotifications && actionAccessMain(8)?.read ?
          <NavLink
            to="/notifications"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/notifications", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "1rem" }}
                src={
                  location.pathname == "/notifications"
                    ? "/assets/svgs/notifications-white.svg"
                    : "/assets/svgs/notifications-filled.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={
                  location.pathname == "/notifications" ? "white" : "#E6E7E9"
                }
              >
                Notifications
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        }
    <Menu placement="right-end">
      <MenuButton
        as={Box}
        cursor='pointer'
      >
        <Box sx={linkStyle} cursor='pointer'>
          <Box
            display="flex"
            gap={{ md: "3px", xl: "8px" }}
            w="100%"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
          >
            <Image
              w={{ md: "0.875rem", xl: "1rem" }}
              src="/assets/svgs/apps.svg"
              filter="invert(86%) sepia(0%) saturate(342%) hue-rotate(26deg) brightness(77%) contrast(88%)"
            />
            <Text
              fontSize="0.625rem"
              fontWeight="500"
              color={"#E6E7E9"}
            >
              Apps
            </Text>
          </Box>
        </Box>
        </MenuButton>
      <MenuList pos='relative' zIndex='99999' left='25px'>
      {disablePages &&  (!disablePages?.disableroles || !disablePages?.disableusers) ? (
        !disablePages?.disableroles ?(
          <NavLink to="/roles" target="_blank" >
          <MenuItem>User Management</MenuItem>
        </NavLink>
        ):(
          <NavLink to="/users" target="_blank" >
          <MenuItem>User Management</MenuItem>
        </NavLink>
        )
       
      ):<NavLink to="/roles" target="_blank" style={disabledStyle}>
        <MenuItem style={disabledStyle}>User Management</MenuItem>
      </NavLink>
      }
      { actionAccessMain(11)?.read ?
        <NavLink to="/inventory" target="_blank">
          <MenuItem>Inventory Management</MenuItem>
        </NavLink>
            : 
        <NavLink to="/inventory" target="_blank" style={disabledStyle}>
          <MenuItem style={disabledStyle}>Inventory Management</MenuItem>
        </NavLink>
      }
      {actionAccessMain(12)?.read || actionAccessMain(13)?.read ?
        <NavLink to="/billing" target="_blank">
          <MenuItem>Billing Management</MenuItem>
        </NavLink>
        : 
        <NavLink to="/billing" target="_blank" style={disabledStyle}>
          <MenuItem style={disabledStyle}>Billing Management</MenuItem>
        </NavLink>
       }
      </MenuList>
    </Menu>
  </Box>
        {/* {disablePages &&  !disablePages?.disabletotalrevenue && actionAccess && actionAccess[7]?.read ?
          <NavLink
            to="/totalrevenue"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/totalrevenue", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "1rem" }}
                src={
                  location.pathname == "/totalrevenue"
                    ? "/assets/svgs/total-revenue.svg"
                    : "/assets/svgs/total-revenue.svg"
                }
                filter={
                  "brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={
                  location.pathname == "/totalrevenue" ? "white" : "#E6E7E9"
                }
              >
                Total Revenue
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        } */}
        
        {/* {disablePages &&  !disablePages?.disableinvoices && actionAccess && actionAccess[12]?.read ?
          <NavLink
            to="/invoices"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/invoices", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w={{ md: "0.875rem", xl: "1rem" }}
                src={
                  location.pathname == "/invoices"
                    ? "/assets/svgs/notifications-white.svg"
                    : "/assets/svgs/notifications-filled.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={
                  location.pathname == "/invoices" ? "white" : "#E6E7E9"
                }
              >
                Invoices
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
        } */}

        {/* {disablePages &&  !disablePages?.disableroles && actionAccess && actionAccess[8]?.read ?
          <NavLink
            to="/roles"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/roles", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w="1.5rem"
                filter={
                  location.pathname == "/roles"
                    ? "brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)"
                    : "invert(73%) sepia(6%) saturate(40%) hue-rotate(201deg) brightness(87%) contrast(89%)"
                }
                src={
                  location.pathname == "/roles"
                    ? "/assets/svgs/roles-active.svg"
                    : "/assets/svgs/roles-inactive.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={
                  location.pathname == "/roles" ? "white" : "#E6E7E9"
                }
              >
                Roles
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
         : null
         }
          
          {disablePages &&  !disablePages?.disableusers && actionAccess && actionAccess[9]?.read ?
          <NavLink
            to="/users"
            className={({ isActive }) =>
              isActive ? "active activeLink" : "noActiveLink"
            }
            style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
            onClick={(event) => handleNavLinkClick("/users", event)}
          >
            <Box
              display="flex"
              gap={{ md: "3px", xl: "8px" }}
              w="100%"
              justifyContent="center"
              alignItems="center"
              flexDir="column"
            >
              <Image
                w="1.5rem"
                filter={
                  location.pathname == "/users"
                    ? "brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(7364%) hue-rotate(186deg) brightness(111%) contrast(97%)"
                    : "invert(73%) sepia(6%) saturate(40%) hue-rotate(201deg) brightness(87%) contrast(89%)"
                }
                src={
                  location.pathname == "/users"
                    ? "/assets/svgs/users-active.svg"
                    : "/assets/svgs/users-inactive.svg"
                }
              />
              <Text
                fontSize="0.625rem"
                fontWeight="500"
                color={
                  location.pathname == "/users" ? "white" : "#E6E7E9"
                }
              >
                Users
              </Text>
            </Box>
            <Box as="span" className="greenBorder"></Box>
          </NavLink>
          : null
          } */}

          {/* <Spacer /> */}

          {/* <NavLink
            to="/doctorprofile"
            style={{ position: "relative", bottom: "1.125rem" }}
            onClick={(event) => handleNavLinkClick("/doctorprofile", event)}
          >
            <Box borderRadius="full" bg="white" overflow="hidden">
              <Image
                w="2.5rem"
                h="2.5rem"
                objectFit="cover"
                src={
                  profilephoto? profilephoto: "/assets/imgs/no-image.png"
                }
              />
            </Box>
          </NavLink> */}

          <Box>
          <Menu placement="right-end">
            <MenuButton
              as={Box}
              cursor='pointer'
              onClick={handleSwitchBranchDropDown}

            >
              <Box sx={linkStyle} cursor='pointer'>

                <NavLink
                  to="/doctorprofile"
                  // style={{ position: "relative", bottom: "1.125rem" }}
                  onClick={(event) => handleNavLinkClick("/doctorprofile", event)}
                >
                  <Box borderRadius="full" bg="white" overflow="hidden">
                    <Image
                      w="2.5rem"
                      h="2.5rem"
                      objectFit="cover"
                      src={
                        profilephoto ? profilephoto : "/assets/imgs/no-image.png"
                      }
                    />
                  </Box>
                </NavLink>
              </Box>
            </MenuButton>
            <MenuList pos='relative' mb='12px' zIndex='99999' left='25px'>
              <MenuItem onClick={(event) => handleNavLinkClick("/doctorprofile", event)} >
                <Box borderRadius="full" bg="white" overflow="hidden"  >
                  <Image
                    w="2.5rem"
                    h="2.5rem"
                    objectFit="cover"
                    src={
                      profilephoto ? profilephoto : "/assets/imgs/no-image.png"
                    }
                  />
                </Box>
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  ml="8px"
                >
                  {doctorName}
                </Text>
              </MenuItem>
              <Divider my="0.5rem" />
              {clinicData && clinicData.map((item, index) => (
                <MenuItem key={index} onClick={() =>  handleCheckboxChange(item.branchid)}
                >
                  <Box display="flex" gap='0.875rem' justifyContent="space-between" width="100%" >
                    <Text>
                      {item?.clinicname}
                    </Text>
                    {/* <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      onChange={() =>
                        handleCheckboxChange(item.branchid)
                      }
                      defaultChecked={defaultCheckedState == item.branchid }
                      isChecked={defaultCheckedState == item.branchid}
                    ></Checkbox> */}
                    <Box display="flex" justifyContent="center" alignItems="center">
                    {defaultCheckedState == item.branchid &&
                    <Image src="/assets/imgs/check-black.png"/>
                    }
                    </Box>

                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default MiniSideBar;
