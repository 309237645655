import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Input,
  VStack,
  Text,
  HStack,
  Image,
  Spinner,
  Icon,
} from "@chakra-ui/react";
import { CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { useWebSocket } from "./connector";
import { useTranscriber } from "./audio/useTranscriber";
import { ChatAudioManager } from "./audio/chatVoiceManager";
import { BiMicrophoneOff } from "react-icons/bi";
import { IoMdSend } from "react-icons/io";
import { useGPUTranscriber } from "./audio/useGPUTranscriber";
import { GPUChatAudioManager } from "./audio/gpuChatVoiceManager";

const GenericChatbot = ({
  closeSocketCommand,
  resetCloseSocketCommand,
  doctorId,
}) => {
  const IS_WEBGPU_AVAILABLE = !!navigator.gpu;

  // Call both hooks unconditionally
  const cpuTranscriber = useTranscriber();

  // Choose which transcriber to use based on the condition
  // const activeTranscriber = IS_WEBGPU_AVAILABLE ? gpuTranscriber : transcriber;
  const activeTranscriber = cpuTranscriber;

  //-------------------------audio

  const [voiceInput, setVoiceInput] = useState("");

  const [placeholder, setPlaceholder] = useState("Ask Aira anything...");

  useEffect(() => {
    // console.log("triggered");
    if (activeTranscriber?.output?.text) {
      setInputValue(activeTranscriber.output.text);
      setPlaceholder("Ask Aira anything...");
      setTyping(false); // it was set true in chatvoicemanager
    }
    // if (!activeTranscriber?.output) {
    //   console.log(activeTranscriber?.output);
    // }
  }, [activeTranscriber?.output]);

  //-------------------------chatbot

  const [isOpen, setIsOpen] = useState(false);
  const [isSocketInitialized, setIsSocketInitialized] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [typing, setTyping] = useState(false); // Typing indicator state

  const {
    messages,
    llmCalls,
    sendMessage,
    closeConnection,
    initializeWebSocket,
  } = useWebSocket(
    `https://vm-midocai.eastus.cloudapp.azure.com:8080/generic-chatbot/ws/doctor-${doctorId}`,
    // `ws://127.0.0.1:8000/generic-chatbot/ws/doctor-${doctorId}`,
    () => {
      setIsInputDisabled(false);
    }
  );

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (closeSocketCommand) {
      console.log("visit interrupted");
      handleClose();
      resetCloseSocketCommand(); // Reset the command after handling it
    }
  }, [closeSocketCommand]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleOpen = () => {
    if (!isSocketInitialized) {
      setTyping(false);
      initializeWebSocket();
      setIsSocketInitialized(true);
      setInputValue("");
      if (activeTranscriber?.output) {
        activeTranscriber.output = {
          ...activeTranscriber.output,
          text: "",
          isBusy: false,
        };
      }
    }
    setIsOpen(true);
  };

  // const handleSend = () => {
  //   if (inputValue.trim() !== "") {
  //     const question = { question: inputValue };
  //     setMessages((prevMessages) => [...prevMessages, question]); // Show the question immediately
  //     setInputValue("");
  //     setTyping(true); // Show typing indicator
  //     sendMessage(question);
  //   }
  // };

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].answer) {
      setTyping(false); // Disable typing indicator when an answer is added
    }
  }, [messages]);

  const handleSend = () => {
    if (inputValue.trim() !== "") {
      const question = { question: inputValue };
      setTyping(true); // Show typing indicator
      sendMessage(question);
      setInputValue("");
    }
  };

  const handleClose = () => {
    closeConnection();
    setIsOpen(false);
    setIsSocketInitialized(false);
  };

  const handleMinimize = () => {
    setIsOpen(false);
    setInputValue("");
    if (activeTranscriber?.output) {
      activeTranscriber.output = {
        ...activeTranscriber.output,
        text: "",
        isBusy: false,
      };
    }
  };

  return (
    <Box
      position={isOpen ? "fixed" : "relative"} // When open, position fixed to overlap parent
      top={isOpen ? "20vh" : "auto"} // Adjust top value as needed
      right={isOpen ? "0.75rem" : "auto"} // Adjust right value as needed
      width={isOpen ? "600px" : "auto"}
      height={isOpen ? "450px" : "auto"}
      bg="gray.700"
      color="white"
      borderRadius="md"
      zIndex={isOpen ? "1000" : "auto"} // Bring it to the front when open
      overflow="hidden"
    >
      {isOpen && (
        <>
          <HStack justifyContent="space-between" bg="#17181b" p={2}>
            <Text>Chat with Aira</Text>
            <HStack>
              <IconButton
                icon={<MinusIcon />}
                size="sm"
                bg="#17181b"
                color={"white"}
                onClick={handleMinimize}
              />
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                bg="#17181b"
                color={"white"}
                onClick={handleClose}
              />
            </HStack>
          </HStack>

          <VStack
            spacing={3}
            p={3}
            pb={"3rem"}
            flex="1"
            overflowY="scroll"
            height="calc(100% - 60px)"
          >
            {messages.map((msg, index) => (
              <React.Fragment key={index}>
                {msg.question && (
                  <Box
                    alignSelf="flex-end"
                    bg="blue.500"
                    color="white"
                    p={2}
                    borderRadius="md"
                    maxW="80%"
                  >
                    <Text>{msg.question}</Text>
                  </Box>
                )}
                {msg.answer && (
                  <Box
                    alignSelf="flex-start"
                    bg="gray.600"
                    color="white"
                    p={2}
                    borderRadius="md"
                    maxW="80%"
                    mt={1}
                  >
                    <Text>{msg.answer}</Text>
                  </Box>
                )}
              </React.Fragment>
            ))}
            {typing && (
              <Box alignSelf="flex-start" mt={1} maxW="80%">
                <Spinner size="sm" /> {/* Simple typing animation */}
              </Box>
            )}
            <div ref={messagesEndRef} />
          </VStack>

          <HStack
            p={2}
            position="absolute"
            bottom="0"
            width="100%"
            bg="gray.800"
          >
            <ChatAudioManager
              transcriber={activeTranscriber}
              setPlaceholder={setPlaceholder}
              setTyping={setTyping}
            />
            <Input
              value={inputValue} // Use inputValue or fallback to voiceInput
              defaultValue={voiceInput}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSend()}
              isDisabled={typing}
              placeholder={placeholder} // dynamic placeholder, changes from chataudiomanager
              bg="white"
              color="black"
            />
            {/* <Icon
              as={BiMicrophoneOff}
              h={"36px"}
              w={"36px"}
              bg={"white"}
              color={"black"}
            /> */}
            {/* <Button onClick={handleSend} isDisabled={typing}>
              Send
            </Button> */}
            <Icon
              as={IoMdSend}
              onClick={handleSend}
              isDisabled={typing}
              h={"36px"}
              w={"36px"}
              cursor="pointer"
            />
          </HStack>
        </>
      )}
      {!isOpen && (
        <div>
          <Box
            as="button"
            onClick={handleOpen}
            zIndex="5"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            w={{base:'100px', md:"150px"}}
            p="0.5rem 0.75rem"
            h="2.2rem"
            left={{ lg: "80%", xl: "80%" }}
            backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
          >
            <Image w="16px" src="/assets/imgs/white-spark.png" />
            <Box display="flex" flexDir="column">
              <Text
                textAlign="left"
                fontSize="10px"
                fontWeight="700"
                color="white"
              >
                Aira - Chat
              </Text>
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default GenericChatbot;
