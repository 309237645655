import React, { useEffect, useState, useRef } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  SimpleGrid,
  Td,
  Tr,
  RadioGroup,
  Radio,
  InputLeftAddon,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import GrayCard from "../../components/card";
import { useNavigate, useLocation } from "react-router-dom";

import { DropdownInput, PrimaryInput, PrimaryInputDropdown, Toaster } from "../../components";

import {
  getPatientAppointmentDetails,
  UpdateAppointmentByDoctor,
} from "../../middleware/services/visits";

import DynamicModal from "../../components/dynamic-modal";
import { UpdateAppointmentStatus } from "../../middleware/services/visits/patient-overview";
import { actionAccessMain, calculateAgeWithYearMonth, formatToDecimal, getCurrency, getIsDoctor, getLoginID, useScreenType } from "../../auth";
import { GetPatientHealthData } from "../../middleware/services/visits/patient-overview";
import CurrentVisitOverview from "../../components/patient-health/overview";
import { CreateNewVisit, GetDoctorSlots, GetPatientStatus } from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import moment from "moment";
import DatePicker from "../../components/date-picker";
import { COLORS } from "../../components/styles/colors";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import PastVisitOverview from "../../components/patient-health/past-visits/past-overview";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import MobileNavbar from "../../components/mobile-navbar";
import useSecondLoader from "../../components/loader/secondloader";
import { AcceptVisit, FetchDoctorsByUser, GetSearchedPatientsList } from "../../middleware/services/home";
import SimpleTable from "../../components/simple-table";
import { set } from "date-fns";

const VisitDetailsIndex = () => {
  const { showSuccessToast, showErrorToast } = Toaster();

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { isSecondLoading, showSecondLoader, dismissSecondLoader } =
    useSecondLoader();
  // let activeLoaders = [];

  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const currentPatientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = getLoginID();
  const navigate = useNavigate();

  const newVisit = useRef();
  const startVisit = useRef();
  const manageModel = useRef();
  const cancelVisit = useRef();
  const rescheduleVisit = useRef();
  const noShowVisit = useRef();
  const screenType = useScreenType();
  const [appointmentRescheduleDate, setAppointmentRescheduleDate] = useState(
    new Date()
  );
  const [appointmentRescheduleTime, setAppointmentRescheduleTime] =
    useState(null);

  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);

  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessAppointments, setActionAccessAppointments] =
    useState(null);
  const [actionAccessMessages, setActionAccessMessages] = useState(null);
  const [actionAccessScreening, setActionAccessScreening] = useState(null);
  //New visit State
  let doctorcountrycode = localStorage.getItem("countryid");
  let doctorcountryid = localStorage.getItem("countrycode");
  var [SearchPatient, setSearchPatient] = useState("");
  var [showClearBtn, setshowClearBtn] = useState(false);

  //const [availableSlots, setAvailableSlots] = useState([]);
  const [ShowNewVisit, setShowNewVisit] = useState(false);
  const [PatientSearchedList, setPatientSearchedList] = useState([]);
  var [DoctorList, setDoctorList] = useState([]);
  var [DoctorListForDropdown, setDoctorListForDropdown] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(doctorcountrycode);
  const [selectedCountryId, setSelectedCountryId] = useState(doctorcountryid);
  const [SelectedCountryForEmergency, setSelectedCountryForEmergency] = useState(doctorcountrycode);
  const [SelectedCountryIdForEmergency, setSelectedCountryIdForEmergency] = useState(doctorcountryid);
  const [PatientAgeYear, setPatientAgeYear] = useState(0);
  const [PatientAgeMonth, setPatientAgeMonth] = useState(0);
  //const [timePeriod, setTimePeriod] = useState("Morning");
  const handleCountryChange = (country) => {
    setSelectedCountry(country.code);
    setSelectedCountryId(country.countryid);
    setSelectedCountryForEmergency(country.code);
    setSelectedCountryIdForEmergency(country.countryid);
    setNewVisitData({
      firstname: newVisitData.firstname,
      lastname: newVisitData.lastname,
      age: newVisitData.age,
      gender: newVisitData.gender,
      dob: newVisitData.dob,
      mobileno: newVisitData.mobileno,
      email: newVisitData.email,
      countryid: country.countryid,
      countrycode: country.code,
      emergencycontactcountryid: country.countryid,
      emergencycontactcountrycode: country.code,
      appointmentfor: "",
      appointmenttype_cd: 0,
      schedule_later: "Schedule Now",
      appointment_date: moment().format("DD-MM-YYYY"),
      appointment_time: new Date().toTimeString().substring(0, 5),
      createdby: newVisitData.createdby,
      fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : newVisitData.fees),
      isfreevisit:  newVisitData?.isfreevisit,
      address: newVisitData.patientaddress,
      emergencycontactname: newVisitData.emergencycontactname,
      emergencycontactphone: newVisitData.emergencycontactphone,

    });
  };
  const handleCountryChangeForEmergency = (country) => {
    setSelectedCountryForEmergency(country.code);
    setSelectedCountryIdForEmergency(country.countryid);
  };
  var [DoctorFees, setDoctorFees] = useState(null);
    var [DoctorFeesStatic, setDoctorFeesStatic] = useState(null);
  var [Doctorid, setDoctoridNewVisit] = useState(null);
  var [DefaultDoctor, setDefaultDoctor] = useState(null);
  const [newVisitData, setNewVisitData] = useState({
    firstname: "",
    lastname: "",
    age: null,
    month: null,
    gender: 2,
    dob: "",
    mobileno: "",
    email: "",
    countryid: doctorcountryid,
    countrycode: doctorcountrycode,
    emergencycontactcountryid: doctorcountryid,
    emergencycontactcountrycode: doctorcountrycode,
    appointmentfor: "",
    appointmenttype_cd: 0,
    schedule_later: "Schedule Now",
    appointment_date: "",
    appointment_time: "",
    createdby: "",
    fees: "",
    isfreevisit: "1",
    address: "",
    emergencycontactname: "",
    emergencycontactphone: "",
  });
  const [currency, setCurrency] = useState("");
  const [newvisitdisplaytext, setnewvisitdisplaytext] = useState("");
  var [patientid, setPatientID] = useState("");
  var [doctorid, setDoctorid] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  useEffect(() => {
    setActionAccess(actionAccessMain(2));
    setActionAccessAppointments(actionAccessMain(15));
    setActionAccessMessages(actionAccessMain(3));
    setActionAccessScreening(actionAccessMain(24))
    // Retrieve the value from local storage on component mount
    const storedValue = localStorage.getItem("CurrentVisitAppointmentId");
    // console.log(storedValue);
    if (storedValue) {
      setCurrentAppointmentId(storedValue);
    }
    const currency = getCurrency();
    if (currency == "USD") {
      const uscurrency = '$'
      setCurrency(uscurrency);
    } else {
      setCurrency(currency);
    }
  }, []);
  useEffect(() => {
    if (newVisitData.appointment_date != "") {
      fetchDoctorSlotsNewVisit();
    }
  }, [newVisitData.appointment_date, Doctorid]);
  //----------------Accepting visit
  const acceptvisit = async () => {
    let input = {
      doctorid: doctorId,
      patientid: currentPatientId,
      appointmentid: appointmentId,
      completed: "1",
    };
    try {
      const result = await AcceptVisit(input);
      // console.log(result);
      // console.log(input);
      if (result != null) {
        fetchPatientHealthData();
        fetchAppointmentDetails(currentPatientId, appointmentId);
      } else {
      }
    } catch (error) {
      console.error("Error in Visit details accepting visit", error);
    }
  };

  //----------------Reschedule Visit

  const [availableSlots, setAvailableSlots] = useState([]);

  const fetchDoctorSlots = async () => {
    // console.log(appointmentRescheduleDate);
    const inputToFetch = {
      input: {
        doctorid: doctorId,
        date: appointmentRescheduleDate
          ? moment(appointmentRescheduleDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
      },
    };
    // console.log(inputToFetch);
    showLoader();
    try {
      const data = await GetDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];
      // console.log("New Slots: ", slots);
      const currentDate = moment().format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        appointmentRescheduleDate
      )
        ? appointmentRescheduleDate
        : moment(appointmentRescheduleDate).format("DD-MM-YYYY");
      if (
        appointmentRescheduleDate &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("first case");
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots);
        // setAvailableSlots(filteredSlots);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorSlots();
  }, [appointmentRescheduleDate]);

  const [timePeriod, setTimePeriod] = useState("Morning");

  const NewSlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const currentDate = moment().format("DD-MM-YYYY");
    const currentTime = new Date();

    const isSlotDisabled = (slot) => {
      const slotTime = new Date(`1970-01-01T${slot.starttime}`);
      const slotDate = moment(appointmentRescheduleDate).format("DD-MM-YYYY");

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        appointmentRescheduleDate
      )
        ? appointmentRescheduleDate
        : moment(appointmentRescheduleDate).format("DD-MM-YYYY");
      if (
        appointmentRescheduleDate &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("Same day events")

        // If the slot is for today and its time has passed, disable it

        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);

        return (
          slotHours < currentHours ||
          (slotHours === currentHours && slotMinutes < currentMinutes)
        );
      }

      // If it's not today, don't disable
      return false;
    };

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      if (!isSlotDisabled(slot)) {
        // console.log("Selection made")
        setSelectedSlot(slot.starttime);
        setAppointmentRescheduleTime(slot.starttime);
        const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
        if (
          selectedTime >= new Date("1970-01-01T09:00:00") &&
          selectedTime < new Date("1970-01-01T12:00:00")
        ) {
          setTimePeriod("Morning");
        } else if (
          selectedTime >= new Date("1970-01-01T12:00:00") &&
          selectedTime < new Date("1970-01-01T18:00:00")
        ) {
          setTimePeriod("Afternoon");
        } else if (
          selectedTime >= new Date("1970-01-01T18:00:00") &&
          selectedTime < new Date("1970-01-01T21:00:00")
        ) {
          setTimePeriod("Evening");
        } else {
          setTimePeriod("Night");
        }
        // console.log(timePeriod);
      }
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : isSlotDisabled(slot)
                        ? "gray.300"
                        : "white"
                  }
                  color={
                    appointmentRescheduleTime === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      appointmentRescheduleTime === slot.starttime
                        ? "blue.600"
                        : isSlotDisabled(slot)
                          ? "gray.300"
                          : "gray.200",
                  }}
                  disabled={isSlotDisabled(slot)}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const SlotSelector = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
            new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
            new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      setAppointmentRescheduleTime(slot.starttime);
      const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
      if (
        selectedTime >= new Date("1970-01-01T09:00:00") &&
        selectedTime < new Date("1970-01-01T12:00:00")
      ) {
        setTimePeriod("Morning");
      } else if (
        selectedTime >= new Date("1970-01-01T12:00:00") &&
        selectedTime < new Date("1970-01-01T18:00:00")
      ) {
        setTimePeriod("Afternoon");
      } else if (
        selectedTime >= new Date("1970-01-01T18:00:00") &&
        selectedTime < new Date("1970-01-01T21:00:00")
      ) {
        setTimePeriod("Evening");
      } else {
        setTimePeriod("Night");
      }
      // console.log(timePeriod);
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}

        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots{" "}
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={5} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "white"
                  }
                  color={
                    appointmentRescheduleTime === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    appointmentRescheduleTime === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      appointmentRescheduleTime === slot.starttime
                        ? "blue.600"
                        : "gray.200",
                  }}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };

  const openRescheduleModal = async () => {
    // console.log("function called", availableSlots);
    setAvailableSlots([]);
    fetchDoctorSlots();
    setTimePeriod("Morning");
    setAppointmentRescheduleTime(null);
    setAppointmentRescheduleDate(new Date());
    rescheduleVisit.current.openModal();
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const openModal = queryParams.get("openRescheduleModal");
    //   console.log('Query Params:', queryParams.toString());
    // console.log('openModal:', openModal);
    if (openModal === "true") {
      const checkRefAndOpenModal = setInterval(() => {
        if (rescheduleVisit.current) {
          openRescheduleModal();
          clearInterval(checkRefAndOpenModal);
        }
      }, 100);
    }
  }, [location]);

  const handleRescheduleClick = async () => {
    if (!appointmentRescheduleTime) {
      showErrorToast("Please select a slot before rescheduling");
    } else {
      const inputToSave = {
        input: {
          patientid: currentPatientId,
          doctorid: doctorId,
          appointmentdate: moment(appointmentRescheduleDate).format(
            "YYYY-MM-DD"
          ),
          appointmenttime: appointmentRescheduleTime.substring(0, 5),
          // appointmentfor: "Follow-up consultation",
          familyid: null,
          appointmentid: appointmentId,
          reason: "Scheduling conflict",
          type: "reschedule",
        },
      };

      console.log(inputToSave);
      try {
        const result = await UpdateAppointmentByDoctor(inputToSave.input);
        console.log(result);
        if (result) {
          rescheduleVisit.current.closeModal();
          showSuccessToast("Visit rescheduled successfully");
          navigate(`/visits`);
        }
      } catch (error) {
        console.error("Error in create visit:", error);
        showErrorToast("Error Happened While Saving.");
      }
    }
  };

  //---------------------------

  useEffect(() => {
    const patientIdValue = params.get("patientId");
    const appointmentIdValue = params.get("appointmentId");

    if (patientIdValue && appointmentIdValue) {
      fetchAppointmentDetails(patientIdValue, appointmentIdValue);
    }
  }, [location.search]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const fetchAppointmentDetails = async (patientId, appointmentId) => {
    showLoader();
    // console.log("new loader added from appointment details");
    try {
      const response = await getPatientAppointmentDetails({
        patientid: patientId,
        appointmentid: appointmentId,
      });

      if (typeof response === "undefined") {
        navigate(`/visits`);
      }

      // console.log(response);
      setAppointmentDetails(response);
      const age = calculateAgeWithYearMonth(response?.patientinfo.dob)
      setPatientAgeYear(age?.years?age?.years:0)
      setPatientAgeMonth(age.months?age.months:0)
      // await delay(2000);
    } catch (error) {
      console.error("Error fetching appointment details:", error);
    } finally {
      dismissLoader();
      // console.log("loader removed from appointment details");
    }
  };

  const handleClick = async (visit) => {
    if (visit && visit.appointmentid) {
      navigate(
        `/visitdetails?patientId=${currentPatientId}&appointmentId=${visit.appointmentid}`
      );
    } else {
      console.error("Error: Visit details are incomplete.");
    }
  };

  const handleCurrentVisitClick = async () => {
    if (currentAppointmentId) {
      navigate(
        `/visitdetails?patientId=${currentPatientId}&appointmentId=${currentAppointmentId}`
      );
    } else {
      console.error("Error: Unable to fetch stored value");
    }
  };

  const handleConfirmStart = async () => {
    // console.log("Visit Started");
    const inputForStart = {
      input: {
        doctorid: doctorId,
        appointmentid: appointmentId,
        type: "start",
        time: new Date().toISOString(), // current timestamp
      },
    };
    try {
      // console.log("input of start", inputForStart.input);
      const result = await UpdateAppointmentStatus(inputForStart.input);
      // console.log("result of start", result);
      startVisit.current.closeModal();
      appointmentDetails?.timestamp
        ? showSuccessToast("Visit Resumed Successfully.")
        : showSuccessToast("Visit Started Successfully.");
      navigate(
        `/startvisit?doctorId=${doctorId}&patientId=${currentPatientId}&appointmentId=${appointmentId}&timeContinue=${appointmentDetails?.timestamp}`
      );
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleConfirmCancel = async () => {
    // console.log("Visit Canceled");
    const inputForStart = {
      input: {
        doctorid: doctorId,
        patientid: currentPatientId,
        appointmentid: appointmentId,
        reason: "abc", // CHANGE IT -> TO an input box
        completed: "2",
      },
    };
    try {
      // console.log("input of cancel", inputForStart.input);
      const result = await UpdateAppointmentByDoctor(inputForStart.input);
      // console.log("result of cancel", result);
      cancelVisit.current.closeModal();

      showSuccessToast("Visit canceled successfully.");
      navigate(`/visits`);
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleConfirmNoShow = async () => {
    // console.log("Visit Marked as No Show");
    const inputForStart = {
      input: {
        doctorid: doctorId,
        patientid: currentPatientId,
        appointmentid: appointmentId,
        reason: "abc", // CHANGE IT -> TO an input box
        completed: "4",
      },
    };
    try {
      // console.log("input of no show", inputForStart.input);
      const result = await UpdateAppointmentByDoctor(inputForStart.input);
      // console.log("result of no show", result);

      showSuccessToast("Visit Marked as No-Show successfully.");
      noShowVisit.current.closeModal();
      navigate(`/visits`);
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const handleMessageClick = async () => {
    navigate("/messages", { state: { id: currentPatientId } });
  };

  const handleProfileClick = async () => {
    navigate(`/patientprofile?id=${currentPatientId}&page=overview`);
  };

  // function calculateAge(dob) {
  //   const dobDate = new Date(dob);
  //   const today = new Date();

  //   let age = today.getFullYear() - dobDate.getFullYear();
  //   const monthDifference = today.getMonth() - dobDate.getMonth();
  //   const dayDifference = today.getDate() - dobDate.getDate();

  //   if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
  //     age--;
  //   }

  //   return age;
  // }

  // hpi formatting
  function generateHistoryOfPresentIllnessString(
    input,
    patientAge,
    patientGender
  ) {
    if (!Array.isArray(input) || input.length === 0) return ""; // Return an empty string if input is not an array or is empty

    const genderPronoun = patientGender === "male" ? "he" : "she";
    const patientDescription = `The patient is a ${patientAge}-year-old individual(${patientGender})`;

    const historyStrings = input.map((item) => {
      let painLocation = "";
      let onset = "";
      let chronicity = "";
      let onsetQuality = "";
      let frequency = "";
      let painRating = "";
      let associatedSymptoms = "";

      item.details.forEach((detail) => {
        switch (detail.attribute) {
          case "Site":
            painLocation = detail.values.map((val) => val.value).join(", ");
            break;
          case "Onset":
            onset = detail.value;
            break;
          case "Chronicity":
            chronicity = detail.value;
            break;
          case "Onset Quality":
            onsetQuality = detail.value;
            break;
          case "Frequency":
            frequency = detail.value;
            break;
          case "Rating Scale":
            painRating = detail.value;
            break;
          case "Associated Symptoms":
            associatedSymptoms = detail.values
              .map((symptom) => symptom.value)
              .join(", ");
            break;
          default:
            break;
        }
      });

      const painDescription = `presenting with ${item.type.toLowerCase()}.`;
      const onsetDescription = onset
        ? ` The ${item.type.toLowerCase()} started ${onset}`
        : "";
      const chronicityDescription = chronicity
        ? ` and is described as ${chronicity} in nature.`
        : " in nature.";
      const onsetQualityDescription = onsetQuality
        ? ` The onset of the ${item.type.toLowerCase()} was ${onsetQuality}.`
        : "";
      const frequencyDescription = frequency
        ? ` The ${item.type.toLowerCase()} is ${frequency}`
        : "";
      const ratingDescription = painRating
        ? ` and is rated as ${painRating} out of 10 in intensity.`
        : "";
      const associatedSymptomsDescription = associatedSymptoms
        ? ` Associated with ${associatedSymptoms}.`
        : "";

      return `${painDescription}${onsetDescription}${chronicityDescription}${onsetQualityDescription}${frequencyDescription}${ratingDescription}${associatedSymptomsDescription}`;
    });

    // console.log(`${patientDescription}\n${historyStrings.join("\n")}`);
    return `${patientDescription}\n${historyStrings.join("\n")}`;
  }

  const generateDynamicText = (value) => {
    let outputTexts = [];

    value.forEach((item, index) => {
      // Extract patient details
      let gender = appointmentDetails?.patientinfo?.gender || "";
      let age = calculateAge(appointmentDetails?.patientinfo?.dob) || "";

      // Initialize attributes
      let attributes = {};
      item.details.forEach((attribute) => {
        switch (attribute.attribute) {
          case "Site":
            attributes.site = attribute.values.map((v) => v.value).join(", ");
            break;
          case "Onset":
            attributes.onset = attribute.value;
            break;
          case "Chronicity":
            attributes.chronicity = attribute.value;
            break;
          case "Onset Quality":
            attributes.onsetQuality = attribute.value;
            break;
          case "Frequency":
            attributes.frequency = attribute.value;
            break;
          case "Associated Symptoms":
            attributes.symptoms = attribute.values
              .map((v) => v.value)
              .join(", ");
            break;
          case "Rating Scale":
            attributes.ratingScale = attribute.value;
            break;
          case "Time":
            attributes.time = attribute.value;
            break;
          case "Time Value":
            attributes.timeValue = attribute.value;
            break;
          default:
            break;
        }
      });

      // Construct patient text (only for the first item)
      let patientText =
        index === 0
          ? `The patient is a ${age}-year-old individual (${gender}) `
          : "";

      // Construct template based on available data, skipping sentences where data is missing
      let template = `${patientText}`;

      if (attributes.site) {
        template += `presenting with ${attributes.site} ${item.type}. `;
      }

      if (attributes.onset) {
        template += `The ${item.type} started ${attributes.onset} and `;
        if (attributes.chronicity) {
          template += `is described as ${attributes.chronicity} in nature. `;
        }
      }

      if (attributes.onsetQuality) {
        template += `The onset of the ${item.type} was ${attributes.onsetQuality}. `;
      }

      if (attributes.frequency) {
        template += `The ${item.type} is ${attributes.frequency} and `;
      }

      if (attributes.ratingScale) {
        template += `is rated as ${attributes.ratingScale} out of 10 in intensity. `;
      }

      if (attributes.symptoms) {
        template += `Associated with ${attributes.symptoms}.`;
      }

      // Push the constructed template to the outputTexts array
      outputTexts.push(template.trim());
    });

    return outputTexts;
  };

  //-------------Overview Details are fetched from patient health details

  const [patientHealthData, setPatientHealthData] = useState(null);

  const fetchPatientHealthData = async () => {
    const inputData = {
      input: {
        doctorid: doctorId,
        patientid: currentPatientId,
      },
    };
    showSecondLoader();
    // activeLoaders.push("1");
    // console.log("new loader added from patient details");
    try {
      const data = await GetPatientHealthData(inputData.input);
      setPatientHealthData(data);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissSecondLoader();
      // if (activeLoaders.length > 0) {
      //   activeLoaders.pop();
      //   updateLoaders(); // Manually update
      // }
      // console.log("loader removed from patient details");
    }
  };

  // const updateLoaders = () => {
  //   if (activeLoaders.length > 0) {
  //     showLoader();
  //   } else {
  //     dismissLoader();
  //   }
  //   // Force a re-render by setting a dummy state or other means
  //   // Here we'll force a render by updating a local component state or other methods
  // };

  useEffect(() => {
    fetchPatientHealthData();
  }, []);

  const [patientPastHealthData, setPatientPastHealthData] = useState(null);

  const fetchPatientPastHealthData = async () => {
    const inputData = {
      input: {
        doctorid: doctorId,
        patientid: currentPatientId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetPatientHealthData(inputData.input);
      // console.log(data);
      setPatientPastHealthData(data);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchPatientPastHealthData();
  }, [appointmentId]);

  //-----------------------------

  if (!appointmentDetails) {
    return <Loader />; // or render a loading state
  }
//New Visit

function getGender(value) {
  switch (value) {
    case 1:
    case "female":
      return "Female";
    case 2:
    case "male":
      return "Male";
    case 3:
    case "other":
      return "Other";
    default:
      return "Unknown"; // Fallback for unexpected or unsupported values
  }
}

const handleGenderChange = (value) => {
  let numericValue;
  switch (value) {
    case "Male":
      numericValue = 2;
      break;
    case "Female":
      numericValue = 1;
      break;
    case "Other":
      numericValue = 3;
      break;
    default:
      numericValue = null;
  }
  // console.log("Setting gender: ", value, numericValue);
  handleNewVisitChange("gender", numericValue);
};

const openNewVisitForm = () => {
  setShowNewVisit(false)
  setIsButtonDisabled(false);
  setPatientSearchedList([])
  setshowClearBtn(false)
  setSearchPatient("")
  setAvailableSlots([]);
  setNewVisitData({
    createdby: "",
  });
  fetchDoctors();
  newVisit.current.openModal();
};
const fetchDoctors = async () => {
  showLoader();
  try {
    const result = await FetchDoctorsByUser({
      doctorid,
    });
    if (result.output.result == "success") {
      setDoctorList(result.output.data);
      const Doctors = result.output.data && result.output.data.map((step) => [
        step.fullname
      ]);
      setDoctorListForDropdown(Doctors);
      const currentdoctor = getIsDoctor();
      const loginid = getLoginID()
      let obj = {}
      if (currentdoctor == 1 && currentdoctor != null) {
        obj = result.output.data && result.output.data.filter(doctor => doctor.doctorid == loginid);
        if (obj[0]) {
          setDefaultDoctor(obj[0].fullname)
          // setDoctorFees(formatToDecimal(obj[0].fee));
          
          setDoctorFeesStatic(obj[0].fee);
          if (newVisitData?.isfreevisit === "1") {
            setDoctorFees("0.00");
          } else {
            setDoctorFees(formatToDecimal(obj[0].fee));
          }

          setDoctoridNewVisit(obj[0].doctorid);
          setNewVisitData({
            firstname: "",
            lastname: "",
            age: null,
            month:null,
            gender: 2,
            dob: "",
            mobileno: "",
            email: "",
            countryid: doctorcountryid,
            countrycode: doctorcountrycode,
            emergencycontactcountryid: doctorcountryid,
            emergencycontactcountrycode: doctorcountrycode,
            appointmentfor: "",
            appointmenttype_cd: 0,
            schedule_later: "Schedule Now",
            appointment_date: moment().format("DD-MM-YYYY"),
            appointment_time: new Date().toTimeString().substring(0, 5),
            createdby: obj[0].doctorid,
            // fees: obj[0].fee,
            // isfreevisit: "0",
            fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : obj[0].fee),

              isfreevisit:  newVisitData?.isfreevisit,
          });
        }
      } else {
        setDefaultDoctor("");
        setNewVisitData({
          firstname: "",
          lastname: "",
          age: null,
          month:null,
          gender: 2,
          dob: "",
          mobileno: "",
          email: "",
          countryid: doctorcountryid,
          countrycode: doctorcountrycode,
          emergencycontactcountryid: doctorcountryid,
          emergencycontactcountrycode: doctorcountrycode,
          appointmentfor: "",
          appointmenttype_cd: 0,
          schedule_later: "Schedule Now",
          appointment_date: moment().format("DD-MM-YYYY"),
          appointment_time: new Date().toTimeString().substring(0, 5),
          createdby: "",
          fees: "",
          // isfreevisit: "0",
          isfreevisit:  newVisitData?.isfreevisit,
        });
      }
    } else {
      setDoctorList([]);
      dismissLoader();
    }
    dismissLoader();
  } catch (error) {
    console.error("Error", error);
  }
};
const HandleEditDoctor = (selectedOption) => {
  setDoctorFees(null)
  let obj = DoctorList.filter(doctor => doctor.fullname == selectedOption);
  // setDoctorFees(formatToDecimal(obj[0].fee));
  
  setDoctorFeesStatic(obj[0].fee);
  if (newVisitData?.isfreevisit === "1") {
    setDoctorFees("0.00");
  } else {
    setDoctorFees(formatToDecimal(obj[0].fee));
  }

  setDoctoridNewVisit(obj[0].doctorid)
  setNewVisitData({
    firstname: newVisitData.firstname,
    lastname: newVisitData.lastname,
    age: newVisitData.age,
    month: newVisitData.month,
    gender: newVisitData.gender,
    dob: newVisitData.dob,
    mobileno: newVisitData.mobileno,
    email: newVisitData.email,
    countryid: newVisitData.countryid,
    countrycode: newVisitData.countrycode,
    emergencycontactcountryid: newVisitData.emergencycontactcountryid,
    emergencycontactcountrycode: newVisitData.emergencycontactcountrycode,
    appointmentfor: "",
    appointmenttype_cd: 0,
    schedule_later: "Schedule Now",
    appointment_date: moment().format("DD-MM-YYYY"),
    appointment_time: new Date().toTimeString().substring(0, 5),
    createdby: obj[0].doctorid,
    // fees: obj[0].fee,
    fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : obj[0].fee),
    isfreevisit:  newVisitData?.isfreevisit,
  });
}
const handleNewVisitChange = (field, value) => {
  setNewVisitData((prev) => ({ ...prev, [field]: value }));
  // if (field === 'mobileno' && value.length === 10) {
  //   handleFetchPatientStatus(field, value); // Call immediately after 10 digits
  // }
  // if (field === 'email' && value.includes('@')) {
  //   handleFetchPatientStatus(field, value); // Call immediately after entering '@'
  // }
};
 const fetchDoctorSlotsNewVisit = async () => {
    const inputToFetch = {
      input: {
        doctorid: Doctorid,
        date: newVisitData.appointment_date
          ? moment(newVisitData.appointment_date, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
          : null,
      },
    };
    //(inputToFetch);
    showLoader();
    try {
      const data = await GetDoctorSlots(inputToFetch.input);
      const slots = data?.output?.data || [];

      const currentDate = moment().format("DD-MM-YYYY");
      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedDate = isDateInCorrectFormat(newVisitData.appointment_date)
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (newVisitData.appointment_date && formattedDate === currentDate) {
        // console.log(newVisitData.appointment_date);
        // console.log(currentDate);
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const filteredSlots = slots.filter((slot) => {
          const [slotHours, slotMinutes] = slot.starttime
            .split(":")
            .map(Number);
          return (
            slotHours > currentHours ||
            (slotHours === currentHours && slotMinutes >= currentMinutes)
          );
        });
        // console.log(filteredSlots.length);
        // console.log(slots.length);
        setAvailableSlots(slots);
      }
      // console.log("New Slots: ", slots);
      else {
        // console.log("Other case");
        setAvailableSlots(slots);
      }
    } catch (error) {
      console.error("Error fetching Doctor Slots data:", error);
    } finally {
      dismissLoader();
    }
  };
  const NewSlotSelectorNewVisit = ({ availableSlots }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const currentDate = moment().format("DD-MM-YYYY");
    const currentTime = new Date();

    const isSlotDisabled = (slot) => {
      const slotTime = new Date(`1970-01-01T${slot.starttime}`);
      const slotDate = moment(newVisitData.appointment_date).format(
        "DD-MM-YYYY"
      );

      function isDateInCorrectFormat(dateStr) {
        const regex = /^\d{2}-\d{2}-\d{4}$/;
        return regex.test(dateStr);
      }

      const formattedRescheduleDate = isDateInCorrectFormat(
        newVisitData.appointment_date
      )
        ? newVisitData.appointment_date
        : moment(newVisitData.appointment_date).format("DD-MM-YYYY");
      if (
        newVisitData.appointment_date &&
        formattedRescheduleDate === currentDate
      ) {
        // console.log("Same day events")

        // If the slot is for today and its time has passed, disable it

        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [slotHours, slotMinutes] = slot.starttime.split(":").map(Number);

        return (
          slotHours < currentHours ||
          (slotHours === currentHours && slotMinutes < currentMinutes)
        );
      }

      // If it's not today, don't disable
      return false;
    };

    const timePeriods = ["Morning", "Afternoon", "Evening", "Night"];
    const timePeriodSlots = {
      Morning: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T09:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T12:00:00")
      ),
      Afternoon: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T12:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T18:00:00")
      ),
      Evening: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T18:00:00") &&
          new Date(`1970-01-01T${slot.starttime}`) <
          new Date("1970-01-01T21:00:00")
      ),
      Night: availableSlots.filter(
        (slot) =>
          new Date(`1970-01-01T${slot.starttime}`) >=
          new Date("1970-01-01T21:00:00")
      ),
    };

    const handleTimePeriodChange = (direction) => {
      const currentIndex = timePeriods.indexOf(timePeriod);
      const newIndex =
        (currentIndex + direction + timePeriods.length) % timePeriods.length;
      setTimePeriod(timePeriods[newIndex]);
    };

    const handleSlotSelection = (slot) => {
      if (!isSlotDisabled(slot)) {
        // console.log("Selection made")
        setSelectedSlot(slot.starttime);
        handleNewVisitChange("appointment_time", slot.starttime);
        const selectedTime = new Date(`1970-01-01T${slot.starttime}`);
        if (
          selectedTime >= new Date("1970-01-01T09:00:00") &&
          selectedTime < new Date("1970-01-01T12:00:00")
        ) {
          setTimePeriod("Morning");
        } else if (
          selectedTime >= new Date("1970-01-01T12:00:00") &&
          selectedTime < new Date("1970-01-01T18:00:00")
        ) {
          setTimePeriod("Afternoon");
        } else if (
          selectedTime >= new Date("1970-01-01T18:00:00") &&
          selectedTime < new Date("1970-01-01T21:00:00")
        ) {
          setTimePeriod("Evening");
        } else {
          setTimePeriod("Night");
        }
        // console.log(timePeriod);
      }
    };

    return (
      <Box>
        {isLoading && <Loader miniLoader={true} />}
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Box as="button" onClick={() => handleTimePeriodChange(-1)}>
                <Image src="/assets/svgs/left-tab-icon.svg" />
              </Box>
              <Text fontSize="xl" fontWeight="bold">
                {timePeriod}
                <Text fontSize="md" color="gray" display={"inline"} ml={"8px"}>
                  {timePeriodSlots[timePeriod].length} slots
                </Text>
              </Text>
              <Box as="button" onClick={() => handleTimePeriodChange(1)}>
                <Image src="/assets/svgs/right-tab-icon.svg" />
              </Box>
            </Box>

            <SimpleGrid columns={{ base: 4, md: 5 }} spacing={4}>
              {timePeriodSlots[timePeriod].map((slot) => (
                <Box
                  key={slot.starttime}
                  as="button"
                  onClick={() => handleSlotSelection(slot)}
                  borderWidth="1px"
                  borderRadius="md"
                  p={2}
                  textAlign="center"
                  bg={
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.500"
                      : isSlotDisabled(slot)
                        ? "gray.300"
                        : "white"
                  }
                  color={
                    newVisitData.appointment_time === slot.starttime
                      ? "white"
                      : "black"
                  }
                  borderColor={
                    newVisitData.appointment_time === slot.starttime
                      ? "blue.500"
                      : "gray.300"
                  }
                  _hover={{
                    bg:
                      newVisitData.appointment_time === slot.starttime
                        ? "blue.600"
                        : isSlotDisabled(slot)
                          ? "gray.300"
                          : "gray.200",
                  }}
                  disabled={isSlotDisabled(slot)}
                >
                  {slot.starttime.substring(0, 5)}
                </Box>
              ))}
            </SimpleGrid>
          </>
        )}
      </Box>
    );
  };
const formatDOBDate = (date) => {
  // Check if the date is already in YYYY-MM-DD format
  if (moment(date, "YYYY-MM-DD", true).isValid()) {
    return date;
  }
  // If not, assume it's in DD-MM-YYYY format and convert it to YYYY-MM-DD
  return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
};
function calculateAge(dob) {
  // Check if dob is valid
  if (!dob || isNaN(new Date(dob).getTime())) {
    return ""; // Return empty if dob is invalid
  }

  const dobDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDifference = today.getMonth() - dobDate.getMonth();
  const dayDifference = today.getDate() - dobDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

function formatgender(value) {
  if (typeof value !== "string") {
    return value; // Return the value as is if it's not a string
  }

  switch (value.toLowerCase()) { // Optional: Make it case-insensitive
    case "female":
      return 1;
    case "male":
      return 2;
    case "other":
      return 3;
    default:
      return 2; // Default value for unexpected strings
  }
}

const handleCreateVisitClick = async (IsAdvance) => {
  if (isButtonDisabled) return; // Prevent further clicks
  setIsButtonDisabled(true);
  const typeofschedule = newVisitData.schedule_later;
  const currentdoctor = getIsDoctor();
  let type = null;
  if (currentdoctor == 1 && currentdoctor != null) {
    if (typeofschedule === "Schedule Now") {
      type = "startvisit"
    }
  }

  const inputToSave = {
    input: {
      firstname: newVisitData.firstname,
      lastname: newVisitData.lastname,
      fullname: newVisitData.firstname + " " + newVisitData.lastname,
      gender: newVisitData.gender ? formatgender(newVisitData.gender) : 2,
      dob: newVisitData.dob ? formatDOBDate(newVisitData.dob) : null,
      age: newVisitData.age,
      month: newVisitData.month,
      mobileno: newVisitData.mobileno ? newVisitData.mobileno : null,
      email: newVisitData.email ? newVisitData.email : null,
      countryid: newVisitData.countryid ? newVisitData.countryid : "",
      countrycode: newVisitData.countrycode ? newVisitData.countrycode : "",
      emergencycontactcountryid: SelectedCountryIdForEmergency ? SelectedCountryIdForEmergency : "",
      emergencycontactcountrycode: SelectedCountryForEmergency ? SelectedCountryForEmergency : "",
      patientimage: null,
      doctorid: Doctorid,
      appointmentfor: null,
      appointmenttype_cd: 693,
      appointmentdate: moment(
        newVisitData.appointment_date,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD"),
      appointmenttime: newVisitData.appointment_time,
      createdby: newVisitData.createdby,
      fees: newVisitData.fees,
      isfreevisit: newVisitData.isfreevisit,
      paymenttype: "cash",
      type: type,
      address: newVisitData.address,
      emergencycontactname: newVisitData.emergencycontactname,
      emergencycontactphone: newVisitData.emergencycontactphone,
      patientid: patientid
    },
  };
  // We assume inputToSave.input can have these properties:
  if (inputToSave.input.dob == null) {
    const rawAge = inputToSave.input.age;
    const rawMonth = inputToSave.input.month;
  
    // Convert to numbers (NaN if invalid or nullish)
    const ageYears = parseInt(rawAge, 10);
    const ageMonths = parseInt(rawMonth, 10);
  
    // 1) Only AGE is provided (month is null or invalid)
    if (!isNaN(ageYears) && (rawMonth == null || isNaN(ageMonths))) {
      // "Old" logic: subtract years only
      const currentYear = new Date().getFullYear();
      const yearOfBirth = currentYear - ageYears;
  
      // Fix date to January 2nd
      const birthYear = yearOfBirth;
      const birthMonth = 0; // January = 0
      const birthDay = 2;
  
      const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
  
      // Format as YYYY-MM-DD
      const year = calculatedDOB.getFullYear();
      const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
      const day = String(calculatedDOB.getDate()).padStart(2, '0');
      const formattedDob = `${year}-${month}-${day}`;
  
      inputToSave.input.dob = formattedDob;
    }
    // 2) Only MONTH is provided (age is null or invalid)
    else if (!isNaN(ageMonths) && (rawAge == null || isNaN(ageYears))) {
      // Subtract only months from today's date
      const now = new Date();
      let birthYear = now.getFullYear();
      let birthMonth = now.getMonth() - ageMonths; // 0-based month
      let birthDay = 2; // pick day = 2
  
      // If birthMonth is negative, wrap to previous year
      if (birthMonth < 0) {
        birthYear -= 1;
        birthMonth += 12;
      }
  
      const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
  
      // Format as YYYY-MM-DD
      const year = calculatedDOB.getFullYear();
      const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
      const day = String(calculatedDOB.getDate()).padStart(2, '0');
      const formattedDob = `${year}-${month}-${day}`;
  
      inputToSave.input.dob = formattedDob;
    }
    // 3) Both AGE and MONTH are provided
    else if (!isNaN(ageYears) && !isNaN(ageMonths)) {
      // Subtract years + months
      const now = new Date();
  
      let birthYear = now.getFullYear() - ageYears;
      let birthMonth = now.getMonth() - ageMonths;
      const birthDay = 2;
  
      if (birthMonth < 0) {
        birthYear -= 1;
        birthMonth += 12;
      }
  
      const calculatedDOB = new Date(birthYear, birthMonth, birthDay);
  
      // Format as YYYY-MM-DD
      const year = calculatedDOB.getFullYear();
      const month = String(calculatedDOB.getMonth() + 1).padStart(2, '0');
      const day = String(calculatedDOB.getDate()).padStart(2, '0');
      const formattedDob = `${year}-${month}-${day}`;
  
      inputToSave.input.dob = formattedDob;
    }
  }
  // Validation checks
  if (inputToSave.input.createdby === "") {
    showErrorToast("Please select any doctor to proceed");
    setIsButtonDisabled(false);
    return;
  } else if (
    (!inputToSave.input.mobileno || inputToSave.input.mobileno.trim() === "") &&
    (!inputToSave.input.email || inputToSave.input.email.trim() === "")
  ) {
    showErrorToast("Please enter either a mobile number or an email.");
    setIsButtonDisabled(false);
    return;
  } else if (
    inputToSave.input.firstname === ""
  ) {
    showErrorToast("Please enter patient name");
    setIsButtonDisabled(false);
    return;
  } else if (
    inputToSave.input.mobileno !== "" &&
    inputToSave.input.mobileno !== null &&
    inputToSave.input.mobileno !== undefined
  ) {
    // Validate mobile number (numbers only and exactly 10 digits)
    const mobileRegex = /^[0-9]{9,10}$/;
    if (!mobileRegex.test(inputToSave.input.mobileno)) {
      showErrorToast(
        "Invalid mobile number."
      );
      // handleNewVisitChange("mobileno", "");
      setIsButtonDisabled(false);
      return;
    }
  } else if (
    inputToSave.input.email !== "" &&
    inputToSave.input.email !== null &&
    inputToSave.input.email !== undefined
  ) {
    // Validate email address if provided
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputToSave.input.email)) {
      showErrorToast("Invalid email address. Please enter a valid email.");
      // handleNewVisitChange("email", "");
      setIsButtonDisabled(false);
      return;
    }
  }
  // console.log(inputToSave.input);
  // console.log(inputToSave.input.dob);

  // if (
  //   inputToSave.input.dob !== "" &&
  //   inputToSave.input.dob !== null &&
  //   inputToSave.input.dob !== undefined &&
  //   inputToSave.input.age !== "" &&
  //   inputToSave.input.age !== null &&
  //   inputToSave.input.age !== undefined
  // ) {
  //   // console.log("Checking");
  //   if (!isAgeInRange(inputToSave.input.dob, inputToSave.input.age)) {
  //     showErrorToast("Age and DOB do not match.");
  //     return;
  //   }
  // }

  try {
    const result = await CreateNewVisit(inputToSave.input);
    if (result.output.result == "success") {
      if (IsAdvance === "true") {
        localStorage.removeItem('formState');
        localStorage.removeItem('saveStates');
        localStorage.removeItem('formData');
        localStorage.removeItem('answers')
        localStorage.removeItem('followUps')
        navigate("/advancedetails", { state: { input: inputToSave.input, patientid: result.output.patientid, appointmentid: result.output.appointmentid } })
      } else if (IsAdvance === "false") {
        const typeofschedule = newVisitData.schedule_later;
        if (currentdoctor == 1 && currentdoctor != null && result.output.patientid) {
          if (typeofschedule === "Schedule Later") {
            navigate(
             `/visitdetails?patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`
            );
          } else {
            navigate(`/startvisit?doctorId=${result.output.doctorid}&patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`)
          }
        }
        else if (result.output.patientid) {
          navigate(
            `/visitdetails?patientId=${result.output.patientid}&appointmentId=${result.output.appointmentid}`
          );
        }
      }
      showSuccessToast("Visit scheduled successfully");
      newVisit.current.closeModal();
    } else {
      newVisit.current.closeModal();
    }
  } catch (error) {
    console.error("Error in create visit:", error);
    showErrorToast("Error Happened While Saving.");
  }finally{
    setIsButtonDisabled(false);
  }
}; 
const ShowVisitForm = () => {
  // setNewVisitData({
  //   fees: "0.00",
  //   isfreevisit:  "1",

  // });
  setShowNewVisit(true)
}
const HandleClearData = () => {
  setSearchPatient("");
  setPatientSearchedList([])
  setshowClearBtn(false)
  setnewvisitdisplaytext("")
}
const HandleSearchPatient = (e) => {
  setSearchPatient(e.target.value)
}
const SearchPatientOnKeyPress = (event) => {
  if (event.key === "Enter") {
    HandleSearchPatientCLick();
  }
}
const HandleSearchPatientCLick = async () => {
  const input = { searchtext: SearchPatient };
  setshowClearBtn(true)
  showLoader()
  try {
    const result = await GetSearchedPatientsList(input);
    if (result?.output?.result === "failure") {
      showSuccessToast("No existing record found. Please create a new patient profile.")
      setnewvisitdisplaytext("No patient data matches your search input!")
    } else {
      showSuccessToast("Existing patient details found. Click on the patient to proceed")
      setPatientSearchedList(result?.output?.data)
    }
    dismissLoader();
  } catch (error) {
    console.error("Error in create visit:", error);
    showErrorToast("Error Happened While Saving.");
  }
};
  const handlepatientSelected = (item) => {
    setPatientID(item.patientid);
    const patientInfo = item;
    const Age = patientInfo?.dob ? calculateAgeWithYearMonth(patientInfo.dob) : null
    setNewVisitData({
      firstname: patientInfo.firstname,
      lastname: patientInfo.lastname,
       age: Age.years?Age?.years:0,
      month: Age.months?Age?.months:0,
      gender: patientInfo.gender,
      dob: patientInfo.dob? moment(patientInfo.dob, "YYYY-MM-DD").format("DD-MM-YYYY"):"",
      mobileno: patientInfo.mobileno,
      email: patientInfo.email,
      countryid: patientInfo.countryid,
      countrycode: patientInfo.countrycode,
      emergencycontactcountryid: patientInfo.emergencycontactcountryid ? patientInfo.emergencycontactcountryid : patientInfo.countryid,
      emergencycontactcountrycode: patientInfo.emergencycontactcountrycode ? patientInfo.emergencycontactcountrycode : patientInfo.countrycode,
      appointmentfor: "",
      appointmenttype_cd: 0,
      schedule_later: "Schedule Now",
      appointment_date: moment().format("DD-MM-YYYY"),
      appointment_time: new Date().toTimeString().substring(0, 5),
      createdby: newVisitData.createdby,
      // fees: DoctorFees,
      // isfreevisit: "0",
      fees: newVisitData?.isfreevisit === "1" ? "0.00" : (newVisitData?.isfreevisit === undefined ? "0.00" : DoctorFees),
      isfreevisit:  newVisitData?.isfreevisit,
      address: patientInfo.patientaddress,
      emergencycontactname: patientInfo.emergencycontactname,
      emergencycontactphone: patientInfo.emergencycontactphone,
    });
    setSelectedCountry(patientInfo?.countrycode);
    setSelectedCountryId(patientInfo?.countryid);
    if (
      patientInfo.emergencycontactcountrycode !== null &&
      patientInfo.emergencycontactcountrycode !== undefined
    ) {
      setSelectedCountryForEmergency(patientInfo.emergencycontactcountrycode);
      setSelectedCountryIdForEmergency(patientInfo.emergencycontactcountryid);
    }
    setShowNewVisit(true);
  };
  const TableHeaderPatients = [
    { key: "patient", label: "Patient" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile No" },
    { key: "address", label: "Address" },
  ];

  const handleAdvancedDetails = async () => {
    const getPatientInfo = async () => {
          showLoader();
          try {
            const result = await GetPatientHealthData({
              patientid: currentPatientId,
              doctorid: doctorId,
            });
            
            return result; // Return the fetched result
          } catch (error) {
            console.error("Error in fetching patient info:", error);
            return null; // Return null in case of an error
          } finally {
            dismissLoader();
          }
        };
      
        // Fetch patient info
        const patientInfo = await getPatientInfo();
      
        if (!patientInfo || !patientInfo.patientinfo) {
          console.error("Patient information not available.");
          return; // Stop execution if patient info is not fetched
        }
        const Age = patientInfo.patientinfo?.dob ? calculateAgeWithYearMonth(patientInfo.patientinfo?.dob) : null
        // Create the input to save
        const inputToSave = {
          input: {
            firstname: patientInfo.patientinfo?.firstname,
            lastname: patientInfo.patientinfo?.lastname,
            fullname: patientInfo.patientinfo?.firstname + " " + patientInfo.patientinfo?.lastname,
            gender: patientInfo.patientinfo?.gender ? formatgender(patientInfo.patientinfo?.gender) : 2,
            dob: patientInfo.patientinfo?.dob ? formatDOBDate(patientInfo.patientinfo?.dob) : null,
            age: Age.years?Age?.years:0,
            month: Age.months?Age?.months:0,
            mobileno: patientInfo.patientinfo?.mobileno || null,
            email: patientInfo.patientinfo?.email || null,
            countryid: patientInfo.patientinfo?.countryid || "",
            countrycode: patientInfo.patientinfo?.countrycode || "",
            doctorid: doctorId,
            patientid: currentPatientId,
          },
        };
      
        // Navigate to advanced details
        navigate("/advancedetails", {
          state: {
            input: inputToSave.input,
            patientid: currentPatientId,
            appointmentid: appointmentId,
          },
        });
  }
  return (
    <>
      {isLoading && <Loader />}
      {isSecondLoading && <Loader />}
      {screenType == "web" ? (
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            <Box w="100%">
              <SecondaryNav
                customHeader="Visit Detail"
                showBack={true}
                onBack={() => navigate(`/visits`)}
              />

              {/* Main container start */}
              <Flex w="calc(100vw - 6rem)">
                <Box
                  display="flex"
                  h="calc(100vh - 50px)"
                  overflow="hidden"
                  w="100%"
                >
                  {/* Left area start */}
                  <Box
                    w={{ sm: "18rem", md: "20rem", xl: "23rem" }}
                    h="calc(100vh - 14rem)"
                    borderRight="1px solid #E6E7E9"
                  >
                    <Box
                      w="100%"
                      p="1.25rem"
                      display="flex"
                      flexDir="column"
                      gap="1rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Box display="flex" gap="0.87rem" w="100%">
                        {appointmentDetails.patientinfo.image ? (
                          <Image
                            w="4rem"
                            h="4rem"
                            borderRadius="full"
                            // overflow="hidden"
                            src={appointmentDetails.patientinfo.image}
                            onClick={handleProfileClick}
                            cursor={"pointer"}
                          />
                        ) : (
                          <Image
                            borderRadius="full"
                            w="4rem"
                            h="4rem"
                            src="/assets/imgs/no-image.png"
                            onClick={handleProfileClick}
                            cursor={"pointer"}
                          />
                        )}
                        <Box display="flex" flexDir="column" w="100%">
                          <Text
                            fontSize="1rem"
                            fontWeight="600"
                            color="#061027"
                            onClick={handleProfileClick}
                            cursor={"pointer"}
                          >
                            {appointmentDetails.patientinfo?.firstname +
                              " " +
                              appointmentDetails.patientinfo?.lastname}
                          </Text>
                          {appointmentDetails?.bookingid && (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              color="#384052"
                            >
                              #{appointmentDetails?.bookingid}
                            </Text>
                          )}
                        </Box>
                      </Box>
                      <Flex w="100%" justifyContent="space-evenly">
                        <PrimaryButton
                          variant="grayBtn"
                          buttonText="Message patient"
                          btnStyle={{ w: "45%", color: "#061027" }}
                          onClick={handleMessageClick}
                          isDisabled={
                            actionAccessMessages && actionAccessMessages.create
                              ? false
                              : true
                          }
                        />
                        <PrimaryButton
                          variant="grayBtn"
                          buttonText="View Profile"
                          btnStyle={{ w: "45%", color: "#061027" }}
                          onClick={handleProfileClick}
                        />
                      </Flex>
                    </Box>

                    <Box h="100%" overflowY="auto">
                      <Box p="1.5rem" bg="white" borderRadius="lg">
                        <VStack spacing={4} align="stretch" h="100%">
                          <Box
                            p="1rem"
                            display="flex"
                            justifyContent="space-around"
                            border="1px solid #E6E7E9"
                            borderRadius="0.75rem"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              flexDir="column"
                              gap="0.5rem"
                            >
                              <Image
                                w="1rem"
                                src="/assets/svgs/calendar-visits.svg"
                              />
                              <Text fontSize="0.875rem" fontWeight="500">
                                {appointmentDetails?.appointmentdate}
                              </Text>
                            </Box>
                            <Divider orientation="vertical" h="auto" />
                            <Box
                              display="flex"
                              alignItems="center"
                              flexDir="column"
                              gap="0.5rem"
                            >
                              <Image
                                w="1.25rem"
                                src="/assets/svgs/time-visit.svg"
                              />
                              <Text fontSize="0.875rem" fontWeight="500">
                                {appointmentDetails?.appointmenttime}
                              </Text>
                            </Box>
                          </Box>

                          {appointmentDetails?.patientinfo?.email && (
                            <Box display="flex" alignItems="center">
                              <Image src="/assets/svgs/email.svg" />
                              <Text
                                fontSize="0.875rem"
                                fontWeight="500"
                                ml="0.5rem"
                              >
                                {appointmentDetails.patientinfo?.email}
                              </Text>
                            </Box>
                          )}

                          {appointmentDetails.patientinfo?.mobileno && (
                            <Box display="flex" alignItems="center">
                              <Image
                                src="/assets/svgs/phone-number.svg"
                                // boxSize="1rem"
                                // mr="0.5rem"
                                // alt="Phone Icon"
                              />
                              <Box display="flex" alignItems="center">
                                {appointmentDetails.patientinfo?.flagimg && (
                                  <Image
                                    src={
                                      appointmentDetails.patientinfo?.flagimg
                                    }
                                    // boxSize="1rem"
                                    width="1.4rem"
                                    height="1rem"
                                    mr="0.5rem"
                                    ml="0.5rem"
                                    alt="Country Flag"
                                  />
                                )}

                                <Text fontSize="0.875rem" fontWeight="500">
                                  ({appointmentDetails.patientinfo?.countrycode}){" "}
                                  {appointmentDetails.patientinfo?.mobileno}
                                </Text>
                              </Box>
                            </Box>
                          )}

                          {appointmentDetails.patientinfo.gender && (
                            <Box display="flex" alignItems="center">
                              <Image src="/assets/svgs/gender.svg" />
                              <Text
                                fontSize="0.875rem"
                                fontWeight="500"
                                ml="0.5rem"
                              >
                                {appointmentDetails.patientinfo?.gender}
                              </Text>
                            </Box>
                          )}

                          {appointmentDetails.patientinfo.height && (
                            <Box display="flex" alignItems="center">
                              <Image src="/assets/svgs/height.svg" />
                              <Text
                                fontSize="0.875rem"
                                fontWeight="500"
                                ml="0.5rem"
                              >
                                {appointmentDetails.patientinfo?.height +
                                  " " +
                                  appointmentDetails.patientinfo?.heightunit}
                              </Text>
                            </Box>
                          )}

                          {appointmentDetails.patientinfo.weight && (
                            <Box display="flex" alignItems="center">
                              <Image src="/assets/svgs/weight.svg" />
                              <Text
                                fontSize="0.875rem"
                                fontWeight="500"
                                ml="0.5rem"
                              >
                                {appointmentDetails.patientinfo?.weight +
                                  " " +
                                  appointmentDetails.patientinfo?.weightunit}
                              </Text>
                            </Box>
                          )}

                          {appointmentDetails.patientinfo.dob && (
                            <Box display="flex" alignItems="center">
                              <Image src="/assets/svgs/cake.svg" />
                              <Text
                                fontSize="0.875rem"
                                fontWeight="500"
                                ml="0.5rem"
                              >
                                <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                                {appointmentDetails.patientinfo?.dob} 
                                {PatientAgeYear && ` (${PatientAgeYear} Years`}
                                {PatientAgeMonth && `${PatientAgeYear ? " " : " ("}${PatientAgeMonth} Months`}
                                {(PatientAgeYear || PatientAgeMonth) && ")"}
                              </Text>

                              </Text>
                            </Box>
                          )}

                          {appointmentDetails.patientinfo.id && (
                            <Box display="flex" alignItems="center">
                              <Image src="/assets/svgs/calendar-filled.svg" />
                              <Text
                                fontSize="0.875rem"
                                fontWeight="500"
                                ml="0.5rem"
                              >
                                {appointmentDetails.patientinfo?.id}
                              </Text>
                            </Box>
                          )}

                          <Spacer />

                          <Box w="100%" display="flex" gap="0.5rem">
                            {appointmentDetails.appointmentstatusname ===
                            "Pending" ? (
                              <PrimaryButton
                                onClick={() => acceptvisit()}
                                buttonText="Accept visit"
                                // variant="grayBtn"
                                btnStyle={{
                                  h: "3rem",
                                  w: "100%",
                                  // color: "#061027",
                                }}
                                isDisabled={
                                  actionAccess && actionAccess.create
                                    ? false
                                    : true
                                }
                              />
                            ) : (
                              <>
                                <PrimaryButton
                                  variant="grayBtn"
                                  buttonText={
                                    <Image src="/assets/svgs/more-options.svg" />
                                  }
                                  btnStyle={{ h: "3rem", w: "max-content" }}
                                  onClick={() =>
                                    manageModel.current.openModal()
                                  }
                                  isDisabled={
                                    appointmentDetails.appointmentstatus ===
                                      "end" ||
                                    appointmentDetails.appointmentstatus ===
                                      "pause"
                                  }
                                />
                                <PrimaryButton
                                  buttonText={
                                    appointmentDetails.appointmentstatus ===
                                    "start"
                                      ? "Start call"
                                      : appointmentDetails.appointmentstatus ===
                                          "pause"
                                        ? "Resume call"
                                        : appointmentDetails.appointmentstatus ===
                                            "end"
                                          ? "Completed"
                                          : "Start call"
                                  }
                                  onClick={() => startVisit.current.openModal()}
                                  isDisabled={
                                    appointmentDetails.appointmentstatus ===
                                      "end" ||
                                    (actionAccessAppointments &&
                                      (!actionAccessAppointments.create))
                                      ? true
                                      : false ||
                                        appointmentDetails?.appointmentstatusname ===
                                          "Cancelled"
                                          || appointmentDetails?.appointmentstatusname === "No-show"
                                  }
                                />
                              </>
                            )}
                          </Box>
                        </VStack>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    w={{
                      sm: "calc(100vw - 36rem)",
                      md: "calc(100vw - 40rem)",
                      xl: "calc(100vw - 46rem)",
                    }}
                    bg="#ffffff"
                    p="1.5rem"
                    pb="0"
                    overflowX="auto"
                    overflowY="scroll"
                    maxH="calc(100vh - 50px)"
                  >
                    <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                      {appointmentDetails?.chiefcomplaint ? (
                        <GrayCard
                          cardHeader="chief complaint"
                          cardHeaderIcon="/assets/imgs/cheif-complaint.png"
                          bodyStyle={{
                            p: "10px 20px",
                            overflow: "auto",
                            // maxH: "220px",
                            h: "100%",
                            maxH: "10rem",
                          }}
                          // overflowY="auto"
                          cardBody={
                            <>
                              <Flex
                                flexDir="column"
                                justifyContent="space-between"
                                h="100%"
                              >
                                <Text fontSize="0.875rem" fontWeight="500">
                                  {appointmentDetails?.chiefcomplaint}
                                </Text>
                                <Spacer />
                                {/* <Text
                                  fontSize="0.75rem"
                                  fontWeight="500"
                                  color="#384052"
                                >
                                  {appointmentDetails?.chiefcomplaint}
                                </Text> */}
                              </Flex>
                            </>
                          }
                          hasFooter={false}
                        />
                      ) : (
                        <GrayCard
                          cardHeader="chief complaint"
                          cardHeaderIcon="/assets/imgs/cheif-complaint.png"
                          bodyStyle={{
                            p: "10px 20px",
                            overflow: "auto",
                            // maxH: "220px",
                            h: "100%",
                            maxH: "10rem",
                          }}
                          // overflowY="auto"
                          cardBody={
                            <>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="10px"
                                alignItems="center"
                                height={"120px"}
                              >
                                <Box
                                  textAlign="left"
                                  fontSize="16px"
                                  fontWeight="600"
                                >
                                  <Image
                                    w="26px"
                                    opacity="0.5"
                                    src="/assets/imgs/cheif-complaint.png"
                                  />
                                </Box>
                                <Text
                                  textAlign="left"
                                  fontSize="12px"
                                  fontWeight="500"
                                >
                                  No Data available
                                </Text>
                              </Box>
                            </>
                          }
                          hasFooter={false}
                        />
                      )}

                      {appointmentDetails?.patientnotes && (
                        <GrayCard
                          cardHeader="Patient notes"
                          cardHeaderIcon="/assets/imgs/patient-notes.png"
                          bodyStyle={{
                            p: "10px 20px",
                            overflow: "auto",
                            // maxH: "220px",
                            h: "100%",
                            maxH: "10rem",
                          }}
                          // overflowY="auto"
                          cardBody={
                            <>
                              <Flex
                                flexDir="column"
                                justifyContent="space-between"
                                h="100%"
                              >
                                <Text fontSize="0.875rem" fontWeight="500">
                                  {appointmentDetails?.patientnotes}
                                </Text>
                              </Flex>
                            </>
                          }
                          hasFooter={false}
                        />
                      )}

                      {appointmentDetails?.historyofpresentillness || appointmentDetails?.hpitext!==null ? (
                        <GrayCard
                          cardHeader="history of present illness"
                          cardHeaderIcon="/assets/imgs/history-illeness.png"
                          // bodyStyle={{ h: "100%", maxH: "10rem" }}
                          bodyStyle={{
                            p: "10px 20px",
                            overflow: "auto",
                            // maxH: "220px",
                            h: "100%",
                            maxH: "10rem",
                          }}
                          // overflowY="auto"
                          cardBody={
                            <>
                              <Flex
                                flexDir="column"
                                justifyContent="space-between"
                                h="100%"
                              >
                                {appointmentDetails.historyofpresentillness !== null && (
                                <Text fontSize="0.875rem" fontWeight="500">
                                  {/* {generateHistoryOfPresentIllnessString(
                                    appointmentDetails?.historyofpresentillness ||
                                      [],
                                    calculateAge(
                                      appointmentDetails?.patientinfo?.dob
                                    ),
                                    appointmentDetails?.patientinfo?.gender
                                  )} */}
                                  {generateDynamicText(
                                    appointmentDetails &&
                                      appointmentDetails.historyofpresentillness
                                  ).map((text, index) => (
                                    <React.Fragment key={index}>
                                      {text}
                                      <br />
                                      <br />
                                    </React.Fragment>
                                  ))}
                                </Text>
                                )}
                                {appointmentDetails && appointmentDetails.hpitext !== null && (
                                  <Box>
                                {/* <Text fontSize="0.875rem" fontWeight="600">
                                  Description
                                </Text> */}
                                <Text fontSize="0.875rem" fontWeight="500">
                                  {appointmentDetails.hpitext.description}
                                </Text>
                                  </Box>
                                )}
                              </Flex>
                            </>
                          }
                          hasFooter={false}
                        />
                      ) : (
                        <GrayCard
                          cardHeader="history of present illness"
                          cardHeaderIcon="/assets/imgs/history-illeness.png"
                          // bodyStyle={{ h: "100%", maxH: "10rem" }}
                          bodyStyle={{
                            p: "10px 20px",
                            overflow: "auto",
                            // maxH: "220px",
                            h: "100%",
                            maxH: "10rem",
                          }}
                          // overflowY="auto"
                          cardBody={
                            <>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="10px"
                                alignItems="center"
                                height={"120px"}
                              >
                                <Box
                                  textAlign="center"
                                  fontSize="16px"
                                  fontWeight="600"
                                >
                                  <Image
                                    w="26px"
                                    opacity="0.5"
                                    src="/assets/imgs/medical-history.png"
                                  />
                                </Box>
                                <Text
                                  textAlign="center"
                                  fontSize="12px"
                                  fontWeight="500"
                                >
                                  No Data available
                                </Text>
                              </Box>
                            </>
                          }
                          hasFooter={false}
                        />
                      )}

                      <Flex mb="36px">
                        {appointmentDetails.appointmentstatus === "start" ||
                        appointmentDetails.appointmentstatus === null ? (
                          <CurrentVisitOverview
                            patienData={patientHealthData}
                            displayOverview={true}
                          />
                        ) : (
                          <PastVisitOverview
                            patienData={patientPastHealthData}
                            displayChiefComplaint={false}
                            displayHPI={false}
                            displayPrescription={
                              appointmentDetails.appointmentstatus === "end"
                                ? true
                                : false
                            }
                          />
                        )}
                      </Flex>
                    </Box>
                  </Box>

                  <Box
                    w={{ sm: "18rem", md: "20rem", xl: "23rem" }}
                    h="calc(100vh - 70px)"
                    borderLeft="1px solid #E6E7E9"
                    overflowY="auto"
                  >
                    <Box w="100%" mb="1.25rem">
                  <Box w="100%">
                            {/* Title */}
                            <Box
                              as="h2"
                              p="1.5rem"
                              py="1rem"
                              fontSize="1.25rem"
                              fontWeight="600"
                              borderBottom="1px solid #E6E7E9"
                            >
                              Quick Links
                            </Box>
      
                            {/* Links Container */}
                            <Box px="1.5rem" paddingTop="1rem">
                              {/* Link 2 */}
                              {actionAccess && actionAccess.create &&
                                <Box>
                                  <a
                                    href="#"
                                    style={{
                                      color: "black",
                                      textDecoration: "none",
                                      fontSize: "1rem",
                                      position: "relative",
                                      display: "inline-block",
                                      cursor: "pointer",
                                      lineHeight: "1.5", // Aligns the link properly
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent default navigation
                                      if (actionAccess && actionAccess.create) {
                                        openNewVisitForm(); // Call the function if allowed
                                      }
                                    }}
                                  >
                                    New visit
                                    <Box
                                      as="span"
                                      position="absolute"
                                      left="0"
                                      bottom="0"
                                      height="1px"
                                      width="100%"
                                      bg="black"
                                    />
                                  </a>
                                </Box>
                              }
                            </Box>
                          </Box>                
                      <Box
                        d="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={"2rem"}
                      >
                        <PrimaryButton
                          variant="grayBtn"
                          buttonText="Current Visit"
                          onClick={handleCurrentVisitClick}
                          btnStyle={{
                            h: "2.5rem",
                            w: "80%",
                            ml: "2rem",
                            color: "#061027",
                          }}
                          isDisabled={appointmentId === currentAppointmentId}
                        />
                      </Box>

                      <Box
                        as="h2"
                        p="1.5rem"
                        ppl="1.875rem"
                        fontSize="1.25rem"
                        fontWeight="600"
                        pb="3rem"
                        // borderBottom="1px solid #E6E7E9"
                      >
                        Previous visits
                        <Box pt={"14px"}>
                          <Stepper
                            orientation="vertical"
                            height="400px"
                            size="xs"
                            colorScheme="#CDCFD4"
                            gap="0"
                            w="100%"
                          >
                            {appointmentDetails &&
                            appointmentDetails?.previousvisits.length > 0 ? (
                              appointmentDetails?.previousvisits.map(
                                (visit, index) => (
                                  <Step w="100%" pb="1rem">
                                    <StepIndicator color="#E6E7E9" />

                                    <Box flexShrink="0" w="95%">
                                      <StepTitle as="div">
                                        <Text
                                          fontWeight="600"
                                          fontSize="0.875rem"
                                          color="#384052"
                                          mb="0.5rem"
                                        >
                                          {visit?.appointmentdate}
                                        </Text>
                                        <Text
                                          fontWeight="600"
                                          fontSize="0.875rem"
                                          color="#384052"
                                          mb="0.5rem"
                                        >{`${
                                          visit?.doctorname
                                            ? visit.doctorname
                                            : "-"
                                        } , ${
                                          visit?.bookingid
                                            ? visit.bookingid
                                            : "-"
                                        }`}</Text>
                                        <Text
                                          fontSize="0.875rem"
                                          color="#384052"
                                          mb="0.5rem"
                                        >
                                          {visit?.specality}
                                        </Text>
                                      </StepTitle>
                                      <StepDescription
                                        bg="white"
                                        w="100%"
                                        border="1px solid #E6E7E9"
                                        borderRadius="0.65rem"
                                        p="0.75rem"
                                      >
                                        <Box
                                          bg="#F9FAFB"
                                          borderLeft="2px solid #14C585"
                                          p="0.5rem"
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                          >
                                            {/* Reason: Headache, stomach ache, coughing and vomiting */}
                                            Reason:{" "}
                                            {visit?.chiefcomplaint
                                              ? visit.chiefcomplaint
                                              : "-/-"}
                                          </Text>
                                        </Box>
                                        <Box
                                          as="button"
                                          my="0.75rem"
                                          onClick={() => handleClick(visit)}
                                        >
                                          <Text
                                            fontSize="0.75rem"
                                            pl="0.30rem"
                                            fontWeight="700"
                                            textDecor="underline"
                                          >
                                            View details
                                          </Text>
                                        </Box>
                                      </StepDescription>
                                    </Box>
                                    <StepSeparator colorScheme="red" />
                                  </Step>
                                )
                              )
                            ) : (
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="10px"
                                alignItems="center"
                                h="calc(100vh - 100px)"
                                w="100%"
                              >
                                <Box
                                  textAlign="left"
                                  fontSize="16px"
                                  fontWeight="600"
                                >
                                  <Image
                                    w="26px"
                                    opacity="0.5"
                                    src="/assets/imgs/visit.png"
                                  />
                                </Box>
                                <Text
                                  textAlign="left"
                                  fontSize="12px"
                                  fontWeight="500"
                                >
                                  No Data available
                                </Text>
                              </Box>
                            )}
                          </Stepper>

                          {/* old code  */}
                          {/* {appointmentDetails?.previousvisits.map((visit) => (
                            <VStack
                              key={visit?.appointmentid}
                              p="8px"
                              align="stretch"
                              overflow="hidden"
                              bg="#fcfcfd"
                            >
                              <Stack
                                direction="column"
                                align="center"
                                gap="0"
                                w="100%"
                              >
                                <Box
                                  display="flex"
                                  flexDir="column"
                                  alignItems="center"
                                  h="100%"
                                  w="100%"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    w="100%"
                                  >
                                    <Circle
                                      size="1rem"
                                      bg="white"
                                      border="2px solid #CDCFD4"
                                      borderRadius="full"
                                      color="black"
                                    />
                                    <Text
                                      fontSize="0.9rem"
                                      pl="0.75rem"
                                      fontWeight="500"
                                      lineHeight="normal"
                                    >
                                      {visit?.chiefcomplaint}
                                    </Text>
                                  </Box>
                                </Box>
                                <Box display="flex" w="100%">
                                  <Center
                                    h="3.125rem"
                                    ml="0.43rem"
                                    alignItems="start"
                                    flexDir="column"
                                    borderLeft="2px solid #E6E7E9"
                                  >
                                    <Text
                                      fontSize="0.75rem"
                                      pl="1.35rem"
                                      mt="0.75rem"
                                      fontWeight="500"
                                    >
                                      {visit?.appointmentdate}
                                    </Text>
                                    <Box
                                      as="button"
                                      my="0.75rem"
                                      onClick={() => handleClick(visit)}
                                    >
                                      <Text
                                        fontSize="0.75rem"
                                        pl="1.35rem"
                                        fontWeight="700"
                                        textDecor="underline"
                                      >
                                        View details
                                      </Text>
                                    </Box>
                                  </Center>
                                </Box>
                              </Stack>
                            </VStack>
                          ))} */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <MobileSecondaryNav
            header="Visit Detail"
            handlebackarrow={() => navigate(`/visits`)}
          />
          <Box
            pos="fixed"
            bottom="8.5%"
            boxShadow="rgba(50, 50, 93, 0.25) 0px -8px 10px -10px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            bg="white"
            w="100%"
            zIndex="99"
          >
            <Box p="1rem" display="flex" gap="0.5rem" justifyContent="center">
              <PrimaryButton
                variant="grayBtn"
                buttonText={<Image src="/assets/svgs/more-options.svg" />}
                btnStyle={{ h: "3rem", w: "max-content" }}
                onClick={() => manageModel.current.openModal()}
                isDisabled={appointmentDetails.appointmentstatus === "end"}
              />
              <PrimaryButton
                buttonText={
                  appointmentDetails.appointmentstatus === "start"
                    ? "Start call"
                    : appointmentDetails.appointmentstatus === "pause"
                      ? "Resume call"
                      : appointmentDetails.appointmentstatus === "end"
                        ? "Completed"
                        : "Start call"
                }
                onClick={() => startVisit.current.openModal()}
                isDisabled={
                  appointmentDetails.appointmentstatus === "end" ||
                  (actionAccessAppointments &&
                    (!actionAccessAppointments.create ||
                      !actionAccessAppointments.update))
                    ? true
                    : false
                }
              />
            </Box>
          </Box>

          <Box w="100%" h="calc(100vh - 230px)" overflow="hidden auto">
            {/* Patient details start */}
            <Box w="100%" borderLeft="1px solid #E6E7E9">
              {appointmentDetails && appointmentDetails.patientinfo && (
                <VStack p="1.5rem" align="stretch" gap="1rem" bg="white">
                  <Box>
                    <Box display="flex" gap="0.85rem">
                      <Box w="6rem" h="6rem">
                        <Image
                          w="100%"
                          h="100%"
                          borderRadius="full"
                          src={
                            appointmentDetails.patientinfo.image
                              ? appointmentDetails.patientinfo.image
                              : "/assets/imgs/no-image.png"
                          }
                        />
                      </Box>
                      <Box display="flex" flexDir="column" w="70%" gap="10px">
                        <Text fontSize="1rem" fontWeight="600">
                          {appointmentDetails.patientinfo?.firstname +
                            appointmentDetails.patientinfo?.lastname}
                        </Text>
                        <Box display="flex" gap="0.5rem">
                          {appointmentDetails?.bookingid && (
                            <Text
                              fontSize="0.875rem"
                              fontWeight="500"
                              color="#384052"
                            >
                              #{appointmentDetails?.bookingid}
                            </Text>
                          )}
                        </Box>
                        <Box>
                          <PrimaryButton
                            variant="grayBtn"
                            buttonText="Message patient"
                            btnStyle={{ w: "100%", color: "#061027" }}
                            onClick={handleMessageClick}
                            isDisabled={
                              actionAccessMessages &&
                              actionAccessMessages.create
                                ? false
                                : true
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    p="1rem"
                    display="flex"
                    justifyContent="space-around"
                    border="1px solid #E6E7E9"
                    borderRadius="0.75rem"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDir="column"
                      gap="0.5rem"
                    >
                      <Image w="1rem" src="/assets/svgs/calendar-visits.svg" />
                      <Text fontSize="0.875rem" fontWeight="500">
                        {appointmentDetails?.appointmentdate}
                      </Text>
                    </Box>
                    <Divider orientation="vertical" h="auto" />
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDir="column"
                      gap="0.5rem"
                    >
                      <Image w="1.25rem" src="/assets/svgs/time-visit.svg" />
                      <Text fontSize="0.875rem" fontWeight="500">
                        {appointmentDetails?.appointmenttime}
                      </Text>
                    </Box>
                  </Box>
                  <Divider />
                  <Stack direction="column" gap="0.75rem" w="100%">
                    <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/email.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                        {appointmentDetails.patientinfo?.email}
                      </Text>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Image src="/assets/svgs/phone-number.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                        {appointmentDetails.patientinfo?.mobileno}
                      </Text>
                    </Box>

                    <Box display="flex" w="100%">
                      <Image src="/assets/svgs/gender.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                        {appointmentDetails.patientinfo?.gender}
                      </Text>
                    </Box>

                    <Box display="flex" w="100%">
                      <Image src="/assets/svgs/cake.svg" />
                      <Text fontSize="0.875rem" fontWeight="500" ml="0.5rem">
                        {appointmentDetails.patientinfo?.dob}
                      </Text>
                    </Box>
                  </Stack>
                </VStack>
              )}
            </Box>
            {/* Patient details end */}

            {/* Patient Health overview start */}
            <Box
              w="100%"
              display="flex"
              flexDir="column"
              gap="1.5rem"
              px="1rem"
            >
              {appointmentDetails?.chiefcomplaint && (
                <GrayCard
                  cardHeader="chief complaint"
                  cardHeaderIcon="/assets/imgs/cheif-complaint.png"
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    // maxH: "220px",
                    h: "100%",
                    maxH: "10rem",
                  }}
                  // overflowY="auto"
                  cardBody={
                    <>
                      <Flex
                        flexDir="column"
                        justifyContent="space-between"
                        h="100%"
                      >
                        <Text fontSize="0.875rem" fontWeight="500">
                          {appointmentDetails?.chiefcomplaint}
                        </Text>
                        <Spacer />
                        {/* <Text
                        fontSize="0.75rem"
                        fontWeight="500"
                        color="#384052"
                      >
                        {appointmentDetails?.chiefcomplaint}
                      </Text> */}
                      </Flex>
                    </>
                  }
                  hasFooter={false}
                />
              )}

              {appointmentDetails?.patientnotes && (
                <GrayCard
                  cardHeader="Patient notes"
                  cardHeaderIcon="/assets/imgs/patient-notes.png"
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    // maxH: "220px",
                    h: "100%",
                    maxH: "10rem",
                  }}
                  // overflowY="auto"
                  cardBody={
                    <>
                      <Flex
                        flexDir="column"
                        justifyContent="space-between"
                        h="100%"
                      >
                        <Text fontSize="0.875rem" fontWeight="500">
                          {appointmentDetails?.patientnotes}
                        </Text>
                      </Flex>
                    </>
                  }
                  hasFooter={false}
                />
              )}

              {appointmentDetails?.historyofpresentillness && (
                <GrayCard
                  cardHeader="history of present illness"
                  cardHeaderIcon="/assets/imgs/history-illeness.png"
                  // bodyStyle={{ h: "100%", maxH: "10rem" }}
                  bodyStyle={{
                    p: "10px 20px",
                    overflow: "auto",
                    // maxH: "220px",
                    h: "100%",
                    maxH: "10rem",
                  }}
                  // overflowY="auto"
                  cardBody={
                    <>
                      <Flex
                        flexDir="column"
                        justifyContent="space-between"
                        h="100%"
                      >
                        <Text fontSize="0.875rem" fontWeight="500">
                          {/* {generateHistoryOfPresentIllnessString(
                          appointmentDetails?.historyofpresentillness ||
                            [],
                          calculateAge(
                            appointmentDetails?.patientinfo?.dob
                          ),
                          appointmentDetails?.patientinfo?.gender
                        )} */}
                          {generateDynamicText(
                            appointmentDetails &&
                              appointmentDetails.historyofpresentillness
                          ).map((text, index) => (
                            <React.Fragment key={index}>
                              {text}
                              <br />
                              <br />
                            </React.Fragment>
                          ))}
                        </Text>
                      </Flex>
                    </>
                  }
                  hasFooter={false}
                />
              )}

              <Flex>
                {appointmentDetails.appointmentstatus === "start" ||
                appointmentDetails.appointmentstatus === null ? (
                  <CurrentVisitOverview
                    patienData={patientHealthData}
                    displayOverview={true}
                  />
                ) : (
                  <PastVisitOverview
                    patienData={patientPastHealthData}
                    displayChiefComplaint={false}
                    displayHPI={false}
                    displayPrescription={
                      appointmentDetails.appointmentstatus === "end"
                        ? true
                        : false
                    }
                  />
                )}
              </Flex>
            </Box>
            {/* Patient Health overview end */}
            <Divider my="1rem" />
            {/* Previous visits start */}
            <Box w="100%" mb="1.25rem">
              <Box d="flex" justifyContent="center" alignItems="center">
                <PrimaryButton
                  variant="grayBtn"
                  buttonText="Current Visit"
                  onClick={handleCurrentVisitClick}
                  btnStyle={{
                    h: "2.5rem",
                    w: "80%",
                    ml: "2rem",
                    color: "#061027",
                  }}
                  isDisabled={appointmentId === currentAppointmentId}
                />
              </Box>

              <Box
                as="h2"
                p="1.5rem"
                ppl="1.875rem"
                fontSize="1.25rem"
                fontWeight="600"
                borderBottom="1px solid #E6E7E9"
              >
                Previous visits
                <Box pt={"14px"}>
                  {appointmentDetails?.previousvisits.map((visit) => (
                    <VStack
                      key={visit?.appointmentid}
                      p="8px"
                      align="stretch"
                      overflow="hidden"
                      bg="#fcfcfd"
                    >
                      <Stack direction="column" align="center" gap="0" w="100%">
                        <Box
                          display="flex"
                          flexDir="column"
                          alignItems="center"
                          h="100%"
                          w="100%"
                        >
                          <Box display="flex" alignItems="center" w="100%">
                            <Circle
                              size="1rem"
                              bg="white"
                              border="2px solid #CDCFD4"
                              borderRadius="full"
                              color="black"
                            />
                            <Text
                              fontSize="0.9rem"
                              pl="0.75rem"
                              fontWeight="500"
                              lineHeight="normal"
                            >
                              {visit?.chiefcomplaint}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex" w="100%">
                          <Center
                            h="3.125rem"
                            ml="0.43rem"
                            alignItems="start"
                            flexDir="column"
                            borderLeft="2px solid #E6E7E9"
                          >
                            <Text
                              fontSize="0.75rem"
                              pl="1.35rem"
                              mt="0.75rem"
                              fontWeight="500"
                            >
                              {visit?.appointmentdate}
                            </Text>
                            <Box
                              as="button"
                              my="0.75rem"
                              onClick={() => handleClick(visit)}
                            >
                              <Text
                                fontSize="0.75rem"
                                pl="1.35rem"
                                fontWeight="700"
                                textDecor="underline"
                              >
                                View details
                              </Text>
                            </Box>
                          </Center>
                        </Box>
                      </Stack>
                    </VStack>
                  ))}
                </Box>
              </Box>
            </Box>
            {/* Previous visits end */}
          </Box>
        </>
      )}
      {/* Start Visit  */}
      <DynamicModal
        ref={startVisit}
        modalHeader={
          appointmentDetails.appointmentstatus === "pause"
            ? "Resume Visit"
            : "Start Visit"
        }
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to{" "}
                {appointmentDetails.appointmentstatus === "pause"
                  ? "resume"
                  : "start"}{" "}
                this visit now?
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText={
                appointmentDetails.appointmentstatus === "start"
                  ? "Start call"
                  : appointmentDetails.appointmentstatus === "pause"
                    ? "Resume call"
                    : appointmentDetails.appointmentstatus === "end"
                      ? "Completed"
                      : "Start call"
              }
              onClick={handleConfirmStart}
              isDisabled={
                appointmentDetails.appointmentstatus === "end" ||
                (actionAccessAppointments &&
                  (!actionAccessAppointments.create))
                  ? true
                  : false
              }
            />
          </>
        }
      />

      {/* Manage Modal  */}
      <DynamicModal
        ref={manageModel}
        modalHeader="Manage"
        modalBody={
          <>
            <Box>
              <Box
                as="button"
                onClick={openRescheduleModal}
                w="100%"
                pb="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #E6E7E9"
                disabled={actionAccess && !actionAccess.update ? true : false}
                opacity={actionAccess && !actionAccess.update ? "0.5" : ""}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Reschedule Visit
                </Text>
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              {appointmentDetails.appointmentstatus === "start" && (
                <Box
                  as="button"
                  w="100%"
                  py="1rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom="1px solid #E6E7E9"
                  disabled={actionAccessScreening && (actionAccessScreening.create) ? false : true}
                  onClick={handleAdvancedDetails}
                  opacity={actionAccessScreening && (actionAccessScreening.create) ? "" : "0.5"}
                  >
                  <Text fontSize="1rem" fontWeight="500">
                    Advanced details
                  </Text>
                  <Image src="/assets/imgs/right-icon.png" />
                </Box>
              )}

              <Box
                as="button"
                w="100%"
                onClick={() => noShowVisit.current.openModal()}
                py="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #E6E7E9"
                disabled={actionAccess && !actionAccess.update ? true : false}
                opacity={actionAccess && !actionAccess.update ? "0.5" : ""}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Mark patient as no-show
                </Text>
                <Image src="/assets/imgs/right-icon.png" />
              </Box>
              <Box
                as="button"
                w="100%"
                onClick={() => cancelVisit.current.openModal()}
                pt="1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color="#CA3A31"
                disabled={actionAccess && !actionAccess.update ? true : false}
                opacity={actionAccess && !actionAccess.update ? "0.5" : ""}
              >
                <Text fontSize="1rem" fontWeight="500">
                  Cancel appointment
                </Text>
                <Image src="/assets/imgs/right-icon-red.png" />
              </Box>
            </Box>
          </>
        }
        hasFooter={false}
      />

      {/* Reschedule Visit  */}
      <DynamicModal
        ref={rescheduleVisit}
        modalHeader="Reschedule Visit"
        modalWidth="40%"
        modalBody={
          <>
            <Box>
              <Box mt={"8px"}>
                <DatePicker
                  defaultDate={moment(new Date()).format("DD-MM-YYYY")}
                  minDate={moment(new Date()).format("DD-MM-YYYY")}
                  // disableFutureDates={false}
                  onDateChange={(selectedDateValue) =>
                    setAppointmentRescheduleDate(selectedDateValue)
                  }
                  placeholderProp={"Select Date"}
                  calendarStlye={{ left: "-18px", right: "unset" }}
                />
                <NewSlotSelector availableSlots={availableSlots} />
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="grayBtn"
              buttonText="Reschedule"
              // onClick={() => rescheduleVisit.current.closeModal()}
              onClick={handleRescheduleClick}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />
          </>
        }
      />

      {/* Mark as No Show Visit  */}
      <DynamicModal
        ref={noShowVisit}
        modalHeader="Mark as No Show"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to mark as no-show for your visit with{" "}
                <Box as="span" fontWeight="500">
                  {appointmentDetails.patientinfo?.firstname +
                    appointmentDetails.patientinfo?.lastname}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                  {appointmentDetails?.appointmentdate} at{" "}
                  {appointmentDetails?.appointmenttime}?
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Mark as no-show"
              onClick={handleConfirmNoShow}
            />
          </>
        }
      />

      {/* Mark as cancel Visit  */}
      <DynamicModal
        ref={cancelVisit}
        modalHeader="Cancel visit"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" fontWeight="400">
                Are you sure you want to cancel your visit with{" "}
                <Box as="span" fontWeight="500">
                  {appointmentDetails.patientinfo?.firstname +
                    appointmentDetails.patientinfo?.lastname}
                </Box>{" "}
                scheduled on{" "}
                <Box as="span" fontWeight="500">
                  {appointmentDetails?.appointmentdate} at{" "}
                  {appointmentDetails?.appointmenttime}?
                </Box>
              </Text>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Cancel visit"
              onClick={handleConfirmCancel}
            />
          </>
        }
      />
       <DynamicModal
        ref={newVisit}
        modalHeader="New Visit"
        modalWidth={{ base: "100%", md: "60%" }}
        modalBody={
          <>
            <Box bg={"#fcfcfd"}>
              {!ShowNewVisit &&
                <>
                  <Text fontSize='1rem'>
                    {"Please use the search option to look up for existing patient information"}
                  </Text>
                  <Box display='flex' gap='0.75rem' my='0.5rem'>

                    <Box w='100%' position='relative'>
                      <Box w='100%'>
                        <PrimaryInput
                          inputPlace='Search by name, email, mobile'
                          variant='fullRound'
                          inputValue={SearchPatient}
                          onKeyPress={SearchPatientOnKeyPress}
                          onChange={HandleSearchPatient}
                        />
                      </Box>
                    </Box>
                    <Flex gap='0.75rem'>
                      <PrimaryButton buttonText='Fetch' onClick={HandleSearchPatientCLick} />
                      {showClearBtn &&
                        <PrimaryButton buttonText='Clear' variant='grayBtn' onClick={HandleClearData} btnStyle={{ w: '50%', h: '48px', color: 'black' }} />
                      }
                      {PatientSearchedList && PatientSearchedList.length > 0 &&
                        <PrimaryButton buttonText='Add New Patient' onClick={ShowVisitForm} btnStyle={{ minW: 'unset' }} />
                      }
                    </Flex>
                  </Box>
                  {PatientSearchedList && PatientSearchedList.length > 0 &&
                    <Box
                      border="1px solid #E6E7E9"
                      mt='1rem'
                    >
                      <SimpleTable
                        headers={TableHeaderPatients}
                        className='tableContainer'
                        isDataPresent={true}
                        tableStyle={{ maxH: "calc(100vh - 320px)", overflowY: "auto", overflowX: "hidden", tableLayout: "fixed", width: "100%" }}
                        tableBody={
                          <>
                            {PatientSearchedList &&
                              PatientSearchedList.map((step, index) => (
                                <Tr cursor='pointer' w="100%" key={index} onClick={() => handlepatientSelected(step)}>
                                  <Td w="100x">
                                    <Box w="100%" display="flex" gap="0.5rem" alignItems="center">
                                      <Box boxSize="2.75rem">
                                        <Image
                                          boxSize="40px"
                                          h="40px"
                                          objectFit="cover"
                                          // src={
                                          //   step.patientimage? step.imagepath + step.patientimage:"/assets/imgs/no-image.png"
                                          // } 
                                          src={
                                            step.patientimage ?
                                              (step.patientimage.startsWith('http') ? step.patientimage : step.imagepath + step.patientimage)
                                              : '/assets/imgs/no-image.png'
                                          }
                                          borderRadius="full"
                                        />
                                      </Box>
                                      <Box w="80%" maxW="8rem" isTruncated>
                                        <Text
                                          className="textOverflow"
                                          fontSize=".875rem"
                                          fontWeight="600"
                                          title={step.fullname}
                                        >
                                          {step.fullname
                                            ? step.fullname
                                            : "-"}
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                      {step.email ? step.email : "-"}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Box
                                      p=".25rem .5rem"
                                      borderRadius="full"
                                    >
                                      <Text
                                        fontSize=".75rem"
                                        fontWeight="600"
                                      //color="#109E6A"
                                      >
                                        {step.mobileno ? ((step?.countrycode ? step?.countrycode + " " : "") + step.mobileno) : ('-')}
                                      </Text>
                                    </Box>
                                  </Td>

                                  <Td maxW="8rem">
                                    <Text fontSize=".875rem" fontWeight="500" title={step.patientaddress ? step.patientaddress : ""} isTruncated>
                                      {step.patientaddress ? step.patientaddress : "-"}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Image src='/assets/imgs/right-icon.png' />
                                  </Td>


                                </Tr>
                              ))}
                          </>
                        }
                      />
                    </Box>
                  }
                  {PatientSearchedList && PatientSearchedList.length === 0 && (
                    <Flex w='100%' flexDir='column' h='200px' justifyContent='center' alignItems='center' my='1rem' gap='0.5rem'>
                      <Text fontSize='1rem'>
                        {newvisitdisplaytext ? newvisitdisplaytext : "Alternatively, you can use the button below to proceed with adding new patient information."}
                      </Text>
                      <PrimaryButton buttonText='Add New Patient' onClick={ShowVisitForm} variant='mdBtn' btnStyle={{ w: 'fit-content' }} />
                    </Flex>
                  )}
                </>
              }

              {ShowNewVisit &&
                <>
                  <Box display="flex" flexDir="column">
                    <DropdownInput
                      variant="fullRound"
                      dropDownPlace="Doctor Name*"
                      options={DoctorListForDropdown}
                      id="Doctor Name"
                      onClick={HandleEditDoctor}
                      defaultOption={DefaultDoctor}
                      menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                      inputStyleCss={{ w: "100%" }}
                    />
                  </Box>

                  <Box display="flex" alignItems='center' gap='1rem' flexDir={{ base: "column", md: "row" }}>
                    <PrimaryInputDropdown
                      variant="bothSide"
                      boxStyle={{ borderRadius: '0' }}
                      inputStyle={{ borderRadius: "0" }}
                      containerStyle={{ h: '48px' }}
                      onCountryChange={handleCountryChange} // for country codes
                      useLocalStorage={false} // Ensure this is set to false to use the callback
                      defaultCountryCode={parseInt(newVisitData.countryid)}
                      onChange={(e) =>
                        handleNewVisitChange(
                          "mobileno",
                          e.target.value.replace(/\D/g, "").slice(0, 10)
                        )
                      }
                      // onKeyPress={handleMobileKeyPress}
                      inputValue={newVisitData.mobileno}
                    />
                     <Text>or</Text>
                    {screenType == 'web' ? (
                      <>
                        <PrimaryInput
                          type="text"
                          mt={"8px"}
                          // width={"80%"}
                          inputStyleCss={{ w: '100%' }}
                          variant='bothSide'
                          inputPlace="Email Address*"

                          inputValue={newVisitData.email}
                          onChange={(e) =>
                            handleNewVisitChange("email", e.target.value)
                          }
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleFetchPatientStatus("email", e.target.value);
                        //   }
                        // }}
                        />
                        {/* <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "12%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "2px",
                    borderRadius: "0"
                  }}
                  onClick={() =>
                    handleFetchPatientStatus("both")
                  }
                  isDisabled={false}
                /> */}
                      </>
                    ) : (
                      <Box display='flex'>
                        <PrimaryInput
                          type="text"
                          mt={"8px"}
                          // width={"80%"}
                          variant='bothSide'
                          inputPlace="Email Address"
                          inputValue={newVisitData.email}
                          onChange={(e) =>
                            handleNewVisitChange("email", e.target.value)
                          }
                        // onKeyPress={(e) => {
                        //   if (e.key === 'Enter') {
                        //     handleFetchPatientStatus("email", e.target.value);
                        //   }
                        // }}
                        />
                        {/* <PrimaryButton
                  buttonText="Fetch"
                  verticalAlign="bottom"
                  variant="grayBtn"
                  btnStyle={{
                    w: "12%",
                    color: COLORS.PRIMARY_COLOR,
                    verticalAlign: "bottom",
                    ml: "4px",
                    mb: "0px",
                    h: "2.8rem",
                    m: "2px",
                    borderRadius: "0"
                  }}
                  onClick={() =>
                    handleFetchPatientStatus("both")
                  }
                  isDisabled={false}
                /> */}
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" flexDir={{ base: 'column', md: 'row' }}>
                    <PrimaryInput
                      type="text"
                      variant={screenType == 'web' ? 'leftDown' : 'bothSide'}
                      inputPlace="First Name*"
                      inputValue={newVisitData.firstname}
                      onChange={(e) =>
                        handleNewVisitChange("firstname", e.target.value)
                      }
                      width={"30%"}
                    />
                    <Spacer />
                    <PrimaryInput
                      type="text"
                      variant={screenType == 'web' ? 'rightDown' : 'downSide'}
                      inputPlace="Last Name"
                      inputValue={newVisitData.lastname}
                      onChange={(e) =>
                        handleNewVisitChange("lastname", e.target.value)
                      }
                    />
                  </Box>
                  <Box display="flex" alignItems='center' gap='1rem' flexDir={{ base: 'column', md: 'row' }} mt="12px">
                    <DatePicker
                      value={newVisitData.dob ? newVisitData.dob : null}
                      clearValue={true}
                      disableFutureDates={true}
                      onDateChange={(selectedDateValue) => {
                        const formattedDob = moment(selectedDateValue).format("DD-MM-YYYY");
                        const formattedAge = moment(selectedDateValue).format("YYYY-MM-DD");
                        const age = calculateAgeWithYearMonth(formattedAge);
                        handleNewVisitChange('dob', formattedDob); // Set DOB
                        handleNewVisitChange('age', age.years); // Set Age
                        handleNewVisitChange('month', age.months);
                      }}
                      placeholderProp={"Date of Birth"}
                      calendarStlye={{ left: "-18px", right: "unset" }}
                      datePickerInputStyle={{ borderRadius: '10px 0 0 0px' }}
                    />
                    <Text>or</Text>
                    <Box display='flex' w='100%'>
                      {/* <PrimaryInput
                        type="number"
                        variant={"bothSide"}
                        inputPlace="Age in Years"
                        inputValue={newVisitData.age}
                        //isDisabled={true}
                        onChange={(e) => {
                          const age = parseInt(e.target.value, 10); // Convert input to number
                          handleNewVisitChange("age", age);
                          handleNewVisitChange('dob', "");
                        }}
                      /> */}
                      <PrimaryInput
                        type="number"
                        variant={"bothSide"}
                        inputPlace="Age in Years"
                        inputValue={newVisitData.age || ""} // Ensure the input value doesn't show NaN
                        onChange={(e) => {
                          const value = e.target.value; // Get the input value
                          const age = value === "" ? null : parseInt(value, 10); // Handle empty string
                          handleNewVisitChange("age", age);
                          handleNewVisitChange("dob", "");
                        }}
                      />
                    <PrimaryInput
                        type="number"
                        variant={screenType === 'mobile' ? "leftSide" : "bothSide"}
                        inputPlace="Age in Months"
                        inputValue={newVisitData.month || ""} // Ensure the input value doesn't show NaN
                        onChange={(e) => {
                          const value = e.target.value; // Get the input value
                          const age = value === "" ? null : parseInt(value, 10); // Handle empty string
                          handleNewVisitChange("month", age);
                          handleNewVisitChange("dob", "");
                        }}
                      />
                      <DropdownInput
                        variant={screenType == 'web' ? "rightSide" : 'bothSide'}
                        dropDownPlace="Gender"
                        defaultOption={getGender(newVisitData.gender)}
                        options={["Male", "Female", "Other"]}
                        onClick={handleGenderChange}
                        menuList={{ pos: 'relative', right: { base: 'unset', md: '80%' } }}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" flexDir="column">
                    <Textarea
                      borderRadius='0'
                      placeholder="Address"
                      defaultValue={newVisitData.address}
                      onChange={(e) =>
                        handleNewVisitChange("address", e.target.value)
                      }
                    />
                  </Box>
                  <Box display="flex" flexDir={{ base: 'column', md: 'row' }}>
                    <PrimaryInput
                      type="text"
                      variant={screenType == 'web' ? 'leftDown' : 'bothSide'}
                      inputPlace="Emergency Contact Name"
                      inputValue={newVisitData.emergencycontactname}
                      onChange={(e) =>
                        handleNewVisitChange("emergencycontactname", e.target.value)
                      }
                      width={"30%"}
                    />
                    <Spacer />
                    <PrimaryInputDropdown
                      variant="bothSide"
                      boxStyle={{ borderRadius: { base: '0 0 10px 10px', md: '0 0 10px 0' }, h: '48px' }}
                      inputStyle={{ borderRadius: "0" }}
                      containerStyle={{ borderRadius: { base: '0 0 10px 10px', md: '0 0 10px 0' }, h: '48px' }}
                      placeholderProp="Emergency mobile"
                      onCountryChange={handleCountryChangeForEmergency} // for country codes
                      useLocalStorage={false} // Ensure this is set to false to use the callback
                      defaultCountryCode={parseInt(newVisitData.emergencycontactcountryid)}
                      onChange={(e) =>
                        handleNewVisitChange(
                          "emergencycontactphone",
                          e.target.value.replace(/\D/g, "").slice(0, 10)
                        )
                      }
                      // onKeyPress={handleMobileKeyPress}
                      inputValue={newVisitData.emergencycontactphone}
                    />
                    {/* <PrimaryInput
                  type="text"
                  variant='rightDown'
                  inputPlace="Emergency Contact Phone"
                  inputValue={newVisitData.emergencycontactphone}
                  onChange={(e) =>
                    handleNewVisitChange("emergencycontactphone", e.target.value)
                  }
                /> */}
                  </Box>
                  <RadioGroup
                    defaultValue={"Schedule Now"}
                    mt={"12px"}
                    onChange={(value) =>
                      handleNewVisitChange("schedule_later", value)
                    }
                  >
                    <Stack direction="row" gap={{ base: '12px', md: "70px" }} mx={{ base: '0', md: "20%" }}>
                      <Radio colorScheme="green" size={{base:'sm', md:'md'}} value={"Schedule Now"}>Schedule Now</Radio>
                      <Spacer />
                      <Radio colorScheme="green" size={{base:'sm', md:'md'}} value={"Schedule Later"}>Schedule Later</Radio>
                    </Stack>
                  </RadioGroup>
                  {/* Space to Schedule visit */}
                  {newVisitData.schedule_later === "Schedule Later" && (
                    <Box mt={"8px"}>
                      <DatePicker
                        defaultDate={newVisitData.appointment_date}
                        minDate={newVisitData.appointment_date}
                        // disableFutureDates={false}
                        onDateChange={(selectedDateValue) =>
                          handleNewVisitChange(
                            "appointment_date",
                            selectedDateValue
                          )
                        }
                        placeholderProp={"Select Date"}
                        calendarStlye={{ left: "-18px", right: "unset" }}
                        datePickerInputStyle={{ borderRadius: '10px' }}
                      />
                      <NewSlotSelectorNewVisit availableSlots={availableSlots} />

                    </Box>
                  )}
                  <Box display="flex" flexDir={{ base: 'column', md: 'row' }} alignItems={{ base: 'flex-start', md: 'center' }} gap='1rem' mt="12px">
                    <InputGroup>
                      <InputLeftAddon height="48px">{currency}</InputLeftAddon>
                      <PrimaryInput
                        type="number"
                        // inputStyleCss={{w:"50%"}}
                        variant={"bothSide"}
                        inputPlace="Fees"
                        inputValue={newVisitData.fees}
                        onChange={(e) => handleNewVisitChange("fees", e.target.value)}
                      />
                    </InputGroup>

                    <RadioGroup
                      defaultValue={"1"}
                      mt={"12px"}
                      w='100%'
                      onChange={(value) => {
                        handleNewVisitChange("isfreevisit", value);

                        // If value is '1', set fees to 0
                        if (value === "1") {
                          handleNewVisitChange("fees", "0.00"); // Set fees to 0
                        } else
                          handleNewVisitChange("fees", DoctorFeesStatic);
                      }}
                    >
                      <Stack direction="row" gap="1rem" >
                        <Radio colorScheme="green" value={"0"}>Cash</Radio>
                        {/* <Spacer /> */}
                        <Radio colorScheme="green" value={"1"}>Free visit</Radio>
                      </Stack>
                    </RadioGroup>

                  </Box>
                </>
              }
            </Box>
          </>
        }
        modalFooterBtn={
          ShowNewVisit ? (
            <Flex gap="1rem" w="100%">
              {/* Create Visit Button */}
              <PrimaryButton
                variant="grayBtn"
                buttonText={
                  newVisitData.schedule_later === "Schedule Now"
                    ? "Create Visit"
                    : "Create Visit"
                }
                btnStyle={{
                  h: "2.5rem",
                  w: "100%",
                  p: "0",
                  m: "0",
                  bg: COLORS.PRIMARY_GRADIENT,
                  color: COLORS.PRIMARY_WHITE,
                  _hover: { bg: COLORS.PRIMARY_COLOR },
                }}
                onClick={() => handleCreateVisitClick("false")}
                isDisabled={isButtonDisabled}
              />

              {/* Advanced Details Button */}
              <PrimaryButton
                variant="grayBtn"
                buttonText="Advanced Details"
                btnStyle={{
                  h: "2.5rem",
                  w: "100%",
                  p: "0",
                  m: "0",
                  color: "#000",
                }}
                onClick={() => handleCreateVisitClick("true")}
                isDisabled={isButtonDisabled}
              />
            </Flex>
          ) : null
        }
      />
    </>
  );
};

export default VisitDetailsIndex;
