import  FetchData, { } from '../../client';
import {getBranchId, getLoginID, getLoginInfo}  from '../../../auth';

//reac the file

export const getVisitFormDetail = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getvisitformdetail", inputData);
    //console.log(result,"Home db response")
    // if (result.output) {
    // var finalOutput = formapimodalhome(result.output)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export const addVisitFormDetail = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_addvisitformdetail", inputData);
    //console.log(result,"Home db response")
    // if (result.output) {
    // var finalOutput = formapimodalhome(result.output)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};