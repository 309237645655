import { Box, Divider, Flex, Image, Radio, RadioGroup, Slider, Text, Checkbox,
  Stack,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  CheckboxGroup,
  InputRightElement,
  InputGroup,
  Input,
  Step,
  Center,
  VStack,
 } from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from "react";
import { DropdownInput, PrimaryButton, PrimaryInput, Toaster, SearchBar, PrimaryButtonIcon, PrimaryInputDropdown } from "../../components";
import { GetSocialHistoryTypes, AddSocialHistory, addAllergies, uploadImageInProfile,
     getmedicalcondition, addMedicalConditions,
     addSurgeries, FetchTreatmentTeam,
     GetMedicineName, AddMedicine,GetAIMedicineComposition,
     AddFamilyHistory,getmartialstatus, getrelations, FetchAdvanceDetails,
     DeleteSocialHistory, EditFamilyHistory,DeleteFamilyHistory,DeleteMedicine, 
     deleteAllergies,deleteSurgeries,deleteMedicalConditions,
     UpdateSocialHistory,editAllergies,EditCondition,editSurgeries,EditMedicine, GetActivityTypes} from '../../middleware/services/home/newvisitdetails'
import ModalRight from "../../components/modal-right";
import moment from "moment";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import {handleAlphaNumericDotAndSpace, handleAlphaDotAndSpace, validationOfNumberWithOnly1Decimal, validationMobileNumber,
  ftToCmConverter, cmToFtConverter,lbToKgConverter, kgToLbConverter,
  getLoginID,
  formatDate_DD_MM_YYYY,
  useScreenType,
  formatDate_Do_MMM_YYYY
 } from "../../auth";
import DatePicker from "../../components/date-picker";
import { format, setDate } from "date-fns";
import DatePickerMonthYear from "../../components/date-monthyear";
import MiniDropdownInput from "../../components/mini-dropdown";
import { COLORS } from "../../components/styles/colors";
import DynamicModal from "../../components/dynamic-modal";
import DropdownInputForVitals from "../../components/dropdown-input-forVitals";
import ReportViewCard from "../../components/report-card";
import { capitalizeFirstLetter, formatDate_yyyy } from "../../auth";
import { addVisitFormDetail } from '../../middleware/services/home/childscreening';



const PatientScreening = ({patientid, appointmentid, patientData, onSubmit}) => {
  const screenType = useScreenType()
    const bmiCategories = [
        { label: "Underweight", range: "<18.5", color: "red.400", left: screenType === "web" ? "-20%" : "0%" },
        { label: "Normal", range: "18.5-24.9", color: "green.400", left: screenType === "web" ? "10%" : "25%" },
        { label: "Overweight", range: "25-29.9", color: "yellow.400", left: screenType === "web" ? "40%" : "50%" },
        { label: "Obese", range: "30 and above", color: "red.400", left: screenType === "web" ? "70%" : "75%" },
      ];
      var [GetSectionDetails, setGetSectionDetails] = useState(null);
//Social History
  var [typedata, setTypeData] = useState([]);
  var [socialtypedata, setSocialTypeData] = useState([]);
  const addsocialhistory = React.useRef();
  const [type, setType] = useState('');
  const [duration, setDuration] = useState('');
  const [frequency, setFrequency] = useState('');
  const [frequency_cd, setFrequencyTimeID] = useState('327');
  const [type_cd, setTypeID] = useState('');
  const [current, setCurrent] = useState(0);
  const [smokingSelected, setSmokingSelected] = useState(false);
  const [smokeLessSelected, setSmokeLessSelected] = useState(false);
  const [hideFrequency, setHideFrequency] = useState(false);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [yearsDiff, setYearsDiff] = useState('');
  const [showQuitCheckBox, setShowQuitCheckBox] = useState(false);
  const [showIsCurentCheckBox, setShowIsCurentCheckBox] = useState(false);
  const [text, setText] = useState("");
  const [sendSmoklessInInput, setSendSmoklessInInput] = useState(false);
  const [sendDrugTypeInInput, setSendDrugTypeInInput] = useState(false);
  const [smokelessType, setSmokelessType] = useState(null);
  const [drugType, setDrugType] = useState(null);
  const [drugsSelected, setDrugsSelected] = useState(false);
  const [showFrequencyCalculation, setShowFrequencyCalculation] = useState(false);
  var [drugList, setDrugList] = useState(null);
  const opendrugList = React.useRef();
  const [searchValue, setSearchValue] = useState("");
  const [reasonsArraysocial, setReasonsArraySocial] = useState([]);
  const [reasonsArray, setReasonsArray] = useState([]);
  const floatLabel = { color: "#384052", fontSize: "0.775rem", position: "relative", top: "-4px", left: "-4px", opacity: "0.5", width: "100%" };
  var [fromYearOptions, setFromYearOptions] = useState([]);
  var [toYearOptions, setToYearOptions] = useState([]);
  const [radioValue, setRadioValue] = useState("");
  const [fromdate, setFromDate] = useState(moment().format("DD-MM-yyyy"));
  const [todate, setToDate] = useState("");
  const [fromdateYear, setFromDateYear] = useState("")
  const [toDateYear, setToDateYear] = useState("")
  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [showYearsDiffTag, setShowYearsDiffTag] = useState(false)
  const [calculatedConsumption, setCalculatedConsumption] = useState("")
  const [neverSelected, setNeverSelected] = useState(false);
  const [socialreactionsContinueBtnDisabled, setsocialreactionsContinueBtnDisabled] = useState(true)
  const [activityContinueBtnDisabled, setActivityContinueBtnDisabled] = useState(true)
  const [activityArray, setActivityArray] = useState([]);
  const [physicalactivitySelected, SetphysicalactivitySelected] = useState(false);
  const addphysicalactivitytype = React.useRef();
  const [ActivityTypeList, setActivityTypeList] = useState([])
  const [duration_cd, setDurationTimeID] = useState('29');
  const [hideDuration, sethideDuration] = useState(false);

  // Allergy Year States
  var [socialYearOptions, setSocialYearOptions] = useState([]);
    const [socialDate, setSocialDate] = useState(moment().format("DD-MM-yyyy"));
    const [socialDateYear, setSocialDateYear] = useState({})
    var [fromYearEditOptions, setFromYearEditOptions] = useState([]);
      const [fromEditdate, setFromEditDate] = useState(moment().format("DD-MM-yyyy"));
      const [fromEditdateYear, setFromEditDateYear] = useState("")

  // Surgery Year States
  var [surgeryYearOptions, setSurgeryYearOptions] = useState([]);
    const [surgeryDate, setSurgeryDate] = useState(moment().format("DD-MM-yyyy"));
    const [surgeryDateYear, setSurgeryDateYear] = useState({})
  var [surgeryYearEditOptions, setSurgeryYearEditOptions] = useState([]);
    const [surgeryEditDate, setSurgeryEditDate] = useState(moment().format("DD-MM-yyyy"));
    const [surgeryEditDateYear, setSurgeryEditDateYear] = useState("")

  // Condition Year States
  var [conditionYearOptions, setConditionYearOptions] = useState([]);
    const [conditionsDate, setConditionsDate] = useState(moment().format("DD-MM-yyyy"));
    const [conditionDateYear, setConditionDateYear] = useState({})
    var [conditionYearEditOptions, setConditionYearEditOptions] = useState([]);
    const [conditionEditDate, setConditionEditDate] = useState(moment().format("DD-MM-yyyy"));
    const [conditionEditDateYear, setConditionEditDateYear] = useState("")

  //Allergies
  const addCondition = React.useRef();
  const addCondition2 = React.useRef();
  const addCondition3 = React.useRef();
  var [conditionsList, setConditionsList] = useState(null);
  var [reactionList, setReactionList] = useState(null);
  const [condition, setCondition] = useState('')
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedReactions, setSelectedReactions] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [allergiesContinueBtnDisabled, setAllergiesContinueBtnDisabled] = useState(true)
  const [reactionsContinueBtnDisabled, setReactionsContinueBtnDisabled] = useState(true)
  const [reactionsContinueBtnDisabledEdit, setReactionsContinueBtnDisabledEdit] = useState(true)
  const [reactionsContinueBtnDisabled2, setReactionsContinueBtnDisabled2] = useState(true)
  const [patientallergyid, setPatientallergyid] = useState("");
  const [allergytype, setAllergytype] = useState("");
  const [allergyID, setAllergieID] = useState("");
  const [identifiedData, setIdentifiedData] = useState("")
  const [documentURLs, setDocumentURLs] = useState([]);
  const [allergytype_cd , setAllergytype_cd ] = useState("")
  const [patientAllergyId , setPatientAllergyId ] = useState("")
  const [typedCondition , setTypedCondition] = useState("")
  const [typedReaction , setTypedReaction] = useState("")
  const fileInputRef = useRef(null);
  //Medical Conditions
  const medicalconditions = React.useRef();
  const medicalconditions2 = React.useRef();
  const [data, setData] = useState('')
  var [medicalconditionsList, setMedicalConditionsList] = useState(null);
  const [selectedMedicalConditions, setSelectedMedicalConditions] = useState([]);
  const [typedMedicalCondition , setTypedMedicalCondition] = useState("")
  //Surgeries
  const addSurgery = React.useRef();
  const addSurgeriesModal = React.useRef();
  const [treatmentteamdata, setTreatmentTeamData] = useState("");
  var [surgeriesList, setSurgeriesList] = useState(null);
  const [selectedSurgeries, setSelectedSurgeries] = useState([]);
  
  const [selectedSurgeriesDates, setSelectedSurgeriesDates] = useState({});
  const [id, setId] = useState("");
  const [surgeonName, setSurgeonName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [procedureDate, setProcedureDate] = useState("");
  const [procedure, setProcedure] = useState("");
  const [procedureTitle, setProcedureTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNameForUpdate, setFileNameForUpdate] = useState('')
  const [todaysDate, setTodaysDate] = useState(moment().format("DD-MM-YYYY"));
  const indexMap = {};
  var inputForGet = {};
  const [inputValues, setInputValues] = useState({});
  const [suggestions, setSuggestions] = useState({});
  const [typedSurgeryCondition , setTypedSurgeriesCondition] = useState("")
  const [boolValForSuggestions , setBoolValForSuggestions] = useState(true)
  const [showDocNamesDropdown , setShowDocNamesDropdown] = useState(true)
  const [ inputId , setInputId ] = useState("");
  //Medications

  const detailsmodel = React.useRef();
  const showmedicinename = React.useRef();
  const addmedications = React.useRef();
  const learnMoreModal = React.useRef();
  
  const [selectedImage, setSelectedImage] = useState(null);
  const today = moment().format("YYYY-MM-DD HH:mm:ss.SSS");
  const [prescribedby, setPrescribedBy] = useState(null);
  const [hospitalname, setHospitalname] = useState(null);
  const [medicinename, setMedicineName] = useState("");
  const [Composition, setComposition] = useState("");
  const [medicinedata, setMedicinedata] = useState(null);
  //const [medicineenddata, setMedicineEndDate] = useState('');
  const [dosage, setMedicineDosage] = useState(null);
  const [dosageunit, setMedicineDosageUnit] = useState("mg");
  const [medicinefrequency, setMedicineDosageFrequency] = useState("");
  const [medicinetype, setMedicineType] = useState("Tablet");
  const [morning, setMorning] = useState("0");
  const [setevening, setEvening] = useState("0");
  const [setnight, setNight] = useState("0");
  const [beforefood, setBeforefood] = useState("0");
  const [afterfood, setAfterfood] = useState("0");
  const [filenameArray, setFilenameArray] = useState([]);
  const [showhidesearch, ShowHideSearch] = useState(false);
  const [medicineData, setMedicineData] = useState([])
  const [medicineTypeRadio, setMedicineTypeRadio] = useState("Tablet")
  const [medicinetypeFilter, setMedicineTypeFilter] = useState("All")
  const [filenameforUpload, setFilenameforUpload] = useState([])
  const [daysDiffrence, setDaysDiffrence] = useState("")
  const [activeTab, setActiveTab] = useState(0);
  const [minDateValue, setMinDateValue] = useState("");
  const [benifit, setBenifit] = useState('')
  const [sideEffects, setSideEffects] = useState('')
  const [medicineexpirydate, setMedicineExpiryDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isChecked , setIsChecked] = useState(0)
  const chatContainerRef = useRef(null);
  const [aianswers, setAIAnswers] = useState([]);
  const [remainingquestions, setRemainingQuestions] = useState([]);
  const [aiquestion, setAIAnalysis] = useState(null);
  const [AiInput, setAiInput] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [  boolCheck , setBoolCheck ] = useState(false);
  const [  Medicinealert , setMedicinealert ] = useState(0);
  const [  initialPageLoad , setInitialPageLoad ] = useState(false)
  //Family
 
  var [medicaldata, setMedicalData] = useState([]);
  var [relationdata, setRelationData] = useState([]);
  var [martialstatusdata, setMartialstatusData] = useState([]);
  var [martialstatuscomplete, setMartialstatusCompleteData] = useState([]);
  var [relationscomplete, setRelationsComplete] = useState([]);
  const dynamicModal = React.useRef();
  const AddFamily = React.useRef();
  const EditFamily = React.useRef();
  const Medicalconditionsmodel = useRef();
  const [dateofbirth, setDate] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [relationship_cd, setRelationshipID] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [age, setAge] = useState(null);
  const [maritalstatus, setMaritalstatus] = useState("");
  const [maritalstatus_cd, setMaritalstatusID] = useState(null);
  const [adopted, setAdoptedcheckbox] = useState(false);
  const [deceased, setdeceasedcheckbox] = useState(false);
  const [title, setTitle] = useState('');
  const [gender, setGender] = useState(null);
  const [gender_cd, setGenderCD] = useState("");
  const [height, setHeight] = useState(null)
  const [weight, setWeight] = useState(null)
  const [heightunit_cd, setHeightunit_cd] = useState(151)
  const [weightunit_cd, setWeightunit_cd] = useState(154)
  const [familyId, setFamilyId] = useState('')
  const [email, setEmail] = useState("")
  const [mobileno, setMobileno] = useState("")
  const [bloodtype_cd, setBloodtype_cd] = useState("")
  var [allergiesdata, setAllergiesData] = useState(null);
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [medicationname, setMedicationname] = useState("");
  const allergiesModal = useRef();
  //BMI states 
  const [vitalunitheight, setVitalUnitHeight] = useState("");
  const [vitalunitheight_cd, setVitalUnitHeight_cd] = useState("");
  const [BMICalculatorType, setBMICalculatorType] = useState('standard');
  const [vitalunitweight, setVitalUnitWeight] = useState("");
  const [feetValue , setFeetValue] = useState(null)
  const [inchValue , setInchValue] = useState(null)
  const [inchToCalculate , setInchToCalculate] = useState("")
  const [heightOptionsInBMI, setHeightOptionsInBMI] = useState([]);
  const [weightOptionsInBMI, setWeightOptionsInBMI] = useState([]);
  const [systolicOptionsInBP, setSystolicOptionsInBP] = useState([]);

  const [ weightUnitBMI , setWeightUnitBMI ]= useState("")
  const [ heightUnitBMI , setHeightUnitBMI ]= useState("")
  const [showInchesInput , setShowInchesInput] = useState(false)
  const [feetOptions, setFeetOptions] = useState([]);
  const [inchesOptions, setInchesOptions] = useState([]);
  const [typedAllergy , setTypedAllergy] = useState("")
  const [isAdd , setIsAdd] = useState(true)
  const [time, setTime] = useState(moment().format("HH:mm"));
  const [defaultCountryCode ,setDefaultCountryCode ] = useState("")
  const [ weightToCalculate ,setWeightToCalculate] = useState("")
  const [ calculatedBMI ,setcalculatedBMI] = useState(0)
  const [date, setTodaysDateFamily] = useState(moment().format("YYYY-MM-DD"));

  const [defaultSocialType, setdefaultSocialType] = useState("");
  const [exerciseradioValue, setExerciseRadioValue] = useState("no");
  const [smokeradioValue, setSmokeRadioValue] = useState("no");
  const [alcoholradioValue, setAlcoholRadioValue] = useState("no");
  const [allergyradioValue, setAllergiesRadioValue] = useState("no");
  const [conditionradioValue, setConditionsRadioValue] = useState("no");
  const [surgeryradioValue, setSurgeryRadioValue] = useState("no");
  const [medicationradioValue, setMedicationRadioValue] = useState("no");
  const [familyradioValue, setFamilyRadioValue] = useState("no");
  const [vitalunitheightONPAGE, setVitalUnitHeightONPAGE] = useState("");
  const [vitalunitweightONPAGE, setVitalUnitWeightONPAGE] = useState("");
  const [showInchesInputONPAGE , setShowInchesInputONPAGE] = useState(false)
  
  
  useEffect(()=>{
    getSectionDetails();
  },[])
      const handleChange = (value, type) => {
        // console.log(value , " value")
        // console.log(type , " type")
        if(type == "socialhistory"){
            if(value === "yes"){
                OpenAddSocialHistory('Smoking');
            }else if(value === "no"){
              setSmokeRadioValue("no");
            }
        }else if(type == "alcohol"){
          if(value === "yes"){
            OpenAddSocialHistory('Alcohol');
          }else if(value === "no"){
            setAlcoholRadioValue("no");
          }
        }
        else if(type == "exercise"){
          if(value === "yes"){
            // setExerciseRadioValue("yes");
            // setSaveStates((prevState) => ({
            //   ...prevState,
            //   exerciseRegularly: "Yes", // Dynamically update the key with the selected value
            // }));
            OpenAddSocialHistory('Physical Activity');
          }else if(value === "no"){
            setExerciseRadioValue("no");
            // setSaveStates((prevState) => ({
            //   ...prevState,
            //   exerciseRegularly: "No", // Dynamically update the key with the selected value
            // }));
          }
        }
        else if(type == "allergy"){
            if(value === "yes"){
                openAllergiesModal();
            }else if(value === "no"){
              setAllergiesRadioValue("no");
            }else if(value === "not known"){
              setAllergiesRadioValue("not known");
            }
        }
        else if(type == "medicalconditions"){
            if(value === "yes"){
                openAddConditionModal();
            }else if(value === "no"){
              setConditionsRadioValue("no");
            }
        }
        else if(type == "surgeries"){
          if(value === "yes"){
            openSurgeriesModal();
          }else if(value === "no"){
            setSurgeryRadioValue("no");
          }
      }
      else if(type == "medication"){
        if(value === "yes"){
          addData();
        }else if(value === "no"){
          setMedicationRadioValue("no");
        }
    }
    else if(type == "family"){
      if(value === "yes"){
        addfamilymodel();
      }else if(value === "no"){
        setFamilyRadioValue("no");
      }
  }
      };
      const getSectionDetails = async (question) => {
        showLoader()
        try {
          const result = await FetchAdvanceDetails({
            patientid: patientid,
            visitid: appointmentid,
            doctorid: getLoginID(),
          });

          if(result.output.data != null) {
            setGetSectionDetails(result.output.data.section_two);
          } else {
            setGetSectionDetails(null)
          }
          if(result?.output?.data?.section_two?.patientfamily == null && question?.includes("family")){
            setFamilyRadioValue("No");
          }
          if(result?.output?.data?.section_two?.patientmedicine == null && question?.includes("medication")){
            setMedicationRadioValue("No");
          }
         
          
          if(result?.output?.data?.section_two?.patientallergies == null && question?.includes("allergy")){
            setAllergiesRadioValue("no");
          }
          if(result?.output?.data?.section_two?.patientsurgeries == null && question?.includes("surgery")){
            setSurgeryRadioValue("no");
          }
          if(result?.output?.data?.section_two?.patientmedicalcondition == null && question?.includes("condition")){
            setConditionsRadioValue("no");
          }
          if (result?.output?.data?.section_two?.socialhistory == null && question?.includes("social")) {
            setAlcoholRadioValue("no");
            setSmokeRadioValue("no");
            setExerciseRadioValue("no")
          } else if (result?.output?.data?.section_two?.socialhistory) {
            const hasAlcohol = result.output.data.section_two.socialhistory.some(
              (item) => item.socialhistory === "alcohol"
            );
            const hasSmoke = result.output.data.section_two.socialhistory.some(
              (item) => item.socialhistory === "smoke"
            );
            const hasActivity = result.output.data.section_two.socialhistory.some(
              (item) => item.socialhistory === "physical"
            );
          
            if (!hasAlcohol && !hasSmoke && !hasActivity) {
              setAlcoholRadioValue("no");
              setSmokeRadioValue("no");
              setExerciseRadioValue("no");
            } else if (!hasAlcohol) {
              setAlcoholRadioValue("no");
            } else if (!hasSmoke) {
              setSmokeRadioValue("no");
            }
            else if (!hasActivity) {
              setExerciseRadioValue("no");
            }
          }
          
        } catch (error) {
          console.error("Error in home:", error);
        }finally{
          dismissLoader();
        }
      };
      //Social History
      const OpenAddSocialHistory = async (type) => {
        clearState();
        const result = await GetSocialHistoryTypes({
          type: "socialhistorytype",
        });
        if (result) {
          // Iterate through the options and create an array of formatted strings
          const formattedOptions = result.output.data[0].data.map(option => (
            [option.condition]
          ));
    
          setTypeData(formattedOptions);
        }
        const currentYear = new Date().getFullYear();
        const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
        const fromOptions = fromYears.map((year) => String(year));
        setFromYearOptions(fromOptions);
    
        const toYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
        const toOptions = toYears.map((year) => String(year));
        setToYearOptions(toOptions);
    
        setSocialTypeData(result.output.data[0].data)
        setDuration("")
        setFrequency("")
        setdefaultSocialType(type);
        const data = [type]
        handleTypeInputChange(data, result.output.data[0].data);
        addsocialhistory.current.openRight()
      }
      const handleTypeInputChange = (selectedOption, data) => {
        let foundObject = {}
        if(data){
          foundObject = data && data.find(x => x.condition == selectedOption);
        }else{
          foundObject = socialtypedata && socialtypedata.find(x => x.condition == selectedOption);
        }
        clearState();
        if (foundObject) {
    
          setRadioValue("yes")
          setShowQuitCheckBox(false)
          setShowDateSelection(true)
          setShowIsCurentCheckBox(true)
    
          const currentYear = new Date().getFullYear();
    
          var janOne = "-01-01"
          var fullDate = currentYear + janOne
          setToDate(fullDate)
          setCurrent(1)
          setNeverSelected(false)
          setFrequencyTimeID("327")
          if (foundObject.condition == "Smoking") {
            setText("Packs")
            setSmokingSelected(true)
            setShowFrequencyCalculation(true)
            sethideDuration(true)
            setSmokeLessSelected(false)
            setDrugsSelected(false)
            SetphysicalactivitySelected(false)
          } else if (foundObject.condition == "Alcohol") {
            setText("Drinks")
            setSmokingSelected(true)
            setShowFrequencyCalculation(false)
    
            setDrugsSelected(false)
            sethideDuration(true)
            setSmokeLessSelected(false)
            SetphysicalactivitySelected(false)
          } else if (foundObject.condition == "Smokeless") {
            setText("Cans")
            setSmokingSelected(true)
            // setHideFrequency(true)
            setSmokeLessSelected(true)
            setSendSmoklessInInput(true)
            setShowFrequencyCalculation(false)
            SetphysicalactivitySelected(false)
            sethideDuration(true)
            setDrugsSelected(false)
          }
          else if (foundObject.condition == "Illicit Drugs") {
            //setText("drinks")
    
            setText("Times")
            setSmokingSelected(true)
            setDrugsSelected(true)
            setSmokeLessSelected(false)
            setShowFrequencyCalculation(false)
            setSendDrugTypeInInput(true)
            SetphysicalactivitySelected(false)
            // getDrugList();
            sethideDuration(true)
          }else if (foundObject.condition == "Physical Activity") {
            //setText("drinks")
    
            setText("Days")
            
            setSmokingSelected(true)
            SetphysicalactivitySelected(true)
          }
          else {
            setSmokingSelected(false)
          }
          setTypeID(foundObject.condition_cd)
          setType(selectedOption)
        }
      }
      const handleYesSelected = (value) => {

        if (value == "yes") {
          setRadioValue("yes")
          setShowQuitCheckBox(false)
          setShowDateSelection(true)
          setShowIsCurentCheckBox(true)
    
          const currentYear = new Date().getFullYear();
    
          var janOne = "-01-01"
          var fullDate = currentYear + janOne
          setToDate(fullDate)
          setCurrent(1)
          setNeverSelected(false)
          setFrequencyTimeID("327")
    
          if (type == "Smokeless") {
            setSmokeLessSelected(true)
          }
          if (type == "Illicit Drugs") {
            setDrugsSelected(true)
          }
          if (type == "Physical Activity") {
            SetphysicalactivitySelected(true)
          }
        } else if (value == "currentlyNot") {
    
          setRadioValue("currentlyNot")
          setShowQuitCheckBox(true)
          setShowDateSelection(true)
          // setFromDate("")
          // setFromDateYear("")
          const currentYear = new Date().getFullYear();
          var janOne = "-01-01"
          var fullDate = currentYear + janOne
          // setToDate(fullDate)
          setToDate("")
    
          setNeverSelected(false)
          setShowIsCurentCheckBox(false)
          setCurrent(2)
    
          setFrequencyTimeID("327")
          if (type == "Smokeless") {
            setSmokeLessSelected(true)
          }
          if (type == "Illicit Drugs") {
            setDrugsSelected(true)
          }
          if (type == "Physical Activity") {
            SetphysicalactivitySelected(true)
          }
        } else if (value == "never") {
          setNeverSelected(true)
          setRadioValue("never")
          setShowDateSelection(false)
          setShowQuitCheckBox(false)
          setCurrent(0)
          setShowIsCurentCheckBox(false)
          setYearsDiff('')
          setToDate("")
    
          setFrequency("")
          setFrequencyTimeID("")
          setFromDateYear("")
          setFromDate("")
    
          setCalculatedConsumption("")
          setSmokeLessSelected(false)
          SetphysicalactivitySelected(false)
          setDrugsSelected(false)
          setDrugType(null)
          setSendDrugTypeInInput(false)
          //here
          setSendSmoklessInInput(true)
          setSmokelessType(null)
          if (type == "Illicit Drugs") {
            setDrugsSelected(false)
            // setSendDrugTypeInInput(false)
            setDrugType(null)
            setReasonsArraySocial([])
          }
        }
      };
      const handleSmokelessType = (value) => {
    
        if (value == "Chew") {
          setSmokelessType("chew")
        } else if (value == "Snuff") {
          setSmokelessType("snuff")
        }
      }
      const handleDrugTypeType = (value) => {
    
        if (value == "Smoke") {
          setDrugType("smoke")
        } else if (value == "Snort") {
          setDrugType("snort")
        } else if (value == "ivDrug") {
          setDrugType("ivDrug")
        }
      }
      const openDrugListModal = () => {
        getDrugList();
        //here 
        setSearchValue("")
        opendrugList.current.openRight()
      };
      const removeTag = (index) => {
        const updatedReasonsArray = [...reasonsArraysocial];
        updatedReasonsArray.splice(index, 1);
        setReasonsArraySocial(updatedReasonsArray);
      };
      const handleFromYearChange = (selecteddate) => {
        setFromDateYear(selecteddate)
    
        var janOne = "-05-05"
        var yearWithAddedDayAndMonth = selecteddate + janOne;
        setFromDate(yearWithAddedDayAndMonth);
      };
      const handleSocialStartYearChange = (value) => {
        setFromDateYear(value)
    
        var janOne = "-05-05"
        var yearWithAddedDayAndMonth = value + janOne;
        setFromDate(yearWithAddedDayAndMonth);
      };
      const handleToYearChange = (selecteddate) => {
        setToDateYear(selecteddate)
    
        var janOne = "-05-05"
        var yearWithAddedDayAndMonth = selecteddate + janOne;
        setToDate(yearWithAddedDayAndMonth);
      };
      const handleSocialEndYearChange = (value) => {
        setToDateYear(value)
    
        var janOne = "-05-05"
        var yearWithAddedDayAndMonth = value + janOne;
        setToDate(yearWithAddedDayAndMonth);
      };
      const handleFrequency = (e) => {
        let inputText = e.target.value;
        const maxLength = 3;
        const regex = /^\d+$/;
    
        inputText = inputText.replace(/\./g, '');
        if (inputText === '' || regex.test(inputText)) {
          if (inputText.length <= maxLength) {
            if (inputText.startsWith(0)) {
              inputText = inputText.substring(1);
            }
            setFrequency(inputText);
          }
        }
      }
      const clearState = () => {
        setTypeID('')
        setHideFrequency(false)
        setSmokeLessSelected(false)
        setSmokingSelected(false)
    
        setNeverSelected(false)
        setShowDateSelection(false)
        setShowQuitCheckBox(false)
        setShowIsCurentCheckBox(false)
        setShowQuitCheckBox(false)
        setYearsDiff("")
        setReasonsArraySocial([])
        sethideDuration(false)
        setActivityArray([])
        setDrugsSelected(false)
        setToDateYear("")
        setToDate("")
        setFromDateYear("")
        setFromDate("")
        setShowYearsDiffTag(false)
        setSmokelessType(null)
        setDrugType(null)
        setCalculatedConsumption("")
        setFrequency('')
      }
      const getDrugList = async () => {
        const result = await GetSocialHistoryTypes({
          type: "drug",
        });
        setDrugList(result.output.data[0].data)
      }
      const closeDrugsModal = () => {
        opendrugList.current.closeRight();
      };
      useEffect(() => {
        if (reasonsArraysocial.length === 0) {
            setsocialreactionsContinueBtnDisabled(true);
        } else {
            setsocialreactionsContinueBtnDisabled(false);
    
        }
      }, [reasonsArraysocial]);
      const handleSocialReactionCheckboxChange = (condition) => {
        const isSelected = reasonsArraysocial.some(
          (item) =>
            item.condition === condition.condition
        );
    
        if (isSelected) {
          // If already selected, remove the item from reasonsArray
          setReasonsArraySocial((prevConditions) =>
            prevConditions.filter(
              (item) =>
                item.condition !== condition.condition
            )
          );
        } else {
          // If not selected, add the item to reasonsArray
          setReasonsArraySocial([...reasonsArraysocial, { ...condition }]);
        }
      };

      const [isProcessing, setIsProcessing] = useState(false); // Add state for preventing duplicate clicks
      
      const handleaddsocialhistory = async () => {
        if (isProcessing) return; // Prevent multiple clicks
        setIsProcessing(true); // Set processing state to true
      
        try {
          if (type_cd == "") {
            showErrorToast("Please select any type");
            return;
          } else if (radioValue == "") {
            showErrorToast("Please select a value");
            return;
          } else if (radioValue != "never" && type_cd == "322" && drugType == null) {
            showErrorToast("Please select drug type");
            return;
          } else if (radioValue != "never" && type_cd == "322" && reasonsArraysocial.length == 0) {
            showErrorToast("Please select a drug");
            return;
          } else if (radioValue != "never" && type_cd == "533" && smokelessType == null) {
            showErrorToast("Please select smokeless type");
            return;
          } else if (radioValue != "never" && fromdateYear == "") {
            showErrorToast("Please select start year");
            return;
          } else if (radioValue != "never" && toDateYear == "" && todate == "") {
            showErrorToast("Please select end year");
            return;
          } else if (radioValue != "never" && type_cd != 533 && frequency == "") {
            showErrorToast("Please enter frequency");
            return;
          }
      
          // Show loader while processing
          showLoader();
      
          // Prepare form data
          const formData = {
            loginid: patientid,
            patientid: patientid,
            visitid: appointmentid,
            socialhistory: [
              {
                socialhistorytype_cd: type_cd,
                duration: duration,
                frequency: frequency,
                iscurrent: current,
                frequencytype_cd: frequency_cd,
                durationtype_cd: duration_cd,
                fromdate: fromdate || null,
                todate: todate || null,
                ...(sendSmoklessInInput && { smokelesstype: smokelessType }),
                ...(sendDrugTypeInInput && { drugtype: drugType }),
                ...(reasonsArray.length > 0 && {
                  drugnames: reasonsArray.map((reason) => ({
                    name: reason.condition,
                  })),
                }),
                ...(activityArray.length > 0 && {
                  exercisesdaily: activityArray.map((reason) => ({
                    name: reason.condition,
                  })),
                }),
              },
            ],
          };
      
          // Call the API
          const result = await AddSocialHistory(formData);
      
          if (result) {
            showSuccessToast("Social history added successfully");
            getSectionDetails();
            addsocialhistory.current.closeRight();
          }
        } catch (error) {
          console.error("Error adding social history:", error);
          showErrorToast("Something went wrong. Please try again.");
        } finally {
          dismissLoader();
          setIsProcessing(false); // Reset processing state
        }
      };
      
      const [socialid , setSocialID] = useState("")
      const [socialtitle , setSocialTitle] = useState("")
      const deletesocialhistorymodel = (id, title) => {
        setSocialID(id)
        setSocialTitle(title)
        dynamicModal.current.openModal()
      }
      const deletesocialhistory = async () => {
        const result = await DeleteSocialHistory({
          loginid: patientid,
          id: socialid,
        });
        if (result) {
          showSuccessToast("Social history deleted successfully");
          getSectionDetails("social");
          dynamicModal.current.closeModal();
        }
      }
      //Edit Social
      const [socialduration, setSocialDuration] = useState('');
      const [durationtimespan, setDurationTime] = useState('');
      const [frequencytimespan, setFrequencyTime] = useState('');
      const editsocialhistory = React.useRef();
      
      const [showFrequencyInEditModal, setShowFrequencyInEditModal] = useState(true);
      const [calculatedYearsForEdit, setcalculatedYearsForEdit] = useState("")
      const [fromYearForValidation, setFromYearForValidation] = useState("")
      const [toYearForValidation, setToYearForValidation] = useState("")



      const handleeditsocialhistory = async (item, consumptionYears) => {
        //console.log(item, " item sdf")
         //here
         setSocialID(item.id);
         setType(item.socialhistory);
         setTypeID(item.socialhistorytype_cd);
         setSocialDuration(item.duration);
         setDurationTime(item.durationtype);
         setDurationTimeID(item.durationtype_cd);
         setFrequency(item.frequency);
         setDuration(item.duration);
         if (item.iscurrent == 0) {
           setFrequency('')
         }
         setFrequencyTime(item.frequencytype);
         setFrequencyTimeID(item.frequencytype_cd);
         setCurrent(item.iscurrent);
     
         const currentYear = new Date().getFullYear();
         // const toYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
         // const toOptions = toYears.map((year) => String(year));
         // setToYearOptions(toOptions);
     
         const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
         const fromOptions = fromYears.map((year) => String(year));
         setFromYearOptions(fromOptions);
     
         if (item.iscurrent == 1) {
           setShowIsCurentCheckBox(true)
           setShowQuitCheckBox(false)
           setNeverSelected(false)
           setRadioValue("yes")
           setSmokelessType(item.smokelesstype)
         } else if (item.iscurrent == 2) {
           setShowIsCurentCheckBox(false)
           setShowQuitCheckBox(true)
           setNeverSelected(false)
           setRadioValue("currentlyNot")
           setSmokelessType(item.smokelesstype)
         } else if (item.iscurrent == 0) {
           setNeverSelected(true)
           setShowQuitCheckBox(false)
           setShowIsCurentCheckBox(false)
           setRadioValue("never")
         }
         setDrugType(item.drugtype)
         if (item.socialhistory == "Smoking") {
           setShowFrequencyCalculation(true)
         } else {
     
           setShowFrequencyCalculation(false)
         }
         if (item.smokelesstype || item.socialhistory == "Smokeless") {
           setSmokeLessSelected(true)
         } else {
     
           setSmokeLessSelected(false)
         }
         if (item.iscurrent == 0) {
           setSmokeLessSelected(false)
         }
         if (item.smokelesstype || item.socialhistory == "Illicit Drugs") {
           setSendDrugTypeInInput(true)
         } else {
     
           setSendDrugTypeInInput(false)
         }
     
         if (item.drugnames) {
     
           setReasonsArray([])
           item.drugnames.forEach((drug) => {
             // Assuming each drug has a "name" property
             const newReason = {
               condition: drug.name
             };
             setReasonsArray((prevArray) => [...prevArray, newReason]);
           });
           setDrugsSelected(true)
         } else {
           if (item.socialhistory == "Illicit Drugs" && item.drugtype) {
     
             setDrugsSelected(true)
           } else {
             setDrugsSelected(false)
           }
           setReasonsArray([])
         }
         if (item.exercisesdaily) {

          setActivityArray([])
          item.exercisesdaily?.forEach((drug) => {
            // Assuming each drug has a "name" property
            const newReason = {
              condition: drug.name
            };
            setActivityArray((prevArray) => [...prevArray, newReason]);
          });
          SetphysicalactivitySelected(true)
        } else {
          if (item.socialhistory == "Physical Activity") {
    
            SetphysicalactivitySelected(true)
          } else {
            SetphysicalactivitySelected(false)
          }
          setActivityArray([])
        }
         if (item.socialhistory == "Smoking") {
           setText("Packs")
         } else if (item.socialhistory == "Alcohol") {
           setText("Drinks")
         } else if (item.socialhistory == "Smokeless") {
           setText("Cans")
         } else if (item.socialhistory == "Illicit Drugs") {
           setText("Times")
         }
         else if (item.socialhistory == "Physical Activity") {
           setText("Days")
         }  else {
           setText("Packs")
         }
     
         setcalculatedYearsForEdit(consumptionYears)
         if (item.fromdate) {
           var formattedfromdate = format(item.fromdate, "yyyy")
           setFromDate(formattedfromdate);
           setFromDateYear(formattedfromdate)
         } else {
           setFromDate("");
         }
         if (item.todate) {
           var formattedToDate = format(item.todate, "yyyy")
           setToDate(formattedToDate);
           setToDateYear(formattedToDate)
         }
     
         if (item.fromdate && item.todate) {
           const diffYears = moment(item.todate).diff(moment(item.fromdate), 'years');
     
     
           if (diffYears > 0) {
             setYearsDiff(diffYears)
           } else {
             setYearsDiff("")
           }
         }
     
         const result = await GetSocialHistoryTypes({
           type: "socialhistorytype",
         });
         if (result) {
           // Iterate through the options and create an array of formatted strings
           const formattedOptions = result.output.data[0].data.map(option => (
             [option.condition]
           ));
     
           setTypeData(formattedOptions);
         }
         setSocialTypeData(result.output.data[0].data);
     
         editsocialhistory.current.openRight();
       }
       const handleCheckboxChangeQuit = (event) => {
        if (event.target.checked == true) {
          setCurrent(0);
    
        }
      };

      const [isProcessingEdit, setIsProcessingEdit] = useState(false); // State to prevent duplicate clicks
      const Editsocialhistory = async () => {
        if (isProcessingEdit) return; // Prevent duplicate clicks
        setIsProcessingEdit(true); // Set the processing flag to true

        try {
          // Validation checks
          if (type == "") {
            showErrorToast("Please select any type");
            return;
          } else if (radioValue != "never" && type_cd == "322" && reasonsArray.length == 0) {
            showErrorToast("Please select a drug");
            return;
          } else if (radioValue != "never" && fromdateYear == "") {
            showErrorToast("Please select start year");
            return;
          } else if (radioValue != "never" && toDateYear == "" && todate == "") {
            showErrorToast("Please select end year");
            return;
          } else if (radioValue != "never" && frequency == "") {
            showErrorToast("Please enter frequency");
            return;
          }

          // Show loader while processing
          showLoader();

          // Prepare the form data
          const formData = {
            loginid: patientid,
            visitid: appointmentid,
            id: socialid,
            socialhistorytype_cd: type_cd,
            duration: duration,
            frequency: frequency,
            iscurrent: current,
            frequencytype_cd: frequency_cd,
            durationtype_cd: duration_cd,
            fromdate: fromdate || null,
            todate: todate || null,
            ...(sendSmoklessInInput && { smokelesstype: smokelessType }),
            ...(sendDrugTypeInInput && { drugtype: drugType }),
            ...(reasonsArray.length > 0 && {
              drugnames: reasonsArray.map((reason) => ({
                name: reason.condition,
              })),
            }),
            ...(activityArray.length > 0 && {
              exercisesdaily: activityArray.map((reason) => ({
                name: reason.condition,
              })),
            }),
          };

          // Call the API
          const result = await UpdateSocialHistory(formData);

          if (result) {
            showSuccessToast("Social history updated successfully");
            getSectionDetails();
            editsocialhistory.current.closeRight();
          }
        } catch (error) {
          console.error("Error updating social history:", error);
          showErrorToast("Something went wrong. Please try again.");
        } finally {
          dismissLoader();
          setIsProcessingEdit(false); // Reset processing flag
        }
      };

      useEffect(() => {
        if (fromYearForValidation) {
          const currentYear = new Date().getFullYear();
          const fromYear = parseInt(fromYearForValidation); // Convert to number
          const toYears = Array.from({ length: currentYear - fromYear + 1 }, (_, index) => fromYear + index);
          const toOptions = toYears.map((year) => String(year));
          setToYearOptions(toOptions);
        }
      }, [fromYearForValidation]);
      useEffect(() => {
        if (toYearForValidation) {
          const toYears = Array.from({ length: 101 }, (_, index) => toYearForValidation - index);
          const toOptions = toYears.map((year) => String(year));
          setFromYearOptions(toOptions);
        }
      }, [toYearForValidation, socialid])
      useEffect(() => {
        setFromYearForValidation(fromdateYear)
        setToYearForValidation(toDateYear)
    
      }, [fromdateYear, toDateYear])
      useEffect(() => {
        if (activityArray.length === 0) {
          setActivityContinueBtnDisabled(true);
        } else {
          setActivityContinueBtnDisabled(false);
    
        }
      }, [activityArray]);
      const handleActivityCheckboxChange = (condition) => {
        const isSelected = activityArray.some(
          (item) =>
            item.condition === condition.condition
        );
    
        if (isSelected) {
          setActivityArray((prevConditions) =>
            prevConditions.filter(
              (item) =>
                item.condition !== condition.condition
            )
          );
        } else {
          // If not selected, add the item to reasonsArray
          setActivityArray([...activityArray, { ...condition }]);
        }
      };
      const removeActivityTag = (index) => {
        const updatedReasonsArray = [...activityArray];
        updatedReasonsArray.splice(index, 1);
        setActivityArray(updatedReasonsArray);
      };
      const getActivityTypeList = async () => {
        const result = await GetActivityTypes({
          type: "physicalactivity_cd",
        });
        setActivityTypeList(result.output.data[0].data)
    
      }
      const openActivityListModal = () => {
        getActivityTypeList();
        //here 
        setSearchValue("")
        addphysicalactivitytype.current.openRight()
      };
      const handleDuration = (e) => {
        const inputText = e.target.value;
        const maxLength = 3;
    
        if (inputText.length <= maxLength) {
          setDuration(inputText);
        }
      }
      //Allergies
      const openAllergiesModal = () => {
        fetchAllergiesList();
         setReasonsArray([])
         setSearchValue("");
         setSelectedConditions([]);
         setSelectedReactions([]);
         setUploadedDocuments([])
         setDocumentURLs([])
         setTypedCondition("")
         setTypedReaction("")
         addCondition.current.openRight();
       };
       useEffect(() => {
   
        if (selectedConditions.length === 0) {
          setAllergiesContinueBtnDisabled(true);
        } else {
          setAllergiesContinueBtnDisabled(false);
        }
      }, [selectedConditions]);
      useEffect(() => {
        let filteredReactions = reasonsArray.filter(item => item.allergy_id == allergyID)
        if(filteredReactions.length > 0 ){
          setReactionsContinueBtnDisabled2(false)
        }else{
          setReactionsContinueBtnDisabled2(true)
        }
       }, [reasonsArray]);
       const getUniqueObjects = (arr, key) => {
        return arr.reduce((acc, current) => {
          if (!acc.some(item => item[key] === current[key])) {
            acc.push(current);
          }
          return acc;
        }, []);
      };
       useEffect(() => {
        const uniqueObjects = getUniqueObjects(reasonsArray, 'allergy_id');
        if(reasonsArray.length > 0 && uniqueObjects.length > 0){
          if(selectedConditions.length == uniqueObjects.length ){
            setReactionsContinueBtnDisabled(false);
          }else {
            setReactionsContinueBtnDisabled(true);
          }
        }
      }, [reasonsArray]);
      const handleCheckboxChange = (condition) => {
        const isSelected = selectedConditions.some(
          (item) => item.condition_cd === condition.condition_cd
        );
    
        if (isSelected) {
          setSelectedConditions((prevConditions) =>
            prevConditions.filter(
              (item) => item.condition_cd !== condition.condition_cd
            )
          );
        } else {
          setSelectedConditions([...selectedConditions, condition]);
        }
      };
      const handleReactionCheckboxChange = (condition, allergyid) => {
        const isSelected = reasonsArray.some(
          (item) =>
            item.allergy_id === allergyid &&
            item.condition_cd === condition.condition_cd
        );
    
        if (isSelected) {
          // If already selected, remove the item from reasonsArray
          setReasonsArray((prevConditions) =>
            prevConditions.filter(
              (item) =>
                item.allergy_id !== allergyid ||
                item.condition_cd !== condition.condition_cd
            )
          );
        } else {
          // If not selected, add the item to reasonsArray
          setReasonsArray([...reasonsArray, { ...condition, allergy_id: allergyid }]);
        }
      };
      const handleCondition = (e) => {
        const inputText = e.target.value;
        const maxLength = 80;
      
        if (inputText.length <= maxLength) {
          setTypedCondition(inputText);
        }
        if(e.target.value){
          setAllergiesContinueBtnDisabled(false)
        }else{
          setAllergiesContinueBtnDisabled(true)
        }
      
      }
      const handleReaction = (e) => {
        const inputText = e.target.value;
        const maxLength = 80;
        
        if (inputText.length <= maxLength) {
          setTypedReaction(inputText);
        }
        if(e.target.value){
          setReactionsContinueBtnDisabled2(false)
          setReactionsContinueBtnDisabledEdit(false)
        }else{
          setReactionsContinueBtnDisabled2(true)
          setReactionsContinueBtnDisabledEdit(true)
        }
      
      }
      const AddReactionsInInput = (allergyid) => {
        // enter manually 
      
        if (typedReaction !== "") {
         const newSelectedCondition = {
           condition:typedReaction,
           allergy_id:allergyid,
           condition_cd:null,
           conditiontype:"other"
         };
     
         setReasonsArray((prevConditions) => {
           // Filter out any existing condition with the same type
           // const filteredConditions = prevConditions.filter(
           //   (item) => item?.conditiontype !== "other"
           // );
           // Add the new condition
           return [...prevConditions, newSelectedCondition];
         });
       }
   
       //--------------------------------
   
       addCondition3.current.closeRight();
      // const selectedReactionsArray = Object.values(selectedReactions);
      // handleReactionChange(allergyid, selectedReactionsArray);
     };
     const handleDateChange = (conditionCd, date) => {

        const formattedDate = format(date, "yyyy-MM-dd");
        const formattedYear = format(date, 'yyyy');
        setSocialDateYear((prevYears) => ({
          ...prevYears,
          [conditionCd]: formattedYear, // Store the year for the specific conditionCd
        }));
        //setSocialDateYear(formattedYear)
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: formattedDate,
        }));
      };
      const handleAllergyYearChange = (conditionCd, selecteddate) => {
        // console.log('selectedDate: ', selecteddate)
        //setSocialDateYear(selecteddate)
        // setFromEditDateYear(selecteddate)
    
        var janOne = "-02-01"
        var yearWithAddedDayAndMonth = selecteddate + janOne;
        //setSocialDate(yearWithAddedDayAndMonth);
        setSocialDateYear((prevYears) => ({
          ...prevYears,
          [conditionCd]: selecteddate, // Store the year for the specific conditionCd
        }));
        // const formattedDate = format(date, "yyyy-MM-dd");
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: yearWithAddedDayAndMonth,
        }));
      };
      
      useEffect(() => {
        setSocialDateYear((prevYears) => {
          const initialYears = selectedConditions.reduce((acc, condition) => {
            if (!(condition.condition_cd in prevYears)) {
              acc[condition.condition_cd] = ""; // Initialize only new conditions
            } else {
              acc[condition.condition_cd] = prevYears[condition.condition_cd]; // Retain existing values
            }
            return acc;
          }, {});
          return initialYears;
        });
      }, [selectedConditions]);
      
      
   
      const handleAllergyEditYearChange = (conditionCd, selecteddate) => {
          // console.log('selectedDate: ', selecteddate)
          // const date = selecteddate.includes('02-01');
          // if(selecteddate === date ){
            // alert('if hit')
            setFromEditDateYear(selecteddate)
      
            var janOne = "-02-01"
            var yearWithAddedDayAndMonth = selecteddate + janOne;
            let formateddate = moment(yearWithAddedDayAndMonth, 'YYYY-MM-DD').format('DD-MM-YYYY');
            
            setDateInEdit(formateddate)
            setFromEditDate(yearWithAddedDayAndMonth);
            setSelectedDates((prevDates) => ({
              ...prevDates,
              [conditionCd]: yearWithAddedDayAndMonth,
            }));
        };

      const showReactionList = (allergyid) => {
        let filteredReactions = reasonsArray.filter(item => item.allergy_id == allergyid)
      if(filteredReactions.length > 0 ){
         setReactionsContinueBtnDisabled2(false)
       }else{
         setReactionsContinueBtnDisabled2(true)
       }
       // if (reactionList == null) {
          fetchReactionsList();
       // }
       setTypedCondition("")
       setTypedReaction("")
        setSearchValue("")
        setAllergieID(allergyid);
        addCondition3.current.openRight();
      };
      const removeReaction = (allergyId, reactionId) => {
        setReasonsArray((prev) =>
          prev.filter(
            (tag) => !(tag.allergy_id === allergyId && tag.condition_cd === reactionId)
          )
        );
      };
      const handleDelete = (index) => {
        const updatedUrls = [...uploadedDocuments];
        updatedUrls.splice(index, 1);
        setUploadedDocuments(updatedUrls);
      };
      const addSurgeriesUploadDocument = (condition_cd) => {

 

        if(condition_cd){
          
          fileInputRef.current.setAttribute('data-surgery-cd', condition_cd);
          fileInputRef.current.click();
          
        }else{
          
          fileInputRef.current.setAttribute('data-surgery-cd', null);
          fileInputRef.current.setAttribute('data-surgery',  "other");
          fileInputRef.current.click();
        }
    
      }
      const handleAllergiesUpload = async (e) => {
        e.preventDefault();
    
        let condition_cd = e.target.getAttribute('data-surgery-cd');
        if(condition_cd === "null"){
          condition_cd = null
         }
        showLoader();
    
        const file2 = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file2);
        const result = await uploadImageInProfile(file2, file2.name);
        const filename = result.name;
        setUploadedDocuments((prevDocuments) => [
          ...prevDocuments,
          { condition_cd, filename },
        ]);
        showSuccessToast("Document Uploaded Successfully.");
        e.target.value = '';
        dismissLoader();
    
      };

      const [isProcessingAdd, setIsProcessingAdd] = useState(false); // State to prevent duplicate clicks

      const AddMedicalConditionsCall = () => {
        const addConditions = async () => {
          if (isProcessingAdd) return; // Prevent duplicate clicks
          setIsProcessingAdd(true); // Set the processing flag to true
          showLoader();

          try {
            const result = await addAllergies(inputForAdd);
            if (result.output.result === "success") {
              showSuccessToast("Successfully Added");
              getSectionDetails();
              addCondition2.current.closeRight();
              addCondition.current.closeRight();
              setAllergieID("");
              setSocialDateYear("")
            } else {
              showErrorToast("Something went wrong!");
            }
          } catch (error) {
            console.error("Error in allergies:", error);
            showErrorToast("An error occurred. Please try again.");
          } finally {
            dismissLoader();
            setIsProcessingAdd(false); // Reset processing flag
          }
        };

        const isDateMissing = selectedConditions.some(
          (condition) => !selectedDates[condition.condition_cd]
        );

        const isReactionMissing = selectedConditions.some((condition) =>
          !reasonsArray.some((reason) => reason.allergy_id === condition.condition_cd)
        );

        if (isDateMissing) {
          showErrorToast("Please Select the Date");
          return;
        } else if (isReactionMissing) {
          showErrorToast("Please Select the reaction");
          return;
        } else {
          addConditions();
        }
      };

      var inputForAdd = {
        patientid: patientid,
        visitid: appointmentid,
        allergy: selectedConditions?.map((condition, index) => ({
          condition: condition.condition,
          condition_cd: condition.condition_cd,
          allergytype_cd: condition.condition_cd,
          allergyidentifieddate: selectedDates[condition.condition_cd] || "",
          ...(condition.conditiontype && { conditiontype: condition.conditiontype }),
          reactions:
            reasonsArray
              .filter((reaction) => reaction.allergy_id === condition.condition_cd)
              .map((filteredReaction) => ({
                reaction_cd: filteredReaction.condition_cd,
                reaction: filteredReaction.condition,
              })) || [],
          documents: uploadedDocuments
            .filter((doc) => doc.condition_cd == condition.condition_cd)
            .map(({ condition_cd, filename }) => ({
              condition_cd,
              filename,
            })),
        })),
      };
      const AddMedicalConditions = () => {
        if (selectedConditions.length === 0 && typedCondition == "") {
          showErrorToast("Please Select a Allergy to Continue");
        } else {
            // enter manually 
            if (typedCondition !== "") {
              const newSelectedCondition = {
                condition:typedCondition,
                condition_cd:null,
                conditiontype:"other"
              };
          
              setSelectedConditions((prevConditions) => {
                // Filter out any existing condition with the same type
                const filteredConditions = prevConditions.filter(
                  (item) => item?.conditiontype !== "other"
                );
                // Add the new condition
                return [...filteredConditions, newSelectedCondition];
              });
            }
      
            //--------------------------------
          setSelectedDates({})
          setSocialDateYear({})
          addCondition2.current.openRight();
        }
      };
      const fetchReactionsList = async () => {
        try {
          const result = await getmedicalcondition({
            type: "reaction",
            patientid: patientid
          });
          if (result.output.result == "success") {
            setReactionList(result.output.data);
          } else {
            showErrorToast("Something went wrong!")
          }
        } catch (error) {
          console.error("Error", error);
        }
      };
      const fetchAllergiesList = async () => {
        try {
          const result = await getmedicalcondition({
            type: "allergies",
            patientid: patientid
          });
          if (result.output.result == "success") {
            setConditionsList(result.output.data);

            const currentYear = new Date().getFullYear();
        const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
        const fromOptions = fromYears.map((year) => String(year));
        setSocialYearOptions(fromOptions);
        setFromYearEditOptions(fromOptions)
          } else {
            showErrorToast("Something went wrong!")
          }
        } catch (error) {
          console.error("Error", error);
        }
      };
      const [allergyid, setAllergyID] = useState('');
      const [allergytitle, setAllergyTitle] = useState('');
      const dynamicModalAllergy = React.useRef();
      const openDeleteModalAllergy = (id, allergy) => {
        setPatientallergyid(id);
        setAllergytype(allergy);
        dynamicModalAllergy.current.openModal();
      };
      const deleteAllergy = async () => {
        var inputForDelete = {
          patientallergyid,
          "patientid":patientid
        };
          showLoader();
          try {
            const result = await deleteAllergies(inputForDelete);
            if (result.output.result == "success") {
              //showErrorToast(allergytype + " has been Deleted");
              getSectionDetails("allergy");
              dynamicModalAllergy.current.closeModal();
            } else {
              showErrorToast("Something went Wrong!")
            }
          } catch (error) {
            console.error("Error in DashboardIndex:", error);
          }
    
      };
      //Edit Allergy
      const editConditionModal = React.useRef();
      const reactionListModal = React.useRef();
      const [selectedConditionsInEdit, setSelectedConditionsInEdit] = useState([]);
      const [dateInEdit , setDateInEdit] = useState("")
      const openEditAllergyModal = (  patientallergyid, allergytype, allergyidentifieddate,
        reaction,
        allergytype_cd)=>{
          const currentYear = new Date().getFullYear();
      const fromEditYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
      const fromEditOptions = fromEditYears.map((year) => String(year));
      setFromYearEditOptions(fromEditOptions);

          setSelectedConditionsInEdit([])
          setAllergytype_cd(allergytype_cd)
          setPatientAllergyId(patientallergyid)
        var formatedDateInEdit =  moment(allergyidentifieddate).format("DD-MM-YYYY")
        // console.log('formatedDateInEdit : ', formatedDateInEdit)
        var formatedYearInEdit = moment(allergyidentifieddate).format("yyyy")
        // console.log('formatedYearInEdit : ', formatedYearInEdit)
        setDateInEdit(formatedDateInEdit)
        setFromEditDateYear(formatedYearInEdit)
        setReasonsArray([])
        
    
        reaction.forEach((item) => {
          // Assuming each drug has a "name" property
          const newReason = {
            reaction: item.reaction,
            reaction_cd: item.reaction_cd,
          };
          setReasonsArray((prevArray) => [...prevArray, newReason]);
        });
          setSelectedConditionsInEdit(allergytype)
    
        editConditionModal.current.openRight();
      }
      const handleDateChangeInEdit = ( date) => {
        // console.log('date: ', date)
    var formatedYearInEdit = moment(date).format("yyyy")
    setDateInEdit(date)
    // setYearInEdit(formatedYearInEdit)
    setFromEditDateYear(formatedYearInEdit)
      };
      const showReactionListInEdit = () => {
        // if (reactionList == null) {
           fetchReactionsList();
       //  }
         setSearchValue("")
         reactionListModal.current.openRight();
       };
       const handleReactionCheckboxChangeInEdit = (reaction) => {
        // Check if the reaction object has condition and condition_cd attributes
        if (reaction.hasOwnProperty('condition') && reaction.hasOwnProperty('condition_cd')) {
          // Convert to reaction and reaction_cd attributes
          reaction = {
            reaction: reaction.condition,
            reaction_cd: reaction.condition_cd
          };
        }
      
        const isSelected = reasonsArray.some((item) => item.reaction === reaction.reaction);
      
        if (isSelected) {
          // If already selected, remove the item from reasonsArray
          setReasonsArray((prevConditions) =>
            prevConditions.filter((item) => item.reaction !== reaction.reaction)
          );
        } else {
          // If not selected, add the item to reasonsArray
          setReasonsArray([...reasonsArray, reaction]);
        }
      };
      
      const editReactions = ()=>{
       
          // enter manually 
          if (typedReaction !== "") {
            const newSelectedCondition = {
              reaction:typedReaction,
              reaction_cd:null,
              conditiontype:"other"
            };
        
            setReasonsArray((prevConditions) => {
              // Filter out any existing condition with the same type
              const filteredConditions = prevConditions.filter(
                (item) => item?.conditiontype !== "other"
              );
              // Add the new condition
              return [...filteredConditions, newSelectedCondition];
            });
          }
      
          //--------------------------------
      
        reactionListModal.current.closeRight()
      }

       const EditMedicalConditionsCall = () => 
        {
          if (isProcessingEdit) return; // Prevent multiple clicks
          setIsProcessingEdit(true); // Set the processing flag to true

          let formatedproceduredate = moment(dateInEdit, "DD-MM-YYYY").format("YYYY-MM-DD");

          var inputForEdit = {
            patientid: patientid,
            visitid: appointmentid,
            allergytype_cd,
            allergyidentifieddate: formatedproceduredate,
            patientallergyid: patientAllergyId,
            reactions: reasonsArray,
            documents: uploadedDocuments.map(({ condition_cd, filename }) => ({
              condition_cd,
              filename,
            })),
          };

          const editConditions = async () => {
            showLoader();
            try {
              const result = await editAllergies(inputForEdit);

              if (result.output.result === "success") {
                // showSuccessToast("Successfully Updated");
                getSectionDetails();
                reactionListModal.current.closeRight();
                editConditionModal.current.closeRight();
                setAllergieID("");
              } else {
                showErrorToast("Something went wrong!");
              }
            } catch (error) {
              console.error("Error in allergies:", error);
              showErrorToast("An error occurred. Please try again.");
            } finally {
              dismissLoader();
              setIsProcessingEdit(false); // Reset processing state
            }
          };

          editConditions();
        };

      const removeTagAllergy = (index) => {
        const updatedReasonsArray = [...reasonsArray];
        updatedReasonsArray.splice(index, 1);
        setReasonsArray(updatedReasonsArray);
      };
      useEffect(() => {
        if (reasonsArray.length === 0) {
          setReactionsContinueBtnDisabledEdit(true);
        } else {
          setReactionsContinueBtnDisabledEdit(false);
        }
      }, [reasonsArray]);
      //Medical Conditions
      const openAddConditionModal = () => {
        // if (conditionsList == null) {
          fetchMedicalConditionList();
        // }
        setSearchValue('')
        setSelectedConditions([]);
        setSelectedMedicalConditions([]);
        // setConditionsList([])
        setSelectedDates({})
        setTypedCondition("")
        medicalconditions.current.openRight()
      }
      const fetchMedicalConditionList = async () => {
        try {
          const result = await getmedicalcondition({
            type: "healthcondition",
            patientid: patientid
          });
          if (result.output.result == "success") {
            setMedicalConditionsList(result.output.data);

            const currentYear = new Date().getFullYear();
        const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
        const fromOptions = fromYears.map((year) => String(year));
        setConditionYearOptions(fromOptions);
          } else {
            showErrorToast("Something went wrong!")
          }
        } catch (error) {
          console.error("Error", error);
        }
      };
      const handleCheckboxChangeMedicalConditions = (condition, category) => {
        const isSelected = selectedMedicalConditions.some(
          (item) => item.condition_cd === condition.condition_cd
        );
    
        if (isSelected) {
          setSelectedMedicalConditions((prevConditions) =>
            prevConditions.filter(
              (item) => item.condition_cd !== condition.condition_cd
            )
          );
        } else {
            setSelectedMedicalConditions((prevConditions) => [
            ...prevConditions,
            { ...condition, category },
          ]);
        }
      };
      const handleMedicalCondition = (e) => {
        const inputText = e.target.value;
        const maxLength = 80;
      
        if (inputText.length <= maxLength) {
            setTypedMedicalCondition(inputText);
        }
      
      }
      const handleDateChangeMedicalCondition = (conditionCd, date) => {
        const formattedDate = format(date, "yyyy-MM-dd");
        const formattedYear = format(date, 'yyyy');
        //setConditionDateYear(formattedYear)
        setConditionDateYear((prevYears) => ({
          ...prevYears,
          [conditionCd]: formattedYear, // Store the year for the specific conditionCd
        }));
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: formattedDate,
        }));
      };
      const handleConditionYearChange = (conditionCd, selectedDate) => {
      
        const janOne = "-02-01";
        const yearWithAddedDayAndMonth = selectedDate + janOne;
      
        setConditionDateYear((prevYears) => ({
          ...prevYears,
          [conditionCd]: selectedDate, // Store the year for the specific conditionCd
        }));
      
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: yearWithAddedDayAndMonth,
        }));
      };
      useEffect(() => {
        setConditionDateYear((prevYears) => {
          const initialYears = selectedMedicalConditions.reduce((acc, condition) => {
            if (!(condition.condition_cd in prevYears)) {
              // Only initialize new conditions
              acc[condition.condition_cd] = ""; 
            } else {
              acc[condition.condition_cd] = prevYears[condition.condition_cd];
            }
            return acc;
          }, {});
          return initialYears;
        });
      }, [selectedMedicalConditions]);
      
  const handleConditionEditYearChange = (conditionCd, selecteddate) => {
    // const date = selecteddate.includes('02-01');
    // if(selecteddate === date ){
      // alert('if hit')
      setConditionEditDateYear(selecteddate)

      var janOne = "-02-01"
      var yearWithAddedDayAndMonth = selecteddate + janOne;
      let formateddate = moment(yearWithAddedDayAndMonth, 'YYYY-MM-DD').format('DD-MM-YYYY');
      
      setConditionDate(formateddate)
      setConditionEditDate(yearWithAddedDayAndMonth);
      setConditionEditDateYear(selecteddate)
      setSelectedDates((prevDates) => ({
        ...prevDates,
        [conditionCd]: {
          date: yearWithAddedDayAndMonth,
          surgeonName: prevDates[conditionCd]?.surgeonName || "",
          hospitalName: prevDates[conditionCd]?.hospitalName || "",
          fileName
        },
      }));
  };
      var inputForAddcondition = {
        patientid: patientid,
        visitid: appointmentid,
        medicalcondition: selectedMedicalConditions.map((condition) => ({
          conditiontype_cd: condition?.category?.conditiontype_cd ,
          condition_cd: condition.condition_cd,
          nomenclatureid: condition.condition_cd,
          conditionidentifieddate: selectedDates[condition.condition_cd] || "",
          "condition":condition.condition,
          "conditiontype":condition.category.conditiontype
        })),
      };
      const AddConditions = () => {
        if (selectedMedicalConditions.length === 0 && typedMedicalCondition == "") {
          showErrorToast("Please Select a Condition to Continue")
        } else {
          if (typedMedicalCondition !== "") {
            const newSelectedCondition = {
              category: {
                conditiontype: "other",
                conditiontype_cd: null,
              },
              condition: typedMedicalCondition,
              condition_cd: null,
            };
        
            setSelectedMedicalConditions((prevConditions) => {
              // Filter out any existing condition with the same type
              const filteredConditions = prevConditions.filter(
                (item) => item.category.conditiontype !== "other"
              );
              // Add the new condition
              return [...filteredConditions, newSelectedCondition];
            });
          }
         
          setSelectedDates({})
          setConditionDateYear({})
          medicalconditions2.current.openRight()
        }
    
      }      
      const AddConditionsCall = () => {
        if (isProcessingAdd) return; // Prevent multiple clicks
        setIsProcessingAdd(true); // Set the processing flag to true
      
        const addConditions = async () => {
          showLoader();
          try {
            console.log(inputForAddcondition, " inputForAddcondition");
      
            const result = await addMedicalConditions(inputForAddcondition);
      
            if (result.output.result === "success") {
              showSuccessToast("Condition added Successfully!");
              setTimeout(() => {
                getSectionDetails();
                medicalconditions.current.closeRight();
                medicalconditions2.current.closeRight();
              }, 700);
              setConditionDateYear('')
            } else {
              showErrorToast("Something went wrong!");
            }
          } catch (error) {
            console.error("Error in DashboardIndex:", error);
            showErrorToast("An error occurred. Please try again.");
          } finally {
            dismissLoader();
            setIsProcessingAdd(false); // Reset processing state
          }
        };
      
        const isDateMissing = selectedMedicalConditions.some(
          (condition) => !selectedDates[condition.condition_cd]
        );
      
        if (isDateMissing) {
          showErrorToast("Please Select the Date");
          dismissLoader();
          setIsProcessingAdd(false); // Reset the state immediately
          return;
        } else {
          addConditions();
        }
      };
      
      const [conditionid, setConditionID] = useState('');
      const [conditiontitle, setConditionTitle] = useState('');
      const dynamicModalConditions = React.useRef();

      const deleteMedicalCondition = async () => {
        var inputForDelete = {
          id: conditionid,
          patientid:patientid
        }
          showLoader();
          try {
            const result = await deleteMedicalConditions(inputForDelete);
            if (result.output.result == "successfully deleted") {
              getSectionDetails("condition");
              dynamicModalConditions.current.closeModal();
            } else {
              showErrorToast("Somethnig went wrong!")
            }
    
          } catch (error) {
            console.error("Error in delete:", error);
          }
          dismissLoader();
      }
    
      const openDeleteModalConditions = (id, condition) => {
        setConditionID(id)
        setConditionTitle(condition)
    
        dynamicModalConditions.current.openModal();
    
      }
      const handleDateChangeEditModel = (date) => {
        var formatedYearInEdit = moment(date).format("yyyy") 
        setConditionDate(date)
        setConditionEditDateYear(formatedYearInEdit)

      };
      const [conditionType  , setConditionType] = useState("")
      const [ConditionTypeID, setConditionTypeID] = useState('');
      const [ConditionName, setConditionName] = useState('')
      const [ConditionDate, setConditionDate] = useState('')
      const editCondition = React.useRef();
      const addConditionModalInsideEdit = React.useRef();
      const [ConditionIDMedical, setConditionIDMedical] = useState('')

      const openEditModalCondition = (condition, conditionid, date, id, type , type_cd) => {
        const currentYear = new Date().getFullYear();
      const fromEditYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
      const fromEditOptions = fromEditYears.map((year) => String(year));
      setConditionYearEditOptions(fromEditOptions);

        fetchMedicalConditionList();
        setConditionType(type)
        setTypedCondition("")
        // if(type == "Other"){
        //   setTypedCondition(condition)
        // }
        setConditionID(id)
        setConditionTypeID(type_cd)
        setConditionName(condition)
        setConditionIDMedical(conditionid)
        const conditiondate =  moment(date, 'YYYY-MM-DD').format('DD-MM-yyyy');
        var formatedYearInEdit = moment(conditiondate).format("yyyy") 
        setConditionDate(conditiondate)
      setConditionEditDateYear(formatedYearInEdit)

        const newSelectedConditions = [{
          conditiontype_cd: type_cd,
          condition: condition,
           category: "" // Assuming you want to store the category of the condition as well
        }];
        setSelectedConditions(newSelectedConditions);
        editCondition.current.openRight()
      }
     
      const [isProcessingUpdate, setIsProcessingUpdate] = useState(false); // State to prevent duplicate clicks

      const UpdateCondition = async () => {
        if (isProcessingUpdate) return; // Prevent duplicate clicks
        setIsProcessingUpdate(true); // Set processing state to true
        console.log(ConditionDate, "conditionDate")

        var inputForEdit = {
          condition_cd: ConditionIDMedical
            ? ConditionIDMedical
            : selectedConditions[0]?.condition_cd,
          conditiontype_cd: ConditionTypeID
            ? ConditionTypeID
            : selectedConditions[0]?.category.conditiontype_cd,
          conditionidentifieddate: ConditionDate,
          id: conditionid,
          patientid: patientid,
          visitid: appointmentid,
          condition: ConditionName,
          conditiontype: conditionType,
        };

        if (
          moment(inputForEdit.conditionidentifieddate, "DD-MM-YYYY", true).isValid()
        ) {
          inputForEdit.conditionidentifieddate = moment(
            inputForEdit.conditionidentifieddate,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
        }

        if (inputForEdit.conditionidentifieddate === "") {
          showErrorToast("Please select any date");
          setIsProcessingUpdate(false); // Reset state
          return;
        }

        showLoader();

        try {
          const result = await EditCondition(inputForEdit);

          if (result) {
            showSuccessToast("Condition updated Successfully");
            getSectionDetails();
            editCondition.current.closeRight();
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error updating condition:", error);
          showErrorToast("An error occurred. Please try again.");
        } finally {
          dismissLoader();
          setIsProcessingUpdate(false); // Reset processing state
        }
      };

      const editConditionName = () =>{
       if (conditionsList == null) {
        fetchMedicalConditionList();
      }
      setSearchValue('')
     // setSelectedConditions([]);
      setSelectedDates({})
      
       addConditionModalInsideEdit.current.openRight();
       setConditionID("")
       setConditionTypeID("")
      }

      const selectConditionInEdit = ()=>{
          if(typedCondition){
            setConditionType("other")
            setConditionName(typedCondition)
          }
        addConditionModalInsideEdit.current.closeRight()
      }
      const handleCheckboxChangeForEdit = (selectedCondition, category) => {
        setSelectedConditions([])
        setConditionName(selectedCondition.condition)
        setConditionType(category.conditiontype)
        // Create a new array with only the selected condition
        const newSelectedConditions = [{
          condition_cd: selectedCondition.condition_cd,
          condition: selectedCondition.condition,
          category: category // Assuming you want to store the category of the condition as well
        }];
        setSelectedConditions(newSelectedConditions);
      };
      //Surgeries
      const openSurgeriesModal = () => {
        if (surgeriesList == null) {
          fetchSurgeriesList();
        }
        fetchTreatmentTeam();
        setSearchValue("");
        setSelectedSurgeries([]);
        setUploadedDocuments([]);
        setTypedCondition("")
        addSurgery.current.openRight();
      };
      const fetchSurgeriesList = async () => {
        try {
          const result = await getmedicalcondition({
            type: "surgical",
            patientid: patientid
          });
         // console.log(result)
        //  console.log('result.output', result.output)
          if (result.output.result == "success") {
            setSurgeriesList(result.output.data);

            const currentYear = new Date().getFullYear();
        const fromYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
        const fromOptions = fromYears.map((year) => String(year));
        setSurgeryYearOptions(fromOptions);
          } else {
            showErrorToast("Something went wrong!")
          }
        } catch (error) {
          console.error("Error", error);
        }
      };
      const fetchTreatmentTeam = async () => {
        try {
          const result = await FetchTreatmentTeam({
            patientid: patientid,
          });
        //  console.log(result, "modified treatment res");
          if (result != null) {
            setTreatmentTeamData(result);
            
          } else {
            //alert("No data");
          }
          dismissLoader();
        } catch (error) {
          console.error("Error in home:", error);
        }
      };
      const handleSurgeriesCheckboxChange = (condition) => {
        const isSelected = selectedSurgeries.some(
          (item) => item.condition_cd === condition.condition_cd
        );
    
        if (isSelected) {
          setSelectedSurgeries((prevConditions) =>
            prevConditions.filter(
              (item) => item.condition_cd !== condition.condition_cd
            )
          );
        } else {
          setSelectedSurgeries([...selectedSurgeries, condition]);
        }
      };
    
      const handleDatePickerChange = (conditionCd, selectedDate) => {
    
        const formattedDate = format(selectedDate, "yyyy-MM-dd");
    var formatedYearInEdit = moment(selectedDate).format("yyyy")
    //setSurgeryDateYear(formatedYearInEdit)
    setSurgeryDateYear((prevYears) => ({
      ...prevYears,
      [conditionCd]: formatedYearInEdit, // Store the year for the specific conditionCd
    }));
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: {
            date: formattedDate,
            surgeonName: prevDates[conditionCd]?.surgeonName || "",
            hospitalName: prevDates[conditionCd]?.hospitalName || "",
            fileName
          },
        }));
      };
      const handleSurgeryYearChange = (conditionCd, selecteddate) => {
        // console.log('selectedDate: ', selecteddate)
        //setSurgeryDateYear(selecteddate)
        // setFromEditDateYear(selecteddate)
    
        var janOne = "-02-01"
        var yearWithAddedDayAndMonth = selecteddate + janOne;
        //setSurgeryDate(yearWithAddedDayAndMonth);
        setSurgeryDateYear((prevYears) => ({
          ...prevYears,
          [conditionCd]: selecteddate, // Store the year for the specific conditionCd
        }));
        // const formattedDate = format(selecteddate, "yyyy-MM-dd");
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: {
            date: yearWithAddedDayAndMonth,
            surgeonName: prevDates[conditionCd]?.surgeonName || "",
            hospitalName: prevDates[conditionCd]?.hospitalName || "",
            fileName
          },
        }));
      };
      const currentYear = new Date().getFullYear();
      useEffect(() => {
        
        setSurgeryDateYear((prevYears) => {
          const updatedYears = selectedSurgeries.reduce((acc, condition) => {
            if (!(condition.condition_cd in prevYears)) {
              // Initialize only new conditions with the current year
              acc[condition.condition_cd] = currentYear.toString();
            } else {
              // Retain existing values
              acc[condition.condition_cd] = prevYears[condition.condition_cd];
            }
            return acc;
          }, {});
          return { ...prevYears, ...updatedYears }; // Merge new and existing values
        });
      }, [selectedSurgeries]);
      
      // useEffect(() => {
      //   setSurgeryDateYear((prevYears) => {
      //     const updatedYears = selectedSurgeries.reduce((acc, condition) => {
      //       if (!(condition.condition_cd in prevYears)) {
      //         acc[condition.condition_cd] = ""; // Initialize only new conditions
      //       } else {
      //         acc[condition.condition_cd] = prevYears[condition.condition_cd]; // Preserve existing values
      //       }
      //       return acc;
      //     }, {});
      //     return { ...prevYears, ...updatedYears }; // Merge with previous state
      //   });
      // }, [selectedSurgeries]);
      
      const handleSurgeryEditYearChange = (conditionCd, selecteddate) => {
        console.log('selectedDate: ', selecteddate)
        // const date = selecteddate.includes('02-01');
        // if(selecteddate === date ){
          // alert('if hit')
          setSurgeryEditDateYear(selecteddate)
    
          var janOne = "-02-01"
          var yearWithAddedDayAndMonth = selecteddate + janOne;
          let formateddate = moment(yearWithAddedDayAndMonth, 'YYYY-MM-DD').format('DD-MM-YYYY');
          
          setProcedureDate(formateddate)
          setSurgeryEditDate(yearWithAddedDayAndMonth);
          setSurgeryEditDateYear(selecteddate)
          setSelectedDates((prevDates) => ({
            ...prevDates,
            [conditionCd]: {
              date: yearWithAddedDayAndMonth,
              surgeonName: prevDates[conditionCd]?.surgeonName || "",
              hospitalName: prevDates[conditionCd]?.hospitalName || "",
              fileName
            },
          }));
      };

      const filterSurgeonNames = (conditionCd, surgeonName) => {
    
        const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
          surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
        );
        setSuggestions(prevSuggestions => ({
          ...prevSuggestions,
          [conditionCd]: filtered
        }));
        setBoolValForSuggestions(!boolValForSuggestions)  
        if(filtered){
          setShowDocNamesDropdown(true)
        }
      };
    
      const listOfSurgeonNames = (conditionCd , isImage) => {
     
        const allSuggestions = treatmentteamdata && treatmentteamdata
    
        setSuggestions(prevSuggestions => ({
          ...prevSuggestions,
          [conditionCd]: allSuggestions
        }));
        // setBoolValForSuggestions(!boolValForSuggestions) 
        if(isImage){
          setShowDocNamesDropdown(!showDocNamesDropdown)
    
        }
         
     
      };
      const handleSurgeonNameChange = (conditionCd, surgeonName) => {
        setInputId(conditionCd)
        setInputValues(prevValues => ({
          ...prevValues,
          [conditionCd]: surgeonName
        }));
    
        // Filter surgeon names based on input
        if (surgeonName) {
          filterSurgeonNames(conditionCd, surgeonName);
        } else {
          setSuggestions(prevSuggestions => ({
            ...prevSuggestions,
            [conditionCd]: []
          }));
          
        setBoolValForSuggestions(!boolValForSuggestions)
        }
    
        // Update selectedDates state
        setSelectedDates(prevDates => ({
          ...prevDates,
          [conditionCd]: {
            date: prevDates[conditionCd]?.date || "",
            surgeonName: surgeonName,
            hospitalName: prevDates[conditionCd]?.hospitalName || "",
            fileName: prevDates[conditionCd]?.fileName || "",
          },
        }));
      };

      const handleSelect = (conditionCd, suggestion) => {
        setInputValues(prevValues => ({
          ...prevValues,
          [conditionCd]: suggestion.doctorname
        }));
        setSuggestions(prevSuggestions => ({
          ...prevSuggestions,
          [conditionCd]: []
        }));
        
        setBoolValForSuggestions(!boolValForSuggestions)
        setSelectedDates(prevDates => ({
          ...prevDates,
          [conditionCd]: {
            date: prevDates[conditionCd]?.date || "",
            surgeonName: suggestion.doctorname,
            hospitalName: prevDates[conditionCd]?.hospitalName || "",
            fileName: prevDates[conditionCd]?.fileName || "",
          },
        }));
        setShowDocNamesDropdown(!showDocNamesDropdown)
      };
      const handleHospitalNameChange = (conditionCd, hospitalName) => {
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [conditionCd]: {
            date: prevDates[conditionCd]?.date || "",
            surgeonName: prevDates[conditionCd]?.surgeonName || "",
            hospitalName,
            fileName: prevDates[conditionCd]?.fileName || "",
          },
        }));
      };
      const handleSurgeriesCondition = (e) => {
        const inputText = e.target.value;
        const maxLength = 80;
      
        if (inputText.length <= maxLength) {
          let formatted = inputText.replace(/[^0-9a-zA-Z\s.]/g, "")
          setTypedSurgeriesCondition(formatted);
        }
      
      }
     // const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
     var inputForAddSurgery = {
      patientid: patientid,
          visitid: appointmentid,
      procedures: selectedSurgeries.map((condition, index) => {
        const procedure = {
          
          proceduredate: selectedDates[condition.condition_cd]?.date || todaysDate,
          proceduretype_cd: "1",
          procedure_cd: condition.condition_cd,
          proceduretitle: condition.condition,
          nomenclatureid: condition.condition_cd,
          hospitalid: "1",
          hospitalname: selectedDates[condition.condition_cd]?.hospitalName || "",
          surgeonname: selectedDates[condition.condition_cd]?.surgeonName || "",
          documenttype_cd: "",
          documentcategory_cd: "",
          documentname: "",
          documentsize: "",
          documenturl: "www.google.com",
          documents: uploadedDocuments
            .filter((doc) => doc.condition_cd == condition.condition_cd)
            .map(({ condition_cd, filename }) => ({
              condition_cd,
              filename,
            })),
        };
    
        // if (condition.conditiontype) {
        //   procedure.proceduretype = condition.conditiontype;
        // }
    
        return procedure;
      }),
     };
      const AddSurgery = () => {
        setInputValues({})
        if (selectedSurgeries.length === 0  && typedCondition == "") {
          showErrorToast("Please select a surgery to continue");
        } else {
          if (typedCondition !== "") {
            const newSelectedCondition = {
              condition:typedCondition,
              condition_cd:null,
              conditiontype:"other"
            };
        
            setSelectedSurgeries((prevConditions) => {
              // Filter out any existing condition with the same type
              const filteredConditions = prevConditions.filter(
                (item) => item?.conditiontype !== "other"
              );
              // Add the new condition
              return [...filteredConditions, newSelectedCondition];
            });
          }
    
          const currentDate = new Date();
          const formattedYear = moment(currentDate).format("yyyy")
          setSurgeryDateYear(formattedYear)
          setSelectedDates({})
          setSurgeonName("")
          setBoolValForSuggestions(false)
          addSurgeriesModal.current.openRight();
        }
      };

        const [isProcessingAddSurgery, setIsProcessingAddSurgery] = useState(false); // State to prevent duplicate clicks

        const AddSurgeriesCall = () => {
          if (isProcessingAddSurgery) return; // Prevent multiple clicks
          setIsProcessingAddSurgery(true); // Set the processing flag to true

          const addSurgeriesFunction = async () => {
            showLoader();
            try {
              inputForAddSurgery.procedures.forEach((procedure, index) => {
                const inputFormat = ["DD-MM-YYYY", "YYYY-MM-DD"];
                const parsedDate = moment(procedure.proceduredate, inputFormat, true);

                if (parsedDate.isValid()) {
                  // Format the date to 'YYYY-MM-DD'
                  inputForAddSurgery.procedures[index].proceduredate = parsedDate.format(
                    "YYYY-MM-DD"
                  );
                }
              });

              // Call API to add surgeries
              const result = await addSurgeries(inputForAddSurgery);

              if (result.output.result === "Successfully Inserted") {
                showSuccessToast("Surgery added Successfully.");
                setTimeout(() => {
                  addSurgeriesModal.current.closeRight();
                  addSurgery.current.closeRight();
                }, 700);
                getSectionDetails();
              setSurgeryDateYear('')
              } else {
                showErrorToast("Something went wrong!");
              }
            } catch (error) {
              console.error("Error in add Surgeries:", error);
              showErrorToast("An error occurred. Please try again.");
            } finally {
              dismissLoader();
              setIsProcessingAddSurgery(false); // Reset processing flag
            }
          };

          // Validation checks
          // const isDateMissing = selectedSurgeries.some(
          //   (condition) => !selectedDates[condition.condition_cd]?.date
          // );

          // const isSurgeonNameMissing = selectedSurgeries.some(
          //   (condition) => !selectedDates[condition.condition_cd]?.surgeonName
          // );

          // const isHospitalNameMissing = selectedSurgeries.some(
          //   (condition) => !selectedDates[condition.condition_cd]?.hospitalName
          // );

          // if (isDateMissing) {
          //   showErrorToast("Please select the Date.");
          //   setIsProcessingAddSurgery(false);
          //   return;
          // } else if (isSurgeonNameMissing) {
          //   showErrorToast("Please Enter Surgeon Name.");
          //   setIsProcessingAddSurgery(false);
          //   return;
          // } else if (isHospitalNameMissing) {
          //   showErrorToast("Please Enter Hospital Name.");
          //   setIsProcessingAddSurgery(false);
          //   return;
          // } else {
            addSurgeriesFunction();
          // }
        };

      const [surgeryid, setSurgeryID] = useState('');
      const [surgerytitle, setSurgeryTitle] = useState('');
      const dynamicModalSurgeries = React.useRef();
      const editSurgery = React.useRef();

      const deletePatientSurgeries = async () => {
        var inputForDelete = {
          id:surgeryid,
          "patientid":patientid
        };
          try {
            showLoader();
            const result = await deleteSurgeries(inputForDelete);
            if (result.output.result == "success") {
              showSuccessToast("Deleted Successfully")
              dynamicModalSurgeries.current.closeModal();
              getSectionDetails("surgery");
            } else {
              showErrorToast("Something went wrong!")
            }
            dismissLoader();
          } catch (error) {
            console.error("Error in DashboardIndex:", error);
          }
    
      };

      const openDeleteModalSurgery = (id, proceduretitle) => {
        setSurgeryID(id);
        setSurgeryTitle(proceduretitle);
    
        dynamicModalSurgeries.current.openModal();
    
      };
      const openEditModalSurgery = (id, doctorName, hospitalname, proceduredate, proceduretitle) => {
        const currentYear = new Date().getFullYear();
      const fromEditYears = Array.from({ length: 101 }, (_, index) => currentYear - index);
      const fromEditOptions = fromEditYears.map((year) => String(year));
      setSurgeryYearEditOptions(fromEditOptions);

        var formatedDate = moment(proceduredate).format("DD-MM-yyyy");
        var formatedYearInEdit = moment(proceduredate).format("yyyy")               
        const date = new Date(proceduredate);    
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());    
        const formattedDate = date.toISOString().split("T")[0];
        setSurgeryID(id);
        setSurgeonName(doctorName);
        setHospitalName(hospitalname);
        setProcedureTitle(proceduretitle)
        setProcedureDate(formatedDate);
        setSurgeryEditDateYear(formatedYearInEdit)
        setShowDocNamesDropdown(false)
        editSurgery.current.openRight();
      };
      const handleChangeDate = (selectedDate) => {
        const formattedDate = format(selectedDate, "dd-MM-yyyy");
        setProcedureDate(formattedDate);

    var formatedYearInEdit = moment(selectedDate).format("yyyy")
    setSurgeryEditDateYear(formatedYearInEdit) 
      };
      const handleSurgeonNameEditChange = (surgeonName) => {
        let filteredValue = surgeonName.replace(/[^0-9a-zA-Z\s.]/g, "")
        setInputValues(filteredValue);
    
        // Filter surgeon names based on input
        if (filteredValue) {
          filterSurgeonNamesEdit(filteredValue);
        } else {
          setSuggestions([]);
          
        setBoolValForSuggestions(!boolValForSuggestions)
        }
        setSurgeonName(filteredValue);
      };
      const listOfSurgeonNamesEdit = ( isImage) => {
 
        const allSuggestions = treatmentteamdata && treatmentteamdata
    
        setSuggestions(allSuggestions);
        // setBoolValForSuggestions(!boolValForSuggestions) 
        if(isImage){
          setShowDocNamesDropdown(!showDocNamesDropdown)
    
        }
         
     
      };
      const filterSurgeonNamesEdit = (surgeonName) => {
        const filtered = treatmentteamdata && treatmentteamdata.filter((surgeon) =>
          surgeon.doctorname?.toLowerCase().includes(surgeonName.toLowerCase())
        );
        setSuggestions(filtered);
        
        setBoolValForSuggestions(!boolValForSuggestions)  
      };
      const handleEditSelect = (suggestion) => {
        setSurgeonName(suggestion.doctorname);
        setSuggestions([]);
        
        setBoolValForSuggestions(!boolValForSuggestions)
        
        setShowDocNamesDropdown(!showDocNamesDropdown)
      };
      const handleChangeHospitalName = (event) => {
        var value = event.target.value;
        let formattedValue = value.replace(/[^0-9a-zA-Z\s.]/g, "")
        setHospitalName(formattedValue);
      };

      const [isProcessingEditSurgery, setIsProcessingEditSurgery] = useState(false); // State to prevent duplicate clicks

      const EditSurgicalHistory = async () => {
        if (isProcessingEditSurgery) return; // Prevent multiple clicks
        setIsProcessingEditSurgery(true); // Set the processing flag to true

        let formatedproceduredate = moment(procedureDate, "DD-MM-YYYY").format("YYYY-MM-DD");

        const inputForEdit = {
          id: surgeryid,
          patientid: patientid,
          visitid: appointmentid,
          proceduredate: formatedproceduredate,
          hospitalname: hospitalName,
          surgeonname: surgeonName,
        };

        const editSurgeriesFunction = async () => {
          showLoader();
          try {
            const result = await editSurgeries(inputForEdit);

            if (result.output.result === "Successfully Updated") {
              showSuccessToast("Surgical history updated successfully.");
              getSectionDetails();
              editSurgery.current.closeRight();
            } else {
              showErrorToast("Something went wrong!");
            }
          } catch (error) {
            console.error("Error in editSurgeriesFunction:", error);
            showErrorToast("An error occurred. Please try again.");
          } finally {
            dismissLoader();
            setIsProcessingEditSurgery(false); // Reset processing flag
          }
        };

        editSurgeriesFunction();
      };

      //Medications
      const addData = () => {
        setFilenameArray([]);
        setMedicineName("") 
        setComposition("") 
        fetchTreatmentTeam();
        // setFromDate(moment().format("DD-MM-yyyy"))
        // setToDate(moment().format("DD-MM-yyyy"))
        setHospitalname(null)
        setPrescribedBy(null)
        //setMedicineType("Tablet")
        //setMedicineDosageUnit("mg")
        setMedicineDosage(null)
        setMedicineDosageFrequency("")
        setMorning("0")
        setEvening("0")
        setNight("0")
        setComposition("")
        setMedicineExpiryDate("")
        //setShowAIModal(true)
        addmedications.current.openRight()
        setShowDocNamesDropdown(false)
       
      }
      // useEffect(()=>{
      //   if(initialPageLoad)editmedicine()
    
      // },[boolCheck])
    
      const handleChangeDosageUnit = (selectedOption) => {
        setMedicineDosageUnit(selectedOption.label);
      };
      const handleconsumptionfrequencychange = (selectedOption) => {
        if(selectedOption == "1"){
          setMedicineDosageFrequency("1");
        }else  if(selectedOption == "2"){
          setMedicineDosageFrequency("2");
        }else  if(selectedOption == "3"){
          setMedicineDosageFrequency("3");
        }else  if(selectedOption == "4"){
          setMedicineDosageFrequency("4");
        }
      };
      const handlemedicinetypechange = (event) => {
    
        setMedicineType(event);
        if(event == "Tablet" || event == "Capsule"){
          setMedicineDosageUnit("mg");
        }else if (event == "Syrup" || event == "Injection"){
          
          setMedicineDosageUnit("ml");
        }else if (event == "Ointment"){
          
          setMedicineDosageUnit("grms");
        }
      };
      const handlemedicinename = (event) => {
        setMedicineName(event.target.value);
      };
      const handleimageselection = async (e) => {
        e.preventDefault();
        setMedicineName("")
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
        if (!allowedTypes.includes(file.type)) {
          showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
        dismissLoader();
          return false;
        }
        showLoader();
        const result = await GetMedicineName(file, file.name, file.type);
        if(result?.output?.message  == "No credits are available"){
          showErrorToast("No sufficient tokens available");
          // setUpgradePlanModal(true)
          //     setTimeout(() => {
          //       setUpgradePlanModal(false)
          //     }, 100);
        }else{
        setMedicineName(result.medicine_name);
        setComposition(result.chemical_composition)
    
         if(result.expiry_date !== "00-0000"){
          //const formatteddate = moment(result.expiry_date).format("DD-MM-yyyy")
          setMedicineExpiryDate(result.expiry_date);
         }
        setBenifit(result.benefit);
        setSideEffects(result.side_effects);
        showmedicinename.current.openRight();
        }
        
        
      e.target.value = '';
        dismissLoader();
      };
      const fetchComposition = async (medicineName) => {
        const inputData = {
          input: {
            name: medicineName,
          },
        };
        if(medicineName){
        try {
           showLoader();
          const result = await GetAIMedicineComposition(inputData.input);
          // console.log(result);
          if (result) {
            showSuccessToast("Medicine composition fetched successfully");
            setComposition(result.chemical_composition);
            if(result.expiry_date){
              setMedicineExpiryDate(result.expiry_date);
            }
            setBenifit(result.benefit);
        setSideEffects(result.side_effects);
          }
          dismissLoader()
        } catch (error) {
          console.error("Error Fetching Medicine composition:", error);
          showErrorToast("An error occurred while Fetching Medicine composition");
        } 
          
      }else{
        showErrorToast("Please provide medicine name or upload medicine photo")
      }
      };
      const makeAicall = async ()=>{
        const inputData = {
          input: {
            name: medicinename,
          },
        };
        if(medicinename){
        try {
         showLoader();
        const result = await GetAIMedicineComposition(inputData.input);
        if(result){
            // setMedicineName(result.medicine_name);
            setComposition(result.chemical_composition)
    
            if(result.expiry_date !== "00-0000"){
              //const formatteddate = moment(result.expiry_date).format("DD-MM-yyyy")
              setMedicineExpiryDate(result.expiry_date);
            }
            setBenifit(result.benefit);
            setSideEffects(result.side_effects);
            learnMoreModal.current.openModal()
             dismissLoader();
        }
        }catch(error){
    
        }
      }
    };
    const handleMonthYearChange = (date) => {
      setMedicineExpiryDate(moment(date).format('MM-yyyy'));
    };
    const handlemedicinetime = (values) => {
      if (values.includes("morning")) {
        setMorning(1);
      } else {
        setMorning(0);
      }
    
      if (values.includes("evening")) {
        setEvening(1);
      } else {
        setEvening(0);
      }
    
      if (values.includes("night")) {
        setNight(1);
      } else {
        setNight(0);
      }
    };
    const handlemedicinetimebeforeafter = (values) => {
      if (values.includes("beforefood")) {
        setBeforefood(1);
      } else {
        setBeforefood(0);
      }
    
      if (values.includes("afterfood")) {
        setAfterfood(1);
      } else {
        setAfterfood(0);
      }
    };
    useEffect(()=>{
      setBoolValForSuggestions(!boolValForSuggestions)
    },[suggestions])
    const handleMedicineSelect = (suggestion) => {
      setPrescribedBy(suggestion.doctorname);
      setSuggestions([]);
    };
    const handleIsCurrent = (e)=>{
      if(e.target.checked){
        setIsChecked(1)
      }else{
        setIsChecked(0)
      }
    }
    let input = {
      patientid: patientid,
      visitid: appointmentid,
      documenttype_cd: "",
      documentcategory_cd: "",
      documentname: "",
      documenturl: "",
      prescribedby: prescribedby,
      hospitalname: hospitalname,
      patientname: patientData?.fullname,
      age: patientData?.age,
      documentdate: today,
      medicinedetail: [
        {
          medicinename: medicinename,
          chemical_composition: Composition,
          medicinetype: medicinetype,
          dosage: dosage,
          dosageunit:dosageunit,
          // startdate: fromdate,
          // enddate: todate,
          frequency: frequency,
          morning: morning,
          afternoon: "",
          evening: setevening,
          night: setnight,
          beforefood: beforefood,
          afterfood: afterfood,
          medicineexpirydate: medicineexpirydate,
          iscurrentmedicine: isChecked,
          longtext: "",
          //documents: filenameArray.map(filename => ({ filename }))
        },
      ],
    };
    const [isProcessingAddMedicine, setIsProcessingAddMedicine] = useState(false); // State to prevent duplicate clicks

    const addmedicine = async () => {
      if (isProcessingAddMedicine) return; // Prevent duplicate clicks
      setIsProcessingAddMedicine(true); // Set processing state to true

      const currentMonth = moment().month() + 1;
      const currentYear = moment().year();

      const parsedDateExp = moment(medicineexpirydate, "MM-yyyy");
      const expMonth = parsedDateExp.month() + 1;
      const expYear = parsedDateExp.year();

      showLoader();

      try {
        // Validate and format start date
        if (moment(input.medicinedetail[0].startdate, "DD-MM-YYYY", true).isValid()) {
          input.medicinedetail[0].startdate = moment(
            input.medicinedetail[0].startdate,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
        }

        // Validate and format end date
        if (moment(input.medicinedetail[0].enddate, "DD-MM-YYYY", true).isValid()) {
          input.medicinedetail[0].enddate = moment(
            input.medicinedetail[0].enddate,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
        }

        // Validations
        if (medicinename === "") {
          showErrorToast("Please specify or upload any medicine");
          return;
        } else if (medicineexpirydate && expYear === currentYear && expMonth < currentMonth) {
          showErrorToast("Exp month cannot be less than current Month!");
          return;
        }

        // API Call
        const result = await AddMedicine(input);

        if (result) {
          showSuccessToast("Medicine created Successfully");
          getSectionDetails();
          addmedications.current.closeRight();
        } else {
          showErrorToast("Something went wrong while creating medicine.");
        }
      } catch (error) {
        console.error("Error adding medicine:", error);
        showErrorToast("An error occurred. Please try again.");
      } finally {
        dismissLoader();
        setIsProcessingAddMedicine(false); // Reset processing state
      }
    };

    const [medicationid, setMedicationID] = useState('');
    const [medicationtitle, setMedicationTitle] = useState('');
    const dynamicModalMedication = React.useRef();

    const DeleteMedicineModel = (title, id) => {
      setMedicationID(id);
      setMedicationTitle(title);
      dynamicModalMedication.current.openModal();
    };
    const deletemedicine = async () => {
      const result = await DeleteMedicine({
        loginid: patientid,
        id: medicationid,
      });
      if (result) {
        showSuccessToast("Medicine deleted successfully");
        getSectionDetails("medication");
        dynamicModalMedication.current.closeModal();
      }
    };
    //Edit
    const editmedications = React.useRef();
    const [minDateStateEdit , setMinDateStateEdit] = useState('')
    const [maxDateStateEdit , setMaxDateStateEdit] = useState('')
    
    const handleeditmedication = (item)=>{
      fetchTreatmentTeam();
      setMedicationID(item.id);
      setMedicineName(item.medicinename);
      setComposition(item.chemicalcomposition);
      setMedicineType(item.medicinetype);
      setMedicineDosage(item.dosage);
      setMedicineDosageUnit(item.dosageunit);
      // const stratdate =  moment(item.medicationstartdate, 'YYYY-MM-DD').format('DD-MM-yyyy');
      // const enddate =  moment(item.medicationenddate, 'YYYY-MM-DD').format('DD-MM-yyyy');
      // setFromDate(stratdate)
      // setToDate(enddate);
    
      setMedicineDosageFrequency(item.frequency);
      setMorning(item.morning);
      setEvening(item.evening);
      setNight(item.night);
      setBeforefood(item.beforefood);
      setAfterfood(item.afterfood);
      setPrescribedBy(item.prescribedby)
      setHospitalname(item.prescribedhospitalname)
      setIsChecked(item.iscurrentmedicine)
      setMaxDateStateEdit("")
      setMinDateStateEdit("")
      setMedicineExpiryDate(item.medicineexpirydate)
      setShowDocNamesDropdown(false)
      editmedications.current.openRight();
    }
    const [isProcessingEditMedicine, setIsProcessingEditMedicine] = useState(false); // State to prevent duplicate clicks

    const editmedicine = async () => {
      if (isProcessingEditMedicine) return; // Prevent multiple clicks
      setIsProcessingEditMedicine(true); // Set the processing state to true

      let input = {
        patientid: patientid,
        id: medicationid,
        documenttype_cd: "",
        documentcategory_cd: "",
        documentname: "",
        documenturl: "",
        prescribedby: prescribedby,
        hospitalname: hospitalname,
        documentdate: today,
        medicinename: medicinename,
        chemical_composition: Composition,
        medicinetype: medicinetype,
        dosage: dosage,
        dosageunit: dosageunit,
        frequency: frequency,
        morning: morning,
        afternoon: "",
        evening: setevening,
        night: setnight,
        beforefood: beforefood,
        afterfood: afterfood,
        medicineexpirydate: medicineexpirydate,
        iscurrentmedicine: isChecked,
        longtext: "",
        isalert: Medicinealert,
        documents: filenameforUpload.map((filename) => ({ filename })),
      };

      // Validate and format start date
      if (moment(input.startdate, "DD-MM-YYYY", true).isValid()) {
        input.startdate = moment(input.startdate, "DD-MM-YYYY").format("YYYY-MM-DD");
      }

      // Validate and format end date
      if (moment(input.enddate, "DD-MM-YYYY", true).isValid()) {
        input.enddate = moment(input.enddate, "DD-MM-YYYY").format("YYYY-MM-DD");
      }

      const currentMonth = moment().month() + 1;
      const currentYear = moment().year();

      const parsedDateExp = moment(medicineexpirydate, "MM-yyyy");
      const expMonth = parsedDateExp.month() + 1;
      const expYear = parsedDateExp.year();

      // Validations
      if (medicinename === "") {
        showErrorToast("Please specify or upload any medicine");
        setIsProcessingEditMedicine(false);
        return;
      } else if (medicineexpirydate && expYear === currentYear && expMonth < currentMonth) {
        showErrorToast("Exp month cannot be less than current Month!");
        setIsProcessingEditMedicine(false);
        return;
      }

      // Show loader and process API call
      showLoader();

      try {
        const result = await EditMedicine(input);

        if (result) {
          showSuccessToast("Medicine updated Successfully");
          getSectionDetails();
          editmedications.current.closeRight();
        } else {
          showErrorToast("Something went wrong while updating medicine.");
        }
      } catch (error) {
        console.error("Error updating medicine:", error);
        showErrorToast("An error occurred. Please try again.");
      } finally {
        dismissLoader();
        setIsProcessingEditMedicine(false); // Reset processing state
      }
    };

    const handleSelectMedince = (suggestion) => {
      setPrescribedBy(suggestion.doctorname);
      setSuggestions([]);
    };
   //Family
   const addfamilymodel = async () => {
    clearStateFamily();
    setSearchValue('')
    setAge("");
    setSelectedConditions([]);
    setDate(null);
    setMaritalstatusID(null)
    setHeightunit_cd(152)
    setWeightunit_cd(155)
    

    AddFamily.current.openRight();
    try {
      const result = await getmedicalcondition({
        type: "healthcondition",
        patientid: patientid
      });
      //console.log(result);
      if (result != null) {       
        setMedicalData(result.output.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
    //Relations
    try {
      const result = await getrelations({
        type: "relation",
      });
      //console.log(result,"relations");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setRelationData(formattedOptions);
      }
      setRelationsComplete(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    //Martial status
    try {
      const result = await getmartialstatus({
        type: "maritalstatus",
      });
      //console.log(result,"status");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setMartialstatusData(formattedOptions);
      }
      setMartialstatusCompleteData(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
     // BMI States on initial page load -
     setVitalUnitHeight("ft");
     setVitalUnitWeight("lbs");
 
     setBMICalculatorType("standard")
     const tempOptions = [];
     const tempOptionsInches = [];
     const tempOptionsHeight = [];
     const tempOptionsWeightLB = [];
     const tempOptionsBP = [];
     for (let i = 1; i <= 10; i++) {
       tempOptions.push(i);
     }
     for (let i = 1; i <= 11; i++) {
       tempOptionsInches.push(i);
     }
     for (let i = 10; i <= 260; i++) {
      tempOptionsBP.push(i);
    }
     for (let i = 1; i <= 10; i++) {
       tempOptionsHeight.push(i);
        for (let j = 1; j <= 11; j++) {
         tempOptionsHeight.push((`${i}.${j}`));
             }
     }
     for (let i = 1; i <= 479; i++) {
       tempOptionsWeightLB.push(i);
     }
     setFeetOptions(tempOptions)
     setInchesOptions(tempOptionsInches)
     setHeightOptionsInBMI(tempOptionsHeight)
     setWeightOptionsInBMI(tempOptionsWeightLB)
     setSystolicOptionsInBP(tempOptionsBP)
  };
  const handleRelationshipInput= (selectedOption) =>{
    var foundObject = relationscomplete && relationscomplete.find(x => x.condition == selectedOption);
    
    if (foundObject) {
      console.log(foundObject)
      setRelationshipID(foundObject.condition_cd)
      if(foundObject.condition === "Brother" || foundObject.condition == "Father" || foundObject.condition == "Husband" || foundObject.condition == "Son" || foundObject.condition == "Grandfather"){
        setGenderCD(2)
        setGender("2")
      }else if(foundObject.condition == "Mother" || foundObject.condition == "Sister" || foundObject.condition == "Wife" || foundObject.condition == "Grandmother" || foundObject.condition == "Daughter"){
        setGenderCD(1)
        setGender("1")
      }else{
        setGenderCD(3)
        setGender("3")
      }
    } 
  }
  const handleMartitalstatusinput= (selectedOption) =>{
    var foundObject = martialstatuscomplete && martialstatuscomplete.find(x => x.condition == selectedOption);
    
    if (foundObject) {
      setMaritalstatusID(foundObject.condition_cd)
    } 
  }
  const handleAdoptedCheckboxChange= (e) =>{
    setAdoptedcheckbox(e.target.checked);
  }
  const handledeceasedCheckboxChange= (e) =>{
    setdeceasedcheckbox(e.target.checked);
  }
  

  const [isProcessingAddFamily, setIsProcessingAddFamily] = useState(false); // State to prevent duplicate clicks

  const SubmitAddForm = async () => {
    if (isProcessingAddFamily) return; // Prevent multiple submissions
    setIsProcessingAddFamily(true); // Set processing state to true

    let AddInput = {
      patientid: patientid,
      visitid: appointmentid,
      fullname: name + " " + lastName,
      firstname: name,
      lastname: lastName,
      dob: dateofbirth,
      relation_cd: relationship_cd,
      isadopted: adopted === false ? 0 : 1,
      isdeceased: deceased === false ? 0 : 1,
      gender,
      vitaladdeddate: `${date} ${time}`,
      medicalcondition: selectedConditions?.map((condition) => ({
        conditiontype_cd: condition?.category?.conditiontype_cd,
        condition_cd: condition.condition_cd,
        nomenclatureid: condition.condition_cd,
        conditionidentifieddate: new Date().toISOString(),
        condition: condition.condition,
        conditiontype: condition.category.conditiontype,
      })),
      allergies: selectedAllergiesFamily?.map((allergy) => ({
        allergytype_cd: allergy.condition_cd,
        allergy_cd: allergy.condition_cd,
        nomenclatureid: allergy.condition_cd,
        allergyidentifieddate: new Date().toISOString(),
        condition: allergy.condition,
        ...(allergy.conditiontype && { conditiontype: allergy.conditiontype }),
      })),
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Input Validations
    if (name === "" || name == null) {
      showErrorToast("Please enter first name");
      setIsProcessingAddFamily(false);
      return;
    } else if (gender == null) {
      showErrorToast("Please select gender!");
      setIsProcessingAddFamily(false);
      return;
    } else if (dateofbirth == null) {
      showErrorToast("Please provide date of birth or age");
      setIsProcessingAddFamily(false);
      return;
    } else if (relationship_cd === "" || relationship_cd == null) {
      showErrorToast("Please select relationship");
      setIsProcessingAddFamily(false);
      return;
    }

    // API Call
    try {
      showLoader();
      const result = await AddFamilyHistory(AddInput);

      if (result) {
        showSuccessToast("Family history added successfully");
        getSectionDetails();
        AddFamily.current.closeRight();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred. Please try again.");
    } finally {
      dismissLoader();
      setIsProcessingAddFamily(false); // Reset processing state
    }
  };

  useEffect(()=>{
    if(vitalunitheight == "ft"){
      setShowInchesInput(true)
     // alert(vitalunitheight)
    }else{
      setShowInchesInput(false)
    }
  }, [vitalunitheight])

  const switchBMICalculation = (value) => {
    const tempOptionsWeightLB = [];
    const tempOptionsHeight = [];
    setBMICalculatorType(value);
    // //here
    // setSaveStates((prevState) => {
    //   const updatedState = {
    //     ...prevState,
    //     type: value, // Save BMI type
    //   };

    //   // Save to localStorage
    //   localStorage.setItem("saveStates", JSON.stringify(updatedState));

    //   return updatedState;
    // });
    if (value == "metric") {
     // setWeight("")
      setVitalUnitHeight("cms");
      setHeightunit_cd(151)

      setVitalUnitWeight("kg");
      setWeightunit_cd(154)

      if(weight){
        var converterValue = lbToKgConverter(weight)
        setWeight(converterValue.value)
        setWeightToCalculate(converterValue.valueToCalculate)
        // setSaveStates((prevState) => {
        //   const updatedState = {
        //     ...prevState,
        //     weight: converterValue.value, // Dynamically update height
        //     type: BMICalculatorType, // Save BMI type
        //   };
  
        //   // Save to localStorage
        //   localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
        //   return updatedState;
        // });
      }
      if(feetValue || inchValue){
       // if(height.includes(".")){
        
      var heightValueOfFeet = feetValue && inchValue ? feetValue + "." + inchValue : feetValue;
          var converterValue = ftToCmConverter(feetValue ,inchToCalculate )
          setHeight(converterValue)
          // setSaveStates((prevState) => {
          //   const updatedState = {
          //     ...prevState,
          //     height: converterValue, // Dynamically update height
          //     type: BMICalculatorType, // Save BMI type
          //   };
    
          //   // Save to localStorage
          //   localStorage.setItem("saveStates", JSON.stringify(updatedState));
    
          //   return updatedState;
          // });
       // }
      }
      
      for (let i = 1; i <= 250; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 30; i <= 303; i++) {
        tempOptionsHeight.push(i);
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } else if (value == "standard") {
    //  setWeight("")
      setVitalUnitHeight("ft");
      setHeightunit_cd(152)

      setVitalUnitWeight("lbs");
      setWeightunit_cd(155)


      if(weight){
        var converterValue = kgToLbConverter(weightToCalculate)
        setWeight(converterValue.value)
        // setSaveStates((prevState) => {
        //   const updatedState = {
        //     ...prevState,
        //     weight: converterValue.value, // Dynamically update height
        //     type: BMICalculatorType, // Save BMI type
        //   };
  
        //   // Save to localStorage
        //   localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
        //   return updatedState;
        // });

      }

      if(height){

          var converterValue = cmToFtConverter(height)
          setFeetValue(converterValue.feet)
          setInchValue(converterValue.inches)
          // setSaveStates((prevState) => {
          //   const updatedState = {
          //     ...prevState,
          //     feetValue: converterValue.feet, // Dynamically update height
          //     inchValue: converterValue.inches, // Dynamically update height
          //     type: BMICalculatorType, // Save BMI type
          //   };
    
          //   // Save to localStorage
          //   localStorage.setItem("saveStates", JSON.stringify(updatedState));
    
          //   return updatedState;
          // });
      }

      for (let i = 1; i <= 479; i++) {
        tempOptionsWeightLB.push(i);
      }
      for (let i = 1; i <= 10; i++) {
        tempOptionsHeight.push(i);
        for (let j = 1; j <= 9; j++) {
          tempOptionsHeight.push((`${i}.${j}`));
        }
      }
    setHeightOptionsInBMI(tempOptionsHeight)
    setWeightOptionsInBMI(tempOptionsWeightLB)
    } 
  };

  //Edit Delete Family
  const [familyid, setFamilyID] = useState('');
  const [familytitle, setFamilyTitle] = useState('');
  const dynamicModalFamily = React.useRef();
  const [selectedAllergiesFamily, setSelectedAllergiesFamily] = useState([]);
  function clearStateFamily() {
    setFamilyID("");
    setName("");
    setLastName("");
    setRelationship("");
    setRelationshipID(null)
    setMaritalstatus("");
    setDate("");
    setSelectedConditions([]);
    setdeceasedcheckbox(false);
    setAdoptedcheckbox(false);
    setHeight(null)
    setWeight(null)
    setMobileno("")
    setSelectedAllergiesFamily([])
    setFamilyId("")
    setFeetValue(null)
    setInchValue(null)
    setInchToCalculate(null)
    setTypedCondition("")
    setTypedAllergy("")
    setAge("")
  }
  const handleFamilyEdit = async (item) =>{
    clearStateFamily();
    EditFamily.current.openRight();
    setIsAdd(false)
    setDefaultCountryCode(item.countryid)
    setFamilyID(item.familyid);
    setGenderCD(item.gender_cd);
    setName(item.firstname);
    setLastName(item.lastname);
    setRelationship(item.relation);
    setRelationshipID(item.relation_cd);
    setMaritalstatus(item.maritalstatus);
    setMaritalstatusID(item.maritalstatus_cd);
    setFamilyId(item.familyid);
    if(item.dob !== null){
      const formattedDate =  moment(item.dob, 'YYYY-MM-DD').format('DD-MM-yyyy');
      const formattedAge =  moment(item.dob, 'YYYY-MM-DD').format('YYYY-MM-DD');
      const age = calculateAge(formattedAge)
      setAge(age)
      setDate(formattedDate);
    }else{
      setDate(null)
    }
     setSelectedConditions(item.medicalcondition);
     setSelectedAllergiesFamily(item.allergies)
    setdeceasedcheckbox(item.isdeceased === 0 ? false : true);
    setAdoptedcheckbox(item.isadopted === 0 ? false:true);
    setEmail(item.email)
    setMobileno(item.mobileno)
    setBloodtype_cd(item.bloodtype_cd)
    setHeight(item.height)
    setWeight(item.weight)
    setGender(item.gender_cd)
   
   setHeightunit_cd(152)
   setWeightunit_cd(155)
   
    setVitalUnitHeight("ft");
    setVitalUnitWeight("lbs");
  
    if(item.weightunit_cd && item.weightunit_cd == 154){
     
      var converterValue = kgToLbConverter(item.weight)
      setWeight(converterValue.value)
    }
  
    if(item.heightunit_cd && item.heightunit_cd == 151){
     
      var converterValue = cmToFtConverter(item.height)
      setFeetValue(converterValue.feet)
      setInchValue(converterValue.inches)
      
      setInchToCalculate(converterValue.inchesToCalculate)
    }else if(item.heightunit_cd && item.heightunit_cd == 152){
    if(item.height){
      let parts = item.height.toString().split('.');
      setFeetValue(parts[0])
      setInchValue(parts[1])
  
      setInchToCalculate(parts[1])
    }
    }
    setBMICalculatorType("standard")
  
    try {
      const result = await getmedicalcondition({
        type: "healthcondition",
        "patientid":patientid
      });
      //console.log(result);
      if (result != null) {       
        setMedicalData(result.output.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
    //Relations
    try {
      const result = await getrelations({
        type: "relation",
        patientid:patientid
      });
      //console.log(result,"relations");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setRelationData(formattedOptions);
      }
      setRelationsComplete(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    //Martial status
    try {
      const result = await getmartialstatus({
        type: "maritalstatus",
        patientid:patientid
      });
      //console.log(result,"status");
      if (result != null) {
        const formattedOptions = result.output.data[0].data.map(option => (
          [option.condition]     
        ));
        setMartialstatusData(formattedOptions);
      }
      setMartialstatusCompleteData(result.output.data[0].data);
    } catch (error) {
      console.error("Error", error);
    }
    
    
  }

  const [isProcessingEditFamily, setIsProcessingEditFamily] = useState(false); // State to prevent duplicate clicks

  const SubmitEditForm = async () => {
    if (isProcessingEditFamily) return; // Prevent duplicate submissions
    setIsProcessingEditFamily(true); // Set processing state to true

    let EditInput = {
      patientid: patientid,
      visitid: appointmentid,
      familyid: familyid,
      fullname: name,
      firstname: name,
      lastname: lastName,
      gender,
      dob: dateofbirth,
      relation_cd: relationship_cd,
      isadopted: adopted === false ? 0 : 1,
      isdeceased: deceased === false ? 0 : 1,
      medicalcondition: selectedConditions?.map((condition, index) => ({
        conditiontype_cd: index + 1,
        condition_cd: condition.condition_cd,
        nomenclatureid: condition.condition_cd,
        conditionidentifieddate: new Date().toISOString(),
        condition: condition.condition,
        conditiontype: condition?.category?.conditiontype,
      })),
      allergies: selectedAllergiesFamily?.map((allergy) => ({
        allergytype_cd: allergy.condition_cd,
        allergy_cd: allergy.condition_cd,
        nomenclatureid: allergy.condition_cd,
        allergyidentifieddate: new Date().toISOString(),
        condition_type: allergy.condition_type,
        condition: allergy.condition,
        ...(allergy.conditiontype && { conditiontype: allergy.conditiontype }),
      })),
    };

    // Add country code and ID from local storage
    var countryCode = localStorage.getItem("countrycode");
    var countryId = localStorage.getItem("countryid");

    EditInput.countrycode = countryCode;
    EditInput.countryid = countryId;

    // Validate and format date of birth
    if (moment(EditInput.dob, "DD-MM-YYYY", true).isValid()) {
      EditInput.dob = moment(EditInput.dob, "DD-MM-YYYY").format("YYYY-MM-DD");
    }

    // Input Validations
    if (name === "") {
      showErrorToast("Please enter first name");
      setIsProcessingEditFamily(false);
      return;
    } else if (gender == null) {
      showErrorToast("Please select gender!");
      setIsProcessingEditFamily(false);
      return;
    } else if (relationship_cd === "" || relationship_cd == null) {
      showErrorToast("Please select any relation");
      setIsProcessingEditFamily(false);
      return;
    }

    // API Call
    try {
      showLoader();
      const response = await EditFamilyHistory(EditInput);

      if (response) {
        showSuccessToast("Family history updated successfully");
        getSectionDetails();
        EditFamily.current.closeRight();
      } else {
        showErrorToast("Something went wrong while updating family history.");
      }
    } catch (error) {
      console.error("Error updating family history:", error);
      showErrorToast("An error occurred. Please try again.");
    } finally {
      dismissLoader();
      setIsProcessingEditFamily(false); // Reset processing state
    }
  };

  const handleFamilyDelete = (id, name) =>{
    setFamilyID(id);
    setFamilyTitle(name)
    dynamicModalFamily.current.openModal();
  }
  const deletefamilyhistory = async()=>{
    const result = await DeleteFamilyHistory({
      patientid: patientid,
      familyid: familyid,
    });
    if(result){
      getSectionDetails("family");
      dynamicModalFamily.current.closeModal();
    }
  }
  // const switchBMICalculationForSaveState = (value) => {
  //   const tempOptionsWeightLB = [];
  //   const tempOptionsHeight = [];
  //   // setBMICalculatorType(value);
  //   //here
  //   setSaveStates((prevState) => {
  //     const updatedState = {
  //       ...prevState,
  //       type: value, // Save BMI type
  //     };

  //     // Save to localStorage
  //     localStorage.setItem("saveStates", JSON.stringify(updatedState));

  //     return updatedState;
  //   });
  //   if (value == "metric") {
  //    // setWeight("")
  //     setVitalUnitHeight("cms");
  //     setHeightunit_cd(151)

  //     setVitalUnitWeight("kg");
  //     setWeightunit_cd(154)
  //     console.log(saveStates.weight , ' saveStates.weight convert to metric')
  //     console.log(typeof saveStates.weight , 'typeof saveStates.weight convert to metric')
  //     if(34){
  //       console.log("console of nuner")
  //     }
  //   //   setSaveStates((prevState) => {
  //   //     const updatedState = {
  //   //       ...prevState,
  //   //       weight: converterValue.value, // Dynamically update height
  //   //       // type: value, // Save BMI type
  //   //     };

  //   //     // Save to localStorage
  //   //     localStorage.setItem("saveStates", JSON.stringify(updatedState));

  //   //     return updatedState;
  //   //   });
  //   // }
  //     if(saveStates.weight){
  //       var converterValue = lbToKgConverter(saveStates.weight)
  //       console.log(converterValue.value , "converterValue lb to kg")
  //       if(converterValue.value){
  //         setSaveStates((prevState) => {
  //           const updatedState = {
  //             ...prevState,
  //             weight: converterValue.value, 
  //           };
    
  //           // Save to localStorage
  //           localStorage.setItem("saveStates", JSON.stringify(updatedState));
    
  //           return updatedState;
  //         });
  //       }
       
  //     }
  //     if(saveStates.feetValue || saveStates.inchValue){
  //      // if(height.includes(".")){
        
  //     var heightValueOfFeet = saveStates.feetValue && saveStates.inchValue ? saveStates.feetValue + "." + saveStates.inchValue : saveStates.feetValue;
  //         var converterValue = ftToCmConverter(saveStates.feetValue ,saveStates.inchToCalculate )
  //         setSaveStates((prevState) => {
  //           const updatedState = {
  //             ...prevState,
  //             height: converterValue, // Dynamically update height
  //             // type: value, // Save BMI type
  //           };
    
  //           // Save to localStorage
  //           localStorage.setItem("saveStates", JSON.stringify(updatedState));
    
  //           return updatedState;
  //         });
  //      // }
  //     }
      
  //     for (let i = 1; i <= 250; i++) {
  //       tempOptionsWeightLB.push(i);
  //     }
  //     for (let i = 30; i <= 303; i++) {
  //       tempOptionsHeight.push(i);
  //     }
  //   setHeightOptionsInBMI(tempOptionsHeight)
  //   setWeightOptionsInBMI(tempOptionsWeightLB)
  //   } else if (value == "standard") {
  //   //  setWeight("")
  //     setVitalUnitHeight("ft");
  //     setHeightunit_cd(152)

  //     setVitalUnitWeight("lbs");
  //     console.log("BMI useeffect hit 3")

  //     setWeightunit_cd(155)


  //     console.log(saveStates , ' saveStates convert to stadrnd')
  //     // if(saveStates.weight){
  //     //   var converterValue = kgToLbConverter(saveStates.weight)
  //     //   setSaveStates((prevState) => {
  //     //     const updatedState = {
  //     //       ...prevState,
  //     //       weight: converterValue.value, // Dynamically update height
  //     //       type: value, // Save BMI type
  //     //     };
  
  //     //     // Save to localStorage
  //     //     localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
  //     //     return updatedState;
  //     //   });

  //     // }

  //     if(saveStates.height){

  //         var converterValue = cmToFtConverter(saveStates.height)
  //         setSaveStates((prevState) => {
  //           const updatedState = {
  //             ...prevState,
  //             feetValue: converterValue.feet, // Dynamically update height
  //             inchValue: converterValue.inches, // Dynamically update height
  //             // type: value, // Save BMI type
  //           };
    
  //           // Save to localStorage
  //           localStorage.setItem("saveStates", JSON.stringify(updatedState));
    
  //           return updatedState;
  //         });
  //     }

  //     const tempOptions = [];
  //     const tempOptionsInches = [];
  //     const tempOptionsHeight = [];
  //     const tempOptionsWeightLB = [];
  //     for (let i = 1; i <= 10; i++) {
  //       tempOptions.push(i);
  //     }
  //     for (let i = 1; i <= 11; i++) {
  //       tempOptionsInches.push(i);
  //     }
  //     for (let i = 1; i <= 10; i++) {
  //       tempOptionsHeight.push(i);
  //        for (let j = 1; j <= 11; j++) {
  //         tempOptionsHeight.push((`${i}.${j}`));
  //             }
  //     }
  //     for (let i = 1; i <= 479; i++) {
  //       tempOptionsWeightLB.push(i);
  //     }
  //     setFeetOptions(tempOptions)
  //     setInchesOptions(tempOptionsInches)
  //     setHeightOptionsInBMI(tempOptionsHeight)
  //     setWeightOptionsInBMI(tempOptionsWeightLB)
  //   } 
  // };


  const switchBMICalculationForSaveState = (value, isInitial) => {
 
    setSaveStates((prevState) => {
      const updatedState = { ...prevState, type: value };
  
      if (value === "metric") {
        setShowInchesInputONPAGE(false)
        setVitalUnitHeightONPAGE("cms");
        setVitalUnitWeightONPAGE("kg");

        // Convert weight from lbs to kg
        if(!isInitial){
        if (prevState.weight) {
          const converterValue = lbToKgConverter(prevState.weight);
          if (converterValue?.value) {
            updatedState.weight = converterValue.value;
          }
        }
      }
  
        // Convert height from ft+in to cm
        if (prevState.feetValue || prevState.inchValue) {
          const heightInFeet = prevState.feetValue || 0;
          const heightInInches = prevState.inchValue || 0;
          updatedState.height = ftToCmConverter(heightInFeet, heightInInches);
        }
  
        // Update metric-specific units
        updatedState.vitalUnitHeight = "cms";
        updatedState.vitalUnitWeight = "kg";
      } else if (value === "standard") {
        setShowInchesInputONPAGE(true)
        setVitalUnitHeightONPAGE("ft");
        setVitalUnitWeightONPAGE("lbs");
        

        // Convert weight from kg to lbs
        if(!isInitial){
          
        if (prevState.weight) {
          const converterValue = kgToLbConverter(prevState.weight);
          if (converterValue?.value) {
            updatedState.weight = converterValue.value;
          }
        }
      }
  
        // Convert height from cm to ft+in
        if (prevState.height) {
          const converterValue = cmToFtConverter(prevState.height);
          updatedState.feetValue = converterValue.feet;
          updatedState.inchValue = converterValue.inches;
        }
  
        // Update standard-specific units
        updatedState.vitalUnitHeight = "ft";
        updatedState.vitalUnitWeight = "lbs";
      }
  
      // Save to local storage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
      return updatedState;
    });
  
    // Update dropdown options for height and weight
    if (value === "metric") {
      const tempOptionsWeight = [];
      const tempOptionsHeight = [];
      for (let i = 1; i <= 250; i++) tempOptionsWeight.push(i);
      for (let i = 30; i <= 303; i++) tempOptionsHeight.push(i);
      setWeightOptionsInBMI(tempOptionsWeight);
      setHeightOptionsInBMI(tempOptionsHeight);
    } else if (value === "standard") {
      const tempOptionsFeet = [];
      const tempOptionsInches = [];
      const tempOptionsWeight = [];
      const tempOptionsBP = [];

      for (let i = 1; i <= 10; i++) tempOptionsFeet.push(i);
      for (let i = 0; i <= 11; i++) tempOptionsInches.push(i);
      for (let i = 1; i <= 479; i++) tempOptionsWeight.push(i);
      for (let i = 10; i <= 260; i++) tempOptionsBP.push(i);
      setFeetOptions(tempOptionsFeet);
      setInchesOptions(tempOptionsInches);
      setWeightOptionsInBMI(tempOptionsWeight);
      setSystolicOptionsInBP(tempOptionsBP)

    }
  };

  
  
  const setInchValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setInchValue(stringValue)
    setInchToCalculate(stringValue)
   
  }
  const setInchValueInDropDownONPAGE = (value) =>{
    const stringValue = String(value); // Convert value to string
    setSaveStates((prevState) => {
      const updatedState = {
        ...prevState,
        inchValue: stringValue, // Dynamically update height
        // type: BMICalculatorType, // Save BMI type
      };

      // Save to localStorage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));

      return updatedState;
    });
  }
  const handleBMIinchValue = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
      setInchValue(value)
      setInchToCalculate(value)
      //here
     
     }
     
   }
   const handleBMIinchValueONPAGE = (e)=>{
    /// alert(e.target.value)
     var value = e.target.value;
     const numbersRegex = /^[0-9]+$/;
     if (numbersRegex.test(value) || value === "") { // Also allow empty value
     
      //here
      setSaveStates((prevState) => {
        const updatedState = {
          ...prevState,
          inchValue: value, // Dynamically update height
          // type: BMICalculatorType, // Save BMI type
        };

        // Save to localStorage
        localStorage.setItem("saveStates", JSON.stringify(updatedState));

        return updatedState;
      });
     }
     
   }

   const setFeetValueInDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setFeetValue(stringValue)
  }
  const setFeetValueInDropDownONPAGE = (value) =>{
    const stringValue = String(value);
    setSaveStates((prevState) => {
      const updatedState = {
        ...prevState,
        feetValue: stringValue, // Dynamically update height
        // type: BMICalculatorType, // Save BMI type
      };

      // Save to localStorage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));

      return updatedState;
    });
  }
  const handleBMIFeetValue = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setFeetValue(value)
    }
    
  }
  const handleBMIFeetValueONPAGE = (e)=>{
    var value = e.target.value;
    const numbersRegex = /^[0-9]+$/;
    if (numbersRegex.test(value) || value === "") { 
      setSaveStates((prevState) => {
        const updatedState = {
          ...prevState,
          feetValue: value, // Dynamically update height
          // type: BMICalculatorType, // Save BMI type
        };

        // Save to localStorage
        localStorage.setItem("saveStates", JSON.stringify(updatedState));

        return updatedState;
      });
    }
    
  }

   // THis is BMI on change event
   const handlebmivitalvaluechange = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weight":
        let inputValue = value;
       const inputText = e.target.value;
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setWeight(cleanedValue);
        }
        break;
      case "height":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setHeight(cleanedValue);
        }
        break;
       
      default:
        break;
    }
  };

  const handlebmivitalvaluechangeONPAGE = (e) => {
    const { id, value } = e.target;
    const maxLength = 6;
    switch (id) {
      case "weight":
        let inputValue = value;
       const inputText = e.target.value;
        if (inputText.length <= maxLength) {
          
        let cleanedValue = inputText.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setSaveStates((prevState) => {
          const updatedState = {
            ...prevState,
            weight: cleanedValue, // Dynamically update height
            // type: BMICalculatorType, // Save BMI type
          };
  
          // Save to localStorage
          localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
          return updatedState;
        });
        }
        break;
      case "height":
        const input = e.target.value;
      
        if (input.length <= maxLength) {
          
        let cleanedValue = input.replace(/[^0-9.]/g, '');
        if(cleanedValue.startsWith(".")){
          cleanedValue = cleanedValue.substring(1)
        }
        if (cleanedValue.match(/[0-9]{4,}/)) {
          cleanedValue = cleanedValue.slice(0, -1);
        }
        const decimalIndex = cleanedValue.indexOf('.');
        if (decimalIndex !== -1) {
          const parts = cleanedValue.split('.');
          cleanedValue = parts[0] + '.' + parts[1].slice(0, 2);
        }
        setSaveStates((prevState) => {
          const updatedState = {
            ...prevState,
            height: cleanedValue, // Dynamically update height
            // type: BMICalculatorType, // Save BMI type
          };
  
          // Save to localStorage
          localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
          return updatedState;
        });
        }
        break;
       
      default:
        break;
    }
  };

  const setHeightValueInBMIDropDown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setHeight(stringValue)
  }

  const setHeightValueInBMIDropDownONPAGE = (value) =>{
    const stringValue = String(value);
    setSaveStates((prevState) => {
      const updatedState = {
        ...prevState,
        height: stringValue, // Dynamically update height
        // type: BMICalculatorType, // Save BMI type
      };

      // Save to localStorage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));

      return updatedState;
    });
  }

  const setWeightvalueInBMIDropdown = (value) =>{
    const stringValue = String(value); // Convert value to string
    setWeight(stringValue)
  }
  const setWeightvalueInBMIDropdownONPAGE = (value) =>{
    const stringValue = String(value); // Convert value to string
    setSaveStates((prevState) => {
      const updatedState = {
        ...prevState,
        weight: value, // Dynamically update height
        // type: BMICalculatorType, // Save BMI type
      };

      // Save to localStorage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));

      return updatedState;
    });
  }
  const setSystolicBPValueInDropDownONPAGE = (value) =>{
    const stringValue = String(value); // Convert value to string
    setSaveStates((prevState) => {
      const updatedState = {
        ...prevState,
        systolicValue: value, // Dynamically update height
        // type: BMICalculatorType, // Save BMI type
      };

      // Save to localStorage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));

      return updatedState;
    });
  }
  const handleSystolicBPValueONPAGE = (e) => {
    let value = validationOfNumberWithOnly1Decimal(e.target.value , 10)
    
        setSaveStates((prevState) => {
          const updatedState = {
            ...prevState,
            systolicValue: value, // Dynamically update height
            // type: BMICalculatorType, // Save BMI type
          };
  
          // Save to localStorage
          localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
          return updatedState;
        });
  };
  const setDistolicBPValueInDropDownONPAGE = (value) =>{
    const stringValue = String(value); // Convert value to string
    setSaveStates((prevState) => {
      const updatedState = {
        ...prevState,
        distolicValue: value, // Dynamically update height
        // type: BMICalculatorType, // Save BMI type
      };

      // Save to localStorage
      localStorage.setItem("saveStates", JSON.stringify(updatedState));

      return updatedState;
    });
  }
  const handleDistolicBPValueONPAGE = (e) => {
    let value = validationOfNumberWithOnly1Decimal(e.target.value , 10)
    
        setSaveStates((prevState) => {
          const updatedState = {
            ...prevState,
            distolicValue: value, // Dynamically update height
            // type: BMICalculatorType, // Save BMI type
          };
  
          // Save to localStorage
          localStorage.setItem("saveStates", JSON.stringify(updatedState));
  
          return updatedState;
        });
  };
  const handleAllergiesCheckboxChange = (condition) => {
    const isSelected = selectedAllergiesFamily?.some(
      (item) => item.condition_cd === condition.condition_cd
    );
  
    if (isSelected) {
      setSelectedAllergiesFamily((prevConditions) =>
        prevConditions?.filter(
          (item) => item.condition_cd !== condition.condition_cd
        )
      );
    } else {
      setSelectedAllergiesFamily((prevConditions = []) => [
      ...prevConditions,
      condition
    ]);
     
      
    }
  };
  const removeTagAllergiesFromEdit = (condition) => {
    setSelectedAllergiesFamily(prevTags => prevTags.filter(tag => tag.condition_cd !== condition));
  };
  const removeTagAllergiesFromAdd = (condition_cd) => {
    setSelectedAllergiesFamily(prevTags => prevTags.filter(tag => tag.condition_cd !== condition_cd));
  };
  const handleFirstName = (e)=>{
    setName(e.target.value.replace(/[^a-zA-Z\s.]/g, "").slice(0,50))
  }
  const handleLastName = (e)=>{
    setLastName(e.target.value.replace(/[^a-zA-Z\s.]/g, "").slice(0,50))
  }
  const handleAge = (e) => {
    const input = e.target.value;
    setAge(input)
    // Allow only numbers and limit to 3 digits
    if (/^\d{0,3}$/.test(input)) {
      const age = parseInt(input, 10);
      if (!isNaN(age)) {
        const currentYear = new Date().getFullYear();
        const targetYear = currentYear - age;
        const formattedDate = `${targetYear}-01-01`; // Format: yyyy-mm-dd
        const formatt = `01-01-${targetYear}`;
        const formattedDateToShow =  moment(formatt);
        setDate(formattedDateToShow); 
      } else {
        setDate(""); // Clear the date if the input is not valid
      }
    }else{
      showErrorToast("Please provide only numbers")
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return moment(dateString).format('Do MMM, YYYY');
  };
  const selectGender = (selectedOption) => {
    if (selectedOption == "Male") {
      setGender("2")
    } else if (selectedOption == "Female") {
      setGender("1")
    } else if (selectedOption == "Other") {
      setGender("3")
    }
  }
  function calculateAge(dob) {
    const dobDate = new Date(dob);
    const today = new Date();
  
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();
  
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
  
    return age;
  }
  const handleDateChangeFamily = (selecteddate) => {
    const formattedDate = format(selecteddate, "yyyy-MM-dd");
    const formattedAge = moment(selecteddate).format("YYYY-MM-DD");
    const age = calculateAge(formattedAge);
    setAge(age)
    setDate(formattedDate);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleMobile = (e) => {
    const inputText = e.target.value;
    const maxLength = 10;
   let valid =  validationMobileNumber(inputText, maxLength)
   setMobileno(valid);
  
    // if (inputText.length <= maxLength) {
    //   setMobileno(inputText);
    // }
  
  }
  const selectBloodType = (selectedOption) => {
  
    if (selectedOption == "A+") {
      setBloodtype_cd("158")
    } else if (selectedOption == "A-") {
      setBloodtype_cd("159")
    } else if (selectedOption == "B+") {
      setBloodtype_cd("160")
    } else if (selectedOption == "B-") {
      setBloodtype_cd("161")
    } else if (selectedOption == "AB+") {
      setBloodtype_cd("162")
    } else if (selectedOption == "AB-") {
      setBloodtype_cd("163")
    } else if (selectedOption == "O+") {
      setBloodtype_cd("164")
    } else if (selectedOption == "O-") {
      setBloodtype_cd("165")
    }
  }
  const handleConditionforFamily = (e) => {
    const inputText = e.target.value;
    const maxLength = 80;
  
    if (inputText.length <= maxLength) {
      setTypedCondition(inputText);

     
    }
  
  }

  const AddMedicalConditionsforFamily = () => {
   
    if (typedCondition !== "") {
      const newSelectedCondition = {
        category: {
          conditiontype: "other",
          conditiontype_cd: null,
        },
        condition: typedCondition,
        condition_cd: null,
      };
      if(isAdd){
        
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.category.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
      
    }

    if(!isAdd){
      
      setSelectedConditions((prevConditions) => {
        // Filter out any existing condition with the same type
        const filteredConditions = prevConditions.filter(
          (item) => item.conditiontype !== "other"
        );
        // Add the new condition
        return [...filteredConditions, newSelectedCondition];
      });
    }
    
  }
  Medicalconditionsmodel.current.closeRight()
    }
    const openAddFamilyConditionModal = () =>{
      setSearchValue('')
     // setSelectedConditions([]);
     setTypedCondition("")
     setTypedAllergy("")
      Medicalconditionsmodel.current.openRight()
    }
    const handleCheckboxChangeFamily = (condition, category) => {
      const isSelected = selectedConditions.some(
        (item) => item.condition_cd === condition.condition_cd
      );
  
      if (isSelected) {
        // If already selected, remove it from the array
        setSelectedConditions((prevConditions) =>
          prevConditions.filter(
            (item) => item.condition_cd !== condition.condition_cd
          )
        );
      } else {
        // If not selected, add it to the array
        // setSelectedConditions([...selectedConditions, condition]);
        setSelectedConditions((prevConditions) => [
          ...prevConditions,
          { ...condition, category },
        ]);
      }
    };
    const fetchFamilyAllergiesData = async () => {
      try {
        const response = await getmedicalcondition({
          type: "allergies",
          patientid: patientid
        });
        if (response.output.result == "success") {
          setAllergiesData(response.output.data);
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.error("Error", error);
      }
    };
    const openAllergiesModalFamily = () => {
      // if (allergiesdata == null) {
        fetchFamilyAllergiesData();
      // }
      setTypedAllergy("")
      allergiesModal.current.openRight()
    }
    const handleAllergy = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;
    
      if (inputText.length <= maxLength) {
        setTypedAllergy(inputText);
      }
      if(e.target.value){
        setAllergiesContinueBtnDisabled(false)
      }else{
        setAllergiesContinueBtnDisabled(true)
      }
    
    }
    const closeAddAllergyModal =()=>{
      // enter manually 
      if (typedAllergy !== "") {
        const newSelectedCondition = {
          condition:typedAllergy,
          condition_type:typedAllergy,
          condition_cd:null,
          conditiontype:"other",
         // familyid,
        };
    
        setSelectedAllergiesFamily((prevConditions) => {
          // Filter out any existing condition with the same type
          const filteredConditions = prevConditions.filter(
            (item) => item?.conditiontype !== "other"
          );
          // Add the new condition
          return [...filteredConditions, newSelectedCondition];
        });
      }
    allergiesModal.current.closeRight()
    }
    useEffect(()=>{
      if(selectedAllergiesFamily?.length > 0 || typedAllergy){
        setAllergiesContinueBtnDisabled(false)
      }else if(selectedAllergiesFamily?.length < 0 || !typedAllergy){
        
        setAllergiesContinueBtnDisabled(true)
      }
    },[selectedAllergiesFamily])
    const scrollRef = useRef(null);

      // Mouse dragging logic
      const isDragging = useRef(false);
      const startX = useRef(0);
      const scrollLeft = useRef(0);

      const handleMouseDown = (event) => {
        isDragging.current = true;
        startX.current = event.pageX - scrollRef.current.offsetLeft;
        scrollLeft.current = scrollRef.current.scrollLeft;
      };

      const handleMouseLeave = () => {
        isDragging.current = false;
      };

      const handleMouseUp = () => {
        isDragging.current = false;
      };

      const handleMouseMove = (event) => {
        if (!isDragging.current) return;
        event.preventDefault();
        const x = event.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX.current) * 1.5; // The multiplier increases the drag speed
        scrollRef.current.scrollLeft = scrollLeft.current - walk;
      };
      useEffect(() => {
        // console.log(GetSectionDetails, "GetSectionDetails")
        if (GetSectionDetails?.socialhistory?.some((step) => step.socialhistory === "Smoking")) {
          setSmokeRadioValue("yes");
        } 
        // else {
        //   setSmokeRadioValue("no");
        // }
      
        if (GetSectionDetails?.socialhistory?.some((step) => step.socialhistory === "Alcohol")) {
          setAlcoholRadioValue("yes");
        } 
        // else {
        //   setAlcoholRadioValue("no");
        // }
        if (GetSectionDetails?.socialhistory?.some((step) => step.socialhistory === "Physical Activity")) {
          setExerciseRadioValue("yes");
        } 
        if (GetSectionDetails?.patientallergies?.length > 0) {
          setAllergiesRadioValue("yes");
        } 
        // else {
        //   setAllergiesRadioValue("no");
        // }
      
        if (GetSectionDetails?.patientmedicalcondition?.length > 0) {
          setConditionsRadioValue("yes");
        } 
        // else {
        //   setConditionsRadioValue("no");
        // }
      
        if (GetSectionDetails?.patientsurgeries?.length > 0) {
          setSurgeryRadioValue("yes");
        } 
        // else {
        //   setSurgeryRadioValue("no");
        // }
      
        if (GetSectionDetails?.patientmedicine?.length > 0) {
          setMedicationRadioValue("yes");
        } 
        // else {
        //   setMedicationRadioValue("no");
        // }
      
        if (GetSectionDetails?.patientfamily?.length > 0) {
          setFamilyRadioValue("yes");
        } 
        // else {
        //   setFamilyRadioValue("no");
        // }
      }, [GetSectionDetails]);
      //Save section
        const saveSectiontwo = async () => {
          showLoader();
            // Adjust visitformdata based on patient gender
        const filteredVisitFormData = inputForSave.visitformdata.filter((item, index) => {
          if (patientData.gender === 2 && index === inputForSave.visitformdata.length - 1) {
            // Exclude last question for gender == 2
            return false;
          }
          if (patientData.gender === 1 && index === inputForSave.visitformdata.length - 2) {
            // Exclude second-to-last question for gender == 1
            return false;
          }
          return true;
        });

        // Add question number starting from 1
        inputForSave.visitformdata = filteredVisitFormData.map((item, index) => ({
          ...item,
          questionnumber: index + 1,
        }));
          

          try{
            // console.log(inputForSave , " inputForSave")
            var result = await addVisitFormDetail(inputForSave);
            // console.log(result, " result")
            if(result.output.result == "success"){
              showSuccessToast("Adult Screening details have been saved successfully!");
              setTimeout(() => {
                const newActiveComponent = "patient-screening";
                onSubmit(newActiveComponent);
              }, 1000);
            }
              // console.log(result , "result getVisitFormDetail")
          
          } catch (error) {
            console.error("Error in home:", error);
          }finally{
          dismissLoader();
          }
        };
        // setTimeout(() => {
        //   const newActiveComponent = "patient-screening";
        //   onSubmit(newActiveComponent);
        // }, 1000);

      // BMI Functionality

   

      //BMI Functionality End
      // useEffect(() => {
      //   // Update saveStates based on BMI type
      //   if (BMICalculatorType === "standard") {
      //     if (feetValue || inchValue) {
      //       let feetVar = feetValue && inchValue ? `${feetValue}.${inchValue}` : feetValue;
      
      //       setSaveStates((prevState) => {
      //         const updatedState = {
      //           ...prevState,
      //           height: feetVar, // Dynamically update height
      //           type: BMICalculatorType, // Save BMI type
      //         };
      
      //         // Save to localStorage
      //         localStorage.setItem("saveStates", JSON.stringify(updatedState));
      
      //         return updatedState;
      //       });
      //     }
      //   } else if (BMICalculatorType === "metric") {
      //     if (height) {
      //       setSaveStates((prevState) => {
      //         const updatedState = {
      //           ...prevState,
      //           height: height, // Dynamically update height
      //           type: BMICalculatorType, // Save BMI type
      //         };
      
      //         // Save to localStorage
      //         localStorage.setItem("saveStates", JSON.stringify(updatedState));
      
      //         return updatedState;
      //       });
      //     }
      //   }
      
      //   if (weight) {
      //     setSaveStates((prevState) => {
      //       const updatedState = {
      //         ...prevState,
      //         weight: weight, // Dynamically update weight
      //         type: BMICalculatorType, // Save BMI type
      //       };
      
      //       // Save to localStorage
      //       localStorage.setItem("saveStates", JSON.stringify(updatedState));
      
      //       return updatedState;
      //     });
      //   }
      // }, [saveStates?.height, saveStates?.weight, saveStates?.feetValue, saveStates?.inchValue, saveStates?.type]);
      
      // Save Data Functionality Start
      const [saveStates, setSaveStates] = useState({
        "exerciseRegularly": "",

        height:null,
        weight:null,
        type:"",
        feetValue:null,
        inchValue:null,
        systolicValue: null,
        distolicValue: null,
        Fatigue:"",
        Dizziness:"",
        ChestPain:"",
        ShortnessofBreath:"",
        PersistentCough:"",
        UnexplainedWeightLoss:"",

        LastBloodPressureCheckSystolic:null,
        LastBloodPressureCheckDistolic:null,

        LastBloodPressureCheck:"",
        LastBloodSugarCheck:"",
        CholesterolLevels:null,
        ProstateCancerScreening:"not done",
        CervixandBreastCancerScreening:"not done",
      });

      // Function to handle radio button changes
      const handleChangeRadio = (key, value) => {
        setSaveStates((prevState) => {
          const updatedState = {
            ...prevState,
            [key]: value, // Dynamically update the key with the selected value
          };

          // Save updated state to localStorage
          localStorage.setItem("saveStates", JSON.stringify(updatedState));

          return updatedState;
        });
      };

      // Function to handle input field changes
      const handleChangeInput = (key, e) => {
        let value = validationOfNumberWithOnly1Decimal(e.target.value , 10)
        setSaveStates((prevState) => {
          const updatedState = {
            ...prevState,
            [key]: value, // Dynamically update the key with the selected value
          };

          // Save updated state to localStorage
          localStorage.setItem("saveStates", JSON.stringify(updatedState));

          return updatedState;
        });
      };

      // Load initial state from localStorage
      useEffect(() => {
        const storedState = localStorage.getItem("saveStates");
        // console.log(storedState)
        // saveStates.height = storedState.height;
        // saveStates.weight = storedState.weight;
        if (storedState) {
          setSaveStates(JSON.parse(storedState)); // Load stored state if available
        }
        if(GetSectionDetails && GetSectionDetails?.demographics){
          const height = GetSectionDetails?.demographics?.height?.toString() || "";
          let feet = 0;
          let inches = 0;

          // Check if the height includes a dot
          if (height.includes(".")) {
            const [feetPart, inchPart] = height.split(".");
            feet = parseInt(feetPart, 10) || 0; // Set feet
            inches = parseInt(inchPart, 10) || 0; // Set inches
          } else {
            // If there's no dot, consider the value as feet
            feet = parseInt(height, 10) || 0;
          }
            setSaveStates((prevState) => ({
              ...prevState,
              weight: GetSectionDetails?.demographics?.weight, // Dynamically update the key with the selected value
            }));
            if(GetSectionDetails?.demographics.heightunit == "ft"){
              setSaveStates((prevState) => ({
                ...prevState,
                feetValue: feet,
                inchValue: inches,// Dynamically update the key with the selected value
              }));
            }else if (GetSectionDetails?.demographics.heightunit  == "cms"){
              setSaveStates((prevState) => ({
                ...prevState,
                height: GetSectionDetails?.demographics?.height, // Dynamically update the key with the selected value
              }));
            }
        }
        setcalculatedBMI(GetSectionDetails?.demographics?.bmi)
        console.log(GetSectionDetails)
        //hereb
        if(GetSectionDetails && GetSectionDetails?.demographics){
          if(GetSectionDetails?.demographics.heightunit == "ft"){
            switchBMICalculationForSaveState("standard" , true)
          }else if (GetSectionDetails?.demographics.heightunit  == "cms"){
            switchBMICalculationForSaveState("metric" , true)
          }
        }else{
        let localData = JSON.parse(storedState);
        if( localData && localData?.type){
        if(localData.type == "metric"){
            switchBMICalculationForSaveState("metric" , true)
        }else if (localData.type == "standard"){
          switchBMICalculationForSaveState("standard" , true)
        }
      
        
          
        }else {
            
          switchBMICalculationForSaveState("standard" , true)
        }
      }
      }, [GetSectionDetails]);

      // useEffect(()=>{
      //   if(BMICalculatorType == "standard"){

        
      //     console.log("BMI useeffect hit 1")
      //     }else if(BMICalculatorType == "metric"){
      
      //     setVitalUnitHeight("cms");
      //     setVitalUnitWeight("kg");
      //     }else{
      
      //     setVitalUnitHeight("ft");
      //     setVitalUnitWeight("lbs");
      //     console.log("BMI useeffect hit 1.2 else")

      //     }
          
      //     const tempOptions = [];
      //     const tempOptionsInches = [];
      //     const tempOptionsHeight = [];
      //     const tempOptionsWeightLB = [];
      //     for (let i = 1; i <= 10; i++) {
      //       tempOptions.push(i);
      //     }
      //     for (let i = 1; i <= 11; i++) {
      //       tempOptionsInches.push(i);
      //     }
        
      //     for (let i = 1; i <= 479; i++) {
      //       tempOptionsWeightLB.push(i);
      //     }
      //     setFeetOptions(tempOptions)
      //     setInchesOptions(tempOptionsInches)
      //     setWeightOptionsInBMI(tempOptionsWeightLB)
      // },[BMICalculatorType])

      let inputForSave = {
        "patientid": patientid,
        "visitid": appointmentid,
      
        "visitformdata": [
           // BMI 
           {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.3,
            "subsectionname": "Body Measurements (BMI Calculation)",
            "question": "Height",
            "subquestion": null,
            "nestedquestion": null,
            // "unit": saveStates.vitalunitheight,
            "value": saveStates?.type == "standard" ? 
            (saveStates?.feetValue && saveStates?.inchValue ? saveStates?.feetValue + "." + saveStates?.inchValue : saveStates?.feetValue) : 
             saveStates?.height,
            "description": null,
            "unit":vitalunitheightONPAGE
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.3,
            "subsectionname": "Body Measurements (BMI Calculation)",
            "question": "Weight",
            "subquestion": null,
            "nestedquestion": null,
            // "unit": saveStates.vitalunitweight,
            "value": saveStates.weight,
            "description": null,
            "unit":vitalunitweightONPAGE
          },

          // {
          //   "sectionid": 2,
          //   "sectionname": "Adult Patient Screening",
          //   "subsectionid": 2.3,
          //   "subsectionname": "General Health Information",
          //   "question": "Do you exercise regularly?",
          //   "subquestion": null,
          //   "nestedquestion": null,
          //   "value": saveStates.exerciseRegularly,
          //   "description": null
          // },

         

          // BMI End
          //2.4 start
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.4,
            "subsectionname": "Symptom Screening",
            "question": "Have you experienced any of the following symptoms recently?",
            "subquestion": "Fatigue",
            "nestedquestion": null,
            "value": saveStates.Fatigue ? saveStates.Fatigue : 'no',
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.4,
            "subsectionname": "Symptom Screening",
            "question": "Have you experienced any of the following symptoms recently?",
            "subquestion": "Dizziness",
            "nestedquestion": null,
            "value": saveStates.Dizziness ? saveStates.Dizziness : 'no',
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.4,
            "subsectionname": "Symptom Screening",
            "question": "Have you experienced any of the following symptoms recently?",
            "subquestion": "Chest Pain",
            "nestedquestion": null,
            "value": saveStates.ChestPain ? saveStates.ChestPain : 'no',
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.4,
            "subsectionname": "Symptom Screening",
            "question": "Have you experienced any of the following symptoms recently?",
            "subquestion": "Shortness of Breath",
            "nestedquestion": null,
            "value": saveStates.ShortnessofBreath ? saveStates.ShortnessofBreath : 'no',
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.4,
            "subsectionname": "Symptom Screening",
            "question": "Have you experienced any of the following symptoms recently?",
            "subquestion": "Persistent Cough",
            "nestedquestion": null,
            "value": saveStates.PersistentCough ? saveStates.PersistentCough : 'no',
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.4,
            "subsectionname": "Symptom Screening",
            "question": "Have you experienced any of the following symptoms recently?",
            "subquestion": "Unexplained Weight Loss",
            "nestedquestion": null,
            "value": saveStates.UnexplainedWeightLoss ? saveStates.UnexplainedWeightLoss : 'no',
            "description": null
          },
          
          //2.4 start End

          //2.5 Start 
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.5,
            "subsectionname": "Preventive Health Screening",
            "question": "Last Blood Pressure Check",
            "subquestion": null,
            "nestedquestion": null,
            "unit": "mmHg",
            "value": saveStates.systolicValue && saveStates.distolicValue? (saveStates.systolicValue + "/" + saveStates.distolicValue) : null,
            // "value": saveStates.LastBloodPressureCheckSystolic && saveStates.LastBloodPressureCheckDistolic? (saveStates.LastBloodPressureCheckSystolic + "/" + saveStates.LastBloodPressureCheckDistolic) : null,
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.5,
            "subsectionname": "Preventive Health Screening",
            "question": "Last Blood Sugar Check",
            "subquestion": null,
            "nestedquestion": null,
            "unit": "mmdL",
            "value": saveStates.LastBloodSugarCheck,
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.5,
            "subsectionname": "Preventive Health Screening",
            "question": "Cholesterol Levels (if known)",
            "subquestion": null,
            "nestedquestion": null,
            "unit": "mmdL",
            "value": saveStates.CholesterolLevels,
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.5,
            "subsectionname": "Preventive Health Screening",
            "question": "Prostate Cancer Screening (for men)",
            "subquestion": null,
            "nestedquestion": null,
            "value": saveStates.ProstateCancerScreening,
            "description": null
          },
          {
            "sectionid": 2,
            "sectionname": "Adult Patient Screening",
            "subsectionid": 2.5,
            "subsectionname": "Preventive Health Screening",
            "question": "Cervix and Breast Cancer Screening (for women)",
            "subquestion": null,
            "nestedquestion": null,
            "value": saveStates.CervixandBreastCancerScreening,
            "description": null
          }
          
          //2.5 End
        ]
        
      
        }
        let [pointerPos , setPointerPos] = useState(-5)

        const calculateBMI = () => {
          let bmiValue = 0; // Initialize BMI to avoid NaN
          const weight = parseFloat(saveStates.weight); // Ensure weight is a valid number
          const height = parseFloat(saveStates.height); // Ensure height is a valid number
          const feetValue = parseFloat(saveStates.feetValue); // Ensure feet value is valid
          const inchValue = parseFloat(saveStates.inchValue); // Ensure inch value is valid
        
          if (!weight || (!height && !feetValue && !inchValue)) {
            // If weight or height is invalid, set BMI to 0 and return
            setcalculatedBMI(0.0);
            setPointerPos(0);
            return;
          }
        
          if (saveStates?.type === "metric") {
            // Metric system: weight (kg) and height (cm)
            const heightInMeters = height / 100;
            bmiValue = weight / (heightInMeters * heightInMeters);
          } else if (saveStates?.type === "standard") {
            // Standard system: Convert feet and inches to total inches
            const totalHeightInInches =  (feetValue ? feetValue * 12 : 0) + (inchValue ? inchValue : 0);
        
            if (!totalHeightInInches) {
              // If total height is invalid, set BMI to 0 and return
              setcalculatedBMI(0.0);
              setPointerPos(0);
              return;
            }
        
            bmiValue = (weight / (totalHeightInInches * totalHeightInInches)) * 703;
          }
        
          setcalculatedBMI(bmiValue.toFixed(1));
          
        // bmiValue = Math.trunc(bmiValue * 10) / 10; // Truncate to 1 decimal place 
        bmiValue = bmiValue.toFixed(1); // Truncate to 1 decimal place 

          // Correct pointer position logic
          if (bmiValue < 18.5) {
            screenType == 'web' ? setPointerPos(-5) : setPointerPos(10);
            // setPointerPos(-5); // Underweight
          } else if (bmiValue >= 18.5 && bmiValue <= 24.9) {
            screenType == 'web' ? setPointerPos(25) : setPointerPos(35);
            // setPointerPos(25); // Normal weight
          } else if (bmiValue >= 25 && bmiValue <= 29.9) {
            screenType == 'web' ? setPointerPos(55) : setPointerPos(60);
            // setPointerPos(55); // Overweight
          } else if (bmiValue >= 30) {
            screenType == 'web' ? setPointerPos(85) : setPointerPos(85);
            // setPointerPos(85); // Obesity
          }
        };
        useEffect(() => {
          calculateBMI();
        }, [saveStates.height, saveStates.weight, saveStates.feetValue , saveStates.inchValue]); // Dependency array with height and weight
        
        const handlemedicinenamecontinue = () => {
          showmedicinename.current.closeRight();
          
        };
      // Save Data Functionality End
  return (
    <>
      {screenType == 'web' ? (

      <>
      {isLoading && <Loader />}
        <Flex gap='1rem' flexDir='column' w='100%'>
            {/* General Health Information start */}
            <Box>
            <Text fontSize='1rem' fontWeight='600'>General Health Information</Text>
                  <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you smoke?</Text>
                        <RadioGroup onChange={(value) => handleChange(value, 'socialhistory')} 
                        value={smokeradioValue} colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.socialhistory !== null &&
                        GetSectionDetails.socialhistory.some(
                          (step) => step.socialhistory === "Smoking" 
                        ) && (
                          <Box
                          className="buttonContainer"
                          py="10px"
                          mt="0.5rem"
                          w="100%"
                          overflowX="auto"
                          overflowY="hidden"
                          whiteSpace="nowrap"
                          display="flex"
                          gap="0.75rem"
                          ref={scrollRef}
                          onMouseDown={handleMouseDown}
                          onMouseLeave={handleMouseLeave}
                          onMouseUp={handleMouseUp}
                          onMouseMove={handleMouseMove}
                        >
                      {GetSectionDetails &&
                        GetSectionDetails.socialhistory !== null &&
                        GetSectionDetails.socialhistory.some(
                          (step) => step.socialhistory === "Smoking" 
                        ) && (
                          <Box
                            cursor="pointer"
                            title='Add new social history'
                            onClick={() => OpenAddSocialHistory('Smoking')}
                            h="auto"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              boxSize="50px"
                              border="1px dashed #E6E7E9"
                              borderRadius="10px"
                            >
                              <Image src="/assets/svgs/add.svg" />
                            </Flex>
                          </Box>
                      )}
                      {GetSectionDetails &&
                      GetSectionDetails.socialhistory
                        ?.filter((step) => step.socialhistory === "Smoking") // Filter for Smoking socialhistory
                        .map((step, i) => {
                          const fromDate = new Date(step.fromdate);
                          const toDate = new Date(step.todate);
                          const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                          let consumptionYears =
                            diffInYears > 0
                              ? `Consumption years : ${diffInYears} `
                              : "Consumption years : Less than a year";

                          return (
                            <Box
                            borderRadius="12px"
                            minH={"100px"}
                            // maxH={"150px"}
                            minW={"300px"}
                            w="100%"
                            maxW={"300px"}
                            border="1px solid #E6E7E9"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                            p='0.75rem'
                            display='flex'
                            flexDir='column'
                            // gap='0.5rem'
                          >
                            <Flex w='100%' alignItems='center' justifyContent='space-between'>
                              <Text fontSize="15px" fontWeight="600" isTruncated title={step.socialhistory}>
                              {step.socialhistory}
                              </Text>

                              <Box display="flex" gap="10px">
                                <Box
                                  as="button"
                                  onClick={() => handleeditsocialhistory(step, consumptionYears)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/edit-gray.png" />
                                </Box>
                                <Box
                                  as="button"
                                  onClick={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/delete.png" />
                                </Box>
                              </Box>
                            </Flex>
                            <Box display='flex' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Status: 
                                <span style={{ fontWeight: "500" }}>
                                {step.iscurrent == 1
                                      ? "Current"
                                      : step.iscurrent == 0
                                      ? "Never"
                                      : step.iscurrent == 2
                                      ? "Former"
                                      : ""}
                                </span>
                              </Text>
                            </Box>
                            {/* <Box display='flex' flexWrap='wrap' alignItems='center'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Type:
                              
                              </Text>
                              {step?.exercisesdaily?.map((exercise, index) => (
                                    <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.exercisesdaily?.map(reaction => reaction?.name).join(", ")}>
                                      {exercise.name}
                                      {index < step.exercisesdaily.length - 1 && ", "}
                                    </Box>
                                  ))}
                              
                            </Box> */}
                            <Box display='flex' flexWrap='wrap' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                <span style={{ fontWeight: "500",whiteSpace:'wrap' }}>
                                {step.frequency && step.frequency !== 0
                                    ? `${step.frequency} 
                                        ${
                                          step.socialhistory && step.socialhistory == "Smoking"
                                            ? "Packs"
                                            : step.socialhistory == "Alcohol"
                                            ? "Drinks"
                                            : step.socialhistory == "Smokeless"
                                            ? "Cans"
                                            : step.socialhistory == "Illicit Drugs"
                                            ? "Times":
                                        step.socialhistory == "Physical Activity" ? "Days"
                                            : "Packs"
                                        } 
                                        Per ${step.frequencytype}   
                                        x ${
                                          diffInYears == 1
                                            ? diffInYears + " year"
                                            : diffInYears > 1
                                            ? diffInYears + " years"
                                            : "less than a year"
                                        }`
                                    : null}
                                  {step.iscurrent == 2 && (
                                    <>
                                      {` Until ${
                                        step.todate ? formatDate_Do_MMM_YYYY(step.todate) : ""
                                      }`}
                                    </>
                                  )}
                                </span>
                              </Text>
                            </Box>
                          </Box>
                          );
                        })}
                        </Box>
                      )}
                  </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you consume alcohol?</Text>
                        <RadioGroup onChange={(value) => handleChange(value, 'alcohol')} 
                        value={alcoholradioValue} colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.socialhistory !== null &&
                        GetSectionDetails.socialhistory.some(
                          (step) => step.socialhistory === "Alcohol" 
                        ) && (
                          <Box
                          className="buttonContainer"
                          py="10px"
                          mt="0.5rem"
                          w="100%"
                          overflowX="auto"
                          overflowY="hidden"
                          whiteSpace="nowrap"
                          display="flex"
                          gap="0.75rem"
                          ref={scrollRef}
                          onMouseDown={handleMouseDown}
                          onMouseLeave={handleMouseLeave}
                          onMouseUp={handleMouseUp}
                          onMouseMove={handleMouseMove}
                        >
                      {GetSectionDetails &&
                        GetSectionDetails.socialhistory !== null &&
                        GetSectionDetails.socialhistory.some(
                          (step) => step.socialhistory === "Alcohol" 
                        ) && (
                          <Box
                            cursor="pointer"
                            title='Add new social history'
                            onClick={() => OpenAddSocialHistory('Alcohol')}
                            h="auto"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              boxSize="50px"
                              border="1px dashed #E6E7E9"
                              borderRadius="10px"
                            >
                              <Image src="/assets/svgs/add.svg" />
                            </Flex>
                          </Box>
                      )}
                      {GetSectionDetails &&
                      GetSectionDetails.socialhistory
                        ?.filter((step) => step.socialhistory === "Alcohol") // Filter for Smoking socialhistory
                        .map((step, i) => {
                          const fromDate = new Date(step.fromdate);
                          const toDate = new Date(step.todate);
                          const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                          let consumptionYears =
                            diffInYears > 0
                              ? `Consumption years : ${diffInYears} `
                              : "Consumption years : Less than a year";

                          return (
                            <Box
                            borderRadius="12px"
                            minH={"100px"}
                            // maxH={"150px"}
                            minW={"300px"}
                            w="100%"
                            maxW={"300px"}
                            border="1px solid #E6E7E9"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                            p='0.75rem'
                            display='flex'
                            flexDir='column'
                            // gap='0.5rem'
                          >
                            <Flex w='100%' alignItems='center' justifyContent='space-between'>
                              <Text fontSize="15px" fontWeight="600" isTruncated title={step.socialhistory}>
                              {step.socialhistory}
                              </Text>

                              <Box display="flex" gap="10px">
                                <Box
                                  as="button"
                                  onClick={() => handleeditsocialhistory(step, consumptionYears)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/edit-gray.png" />
                                </Box>
                                <Box
                                  as="button"
                                  onClick={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/delete.png" />
                                </Box>
                              </Box>
                            </Flex>
                            <Box display='flex' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Status: 
                                <span style={{ fontWeight: "500",whiteSpace:'wrap' }}>
                                {step.iscurrent == 1
                                      ? "Current"
                                      : step.iscurrent == 0
                                      ? "Never"
                                      : step.iscurrent == 2
                                      ? "Former"
                                      : ""}
                                </span>
                              </Text>
                            </Box>
                            {/* <Box display='flex' flexWrap='wrap' alignItems='center'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Type:
                              
                              </Text>
                              {step?.exercisesdaily?.map((exercise, index) => (
                                    <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.exercisesdaily?.map(reaction => reaction?.name).join(", ")}>
                                      {exercise.name}
                                      {index < step.exercisesdaily.length - 1 && ", "}
                                    </Box>
                                  ))}
                              
                            </Box> */}
                            <Box display='flex' flexWrap='wrap' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                <span style={{ fontWeight: "500",whiteSpace:'wrap' }}>
                                {step.frequency && step.frequency !== 0
                                    ? `${step.frequency} 
                                        ${
                                          step.socialhistory && step.socialhistory == "Smoking"
                                            ? "Packs"
                                            : step.socialhistory == "Alcohol"
                                            ? "Drinks"
                                            : step.socialhistory == "Smokeless"
                                            ? "Cans"
                                            : step.socialhistory == "Illicit Drugs"
                                            ? "Times": "Packs"
                                        } 
                                        Per ${step.frequencytype}   
                                        x ${
                                          diffInYears == 1
                                            ? diffInYears + " year"
                                            : diffInYears > 1
                                            ? diffInYears + " years"
                                            : "less than a year"
                                        }`
                                    : null}
                                  {step.iscurrent == 2 && (
                                    <>
                                      {` Until ${
                                        step.todate ? formatDate_Do_MMM_YYYY(step.todate) : ""
                                      }`}
                                    </>
                                  )}
                                </span>
                              </Text>
                            </Box>
                          </Box>
                          );
                        })}
                        </Box>
                      )}
                  </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                  <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                      <Text fontSize='0.875rem'>Do you exercise regularly?</Text>
                      <RadioGroup onChange={(value) => handleChange(value, 'exercise')} 
                      value={exerciseradioValue} colorScheme='green' display='flex' gap='1rem'>
                          <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                          <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                      </RadioGroup>
                  </Flex>
                  {GetSectionDetails &&
                      GetSectionDetails.socialhistory !== null &&
                      GetSectionDetails.socialhistory.some(
                        (step) => step.socialhistory === "Physical Activity" 
                      ) && (
                        <Box
                        className="buttonContainer"
                        py="10px"
                        mt="0.5rem"
                        w="100%"
                        overflowX="auto"
                        overflowY="hidden"
                        whiteSpace="nowrap"
                        display="flex"
                        gap="0.75rem"
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                      >
                    {GetSectionDetails &&
                      GetSectionDetails.socialhistory !== null &&
                      GetSectionDetails.socialhistory.some(
                        (step) => step.socialhistory === "Physical Activity" 
                      ) && (
                        <Box
                          cursor="pointer"
                          title='Add new social history'
                          onClick={() => OpenAddSocialHistory('Physical Activity')}
                          h="auto"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            boxSize="50px"
                            border="1px dashed #E6E7E9"
                            borderRadius="10px"
                          >
                            <Image src="/assets/svgs/add.svg" />
                          </Flex>
                        </Box>
                    )}
                    {GetSectionDetails &&
                    GetSectionDetails.socialhistory
                      ?.filter((step) => step.socialhistory === "Physical Activity") // Filter for Smoking socialhistory
                      .map((step, i) => {
                        const fromDate = new Date(step.fromdate);
                        const toDate = new Date(step.todate);
                        const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                        let consumptionYears =
                          diffInYears > 0
                            ? `Consumption years : ${diffInYears} `
                            : "Consumption years : Less than a year";

                        return (
                          <Box
                          borderRadius="12px"
                          minH={"100px"}
                          // maxH={"150px"}
                          minW={"300px"}
                          w="100%"
                          maxW={"300px"}
                          border="1px solid #E6E7E9"
                          boxShadow="0px 8px 20px 0px #E6E7E966"
                          p='0.75rem'
                          display='flex'
                          flexDir='column'
                          // gap='0.5rem'
                        >
                          <Flex w='100%' alignItems='center' justifyContent='space-between'>
                            <Text fontSize="15px" fontWeight="600" isTruncated title={step.socialhistory}>
                            {step.socialhistory}
                            </Text>

                             <Box display="flex" gap="10px">
                              <Box
                                as="button"
                                onClick={() => handleeditsocialhistory(step, consumptionYears)}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/edit-gray.png" />
                              </Box>
                              <Box
                                as="button"
                                onClick={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box>
                          </Flex>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Status: 
                              <span style={{ fontWeight: "500" }}>
                              {step.iscurrent == 1
                                    ? "Current"
                                    : step.iscurrent == 0
                                    ? "Never"
                                    : step.iscurrent == 2
                                    ? "Former"
                                    : ""}
                              </span>
                            </Text>
                          </Box>
                          <Box display='flex' flexWrap='wrap' alignItems='center'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Type:
                             
                            </Text>
                             {step?.exercisesdaily?.map((exercise, index) => (
                                  <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.exercisesdaily?.map(reaction => reaction?.name).join(", ")}>
                                    {exercise.name}
                                    {index < step.exercisesdaily.length - 1 && ", "}
                                  </Box>
                                ))}
                            {/* <Flex >
                              
                            </Flex> */}
                          </Box>
                          <Box display='flex' flexWrap='wrap' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              <span style={{ fontWeight: "500" }}>
                              {step.frequency && step.frequency !== 0
                                  ? `${step.frequency} 
                                      ${
                                        step.socialhistory && step.socialhistory == "Smoking"
                                          ? "Packs"
                                          : step.socialhistory == "Alcohol"
                                          ? "Drinks"
                                          : step.socialhistory == "Smokeless"
                                          ? "Cans"
                                          : step.socialhistory == "Illicit Drugs"
                                          ? "Times":
                                      step.socialhistory == "Physical Activity" ? "Days"
                                          : "Packs"
                                      } 
                                      Per ${step.frequencytype}   
                                      x ${
                                        diffInYears == 1
                                          ? diffInYears + " year"
                                          : diffInYears > 1
                                          ? diffInYears + " years"
                                          : "less than a year"
                                      }`
                                  : null}
                                {step.iscurrent == 2 && (
                                  <>
                                    {` Until ${
                                      step.todate ? formatDate_Do_MMM_YYYY(step.todate) : ""
                                    }`}
                                  </>
                                )}
                              </span>
                            </Text>
                          </Box>
                        </Box>
                        );
                      })}
                      </Box>
                    )}
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you have any known allergies?</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'allergy')}
                         colorScheme='green' display='flex' gap='1rem'
                         value={allergyradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                            <Radio value='not known'><Text fontSize='0.875rem'>Not known</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientallergies != null &&
                    <Box
                    className="buttonContainer"
                    py="10px"
                    mt="0.5rem"
                    w="100%"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    display="flex"
                    gap="0.75rem"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                     {GetSectionDetails &&
                    GetSectionDetails.patientallergies != null &&
                    <Box title='Add new allergy' cursor='pointer' onClick={()=>openAllergiesModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientallergies?.map((step, i) => (
                          <Box
                          borderRadius="12px"
                          minH={"100px"}
                          // maxH={"150px"}
                          minW={"300px"}
                          w="100%"
                          maxW={"300px"}
                          border="1px solid #E6E7E9"
                          boxShadow="0px 8px 20px 0px #E6E7E966"
                          p='0.75rem'
                          display='flex'
                          flexDir='column'
                          // gap='0.5rem'
                        >
                          <Flex w='100%' alignItems='center' justifyContent='space-between'>
                            <Text fontSize="15px" fontWeight="600" isTruncated title={step.allergytype}>
                            {step.allergytype}
                            </Text>

                             <Box display="flex" gap="10px">
                              <Box
                                as="button"
                                onClick={() =>
                                  openEditAllergyModal(
                                    step.patientallergyid,
                                    step.allergytype,
                                    step.allergyidentifieddate,
                                    step.reaction,
                                    step.allergytype_cd
                                  )}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/edit-gray.png" />
                              </Box>
                              <Box
                                as="button"
                                onClick={() =>
                                  openDeleteModalAllergy(step.patientallergyid, step.allergytype)}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box>
                          </Flex>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Date: 
                              <span style={{ fontWeight: "500" }}>
                              {step.allergyidentifieddate? formatDate_Do_MMM_YYYY(step.allergyidentifieddate):"-"}
                              </span>
                            </Text>
                          </Box>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Reactions:
                            </Text>
                            <Flex flexWrap='wrap'>
                            {step?.reaction?.map((reaction, index) => (
                                  <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.reaction?.map(reaction => reaction?.reaction).join(", ")}>
                                    {reaction.reaction}
                                    {index < step.reaction.length - 1 && ", "}
                                  </Box>
                                ))}
                            </Flex>
                          </Box>
                        </Box>
                         
                        ))}
                  </Box>
                }

                </Box>
            </Box>
            {/* General Health Information end */}

            <Divider />
            
            {/* Personal Medical History start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Personal Medical History</Text>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you have any chronic conditions? (e.g., Diabetes, Hypertension, Heart Disease)</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'medicalconditions')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={conditionradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails?.patientmedicalcondition != null&&
                    <Box
                    className="buttonContainer"
                    py="10px"
                    mt="0.5rem"
                    w="100%"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    display="flex"
                    gap="0.75rem"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                     {GetSectionDetails &&
                    GetSectionDetails.patientmedicalcondition != null &&
                    <Box title='Add new medical condition' cursor='pointer' onClick={()=>openAddConditionModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails?.patientmedicalcondition?.map((category, categoryIndex) => (
                        <React.Fragment key={category.conditiontype_cd}>
                          {category.contiondata?.map((condition, conditionIndex) => (
                            <ReportViewCard
                              key={`${categoryIndex}-${conditionIndex}`}
                              cardHeight="auto"
                              minCardWidth="300px"
                              maxCardWidth="300px"
                              cardHeaderStyle={{maxW:'170px'}}
                              cardHeader={condition.condition} // Displaying condition from contiondata
                              cardSecondary={
                                <Text fontWeight="bold" title={condition.conditionidentifieddate? formatDate_Do_MMM_YYYY(condition.conditionidentifieddate):"-"}>
                                  {"Date: "}
                                  <span style={{ fontWeight: "500" }}>
                                    {condition.conditionidentifieddate? formatDate_Do_MMM_YYYY(condition.conditionidentifieddate):"-"}
                                  </span>
                                </Text>
                              }
                              cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                              cardBtn={false}
                              cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                              deleteBtn={false}
                              editDeleteBtn={() => openDeleteModalConditions(condition.id, condition.condition)}
                              editBtn={() => openEditModalCondition(
                                condition.condition, 
                                condition.condition_cd, 
                                condition.conditionidentifieddate, 
                                condition.id, 
                                category.conditiontype, 
                                category.conditiontype_cd
                              )}
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </Box>
                  }

                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Any history of surgeries?</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'surgeries')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={surgeryradioValue}>
                            <Radio value="yes"><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value="no"><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientsurgeries != null &&
                    <Box
                    className="buttonContainer"
                    py="10px"
                    mt="0.5rem"
                    w="100%"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    display="flex"
                    gap="0.75rem"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                     {GetSectionDetails &&
                    GetSectionDetails.patientsurgeries != null &&
                    <Box title='Add new surgery' cursor='pointer' onClick={()=>openSurgeriesModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientsurgeries?.map((step, i) => (
                          <ReportViewCard
                            key={i}
                            //cardStyle={{ h: "205px" }}
                            cardHeight='auto'
                            minCardWidth="300px"
                            maxCardWidth="300px"
                            cardHeaderStyle={{maxW:'170px'}}
                            cardHeader={step.proceduretitle}
                            cardSecondary={
                              <Text fontWeight="bold" title={step.proceduredate?formatDate_Do_MMM_YYYY(step.proceduredate):"-"}>
                                {"Date: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.proceduredate?formatDate_Do_MMM_YYYY(step.proceduredate):"-"}
                                </span>
                              </Text>
                            }
                            cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                            cardBtn={false}
                            cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                            deleteBtn={false}
                            editDeleteBtn={() => openDeleteModalSurgery(step.id, step.proceduretitle)}
                            editBtn={() =>
                              openEditModalSurgery(
                                step.id,
                                step.surgeonname,
                                step.hospitalname,
                                step.proceduredate,
                                step.proceduretitle,
                              )}
                          />
                        ))}
                    </Box>
                  }
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Current Medications </Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'medication')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={medicationradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientmedicine != null &&
                    <Box
                      className="buttonContainer"
                      py="10px"
                      mt="0.5rem"
                      w="100%"
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      display="flex"
                      gap="0.75rem"
                      ref={scrollRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                       {GetSectionDetails &&
                    GetSectionDetails.patientmedicine != null &&
                    <Box title='Add new medicine' cursor='pointer' onClick={()=>addData()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientmedicine?.map((step, i) => (
                          <ReportViewCard
                            key={i}
                            //cardStyle={{ h: "205px" }}
                            cardHeight='auto'
                            minCardWidth="300px"
                            maxCardWidth="300px"
                            cardHeaderStyle={{maxW:'170px'}}
                            cardHeader={step.medicinename}
                            cardSecondary={
                              <Text fontWeight="bold" title={step.medicinetype}>
                                {"Type: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.medicinetype}
                                </span>
                              </Text>
                            }
                            cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                            cardBtn={false}
                            cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                            deleteBtn={false}
                            editDeleteBtn={() =>
                              DeleteMedicineModel(
                                Step.medicinename,
                                step.id
                              )
                            }
                            editBtn={() => handleeditmedication(step)}
                          />
                        ))}
                        </Box>
                        }
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Family History of Chronic Illnesses  </Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'family')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={familyradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientfamily != null &&
                    <Box
                      className="buttonContainer"
                      py="10px"
                      mt="0.5rem"
                      w="100%"
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      display="flex"
                      gap="0.75rem"
                      ref={scrollRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      >
                         {GetSectionDetails &&
                    GetSectionDetails.patientfamily != null &&
                    <Box title='Add new family member' cursor='pointer' onClick={()=>addfamilymodel()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientfamily?.map((step, i) => (
                         
                          <Box
                            borderRadius="12px"
                            minH={"100px"}
                            // maxH={"150px"}
                            minW={"300px"}
                            w="100%"
                            maxW={"300px"}
                            border="1px solid #E6E7E9"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                            p='0.75rem'
                            display='flex'
                            flexDir='column'
                            // gap='0.5rem'
                          >
                            <Flex w='100%' alignItems='center' justifyContent='space-between'>
                              <Text fontSize="15px" fontWeight="600" isTruncated title={step.fullname}>
                                {step.fullname}
                              </Text>

                               <Box display="flex" gap="10px">
                                <Box
                                  as="button"
                                  onClick={() =>handleFamilyEdit(step)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/edit-gray.png" />
                                </Box>
                                <Box
                                  as="button"
                                  onClick={()=> handleFamilyDelete(step.familyid,step.fullname)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/delete.png" />
                                </Box>
                              </Box>
                            </Flex>
                            <Box display='flex' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Relation: 
                                <span style={{ fontWeight: "500" }}>
                                  {step.relation}
                                </span>
                              </Text>
                            </Box>
                            <Box display='flex' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Conditions:
                              </Text>
                              <Flex flexWrap='wrap'>
                                {step?.medicalcondition?.map((condition, index) => (
                                  <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.medicalcondition?.map(condition => condition.condition).join(", ")}>
                                    {condition.condition}
                                    {index < step.medicalcondition.length - 1 && ", "}
                                  </Box>
                                ))}
                              </Flex>
                            </Box>
                          </Box>
                        ))}
                        </Box>
                  }
                </Box>
               

            </Box>
            {/* Personal Medical History end */}
            
            <Divider />
            
            {/* Body Measurements start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Body Measurements (BMI Calculation)</Text>
                <RadioGroup 
                  onChange={switchBMICalculationForSaveState}
                  value={saveStates.type ? saveStates.type : "standard"}
                  w="100%"
                  bg="#FFFFFF"
                  // border="1px solid #e2e8f0"
                  pl="10px"
                  >
                      <Box display="flex" justifyContent="start" gap="1.5rem" py="10px" w="100%">
                        <Radio value="standard" colorScheme="green">
                          Standard
                        </Radio>
                      
                        <Radio value="metric" colorScheme="green">
                          Metric
                        </Radio>

                      </Box>
                    </RadioGroup>
                <Flex flexDir='column' gap='0.75rem'>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem' w='50%'>Height (in  {vitalunitheightONPAGE})</Text>
                        {/* <PrimaryInput variant='fullRound' inputPlace='cm' inputStyleCss={{w:'25%'}}/> */}
                       
                    {!showInchesInputONPAGE && 
                
                    <DropdownInputForVitals
                      variant="fullSide"
                      inputStyleCss={{w: "50%"}}
                      dropDownPlace="Enter value"
                      options={heightOptionsInBMI}
                      onClick={setHeightValueInBMIDropDownONPAGE}
                      onChange={handlebmivitalvaluechangeONPAGE}
                      menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                      readOnly=""
                      id="height"
                      className="StandardMetric"
                      // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                      inputValueProp={saveStates.height}
                    />
                    }
                    
                    {showInchesInputONPAGE && 
                    <>
                    <Box w="50%" display="flex">
                        <DropdownInputForVitals
                          variant="fullLeft"
                          inputStyleCss={{w: "50%"}}
                          dropDownPlace="Feet"
                          options={feetOptions}
                          onClick={setFeetValueInDropDownONPAGE}
                          onChange={handleBMIFeetValueONPAGE}
                          readOnly=""
                          id="height"
                          className="StandardMetric"
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          inputValueProp={saveStates.feetValue}
                          maxLength={1}
                        />

                  
                        <DropdownInputForVitals
                          variant="fullRight"
                          inputStyleCss={{w: "50%"}}
                          dropDownPlace="Inches"
                          options={inchesOptions}
                          onClick={setInchValueInDropDownONPAGE}
                          onChange={handleBMIinchValueONPAGE}
                          readOnly=""
                          id="height"
                          className="StandardMetric"
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          inputValueProp={saveStates.inchValue}
                          maxLength={2}
                        />
                      </Box>
                    </>
                    }
                  </Box>

                        
                  
                    <Box display='flex' justifyContent='space-between' w='100%'>
                        <Box w='50%'>
                            <Text fontSize='0.875rem'>Weight (in  {vitalunitweightONPAGE})</Text>
                            {/* <Text fontSize='0.75rem' fontWeight='500'>(Calculate BMI = Weight in kg / (Height in meters)^2)</Text> */}
                        </Box>
                        
                      <DropdownInputForVitals
                      
                          variant="fullSide"
                          dropDownPlace="Enter Value"
                          options={weightOptionsInBMI}
                          onClick={setWeightvalueInBMIDropdownONPAGE}
                          onChange={handlebmivitalvaluechangeONPAGE}
                          menuList={{pos: "absolute", right: "-45px", minW: "115px", zIndex:"999"}}
                          readOnly=""
                          id="weight"
                          inputStyleCss={{w: "25%"}}
                          className="StandardMetric"
                          
                          // defaultOption={saveStates.weight}
                            inputValueProp={saveStates.weight}
                            
                        />
                        {/* <PrimaryInput variant='fullRound' inputPlace='kg' inputStyleCss={{w:'25%'}}/> */}
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem' fontWeight='600'>BMI (calculated):</Text>
                        <Text fontSize='0.875rem' fontWeight='600'>{calculatedBMI || 0}</Text>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem' fontWeight='600'>BMI Categories:</Text>
                        <Box
                            width="50%"
                            borderRadius="50px"
                            display="flex"
                            position="relative"
                            mt="20px"
                            zIndex="9"
                           >
                            {/* Sections */}
                            {bmiCategories.map(({ label, range, color, left, radius }, index) => (
                                <Box
                                key={index}
                                position="absolute"
                                top="0"
                                left={left}
                                width="30%"
                                height="60%"
                                bg={color}
                                borderRadius={radius || "none"}
                                >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontSize="12px"
                                    h="100%"
                                    color={label == 'Overweight' ? 'black' : 'white'}
                                    pos='relative'
                                    textAlign='center'
                                >
                                    {label}
                                    <Text pos='absolute' top='-20px' color='black' fontWeight='500'>{range}</Text>
                                </Box>
                                </Box>
                            ))}

                            {/* Pointer */}
                            <Box
                                position="absolute"
                                top="30px"
                                left={`${pointerPos}%`}
                                //left={'33%'}
                                width="0"
                                height="0"
                                borderLeft="6px solid transparent"
                                borderRight="6px solid transparent"
                                borderBottom="12px solid black"
                            />

                            {/* Slider */}
                            <Slider
                                mt={10}
                                defaultValue={50}
                                min={0}
                                max={100}
                                step={1}
                                // onChange={(val) => setMeter(val)}
                            />
                            </Box>
                    </Box>
                </Flex>

            </Box>
            {/* Body Measurements end */}

            <Divider />
            
            {/* Symptom Screening start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Have you experienced any of the following symptoms recently?</Text>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Fatigue?</Text>
                        <RadioGroup value={saveStates.Fatigue ? saveStates.Fatigue : 'no'} onChange={(value)=>handleChangeRadio("Fatigue" ,value )} colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Dizziness:</Text>
                        <RadioGroup  value={saveStates.Dizziness ? saveStates.Dizziness : 'no'} onChange={(value)=>handleChangeRadio("Dizziness" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Chest Pain:</Text>
                        <RadioGroup  value={saveStates.ChestPain ? saveStates.ChestPain : 'no'} onChange={(value)=>handleChangeRadio("ChestPain" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Shortness of Breath:</Text>
                        <RadioGroup  value={saveStates.ShortnessofBreath ? saveStates.ShortnessofBreath : "no"} onChange={(value)=>handleChangeRadio("ShortnessofBreath" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Persistent Cough:</Text>
                        <RadioGroup  value={saveStates.PersistentCough ? saveStates.PersistentCough : 'no'} onChange={(value)=>handleChangeRadio("PersistentCough" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Unexplained Weight Loss:</Text>
                        <RadioGroup  value={saveStates.UnexplainedWeightLoss ? saveStates.UnexplainedWeightLoss : 'no'} onChange={(value)=>handleChangeRadio("UnexplainedWeightLoss" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
            </Box>
            {/* Symptom Screening end */}
            
            <Divider />
            
            {/* Preventive Health Screening start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Preventive Health Screening</Text>
                <Flex flexDir='column' gap='0.75rem'>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem'>Last Blood Pressure Check:</Text>
                        <Box display='flex' alignItems='center' justifyContent='flex-end' w='50%'>
                        {/* <PrimaryInput inputValue={saveStates.LastBloodPressureCheckSystolic} onChange={(value)=>handleChangeInput("LastBloodPressureCheckSystolic" ,value )} variant='fullLeft' inputPlace='Systolic (mmHg)' inputStyleCss={{w:'35%'}}/> */}

                        <DropdownInputForVitals
                        variant="fullLeft"
                        inputStyleCss={{w: "50%"}}
                        dropDownPlace="Systolic (mmHg)"
                        options={systolicOptionsInBP}
                        onClick={setSystolicBPValueInDropDownONPAGE}
                        onChange={handleSystolicBPValueONPAGE}
                        menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                        readOnly=""
                        id="height"
                        className="StandardMetric"
                        inputValueProp={saveStates.systolicValue}
                      />
  
  <DropdownInputForVitals
                        variant="fullLeft"
                        inputStyleCss={{w: "50%"}}
                        dropDownPlace="Distolic (mmHg)"
                        options={systolicOptionsInBP}
                        onClick={setDistolicBPValueInDropDownONPAGE}
                        onChange={handleDistolicBPValueONPAGE}
                        // onChange={(value)=>handleChangeInput("LastBloodPressureCheckSystolic" ,value )}
                        menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                        readOnly=""
                        id="height"
                        className="StandardMetric"
                        inputValueProp={saveStates.distolicValue}
                      />

                        {/* <PrimaryInput inputValue={saveStates.LastBloodPressureCheckDistolic} onChange={(value)=>handleChangeInput("LastBloodPressureCheckDistolic" ,value )} variant='fullRight' inputPlace='Diastolic (mmHg)' inputStyleCss={{w:'35%'}}/> */}
                        </Box>
                    </Box>
                    <Divider/>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem'>Last Blood Sugar Check:</Text>
                        <PrimaryInput  inputValue={saveStates.LastBloodSugarCheck} onChange={(value)=>handleChangeInput("LastBloodSugarCheck" ,value )} variant='fullRound' inputPlace='Value (mmdL)' inputStyleCss={{w:'25%'}}/>
                    </Box>
                    <Divider/>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem'>Cholesterol Levels (if known):</Text>
                       <PrimaryInput inputValue={saveStates.CholesterolLevels} onChange={(value)=>handleChangeInput("CholesterolLevels" ,value )} variant='fullRound' inputPlace='Value (mmdL)' inputStyleCss={{w:'25%'}}/>
                   
                    </Box>
                    <Divider/>
                    {patientData.gender === 2 ?(
                      <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                            <Text fontSize='0.875rem'>Prostate Cancer Screening (for men):</Text>
                            <RadioGroup   value={saveStates.ProstateCancerScreening} onChange={(value)=>handleChangeRadio("ProstateCancerScreening" ,value )}   colorScheme='green' display='flex' gap='1rem'>
                                <Radio value='done'><Text fontSize='0.875rem'>Done</Text></Radio>
                                <Radio value='not done'><Text fontSize='0.875rem'>Not Done</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </Box>
                    ):(
                      <Box w='100%' display='flex' flexDir='column'>
                      <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                          <Text fontSize='0.875rem'>Cervix and Breast Cancer Screening (for women):</Text>
                          <RadioGroup   value={saveStates.CervixandBreastCancerScreening} onChange={(value)=>handleChangeRadio("CervixandBreastCancerScreening" ,value )}   colorScheme='green' display='flex' gap='1rem'>
                              <Radio value='done'><Text fontSize='0.875rem'>Done</Text></Radio>
                              <Radio value='not done'><Text fontSize='0.875rem'>Not Done</Text></Radio>
                          </RadioGroup>
                      </Flex>
                   </Box>
                    )}
                    
                </Flex>

            </Box>
            {/* Preventive Health Screening end */}

        </Flex>
        <Box pos='fixed' w='calc(100% - 6rem)' left='5.75rem' bottom='0' p='1rem' bg='white' boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'>
          <PrimaryButton buttonText='Save' onClick={saveSectiontwo} btnStyle={{w:'25%', float:'right'}} />
        </Box>
      </>
      ) : (
        <>
          <Flex gap='1rem' flexDir='column' w='100%'>
            {/* General Health Information start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>General Health Information</Text>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between' flexWrap='wrap'>
                        <Text fontSize='0.875rem'>Do you smoke?</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'socialhistory')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={smokeradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                      GetSectionDetails.socialhistory !== null &&
                      GetSectionDetails.socialhistory.some(
                        (step) => step.socialhistory === "Smoking" 
                      ) && (
                    <Box className="buttonContainer" py='10px' mt='0.5rem' w='100%' overflowX='auto' overflowY='hidden' whiteSpace='nowrap' display='flex' gap='0.75rem' ref={scrollRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp}  onMouseMove={handleMouseMove}>
                    {GetSectionDetails &&
                      GetSectionDetails.socialhistory !== null &&
                      GetSectionDetails.socialhistory.some(
                        (step) => step.socialhistory === "Smoking" 
                      ) && (
                        <Box
                          cursor="pointer"
                          title='Add new social history'
                          onClick={() => OpenAddSocialHistory('Smoking')}
                          h="auto"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            boxSize="50px"
                            border="1px dashed #E6E7E9"
                            borderRadius="10px"
                          >
                            <Image src="/assets/svgs/add.svg" />
                          </Flex>
                        </Box>
                    )}
                    {GetSectionDetails &&
                    GetSectionDetails.socialhistory
                      ?.filter((step) => step.socialhistory === "Smoking") // Filter for Smoking socialhistory
                      .map((step, i) => {
                        const fromDate = new Date(step.fromdate);
                        const toDate = new Date(step.todate);
                        const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                        let consumptionYears =
                          diffInYears > 0
                            ? `Consumption years : ${diffInYears} `
                            : "Consumption years : Less than a year";

                        return (
                          <ReportViewCard
                            key={i}
                            cardStyle={{ h: "205px" }}
                            minCardWidth="300px"
                            maxCardWidth="300px"
                            cardHeaderStyle={{maxW:'170px'}}
                            cardHeader={step.socialhistory}
                            cardSecondary={
                              <Text fontWeight="bold">
                                {"Status: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.iscurrent == 1
                                    ? "Current"
                                    : step.iscurrent == 0
                                    ? "Never"
                                    : step.iscurrent == 2
                                    ? "Former"
                                    : ""}
                                </span>
                              </Text>
                            }
                            cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                            cardText={
                              <>
                                {step.smokelesstype
                                  ? capitalizeFirstLetter(step.smokelesstype)
                                  : ""}{" "}
                                &nbsp;
                                {step.drugtype ? capitalizeFirstLetter(step.drugtype) : ""}
                                <br />
                                {step.frequency && step.frequency !== 0
                                  ? `${step.frequency} 
                                      ${
                                        step.socialhistory && step.socialhistory == "Smoking"
                                          ? "Packs"
                                          : step.socialhistory == "Alcohol"
                                          ? "Drinks"
                                          : step.socialhistory == "Smokeless"
                                          ? "Cans"
                                          : step.socialhistory == "Illicit Drugs"
                                          ? "Times"
                                          : "Packs"
                                      } 
                                      Per ${step.frequencytype}   
                                      x ${
                                        diffInYears == 1
                                          ? diffInYears + " year"
                                          : diffInYears > 1
                                          ? diffInYears + " years"
                                          : "less than a year"
                                      }`
                                  : null}
                                {step.iscurrent == 2 && (
                                  <>
                                    {` Until ${
                                      step.todate ? formatDate_yyyy(step.todate) : ""
                                    }`}
                                  </>
                                )}
                              </>
                            }
                            cardBtn={false}
                            cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                            deleteBtn={false}
                            editBtn={() => handleeditsocialhistory(step, consumptionYears)}
                            editDeleteBtn={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                          />
                        );
                      })}
                      </Box>
                    )}
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                  <Flex gap='1rem' mt='0.875rem' justifyContent='space-between' flexWrap='wrap'>
                      <Text fontSize='0.875rem'>Do you consume alcohol?</Text>
                      <RadioGroup 
                      onChange={(value) => handleChange(value, 'alcohol')} 
                      colorScheme='green' display='flex' gap='1rem'
                      value={alcoholradioValue}>
                          <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                          <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                      </RadioGroup>                   
                  </Flex>
                  {GetSectionDetails &&
                  GetSectionDetails.socialhistory !== null &&
                  GetSectionDetails.socialhistory.some(
                    (step) => step.socialhistory === "Alcohol"
                  ) && (
                  <Box className="buttonContainer" py='10px' mt='0.5rem' w='100%' overflowX='auto' overflowY='hidden' whiteSpace='nowrap' display='flex' gap='0.75rem' ref={scrollRef} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp}  onMouseMove={handleMouseMove}>
                 {GetSectionDetails &&
                  GetSectionDetails.socialhistory !== null &&
                  GetSectionDetails.socialhistory.some(
                    (step) => step.socialhistory === "Alcohol"
                  ) && (
                    <Box
                      cursor="pointer"
                      title='Add new social history'
                      onClick={() => OpenAddSocialHistory("Alcohol")}
                      h="auto"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        boxSize="50px"
                        border="1px dashed #E6E7E9"
                        borderRadius="10px"
                      >
                        <Image src="/assets/svgs/add.svg" />
                      </Flex>
                    </Box>
                  )}

                      {GetSectionDetails &&
                        GetSectionDetails.socialhistory
                          ?.filter((step) => step.socialhistory === "Alcohol") // Filter items with socialhistory as "Alcohol"
                          .map((step, i) => {
                            const fromDate = new Date(step.fromdate);
                            const toDate = new Date(step.todate);
                            const diffInYears = toDate.getFullYear() - fromDate.getFullYear();

                            let consumptionYears =
                              diffInYears > 0
                                ? `Consumption years : ${diffInYears} `
                                : "Consumption years : Less than a year";

                            return (
                              <ReportViewCard
                                key={i}
                                cardStyle={{ h: "205px" }}
                                minCardWidth="300px"
                                maxCardWidth="300px"
                                cardHeaderStyle={{maxW:'170px'}}
                                cardHeader={step.socialhistory}
                                cardSecondary={
                                  <Text fontWeight="bold">
                                    {"Status: "}
                                    <span style={{ fontWeight: "500" }}>
                                      {step.iscurrent == 1
                                        ? "Current"
                                        : step.iscurrent == 0
                                        ? "Never"
                                        : step.iscurrent == 2
                                        ? "Former"
                                        : ""}
                                    </span>
                                  </Text>
                                }
                                cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                                cardText={
                                  <>
                                    {step.smokelesstype
                                      ? capitalizeFirstLetter(step.smokelesstype)
                                      : ""}{" "}
                                    &nbsp;
                                    {step.drugtype ? capitalizeFirstLetter(step.drugtype) : ""}
                                    <br />
                                    {step.frequency && step.frequency !== 0
                                      ? `${step.frequency} 
                                          ${
                                            step.socialhistory && step.socialhistory == "Smoking"
                                              ? "Packs"
                                              : step.socialhistory == "Alcohol"
                                              ? "Drinks"
                                              : step.socialhistory == "Smokeless"
                                              ? "Cans"
                                              : step.socialhistory == "Illicit Drugs"
                                              ? "Times"
                                              : "Packs"
                                          } 
                                          Per ${step.frequencytype}   
                                          x ${
                                            diffInYears == 1
                                              ? diffInYears + " year"
                                              : diffInYears > 1
                                              ? diffInYears + " years"
                                              : "less than a year"
                                          }`
                                      : null}
                                    {step.iscurrent == 2 && (
                                      <>
                                        {` Until ${
                                          step.todate ? formatDate_yyyy(step.todate) : ""
                                        }`}
                                      </>
                                    )}
                                  </>
                                }
                                cardBtn={false}
                                cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                                deleteBtn={false}
                                editBtn={() => handleeditsocialhistory(step, consumptionYears)}
                                editDeleteBtn={() => deletesocialhistorymodel(step.id, step.socialhistory)}
                              />
                            );
                          })}
                  </Box>
                 )}
                </Box>
                <Divider mt='0.75rem'/>
                <Flex gap='1rem' mt='0.875rem' justifyContent='space-between' flexWrap='wrap'>
                    <Text fontSize='0.875rem'>Do you exercise regularly?</Text>
                    <RadioGroup onChange={(value) => handleChange(value, 'exercise')} 
                    value={exerciseradioValue} colorScheme='green' display='flex' gap='1rem'>
                        <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                        <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                    </RadioGroup>
                </Flex>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you have any known allergies?</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'allergy')}
                         colorScheme='green' display='flex' gap='1rem'
                         value={allergyradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientallergies != null &&
                    <Box
                    className="buttonContainer"
                    py="10px"
                    mt="0.5rem"
                    w="100%"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    display="flex"
                    gap="0.75rem"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                     {GetSectionDetails &&
                    GetSectionDetails.patientallergies != null &&
                    <Box title='Add new allergy' cursor='pointer' onClick={()=>openAllergiesModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientallergies?.map((step, i) => (
                          <Box
                          borderRadius="12px"
                          minH={"100px"}
                          // maxH={"150px"}
                          minW={"300px"}
                          w="100%"
                          maxW={"300px"}
                          border="1px solid #E6E7E9"
                          boxShadow="0px 8px 20px 0px #E6E7E966"
                          p='0.75rem'
                          display='flex'
                          flexDir='column'
                          // gap='0.5rem'
                        >
                          <Flex w='100%' alignItems='center' justifyContent='space-between'>
                            <Text fontSize="15px" fontWeight="600" isTruncated title={step.allergytype}>
                            {step.allergytype}
                            </Text>

                             <Box display="flex" gap="10px">
                              <Box
                                as="button"
                                onClick={() =>
                                  openEditAllergyModal(
                                    step.patientallergyid,
                                    step.allergytype,
                                    step.allergyidentifieddate,
                                    step.reaction,
                                    step.allergytype_cd
                                  )}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/edit-gray.png" />
                              </Box>
                              <Box
                                as="button"
                                onClick={() =>
                                  openDeleteModalAllergy(step.patientallergyid, step.allergytype)}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box>
                          </Flex>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Date: 
                              <span style={{ fontWeight: "500" }}>
                              {step.allergyidentifieddate? formatDate_DD_MM_YYYY(step.allergyidentifieddate):"-"}
                              </span>
                            </Text>
                          </Box>
                          <Box display='flex' flexDir='column'>
                            <Text fontWeight='600' fontSize='0.75rem'>
                              Reactions:
                            </Text>
                            <Flex flexWrap='wrap'>
                            {step?.reaction?.map((reaction, index) => (
                                  <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.reaction?.map(reaction => reaction?.reaction).join(", ")}>
                                    {reaction.reaction}
                                    {index < step.reaction.length - 1 && ", "}
                                  </Box>
                                ))}
                            </Flex>
                          </Box>
                        </Box>
                         
                        ))}
                  </Box>
                }

                </Box>
            </Box>
            {/* General Health Information end */}

            <Divider />
            
            {/* Personal Medical History start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Personal Medical History</Text>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' flexDir='column' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Do you have any chronic conditions? (e.g., Diabetes, Hypertension, Heart Disease)</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'medicalconditions')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={conditionradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails?.patientmedicalcondition != null&&
                    <Box
                    className="buttonContainer"
                    py="10px"
                    mt="0.5rem"
                    w="100%"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    display="flex"
                    gap="0.75rem"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                     {GetSectionDetails &&
                    GetSectionDetails.patientmedicalcondition != null &&
                    <Box title='Add new medical condition' cursor='pointer' onClick={()=>openAddConditionModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails?.patientmedicalcondition?.map((category, categoryIndex) => (
                        <React.Fragment key={category.conditiontype_cd}>
                          {category.contiondata?.map((condition, conditionIndex) => (
                            <ReportViewCard
                              key={`${categoryIndex}-${conditionIndex}`}
                              cardHeight="auto"
                              minCardWidth="300px"
                              maxCardWidth="300px"
                              cardHeaderStyle={{maxW:'170px'}}
                              cardHeader={condition.condition} // Displaying condition from contiondata
                              cardSecondary={
                                <Text fontWeight="bold">
                                  {"Date: "}
                                  <span style={{ fontWeight: "500" }}>
                                    {condition.conditionidentifieddate? formatDate_DD_MM_YYYY(condition.conditionidentifieddate):"-"}
                                  </span>
                                </Text>
                              }
                              cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                              cardBtn={false}
                              cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                              deleteBtn={false}
                              editDeleteBtn={() => openDeleteModalConditions(condition.id, condition.condition)}
                              editBtn={() => openEditModalCondition(
                                condition.condition, 
                                condition.condition_cd, 
                                condition.conditionidentifieddate, 
                                condition.id, 
                                category.conditiontype, 
                                category.conditiontype_cd
                              )}
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </Box>
                  }

                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Any history of surgeries?</Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'surgeries')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={surgeryradioValue}>
                            <Radio value="yes"><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value="no"><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientsurgeries != null &&
                    <Box
                    className="buttonContainer"
                    py="10px"
                    mt="0.5rem"
                    w="100%"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    display="flex"
                    gap="0.75rem"
                    ref={scrollRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                     {GetSectionDetails &&
                    GetSectionDetails.patientsurgeries != null &&
                    <Box title='Add new surgery' cursor='pointer' onClick={()=>openSurgeriesModal()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientsurgeries?.map((step, i) => (
                          <ReportViewCard
                            key={i}
                            //cardStyle={{ h: "205px" }}
                            cardHeight='auto'
                            minCardWidth="300px"
                            maxCardWidth="300px"
                            cardHeaderStyle={{maxW:'170px'}}
                            cardHeader={step.proceduretitle}
                            cardSecondary={
                              <Text fontWeight="bold">
                                {"Date: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.proceduredate?formatDate_DD_MM_YYYY(step.proceduredate):"-"}
                                </span>
                              </Text>
                            }
                            cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                            cardBtn={false}
                            cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                            deleteBtn={false}
                            editDeleteBtn={() => openDeleteModalSurgery(step.id, step.proceduretitle)}
                            editBtn={() =>
                              openEditModalSurgery(
                                step.id,
                                step.surgeonname,
                                step.hospitalname,
                                step.proceduredate,
                                step.proceduretitle,
                              )}
                          />
                        ))}
                    </Box>
                  }
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Current Medications </Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'medication')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={medicationradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientmedicine != null &&
                    <Box
                      className="buttonContainer"
                      py="10px"
                      mt="0.5rem"
                      w="100%"
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      display="flex"
                      gap="0.75rem"
                      ref={scrollRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                       {GetSectionDetails &&
                    GetSectionDetails.patientmedicine != null &&
                    <Box title='Add new medicine' cursor='pointer' onClick={()=>addData()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientmedicine?.map((step, i) => (
                          <ReportViewCard
                            key={i}
                            //cardStyle={{ h: "205px" }}
                            cardHeight='auto'
                            minCardWidth="300px"
                            maxCardWidth="300px"
                            cardHeaderStyle={{maxW:'170px'}}
                            cardHeader={step.medicinename}
                            cardSecondary={
                              <Text fontWeight="bold">
                                {"Type: "}
                                <span style={{ fontWeight: "500" }}>
                                  {step.medicinetype}
                                </span>
                              </Text>
                            }
                            cardTextStyle={{ mt: "3.5px", fontSize: "12px" }}
                            cardBtn={false}
                            cardBtnStyle={{ flexWrap: "wrap", whiteSpace: "wrap" }}
                            deleteBtn={false}
                            editDeleteBtn={() =>
                              DeleteMedicineModel(
                                Step.medicinename,
                                step.id
                              )
                            }
                            editBtn={() => handleeditmedication(step)}
                          />
                        ))}
                        </Box>
                        }
                </Box>
                <Divider mt='0.75rem'/>
                <Box display='flex' flexDirection='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Family History of Chronic Illnesses  </Text>
                        <RadioGroup 
                        onChange={(value) => handleChange(value, 'family')} 
                        colorScheme='green' display='flex' gap='1rem'
                        value={familyradioValue}>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                    {GetSectionDetails &&
                        GetSectionDetails.patientfamily != null &&
                    <Box
                      className="buttonContainer"
                      py="10px"
                      mt="0.5rem"
                      w="100%"
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      display="flex"
                      gap="0.75rem"
                      ref={scrollRef}
                      onMouseDown={handleMouseDown}
                      onMouseLeave={handleMouseLeave}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                      >
                         {GetSectionDetails &&
                    GetSectionDetails.patientfamily != null &&
                    <Box title='Add new family member' cursor='pointer' onClick={()=>addfamilymodel()} h='auto' display='flex' justifyContent='center' alignItems='center'>
                        <Flex  alignItems='center' justifyContent='center' boxSize='50px' border='1px dashed #E6E7E9' borderRadius='10px'>
                          <Image src='/assets/svgs/add.svg' />
                        </Flex>
                    </Box>
                    }
                      {GetSectionDetails &&
                        GetSectionDetails.patientfamily?.map((step, i) => (
                         
                          <Box
                            borderRadius="12px"
                            minH={"100px"}
                            // maxH={"150px"}
                            minW={"300px"}
                            w="100%"
                            maxW={"300px"}
                            border="1px solid #E6E7E9"
                            boxShadow="0px 8px 20px 0px #E6E7E966"
                            p='0.75rem'
                            display='flex'
                            flexDir='column'
                            // gap='0.5rem'
                          >
                            <Flex w='100%' alignItems='center' justifyContent='space-between'>
                              <Text fontSize="15px" fontWeight="600" isTruncated title={step.fullname}>
                                {step.fullname}
                              </Text>

                               <Box display="flex" gap="10px">
                                <Box
                                  as="button"
                                  onClick={() =>handleFamilyEdit(step)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/edit-gray.png" />
                                </Box>
                                <Box
                                  as="button"
                                  onClick={()=> handleFamilyDelete(step.familyid,step.fullname)}
                                  border="1px solid #E6E7E9"
                                  borderRadius="full"
                                  w="36px"
                                  h="auto"
                                  p="10px"
                                >
                                  <Image w="13px" src="/assets/imgs/delete.png" />
                                </Box>
                              </Box>
                            </Flex>
                            <Box display='flex' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Relation: 
                                <span style={{ fontWeight: "500" }}>
                                  {step.relation}
                                </span>
                              </Text>
                            </Box>
                            <Box display='flex' flexDir='column'>
                              <Text fontWeight='600' fontSize='0.75rem'>
                                Conditions:
                              </Text>
                              <Flex flexWrap='wrap'>
                                {step?.medicalcondition?.map((condition, index) => (
                                  <Box as='span' lineHeight='normal' fontWeight='500' display='inline-flex' fontSize='0.65rem' key={index} title={step?.medicalcondition?.map(condition => condition.condition).join(", ")}>
                                    {condition.condition}
                                    {index < step.medicalcondition.length - 1 && ", "}
                                  </Box>
                                ))}
                              </Flex>
                            </Box>
                          </Box>
                        ))}
                        </Box>
                  }
                </Box>
               

            </Box>
            {/* Personal Medical History end */}
            
            <Divider />
            
            {/* Body Measurements start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Body Measurements (BMI Calculation)</Text>
                <RadioGroup 
                  onChange={switchBMICalculationForSaveState}
                  value={saveStates.type ? saveStates.type : "standard"}
                  w="100%"
                  bg="#FFFFFF"
                  // border="1px solid #e2e8f0"
                  pl="10px"
                  >
                      <Box display="flex" justifyContent="space-between" gap="1.5rem" py="10px" w="100%">
                        <Radio value="standard" colorScheme="green">
                          Standard
                        </Radio>
                      
                        <Radio value="metric" colorScheme="green">
                          Metric
                        </Radio>

                      </Box>
                    </RadioGroup>
                <Flex flexDir='column' gap='0.75rem'>
                    <Box display='flex' alignItems='center' gap='0.75rem' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem' w='40%'>Height (in  {vitalunitheightONPAGE})</Text>
                        {/* <PrimaryInput variant='fullRound' inputPlace='cm' inputStyleCss={{w:'25%'}}/> */}
                       
                    {!showInchesInputONPAGE && 
                
                    <DropdownInputForVitals
                      variant="fullSide"
                      inputStyleCss={{w: "75%"}}
                      dropDownPlace="Enter value"
                      options={heightOptionsInBMI}
                      onClick={setHeightValueInBMIDropDownONPAGE}
                      onChange={handlebmivitalvaluechangeONPAGE}
                      menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                      readOnly=""
                      id="height"
                      className="StandardMetric"
                      // defaultOption={gender == "2" ? "Male" : gender == "1" ? "Female" : gender == "3" ? "Other" : ""}
                      inputValueProp={saveStates.height}
                    />
                    }
                    
                    {showInchesInputONPAGE && 
                    <>
                    <Box w="75%" display="flex">
                        <DropdownInputForVitals
                          variant="fullLeft"
                          inputStyleCss={{w: "50%"}}
                          dropDownPlace="Feet"
                          options={feetOptions}
                          onClick={setFeetValueInDropDownONPAGE}
                          onChange={handleBMIFeetValueONPAGE}
                          readOnly=""
                          id="height"
                          className="StandardMetric"
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          inputValueProp={saveStates.feetValue}
                          maxLength={1}
                        />

                  
                        <DropdownInputForVitals
                          variant="fullRight"
                          inputStyleCss={{w: "50%"}}
                          dropDownPlace="Inches"
                          options={inchesOptions}
                          onClick={setInchValueInDropDownONPAGE}
                          onChange={handleBMIinchValueONPAGE}
                          readOnly=""
                          id="height"
                          className="StandardMetric"
                          menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                          inputValueProp={saveStates.inchValue}
                          maxLength={2}
                        />
                      </Box>
                    </>
                    }
                  </Box>

                        
                  
                    <Box display='flex' justifyContent='space-between' w='100%'>
                        <Box w='40%'>
                            <Text fontSize='0.875rem'>Weight (in  {vitalunitweightONPAGE})</Text>
                            {/* <Text fontSize='0.75rem' fontWeight='500'>(Calculate BMI = Weight in kg / (Height in meters)^2)</Text> */}
                        </Box>
                        
                      <DropdownInputForVitals
                      
                          variant="fullSide"
                          dropDownPlace="Enter Value"
                          options={weightOptionsInBMI}
                          onClick={setWeightvalueInBMIDropdownONPAGE}
                          onChange={handlebmivitalvaluechangeONPAGE}
                          menuList={{pos: "absolute", right: "-45px", minW: "115px", zIndex:"999"}}
                          readOnly=""
                          id="weight"
                          inputStyleCss={{w: "37%"}}
                          className="StandardMetric"
                          
                          // defaultOption={saveStates.weight}
                            inputValueProp={saveStates.weight}
                            
                        />
                        {/* <PrimaryInput variant='fullRound' inputPlace='kg' inputStyleCss={{w:'25%'}}/> */}
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem' fontWeight='600'>BMI (calculated):</Text>
                        <Text fontSize='0.875rem' fontWeight='600'>{calculatedBMI || 0}</Text>
                    </Box>
                    <Box display='flex' alignItems='start' flexDir='column' w='100%'>
                        <Text fontSize='0.875rem' fontWeight='600'>BMI Categories:</Text>
                        <Box
                            width="100%"
                            borderRadius="50px"
                            display="flex"
                            position="relative"
                            mt="20px"
                            zIndex="9"
                           >
                            {/* Sections */}
                            {bmiCategories.map(({ label, range, color, left, radius }, index) => (
                                <Box
                                key={index}
                                position="absolute"
                                top="0"
                                left={left}
                                width="25%"
                                height="60%"
                                bg={color}
                                borderRadius={radius || "none"}
                                >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontSize="12px"
                                    h="100%"
                                    color={label == 'Overweight' ? 'black' : 'white'}
                                    pos='relative'
                                    textAlign='center'
                                >
                                    {label}
                                    <Text pos='absolute' top='-20px' color='black' whiteSpace='nowrap' fontWeight='500'>{range}</Text>
                                </Box>
                                </Box>
                            ))}

                            {/* Pointer */}
                            <Box
                                position="absolute"
                                top="30px"
                                left={`${pointerPos}%`}
                                //left={'33%'}
                                width="0"
                                height="0"
                                borderLeft="6px solid transparent"
                                borderRight="6px solid transparent"
                                borderBottom="12px solid black"
                            />

                            {/* Slider */}
                            <Slider
                                mt={10}
                                defaultValue={50}
                                min={0}
                                max={100}
                                step={1}
                                // onChange={(val) => setMeter(val)}
                            />
                            </Box>
                    </Box>
                </Flex>

            </Box>
            {/* Body Measurements end */}

            <Divider />
            
            {/* Symptom Screening start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Have you experienced any of the following symptoms recently?</Text>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Fatigue?</Text>
                        <RadioGroup value={saveStates.Fatigue} onChange={(value)=>handleChangeRadio("Fatigue" ,value )} colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Dizziness:</Text>
                        <RadioGroup  value={saveStates.Dizziness} onChange={(value)=>handleChangeRadio("Dizziness" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Chest Pain:</Text>
                        <RadioGroup  value={saveStates.ChestPain} onChange={(value)=>handleChangeRadio("ChestPain" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Shortness of Breath:</Text>
                        <RadioGroup  value={saveStates.ShortnessofBreath} onChange={(value)=>handleChangeRadio("ShortnessofBreath" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Persistent Cough:</Text>
                        <RadioGroup  value={saveStates.PersistentCough} onChange={(value)=>handleChangeRadio("PersistentCough" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
                <Divider mt='0.75rem'/>
                <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                        <Text fontSize='0.875rem'>Unexplained Weight Loss:</Text>
                        <RadioGroup  value={saveStates.UnexplainedWeightLoss} onChange={(value)=>handleChangeRadio("UnexplainedWeightLoss" ,value )}  colorScheme='green' display='flex' gap='1rem'>
                            <Radio value='yes'><Text fontSize='0.875rem'>Yes</Text></Radio>
                            <Radio value='no'><Text fontSize='0.875rem'>No</Text></Radio>
                        </RadioGroup>
                    </Flex>
                </Box>
            </Box>
            {/* Symptom Screening end */}
            
            <Divider />
            
            {/* Preventive Health Screening start */}
            <Box>
                <Text fontSize='1rem' fontWeight='600'>Preventive Health Screening</Text>
                <Flex flexDir='column' gap='0.75rem'>
                    <Box display='flex' alignItems='start' flexDir='column' w='100%'>
                        <Text fontSize='0.875rem'>Last Blood Pressure Check:</Text>
                        <Box display='flex' mt='0.75rem' w='100%'>
                        <PrimaryInput inputValue={saveStates.LastBloodPressureCheckSystolic} onChange={(value)=>handleChangeInput("LastBloodPressureCheckSystolic" ,value )} variant='fullLeft' inputPlace='Systolic' inputStyleCss={{w:'50%'}}/>

                        <PrimaryInput inputValue={saveStates.LastBloodPressureCheckDistolic} onChange={(value)=>handleChangeInput("LastBloodPressureCheckDistolic" ,value )} variant='fullRight' inputPlace='Diastolic' inputStyleCss={{w:'50%'}}/>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem'>Last Blood Sugar Check:</Text>
                        <PrimaryInput  inputValue={saveStates.LastBloodSugarCheck} onChange={(value)=>handleChangeInput("LastBloodSugarCheck" ,value )} variant='fullRound' inputPlace='Value' inputStyleCss={{w:'25%'}}/>
                    </Box>
                    <Divider/>
                    <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                        <Text fontSize='0.875rem'>Cholesterol Levels (if known):</Text>
                       <PrimaryInput inputValue={saveStates.CholesterolLevels} onChange={(value)=>handleChangeInput("CholesterolLevels" ,value )} variant='fullRound' inputPlace='Value' inputStyleCss={{w:'25%'}}/>
                   
                    </Box>
                    <Divider/>
                    {patientData.gender === 2 ?(
                      <Box w='100%' display='flex' flexDir='column'>
                    <Flex gap='1rem' mt='0.875rem' flexDir='column'>
                            <Text fontSize='0.875rem'>Prostate Cancer Screening (for men):</Text>
                            <RadioGroup   value={saveStates.ProstateCancerScreening} onChange={(value)=>handleChangeRadio("ProstateCancerScreening" ,value )}   colorScheme='green' display='flex' gap='1rem'>
                                <Radio value='done'><Text fontSize='0.875rem'>Done</Text></Radio>
                                <Radio value='not done'><Text fontSize='0.875rem'>Not Done</Text></Radio>
                            </RadioGroup>
                        </Flex>
                    </Box>
                    ):(
                      <Box w='100%' display='flex' flexDir='column'>
                      <Flex gap='1rem' mt='0.875rem' justifyContent='space-between'>
                          <Text fontSize='0.875rem'>Cervix and Brest Cancer Screening (for women):</Text>
                          <RadioGroup   value={saveStates.CervixandBreastCancerScreening} onChange={(value)=>handleChangeRadio("CervixandBreastCancerScreening" ,value )}   colorScheme='green' display='flex' gap='1rem'>
                              <Radio value='done'><Text fontSize='0.875rem'>Done</Text></Radio>
                              <Radio value='not done'><Text fontSize='0.875rem'>Not Done</Text></Radio>
                          </RadioGroup>
                      </Flex>
                   </Box>
                    )}
                    
                </Flex>

            </Box>
            {/* Preventive Health Screening end */}
        </Flex>
        <Box pos='fixed' w='100%' bottom='65px' zIndex='20' left='1px' p='1rem' bg='white' boxShadow='rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'>
          <PrimaryButton buttonText='Save' onClick={saveSectiontwo} />
        </Box>
        </>
      )}

       {/* Social History */}
        <ModalRight
          ref={addsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Add Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide" dropDownPlace="Type*"
                  options={typedata}
                  defaultOption={defaultSocialType}
                  onClick={handleTypeInputChange} 
                  nonClickable={true}/>
                {smokingSelected &&
                  <RadioGroup onChange={handleYesSelected} value={radioValue}>
                    <Box display="flex" justifyContent="space-between" py="20px" >
                      <Radio value="yes" colorScheme="green" >
                        Current
                      </Radio>

                      <Radio value="currentlyNot" colorScheme="green">
                        Former
                      </Radio>

                      <Radio value="never" colorScheme="green">
                        Never
                      </Radio>
                    </Box>
                  </RadioGroup>
                }

                {
                  showDateSelection &&
                  <>
                    {
                      smokeLessSelected &&
                      <>
                        <Divider />
                        <RadioGroup
                          onChange={handleSmokelessType}
                        >
                          <Box display="flex" justifyContent="start" gap="15px" py="20px">
                            <Radio value="Chew" colorScheme="green">
                              Chew
                            </Radio>

                            <Radio value="Snuff" colorScheme="green">
                              Snuff
                            </Radio>

                          </Box>
                        </RadioGroup>
                      </>
                    }
                    {
                      drugsSelected &&
                      <>
                        <Divider />
                        <RadioGroup
                          onChange={handleDrugTypeType}
                        >
                          <Box display="flex" justifyContent="space-around" py="20px">
                            <Radio value="Smoke" colorScheme="green">
                              Smoke
                            </Radio>

                            <Radio value="Snort" colorScheme="green">
                              Snort
                            </Radio>
                            <Radio value="ivDrug" colorScheme="green">
                              IV Drug use
                            </Radio>

                          </Box>
                        </RadioGroup>
                      </>
                    }
                    {
                      drugsSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={reasonsArraysocial.length === 0 ? "space-between" : "start"}
                        >
                          {reasonsArraysocial.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArraysocial.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...reasonsArraysocial.length === 0 ? {} : floatLabel }} >Drugs<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArraysocial.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArraysocial

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {
                      physicalactivitySelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openActivityListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={activityArray.length === 0 ? "space-between" : "start"}
                        >
                          {activityArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          ) : activityArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...activityArray.length === 0 ? {} : floatLabel }} >Type<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          )}
                          {activityArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {activityArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeActivityTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    <Box display="flex" flexDir="row">
                      <DropdownInputForVitals
                        variant="defaultSide" 
                        dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        }
                        onChange={(e)=>handleSocialStartYearChange(e.target.value)} 
                        inputValueProp={fromdateYear}
                        menuList={{pos: "absolute", right: "-45px", minW: "115px", zIndex:"999"}}
                        readOnly=""
                        id="startyear"
                        />
                      {!showIsCurentCheckBox &&
                        <DropdownInputForVitals
                          variant="defaultSide" dropDownPlace="End year*"
                          options={toYearOptions}
                          onClick={(selectedDateValue) =>
                            handleToYearChange(selectedDateValue)
                          }
                          onChange={(e)=>handleSocialEndYearChange(e.target.value)} 
                          inputValueProp={toDateYear}
                          menuList={{pos: "absolute", right: "-45px", minW: "115px", zIndex:"999"}}
                          readOnly=""
                          id="endyear" />
                      }

                    </Box>

                    {showYearsDiffTag &&
                      <Box my="2px" >
                        <p>
                          Consuming Years : {yearsDiff}</p>

                      </Box>
                    }

                    {!hideFrequency &&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" inputPlace={`${text} per week*`}
                          onChange={handleFrequency} inputValue={frequency}
                        />
                      </Box>
                    }
                    {!hideDuration&&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" inputPlace={`Hours per day*`}
                          onChange={handleDuration} inputValue={duration}
                        />
                        {/* <DropdownInput
                          variant="rightDown" dropDownPlace="Time Span" options={["Week"]}
                          defaultOption={"Week"}
                          onClick={handlefrequencyInputChange}
                        /> */}
                      </Box>
                    }
                    {showFrequencyCalculation &&
                      <Box my="2px" >

                        <p>{calculatedConsumption} </p>
                      </Box>
                    }

                    {showQuitCheckBox &&

                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 2}
                          isDisabled={true}
                        >
                          I Quit
                        </Checkbox>

                      </Box>
                    }

                    {showIsCurentCheckBox &&
                      <Box display="flex" justifyContent="start" py="20px">
                        <Checkbox
                          colorScheme="green"
                          sx={{
                            ".chakra-checkbox__control": {
                              width: "20px",
                              height: "20px",
                              borderRadius: "5px",
                            },
                          }}
                          defaultChecked={current === 1}
                          isDisabled={true}
                        >
                          Current
                        </Checkbox>
                      </Box>

                    }

                  </>
                }
                <Divider />
                </Box>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Add Social History"
                onClick={handleaddsocialhistory}
                isDisabled={false}
              />
            </Box>
          </>}
        />
        <ModalRight
        ref={opendrugList}
        handlebackarrow={() => opendrugList.current.closeRight()}
        withBackBtn={true}
        withBackHeader="Drugs"
        body={
          <>
            <SearchBar
              passInputPlace="Search Drugs"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {drugList && drugList.length > 0 ? (
                        drugList
                          .flatMap((category) => category)
                          .filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          ).length === 0 ? (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching drugs found!
                          </Box>
                        ) : (
                            drugList
                            .flatMap((category) => category)
                            .filter((condition) =>
                              condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  p="15px"
                                  height="50px"
                                  width="100%"
                                  sx={{
                                    _hover: {
                                      border: "2px solid #384052",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Box key={condition.condition_cd} display="flex" alignItems="center">
                                    <Text fontSize="16px" fontWeight="600">
                                      {condition.condition}
                                      <br />
                                      <Box
                                        as="span"
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      ></Box>
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() => handleSocialReactionCheckboxChange(condition)}
                                      isChecked={reasonsArraysocial.some(
                                        (item) => item.condition === condition.condition
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                    </Stack>

                  </Box>



                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Drugs"
                isDisabled={socialreactionsContinueBtnDisabled}
                onClick={() => closeDrugsModal()}
              />
            </Box>
          </>
        }
      />
       <DynamicModal
          ref={dynamicModal}
          modalHeader="Delete Social History?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete. <br />
                {socialtitle ? socialtitle : "-"}
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletesocialhistory}
              />
            </>
          }
        />
        <ModalRight
          ref={editsocialhistory}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Edit Social History"
          body={
            <>
              <Box display="flex" flexDir="column">
                <DropdownInput
                  variant="defaultSide" dropDownPlace="Type*"
                  options={typedata}
                  defaultOption={type}
                  onClick={handleTypeInputChange}

                  nonClickable={true} />
                {/* {smokingSelected && */}
                <RadioGroup onChange={handleYesSelected} value={current == "1" ? "yes" : current == "0" ? "never" : "currentlyNot"}>
                  <Box display="flex" justifyContent="space-between" py="20px" >
                    <Radio value="yes" colorScheme="green" >
                      Current
                    </Radio>

                    <Radio value="currentlyNot" colorScheme="green">
                      Former
                    </Radio>

                    <Radio value="never" colorScheme="green">
                      Never
                    </Radio>
                  </Box>
                </RadioGroup>
                {
                  smokeLessSelected &&
                  <>
                    <Divider />
                    <RadioGroup
                      onChange={handleSmokelessType}
                      value={smokelessType == "snuff" ? "Snuff" : smokelessType == "chew" ? "Chew" : ""}
                    >
                      <Box display="flex" justifyContent="start" gap="15px" py="20px">
                        <Radio value="Chew" colorScheme="green">
                          Chew
                        </Radio>

                        <Radio value="Snuff" colorScheme="green">
                          Snuff
                        </Radio>

                      </Box>
                    </RadioGroup>
                  </>
                }
                {
                  drugsSelected &&
                  <>
                    <Divider />
                    <RadioGroup
                      onChange={handleDrugTypeType}
                      value={drugType == "smoke" ? "Smoke" : drugType == "snort" ? "Snort" : drugType == "ivDrug" ? "ivDrug" : ""}

                    >
                      <Box display="flex" justifyContent="space-around" py="20px">
                        <Radio value="Smoke" colorScheme="green">
                          Smoke
                        </Radio>

                        <Radio value="Snort" colorScheme="green">
                          Snort
                        </Radio>
                        <Radio value="ivDrug" colorScheme="green">
                          IV Drug use
                        </Radio>

                      </Box>
                    </RadioGroup>
                  </>
                }
{
                  physicalactivitySelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        // onClick={() => showReactionList(condition.condition_cd)}
                        onClick={openActivityListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={activityArray.length === 0 ? "space-between" : "start"}
                        >
                          {activityArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          ) : activityArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...activityArray.length === 0 ? {} : floatLabel }} >Type<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Type<sup>*</sup>
                            </Text>
                          )}
                          {activityArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {activityArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeActivityTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                {!neverSelected &&
                  <>
                    {
                      drugsSelected &&
                      <Box
                        as="div"
                        role="button"
                        aria-haspopup="dialog"
                        display="flex"
                        alignItems="center"
                        tabIndex={0}
                        border="1px solid #e2e8f0"
                        borderRadius="10px 10px 0 0"
                        p="10px"
                        height="auto"
                        width="100%"
                        sx={{
                          _hover: { border: "2px solid #384052" },
                        }}
                        onClick={openDrugListModal}
                        minHeight="48px"
                        flexWrap="wrap"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          gap="5px"
                          w="100%"
                          justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}
                        >
                          {reasonsArray.length === 0 ? (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          ) : reasonsArray.some(
                            (item) => item.condition_cd === item.condition_cd
                          ) ? (
                            <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Drugs<sup>*</sup></Text>
                          ) : (
                            <Text pl="2" color="gray.400">
                              Drugs<sup>*</sup>
                            </Text>
                          )}
                          {reasonsArray.length === 0 && (
                            <Image src="/assets/imgs/right-icon.png" />
                          )}
                          {reasonsArray

                            .map((tag, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="5px"
                                bg="gray.100"
                                borderRadius="full"
                                p="1"
                              >
                                <Text fontSize="sm" px="5px">
                                  {tag.condition}
                                </Text>
                                <Box
                                  as="button"
                                  p="5px"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeTag(index);
                                  }}
                                  aria-label="Remove condition"
                                >
                                  <Image src="/assets/imgs/close.png" />
                                </Box>
                              </Box>
                            ))}

                        </Box>
                      </Box>
                    }
                    {/* <Box display="flex" flexDir="row">
                  <PrimaryInput variant="bothSide" defaultValue={duration} inputStyleCss={{ w: "60%" }} inputType="text" inputPlace="Duration*"
                    onChange={handleDuration} inputValue={duration} />
                  <DropdownInput
                    variant="bothSide" defaultOption={durationtimespan} dropDownPlace="Time Span" inputStyleCss={{ w: "40%" }} options={["Hour", "Day", "Week", "Month", "Year"]}
                    onClick={handledurationInputChange} />
                </Box> */}


                    <Box display="flex" flexDir="row">
                    <DropdownInputForVitals
                        variant="defaultSide" 
                        dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        }
                        onChange={(e)=>handleSocialStartYearChange(e.target.value)} 
                        inputValueProp={fromdateYear}
                        menuList={{pos: "absolute", right: "-45px", minW: "115px", zIndex:"999"}}
                        readOnly=""
                        id="startyear"
                        />
                      {/* <DropdownInput

                        defaultOption={fromdateYear}
                        variant="defaultSide" dropDownPlace="Start year*"
                        options={fromYearOptions}
                        onClick={(selectedDateValue) =>
                          handleFromYearChange(selectedDateValue)
                        } /> */}

                      {!showIsCurentCheckBox &&
                       <DropdownInputForVitals
                       variant="defaultSide" dropDownPlace="End year*"
                       options={toYearOptions}
                       onClick={(selectedDateValue) =>
                         handleToYearChange(selectedDateValue)
                       }
                       onChange={(e)=>handleSocialEndYearChange(e.target.value)} 
                       inputValueProp={toDateYear}
                       menuList={{pos: "absolute", right: "-45px", minW: "115px", zIndex:"999"}}
                       readOnly=""
                       id="endyear" />
                        // <DropdownInput

                        //   defaultOption={toDateYear}
                        //   variant="defaultSide" dropDownPlace="End year*"
                        //   options={toYearOptions}
                        //   onClick={(selectedDateValue) =>
                        //     handleToYearChange(selectedDateValue)
                        //   } />
                      }
                    </Box>
                    {/*  not showing Consumption years in Edit as well , so commented below code -
                    <Box my="2px" >

                      <p>{calculatedYearsForEdit} </p>
                    </Box> */}
                    {showFrequencyInEditModal &&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" defaultValue={frequency} inputPlace={`${text} per week*`}
                          onChange={handleFrequency} inputValue={frequency} />
                        {/* <DropdownInput
                          variant="rightDown" defaultOption={frequencytimespan} dropDownPlace="Time Span" inputStyleCss={{ w: "40%" }} options={["Week"]}
                          onClick={handlefrequencyInputChange} /> */}
                      </Box>
                    }
                    {!hideDuration&&
                      <Box display="flex" flexDir="row">
                        <PrimaryInput variant="leftDown" inputPlace={`Hours per day*`}
                          onChange={handleDuration} inputValue={duration}
                        />
                        {/* <DropdownInput
                          variant="rightDown" dropDownPlace="Time Span" options={["Week"]}
                          defaultOption={"Week"}
                          onClick={handlefrequencyInputChange}
                        /> */}
                      </Box>
                    }
                    {showFrequencyCalculation &&
                      <Box my="2px" >

                        <p>{calculatedConsumption} </p>
                      </Box>
                    }
                  </>
                }

                {showQuitCheckBox &&
                  <Box display="flex" justifyContent="start" py="20px">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      defaultChecked={current === 2}
                      isDisabled={true}
                      onChange={handleCheckboxChangeQuit}>
                      I Quit
                    </Checkbox>
                  </Box>
                }

                {showIsCurentCheckBox &&
                  <Box display="flex" justifyContent="start" py="20px">
                    <Checkbox
                      colorScheme="green"
                      sx={{
                        ".chakra-checkbox__control": {
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        },
                      }}
                      defaultChecked={current === 1}
                      isDisabled={true}

                      onChange={handleCheckboxChange}>
                      Current
                    </Checkbox>
                  </Box>

                }

                <Divider />
                {/* <Box as="button" display="flex" alignItems="center" gap="10px" py="20px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                    <Image w="11px" src="/assets/imgs/add-blue.png"/> Add social history</Box> */}
              </Box>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Update Social History"
                onClick={Editsocialhistory}
                isDisabled={false}
              />
            </Box>
          </>}
        />
         <ModalRight
        ref={addphysicalactivitytype}
        handlebackarrow={() => addphysicalactivitytype.current.closeRight()}
        withBackBtn={true}
        withBackHeader="Activities"
        body={
          <>
            <SearchBar
              passInputPlace="Search Activity"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {ActivityTypeList && ActivityTypeList.length > 0 ? (
                        ActivityTypeList
                          .flatMap((category) => category)
                          .filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          ).length === 0 ? (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching activity found!
                          </Box>
                        ) : (
                          ActivityTypeList
                            .flatMap((category) => category)
                            .filter((condition) =>
                              condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="1px solid #e2e8f0"
                                  p="15px"
                                  height="50px"
                                  width="100%"
                                  sx={{
                                    _hover: {
                                      border: "2px solid #384052",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  <Box key={condition.condition_cd} display="flex" alignItems="center">
                                    <Text fontSize="16px" fontWeight="600">
                                      {condition.condition}
                                      <br />
                                      <Box
                                        as="span"
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#6A707D"
                                      ></Box>
                                    </Text>
                                  </Box>
                                  <Box display="flex" justifyContent="end">
                                    <Checkbox
                                      colorScheme="green"
                                      sx={{
                                        ".chakra-checkbox__control": {
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "5px",
                                        },
                                      }}
                                      onChange={() => handleActivityCheckboxChange(condition)}
                                      isChecked={activityArray.some(
                                        (item) => item.condition === condition.condition
                                      )}
                                    ></Checkbox>
                                  </Box>
                                </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                    </Stack>

                  </Box>



                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Activity"
                isDisabled={activityContinueBtnDisabled}
                onClick={() => addphysicalactivitytype.current.closeRight()}
              />
            </Box>
          </>
        }
      />
        {/* Allergies */}
        <ModalRight
          ref={addCondition}
          header="Add allergy"
          body={
            <>
              <SearchBar
                passInputPlace="Search Allergy"
                onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
                searchValue={searchValue}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                    <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {conditionsList && conditionsList.length > 0 ? (
                        conditionsList.some(category =>
                          category.data.some(condition =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          )
                        ) ? (
                          conditionsList.map(category => (
                            <React.Fragment key={category.category_id}>
                              {category.data
                                .filter(condition =>
                                  condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                                )
                                .map(condition => (
                                  <label key={condition.condition_cd}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      borderBottom="1px solid #e2e8f0"
                                      p="15px"
                                      height="50px"
                                      width="100%"
                                      sx={{
                                        _hover: {
                                          border: "2px solid #384052",
                                          borderRadius: "10px",
                                        },
                                      }}
                                    >
                                      <Box
                                        key={condition.condition_cd}
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text fontSize="16px" fontWeight="600">
                                          {condition.condition}
                                          <br />
                                          <Box
                                            as="span"
                                            fontSize="12px"
                                            fontWeight="500"
                                            color="#6A707D"
                                          ></Box>
                                        </Text>
                                      </Box>
                                      <Box display="flex" justifyContent="end">
                                        <Checkbox
                                          colorScheme="green"
                                          sx={{
                                            ".chakra-checkbox__control": {
                                              width: "20px",
                                              height: "20px",
                                              borderRadius: "5px",
                                            },
                                          }}
                                                          onChange={() =>
                                                            handleCheckboxChange(condition)
                                                          }
                                          isChecked={selectedConditions.some(
                                                            (item) =>
                                                              item.condition_cd ===
                                                              condition.condition_cd
                                          )}
                                        ></Checkbox>
                                      </Box>
                                    </Box>


                                  </label>
                                ))}
                            </React.Fragment>
                          ))
                        ) : (
                          <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching allergies found!
                          </Box>
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                      <Divider/>
                            <br/>
                            <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                    Other Allergy
                                    </Heading>
                          <PrimaryInput
                          inputPlace="Allergy"
                          variant="bothSide"
                                  onChange={handleCondition} inputValue={typedCondition}
                                  />
                                  <br/>
                    </Stack>
                  </Box>
                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={addCondition3}
                handlebackarrow={() => addCondition3.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChange(
                                                  condition, 
                                                  allergyID
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.allergy_id === allergyID &&
                                                  item.condition_cd ===
                                                  condition.condition_cd
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                               <Divider/>
                                <br/>
                                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                         Other Reactions
                                        </Heading>
                              <PrimaryInput
                               inputPlace="Reactions"
                               variant="bothSide"
                                      onChange={handleReaction} inputValue={typedReaction}
                               />
                               <br/>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add reactions"
                        isDisabled={reactionsContinueBtnDisabled2}
                        onClick={() => AddReactionsInInput(allergyID)}
                      />
                    </Box>
                  </>
                }
              />

              <ModalRight
                ref={addCondition2}
                handlebackarrow={() => addCondition2.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Add allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions?.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            onDateChange={(selectedDateValue) => handleDateChange(condition.condition_cd, selectedDateValue)}
                            placeholderProp="Date*" />
                            <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                        <DropdownInputForVitals
                          variant="defaultSide"
                          dropDownPlace="Year*"
                          options={socialYearOptions}
                          onClick={(selectedDateValue) =>
                            handleAllergyYearChange(condition.condition_cd, selectedDateValue)
                          }
                          onChange={(e) => handleAllergyYearChange(condition.condition_cd, e.target.value)}
                          inputValueProp={socialDateYear[condition.condition_cd] || ""}
                          menuList={{ pos: "absolute", right: "-45px", minW: "115px", zIndex: "999" }}
                          readOnly=""
                          id="startyear"
                        />
                        
                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() => showReactionList(condition.condition_cd)}
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                key={condition.condition_cd}
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}>
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                  (item) => item.allergy_id === condition.condition_cd
                                ) ? (
                                  <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Reaction*</Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) => tag.allergy_id == condition.condition_cd
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      wordBreak="break-all"
                                      p="1"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.condition}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // removeTag(index);
                                          removeReaction(condition.condition_cd, tag.condition_cd);
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                          {/* {documentURLs?.map((document, index) => {
                              if (document.condition_cd == condition.condition_cd || (document.condition_cd == null && condition.condition_cd == null)) {
                            if (!indexMap[document.condition_cd]) {
                                indexMap[document.condition_cd] = 1;
                              }
                              const docIndex = indexMap[document.condition_cd];
                              indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                              return (
                                <Box key={index} mb="2" mt="2">
                                  <Button
                                    as="a"
                                    rel="noopener noreferrer"
                                    w="100%"
                                    h="48px"
                                    borderRadius="10px"
                                    border="1px solid #E6E7E9"
                                    display="flex"
                                    justifyContent="space-between"
                                    bg="white"
                                    color="#1C4EC5"
                                    sx={{ _hover: { bg: "none" } }}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Document {docIndex}
                                    <Box as="span" onClick={() => handleDelete(index)}>
                                      <Image src="/assets/imgs/delete.png" />
                                    </Box>
                                  </Button>
                                </Box>
                              );
                            }
                          })}
                          <Box onClick={() => addSurgeriesUploadDocument(condition.condition_cd)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                            <Image w="11px" src="/assets/imgs/add-blue.png" />
                            Add Document
                          </Box>
                          <input
                            type='file'
                            id='file-upload'
                            onChange={(e) => handleAllergiesUpload(e)}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                          /> */}
                          {/* <Divider /> */}
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add Allergy"
                        isDisabled={reactionsContinueBtnDisabled}
                        onClick={AddMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />

            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={allergiesContinueBtnDisabled}
                  onClick={AddMedicalConditions}
                />
              </Box>
            </>
          }
        />
         <ModalRight
                ref={editConditionModal}
                handlebackarrow={() => editConditionModal.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Edit allergy"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                        <Box>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {selectedConditionsInEdit}
                          </Text>
                          <DatePicker
                            disableFutureDates={true}
                            defaultDate={dateInEdit}
                            onDateChange={(selectedDateValue) => handleDateChangeInEdit(selectedDateValue)}
                            placeholderProp="Date*" />
                            <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                        <DropdownInputForVitals
                          variant="defaultSide"
                          dropDownPlace="Year*"
                          options={fromYearEditOptions}
                          onClick={(selectedDateValue) =>
                            handleAllergyEditYearChange(condition.condition_cd, selectedDateValue)
                          }
                          onChange={(e) => handleAllergyEditYearChange(condition.condition_cd, e.target.value)}
                          inputValueProp={fromEditdateYear}
                          menuList={{ pos: "absolute", right: "-45px", minW: "115px", zIndex: "999" }}
                          readOnly=""
                          id="startyear"
                        />
                        
                          <Flex w="100%" alignItems="center" gap="2">
                            <Box
                              as="div"
                              role="button"
                              aria-haspopup="dialog"
                              display="flex"
                              alignItems="center"
                              tabIndex={0}
                              border="1px solid #e2e8f0"
                              borderRadius="0 0 10px 10px"
                              p="10px"
                              height="auto"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052" },
                              }}
                              onClick={() => showReactionListInEdit()}
                              minHeight="48px"
                              flexWrap="wrap"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                gap="5px"
                                w="100%"
                                justifyContent={reasonsArray.length === 0 ? "space-between" : "start"}>
                                {reasonsArray.length === 0 ? (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                ) : reasonsArray.some(
                                  (item) => item.reaction === item.reaction,
                                ) ? (
                                  <Text pl="2" color="gray.400" sx={{ ...reasonsArray.length === 0 ? {} : floatLabel }} >Reaction*</Text>
                                ) : (
                                  <Text pl="2" color="gray.400">
                                    Reaction*
                                  </Text>
                                )}
                                {reasonsArray.length === 0 && (
                                  <Image src="/assets/imgs/right-icon.png" />
                                )}

                                {reasonsArray
                                  .filter(
                                    (tag) => tag.reaction === tag.reaction,
                                  )
                                  .map((tag, index) => (
                                    <Box
                                      key={index}
                                      display="flex"
                                      alignItems="center"
                                      gap="5px"
                                      bg="gray.100"
                                      borderRadius="full"
                                      p="1"
                                       wordBreak="break-all"
                                    >
                                      <Text fontSize="sm" px="5px">
                                        {tag.reaction}
                                      </Text>
                                      <Box
                                        as="button"
                                        p="5px"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          removeTagAllergy(index);
                                        }}
                                        aria-label="Remove condition"
                                      >
                                        <Image src="/assets/imgs/close.png" />
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                              <Box display="flex" justifyContent="end"></Box>
                            </Box>
                          </Flex>
                        </Box>
                 
                    </Box>
                    <ModalRight
                ref={reactionListModal}
                handlebackarrow={() => reactionListModal.current.closeRight()}
                withBackBtn={true}
                withBackHeader="Reaction"
                body={
                  <>
                    <SearchBar
                      passInputPlace="Search Reaction"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                          py="15px"
                        >
                          <Box display="flex" justifyContent="end" w="100%">
                            <Stack direction="column" gap="0" w="100%">
                              {reactionList && reactionList.length > 0 ? (
                                reactionList.map((category) =>
                                  category.data
                                    .filter((condition) =>
                                      condition.condition
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                    )
                                    .map((condition) => (
                                      <label key={condition.condition_cd} >

                                        <Box
                                          display="flex"
                                          justifyContent="space-between"
                                          borderBottom="1px solid #e2e8f0"
                                          p="15px"
                                          height="50px"
                                          width="100%"
                                          sx={{
                                            _hover: {
                                              border: "2px solid #384052",
                                              borderRadius: "10px",
                                            },
                                          }}
                                        >
                                          <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Text fontSize="16px" fontWeight="600">
                                              {condition.condition}
                                              <br />
                                              <Box
                                                as="span"
                                                fontSize="12px"
                                                fontWeight="500"
                                                color="#6A707D"
                                              ></Box>
                                            </Text>
                                          </Box>
                                          <Box display="flex" justifyContent="end">
                                            <Checkbox
                                              colorScheme="green"
                                              sx={{
                                                ".chakra-checkbox__control": {
                                                  width: "20px",
                                                  height: "20px",
                                                  borderRadius: "5px",
                                                },
                                              }}
                                              onChange={() =>
                                                handleReactionCheckboxChangeInEdit(
                                                  condition
                                                )
                                              }
                                              isChecked={reasonsArray.some(
                                                (item) =>
                                                  item.reaction ===
                                                  condition.condition
          
                                              )}
                                            ></Checkbox>
                                          </Box>
                                        </Box>

                                      </label>
                                    ))
                                )
                              ) : (
                                <p>Loading...</p>
                              )}
                               <Divider/>
                                <br/>
                                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                         Other Reactions
                                        </Heading>
                              <PrimaryInput
                               inputPlace="Reactions"
                               variant="bothSide"
                                      onChange={handleReaction} inputValue={typedReaction}
                               />
                               <br/>
                            </Stack>
                          </Box>
                        </Box>
                      </Stack>
                    </RadioGroup>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Edit reactions"
                        isDisabled={reactionsContinueBtnDisabledEdit}
                        onClick={editReactions}
                      />
                    </Box>
                  </>
                }
              />
                  </>
                }
                
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Update Allergy"
                        isDisabled={reactionsContinueBtnDisabledEdit}
                        onClick={EditMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />
          <DynamicModal
          ref={dynamicModalAllergy}
          modalHeader="Delete Allergy?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete  <strong>"{allergytype}"</strong>
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteAllergy}
              />
            </>
          }
        />

        {/* Medical Conditions */}
        <ModalRight
          ref={medicalconditions}
          modalWidth="360px"
          header="Add condition"
          body={
            <>
              <SearchBar passInputPlace="Search condition" 
              onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
              searchValue={searchValue} />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                  >
                  <Box display="flex" justifyContent="space-between" w="100%">
                    <Stack direction="column" w="100%" gap="0">
                        {medicalconditionsList && medicalconditionsList.length > 0 ? (
                        <>
                            {medicalconditionsList.map((category) => {
                            const filteredConditions = category.data.filter((condition) =>
                                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            );

                            return (
                                <React.Fragment key={category.conditiontype_cd}>
                                {filteredConditions.length > 0 && (
                                    <>
                                    <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                        {category.conditiontype}
                                    </Heading>
                                    {filteredConditions.map((condition) => (
                                        <label key={condition.condition_cd}>
                                        <Box
                                            key={condition.condition_cd}
                                            display="flex"
                                            justifyContent="space-between"
                                            borderBottom="1px solid #e2e8f0"
                                            p="15px"
                                            pl="5px"
                                            minH="50px"
                                            width="100%"
                                            sx={{
                                            _hover: { border: "2px solid #384052", borderRadius: "10px" },
                                            }}
                                        >
                                            <Box
                                            display="flex"
                                            alignItems="center"
                                            w='240px'
                                            >
                                            <Text fontSize="16px" whiteSpace='pre-wrap' overflow='hidden' lineHeight="normal" fontWeight="600">
                                                {condition.condition}
                                            </Text>
                                            </Box>
                                            <Box display="flex" justifyContent="end">
                                            <Checkbox
                                                colorScheme="green"
                                                sx={{
                                                ".chakra-checkbox__control": {
                                                    width: "20px",
                                                    height: "20px",
                                                    borderRadius: "5px",
                                                },
                                                }}
                                                onChange={() => handleCheckboxChangeMedicalConditions(condition, category)}
                                                isChecked={selectedMedicalConditions && selectedMedicalConditions.some(
                                                (item) => item.condition_cd === condition.condition_cd,
                                                )}

                                            ></Checkbox>
                                            </Box>
                                        </Box>
                                        </label>
                                    ))}
                                    </>
                                )}
                                </React.Fragment>
                            );
                            })}
                            {/* Display "No matching conditions found" only if no conditions were found in any category */}
                            {medicalconditionsList.every((category) =>
                            category.data.every((condition) =>
                                !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            ) && (
                            <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                            No matching conditions found!
                            </Box>
                            )}
                            <Divider/>
                            <br/>
                            <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                    Other Condition
                                    </Heading>
                            <PrimaryInput
                            inputPlace="Condition"
                            variant="bothSide"
                                    onChange={handleMedicalCondition} inputValue={typedMedicalCondition}
                                    />
                                    <br/>
                        </>
                        
                        ) : (
                        <p>Loading...</p>
                        )}
                    </Stack>
                    </Box>
                  </Box>
                </Stack>
              </RadioGroup>
              <ModalRight
                ref={medicalconditions2}
                header="Add condition"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedMedicalConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>


                          <DatePicker
                            disableFutureDates={true}
                            onDateChange={(e) => handleDateChangeMedicalCondition(condition.condition_cd, e)}
                            placeholderProp="Select a Date*"
                            calendarStlye={{ transform: "scale(0.9)", right: "-20px", top: "30px" }}
                          />
                          <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                        <DropdownInputForVitals
                          variant="defaultSide"
                          dropDownPlace="Year*"
                          options={conditionYearOptions}
                          onClick={(selectedDateValue) =>
                            handleConditionYearChange(condition.condition_cd, selectedDateValue)
                          }
                          onChange={(e) => handleConditionYearChange(condition.condition_cd, e.target.value)}
                          inputValueProp={conditionDateYear[condition.condition_cd] || ""}
                          menuList={{ pos: "absolute", right: "-45px", minW: "115px", zIndex: "999" }}
                          readOnly=""
                          id="startyear"
                        />
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={<>
                  <Box
                    w="100%"
                    display="flex"
                    flexDirection="row"
                    gap="15px">

                    <PrimaryButton
                      buttonText="Add Condition"
                      isDisabled={false}
                      onClick={AddConditionsCall}
                    />
                  </Box>
                </>}
              />
                 
            </>
            
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddConditions}
              />
            </Box>
          </>}
        />
         <ModalRight
          ref={editCondition}
          header="Edit condition"
          body={
            <>
              <Box display="flex" flexDir="column" gap="20px">
                  <Box>
                    <Box  onClick={editConditionName} alignItems="center" py="10px" borderBottom="1px solid var(--chakra-colors-chakra-border-color);" mb="10px" display="flex" justifyContent="space-between" cursor="pointer">
                    <Text title={ConditionName} w='230px' fontSize="16px" className="textOverflow" fontWeight="600" onClick={editConditionName}>
                      {ConditionName}
                    </Text>
                    <Image src="/assets/imgs/right-icon.png" />
                    </Box>


                    <DatePicker
                      disableFutureDates={true}
                      defaultDate={ConditionDate}
                      onDateChange={(e) => handleDateChangeEditModel(e)}
                      placeholderProp="Select a Date*"
                      calendarStlye={{ transform: "scale(0.9)", right: "-20px", top: "30px" }}
                    />
                    <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                        <DropdownInputForVitals
                          variant="defaultSide"
                          dropDownPlace="Year*"
                          options={conditionYearEditOptions}
                          onClick={(selectedDateValue) =>
                            handleConditionEditYearChange(condition.condition_cd, selectedDateValue)
                          }
                          onChange={(e) => handleConditionEditYearChange(condition.condition_cd, e.target.value)}
                          inputValueProp={conditionEditDateYear}
                          menuList={{ pos: "absolute", right: "-45px", minW: "115px", zIndex: "999" }}
                          readOnly=""
                          id="startyear"
                        />
                  </Box>
                
              </Box>

              

              <ModalRight
                ref={addConditionModalInsideEdit}
                modalWidth="360px"
                header="Update condition"
                body={
                  <>
                    <SearchBar passInputPlace="Search condition" onChange={(e) => setSearchValue(e.target.value)} />
                    <RadioGroup>
                      <Stack direction="column" gap="0">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="1px solid #e2e8f0"
                        >
                        <Box display="flex" justifyContent="space-between" w="100%">
            <Stack direction="column" w="100%" gap="0">
            {conditionsList && conditionsList.length > 0 ? (
            <>
              {conditionsList.map((category) => {
                const filteredConditions = category.data.filter((condition) =>
                  condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                );

                return (
                  <React.Fragment key={category.conditiontype_cd}>
                    {filteredConditions.length > 0 && (
                      <>
                        <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                          {category.conditiontype}
                        </Heading>
                        {filteredConditions.map((condition) => (
                          <label key={condition.condition_cd}>
                            <Box
                              key={condition.condition_cd}
                              display="flex"
                              justifyContent="space-between"
                              borderBottom="1px solid #e2e8f0"
                              p="15px"
                              pl="5px"
                              minH="50px"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052", borderRadius: "10px" },
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                              >
                                <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                                  {condition.condition}
                                </Text>
                              </Box>
                              <Box display="flex" justifyContent="end">
                                <Checkbox
                                  colorScheme="green"
                                  sx={{
                                    ".chakra-checkbox__control": {
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "5px",
                                    },
                                  }}
                                  onChange={() => handleCheckboxChangeForEdit(condition, category)}
                                  isChecked={selectedConditions.some(
                                    (item) => item.condition == condition.condition,
                                  )}
                                ></Checkbox>
                              </Box>
                            </Box>
                          </label>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
              {/* Display "No matching conditions found" only if no conditions were found in any category */}
              {conditionsList.every((category) =>
                category.data.every((condition) =>
                  !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                )
              ) && (
                <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                No matching conditions found!
              </Box>
              )}
            </>
            ) : (
            <p>Loading...</p>
            )}
            <Divider/>
                <br/>
                <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                          Other Condition
                        </Heading>
              <PrimaryInput
                inputPlace="Condition"
                variant="bothSide"
                      onChange={handleMedicalCondition} inputValue={typedMedicalCondition}
                      />
                      <br/>
            </Stack>
            </Box>

                        </Box>
                      </Stack>
                    </RadioGroup>
                    
                        
                  </>
                  
                }
                footerActions={<>
                  <Box
                    w="100%"
                    display="flex"
                    flexDirection="row"
                    gap="15px">

                    <PrimaryButton
                      buttonText="Continue"
                      isDisabled={false}
                      onClick={selectConditionInEdit}
                    />
                  </Box>
                </>}
              />
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Update Condition"
                isDisabled={false}
                onClick={UpdateCondition}
              />
            </Box>
          </>}
        />
        <DynamicModal
          ref={dynamicModalConditions}
          modalHeader="Delete condition?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete "{conditiontitle}"
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deleteMedicalCondition}
              />
            </>
          }
        />

        {/* Surgeries */}
        <ModalRight
        ref={addSurgery}
        header="Add Surgical History"
        body={
          <>
            <SearchBar
              passInputPlace="Search"
              onChange={(e) => setSearchValue(handleAlphaNumericDotAndSpace(e.target.value))}
              searchValue={searchValue}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                  <Stack direction="column" gap="0" w="100%">
                    {surgeriesList && surgeriesList.length > 0 ? (
                      surgeriesList.flatMap((category) =>
                        category.data.filter((condition) =>
                          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                        )
                      ).length > 0 ? (
                        surgeriesList.flatMap((category) =>
                          category.data.filter((condition) =>
                            condition.condition.toLowerCase().includes(searchValue.toLowerCase())
                          )
                        ).map((condition) => (
                          <label key={condition.condition_cd}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              borderBottom="1px solid #e2e8f0"
                              p="15px"
                              pl="5px"
                              minH="50px"
                              width="100%"
                              sx={{
                                _hover: { border: "2px solid #384052", borderRadius: "10px" },
                              }}
                            >
                              <Box
                                key={condition.condition_cd}
                                display="flex"
                                alignItems="center"
                              >
                                <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                                  {condition.condition}
                                  <br />
                                  <Box
                                    as="span"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="#6A707D"
                                  ></Box>
                                </Text>
                              </Box>
                              <Box display="flex" justifyContent="end">
                                <Checkbox
                                  colorScheme="green"
                                  sx={{
                                    ".chakra-checkbox__control": {
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "5px",
                                    },
                                  }}
                                                    onChange={() =>
                                                      handleSurgeriesCheckboxChange(condition)
                                                    }
                                  isChecked={selectedSurgeries.some(
                                                      (item) =>
                                                        item.condition_cd ===
                                                        condition.condition_cd
                                  )}
                                ></Checkbox>
                              </Box>
                            </Box>
                          </label>
                        ))
                      ) : (
                        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
                          No matching surgeries found!
                        </Box>
                      )
                    ) : (
                      <p>Loading...</p>
                    )}
                    <Divider/>
                          <br/>
                          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                                  Other Surgical History
                                  </Heading>
                        <PrimaryInput
                        inputPlace="Surgical History"
                        variant="bothSide"
                                onChange={handleSurgeriesCondition} inputValue={typedSurgeryCondition}
                                />
                                <br/>
                  </Stack>
                </Box>
                </Box>
              </Stack>
            </RadioGroup>
            <ModalRight
              ref={addSurgeriesModal}
              header="Add Surgical History"
              body={
                <>
                  <Box display="flex" flexDir="column" gap="20px">
                    {selectedSurgeries.map((condition, index) => (
                      <Box key={index}>
                        <Text fontSize="16px" py="10px" fontWeight="600">
                          {condition.condition}
                        </Text>
                      
                        <DatePicker
                          disableFutureDates={true}
                          placeholderProp="Date"
                          defaultDate={todaysDate}
                          onDateChange={(e) => handleDatePickerChange(condition.condition_cd, e)}
                          calendarStlye={{ transform: "scale(0.9)", right: "-22px", top: "30px" }}
                        />
                        <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                        <DropdownInputForVitals
                          variant="defaultSide"
                          dropDownPlace="Year*"
                          options={surgeryYearOptions}
                          onClick={(selectedDateValue) =>
                            handleSurgeryYearChange(condition.condition_cd, selectedDateValue)
                          }
                          onChange={(e) => handleSurgeryYearChange(condition.condition_cd, e.target.value)}
                          inputValueProp={surgeryDateYear[condition.condition_cd] || currentYear}
                          menuList={{ pos: "absolute", right: "-45px", minW: "115px", zIndex: "999" }}
                          readOnly=""
                          id="startyear"
                        />
                        <Box w="100%" pos='relative' cursor="pointer">
                        <PrimaryInput
                          variant="bothSide"
                          inputType="text"
                          inputPlace="Surgeon Name"
                          inputValue={inputValues[condition.condition_cd] || ''}
                          onChange={(e) =>
                            handleSurgeonNameChange(condition.condition_cd, handleAlphaDotAndSpace(e.target.value, 50))
                          }
                          onClick={() =>
                            listOfSurgeonNames(condition.condition_cd, false)
                          }
                          //if the inputIdProp have id then it will focus, if id is null it will not focus, 
                          inputIdProp={inputId == condition.condition_cd ? "Active" : null}
                          boolProp={boolValForSuggestions}
                        /> 

                        

                        <Image top="33%" right="6.5%" w="15px" 
                            onClick={() =>
                              listOfSurgeonNames(condition.condition_cd, true)
                            }
                             pos='absolute' src="/assets/imgs/double-arrow.png" />
                           
                         {/* {suggestions[condition.condition_cd] && suggestions[condition.condition_cd].length > 0 && (
                    <List pos="absolute" w="85%" maxH="150px" overflow="hidden auto" bg="white" zIndex="999" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px">
                      {suggestions[condition.condition_cd].map((suggestion, index) => (
                        <ListItem
                          key={index}
                          onClick={() => handleSelect(condition.condition_cd, suggestion)}
                          cursor="pointer"
                          p="1rem"
                          py="0.5rem"
                          borderBottom="1px solid #E6E7E9"
                        >
                          <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                        </ListItem>
                      ))}
                    </List>
                  )} */}
                  {showDocNamesDropdown && suggestions[condition.condition_cd] && suggestions[condition.condition_cd].length > 0 && (
                            <Menu isOpen={true}>
                              <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                                <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                              </MenuButton>
                              <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                                {suggestions[condition.condition_cd].map((suggestion, index) => (
                                  <MenuItem
                                    key={index}
                                    onClick={() => handleSelect(condition.condition_cd, suggestion)}
                                    cursor="pointer"
                                    p="1rem"
                                    w="100%"
                                    py="0.5rem"
                                    borderBottom="1px solid #E6E7E9"
                                  >
                                    <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          )}
                         </Box>
                        <PrimaryInput
                          variant="downSide"
                          inputType="text"
                          inputPlace="Hospital Name"
                          onChange={(e) =>
                            handleHospitalNameChange(condition.condition_cd, e.target.value)
                          }
                        /> 
                        {/* {documentURLs?.map((document, index) => {
                          if (document.condition_cd == condition.condition_cd || (document.condition_cd == null && condition.condition_cd == null)) {
                            if (!indexMap[document.condition_cd]) {
                              indexMap[document.condition_cd] = 1;
                            }
                            const docIndex = indexMap[document.condition_cd];
                            indexMap[document.condition_cd]++; // Increment index for next document with same condition_cd

                            return (
                              <Box key={index} mb="2" mt="2">
                                <Button
                                  as="a"
                                  rel="noopener noreferrer"
                                  w="100%"
                                  h="48px"
                                  borderRadius="10px"
                                  border="1px solid #E6E7E9"
                                  display="flex"
                                  justifyContent="space-between"
                                  bg="white"
                                  color="#1C4EC5"
                                  sx={{ _hover: { bg: "none" } }}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    fontSize: "12px",
                                  }}
                                >
                                  Document {docIndex}
                                  <Box as="span" onClick={() => handleDelete(index)}>
                                    <Image src="/assets/imgs/delete.png" />
                                  </Box>
                                </Button>
                              </Box>
                            );
                          }
                        })}
                        <Box onClick={() => addSurgeriesUploadDocument(condition)} as="button" display="flex" alignItems="center" gap="10px" mt="5px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                          <Image w="11px" src="/assets/imgs/add-blue.png" />
                          Add Document
                        </Box>

                       <input
                          type='file'
                          id='file-upload'
                          onChange={(e) => handleSurgeriesUpload(e)}
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        /> */}
                      </Box>
                    ))}
                  </Box>
                </>
              }
              footerActions={
                <>
                  <Box w="100%" display="flex" flexDirection="row" gap="15px">
                    <PrimaryButton
                      buttonText="Add Surgery"
                      isDisabled={false}
                      onClick={AddSurgeriesCall}
                    />
                  </Box>
                </>
              }
            />
          </>

        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddSurgery}
              />
            </Box>
          </>
        }
      />
       <DynamicModal
        ref={dynamicModalSurgeries}
        modalHeader="Delete Surgery?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete Surgery "{surgerytitle}"
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePatientSurgeries}
            />
          </>
        }
      />
      <ModalRight
        ref={editSurgery}
        header="Edit Surgical History "
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Box>
                <Text fontSize="16px" py="10px" fontWeight="600">
                  {procedureTitle}
                </Text>
                <DatePicker
                  disableFutureDates={true}
                  defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) => handleChangeDate(selectedDateValue)}
                  placeholderProp=" Date"
                  inputStyle={{borderRadius: "10px 10px 0 0"}} />
                  <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                        <DropdownInputForVitals
                          variant="defaultSide"
                          dropDownPlace="Year*"
                          options={surgeryYearEditOptions}
                          onClick={(selectedDateValue) =>
                            handleSurgeryEditYearChange(condition.condition_cd, selectedDateValue)
                          }
                          onChange={(e) => handleSurgeryEditYearChange(condition.condition_cd, e.target.value)}
                          inputValueProp={surgeryEditDateYear}
                          menuList={{ pos: "absolute", right: "-45px", minW: "115px", zIndex: "999" }}
                          readOnly=""
                          id="startyear"
                        />
               
               <Box w="100%" pos='relative' cursor="pointer">
                <PrimaryInput
                keyProp="uniqueKey"
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Surgeon Name"
                  defaultValue={surgeonName}
                  inputValue={surgeonName}
                  onChange={(e) =>
                    handleSurgeonNameEditChange(handleAlphaDotAndSpace(e.target.value, 50))
                  }
                  onClick={() =>
                    listOfSurgeonNamesEdit( false)
                  }
                  
                  inputIdProp={"SurgeonNameInEdit"}
                  boolProp={boolValForSuggestions}
                />
                 <Image top="33%" right="6.5%" w="15px" 
                            onClick={() =>
                              listOfSurgeonNamesEdit( true)
                            }
                             pos='absolute' src="/assets/imgs/double-arrow.png" />
                           
                 { showDocNamesDropdown &&  suggestions && suggestions.length > 0 && (
                            <Menu isOpen={true}>
                              <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                                <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                              </MenuButton>
                              <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                                {suggestions.map((suggestion, index) => (
                                  <MenuItem
                                    key={index}
                                    onClick={() => handleEditSelect(suggestion)}
                                    cursor="pointer"
                                    p="1rem"
                                    w="100%"
                                    py="0.5rem"
                                    borderBottom="1px solid #E6E7E9"
                                  >
                                    <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          )}
                         </Box>
                <PrimaryInput
                  variant="downSide"
                  inputType="text"
                  inputPlace="Hospital Name"
                  defaultValue={hospitalName}
                  inputValue={hospitalName}
                  onChange={handleChangeHospitalName}
                />

              </Box>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Surgical History"
                isDisabled={false}
                onClick={EditSurgicalHistory}
              />
            </Box>
          </>
        }
      />
      {/* Medications */}
      <ModalRight
        ref={addmedications}
        header="Add Medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Stack>
                <InputGroup>
                  <Input
                    pr="4.5rem" // Padding right to make room for the icon
                    type="text"
                    placeholder="Medicine name*"
                    value={medicinename}
                    sx={{
                      height: "48px",
                      borderRadius: "10px",
                      _hover: {
                        border: "2px solid",
                        borderColor: COLORS.INPUT_FOCUS_DARK,
                        outline: "none",
                        bg: "none",
                        _focus: {
                          borderRadius: "10px",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                        },
                        _focusVisible: { borderColor: "none" },
                      },
                    }}
                    onChange={handlemedicinename}
                    maxLength={80}
                  />
                  <InputRightElement width="4.5rem">
                    <Box
                      as="button"
                      h="1.75rem"
                      bg="none"
                      pt="8px"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      sx={{ _hover: { bg: "none" } }}
                    >
                      <Image src="/assets/svgs/image-search-blue.svg" />
                    </Box>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleimageselection}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                <PrimaryInput
                variant="bothSide"
                  inputValue={Composition}
                  onChange={(event)=> setComposition(event.target.value)}
                  inputPlace="Medicine Composition"
                />
                  <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "2.8rem",
                            m: "0"
                          }}
                          onClick={() =>
                            fetchComposition(medicinename)
                          }
                          isDisabled={false}
                        />
                        
                        </InputGroup>
                        {medicinename != '' &&
                        <Box as="button" onClick={makeAicall} textAlign="right" fontSize="12px" textDecoration="underline">Learn more</Box>
                        }
                        <RadioGroup
                  defaultValue={medicineTypeRadio}
                  onChange={handlemedicinetypechange}
                >
                  <Stack direction="row" gap="20px" wrap="wrap">
                    <Radio value="Tablet">Tablet</Radio>
                    <Radio value="Capsule">Capsule</Radio>
                    <Radio value="Syrup">Syrup</Radio>
                    <Radio value="Injection">Injection</Radio>
                    <Radio value="Ointment">Ointment</Radio>
                  </Stack>
                </RadioGroup>
                <Divider />
                <Box>
                  {/* {medicinetype == "Tablet" && */}
                    <MiniDropdownInput
                    variant="bothside"
                    options={ [
                    { label: "mg", value: "mg" },
                    { label: "ml", value: "ml" },
                    { label: "grms", value: "grms" },
                      ] }
                    placeholderProp="Dosage"
                    onSelect={handleChangeDosageUnit}
                    defaultOptionProp={dosageunit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    inputVal={dosage}
                    
                    maxLength="6"
                  />
                  <DropdownInput
                    variant="downSide"
                    dropDownPlace="Consumption frequency"
                    options={["1", "2", "3", "4"]}
                    onClick={handleconsumptionfrequencychange}
                  />
                    {/* <PrimaryInput
                    inputPlace="Medicine Expiry"
                    variant="defaultSide"
                    inputValue={medicineexpirydate}
                    onChange={(event) => handleExpiryDateChange(event.target.value)}
                  /> */}

                   <DatePickerMonthYear
                      placeholderProp="Medicine Expiry"
                      value={medicineexpirydate}
                      onDateChange={handleMonthYearChange}
                      datePickerInputStyle={{ borderRadius: "0!important" }}
                      minYear={moment().year()}
                    />
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={handlemedicinetime}
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox value="morning">Morning</Checkbox>
                      <Checkbox value="evening">Evening</Checkbox>
                      <Checkbox value="night">Night</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider />
                  <CheckboxGroup
                    colorScheme="green"
                    onChange={handlemedicinetimebeforeafter}
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox value="beforefood">Before Food</Checkbox>
                      <Checkbox value="afterfood">After Food</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider />
                  
                </Box>
                <Divider />
                <Box>
                <Box w="100%" pos='relative' cursor="pointer">
                  <PrimaryInput
                    inputPlace="Prescribed by"
                    variant="defaultSide"
                    inputValue={prescribedby}
                    onChange={(e) =>
                      handleSurgeonNameChange(handleAlphaDotAndSpace(e.target.value,50))
                    }
                    onClick={() =>
                      listOfSurgeonNames(false)
                    }
                inputIdProp={"DoctorNameInAdd"}
                boolProp={boolValForSuggestions}
                  />
                    <Image top="33%" right="6.5%" w="15px" 
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />
                          
                  </Box>
                    {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleMedicineSelect(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}


                  <PrimaryInput
                    inputPlace="Hospital name"
                    variant="downSide"
                    onChange={(e) => setHospitalname(handleAlphaNumericDotAndSpace(e.target.value))}
                    inputValue={hospitalname}
                  />
                  <Box mt="15px">
                  <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                onChange={handleIsCurrent}
              >
                  Current
              </Checkbox>
              </Box>
                </Box>
                {/* {filenameArray.map((filename, indexToDelete) => (
                  <Button fontSize="14px" fontWeight="500" w="100%" h="48px" borderRadius="10px" border="1px solid #E6E7E9" display="flex" justifyContent="space-between" bg="white" color="#1C4EC5" sx={{ _hover: { bg: "none" } }}>
                    Document {indexToDelete + 1}
                    <Box as="span" onClick={() => handleDelete(indexToDelete)}>
                      <Image src="/assets/imgs/delete.png" />
                    </Box>
                  </Button>
                ))}
                <Box onClick={addMedicationUploadDocument} as="button" display="flex" alignItems="center" gap="10px" fontSize="14px" fontWeight="600" color="#1C4EC5" textAlign="left">
                  <Image w="11px" src="/assets/imgs/add-blue.png" />
                  Add Document
                </Box>
                <input
                  type='file'
                  id='file-upload'
                  onChange={(e) => handleMedicationUpload(e)}
                  ref={fileInputRef} // Set the ref for the file input
                  style={{ display: 'none' }} // Hide the file input
                /> */}
              </Stack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Add Medication"
                onClick={addmedicine}
                isDisabled={false}
              />
            </Box>
          </>
        }
      />
      <DynamicModal
        ref={learnMoreModal}
        modalHeader={medicinename}

        modalStyle={{maxWidth: "calc(100vw - 45%)",maxHeight: "calc(100vh - 30%)", overflow:"hidden auto", w: "100%"}}
        modalBody={
          <>
          
          <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
              Benifit  
            </Text>
            <Box as="span" >{benifit ? benifit : ''}</Box>
            </Box>
            
            <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
              Side Effect 
            </Text>
            <Box as="span"  > {sideEffects ? sideEffects : ''}</Box>
            </Box>
            
          <Box mb="10px" >
            <Text fontSize="14px" fontWeight="600">
                {Composition == "Not Available" ? "" : "Composition" }
            </Text>
            <Box as="span" mb="10px">{Composition == "Not Available" ? "" : Composition }</Box>
            </Box>
          </>
        }
        
      />
       <DynamicModal
        ref={dynamicModalMedication}
        modalHeader="Delete Medicine?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete ?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletemedicine}
            />
          </>
        }
      />
      <ModalRight
        ref={editmedications}
        header="Update Medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Box display="flex" flexDir="column" gap="20px">
              <Stack spacing={4}>
                <InputGroup>
                  <Input
                    pr="4.5rem" // Padding right to make room for the icon
                    type="text"
                    placeholder="Medicine name*"
                    value={medicinename}
                    sx={{
                      height: "48px",
                      borderRadius: "10px",
                      _hover: {
                        border: "2px solid",
                        borderColor: COLORS.INPUT_FOCUS_DARK,
                        outline: "none",
                        bg: "none",
                        _focus: {
                          borderRadius: "10px",
                          borderColor: COLORS.INPUT_FOCUS_DARK,
                          outline: "none",
                        },
                        _focusVisible: { borderColor: "none" },
                      },
                    }}
                    maxLength={80}
                    onChange={handlemedicinename}
                  />
                  <InputRightElement width="4.5rem">
                    <Box
                      as="button"
                      h="1.75rem"
                      bg="none"
                      pt="8px"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      sx={{ _hover: { bg: "none" } }}
                    >
                      <Image src="/assets/svgs/image-search-blue.svg" />
                    </Box>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: "none" }}
                      //onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleimageselection}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                <PrimaryInput
                variant="bothSide"
                  inputValue={Composition}
                  onChange={(event)=> setComposition(event.target.value)}
                  inputPlace="Chemical Composition"
                />
                  <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "15%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: "4px",
                            mb: "0px",
                            h: "2.8rem",
                            m: "0"
                          }}
                          onClick={() =>
                            fetchComposition(medicinename)
                          }
                          isDisabled={false}
                        />
                </InputGroup>
                {medicinename != ''  &&
                        <Box as="button" onClick={makeAicall} textAlign="right" fontSize="12px" textDecoration="underline">Learn more</Box>
                        }
                <RadioGroup
                  defaultValue={medicinetype}
                  onChange={handlemedicinetypechange}
                >
                  <Stack direction="row" gap="20px" wrap="wrap">
                  <Radio value="Tablet">Tablet</Radio>
                    <Radio value="Capsule">Capsule</Radio>
                    <Radio value="Syrup">Syrup</Radio>
                    <Radio value="Injection">Injection</Radio>
                    <Radio value="Ointment">Ointment</Radio>
                  </Stack>
                </RadioGroup>
                <Divider />
                <Box>
                  <MiniDropdownInput
                    variant="bothside"
                    options={[
                      { label: "mg", value: "mg" },
                      { label: "ml", value: "ml" },
                      { label: "grms", value: "grms" },
                    ]}
                    placeholderProp="Dosage"
                    defaultValue={dosage}
                    inputVal={dosage}
                    defaultOptionProp={dosageunit}
                    onSelect={handleChangeDosageUnit}
                    handleInputChange={(e) =>
                      setMedicineDosage(validationOfNumberWithOnly1Decimal(e.target.value))
                    }
                    maxLength="6"
                  />

                  <Box display="flex" flexDir="row">
                 
                  </Box>
                  <DropdownInput
                    variant="downSide"
                    dropDownPlace="Consumption frequency"
                    options={["1", "2", "3", "4"]}
                    onClick={handleconsumptionfrequencychange}
                    defaultOption={frequency ? frequency + "" : ""}
                  />
                  {/* <PrimaryInput
                    inputPlace="Medicine Expiry"
                    variant="defaultSide"
                    defaultValue={medicineexpirydate}
                    inputValue={medicineexpirydate}
                    onChange={(event) => handleExpiryDateChange(event.target.value)}
                  /> */}
                  <DatePickerMonthYear
        placeholderProp="Medicine Expiry"
        value={medicineexpirydate}
        onDateChange={handleMonthYearChange}
        datePickerInputStyle={{ borderRadius: "0!important" }}
        
        defaultDate={medicineexpirydate}
        minYear={moment().year()}

      />
                  <CheckboxGroup
                    colorScheme="green"
                  //onChange={handlemedicinetime}               
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox isChecked={morning === 1} onChange={() => setMorning(morning === 1 ? 0 : 1)}>Morning</Checkbox>
                      <Checkbox isChecked={setevening === 1} onChange={() => setEvening(setevening === 1 ? 0 : 1)}>Evening</Checkbox>
                      <Checkbox isChecked={setnight === 1} onChange={() => setNight(setnight === 1 ? 0 : 1)}>Night</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <Divider />
                  <CheckboxGroup
                    colorScheme="green"
                  >
                    <Stack spacing={2} pt="20px" direction="row">
                      <Checkbox isChecked={beforefood === 1} onChange={() => setBeforefood(beforefood === 1 ? 0 : 1)}>Before Food</Checkbox>
                      <Checkbox isChecked={afterfood === 1} onChange={() => setAfterfood(afterfood === 1 ? 0 : 1)}>After Food</Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  
                </Box>
                <Divider />
                <Box>
                  
                <Box w="100%" pos='relative' cursor="pointer">
                  <PrimaryInput
                    inputPlace="Prescribed by"
                    variant="defaultSide"
                    onChange={(e) =>
                      handleSurgeonNameChange(handleAlphaDotAndSpace(e.target.value,50))
                    }
                    onClick={() =>
                      listOfSurgeonNames(false)
                    }
                    defaultValue={prescribedby}
                    inputValue={prescribedby}

                    
                inputIdProp={"DoctorNameInEdit"}
                boolProp={boolValForSuggestions}
                  />
                    <Image top="33%" right="6.5%" w="15px" 
                          onClick={() =>
                            listOfSurgeonNames( true)
                          }
                            pos='absolute' src="/assets/imgs/double-arrow.png" />

                  {showDocNamesDropdown &&  suggestions.length > 0 && (
                <Menu isOpen={true}>
                <MenuButton as={Box} w="100%" display="block" pos="relative" zIndex="999">
                  <Box pos="absolute" top="100%" left="0" w="100%" bg="white" borderTop="none" border="1px solid #E6E7E9" borderRadius="0 0 12px 12px" />
                </MenuButton>
                <MenuList w="100%" borderRadius="0 0 12px 12px" zIndex="999" bg="white" maxH="150px" overflow="hidden auto">
                  {suggestions.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSelectMedince(suggestion)}
                      cursor="pointer"
                      p="1rem"
                      py="0.5rem"
                      borderBottom="1px solid #E6E7E9"
                    >
                      <Text fontSize="0.875rem" fontWeight="500">{suggestion.doctorname}</Text>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              )}
              </Box>
                  <PrimaryInput
                    inputPlace="Hospital name"
                    variant="downSide"
                    onChange={(e) => setHospitalname(handleAlphaNumericDotAndSpace(e.target.value))}
                    defaultValue={hospitalname}
                  />
                </Box>
                <Box mt="15px">
                  <Checkbox
                colorScheme="green"
                sx={{
                  ".chakra-checkbox__control": {
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  },
                }}
                isChecked={isChecked == 1}
                onChange={handleIsCurrent}
              >
                  Current
              </Checkbox>
              </Box>

              </Stack>
            </Box>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Update Medication"
                onClick={editmedicine}
                isDisabled={false}
                btnStyle={{my: "0"}}
              />
            </Box>
          </>
        }
      />
      {/* Family  */}
      <ModalRight
          ref={AddFamily}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Add member"
          body={
            <>
              <Box display="flex" flexDir="column">
                <PrimaryInput
                  variant="defaultSide"
                  inputType="text"
                  inputPlace="First Name*"
                  onChange={handleFirstName}
                  inputValue={name}
                />
                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Last Name"
                  onChange={handleLastName}
                  inputValue={lastName}
                />
                 <DropdownInput
                  variant="bothSide"
                  dropDownPlace="Relationship*"
                  options={relationdata}
                  onClick={handleRelationshipInput}
                />
                  <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender*"
                options={["Male", "Female", "Other"]}
                defaultOption={gender_cd == "2" ? "Male" : gender_cd == "1" ? "Female" : gender_cd == "3" ? "Other" : ""}
                onClick={selectGender}
              />
                <DatePicker inputStyle={{borderRadius: "0!important"}} 
                disableFutureDates={true}
                onDateChange={handleDateChangeFamily} 
                placeholderProp={"Date of birth*"} 
                defaultDate={dateofbirth}  />
                  <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Age"
                  onChange={handleAge}
                  //value={age}
                  inputValue={age}
                  //defaultValue={age}
                  maxLength={3}
                />
             <Box mt="15px">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAddFamilyConditionModal}
                  tabIndex={0}
                  bg="white"
                  borderRadius="10px 10px 0 0"
                  border="1px solid #E6E7E9"
                  p={selectedConditions.length === 0 ? "8px 20px 8px 8px" : "2"}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  flexWrap="wrap"
                  justifyContent={selectedConditions.length > 0 ? "flex-start": "space-between"}
                >
                  {selectedConditions.length === 0 && (
                    <Text pl="1" color="gray.400">
                      Medical Condition
                    </Text>
                  )}
                  {selectedConditions.length === 0 && (
                  <Image src="/assets/imgs/right-icon.png" />
                  )}
                  {selectedConditions?.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* <Input
                id="medications"
                placeholder="Medications"
                formLabel="Medical Conditions"
                onChange={medicationsHandleChange}
                height="48px"
                borderRadius="0"
                border="1px solid #CDCFD4"
              /> */}

              {/* Allergies */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openAllergiesModalFamily}
                tabIndex={0}
                bg="white"
                borderRadius="0 0 10px 10px"
                border="1px solid #E6E7E9"
                
                p={selectedAllergiesFamily?.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent={selectedAllergiesFamily?.length > 0 ? "flex-start" : "space-between"}
              >
                {selectedAllergiesFamily?.length === 0 && (
                  <>
                  <Text pl="1" color="gray.400">
                    Allergies
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedAllergiesFamily?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                      
                    {tag.condition ? tag.condition : tag.condition_type}
                    </Text>
                    <Box
                      as="button"
                      p="5px"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeTagAllergiesFromAdd(tag.condition_cd);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>
               
              </Box>
              </Box>
              <Box py="20px">
                <Divider border="1px solid #E6E7E9" />
              </Box>
              <Box display="flex" gap="30px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  onChange={handleAdoptedCheckboxChange}
                >
                  Adopted
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  onChange={handledeceasedCheckboxChange}
                >
                  
                  Deceased
                </Checkbox>
              </Box>
            
            </>
          }
          footerActions={
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton buttonText="Add Member" onClick={SubmitAddForm} isDisabled={false} />
            </Box>
          }
        />
        <ModalRight
          ref={EditFamily}
          bodyStyle={{ p: "24px 32px" }}
          modalWidth={"360px"}
          header="Edit Member"
          body={
            <>
              <Box display="flex" flexDir="column">
                <PrimaryInput
                  variant="defaultSide"
                  inputType="text"
                  inputPlace="First Name*"
                  defaultValue={name}
                  //onChange={(e) => setName(e.target.value)}
                  onChange={handleFirstName}
                  inputValue={name}
                />
                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Last Name"
                  defaultValue={lastName}
                  //onChange={(e) => setLastName(e.target.value)}
                  onChange={handleLastName}
                  inputValue={lastName}
                />
                <DropdownInput
                  variant="bothSide"
                  dropDownPlace="Relationship*"
                  options={relationdata}
                  defaultOption={relationship}
                  onClick={handleRelationshipInput}
                />
                 <DropdownInput
                variant="bothSide"
                dropDownPlace="Gender*"
                options={["Male", "Female", "Other"]}
                onClick={selectGender}
                defaultOption={gender_cd == "2" ? "Male" : gender_cd == "1" ? "Female" : gender_cd == "3" ? "Other" : ""}
                inputValue={gender}
              />

                <DatePicker 
                inputStyle={{borderRadius: "0!important"}}
                disableFutureDates={true}
                defaultDate={dateofbirth} 
                onDateChange={handleDateChangeFamily}
                placeholderProp={"Date of birth*"} />
                  <Text fontSize="16px" my="15px" fontWeight="400" textAlign="center">
               -or-
              </Text>
                <PrimaryInput
                  variant="bothSide"
                  inputType="text"
                  inputPlace="Age"
                  onChange={handleAge}
                  inputValue={age}
                  //defaultValue={age}
                  maxLength={3}
                />

               
              
                <Box mt="15px">
                <Box
                  as="div"
                  role="button"
                  aria-haspopup="dialog"
                  onClick={openAddFamilyConditionModal}
                  tabIndex={0}
                  bg="white"
                  borderRadius="10px 10px 0 0"
                  border="1px solid #E6E7E9"
                  
                  p={selectedConditions?.length === 0 ? "8px 20px 8px 8px" : "2"}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  gap="2"
                  height="auto"
                  minHeight="48px"
                  flexWrap="wrap"
                  justifyContent={selectedConditions?.length > 0 ? "flex-start": "space-between"}
                >
                  {selectedConditions?.length === 0 && (
                    <>
                    <Text pl="1" color="gray.400">
                      Medical Condition
                    </Text>
                    
                  <Image src="/assets/imgs/right-icon.png" />
                  
                  </>)}
                  {selectedConditions?.map((tag, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      gap="1"
                      bg="gray.100"
                      borderRadius="full"
                      p="1"
                    >
                      <Text fontSize="sm" px="5px">
                        {tag.condition}
                      </Text>
                      <Box
                        as="button"
                        p="5px"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTag(index);
                        }}
                        aria-label="Remove condition"
                      >
                        <Image src="/assets/imgs/close.png" />
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* Allergies */}
              <Box
                as="div"
                role="button"
                aria-haspopup="dialog"
                onClick={openAllergiesModalFamily}
                tabIndex={0}
                bg="white"
                borderRadius="0 0 10px 10px"
                border="1px solid #E6E7E9"
                
                p={selectedAllergiesFamily?.length === 0 ? "8px 20px 8px 8px" : "2"}
                w="100%"
                display="flex"
                alignItems="center"
                gap="2"
                height="auto"
                minHeight="48px"
                flexWrap="wrap"
                justifyContent={selectedAllergiesFamily?.length > 0 ? "flex-start" : "space-between"}
              >
              {selectedAllergiesFamily?.length === 0 && (
                <>
                  <Text pl="1" color="gray.400">
                    Allergies
                  </Text>
                  
                  <Image src="/assets/imgs/right-icon.png" />
                  </>
                )}
                {selectedAllergiesFamily?.map((tag, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="1"
                    bg="gray.100"
                    borderRadius="full"
                    p="1"
                  >
                    <Text fontSize="sm" px="5px">
                    {tag.allergy ? tag.allergy : tag.allergytype} 
                    </Text>
                    <Box
                      as="button"
                      p="5px"

                      onClick={(e) => {
                        e.stopPropagation();
                        removeTagAllergiesFromEdit(tag.allergytype);
                      }}
                      aria-label="Remove condition"
                    >
                      <Image src="/assets/imgs/close.png" />
                    </Box>
                  </Box>
                ))}
              </Box>
                </Box>

              </Box>
              <Box py="20px">
                <Divider border="1px solid #E6E7E9" />
              </Box>
              <Box display="flex" gap="30px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={adopted === true}
                  onChange={handleAdoptedCheckboxChange}
                >
                  Adopted
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                  defaultChecked={deceased === true}
                  onChange={handledeceasedCheckboxChange}
                >
                  
                  Deceased
                </Checkbox>
              </Box>

           

        
            </>
          }
          footerActions={
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton buttonText="Update Member" onClick={SubmitEditForm}/>
            </Box>
          }
        />
        <ModalRight
          ref={Medicalconditionsmodel}
          header="Select Condition"
          modalWidth="360px"
          body={
            <>
              <SearchBar
                passInputPlace="Search condition"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                    py="15px"
                  >
                   <Box display="flex" justifyContent="end" w="100%">
  <Stack direction="column" gap="0" w="100%">
    {medicaldata && medicaldata.length > 0 ? (
      medicaldata.some(category =>
        category.data.some(condition =>
          condition.condition.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) ? (
        medicaldata.map(category => (
          <React.Fragment key={category.category_id}>
            {category.data
              .filter(condition =>
                condition.condition.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map(condition => (
                <label key={condition.condition_cd}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #e2e8f0"
                                  // borderRadius="10px"
                    p="15px"
                    pl="5px"
                    minH="50px"
                    width="100%"
                    sx={{
                                    _hover: { border: "2px solid #384052", borderRadius: "10px" },
                    }}
                  >
                    <Box
                      key={condition.condition_cd}
                      display="flex"
                      alignItems="center"
                    >
                      <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                        {condition.condition}
                        <br />
                        <Box
                          as="span"
                          fontSize="12px"
                          fontWeight="500"
                          color="#6A707D"
                        ></Box>
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Checkbox
                        colorScheme="green"
                        sx={{
                          ".chakra-checkbox__control": {
                            width: "20px",
                            height: "20px",
                            borderRadius: "5px",
                          },
                        }}
                                      onChange={() =>
                                        handleCheckboxChangeFamily(condition, category)
                                      }
                        isChecked={selectedConditions && selectedConditions.some(
                                        (item) =>
                                          item.condition_cd ===
                                          condition.condition_cd
                        )}
                      ></Checkbox>
                    </Box>
                  </Box>
                </label>
              ))}
          </React.Fragment>
        ))
      ) : (
        <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
         No matching conditions found!
        </Box>
      )
    ) : (
      <p>Loading...</p>
    )}
     <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Condition
                  </Heading>
        <PrimaryInput
         inputPlace="Condition"
         variant="bothSide"
                onChange={handleConditionforFamily} inputValue={typedCondition}
                />
                <br/>
  </Stack>
</Box>
                  </Box>
                </Stack>
              </RadioGroup>
            </>
          }
          footerActions={<>
            <Box
              w="100%"
              display="flex"
              flexDirection="row"
              gap="15px">

              <PrimaryButton
                buttonText="Continue"
                isDisabled={false}
                onClick={AddMedicalConditionsforFamily}
              />
            </Box>
          </>}
        />
        <ModalRight
        ref={allergiesModal}
        header="Select Allergies"
        body={
          <>
            <SearchBar
              passInputPlace="Search allergies"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <RadioGroup>
              <Stack direction="column" gap="0">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="1px solid #e2e8f0"
                  py="15px"
                >
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {allergiesdata && allergiesdata.length > 0 ? (
                        allergiesdata.map((category) =>
                          category.data
                            .filter((condition) =>
                              condition.condition
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            )
                            .map((condition) => (
                              <label key={condition.condition_cd}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                border="1px solid #e2e8f0"
                                borderRadius="10px"
                                p="15px"
                                height="50px"
                                width="100%"
                                sx={{
                                  _hover: {
                                    border: "2px solid #384052",
                                  },
                                }}
                              >
                                <Box
                                  key={condition.condition_cd}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Text
                                    fontSize="16px"
                                    fontWeight="600"
                                  >
                                    {condition.condition}
                                    <br />
                                    <Box
                                      as="span"
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="#6A707D"
                                    ></Box>
                                  </Text>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                >
                                  <Checkbox
                                    colorScheme="green"
                                    sx={{
                                      ".chakra-checkbox__control": {
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "5px",
                                      },
                                    }}
                                    onChange={() =>
                                      handleAllergiesCheckboxChange(
                                        condition
                                      )
                                    }
                                    isChecked={selectedAllergiesFamily?.some(
                                      (item) =>
                                        item.condition_cd ===
                                        condition.condition_cd
                                    )}
                                  ></Checkbox>
                                </Box>
                              </Box>
                              </label>
                            ))
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                       <Divider/>
          <br/>
          <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
                   Other Allergy
                  </Heading>
        <PrimaryInput
         inputPlace="Allergy"
         variant="bothSide"
                onChange={handleAllergy} inputValue={typedAllergy}
                />
                <br/>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </RadioGroup>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" flexDirection="row" gap="15px">
              <PrimaryButton
                buttonText="Continue"
                isDisabled={allergiesContinueBtnDisabled}
                onClick={closeAddAllergyModal}
              />
            </Box>
          </>
        }
      />
      
      <DynamicModal
          ref={dynamicModalFamily}
          modalHeader="Delete Family Member?"
          modalBody={
            <>
              <Text fontSize="14px" fontWeight="600">
                Are you sure you want to delete<br/>
                {familytitle ? familytitle : "-"} 
              </Text>
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Delete"
                onClick={deletefamilyhistory}
              />
            </>
          }
        />


<ModalRight
        ref={showmedicinename}
        header="Upload medication"
        bodyStyle={{ p: "24px 32px" }}
        modalWidth="360px"
        body={
          <>
            <Flex>
              <Box w="100%">
                <Center>
                  <VStack spacing={6} w="450px">
                    {/* <img
                      alt="not found"
                      width={"250px"}
                      src={selectedImage}
                    /> */}
                    <Image
                      alt="Uploaded medication"
                      src={selectedImage}
                      // boxSize="297px" // Sets both width and height to 297px
                      objectFit="contain" // This will ensure the aspect ratio is maintained without stretching
                    />
                    {/* <Input placeholder="Medicine Name" onChange={(event)=> setMedicineName(event.target.value)}/> */}
                    <PrimaryInput
                      defaultValue={medicinename}
                      inputValue={medicinename}
                      variant="fullRound"
                      inputPlace="Medicine Name"
                      mt="20px"
                      onChange={(event) =>
                        setMedicineName(event.target.value)
                      }
                      id="MedicineNameAdd"
                    />
                  </VStack>
                </Center>
              </Box>
            </Flex>
          </>
        }
        footerActions={
          <>
            <Box w="100%" display="flex" alignItems="center" gap="15px">
              <PrimaryInput
                inputType="file"
                uploadText="Re-Upload"
                id="file-upload"
                onChange={handleimageselection}
                variant="grayBtnFull"
                buttonText="Reupload"
                isDisabled={false}
              />
              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                //onChange={(e) => setFile(e.target.files[0])}
                onChange={handleimageselection}
              />
              <PrimaryButton
                onClick={handlemedicinenamecontinue}
                buttonText="Continue"
                isDisabled={false}
              />
            </Box>
          </>
        }
      />
    </>
  )
}

export default PatientScreening