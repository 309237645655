import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Input,
  FormControl,
  Image,
  InputRightElement,
  FormLabel,
  InputGroup,
  Select,
  RadioGroup,
  Radio,
  Stack,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import moment from 'moment';
import { isValid, isAfter, isBefore } from 'date-fns';

const COLORS = {
  INPUT_FOCUS_DARK: "#384052",
  PRIMARY_WHITE: "#ffffff",
  BTN_DISABLE: "#f0f0f0",
  BTN_DISABLE_TEXT: "#a0a0a0",
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
  fontSize: "sm",
  color: COLORS.INPUT_FOCUS_DARK,
  opacity: "50%",
};

const inputStyles = {
  borderRadius: "0px",
  background: COLORS.PRIMARY_WHITE,
  boxShadow: "none",
  width: "100%",
  height: "48px",
  _disabled: {
    bg: COLORS.BTN_DISABLE,
    color: COLORS.BTN_DISABLE_TEXT,
    cursor: "not-allowed",
  },
  _hover: {
    borderRadius: "10px",
    border: `2px solid`,
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
    bg: "none",
  },
  _focus: {
    borderRadius: "10px",
    border: "2px solid",
    borderColor: COLORS.INPUT_FOCUS_DARK,
    outline: "none",
  },
};

function DatePickerQuick({
  id,
  onDateChange,
  placeholderProp,
  defaultDate,
  value,
  clearValue = false,
  dateWidth = '100%',
  disableFutureDates,
  disablePastDates,
  minDate,
  maxDate,
  datePickerInputStyle,
  CUSTOM_DISABLE = false,
  patientDOB,
}) {
  const initialDate = defaultDate ? moment(defaultDate, 'DD-MM-yyyy').toDate() : new Date();
  const [selected, setSelected] = useState(initialDate);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [viewMode, setViewMode] = useState('quickView'); // Modes: 'date', 'monthYear', 'quickView'
  const [selectedMonth, setSelectedMonth] = useState(initialDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(initialDate.getFullYear());
  const outSideClick = useRef(null);
  const today = new Date();
  const years = Array.from({ length: 101 }, (_, i) => today.getFullYear() - 50 + i);
  const minDateFromDOB = patientDOB ? moment(patientDOB, 'Do MMMM, YYYY').toDate() : null;

  useEffect(() => {
    if (clearValue) {
      setSelected(value ? moment(value, 'DD-MM-yyyy').toDate() : null);
      setSelectedMonth(initialDate.getMonth());
      setSelectedYear(initialDate.getFullYear());
    }
  }, [value, clearValue, initialDate]);

  const handleInputClick = () => {
    setDatePickerVisible(prev => !prev);
  };

  const disableDates = date => {
    const normalizedToday = new Date(today.setHours(0, 0, 0, 0));
    let shouldDisable = false;

    if (CUSTOM_DISABLE) {
      if (minDateFromDOB && isBefore(date, minDateFromDOB)) shouldDisable = true;
      if (!maxDate && disableFutureDates && isAfter(date, normalizedToday)) shouldDisable = true;
      if (!minDate && disablePastDates && isBefore(date, normalizedToday)) shouldDisable = true;
      if (minDate && isBefore(date, new Date(minDate))) shouldDisable = true;
      if (maxDate && isAfter(date, new Date(maxDate))) shouldDisable = true;
    } else {
      if (disableFutureDates && isAfter(date, normalizedToday)) return true;
      if (minDate && isBefore(date, normalizedToday)) return true;
      if (maxDate && isAfter(date, new Date(maxDate))) return true;
    }

    return shouldDisable;
  };

  const handleDateSelect = date => {
    if (date) {
      setSelected(date);
      setSelectedMonth(null);
      setSelectedYear(null);
      onDateChange?.(date);
    }
    setDatePickerVisible(false);
  };

  const handleMonthChange = e => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    if (selectedYear) {
      const date = new Date(selectedYear, month, 1);
      setSelected(date);
      onDateChange?.(date);
      setDatePickerVisible(false);
    }
  };

  const handleYearChange = e => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    if (selectedMonth !== null) {
      const date = new Date(year, selectedMonth, 1);
      setSelected(date);
      onDateChange?.(date);
      setDatePickerVisible(false);
    }
  };

  const handleQuickViewChange = value => {
    const year = today.getFullYear() - parseInt(value, 10);
    const quickViewDate = new Date(year, 0, 1); // Jan 1st of calculated year
    setSelected(quickViewDate);
    onDateChange?.(quickViewDate);
    setDatePickerVisible(false);
  };

  const isLabelActive = selected != null;

  useOutsideClick({
    ref: outSideClick,
    handler: () => isDatePickerVisible && setDatePickerVisible(false),
  });

  const displayValue =
    viewMode === 'monthYear'
      ? selectedMonth !== null && selectedYear
        ? `${moment().month(selectedMonth).format('MMMM')} ${selectedYear}`
        : ''
      : viewMode === 'quickView'
      ? moment(selected).format('YYYY')
      : selected
      ? moment(selected).format('DD-MM-yyyy')
      : '';

  return (
    <FormControl position="relative" width={dateWidth} ref={outSideClick}>
      <InputGroup borderRadius="0">
        <Input
          sx={{ ...inputStyles, ...datePickerInputStyle }}
          type="text"
          id={id}
          value={displayValue}
          onClick={handleInputClick}
          readOnly
          placeholder={!isLabelActive ? placeholderProp : ''}
          p={isLabelActive ? '12px 10px 0 10px' : '0px 10px 0 10px'}
          paddingRight="20px"
        />
        <InputRightElement
          h="100%"
          children={
            <Image
              mr="8px"
              cursor="pointer"
              onClick={handleInputClick}
              w="15px"
              src="/assets/imgs/double-arrow.png"
              alt=""
            />
          }
        />
      </InputGroup>
      <FormLabel
        sx={{
          ...activeLabelStyles,
          position: 'absolute',
          top: '25px',
          pointerEvents: 'none',
          opacity: isLabelActive ? 0.5 : 0,
          zIndex: isLabelActive ? 1 : -1,
          transition: 'all 0.2s ease-out',
          left: '6px',
        }}
      >
        {placeholderProp}
      </FormLabel>
      {isDatePickerVisible && (
        <Box
          position="absolute"
          borderRadius="0 0 15px 15px"
          zIndex="9999"
          bg="white"
          boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1)"
          transform="scale(0.9)"
          right="-18px"
          top="32px"
        >
          <Select onChange={e => setViewMode(e.target.value)} value={viewMode}>
            <option value="date">Date</option>
            <option value="monthYear">Month/Year</option>
            <option value="quickView">Quick View</option>
          </Select>
          {viewMode === 'monthYear' && (
            <Box>
              <Select onChange={handleMonthChange} placeholder="Month" value={selectedMonth || ''}>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={i}>
                    {moment().month(i).format('MMMM')}
                  </option>
                ))}
              </Select>
              <Select onChange={handleYearChange} placeholder="Year" value={selectedYear || ''}>
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </Box>
          )}
          {viewMode === 'date' && (
            <DayPicker
              mode="single"
              selected={selected}
              fromYear={1901}
              style={{ marginTop: '0.5rem' }}
              toYear={2060}
              captionLayout="dropdown-buttons"
              onSelect={handleDateSelect}
              disabled={disableDates}
            />
          )}
          {viewMode === 'quickView' && (
            <RadioGroup onChange={handleQuickViewChange}>
              <Stack>
                <Radio value="1">Less than 1 year</Radio>
                <Radio value="5">Less than 5 years</Radio>
                <Radio value="10">Less than 10 years</Radio>
                <Radio value="20">Less than 20 years</Radio>
                <Radio value="25">More than 20 years</Radio>
              </Stack>
            </RadioGroup>
          )}
        </Box>
      )}
    </FormControl>
  );
}

export default DatePickerQuick;
