import {
  Flex,
  Box,
  IconButton,
  Image,
  useColorMode,
  Spacer,
  Switch,
  Text,
} from "@chakra-ui/react";
import { updatedoctoravailability } from "../../middleware/services/home";
import React, { useEffect, useState } from "react";
import { getProfileforhome } from "../../middleware/services/profile";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import GPUGenericChatbot from '../generic-chatbot/gpuIndex';
import GenericChatbot from '../generic-chatbot';
import {getIsDoctor, getLoginID, IsDoctorAvailable } from "../../auth";


function SecondaryNav({
  defaultNavBody,
  withBack,
  withBackBody,
  onBack,
  showBack = false,
  goBack,
  isAvailable = true,
  isSwitchChecked = false,
  onSwitchChange,
  isDark = false,
  customHeader,
  nextConsult = true,
}) {


  const [shouldCloseSocket, setShouldCloseSocket] = useState(false);

  const closeChatBotOnEndVisit = () => {
    setShouldCloseSocket(true);
  };

  const resetCloseSocketCommand = () => {
    setShouldCloseSocket(false);
  };

  const useGPUChatbot = !!navigator.gpu;

  const [DoctorAvailability, setDoctorAvailability] = useState("");
  const [doctorId, setDoctorId] = useState(getLoginID());
  const [isDoctor, setIsDoctor]  = useState(1)
  const { isLoading, showLoader, dismissLoader } = useLoader();

  // useEffect(() => {
  //   getDoctorProfile();
  // }, []);
  const isavailable = IsDoctorAvailable();
  const currentdoctor = getIsDoctor();

  const switchclicked = async (e) => {
    const isChecked = e.target.checked;
    let input = { doctorid: "", isavailable: "" };
    // Do something based on the checked status
    if (isChecked) {
      // Switch is checked
      input.isavailable = "1";
    } else {
      // Switch is not checked
      input.isavailable = "0";
    }
    const result = await updatedoctoravailability(input);
    if (result.output.success === 1) {
      getDoctorProfile();
      var doctorDetails = localStorage.getItem("LoginInfo")
      if(doctorDetails){  
      var formateddata = JSON.parse(doctorDetails)
      const isavail = parseInt(input.isavailable)
      const updatedDoctorDetails = { ...formateddata, isavailable: isavail };
      localStorage.setItem('LoginInfo', JSON.stringify(updatedDoctorDetails));
      }
    }
  };
  var inputForGet = {};
  const getDoctorProfile = async () => {
    //showLoader();
    try {
      var result = await getProfileforhome(inputForGet);
      if (result && result.ProfileOutput) {
        setDoctorAvailability(result.ProfileOutput);
        setIsDoctor(result.ProfileOutput.isdoctor)
      }
      //dismissLoader();
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile");
    }
  };

  return (
    <Flex
      as="nav"
      align="center"
      alignItems="center"
      justify="space-between"
      wrap="wrap"
      bg="#FFFFFF"
      color="white"
      // h="5rem"
      p="1rem 1.5rem"
      borderBottom="1px solid #E6E7E9"
    >
      {isLoading && <Loader />}

      {/* Back button handling */}
      {showBack && onBack && (
        <Image
          mr="4px"
          src="/assets/svgs/left-tab-icon.svg"
          onClick={onBack}
          cursor="pointer"
        />
      )}

      {customHeader && (
        <Box>
          <Text fontSize="1.25rem" fontWeight="600" color="black">
            {customHeader}
          </Text>
        </Box>
      )}
 {withBack && (
        <Box as="button" display="flex" flexDirection="row" alignItems="center" gap="10px" sx={{bg: "none", _hover: {bg:"none"}}} onClick={goBack}>
          {/* <Box> */}
            <Image w="15px" mr="5px" src="/assets/imgs/arrow_back.png"/>
            <Box as="h1" color="black" fontSize="20px">{withBack}</Box>
          {/* </Box> */}
        </Box>
      )}
      <Spacer />

      {defaultNavBody}
      {withBackBody}

      {DoctorAvailability && DoctorAvailability.nextconsultationin && (
        <Box>
          <Box
            as="button"
            display="flex"
            alignItems="center"
            bg="transparent"
            p="0.5rem 0.75rem"
            h="2.5rem"
            gap="0.625rem"
            border="1px solid #E6E7E9"
            borderRadius=".625rem"
          >
            <Image src="/assets/svgs/home-calendar.svg" />
            <Text fontSize="0.87rem" fontWeight="500" color="#384052">
              Next consultation in {DoctorAvailability.nextconsultationin}
            </Text>
            <Image src="/assets/imgs/right-icon.png" />
          </Box>
        </Box>
      )}

    <Box display="flex" alignItems="center" flexWrap='nowrap'>
      {useGPUChatbot ? (
        <GPUGenericChatbot
          closeSocketCommand={shouldCloseSocket}
          resetCloseSocketCommand={resetCloseSocketCommand}
          doctorId={doctorId}
          height="100%"
        />
      ) : (
        <GenericChatbot
          closeSocketCommand={shouldCloseSocket}
          resetCloseSocketCommand={resetCloseSocketCommand}
          doctorId={doctorId}
          height="100%"
        />
      )}
      
      {currentdoctor && (
        <Box ml="0.75rem">
          <Box
            as="button"
            display="flex"
            alignItems="center"
            bg="transparent"
            p="0.5rem 0.75rem"
            h="2.5rem"
            gap="0.625rem"
            border="1px solid #E6E7E9"
            borderRadius=".625rem"
          >
            {isavailable === 1 ? (
              <>
                <Image src="/assets/svgs/online-circle.svg" />
                <Text fontSize="0.87rem" fontWeight="500" color="#384052">
                  Available
                </Text>
                <Switch
                  colorScheme="green"
                  onChange={(e) => switchclicked(e)}
                  defaultChecked
                />
              </>
            ) : (
              <>
                <Text fontSize="0.87rem" fontWeight="500" color="#384052">
                  Unavailable
                </Text>
                <Switch
                  colorScheme="green"
                  onChange={(e) => switchclicked(e)}
                />
              </>
            )}
          </Box>
        </Box>
      )}
      </Box>

    </Flex>
  );
}

SecondaryNav.defaultProps = {
  defaultHeader: null,
  defaultSecondayHeader: null,
  defaultNavBody: null,
  withBackBody: null,
  withBack: null,
  onBack: null,
};

export default SecondaryNav;
