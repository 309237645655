import  FetchData, { } from '../../client';
import  formapimodalreports from '../../domains/reports/reports';
import {getBranchId, getLoginID}  from '../../../auth';

const FetchReportsData = async (inputData, type, activeTab, selecteddate) => {
  // console.log('selectedDate SErvices: ', selecteddate)
  // console.log('type SErvices: ', type)
  // console.log('activeTab SErvices: ', activeTab)
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdoctorreports", inputData);
    // console.log('result.output:', result.output)
    if (result.output) {
    var finalOutput = formapimodalreports(result.output, type, activeTab, selecteddate)
    }
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchExcelReportsData = async (inputData, type) => {
  try {
    inputData.doctorid = getLoginID();
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdoctorreports", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export {FetchReportsData, FetchExcelReportsData};
