import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Divider,
  Icon,
  Text,
  Progress as ChakraProgress,
  Image,
} from "@chakra-ui/react";

import { BiMicrophone, BiSolidMicrophoneOff } from "react-icons/bi";
import { BiMicrophoneOff } from "react-icons/bi";
import DotElasticAnimation from "../../../dot-listening";
import Progress from "../../../chatbot/audio/progress";
import AudioRecorder from "../../../chatbot/audio/chatAudioRecorder";
import gpuConstants from "../../../chatbot/audio/gpuConstants";

export const AudioSource = {
  URL: "URL",
  FILE: "FILE",
  RECORDING: "RECORDING",
};


export function CompatibilityGPUChatAudioManager(props) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioData, setAudioData] = useState(undefined);
  const [progress, setProgress] = useState(undefined);


  useEffect(() => {
    let timer;
  
    if (props.step === 0 && isRecording === true) {
    //   handleMicClick(); // Turn on recording
      timer = setTimeout(() => {
        handleMicClick(); // Turn off recording after 5 seconds
      }, 5000);
    } else if (props.step === 1 && isRecording === true) {
    //   handleMicClick(); // Turn on recording
      timer = setTimeout(() => {
        handleMicClick(); // Turn off recording after 30 seconds
      }, 30000);
    }
  
    return () => {
      if (timer) clearTimeout(timer); // Cleanup any existing timer
    };
  }, [props.step, isRecording]);
  

  const isAudioLoading = progress !== undefined;

  const resetAudio = () => {
    setAudioData(undefined);
  };

  const setAudioFromRecording = async (data) => {
    resetAudio();
    setProgress(0);
    const blobUrl = URL.createObjectURL(data);
    const fileReader = new FileReader();
    fileReader.onprogress = (event) => {
      setProgress(event.loaded / event.total || 0);
    };
    fileReader.onloadend = async () => {
      const audioCTX = new AudioContext({
        sampleRate: gpuConstants.SAMPLING_RATE,
      });
      const arrayBuffer = fileReader.result;
      const decoded = await audioCTX.decodeAudioData(arrayBuffer);
      setProgress(undefined);
      setAudioData({
        buffer: decoded,
        url: blobUrl,
        source: AudioSource.RECORDING,
        mimeType: data.type,
      });
      // Start transcription automatically after recording stops
      props.transcriber.start(decoded);
    };
    fileReader.readAsArrayBuffer(data);
  };

//   const handleMicClick = () => {
//     console.log("triggered");
//     setIsRecording((prevIsRecording) => !prevIsRecording);
//     isRecording
//       ? props.setPlaceholder("Processing ...")
//       : props.setPlaceholder("Listening ...");
//     props.setTyping(true); // it is disabled later when transcription appears
//   };
    const handleMicClick = () => {
      if (isRecording) {
        console.log("turning off")
        setIsRecording(false);
        props.setTyping(false);
        props.setPlaceholder("Processing ...");
        props.stopRecording()
      } else {
        console.log("turning on")
        setIsRecording(true);
        props.setTyping(true);
        props.setPlaceholder("Listening ...");
        props.startRecording()
      }
    };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <HStack
        // spacing={2}
        // py={2}
        w="full"
        // px={2}
      >
        {isRecording ? (
          <Box onClick={handleMicClick} cursor="pointer">
            <DotElasticAnimation />
          </Box>
        ) : (
          <Image
            onClick={handleMicClick}
            boxSize="30px"
            cursor="pointer"
            src="/assets/svgs/un-mute.svg"
          />
        )}
        {/* <VerticalBar /> */}
      </HStack>
      {audioData && (
        <>
          {/* <AudioPlayer audioUrl={audioData.url} mimeType={audioData.mimeType} /> */}
          {props.transcriber.progressItems.length > 0 && (
            <Box position="relative" zIndex={10} p={4} w="full">
              <Text mb={2}>Loading AIRA...</Text>
              <VStack spacing={2}>
                {props.transcriber.progressItems.map((data) => (
                  <Box key={data.file} w="full">
                    <Progress text={data.file} percentage={data.progress} />
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </>
      )}
      {isAudioLoading && <AudioDataBar progress={progress} />}
      {/* Pass isRecording state to AudioRecorder */}
      <AudioRecorder
        recording={isRecording} // Pass the recording state as a prop
        onRecordingComplete={setAudioFromRecording}
      />
    </Box>
  );
}

function AudioDataBar(props) {
  return <ProgressBar progress={`${Math.round(props.progress * 100)}%`} />;
}

function ProgressBar(props) {
  return (
    <ChakraProgress
      value={props.progress}
      size="xs"
      colorScheme="blue"
      borderRadius="full"
      w="full"
      transition="all 0.1s ease"
    />
  );
}

