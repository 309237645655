import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Textarea, VStack, Image } from "@chakra-ui/react";
import { GetDoctorNotes } from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import Loader from "../../loader/loader";
import useLoader from "../../loader/loaderstates";
import moment from "moment";
import { AddMessage } from "../../../middleware/services/messages";

const VirtualChatDisplay = ({
  doctorId,
  patientId,
  appointmentId,
  patientName,
  showSuccessToast,
  showErrorToast,
}) => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const [askADoctorAIRAChats, setAskADoctorAIRAChats] = useState([]);

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };

    showLoader();
    try {
      const result = await GetDoctorNotes(inputToFetch.input);
      const data = result?.output?.data;

      setAskADoctorAIRAChats(
        data?.virtualappointmentchatresults
          ? data?.virtualappointmentchatresults
          : []
      );
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotes();
  }, []);

  const handleInput = (e) => {
    setMessageValue(e.target.value);
  };

  const [messageValue, setMessageValue] = useState("");
  const textareaRef = useRef(null);
  const chatContainerRef = useRef(null);
  const sendmessage = async () => {
    try {
      //showLoader()
      //const value = messageRef.current.textContent;
      const todayDateTimeUTC = moment.utc();
      const todayDateTimeLocal = todayDateTimeUTC.local();
      const formattedTime = todayDateTimeLocal.format("hh:mm A");
      const fullDateTime = todayDateTimeLocal.format();
      if (messageValue === "") {
        // showErrorToast("Please type something")
        showErrorToast("Please Enter a message");
      } else {
        const newMessage = {
          doctorid: doctorId,
          patientid: patientId,
          appointmentid: appointmentId,
          isvirtualvisit: 1,
          chat: messageValue,
          ispatient: "0",
          chattype: "text",
          formattedtime: formattedTime,
          createddate: fullDateTime,
        };

        const result = await AddMessage(newMessage);
        //console.log(result)
        if (result) {
          // Update the chat by fetching doctor data
          fetchDoctorNotes();
          setMessageValue("");
        } else {
          console.error("Failed to send message. Result is null.");
        }
      }
      //dismissLoader()
    } catch (error) {
      console.error("Error in home:", error);
    }
  };

  useEffect(() => {
      if (textareaRef.current) {
        // console.log("A")
        if (messageValue === "") {
          // console.log("C")
          textareaRef.current.style.height = "40px"; // Reset height when input is empty
          chatContainerRef.current.style.height = "calc(55vh - 40px)";
          // textareaRef.current.scrollTop = 0;
        } else {
          // console.log("D")
          textareaRef.current.style.height = "auto"; // Reset the height to auto to calculate new height
          textareaRef.current.style.height = `${Math.min(
            textareaRef.current.scrollHeight || 40,
            65
          )}px`; // Adjust height based on content
          chatContainerRef.current.style.height = `calc(55vh - ${Math.min(
            textareaRef.current?.scrollHeight || textareaRef.current?.height,
            65
          )}px)`
        }
      }
      // else {
      //   console.log("B")
      // }
    }, [messageValue]);

  return (
    <>
      <Box
        w="full"
        maxW="800px"
        mx="auto"
        p={4}
        bg="gray.50"
        borderRadius="lg"
        shadow="md"
        height={`calc(55vh - ${Math.min(
          textareaRef.current?.scrollHeight || textareaRef.current?.height,
          65
        )}px)`}
        ref={chatContainerRef}
        overflowY="auto"
      >
        {isLoading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="10"
            bg="rgba(255, 255, 255, 0.8)"
          >
            <Loader miniLoader={true} />
          </Box>
        )}
        <VStack spacing={4} align="stretch" 
          
        >
          {askADoctorAIRAChats.map((message, index) => {
            return (
              <React.Fragment key={index}>
                {/* Question Section */}
                <Flex justify="flex-end">
                  <Box
                    bg="green.100"
                    color="green.800"
                    px={4}
                    py={2}
                    borderRadius="lg"
                    maxW="70%"
                    textAlign="left"
                    ml="auto"
                  >
                    <Text fontSize="xs" color="green.500">
                      AIRA
                    </Text>
                    <Text fontSize="sm">{message.question}</Text>
                  </Box>
                </Flex>

                {/* Answer Section */}
                {message.answer && (
                  <Flex justify="flex-start">
                    <Box
                      bg="blue.100"
                      color="blue.800"
                      px={4}
                      py={2}
                      borderRadius="lg"
                      maxW="70%"
                      textAlign="left"
                      mr="auto"
                    >
                      <Text fontSize="xs" color="blue.500">
                        {patientName}
                      </Text>
                      <Text fontSize="sm">{message.answer}</Text>
                    </Box>
                  </Flex>
                )}
              </React.Fragment>
            );
          })}
        </VStack>
      </Box>
      <Box w="100%">
        {/* <InputGroup> */}
        <Box
          tabIndex="-1"
          w="100%"
          mt="1rem"
          mb="1rem"
          bg="#FCFCFD"
          border="1px solid #E6E7E9"
          borderRadius="1rem"
          display="flex"
          alignItems="flex-end"
        >
          <Textarea
            ref={textareaRef}
            p='10px'
            value={messageValue} // Use inputValue or fallback to voiceInput
            onChange={handleInput}
            // onChange={(e) => {
            //   e.target.style.height = "auto"; // Reset the height
            //   e.target.style.height = `${Math.min(
            //     e.target.scrollHeight,
            //     65
            //   )}px`; // Adjust height dynamically
            //   setMessageValue(e.target.value);
            // }}
            placeholder={"Type your message here..."}
            bg="white"
            color="black"
            resize="none"
            overflowY="auto"
            lineHeight='normal'
            maxH="65px"
            minHeight="40px"
            w="100%"
            // border="1px solid #d7d7d7"
            // _active='1px solid #d7d7d7'
            borderRadius='1rem'
            border="none"
            focusBorderColor="transparent"
            // _focusVisible='1px solid #d7d7d7'
          />
          {/* <Textarea
            key={activechatbox}
            ref={messageRef}
            value={messageValue}
            onKeyDown={handlemessageKeyPress}
            onInput={handleInputChange}
            className="chatInput"
            onChange={handleInput}
            height={chatInputHeight}
            minH="unset"
            maxH="100px"
            borderRadius="1rem"
            overflowY="auto"
            border="none"
            resize="none"
            color={!isFocused && isEmpty ? '#888888' : '#061027'}
            _focus={{ boxShadow: "none", outline: "none" }}
            placeholder={"Type your message here..."}
            disabled={
              typing || (actionAccess && !actionAccess.create) ? true : false
            }
          /> */}
          <Box
            as="button"
            // disabled={actionAccess && !actionAccess.create ? true : false}
            onClick={sendmessage}
            // bg={actionAccess && !actionAccess.create ? "#E6E7E9" : "#0B0B0D"}
            bg={"#E6E7E9"}
            w="2rem"
            h="2rem"
            borderRadius="full"
            p="10px"
            m="5px 10px 5px 5px"
            userSelect="none"
          >
            <Image src="/assets/svgs/send-button.svg" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VirtualChatDisplay;
