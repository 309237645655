import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Checkbox,
  Text,
  Flex,
  Stack,
  InputGroup,
  IconButton,
  Input,
  Image,
  RadioGroup,
  Radio,
  Divider,
  CheckboxGroup,
  Button,
  Spacer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Textarea,
} from "@chakra-ui/react";
import PrimaryInput from "../../primary-input";
import DatePicker from "../../date-picker";
import DropdownInput from "../../dropdown-input";
import MiniDropdownInput from "../../mini-dropdown";
import moment from "moment";
import {
  GetDoctorNotes,
  updateDoctorNotes,
  doctorUpdatePatientMedicine,
  GetDoctorFavMedicine,
  GetAIMedicineComposition,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import { COLORS } from "../../styles/colors";
import PrimaryButton from "../../primary-button";
import NoDataScreen from "../no-data-screen";
import SearchBar from "../../search-bar";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import TypingLoader from "../../typing-loader";
import AiModal from "../../ai-modal";
import {
  FetchMedicineAIAnswer,
  FetchMedicineAIPrompts,
  FetchMyHealthAIData,
  DetectDrugInteractions,
  AIAlternativeMedicines,
} from "../../../middleware/services/visits/aicalls";
import AlertToaster from "../../toaster/AlertToaster";
import DynamicModal from "../../dynamic-modal";
import { FetchMedicineList } from "../../../middleware/services/billing";
import { useScreenType } from "../../../auth";
import { GiMedicines } from "react-icons/gi";
import DynamicModalForTwo from "./dynamic-modal-for-two";

const PrescriptionComponent = forwardRef(
  (
    {
      patientId,
      patientname,
      patientgender,
      patientage,
      doctorId,
      appointmentId,
      showSuccessToast,
      showErrorToast,
      fetchDoctorNotes,
      addPrescription,
    },
    ref
  ) => {
    //-------------Medicines Inventory

    const [medicineList, setMedicineList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showMedicineNamesDropdown, setShowMedicineNamesDropdown] =
      useState(-1);
    const [boolValForSuggestions, setBoolValForSuggestions] = useState(true);
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
    const suggestionsMenuRef = useRef(null);

    const [QuantityInStrip, setQuantityInStrip] = useState(0);

    //new states
    
    const [sos, setSOS] = useState(null);
    const [customMessage, setCustomMessage] = useState(null);

    useEffect(() => {
      fetchMedicines();
    }, []);

    const fetchMedicines = async () => {
      try {
        const result = await FetchMedicineList({ doctorId });
        if (result.output.data.length > 0) {
          setMedicineList(result.output.data);
        }
        dismissLoader();
      } catch (error) {
        console.error("Error in home:", error);
      }
    };

    const filterMedicineNames = (medicine) => {
      const filtered = medicineList.filter((obj) =>
        obj.productname?.toLowerCase().includes(medicine.toLowerCase())
      );
      setSuggestions(filtered);
      setBoolValForSuggestions(!boolValForSuggestions);
    };

    // const handleMedicineNameChange = (name, index) => {
    //   console.log(name, "name");
    //   const newRows = [...rows];
    //   newRows[index].medicinename = name;
    //   if (!name) {
    //     newRows[index] = { ...initialRow }; // Reset the entire row if medicine name is cleared
    //   }
    //   setRows(newRows);
    //   if (name) {
    //     filterMedicineNames(name);
    //     setShowMedicineNamesDropdown(index);
    //     setBoolValForSuggestions(!boolValForSuggestions);
    //   } else {
    //     setSuggestions([]);
    //     setBoolValForSuggestions(!boolValForSuggestions);
    //     setShowMedicineNamesDropdown(-1);
    //   }
    // };
    const handleSelect = (suggestion, index) => {
      // handleNewMedicineChange(
      //   "medicinename",
      //   suggestion.productname + " (" + suggestion.remainingquantity + " Qty)"
      // );
      handleNewMedicineChange("medicinename", suggestion.productname);
      handleNewMedicineChange("composition", suggestion.chemicalcomposition);
      handleNewMedicineChange("inventoryid", suggestion.inventoryid);
      // console.log(suggestion);

      setSuggestions([]);
      setBoolValForSuggestions(!boolValForSuggestions);
      setShowMedicineNamesDropdown(-1);
    };

    const handleEditSelect = (suggestion, index) => {
      // handleEditMedicineChange(
      //   "medicinename",
      //   suggestion.productname + " (" + suggestion.remainingquantity + " Qty)"
      // );
      handleEditMedicineChange("medicinename", suggestion.productname);
      handleEditMedicineChange("inventoryid", suggestion.inventoryid);
      setSuggestions([]);
      setBoolValForSuggestions(!boolValForSuggestions);
      setShowMedicineNamesDropdown(-1);
    };

    const handleKeyDown = (e, index) => {
      if (e.key === "ArrowDown") {
        setFocusedItemIndex(
          (prevIndex) => (prevIndex + 1) % suggestions.length
        );
        const itemHeight =
          suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
        if (suggestionsMenuRef.current) {
          suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
        }
      } else if (e.key === "ArrowUp") {
        setFocusedItemIndex((prevIndex) =>
          prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
        );
        const itemHeight =
          suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
        if (suggestionsMenuRef.current) {
          suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
        }
      } else if (e.key === "Enter" && focusedItemIndex !== -1) {
        const selectedItem = suggestions[focusedItemIndex];
        handleSelect(selectedItem, index);
      }
    };

    const handleEditKeyDown = (e, index) => {
      if (e.key === "ArrowDown") {
        setFocusedItemIndex(
          (prevIndex) => (prevIndex + 1) % suggestions.length
        );
        const itemHeight =
          suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
        if (suggestionsMenuRef.current) {
          suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
        }
      } else if (e.key === "ArrowUp") {
        setFocusedItemIndex((prevIndex) =>
          prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
        );
        const itemHeight =
          suggestionsMenuRef.current?.children[0]?.clientHeight || 0;
        if (suggestionsMenuRef.current) {
          suggestionsMenuRef.current.scrollTop = focusedItemIndex * itemHeight;
        }
      } else if (e.key === "Enter" && focusedItemIndex !== -1) {
        const selectedItem = suggestions[focusedItemIndex];
        handleEditSelect(selectedItem, index);
      }
    };

    //-------------AI Insights
    const chatContainerRef = useRef(null);
    const [aianswers, setAIAnswers] = useState([]);
    const [remainingquestions, setRemainingQuestions] = useState([]);
    const [aiquestion, setAIAnalysis] = useState(null);
    const [AiInput, setAiInput] = useState(null);
    const [initialRender, setInitialRender] = useState(true);
    const [animate, setAnimate] = useState(false);
    const [showAIModal, setShowAIModal] = useState(true);

    const AIChatModel = useRef();
    const drugInteractionAlertModal = useRef();

    //------------------alternative medicine
    const aiRecommendMedicineRef = useRef();

    const [recommendMedicinesAiResponse, setRecommendMedicinesAiResponse] =
      useState([]);

    const [
      selectedRecommendedMedicinesAiResponses,
      setSelectedRecommendedMedicinesAiResponses,
    ] = useState([]);

    const handleRecommendedMedicinesCheckboxChange = (index) => {
      setSelectedRecommendedMedicinesAiResponses((prevSelected) =>
        prevSelected.includes(index)
          ? prevSelected.filter((i) => i !== index)
          : [...prevSelected, index]
      );
    };

    const fetchAlternativeMedicines = async (name, composition) => {
      showLoader();

      const Aiprompts = {
        medicinename: name,
        medicinecomposition: composition,
        patientcountry: "India",
      };
      const response = await AIAlternativeMedicines(Aiprompts);
      console.log("ai response", response);
      if (
        response &&
        response?.alternative_medicines &&
        response?.alternative_medicines?.length > 0
      ) {
        setRecommendMedicinesAiResponse(response?.alternative_medicines);
        setSelectedRecommendedMedicinesAiResponses([]);
        aiRecommendMedicineRef.current.openModal();
      } else {
        // if (noAiResponseModal.current) {
        //   noAiResponseModal.current.openModal();
        //   setTimeout(() => {
        //     if (noAiResponseModal.current) {
        //       // Check again before closing
        //       noAiResponseModal.current.closeModal();
        //     }
        //   }, 3000); // 3 seconds delay
        // }
        showErrorToast("Currently No Alternatives Available");
      }
      dismissLoader();
    };

    const saveSelectedRecommendedMedicinesToOrder = async () => {
      console.log(selectedRecommendedMedicinesAiResponses); // Logs the array of selected indices

      selectedRecommendedMedicinesAiResponses.forEach((index) => {
        const selectedMedicine = recommendMedicinesAiResponse[index]; // Get the item at the respective index

        const newMedicine = {
          medicinename: selectedMedicine?.name,
          composition: selectedMedicine?.composition,
          id: null,
          inventoryid: null,
          medicinetype: "Tablet",
          dosage: "",
          dosageunit: "mg",
          medicationstartdate: today, // Default values
          medicationenddate: fiveDaysFromToday, // Default values
          frequency: 1, // Default values
          morning: 1, // Default values
          afternoon: 0,
          evening: 0,
          night: 0,
          prescribedby: "",
          hospitalname: "",
          beforefood: 1, // Default values
          afterfood: 0,
          issaved: false,

          
      custommessage:null,
      sos:null
        };

        setSelectedMedicines((prev) => [...prev, newMedicine]); // Add the new medicine to the list
        DetectInteractionsUsingAI(newMedicine.medicinename); // Call the AI detection function
      });

      // Reset the new medicine form
      setNewMedicine({
        id: null,
        inventoryid: null,
        medicinename: "",
        composition: "",
        medicinetype: "Tablet",
        dosage: "",
        dosageunit: "mg",
        medicationstartdate: today, // Default values
        medicationenddate: fiveDaysFromToday, // Default values
        frequency: 1, // Default values
        morning: 1, // Default values
        afternoon: 0,
        evening: 0,
        night: 0,
        prescribedby: "",
        hospitalname: "",
        beforefood: 1, // Default values
        afterfood: 0,
        issaved: false,

        
      });
    };

    useEffect(() => {
      if (animate) {
        const timer = setTimeout(() => {
          setAnimate(false);
        }, 500);
        return () => clearTimeout(timer);
      }
    }, [animate]);

    useEffect(() => {
      // Scroll to bottom whenever aianswers changes
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, [aianswers]);
    const fetchAiDoctorPrompts = async (medicinename) => {
      showLoader();
      setRemainingQuestions([]);
      setAiInput(null);
      setAIAnswers([]);
      setInitialRender(true);
      const result = await FetchMyHealthAIData(
        {},
        patientId,
        patientname,
        patientgender,
        patientage
      );
      // console.log(result);
      let Aiprompts = { ...result, medicinename };
      setAiInput(Aiprompts);
      const response = await FetchMedicineAIPrompts(Aiprompts);
      if (response.generated_question) {
        setAIAnalysis(response);
        setRemainingQuestions(response.generated_question);
        AIChatModel.current.openRight();
      }
      dismissLoader();
    };

    const GetAIAnswer = async (prompt) => {
      // Display question immediately
      const newQuestion = { question: prompt, answer: "" };
      setAIAnswers((prevState) => [...prevState, newQuestion]);

      // Remove the question from remaining questions immediately
      setRemainingQuestions((prevState) =>
        prevState.filter((question) => question.value !== prompt)
      );

      // Trigger animation
      setAnimate(true);

      // Set initialRender to false after the first question is clicked
      setInitialRender(false);
      let AichatInput = { ...AiInput, query: prompt };

      const response = await FetchMedicineAIAnswer(AichatInput);

      if (response) {
        // Replace "typing" with actual answer
        setAIAnswers((prevState) =>
          prevState.map((item) =>
            item.question === prompt && item.answer === ""
              ? { question: item.question, answer: response.answer }
              : item
          )
        );
      }
    };

    const [drugInteractionAlerts, setDrugInteractionAlerts] = useState([]);

    const { showAlertToast } = AlertToaster();

    const DetectInteractionsUsingAI = async (medicinestocheck) => {
      setDrugInteractionAlerts([]);
      const result = await FetchMyHealthAIData(
        {},
        patientId,
        patientname,
        patientgender,
        patientage
      );
      let AiInput = { ...result, medicinestocheck };

      const isValidArray = (arr) => Array.isArray(arr) && arr.length > 0;
      AiInput.currentmedications = [
        ...(isValidArray(patientCurrentMedicinesForDoctor)
          ? patientCurrentMedicinesForDoctor
              .filter((med) => med.isactive !== 0) // Filter out items with isactive === 0
              .map((med) => ({ medicinename: med.medicinename }))
          : []),
        ...(isValidArray(selectedMedicines)
          ? selectedMedicines
              .filter((med) => med.isactive !== 0) // Filter out items with isactive === 0
              .map((med) => ({ medicinename: med.medicinename }))
          : []),
      ];
      console.log("medicine to check", medicinestocheck);
      console.log("medicine list", AiInput?.currentmedications);
      const response = await DetectDrugInteractions(AiInput);
      // console.log("output", response);
      if (response.interactions && response.interactions.length > 0) {
        setDrugInteractionAlerts(response.interactions);

        // response.interactions.forEach((interaction) => {
        //   showAlertToast(interaction.message);
        // });
        if (drugInteractionAlertModal.current) {
          drugInteractionAlertModal.current.openModal();
        } else {
          // console.log("Modal unavailable");
        }
      }
    };

    //--------------------other functionality
    const { isLoading, showLoader, dismissLoader } = useLoader();

    const [selectedTab, setSelectedTab] = useState("recurrent");
    const [selectedMedicines, setSelectedMedicines] = useState([]);
    const [showAddCard, setShowAddCard] = useState(false);

    const today = moment().format("DD-MM-YYYY");
    const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");

    const [newMedicine, setNewMedicine] = useState({
      id: null,
      inventoryid: null,
      medicinename: "",
      composition: "",
      medicinetype: "Tablet",
      dosage: "",
      dosageunit: "mg",
      medicationstartdate: today, // SETTING DEFAULTS
      medicationenddate: fiveDaysFromToday, // SETTING DEFAULTS
      frequency: 1, // SETTING DEFAULTS
      morning: 1, // SETTING DEFAULTS
      afternoon: 0,
      evening: 0,
      night: 0,
      prescribedby: "",
      hospitalname: "",
      beforefood: 1, // SETTING DEFAULTS
      afterfood: 0,
      issaved: false,

      // new inputs

      frequencyduration: null,
      routeofadministration: null,
      timingofinjection: null,
      quantitytobeapplied: null,
      areaofapplication: null,
      diluent: null,
      additives: null,

      rateofinfusionvalue: null,
      rateofinfusionunit: "ml/hr",
      duration: null,

      custommessage:null,
      sos:null
    });

    const [editMedicine, setEditMedicine] = useState(null);
    const [editCurrentMedicine, setEditCurrentMedicine] = useState(null);

    //-----Fetch Current and Recent Medicines

    const [currentMedicines, setCurrentMedicines] = useState([]);
    const [recentMedicines, setRecentMedicines] = useState([]);
    const [savedMedicines, setSavedMedicines] = useState([]);
    const [
      patientCurrentMedicinesForDoctor,
      setPatientCurrentMedicinesForDoctor,
    ] = useState([]);
    const [search, setSearch] = useState(""); // for recent medicine
    const screenType = useScreenType();
    const [doctorName, setDoctorName] = useState("");

    function processMedicationsData(medicationArray) {
      if (!medicationArray || medicationArray.length === 0) {
        return [];
      }

      const today = moment().format("DD-MM-YYYY");
      const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");

      return medicationArray.map((med) => ({
        id: med.id,
        inventoryid: med?.inventoryid,
        remainingquantity: med?.remainingquantity ? med.remainingquantity : 0,
        medicinename: med.medicinename,
        medicinetype: med.medicinetype,
        dosage: med.dosage,
        dosageunit: med.dosageunit,
        // medicationstartdate: med.medicationstartdate
        //   ? moment(med.medicationstartdate, "YYYY-MM-DD").format("DD-MM-YYYY")
        //   : today,
        medicationstartdate: today,
        // medicationenddate: med.medicationenddate
        //   ? moment(med.medicationenddate, "YYYY-MM-DD").format("DD-MM-YYYY")
        //   : fiveDaysFromToday,
        medicationenddate: fiveDaysFromToday,
        duration:
          Math.abs(
            new Date(med.medicationstartdate) - new Date(med.medicationenddate)
          ) /
            (1000 * 60 * 60 * 24) +
          " days",
        frequency: med.frequency,
        morning: med.morning,
        afternoon: med.afternoon,
        evening: med.evening,
        night: med.night,
        beforefood: med.beforefood,
        afterfood: med.afterfood,
        composition: med.chemicalcomposition,
        issaved: false,

        custommessage:med.custommessage,
        sos:med.sos
      }));
    }

    function processCurrentMedicationsDataForDoctor(medicationArray) {
      if (!medicationArray || medicationArray.length === 0) {
        return [];
      }

      const today = moment().format("DD-MM-YYYY");
      const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");

      return medicationArray.map((med) => ({
        id: med.id,
        remainingquantity: med?.remainingquantity ? med.remainingquantity : 0,
        medicinename: med.medicinename,
        medicinetype: med.medicinetype,
        dosage: med.dosage,
        dosageunit: med.dosageunit,
        // medicationstartdate: med.medicationstartdate
        //   ? moment(med.medicationstartdate, "YYYY-MM-DD").format("DD-MM-YYYY")
        //   : today,
        medicationstartdate: today,
        // medicationenddate: med.medicationenddate
        //   ? moment(med.medicationenddate, "YYYY-MM-DD").format("DD-MM-YYYY")
        //   : fiveDaysFromToday,
        medicationenddate: fiveDaysFromToday,
        duration:
          Math.abs(
            new Date(med.medicationstartdate) - new Date(med.medicationenddate)
          ) /
            (1000 * 60 * 60 * 24) +
          " days",
        frequency: med.frequency,
        morning: med.morning,
        afternoon: med.afternoon,
        evening: med.evening,
        night: med.night,
        beforefood: med.beforefood,
        afterfood: med.afterfood,
        composition: med.chemicalcomposition,
        iscurrent: med.iscurrent,
        issaved: true,

        custommessage:med.custommessage,
        sos:med.sos
      }));
    }

    function processSavedMedicationsData(medicationArray) {
      if (!medicationArray || medicationArray.length === 0) {
        return [];
      }

      const today = moment().format("DD-MM-YYYY");
      const fiveDaysFromToday = moment().add(5, "days").format("DD-MM-YYYY");

      return medicationArray.map((med) => ({
        id: med.id,
        inventoryid: med?.inventoryid,
        medicinename: med.medicinename,
        medicinetype: med.medicinetype,
        dosage: med.dosage,
        dosageunit: med.dosageunit,
        medicationstartdate: med.medicationstartdate
          ? moment(med.medicationstartdate, "YYYY-MM-DD").format("DD-MM-YYYY")
          : today,
        medicationenddate: med.medicationenddate
          ? moment(med.medicationenddate, "YYYY-MM-DD").format("DD-MM-YYYY")
          : fiveDaysFromToday,
        duration:
          Math.abs(
            new Date(med.medicationstartdate) - new Date(med.medicationenddate)
          ) /
            (1000 * 60 * 60 * 24) +
          " days",
        frequency: med.frequency,
        morning: med.morning,
        afternoon: med.afternoon,
        evening: med.evening,
        night: med.night,
        beforefood: med.beforefood,
        afterfood: med.afterfood,
        composition: med.chemicalcomposition,
        isactive: 1,
        issaved: true,

        custommessage:med.custommessage,
        sos:med.sos
      }));
    }

    const fetchCurrentMedicines = async () => {
      // console.log("Function called");
      const inputToFetch = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
        },
      };
      try {
        const data = await GetDoctorNotes(inputToFetch.input);
        // console.log(data);
        // console.log("fetching patient medicines");
        setDoctorName(data?.output?.data?.info?.drname);
        setCurrentMedicines(
          processMedicationsData(data?.output?.data?.currentpatientmedicine)
        );
        // setPatientCurrentMedicinesForDoctor(
        //   processCurrentMedicationsDataForDoctor(
        //     data?.output?.data?.currentpatientmedicine
        //   )
        // );
        // console.log(currentMedicines);
        console.log("saved medicines", prescribedMedicines);
        if (!prescribedMedicines || prescribedMedicines.length === 0) {
          setPatientCurrentMedicinesForDoctor(
            processCurrentMedicationsDataForDoctor(
              data?.output?.data?.currentpatientmedicine
            )
          );
        } else {
          // console.log("removing current medicines once something is prescribed")
          setPatientCurrentMedicinesForDoctor([]);
        }
      } catch (error) {
        console.error("Error fetching Current Medications data:", error);
      }
    };

    const fetchRecentMedicines = async () => {
      // console.log("Function called");
      const inputToFetch = {
        input: {
          doctorid: doctorId,
        },
      };
      showLoader();
      try {
        const data = await GetDoctorFavMedicine(inputToFetch.input);
        setRecentMedicines(processMedicationsData(data?.output?.data));
        // console.log(recentMedicines);
      } catch (error) {
        console.error("Error fetching Recent Medications data:", error);
      } finally {
        dismissLoader();
      }
    };

    let prescribedMedicines = [];

    const fetchSavedMedicines = async () => {
      const inputToFetch = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
        },
      };

      // console.log("fetching prescribed medicines");
      showLoader();
      try {
        const data = await GetDoctorNotes(inputToFetch.input);
        // console.log(data , " data output");
        setSavedMedicines(
          processSavedMedicationsData(data.output.data?.orders?.medication)
        );
        prescribedMedicines = processSavedMedicationsData(
          data.output.data?.orders?.medication
        );
        savedMedicines.forEach((medicine) => {
          if (!selectedMedicines.includes(medicine)) {
            handleCheckboxChange(medicine);
          }
        });
        // console.log(selectedMedicines);
      } catch (error) {
        console.error("Error fetching Doctor Notes data:", error);
      } finally {
        dismissLoader();
      }
    };

    useEffect(() => {
      fetchRecentMedicines();
    }, []);

    // useEffect(() => {
    //   fetchSavedMedicines();
    //   fetchCurrentMedicines();
    //   fetchRecentMedicines();
    // }, []);

    useEffect(() => {
      fetchSavedMedicines()
        .then(() => {
          // Once fetchSavedMedicines is completed, call fetchCurrentMedicines
          return fetchCurrentMedicines();
        })
        .catch((error) => {
          // Handle any errors from either of the fetch calls
          console.error("Error occurred during fetching medicines:", error);
        });
    }, []);

    useEffect(() => {
      // console.log(savedMedicines  , " savedMedicines")
      setSelectedMedicines(savedMedicines);
    }, [savedMedicines]);

    const handleTabChange = (tab) => {
      setSelectedTab(tab);
    };

    // const handleCheckboxChange = (medicine) => {
    //   setSelectedMedicines((prev) =>
    //     prev.includes(medicine)
    //       ? prev.filter((m) => m !== medicine)
    //       : [...prev, medicine]
    //   );
    //   if (selectedTab === "recurrent") {
    //     DetectInteractionsUsingAI(medicine.medicinename);
    //   }
    // };
    let lastInteractionName = "";

    const handleCheckboxChange = (medicine) => {
      setSelectedMedicines((prev) => {
        const isAlreadySelected = prev.includes(medicine);
        const updatedMedicines = isAlreadySelected
          ? prev.filter((m) => m !== medicine)
          : [...prev, medicine];

        // Run AI only if the medicine is being added and the selected tab is "recurrent"
        if (medicine.medicinename !== lastInteractionName) {
          if (!isAlreadySelected && selectedTab === "recurrent") {
            console.log("calling interacton");
            DetectInteractionsUsingAI(medicine.medicinename);
            lastInteractionName = medicine.medicinename;
          }
        } else {
          console.log("exception occured");
        }

        return updatedMedicines;
      });
    };

    const formatQuantity = (quantity) => {
      if (quantity === 0) {
        return "Out of Stock";
      } else {
        return quantity.toLocaleString(); // This formats the number with commas
      }
    };

    const handleSelectAll = () => {
      currentMedicines.forEach((medicine) => {
        if (!selectedMedicines.includes(medicine)) {
          handleCheckboxChange(medicine);
        }
      });
    };

    const handleNewMedicineChange = (field, value) => {
      setNewMedicine((prev) => ({ ...prev, [field]: value }));
    };

    const handleAddMedicine = () => {
      if (newMedicine.medicinename === "") {
        showErrorToast("Please enter medicine name to continue");
        return;
      }
      setSelectedMedicines((prev) => [...prev, newMedicine]);
      DetectInteractionsUsingAI(newMedicine.medicinename);
      setShowAddCard(false);
      // Reset the new medicine form
      setNewMedicine({
        id: null,
        inventoryid: null,
        medicinename: "",
        composition: "",
        medicinetype: "Tablet",
        dosage: "",
        dosageunit: "mg",
        medicationstartdate: today, // SETTING DEFAULTS
        medicationenddate: fiveDaysFromToday, // SETTING DEFAULTS
        frequency: 1, // SETTING DEFAULTS
        morning: 1, // SETTING DEFAULTS
        afternoon: 0,
        evening: 0,
        night: 0,
        prescribedby: "",
        hospitalname: "",
        beforefood: 1, // SETTING DEFAULTS
        afterfood: 0,
        issaved: false,

        // new inputs

        frequencyduration: null,
        routeofadministration: null,
        timingofinjection: null,
        quantitytobeapplied: null,
        areaofapplication: null,
        diluent: null,
        additives: null,

        rateofinfusionvalue: null,
        rateofinfusionunit: "ml/hr",
        duration: null,

        custommessage:null,
      sos:null
      });

      // console.log(selectedMedicines);
    };

    const handleCancelAddMedicine = () => {
      setShowAddCard(false);
      // Reset the new medicine form
      setNewMedicine({
        id: null,
        medicinename: "",
        composition: "",
        medicinetype: "Tablet",
        dosage: "",
        dosageunit: "mg",
        medicationstartdate: today, // SETTING DEFAULTS
        medicationenddate: fiveDaysFromToday, // SETTING DEFAULTS
        frequency: 1, // SETTING DEFAULTS
        morning: 1, // SETTING DEFAULTS
        afternoon: 0,
        evening: 0,
        night: 0,
        prescribedby: "",
        hospitalname: "",
        beforefood: 1, // SETTING DEFAULTS
        afterfood: 0,
        issaved: false,

        // new inputs

        frequencyduration: null,
        routeofadministration: null,
        timingofinjection: null,
        quantitytobeapplied: null,
        areaofapplication: null,
        diluent: null,
        additives: null,

        rateofinfusionvalue: null,
        rateofinfusionunit: "ml/hr",
        duration: null,

        custommessage:null,
      sos:null
      });

      // console.log(selectedMedicines);
    };

    const handleDeleteMedicine = (medicine) => {
      setSelectedMedicines((prev) => {
        if (medicine.issaved) {
          const updatedMedicine = { ...medicine, isactive: 0 };
          // console.log("recorded to delete");
          return prev.map((m) => (m === medicine ? updatedMedicine : m));
        } else {
          return prev.filter((m) => m !== medicine);
        }
      });
    };

    const handleDeleteCurrentMedicine = (medicine) => {
      setPatientCurrentMedicinesForDoctor((prev) => {
        if (medicine.issaved) {
          const updatedMedicine = { ...medicine, isactive: 0 };
          console.log("recorded to delete");
          return prev.map((m) => (m === medicine ? updatedMedicine : m));
        } else {
          return prev.filter((m) => m !== medicine);
        }
      });
    };

    const validationOfNumberWithOnly1Decimal = (inputValue, maxLength) => {
      // Remove all characters that are not digits or a decimal point
      let cleanedValue = inputValue.replace(/[^0-9.]/g, "");

      // Remove leading decimal point if present
      if (cleanedValue.startsWith(".")) {
        cleanedValue = cleanedValue.substring(1);
      }

      // Handle multiple decimal points
      const decimalIndex = cleanedValue.indexOf(".");
      if (decimalIndex !== -1) {
        const parts = cleanedValue.split(".");
        // Keep only the first two digits after the decimal point
        cleanedValue = parts[0] + "." + parts[1].slice(0, 2);
      }

      // Enforce the max length after cleaning and truncating
      if (cleanedValue.length > maxLength) {
        cleanedValue = cleanedValue.substring(0, maxLength);
      }

      // console.log("input", inputValue);
      // console.log("cleaned", cleanedValue);

      return cleanedValue;
    };

    const handleEditMedicine = (medicine) => {
      setEditMedicine(medicine);
   };

    const handleEditCurrentMedicine = (medicine) => {
      setEditCurrentMedicine(medicine);

      // console.log("Called")
    };

    const handleEditMedicineChange = (field, value) => {
      setEditMedicine((prev) => ({ ...prev, [field]: value }));
    };

    const handleEditCurrentMedicineChange = (field, value) => {
      setEditCurrentMedicine((prev) => ({ ...prev, [field]: value }));
    };

    const handleSaveEditMedicine = () => {
      if (editMedicine.medicinename === "") {
        showErrorToast("Please enter medicine name to continue");
        return;
      }
      setSelectedMedicines((prev) =>
        prev.map((m) => (m.id === editMedicine.id ? editMedicine : m))
      );

      // console.log(selectedMedicines);
      setEditMedicine(null);
    };

    const handleSaveEditCurrentMedicine = () => {
      if (editCurrentMedicine.medicinename === "") {
        showErrorToast("Please enter medicine name to continue");
        return;
      }
      setPatientCurrentMedicinesForDoctor((prev) =>
        prev.map((m) =>
          m.id === editCurrentMedicine.id ? editCurrentMedicine : m
        )
      );

      // console.log(selectedMedicines);
      setEditCurrentMedicine(null);
    };

    const [key, setKey] = useState(0); // to rerender the composition input box
    const [compositionEditKey, setCompositionEditKey] = useState(0); // to rerender the composition input box

    const fetchComposition = async (medicineName) => {
      if (!medicineName) {
        showErrorToast("Please enter medicine name first to fetch composition");
      } else {
        const inputData = {
          input: {
            name: medicineName,
          },
        };

        // console.log(inputData.input);

        try {
          // showLoader();
          // console.log(inputData);
          const result = await GetAIMedicineComposition(inputData.input);
          // console.log(result);
          if (result) {
            showSuccessToast("Fetched Medicine composition successfully");
            handleNewMedicineChange("composition", result.chemical_composition);
            setKey((prevKey) => prevKey + 1);
            // handleNewMedicineChange("medicinetype", result.type);
          }
        } catch (error) {
          console.error("Error Fetching Medicine composition:", error);
          showErrorToast(
            "An error occurred while Fetching Medicine composition"
          );
        } finally {
          // dismissLoader();
        }

        // fetchDoctorNotes();

        // console.log("Clicked");
      }
    };

    const fetchCompositionForEdit = async (medicineName) => {
      if (!medicineName) {
        showErrorToast("Please enter medicine name first to fetch composition");
      } else {
        const inputData = {
          input: {
            name: medicineName,
          },
        };

        // console.log(inputData.input);

        try {
          // showLoader();
          // console.log(inputData);
          const result = await GetAIMedicineComposition(inputData.input);
          // console.log(result);
          if (result) {
            showSuccessToast("Fetched Medicine composition successfully");
            handleEditMedicineChange(
              "composition",
              result.chemical_composition
            );
            setCompositionEditKey((prevKey) => prevKey + 1);
            // handleNewMedicineChange("medicinetype", result.type);
          }
        } catch (error) {
          console.error("Error Fetching Medicine composition:", error);
          showErrorToast(
            "An error occurred while Fetching Medicine composition"
          );
        } finally {
          // dismissLoader();
        }

        // fetchDoctorNotes();

        // console.log("Clicked");
      }
    };

    const fetchCompositionForCurrentEdit = async (medicineName) => {
      if (!medicineName) {
        showErrorToast("Please enter medicine name first to fetch composition");
      } else {
        const inputData = {
          input: {
            name: medicineName,
          },
        };

        // console.log(inputData.input);

        try {
          // showLoader();
          // console.log(inputData);
          const result = await GetAIMedicineComposition(inputData.input);
          // console.log(result);
          if (result) {
            showSuccessToast("Fetched Medicine composition successfully");
            handleEditCurrentMedicineChange(
              "composition",
              result.chemical_composition
            );
            setCompositionEditKey((prevKey) => prevKey + 1);
            // handleNewMedicineChange("medicinetype", result.type);
          }
        } catch (error) {
          console.error("Error Fetching Medicine composition:", error);
          showErrorToast(
            "An error occurred while Fetching Medicine composition"
          );
        } finally {
          // dismissLoader();
        }

        // fetchDoctorNotes();

        // console.log("Clicked");
      }
    };

    function transformStatesToApiInput(medicationArray) {
      const today = moment().format("YYYY-MM-DD");
      const fiveDaysFromToday = moment().add(5, "days").format("YYYY-MM-DD");

      return medicationArray.map((med) => {
        const frequency =
          med.frequency ||
          (med.morning ? 1 : 0) + (med.afternoon ? 1 : 0) + (med.night ? 1 : 0);

        return {
          id: med.issaved ? med.id : null,
          inventoryid: med?.inventoryid,
          medicinename: med.medicinename,
          medicinetype: med.medicinetype,
          dosage: med.dosage,
          dosageunit: med.dosageunit,
          startdate: med.medicationstartdate
            ? moment(med.medicationstartdate, "DD-MM-YYYY").format("YYYY-MM-DD")
            : today,
          enddate: med.medicationenddate
            ? moment(med.medicationenddate, "DD-MM-YYYY").format("YYYY-MM-DD")
            : fiveDaysFromToday,
          // frequency: frequency,
          frequency:
            med.frequency && med.frequencyduration
              ? med.frequency + " " + med.frequencyduration
              : med.frequency,
          morning: med.morning,
          afternoon: med.afternoon,
          evening: med.evening,
          night: med.night,
          beforefood: med.beforefood,
          afterfood: med.afterfood,
          chemical_composition: med.composition, // uncomment it when db changes are done
          prescribedby: doctorName,
          isactive: med.issaved ? med.isactive : 1,

          routeofadministration: med.routeofadministration,
          timingofinjection: med.timingofinjection,
          quantitytobeapplied: med.quantitytobeapplied,
          areaofapplication: med.areaofapplication,
          diluent: med.diluent,
          additives: med.additives,

          rateofinfusionvalue: med.rateofinfusionvalue,
          rateofinfusionunit: med.rateofinfusionunit,
          // "duration": durationValue && durationUnit ? (durationValue + " " + durationUnit) : durationValue,
       
          
      custommessage:med.custommessage,
      sos:med.sos
        };
      });
    }

    function transformCurrentMedicationStatesToApiInput(medicationArray) {
      const today = moment().format("YYYY-MM-DD");
      const fiveDaysFromToday = moment().add(5, "days").format("YYYY-MM-DD");

      return medicationArray.map((med) => {
        const frequency =
          med.frequency ||
          (med.morning ? 1 : 0) + (med.afternoon ? 1 : 0) + (med.night ? 1 : 0);

        return {
          prescribedmedicineid: med.issaved ? med.id : null,
          medicinename: med.medicinename,
          medicinetype: med.medicinetype,
          dosage: med.dosage,
          dosageunit: med.dosageunit,
          startdate: med.medicationstartdate
            ? moment(med.medicationstartdate, "DD-MM-YYYY").format("YYYY-MM-DD")
            : today,
          enddate: med.medicationenddate
            ? moment(med.medicationenddate, "DD-MM-YYYY").format("YYYY-MM-DD")
            : fiveDaysFromToday,
          frequency: frequency,
          morning: med.morning,
          afternoon: med.afternoon,
          evening: med.evening,
          night: med.night,
          beforefood: med.beforefood,
          afterfood: med.afterfood,
          chemicalcomposition: med.composition, // uncomment it when db changes are done
          prescribedby: doctorName,
          medicineactive: med.issaved ? med.isactive : 1,
          iscurrentmedicine: med.iscurrent,

          
      custommessage:med.custommessage,
      sos:med.sos
        };
      });
    }

    function filterAndTransformCurrentMedicinesToPrescription(input) {
      // Filter the objects where "isactive" is not available or not zero
      const filtered = input.filter(
        (item) => item.isactive === undefined || item.isactive !== 0
      );
      console.log("function called");

      // Transform the filtered objects
      const transformed = filtered.map((item) => ({
        ...item,
        id: null, // Set id to null
        inventoryid: null, // Add inventoryid key with value null
        isactive: 1, // Make isactive 1 for all
        medicationstartdate: moment(
          item.medicationstartdate,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD"),
        medicationenddate: moment(item.medicationenddate, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
      }));

      return transformed;
    }

    const saveMedicineToOrders = async () => {
      // if (selectedMedicines.length === 0) {
      //   showErrorToast("Please select or add any medicines first.");
      // } else {
      const inputData = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          medicinedetail: [
            ...transformStatesToApiInput(selectedMedicines),
            ...filterAndTransformCurrentMedicinesToPrescription(
              patientCurrentMedicinesForDoctor
            ),
          ],
        },
      };

      console.log("doctor save", inputData.input);
      // console.log("doctor add", selectedMedicines);

      try {
        showLoader();
        // console.log(inputData);
        const result = await updateDoctorNotes(inputData.input);
        // console.log(result);
        if (result) {
          showSuccessToast("Medicine added to orders successfully");
        }
        addPrescription.current.closeRight();
      } catch (error) {
        console.error("Error adding medicine to orders:", error);
        showErrorToast("An error occurred while adding medicine to orders");
      } finally {
        dismissLoader();
      }

      fetchDoctorNotes();
    };
    // console.log("Clicked");
    // };

    function flipCurrentAndFilterAndTransformMedicines(input) {
      // Filter the objects where "isactive" is not available or not zero
      const filtered = input.filter(
        (item) => item.isactive === undefined || item.isactive !== 0
      );

      // Transform the filtered objects
      const transformed = filtered.map((item) => ({
        ...item,
        id: null, // Set id to null
        inventoryid: null, // Add inventoryid key with value null
      }));

      return transformed;
    }

    const saveCurrentPatientMedicineToOrders = async () => {
      const inputData = {
        input: {
          patientid: patientId,
          doctorid: doctorId,
          appointmentid: appointmentId,
          medications: flipCurrentAndFilterAndTransformMedicines(
            patientCurrentMedicinesForDoctor
          ),
        },
      };

      // console.log(inputData.input);

      console.log("patient save", inputData.input);
      // console.log("patient add", patientCurrentMedicinesForDoctor);

      try {
        // showLoader();
        console.log(inputData);
        const result = await doctorUpdatePatientMedicine(inputData.input);
        // const result = "abc"
        console.log(result);
        if (result) {
          // showSuccessToast("Medicine added to orders successfully");
        }
        addPrescription.current.closeRight();
      } catch (error) {
        console.error("Error adding medicine to orders:", error);
        showErrorToast(
          "An error occurred while updating patient current medicines."
        );
      } finally {
        // dismissLoader();
      }

      // console.log("Clicked");
    };

    useImperativeHandle(ref, () => ({
      saveMedicineToOrders,
      saveCurrentPatientMedicineToOrders,
    }));

    const [sortOption, setSortOption] = useState(""); // this is for sorting medicines on doctor fav panel

    //--------------------Quick Dates Set Options

    //----------Edit card
    const [intervalOption, setIntervalOption] = useState("Days");

    const [intervalValue, setIntervalValue] = useState(5); // default value of 5

    const calculateEndDate = () => {
      if (
        editMedicine?.medicationstartdate &&
        intervalOption &&
        intervalValue
      ) {
        const intervalMap = { Days: 1, Weeks: 7, Months: 30, Years: 365 };
        const intervalMultiplier = intervalMap[intervalOption];
        const daysToAdd = intervalMultiplier * intervalValue - 1;

        // Parse start date in DD-MM-YYYY format
        let medicationStartDate = editMedicine.medicationstartdate;

        // // Check if the date is in DD-MM-YYYY format, if not, convert it
        // console.log(medicationStartDate)
        // console.log(moment(medicationStartDate, "DD-MM-YYYY", true).isValid())
        if (!/^\d{2}-\d{2}-\d{4}$/.test(medicationStartDate)) {
          // console.log("converting")
          medicationStartDate =
            moment(medicationStartDate).format("DD-MM-YYYY");
        }
        // console.log(medicationStartDate)
        const [day, month, year] = medicationStartDate.split("-").map(Number);
        const startDate = new Date(year, month - 1, day); // JS months are 0-indexed

        startDate.setDate(startDate.getDate() + daysToAdd);

        // Format new end date as DD-MM-YYYY
        const formattedEndDate = [
          String(startDate.getDate()).padStart(2, "0"),
          String(startDate.getMonth() + 1).padStart(2, "0"),
          startDate.getFullYear(),
        ].join("-");

        // Update medicationenddate in editMedicine
        setEditMedicine((prev) => ({
          ...prev,
          medicationenddate: formattedEndDate,
        }));
      }
    };

    useEffect(() => {
      if (intervalOption && intervalValue) {
        calculateEndDate();
      }
    }, [intervalOption, intervalValue, editMedicine?.medicationstartdate]);

    const handleIntervalChange = (value) => {
      setIntervalOption(value);
    };

    const handleIntervalValueChange = (e) => {
      const value = parseInt(e.target.value, 10);
      setIntervalValue(isNaN(value) ? 0 : value); // handle NaN cases if input is empty
    };

    // const handleRadioChange = (value) => {
    //   setRadioValue(value);
    //   calculateEndDate();
    // };

    // const handleSelectIntervalChange = (e) => {
    //   setIntervalOption(e.target.value);
    //   calculateEndDate();
    // };

    //-------------New card
    const [intervalNewOption, setIntervalNewOption] = useState("Days");

    const [intervalNewValue, setIntervalNewValue] = useState(5); // default value of 5

    const calculateNewEndDate = () => {
      if (newMedicine?.medicationstartdate && intervalOption && intervalValue) {
        const intervalMap = { Days: 1, Weeks: 7, Months: 30, Years: 365 };
        const intervalMultiplier = intervalMap[intervalNewOption];
        const daysToAdd = intervalMultiplier * intervalNewValue - 1;

        // Parse start date in DD-MM-YYYY format
        let medicationStartDate = newMedicine.medicationstartdate;

        // Check if the date is in DD-MM-YYYY format, if not, convert it

        if (!/^\d{2}-\d{2}-\d{4}$/.test(medicationStartDate)) {
          medicationStartDate =
            moment(medicationStartDate).format("DD-MM-YYYY");
        }
        const [day, month, year] = medicationStartDate.split("-").map(Number);
        const startDate = new Date(year, month - 1, day); // JS months are 0-indexed

        startDate.setDate(startDate.getDate() + daysToAdd);

        // Format new end date as DD-MM-YYYY
        const formattedEndDate = [
          String(startDate.getDate()).padStart(2, "0"),
          String(startDate.getMonth() + 1).padStart(2, "0"),
          startDate.getFullYear(),
        ].join("-");

        // console.log(formattedEndDate);

        // Update medicationenddate in editMedicine
        setNewMedicine((prev) => ({
          ...prev,
          medicationenddate: formattedEndDate,
        }));
      }
    };

    useEffect(() => {
      if (intervalNewOption && intervalNewValue) {
        calculateNewEndDate();
      }
    }, [intervalNewOption, intervalNewValue, newMedicine?.medicationstartdate]);

    const handleIntervalNewChange = (value) => {
      setIntervalNewOption(value);
    };

    const handleIntervalNewValueChange = (e) => {
      const value = parseInt(e.target.value, 10);
      setIntervalNewValue(isNaN(value) ? 0 : value); // handle NaN cases if input is empty
    };

    //----------------------Current card
    const [intervalCurrentOption, setIntervalCurrentOption] = useState("Days");

    const [intervalCurrentValue, setIntervalCurrentValue] = useState(5); // default value of 5

    const calculateCurrentEndDate = () => {
      if (
        editCurrentMedicine?.medicationstartdate &&
        intervalOption &&
        intervalValue
      ) {
        const intervalMap = { Days: 1, Weeks: 7, Months: 30, Years: 365 };
        const intervalMultiplier = intervalMap[intervalCurrentOption];
        const daysToAdd = intervalMultiplier * intervalCurrentValue - 1;

        // Parse start date in DD-MM-YYYY format
        // console.log(editCurrentMedicine.medicationstartdate)
        let medicationStartDate = editCurrentMedicine.medicationstartdate;

        // Check if the date is in DD-MM-YYYY format, if not, convert it

        if (!/^\d{2}-\d{2}-\d{4}$/.test(medicationStartDate)) {
          medicationStartDate =
            moment(medicationStartDate).format("DD-MM-YYYY");
        }
        const [day, month, year] = medicationStartDate.split("-").map(Number);
        const startDate = new Date(year, month - 1, day); // JS months are 0-indexed

        startDate.setDate(startDate.getDate() + daysToAdd);

        // Format new end date as DD-MM-YYYY
        const formattedEndDate = [
          String(startDate.getDate()).padStart(2, "0"),
          String(startDate.getMonth() + 1).padStart(2, "0"),
          startDate.getFullYear(),
        ].join("-");

        // Update medicationenddate in editMedicine
        setEditCurrentMedicine((prev) => ({
          ...prev,
          medicationenddate: formattedEndDate,
        }));
      }
    };

    useEffect(() => {
      if (intervalCurrentOption && intervalCurrentValue) {
        calculateCurrentEndDate();
      }
    }, [
      intervalCurrentOption,
      intervalCurrentValue,
      editCurrentMedicine?.medicationstartdate,
    ]);

    const handleIntervalCurrentChange = (value) => {
      setIntervalCurrentOption(value);
    };

    const handleIntervalCurrentValueChange = (e) => {
      const value = parseInt(e.target.value, 10);
      setIntervalCurrentValue(isNaN(value) ? 0 : value); // handle NaN cases if input is empty
    };

    // new functions
    function handleSOS(e) {
      // setSOS(e.target.value)
    } 
    function handleCustomMessage(e) {
      // setCustomMessage(e.target.value)
    }

    return (
      <Box
        display="flex"
        height={{ base: "100vh", md: "79vh" }}
        overflow={"hidden, auto"}
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <Box
          width={{ base: "100%", lg: "50%" }}
          borderRight={{ base: "none", lg: "1px solid gray" }}
        >
          <Tabs>
            {screenType == "mobile" && (
              <>
                {selectedTab === "recurrent" && (
                  <Box width="100%" mb="12px" height={"2rem"} mr={"12px"}>
                    <SearchBar
                      passInputPlace="Search"
                      height={"2rem"}
                      onChange={(event) => setSearch(event.target.value)}
                    />
                  </Box>
                )}

                {selectedTab === "current" && (
                  <PrimaryButton
                    buttonText="Select All"
                    verticalAlign="bottom"
                    variant="grayBtn"
                    btnStyle={{
                      w: "100%",
                      color: COLORS.PRIMARY_COLOR,
                      verticalAlign: "bottom",
                      mr: "12px",
                      mb: "10px",
                      h: "2.7rem",
                    }}
                    onClick={handleSelectAll}
                  />
                )}
              </>
            )}
            <TabList height={"3rem"} borderBottom={"1px solid gray"}>
              <Tab
                onClick={() => handleTabChange("recurrent")}
                _selected={{ color: "black", borderBottom: "2px solid black" }}
                fontSize={{ base: "12px", lg: "initial" }}
              >
                My Prescribed Medicines
              </Tab>
              <Tab
                onClick={() => handleTabChange("current")}
                _selected={{ color: "black", borderBottom: "2px solid black" }}
                fontSize={{ base: "12px", lg: "initial" }}
              >
                Patient Current Medicine
              </Tab>
              <Spacer />
              {screenType !== "mobile" && (
                <>
                  {/* {selectedTab === "recurrent" && (
                    <Box width="30%" mb={"4px"} height={"2rem"} mr={"12px"}>
                      <SearchBar
                        passInputPlace="Search"
                        height={"2rem"}
                        onChange={(event) => setSearch(event.target.value)}
                      />
                    </Box>
                  )} */}
                  {selectedTab === "recurrent" && (
                    // <Box display="flex" alignItems="end">

                    <Box
                      display="flex"
                      width="50%"
                      mb={"4px"}
                      height={"2rem"}
                      mr={"12px"}
                    >
                      <Box width="40%">
                        <Select
                          placeholder="Sort by"
                          onChange={(e) => setSortOption(e.target.value)}
                          width="100%"
                          // height={"2rem"}
                        >
                          <option value="A-Z">Name</option>
                          <option value="availability">Availability</option>
                        </Select>
                      </Box>

                      <Box width="60%" ml="12px">
                        {" "}
                        {/* Add margin-left to separate them */}
                        <SearchBar
                          passInputPlace="Search"
                          height={"2rem"}
                          onChange={(event) => setSearch(event.target.value)}
                          width="100%"
                        />
                      </Box>
                    </Box>

                    // </Box>
                  )}
                  {selectedTab === "current" && (
                    <PrimaryButton
                      buttonText="Select All"
                      verticalAlign="bottom"
                      variant="grayBtn"
                      btnStyle={{
                        w: "15%",
                        color: COLORS.PRIMARY_COLOR,
                        verticalAlign: "bottom",
                        mr: "12px",
                        mb: "0px",
                        h: "2.7rem",
                      }}
                      onClick={handleSelectAll}
                    />
                  )}
                </>
              )}
            </TabList>
            <TabPanels height="calc(77vh - 2.5rem)" overflowY="auto">
              {/* {isLoading && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  zIndex="10"
                  bg="rgba(255, 255, 255, 0.8)"
                >
                  <Loader miniLoader={true} />
                </Box>
              )} */}
              <TabPanel p={{ base: "0" }}>
                {isLoading && (
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    zIndex="10"
                    bg="rgba(255, 255, 255, 0.8)"
                  >
                    <Loader miniLoader={true} />
                  </Box>
                )}
               {recentMedicines.length === 0 ? (
                  <NoDataScreen width="100%" height="100%" />
                ) : search.trim() !== "" &&
                  recentMedicines.filter((medicine) =>
                    medicine.medicinename.toLowerCase().includes(search.toLowerCase()) ||
                    medicine.composition?.toLowerCase().includes(search.toLowerCase())
                  ).length === 0 ? (
                  <NoDataScreen width="100%" height="100%" />
                ): (
                  recentMedicines
                    .filter((medicine) =>
                      medicine.medicinename
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                        medicine.composition?.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort((a, b) => {
                      if (sortOption === "A-Z") {
                        return a.medicinename.localeCompare(b.medicinename);
                      } else if (sortOption === "availability") {
                        return b.remainingquantity - a.remainingquantity;
                      }
                      return 0;
                    })
                    .map((medicine) => (
                      <Box display="flex" alignItems="center">
                        <Box
                          as="button"
                          // onClick={}
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          w="36px"
                          h="auto"
                          p="10px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onClick={() =>
                            fetchAiDoctorPrompts(medicine.medicinename)
                          }
                        >
                          <Image
                            w="26px"
                            src="/assets/svgs/ai-star-outline.svg"
                          />
                        </Box>
                        <IconButton
                          aria-label="Search for alternatives"
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          icon={<GiMedicines />}
                          onClick={() =>
                            fetchAlternativeMedicines(
                              medicine.medicinename,
                              medicine.composition
                            )
                          }
                          variant="ghost"
                          w="36px"
                          h="36px"
                          ml="4px"
                        />
                        <Box
                          key={medicine.id}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          p="10px"
                          borderBottom="1px solid gray"
                          as="button"
                          onClick={() => {
                            // console.log("clicked")
                            handleCheckboxChange(medicine);
                            // Set edit medicine only if it's not already in selectedMedicines
                            if (!selectedMedicines.includes(medicine)) {
                              setEditMedicine(medicine);
                            }
                          }}
                          width={"100%"}
                          gap={{ base: "5px", md: "0" }}
                        >
                          <Checkbox
                            isChecked={selectedMedicines.includes(medicine)}
                            onChange={() => {
                              // console.log("clicked")
                              handleCheckboxChange(medicine);
                              // Set edit medicine only if it's not already in selectedMedicines
                              if (!selectedMedicines.includes(medicine)) {
                                setEditMedicine(medicine);
                              }
                            }}
                          ></Checkbox>
                          <Box width="45%">
                            <Box
                              fontWeight={"semibold"}
                              textAlign={"left"}
                              fontSize={{ base: "14px", md: "16px" }}
                            >
                              {medicine.medicinename}{" "}
                              {medicine.dosage && (
                                <>
                                  ({medicine.dosage} {medicine.dosageunit})
                                </>
                              )}{" "}
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                bg="#E6E7E966"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {medicine.medicinetype
                                  ? medicine.medicinetype
                                  : "-"}
                              </Box>
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                bg={
                                  medicine.remainingquantity
                                    ? "#73FF99"
                                    : "#FF7373"
                                }
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                ml="4px"
                              >
                                {formatQuantity(medicine.remainingquantity)}
                              </Box>
                            </Box>
                            <Box fontSize={"xs"} textAlign={"left"}>
                              Composition: {medicine.composition}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            flexDir={{ base: "column", md: "row" }}
                            w={{ base: "50%" }}
                          >
                            <Box
                              width={{ base: "100%", md: "30%" }}
                              textAlign={"left"}
                              fontSize={{ base: "14px", md: "16px" }}
                            >
                              {medicine.morning}-{medicine.afternoon}-
                              {medicine.night}
                            </Box>
                            <Box
                              width={{ base: "100%", md: "70%" }}
                              textAlign={"left"}
                              fontSize={{ base: "14px", md: "16px" }}
                            >
                              {medicine.beforefood === 1 &&
                                medicine.afterfood === 1 && (
                                  <>Before & After Food</>
                                )}
                              {medicine.beforefood === 1 &&
                                (medicine.afterfood === 0 ||
                                  medicine.afterfood === null) && (
                                  <>Before Food</>
                                )}
                              {(medicine.beforefood === 0 ||
                                medicine.beforefood === null) &&
                                medicine.afterfood === 1 && (
                                  <>After Food</>
                                )}{" "}
                              {(medicine.beforefood === 1 ||
                                medicine.afterfood === 1) &&
                                medicine.frequency &&
                                "-"}
                              {medicine.frequency &&
                                `${medicine.frequency} x per day`}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                )}
              </TabPanel>
              <TabPanel>
                {isLoading && (
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    zIndex="10"
                    bg="rgba(255, 255, 255, 0.8)"
                  >
                    <Loader miniLoader={true} />
                  </Box>
                )}
                {patientCurrentMedicinesForDoctor.length === 0 ? (
                  <NoDataScreen width="100%" height="100%" />
                ) : (
                  currentMedicines.map((medicine) => (
                    <Box display="flex" alignItems="center">
                      <Box
                        as="button"
                        // onClick={}
                        border="1px solid #E6E7E9"
                        borderRadius="full"
                        w="36px"
                        h="auto"
                        p="10px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() =>
                          fetchAiDoctorPrompts(medicine.medicinename)
                        }
                      >
                        <Image
                          w="26px"
                          src="/assets/svgs/ai-star-outline.svg"
                        />
                      </Box>
                      {/* <IconButton
                          aria-label="Search for alternatives"
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          icon={<GiMedicines />}
                          // onClick={() =>
                          //   copyToClipboard(aiPatientSocialHistory)
                          // }
                          variant="ghost"
                          w="36px"
                          h="36px"
                          ml="4px"
                        /> */}
                      <Box
                        key={medicine.id}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p="10px"
                        borderBottom="1px solid gray"
                        as="button"
                        onClick={() => handleCheckboxChange(medicine)}
                        width={"100%"}
                      >
                        <Checkbox
                          isChecked={selectedMedicines.includes(medicine)}
                          onChange={() => handleCheckboxChange(medicine)}
                        ></Checkbox>
                        <Box width="45%">
                          <Box fontWeight={"semibold"} textAlign={"left"}>
                            {medicine.medicinename}{" "}
                            {medicine.dosage && (
                              <>
                                ({medicine.dosage} {medicine.dosageunit})
                              </>
                            )}{" "}
                            <Box
                              display="inline-block"
                              verticalAlign="bottom"
                              fontSize="10px"
                              fontWeight="600"
                              bg="#E6E7E966"
                              border="1px solid #E6E7E9"
                              p="5px 10px"
                              borderRadius="full"
                              w="fit-content"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {medicine.medicinetype
                                ? medicine.medicinetype
                                : "-"}
                            </Box>
                          </Box>
                          <Box fontSize={"xs"} textAlign={"left"}>
                            Composition: {medicine.composition}
                          </Box>
                        </Box>
                        <Box width="15%" textAlign={"left"}>
                          {medicine.morning}-{medicine.afternoon}-{medicine.night}
                        </Box>
                        <Box width="32%" textAlign={"left"}>
                          {medicine.beforefood === 1 &&
                            medicine.afterfood === 1 && (
                              <>Before & After Food</>
                            )}
                          {medicine.beforefood === 1 &&
                            (medicine.afterfood === 0 ||
                              medicine.afterfood === null) && <>Before Food</>}
                          {(medicine.beforefood === 0 ||
                            medicine.beforefood === null) &&
                            medicine.afterfood === 1 && <>After Food</>}{" "}
                          {(medicine.beforefood === 1 ||
                            medicine.afterfood === 1) &&
                            medicine.frequency &&
                            "-"}
                          {medicine.frequency &&
                            `${medicine.frequency} x per day`}
                        </Box>
                      </Box>
                    </Box>
                  ))
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box width={{ base: "100%", lg: "50%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={"3rem"}
            borderBottom={"1px solid gray"}
          >
            {/* Right Header */}
            <Box
              fontSize="md"
              px={{ base: "0", md: "32px" }}
              fontWeight={"semibold"}
            >
              New Prescription
            </Box>
            <Button
              bg={"#fcfcfd"}
              color={"blue"}
              height={"2rem"}
              onClick={() => setShowAddCard(true)}
            >
              + Add medicine
            </Button>
          </Box>
          <Box
            px={{ base: "0", md: "2rem" }}
            height="calc(77vh - 2.5rem)"
            overflowY="auto"
            pt="1.5rem"
          >
            <Stack spacing={4}>
            {showAddCard && (
                <Box
                  p="20px"
                  border="1px solid gray"
                  borderRadius="10px"
                  ml="0px"
                  // mb="10rem"
                >
                  <Stack spacing={4}>
                    {/* <InputGroup> */}
                    {/* <PrimaryInput
                        type="text"
                        variant={"bothSide"}
                        inputPlace="Medicine name*"
                        defaultValue={newMedicine.medicinename}
                        onChange={(e) =>
                          handleNewMedicineChange(
                            "medicinename",
                            e.target.value
                          )
                        }
                      /> */}
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      flexDir={{ base: "column", md: "row" }}
                    >
                      <Box width={{ base: "100%", md: "45%" }}>
                        <Box w="100%" pos="relative" cursor="pointer">
                          <PrimaryInput
                            variant="bothSide"
                            inputPlace="Medicine name"
                            inputValue={newMedicine.medicinename}
                            onChange={(e) => {
                              handleNewMedicineChange(
                                "medicinename",
                                e.target.value
                              );
                              filterMedicineNames(e.target.value);
                              setShowMedicineNamesDropdown(1);
                              setBoolValForSuggestions(!boolValForSuggestions);
                            }}
                            inputIdProp={"MedicineNameInAdd" + 1}
                            boolProp={boolValForSuggestions}
                            onKeyDown={(e) => handleKeyDown(e, 1)}
                            autoFocus={showMedicineNamesDropdown === 1}
                            autocomplete="off"
                          />
                          <Image
                            top="33%"
                            right="6.5%"
                            w="15px"
                            pos="absolute"
                            src="/assets/imgs/double-arrow.png"
                          />
                        </Box>
                        {showMedicineNamesDropdown === 1 &&
                          suggestions.length > 0 && (
                            <Menu isOpen={true}>
                              <MenuButton
                                as={Box}
                                w="100%"
                                display="block"
                                pos="relative"
                                zIndex="999"
                              >
                                <Box
                                  pos="absolute"
                                  top="100%"
                                  left="0"
                                  w="100%"
                                  bg="white"
                                  borderTop="none"
                                  border="1px solid #E6E7E9"
                                  borderRadius="0 0 12px 12px"
                                />
                              </MenuButton>
                              <MenuList
                                w="100%"
                                borderRadius="0 0 12px 12px"
                                zIndex="999"
                                bg="white"
                                maxH="150px"
                                overflow="hidden auto"
                                ref={suggestionsMenuRef}
                              >
                                {suggestions.map((suggestion, sIndex) => (
                                  <MenuItem
                                    key={sIndex}
                                    onClick={() => handleSelect(suggestion, 1)}
                                    cursor="pointer"
                                    p="1rem"
                                    py="0.5rem"
                                    borderBottom="1px solid #E6E7E9"
                                    bg={
                                      focusedItemIndex === sIndex
                                        ? "#f0f0f0"
                                        : "white"
                                    }
                                  >
                                    <Text fontSize="0.875rem" fontWeight="500">
                                      {suggestion.productname +
                                        " (" +
                                        suggestion.remainingquantity +
                                        " Qty)"}
                                    </Text>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          )}
                      </Box>
                      <Box width={{ base: "100%", md: "45%" }}>
                        <PrimaryInput
                          key={key}
                          type="text"
                          variant={"bothSide"}
                          inputPlace="Medicine Composition"
                          inputValue={newMedicine.composition}
                          onChange={(e) =>
                            handleNewMedicineChange(
                              "composition",
                              e.target.value
                            )
                          }
                        />{" "}
                      </Box>
                      <Box width={{ base: "100%", md: "10%" }}>
                        <PrimaryButton
                          buttonText="Fetch"
                          verticalAlign="bottom"
                          variant="grayBtn"
                          btnStyle={{
                            w: "100%",
                            color: COLORS.PRIMARY_COLOR,
                            verticalAlign: "bottom",
                            ml: { base: "0", md: "4px" },
                            mb: "0px",
                            h: "2.8rem",
                          }}
                          onClick={() =>
                            fetchComposition(newMedicine.medicinename)
                          }
                          isDisabled={false}
                        />
                      </Box>
                    </Flex>
                    {/* </InputGroup> */}
                    <RadioGroup
                      defaultValue={newMedicine.medicinetype}
                      onChange={(value) => {
                        handleNewMedicineChange("medicinetype", value);

                        if (value === "Syrup") {
                          handleNewMedicineChange("dosageunit", "ml");
                        } else if (value === "Tablet") {
                          handleNewMedicineChange("dosageunit", "mg");
                        } else if (value === "Capsule") {
                          handleNewMedicineChange("dosageunit", "mg");
                        } else if (value === "Injection") {
                          handleNewMedicineChange("dosageunit", "ml");
                        } else if (value === "Ointment") {
                          handleNewMedicineChange("dosageunit", "grms");
                        } else if (value === "Nebulization") {
                          handleNewMedicineChange("dosageunit", "ml");
                        } else if (value === "IVFluids") {
                          handleNewMedicineChange("dosageunit", "ml");
                        }
                      }}
                    >
                      <Stack direction="row" gap="20px" wrap="wrap">
                        <Radio value="Tablet">Tablet</Radio>
                        <Radio value="Syrup">Syrup</Radio>
                        <Radio value="Capsule">Capsule</Radio>
                        <Radio value="Injection">Injection</Radio>
                        {/* <Radio value="Ointment">Ointment</Radio> */}
                        <Radio value="Ointment">
                          Ointments / Creams / Gels
                        </Radio>
                        <Radio value="Nebulization">Nebulization</Radio>
                        <Radio value="IVFluids">IV Fluids</Radio>
                      </Stack>
                    </RadioGroup>
                    <Divider />
                    <Box>
                      <Box
                        display="flex"
                        flexDir={{ base: "column", md: "row" }}
                      >
                        <MiniDropdownInput
                          variant="bothSide"
                          options={[
                            { label: "mg", value: "mg" },
                            { label: "ml", value: "ml" },
                            { label: "grms", value: "grms" },
                          ]}
                          // placeholderProp="Dosage"
                          placeholderProp={
                            newMedicine.medicinetype == "Syrup" ||
                            newMedicine.medicinetype == "Injection" ||
                            newMedicine.medicinetype == "Nebulization" ||
                            newMedicine.medicinetype == "IVFluids"
                              ? "Volume"
                              : "Dosage"
                          }
                          defaultValue={newMedicine.dosage}
                          defaultOptionProp={newMedicine.dosageunit}
                          onSelect={(value) =>
                            handleNewMedicineChange("dosageunit", value.label)
                          }
                          handleInputChange={(event) =>
                            handleNewMedicineChange(
                              "dosage",
                              validationOfNumberWithOnly1Decimal(
                                event.target.value
                              )
                            )
                          }
                          maxLength="6"
                          inputVal={newMedicine.dosage}
                        />
                        <DropdownInput
                          variant="bothSide"
                          dropDownPlace="Consumption frequency"
                          options={["1", "2", "3", "4"]}
                          onClick={(value) =>
                            handleNewMedicineChange("frequency", value)
                          }
                          defaultOption={newMedicine.frequency}
                        />
                        <DropdownInput
                          variant="bothSide"
                          dropDownPlace="Duration"
                          options={[
                            "Until symptoms resolve",
                            "Hours",
                            "Days",
                            "Weeks",
                            "Months",
                            "Years",
                          ]} // Interval units as options
                          onClick={(value) =>
                            handleNewMedicineChange("frequencyduration", value)
                          }
                          // defaultOption={intervalOption}
                        />
                      </Box>
                      {/* <Box
                        display="flex"
                        flexDir={{ base: "column", md: "row" }}
                      >
                        <DatePicker
                          // disableFutureDates={true}
                          defaultDate={newMedicine.medicationstartdate}
                          minDate={newMedicine.medicationstartdate}
                          onDateChange={(selectedDateValue) =>
                            handleNewMedicineChange(
                              "medicationstartdate",
                              selectedDateValue
                            )
                          }
                          placeholderProp={"Start Date"}
                          calendarStlye={{ left: "-18px", right: "unset" }}
                        />
                        <DatePicker
                          defaultDate={newMedicine.medicationenddate}
                          minDate={newMedicine.medicationstartdate} // which is today
                          onDateChange={(selectedDateValue) =>
                            handleNewMedicineChange(
                              "medicationenddate",
                              selectedDateValue
                            )
                          }
                          placeholderProp={"End Date"}
                          calendarStlye={{
                            transform: "scale(0.9)",
                            right: "-22px",
                            top: "30px",
                          }}
                        />
                      </Box> */}
                      <Box display="flex" flexDir="row">
                        <Box width="25%">
                          <DropdownInput
                            variant="bothSide"
                            dropDownPlace={
                              newMedicine.medicinetype === "IVFluids"
                                ? "Infusion Frequency"
                                : newMedicine.medicinetype === "Ointment"
                                ? "Application Duration"
                                : "Duration"
                            }
                            options={["Days", "Weeks", "Months", "Years"]} // Interval units as options
                            onClick={handleIntervalNewChange} // Handle changes with setIntervalOption
                            defaultOption={intervalNewOption}
                          />
                        </Box>
                        <Box width="25%">
                          <PrimaryInput
                            variant={"bothSide"}
                            inputType="number"
                            defaultValue={intervalNewValue}
                            onChange={handleIntervalNewValueChange}
                            inputPlace={
                              newMedicine.medicinetype === "IVFluids"
                                ? "Infusion Duration"
                                : "Interval Value"
                            }
                          />
                        </Box>
                        <Box width={"50%"} display="flex" flexDir="row">
                          <DatePicker
                            dateWidth="50%"
                            defaultDate={newMedicine.medicationstartdate}
                            minDate={newMedicine.medicationstartdate}
                            onDateChange={(selectedDateValue) =>
                              handleNewMedicineChange(
                                "medicationstartdate",
                                selectedDateValue
                              )
                            }
                            placeholderProp={"Start Date"}
                            calendarStlye={{
                              left: "-18px",
                              right: "unset",
                            }}
                          />
                          <DatePicker
                            dateWidth="50%"
                            key={newMedicine.medicationenddate}
                            defaultDate={newMedicine.medicationenddate}
                            disableDatePicker={true}
                            minDate={newMedicine.medicationstartdate}
                            onDateChange={(selectedDateValue) =>
                              handleNewMedicineChange(
                                "medicationenddate",
                                selectedDateValue
                              )
                            }
                            placeholderProp={"End Date"}
                            calendarStlye={{
                              transform: "scale(0.9)",
                              right: "-22px",
                              top: "30px",
                              pointerEvents: "none", // Disable interaction
                              opacity: 0.5, // Visually indicate disabled state
                            }}
                          />
                        </Box>
                      </Box>
                      <Box display="flex" flexDir="row">
                        {newMedicine.medicinetype == "Injection" && (
                          <>
                            <Box width={"50%"}>
                              <DropdownInput
                                variant="downSide"
                                dropDownPlace="Route of Administration"
                                options={[
                                  "Intravenous (IV)",
                                  "Intramuscular (IM)",
                                  "Subcutaneous (SC)",
                                ]}
                                onClick={(value) =>
                                  handleNewMedicineChange(
                                    "routeofadministration",
                                    value
                                  )
                                }
                              />
                            </Box>
                            <Box width={"50%"}>
                              <PrimaryInput
                                variant={"bothSide"}
                                onChange={(e) =>
                                  handleNewMedicineChange(
                                    "timingofinjection",
                                    e.target.value
                                  )
                                }
                                inputPlace="Timing"
                              />
                            </Box>
                          </>
                        )}
                        {newMedicine.medicinetype == "Ointment" && (
                          <>
                            <DropdownInput
                              variant="downSide"
                              dropDownPlace="Quantity"
                              options={[
                                "Pea-sized",
                                "Thin layer",
                                "Fingertip unit (FTU)",
                                "Half a fingertip",
                              ]}
                              onClick={(value) =>
                                handleNewMedicineChange(
                                  "quantitytobeapplied",
                                  value
                                )
                              }
                            />
                            <PrimaryInput
                              variant={"bothSide"}
                              onChange={(e) =>
                                handleNewMedicineChange(
                                  "areaofapplication",
                                  e.target.value
                                )
                              }
                              inputPlace="Area of Application"
                            />
                          </>
                        )}

                        {newMedicine.medicinetype == "Nebulization" && (
                          <>
                            <DropdownInput
                              variant="downSide"
                              dropDownPlace="Diluent"
                              options={["Normal saline", "Distilled water","3% saline"]}
                              onClick={(value) =>
                                handleNewMedicineChange("diluent", value)
                              }
                            />
                          </>
                        )}

                        {newMedicine.medicinetype == "IVFluids" && (
                          <>
                            <MiniDropdownInput
                              variant="bothside"
                              options={[
                                { label: "ml/hr", value: "ml/hr" },
                                { label: "ml/min", value: "ml/min" },
                                // { label: "mg/hr", value: "mg/hr" },
                                // { label: "grms/hr", value: "grms/hr" },
                              ]}
                              handleInputChange={(e) =>
                                handleNewMedicineChange(
                                  "rateofinfusionvalue",
                                  validationOfNumberWithOnly1Decimal(
                                    e.target.value
                                  )
                                )
                              }
                              placeholderProp={"Rate of Infusion"}
                              onSelect={(selectedOption) =>
                                handleEditMedicineChange(
                                  "rateofinfusionunit",
                                  selectedOption.label
                                )
                              }
                              maxLength="6"
                            />
                            <PrimaryInput
                              variant={"bothSide"}
                              onChange={(e) =>
                                handleNewMedicineChange(
                                  "additives",
                                  e.target.value
                                )
                              }
                              inputPlace="Additives"
                            />
                          </>
                        )}
                      </Box>
                      <CheckboxGroup colorScheme="green">
                        <Stack
                          spacing={2}
                          pt="20px"
                          direction="row"
                          flexWrap={{ base: "wrap", md: "nowrap" }}
                        >
                          <Checkbox
                            isChecked={newMedicine.morning === 1}
                            onChange={() =>
                              handleNewMedicineChange(
                                "morning",
                                newMedicine.morning === 1 ? 0 : 1
                              )
                            }
                          >
                            Morning
                          </Checkbox>
                          <Checkbox
                            isChecked={newMedicine.afternoon === 1}
                            onChange={() =>
                              handleNewMedicineChange(
                                "afternoon",
                                newMedicine.afternoon === 1 ? 0 : 1
                              )
                            }
                          >
                            Afternoon
                          </Checkbox>
                          <Checkbox
                            isChecked={newMedicine.night === 1}
                            onChange={() =>
                              handleNewMedicineChange(
                                "night",
                                newMedicine.night === 1 ? 0 : 1
                              )
                            }
                          >
                            Night
                          </Checkbox>
                          <Spacer />{" "}
                          {/* Add a spacer to push the next checkboxes to the right */}
                          <Checkbox
                            isChecked={newMedicine.beforefood === 1}
                            onChange={() =>
                              handleNewMedicineChange(
                                "beforefood",
                                newMedicine.beforefood === 1 ? 0 : 1
                              )
                            }
                          >
                            Before Food
                          </Checkbox>
                          <Checkbox
                            isChecked={newMedicine.afterfood === 1}
                            onChange={() =>
                              handleNewMedicineChange(
                                "afterfood",
                                newMedicine.afterfood === 1 ? 0 : 1
                              )
                            }
                          >
                            After Food
                          </Checkbox>
                        </Stack>
                      </CheckboxGroup>

                      <Divider my="10px" />

{/* <PrimaryInput
  variant={"bothSide"}
  onChange={(e) => handleNewMedicineChange("sos", e.target.value )}
  // defaultValue={sos}
  inputValue={newMedicine.sos}
  inputPlace="SOS"
/> */}
<Textarea
  borderRadius='0'
  placeholder="Custom Message"
  defaultValue={newMedicine.custommessage}
  inputValue={newMedicine.custommessage}
  onChange={(e) => handleNewMedicineChange("custommessage", e.target.value )}
/>

<Divider my="10px" />
                    </Box>
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      w={{ base: "100%" }}
                      gap="10px"
                    >
                      {/* <Button onClick={() => setShowAddCard(false)}>
                        Cancel
                      </Button> */}
                      <PrimaryButton
                        buttonText="Cancel"
                        variant="grayBtn"
                        btnStyle={{
                          w: { base: "50%", md: "15%" },
                          color: COLORS.PRIMARY_COLOR,
                          h: "2.5rem",
                        }}
                        onClick={handleCancelAddMedicine}
                        isDisabled={false}
                      />
                      {/* <Button colorScheme="blue" onClick={handleAddMedicine}>
                        Add medicine
                      </Button> */}
                      <PrimaryButton
                        buttonText="Add Medication"
                        onClick={handleAddMedicine}
                        isDisabled={false}
                        btnStyle={{
                          w: { base: "50%", md: "25%" },
                          //   color: COLORS.PRIMARY_COLOR,
                          h: "2.5rem",
                        }}
                      />
                    </Box>
                  </Stack>
                </Box>
              )}

              {patientCurrentMedicinesForDoctor
                .filter((medicine) => medicine.isactive !== 0)
                .map((medicine) => (
                  <Box key={medicine.id}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p="10px"
                      border="1px solid gray"
                      borderRadius="10px"
                    >
                      {screenType !== "mobile" && (
                        <>
                          <Box width="42%">
                            <Box fontWeight={"semibold"}>
                              {medicine.medicinename} (
                              {medicine.dosage ? (
                                <>
                                  {medicine.dosage} {medicine.dosageunit}
                                </>
                              ) : (
                                <>-/- {medicine.dosageunit}</>
                              )}
                              )
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                bg="#E6E7E966"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {medicine.medicinetype
                                  ? medicine.medicinetype
                                  : "-"}
                              </Box>
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                // bg="#E6E7E966"
                                bg="#FF7373"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Current
                              </Box>
                            </Box>
                            <Box fontSize={"xs"}>
                              Composition: {medicine.composition}
                            </Box>
                          </Box>
                          <Box width="8%">
                            {medicine.morning}-{medicine.afternoon}-
                            {medicine.night}
                          </Box>
                          <Box width="30%">
                            {medicine.beforefood === 1 &&
                              medicine.afterfood === 1 && (
                                <>Before & After Food</>
                              )}
                            {medicine.beforefood === 1 &&
                              (medicine.afterfood === 0 ||
                                medicine.afterfood === null) && (
                                <>Before Food</>
                              )}
                            {(medicine.beforefood === 0 ||
                              medicine.beforefood === null) &&
                              medicine.afterfood === 1 && <>After Food</>}{" "}
                            {(medicine.beforefood === 1 ||
                              medicine.afterfood === 1) &&
                              medicine.frequency &&
                              "-"}
                            {medicine.frequency &&
                              `${medicine.frequency} x per day`}
                          </Box>
                          <Box display="flex" alignItems="center" width="20%">
                            <Box
                              as="button"
                              // onClick={}
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() =>
                                fetchAiDoctorPrompts(medicine.medicinename)
                              }
                            >
                              <Image
                                w="26px"
                                src="/assets/svgs/ai-star-outline.svg"
                              />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              ml="10px"
                              onClick={() =>
                                handleEditCurrentMedicine(medicine)
                              }
                            >
                              <Image
                                w="13px"
                                src="/assets/svgs/edit-gray.svg"
                              />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              ml="10px"
                              onClick={() =>
                                handleDeleteCurrentMedicine(medicine)
                              }
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </>
                      )}

                      {screenType == "mobile" && (
                        <>
                          <Box width="100%">
                            <Box fontWeight={"semibold"}>
                              {medicine.medicinename} ({medicine.dosage}
                              {medicine.dosageunit}){" "}
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                bg="#E6E7E966"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {medicine.medicinetype
                                  ? medicine.medicinetype
                                  : "-"}
                              </Box>
                            </Box>
                            <Box fontSize={"xs"}>
                              Composition: {medicine.composition}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            w="100%"
                            flexDir="column"
                            gap="10px"
                          >
                            <Box width="100%">
                              {medicine.morning}-{medicine.afternoon}-
                              {medicine.night}
                            </Box>
                            <Box width="100%">
                              {medicine.beforefood === 1 &&
                                medicine.afterfood === 1 && (
                                  <>Before & After Food</>
                                )}
                              {medicine.beforefood === 1 &&
                                (medicine.afterfood === 0 ||
                                  medicine.afterfood === null) && (
                                  <>Before Food</>
                                )}
                              {(medicine.beforefood === 0 ||
                                medicine.beforefood === null) &&
                                medicine.afterfood === 1 && (
                                  <>After Food</>
                                )}{" "}
                              {(medicine.beforefood === 1 ||
                                medicine.afterfood === 1) &&
                                medicine.frequency &&
                                "-"}
                              {medicine.frequency &&
                                `${medicine.frequency} x per day`}
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              width="100%"
                            >
                              <Box
                                as="button"
                                // onClick={}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                onClick={() =>
                                  fetchAiDoctorPrompts(medicine.medicinename)
                                }
                              >
                                <Image
                                  w="26px"
                                  src="/assets/svgs/ai-star-outline.svg"
                                />
                              </Box>
                              <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                ml="10px"
                                onClick={() =>
                                  handleEditCurrentMedicine(medicine)
                                }
                              >
                                <Image
                                  w="13px"
                                  src="/assets/svgs/edit-gray.svg"
                                />
                              </Box>
                              <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                ml="10px"
                                onClick={() => handleDeleteMedicine(medicine)}
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>

                    {editCurrentMedicine &&
                      editCurrentMedicine.id === medicine.id && (
                        <Box
                          p="20px"
                          border="1px solid gray"
                          borderRadius="10px"
                          mt="10px" // Add some margin-top to separate from the above row
                        >
                          <Stack spacing={4}>
                            <Flex
                              width="100%"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box width="45%">
                                <Box w="100%" pos="relative" cursor="pointer">
                                  <PrimaryInput
                                    variant="bothSide"
                                    inputPlace="Medicine name"
                                    inputValue={
                                      editCurrentMedicine.medicinename
                                    }
                                    onChange={(e) => {
                                      handleEditCurrentMedicineChange(
                                        "medicinename",
                                        e.target.value
                                      );
                                      // filterMedicineNames(e.target.value);
                                      // setShowMedicineNamesDropdown(0);
                                      // setBoolValForSuggestions(
                                      //   !boolValForSuggestions
                                      // );
                                    }}
                                    // inputIdProp={"MedicineNameInAdd" + 0}
                                    // boolProp={boolValForSuggestions}
                                    // onKeyDown={(e) => handleEditKeyDown(e, 0)}
                                    // autoFocus={showMedicineNamesDropdown === 0}
                                    // autocomplete="off"
                                  />
                                  <Image
                                    top="33%"
                                    right="6.5%"
                                    w="15px"
                                    pos="absolute"
                                    src="/assets/imgs/double-arrow.png"
                                  />
                                </Box>
                              </Box>
                              <Box width="45%">
                                <PrimaryInput
                                  key={compositionEditKey}
                                  type="text"
                                  variant="bothSide"
                                  inputPlace="Medicine Composition"
                                  defaultValue={editCurrentMedicine.composition}
                                  onChange={(e) =>
                                    handleEditCurrentMedicineChange(
                                      "composition",
                                      e.target.value
                                    )
                                  }
                                />{" "}
                              </Box>
                              <Box width="10%">
                                <PrimaryButton
                                  buttonText="Fetch"
                                  verticalAlign="bottom"
                                  variant="grayBtn"
                                  btnStyle={{
                                    w: "100%",
                                    color: COLORS.PRIMARY_COLOR,
                                    verticalAlign: "bottom",
                                    ml: "4px",
                                    mb: "0px",
                                    h: "2.8rem",
                                  }}
                                  onClick={() =>
                                    fetchCompositionForCurrentEdit(
                                      editCurrentMedicine.medicinename
                                    )
                                  }
                                  isDisabled={false}
                                />
                              </Box>
                            </Flex>
                            <RadioGroup
                              defaultValue={editCurrentMedicine.medicinetype}
                              onChange={(value) => {
                                handleEditCurrentMedicineChange(
                                  "medicinetype",
                                  value
                                );

                                if (value === "Syrup") {
                                  handleEditCurrentMedicineChange(
                                    "dosageunit",
                                    "ml"
                                  );
                                } else if (value === "Tablet") {
                                  handleEditCurrentMedicineChange(
                                    "dosageunit",
                                    "mg"
                                  );
                                } else if (value === "Capsule") {
                                  handleEditCurrentMedicineChange(
                                    "dosageunit",
                                    "mg"
                                  );
                                }  else if (value === "Injection") {
                                  handleEditCurrentMedicineChange("dosageunit", "ml");
                                } else if (value === "Ointment") {
                                  handleEditCurrentMedicineChange("dosageunit", "grms");
                                } else if (value === "Nebulization") {
                                  handleEditCurrentMedicineChange("dosageunit", "ml");
                                } else if (value === "IVFluids") {
                                  handleEditCurrentMedicineChange("dosageunit", "ml");
                                }
                              }}
                            >
                             <Stack direction="row" gap="20px" wrap="wrap">
                              <Radio value="Tablet">Tablet</Radio>
                              <Radio value="Syrup">Syrup</Radio>
                              <Radio value="Capsule">Capsule</Radio>
                              <Radio value="Injection">Injection</Radio>
                              {/* <Radio value="Ointment">Ointment</Radio> */}
                              <Radio value="Ointment">
                                Ointments / Creams / Gels
                              </Radio>
                              <Radio value="Nebulization">Nebulization</Radio>
                              <Radio value="IVFluids">IV Fluids</Radio>
                            </Stack>
                            </RadioGroup>
                            <Divider />
                            <Box>
                              <Box display="flex" flexDir="row">
                                <MiniDropdownInput
                                  variant="bothSide"
                                  options={[
                                    { label: "mg", value: "mg" },
                                    { label: "ml", value: "ml" },
                                    { label: "grms", value: "grms" },
                                  ]}
                                  placeholderProp="Dosage"
                                  defaultValue={editCurrentMedicine.dosage}
                                  defaultOptionProp={
                                    editCurrentMedicine.dosageunit
                                  }
                                  onSelect={(value) =>
                                    handleEditCurrentMedicineChange(
                                      "dosageunit",
                                      value.label
                                    )
                                  }
                                  // changes here
                                  handleInputChange={(event) =>
                                    handleEditCurrentMedicineChange(
                                      "dosage",
                                      // event.target.value
                                      validationOfNumberWithOnly1Decimal(
                                        event.target.value
                                      )
                                    )
                                  }
                                  inputVal={editCurrentMedicine.dosage}
                                  maxLength="6"
                                />
                                <DropdownInput
                                  variant="bothSide"
                                  dropDownPlace="Consumption frequency"
                                  options={["1", "2", "3", "4"]}
                                  onClick={(value) =>
                                    handleEditCurrentMedicineChange(
                                      "frequency",
                                      value
                                    )
                                  }
                                  defaultOption={editCurrentMedicine.frequency}
                                />
                              </Box>
                              {/* <Box display="flex" flexDir="row">
                                <DatePicker
                                  // disableFutureDates={true}
                                  defaultDate={
                                    editCurrentMedicine.medicationstartdate
                                  }
                                  // minDate={editCurrentMedicine.medicationstartdate}
                                  onDateChange={(selectedDateValue) =>
                                    handleEditCurrentMedicineChange(
                                      "medicationstartdate",
                                      selectedDateValue
                                    )
                                  }
                                  placeholderProp={"Start Date"}
                                  calendarStlye={{
                                    left: "-18px",
                                    right: "unset",
                                  }}
                                />
                                <DatePicker
                                  defaultDate={
                                    editCurrentMedicine.medicationenddate
                                  }
                                  // minDate={editCurrentMedicine.medicationstartdate}
                                  onDateChange={(selectedDateValue) =>
                                    handleEditCurrentMedicineChange(
                                      "medicationenddate",
                                      selectedDateValue
                                    )
                                  }
                                  placeholderProp={"End Date"}
                                  calendarStlye={{
                                    transform: "scale(0.9)",
                                    right: "-22px",
                                    top: "30px",
                                  }}
                                />
                              </Box> */}

                              <Box display="flex" flexDir="row">
                                <Box width="25%">
                                  <DropdownInput
                                    variant="bothSide"
                                    dropDownPlace="Duration"
                                    options={[
                                      "Days",
                                      "Weeks",
                                      "Months",
                                      "Years",
                                    ]} // Interval units as options
                                    onClick={handleIntervalCurrentChange} // Handle changes with setIntervalOption
                                    defaultOption={intervalCurrentOption}
                                  />
                                </Box>
                                <Box width="25%">
                                  <PrimaryInput
                                    variant={"bothSide"}
                                    inputType="number"
                                    defaultValue={intervalCurrentValue}
                                    onChange={handleIntervalCurrentValueChange}
                                    inputPlace="Interval Value"
                                  />
                                </Box>
                                <Box width={"50%"} display="flex" flexDir="row">
                                  <DatePicker
                                    dateWidth="50%"
                                    defaultDate={
                                      editCurrentMedicine.medicationstartdate
                                    }
                                    minDate={
                                      editCurrentMedicine.medicationstartdate
                                    }
                                    onDateChange={(selectedDateValue) =>
                                      handleEditCurrentMedicineChange(
                                        "medicationstartdate",
                                        selectedDateValue
                                      )
                                    }
                                    placeholderProp={"Start Date"}
                                    calendarStlye={{
                                      left: "-18px",
                                      right: "unset",
                                    }}
                                  />
                                  <DatePicker
                                    dateWidth="50%"
                                    key={editCurrentMedicine.medicationenddate}
                                    defaultDate={
                                      editCurrentMedicine.medicationenddate
                                    }
                                    disableDatePicker={true}
                                    minDate={
                                      editCurrentMedicine.medicationstartdate
                                    }
                                    onDateChange={(selectedDateValue) =>
                                      handleEditCurrentMedicineChange(
                                        "medicationenddate",
                                        selectedDateValue
                                      )
                                    }
                                    placeholderProp={"End Date"}
                                    calendarStlye={{
                                      transform: "scale(0.9)",
                                      right: "-22px",
                                      top: "30px",
                                      pointerEvents: "none", // Disable interaction
                                      opacity: 0.5, // Visually indicate disabled state
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box display="flex" flexDir="row">
                                {editCurrentMedicine.medicinetype == "Injection" && (
                                  <>
                                    <Box width={"50%"}>
                                      <DropdownInput
                                        variant="downSide"
                                        dropDownPlace="Route of Administration"
                                        options={[
                                          "Intravenous (IV)",
                                          "Intramuscular (IM)",
                                          "Subcutaneous (SC)",
                                        ]}
                                        onClick={(value) =>
                                          handleEditCurrentMedicineChange(
                                            "routeofadministration",
                                            value
                                          )
                                        }
                                        defaultOption={
                                          editCurrentMedicine.routeofadministration
                                        }
                                      />
                                    </Box>
                                    <Box width={"50%"}>
                                      <PrimaryInput
                                        variant={"bothSide"}
                                        onChange={(e) =>
                                          handleEditCurrentMedicineChange(
                                            "timingofinjection",
                                            e.target.value
                                          )
                                        }
                                        inputPlace="Timing"
                                        defaultValue={
                                          editCurrentMedicine.timingofinjection
                                        }
                                      />
                                    </Box>
                                  </>
                                )}
                                {editCurrentMedicine.medicinetype == "Ointment" && (
                                  <>
                                    <DropdownInput
                                      variant="downSide"
                                      dropDownPlace="Quantity"
                                      options={[
                                        "Pea-sized",
                                        "Thin layer",
                                        "Fingertip unit (FTU)",
                                        "Half a fingertip",
                                      ]}
                                      onClick={(value) =>
                                        handleEditCurrentMedicineChange(
                                          "quantitytobeapplied",
                                          value
                                        )
                                      }
                                      defaultOption={
                                        editCurrentMedicine.quantitytobeapplied
                                      }
                                    />
                                    <PrimaryInput
                                      variant={"bothSide"}
                                      onChange={(e) =>
                                        handleEditCurrentMedicineChange(
                                          "areaofapplication",
                                          e.target.value
                                        )
                                      }
                                      inputPlace="Area of Application"
                                      defaultValue={
                                        editCurrentMedicine.areaofapplication
                                      }
                                    />
                                  </>
                                )}

                                {editCurrentMedicine.medicinetype ==
                                  "Nebulization" && (
                                  <>
                                    <DropdownInput
                                      variant="downSide"
                                      dropDownPlace="Diluent"
                                      options={[
                                        "Normal saline",
                                        "Distilled water",
                                        "3% saline",
                                      ]}
                                      onClick={(value) =>
                                        handleEditCurrentMedicineChange(
                                          "diluent",
                                          value
                                        )
                                      }
                                      defaultOption={editCurrentMedicine.diluent}
                                    />
                                  </>
                                )}

                                {editCurrentMedicine.medicinetype == "IVFluids" && (
                                  <>
                                    <MiniDropdownInput
                                      variant="bothside"
                                      options={[
                                        { label: "ml/hr", value: "ml/hr" },
                                        { label: "ml/min", value: "ml/min" },
                                        // { label: "mg/hr", value: "mg/hr" },
                                        // { label: "grms/hr", value: "grms/hr" },
                                      ]}
                                      handleInputChange={(e) =>
                                        handleEditCurrentMedicineChange(
                                          "rateofinfusionvalue",
                                          validationOfNumberWithOnly1Decimal(
                                            e.target.value
                                          )
                                        )
                                      }
                                      placeholderProp={"Rate of Infusion"}
                                      onSelect={(selectedOption) =>
                                        handleEditCurrentMedicineChange(
                                          "rateofinfusionunit",
                                          selectedOption.label
                                        )
                                      }
                                      maxLength="6"
                                      defaultOptionProp={
                                        editCurrentMedicine.rateofinfusionunit
                                      }
                                      defaultValue={
                                        editCurrentMedicine.rateofinfusionvalue
                                      }
                                    />
                                    <PrimaryInput
                                      variant={"bothSide"}
                                      onChange={(e) =>
                                        handleEditCurrentMedicineChange(
                                          "additives",
                                          e.target.value
                                        )
                                      }
                                      inputPlace="Additives"
                                      defaultValue={editCurrentMedicine.additives}
                                    />
                                  </>
                                )}
                              </Box>

                              <CheckboxGroup colorScheme="green">
                                <Stack spacing={2} pt="20px" direction="row">
                                  <Checkbox
                                    isChecked={
                                      editCurrentMedicine.morning === 1
                                    }
                                    onChange={() =>
                                      handleEditCurrentMedicineChange(
                                        "morning",
                                        editCurrentMedicine.morning === 1
                                          ? 0
                                          : 1
                                      )
                                    }
                                  >
                                    Morning
                                  </Checkbox>
                                  <Checkbox
                                    isChecked={
                                      editCurrentMedicine.afternoon === 1
                                    }
                                    onChange={() =>
                                      handleEditCurrentMedicineChange(
                                        "afternoon",
                                        editCurrentMedicine.afternoon === 1
                                          ? 0
                                          : 1
                                      )
                                    }
                                  >
                                    Afternoon
                                  </Checkbox>
                                  <Checkbox
                                    isChecked={editCurrentMedicine.night === 1}
                                    onChange={() =>
                                      handleEditCurrentMedicineChange(
                                        "night",
                                        editCurrentMedicine.night === 1 ? 0 : 1
                                      )
                                    }
                                  >
                                    Night
                                  </Checkbox>
                                  <Spacer />{" "}
                                  {/* Add a spacer to push the next checkboxes to the right */}
                                  <Checkbox
                                    isChecked={
                                      editCurrentMedicine.beforefood === 1
                                    }
                                    onChange={() =>
                                      handleEditCurrentMedicineChange(
                                        "beforefood",
                                        editCurrentMedicine.beforefood === 1
                                          ? 0
                                          : 1
                                      )
                                    }
                                  >
                                    Before Food
                                  </Checkbox>
                                  <Checkbox
                                    isChecked={
                                      editCurrentMedicine.afterfood === 1
                                    }
                                    onChange={() =>
                                      handleEditCurrentMedicineChange(
                                        "afterfood",
                                        editCurrentMedicine.afterfood === 1
                                          ? 0
                                          : 1
                                      )
                                    }
                                  >
                                    After Food
                                  </Checkbox>
                                </Stack>
                              </CheckboxGroup>

                               <Divider my="10px" />

                  {/* <PrimaryInput
                    variant={"bothSide"}
                    onChange={(e) => handleEditCurrentMedicineChange("sos", e.target.value )}
                    // defaultValue={editCurrentMedicine.sos}
                    inputPlace="SOS"
  inputValue={editCurrentMedicine.sos}
                  /> */}
                  <Textarea
                    borderRadius='0'
                    placeholder="Custom Message"
                    defaultValue={editCurrentMedicine.custommessage}
                    onChange={(e) => handleEditCurrentMedicineChange("custommessage", e.target.value )}
                    inputValue={editCurrentMedicine.custommessage}
                  />

                  <Divider my="10px" />
                            </Box>
                            <Divider />
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              gap="10px"
                            >
                              {/* <Button onClick={() => setEditMedicine(null)}>
                            Cancel
                          </Button> */}
                              <PrimaryButton
                                buttonText="Cancel"
                                variant="grayBtn"
                                btnStyle={{
                                  w: "15%",
                                  color: COLORS.PRIMARY_COLOR,
                                  h: "2.5rem",
                                }}
                                onClick={() => setEditCurrentMedicine(null)}
                                isDisabled={false}
                              />
                              {/* <Button
                            colorScheme="blue"
                            onClick={handleSaveEditMedicine}
                          >
                            Save
                          </Button> */}
                              <PrimaryButton
                                buttonText="Save"
                                onClick={handleSaveEditCurrentMedicine}
                                isDisabled={false}
                                btnStyle={{
                                  w: "25%",
                                  //   color: COLORS.PRIMARY_COLOR,
                                  h: "2.5rem",
                                }}
                              />
                            </Box>
                          </Stack>
                        </Box>
                      )}
                  </Box>
                ))}

              {selectedMedicines
                .filter((medicine) => medicine.isactive !== 0)
                .map((medicine) => (
                  <Box key={medicine.id}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p="10px"
                      border="1px solid gray"
                      borderRadius="10px"
                    >
                      {screenType !== "mobile" && (
                        <>
                          <Box width="42%">
                            <Box fontWeight={"semibold"}>
                              {medicine.medicinename} (
                              {medicine.dosage ? (
                                <>
                                  {medicine.dosage} {medicine.dosageunit}
                                </>
                              ) : (
                                <>-/- {medicine.dosageunit}</>
                              )}
                              )
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                bg="#E6E7E966"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {medicine.medicinetype
                                  ? medicine.medicinetype
                                  : "-"}
                              </Box>
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                // bg="#E6E7E966"
                                bg="#73FF99"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                Prescribed
                              </Box>
                            </Box>
                            <Box fontSize={"xs"}>
                              Composition: {medicine.composition}
                            </Box>
                          </Box>
                          <Box width="8%">
                            {medicine.morning}-{medicine.afternoon}-
                            {medicine.night}
                          </Box>
                          <Box width="30%">
                            {medicine.beforefood === 1 &&
                              medicine.afterfood === 1 && (
                                <>Before & After Food</>
                              )}
                            {medicine.beforefood === 1 &&
                              (medicine.afterfood === 0 ||
                                medicine.afterfood === null) && (
                                <>Before Food</>
                              )}
                            {(medicine.beforefood === 0 ||
                              medicine.beforefood === null) &&
                              medicine.afterfood === 1 && <>After Food</>}{" "}
                            {(medicine.beforefood === 1 ||
                              medicine.afterfood === 1) &&
                              medicine.frequency &&
                              "-"}
                            {medicine.frequency &&
                              `${medicine.frequency} x per day`}
                          </Box>
                          <Box display="flex" alignItems="center" width="20%">
                            <Box
                              as="button"
                              // onClick={}
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() =>
                                fetchAiDoctorPrompts(medicine.medicinename)
                              }
                            >
                              <Image
                                w="26px"
                                src="/assets/svgs/ai-star-outline.svg"
                              />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              ml="10px"
                              onClick={() => handleEditMedicine(medicine)}
                            >
                              <Image
                                w="13px"
                                src="/assets/svgs/edit-gray.svg"
                              />
                            </Box>
                            <Box
                              as="button"
                              border="1px solid #E6E7E9"
                              borderRadius="full"
                              w="36px"
                              h="auto"
                              p="10px"
                              ml="10px"
                              onClick={() => handleDeleteMedicine(medicine)}
                            >
                              <Image w="13px" src="/assets/imgs/delete.png" />
                            </Box>
                          </Box>
                        </>
                      )}

                      {screenType == "mobile" && (
                        <>
                          <Box width="100%">
                            <Box fontWeight={"semibold"}>
                              {medicine.medicinename} ({medicine.dosage}
                              {medicine.dosageunit}){" "}
                              <Box
                                display="inline-block"
                                verticalAlign="bottom"
                                fontSize="10px"
                                fontWeight="600"
                                bg="#E6E7E966"
                                border="1px solid #E6E7E9"
                                p="5px 10px"
                                borderRadius="full"
                                w="fit-content"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {medicine.medicinetype
                                  ? medicine.medicinetype
                                  : "-"}
                              </Box>
                            </Box>
                            <Box fontSize={"xs"}>
                              Composition: {medicine.composition}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            w="100%"
                            flexDir="column"
                            gap="10px"
                          >
                            <Box width="100%">
                              {medicine.morning}-{medicine.afternoon}-
                              {medicine.night}
                            </Box>
                            <Box width="100%">
                              {medicine.beforefood === 1 &&
                                medicine.afterfood === 1 && (
                                  <>Before & After Food</>
                                )}
                              {medicine.beforefood === 1 &&
                                (medicine.afterfood === 0 ||
                                  medicine.afterfood === null) && (
                                  <>Before Food</>
                                )}
                              {(medicine.beforefood === 0 ||
                                medicine.beforefood === null) &&
                                medicine.afterfood === 1 && (
                                  <>After Food</>
                                )}{" "}
                              {(medicine.beforefood === 1 ||
                                medicine.afterfood === 1) &&
                                medicine.frequency &&
                                "-"}
                              {medicine.frequency &&
                                `${medicine.frequency} x per day`}
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              width="100%"
                            >
                              <Box
                                as="button"
                                // onClick={}
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                onClick={() =>
                                  fetchAiDoctorPrompts(medicine.medicinename)
                                }
                              >
                                <Image
                                  w="26px"
                                  src="/assets/svgs/ai-star-outline.svg"
                                />
                              </Box>
                              <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                ml="10px"
                                onClick={() => handleEditMedicine(medicine)}
                              >
                                <Image
                                  w="13px"
                                  src="/assets/svgs/edit-gray.svg"
                                />
                              </Box>
                              <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                ml="10px"
                                onClick={() => handleDeleteMedicine(medicine)}
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                    {editMedicine && editMedicine.id === medicine.id && (
                      <Box
                        p="20px"
                        border="1px solid gray"
                        borderRadius="10px"
                        mt="10px" // Add some margin-top to separate from the above row
                      >
                        <Stack spacing={4}>
                          <Flex
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box width="45%">
                              <Box w="100%" pos="relative" cursor="pointer">
                                <PrimaryInput
                                  variant="bothSide"
                                  inputPlace="Medicine name"
                                  inputValue={editMedicine.medicinename}
                                  onChange={(e) => {
                                    handleEditMedicineChange(
                                      "medicinename",
                                      e.target.value
                                    );
                                    filterMedicineNames(e.target.value);
                                    setShowMedicineNamesDropdown(0);
                                    setBoolValForSuggestions(
                                      !boolValForSuggestions
                                    );
                                  }}
                                  inputIdProp={"MedicineNameInAdd" + 0}
                                  boolProp={boolValForSuggestions}
                                  onKeyDown={(e) => handleEditKeyDown(e, 0)}
                                  autoFocus={showMedicineNamesDropdown === 0}
                                  autocomplete="off"
                                />
                                <Image
                                  top="33%"
                                  right="6.5%"
                                  w="15px"
                                  pos="absolute"
                                  src="/assets/imgs/double-arrow.png"
                                />
                              </Box>
                              {showMedicineNamesDropdown === 0 &&
                                suggestions.length > 0 && (
                                  <Menu isOpen={true}>
                                    <MenuButton
                                      as={Box}
                                      w="100%"
                                      display="block"
                                      pos="relative"
                                      zIndex="999"
                                    >
                                      <Box
                                        pos="absolute"
                                        top="100%"
                                        left="0"
                                        w="100%"
                                        bg="white"
                                        borderTop="none"
                                        border="1px solid #E6E7E9"
                                        borderRadius="0 0 12px 12px"
                                      />
                                    </MenuButton>
                                    <MenuList
                                      w="100%"
                                      borderRadius="0 0 12px 12px"
                                      zIndex="999"
                                      bg="white"
                                      maxH="150px"
                                      overflow="hidden auto"
                                      ref={suggestionsMenuRef}
                                    >
                                      {suggestions.map((suggestion, sIndex) => (
                                        <MenuItem
                                          key={sIndex}
                                          onClick={() =>
                                            handleEditSelect(suggestion, 0)
                                          }
                                          cursor="pointer"
                                          p="1rem"
                                          py="0.5rem"
                                          borderBottom="1px solid #E6E7E9"
                                          bg={
                                            focusedItemIndex === sIndex
                                              ? "#f0f0f0"
                                              : "white"
                                          }
                                        >
                                          <Text
                                            fontSize="0.875rem"
                                            fontWeight="500"
                                          >
                                            {suggestion.productname +
                                              " (" +
                                              suggestion.remainingquantity +
                                              " Qty)"}
                                          </Text>
                                        </MenuItem>
                                      ))}
                                    </MenuList>
                                  </Menu>
                                )}
                            </Box>
                            <Box width="45%">
                              <PrimaryInput
                                key={compositionEditKey}
                                type="text"
                                variant="bothSide"
                                inputPlace="Medicine Composition"
                                defaultValue={editMedicine.composition}
                                onChange={(e) =>
                                  handleEditMedicineChange(
                                    "composition",
                                    e.target.value
                                  )
                                }
                              />{" "}
                            </Box>
                            <Box width="10%">
                              <PrimaryButton
                                buttonText="Fetch"
                                verticalAlign="bottom"
                                variant="grayBtn"
                                btnStyle={{
                                  w: "100%",
                                  color: COLORS.PRIMARY_COLOR,
                                  verticalAlign: "bottom",
                                  ml: "4px",
                                  mb: "0px",
                                  h: "2.8rem",
                                }}
                                onClick={() =>
                                  fetchCompositionForEdit(
                                    editMedicine.medicinename
                                  )
                                }
                                isDisabled={false}
                              />
                            </Box>
                          </Flex>
                          <RadioGroup
                            defaultValue={editMedicine.medicinetype}
                            onChange={(value) => {
                              handleEditMedicineChange("medicinetype", value);

                              if (value === "Syrup") {
                                handleEditMedicineChange("dosageunit", "ml");
                              } else if (value === "Tablet") {
                                handleEditMedicineChange("dosageunit", "mg");
                              } else if (value === "Capsule") {
                                handleEditMedicineChange("dosageunit", "mg");
                              } else if (value === "Injection") {
                                handleEditMedicineChange("dosageunit", "ml");
                              } else if (value === "Ointment") {
                                handleEditMedicineChange("dosageunit", "grms");
                              } else if (value === "Nebulization") {
                                handleEditMedicineChange("dosageunit", "ml");
                              } else if (value === "IVFluids") {
                                handleEditMedicineChange("dosageunit", "ml");
                              }
                            }}
                          >
                            <Stack direction="row" gap="20px" wrap="wrap">
                              <Radio value="Tablet">Tablet</Radio>
                              <Radio value="Syrup">Syrup</Radio>
                              <Radio value="Capsule">Capsule</Radio>
                              <Radio value="Injection">Injection</Radio>
                              {/* <Radio value="Ointment">Ointment</Radio> */}
                              <Radio value="Ointment">
                                Ointments / Creams / Gels
                              </Radio>
                              <Radio value="Nebulization">Nebulization</Radio>
                              <Radio value="IVFluids">IV Fluids</Radio>
                            </Stack>
                          </RadioGroup>
                          <Divider />
                          <Box>
                            <Box display="flex" flexDir="row">
                              <MiniDropdownInput
                                variant="bothSide"
                                options={[
                                  { label: "mg", value: "mg" },
                                  { label: "ml", value: "ml" },
                                  { label: "grms", value: "grms" },
                                ]}
                                // placeholderProp="Dosage"
                                placeholderProp={
                                  editMedicine.medicinetype == "Syrup" ||
                                  editMedicine.medicinetype == "Injection" ||
                                  editMedicine.medicinetype == "Nebulization" ||
                                  editMedicine.medicinetype == "IVFluids"
                                    ? "Volume"
                                    : "Dosage"
                                }
                                defaultValue={editMedicine.dosage}
                                defaultOptionProp={editMedicine.dosageunit}
                                onSelect={(value) =>
                                  handleEditMedicineChange(
                                    "dosageunit",
                                    value.label
                                  )
                                }
                                // changes here
                                handleInputChange={(event) =>
                                  handleEditMedicineChange(
                                    "dosage",
                                    // event.target.value
                                    validationOfNumberWithOnly1Decimal(
                                      event.target.value
                                    )
                                  )
                                }
                                inputVal={editMedicine.dosage}
                                maxLength="6"
                              />
                              <DropdownInput
                                variant="bothSide"
                                dropDownPlace="Consumption frequency"
                                options={["1", "2", "3", "4"]}
                                onClick={(value) =>
                                  handleEditMedicineChange("frequency", value)
                                }
                                defaultOption={editMedicine.frequency}
                              />

                              <DropdownInput
                                variant="bothSide"
                                dropDownPlace="Duration"
                                options={[
                                  "Until symptoms resolve",
                                  "Hours",
                                  "Days",
                                  "Weeks",
                                  "Months",
                                  "Years",
                                ]} // Interval units as options
                                onClick={(value) =>
                                  handleEditMedicineChange(
                                    "frequencyduration",
                                    value
                                  )
                                }
                                // defaultOption={intervalOption}
                              />
                            </Box>
                            {/* <Flex
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box width="45%">
                                <Select
                                  value={intervalOption}
                                  onChange={handleIntervalChange}
                                  placeholder="Select Interval"
                                >
                                  <option value="Days">Days</option>
                                  <option value="Weeks">Weeks</option>
                                  <option value="Months">Months</option>
                                  <option value="Years">Years</option>
                                </Select>
                              </Box>
                              <Box width="45%">
                                <RadioGroup
                                  value={radioValue}
                                  onChange={handleRadioChange}
                                >
                                  <Stack direction="row" spacing={4}>
                                    <Radio value="1">1</Radio>
                                    <Radio value="2">2</Radio>
                                    <Radio value="3">3</Radio>
                                    <Radio value="4">4</Radio>
                                    <Radio value="5">5</Radio>
                                    <Radio value="6">6</Radio>
                                  </Stack>
                                </RadioGroup>
                              </Box>
                            </Flex> */}

                            {/* <Box display="flex" flexDir="row">
                            <Box width="25%"
                            >
                                <Select
                                  value={intervalOption}
                                  onChange={handleIntervalChange}
                                  placeholder="Select Interval"
                                >
                                  <option value="Days">Days</option>
                                  <option value="Weeks">Weeks</option>
                                  <option value="Months">Months</option>
                                  <option value="Years">Years</option>
                                </Select>
                              </Box>
                              <DatePicker
                                // disableFutureDates={true}
                                defaultDate={editMedicine.medicationstartdate}
                                minDate={newMedicine.medicationstartdate}
                                onDateChange={(selectedDateValue) =>
                                  handleEditMedicineChange(
                                    "medicationstartdate",
                                    selectedDateValue
                                  )
                                }
                                placeholderProp={"Start Date"}
                                calendarStlye={{
                                  left: "-18px",
                                  right: "unset",
                                }}
                              />
                              <DatePicker
                                key={editMedicine.medicationenddate} 
                                defaultDate={editMedicine.medicationenddate}
                                minDate={newMedicine.medicationstartdate}
                                onDateChange={(selectedDateValue) =>
                                  handleEditMedicineChange(
                                    "medicationenddate",
                                    selectedDateValue
                                  )
                                }
                                placeholderProp={"End Date"}
                                calendarStlye={{
                                  transform: "scale(0.9)",
                                  right: "-22px",
                                  top: "30px",
                                }}
                              />
                            </Box> */}

                            <Box display="flex" flexDir="row">
                              <Box width="25%">
                                <DropdownInput
                                  variant="bothSide"
                                  dropDownPlace={
                                    editMedicine.medicinetype === "IVFluids"
                                      ? "Infusion Frequency"
                                      : editMedicine.medicinetype === "Ointment"
                                      ? "Application Duration"
                                      : "Duration"
                                  }
                                  options={["Days", "Weeks", "Months", "Years"]} // Interval units as options
                                  onClick={handleIntervalChange} // Handle changes with setIntervalOption
                                  defaultOption={intervalOption}
                                />
                              </Box>
                              <Box width="25%">
                                <PrimaryInput
                                  variant={"bothSide"}
                                  inputType="number"
                                  defaultValue={intervalValue}
                                  onChange={handleIntervalValueChange}
                                  inputPlace={
                                    editMedicine.medicinetype === "IVFluids"
                                      ? "Infusion Duration"
                                      : "Interval Value"
                                  }
                                />
                              </Box>
                              <Box width={"50%"} display="flex" flexDir="row">
                                <DatePicker
                                  dateWidth="50%"
                                  defaultDate={editMedicine.medicationstartdate}
                                  minDate={newMedicine.medicationstartdate}
                                  onDateChange={(selectedDateValue) =>
                                    handleEditMedicineChange(
                                      "medicationstartdate",
                                      selectedDateValue
                                    )
                                  }
                                  placeholderProp={"Start Date"}
                                  calendarStlye={{
                                    left: "-18px",
                                    right: "unset",
                                  }}
                                />
                                <DatePicker
                                  dateWidth="50%"
                                  key={editMedicine.medicationenddate}
                                  defaultDate={editMedicine.medicationenddate}
                                  disableDatePicker={true}
                                  minDate={newMedicine.medicationstartdate}
                                  onDateChange={(selectedDateValue) =>
                                    handleEditMedicineChange(
                                      "medicationenddate",
                                      selectedDateValue
                                    )
                                  }
                                  placeholderProp={"End Date"}
                                  calendarStlye={{
                                    transform: "scale(0.9)",
                                    right: "-22px",
                                    top: "30px",
                                    pointerEvents: "none", // Disable interaction
                                    opacity: 0.5, // Visually indicate disabled state
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box display="flex" flexDir="row">
                              {editMedicine.medicinetype == "Injection" && (
                                <>
                                  <Box width={"50%"}>
                                    <DropdownInput
                                      variant="downSide"
                                      dropDownPlace="Route of Administration"
                                      options={[
                                        "Intravenous (IV)",
                                        "Intramuscular (IM)",
                                        "Subcutaneous (SC)",
                                      ]}
                                      onClick={(value) =>
                                        handleEditMedicineChange(
                                          "routeofadministration",
                                          value
                                        )
                                      }
                                      defaultOption={
                                        editMedicine.routeofadministration
                                      }
                                    />
                                  </Box>
                                  <Box width={"50%"}>
                                    <PrimaryInput
                                      variant={"bothSide"}
                                      onChange={(e) =>
                                        handleEditMedicineChange(
                                          "timingofinjection",
                                          e.target.value
                                        )
                                      }
                                      inputPlace="Timing"
                                      defaultValue={
                                        editMedicine.timingofinjection
                                      }
                                    />
                                  </Box>
                                </>
                              )}
                              {editMedicine.medicinetype == "Ointment" && (
                                <>
                                  <DropdownInput
                                    variant="downSide"
                                    dropDownPlace="Quantity"
                                    options={[
                                      "Pea-sized",
                                      "Thin layer",
                                      "Fingertip unit (FTU)",
                                      "Half a fingertip",
                                    ]}
                                    onClick={(value) =>
                                      handleEditMedicineChange(
                                        "quantitytobeapplied",
                                        value
                                      )
                                    }
                                    defaultOption={
                                      editMedicine.quantitytobeapplied
                                    }
                                  />
                                  <PrimaryInput
                                    variant={"bothSide"}
                                    onChange={(e) =>
                                      handleEditMedicineChange(
                                        "areaofapplication",
                                        e.target.value
                                      )
                                    }
                                    inputPlace="Area of Application"
                                    defaultValue={
                                      editMedicine.areaofapplication
                                    }
                                  />
                                </>
                              )}

                              {editMedicine.medicinetype == "Nebulization" && (
                                <>
                                  <DropdownInput
                                    variant="downSide"
                                    dropDownPlace="Diluent"
                                    options={[
                                      "Normal saline",
                                      "Distilled water",
                                      "3% saline",
                                    ]}
                                    onClick={(value) =>
                                      handleEditMedicineChange("diluent", value)
                                    }
                                    defaultOption={editMedicine.diluent}
                                  />
                                </>
                              )}

                              {editMedicine.medicinetype == "IVFluids" && (
                                <>
                                  <MiniDropdownInput
                                    variant="bothside"
                                    options={[
                                      { label: "ml/hr", value: "ml/hr" },
                                      { label: "ml/min", value: "ml/min" },
                                      // { label: "mg/hr", value: "mg/hr" },
                                      // { label: "grms/hr", value: "grms/hr" },
                                    ]}
                                    handleInputChange={(e) =>
                                      handleEditMedicineChange(
                                        "rateofinfusionvalue",
                                        validationOfNumberWithOnly1Decimal(
                                          e.target.value
                                        )
                                      )
                                    }
                                    placeholderProp={"Rate of Infusion"}
                                    onSelect={(selectedOption) =>
                                      handleEditMedicineChange(
                                        "rateofinfusionunit",
                                        selectedOption.label
                                      )
                                    }
                                    maxLength="6"
                                    defaultOptionProp={
                                      editMedicine.rateofinfusionunit
                                    }
                                    defaultValue={
                                      editMedicine.rateofinfusionvalue
                                    }
                                  />
                                  <PrimaryInput
                                    variant={"bothSide"}
                                    onChange={(e) =>
                                      handleEditMedicineChange(
                                        "additives",
                                        e.target.value
                                      )
                                    }
                                    inputPlace="Additives"
                                    defaultValue={editMedicine.additives}
                                  />
                                </>
                              )}
                            </Box>

                            <CheckboxGroup colorScheme="green">
                              <Stack spacing={2} pt="20px" direction="row">
                                <Checkbox
                                  isChecked={editMedicine.morning === 1}
                                  onChange={() =>
                                    handleEditMedicineChange(
                                      "morning",
                                      editMedicine.morning === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  Morning
                                </Checkbox>
                                <Checkbox
                                  isChecked={editMedicine.afternoon === 1}
                                  onChange={() =>
                                    handleEditMedicineChange(
                                      "afternoon",
                                      editMedicine.afternoon === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  Afternoon
                                </Checkbox>
                                <Checkbox
                                  isChecked={editMedicine.night === 1}
                                  onChange={() =>
                                    handleEditMedicineChange(
                                      "night",
                                      editMedicine.night === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  Night
                                </Checkbox>
                                <Spacer />{" "}
                                {/* Add a spacer to push the next checkboxes to the right */}
                                <Checkbox
                                  isChecked={editMedicine.beforefood === 1}
                                  onChange={() =>
                                    handleEditMedicineChange(
                                      "beforefood",
                                      editMedicine.beforefood === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  Before Food
                                </Checkbox>
                                <Checkbox
                                  isChecked={editMedicine.afterfood === 1}
                                  onChange={() =>
                                    handleEditMedicineChange(
                                      "afterfood",
                                      editMedicine.afterfood === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  After Food
                                </Checkbox>
                              </Stack>
                            </CheckboxGroup>

                            <Divider my="10px" />

{/* <PrimaryInput
  variant={"bothSide"}
  onChange={(e) => handleEditMedicineChange("sos", e.target.value )}
  // defaultValue={editMedicine.sos}
  inputPlace="SOS"
  inputValue={editMedicine.sos}
  
/> */}
<Textarea
  borderRadius='0'
  placeholder="Custom Message"
  defaultValue={editMedicine.custommessage}
  // onChange={handleCustomMessage}
  onChange={(e) => handleEditMedicineChange("custommessage", e.target.value )}
  inputValue={editMedicine.custommessage}
/>

<Divider my="10px" />
                          </Box>
                          <Divider />
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            gap="10px"
                          >
                            {/* <Button onClick={() => setEditMedicine(null)}>
                            Cancel
                          </Button> */}
                            <PrimaryButton
                              buttonText="Cancel"
                              variant="grayBtn"
                              btnStyle={{
                                w: "15%",
                                color: COLORS.PRIMARY_COLOR,
                                h: "2.5rem",
                              }}
                              onClick={() => setEditMedicine(null)}
                              isDisabled={false}
                            />
                            {/* <Button
                            colorScheme="blue"
                            onClick={handleSaveEditMedicine}
                          >
                            Save
                          </Button> */}
                            <PrimaryButton
                              buttonText="Save"
                              onClick={handleSaveEditMedicine}
                              isDisabled={false}
                              btnStyle={{
                                w: "25%",
                                //   color: COLORS.PRIMARY_COLOR,
                                h: "2.5rem",
                              }}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                ))}

            
            </Stack>
          </Box>
        </Box>

        <AiModal
          ref={AIChatModel}
          modalSize="420px"
          //icon="/assets/imgs/ai-doctor.png"
          header={
            <>
              <Box display="flex" flexDir="column">
                <Text
                  fontSize="1rem"
                  fontWeight="700"
                  backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                  color="transparent"
                  backgroundClip="text"
                >
                  Aira - AI
                </Text>
                <Text fontSize="0.75rem" fontWeight="400">
                  Chat with your AI health guide
                </Text>
              </Box>
            </>
          }
          headerStyle={{ p: "24px 32px", lineHeight: "normal" }}
          closeBtnStyle={{ top: "18px" }}
          footerStyle={{
            maxH: "35%",
            overflow: "hidden auto",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
          withoutBg={true}
          backDropBlur="10px"
          body={
            <>
              <Flex flexDirection="column" height="100%">
                <Box
                  p="24px 32px"
                  ref={chatContainerRef}
                  className="chat-container"
                  style={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 160px)",
                  }}
                >
                  {initialRender && remainingquestions.length > 0 && (
                    <Box>
                      {remainingquestions.map((step, index) => (
                        <Box
                          key={index}
                          as="button"
                          w="100%"
                          p="16px"
                          mb="16px"
                          fontSize="14px"
                          fontWeight="600"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          borderRadius="10px"
                          border="1px solid #7795DC"
                          bg="#EAEDF5"
                          color="#163E9E"
                          onClick={() => GetAIAnswer(step.value)}
                          className={`question-box ${
                            animate ? "slide-in" : ""
                          }`}
                        >
                          {step.value ? step.value : "-"}
                          <Image src="/assets/imgs/right-icon.png" />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {aianswers.map((step, index) => (
                    <React.Fragment key={index}>
                      <Box
                        as="div"
                        bg="#061027"
                        p="12px 16px"
                        w="fit-content"
                        float="right"
                        borderRadius="10px 10px 1px 10px"
                        className={`answer-box ${animate ? "slide-in" : ""}`}
                      >
                        <Text fontSize="14px" fontWeight="500" color="white">
                          {step.question ? step.question : "-"}
                        </Text>
                      </Box>

                      <Box
                        as="div"
                        my="20px"
                        w="fit-content"
                        display="flex"
                        alignItems="end"
                        float="left"
                        className={`answer-box ${animate ? "slide-in" : ""}`}
                      >
                        <Image
                          bg="#061027"
                          p="4px"
                          mr="8px"
                          borderRadius="6px"
                          src="/assets/imgs/ai-chat-logo.png"
                        />
                        <Text
                          bg="#E6E7E980"
                          p="12px 16px"
                          fontSize="14px"
                          fontWeight="500"
                          color="#061027"
                          borderRadius="10px 10px 10px 1px"
                        >
                          {step.answer ? step.answer : <TypingLoader />}
                        </Text>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              </Flex>
            </>
          }
          footerActions={
            !initialRender && (
              <Box
                p="24px 32px"
                display="flex"
                w="100%"
                h="100%"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box w="100%">
                  {remainingquestions.map((step, index) => (
                    <Box
                      key={index}
                      as="button"
                      w="100%"
                      p="16px"
                      mb="16px"
                      fontSize="14px"
                      fontWeight="600"
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius="10px"
                      border="1px solid #7795DC"
                      bg="#EAEDF5"
                      color="#163E9E"
                      onClick={() => GetAIAnswer(step.value)}
                      className={`question-box ${animate ? "slide-in" : ""}`}
                    >
                      {step.value ? step.value : "-"}
                      <Image src="/assets/imgs/right-icon.png" />
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          }
        />

        {/* DynamicModal for Close Prescription */}
        <DynamicModal
          ref={drugInteractionAlertModal}
          modalHeader={`Aira - AI: Your AI Drug Interaction Alert`}
          modalBody={
            <>
              {drugInteractionAlerts.map((interaction, index) => (
                <Text key={index} fontSize="14px" fontWeight="600">
                  •{interaction.message}
                </Text>
              ))}
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                buttonText="Close"
                variant="grayBtn"
                btnStyle={{
                  w: "100%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                onClick={() => {
                  drugInteractionAlertModal.current.closeModal();
                }}
              />
            </>
          }
        />

        <DynamicModalForTwo
          ref={aiRecommendMedicineRef}
          modalWidth="45%"
          modalHeader={`Aira - AI: Alternatives`}
          modalBody={
            <>
              {recommendMedicinesAiResponse.map((medicine, index) => (
                <Box key={medicine.id}>
                  <Box
                    p="10px"
                    border="1px solid gray"
                    borderRadius="10px"
                    mt="10px"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      // p="10px"
                      // border="1px solid gray"
                      // borderRadius="10px"
                      // mt="10px"
                    >
                      <Checkbox
                        isChecked={selectedRecommendedMedicinesAiResponses.includes(
                          index
                        )}
                        onChange={() =>
                          handleRecommendedMedicinesCheckboxChange(index)
                        }
                        mr={4}
                        alignSelf="center" // Vertically center the checkbox
                      ></Checkbox>
                      <Box width="100%">
                        <Box fontWeight={"semibold"}>
                          {medicine.name}
                          <Box
                            display="inline-block"
                            verticalAlign="bottom"
                            fontSize="10px"
                            fontWeight="600"
                            bg="#E6E7E966"
                            border="1px solid #E6E7E9"
                            p="5px 10px"
                            borderRadius="full"
                            w="fit-content"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {medicine.medicinetype
                              ? medicine.medicinetype
                              : "Tablet"}
                            {/* Tablet */}
                          </Box>
                        </Box>
                        <Box fontSize={"xs"}>
                          Composition: {medicine.composition}
                        </Box>
                      </Box>
                      {/* <Box width="8%">
                        {medicine.morning}-{medicine.evening}-{medicine.night}
                      </Box> */}
                      {/* <Box width="35%">
                        {medicine.beforefood === 1 &&
                          medicine.afterfood === 1 && <>Before & After Food</>}
                        {medicine.beforefood === 1 &&
                          (medicine.afterfood === 0 ||
                            medicine.afterfood === null) && <>Before Food</>}
                        {(medicine.beforefood === 0 ||
                          medicine.beforefood === null) &&
                          medicine.afterfood === 1 && <>After Food</>}{" "}
                        {(medicine.beforefood === 1 ||
                          medicine.afterfood === 1) &&
                          medicine.frequency &&
                          "-"}
                        {medicine.frequency &&
                          `${medicine.frequency} x per day`}
                      </Box> */}

                      {/* <Box>
                        
                        <Box display="flex" justifyContent="space-between">
                          <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() =>
                              fetchAiDoctorPrompts(medicine.medicinename)
                            }
                          >
                            <Image
                              w="26px"
                              src="/assets/svgs/ai-star-outline.svg"
                            />
                          </Box>
                          <Box
                            as="button"
                            border="1px solid #E6E7E9"
                            borderRadius="full"
                            w="36px"
                            h="auto"
                            p="10px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            onClick={() => handleEditMedicine(medicine)}
                          >
                            <Image w="13px" src="/assets/svgs/edit-gray.svg" />
                          </Box>
                        </Box>
                      </Box> */}
                    </Box>
                    {/* Reason Box */}
                    {/* <Box
                      as="p"
                      p="2px"
                      bg="#F9FAFB"
                      w="auto"
                      borderLeft="2px solid #14C585"
                      fontSize="0.875rem"
                      fontWeight="500"
                      mt="2px"
                      ml="3rem"
                    >
                      {medicine?.reason}{" "}
                    </Box> */}
                  </Box>

                  {/* {editMedicine && editMedicine.id === medicine.id && (
                    <Box
                      p="20px"
                      border="1px solid gray"
                      borderRadius="10px"
                      mt="10px" 
                    >
                      <Stack spacing={4}>
                        <InputGroup>
                          <PrimaryInput
                            type="text"
                            variant="bothSide"
                            inputPlace="Medicine name*"
                            defaultValue={editMedicine.medicinename}
                            onChange={(e) =>
                              handleEditMedicineChange(
                                "medicinename",
                                e.target.value
                              )
                            }
                          />
                          <PrimaryInput
                            key={compositionEditKey}
                            type="text"
                            variant="bothSide"
                            inputPlace="Medicine Composition"
                            defaultValue={editMedicine.composition}
                            onChange={(e) =>
                              handleEditMedicineChange(
                                "composition",
                                e.target.value
                              )
                            }
                          />{" "}
                          <PrimaryButton
                            buttonText="Fetch"
                            verticalAlign="bottom"
                            variant="grayBtn"
                            btnStyle={{
                              w: "15%",
                              color: COLORS.PRIMARY_COLOR,
                              verticalAlign: "bottom",
                              ml: "4px",
                              mb: "0px",
                              h: "2.8rem",
                            }}
                            onClick={() =>
                              fetchCompositionForEdit(editMedicine.medicinename)
                            }
                            isDisabled={false}
                          />
                        </InputGroup>
                        <RadioGroup
                          defaultValue={editMedicine.medicinetype}
                          onChange={(value) => {
                            handleEditMedicineChange("medicinetype", value);

                            if (value === "Syrup") {
                              handleEditMedicineChange("dosageunit", "ml");
                            } else if (value === "Tablet") {
                              handleEditMedicineChange("dosageunit", "mg");
                            }
                          }}
                        >
                          <Stack direction="row" gap="20px">
                            <Radio value="Tablet">Tablet</Radio>
                            <Radio value="Syrup">Syrup</Radio>
                          </Stack>
                        </RadioGroup>
                        <Divider />
                        <Box>
                          <Box display="flex" flexDir="row">
                            <MiniDropdownInput
                              variant="bothSide"
                              options={[
                                { label: "mg", value: "mg" },
                                { label: "ml", value: "ml" },
                              ]}
                              placeholderProp="Dosage"
                              defaultValue={editMedicine.dosage}
                              defaultOptionProp={editMedicine.dosageunit}
                              onSelect={(value) =>
                                handleEditMedicineChange(
                                  "dosageunit",
                                  value.label
                                )
                              }
                              handleInputChange={(event) =>
                                handleEditMedicineChange(
                                  "dosage",
                                  event.target.value
                                )
                              }
                              maxLength="6"
                            />
                            <DropdownInput
                              variant="bothSide"
                              dropDownPlace="Consumption frequency per day"
                              options={["1", "2", "3", "4"]}
                              onClick={(value) =>
                                handleEditMedicineChange("frequency", value)
                              }
                              defaultOption={editMedicine.frequency}
                            />
                          </Box>
                          <Box display="flex" flexDir="row">
                            <DatePicker
                              defaultDate={moment(
                                editMedicine.medicationstartdate,
                                ["YYYY-MM-DD", "DD-MM-YYYY"]
                              ).format("DD-MM-YYYY")}
                              minDate={editMedicine.medicationstartdate}
                              onDateChange={(selectedDateValue) =>
                                handleEditMedicineChange(
                                  "medicationstartdate",
                                  moment(selectedDateValue).format("YYYY-MM-DD")
                                )
                              }
                              placeholderProp={"Start Date"}
                              calendarStlye={{
                                left: "-18px",
                                right: "unset",
                              }}
                            />
                            <DatePicker
                              defaultDate={moment(
                                editMedicine.medicationenddate,
                                ["YYYY-MM-DD", "DD-MM-YYYY"]
                              ).format("DD-MM-YYYY")}
                              minDate={editMedicine.medicationstartdate}
                              onDateChange={(selectedDateValue) =>
                                handleEditMedicineChange(
                                  "medicationenddate",
                                  moment(selectedDateValue).format("YYYY-MM-DD")
                                )
                              }
                              placeholderProp={"End Date"}
                              calendarStlye={{
                                transform: "scale(0.9)",
                                right: "-22px",
                                top: "30px",
                              }}
                            />
                          </Box>

                          <CheckboxGroup colorScheme="green">
                            <Stack spacing={2} pt="20px" direction="row">
                              <Checkbox
                                isChecked={editMedicine.morning === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "morning",
                                    editMedicine.morning === 1 ? 0 : 1
                                  )
                                }
                              >
                                Morning
                              </Checkbox>
                              <Checkbox
                                isChecked={editMedicine.evening === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "evening",
                                    editMedicine.evening === 1 ? 0 : 1
                                  )
                                }
                              >
                                Evening
                              </Checkbox>
                              <Checkbox
                                isChecked={editMedicine.night === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "night",
                                    editMedicine.night === 1 ? 0 : 1
                                  )
                                }
                              >
                                Night
                              </Checkbox>
                              <Spacer />{" "}
                              <Checkbox
                                isChecked={editMedicine.beforefood === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "beforefood",
                                    editMedicine.beforefood === 1 ? 0 : 1
                                  )
                                }
                              >
                                Before Food
                              </Checkbox>
                              <Checkbox
                                isChecked={editMedicine.afterfood === 1}
                                onChange={() =>
                                  handleEditMedicineChange(
                                    "afterfood",
                                    editMedicine.afterfood === 1 ? 0 : 1
                                  )
                                }
                              >
                                After Food
                              </Checkbox>
                            </Stack>
                          </CheckboxGroup>
                        </Box>
                        <Divider />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap="10px"
                        >
                          <PrimaryButton
                            buttonText="Cancel"
                            variant="grayBtn"
                            btnStyle={{
                              w: "15%",
                              color: COLORS.PRIMARY_COLOR,
                              h: "2.5rem",
                            }}
                            onClick={() => setEditMedicine(null)}
                            isDisabled={false}
                          />
                          <PrimaryButton
                            buttonText="Save"
                            onClick={handleSaveEditMedicine}
                            isDisabled={false}
                            btnStyle={{
                              w: "25%",
                              h: "2.5rem",
                            }}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  )} */}
                </Box>
              ))}
            </>
          }
          modalFooterBtn={
            <>
              <PrimaryButton
                variant="deleteBtn"
                buttonText="Discard"
                btnStyle={{
                  w: "48%",
                  h: "2.5rem",
                }}
                onClick={() => {
                  aiRecommendMedicineRef.current.closeModal();
                }}
              />

              <Spacer />

              <PrimaryButton
                buttonText="Save selected items"
                variant="grayBtn"
                btnStyle={{
                  w: "48%",
                  color: COLORS.PRIMARY_COLOR,
                  h: "2.5rem",
                }}
                isDisabled={
                  !(selectedRecommendedMedicinesAiResponses.length > 0)
                }
                onClick={() => {
                  saveSelectedRecommendedMedicinesToOrder();
                  aiRecommendMedicineRef.current.closeModal();
                }}
              />
            </>
          }
        />
      </Box>
    );
  }
);

export default PrescriptionComponent;
