import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Checkbox,
  Button,
  FormControl,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Stack,
  Input,
  Badge,
  Text,
  Spacer,
  Image,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import {
  updateDoctorNotes,
  GetDoctorNotes,
  DeleteOrder,
  GetDoctorMasterData,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";

import { PrimaryButton, Toaster, PrimaryInput } from "../../../components";

import { COLORS } from "../../styles/colors";
import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import DynamicModal from "../../dynamic-modal";
import {
  AiGenerateAssessmentPlan,
  FetchMyHealthAIData,
} from "../../../middleware/services/visits/aicalls";
import PrimaryAssessmentInput from "./assessment-input";
import AIFeedbackFooter from "./ai-feedback";
import AICPUFeedbackFooter from "./ai-feedback/cpu-feedback";
import { actionAccessMain } from "../../../auth";

const DoctorNotesAssessment = ({
  patientId,
  patientname,
  patientgender,
  patientage,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  triggerFetchAssessmentPlans,
  fetchDoctorNotesEmptyIndication,
  submitOnTabSwitch
}) => {
  //---------------------------------------
  const buttons = [
    "Primary Health Issues",
    "Infectious Diseases",
    "Non Infectious Diseases",
    "Trauma",
    "Gynaec & Obstetrics",
    "Ophthalmology",
    "Dental Care",
  ];

  const [selectedButton, setSelectedButton] = useState("");
  const [selectedButtonInitial, setSelectedButtonInitial] = useState("");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  //---------------------------------------
  const useGPUChatbot = !!navigator.gpu;
  // const useGPUChatbot = false;

  useEffect(() => {
    if (triggerFetchAssessmentPlans) {
      fetchAssessmentPlans();
    }
  }, [triggerFetchAssessmentPlans]);

  const { isLoading, showLoader, dismissLoader } = useLoader();

  const [doctorNotes, setDoctorNotes] = useState({});
  const [aiResponse, setAiResponse] = useState([]);

  const [selectedAiResponses, setSelectedAiResponses] = useState([]);

  const noAiResponseModal = useRef();
  const aiGenerateAssessmentPlanRef = useRef();

  // function for no data msg

  function checkIndividualFields(jsonInput) {
    if (typeof jsonInput !== "object" || jsonInput === null) {
      return null;
    }

    const {
      chiefcomplaint,
      historyofpresentillness,
      hpitext,
      physicalexamtext,
      rostext,
      physicalexam,
      reviewofsystem,
    } = jsonInput;

    const isArrayNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;
    const isChiefComplaintNotNull = (obj) => obj && obj.chiefcomplaint;

    const isHPITextNotNull = (obj) => obj && obj.description;
    const isPETextNotNull = (obj) => obj && obj.description;
    const isROSTextNotNull = (obj) => obj && obj.description;

    const fieldStatus = {
      chiefcomplaint: isChiefComplaintNotNull(chiefcomplaint),
      historyofpresentillness:
        isArrayNotEmpty(historyofpresentillness) || isHPITextNotNull(hpitext),
      physicalexam:
        isArrayNotEmpty(physicalexam) || isPETextNotNull(physicalexamtext),
      reviewofsystem:
        isArrayNotEmpty(reviewofsystem) || isROSTextNotNull(rostext),
    };

    const filledCount = Object.values(fieldStatus).filter(Boolean).length;

    return {
      fieldStatus,
      filledCount,
    };
  }

  function renderModalContent(jsonInput) {
    const { fieldStatus, filledCount } = checkIndividualFields(jsonInput);

    const fieldLabels = {
      chiefcomplaint: "Chief Complaint",
      historyofpresentillness: "History of Present Illness",
      physicalexam: "Physical Exam",
      reviewofsystem: "Review of Systems",
    };

    const getMessage = () => {
      if (filledCount >= 2) return null;
      if (filledCount === 1) return "Please fill in one more field.";
      return "Please fill in at least two fields.";
    };

    return (
      <>
        <Text fontSize="14px" fontWeight="600">
          Insufficient data is available to draw inferences. Please ensure that
          relevant notes are provided.
        </Text>
        <ul>
          {Object.entries(fieldStatus).map(([key, isFilled]) => (
            <li
              key={key}
              style={{ textDecoration: isFilled ? "line-through" : "none" }}
            >
              {fieldLabels[key]}
            </li>
          ))}
        </ul>
        {getMessage() && (
          <Text fontSize="14px" fontWeight="600">
            {getMessage()}
          </Text>
        )}
      </>
    );
  }

  //------------------------------AI Feedback

  const [aiInputForFeedback, setAiInputForFeedback] = useState({});
  const [aiOutputForFeedback, setAiOutputForFeedback] = useState({});

  //-----------------------AI Recommendations

  function checkFields(jsonInput) {
    // Check if the input is an object
    if (typeof jsonInput !== "object" || jsonInput === null) {
      return false;
    }

    // Extract the necessary fields
    const {
      chiefcomplaint,
      historyofpresentillness,
      hpitext,
      physicalexamtext,
      rostext,
      physicalexam,
      reviewofsystem,
    } = jsonInput;

    // Helper function to check if an array is not empty
    const isArrayNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;

    // Helper function to check if the chief complaint is not null
    const isChiefComplaintNotNull = (obj) => obj && obj.chiefcomplaint;

    const isHPITextNotNull = (obj) => obj && obj.description;
    const isPETextNotNull = (obj) => obj && obj.description;
    const isROSTextNotNull = (obj) => obj && obj.description;

    // Count the non-null fields
    let nonNullCount = 0;
    if (isChiefComplaintNotNull(chiefcomplaint)) nonNullCount++;
    if (isHPITextNotNull(hpitext)) nonNullCount++;
    if (isPETextNotNull(physicalexamtext)) nonNullCount++;
    if (isROSTextNotNull(rostext)) nonNullCount++;
    if (isArrayNotEmpty(historyofpresentillness)) nonNullCount++;
    if (isArrayNotEmpty(physicalexam)) nonNullCount++;
    if (isArrayNotEmpty(reviewofsystem)) nonNullCount++;

    // Return true if at least two fields are non-null
    return nonNullCount >= 2;
  }

  const [AiInput, setAiInput] = useState(null);
  const fetchAssessmentPlans = async () => {
    showLoader();
    setAiInput(null);

    if (checkFields(doctorNotes) === true) {
      const result = await FetchMyHealthAIData(
        {},
        patientId,
        patientname,
        patientgender,
        patientage
      );
      const doctornotes = doctorNotes;
      let Aiprompts = { ...result, doctornotes };
      // console.log(Aiprompts);
      setAiInput(Aiprompts);
      setAiInputForFeedback(Aiprompts);
      const response = await AiGenerateAssessmentPlan(Aiprompts);
      console.log("ai response", response);
      if (
        response &&
        response?.assessment_plan &&
        response?.assessment_plan?.length > 0
      ) {
        setAiResponse(response?.assessment_plan);
        setAiOutputForFeedback(response?.assessment_plan);
        setSelectedAiResponses([]);
        aiGenerateAssessmentPlanRef.current.openModal();
      } else {
        // noAiResponseModal.current.openModal();
        // setTimeout(() => {
        //   noAiResponseModal.current.closeModal();
        // }, 3000); // 3 seconds delay
        if (noAiResponseModal.current) {
          noAiResponseModal.current.openModal();
          setTimeout(() => {
            if (noAiResponseModal.current) {
              // Check again before closing
              noAiResponseModal.current.closeModal();
            }
          }, 3000); // 3 seconds delay
        }
      }
    } else {
      // noAiResponseModal.current.openModal();
      // setTimeout(() => {
      //   noAiResponseModal.current.closeModal();
      // }, 3000); // 3 seconds delay
      if (noAiResponseModal.current) {
        noAiResponseModal.current.openModal();
        setTimeout(() => {
          if (noAiResponseModal.current) {
            // Check again before closing
            noAiResponseModal.current.closeModal();
          }
        }, 3000); // 3 seconds delay
      }
    }

    dismissLoader();
  };

  const handleImportAIPlans = () => {
    const selectedItems = aiResponse.filter((_, index) =>
      selectedAiResponses.includes(index)
    );
    const newAssessments = selectedItems.map((item, index) => ({
      diagnosisid: formInputs.length + index + 1, // Assign unique diagnosisid
      diagnosis: item.diagnosis,
      plan: item.plan,
    }));

    // Filter out any empty initial inputs from formInputs before adding new assessments
    // console.log(formInputs);
    const filteredFormInputs = formInputs.filter(
      (input) => input.diagnosis.trim() !== "" && input.plan.trim() !== ""
    );
    // console.log(filteredFormInputs);

    // Update formInputs state with new assessments
    // setFormInputs((prevInputs) => [...filteredFormInputs, ...newAssessments]);
    // setFormInputs([...filteredFormInputs, ...newAssessments]);
    // console.log([...filteredFormInputs, ...newAssessments]);

    let updatedFormInputs = [...filteredFormInputs, ...newAssessments];

    // Check if the form is in its initial state with an empty first entry
    if (
      formInputs.length === 1 &&
      formInputs[0].diagnosis === "" &&
      formInputs[0].plan === ""
    ) {
      // Replace the initial empty entry with the first new assessment
      // console.log("case triggered", newAssessments);
      updatedFormInputs = [...newAssessments];
    } else {
      // Otherwise, append the new assessments to the existing filtered form inputs
      updatedFormInputs = [...filteredFormInputs, ...newAssessments];
    }

    setFormInputs(updatedFormInputs);

    // Ensure that the form is reset with the new inputs
    reset({
      doctorsassessment: updatedFormInputs.map((assessment) => ({
        diagnosis: assessment.diagnosis,
        plan: assessment.plan,
      })),
    });

    aiGenerateAssessmentPlanRef.current.closeModal();
  };

  // Toggle selection of AI responses
  const handleCheckboxChange = (index) => {
    setSelectedAiResponses((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  //-----------------------Master Data
  // State variables
  // const [diagnosisSuggestions, setDiagnosisSuggestions] = useState([]);
  // const [showDiagnosisDropdown, setShowDiagnosisDropdown] = useState(false);
  // const [focusedDiagnosisIndex, setFocusedDiagnosisIndex] = useState(-1);
  // const diagnosisMenuRef = useRef(null);

  // // Function to fetch diagnosis suggestions
  // const fetchDiagnosisSuggestions = async (searchText) => {
  //   try {
  //     const inputData = {
  //       input: {
  //         type: "Diagnosis",
  //         searchtext: searchText,
  //       },
  //     };
  //     const data = await GetDoctorMasterData(inputData.input);
  //     if (data.length > 0) {
  //       setDiagnosisSuggestions(data);
  //       setShowDiagnosisDropdown(true);
  //     } else {
  //       setDiagnosisSuggestions([]);
  //       setShowDiagnosisDropdown(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching diagnosis suggestions:", error);
  //   }
  // };

  // // Function to handle selection of a suggestion
  // const handleDiagnosisSelect = (suggestion, index, field) => {
  //   field.onChange(suggestion.name); // Update input with selected suggestion
  //   setDiagnosisSuggestions([]); // Clear suggestions
  //   setShowDiagnosisDropdown(false);
  // };

  // // Function to handle keydown navigation
  // const handleDiagnosisKeyDown = (e, field) => {
  //   if (e.key === "ArrowDown") {
  //     setFocusedDiagnosisIndex((prevIndex) => (prevIndex + 1) % diagnosisSuggestions.length);
  //     const itemHeight = diagnosisMenuRef.current?.children[0]?.clientHeight || 0;
  //     if (diagnosisMenuRef.current) {
  //       diagnosisMenuRef.current.scrollTop = focusedDiagnosisIndex * itemHeight;
  //     }
  //   } else if (e.key === "ArrowUp") {
  //     setFocusedDiagnosisIndex((prevIndex) =>
  //       prevIndex === 0 ? diagnosisSuggestions.length - 1 : prevIndex - 1
  //     );
  //     const itemHeight = diagnosisMenuRef.current?.children[0]?.clientHeight || 0;
  //     if (diagnosisMenuRef.current) {
  //       diagnosisMenuRef.current.scrollTop = focusedDiagnosisIndex * itemHeight;
  //     }
  //   } else if (e.key === "Enter" && focusedDiagnosisIndex !== -1) {
  //     const selectedItem = diagnosisSuggestions[focusedDiagnosisIndex];
  //     handleDiagnosisSelect(selectedItem, field);
  //   }
  // };

  //------------------------------------------------------------------------------------

  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null); // Track the active index

  const inputRef = useRef(null); // Reference for the input box

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsMenuOpen(false); // Close the menu when clicking outside
        setActiveIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchMasterData = async (searchtext) => {
    console.log("function called");
    try {
      const inputData = {
        input: {
          type: "Diagnosis",
          searchtext,
        },
      };
      const data = await GetDoctorMasterData(inputData.input);
      setSuggestions(data || []);
      console.log("search text data", data);
    } catch (error) {
      console.error("Error fetching Master data:", error);
      setSuggestions([]);
    }
  };



  const handleInputChangeInternal = (e, index) => {
    const value = e.target.value;
    setSearchText(value);

    setActiveIndex(index); // Set the active input index

    if (value.length > 3) {
      fetchMasterData(value);
      // setIsMenuOpen(true);
    } else {
      setSuggestions([]);
      // setIsMenuOpen(false);
    }

    handleInputChange(index, "diagnosis", value);
  };

  const handleSelectSuggestion = (suggestion, index) => {
    setSearchText(suggestion.diagnosisname);
    handleInputChange(index, "diagnosis", suggestion.diagnosisname);
    // setIsMenuOpen(false);
    setActiveIndex(null); // Close the menu after selection
  };
  //----------------------Other Functionalities

  const [formInputs, setFormInputs] = useState([
    { diagnosisid: 0, diagnosis: "", plan: "", isSavedId: null },
  ]);
  const [formInputsInitial, setFormInputsInitial] = useState([
    { diagnosisid: 0, diagnosis: "", plan: "", isSavedId: null },
  ]);
  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      doctorsassessment: [{ diagnosis: "", plan: "" }],
    },
  });

  const [initialLoad, setInitialLoad] = useState(true);
 
  const { isDirty, isValid } = formState;

  useEffect(() => {
    fetchDoctorNotesData();
  }, []);

  const [panelsToExpand, setPanelsToExpand] = useState([]);

  const fetchDoctorNotesData = async () => {
    setFormInputs([]); // Clear the state
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      setDoctorNotes(data?.output?.data);
      const doctorsassessment = data?.output?.data?.doctorsassessment || [];
      console.log(doctorsassessment)

      if (doctorsassessment.length === 0) {
        doctorsassessment.push({
          diagnosisid: 0,
          diagnosis: "",
          plan: "",
          isSavedId: null,
        });
      }

      setPanelsToExpand(doctorsassessment); // Update panelsToExpand state

      setSelectedButton(data?.output?.data?.patientdiagnosis);
      setSelectedButtonInitial(data?.output?.data?.patientdiagnosis);
      // Populate formInputs with fetched data and retain their IDs
      setFormInputs(
        doctorsassessment.map((assessment) => ({
          ...assessment,
          isSavedId: assessment.id || null, // Store the saved ID
        }))
      );
      setFormInputsInitial(
        doctorsassessment.map((assessment) => ({
          ...assessment,
          isSavedId: assessment.id || null, // Store the saved ID
        }))
      );

      reset({
        doctorsassessment: doctorsassessment.map((assessment) => ({
          diagnosis: assessment.diagnosis || "",
          plan: assessment.plan || "",
        })),
      });
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const deleteSelectedTreatmentPlan = async (id) => {
    const inputForDelete = {
      input: {
        patientid: patientId,
        id: id,
        type: "treatmentplan",
      },
    };
    showLoader();
    try {
      // console.log("delete", inputForDelete);
      const result = await DeleteOrder(inputForDelete.input);
      if (result.output.message === "Successfully Deleted") {
        showSuccessToast("Treatment Plan has been deleted");
        fetchDoctorNotesEmptyIndication();
        setFormInputs((prevInputs) =>
          prevInputs.filter((input) => input.id !== id)
        );
        if (formInputs.length === 1) {
          reset({
            doctorsassessment: [{ diagnosis: "", plan: "" }],
          });
          setFormInputs([{ diagnosis: "", plan: "" }]);
        }
        setInitialLoad(true);
        fetchDoctorNotesData();
      } else {
        showErrorToast("Something went wrong!");
      }
    } catch (error) {
      console.error("Error in delete order:", error);
    } finally {
      dismissLoader();
    }
  };

  const addDiagnosis = (event, index) => {
    event.stopPropagation();
    setFormInputs((prevInputs) => {
      const lastDiagnosisId =
        prevInputs.length > 0
          ? prevInputs[prevInputs.length - 1].diagnosisid + 1
          : 0; // Start from 0 if no existing items

      return [
        ...prevInputs,
        {
          diagnosisid: lastDiagnosisId,
          diagnosis: "",
          plan: "",
          isSavedId: null,
        },
      ];
    });
  };

  // const removeDiagnosis = (event, index) => {
  //   event.stopPropagation();
  //   const item = formInputs[index];
  //   console.log("id", item.id);
  //   console.log("index", index);
  //   if (item.id) {
  //     deleteSelectedTreatmentPlan(item.id);
  //   }
  //   console.log(formInputs);
  //   // setFormInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  //   setFormInputs((prevInputs) =>
  //     prevInputs.filter(
  //       (input, i) => i !== index && input.diagnosisid !== item.diagnosisid
  //     )
  //   );
  // };

  const removeDiagnosis = (event, index) => {
    event.stopPropagation();

    // Check if the diagnosis has an ID
    const item = formInputs[index];

    // console.log("item", item);
    // console.log("index", index);
    // console.log(formInputs);

    // Remove from formInputs
    // setFormInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));

    if (formInputs?.length === 1) {
      console.log("emptying form");
      // setFormInputs([
      //   { diagnosisid: 0, diagnosis: "", plan: "", isSavedId: null },
      // ])
      reset({
        doctorsassessment: [{ diagnosis: "", plan: "" }],
      });
      setFormInputs([{ diagnosis: "", plan: "" }]);
    } else {
      setFormInputs((prevInputs) => {
        console.log("deleting entry");
        // Remove the item
        const updatedInputs = prevInputs.filter((_, i) => i !== index);

        return updatedInputs;
        // Decrement the IDs for items from the removed index onwards
        // return updatedInputs.map((input, i) => ({
        //   ...input,
        //   diagnosisid: i >= index ? input.diagnosisid - 1 : input.diagnosisid, // Decrement ID for items from the index onwards
        // }));
      });
    }

    // If the diagnosis has an ID, delete from server and update state
    if (item.id) {
      deleteSelectedTreatmentPlan(item.id);
    }
  };

  // useEffect(() => {
  //   console.log("Updated formInputs", formInputs);
  // }, [formInputs]);

  const handleInputChange = (index, field, value) => {
    const newFormInputs = [...formInputs];
    newFormInputs[index][field] = value;
    setFormInputs(newFormInputs);
  };

  const onSubmit = async (data) => {
    const filteredAssessments = formInputs.filter(
      (assessment) =>
        (assessment.diagnosis && assessment.diagnosis.trim() !== "") ||
        (assessment.plan && assessment.plan.trim() !== "")
    );

    if (filteredAssessments.length === 0 && !selectedButton) {
      showErrorToast("Please enter some data");
    } else {
      const inputToSave = {
        input: {
          doctorid: doctorId,
          patientid: patientId,
          appointmentid: appointmentId,
          patientdiagnosis: selectedButton,
          doctorsassessment: filteredAssessments.map((assessment) => ({
            diagnosis: assessment.diagnosis,
            plan: assessment.plan,
            // diagnosisid: assessment.isSavedId || assessment.diagnosisid, // Use saved ID if it exists
            diagnosisid: assessment.isSavedId ? assessment.isSavedId:null, // Use saved ID if it exists
          })),
        },
      };
      showLoader();

      try {
        console.log("input send", inputToSave.input);
        const result = await updateDoctorNotes(inputToSave.input);
        if (result) {
          showSuccessToast("Assessments Saved successfully");
          fetchDoctorNotesEmptyIndication();
          fetchDoctorNotesData(); // Fetch again to update the state with the correct IDs
        }
      } catch (error) {
        console.error("Error in Update Doctor Notes:", error);
        showErrorToast("Error Happened While Saving.");
      } finally {
        dismissLoader();
      }
    }
  };

  const [indicesToExpand, setIndicesToExpand] = useState([]);

  useEffect(() => {
    const indices = Array.from({ length: panelsToExpand.length }, (_, i) => i);
    setIndicesToExpand(indices); // Set the indices to expand based on the fetched data
  }, [panelsToExpand]);

  //compare objects, if not same then submit
  function deepCompare(obj1, obj2) {
    // If both are the same reference, they are equal
    if (obj1 === obj2) return true;
  
    // If their types are different, they are not equal
    if (typeof obj1 !== typeof obj2) return false;
  
    // If either is null or not an object (e.g., strings, numbers), compare directly
    if (obj1 === null || obj2 === null || typeof obj1 !== "object") {
      return obj1 === obj2;
    }
  
    // Get keys for both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    // If the number of keys is different, objects are not equal
    if (keys1.length !== keys2.length) return false;
  
    // Compare each key and value recursively
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  }


  // submit on tab switch and submit button is enalbed
    useEffect(()=>{
      if ( submitOnTabSwitch && 
        ( !deepCompare(formInputsInitial, formInputs) ||  selectedButton !== selectedButtonInitial )
      ) {
        handleSubmit(onSubmit)();
      }
    },[submitOnTabSwitch])
    

  const css = `
    .chakra-collapse {
      overflow: visible!important;
    }
  `;

  return (
    <Box
      position="relative"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <style>{css}</style>
      <Box flex="1" overflow="hidden auto">
        {isLoading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="10"
            // bg="rgba(255, 255, 255, 0.8)"
          >
            <Loader miniLoader={true} />
          </Box>
        )}
        <Box>
          {buttons.map((buttonName) => {
            const isSelected = selectedButton === buttonName;
            return (
              <Button
                key={buttonName}
                borderRadius="full"
                onClick={() => handleButtonClick(buttonName)}
                bg={isSelected ? "black" : "white"}
                color={isSelected ? "white" : "black"}
                leftIcon={
                  isSelected ? (
                    <Image src="/assets/svgs/white-ok.svg" boxSize="1em" />
                  ) : null
                }
                variant="solid"
                boxShadow={
                  isSelected ? "none" : "0px 2px 3px rgba(0, 0, 0, 0.2)"
                }
                _hover={{
                  bg: isSelected ? "black" : "white",
                }}
                _active={{
                  bg: isSelected ? "black" : "white",
                }}
                border={isSelected ? "none" : "1px solid #E6E7E9"}
                size="sm"
                m={1}
              >
                {buttonName}
              </Button>
            );
          })}
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
          <Accordion
            allowMultiple
            flex="1"
            defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]}
          >
            {formInputs.map((item, index) => (
              <AccordionItem key={item.diagnosisid}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Diagnosis {index + 1}
                  </Box>
                  <>
                    <Badge
                      onClick={(event) => addDiagnosis(event, index)}
                      cursor="pointer"
                      color="blue"
                      bg={"white"}
                      ml={2}
                      {...(index === formInputs.length - 1
                        ? {}
                        : { display: "none" })}
                    >
                      + Add
                    </Badge>
                    <Badge
                      // Delete Functionality
                      onClick={(event) => removeDiagnosis(event, index)}
                      cursor="pointer"
                      bg={"white"}
                      ml={2}
                      // {...(index !== 0 || formInputs.length > 1
                      //   ? {}
                      //   : { display: "none" })}
                    >
                      <Image src="assets/imgs/delete.png" alt="Delete" />
                    </Badge>
                  </>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} overflow="visible" bg="#f9fafb" pt={8}>
                  <Stack spacing={0}>
                    {/* <FormControl isRequired>
                      <Controller
                        name={`doctorsassessment[${index}].diagnosis`}
                        control={control}
                        render={({ field }) => (
                          <div style={{ position: "relative" }}>
                            <Input
                              value={searchText}
                              onChange={(e) => {
                                field.onChange(e);
                                handleInputChangeInternal(e, index);
                              }}
                              placeholder="Diagnosis"
                            />
                            {isMenuOpen && (
                              <Menu isOpen>
                                <MenuList
                                  style={{
                                    position: "absolute",
                                    zIndex: 1,
                                    width: "100%",
                                  }}
                                >
                                  {suggestions.length > 0 ? (
                                    suggestions.map((suggestion, i) => (
                                      <MenuItem
                                        key={i}
                                        onClick={() => handleSelectSuggestion(suggestion, index)}
                                      >
                                        {suggestion.diagnosisname}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem isDisabled>No suggestions found</MenuItem>
                                  )}
                                </MenuList>
                              </Menu>
                            )}
                          </div>
                        )}
                      />
                    </FormControl> */}
                    <FormControl isRequired>
                      <Controller
                        name={`doctorsassessment[${index}].diagnosis`}
                        control={control}
                        render={({ field }) => (
                          // <div style={{ position: "relative" }}>
                          //   <Input
                          //     ref={inputRef}
                          //     value={searchText}
                          //     onChange={(e) => {
                          //       field.onChange(e);
                          //       handleInputChangeInternal(e, index);
                          //     }}
                          //     placeholder="Diagnosis"
                          //   />
                          //   {isMenuOpen && (
                          //     <Menu isOpen>
                          //       <MenuList
                          //         style={{
                          //           position: "absolute",
                          //           zIndex: 1,
                          //           width: inputRef.current
                          //             ? `${inputRef.current.offsetWidth}px`
                          //             : "100%", // Fallback width
                          //         }}
                          //       >
                          //         {suggestions.length > 0 ? (
                          //           suggestions.map((suggestion, i) => (
                          //             <MenuItem
                          //               key={i}
                          //               onClick={() => handleSelectSuggestion(suggestion, index)}
                          //             >
                          //               {suggestion.diagnosisname}
                          //             </MenuItem>
                          //           ))
                          //         ) : (
                          //           <MenuItem isDisabled>No suggestions found</MenuItem>
                          //         )}
                          //       </MenuList>
                          //     </Menu>
                          //   )}
                          // </div>
                          // <div
                          //   ref={wrapperRef}
                          //   style={{ position: "relative", zIndex: 999 }}
                          // >
                          //   <PrimaryInput
                          //     ref={inputRef}
                          //     inputValue={formInputs[index]?.diagnosis}
                          //     variant="defaultSide"
                          //     inputType="text"
                          //     inputPlace="Diagnosis"
                          //     onChange={(e) => {
                          //       field.onChange(e);
                          //       handleInputChangeInternal(e, index);
                          //     }}
                          //     // inputPlace="Diagnosis"
                          //     style={{ zIndex: 999 }} // Add zIndex style here
                          //   />
                          //   {activeIndex === index && ( // Show the menu only for the active input
                          //     <Menu isOpen>
                          //       <MenuList
                          //         style={{
                          //           position: "relative",
                          //           top: "48px",
                          //           zIndex: 2,
                          //           width: inputRef.current
                          //             ? `${inputRef.current.offsetWidth}px`
                          //             : "70%",
                          //           maxHeight: "200px", // Fixed height for menu
                          //           overflowY: "auto", // Enable scrolling
                          //           border: "1px solid #ccc", // Optional: to match input styling
                          //           borderRadius: "4px", // Optional: rounded corners
                          //           boxShadow: "0 4px 6px rgba(0, 0, 0, 0)", // Full opaque shadow
                          //           bg:"red"
                          //         }}
                          //       >
                          //         {suggestions.length > 0
                          //           ? suggestions.map((suggestion, i) => (
                          //               <MenuItem
                          //                 key={i}
                          //                 onClick={() =>
                          //                   handleSelectSuggestion(
                          //                     suggestion,
                          //                     index
                          //                   )
                          //                 }
                          //                 style={{
                          //                   padding: "8px 12px",
                          //                   cursor: "pointer",
                          //                 }}
                          //               >
                          //                 {suggestion.diagnosisname}
                          //               </MenuItem>
                          //             ))
                          //           : // <MenuItem isDisabled>
                          //             //   No suggestions found
                          //             // </MenuItem>
                          //             null}
                          //       </MenuList>
                          //     </Menu>
                          //   )}
                          // </div>
                          <div
                            ref={wrapperRef}
                            style={{ position: "relative", zIndex: 999 }}
                          >
                            <PrimaryInput
                              ref={inputRef}
                              inputValue={formInputs[index]?.diagnosis}
                              variant="defaultSide"
                              inputType="text"
                              inputPlace="Diagnosis"
                              onChange={(e) => {
                                field.onChange(e);
                                handleInputChangeInternal(e, index);
                              }}
                              onFocus={() => setActiveIndex(index)} // Set the active input index on focus
                              onBlur={(e) => {
                                // Delay to allow click events on the menu to process
                                setTimeout(() => {
                                  if (
                                    !wrapperRef.current.contains(
                                      document.activeElement
                                    )
                                  ) {
                                    setActiveIndex(null); // Reset active index if focus leaves wrapper
                                  }
                                }, 100);
                              }}
                            />
                            {activeIndex === index && (formInputs[index]?.diagnosis?.length > 3) && ( // Show the menu only for the active input
                              <Menu isOpen>
                                <MenuList
                                  style={{
                                    position: "absolute", // Ensure positioning
                                    top: "48px",
                                    zIndex: 99,
                                    // width: inputRef.current
                                    //   ? `${inputRef.current.offsetWidth}px`
                                    //   : "70%",
                                    width: "70%",
                                    maxHeight: "200px", // Fixed height for menu
                                    overflowY: "auto", // Enable scrolling
                                    border: "1px solid #ccc", // Optional: to match input styling
                                    borderRadius: "4px", // Optional: rounded corners
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Slight shadow
                                    // backgroundColor: "red"
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent blur when clicking menu
                                >
                                  {suggestions.length > 0
                                    ? suggestions.map((suggestion, i) => (
                                        <MenuItem
                                          key={i}
                                          onClick={() =>
                                            handleSelectSuggestion(
                                              suggestion,
                                              index
                                            )
                                          }
                                          style={{
                                            padding: "8px 12px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {suggestion.diagnosisname}
                                        </MenuItem>
                                      ))
                                    : null}
                                </MenuList>
                              </Menu>
                            )}
                          </div>
                        )}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <Controller
                        name={`doctorsassessment[${index}].plan`}
                        control={control}
                        render={({ field }) => (
                          <PrimaryAssessmentInput
                            textAreaHeight={"100px"}
                            id={`doctorsassessment[${index}].plan`}
                            variant="downSide"
                            inputType="text"
                            inputPlace="Plan"
                            defaultValue={formInputs[index]?.plan}
                            onChange={(e) => {
                              field.onChange(e);
                              handleInputChange(index, "plan", e.target.value);
                            }}
                            wrap={true}
                            // defaultHeight={"24px"}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </form>
      </Box>
      {/* <Box
        position="sticky"
        bottom={0}
        bg="white"
        p={4}
        borderTop="1px solid #e2e8f0"
      >
        <Button
          onClick={handleSubmit(onSubmit)}
          colorScheme="teal"
          width="100%"
          isDisabled={!isValid || formInputs.length === 0}
        >
          Save Assessment
        </Button>
      </Box> */}
      <PrimaryButton
        buttonText="Save Assessment"
        onClick={handleSubmit(onSubmit)}
        isDisabled={
          !isValid || formInputs.length === 0 || !actionAccessMain(21)?.create
        }
        btnStyle={{
          h: "2.5rem",
          w: "95%",
          p: "0",
          mr: "12px",
          ml: "12px",
          position: "sticky",
          bottom: "0",
          bg: COLORS.PRIMARY_GRADIENT,
          color: COLORS.PRIMARY_WHITE,
          _hover: { bg: COLORS.PRIMARY_COLOR },
        }}
      />

      {/* DynamicModal for Close Prescription */}
      <DynamicModal
        ref={aiGenerateAssessmentPlanRef}
        modalWidth="60%"
        modalHeader={`Aira - AI: Recommended Assessment Plans`}
        modalBody={
          <>
            {aiResponse.map((response, index) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={4}
                mb={4}
                display="flex"
                // as="button"
                boxShadow="rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
                onClick={() => handleCheckboxChange(index)}
                alignItems="center"
              >
                <Checkbox
                  isChecked={selectedAiResponses.includes(index)}
                  onChange={() => handleCheckboxChange(index)}
                  mr={4}
                  alignSelf="center" // Vertically center the checkbox
                ></Checkbox>
                <Box
                  key={index}
                  // borderBottom="1px solid #E6E7E9"
                  // py="0.875rem"
                  display="flex"
                  flexDir="column"
                  gap="0.5rem"
                >
                  <Text fontSize="16px" fontWeight="600">
                    {response.diagnosis}
                  </Text>
                  <Box
                    as="p"
                    p="0.5rem"
                    bg="#F9FAFB"
                    borderLeft="4px solid #14C585"
                    fontSize="0.875rem"
                    fontWeight="500"
                  >
                    Plan: {response.plan}
                  </Box>
                </Box>
              </Box>
            ))}
            {useGPUChatbot ? (
              <AIFeedbackFooter
                disclaimer={
                  "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
                }
                doctorId={doctorId}
                patientId={patientId}
                appointmentId={appointmentId}
                aiInput={aiInputForFeedback}
                aiOutput={aiOutputForFeedback}
                featureType={"Assist Assessment Plan"}
                containerStyle={{ p: 0, pt: 4 }}
              />
            ) : (
              <AICPUFeedbackFooter
                disclaimer={
                  "AIRA strives to be as accurate as possible, but occasional errors may occur. Please verify its responses to ensure the information meets your needs. Your discretion will ensure the best experience."
                }
                doctorId={doctorId}
                patientId={patientId}
                appointmentId={appointmentId}
                aiInput={aiInputForFeedback}
                aiOutput={aiOutputForFeedback}
                featureType={"Assist Assessment Plan"}
              />
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Discard"
              btnStyle={{
                w: "48%",
                h: "2.5rem",
              }}
              onClick={() => {
                aiGenerateAssessmentPlanRef.current.closeModal();
              }}
            />

            <Spacer />

            <PrimaryButton
              buttonText="Import selected items"
              variant="grayBtn"
              btnStyle={{
                w: "48%",
                color: COLORS.PRIMARY_COLOR,
                h: "2.5rem",
              }}
              isDisabled={selectedAiResponses.length === 0}
              onClick={() => {
                handleImportAIPlans();
                aiGenerateAssessmentPlanRef.current.closeModal();
              }}
            />
          </>
        }
      />

      {/* DynamicModal for No AI response */}
      {/* <DynamicModal
        ref={noAiResponseModal}
        modalHeader={`Aira - AI: Recommended Assessment Plans`}
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Insufficient data is available to draw inferences. Please ensure
              that relevant notes are provided.
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText="Close"
              variant="grayBtn"
              btnStyle={{
                w: "100%",
                color: COLORS.PRIMARY_COLOR,
                h: "2.5rem",
              }}
              onClick={() => {
                noAiResponseModal.current.closeModal();
              }}
            />
          </>
        }
      /> */}
      <DynamicModal
        ref={noAiResponseModal}
        modalHeader={`Aira - AI: Recommended Assessment Plans`}
        modalBody={renderModalContent(doctorNotes)}
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText="Close"
              variant="grayBtn"
              btnStyle={{
                w: "100%",
                color: COLORS.PRIMARY_COLOR,
                h: "2.5rem",
              }}
              onClick={() => {
                noAiResponseModal.current.closeModal();
              }}
            />
          </>
        }
      />
    </Box>
  );
};

export default DoctorNotesAssessment;
