import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Image,
  RadioGroup,
  Stack,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from "@chakra-ui/react";
import ModalRight from "../../modal-right";
import SearchBar from "../../search-bar";
import PrimaryButton from "../../primary-button";
import PrimaryInput from "../../primary-input";
import DatePicker from "../../date-picker";
import {
  addVaccination,
  deleteVacinations,
  editVaccinations,
  FetchVaccinationDetails,
  getVaccineList,
} from "../../../middleware/services/messages";
import { format } from "date-fns";
import { actionAccessMain, getLoginID } from "../../../auth";
import moment from "moment";
import DatePickerQuick from "../date-picker-quick";

const NoVaccinationDataScreen = forwardRef(
  (
    {
      width,
      height,
      patientId,
      showErrorToast,
      showSuccessToast,
      showLoader,
      dismissLoader,
      fetchPatientHealthData,
    },
    ref
  ) => {
    const openFromStartVisitOverview = () => {
      console.log("Function called!");
      openVaccinationsModal();
    };

    useImperativeHandle(ref, () => ({
      openFromStartVisitOverview,
    }));

    const [actionAccessPatients, setActionAccessPatients] = useState(null);
    useEffect(() => {
      setActionAccessPatients(actionAccessMain(4));
    }, []);

    const dynamicModal = React.useRef();
    const addCondition = React.useRef();
    const addCondition2 = React.useRef();

    const addCondition3 = React.useRef();

    const [data, setData] = useState("");
    var [conditionsList, setConditionsList] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedDates, setSelectedDates] = useState({});
    const [selectedDueDates, setSelectedDueDates] = useState({});
    const [condition, setCondition] = useState("");
    const [vaccineid, setVaccineid] = useState("");
    const [idPatient, setIdPatient] = useState("");
    const [vaccineName, setVaccineName] = useState("");
    const [adminDate, setAdminDate] = useState("");
    const [dueDate, setDueDate] = useState("");

    const scrollDiv = useRef(null);
    const chatContainerRef = useRef(null);
    const [aianswers, setAIAnswers] = useState([]);
    const [remainingquestions, setRemainingQuestions] = useState([]);
    const [aiquestion, setAIAnalysis] = useState(null);
    const [AiInput, setAiInput] = useState(null);
    const [initialRender, setInitialRender] = useState(true);
    const [animate, setAnimate] = useState(false);
    const AIChatModel = useRef();

    const [typedCondition, setTypedCondition] = useState("");

    let patientidFromLocal = patientId;

    const fetchMedicalConditionList = async () => {
      try {
        const result = await getVaccineList({
          type: "vaccine",
          patientid: patientidFromLocal,
        });
        if (result.output.result == "success") {
          setConditionsList(result.output.data);
        } else {
          showErrorToast("Something went wrong!");
        }
      } catch (error) {
        console.error("Error", error);
      }
    };

    const handleCheckboxChange = (condition) => {
      const isSelected = selectedConditions.some(
        (item) => item.condition_cd === condition.condition_cd
      );

      if (isSelected) {
        setSelectedConditions((prevConditions) =>
          prevConditions.filter(
            (item) => item.condition_cd !== condition.condition_cd
          )
        );
      } else {
        setSelectedConditions([...selectedConditions, condition]);
      }
    };
    const [minDateState, setMinDateState] = useState("");
    const [maxDateState, setMaxDateState] = useState("");
    const handleDateChange = (conditionCd, date) => {
      const formattedDate = format(date, "yyyy-MM-dd");
      setMinDateState(date);
      setSelectedDates((prevDates) => ({
        ...prevDates,
        [conditionCd]: formattedDate,
      }));
    };
    const handleDueDateChange = (conditionCd, date) => {
      setMaxDateState(date);
      const formattedDate = format(date, "yyyy-MM-dd");
      setSelectedDueDates((prevDates) => ({
        ...prevDates,
        [conditionCd]: formattedDate,
      }));
    };

    var inputForAdd = {
      patientid: patientidFromLocal,
      doctorid: getLoginID(),
      vaccination: selectedConditions.map((condition, index) => ({
        vaccine_cd: condition.condition_cd,
        vaccinename: condition.condition,
        admindate: selectedDates[condition.condition_cd] || null,
        duedate: selectedDueDates[condition.condition_cd] || null,
      })),
    };

    const AddMedicalConditions = () => {
      if (selectedConditions.length === 0 && typedCondition == "") {
        showErrorToast("Please Select a Vaccine to Continue");
      } else {
        // enter manually
        if (typedCondition !== "") {
          const newSelectedCondition = {
            condition: typedCondition,
            condition_cd: null,
            conditiontype: "other",
          };

          setSelectedConditions((prevConditions) => {
            // Filter out any existing condition with the same type
            const filteredConditions = prevConditions.filter(
              (item) => item?.conditiontype !== "other"
            );
            // Add the new condition
            return [...filteredConditions, newSelectedCondition];
          });
        }

        //--------------------------------
        setSelectedDates({});
        setSelectedDueDates({});
        addCondition2.current.openRight();
      }
    };

    const AddMedicalConditionsCall = () => {
      const addConditions = async () => {
        showLoader();
        try {
          const result = await addVaccination(inputForAdd);
          if (result.output.result == "success") {
            showSuccessToast("Successfully Added");
            if (scrollDiv.current) {
              scrollDiv.current.scrollTop = 0;
            }

            fetchPatientHealthData();

            addCondition2.current.closeRight();
            addCondition.current.closeRight();
          } else {
            showErrorToast("Something went wrong!");
          }
        } catch (error) {
          console.error("Error in DashboardIndex:", error);
        }
        dismissLoader();
      };

      const isDateMissing = selectedConditions.some(
        // (condition) => !selectedDates[condition.condition_cd] || !selectedDueDates[condition.condition_cd]
        (condition) => !selectedDates[condition.condition_cd]
      );

      if (isDateMissing) {
        showErrorToast("Please select admin date");
        return false;
      } else {
        addConditions();
      }
    };

    const openVaccinationsModal = () => {
      // if (conditionsList == null) {
      fetchMedicalConditionList();
      // }
      setSearchValue("");
      setSelectedConditions([]);
      setSelectedDueDates({});
      setTypedCondition("");
      addCondition.current.openRight();
    };

    const handleCondition = (e) => {
      const inputText = e.target.value;
      const maxLength = 80;

      if (inputText.length <= maxLength) {
        setTypedCondition(inputText);
      }
    };

    return (
      <Box>
        <Box
          bg="#fcfcfd"
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            btnIcon="/assets/imgs/add.png"
            buttonText="Add Vaccination"
            onClick={openVaccinationsModal}
            isDisabled={actionAccessPatients?.create ? false : true}
          />
          <Text textAlign="center" position="absolute" top="50%" left="40%">
            No Vaccination Data Available
          </Text>
        </Box>
        {/* Add Modal Here  */}
        <ModalRight
          ref={addCondition}
          header="Add vaccine"
          body={
            <>
              <SearchBar
                passInputPlace="Search condition"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <RadioGroup>
                <Stack direction="column" gap="0">
                  <Box display="flex" justifyContent="end" w="100%">
                    <Stack direction="column" gap="0" w="100%">
                      {conditionsList && conditionsList.length > 0 ? (
                        conditionsList.some((category) =>
                          category.data.some((condition) =>
                            condition.condition
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          )
                        ) ? (
                          conditionsList.map((category) => (
                            <React.Fragment key={category.category_id}>
                              {category.data
                                .filter((condition) =>
                                  condition.condition
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                                )
                                .map((condition) => (
                                  <label key={condition.condition_cd}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      borderBottom="1px solid #e2e8f0"
                                      p="15px"
                                      pl="5px"
                                      minH="50px"
                                      width="100%"
                                      sx={{
                                        _hover: {
                                          border: "2px solid #384052",
                                          borderRadius: "10px",
                                        },
                                      }}
                                    >
                                      <Box
                                        key={condition.condition_cd}
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text
                                          fontSize="16px"
                                          lineHeight="normal"
                                          fontWeight="600"
                                        >
                                          {condition.condition}
                                        </Text>
                                      </Box>
                                      <Box display="flex" justifyContent="end">
                                        <Checkbox
                                          colorScheme="green"
                                          sx={{
                                            ".chakra-checkbox__control": {
                                              width: "20px",
                                              height: "20px",
                                              borderRadius: "5px",
                                            },
                                          }}
                                          onChange={() =>
                                            handleCheckboxChange(condition)
                                          }
                                          isChecked={selectedConditions.some(
                                            (item) =>
                                              item.condition_cd ===
                                              condition.condition_cd
                                          )}
                                        ></Checkbox>
                                      </Box>
                                    </Box>
                                  </label>
                                ))}
                            </React.Fragment>
                          ))
                        ) : (
                          <Box
                            p="15px"
                            fontSize="16px"
                            fontWeight="600"
                            textAlign="center"
                          >
                            No matching vaccinations found!
                          </Box>
                        )
                      ) : (
                        <p>Loading...</p>
                      )}
                      <Divider />
                      <br />
                      <Heading
                        as="h2"
                        fontSize="12px"
                        pl="5px"
                        textTransform="uppercase"
                        fontWeight="600"
                        my="10px"
                      >
                        Other Vaccination
                      </Heading>
                      <PrimaryInput
                        inputPlace="Vaccination"
                        variant="bothSide"
                        onChange={handleCondition}
                        inputValue={typedCondition}
                      />
                      <br />
                    </Stack>
                  </Box>
                </Stack>
              </RadioGroup>

              <ModalRight
                ref={addCondition2}
                header="Add Vaccination"
                body={
                  <>
                    <Box display="flex" flexDir="column" gap="20px">
                      {selectedConditions.map((condition, index) => (
                        <Box key={index}>
                          <Text fontSize="16px" py="10px" fontWeight="600">
                            {condition.condition}
                          </Text>

                          {/* <DatePicker
                            disableFutureDates={true}
                            //minDate={null}
                            maxDate={maxDateState}
                            onDateChange={(selectedDateValue) =>
                              handleDateChange(
                                condition.condition_cd,
                                selectedDateValue
                              )
                            }
                            placeholderProp="Admin Date*"
                          /> */}

                          <DatePickerQuick
                            disableFutureDates={true}
                            // patientDOB={PatientDOB}
                            maxDate={maxDateState}
                            CUSTOM_DISABLE={true}
                            placeholderProp="Select Admin Date*"
                            // defaultDate={todaysDate}
                            onDateChange={(selectedDateValue) =>
                              handleDateChange(
                                condition.condition_cd,
                                selectedDateValue
                              )
                            }
                            calendarStlye={{
                              transform: "scale(0.9)",
                              right: "-22px",
                              top: "30px",
                            }}
                          />

                          {/* <DatePicker
                            disablePastDates={true}
                            //minDate={minDateState}
                            onDateChange={(selectedDateValue) =>
                              handleDueDateChange(
                                condition.condition_cd,
                                selectedDateValue
                              )
                            }
                            placeholderProp="Due Date"
                            minDate={new Date().toISOString()}
                          /> */}

                          <DatePickerQuick
                            disablePastDates={true}
                            // patientDOB={PatientDOB}
                            CUSTOM_DISABLE={true}
                            placeholderProp="Select Due Date"
                            minDate={new Date().toISOString()}
                            // defaultDate={todaysDate}
                            onDateChange={(selectedDateValue) =>
                              handleDueDateChange(
                                condition.condition_cd,
                                selectedDateValue
                              )
                            }
                            calendarStlye={{
                              transform: "scale(0.9)",
                              right: "-22px",
                              top: "30px",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                }
                footerActions={
                  <>
                    <Box w="100%" display="flex" flexDirection="row" gap="15px">
                      <PrimaryButton
                        buttonText="Add Vaccine"
                        isDisabled={actionAccessPatients?.create ? false : true}
                        onClick={AddMedicalConditionsCall}
                      />
                    </Box>
                  </>
                }
              />
            </>
          }
          footerActions={
            <>
              <Box w="100%" display="flex" flexDirection="row" gap="15px">
                <PrimaryButton
                  buttonText="Continue"
                  isDisabled={false}
                  onClick={AddMedicalConditions}
                />
              </Box>
            </>
          }
        />
      </Box>
    );
  }
);

export default NoVaccinationDataScreen;
