import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Flex,
  Divider,
  FormErrorMessage,
  Spacer,
} from "@chakra-ui/react";
import { COLORS } from "../../styles/colors";
import {
  updateDoctorNotes,
  GetDoctorNotes,
} from "../../../middleware/services/visits/patient-overview/updatedoctornotes";
import PrimaryButton from "../../primary-button";

import useLoader from "../../loader/loaderstates";
import Loader from "../../loader/loader";
import { Controller, useWatch } from "react-hook-form";
import { actionAccessMain } from "../../../auth";
import moment from "moment";
import { AddVitalData } from "../../../middleware/services/messages";
import { GetPatientHealthData } from "../../../middleware/services/visits/patient-overview";

const DoctorVitalsForm = ({
  patientId,
  doctorId,
  appointmentId,
  showSuccessToast,
  showErrorToast,
  fetchDoctorNotesEmptyIndication,
  fetchPatientHealthDataOfStartVisit,
  submitOnTabSwitch
}) => {

  const [patientHealthData, setPatientHealthData] = useState(null);

  const [currentHeightValue, setCurrentHeightValue] = useState("");
    const [currentHeightSubValue, setCurrentHeightSubValue] = useState("");
    const [currentHeightUnit, setCurrentHeightUnit] = useState("");
    const [currentWeightValue, setCurrentWeightValue] = useState("");
    const [currentWeightUnit, setCurrentWeightUnit] = useState("");

    
  const [weightUnit, setWeightUnit] = useState( "lbs"
  );
  const [weightValue, setWeightValue] = useState(""
  );
  const [heightUnit, setHeightUnit] = useState("ft"
  );
  const [heightValue, setHeightValue] = useState(""
  );
  const [heightSubValue, setHeightSubValue] = useState( ""
  );

  function formatHeightNumber(input) {
    const parts = input.toString().split(".");
    return {
      height: parts[0], // Whole number (integer part)
      subHeight: parts[1] || "", // Decimal part (no padding)
    };
  }
  

  const [inputData, setInputData] = useState({
    patientid: patientId,
    // appointmentid: appointmentId,
    doctorid: doctorId,
  });

  // Function to fetch patient entire health data and update state - FOR PATIENT CURRENT VISIT
  const fetchPatientHealthData = async () => {
    showLoader();
    try {
      const data = await GetPatientHealthData(inputData);
      
      setPatientHealthData(data);
      if (data?.demographics?.height) {
        const formattedHeight = formatHeightNumber(data?.demographics?.height ?? 0);
        setCurrentHeightValue(formattedHeight.height);
        setHeightValue(formattedHeight.height);
        setCurrentHeightSubValue(formattedHeight.subHeight);
        setHeightSubValue(formattedHeight.subHeight);
    }
    
    if (data?.demographics?.heightunit) {
        setCurrentHeightUnit(data?.demographics?.heightunit);
        setHeightUnit(data?.demographics?.heightunit);
    }
    
    if (data?.demographics?.weight) {
        setCurrentWeightValue(data?.demographics?.weight);
        setWeightValue(data?.demographics?.weight);
    }
    
    if (data?.demographics?.weightunit) {
        setCurrentWeightUnit(data?.demographics?.weightunit);
        setWeightUnit(data?.demographics?.weightunit);
    }
    
      // console.log(data?.demographics?.heightunit)
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  /// Call fetchPatientHealthData function whenever inputData or formattedInput changes
  useEffect(() => {
    fetchPatientHealthData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, errors },
  } = useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const temperatureUnit = useWatch({ control, name: "temperatureUnit" });

  const fetchDoctorNotes = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      const vitals = data?.output?.data?.vitals || [];
      const formValues = {};
      vitals.forEach((vital) => {
        let vitalName = vital.vitalname;

        // Check and adjust vital names if needed
        if (vitalName === "Body temperature") {
          vitalName = "Body Temperature";
        } else if (vitalName === "Respiratory rate") {
          vitalName = "Respiratory Rate";
        }
        formValues[vitalName] = vital.vitalvalue;
      });
      const temperatureUnit =
        vitals.find(
          (vital) =>
            vital.vitalname === "Body temperature" ||
            vital.vitalname === "Body Temperature"
        )?.vitalunit || "°F";
      formValues["temperatureUnit"] = temperatureUnit;

      // Reset form with fetched values
      reset(formValues);

      // console.log("Fetched form values: ", formValues);
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  const onSubmit = async (data) => {
    const vitals = [];

    const fields = [
      { name: "Systolic Blood Pressure", unit: "mmHg" },
      { name: "Diastolic Blood Pressure", unit: "mmHg" },
      { name: "Pulse", unit: "bpm" },
      { name: "Body Temperature", unit: data.temperatureUnit },
      { name: "Respiratory Rate", unit: "bpm" },
      { name: "Oxygen Saturation", unit: "%" },
      { name: "Fasting Blood Glucose", unit: "mg/dL" },
      { name: "Postprandial Blood Glucose", unit: "mg/dL" },
    ];

    fields.forEach((field) => {
      const value = data[field.name];
      const padZero = (num) => num.toString().padStart(2, "0");

      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${padZero(
        now.getMonth() + 1
      )}-${padZero(now.getDate())} ${padZero(now.getHours())}:${padZero(
        now.getMinutes()
      )}:${padZero(now.getSeconds())}`;

      if (value !== undefined && value !== "") {
        vitals.push({
          vitalname: field.name,
          vitalvalue: value,
          vitalunit: field.unit,
          vitaltype_cd: "",
          // vitaladdeddate: new Date().toISOString(),
          vitaladdeddate: formattedDate,
        });
      }
    });

    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        vitals: vitals,
      },
    };
    console.log(inputToSave);
    showLoader();
    try {
      // const result = await updateDoctorNotes(inputToSave.input);
      // if (result) {
      //   showSuccessToast("Vitals saved successfully");
      //   fetchDoctorNotesEmptyIndication();
      //   setIsButtonDisabled(true); // Disable button after successful submission
      // }
      if (inputToSave.input.vitals && inputToSave.input.vitals.length > 0) {
        const result = await updateDoctorNotes(inputToSave.input);
        if (result) {
          showSuccessToast("Vitals saved successfully");
          fetchDoctorNotesEmptyIndication();
          setIsButtonDisabled(true); // Disable button after successful submission
        }
      }
    } catch (error) {
      console.error("Error in saving vitals:", error);
      showErrorToast("Error happened while saving vitals.");
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotes();
  }, []);

  useEffect(() => {
    setIsButtonDisabled(!isDirty);
  }, [isDirty]);

  //BMI

  // const [weightUnit, setWeightUnit] = useState(
  //   currentWeightUnit ? currentWeightUnit : "lbs"
  // );
  // const [weightValue, setWeightValue] = useState(
  //   currentWeightValue ? currentWeightValue : ""
  // );
  // const [heightUnit, setHeightUnit] = useState(
  //   currentHeightUnit ? currentHeightUnit : "ft"
  // );
  // const [heightValue, setHeightValue] = useState(
  //   currentHeightValue ? currentHeightValue : ""
  // );
  // const [heightSubValue, setHeightSubValue] = useState(
  //   currentHeightSubValue ? currentHeightSubValue : ""
  // );

  const handleUnitChange = (newUnit) => {
    if (newUnit === "Standard") {
      if (weightValue) {
        if (weightUnit === "kg")
          setWeightValue((prev) => (prev * 2.20462).toFixed(2));
      }
      if (heightValue) {
        if (heightUnit === "cm") {
          setHeightValue(Math.floor(heightValue / 30.48));
          setHeightSubValue(Math.round((heightValue % 30.48) / 2.54));
        }
      }
      setWeightUnit("lbs");
      setHeightUnit("ft");
    } else if (newUnit === "Metric") {
      if (weightValue) {
        if (weightUnit === "lbs") {
          setWeightValue((prev) => (prev / 2.20462).toFixed(2));
        }
      }
      if (heightValue) {
        if (heightUnit === "ft") {
          const totalInches = heightValue * 12 + Number(heightSubValue);
          setHeightValue(Math.floor(totalInches * 2.54));
          setHeightSubValue(Math.round(((totalInches * 2.54) % 1) * 10));
        }
      }
      setWeightUnit("kg");
      setHeightUnit("cm");
    }
  };

  const [date, setDate] = useState(moment().format("DD-MM-yyyy"));
  const [time, setTime] = useState(moment().format("HH:mm"));

  const SubmitBMIform = async () => {
    let Weightinput = {
      patientid: patientId,
      appointment_id: appointmentId,
      vitals: [
        {
          vitalname: "Weight",
          vitalvalue: weightValue,
          vitalunit: weightUnit,
          vitaltype_cd: "",
          vitaladdeddate: `${date} ${time}`,
        },
      ],
    };
    let Heightinput = {
      patientid: patientId,
      appointment_id: appointmentId,
      vitals: [
        {
          vitalname: "Height",
          vitalvalue: heightValue + "." + heightSubValue,
          vitalunit: heightUnit,
          vitaltype_cd: "238",
          vitaladdeddate: `${date} ${time}`,
        },
      ],
    };
    if (moment(date, "DD-MM-YYYY", true).isValid()) {
      const formatteddate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      const vitaldate = formatteddate + " " + time;
      Weightinput.vitals[0].vitaladdeddate = vitaldate;
      Heightinput.vitals[0].vitaladdeddate = vitaldate;
    }
    try {
      // showLoader();
      if (weightValue) {
        console.log("updating weight");
        const response = await AddVitalData(Weightinput);
      }
      if (heightValue) {
        console.log("updating height");
        const result = await AddVitalData(Heightinput);
      }
      if (weightValue || heightValue) {
        // This block will execute if either var1 or var2 is truthy
        console.log("Refreshing patient data");
        fetchPatientHealthDataOfStartVisit();
      }

      // if (response.output.success == 1) {
      //   if (result.output.success == 1) {
      //     // dismissLoader();
      //   }
      // } else {
      //   showErrorToast("Error happened while saving demographics.");
      // }
    } catch (error) {
      console.error("Error in SubmitFunction:", error);
    }
  };

  // submit on tab switch and submit button is enalbed
  useEffect(()=>{
      if(!isButtonDisabled && submitOnTabSwitch){
      handleSubmit(onSubmit)();
      }
  },[submitOnTabSwitch])

  return (
    <Box
      position="relative"
      // height="120vh"
      display="flex"
      flexDirection="column"
      bg="#fcfcfd"
    >
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
          bg="rgba(255, 255, 255, 0.8)"
        >
          <Loader miniLoader={true} />
        </Box>
      )}
      <Box
        // bg="#fcfcfd"
        p={{ base: 4, md: 3 }}
        pt={1}
        borderRadius="md"
        boxShadow="md"
        // height="110vh"
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <VStack spacing={4} align="stretch" height="100%">
            {/* Row 1 */}
            <FormControl isInvalid={errors["Body Temperature"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width={{ base: "50%", md: "30%" }}>
                  <FormLabel mb="0px">Body Temperature</FormLabel>
                  <Controller
                    name="temperatureUnit"
                    control={control}
                    defaultValue="°F"
                    render={({ field }) => (
                      <Select
                        {...field}
                        height="50%"
                        width={{ base: "5%", md: "45%" }}
                        fontSize={"xs"}
                        size={"xs"}
                        border={"none"}
                        // style={{ textAlignLast: "left", ml: "0px" }}
                        style={{
                          textAlignLast: "left",
                          marginLeft: "0px",
                          minWidth: "60px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // backgroundColor: "red",
                        }}
                      >
                        <option value="°F" style={{ textAlignLast: "left" }}>
                          °F
                        </option>
                        <option value="°C">°C</option>
                      </Select>
                    )}
                  />
                </Flex>
                <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Body Temperature", {
                      maxLength: {
                        value: 6,
                        message: "Maximum 6 characters allowed",
                      },
                      pattern: {
                        value: /^(\d{1,3}(\.\d{0,2})?)$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const temp = parseFloat(value);
                          if (temperatureUnit === "°F") {
                            return (
                              (temp >= 77 && temp <= 108) ||
                              "Invalid input: Temperature should be between 77°F and 108°F"
                            );
                          } else {
                            return (
                              (temp >= 24 && temp <= 42) ||
                              "Invalid input: Temperature should be between 24°C and 42°C"
                            );
                          }
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    p="0 10px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Body Temperature"] &&
                      errors["Body Temperature"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider />

            {/* Row 2 */}
            <FormControl isInvalid={errors["Pulse"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width={{ base: "50%", md: "100%" }}>
                  <FormLabel width={{ base: "100%", md: "30%" }} mb="0px">
                    Heart Rate
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    beats per minute
                  </Box>
                </Flex>
                <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                  <Input
                    type="number"
                    {...register("Pulse", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const pulse = parseInt(value);
                          return (
                            (pulse >= 20 && pulse <= 300) ||
                            "Invalid input: Heart rate should be between 20 and 300 bpm"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                    p="0 10px"
                  />
                  <FormErrorMessage>
                    {errors["Pulse"] && errors["Pulse"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider />

            {/* Row 3 */}
            <FormControl isInvalid={errors["Respiratory Rate"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width={{ base: "100%", md: "30%" }} mb="0px">
                    Respiratory Rate
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    breaths per minute
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    {...register("Respiratory Rate", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9][0-9]{0,2}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const rate = parseInt(value);
                          return (
                            (rate >= 5 && rate <= 60) ||
                            "Invalid input: Respiratory rate should be between 5 and 60 breaths per minute"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Respiratory Rate"] &&
                      errors["Respiratory Rate"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <Divider />

            {/* Row 4 */}
            {/* <FormControl isInvalid={errors["Systolic Blood Pressure"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="30%">
                  <FormLabel mb="0px">Systolic</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    {...register("Systolic Blood Pressure", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const systolic = parseInt(value);
                          return (
                            (systolic >= 70 && systolic <= 200) ||
                            "Invalid input: Systolic BP should be between 70 and 200 mmHg"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    p="0 10px"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Systolic Blood Pressure"] &&
                      errors["Systolic Blood Pressure"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl> */}

            {/* Row 5 */}
            {/* <FormControl isInvalid={errors["Diastolic Blood Pressure"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="30%">
                  <FormLabel mb="0px">Diastolic</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    {...register("Diastolic Blood Pressure", {
                      maxLength: {
                        value: 3,
                        message: "Maximum 3 digits allowed",
                      },
                      pattern: {
                        value: /^[0-9]{1,3}$/,
                        message: "Invalid input",
                      },
                      validate: (value) => {
                        if (value) {
                          const diastolic = parseInt(value);
                          return (
                            (diastolic >= 40 && diastolic <= 120) ||
                            "Invalid input: Diastolic BP should be between 40 and 120 mmHg"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage>
                    {errors["Diastolic Blood Pressure"] &&
                      errors["Diastolic Blood Pressure"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl> */}

            {/* Row 4: Merged Systolic and Diastolic BP Inputs */}
            <FormControl
              isInvalid={
                errors["Systolic Blood Pressure"] ||
                errors["Diastolic Blood Pressure"]
              }
            >
              <Flex height="60px" align="center">
                {/* Single Label for both Systolic and Diastolic */}
                <Flex direction="column" width="30%">
                  <FormLabel mb="0px">Blood Pressure</FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mmHg
                  </Box>
                </Flex>

                {/* Systolic and Diastolic Input Fields Aligned Right */}
                <Box width="70%" textAlign="right">
                  <Flex justify="flex-end" align="center">
                    {/* Systolic Input */}
                    <Input
                      type="number"
                      {...register("Systolic Blood Pressure", {
                        maxLength: {
                          value: 3,
                          message: "Maximum 3 digits allowed",
                        },
                        pattern: {
                          value: /^[0-9]{1,3}$/,
                          message: "Invalid input",
                        },
                        validate: (value) => {
                          if (value) {
                            const systolic = parseInt(value);
                            return (
                              (systolic >= 60 && systolic <= 300) ||
                              "Invalid input: Systolic BP should be between 60 and 300 mmHg"
                            );
                          }
                        },
                      })}
                      onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                      placeholder="Systolic"
                      height="48px"
                      width={{ base: "90px", md: "90px" }}
                      bg={"white"}
                      textAlign={"center"}
                      p="0 10px"
                      onChange={() => setIsButtonDisabled(false)}
                    />

                    {/* Separator */}
                    <Box mx={2}>/</Box>

                    {/* Diastolic Input */}
                    <Input
                      type="number"
                      {...register("Diastolic Blood Pressure", {
                        maxLength: {
                          value: 3,
                          message: "Maximum 3 digits allowed",
                        },
                        pattern: {
                          value: /^[0-9]{1,3}$/,
                          message: "Invalid input",
                        },
                        validate: (value) => {
                          if (value) {
                            const diastolic = parseInt(value);
                            return (
                              (diastolic >= 30 && diastolic <= 200) ||
                              "Invalid input: Diastolic BP should be between 30 and 200 mmHg"
                            );
                          }
                        },
                      })}
                      onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                      placeholder="Diastolic"
                      height="48px"
                      width={{ base: "90px", md: "90px" }}
                      bg={"white"}
                      textAlign={"center"}
                      p="0 10px"
                      onChange={() => setIsButtonDisabled(false)}
                    />
                  </Flex>

                  {/* Error Messages */}
                  <FormErrorMessage>
                    {errors["Systolic Blood Pressure"]?.message ||
                      errors["Diastolic Blood Pressure"]?.message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <Divider />

            {/* Row 6 */}
            <FormControl isInvalid={errors["Oxygen Saturation"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="30%" mb="0px">
                    SPO2
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    %
                  </Box>
                </Flex>
                <Box width="70%" textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Oxygen Saturation", {
                      maxLength: {
                        value: 6, // Adjusted to accommodate decimal places
                        message: "Enter value between 0 to 100",
                      },
                      pattern: {
                        value: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
                        message: "Enter value between 0 to 100",
                      },
                      validate: (value) => {
                        if (value) {
                          const o2 = parseInt(value);
                          return (
                            (o2 >= 50 && o2 <= 100) ||
                            "Invalid input: SPO2 should be between 50 and 100 %"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage mb={"1.5rem"}>
                    {errors["Oxygen Saturation"] &&
                      errors["Oxygen Saturation"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <Divider />

            {/* Row 7 */}
            <FormControl isInvalid={errors["Fasting Blood Glucose"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="60%" mb="0px">
                    Fasting Blood Glucose
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mg/dL
                  </Box>
                </Flex>
                <Box width="60%" textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Fasting Blood Glucose", {
                      // maxLength: {
                      //   value: 6, // Adjusted to accommodate decimal places
                      //   message: "Enter value between 20 to 500",
                      // },
                      // pattern: {
                      //   value: /^(20(\.\d{1,2})?|[2-4]\d{1,2}(\.\d{1,2})?|500(\.0{1,2})?)$/,
                      //   message: "Enter value between 20 and 500 (up to 2 decimal places)",
                      // },
                      validate: (value) => {
                        if (value) {
                          const fbg = parseInt(value);
                          return (
                            (fbg >= 20 && fbg <= 500) ||
                            "Invalid input: Blood Glucose must be between 20 and 500 mg/dL"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage mb={"1.5rem"}>
                    {errors["Fasting Blood Glucose"] &&
                      errors["Fasting Blood Glucose"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            {/* Row 8 */}
            <FormControl isInvalid={errors["Postprandial Blood Glucose"]}>
              <Flex height="60px" align="center">
                <Flex direction="column" width="100%">
                  <FormLabel width="60%" mb="0px">
                    Postprandial Blood Glucose
                  </FormLabel>
                  <Box fontSize={"xs"} pt="2px">
                    mg/dL
                  </Box>
                </Flex>
                <Box width="60%" textAlign="right">
                  <Input
                    type="number"
                    step="0.01"
                    {...register("Postprandial Blood Glucose", {
                      // maxLength: {
                      //   value: 6, // Adjusted to accommodate decimal places
                      //   message: "Enter value between 20 to 500",
                      // },
                      // pattern: {
                      //   value:
                      //     /^(20(\.\d{1,2})?|[2-4]\d{1,2}(\.\d{1,2})?|500(\.0{1,2})?)$/,
                      //   message:
                      //     "Enter value between 20 and 500 (up to 2 decimal places)",
                      // },
                      validate: (value) => {
                        if (value) {
                          const pbg = parseInt(value);
                          return (
                            (pbg >= 20 && pbg <= 500) ||
                            "Invalid input: Blood Glucose must be between 20 and 500 mg/dL"
                          );
                        }
                      },
                    })}
                    onWheel={(e) => e.target.blur()} // Prevent value change on scrolling
                    placeholder="Enter value"
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                    onChange={() => setIsButtonDisabled(false)}
                  />
                  <FormErrorMessage mb={"1.5rem"}>
                    {errors["Postprandial Blood Glucose"] &&
                      errors["Postprandial Blood Glucose"].message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider />

            {/* <Flex direction="column" gap={4} mt="2px">
              <FormControl>
                <Flex align="center">
                  <Flex direction="column" width={{ base: "50%", md: "30%" }}>
                    <FormLabel mb="0px">Weight</FormLabel>
                    <Select
                      value={weightUnit === "lbs" ? "Standard" : "Metric"}
                      onChange={(e) => handleUnitChange(e.target.value)}
                      fontSize={"xs"}
                      size={"xs"}
                      border="none"
                      textAlignLast="left"
                      ml="0px"
                      minWidth="60px"
                    >
                      <option value="Standard">Standard</option>
                      <option value="Metric">Metric</option>
                    </Select>
                  </Flex>
                  <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                    <Input
                      type="number"
                      value={weightValue}
                      placeholder={weightUnit}
                      onChange={(e) => setWeightValue(e.target.value)}
                      height="48px"
                      p="0 10px"
                      width={{ base: "100px", md: "200px" }}
                      bg={"white"}
                      textAlign={"center"}
                      ml="auto"
                    />
                  </Box>
                </Flex>
              </FormControl>

              <FormControl>
                <Flex align="center">
                  <Flex direction="column" width={{ base: "50%", md: "30%" }}>
                    <FormLabel mb="0px">Height</FormLabel>
                    <Select
                      value={heightUnit === "ft" ? "Standard" : "Metric"}
                      onChange={(e) => handleUnitChange(e.target.value)}
                      fontSize={"xs"}
                      size={"xs"}
                      border="none"
                      textAlignLast="left"
                      ml="0px"
                      minWidth="60px"
                    >
                      <option value="Standard">Standard</option>
                      <option value="Metric">Metric</option>
                    </Select>
                  </Flex>
                  <Spacer />
                  <Box width={{ base: "50%", md: "70%" }} textAlign="right">
                    <Flex align="center">
                      <Input
                        type="number"
                        value={heightValue}
                        placeholder={heightUnit}
                        onChange={(e) => setHeightValue(e.target.value)}
                        height="48px"
                        p="0 10px"
                        width={{ base: "70px", md: "100px" }}
                        bg={"white"}
                        textAlign={"center"}
                      />
                      <Box mx={2}>.</Box>
                      <Input
                        type="number"
                        value={heightSubValue}
                        placeholder={heightUnit === "ft" ? "in" : "mm"}
                        onChange={(e) => setHeightSubValue(e.target.value)}
                        height="48px"
                        p="0 10px"
                        width={{ base: "70px", md: "100px" }}
                        bg={"white"}
                        textAlign={"center"}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </FormControl>
            </Flex> */}

            <FormControl>
              {/* Weight */}
              <Flex direction="row" align="center" width="100%" mb={4}>
                <Flex direction="column" width="100%">
                  <FormLabel mb="0" width={{ base: "50%", md: "15%" }}>
                    Weight
                  </FormLabel>
                  <Flex direction="column" width="100%">
                    <Flex align="center" mb={1} gap={2}>
                      <Box fontSize="xs" fontWeight="bold">
                        {weightUnit}
                      </Box>
                      <Select
                        value={weightUnit === "lbs" ? "Standard" : "Metric"}
                        onChange={(e) => handleUnitChange(e.target.value)}
                        fontSize={"xs"}
                        size={"xs"}
                        width={{ base: "80px", md: "100px" }}
                        border="none"
                        textAlignLast="left"
                      >
                        <option value="Standard">Standard</option>
                        <option value="Metric">Metric</option>
                      </Select>
                    </Flex>
                  </Flex>
                </Flex>
                <Box w="60%" textAlign="right">
                  <Input
                    type="number"
                    value={weightValue}
                    placeholder={weightUnit}
                    onChange={(e) => setWeightValue(e.target.value)}
                    // height="40px"
                    // p="0 10px"
                    // width={{ base: "100px", md: "150px" }}
                    height="48px"
                    width={{ base: "100px", md: "200px" }}
                    p="0 10px"
                    bg={"white"}
                    textAlign={"center"}
                    ml="auto"
                  />
                </Box>
              </Flex>

              {/* Height */}
              <Flex direction="row" align="center" width="100%">
                <Flex direction="column" width="100%">
                  <FormLabel mb="0" width={{ base: "50%", md: "15%" }}>
                    Height
                  </FormLabel>
                  <Flex direction="column" width="100%">
                    <Flex align="center" mb={1} gap={2}>
                      <Box fontSize="xs" fontWeight="bold">
                        {heightUnit}
                      </Box>
                      <Select
                        value={heightUnit === "ft" ? "Standard" : "Metric"}
                        onChange={(e) => handleUnitChange(e.target.value)}
                        defaultValue={heightUnit}
                        fontSize={"xs"}
                        size={"xs"}
                        width={{ base: "80px", md: "100px" }}
                        border="none"
                        textAlignLast="left"
                      >
                        <option value="Standard">Standard</option>
                        <option value="Metric">Metric</option>
                      </Select>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justify="flex-end" align="center" gap={2}>
                  <Input
                    type="number"
                    value={heightValue}
                    defaultValue={heightValue}
                    placeholder={heightUnit}
                    onChange={(e) => setHeightValue(e.target.value)}
                    height="48px"
                    p="0 10px"
                    width={{ base: "70px", md: "89px" }}
                    bg={"white"}
                    textAlign={"center"}
                  />
                  <Box>.</Box>
                  <Input
                    type="number"
                    value={heightSubValue}
                    placeholder={heightUnit === "ft" ? "in" : "mm"}
                    onChange={(e) => setHeightSubValue(e.target.value)}
                    height="48px"
                    p="0 10px"
                    width={{ base: "70px", md: "89px" }}
                    bg={"white"}
                    textAlign={"center"}
                  />
                </Flex>
              </Flex>
            </FormControl>

            <PrimaryButton
              type="submit"
              onClick={() => {
                // Call both functions in sequence
                // await onSubmit(data);  // Ensure that 'data' is available in the context
                SubmitBMIform();
              }}
              buttonText="Save Vitals"
              isDisabled={
                (isButtonDisabled && !heightValue && !weightValue) ||
                !actionAccessMain(19)?.create
              }
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                mr: "0px",
                ml: "0px",
                position: "sticky",
                bottom: "0",
                zIndex: "1",
                mx: "auto",
                mt: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            />

            {/* <PrimaryButton
              type="submit"
              buttonText="Submit Vitals"
              isDisabled={isButtonDisabled}
              btnStyle={{
                h: "2.5rem",
                w: "100%",
                p: "0",
                mr: "12px",
                ml: "12px",
                position: "sticky",
                bottom: "0",
                zIndex: "1",
                mx: "auto",
                bg: COLORS.PRIMARY_GRADIENT,
                color: COLORS.PRIMARY_WHITE,
                _hover: { bg: COLORS.PRIMARY_COLOR },
              }}
            /> */}
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default DoctorVitalsForm;
