import  FetchData, { } from '../../client';
import formapimodalhome from '../../domains/home/home';
import {getBranchId, getLoginID}  from '../../../auth';
import formAPIModeluserdetails from '../../domains/users/userdetails/doctoruserdetails';
import formAPIModeluser from '../../domains/users/user/doctorusers';

// without formatting output

export const createDoctorUser = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    inputData.loginid = getLoginID();
    const result = await FetchData("midoc_createdoctoruser", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in createDoctorUser :', error);
    throw error;
  }
};


export const updateDoctorUser = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.loginid = getLoginID();
      const result = await FetchData("midoc_updatedoctoruser", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in updateDoctorUser:', error);
      throw error;
    }
  };

  export const deleteDoctorUser = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_deletedoctoruser", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in deleteDoctorUser:', error);
      throw error;
    }
  };


  
// with formatting output
export const getDoctorUser = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctoruser", inputData);
      //console.log(result,"Home db response")
     
      if(result.output.message == "No roles are available for this doctorid"){
        return result
      }else if(result.output.data) {
      var finalOutput = formAPIModeluser(result.output.data)
      
      return finalOutput;
      }
  
    } catch (error) {
      console.error('Error in getDoctorUser:', error);
      throw error;
    }
  };

 export const getDoctorUserDetail = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      inputData.doctorid = getLoginID();
      const result = await FetchData("midoc_getdoctoruserdetail", inputData);
      //console.log(result,"Home db response")
      if(result.output.message == "No users are available for this doctorid"){
        return result;
      }else if (result.output) {
      var finalOutput = formAPIModeluserdetails(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error in getDoctorUserDetail:', error);
      throw error;
    }
  };


