import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  createRef,
} from "react";
import {
  Box,
  Badge,
  Flex,
  Divider,
  Text,
  Image,
  Button,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";
import MiniSideBar from "../../components/mini-sidebar";
import PatientVisitNav from "../../components/patient-visit-navbar";
import styles from "./styles/startvisit.module.css";
import VitalDisplayCard from "../../components/patient-health/vital-display-card";
import PastVitalDisplayCard from "../../components/patient-health/past-visits/past-vitals";
import PastMedicalHistory from "../../components/patient-health/past-visits/past-medical-history";
import PastMedications from "../../components/patient-health/past-visits/past-medications";
import PastPhysicalExam from "../../components/patient-health/past-visits/past-physical-exam";
import PastTreatmentPlan from "../../components/patient-health/past-visits/past-treatment-plan";
import PastSurgicalHistory from "../../components/patient-health/past-visits/past-surgical-history";
import PastFamilyTreeTable from "../../components/patient-health/past-visits/past-family-tree";
import PastVaccination from "../../components/patient-health/past-visits/past-vaccinations";
import PastSocialHistory from "../../components/patient-health/past-visits/past-social-history";
import PastAllergies from "../../components/patient-health/past-visits/past-allergy";
import PastLabTests from "../../components/patient-health/past-visits/past-lab-test";
import PastVisitOverview from "../../components/patient-health/past-visits/past-overview";
import PhysicalExamCard from "../../components/patient-health/physical-exam-card";
import HpiCard from "../../components/patient-health/hpi-card";
import DemographicsCard from "../../components/patient-health/demographics-card";
import ChiefComplaintCard from "../../components/patient-health/chief-complaint-card";
import DoctorAssessmentCard from "../../components/patient-health/doctor-assessment-card";
import TreatmentPlanCard from "../../components/patient-health/treatment-plan-card";
import CurrentVisitOverview from "../../components/patient-health/overview";
import MedicalHistory from "../../components/patient-health/medical-history";
import SurgicalHistory from "../../components/patient-health/surgical-history";
import FamilyHistory from "../../components/patient-health/family-history";
import Medications from "../../components/patient-health/medications";
import Vaccination from "../../components/patient-health/vaccinations";
import TreatmentPlan from "../../components/patient-health/treatment-plan";
import PatientVisit from "../../components/patient-health/past-visits";
import SocialHistory from "../../components/patient-health/social-history";
import PhysicalExam from "../../components/patient-health/physical-exam";
import Allergies from "../../components/patient-health/allergies";
import LabTests from "../../components/patient-health/lab-test";
import {
  GetPatientHealthData,
  GetPatientSelectedVisitData,
} from "../../middleware/services/visits/patient-overview";
import { PrimaryButton, Toaster, PrimaryInput, DropdownInput } from "../../components";

import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import Loader from "../../components/loader/loader";

import useLoader from "../../components/loader/loaderstates";
import DatePicker from "../../components/date-picker";
import { format, parseISO } from "date-fns";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import NoDataScreen from "../../components/patient-health/no-data-screen";
import { getIsDoctor, getLoginID, useScreenType } from "../../auth";

import { updateCondition } from "../../middleware/services/visits/patient-overview/updatehealth";
import DoctorVitalsForm from "../../components/patient-health/doctor-notes/vital-form";
import DoctorComplaintInputGPUCard from "../../components/patient-health/doctor-notes/complaint-card";
import DoctorComplaintInputCPUCard from "../../components/patient-health/doctor-notes/complaint-card-cpu";
import DoctorNotesAssessment from "../../components/patient-health/doctor-notes/assessment-plans";
import DoctorROSCard from "../../components/patient-health/doctor-notes/ros-card";

import DoctorPhysicalExamCard from "../../components/patient-health/doctor-notes/physical-exam-card";
import DoctorNotesOrders from "../../components/patient-health/doctor-notes/order-card";
import HpiNotesCard from "../../components/patient-health/doctor-notes/hpi-notes-card";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import useSecondLoader from "../../components/loader/secondloader";
import Chatbot from "../../components/chatbot";
import GPUChatbot from "../../components/chatbot/gpuIndex";
import TranscribeNotes from "../../components/patient-health/doctor-notes/notes-transcription/notes-transcription";

import GPUChunks from "../../components/patient-health/doctor-notes/notes-transcription/gpu-notes-transcription";
import GPUChunksTranscribeNotes from "../../components/patient-health/doctor-notes/notes-transcription/GPU-chunks-notes-transcription";
import {
  GetDoctorNotes,
  updateDoctorNotes,
} from "../../middleware/services/visits/patient-overview/updatedoctornotes";
import InvestigationsDisplayCard from "../../components/patient-health/vital-display-card/investigations";
import NoMedicalConditionDataScreen from "../../components/patient-health/medical-history/medical-condition-no-screen";
import NoSurgicalHistoryDataScreen from "../../components/patient-health/surgical-history/surgical-history-no-screen";
import StartVisitOverview from "../../components/patient-health/overview/start-visit-overview";
import ImagingTests from "../../components/patient-health/lab-test/imaging";
import PastImagingTests from "../../components/patient-health/past-visits/past-imaging";
import NoAllergyDataScreen from "../../components/patient-health/allergies/allergy-no-screen";
import NoSocialHistoryDataScreen from "../../components/patient-health/social-history/social-history-no-screen";
import NoVaccinationDataScreen from "../../components/patient-health/vaccinations/vaccinations-no-data-screen";
import CPUHpiNotesCard from "../../components/patient-health/doctor-notes/hpi-notes-card-cpu";
import { actionAccessMain } from "../../auth";
import {
  FetchMyHealthAIData,
  GenerateAskADoctorPrescriptionFromChats,
} from "../../middleware/services/visits/aicalls";
import CPUMessages from "../../components/patient-health/messages";
import GPUMessages from "../../components/patient-health/messages/gpu-index";
import VirtualChatDisplay from "../../components/patient-health/doctor-notes/virtual-visit-chats";
import CPUTranscribeNotes from "../../components/patient-health/doctor-notes/notes-transcription/CPU-transcription";
import AudioCompatibilityCheck from "../../components/patient-health/doctor-notes/notes-transcription/compatibility-check";
import DoctorPhysicalExamCPUCard from "../../components/patient-health/doctor-notes/physical-exam-card-cpu";
import UploadDoctorDocuments from "../../components/patient-health/doctor-notes/doctor-documents";
import DoctorROSCPUCard from "../../components/patient-health/doctor-notes/ros-card-cpu";
import { AcceptVisit, FetchDoctorsByUser } from "../../middleware/services/home";
import AssignVisit from "../../components/assignVisit";

const StartVisitIndex = () => {
  //----------------------------------------AI chatbot
  const [shouldCloseSocket, setShouldCloseSocket] = useState(false);
  const [actionAccessAssigning, setActionAccessAssigning] = useState(null);
  const [ShowAssignModal , SetShowAssignModal ] = useState(false)

  const closeChatBotOnEndVisit = () => {
    setShouldCloseSocket(true);
  };

  const resetCloseSocketCommand = () => {
    setShouldCloseSocket(false);
  };

  const useGPUChatbot = !!navigator.gpu;
  // const useGPUChatbot = false;

  // --------------------------------------- dummy data---------------------------
  const doctorAssessmentData = {
    remarks: [
      "Detected cancer cells in your body.",
      "You need urgent attention.",
    ],
    doctortype: "General physician",
    doctorname: "Dr. Mahroos Ali",
    date: "10th Jan, 2023",
    doctorimage: "/assets/imgs/no-image.png",
  };

  //------------------------

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const patientId = params.get("patientId");
  const appointmentId = params.get("appointmentId");
  const doctorId = getLoginID();

  const timeToResume = params.get("timeContinue");
  const virtualVisit = params.get("virtualVisit");

  // console.log(virtualVisit);

  const screenType = useScreenType();

  const audioCompatibilityDemo = useRef(null);

  //------------------------------------------ Virtual Visits

  // accepting virtual visit

  const acceptvisit = async () => {
    let input = {
      doctorid: doctorId,
      patientid: patientId,
      appointmentid: appointmentId,
      completed: "1",
    };
    try {
      const result = await AcceptVisit(input);
      // console.log(result);
      // console.log(input);
      if (result != null) {
        // do nothing
        return;
      } else {
      }
    } catch (error) {
      console.error("Error in Visit details accepting visit", error);
    }
  };

  //----------------------------
  const reAssignModal = useRef();
  var [DoctorList, setDoctorList] = useState([]);
  var [DoctorListForDropdown, setDoctorListForDropdown] = useState([]);
  
    var [DefaultDoctor, setDefaultDoctor] = useState(null);
    var [IsAssign, setIsAssign] = useState(null);

  const [askDoctorAIResponse, setAskDoctorAIResponse] = useState({});
  const [askADoctorAIRAChats, setAskADoctorAIRAChats] = useState([]);
  const [saveAIAskDoctorPrescription, setSaveAIAskDoctorPrescription] =
    useState(true);

  const virtualVisitChatNotes = useRef(null);

  const fetchDoctorNotesForVirtualVisit = async () => {
    const patientNameForAskDoctor = "Not available";

    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };
    //console.log("Calling AI Prescription");

    showLoader();
    try {
      const data = await GetDoctorNotes(inputToFetch.input);
      //console.log(data?.output?.data);
      setIsAssign(data?.output?.data?.isassign)
      //console.log("status", data?.output?.data?.appointmentstatus);
      if (data?.output?.data?.appointmentstatus === "start") {
        const acceptvisitresult = await acceptvisit();
        const result = await FetchMyHealthAIData(
          {},
          patientId,
          "Not available",
          "Not available",
          "Not available"
        );
        const doctornotes = data?.output?.data;
        let AiInput = {
          ...result,
          chats: doctornotes?.virtualappointmentchatresults
            ? doctornotes?.virtualappointmentchatresults
            : [],
          chiefcomplaint: doctornotes?.chiefcomplaint,
          historyofpresentillness: doctornotes?.historyofpresentillness,
          hpitext: doctornotes?.hpitext,
          patientcountry: "USA",
          patientname: patientNameForAskDoctor,
          doctorid: doctorId,
        };
        // console.log(AiInput);
        const AiResultOfAskDoctorPrescription =
          await GenerateAskADoctorPrescriptionFromChats(AiInput);
        // console.log(AiResultOfAskDoctorPrescription);
        setAskDoctorAIResponse(AiResultOfAskDoctorPrescription);

        // state true
        // Run saveDoctorNotesForVirtualVisit immediately after setting the AI response if saveAIAskDoctorPrescription is true
        if (saveAIAskDoctorPrescription) {
          console.log(
            "Saving AI Response to Doctor Notes",
            AiResultOfAskDoctorPrescription
          );
          saveDoctorNotesForVirtualVisit(AiResultOfAskDoctorPrescription);
        }
      }
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      dismissLoader();
    }
  };

  //-----------------------Formatting AI response

  function formatAIMedications(medications) {
    return medications.map((medication) => {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(parseInt(medication.duration) - 1, "days")
        .format("YYYY-MM-DD");

      return {
        id: null,
        inventoryid: null,
        medicinename: medication.medicinename || "",
        medicinetype: "Tablet", // Assuming default
        dosage: medication.dosage || "",
        dosageunit: "mg",
        startdate: startDate,
        enddate: endDate,
        frequency: parseInt(medication.frequency) || 1,
        morning: medication.morning === "yes" ? 1 : 0,
        evening: medication.evening === "yes" ? 1 : 0,
        night: medication.night === "yes" ? 1 : 0,
        beforefood: medication.beforefood === "yes" ? 1 : 0,
        afterfood: medication.afterfood === "yes" ? 1 : 0,
        chemical_composition: medication.chemicalcomposition || "",
        prescribedby: null, // Assuming default
        isactive: 1,
      };
    });
  }

  function formatAIVitals(vitals) {
    const currentTimestamp = moment().format("YYYY-MM-DD HH:mm:ss");

    return vitals.map((vital) => ({
      vitalname: vital.name,
      vitalvalue: vital.value,
      vitalunit:
        vital.unit === "F" ? "°F" : vital.unit === "C" ? "°C" : vital.unit, // Convert "F" to "°F" and "C" to "°C"
      vitaltype_cd: "",
      vitaladdeddate: currentTimestamp,
    }));
  }

  function formatAIDiagnoses(diagnoses) {
    return diagnoses.map((item, index) => ({
      diagnosis: item.diagnosis,
      plan: item.plan,
      diagnosisid: index,
    }));
  }

  function formatAIOrders(order, order_type) {
    return order.map((item, index) => ({
      id: null,
      entitytype: order_type,
      ordername: item.name,
    }));
  }

  const saveDoctorNotesForVirtualVisit = async (AIResponse) => {
    // console.log("input to format", AIResponse)
    const inputToSave = {
      input: {
        doctorid: doctorId,
        patientid: patientId,
        appointmentid: appointmentId,
        physicalexamtext: {
          description: AIResponse?.assessment_plan?.physical_exam,
          type: "other",
        },
        rostext: {
          description: AIResponse?.assessment_plan?.review_of_system,
          type: "other",
        },
        medicinedetail: AIResponse?.medicines?.recommended_medicines
          ? formatAIMedications(AIResponse?.medicines?.recommended_medicines)
          : [],
        vitals: AIResponse?.vitals?.vitals
          ? formatAIVitals(AIResponse?.vitals?.vitals)
          : [],
        doctorsassessment: AIResponse?.assessment_plan?.assessment_plan
          ? formatAIDiagnoses(AIResponse?.assessment_plan?.assessment_plan)
          : [],
        procedure: AIResponse?.procedures?.recommended_procedures
          ? formatAIOrders(
              AIResponse?.procedures?.recommended_procedures,
              "procedure"
            )
          : [],
        lab: AIResponse?.labs?.recommended_lab
          ? formatAIOrders(AIResponse?.labs?.recommended_lab, "lab")
          : [],
        imaging: AIResponse?.imaging?.recommended_imaging
          ? formatAIOrders(AIResponse?.imaging?.recommended_imaging, "imaging")
          : [],
      },
    };
    showLoader();
    // console.log(inputToSave.input);
    try {
      console.log();
      const result = await updateDoctorNotes(inputToSave.input);
      if (result) {
        showSuccessToast("AI Notes Saved successfully");
        setSaveAIAskDoctorPrescription(false);
      }
    } catch (error) {
      console.error("Error in Update Doctor Notes:", error);
      showErrorToast("Error Happened While Saving.");
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    // if virtual visit, fetch data
    if (virtualVisit) {
      fetchDoctorNotesForVirtualVisit();
    }
    setActionAccessAssigning(actionAccessMain(25))
  }, []);

  // useEffect(() => {
  //   // run this whenever the AIResponse changes and save state is true
  //   if (saveAIAskDoctorPrescription) {
  //     console.log("Saving AI Response to Doctor Notes", askDoctorAIResponse);
  //     saveDoctorNotesForVirtualVisit();
  //   }
  // }, [askDoctorAIResponse]);

  //   useEffect(() => {
  //     // if virtual visit, fetch data
  //     if (virtualVisit) {
  //         fetchDoctorNotesForVirtualVisit()
  //             .then(() => {
  //                 // Run this after fetching data if saveAIAskDoctorPrescription is true
  //                 if (saveAIAskDoctorPrescription) {
  //                     console.log("Saving AI Response to Doctor Notes", askDoctorAIResponse);
  //                     saveDoctorNotesForVirtualVisit();
  //                 }
  //             })
  //             .catch((error) => console.error("Error fetching doctor notes:", error));
  //     }
  // }, []);

  // ----------------------------------------- tabs section--------------------------------------

  const patientOverviewTabs = [
    "Overview",
    "Past Visits",
    "Vitals",
    "Medications",
    "Medical History",
    "Surgical History",
    "Family History",
    "Social History",
    // "Physical Exam", // THESE WILL NOT BE THERE IN PATIENT CURRENT VISIT OVERVIEW PANEL
    // "Treatment Plan", // THESE WILL NOT BE THERE IN PATIENT CURRENT VISIT OVERVIEW PANEL
    // "ROS", // THESE WILL NOT BE THERE IN PATIENT CURRENT VISIT OVERVIEW PANEL
    "Allergies",
    "Vaccinations",
    "Investigations",
    "Lab Tests",
    "Imaging Reports",
  ];

  const patientPastVisitTabs = [
    "Overview",
    "Vitals",
    "Medications",
    "Medical History",
    "Surgical History",
    "Family History",
    "Social History",
    "Physical Exam",
    "Treatment Plan",
    "ROS",
    "Allergies",
    "Vaccinations",
    "Investigations",
    "Lab Tests",
    "Imaging Reports",
  ];

  const [selectedPatientTabIndex, setSelectedPatientTabIndex] = useState(0);
  const tabListRef = useRef(null);

  const [viewPastVisitTabs, setViewPastVisitTabs] = useState(false);
  const [pastVisitTabsHeader, setPastVisitTabsHeader] = useState("");

  useEffect(() => {
    // Ensure the initial scroll position shows the very first tab
    if (tabListRef.current) {
      tabListRef.current.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, []);

  const handlePatientTabClick = (index) => {
    setSelectedPatientTabIndex(index);
  };

  const scrollLeft = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handlePastVisitClick = async (appointmentid, date) => {
    // console.log("Appointment ID:", appointmentid);
    const formattedDate = moment(date).format("DD MMM, YYYY");
    const newHeader = `${formattedDate} - Visit`;
    setPastVisitTabsHeader(newHeader);
    setViewPastVisitTabs(true); // sets to true
    setSelectedPatientTabIndex(0);
    const inputData = {
      patientid: patientId,
      appointmentid: appointmentid.toString(), // pass appointment id here
      doctorid: doctorId,
    };

    // Fetch data directly with the constructed input data
    await fetchPatientSelectedVisitData(inputData, date);
  };

  const goToPatientCurrentVisit = (isVisible) => {
    setViewPastVisitTabs(isVisible);
    setSelectedPatientTabIndex(1); // 1 index is of past visit tab in patient overview of current visit
  };

  const doctorNoteTabs = [
    { name: "Chief complaint", id: 16, tabindex: 0 },
    { name: "HPI", id: 17, tabindex: 1 },
    { name: "ROS", id: 18, tabindex: 2 },
    { name: "Vitals", id: 19, tabindex: 3 },
    { name: "Physical Exam", id: 20, tabindex: 4 },
    { name: "Assessment", id: 21, tabindex: 5 },
    { name: "Orders", id: 22, tabindex: 6 },
    { name: "Documents", id: 23, tabindex: 7 },
  ];

  // Map to get the complete objects of accessible tabs
  const accessibleTabNames = doctorNoteTabs
    .filter((tab) => actionAccessMain(tab.id)?.read) // Check if the user has read access for the tab
    .map((tab) => tab); // This will return the entire tab object

  const [selectedDoctorTabIndex, setSelectedDoctorTabIndex] = useState(
    accessibleTabNames.length > 0 ? accessibleTabNames[0].tabindex : 0
  );

  const [submitChiefComplaintTab, setSubmitChiefComplaintTab] = useState(false);
  const [submitHPITab, setSubmitHPITab] = useState(false);
  const [submitRosTab, setRosTab] = useState(false);
  const [submitVitalsTab, setVitalsTab] = useState(false);
  const [submitPhysicalExamTab, setPhysicalExamTab] = useState(false);
  const [submitAssessmentTab, setAssessmentTab] = useState(false);


  const doctorTabListRef = useRef(null);
  const handleDoctorTabClick = (index) => {

    // when switched to other tabs, first submit current tab using below props, then after 10ms switch to the selected tab
    if(index != 0){
      setSubmitChiefComplaintTab(true)
    }
    if(index != 1){
      setSubmitHPITab(true)
    }
    if(index != 2){
      setRosTab(true)
    }
    if(index != 3){
      setVitalsTab(true)
    }
    if(index != 4){
      setPhysicalExamTab(true)
    }
    if(index != 5){
      setAssessmentTab(true)
    }
    setTimeout(() => {
      setSelectedDoctorTabIndex(index); // switch to the tab clicked

      // set all values to false after switching to the tab
      setSubmitChiefComplaintTab(false)
      setSubmitHPITab(false)
      setRosTab(false)
      setVitalsTab(false)
      setPhysicalExamTab(false)
      setAssessmentTab(false)
    }, 10);
  };
  useEffect(() => {
    // Ensure the initial scroll position shows the very first tab
    if (doctorTabListRef.current) {
      doctorTabListRef.current.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, []);

  const scrollDoctorTabsLeft = () => {
    if (doctorTabListRef.current) {
      doctorTabListRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollDoctorTabsRight = () => {
    if (doctorTabListRef.current) {
      doctorTabListRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const showEmptyIndicator = false;

  function checkDataPresence(data) {
    return {
      "Chief complaint": !!data.chiefcomplaint.chiefcomplaint,
      HPI:
        (Array.isArray(data.historyofpresentillness) &&
          data.historyofpresentillness.length > 0) ||
        !!data.hpitext?.description,
      ROS: Array.isArray(data.reviewofsystem) && data.reviewofsystem.length > 0,
      Vitals: Array.isArray(data.vitals) && data.vitals.length > 0,
      "Physical Exam":
        Array.isArray(data.physicalexam) && data.physicalexam.length > 0,
      Assessment:
        Array.isArray(data.doctorsassessment) &&
        data.doctorsassessment.length > 0,
      Orders:
        !!data.orders &&
        (data.orders.lab ||
          data.orders.imaging ||
          data.orders.procedure ||
          data.orders.medication),
    };
  }

  const [doctorNotesEmptyMapping, setDoctorNotesEmptyMapping] = useState({});

  const fetchDoctorNotesEmptyIndication = async () => {
    const inputToFetch = {
      input: {
        patientid: patientId,
        doctorid: doctorId,
        appointmentid: appointmentId,
      },
    };

    // showLoader();
    try {
      const result = await GetDoctorNotes(inputToFetch.input);
      const data = result?.output?.data;
      setIsAssign(data?.isassign)
      const emptyMapping = checkDataPresence(data);
      // console.log(emptyMapping)
      setDoctorNotesEmptyMapping(emptyMapping);
      if (virtualVisit) {
        setAskADoctorAIRAChats(
          data?.virtualappointmentchatresults
            ? data?.virtualappointmentchatresults
            : []
        );
      }
    } catch (error) {
      console.error("Error fetching Doctor Notes data:", error);
    } finally {
      // dismissLoader();
    }
  };

  useEffect(() => {
    fetchDoctorNotesEmptyIndication();
  }, []);

  //--------------------------------------------------------------------------

  const { isLoading, showLoader, dismissLoader } = useLoader();
  const { isSecondLoading, showSecondLoader, dismissSecondLoader } =
    useSecondLoader();
  const { showSuccessToast, showErrorToast } = Toaster();

  //---------------------------------Patient Entire Health Data Section - FOR PATIENT CURRENT VISIT-----------------------------------

  // Define state to store formatted input data - FOR PATIENT CURRENT VISIT
  const [patientHealthData, setPatientHealthData] = useState(null);
  const [navHeaderOverviewData, setNavHeaderOverviewData] = useState(null);

  // Define state to store input data - FOR PATIENT CURRENT VISIT
  const [inputData, setInputData] = useState({
    patientid: patientId,
    // appointmentid: appointmentId,
    doctorid: doctorId,
  });

  // Function to fetch patient entire health data and update state - FOR PATIENT CURRENT VISIT
  const fetchPatientHealthData = async () => {
    showLoader();
    try {
      const data = await GetPatientHealthData(inputData);
      // console.log(data);
      setPatientHealthData(data);
    } catch (error) {
      console.error("Error fetching patient health data:", error);
    } finally {
      dismissLoader();
    }
  };

  /// Call fetchPatientHealthData function whenever inputData or formattedInput changes
  useEffect(() => {
    fetchPatientHealthData();
  }, [inputData]);

  function calculateAge(dob) {
    if (!dob) {
      return "";
    }

    const dobDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();
    const dayDifference = today.getDate() - dobDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  function formatHeightNumber(input) {
    const parts = input.toString().split(".");
    return {
      height: parts[0], // Whole number (integer part)
      subHeight: parts[1]?.padEnd(2, "0") || "0", // Decimal part (padded to 2 digits)
    };
  }

  useEffect(() => {
    if (patientHealthData) {
      const {
        gender,
        dob,
        bloodtype,
        countrycode,
        mobileno,
        height,
        heightunit,
        weight,
        weightunit,
        bmi,
      } = patientHealthData.patientinfo;

      const formattedGender = gender
        ? gender.charAt(0).toUpperCase() + gender.slice(1)
        : null;
      const dobInYears = dob ? calculateAge(dob) : null;
      const formattedBloodType = bloodtype || null;
      const formattedCountryCode = countrycode || null;
      const formattedMobileNo = mobileno || null;
      const formattedHeight =
        height && heightunit ? `${height} ${heightunit}` : null;
      const formattedWeight =
        weight && weightunit ? `${weight} ${weightunit}` : null;
      const formattedBMI = bmi || null;

      const overviewData = [
        formattedGender,
        dobInYears ? `${dobInYears} years` : null,
        formattedBloodType,
        formattedCountryCode && formattedMobileNo
          ? `(${formattedCountryCode}) ${formattedMobileNo}`
          : null,
        formattedHeight,
        formattedWeight,
        formattedBMI ? `${formattedBMI} BMI` : null,
      ]
        .filter((item) => item !== null)
        .join(", ");

      setNavHeaderOverviewData(overviewData);
    }
  }, [patientHealthData]);

  // Function to handle changes in input data
  const handleInputChange = (propertyName, value) => {
    setInputData((prevInputData) => ({
      ...prevInputData,
      [propertyName]: value,
    }));
  };

  //---------------------------------Patient Entire Health Data Section - FOR PATIENT SELECTED PAST VISIT-----------------------------------

  // Define state to store formatted input data - FOR PATIENT SELECTED PAST VISIT
  const [patientSelectedVisitHealthData, setPatientSelectedVisitHealthData] =
    useState(null);

  const fetchPatientSelectedVisitData = async (inputData, visitDate) => {
    showLoader();
    try {
      // console.log(inputData);
      const data = await GetPatientSelectedVisitData(inputData, visitDate);
      setPatientSelectedVisitHealthData(data);
      // console.log(data.socialhistory);
    } catch (error) {
      console.error(
        "Error fetching patient health data for selected past visit:",
        error
      );
    } finally {
      dismissLoader();
    }
  };

  //---------------------------------Timer Section-----------------------------------
  // const navRef = useRef();

  // const handleClick = (action) => {
  //   if (navRef.current) {
  //     navRef.current[action]();
  //   } else {
  //     console.error(`navRef is null. Cannot perform action: ${action}`);
  //   }
  // };

  // // Start the timer when the component mounts
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (navRef.current) {
  //       handleClick("start");
  //     }
  //   }, 2500); // Adjust the delay as needed

  //   // Cleanup function to clear the timeout if the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  const navRef = useRef();

  const handleClick = (action) => {
    if (navRef.current) {
      navRef.current[action]();
    } else {
      console.error(`navRef is null. Cannot perform action: ${action}`);
    }
  };

  // Start the timer when the component mounts
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("attempt made");
      if (navRef.current) {
        handleClick("start");
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  //----------------------Patient Panel Messaging
  const patientMessenger = useRef();

  const openPatientMessage = () => {
    if (patientMessenger.current) {
      patientMessenger.current.openRight();
    }
  };

  //----------------------Refresh doctor notes from patient medication - on add to orders
  const medicationComponentRef = useRef();

  const handleRefreshDoctorNotes = () => {
    if (medicationComponentRef.current) {
      medicationComponentRef.current.updateOrderFromPatientMedication();
    }
  };

  //---------------------------------Add Medical Condition and Surgeries if they don't exist-------

  const NoMedicalConditionAdd = useRef();
  const NoSurgeryAdd = useRef();
  const NoAllergeAdd = useRef();
  const NoSocialHistoryAdd = useRef();
  const NoVaccineAdd = useRef();

  const handleAddMedicalConditionModal = () => {
    if (NoMedicalConditionAdd.current) {
      console.log("calling");
      NoMedicalConditionAdd.current.openFromStartVisitOverview();
    } else {
      console.log("Not available");
    }
  };

  const handleAddSurgeryConditionModal = () => {
    if (NoSurgeryAdd.current) {
      NoSurgeryAdd.current.openFromStartVisitOverview();
    }
  };

  const handleAddAllergyConditionModal = () => {
    if (NoAllergeAdd.current) {
      NoAllergeAdd.current.openFromStartVisitOverview();
    }
  };

  const handleAddVaccinationConditionModal = () => {
    if (NoVaccineAdd.current) {
      NoVaccineAdd.current.openFromStartVisitOverview();
    }
  };

  const handleAddSocialHistoryConditionModal = () => {
    if (NoSocialHistoryAdd.current) {
      NoSocialHistoryAdd.current.openFromStartVisitOverview();
    }
  };

  //---------------------------------Doctor Notes Search Section-----------------------------------
  const doctorNotesSearchOverlay = createRef();

  const openSearchPanel = () => {
    if (doctorNotesSearchOverlay.current) {
      doctorNotesSearchOverlay.current.openSearchOverlay();
    }
  };

  const [triggerAssessmentPlans, setTriggerAssessmentPlans] = useState(false);
  const [triggerRecommendMedicines, setTriggerRecommendMedicines] =
    useState(false);

  //---------------------------AIRA assist
  const openAssistPanel = () => {
    if (selectedDoctorTabIndex === 5) {
      setTriggerAssessmentPlans(true);
    }
    if (selectedDoctorTabIndex === 6) {
      setTriggerRecommendMedicines(true);
    }
  };

  // Reset triggers after they are handled
  useEffect(() => {
    if (triggerAssessmentPlans) {
      setTriggerAssessmentPlans(false);
    }
  }, [triggerAssessmentPlans]);

  useEffect(() => {
    if (triggerRecommendMedicines) {
      setTriggerRecommendMedicines(false);
    }
  }, [triggerRecommendMedicines]);

  //-------------------------AIRA Notes Transcription
  const airaTranscribeNotes = useRef(null);

  const handleAIRANotesClick = () => {
    if (!useGPUChatbot) {
      // Case 1: If useGPUChatbot is false
      airaTranscribeNotes.current.openModal();
    } else {
      // Case 2: If useGPUChatbot is true
      const capableGPU = localStorage.getItem("capableGPU");
      console.log("capableGPU is set to", capableGPU);
      if (capableGPU === null) {
        // If capableGPU does not exist
        audioCompatibilityDemo.current.openModal();
      } else {
        // If capableGPU exists (either "true" or "false")
        airaTranscribeNotes.current.openModal();
      }
    }
  };

  //-------------------------------Sidebar Visit Interrupt
  const handleSidebarClick = (url) => {
    // console.log("Navigating to:", url);
    if (navRef.current) {
      navRef.current.openInterruptModalWithUrl(url);
    }
  };

  //---------------------------------Render Section-----------------------------------

  // useEffect(() => {
  //   if (!patientHealthData) {
  //     console.log("showing loader");
  //     showSecondLoader();
  //   } else {
  //     console.log("removing loader");
  //     dismissSecondLoader();
  //   }

  //   // Cleanup function to dismiss loader if component is unmounted
  //   return () => dismissSecondLoader();
  // }, [patientHealthData]);

  if (!patientHealthData) {
    return <Loader miniLoader={false} />;
  }
  // if (!patientSelectedVisitHealthData) {
  //   return null;
  // }

  //---------------------------------Render Section-----------------------------------


  // re assign Functionality start   ||--
  

    
    const handleReAssign = async () => {
      SetShowAssignModal(false);
      setTimeout(() => SetShowAssignModal(true), 10);
    }
  // re assign Functionality End   --||
  return (
    <>
      {isSecondLoading && <Loader miniLoader={false} />}

      {screenType == "web" ? (
        <Box w="100%" h="100vh">
          {/* For floating chat bot  */}
          {/* <Chatbot
            closeSocketCommand={shouldCloseSocket}
            resetCloseSocketCommand={resetCloseSocketCommand}
            patientId={patientId}
            doctorId={doctorId}
            patientname={
              patientHealthData?.patientinfo?.firstname +
              " " +
              patientHealthData?.patientinfo?.lastname
            }
            patientgender={patientHealthData?.patientinfo?.gender}
            patientage={calculateAge(patientHealthData?.patientinfo?.dob)}
          /> */}
          {/* <Button onClick={closeChatBotOnEndVisit}>End Visit</Button> */}
          <Flex>
            <Box w="6rem">
              <MiniSideBar
                clickAction={true}
                invokeOnClick={handleSidebarClick}
              />
            </Box>
            <Box w="100%">
              <PatientVisitNav
                ref={navRef}
                patientName={
                  patientHealthData?.patientinfo?.firstname +
                  " " +
                  patientHealthData?.patientinfo?.lastname
                }
                // patientImage={
                //   patientHealthData?.patientinfo?.imagepath +
                //   patientHealthData?.patientinfo?.patientimage
                // }
                patientImage={
                  patientHealthData?.patientinfo?.patientimage
                    ? patientHealthData?.patientinfo?.patientimage.startsWith(
                        "http"
                      )
                      ? patientHealthData?.patientinfo?.patientimage
                      : patientHealthData?.patientinfo?.imagepath +
                        patientHealthData?.patientinfo?.patientimage
                    : "/assets/imgs/no-image.png"
                }
                hasImage={patientHealthData?.patientinfo?.patientimage}
                patientInfoId={
                  patientHealthData.patientinfo.uniqueid
                    ? "#" + patientHealthData.patientinfo.uniqueid
                    : ""
                }
                subHeader={navHeaderOverviewData}
                doctorId={doctorId}
                pateintId={patientId}
                appointmentId={appointmentId}
                time={timeToResume}
                showSuccessToast={showSuccessToast}
                showErrorToast={showErrorToast}
                showLoader={showLoader}
                dismissLoader={dismissLoader}
                virtualVisit={virtualVisit}
                IsAssign={IsAssign}
              />
              {/* <div style={{ marginTop: "1rem" }}>
                <button onClick={() => handleClick("start")}>Start</button>
                <button onClick={() => handleClick("pause")}>Pause</button>
                <button onClick={() => handleClick("resume")}>Resume</button>
                <button onClick={() => handleClick("end")}>End</button>
              </div> */}
              <Flex w="calc(100vw - 6rem)" h="calc(100vh - 5rem)">
                {/* Left Panel */}
                <Box width="50%" p={0} position="relative">
                  {/* Patient Panel */}
                  <Flex direction="column" h="100%">
                    {/* Box 1 */}
                    <Box bg="white" p="4" py="9px" border="1px solid #e2e8f0">
                      <Flex justifyContent="space-between" alignItems="center">
                        {viewPastVisitTabs ? (
                          <>
                            {/* Left side with left arrow and heading */}
                            <Flex alignItems="center">
                              <Image
                                src="/assets/svgs/left-tab-icon.svg"
                                onClick={() => goToPatientCurrentVisit(false)}
                                cursor="pointer"
                              />
                              <Text
                                fontSize="0.75rem"
                                letterSpacing="0.1rem"
                                fontWeight="600"
                                textTransform="uppercase"
                                color="#061027"
                                ml="2" // Adjust margin as needed for spacing
                              >
                                {pastVisitTabsHeader}
                              </Text>
                            </Flex>
                            {/* Right side with search icon */}
                            <Image src="/assets/svgs/search.svg" />
                          </>
                        ) : (
                          <>
                            <Text
                              fontSize="0.75rem"
                              letterSpacing="0.1rem"
                              fontWeight="600"
                              textTransform="uppercase"
                              color="#061027"
                            >
                              Patient Overview
                            </Text>
                            <Spacer />

                                          <PrimaryButton
                              buttonText="Assign"
                              onClick={() => handleReAssign()}  
                              isDisabled={actionAccessAssigning && actionAccessAssigning.create ? false : true}
                              variant="mdBtn"
                              btnStyle={{ mr:2, h: 35, w: 70 }}
                            />

                            <PrimaryButton
                              buttonText="Chat"
                              onClick={openPatientMessage}
                              variant="mdBtn"
                              btnStyle={{ m: 0, h: 35, w: 70 }}
                            />
                            {/* <div>
                              <Box
                                as="button"
                                borderRadius="md"
                                onClick={openPatientMessage}
                                zIndex="5"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap="5px"
                                w="75px"
                                h="20px"
                                mr={"4px"}
                                ml={"4px"}
                                left={{ lg: "80%", xl: "80%" }}
                                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                              >
                                <Image
                                  w="14px"
                                  src="/assets/imgs/white-spark.png"
                                />
                                <Box display="flex" flexDir="column">
                                  <Text
                                    textAlign="left"
                                    fontSize="10px"
                                    fontWeight="700"
                                    color="white"
                                  >
                                    Chat
                                  </Text>
                                </Box>
                              </Box>
                            </div> */}
                            <Box as="button" ml="4">
                              <Image src="/assets/svgs/search.svg" />
                            </Box>
                          </>
                        )}
                      </Flex>
                    </Box>
                    <Box
                      overflow="hidden"
                      position="relative"
                      border="1px solid #e2e8f0"
                    >
                      <Box
                        as="button"
                        className={`${styles.tabArrow} ${styles.tabArrowLeft}`}
                        onClick={scrollLeft}
                      >
                        <Image src="/assets/svgs/left-tab-icon.svg" />
                      </Box>
                      <Box
                        as="button"
                        className={`${styles.tabArrow} ${styles.tabArrowRight}`}
                        onClick={scrollRight}
                      >
                        <Image src="/assets/svgs/right-tab-icon.svg" />
                      </Box>
                      <Box
                        ref={tabListRef}
                        ml="16px" // set according to the text of tab
                        mr="20px" // set according to the text of tab
                        className={styles.scrollContainer}
                      >
                        <Flex>
                          {viewPastVisitTabs
                            ? patientPastVisitTabs.map((tab, index) => (
                                <Box
                                  key={index}
                                  className={styles.tabButton}
                                  onClick={() => handlePatientTabClick(index)}
                                  borderBottom={
                                    selectedPatientTabIndex === index
                                      ? "2px solid black"
                                      : "none"
                                  }
                                  color={
                                    selectedPatientTabIndex === index
                                      ? "black"
                                      : "gray"
                                  }
                                >
                                  {tab}
                                </Box>
                              ))
                            : patientOverviewTabs.map((tab, index) => (
                                <Box
                                  key={index}
                                  className={styles.tabButton}
                                  onClick={() => handlePatientTabClick(index)}
                                  borderBottom={
                                    selectedPatientTabIndex === index
                                      ? "2px solid black"
                                      : "none"
                                  }
                                  color={
                                    selectedPatientTabIndex === index
                                      ? "black"
                                      : "gray"
                                  }
                                >
                                  {tab}
                                </Box>
                              ))}
                        </Flex>
                      </Box>
                    </Box>
                    <Box bg="#fcfcfd" flex="1" overflow="hidden">
                      {/* Content for the third box */}

                      {/* {isLoading && <Loader miniLoader={true} />} */}
                      {isLoading && (
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          zIndex="10"
                          bg="rgba(255, 255, 255, 0.8)"
                        >
                          <Loader miniLoader={true} />
                        </Box>
                      )}
                      <Box
                        overflowY="scroll"
                        height="100%"
                        pr="17px"
                        mr="-17px"
                        pl="17px"
                      >
                        {/* PATIENT CURRENT VISIT OVERVIEW TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 0 && (
                            <Flex>
                              <StartVisitOverview
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                patienData={patientHealthData}
                                displayOverview={true}
                                onAddMedicalConditionClick={
                                  handleAddMedicalConditionModal
                                }
                                onAddSurgeryClick={
                                  handleAddSurgeryConditionModal
                                }
                                onAddAllergyClick={
                                  handleAddAllergyConditionModal
                                }
                                onAddVaccineClick={
                                  handleAddVaccinationConditionModal
                                }
                                onAddSocialHistoryClick={
                                  handleAddSocialHistoryConditionModal
                                }
                                onHeaderClickChangeToTab={handlePatientTabClick}
                              />
                            </Flex>
                          )}

                        {/* PATIENT CURRENT VISIT PREVIOUS VISITS TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 1 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.Previousvisits.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientHealthData.Previousvisits.map(
                                  (visit, index) => (
                                    <PatientVisit
                                      key={index}
                                      doctorid={doctorId}
                                      patientid={patientId}
                                      appointmentid={visit.appointmentid}
                                      chiefcomplaint={visit.chiefcomplaint}
                                      appointmentdate={visit.appointmentdate}
                                      speciality={visit.specality}
                                      onPastVisitClick={handlePastVisitClick}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT VITALS TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 2 && (
                            <Flex wrap="wrap" justify="space-between" p={5}>
                              {patientHealthData.vitals.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                <VitalDisplayCard
                                  vitals={patientHealthData.vitals}
                                  patientId={patientId}
                                  doctorId={doctorId}
                                  appointmentId={appointmentId}
                                  showLoader={showLoader}
                                  dismissLoader={dismissLoader}
                                />
                              )}
                            </Flex>
                          )}

                        {/* PATIENT CURRENT VISIT MEDICATIONS TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 3 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.medications.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientHealthData.medications.map(
                                  (data, index) => (
                                    <Medications
                                      key={index}
                                      patientname={
                                        patientHealthData?.patientinfo
                                          ?.firstname +
                                        " " +
                                        patientHealthData?.patientinfo?.lastname
                                      }
                                      patientgender={
                                        patientHealthData?.patientinfo?.gender
                                      }
                                      patientage={calculateAge(
                                        patientHealthData?.patientinfo?.dob
                                      )}
                                      patientId={patientId}
                                      doctorId={doctorId}
                                      appointmentId={appointmentId}
                                      date={data.date}
                                      medicines={data.medicines}
                                      attachments={data.attachments}
                                      id={data.id}
                                      onEdit={() =>
                                        console.log("Edit", data.id)
                                      }
                                      onDelete={() =>
                                        console.log("Delete", data.id)
                                      }
                                      fetchPatientHealthData={
                                        fetchPatientHealthData
                                      }
                                      showLoader={showLoader}
                                      dismissLoader={dismissLoader}
                                      showSuccessToast={showSuccessToast}
                                      showErrorToast={showErrorToast}
                                      refreshDoctorNotes={
                                        handleRefreshDoctorNotes
                                      }
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}
                        {/* Some parts of code need the No Data Screen Component to be rendered always that's why its hidden till the condition is satisfied */}
                        <Box
                          style={{
                            display:
                              selectedPatientTabIndex === 4 &&
                              patientHealthData.medicalhistory.length === 0
                                ? "block"
                                : "none",
                          }}
                          padding="30px"
                          w="100%"
                        >
                          <NoMedicalConditionDataScreen
                            ref={NoMedicalConditionAdd}
                            width="100%"
                            height="100%"
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            patientId={patientId}
                            fetchPatientHealthData={fetchPatientHealthData}
                          />
                        </Box>

                        {/* PATIENT CURRENT VISIT MEDICAL HISTORY TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 4 && (
                            <Box padding="30px" w="100%">
                              <Box>
                                {patientHealthData.medicalhistory.length ===
                                0 ? (
                                  // <NoMedicalConditionDataScreen
                                  //   width="100%"
                                  //   height="100%"
                                  //   showLoader={showLoader}
                                  //   dismissLoader={dismissLoader}
                                  //   showSuccessToast={showSuccessToast}
                                  //   showErrorToast={showErrorToast}
                                  //   patientId={patientId}
                                  //   fetchPatientHealthData={
                                  //     fetchPatientHealthData
                                  //   }
                                  //   ref={NoMedicalConditionAdd}
                                  //   style={{
                                  //     display: "none",
                                  //   }}
                                  // />
                                  <></>
                                ) : (
                                  patientHealthData.medicalhistory.map(
                                    (condition, index) => (
                                      <MedicalHistory
                                        key={index}
                                        id={condition.id}
                                        patientId={patientId}
                                        patientname={
                                          patientHealthData?.patientinfo
                                            ?.firstname +
                                          " " +
                                          patientHealthData?.patientinfo
                                            ?.lastname
                                        }
                                        patientgender={
                                          patientHealthData?.patientinfo?.gender
                                        }
                                        patientage={calculateAge(
                                          patientHealthData?.patientinfo?.dob
                                        )}
                                        doctorId={doctorId}
                                        appointmentId={appointmentId}
                                        condition={condition.condition}
                                        condition_cd={condition.condition_cd}
                                        conditiontype_cd={
                                          condition.conditiontype_cd
                                        }
                                        date={condition.conditionidentifieddate}
                                        fetchPatientHealthData={
                                          fetchPatientHealthData
                                        }
                                        showLoader={showLoader}
                                        dismissLoader={dismissLoader}
                                        showSuccessToast={showSuccessToast}
                                        showErrorToast={showErrorToast}
                                      />
                                    )
                                  )
                                )}
                              </Box>
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT SURGICAL HISTORY TAB  */}
                        <Box
                          style={{
                            display:
                              selectedPatientTabIndex === 5 &&
                              patientHealthData.surgicalhistory.length === 0
                                ? "block"
                                : "none",
                          }}
                          padding="30px"
                          w="100%"
                          h="100%"
                        >
                          <NoSurgicalHistoryDataScreen
                            width="100%"
                            height="100%"
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            patientId={patientId}
                            fetchPatientHealthData={fetchPatientHealthData}
                            ref={NoSurgeryAdd}
                          />
                        </Box>
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 5 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.surgicalhistory.length ===
                              0 ? (
                                // <NoSurgicalHistoryDataScreen
                                //   width="100%"
                                //   height="100%"
                                //   showLoader={showLoader}
                                //   dismissLoader={dismissLoader}
                                //   showSuccessToast={showSuccessToast}
                                //   showErrorToast={showErrorToast}
                                //   patientId={patientId}
                                //   fetchPatientHealthData={
                                //     fetchPatientHealthData
                                //   }
                                //   ref={NoSurgeryAdd}
                                // />
                                <></>
                              ) : (
                                patientHealthData.surgicalhistory.map(
                                  (surgery, index) => (
                                    <SurgicalHistory
                                      key={index}
                                      date={surgery.date}
                                      patientId={patientId}
                                      patientname={
                                        patientHealthData?.patientinfo
                                          ?.firstname +
                                        " " +
                                        patientHealthData?.patientinfo?.lastname
                                      }
                                      patientgender={
                                        patientHealthData?.patientinfo?.gender
                                      }
                                      patientage={calculateAge(
                                        patientHealthData?.patientinfo?.dob
                                      )}
                                      doctorId={doctorId}
                                      appointmentId={appointmentId}
                                      surgeryName={surgery.surgeryName}
                                      doctorName={surgery.doctorName}
                                      hospital={surgery.hospital}
                                      attachments={surgery.attachments}
                                      surgery_id={surgery.id}
                                      fetchPatientHealthData={
                                        fetchPatientHealthData
                                      }
                                      showLoader={showLoader}
                                      dismissLoader={dismissLoader}
                                      showSuccessToast={showSuccessToast}
                                      showErrorToast={showErrorToast}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT FAMILY HISTORY TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 6 && (
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              p="20px"
                            >
                              {patientHealthData.familyhistory.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientHealthData.familyhistory
                                  .reduce((rows, member, index) => {
                                    if (index % 2 === 0) {
                                      rows.push([member]);
                                    } else {
                                      rows[rows.length - 1].push(member);
                                    }
                                    return rows;
                                  }, [])
                                  .map((row, index) => (
                                    <FamilyHistory
                                      key={index}
                                      familydata={row}
                                      patientId={patientId}
                                      doctorId={doctorId}
                                      appointmentId={appointmentId}
                                      fetchPatientHealthData={
                                        fetchPatientHealthData
                                      }
                                    />
                                  ))
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT SOCIAL HISTORY TAB  */}
                        <Box
                          style={{
                            display:
                              selectedPatientTabIndex === 7 &&
                              patientHealthData.socialhistory.length === 0
                                ? "block"
                                : "none",
                          }}
                          padding="30px"
                          w="100%"
                          h="100%"
                        >
                          <NoSocialHistoryDataScreen
                            ref={NoSocialHistoryAdd}
                            width="100%"
                            height="100%"
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            patientId={patientId}
                            fetchPatientHealthData={fetchPatientHealthData}
                          />
                        </Box>
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 7 && (
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              p="20px"
                            >
                              {patientHealthData.socialhistory.length === 0 ? (
                                // <NoDataScreen width="100%" height="100%" />
                                <></>
                              ) : (
                                patientHealthData.socialhistory
                                  .reduce((rows, drug, index) => {
                                    if (index % 2 === 0) {
                                      rows.push([drug]);
                                    } else {
                                      rows[rows.length - 1].push(drug);
                                    }
                                    return rows;
                                  }, [])
                                  .map((drug, index) => (
                                    <SocialHistory
                                      key={index}
                                      drugData={drug}
                                      patientId={patientId}
                                      doctorId={doctorId}
                                      appointmentId={appointmentId}
                                      fetchPatientHealthData={
                                        fetchPatientHealthData
                                      }
                                    />
                                  ))
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT PHYSICAL EXAM TAB  */}
                        {/* {!viewPastVisitTabs && selectedPatientTabIndex === 8 && (
                          <Box padding="30px" w="100%">
                            {patientHealthData.physicalexam.length === 0 ? (
                              <NoDataScreen width="100%" height="100%" />
                            ) : (
                              patientHealthData.physicalexam.map((exam, index) => (
                                <PhysicalExam
                                  key={index}
                                  type={exam.type}
                                  condition={exam.condition}
                                  date={exam.date}
                                  id={exam.id}
                                  // onEdit={}
                                  // onDelete={}
                                />
                              ))
                            )}
                          </Box>
                        )} */}

                        {/* PATIENT CURRENT VISIT TREATMENT PLAN TAB  */}
                        {/* {!viewPastVisitTabs && selectedPatientTabIndex === 9 && (
                          <Box padding="30px" w="100%">
                            {patientHealthData.doctorsassessment.length === 0 ? (
                              <NoDataScreen width="100%" height="100%" />
                            ) : (
                              patientHealthData.doctorsassessment.map(
                                (treatment, index) => (
                                  <TreatmentPlan
                                    key={index}
                                    date={treatment?.date}
                                    diagnosis={treatment?.diagnosis}
                                    plan={treatment?.plan}
                                    id={treatment?.id}
                                    // onEdit={}
                                    // onDelete={}
                                  />
                                )
                              )
                            )}
                          </Box>
                        )} */}

                        {/* PATIENT CURRENT VISIT ROS TAB  */}
                        {/* {!viewPastVisitTabs && selectedPatientTabIndex === 10 && (
                          <Box padding="30px" w="100%">
                            {patientHealthData.reviewofsystem.length === 0 ? (
                              <NoDataScreen width="100%" height="100%" />
                            ) : (
                              patientHealthData.reviewofsystem.map((ros, index) => (
                                <PhysicalExam
                                  key={index}
                                  type={ros.type}
                                  condition={ros.condition}
                                  date={ros.date}
                                  id={ros.id}
                                  // onEdit={}
                                  // onDelete={}
                                />
                              ))
                            )}
                          </Box>
                        )} */}

                        {/* PATIENT CURRENT VISIT ALLERGIES TAB  */}
                        <Box
                          style={{
                            display:
                              selectedPatientTabIndex === 8 &&
                              patientHealthData.allergy.length === 0
                                ? "block"
                                : "none",
                          }}
                          padding="30px"
                          w="100%"
                        >
                          <NoAllergyDataScreen
                            ref={NoAllergeAdd}
                            width="100%"
                            height="100%"
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            patientId={patientId}
                            fetchPatientHealthData={fetchPatientHealthData}
                          />
                        </Box>
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 8 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.allergy.length === 0 ? (
                                // <NoDataScreen width="100%" height="100%" />
                                <></>
                              ) : (
                                patientHealthData.allergy.map(
                                  (allergy, index) => (
                                    <Allergies
                                      key={index}
                                      patientId={patientId}
                                      patientname={
                                        patientHealthData?.patientinfo
                                          ?.firstname +
                                        " " +
                                        patientHealthData?.patientinfo?.lastname
                                      }
                                      patientgender={
                                        patientHealthData?.patientinfo?.gender
                                      }
                                      patientage={calculateAge(
                                        patientHealthData?.patientinfo?.dob
                                      )}
                                      doctorId={doctorId}
                                      appointmentId={appointmentId}
                                      allergyFrom={allergy.allergyFrom}
                                      onSet={allergy.onSet}
                                      problems={allergy.problems}
                                      attachments={allergy.attachments}
                                      reaction={allergy.reaction}
                                      allergyidentifieddate={
                                        allergy.allergyidentifieddate
                                      }
                                      id={allergy.id}
                                      fetchPatientHealthData={
                                        fetchPatientHealthData
                                      }
                                      showLoader={showLoader}
                                      dismissLoader={dismissLoader}
                                      showSuccessToast={showSuccessToast}
                                      showErrorToast={showErrorToast}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT VACCINATIONS TAB  */}
                        <Box
                          style={{
                            display:
                              selectedPatientTabIndex === 9 &&
                              patientHealthData.vaccinations.length === 0
                                ? "block"
                                : "none",
                          }}
                          padding="30px"
                          w="100%"
                        >
                          <NoVaccinationDataScreen
                            ref={NoVaccineAdd}
                            width="100%"
                            height="100%"
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            patientId={patientId}
                            fetchPatientHealthData={fetchPatientHealthData}
                          />
                        </Box>
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 9 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.vaccinations.length === 0 ? (
                                // <NoDataScreen width="100%" height="100%" />
                                <></>
                              ) : (
                                patientHealthData.vaccinations.map(
                                  (vaccine, index) => (
                                    <Vaccination
                                      key={index}
                                      id={vaccine.id}
                                      patientId={patientId}
                                      patientname={
                                        patientHealthData?.patientinfo
                                          ?.firstname +
                                        " " +
                                        patientHealthData?.patientinfo?.lastname
                                      }
                                      patientgender={
                                        patientHealthData?.patientinfo?.gender
                                      }
                                      patientage={calculateAge(
                                        patientHealthData?.patientinfo?.dob
                                      )}
                                      doctorId={doctorId}
                                      appointmentId={appointmentId}
                                      vaccineName={vaccine.name}
                                      applyDate={vaccine.adminDate}
                                      dueDate={vaccine.dueDate}
                                      attachments={vaccine.attachments}
                                      fetchPatientHealthData={
                                        fetchPatientHealthData
                                      }
                                      showLoader={showLoader}
                                      dismissLoader={dismissLoader}
                                      showSuccessToast={showSuccessToast}
                                      showErrorToast={showErrorToast}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT INVESTIGATIONS TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 10 && (
                            <Flex wrap="wrap" justify="space-between" p={5}>
                              {patientHealthData.overviewinvestigations
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientHealthData.overviewinvestigations.map(
                                  (test, index) => (
                                    <Box
                                      key={index}
                                      flex="1 1 48%"
                                      maxW="48%"
                                      mb={4}
                                    >
                                      <InvestigationsDisplayCard
                                        vitalName={test.eventtitle}
                                        vitalValue={test.actualresultvalue}
                                        vitalUnit={test.resultuom}
                                        vitalDate={test.verifieddate}
                                        status={test.status}
                                        trendColor={test.trendColor}
                                        // vitalStatus={test.vitalStatus}
                                      />
                                    </Box>
                                  )
                                )
                              )}
                            </Flex>
                          )}

                        {/* PATIENT CURRENT VISIT LAB TESTS TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 11 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.labs.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                <LabTests
                                  tests={patientHealthData.labs}
                                  doctorId={doctorId}
                                  pateintId={patientId}
                                  appointmentId={appointmentId}
                                  showSuccessToast={showSuccessToast}
                                  showErrorToast={showErrorToast}
                                  showLoader={showLoader}
                                  dismissLoader={dismissLoader}
                                />
                              )}
                            </Box>
                          )}

                        {/* PATIENT CURRENT VISIT IMAGING TESTS TAB  */}
                        {!viewPastVisitTabs &&
                          selectedPatientTabIndex === 12 && (
                            <Box padding="30px" w="100%">
                              {patientHealthData.imaging.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientHealthData.imaging.map((test, index) => (
                                  <ImagingTests
                                    key={index}
                                    date={test.date}
                                    name={test.name}
                                    documenturl={test?.documenturl}
                                    summary={test.summary}
                                    attachments={test.attachments}
                                    id={test.id}
                                    interpretation={test.interpretation}
                                    normal={test.normal}
                                    abnormal={test.abnormal}
                                    // onEdit={}
                                    // onDelete={}
                                  />
                                ))
                              )}
                            </Box>
                          )}

                        {/* ------------------------------PAST VISIT TABS CONTENT---------------------------------------- */}

                        {/* PAST VISITS OVERVIEW TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 0 && (
                            <Flex>
                              <PastVisitOverview
                                patienData={patientSelectedVisitHealthData}
                              />
                            </Flex>
                          )}

                        {/* PAST VISITS VITALS TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 1 && (
                            <Flex wrap="wrap" justify="space-between" p={5}>
                              {patientSelectedVisitHealthData.vitals.length ===
                              0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.vitals.map(
                                  (vital, index) => (
                                    <Box
                                      key={index}
                                      flex="1 1 48%"
                                      maxW="48%"
                                      mb={4}
                                    >
                                      <PastVitalDisplayCard
                                        vitalName={vital.vitalName}
                                        vitalValue={vital.vitalValue}
                                        vitalUnit={vital.vitalUnit}
                                        vitalStatus={vital.vitalStatus}
                                      />
                                    </Box>
                                  )
                                )
                              )}
                            </Flex>
                          )}

                        {/* PAST VISITS MEDICATIONS TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 2 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.medications
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.medications.map(
                                  (data, index) => (
                                    <PastMedications
                                      key={index}
                                      date={data.date}
                                      medicines={data.medicines}
                                      attachments={data.attachments}
                                      id={data.id}
                                      onEdit={() =>
                                        console.log("Edit", data.id)
                                      }
                                      onDelete={() =>
                                        console.log("Delete", data.id)
                                      }
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS MEDICAL HISTORY TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 3 && (
                            <Box padding="30px" w="100%">
                              <Box>
                                {patientSelectedVisitHealthData.medicalhistory
                                  .length === 0 ? (
                                  <NoDataScreen width="100%" height="100%" />
                                ) : (
                                  patientSelectedVisitHealthData.medicalhistory.map(
                                    (condition, index) => (
                                      <PastMedicalHistory
                                        key={index}
                                        id={condition.id}
                                        condition={condition.condition}
                                        date={condition.conditionidentifieddate}
                                      />
                                    )
                                  )
                                )}
                              </Box>
                            </Box>
                          )}

                        {/* PAST VISITS SURGICAL HISTORY TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 4 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.surgicalhistory
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.surgicalhistory.map(
                                  (surgery, index) => (
                                    <PastSurgicalHistory
                                      key={index}
                                      date={surgery.date}
                                      surgeryName={surgery.surgeryName}
                                      doctorName={surgery.doctorName}
                                      hospital={surgery.hospital}
                                      attachments={surgery.attachments}
                                      id={surgery.id}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS FAMILY HISTORY TAB  */}
                        {viewPastVisitTabs &&
                        patientSelectedVisitHealthData &&
                        patientSelectedVisitHealthData.familyhistory &&
                        patientSelectedVisitHealthData.familyhistory?.length > 0
                          ? selectedPatientTabIndex === 5 && (
                              <Box padding="30px" w="100%">
                                <PastFamilyTreeTable
                                  familyHistory={
                                    patientSelectedVisitHealthData.familyhistory
                                  }
                                />
                              </Box>
                            )
                          : selectedPatientTabIndex === 5 &&
                            viewPastVisitTabs &&
                            patientSelectedVisitHealthData && (
                              <NoDataScreen width="100%" height="100%" />
                            )}

                        {/* PAST VISITS SOCIAL HISTORY TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 6 && (
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              justifyContent="space-between"
                              p="20px"
                            >
                              {patientSelectedVisitHealthData.socialhistory
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.socialhistory
                                  .reduce((rows, drug, index) => {
                                    if (index % 2 === 0) {
                                      rows.push([drug]);
                                    } else {
                                      rows[rows.length - 1].push(drug);
                                    }
                                    return rows;
                                  }, [])
                                  .map((drug, index) => (
                                    <PastSocialHistory
                                      key={index}
                                      drugData={drug}
                                    />
                                  ))
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS PHYSICAL EXAM TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 7 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.physicalexam
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.physicalexam.map(
                                  (exam, index) => (
                                    <PastPhysicalExam
                                      key={index}
                                      type={exam.type}
                                      condition={exam.condition}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS TREATMENT PLAN TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 8 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.doctorsassessment
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.doctorsassessment.map(
                                  (treatment, index) => (
                                    <PastTreatmentPlan
                                      key={index}
                                      diagnosis={treatment.diagnosis}
                                      plan={treatment.plan}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS ROS TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 9 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.reviewofsystem
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.reviewofsystem.map(
                                  (ros, index) => (
                                    <PastPhysicalExam
                                      key={index}
                                      type={ros.type}
                                      condition={ros.condition}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS ALLERGIES TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 10 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.allergy.length ===
                              0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.allergy.map(
                                  (allergy, index) => (
                                    <PastAllergies
                                      key={index}
                                      allergyFrom={allergy.allergyFrom}
                                      onSet={allergy.onSet}
                                      problems={allergy.problems}
                                      attachments={allergy.attachments}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISITS VACCINATIONS TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 11 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.vaccinations
                                .length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.vaccinations.map(
                                  (vaccine, index) => (
                                    <PastVaccination
                                      key={index}
                                      vaccineName={vaccine.name}
                                      dueDate={vaccine.dueDate}
                                      applyDate={vaccine.adminDate}
                                      attachments={vaccine.attachments}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISIT INVESTIGATION TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 12 && (
                            <Flex wrap="wrap" justify="space-between" p={5}>
                              {patientSelectedVisitHealthData
                                .overviewinvestigations.length === 0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.overviewinvestigations.map(
                                  (test, index) => (
                                    <Box
                                      key={index}
                                      flex="1 1 48%"
                                      maxW="48%"
                                      mb={4}
                                    >
                                      <InvestigationsDisplayCard
                                        vitalName={test.eventtitle}
                                        vitalValue={test.actualresultvalue}
                                        vitalUnit={test.resultuom}
                                        vitalDate={test.verifieddate}
                                        status={test.status}
                                        trendColor={test.trendColor}
                                        // vitalStatus={test.vitalStatus}
                                      />
                                    </Box>
                                  )
                                )
                              )}
                            </Flex>
                          )}

                        {/* PAST VISIT LABS TEST TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 13 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.labs.length ===
                              0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.labs.map(
                                  (test, index) => (
                                    <PastLabTests
                                      key={index}
                                      date={test.date}
                                      name={test.name}
                                      summary={test.summary}
                                      attachments={test.attachments}
                                      id={test.id}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}

                        {/* PAST VISIT IMAGING TEST TAB  */}
                        {viewPastVisitTabs &&
                          patientSelectedVisitHealthData &&
                          selectedPatientTabIndex === 14 && (
                            <Box padding="30px" w="100%">
                              {patientSelectedVisitHealthData.imaging.length ===
                              0 ? (
                                <NoDataScreen width="100%" height="100%" />
                              ) : (
                                patientSelectedVisitHealthData.imaging.map(
                                  (test, index) => (
                                    <PastImagingTests
                                      key={index}
                                      date={test.date}
                                      name={test.name}
                                      summary={test.summary}
                                      attachments={test.attachments}
                                      id={test.id}
                                      interpretation={test.interpretation}
                                      normal={test.normal}
                                      abnormal={test.abnormal}
                                    />
                                  )
                                )
                              )}
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Flex>
                </Box>

                {/* ------------------------------------------------------------------------------- */}

                {/* Divider */}
                <Divider orientation="vertical" />
                <Divider
                  orientation="vertical"
                  borderWidth="3px"
                  borderColor="white"
                />
                <Flex alignItems="center" justifyContent="center">
                  <Divider
                    orientation="vertical"
                    borderWidth="3px"
                    borderColor="black"
                    borderRadius={4}
                    height="40px"
                  />
                </Flex>
                <Divider
                  orientation="vertical"
                  borderWidth="3px"
                  borderColor="white"
                />
                <Divider orientation="vertical" />

                {/* ------------------------------------------------------------------------- */}

                {/* Right Panel */}
                <Box width="50%" p={0} position="relative">
                  <Flex direction="column" h="100%">
                    <Box bg="white" p="4" border="1px solid #e2e8f0">
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text
                          fontSize="0.75rem"
                          letterSpacing="0.1rem"
                          fontWeight="600"
                          textTransform="uppercase"
                          color="#061027"
                          mr="auto"
                        >
                          Doctor Notes
                        </Text>
                        <Flex alignItems="center">
                          {/* <Badge
                            onClick={openAssistPanel}
                            cursor="pointer"
                            color="blue"
                            bg={"white"}
                            mr={2}
                            // {...(index === formInputs.length - 1
                            //   ? {}
                            //   : { display: "none" })}
                          >
                            AI Assist
                          </Badge> */}
                          <div>
                            {(selectedDoctorTabIndex === 5 ||
                              selectedDoctorTabIndex === 6) && (
                              <Box
                                as="button"
                                borderRadius="md"
                                onClick={openAssistPanel}
                                zIndex="5"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap="5px"
                                w="100px"
                                h="20px"
                                mr="4px"
                                left={{ lg: "80%", xl: "80%" }}
                                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                              >
                                <Image
                                  w="16px"
                                  src="/assets/imgs/white-spark.png"
                                />
                                <Box display="flex" flexDir="column">
                                  <Text
                                    textAlign="left"
                                    fontSize="10px"
                                    fontWeight="700"
                                    color="white"
                                  >
                                    Aira - Assist
                                  </Text>
                                </Box>
                              </Box>
                            )}
                          </div>
                          {useGPUChatbot ? (
                            <GPUChatbot
                              closeSocketCommand={shouldCloseSocket}
                              resetCloseSocketCommand={resetCloseSocketCommand}
                              patientId={patientId}
                              doctorId={doctorId}
                              appointmentId={appointmentId}
                              patientname={
                                patientHealthData?.patientinfo?.firstname +
                                " " +
                                patientHealthData?.patientinfo?.lastname
                              }
                              patientgender={
                                patientHealthData?.patientinfo?.gender
                              }
                              patientage={calculateAge(
                                patientHealthData?.patientinfo?.dob
                              )}
                              height="100%"
                            />
                          ) : (
                            <Chatbot
                              closeSocketCommand={shouldCloseSocket}
                              resetCloseSocketCommand={resetCloseSocketCommand}
                              patientId={patientId}
                              doctorId={doctorId}
                              appointmentId={appointmentId}
                              patientname={
                                patientHealthData?.patientinfo?.firstname +
                                " " +
                                patientHealthData?.patientinfo?.lastname
                              }
                              patientgender={
                                patientHealthData?.patientinfo?.gender
                              }
                              patientage={calculateAge(
                                patientHealthData?.patientinfo?.dob
                              )}
                              height="100%"
                            />
                          )}
                          {virtualVisit ? (
                            <div>
                              <Box
                                as="button"
                                borderRadius="md"
                                onClick={() =>
                                  virtualVisitChatNotes.current.openModal()
                                }
                                zIndex="5"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap="5px"
                                w="100px"
                                h="20px"
                                mr={"4px"}
                                ml={"4px"}
                                left={{ lg: "80%", xl: "80%" }}
                                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                              >
                                <Image
                                  w="16px"
                                  src="/assets/imgs/white-spark.png"
                                />
                                <Box display="flex" flexDir="column">
                                  <Text
                                    textAlign="left"
                                    fontSize="10px"
                                    fontWeight="700"
                                    color="white"
                                  >
                                    Chat History
                                  </Text>
                                </Box>
                              </Box>
                            </div>
                          ) : (
                            <div>
                              <Box
                                as="button"
                                borderRadius="md"
                                onClick={handleAIRANotesClick}
                                zIndex="5"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                gap="5px"
                                w="100px"
                                h="20px"
                                mr={"4px"}
                                ml={"4px"}
                                left={{ lg: "80%", xl: "80%" }}
                                backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                              >
                                <Image
                                  w="16px"
                                  src="/assets/imgs/white-spark.png"
                                />
                                <Box display="flex" flexDir="column">
                                  <Text
                                    textAlign="left"
                                    fontSize="10px"
                                    fontWeight="700"
                                    color="white"
                                  >
                                    Record
                                  </Text>
                                </Box>
                              </Box>
                            </div>
                          )}

                          <Box as="button" onClick={openSearchPanel} ml="4">
                            <Image src="/assets/svgs/search.svg" />
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>

                    <Box
                      overflow="hidden"
                      position="relative"
                      border="1px solid #e2e8f0"
                    >
                      <Box
                        as="button"
                        className={`${styles.tabArrow} ${styles.tabArrowLeft}`}
                        onClick={scrollDoctorTabsLeft}
                      >
                        <Image src="/assets/svgs/left-tab-icon.svg" />
                      </Box>
                      <Box
                        as="button"
                        className={`${styles.tabArrow} ${styles.tabArrowRight}`}
                        onClick={scrollDoctorTabsRight}
                      >
                        <Image src="/assets/svgs/right-tab-icon.svg" />
                      </Box>
                      <Box
                        ref={doctorTabListRef}
                        ml="16px" // set according to the text of tab
                        mr="20px" // set according to the text of tab
                        className={styles.scrollContainer}
                      >
                        <Flex>
                          {accessibleTabNames.map((tab, index) => (
                            // <Box
                            //   key={index}
                            //   className={styles.tabButton}
                            //   onClick={() => handleDoctorTabClick(index)}
                            //   borderBottom={
                            //     selectedDoctorTabIndex === index
                            //       ? "2px solid black"
                            //       : "none"
                            //   }
                            //   color={
                            //     selectedDoctorTabIndex === index
                            //       ? "black"
                            //       : "gray"
                            //   }
                            // >
                            //   {tab}
                            // </Box>
                            <Box
                              key={tab.tabindex}
                              className={styles.tabButton}
                              onClick={() => handleDoctorTabClick(tab.tabindex)}
                              borderBottom={
                                selectedDoctorTabIndex === tab.tabindex
                                  ? "2px solid black"
                                  : "none"
                              }
                              color={
                                selectedDoctorTabIndex === tab.tabindex
                                  ? "black"
                                  : "gray"
                              }
                            >
                              {tab.name}
                              {showEmptyIndicator &&
                              !doctorNotesEmptyMapping[tab.name]
                                ? "*"
                                : null}
                            </Box>
                          ))}
                        </Flex>
                      </Box>
                    </Box>

                    <Box bg="white" flex="1" overflow="hidden">
                      <Box
                        overflowY="scroll"
                        height="100%"
                        pr="17px"
                        bg="white"
                        mr="-17px"
                        // pl="17px"
                      >
                        {/* DOCTOR NOTES CURRENT VISIT CHIEF COMPLAINTS TAB  */}
                        {selectedDoctorTabIndex === 0 && (
                          <Box height="100%">
                            <Box height="100%">
                              {useGPUChatbot ? (
                                <DoctorComplaintInputGPUCard
                                  patientId={patientId}
                                  doctorId={doctorId}
                                  appointmentId={appointmentId}
                                  showSuccessToast={showSuccessToast}
                                  showErrorToast={showErrorToast}
                                  fetchDoctorNotesEmptyIndication={
                                    fetchDoctorNotesEmptyIndication
                                  }
                                  submitOnTabSwitch={submitChiefComplaintTab}
                                />
                              ) : (
                                <DoctorComplaintInputCPUCard
                                  patientId={patientId}
                                  doctorId={doctorId}
                                  appointmentId={appointmentId}
                                  showSuccessToast={showSuccessToast}
                                  showErrorToast={showErrorToast}
                                  fetchDoctorNotesEmptyIndication={
                                    fetchDoctorNotesEmptyIndication
                                  }
                                  submitOnTabSwitch={submitChiefComplaintTab}
                                />
                              )}
                            </Box>
                          </Box>
                        )}

                        {/* DOCTOR NOTES CURRENT VISIT HPI TAB  */}
                        {selectedDoctorTabIndex === 1 && (
                          <Box height="100%">
                            {" "}
                            {useGPUChatbot ? (
                              <HpiNotesCard
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                fetchDoctorNotesEmptyIndication={
                                  fetchDoctorNotesEmptyIndication
                                }
                                submitOnTabSwitch={submitHPITab}
                              />
                            ) : (
                              <CPUHpiNotesCard
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                fetchDoctorNotesEmptyIndication={
                                  fetchDoctorNotesEmptyIndication
                                }
                                submitOnTabSwitch={submitHPITab}
                              />
                            )}
                          </Box>
                        )}

                        {/* DOCTOR NOTES CURRENT VISIT ROS TAB  */}
                        {selectedDoctorTabIndex === 2 && (
                          <Box height="100%">
                            {useGPUChatbot ? (
                              <DoctorROSCard
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                showLoader={showLoader}
                                dismissLoader={dismissLoader}
                                fetchDoctorNotesEmptyIndication={
                                  fetchDoctorNotesEmptyIndication
                                }
                                submitOnTabSwitch={submitRosTab}
                              />
                            ) : (
                              <DoctorROSCPUCard
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                showLoader={showLoader}
                                dismissLoader={dismissLoader}
                                fetchDoctorNotesEmptyIndication={
                                  fetchDoctorNotesEmptyIndication
                                }
                                submitOnTabSwitch={submitRosTab}
                              />
                            )}
                          </Box>
                        )}
                        {/* DOCTOR NOTES CURRENT VISIT VITALS TAB  */}
                        {selectedDoctorTabIndex === 3 && (
                          <Box height="100%">
                            <DoctorVitalsForm
                              patientId={patientId}
                              doctorId={doctorId}
                              appointmentId={appointmentId}
                              showSuccessToast={showSuccessToast}
                              showErrorToast={showErrorToast}
                              fetchDoctorNotesEmptyIndication={
                                fetchDoctorNotesEmptyIndication
                              }
                              fetchPatientHealthDataOfStartVisit={fetchPatientHealthData}
                              submitOnTabSwitch={submitVitalsTab}
                            />
                          </Box>
                        )}

                        {/* DOCTOR NOTES CURRENT VISIT PHYSICAL EXAM TAB  */}
                        {selectedDoctorTabIndex === 4 && (
                          <Box height="100%">
                            {useGPUChatbot ? (
                              <DoctorPhysicalExamCard
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                fetchDoctorNotesEmptyIndication={
                                  fetchDoctorNotesEmptyIndication
                                }
                                
                                submitOnTabSwitch={submitPhysicalExamTab}
                              />
                            ) : (
                              <DoctorPhysicalExamCPUCard
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                                fetchDoctorNotesEmptyIndication={
                                  fetchDoctorNotesEmptyIndication
                                }
                                submitOnTabSwitch={submitPhysicalExamTab}
                              />
                            )}
                          </Box>
                        )}

                        {/* DOCTOR NOTES CURRENT VISIT ASSESSMENT TAB  */}
                        {selectedDoctorTabIndex === 5 && (
                          <Box height="100%">
                            <DoctorNotesAssessment
                              patientId={patientId}
                              doctorId={doctorId}
                              patientname={
                                patientHealthData?.patientinfo?.firstname +
                                " " +
                                patientHealthData?.patientinfo?.lastname
                              }
                              patientgender={
                                patientHealthData?.patientinfo?.gender
                              }
                              patientage={calculateAge(
                                patientHealthData?.patientinfo?.dob
                              )}
                              appointmentId={appointmentId}
                              showSuccessToast={showSuccessToast}
                              showErrorToast={showErrorToast}
                              triggerFetchAssessmentPlans={
                                triggerAssessmentPlans
                              }
                              fetchDoctorNotesEmptyIndication={
                                fetchDoctorNotesEmptyIndication
                              }
                              
                              submitOnTabSwitch={submitAssessmentTab}
                            />
                          </Box>
                        )}

                        {/* DOCTOR NOTES CURRENT VISIT ORDERS TAB  */}
                        {selectedDoctorTabIndex === 6 && (
                          <Box height="100%">
                            <DoctorNotesOrders
                              ref={medicationComponentRef}
                              searchRef={doctorNotesSearchOverlay}
                              patientId={patientId}
                              patientname={
                                patientHealthData?.patientinfo?.firstname +
                                " " +
                                patientHealthData?.patientinfo?.lastname
                              }
                              patientgender={
                                patientHealthData?.patientinfo?.gender
                              }
                              patientage={calculateAge(
                                patientHealthData?.patientinfo?.dob
                              )}
                              doctorId={doctorId}
                              appointmentId={appointmentId}
                              showSuccessToast={showSuccessToast}
                              showErrorToast={showErrorToast}
                              showSubLoader={showLoader}
                              dismissSubLoader={dismissLoader}
                              triggerFetchRecommendMedicines={
                                triggerRecommendMedicines
                              }
                              fetchDoctorNotesEmptyIndication={
                                fetchDoctorNotesEmptyIndication
                              }
                              fetchPatientHealthData={fetchPatientHealthData}
                            />
                          </Box>
                        )}

                        {/* DOCTOR NOTES CURRENT VISIT DOCUMENTS TAB  */}
                        {selectedDoctorTabIndex === 7 && (
                          <Box height="100%">
                            <UploadDoctorDocuments
                              ref={null}
                              width="100%"
                              height="100%"
                              // showLoader={showLoader}
                              // dismissLoader={dismissLoader}
                              showSuccessToast={showSuccessToast}
                              showErrorToast={showErrorToast}
                              patientId={patientId}
                              doctorId={doctorId}
                              appointmentId={appointmentId}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <ModalRight
            ref={patientMessenger}
            header={"Message"}
            modalWidth="50vw"
            // isBg={true}
            bodyStyle={{ p: "0" }}
            body={
              <>
                {/* <Box bg="#0B0B0D" p="24px" pb="0">
                  Hello
                </Box> */}
                {/* <Box display="flex" gap="1rem">Hi</Box> */}
                {useGPUChatbot ? (
                  <GPUMessages
                    doctorId={doctorId}
                    patientId={patientId}
                    appointmentId={appointmentId}
                    isVirtualVisit={virtualVisit ? true : false}
                  />
                ) : (
                  <CPUMessages
                    doctorId={doctorId}
                    patientId={patientId}
                    appointmentId={appointmentId}
                    isVirtualVisit={virtualVisit ? true : false}
                  />
                )}
              </>
            }
          />

          <DynamicModal
            ref={virtualVisitChatNotes}
            modalHeader="Aira: Chat History"
            modalWidth={"50vw"}
            modalHeight={"75vh"}
            hasFooter={false}
            modalBody={
              <>
                {/* <AIRA CHAT NOTES /> */}
                <VirtualChatDisplay
                  doctorId={doctorId}
                  patientId={patientId}
                  appointmentId={appointmentId}
                  patientName={
                    patientHealthData?.patientinfo?.firstname +
                    " " +
                    patientHealthData?.patientinfo?.lastname
                  }
                  showSuccessToast={showSuccessToast}
                  showErrorToast={showErrorToast}
                />
              </>
            }
          />

          

         


          {/* new modals */}
            {/* reAssign MOdal  */}
        <DynamicModal
        ref={reAssignModal}
        modalHeader="Re-Assign Appointment"
        modalBody={
          <>
            <Box>
            <DropdownInput
                      variant="fullSide"
                      dropDownPlace="Doctor Name*"
                      options={DoctorListForDropdown}
                      id="Doctor Name"
                      // onClick={HandleEditDoctor}
                      // defaultOption={DefaultDoctor}
                      menuList={{ pos: "absolute", right: "-41px", minW: "310px" }}
                      inputStyleCss={{ w: "100%" }}
                    />
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              buttonText="Re-Assign"
              // onClick={}
            />
          </>
        }
      />
        </Box>
      ) : (
        <>
          <MobileNavbar />
          <PatientVisitNav
            ref={navRef}
            patientName={
              patientHealthData?.patientinfo?.firstname +
              " " +
              patientHealthData?.patientinfo?.lastname
            }
            // patientImage={
            //   patientHealthData?.patientinfo?.imagepath +
            //   patientHealthData?.patientinfo?.patientimage
            // }
            patientImage={
              patientHealthData?.patientinfo?.patientimage
                ? patientHealthData?.patientinfo?.patientimage.startsWith(
                    "http"
                  )
                  ? patientHealthData?.patientinfo?.patientimage
                  : patientHealthData?.patientinfo?.imagepath +
                    patientHealthData?.patientinfo?.patientimage
                : "/assets/imgs/no-image.png"
            }
            hasImage={patientHealthData?.patientinfo?.patientimage}
            patientInfoId={
              patientHealthData.patientinfo.uniqueid
                ? "#" + patientHealthData.patientinfo.uniqueid
                : ""
            }
            subHeader={navHeaderOverviewData}
            doctorId={doctorId}
            pateintId={patientId}
            appointmentId={appointmentId}
            time={timeToResume}
            showSuccessToast={showSuccessToast}
            showErrorToast={showErrorToast}
            showLoader={showLoader}
            dismissLoader={dismissLoader}
            IsAssign={IsAssign}
          />

          <Flex
            w="100%"
            flexDir="column"
            h="calc(100vh - 21rem)"
            overflow="hidden auto"
          >
            {/* Left Panel */}
            <Box width="100%" p={0} position="relative">
              {/* Patient Panel */}
              <Flex direction="column" h="100%">
                {/* Box 1 */}
                <Box bg="white" p="4" border="1px solid #e2e8f0">
                <Flex justifyContent="space-between" alignItems="center">
                  {viewPastVisitTabs ? (
                    <>
                      {/* Left side with left arrow and heading */}
                      <Flex alignItems="center">
                        <Image
                          src="/assets/svgs/left-tab-icon.svg"
                          onClick={() => goToPatientCurrentVisit(false)}
                          cursor="pointer"
                        />
                        <Text
                          fontSize="0.75rem"
                          letterSpacing="0.1rem"
                          fontWeight="600"
                          textTransform="uppercase"
                          color="#061027"
                          ml="2" // Adjust margin as needed for spacing
                        >
                          {pastVisitTabsHeader}
                        </Text>
                      </Flex>
                      {/* Right side with search icon */}
                      <Image src="/assets/svgs/search.svg" />
                    </>
                  ) : (
                    <>
                      <Text
                        fontSize="0.75rem"
                        letterSpacing="0.1rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        color="#061027"
                      >
                        Patient Overview
                      </Text>
                      <Spacer />

                      <PrimaryButton
                        buttonText="Assign"
                        onClick={() => handleReAssign()}  
                        isDisabled={actionAccessAssigning && actionAccessAssigning.create ? false : true}
                        variant="mdBtn"
                        btnStyle={{ mr:2, h: 35, w: 70 }}
                      />

                      <PrimaryButton
                        buttonText="Chat"
                        onClick={openPatientMessage}
                        variant="mdBtn"
                        btnStyle={{ m: 0, h: 35, w: 50 }}
                      />

                      <Box as="button" ml="4">
                        <Image src="/assets/svgs/search.svg" />
                      </Box>
                    </>
                  )}
                </Flex>
                </Box>
                <Box
                  overflow="hidden"
                  position="relative"
                  border="1px solid #e2e8f0"
                >
                  <Box
                    as="button"
                    className={`${styles.tabArrow} ${styles.tabArrowLeft}`}
                    onClick={scrollLeft}
                  >
                    <Image src="/assets/svgs/left-tab-icon.svg" />
                  </Box>
                  <Box
                    as="button"
                    className={`${styles.tabArrow} ${styles.tabArrowRight}`}
                    onClick={scrollRight}
                  >
                    <Image src="/assets/svgs/right-tab-icon.svg" />
                  </Box>
                  <Box
                    ref={tabListRef}
                    ml="16px" // set according to the text of tab
                    mr="20px" // set according to the text of tab
                    className={styles.scrollContainer}
                  >
                    <Flex>
                      {viewPastVisitTabs
                        ? patientPastVisitTabs.map((tab, index) => (
                            <Box
                              key={index}
                              className={styles.tabButton}
                              onClick={() => handlePatientTabClick(index)}
                              borderBottom={
                                selectedPatientTabIndex === index
                                  ? "2px solid black"
                                  : "none"
                              }
                              color={
                                selectedPatientTabIndex === index
                                  ? "black"
                                  : "gray"
                              }
                            >
                              {tab}
                            </Box>
                          ))
                        : patientOverviewTabs.map((tab, index) => (
                            <Box
                              key={index}
                              className={styles.tabButton}
                              onClick={() => handlePatientTabClick(index)}
                              borderBottom={
                                selectedPatientTabIndex === index
                                  ? "2px solid black"
                                  : "none"
                              }
                              color={
                                selectedPatientTabIndex === index
                                  ? "black"
                                  : "gray"
                              }
                            >
                              {tab}
                            </Box>
                          ))}
                    </Flex>
                  </Box>
                </Box>
                <Box bg="#fcfcfd" flex="1" overflow="hidden">
                  {/* Content for the third box */}

                  {isLoading && <Loader miniLoader={true} />}
                  <Box overflowY="scroll" height="fit-content" minH='100px' p="1rem">
                    {/* PATIENT CURRENT VISIT OVERVIEW TAB  */}
                    {/* {!viewPastVisitTabs && selectedPatientTabIndex === 0 && (
                      <Flex>
                        <CurrentVisitOverview patienData={patientHealthData} />
                      </Flex>
                    )} */}
                    {!viewPastVisitTabs &&
                      selectedPatientTabIndex === 0 && (
                        <Flex>
                          <StartVisitOverview
                            patientId={patientId}
                            doctorId={doctorId}
                            appointmentId={appointmentId}
                            patienData={patientHealthData}
                            displayOverview={true}
                            onAddMedicalConditionClick={
                              handleAddMedicalConditionModal
                            }
                            onAddSurgeryClick={
                              handleAddSurgeryConditionModal
                            }
                            onAddAllergyClick={
                              handleAddAllergyConditionModal
                            }
                            onAddVaccineClick={
                              handleAddVaccinationConditionModal
                            }
                            onAddSocialHistoryClick={
                              handleAddSocialHistoryConditionModal
                            }
                            onHeaderClickChangeToTab={handlePatientTabClick}
                          />
                        </Flex>
                    )}

                    {/* PATIENT CURRENT VISIT PREVIOUS VISITS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 1 && (
                      <Box w="100%" h='100%'>
                        {patientHealthData.Previousvisits.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.Previousvisits.map(
                            (visit, index) => (
                              <PatientVisit
                                key={index}
                                doctorid={doctorId}
                                patientid={patientId}
                                appointmentid={visit.appointmentid}
                                chiefcomplaint={visit.chiefcomplaint}
                                appointmentdate={visit.appointmentdate}
                                speciality={visit.specality}
                                onPastVisitClick={handlePastVisitClick}
                              />
                            )
                          )
                        )}
                      </Box>
                    )}
                    

                    {/* PATIENT CURRENT VISIT VITALS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 2 && (
                      <Flex wrap="wrap" justify="space-between">
                        {patientHealthData.vitals.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          <VitalDisplayCard
                            vitals={patientHealthData.vitals}
                            patientId={patientId}
                            doctorId={doctorId}
                            appointmentId={appointmentId}
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                          />
                        )}
                      </Flex>
                    )}

                    {/* PATIENT CURRENT VISIT MEDICATIONS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 3 && (
                      <Box w="100%">
                        {patientHealthData.medications.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.medications.map((data, index) => (
                            <Medications
                              key={index}
                              patientId={patientId}
                              doctorId={doctorId}
                              appointmentId={appointmentId}
                              date={data.date}
                              medicines={data.medicines}
                              attachments={data.attachments}
                              id={data.id}
                              onEdit={() => console.log("Edit", data.id)}
                              onDelete={() => console.log("Delete", data.id)}
                              fetchPatientHealthData={fetchPatientHealthData}
                              showLoader={showLoader}
                              dismissLoader={dismissLoader}
                              showSuccessToast={showSuccessToast}
                              showErrorToast={showErrorToast}
                              refreshDoctorNotes={handleRefreshDoctorNotes}
                            />
                          ))
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT MEDICAL HISTORY TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 4 && (
                      <Box w="100%">
                        <Box>
                          {patientHealthData.medicalhistory.length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientHealthData.medicalhistory.map(
                              (condition, index) => (
                                <MedicalHistory
                                  key={index}
                                  id={condition.id}
                                  patientId={patientId}
                                  doctorId={doctorId}
                                  appointmentId={appointmentId}
                                  condition={condition.condition}
                                  condition_cd={condition.condition_cd}
                                  conditiontype_cd={condition.conditiontype_cd}
                                  date={condition.conditionidentifieddate}
                                  fetchPatientHealthData={
                                    fetchPatientHealthData
                                  }
                                  showLoader={showLoader}
                                  dismissLoader={dismissLoader}
                                  showSuccessToast={showSuccessToast}
                                  showErrorToast={showErrorToast}
                                />
                              )
                            )
                          )}
                        </Box>
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT SURGICAL HISTORY TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 5 && (
                      <Box w="100%">
                        {patientHealthData.surgicalhistory.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.surgicalhistory.map(
                            (surgery, index) => (
                              <SurgicalHistory
                                key={index}
                                date={surgery.date}
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                surgeryName={surgery.surgeryName}
                                doctorName={surgery.doctorName}
                                hospital={surgery.hospital}
                                attachments={surgery.attachments}
                                surgery_id={surgery.id}
                                fetchPatientHealthData={fetchPatientHealthData}
                                showLoader={showLoader}
                                dismissLoader={dismissLoader}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                              />
                            )
                          )
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT FAMILY HISTORY TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 6 && (
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        {patientHealthData.familyhistory.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.familyhistory
                            .reduce((rows, member, index) => {
                              if (index % 2 === 0) {
                                rows.push([member]);
                              } else {
                                rows[rows.length - 1].push(member);
                              }
                              return rows;
                            }, [])
                            .map((row, index) => (
                              <FamilyHistory
                                key={index}
                                familydata={row}
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                fetchPatientHealthData={fetchPatientHealthData}
                              />
                            ))
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT SOCIAL HISTORY TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 7 && (
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        {patientHealthData.socialhistory.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.socialhistory
                            .reduce((rows, drug, index) => {
                              if (index % 2 === 0) {
                                rows.push([drug]);
                              } else {
                                rows[rows.length - 1].push(drug);
                              }
                              return rows;
                            }, [])
                            .map((drug, index) => (
                              <SocialHistory
                                key={index}
                                drugData={drug}
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                fetchPatientHealthData={fetchPatientHealthData}
                              />
                            ))
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT ALLERGIES TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 8 && (
                      <Box w="100%">
                        {patientHealthData.allergy.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          <SimpleGrid columns={1} spacing={3}>
                            {patientHealthData.allergy.map((allergy, index) => (
                              <Allergies
                                key={index}
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                allergyFrom={allergy.allergyFrom}
                                onSet={allergy.onSet}
                                problems={allergy.problems}
                                attachments={allergy.attachments}
                                reaction={allergy.reaction}
                                allergyidentifieddate={
                                  allergy.allergyidentifieddate
                                }
                                id={allergy.id}
                                fetchPatientHealthData={fetchPatientHealthData}
                                showLoader={showLoader}
                                dismissLoader={dismissLoader}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                              />
                            ))}
                          </SimpleGrid>
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT VACCINATIONS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 9 && (
                      <Box w="100%">
                        {patientHealthData.vaccinations.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.vaccinations.map(
                            (vaccine, index) => (
                              <Vaccination
                                key={index}
                                id={vaccine.id}
                                patientId={patientId}
                                doctorId={doctorId}
                                appointmentId={appointmentId}
                                vaccineName={vaccine.name}
                                applyDate={vaccine.adminDate}
                                dueDate={vaccine.dueDate}
                                attachments={vaccine.attachments}
                                fetchPatientHealthData={fetchPatientHealthData}
                                showLoader={showLoader}
                                dismissLoader={dismissLoader}
                                showSuccessToast={showSuccessToast}
                                showErrorToast={showErrorToast}
                              />
                            )
                          )
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT INVESTIGATIONS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 10 && (
                      <Flex wrap="wrap" justify="space-between">
                        {patientHealthData.overviewinvestigations.length ===
                        0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          <SimpleGrid columns={1} spacing={3} w="100%">
                            {patientHealthData.overviewinvestigations.map(
                              (test, index) => (
                                <Box key={index} w="100%">
                                  <InvestigationsDisplayCard
                                    vitalName={test.eventtitle}
                                    vitalValue={test.actualresultvalue}
                                    vitalUnit={test.resultuom}
                                    vitalDate={test.verifieddate}
                                    status={test.status}
                                    trendColor={test.trendColor}
                                    // vitalStatus={test.vitalStatus}
                                  />
                                </Box>
                              )
                            )}
                          </SimpleGrid>
                        )}
                      </Flex>
                    )}

                    {/* PATIENT CURRENT VISIT LAB TESTS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 11 && (
                      <Box w="100%">
                        {patientHealthData.labs.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          <LabTests
                            tests={patientHealthData.labs}
                            doctorId={doctorId}
                            pateintId={patientId}
                            appointmentId={appointmentId}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            showLoader={showLoader}
                            dismissLoader={dismissLoader}
                          />
                        )}
                      </Box>
                    )}

                    {/* PATIENT CURRENT VISIT IMAGING TESTS TAB  */}
                    {!viewPastVisitTabs && selectedPatientTabIndex === 12 && (
                      <Box w="100%">
                        {patientHealthData.imaging.length === 0 ? (
                          <NoDataScreen width="100%" height="100%" />
                        ) : (
                          patientHealthData.imaging.map((test, index) => (
                            <ImagingTests
                              key={index}
                              date={test.date}
                              name={test.name}
                              summary={test.summary}
                              attachments={test.attachments}
                              id={test.id}
                              interpretation={test.interpretation}
                              normal={test.normal}
                              abnormal={test.abnormal}
                              // onEdit={}
                              // onDelete={}
                            />
                          ))
                        )}
                      </Box>
                    )}

                    {/* ------------------------------PAST VISIT TABS CONTENT---------------------------------------- */}

                    {/* PAST VISITS OVERVIEW TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 0 && (
                        <Flex>
                          <PastVisitOverview
                            patienData={patientSelectedVisitHealthData}
                          />
                        </Flex>
                      )}

                    {/* PAST VISITS VITALS TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 1 && (
                        <Flex wrap="wrap" justify="space-between">
                          {patientSelectedVisitHealthData.vitals.length ===
                          0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.vitals.map(
                              (vital, index) => (
                                <Box
                                  key={index}
                                  flex="1 1 48%"
                                  maxW="48%"
                                  mb={4}
                                >
                                  <PastVitalDisplayCard
                                    vitalName={vital.vitalName}
                                    vitalValue={vital.vitalValue}
                                    vitalUnit={vital.vitalUnit}
                                    vitalStatus={vital.vitalStatus}
                                  />
                                </Box>
                              )
                            )
                          )}
                        </Flex>
                      )}

                    {/* PAST VISITS MEDICATIONS TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 2 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.medications.length ===
                          0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.medications.map(
                              (data, index) => (
                                <PastMedications
                                  key={index}
                                  date={data.date}
                                  medicines={data.medicines}
                                  attachments={data.attachments}
                                  id={data.id}
                                  onEdit={() => console.log("Edit", data.id)}
                                  onDelete={() =>
                                    console.log("Delete", data.id)
                                  }
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS MEDICAL HISTORY TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 3 && (
                        <Box w="100%">
                          <Box>
                            {patientSelectedVisitHealthData.medicalhistory
                              .length === 0 ? (
                              <NoDataScreen width="100%" height="100%" />
                            ) : (
                              patientSelectedVisitHealthData.medicalhistory.map(
                                (condition, index) => (
                                  <PastMedicalHistory
                                    key={index}
                                    id={condition.id}
                                    condition={condition.condition}
                                    date={condition.conditionidentifieddate}
                                  />
                                )
                              )
                            )}
                          </Box>
                        </Box>
                      )}

                    {/* PAST VISITS SURGICAL HISTORY TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 4 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.surgicalhistory
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.surgicalhistory.map(
                              (surgery, index) => (
                                <PastSurgicalHistory
                                  key={index}
                                  date={surgery.date}
                                  surgeryName={surgery.surgeryName}
                                  doctorName={surgery.doctorName}
                                  hospital={surgery.hospital}
                                  attachments={surgery.attachments}
                                  id={surgery.id}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS FAMILY HISTORY TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      patientSelectedVisitHealthData.familyhistory &&
                      selectedPatientTabIndex === 5 && (
                        <Box w="100%">
                          <PastFamilyTreeTable
                            familyHistory={
                              patientSelectedVisitHealthData.familyhistory
                            }
                          />
                        </Box>
                      )}

                    {/* PAST VISITS SOCIAL HISTORY TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 6 && (
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          justifyContent="space-between"
                        >
                          {patientSelectedVisitHealthData.socialhistory
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.socialhistory
                              .reduce((rows, drug, index) => {
                                if (index % 2 === 0) {
                                  rows.push([drug]);
                                } else {
                                  rows[rows.length - 1].push(drug);
                                }
                                return rows;
                              }, [])
                              .map((drug, index) => (
                                <PastSocialHistory
                                  key={index}
                                  drugData={drug}
                                />
                              ))
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS PHYSICAL EXAM TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 7 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.physicalexam
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.physicalexam.map(
                              (exam, index) => (
                                <PastPhysicalExam
                                  key={index}
                                  type={exam.type}
                                  condition={exam.condition}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS TREATMENT PLAN TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 8 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.doctorsassessment
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.doctorsassessment.map(
                              (treatment, index) => (
                                <PastTreatmentPlan
                                  key={index}
                                  diagnosis={treatment.diagnosis}
                                  plan={treatment.plan}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS ROS TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 9 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.reviewofsystem
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.reviewofsystem.map(
                              (ros, index) => (
                                <PastPhysicalExam
                                  key={index}
                                  type={ros.type}
                                  condition={ros.condition}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS ALLERGIES TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 10 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.allergy.length ===
                          0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.allergy.map(
                              (allergy, index) => (
                                <PastAllergies
                                  key={index}
                                  allergyFrom={allergy.allergyFrom}
                                  onSet={allergy.onSet}
                                  problems={allergy.problems}
                                  attachments={allergy.attachments}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISITS VACCINATIONS TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 11 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.vaccinations
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.vaccinations.map(
                              (vaccine, index) => (
                                <PastVaccination
                                  key={index}
                                  vaccineName={vaccine.name}
                                  dueDate={vaccine.dueDate}
                                  applyDate={vaccine.adminDate}
                                  attachments={vaccine.attachments}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISIT INVESTIGATION TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 12 && (
                        <Flex wrap="wrap" justify="space-between">
                          {patientSelectedVisitHealthData.overviewinvestigations
                            .length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.overviewinvestigations.map(
                              (test, index) => (
                                <Box
                                  key={index}
                                  flex="1 1 48%"
                                  maxW="48%"
                                  mb={4}
                                >
                                  <InvestigationsDisplayCard
                                    vitalName={test.eventtitle}
                                    vitalValue={test.actualresultvalue}
                                    vitalUnit={test.resultuom}
                                    vitalDate={test.verifieddate}
                                    status={test.status}
                                    trendColor={test.trendColor}
                                    // vitalStatus={test.vitalStatus}
                                  />
                                </Box>
                              )
                            )
                          )}
                        </Flex>
                      )}

                    {/* PAST VISIT LABS TEST TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 13 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.labs.length === 0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.labs.map(
                              (test, index) => (
                                <PastLabTests
                                  key={index}
                                  date={test.date}
                                  name={test.name}
                                  summary={test.summary}
                                  attachments={test.attachments}
                                  id={test.id}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}

                    {/* PAST VISIT IMAGING TEST TAB  */}
                    {viewPastVisitTabs &&
                      patientSelectedVisitHealthData &&
                      selectedPatientTabIndex === 14 && (
                        <Box w="100%">
                          {patientSelectedVisitHealthData.imaging.length ===
                          0 ? (
                            <NoDataScreen width="100%" height="100%" />
                          ) : (
                            patientSelectedVisitHealthData.imaging.map(
                              (test, index) => (
                                <PastImagingTests
                                  key={index}
                                  date={test.date}
                                  name={test.name}
                                  summary={test.summary}
                                  attachments={test.attachments}
                                  id={test.id}
                                  interpretation={test.interpretation}
                                  normal={test.normal}
                                  abnormal={test.abnormal}
                                />
                              )
                            )
                          )}
                        </Box>
                      )}
                  </Box>
                </Box>
              </Flex>
            </Box>

            {/* ------------------------------------------------------------------------------- */}

            {/* Divider */}
            <Flex w="50%" mx="auto">
              <Divider
                orientation="horizontal"
                my="1rem"
                borderRadius="4px"
                borderWidth="3px"
                borderColor="#1A202C"
              />
            </Flex>

            {/* ------------------------------------------------------------------------- */}

            {/* Right Panel */}
            <Box width="100%" p={0} position="relative">
              <Flex
                alignItems="center"
                p="1.5rem 1rem"
                justifyContent={selectedDoctorTabIndex === 5 || selectedDoctorTabIndex === 6 ? "space-between" : "flex-end"}
              >
                  {(selectedDoctorTabIndex === 5 ||
                    selectedDoctorTabIndex === 6) && (
                    <div>
                      <Box
                      as="button"
                      borderRadius="md"
                      onClick={openAssistPanel}
                      zIndex="5"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap="5px"
                      w="100px"
                      h="20px"
                      mr="4px"
                      left={{ lg: "80%", xl: "80%" }}
                      backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                    >
                      <Image w="16px" src="/assets/imgs/white-spark.png" />
                      <Box display="flex" flexDir="column">
                        <Text
                          textAlign="left"
                          fontSize="10px"
                          fontWeight="700"
                          color="white"
                        >
                          Aira - Assist
                        </Text>
                      </Box>
                    </Box>
                </div>
                  )}
                {useGPUChatbot ? (
                  <GPUChatbot
                    closeSocketCommand={shouldCloseSocket}
                    resetCloseSocketCommand={resetCloseSocketCommand}
                    patientId={patientId}
                    doctorId={doctorId}
                    appointmentId={appointmentId}
                    patientname={
                      patientHealthData?.patientinfo?.firstname +
                      " " +
                      patientHealthData?.patientinfo?.lastname
                    }
                    patientgender={patientHealthData?.patientinfo?.gender}
                    patientage={calculateAge(
                      patientHealthData?.patientinfo?.dob
                    )}
                    height="100%"
                  />
                ) : (
                  <Chatbot
                    closeSocketCommand={shouldCloseSocket}
                    resetCloseSocketCommand={resetCloseSocketCommand}
                    patientId={patientId}
                    doctorId={doctorId}
                    appointmentId={appointmentId}
                    patientname={
                      patientHealthData?.patientinfo?.firstname +
                      " " +
                      patientHealthData?.patientinfo?.lastname
                    }
                    patientgender={patientHealthData?.patientinfo?.gender}
                    patientage={calculateAge(
                      patientHealthData?.patientinfo?.dob
                    )}
                    height="100%"
                  />
                )}
                <div>
                  {/* <Box
                    as="button"
                    borderRadius="md"
                    onClick={() => airaTranscribeNotes.current.openModal()}
                    zIndex="5"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    w="100px"
                    h="20px"
                    mr={"4px"}
                    ml={"4px"}
                    left={{ lg: "80%", xl: "80%" }}
                    backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                  >
                    <Image w="16px" src="/assets/imgs/white-spark.png" />
                    <Box display="flex" flexDir="column">
                      <Text
                        textAlign="left"
                        fontSize="10px"
                        fontWeight="700"
                        color="white"
                      >
                        Aira - Notes
                      </Text>
                    </Box>
                  </Box> */}
                  <Box
                    as="button"
                    borderRadius="md"
                    onClick={handleAIRANotesClick}
                    zIndex="5"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    w="100px"
                    h="20px"
                    mr={"4px"}
                    ml={"4px"}
                    left={{ lg: "80%", xl: "80%" }}
                    backgroundImage="linear-gradient(to right top, #0d7e54, #007774, #006d94, #005ea7, #1146a0)"
                  >
                    <Image
                      w="16px"
                      src="/assets/imgs/white-spark.png"
                    />
                    <Box display="flex" flexDir="column">
                      <Text
                        textAlign="left"
                        fontSize="10px"
                        fontWeight="700"
                        color="white"
                      >
                        Record
                      </Text>
                    </Box>
                  </Box>
                </div>
              </Flex>
              <Flex direction="column" h="100%">
                <Box bg="white" p="4" border="1px solid #e2e8f0">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text
                      fontSize="0.75rem"
                      letterSpacing="0.1rem"
                      fontWeight="600"
                      textTransform="uppercase"
                      color="#061027"
                    >
                      Doctor Notes
                    </Text>
                    <Box as="button" onClick={openSearchPanel}>
                      <Image src="/assets/svgs/search.svg" />{" "}
                    </Box>
                  </Flex>
                </Box>
                <Box
                  overflow="hidden"
                  position="relative"
                  border="1px solid #e2e8f0"
                >
                  <Box
                    as="button"
                    className={`${styles.tabArrow} ${styles.tabArrowLeft}`}
                    onClick={scrollDoctorTabsLeft}
                  >
                    <Image src="/assets/svgs/left-tab-icon.svg" />
                  </Box>
                  <Box
                    as="button"
                    className={`${styles.tabArrow} ${styles.tabArrowRight}`}
                    onClick={scrollDoctorTabsRight}
                  >
                    <Image src="/assets/svgs/right-tab-icon.svg" />
                  </Box>
                  <Box
                    ref={doctorTabListRef}
                    ml="16px" // set according to the text of tab
                    mr="20px" // set according to the text of tab
                    className={styles.scrollContainer}
                  >
                    <Flex>
                      {accessibleTabNames.map((tab, index) => (
                        <Box
                          key={tab.tabindex}
                          className={styles.tabButton}
                          onClick={() => handleDoctorTabClick(tab.tabindex)}
                          borderBottom={
                            selectedDoctorTabIndex === tab.tabindex
                              ? "2px solid black"
                              : "none"
                          }
                          color={
                            selectedDoctorTabIndex === tab.tabindex
                              ? "black"
                              : "gray"
                          }
                        >
                          {tab.name}
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                </Box>

                <Box bg="white" flex="1" overflow="hidden">
                  <Box
                    overflowY="scroll"
                    height="100%"
                    // pr="17px"
                    bg="white"
                    pl="1rem"
                    // mr="-17px"
                    // pl="17px"
                  >
                    {/* DOCTOR NOTES CURRENT VISIT CHIEF COMPLAINTS TAB  */}
                    {selectedDoctorTabIndex === 0 && (
                      <Box height="100%">
                        {useGPUChatbot ? (
                          <DoctorComplaintInputGPUCard
                            patientId={patientId}
                            doctorId={doctorId}
                            appointmentId={appointmentId}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            fetchDoctorNotesEmptyIndication={
                              fetchDoctorNotesEmptyIndication
                            }
                            submitOnTabSwitch={submitChiefComplaintTab}
                          />
                        ) : (
                          <DoctorComplaintInputCPUCard
                            patientId={patientId}
                            doctorId={doctorId}
                            appointmentId={appointmentId}
                            showSuccessToast={showSuccessToast}
                            showErrorToast={showErrorToast}
                            fetchDoctorNotesEmptyIndication={
                              fetchDoctorNotesEmptyIndication
                            }
                            submitOnTabSwitch={submitChiefComplaintTab}
                          />
                        )}
                      </Box>
                    )}

                    {/* DOCTOR NOTES CURRENT VISIT HPI TAB  */}
                    {selectedDoctorTabIndex === 1 && (
                      <Box height="100%">
                        {" "}
                        <HpiNotesCard
                          patientId={patientId}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          
                          submitOnTabSwitch={submitHPITab}
                        />
                      </Box>
                    )}

                    {/* DOCTOR NOTES CURRENT VISIT ROS TAB  */}
                    {selectedDoctorTabIndex === 2 && (
                      <Box height="" mb="0.5rem">
                        <DoctorROSCard
                          patientId={patientId}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          showLoader={showLoader}
                          dismissLoader={dismissLoader}
                          submitOnTabSwitch={submitHPITab}
                        />
                      </Box>
                    )}
                    {/* DOCTOR NOTES CURRENT VISIT VITALS TAB  */}
                    {selectedDoctorTabIndex === 3 && (
                      <Box height="100%">
                        <DoctorVitalsForm
                          patientId={patientId}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          submitOnTabSwitch={submitVitalsTab}
                        />
                      </Box>
                    )}

                    {/* DOCTOR NOTES CURRENT VISIT PHYSICAL EXAM TAB  */}
                    {selectedDoctorTabIndex === 4 && (
                      <Box height="100%">
                        <DoctorPhysicalExamCard
                          patientId={patientId}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          submitOnTabSwitch={submitPhysicalExamTab}
                        />
                      </Box>
                    )}

                    {/* DOCTOR NOTES CURRENT VISIT ASSESSMENT TAB  */}
                    {selectedDoctorTabIndex === 5 && (
                      <Box height="100%">
                        <DoctorNotesAssessment
                          patientId={patientId}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          
                          submitOnTabSwitch={submitAssessmentTab}
                        />
                      </Box>
                    )}

                    {/* DOCTOR NOTES CURRENT VISIT ORDERS TAB  */}
                    {selectedDoctorTabIndex === 6 && (
                      <Box height="100%">
                        <DoctorNotesOrders
                          ref={medicationComponentRef}
                          searchRef={doctorNotesSearchOverlay}
                          patientId={patientId}
                          patientname={
                            patientHealthData?.patientinfo?.firstname +
                            " " +
                            patientHealthData?.patientinfo?.lastname
                          }
                          patientgender={
                            patientHealthData?.patientinfo?.gender
                          }
                          patientage={calculateAge(
                            patientHealthData?.patientinfo?.dob
                          )}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          showSubLoader={showLoader}
                          dismissSubLoader={dismissLoader}
                          triggerFetchRecommendMedicines={
                            triggerRecommendMedicines
                          }
                          fetchDoctorNotesEmptyIndication={
                            fetchDoctorNotesEmptyIndication
                          }
                          fetchPatientHealthData={fetchPatientHealthData}
                        />
                      </Box>
                    )}

                    {/* DOCTOR NOTES CURRENT VISIT DOCUMENTS TAB  */}
                    {selectedDoctorTabIndex === 7 && (
                      <Box height="100%">
                        <UploadDoctorDocuments
                          ref={null}
                          width="100%"
                          height="100%"
                          // showLoader={showLoader}
                          // dismissLoader={dismissLoader}
                          showSuccessToast={showSuccessToast}
                          showErrorToast={showErrorToast}
                          patientId={patientId}
                          doctorId={doctorId}
                          appointmentId={appointmentId}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </>
      )}

      <DynamicModal
        ref={audioCompatibilityDemo}
        modalHeader="Aira: Config"
        modalWidth={"100vw"}
        modalHeight={"100vh"}
        hasFooter={false}
        modalBody={
          <>
            {/* <AIRA CHAT NOTES /> */}
            <AudioCompatibilityCheck modalRef={audioCompatibilityDemo} />
          </>
        }
      />

      <DynamicModal
        ref={airaTranscribeNotes}
        modalHeader="Aira: Notes"
        hasHeader={false}
        modalWidth={"100vw"}
        modalHeight={"100vh"}
        hasFooter={false}
        modalBody={
          <>
            {/* <TranscribeNotes /> */}
            {useGPUChatbot &&
            localStorage.getItem("capableGPU") === "true" ? (
              <GPUChunksTranscribeNotes
                patientId={patientId}
                doctorId={doctorId}
                appointmentId={appointmentId}
                showSuccessToast={showSuccessToast}
                showErrorToast={showErrorToast}
                closeNotesModal={() =>
                  airaTranscribeNotes.current.closeModal()
                }
                fetchPatientHealthData={fetchPatientHealthData}
              />
            ) : (
              <CPUTranscribeNotes
                patientId={patientId}
                doctorId={doctorId}
                appointmentId={appointmentId}
                showSuccessToast={showSuccessToast}
                showErrorToast={showErrorToast}
                closeNotesModal={() =>
                  airaTranscribeNotes.current.closeModal()
                }
                fetchPatientHealthData={fetchPatientHealthData}
              />
            )}
          </>
        }
        // modalFooterBtn={
        //   <>
        //     <PrimaryButton
        //       variant="deleteBtn"
        //       buttonText="Close"
        //       w="200px"
        //       mx="auto"
        //       onClick={() => airaTranscribeNotes.current.closeModal()}
        //     />
        //   </>
        // }
      />
      {ShowAssignModal &&<AssignVisit appointmentId={appointmentId}/>}
    </>
  );
};

export default StartVisitIndex;
