import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Box, Text, Image, Circle, Center } from "@chakra-ui/react";

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};

const PatientVisit = ({
  appointmentid,
  patientid,
  doctorid,
  appointmentdate,
  chiefcomplaint,
  speciality,
  onPastVisitClick,
}) => {
  const handlePrintOrder = () => {
    const url = `/visitprescription?doctorId=${doctorid}&patientId=${patientid}&appointmentId=${appointmentid}`;
    window.open(url, "_blank");
  };

  const handlePrintProgressNotes = () => {
    const url = `/visitsummary?doctorId=${doctorid}&patientId=${patientid}&appointmentId=${appointmentid}`;
    window.open(url, "_blank");
  };

  const formattedDate = formatDate(appointmentdate);

  const handleClick = () => {
    onPastVisitClick(appointmentid, appointmentdate);
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      w="100%"
      bg="#fcfcfd"
      mb="4"
    >
      <Box display="flex" alignItems="center" w="100%">
        <Circle
          size="1rem"
          bg="white"
          border="2px solid #CDCFD4"
          borderRadius="full"
          color="black"
        />
        <Text
          fontSize="12px"
          fontWeight="500"
          textAlign="left"
          color="#384052"
          ml="0.5rem"
        >
          {formattedDate}
        </Text>
      </Box>
      <Box display="flex" w="100%" mt="10px">
        <Center
          w="100%"
          h="auto"
          ml="0.45rem"
          alignItems="start"
          borderLeft="2px solid #E6E7E9"
        >
          <Box w="100%">
            <Box
              p="20px"
              w="100%"
              ml="10px"
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="space-between"
              border="1px solid #E6E7E9"
              borderRadius="12px"
              backgroundColor="white"
            >
              <Box display="flex" alignItems="center" w="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flex="1"
                >
                  <Text
                    title={speciality}
                    fontSize="16px"
                    textAlign="left"
                    fontWeight="600"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    Visited {speciality}
                  </Text>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box
                    as="button"
                    w="36px"
                    h="auto"
                    p="10px"
                    onClick={handleClick}
                  >
                    <Image w="13px" src="/assets/svgs/right-tab-icon.svg" />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" w="100%">
                <Box
                  mt="3px"
                  display="flex"
                  alignItems="center"
                  backgroundColor="#f9fafb"
                  borderLeft="3px solid #14c585"
                  w="100%"
                >
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="#384052"
                    pl="4px"
                  >
                    Reason: {chiefcomplaint}
                  </Text>
                </Box>
              </Box>
              {/* <Box display="flex" alignItems="center" gap="10px" mt="1rem">
                <Box
                  display="flex"
                  alignItems="center"
                  border="1px solid #E6E7E9"
                  borderRadius="8px"
                  p="5px 10px"
                  // w="4rem"
                  backgroundColor="white"
                  onClick={handlePrintOrder}
                >
                  Prescription
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  border="1px solid #E6E7E9"
                  borderRadius="8px"
                  p="5px 10px"
                  // w="4rem"
                  backgroundColor="white"
                  onClick={handlePrintProgressNotes}
                >
                  Progress Notes
                </Box>
              </Box> */}

              <Box display="flex" flexWrap={{base: "wrap", md: "nowrap"}} alignItems="center" gap="10px" mt="1rem">
                <Box
                  display="flex"
                  alignItems="center"
                  border="1px solid #000000" // Black border
                  borderRadius="8px"
                  p={{base: "5px 12px", md: "8px 10px"}} 
                  backgroundColor="white"
                  fontWeight="semibold" // Bold text
                  color="#000000" // Black text color
                  cursor="pointer" // Change cursor to pointer
                  _hover={{
                    backgroundColor: "#f0f0f0", // Light gray on hover
                    borderColor: "#000000", // Stay black on hover
                    color: "#000000", // Black text color on hover
                  }}
                  transition="all 0.3s ease" // Smooth transition
                  onClick={handlePrintOrder}
                >
                  Prescription
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  border="1px solid #000000" // Black border
                  borderRadius="8px"
                  p={{base: "5px 12px", md: "8px 10px"}} 
                  backgroundColor="white"
                  fontWeight="semibold" // Bold text
                  color="#000000" // Black text color
                  cursor="pointer" // Change cursor to pointer
                  _hover={{
                    backgroundColor: "#f0f0f0", // Light gray on hover
                    borderColor: "#000000", // Stay black on hover
                    color: "#000000", // Black text color on hover
                  }}
                  transition="all 0.3s ease" // Smooth transition
                  onClick={handlePrintProgressNotes}
                >
                  Progress Notes
                </Box>
              </Box>

              {/* <Box display="flex" p="0.5rem" pl="0" alignItems="center">
                <Image w="0.75rem" h="auto" src="/assets/svgs/update.svg" />
                <Text
                  fontSize="0.875rem"
                  fontWeight="500"
                  color="#6A707D"
                  pl="0.5rem"
                >
                  Reason: {chiefcomplaint}
                </Text>
              </Box> */}
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

// PatientVisit.propTypes = {
//   appointmentid: PropTypes.string.isRequired,
//   patientid: PropTypes.string.isRequired,
//   appointmentdate: PropTypes.string.isRequired,
//   chiefcomplaint: PropTypes.string.isRequired,
//   speciality: PropTypes.string.isRequired,
//   onPastVisitClick: PropTypes.func.isRequired,
// };

export default PatientVisit;
