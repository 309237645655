import React, { useState, useEffect, useRef } from "react";
import useLoader from "../../components/loader/loaderstates";
import MiniSideBar from "../../components/mini-sidebar";
import Loader from "../../components/loader/loader";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import { Box, Divider, Flex, Spacer, Text, Tr, Td, Menu, MenuButton, MenuList, MenuItem, Image, Card, CardBody, CardFooter } from "@chakra-ui/react";
import SimpleTable from "../../components/simple-table";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../../components/styles/colors";
import { FetchBillingList} from "../../middleware/services/billing/index";
import { PrimaryInput } from "../../components";
import { actionAccessMain, useScreenType } from "../../auth";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";

const BillingIndex = () => {
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const navigate = useNavigate()
  const location = useLocation()
  var [doctorid, setDoctorid] = useState();
  var [data, setData] = useState(null);
  const [searchtext, setSerachText] = useState("");
  const TableHeader = [
    { key: "prescription-lab-visitng", label: "Type" },
    { key: "patient-name", label: "Patient name" },
    { key: "patient-mobile", label: "Patient Mobile" },
    { key: "appointment", label: "Booking ID" },
    { key: "prescribed-by", label: "Prescribed by" },
    { key: "actions", label: "Actions" },
  ];

  const [actionAccess, setActionAccess] = useState(null);
  const [actionAccessInvoices, setActionAccessInvoices] = useState(null);
  const [actionAccessRevenue, setActionAccessRevenue] = useState(null);
  const screenType = useScreenType()
useEffect(() => {
  setActionAccess(actionAccessMain(12))
  setActionAccessInvoices(actionAccessMain(13))
  setActionAccessRevenue(actionAccessMain(14))
  fetchData();
}, []);
const fetchData = async () => {
  setSerachText("");
  showLoader();
  try {
    const result = await FetchBillingList({
      doctorid,
      pageno:"1",
      pagesize:"50"
    });
    if (result != null) {
      setData(result);
    } else {
    }
  } catch (error) {
    console.error("Error in home:", error);
  }
  dismissLoader();
};
const searchanything = async (e) => {
  const value = e.target.value;
   if (e.key === "Enter") {
    showLoader();
    try {
      const result = await FetchBillingList({
        doctorid,
        pageno:"1",
        pagesize:"10",
        searchtext: searchtext
      });
      if (result != null) {
        setData(result);
      } else {
        setData("")
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
   } 
};
const Filteranything = async (e) => {
    showLoader();
    try {
      const result = await FetchBillingList({
        doctorid,
        pageno:"1",
        pagesize:"10",
        searchtext: searchtext
      });
      if (result != null) {
        setData(result);
      } else {
        setData("")
      }
      dismissLoader();
    } catch (error) {
      console.error("Error in home:", error);
    }
};
const refreshPage = () => {
  fetchData()
};
const editorder = (step) => {
  if(step.entitytype == "prescription"){
    navigate("/editorder", { state: { entityid: step.entityid, entitytype: step.entitytype, patientid:step.patientid, appointmentid:step.appointmentid }})
  }else if(step.entitytype == "lab"){
  navigate("/laborder", { state: { entityid: step.entityid, entitytype: step.entitytype, patientid:step.patientid, appointmentid:step.appointmentid }})
  }
  else if(step.entitytype == "appointment"){
    navigate("/appointmentorder", { state: { entityid: step.entityid, entitytype: step.entitytype, patientid:step.patientid, appointmentid:step.appointmentid }})
    }
};
// const gobackfunction = ()=>{
//   navigate("/doctorprofile")
//  }
const gobackfunction = () => {
  if (window.history.length > 2 && location.key !== "default") {
    window.history.back();

    // Fallback if the page does not navigate
    setTimeout(() => {
      if (document.referrer === "" || window.history.length <= 1) {
        navigate("/home");
      }
    }, 500);
  } else {
    navigate("/home");
  }
};
  return (
    <>
      {screenType == 'web' ? (
        <Box w="100%">
          <Flex>
            <Box w="6rem">
              <MiniSideBar />
            </Box>
            {isLoading && <Loader />}
            <Box w="calc(100vw - 6.25rem)" display="flex" flexDir="column">
              <SecondaryNav withBack="Sales Pending" goBack={gobackfunction} />
              <Box
                gap="10px"
                my="1rem"
                px="1rem"
                w="100%"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                {/* <Box w="80%" display="flex" gap="10px" pl="0.5rem">
                  <Text fontSize="1rem" fontWeight="600">
                    List of Prescription/Lab/Visiting
                  </Text>
                </Box> */}

                <Box w="100%" display="flex" justifyContent='space-between'>
                <Flex w='35%' gap='1rem'>
                <PrimaryInput
                    variant="fullRound"
                    inputPlace="Search"
                    // inputStyleCss={{h: "40px"}}
                    onKeyPress={searchanything}
                    maxLength={50}
                    onChange={(e) => setSerachText(e.target.value)}
                    inputValue={searchtext}
                  />
                  <PrimaryButton
                    buttonText="Filter"
                    id="filterButton"
                    variant="mdBtn"
                    btnStyle={{w: "80px"}}
                    onClick={Filteranything}
                  />
                  <PrimaryButton
                    buttonText="Reset"
                    id="resetButton"
                    variant="grayBtn"
                    btnStyle={{ color: "#AA2F27" }}
                    onClick={fetchData}
                  />
                </Flex>

                <Flex w='65%' justifyContent='end' gap='1rem'>
                  <PrimaryButton
                    onClick={()=> window.open('/invoices', '_blank')}
                    variant="mdBtn"
                    btnStyle={{ w: "85px", float: "right" }}
                    buttonText="Sales"
                    btnIcon='/assets/svgs/new-tab.svg'
                    iconSrc={true}
                    iconSrcStyle={{w:'14px'}}
                    isDisabled={ actionAccessInvoices && (!actionAccessInvoices.read) ? true : false} 
                  />
                  <PrimaryButton
                    onClick={()=> window.open('/labpricing', '_blank')}
                    variant="mdBtn"
                    btnStyle={{ w: "110px", float: "right" }}
                    buttonText="Lab setup"
                    btnIcon='/assets/svgs/new-tab.svg'
                    iconSrc={true}
                    iconSrcStyle={{w:'14px'}}
                    isDisabled={ actionAccess && (!actionAccess.read) ? true : false} 
                  />
                  <PrimaryButton
                    onClick={()=> window.open('/imagepricing', '_blank')}
                    variant="mdBtn"
                    btnStyle={{ w: "130px", float: "right" }}
                    buttonText="Imaging Setup"
                    btnIcon='/assets/svgs/new-tab.svg'
                    iconSrc={true}
                    iconSrcStyle={{w:'14px'}}
                    isDisabled={ actionAccess && (!actionAccess.read) ? true : false} 
                  />
                  <PrimaryButton
                    onClick={()=> window.open('/totalrevenue', '_blank')}
                    variant="mdBtn"
                    btnStyle={{ w: "100px", float: "right" }}
                    buttonText="Revenue"
                    btnIcon='/assets/svgs/new-tab.svg'
                    iconSrc={true}
                    iconSrcStyle={{w:'14px'}}
                    isDisabled={ actionAccessRevenue && (!actionAccessRevenue.read) ? true : false} 
                  />
                  <PrimaryButton
                    buttonText="OTC Sale"
                    id="filterButton"
                    variant="mdBtn"
                    btnStyle={{w: "80px"}}
                    onClick={()=> navigate("/otcsale", { state: { type: "OTC sale"}})}
                    
                  isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
                  />

                  <PrimaryButton
                    buttonText="Clinical Sale"
                    id="filterButton"
                    variant="mdBtn"
                    onClick={()=> navigate("/otcsale", { state: { type: "Clinical sale"}})}
                    btnStyle={{w: "100px"}}
                    isDisabled={ actionAccess && (!actionAccess.create) ? true : false} 
                  />
                  
                  <PrimaryButton
                    id="filterButton"
                    btnIcon="/assets/svgs/refresh.svg"
                    iconSrc={true}
                    variant="grayBtn"
                    btnStyle={{w: "50px"}}
                    iconSrcStyle={{mr: "0"}}
                    onClick={refreshPage}
                  />
                  </Flex>
                </Box>
              </Box>

              <Divider />

              <Box>
                <Flex
                  w="100%"
                  flexDir="column"
                  gap="1rem"
                  p="1rem"
                  h="calc(100vh - 70px)"
                >
                  <SimpleTable
                    headers={TableHeader}
                    isDataPresent={true}
                    ThStyle={{
                      bg: "#FAFAFA",
                    }}
                    footerStyle={{
                      pos: "sticky",
                      bottom: "-1px",
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                    }}
                    tableStyle={{
                      maxH: "calc(100vh - 170px)",
                      overflowY: "auto",
                      tableLayout: "fixed",
                      width: "100%",
                      border: "1px solid #E6E7E9",
                    }}
                    tableBody={
                      <>
                      {data && data.length > 0 ? (
                          data.map((step, index) => (
                        <Tr w="100%" key={index}>
                          <Td>
                          <Text fontSize=".875rem" fontWeight="500">
                            {step.entitytype ? `${step.entitytype.charAt(0).toUpperCase()}${step.entitytype.slice(1)}` : "-"}
                          </Text>
                          </Td>
                        
                          <Td>
                            <Text fontSize=".875rem" fontWeight="500">
                              {step.patientname ? step.patientname:"-"}
                            </Text>
                          </Td>
                          <Td>
                            <Text fontSize=".875rem" fontWeight="500">
                              {step.patientmobile? step.patientmobile:"-"}
                            </Text>
                          </Td>
                          <Td>
                            <Box p=".25rem .5rem" borderRadius="full">
                              <Text
                                fontSize=".75rem"
                                fontWeight="600"
                              >
                                {step.bookingid? "#"+step.bookingid:"-"}
                              </Text>
                            </Box>
                          </Td>

                          <Td>
                            <Text fontSize=".875rem" fontWeight="500">
                              {step.doctorname? step.doctorname:"-"}
                            </Text>
                          </Td>

                          <Td>
                          <PrimaryButton
                            buttonText='Process'
                            variant="grayBtn"
                            btnStyle={{color: COLORS.PRIMARY_COLOR, w: "100px"}}
                            onClick={()=> editorder(step)}
                            
                  isDisabled={ actionAccess && (!actionAccess.update) ? true : false} 
                          />
                          </Td>
                        </Tr>
                        ))
                        ) : (
                        <Tr>
                          <Td colSpan={6} textAlign='center'>No records available.</Td>
                        </Tr>
                        )}
                      </>
                    }
                  />
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Box>      
      ) : (
        <>
         <MobileNavbar />
            <MobileSecondaryNav
              header="Sales Pending"
              handlebackarrow={() => navigate(`/doctorprofile`)}
            />
            <Box
              w="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1rem"
              gap='0.75rem'
            >
              <PrimaryInput
                variant="fullRound"
                inputPlace="Search"
                // inputStyleCss={{h: "40px"}}
                onKeyPress={searchanything}
                maxLength={50}
                onChange={(e) => setSerachText(e.target.value)}
                inputValue={searchtext}
              />
              <PrimaryButton
                buttonText="Filter"
                id="filterButton"
                variant="mdBtn"
                btnStyle={{w: "80px"}}
                onClick={Filteranything}
              />
              <PrimaryButton
                buttonText="Reset"
                id="resetButton"
                variant="grayBtn"
                btnStyle={{ color: "#AA2F27", w: "80px" }}
                onClick={fetchData}
              />
              <PrimaryButton
                // id="resetButton"
                btnIcon="/assets/svgs/refresh.svg"
                iconSrc={true}
                variant="grayBtn"
                btnStyle={{w: "50px",p:'0'}}
                iconSrcStyle={{mr: "0"}}
                onClick={refreshPage}
              />
            </Box>

            <Divider />

          <Flex w='100%' gap='1rem' p='1rem' flexDir='column' maxH="calc(100vh - 275px)" overflow='hidden auto'>
            {data && data.length > 0 ? (
              data.map((item, index) => (
              <Card
                borderRadius="12px"
                key={index}
                w="100%"
                border="1px solid #E6E7E9"
                boxShadow="0px 8px 20px 0px #E6E7E966"
              >
                <CardBody p={{base: "0.875rem",lg: "0.875rem", xl: "1.25rem"}}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb="0.75rem"
                  >                  
                    <Box cursor="pointer" display="flex" alignItems="center">
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Text fontSize="15px" fontWeight="600" className="textOverflow" title={item.productname ? item.productname : "-"} maxW={{base: "180px", xl:"110px"}}>
                        {item.patientname ? item.patientname:"-"}
                        </Text>
                      </Box>
                    </Box>

                      
                  </Box>
                  <Flex w='100%' mb="0.75rem" justifyContent='space-between'>
                    <Box display='flex' flexDir='column'>
                    
                        <Text fontSize="10px" color="gray.500">
                          Type
                        </Text>
                        <Text fontSize="12px" fontWeight="600">
                        {item.entitytype ? `${item.entitytype.charAt(0).toUpperCase()}${item.entitytype.slice(1)}` : "-"}
                        </Text>
                        
                      </Box>
                      <Box display='flex' flexDir='column' alignItems="center">
                    
                    <Text fontSize="10px" color="gray.500">
                      Patient Mobile
                    </Text>
                    <Box
                        as="button"
                      >
                        <Text fontSize="12px" fontWeight="600">
                        {item.patientmobile? item.patientmobile:"-"}
                    </Text>
                      </Box>
                    
                  
                  </Box>
                  </Flex>
                  
                  <Flex w='100%' justifyContent='space-between'>
                    <Box display='flex' flexDir='column'>
                    
                        <Text fontSize="10px" color="gray.500">
                          Booking ID
                        </Text>
                        <Text fontSize="12px" fontWeight="600">
                        {item.bookingid? "#"+item.bookingid:"-"}
                        </Text>
                        
                      </Box>
                      <Box display='flex' flexDir='column' alignItems="center">
                    
                    <Text fontSize="10px" color="gray.500">
                      Prescribed by
                    </Text>
                    <Box
                        as="button"
                      >
                        <Text fontSize="12px" fontWeight="600">
                        {item.doctorname? item.doctorname:"-"}
                    </Text>
                      </Box>
                    
                  
                  </Box>
                  </Flex>
                
                </CardBody>
                <CardFooter
                  h="50px"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  p="15px"
                  borderTop="1px solid #E6E7E9"
                >
                  <Box
                    as="button"
                    onClick={()=> editorder(item)}
                    isDisabled={ actionAccess && (!actionAccess.update) ? true : false} 
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box color="#1C4EC5" mr="8px" fontSize="14px" fontWeight="600">
                      Process
                    </Box>
                    <Box>
                      <Image src="/assets/imgs/right-blue-arrow.png" />
                    </Box>
                  </Box>
                </CardFooter>

              </Card>
            ))) : (
              <Text>No data available</Text>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

export default BillingIndex;
