import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Icon,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { COLORS } from "../../../styles/colors";

const DropdownWithID = ({
  options,
  onClick,
  onStarToggle,
  placeholder,
  value,
  inputStyleCss,
  menuList,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelect = (option) => {
    setSelectedValue(option.value);
    if (onClick) onClick(option);
  };

  return (
    <Menu placement="bottom-end" matchWidth>
      <MenuButton
        as={Button}
        w="100%"
        h="48px"
        fontSize="14px"
        fontWeight="500"
        borderRadius="10px"
        className="textOverflow"
        px="0.7rem"
        textAlign="left"
        color="#384052"
        bg="white"
        border="1px solid #E6E7E9"
        _active={{ bg: "none" }}
        _hover={{
          bg: "none",
          outline: "none",
          border: `2px solid ${COLORS.INPUT_FOCUS_DARK}`,
        }}
        _focus={{
          bg: "none",
          outline: "none",
          border: "none",
          boxShadow: `0 0 0 2px ${COLORS.INPUT_FOCUS_DARK}`,
        }}
        {...inputStyleCss}
        isTruncated
      >
        {selectedValue || placeholder}
      </MenuButton>
      <MenuList maxH="200px" overflowY="auto" w="100%" {...menuList} z="999">
        {options.map((option, index) => (
          <MenuItem
            key={index}
            fontSize="14px"
            lineHeight="normal"
            fontWeight="500"
            // color="#384052"
            color={selectedValue === option.value ? "blue.500" : "#384052"}
            bg={selectedValue === option.value ? "blue.50" : "white"}
            onClick={() => handleSelect(option)}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              w="100%"
            >
              <span>{option.value}</span>
              <Icon
                as={StarIcon}
                color={option.starred ? "yellow.400" : "gray.300"}
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onStarToggle(option.value);
                }}
              />
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DropdownWithID;
