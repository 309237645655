import  FetchData, { } from '../../client';
import {getBranchId, getLoginID, getToken}  from '../../../auth';
import formapimodalmedicinesview from '../../domains/medicinesview/medicineViews';
import axios from 'axios';
import { CONFIG } from '../../../appconfig/appconfig';
import formapimodalpatientsPagination from '../../domains/patientsview';
import formapimodalmedicinesPagination from '../../domains/medicinesview';


  const FetchMedicineViewDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getdoctorfavouritemedicine", inputData);
      console.log(result, "Medicine View Result")
      if (result.output) {
        var finalOutput = formapimodalmedicinesPagination(result.output)
        }

        console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchFilteredMedicinesList = async (inputData) => {
    try{
        // inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
        const result = await FetchData('midoc_getdoctorfavouritemedicine', inputData);
        // console.log(result, "Filtered Patients View Result");

        if(result.output.data){
            var data = formapimodalmedicinesview(result.output.data);

            let count = result?.output?.count;
            let pageno = result?.output?.pageno;
            let pagesize = result?.output?.pagesize;
            var finalOutput = {
                data,
                count,
                pageno,
                pagesize
            }
            console.log(finalOutput, "final OUtuput");
            return finalOutput;
        }

    } catch(error) {
        console.error('Error fetching data in forms.js:', error);
        throw error;
    }
}

// Add Medicine
  export const GetMedicineName = async (uri,name,type) => {
    // alert(uri)
     try {     
       const formData = new FormData();
       formData.append("file", uri);
       // form.append('image', {
       //   uri: uri,
       //   type: type ? 'image/jpeg' : 'image/pdf',
       //   name: name ? 'image.jpg' : 'image.pdf',
       // });
        let doctorid = getLoginID();
        formData.append("doctorid", doctorid);
       //console.log(formData)
       const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinename`,formData, {    
         headers: {
           'Content-Type': 'multipart/form-data', 
         },
       });
       //console.log('@@response',response)
       if (response.statusText === "OK") { 
         const result = await response.data;
         //console.log('@@result',result)
         return result
       } else {
         const error = "failed" ;
         return error
       }
     } catch (err) {
       console.log(err); 
       const error = "failed" ;
       return error
     }
   };

   const AddMedicine = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
    //   inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
    //console.log(inputData , " input to add medication")
            const result = await FetchData("midoc_adddoctorfavouritemedicine ", inputData);
     //console.log(result , " db response of add medicane")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  
const FetchMyHealthAIData = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    // inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
    //console.log(inputData)
    const result = await FetchData("midoc_getpatienthealth", inputData);
    // if (result.output.data) {
    //   //console.log(result.output.data)
    // var finalOutput = formapimodalaidotor(result.output.data)
    // }
    // return finalOutput;
    return result

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const FetchMedicineAIPrompts = async (inputData) => {
  try {
    // inputData.patientid = getLoginInfo();
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinegetprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


const FetchMedicineAIAnswer = async (inputData) => {
  try {
    // inputData.patientid = getLoginInfo()
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axios.post(`${CONFIG.APIBaseUrl}midoc_ai_medicinedescribeprompts`, 
      inputData,
    {
      headers: headers,
    });
    
    return response.data;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


// Edit Calls

const FetchMedicationsData = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    // inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
       // console.log(inputData,"Medications input")
    const result = await FetchData("midoc_getdoctorfavouritemedicine", inputData);
    //console.log(result,"Medications db response")
    if (result.output.data) {
    var finalOutput = formapimodalmedicinesview(result.output.data)
    //console.log(finalOutput)
    }
    // return result;
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const DeleteMedicine = async (inputData) => {
  try {

    inputData.branchid = getBranchId();
    // inputData.loginid = getLoginInfo();
    // inputData.activeuserid = getUserID();
    const result = await FetchData("midoc_deletepatientmedicine", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const EditMedicine = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    // inputData.patientid = getLoginInfo();
  // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_updatedoctorfavouritemedicine", inputData);
   
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const DeleteMedication = async (inputData) => {
  try {
    // inputData.patientid = getLoginInfo();
  // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_deletedoctorfavouritemedicine", inputData);
   
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

  

export {FetchMedicineViewDetails, FetchFilteredMedicinesList, AddMedicine, FetchMyHealthAIData, FetchMedicineAIPrompts, FetchMedicineAIAnswer, EditMedicine, DeleteMedication,  FetchMedicationsData, DeleteMedicine};
