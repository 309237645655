import React, { useEffect, useRef, useState } from "react";
import ImageCropper from './ImageCropper';
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  PinInput,
  PinInputField,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Button,
  Td,
  Text,
  Tr,
  VStack,
  Textarea,
  Grid,
  InputGroup,
  InputLeftAddon,
  Heading,
  Center,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import SideBar from "../../components/sidebar";
import DropdownInput from "../../components/dropdown-input";
import { COLORS } from "../../components/styles/colors";
import PrimaryButton from "../../components/primary-button";
import SimpleTable from "../../components/simple-table";
import Footer from "../../components/footer";
import DynamicModal from "../../components/dynamic-modal";
import PrimaryInput from "../../components/primary-input";
import PrimaryInputDropdown from "../../components/primary-input-dropdown";
import { FetchAlerts, addUpdateAlerts,addUpdateSettings, getProfile, saveNameChanges, saveGenderChanges, saveMobileChanges,deleteDocumentCall,uploadImageInProfile, updatePatientProfileOnChange, saveHospitalChanges, saveDegreeChanges, saveSpecialityChanges, saveExperienceChanges, addDoctorBadge, reGenerateBdgeTextCall, badgeAIPromptCall, FetchDoctorSpecialities, updateProfile, saveAddressChanges, updateLogoOnChange, getDefaultData, addClinic, updateClinic, resetPassword, FetchDoctorClinics, saveClinicChanges, uploadPartnerImageInProfile, updatePartnerLogoOnChange, saveClinicEditedChanges, saveClinicDeletedChanges } from "../../middleware/services/profile";
import Toaster from "../../components/toaster";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { downloadDocument,formatDate_DD_MM_YYYY,formatDate_YYYY_MM_DD,formatToDecimal,getAllCountriesMainFunc,getCountryCodeFromCountriesList,getLoginID,handleAlphaDotAndSpace,handleNumericDotAndSpace,saveFirstName,saveLastName,uploadImageCommonFunction, useScreenType, validationToPreventDBErrors } from "../../auth";
import { uploadDocumentInProfile } from "../../middleware/domains/profile/profiles";
import { getDoctorPlans, updateSubscriptionPlan } from "../../middleware/services/signup";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreatePaymentIntent,
  SavePaymentInitialCall,
} from "../../middleware/services/payment/payment";
import CheckoutForm from "./checkout-form";
import VisibleSidebar from "../../components/visible-sidebar";

import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { SubmitServicetype } from '../../middleware/services/finalsteps';
import Dropdown from '../../components/dropdown/index';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // core Swiper styles
import "swiper/css/navigation"; // navigation module styles
import ScheduleComponent from "./availability";
import { color } from "framer-motion";
import { getCroppedImg } from "../../auth";
import { CONFIG } from "../../appconfig/appconfig";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import DatePicker from "../../components/date-picker";
import { getCountry } from "../../middleware/services/country";
import SearchableDropdown from "../../components/searchable-dropdown";
import ModalRight from "../../components/modal-right";
import { PasswordInput, SearchBar } from "../../components";
import { CodeGenForCausalLM } from "@huggingface/transformers";

const stripePromise = loadStripe(CONFIG.StripePublicKey);





const DoctorProfileIndex = () => {
  const [activeTab, setActiveTab] = useState("service")
  const swiperDocCard = React.useRef(null);
  const swiperAiPrompt = React.useRef(null);
  const editName = useRef();
  const editPhoto = useRef();
  const editLogo = useRef();
  const editPartnerLogo = useRef();
  const editGender = useRef();
  const upgradePlan = useRef();
  const cancelPlan = useRef();
  const shareBadge = useRef();
  const editPhone = useRef();
  const editDOB = useRef();
  const verifyOtp = useRef();
  const uploadDoc = useRef();
  const editAvailability = useRef();

  const editHospital = useRef();
  const editAddress = useRef();
  const editDegree = useRef();
  const editSpecialization = useRef();
  const editCustomSpeciality = useRef();
  const editYrsofexperience = useRef();
  const feeStructureModalRef = useRef();
  const personConsultationFeeModalRef = useRef();
  const onlineConsultationFeeModalRef = useRef();
  const remoteChatFeeModalRef = useRef();
  const changePassword = useRef();
  const currencyModalRef = useRef();
  const addBranch = useRef();
  const editBranch = useRef();
  const addPartner = useRef();
  const editPartner = useRef();
  const deletePartner = useRef();
  const navigate = useNavigate()
  const navItems = [
    {
      to: "personalSection",
      iconSrc: "/assets/svgs/personal-detail.svg",
      text: "Personal details",
      //   onClick: ()=> editDetail.current.openModal()
    },
    {
      to: "professionalSection",
      iconSrc: "/assets/svgs/professional-detail.svg",
      text: "Professional details",
    },
    {
      to: "documentSection",
      iconSrc: "/assets/svgs/document.svg",
      text: "Documents",
    },
    {
      to: "availSection",
      iconSrc: "/assets/svgs/availability.svg",
      text: "Availability",
      //onClick: ()=> editAvailability.current.openModal() ,

    },
    {
      to: "alertSection",
      iconSrc: "/assets/svgs/notifications-profile.svg",
      text: "Alerts",
    },
    {
      to: "settingsSection",
      iconSrc: "/assets/svgs/notifications-profile.svg",
      text: "Settings",
    },
    {
      to: "feeStructureSection",
      iconSrc: "/assets/svgs/fee-structure.svg",
      text: "Fee Structure",
    },
    {
      to: "subscriptionSection",
      iconSrc: "/assets/svgs/subscription.svg",
      text: "Subscription",
    },
    {
      to: "invoicesSection",
      iconSrc: "/assets/svgs/invoices.svg",
      text: "Invoices",
    },
  ];
  const [activeSection, setActiveSection] = useState(navItems[0].to)
  const [page, setPage] = useState("1")
  const mainContainerRef = useRef(null);
  const sectionRefs = {
    personalSection: useRef(null),
    addressSection: useRef(null),
    professionalSection: useRef(null),
    feeStructureSection: useRef(null),
    documentSection: useRef(null),
    alertSection: useRef(null),
    settingsSection: useRef(null),
    subscriptionSection: useRef(null),
    invoicesSection: useRef(null),
    availSection: useRef(null),

  };
  const handleScrollToSection = (sectionId) => {
    const mainContainer = mainContainerRef.current;
    const section = sectionRefs[sectionId].current;
    if (section && mainContainer) {
      const sectionTopOffset = section.offsetTop - mainContainer.offsetTop;
      mainContainer.scrollTo({
        top: sectionTopOffset,
        behavior: 'smooth'
      });
      setActiveSection(sectionId)
    } else {
      console.log(`Section not found: ${sectionId}`);
    }
  };
  // Share badge integration start
  const handleDoc = (direction) => {
    if (swiperDocCard.current && swiperDocCard.current.swiper) {
      const swiper = swiperDocCard.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        setPage("1")
        setCurrentBadge(badgeBox1)
        swiper.slidePrev();
      } else if (direction === "next" && swiper.slideNext) {
        setCurrentBadge(badgeBox2)
        setPage("2")
        swiper.slideNext();
      }
    }
  };

  // Share badge Ai Prompt start
  const handleAiPrompt = (direction) => {
    if (swiperAiPrompt.current && swiperAiPrompt.current.swiper) {
      const swiper = swiperAiPrompt.current.swiper;
      if (direction === "prev" && swiper.slidePrev) {
        swiper.slidePrev();
        // console.log('left clicked')
      } else if (direction === "next" && swiper.slideNext) {
        swiper.slideNext();
        // console.log('next clicked')
      }
    }
  };
  const swiperDocCSS = `
  .swiper-container{
    height: 100%; 
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    max-width: 100vw; 
    width: 100%;
    overflow: hidden;
  }
   
  .swiper-slide{
    width: 100%!important;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }

  .swiper.swiper-initialized {
    margin: 0!important;
  }
  
  .swiper-wrapper{
    max-height: 100%;
    height: 100%;
    display: flex;
    gap: 0px;
  }

  .AiPrompt .swiper-slide {
    width: fit-content!important;
  }

  .AiPrompt .swiper-wrapper {
    gap: 10px;
  }
  `;
  // Share badge integration end
  // const documents = [
  //   { id: 1, name: "degree_A0587.pdf", size: "120 KB" },
  //   { id: 2, name: "license_A0587.pdf", size: "120 KB" },
  //   { id: 3, name: "qualification_A0587.pdf", size: "120 KB" },
  // ];

  const getFilenameExtension = (filename) => {
    return filename.substring(filename.lastIndexOf('.') + 1);
  };

  const getIconForExtension = (filename) => {
    const basename = filename.substring(filename.lastIndexOf('/') + 1);
    const extension = basename.slice(basename.lastIndexOf('.') + 1).toLowerCase();
    
    switch (extension) {
      case 'png':
        return '/assets/imgs/png-icon.png';
      case 'jpg':
      case 'jpeg':
        return '/assets/imgs/jpg-icon.png';
      default:
        return '/assets/svgs/pdf-icon.svg'; 
    }
  }
  const checkboxLabelStyles = {
    ".chakra-checkbox__label": {
      // targeting the label part of the checkbox
      fontSize: "14px",
      color: "#061027",
      fontWeight: "600",
    },
  };
  const TableHeader = [
    { label: "Type" },
    { label: "Date" },
    { label: "Total" },
    { label: " " },
  ];
  const TableHeaderBranch = [
    { label: "Branch Name" },
    { label: "Branch Address" },
    { label: " " },
  ];
  const TableHeaderPartner = [
    { label: "Partner Name" },
    { label: "Branch Name" },
    // { label: "Partner Logo" },
    { label: " " },
  ];
  const [doctorid, setDoctorId] = useState("")
  const [planid, setPlanID] = useState("")
  const [loginid, setLoginId] = useState("")
  const [personalData, setPersonalData] = useState("")
  const [addressData, setAddressData] = useState("")
  const [isDoctor, setIsDoctor] = useState(null)
  const [documentData, setDocumentData] = useState([])
  const [specialityData, setSpecialityData] = useState([])
  const [specialityList, setSpecialityList] = useState([])
  const [subscriptionData, setSubscriptionData] = useState([])
  const [invoiceData, setInvoiceData] = useState([])
  const [appointmentAlertCheck, setAppointmentAlertCheck] = useState(false)
  const [appointmentalert, setAppointmentAlert] = useState("")
  const [messageAlertCheck, setMessageAlertCheck] = useState(false)
  const [messagealert, setMessageAlert] = useState("")
  const [selectedValuesAppoitments, setSelectedValuesAppointments] = useState([]);
  const [appointmentNotif, setAppointmentNotif] = useState(false)
  const [appointmentSMS, setAppointmentSMS] = useState(false)
  const [appointmentEmail, setAppointmentEmail] = useState(false)


  const [appointmentNotifiState, setAppointmentNotifiState] = useState("")
  const [appointmentSmsState, setAppointmentSmsState] = useState("")
  const [appointmentEmailState, setAppointmentEmailState] = useState("")
  const [remindBeforeAppointmentsState, setRemindBeforeAppointmentsState] = useState("")
  const [prefrenceId, setPrefrenceId] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [alerts, setAlerts] = useState('')
  const [documentname, setDocumentName] = useState("")
  const [degreeUploadStage, setDegreeUploadStage] = useState("1")
  const [degreeDocName, setDegreeDocName] = useState("")
  const [degreeDocSize, setDegreeDocSize] = useState("")
  const [errorStyleForDegreeUpload, setErrorStyleForDegreeUpload] = useState(false)
  const [degreeUploadLoadWidth, setDegreeUploadLoadWidth] = useState("0")
  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [AvailabilityData, setAvailabilityData] = useState([])

  
  const [videoVisits, setVideoVisits] = useState([])
  const [clinicVisits, setClinicVisits] = useState([])
  const [groupedVisits, setGroupedVisits] = useState([]);
  

  const [ currentPlanId , setCurrentPlanId] = useState("")

const [refreshPageData, setRefreshPageData] = useState(false)
const [MiniSideBarUpdated, setMiniSideBarUpdated] = useState(false)
const inputRef = useRef(null);
const inputLogoRef = useRef(null);
const inputPartnerLogoRef = useRef(null);
const [imageUrl, setImageUrl] = useState("/assets/imgs/no-image-icon.png");
const [patientimage, setPatientimage] = useState('')
const [subscriptionPlans, setSubscriptionPlans] = useState([])
const [documenttype , setDocumentType] =useState("");
const [clientSecret, setClientSecret] = useState(null);
//const [stripePromise, setStripePromise] = useState(null);

  const { showSuccessToast, showErrorToast } = Toaster();
  const { isLoading, showLoader, dismissLoader } = useLoader();
    // Personal Details Updation (Edit)
    const [editedOldPassword, setEditedOldPassword] = useState("");
    const [editedNewPassword, setEditedNewPassword] = useState("");
    const [editedConfirmPassword, setEditedConfirmPassword] = useState("");
    const [editedFirstName, setEditedFirstName] = useState("");
    const [editedLastName, setEditedLastName] = useState("");
    const [selectedGender, setSelectedGender] = useState('');
    const [editedMobileNo, setEditedMobileNo] = useState('');
    const [editedHospitalAddress, setEditedHospitalAddress] = useState('');

    // Professional Details Updation (Edit)
    const [editedHospitalName, setEditedHospitalName] = useState("");
    const [editedDegreeName, setEditedDegreeName] = useState("");
    const [editedSpecialityName, setEditedSpecialityName] = useState("");
    const [editedExperience, setEditedExperience] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showCustomField, setShowCustomField] = useState(false);

    // Partner States
  const [isSuperUser , setIsSuperUser] = useState(null)

    const [clinics, setClinics] = useState('');
    const [partnerBranchId, setPartnerBranchId] = useState('');
  const [clinicList, setClinicList] = useState([])
  const [clinicBranchList, setClinicBranchList] = useState([])


    
    const badgeBox1 = useRef(null);
    const badgeBox2 = useRef(null);
    const [currentBadge, setCurrentBadge] = useState(badgeBox1);

    //Edit Availability Section
    const [clinicvisit, setclinicVisitCheckbox] = useState(0);
    const [doctorvisit, setDoctorVisitCheckbox] = useState(0);
    const hours = Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, '0')}:00`); // Generates hours from 00:00 to 23:00
    const [SwitchChecked, setSwitchChecked] = useState(false);
    const [DurationofCalls, setDurationofCalls] = useState("");
    const [DurationofCallsType, setDurationofCallsType] = useState("695");
    const [DurationofCallsTypeedit, setDurationofCallsTypeEdit] = useState("");
    const [MinimumNotice, setMinimumNotice] = useState("");
    const [MinimumNoticeType, setMinimumNoticeType] = useState("695");
    const [MinimumNoticeTypeedit, setMinimumNoticeTypeEdit] = useState("");
    const [BeforeCallBuffer, setBeforeCallBuffer] = useState("");
    const [BeforeCallBufferType, setBeforeCallBufferType] = useState("695");
    const [BeforeCallBufferTypeedit, setBeforeCallBufferTypeEdit] = useState("");
    const [AfterCallBuffer, setAfterCallBuffer] = useState("");
    const [AfterCallBufferType, setAfterCallBufferType] = useState("695");
    const [AfterCallBufferTypeedit, setAfterCallBufferTypeEdit] = useState("");
    const [FutureBooking, setFutureBooking] = useState("");
    const [FutureBookingType, setFutureBookingType] = useState("695");
    const [FutureBookingTypeedit, setFutureBookingTypeEdit] = useState("");
    const [FutureBookingsLimit, setFutureBookingsLimit] = useState(0);
    const [activeComponent, setActiveComponent] = useState("");
    const [PlanEndDate, setPlanEndDate] = useState("");

  const [showCropper, setShowCropper] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Logo states
  const [showLogoCropper, setShowLogoCropper] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState('');
const [logoUrl, setLogoUrl] = useState("/assets/imgs/no-image-icon.png");
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  
  const [defaultCountryCode , setDefaultCountryCode] = useState('');
  const [defaultCountryId , setDefaultCountryId] = useState('');
  const [selectedCountryId , setSelectedCountryId] = useState('');

  const [badgeText , setBadgeText] = useState('');
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [resultBranch, setResultBranch] = useState([]);
  const [resultPartner, setResultPartner] = useState([]);
  const screenType = useScreenType()
  // const inputRef = useRef(null);
  
  const [dob, setDob] = useState(null);
  const [IsAddClicked, setIsAddClicked] = useState(false);


  useEffect(() => {
    // Load initial values from localStorage or set defaults
    setDurationofCalls(localStorage.getItem('DurationofCalls') || '');
    setDurationofCallsType(localStorage.getItem('DurationofCallsType') || '695');
    setDurationofCallsTypeEdit(localStorage.getItem('DurationofCallsTypeEdit') || '');
    setMinimumNotice(localStorage.getItem('MinimumNotice') || '');
    setMinimumNoticeType(localStorage.getItem('MinimumNoticeType') || '695');
    setMinimumNoticeTypeEdit(localStorage.getItem('MinimumNoticeTypeEdit') || '');
    setBeforeCallBuffer(localStorage.getItem('BeforeCallBuffer') || '');
    setBeforeCallBufferType(localStorage.getItem('BeforeCallBufferType') || '695');
    setBeforeCallBufferTypeEdit(localStorage.getItem('BeforeCallBufferTypeEdit') || '');
    setAfterCallBuffer(localStorage.getItem('AfterCallBuffer') || '');
    setAfterCallBufferType(localStorage.getItem('AfterCallBufferType') || '695');
    setAfterCallBufferTypeEdit(localStorage.getItem('AfterCallBufferTypeEdit') || '');
    setFutureBooking(localStorage.getItem('FutureBooking') || '');
    setFutureBookingType(localStorage.getItem('FutureBookingType') || '695');
    setFutureBookingTypeEdit(localStorage.getItem('FutureBookingTypeEdit') || '');
    setFutureBookingsLimit(JSON.parse(localStorage.getItem('FutureBookingsLimit')) || 0);
    setSwitchChecked(JSON.parse(localStorage.getItem('SwitchChecked')) || false);
  }, []);


  useEffect(() => {
    var docId = localStorage.getItem("loginid")
    setDoctorId(docId)
    getDoctorProfile();
  }, [refreshPageData])
  var inputForGet = {

  }

  const [usertypeid , setusertypeid] = useState("")
  const [feeStructureArray , setFeeStructureArray] = useState([]);
  const [isGlobalAdmin , setIsGlobalAdmin] = useState(null)

  const getDoctorProfile = async () => {
    showLoader();
    try {
      var result = await getProfile(inputForGet);
      if (result && result.ProfileOutput) {

        setPersonalData(result.ProfileOutput)
        if(result.ProfileOutput.hospitalname){
        localStorage.setItem("hospitalname", result.ProfileOutput.hospitalname);
        }
        if(result.ProfileOutput.preferredcurrency){
          localStorage.setItem("preferredcurrency", result.ProfileOutput.preferredcurrency);
          }
        setIsDoctor(result.ProfileOutput.isdoctor)
        setIsGlobalAdmin(result.ProfileOutput.isglobaladmin)
        setIsSuperUser(result.ProfileOutput.issuperuser)

        let usertypeid = localStorage.getItem("usertypeid")
        setusertypeid(usertypeid)
        let countryId = result.ProfileOutput.countryid;
        // console.log('countryId: ', countryId)
        // if (countryId == "229") {
        //   setDefaultCountryCode("+1")
        //   setSelectedCountryId(countryId)
        // } else if (countryId == "1") {
        //   setDefaultCountryCode("+93")
        //   setSelectedCountryId(countryId)
        // } else if (countryId == "95") {
        //   setDefaultCountryCode("+91")
        //   setSelectedCountryId(countryId)
        // }else{
        //   setDefaultCountryCode("+1")
        //   setSelectedCountryId(countryId)
        // }
        let countryCode = await getCountryCodeFromCountriesList(countryId);
        // console.log('countryCode: ', countryCode)f
        setDefaultCountryCode(countryId) // 229
        setSelectedCountryId(countryCode)// +1
        localStorage.setItem('countrycode', countryId) //229
        localStorage.setItem('countryid', countryCode) // +1
        // if (countryId == "+1") {
        //   setDefaultCountryCode("+1")
        //   setSelectedCountryId("229")
        // } else if (countryId == "+93") {
        //   setDefaultCountryCode("+93")
        //   setSelectedCountryId("1")
        // } else if (countryId == "+91") {
        //   setDefaultCountryCode("+91")
        //   setSelectedCountryId("95")
        // }
     
      }

      if(result && result.firstAddressDetail){
        // Step 1: Get the doctoraddress array from localStorage
        let doctorAddressArray = JSON.parse(localStorage.getItem('doctoraddress')) || [];
          
        // Step 2: Update the array with result.firstAddressDetail
        doctorAddressArray.push(result.firstAddressDetail);  // or you can modify based on your logic
        
        // Step 3: Set the updated array back to localStorage
        localStorage.setItem('doctoraddress', JSON.stringify(doctorAddressArray));
        setAddressData(result.firstAddressDetail);

      }

      if(result && result.feeStructure){
        setFeeStructureArray(result.feeStructure)

      }
      if(result && result.resultbranch){
        setResultBranch(result.resultbranch)

      }
      if(result && result.resultpartner){
        setResultPartner(result.resultpartner)

      }
      // ______________________________________________________________
      if (result && result.DocumentOutput) {
        setDocumentData(result.DocumentOutput || [])
      }
      
      if (result && result.SpecialityOutput) {
        setSpecialityData(result.SpecialityOutput || []);
      
        if (result.SpecialityOutput && result.SpecialityOutput.length > 0) {
          const selectedSpecialties = result.SpecialityOutput.map((speciality) => speciality.specialtyid);
      
          // Check if specialtyid 16 (Other) is selected
          const selectedOtherSpecialtyName = result.SpecialityOutput.find(speciality => speciality.specialtyid === 16);
          
          // If specialityid 16 is found, retrieve its othervalue
          const otherVal = selectedOtherSpecialtyName ? selectedOtherSpecialtyName.othervalue : '';
      
          // Set selected options (this should always happen regardless of specialtyid)
          setSelectedOptions(selectedSpecialties);
          
          // Check if specialtyid 16 (Other) is selected and handle the custom field display
          if (selectedOtherSpecialtyName) {
            setShowCustomField(true);
            setEditedSpecialityName(otherVal);  // Set custom speciality name
          } else {
            setShowCustomField(false);
            setEditedSpecialityName('');  // Reset the custom speciality field
          }
      
          // Store the selected specialties in localStorage
          localStorage.setItem('selectedSpecialities', JSON.stringify(selectedSpecialties));
        }
      }
      
      // useEffect(() => {
      // }, [specialityData]);

      if (result && result.SubscriptionOutput) {
        setSubscriptionData(result.SubscriptionOutput)
      }
      if (result && result.InvoiceOutput) {
        setInvoiceData(result.InvoiceOutput)
      }
     
      // Share Badge Grouping of Availability --------- Functionality Start
      if (result && result.availabilitydata) {         
        //  setVideoVisits(result.availabilitydata.availtimings.availablity.videovisit)
  
        const groupedClicinal = result?.availabilitydata?.availtimings?.availablity?.clinicalvisits?.reduce((acc, visit) => {
          if (visit.isavailable == 1 && visit.timings.length > 0) { // Ensure availability and valid timings array
            visit.timings.forEach(timing => {
              const { fromtime, totime } = timing;
        
              if (fromtime && totime) { // Only include if both fromtime and totime are not null
                const timeKey = `${fromtime}-${totime}`;
                
                if (!acc[timeKey]) {
                  acc[timeKey] = { time: timing, days: [] };
                }
                acc[timeKey].days.push(visit.day.substring(0, 3));
              }
            });
          }
        
          return acc;
        }, {});

        const groupedVideo = result?.availabilitydata?.availtimings?.availablity?.videovisit?.reduce((acc, visit) => {
          if (visit.isavailable == 1 && visit.timings.length > 0) { // Ensure availability and valid timings array
            visit.timings.forEach(timing => {
              const { fromtime, totime } = timing;
        
              if (fromtime && totime) { // Only include if both fromtime and totime are not null
                const timeKey = `${fromtime}-${totime}`;
                
                if (!acc[timeKey]) {
                  acc[timeKey] = { time: timing, days: [] };
                }
                acc[timeKey].days.push(visit.day.substring(0, 3));
              }
            });
          }
        
          return acc;
        }, {});

        if(groupedClicinal){
          if(Object.keys(groupedClicinal).length > 0){  
          const groupedArray = Object.values(groupedClicinal);
          setGroupedVisits(groupedArray);
           }
        } else{
          if(groupedVideo){
            
          const groupedArray = Object.values(groupedVideo);
          setGroupedVisits(groupedArray);
        }
        }
  
        //   setAvailabilityData(result.availabilitydata)
        //   //console.log(result.availabilitydata, "RESULT availability")
        //   setclinicVisitCheckbox(result.availabilitydata.doctortimingsetup && result.availabilitydata.doctortimingsetup.isclinicalvisit);
        // setDoctorVisitCheckbox(result.availabilitydata.doctortimingsetup && result.availabilitydata.doctortimingsetup.isvideocall);
      
        } 
        // Share Badge Grouping of Availability --------- Functionality End

    //    //console.log(invoiceData, "invoiceDATAA")
    // } catch (error) {
    //   console.log(error, "Something went wrong in doctor profile")
    // }

    if (result && result.availabilitydata) {
      // Replace null times with default times "09:00" and "18:00", and filter out invalid timings
      const updatedVideoVisits = result.availabilitydata.availtimings.availablity.videovisit.map(visit => ({
          ...visit,
          timings: visit.timings
              .filter(time => time.fromtime !== null && time.totime !== null)
              .map(time => ({
                  fromtime: time.fromtime || "09:00",
                  totime: time.totime || "18:00",
                  id: time.id
              }))
      }));
  
      const updatedClinicalVisits = result.availabilitydata.availtimings.availablity.clinicalvisits.map(visit => ({
          ...visit,
          timings: visit.timings
              .filter(time => time.fromtime !== null && time.totime !== null)
              .map(time => ({
                  fromtime: time.fromtime || "09:00",
                  totime: time.totime || "18:00",
                  id: time.id
              }))
      }));
  
      setVideoVisits(updatedVideoVisits);
      setClinicVisits(updatedClinicalVisits);
  
      const grouped = updatedVideoVisits.reduce((acc, visit) => {
          const { fromtime, totime } = visit.timings[0] || {};
          if (fromtime && totime) { // Only include if both fromtime and totime are not null
              const timeKey = `${fromtime}-${totime}`;
              if (!acc[timeKey]) {
                  acc[timeKey] = { time: visit.timings[0], days: [] };
              }
              acc[timeKey].days.push(visit.day.substring(0, 3));
          }
          return acc;
      }, {});
  
      // const groupedArray = Object.values(grouped);
      // setGroupedVisits(groupedArray);
      setAvailabilityData(result.availabilitydata);
      setSettingsData(result && result.settingsdata)
      setclinicVisitCheckbox(result.availabilitydata.doctortimingsetup && result.availabilitydata.doctortimingsetup.isclinicalvisit);
      setDoctorVisitCheckbox(result.availabilitydata.doctortimingsetup && result.availabilitydata.doctortimingsetup.isvideocall);
     
    }
  } catch (error) {
    console.log(error, "Something went wrong in doctor profile");
  }finally{
    dismissLoader();
  }
  }
  // Input for Password Change
  const openUpdatePasswordModal = () => {
    changePassword.current.openModal()
  }
  
  const handleChangeOldPassword = (event) => {
    setEditedOldPassword( event.target.value);
  };
  const handleChangeNewPassword = (event) => {
    setEditedNewPassword( event.target.value);
  };
  const handleChangeConfirmPassword = (event) => {
    setEditedConfirmPassword( event.target.value);
  };
  const handlePasswordChanges = () => {
    if (!editedOldPassword.trim() ) {
      showErrorToast("Please enter your old password");
      return;
    }
    if (!editedNewPassword.trim() ) {
      showErrorToast("Please enter new password");
      return;
    }
    if (!editedConfirmPassword.trim() ) {
      showErrorToast("Please enter confirm password");
      return;
    }

    if(editedNewPassword.length < 6 || editedConfirmPassword.length < 6){
      showErrorToast("Password should contain minimum 6 characters!")
    }
    if( editedNewPassword.trim() !== editedConfirmPassword.trim()){
      
      showErrorToast("Password does not match!")
    }

    savePasswordChangeData(passwordInput)
  };
  let passwordInput = {
    // 'loginid': doctorid,
    'password': editedNewPassword,
    'oldpassword': editedOldPassword,
  }
  const savePasswordChangeData = async () => {
    try {
      var result = await resetPassword(passwordInput);
      // console.log(result, 'output')

      if (result && result.output.result === 'success') {
        showSuccessToast("Profile Updated Successfully");
        changePassword.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  
  
  // Input for Personal Changes
  let nameInput = {
    'firstname': editedFirstName,
    'lastname': editedLastName,
    'fullname': editedFirstName + " " + editedLastName,
    doctorid,
  }
  let genderInput = {
    'gender': selectedGender,
    doctorid,
  }
 
  // Name Edit
  const firstNameChange = (event) => {
    const inpValue = event.target.value;
    // const cleanVal = inpValue.replace(/[^a-zA-Z\s]/g, '');
    const cleanVal = validationToPreventDBErrors(inpValue);
    setEditedFirstName(cleanVal);
  };
  const lastNameChange = (event) => {
    const inpValue = event.target.value;
    // const cleanVal = inpValue.replace(/[^a-zA-Z\s]/g, '');
    const cleanVal = validationToPreventDBErrors(inpValue);
    setEditedLastName(cleanVal);
  };
  const handleNameChanges = () => {
    if (!editedFirstName.trim() ) {
      showErrorToast("Please specify your first name");
      return;
    }

    // console.log('Edited First Name:', editedFirstName);
    // console.log('Edited Last Name:', editedLastName);
    saveNameData(nameInput)
  };
  const handleNameEdit = (fname, lname) => {
    setEditedFirstName(fname);
    setEditedLastName(lname);
    editName.current.openModal()
  }
  // Personal details edit Changes
  const saveNameData = async () => {
    try {
      var result = await saveNameChanges(nameInput);

      //setting firstname and lastname to local, to show updated name on the dashboard :-
      saveFirstName(nameInput.firstname)
      saveLastName(nameInput.lastname)

      // console.log(result, 'output')

      if (result && result.output.success === 1) {
        showSuccessToast("Profile Updated Successfully");
        editName.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  
  // Gender Edit
  const genderChange = (event) => {
    setSelectedGender(event);
  };
  const opengendermodel = ()=>{
    setSelectedGender(personalData.gender?.toString())
    editGender.current.openModal()
  }
  const handleGenderChanges = () => {
    if (selectedGender === '1') {
      // console.log('Female')
    } else if (selectedGender === '2') {
      // console.log('Male')
    } else {
      // console.log('Other')
    }
    saveGenderData(genderInput)
  };
  // Gender Changes
  const saveGenderData = async () => {
    try {
      var result = await saveGenderChanges(genderInput);
      // console.log(result, 'output')

      if (result && result.output.success === 1) {
        showSuccessToast("Profile Updated Successfully");
        editGender.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  // Phone Number Edit
  const mobileChange = (event) => {
    const inpValue = event.target.value;
    
     const cleanVal = inpValue.replace(/[^0-9]/g, '').slice(0, 10)
    const maxLength = 10; 
    if (cleanVal.length <= maxLength) {
      setEditedMobileNo( cleanVal );
    }
    var countryId = localStorage.getItem("countrycode")
    var countryCode = localStorage.getItem("countryid")
    
    setDefaultCountryCode(countryCode)
    setSelectedCountryId(countryId)
    // var countryId = localStorage.getItem("countryid")
    //     if (countryId == "+1") {
    //       setDefaultCountryCode("+1")
    //       setSelectedCountryId("229")
    //     } else if (countryId == "+93") {
    //       setDefaultCountryCode("+93")
    //       setSelectedCountryId("1")
    //     } else if (countryId == "+91") {
    //       setDefaultCountryCode("+91")
    //       setSelectedCountryId("95")
    //     }

  }
  const handleMobileEdit = (personalData) => {
    // console.log('PersonalData: ', personalData)
    setDefaultCountryCode(personalData.countrycode) //229
    setEditedMobileNo(personalData.mobileno)
    setDefaultCountryId(personalData.countryid)  //+1
    editPhone.current.openModal()
  }
  
  const handleMobileChanges = () => {
    if(!editedMobileNo.trim() ){
      showErrorToast("Phone number required")
      return;
    }

    var countryId = localStorage.getItem("countrycode")
    var countryCode = localStorage.getItem("countryid")

    // Check if values are unchanged
    const isPhoneUnchanged = editedMobileNo === personalData.mobileno;
    const isCountryUnchanged = countryCode === defaultCountryCode ;

    // Show a toaster if nothing has changed
    if (isPhoneUnchanged && isCountryUnchanged) {
        showErrorToast("No changes made to phone number or country code.");
        return;
    }
    
    // setDefaultCountryCode(countryCode)
    // setDefaultCountryId(countryCode)
    // setSelectedCountryId(countryId)
    // var countryId = localStorage.getItem("countryid")

    //     if (countryId == "+1") {
    //       setDefaultCountryCode("+1")
    //       setSelectedCountryId("229")
    //     } else if (countryId == "+93") {
    //       setDefaultCountryCode("+93")
    //       setSelectedCountryId("1")
    //     } else if (countryId == "+91") {
    //       setDefaultCountryCode("+91")
    //       setSelectedCountryId("95")
    //     }

    saveMobileData()
  }
  // Personal details edit Changes
  const saveMobileData = async () => {
    if(editedMobileNo.length < 10){
      showErrorToast('Invalid Number')
      return;
    }
    try {
      //here
      var countryId = localStorage.getItem("countrycode")
      var countryCode = localStorage.getItem("countryid")
      

      let phoneInput = {
        'mobileno': editedMobileNo,
        'countryid': countryId,
        'countrycode': countryCode,
        doctorid,
      }
      var result = await saveMobileChanges(phoneInput);
      // console.log(result, 'output')
      if(result && result.output.message === "Mobile number already exists"){
        showErrorToast('Mobile number already exists')
        // editPhone.current.closeModal();
        return;
      }

      if (result && result.output.success === 1) {            
        //localStorage.setItem("currency", result.output.doctorcurrenyname);
        showSuccessToast("Profile Updated Successfully");
        editPhone.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }

  // update DOB code -------------
  const handleDOBEdit = (personalData) => {
    if(personalData.dob == null){
      setDob('')
    }else{
      let DobDefault = formatDate_DD_MM_YYYY(personalData.dob) 
      setDob(DobDefault)
    }
    editDOB.current.openModal()
  }
  const handleDobChange = (date) => {
    const formattedDate = format(date, "dd-MM-yyyy");
    setDob(formattedDate);
  }
  const updateDobCall = async () => {
    try {
      let formatedDOB = formatDate_YYYY_MM_DD(dob)

      let phoneInput = {
        "dob":formatedDOB,
        doctorid,
      }
      var result = await saveMobileChanges(phoneInput);

      if (result && result.output.success === 1) {
        showSuccessToast("Date of Birth Updated Successfully!");
        editDOB.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  //-----------------

  const [address1 , setAddress1] = useState("")
  const [address2 , setAddress2] = useState("")
  const [pincode , setPincode] = useState("")
  const [city , setCity] = useState("")
  const [state , setState] = useState("")
  const [country , setCountry] = useState("")
  const [latitude , setLatitude] = useState(null)
  const [longitude , setLongitude] = useState(null)
  // Address Edit Changes
  let AddressInput = {
    address1,
    address2,
    pincode,
    city,
    state,
    country,
    doctorid,
    
    
    //"countrycode": "99",
    latitude,
    longitude,
  }
  const AddressChange = (event) => {
    setEditedHospitalAddress(event.target.value);
  };
  const handleAddressChanges = () => {
    if(!address1){
      showErrorToast("Address is required!")
      return;
    }
    // console.log('Edited Hospital Name:', editedHospitalName);
    saveAddressData(AddressInput)
  };
  const handleAddressEdit = (addres) => {
   // console.log(addres)
    setAddress1(addres.address1)
    setAddress2(addres.address2)
    setCountry(addres.country)
    setState(addres.state)
    setPincode(addres.pincode)
    setCity(addres.city)
    // setLatitude(addres.latitude)
    // setLongitude(addres.longitude)
    // setEditedHospitalAddress(addres);
    editAddress.current.openModal()
  }
  // Professional details edit Changes
  const saveAddressData = async () => {
    try {
      var result = await saveAddressChanges(AddressInput);
      // console.log("Address Input: ", AddressInput)
      // console.log(result, 'output')

      if (result && result.output.success === 1) {
        showSuccessToast("Personal Details updated successfully");
        editAddress.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }


  // ---------------------------------------------------------
  // Input for Professional Changes
  let hospitalInput = {
    'hospitalname': editedHospitalName,
    doctorid,
  }
  
  const hospitalNameChange = (event) => {
    const inpValue = event.target.value;
    const cleanVal = inpValue.replace(/[^a-zA-Z0-9-\s]/g, '');
    const maxLen = 80;
    if(cleanVal.length <= maxLen){
      setEditedHospitalName(cleanVal);
    }
  };
  const handleHospitalChanges = () => {
    if(!editedHospitalName.trim() ){
      showErrorToast("Hospital name required")
      return;
    }
    // console.log('Edited Hospital Name:', editedHospitalName);
    saveHospitalData(hospitalInput)
  };
  const handleHospitalEdit = (hosName) => {
    setEditedHospitalName(hosName);
    editHospital.current.openModal()
  }
  // Professional details edit Changes
  const saveHospitalData = async () => {
    try {
      var result = await saveHospitalChanges(hospitalInput);
      // console.log(result, 'output')

      if (result && result.output.success === 1) {
        showSuccessToast("Professional Details updated successfully");
        editHospital.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
// Degree Name
const sanitizeDegreeName = (degName) => {
  if (typeof degName !== 'string') {
    return [];
  }

  return degName
    .split(',')
    .map((q) => q.trim())
    .filter((q) => q.length > 0)
    .join(', ');
};
  // let degreeInput = {
  //   'qualification': editedDegreeName,
  //   doctorid,
  // }
  const degreeNameChange = (event) => {
    const inpValue = event.target.value;
    const cleanVal = inpValue.replace(/[^a-zA-Z\s]/g, '');
    const maxLen = 80;
    if(cleanVal.length <= maxLen){
      setEditedDegreeName(cleanVal);
    }
  };

  
  const [ selectedDegreesArray , setSelectedDegreesArray] = useState([])

  const handleDegreeSelect = (selectedOption) => {
     // Create a new degree object based on the selected option
  const newDegree = {
    degreeid: selectedOption.id,  // Assume `id` is the degree ID in selectedOption
    othervalue: selectedOption.label  // Assume `label` is the degree name in selectedOption
  };

  // Update `selectedDegreesArray` to include the new degree
  setSelectedDegreesArray((prevArray) => {
    // Check if the degree already exists in the array to avoid duplicates
    const isExisting = prevArray.some(degree => degree.degreeid === newDegree.degreeid);
    
    if (!isExisting) {
      return [...prevArray, newDegree]; // Add the new degree if it doesn't already exist
    }
    return prevArray; // Return the original array if it's already present
  });

      setEditedDegreeName(selectedOption.label);

  };
  const handleDegreeChanges = () => {
  //   const sanitizedDegreeName = sanitizeDegreeName(editedDegreeName);

  // if (!sanitizedDegreeName.trim()) {
  //   showErrorToast("Degree required");
  //   return;
  // }

  if(selectedConditions.length <= 0 ){
    showErrorToast("Please Select a Degree!");
       return;
  }
  const degreeInput = {
    // 'qualification': sanitizedDegreeName,
    degree: selectedConditions.map(item => ({
      "degreeid": item.id,
      "degreename": item.label,
      "othervalue": null
    })),
    doctorid,
  };

    // console.log('Edited Degree Name:', editedDegreeName);
    saveDegreeData(degreeInput)
  };
  const [degreeOptions, setDegreeOptions] = useState([]);
  
  const addConditionModalInsideEdit = React.useRef();
  var [conditionsList, setConditionsList] = useState(null);
  const handleDegreeEdit = async (degName) => {
    showLoader();
    setSearchValue("");
    const degreesList = await getDefaultData({ type: "degree" });

    const options = degreesList?.map((item) => ({
        label: item.degreename,
        id: item.id,
    }));

    if (options) {
        setConditionsList(options);
        // setDegreeOptions(options)
    }

    setEditedDegreeName(degName || ""); // Ensure degName is at least an empty string

    // Split the degName string only if it's defined and not empty
    const initialSelectedConditions = (degName || "")
        .split(", ")
        .map((name) => options?.find((option) => option.label === name))
        .filter((condition) => condition); // Remove any undefined entries if no match found

    setSelectedConditions(initialSelectedConditions);

    addConditionModalInsideEdit.current.openRight();
    dismissLoader();
};
  const saveDegreeData = async (degreeInput) => {
    showLoader();
    try {
      var result = await saveDegreeChanges(degreeInput);
      // console.log(result, 'output')

      if (result && result.output.success === 1) {
        showSuccessToast("Professional Details updated successfully");
        editDegree.current.closeModal();
        addConditionModalInsideEdit.current.closeRight();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
    dismissLoader();
  }
// Speciality Section
// useEffect(() => {
//   handleOptionToggle()
// }, [])
const handleOptionToggle = (text, name) => {
  if (text === 16 && name === 'Other') {
    setShowCustomField(!showCustomField);
  }

  setSelectedOptions(prevOptions => {
    const isSelected = prevOptions.includes(text);
    const updatedOptions = isSelected 
      ? prevOptions.filter(option => option !== text) 
      : [...prevOptions, text];
    
    // Store updated selected options in local storage
    localStorage.setItem('selectedSpecialities', JSON.stringify(updatedOptions));

    return updatedOptions;
  });
};

useEffect(() => {
  // Retrieve selected specialities from local storage
  const savedSpecialities = JSON.parse(localStorage.getItem('selectedSpecialities'));
  
  if (savedSpecialities) {
    setSelectedOptions(savedSpecialities);

    // If "Other" is one of the saved specialities, show the custom input field
    if (savedSpecialities.includes(16)) {
      setShowCustomField(true);
      const customSpeciality = JSON.parse(localStorage.getItem('customSpecialityName'));
      if (customSpeciality) {
        setEditedSpecialityName(customSpeciality);
      }
    }
  }
}, []);


let specialityInput = {
  "doctorid":"",
  "speciality": selectedOptions.map((option) => {
    if (option === 16) {  // Speciality ID 16 represents "Other"
      return {
        specialtyid: option,
        specialityname: 'Other',
        othervalue: editedSpecialityName // Use the custom edited name
        
      };
    } 
    // else {
      // Return the selected specialities as normal
      const selectedSpeciality = specialityList.find(speciality => speciality.id === option);
      return {
        specialtyid: option,
        specialityname: selectedSpeciality?.specialityname // Use the speciality name from the list
      };
    // }
  })
}
const specialityNameChange = (e) => {
  const inpValue = e.target.value;
  // const cleanVal = inpValue.replace(/[^a-zA-Z\s,]/g, '');
  const cleanVal = validationToPreventDBErrors(inpValue);
  setEditedSpecialityName(cleanVal);

  // Store the custom speciality name in local storage
  localStorage.setItem('customSpecialityName', JSON.stringify(cleanVal));
};

const handleCustomSpecialitySubmit = () => {
  if (!editedSpecialityName.trim()) {
    showErrorToast("Please enter a valid speciality");
    return;
  }
  saveSpecialityData(specialityInput)
};
const handleSpecialityChanges = () => {
  
  // console.log('Edited Speciality :', editedSpecialityName);
  saveSpecialityData(specialityInput)
};
const handleSpecialityEdit = () => {
  // setEditedSpecialityName(splty);
  FetchSpecialities();
  editSpecialization.current.openModal()
}
const saveSpecialityData = async () => {
  // if(!editedSpecialityName.trim() || (!selectedOptions || selectedOptions.length===0)){
  //   // showErrorToast("Speciality required")
  //   showErrorToast("Please select Specialization")
  //   return;
  // }

  if (!selectedOptions || selectedOptions.length === 0) {
    showErrorToast("Please select a specialization");
    return;
  }

  if (selectedOptions.includes(16) && (!editedSpecialityName || !editedSpecialityName.trim())) {
    showErrorToast("Please enter a valid custom speciality");
    return;
  }
  try {
    var result = await saveSpecialityChanges(specialityInput);
    // console.log(result, 'output')

    if (result && result.output.success === 1) {
      showSuccessToast("Professional Details updated successfully");
      // editCustomSpeciality.current.closeModal();
      editSpecialization.current.closeModal();
      getDoctorProfile();
    }

  } catch (error) {
    console.log(error, "Something went wrong in doctor profile")
  }
}
 const FetchSpecialities = async ()=>{
   showLoader()
   try {
     var result = await FetchDoctorSpecialities({
      "type":"speciality"
     });
    //  console.log('FetchSpecialities: ', result)
     if (result != null) {
       setSpecialityList(result)
      // const updatedSpecialityList = result.map((speciality) => {
      //   if (speciality.specialtyid === 16 && speciality.editedSpecialityName) {
      //     // If specialtyid is 16, replace "Other" with the custom name
      //     return {
      //       ...speciality,
      //       specality: speciality.editedSpecialityName // Use custom name instead of "Other"
      //     };
      //   }
      //   return speciality;
      // });

      // setSpecialityList(updatedSpecialityList); // Update the state with the custom speciality
     }
     dismissLoader();
   } catch (error) {
     console.log(error, "Something went wrong in doctor profile")
   }
 }

//  const SubmitSpeciality = async () => {
//    showLoader();
//    try{
//     const result = updateProfile({
//       "doctorid":"",
//       speciality: selectedOptions.map(option => {
//           return { specialtyid: option };
//       })
//     })
//     if(result && result.output.success === 1){
//       // setSpecialityData(result)
//       showSuccessToast("Professional Details updated successfully");
//       editSpecialization.current.closeModal();
//       getDoctorProfile();
//     }
//     dismissLoader();
//   } catch(error){
//     console.error('kdslfj')
//   } finally{
//     dismissLoader();
//   }
//  }

// Experience
  let experienceInput = {
    'yearsofexperience': editedExperience,
    doctorid,
  }
  const experienceChange = (event) => {
    const inpValue = event.target.value;
    
    // Regular expression to match valid input: up to 2 digits or 2 digits followed by a decimal and 2 more digits
    const validInput = /^\d{0,2}(\.\d{0,2})?$/;

    // Check if the input matches the pattern
    if (validInput.test(inpValue)) {
        setEditedExperience(inpValue);
    }
  };
  const handleExpSelect = (selectedOption) => {
    setEditedExperience(selectedOption);
  };
  const [personConsultationFee, setPersonConsulatationFee ] = useState("")
  const [onlineConsulationFee, setOnlineConsultationFee ] = useState("")
  const [remoteChatFee, setRemoteChatFee ] = useState("")
  const handleFeeChange = (event , feeType) => {
    const inpValue = event.target.value;
    
    // Regular expression to match valid input: up to 2 digits or 2 digits followed by a decimal and 2 more digits
    const validInput = /^\d{0,6}(\.\d{0,2})?$/;

    // Check if the input matches the pattern
    if (validInput.test(inpValue) && feeType == "Appointment") {
        setOnlineConsultationFee(inpValue);

      }else if (validInput.test(inpValue) &&feeType == "Chat"){

        setRemoteChatFee(inpValue);
      }else if (validInput.test(inpValue) &&feeType == "Person"){

        setPersonConsulatationFee(inpValue);
      }
    
  };
  const handleExperienceChanges = () => {
    if(!editedExperience.trim()){
      showErrorToast("please enter experience")
      return;
    }
    // console.log('Edited Experience :', editedExperience);
    saveExperienceData(experienceInput)
  };
  useEffect(() => {
    // if(feeOrCurrency == "Currency"){
            
      const getCurrency = localStorage.getItem("currency");
      setSelectedCurrency(getCurrency)
          // showSuccessToast("Currency Updated Successfully!");
        // }
  }, [])
  const fee_Currency_UpdateFunc = async (feeOrCurrency) => {
    if(feeOrCurrency == "Fee Structure"){
    if(!personConsultationFee){
      showErrorToast("Please Enter In-Person Consultation Fee!");
      return false;
    }
    if(!onlineConsulationFee){
      showErrorToast("Please Enter Online Consultation Fee!");
      return false;
    }
    if(!remoteChatFee){
      showErrorToast("Please Enter Remote Chat Fee!");
      return false;
    }
  }
    let feestructure  = [
      {
        "fee": personConsultationFee,
        "paymenttypeid": 4
      },
      {
        "fee": onlineConsulationFee,
        "paymenttypeid": 2
      },
      {
        "fee": remoteChatFee,
        "paymenttypeid": 3
      }
    ]
    let inputForFee;
    if(feeOrCurrency == "Fee Structure"){
       inputForFee = {
        "feestructure": feestructure,
      }
    }else if(feeOrCurrency == "Currency"){
      inputForFee = {
        "preferredcurrency":selectedCurrency
      }
    }
    


    const commonFunc = async(feeOrCurrency)=>{
      try {
        showLoader();
        var result = await saveExperienceChanges(inputForFee);
        // console.log(result, 'output')
  
        if (result && result.output.success === 1) {
          if(feeOrCurrency == "Currency"){
            
        localStorage.setItem("currency", selectedCurrency);
            showSuccessToast("Currency Updated Successfully!");
          }else if(feeOrCurrency == "Fee Structure"){
            showSuccessToast("Fee Structure Updated Successfully!");
          }
          feeStructureModalRef.current.closeModal();
          currencyModalRef.current.closeModal();
          getDoctorProfile();
        }
        dismissLoader();
      } catch (error) {
        console.log(error, "Something went wrong in doctor profile")
      }
    }

    commonFunc(feeOrCurrency);
    
  
    
  };
  const personConsulationFee_Currency_UpdateFunc = async (feeOrCurrency) => {
    if(feeOrCurrency == "Fee Structure"){
    if(!personConsultationFee){
      showErrorToast("Please Enter In-Person Consultation Fee!");
      return false;
    }
  }
    let feestructure  = [
      {
        "fee": personConsultationFee,
        "paymenttypeid": 4
      },
    ]
    let inputForFee;
    if(feeOrCurrency == "Fee Structure"){
       inputForFee = {
        "feestructure": feestructure,
      }
    }else if(feeOrCurrency == "Currency"){
      inputForFee = {
        "preferredcurrency":selectedCurrency
      }
    }
    


    const commonFunc = async(feeOrCurrency)=>{
      try {
        showLoader();
        var result = await saveExperienceChanges(inputForFee);
        // console.log(result, 'output')
  
        if (result && result.output.success === 1) {
          if(feeOrCurrency == "Fee Structure"){
            showSuccessToast("Person Consulation Fee Updated Successfully!");
          }
          personConsultationFeeModalRef.current.closeModal();
          getDoctorProfile();
        }
        dismissLoader();
      } catch (error) {
        console.log(error, "Something went wrong in doctor profile")
      }
    }

    commonFunc(feeOrCurrency);
  };
  const onlineConsulationFee_Currency_UpdateFunc = async (feeOrCurrency) => {
    if(feeOrCurrency == "Fee Structure"){
    if(!onlineConsulationFee){
      showErrorToast("Please Enter Online Consultation Fee!");
      return false;
    }
    // if(!remoteChatFee){
    //   showErrorToast("Please Enter Chat Fee!");
    //   return false;
    // }
  }
    let feestructure  = [
      {
        "fee": onlineConsulationFee,
        "paymenttypeid": 2
      },
      // {
      //   "fee": remoteChatFee,
      //   "paymenttypeid": 3
      // }
    ]
    let inputForFee;
    if(feeOrCurrency == "Fee Structure"){
       inputForFee = {
        "feestructure": feestructure,
      }
    }else if(feeOrCurrency == "Currency"){
      inputForFee = {
        "preferredcurrency":selectedCurrency
      }
    }
    


    const commonFunc = async(feeOrCurrency)=>{
      try {
        showLoader();
        var result = await saveExperienceChanges(inputForFee);
        // console.log(result, 'output')
  
        if (result && result.output.success === 1) {
          if(feeOrCurrency == "Fee Structure"){
            showSuccessToast("Online Consulation Fee Updated Successfully!");
          }
          // feeStructureModalRef.current.closeModal();
          onlineConsultationFeeModalRef.current.closeModal();
          // currencyModalRef.current.closeModal();
          getDoctorProfile();
        }
        dismissLoader();
      } catch (error) {
        console.log(error, "Something went wrong in doctor profile")
      }
    }

    commonFunc(feeOrCurrency);
  };
  const remoteChatFee_Currency_UpdateFunc = async (feeOrCurrency) => {
    if(feeOrCurrency == "Fee Structure"){
    if(!remoteChatFee){
      showErrorToast("Please Enter Remote Chat Fee!");
      return false;
    }
  }
    let feestructure  = [
      // {
      //   "fee": onlineConsulationFee,
      //   "paymenttypeid": 2
      // },
      {
        "fee": remoteChatFee,
        "paymenttypeid": 3
      }
    ]
    let inputForFee;
    if(feeOrCurrency == "Fee Structure"){
       inputForFee = {
        "feestructure": feestructure,
      }
    }
    

    const commonFunc = async(feeOrCurrency)=>{
      try {
        showLoader();
        var result = await saveExperienceChanges(inputForFee);
        // console.log(result, 'output')
  
        if (result && result.output.success === 1) {
           if(feeOrCurrency == "Fee Structure"){
            showSuccessToast("Remote Chat Fee Updated Successfully!");
          }
          remoteChatFeeModalRef.current.closeModal();
          getDoctorProfile();
        }
        dismissLoader();
      } catch (error) {
        console.log(error, "Something went wrong in doctor profile")
      }
    }

    commonFunc(feeOrCurrency);
  };
  const experienceInYears = [];

  for (let i = 0; i <= 80; i++) {
    experienceInYears.push(String(i));
  }
  const handleExperienceEdit = (exp) => {
    setEditedExperience(exp);
    editYrsofexperience.current.openModal()
  }
  const openFeeStructureModal = (exp) => {
    //setEditedExperience(exp);
    if (feeStructureArray && feeStructureArray.length > 0) {
      const feeObj = feeStructureArray.find(feeObj => feeObj.paymenttypeid === 2); 
      const feeObj2 = feeStructureArray.find(feeObj => feeObj.paymenttypeid === 3); 
      const feeObj3 = feeStructureArray.find(feeObj => feeObj.paymenttypeid === 4); 
      if (feeObj) {
        setOnlineConsultationFee(formatToDecimal(feeObj.fee));
      }
      if (feeObj2) {
        setRemoteChatFee(formatToDecimal(feeObj2.fee));
      }
      if (feeObj3) {
        setPersonConsulatationFee(formatToDecimal(feeObj3.fee));
      }
    }
    
    feeStructureModalRef.current.openModal()
  }
  const openPersonConsultationFeeModal = () => {
    if (feeStructureArray && feeStructureArray.length > 0) {
      const feeObj3 = feeStructureArray.find(feeObj => feeObj.paymenttypeid === 4);
      if (feeObj3) {
        setPersonConsulatationFee(formatToDecimal(feeObj3.fee));
        personConsultationFeeModalRef.current.openModal()
      }
    }
  }
  const openOnlineConsultationFeeModal = () => {
    if (feeStructureArray && feeStructureArray.length > 0) {
      const feeObj = feeStructureArray.find(feeObj => feeObj.paymenttypeid === 2);
      if (feeObj) {
        setOnlineConsultationFee(formatToDecimal(feeObj.fee));
        onlineConsultationFeeModalRef.current.openModal()
      }
    }
  }
  
  const openRemoteChatFeeModal = () => {
    if (feeStructureArray && feeStructureArray.length > 0) { 
      const feeObj2 = feeStructureArray.find(feeObj => feeObj.paymenttypeid === 3);
      if (feeObj2) {
        setRemoteChatFee(formatToDecimal(feeObj2.fee));
        remoteChatFeeModalRef.current.openModal()
      }
    }
  }
  const [ countriesList , setCountriesList] = useState([])
  const [ currencyOptions , setCurrencyOptions] = useState([])
  let doctorCurrency = localStorage.getItem("preferredcurrency");
  const [ selectedCurrency , setSelectedCurrency] = useState(doctorCurrency)
  const openCurrencyModal = () => {
    
    var input = {};
    var getAllCountries = async () => {
      try {
        var result = await getCountry(input)
        if (result) {
          setCountriesList(result);
          let currencySet = new Set();
          result.forEach(item => currencySet.add({"name":item.currencyname}))
          // let SortedSet = new Set([...currencySet])
          setCurrencyOptions([...currencySet].sort())
        } else {
          setCountriesList([])
          //  showErrorToast("Something went wrong!")
        }
  
      } catch (error) {
        console.log(error, "error in Countries")
      }
    }
    let doctorCurrency = localStorage.getItem("preferredcurrency");
    setSelectedCurrency(doctorCurrency)
    getAllCountries();
    currencyModalRef.current.openModal()
  }
  useEffect(()=>{
    console.log(countriesList , " countriesList")
    console.log(currencyOptions , " currencyOptions")
  },[countriesList])
  const saveExperienceData = async () => {
    try {
      var result = await saveExperienceChanges(experienceInput);
      // console.log(result, 'output')

      if (result && result.output.success === 1) {
        showSuccessToast("Professional Details updated successfully");
        editYrsofexperience.current.closeModal();
        getDoctorProfile();
      }

    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }


useEffect(() => {
  const updatedSelectedValues = [];
  if (appointmentNotif) updatedSelectedValues.push('notification');
  if (appointmentSMS) updatedSelectedValues.push('sms');
  if (appointmentEmail) updatedSelectedValues.push('email');
  setSelectedValuesAppointments(updatedSelectedValues);
}, [appointmentNotif, appointmentSMS, appointmentEmail]);
const handleAppointmentNotif = () => {
  setAppointmentNotif(!appointmentNotif);
  if (appointmentNotif) {
    setAppointmentNotifiState("0")
  } else {
    setAppointmentNotifiState("1")
  }
};
const handleAppointmentSMS = () => {
  setAppointmentSMS(!appointmentSMS);
  if (appointmentSMS) {
    setAppointmentSmsState("0")
  } else {
    setAppointmentSmsState("1")
  }
};
const handleAppointmentEmail = () => {
  setAppointmentEmail(!appointmentEmail);
  if (appointmentEmail) {
    setAppointmentEmailState("0")
  } else {
    setAppointmentEmailState("1")
  }
};
useEffect(()=>{
  if (appointmentNotif && appointmentSMS && appointmentEmail){
    setAppointmentAlertCheck(true)
    setAppointmentAlert("1")
  }else{
    setAppointmentAlertCheck(false)  
    setAppointmentAlert("0")
  }

},[ appointmentNotif , appointmentSMS, appointmentEmail])
// const handleReminderRadio = () => {
//   setAppointmentAlertCheck(!appointmentAlertCheck)
//   if (appointmentAlertCheck) {
//     setAppointmentAlert("0")
    
//     setRemindBeforeAppointmentsState("")

//   }, [appointmentNotif, appointmentSMS, appointmentEmail])
  const handleReminderRadio = () => {
    setAppointmentAlertCheck(!appointmentAlertCheck)
    if (appointmentAlertCheck) {
      setAppointmentAlert("0")

      setRemindBeforeAppointmentsState("")


      setAppointmentNotifiState("0")
      setAppointmentNotif(false);

      setAppointmentEmailState("0")
      setAppointmentEmail(false);

      setAppointmentSmsState("0")
      setAppointmentSMS(false);

    } else {
      setAppointmentAlert("1")

      setAppointmentNotifiState("1")
      setAppointmentNotif(true);

      setAppointmentEmailState("1")
      setAppointmentEmail(true);

      setAppointmentSmsState("1")
      setAppointmentSMS(true);
    }
  };
  const handleReminderRadio2 = () => {
    setMessageAlertCheck(!messageAlertCheck)
    if (messageAlertCheck) {
      setMessageAlert("0")
    } else {
      setMessageAlert("1")
    }
  };
  const inputForAddUpdate = {
    "preferenceid": prefrenceId,
    "appointmentalert": appointmentalert,
    "appointmentnotification": appointmentNotifiState,
    "appointmentsms": appointmentSmsState,
    "appointmentemail": appointmentEmailState,
    "remindappointmentbefore": remindBeforeAppointmentsState,
    "messagealert": messagealert
  }
  const setAlert = () => {
    const setAlertCall = async () => {
      try {
        showLoader();
        const result = await addUpdateAlerts(inputForAddUpdate);
        if (result.output) {
          setRefresh(!refresh)
          showSuccessToast("Updated Alerts Successfully.")
        } else {
          showErrorToast("Something went wrong!")
        }
      } catch (error) {
        console.log(error, "error in updating patient profile")

      }
      dismissLoader();
    }
    setAlertCall();
  }
  const selectRemindBeforeAppointments = (selectedOption) => {
    setRemindBeforeAppointmentsState(selectedOption)
  }
  useEffect(() => {
    // showLoader();
    var input = {};
    var fetchData = async () => {
      try {
        var result = await FetchAlerts(input)
        if (result) {
          setAlerts(result)
        } else {
          setAlerts("")
          //  showErrorToast("Something went wrong!")
        }

      } catch (error) {
        console.log(error, "error in fetching account data")
      }
      // dismissLoader();
    }

    fetchData();

  }, [refresh]);
  useEffect(() => {
    if (alerts && alerts.length > 0) {
      const {
        appointmentalert,
        appointmentemail,
        appointmentnotification,
        appointmentsms,

        messagealert,
        preferenceid,
        remindappointmentbefore,
      } = alerts[0];

      setPrefrenceId(preferenceid)
      if (messagealert == 1) {
        setMessageAlertCheck(true)
        setMessageAlert(messagealert)
      } else if (messagealert == 0) {
        setMessageAlertCheck(false)
        setMessageAlert(messagealert)
      }


      if (appointmentalert == 1) {
        setAppointmentAlertCheck(true)
        setAppointmentAlert(appointmentalert)
      } else if (appointmentalert == 0) {
        setAppointmentAlertCheck(false)
        setAppointmentAlert(appointmentalert)
      }




      if (appointmentnotification == 1) {
        setAppointmentNotif(true)
        setAppointmentNotifiState(appointmentnotification)
      } else if (appointmentnotification == 0) {
        setAppointmentNotif(false)
        setAppointmentNotifiState(appointmentnotification)
      }
      if (appointmentsms == 1) {
        setAppointmentSMS(true)
        setAppointmentSmsState(appointmentsms)
      } else if (appointmentsms == 0) {
        setAppointmentSMS(false)
        setAppointmentSmsState(appointmentsms)
      }
      if (appointmentemail == 1) {
        setAppointmentEmail(true)
        setAppointmentEmailState(appointmentemail)
      } else if (appointmentemail == 0) {
        setAppointmentEmail(false)
        setAppointmentEmailState(appointmentemail)
      }
      setRemindBeforeAppointmentsState(remindappointmentbefore)
      //setReport(reportalert);
    }
  }, [alerts]);
  const widths = ["20", "40", "60", "80", "100"];
  const handleUploadDegree = () => {
    //setDocumenttype("degree")
    if (documentname == "") {
      showErrorToast("Please select document type")
      return false;
    }
    document.getElementById("uploadDegree").click();
  }
  const handleUploadDocument = async (e) => {

    e.preventDefault();
    showLoader();
    if (e.target.files.length == 0) {
      dismissLoader();
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const result = await uploadImageCommonFunction(file, file.name);


    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    const maxSizeInMB = 5;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    const documenturl = result.name;

    if (result) {
      setDegreeUploadStage("2")
      setErrorStyleForDegreeUpload(false)
      setDegreeDocName(file.name)
      var fileSizeBytes = file.size;
      var fileSize = file.size / 1024;
      var docSize = fileSize.toFixed(2)
      setDegreeDocSize(docSize)


      if (file.size > maxSizeInBytes) {
        showErrorToast("File size exceeds 5MB. Please select a smaller file.");

        setErrorStyleForDegreeUpload(true)
      } else if (!allowedTypes.includes(file.type)) {
        setErrorStyleForDegreeUpload(true)
        showErrorToast("Invalid file type. Please select a PNG, JPG, JPEG, or PDF file.");
      } else {
        setUploadedDocuments((prevDocuments) => [
          ...prevDocuments,
          { documenturl, "documentname": file.name , documenttype, "documentsize":fileSizeBytes },
        ]);

      }


      widths.forEach((width, index) => {
        setTimeout(() => {
          setDegreeUploadLoadWidth(width);
        }, (index + 1) * 300);
      });

      setTimeout(() => {
        setDegreeUploadStage("3")
      }, 2000);

    }

    e.target.value = '';
    dismissLoader();

  };
  const handleDeleteUploadedDocument = () => {

    setDegreeUploadStage("1")
    setUploadedDocuments([])
  }
  // const handleDocumentType = (selectedOption) => {
  //     setDocumentName(selectedOption)
  //     setDocumentType(selectedOption)
  // };
  const handleDocumentType = (selectedOption) => {
    setDocumentName(selectedOption);
    setDocumentType(selectedOption);
  
    setUploadedDocuments((prevDocuments) => {
      // console.log('prevDocuments: ', prevDocuments)
      if (prevDocuments.length > 0) {
        const updatedDocuments = prevDocuments.map((doc, index) => {
          if (index === prevDocuments.length - 1) {
            return { ...doc, documenttype: selectedOption };
          }
          return doc;
        });
        return updatedDocuments;
      }
      return prevDocuments;
    });
  };
  
var inpurForUploadDocument = {
  "type": "document",
  "documents":uploadedDocuments
}
const documentUploadCall = async () => {
  // console.log(inpurForUploadDocument, " inpurForUploadDocument")
  // setDocumentType('')
  if (documenttype == "") {
    showErrorToast("Please select document type")
    return false;
  }
   if (uploadedDocuments.length == 0) {
     showErrorToast("Please upload any document")
     return false;
   } else {
     showLoader();
     try {
       const result = await uploadDocumentInProfile(inpurForUploadDocument);

       if (result.output.result == "success") {

         showSuccessToast("Uploaded Successfully")
         setRefreshPageData(!refreshPageData)
         uploadDoc.current.closeModal()

       } else {
         console.error(result, "Something went wrong in Submit.")
         showErrorToast("Something went wrong!")
       }

     } catch (error) {
       console.error('Error in SubmitFunction:', error);
     }

   }
   dismissLoader();

 };
 const openUploadDocumentModal =()=>{
  setDegreeUploadStage("1")
  setUploadedDocuments([])
  setDocumentType('')
   uploadDoc.current.openModal()
 }
 const handleDeleteDocument = async (id)=>{
  showLoader();
  let inputForDeleteDocument = {
    id
  }
  try {
    //console.log(inputForSignUpProfessionalDetails , " inputForSignUpProfessionalDetails")
    const result = await deleteDocumentCall(inputForDeleteDocument);
    

    if (result.output.message == "Successfully Deleted") {

      showSuccessToast("Document Deleted Successfully")
     if(result.output.success === 1){
      setDocumentData(prevData => prevData.filter(doc=>doc.id !== id))
      getDoctorProfile();
     }
      dismissLoader();

    } else {
      console.error(result, "Something went wrong in Submit.")
      showErrorToast("Something went wrong!")
    }

  } catch (error) {
    console.error('Error in SubmitFunction:', error);
  }
  
  dismissLoader();
 }
//  Subscription Functionality Start - 
const getSubscriptionPlans = async () => {
  try {
  var result = await getDoctorPlans(inputForGet);
 // console.log(result , " to get all subscription plans ")
  if(result){
    setSubscriptionPlans(result)
  }else{
    setSubscriptionPlans("")
  }
  } catch (error) {
    console.log(error , "Something went wrong in subscriptions update")
  }
}
const openUpgradeSubscriptionModal = ()=>{
  showLoader();
  if(subscriptionData){
    var currentplanid = subscriptionData[0].subscriptionplanid;
    setCurrentPlanId(currentplanid)
  } 
  getSubscriptionPlans();
  setTimeout(() => {
    upgradePlan.current.openModal();
  }, "1000");
 dismissLoader(); 
}
const cancelCurrentPlan = (date) =>{
  setPlanEndDate(date)
  cancelPlan.current.openModal();
}
const closepopupcancelplan = (date) =>{
  cancelPlan.current.closeModal();
}
const cancelactivePlan = async ()=>{
  var inputForSuscription = {
    planid: 1
  }
    showLoader();
    try {
      const result = await updateSubscriptionPlan(inputForSuscription);
      //console.log(result , " result of professiontl details")

      if (result.output.result == "success") {

        showSuccessToast("Subscription cancelled successfully")
        setRefreshPageData(!refreshPageData)
         cancelPlan.current.closeModal();
         
      }else {
        console.error(result, "Something went wrong in Submit.")
        showErrorToast("Something went wrong!")
      }
      dismissLoader();
    } catch (error) {
      console.error('Error in SubmitFunction:', error);
    }
}
const handleChangePlan = async (planid, amount) => {
  setPlanID(planid)
  let planamount = amount;
  var inputForSuscription = {
    planid
  }
    showLoader();
    if(planid === 1){
    try {
      const result = await updateSubscriptionPlan(inputForSuscription);
      //console.log(result , " result of professiontl details")

      if (result.output.result == "success") {

        showSuccessToast("Successfully Updated")
        setRefreshPageData(!refreshPageData)
         upgradePlan.current.closeModal();
      } else if (result && result.output && result.output.message && result.output.message == "unable to update you plan") {

        console.error(result, "Something went wrong in Submit.")
        showSuccessToast("Successfully Updated")
        //showErrorToast("Unable to change plan, your current plan has not yet expired!")
        setRefreshPageData(!refreshPageData)
         cancelPlan.current.closeModal();
         upgradePlan.current.closeModal();
         
      }else {
        console.error(result, "Something went wrong in Submit.")
        showErrorToast("Something went wrong!")
      }

    } catch (error) {
      console.error('Error in SubmitFunction:', error);
    }
  }else{
         const amount = parseFloat(planamount) * 100;
          try {
              const result = await CreatePaymentIntent({
                amount: amount,
              });
              //console.log(result,"Initial resp");
              result.amount = planamount;
              result.paymenttypeid = "1";
              const resp = await SavePaymentInitialCall(result);
              // console.log(resp,"Initial db resp");
              //setClientSecret("pi_3PAucsIpkkS0IXSH0Y2lFxG8_secret_2YLi2vpWMmcIfVkjUM4i52GU6");
                setClientSecret(result.client_secret);              
              //setStripePromise(loadStripe("pk_test_51OTcjkIpkkS0IXSHPzEVIeKXOmoOHiP4W0SPIjcboHjz6u7hNiW35joXqCgwhliRYbtJDxDFeOMJ0EfSuanV9yGs00Q0VlsMNZ"));
            }
            catch (error) {
              console.error("Error in Payment:", error);
            }
  }
    dismissLoader();
  
};
const formatDate = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString).format("Do MMM, YYYY");
};
const changeImage = () => {
  if (inputRef.current) {
    inputRef.current.click();
  }
};
const handleImageSelection = (e) => {
  e.preventDefault();
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/jpeg", "image/jpg","image/webp"];
    if (!allowedTypes.includes(file.type)) {

      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      return false;
    }

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      setShowCropper(true);
      editPhoto.current.openModal();
    };
    reader.readAsDataURL(file);
    e.target.value = '';
  }
};
const handleCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};
const handleCrop = async () => {
  showLoader()
  try {
    const croppedLogo = await getCroppedImg(selectedImage, croppedAreaPixels);
    if (!croppedLogo) {
      console.error('Cropped image is null or undefined');
      return;
    }
    const croppedUrl = URL.createObjectURL(croppedLogo);
    setImageUrl(croppedUrl);

    const result = await uploadImageInProfile(croppedLogo, 'cropped.jpg');
    const srcInResult = result.name;
    setPersonalData((prevData) => ({
      ...prevData,
      profilepictureurl: srcInResult,
    }));

    const imageUpload = await updatePatientProfileOnChange({
      doctorid,
      profilepictureurl: srcInResult,
    });

    if (imageUpload.output.success === 1) {
      var doctorDetails = localStorage.getItem("LoginInfo")
      if(doctorDetails){  
      var formateddata = JSON.parse(doctorDetails)
      const updatedDoctorDetails = { ...formateddata, imageurl: srcInResult };
      localStorage.setItem('LoginInfo', JSON.stringify(updatedDoctorDetails));
      }
    }

    setShowCropper(false);
    editPhoto.current.closeModal(); // Close the modal after cropping
  } catch (error) {
    console.error('Error handling cropped image:', error);
  }
  dismissLoader()
};

//Logo Functionality
const changeLogo = () => {
  if (inputLogoRef.current) {
    inputLogoRef.current.click();
  }
};

const handleLogoSelection = (e) => {
  e.preventDefault();
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/jpeg", "image/jpg","image/webp"];
    if (!allowedTypes.includes(file.type)) {

      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      return false;
    }

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedLogo(reader.result);
      setShowLogoCropper(true);
      editLogo.current.openModal();
    };
    reader.readAsDataURL(file);
    e.target.value = '';
  }
};

const handleLogoCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};

const handleLogoCrop = async () => {
  showLoader()
  try {
    const croppedLogo = await getCroppedImg(selectedLogo, croppedAreaPixels);
    if (!croppedLogo) {
      console.error('Cropped image is null or undefined');
      return;
    }
    const croppedUrl = URL.createObjectURL(croppedLogo);
    setLogoUrl(croppedUrl);

    const result = await uploadImageInProfile(croppedLogo, 'cropped.jpg');
    const srcInResult = result.name;
    setPersonalData((prevData) => ({
      ...prevData,
      // profilepictureurl: srcInResult,
      hospitallogourl: srcInResult,
    }));

    const imageUpload = await updateLogoOnChange({
      doctorid,
      hospitallogourl: srcInResult,
      // profilepictureurl: srcInResult,
    });

    
    if (imageUpload.output.success === 1) {
      localStorage.setItem("logo", personalData.imagepath + srcInResult);
      window.location.reload();
    }

    setShowLogoCropper(false);
    editLogo.current.closeModal(); // Close the modal after cropping
  } catch (error) {
    console.error('Error handling cropped image:', error);
  }
  dismissLoader()
};


  const editavailability = (type)=>{
    setIsAddClicked(false)
    if(type === "add"){
      setIsAddClicked(true)
    }
    const slotdetails = AvailabilityData && AvailabilityData.doctortimingsetup;
    if(slotdetails){
    setDurationofCalls(slotdetails.durationofcall)
    if (slotdetails.durationofcall_cd == "695") {
      setDurationofCallsTypeEdit("Mins")
      setDurationofCallsType("695")
    } else if (slotdetails.durationofcall_cd == "325") {
      setDurationofCallsTypeEdit("Hours")
      setDurationofCallsType("325")
    } 

    setMinimumNotice(slotdetails.minimumnotice)
    if (slotdetails.minimumnotice_cd == "695") {
      setMinimumNoticeTypeEdit("Mins")
      setMinimumNoticeType("695")
    } else if (slotdetails.minimumnotice_cd == "325") {
      setMinimumNoticeTypeEdit("Hours")
      setMinimumNoticeType("325")
    } 

    setBeforeCallBuffer(slotdetails.beforecallbuffer)
    if (slotdetails.beforecallbuffer_cd == "695") {
      setBeforeCallBufferTypeEdit("Mins")
      setBeforeCallBufferType("695")
    } else if (slotdetails.beforecallbuffer_cd == "325") {
      setBeforeCallBufferTypeEdit("Hours")
      setBeforeCallBufferType("325")
    } 

    setAfterCallBuffer(slotdetails.aftercallbuffer)
    if (slotdetails.aftercallbuffer_cd == "695") {
      setAfterCallBufferTypeEdit("Mins")
      setAfterCallBufferType("695")
    } else if (slotdetails.aftercallbuffer_cd == "325") {
      setAfterCallBufferTypeEdit("Hours")
      setAfterCallBufferType("325")
    } 

    if (slotdetails.islimitfuturebooking === 1) {
      setSwitchChecked(true)
      setFutureBookingsLimit(1)
   } else {
     setSwitchChecked(false);
     setFutureBookingsLimit(0)
   }
   
    setFutureBooking(slotdetails.bookinupto)
    if (slotdetails.bookinupto_cd == "326") {
      setFutureBookingTypeEdit("Days")
      setFutureBookingType("326")
    } else if (slotdetails.bookinupto_cd == "328") {
      setFutureBookingTypeEdit("Months")
      setFutureBookingType("328")
    } 
  }
    setActiveTab("service")
    editAvailability.current.openModal();
    setActiveComponent("")

  }

const switchclicked = (e) => {
  const isChecked = e.target.checked;
  setSwitchChecked(isChecked);
  setFutureBookingsLimit(isChecked ? 1 : 0);
  localStorage.setItem('SwitchChecked', isChecked);
  localStorage.setItem('FutureBookingsLimit', isChecked ? 1 : 0);
};
const handleChangeDuratonofcalls = (event) => {
  let inputValue = event.target.value;
  inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
  setDurationofCalls(inputValue);
  localStorage.setItem('DurationofCalls', inputValue);
};

const selectDurationofcallstype = (selectedOption) => {
  const value = selectedOption === "Mins" ? "695" : "325";
  setDurationofCallsType(value);
  localStorage.setItem('DurationofCallsType', value);
  setDurationofCallsTypeEdit(selectedOption);
  localStorage.setItem('DurationofCallsTypeEdit', selectedOption);
};

const handleChangeMinimumnotice = (event) => {
  let inputValue = event.target.value;
  inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
  setMinimumNotice(inputValue);
  localStorage.setItem('MinimumNotice', inputValue);
};

const selectMinimumNoticeType = (selectedOption) => {
  const value = selectedOption === "Mins" ? "695" : "325";
  setMinimumNoticeType(value);
  localStorage.setItem('MinimumNoticeType', value);
  setMinimumNoticeTypeEdit(selectedOption);
  localStorage.setItem('MinimumNoticeTypeEdit', selectedOption);
};

const handleChangeBeforecallbuffer = (event) => {
  let inputValue = event.target.value;
  inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
  setBeforeCallBuffer(inputValue);
  localStorage.setItem('BeforeCallBuffer', inputValue);
};

const selectBeforeCallBuffer = (selectedOption) => {
  const value = selectedOption === "Mins" ? "695" : "325";
  setBeforeCallBufferType(value);
  localStorage.setItem('BeforeCallBufferType', value);
  setBeforeCallBufferTypeEdit(selectedOption);
  localStorage.setItem('BeforeCallBufferTypeEdit', selectedOption);
};

const handleChangeaftercallbuffer = (event) => {
  let inputValue = event.target.value;
  inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
  setAfterCallBuffer(inputValue);
  localStorage.setItem('AfterCallBuffer', inputValue);
};

const selectAfterCalBuffer = (selectedOption) => {
  const value = selectedOption === "Mins" ? "695" : "325";
  setAfterCallBufferType(value);
  localStorage.setItem('AfterCallBufferType', value);
  setAfterCallBufferTypeEdit(selectedOption);
  localStorage.setItem('AfterCallBufferTypeEdit', selectedOption);
};

const handleChangefuturbookingsupto = (event) => {
  let inputValue = event.target.value;
  inputValue = inputValue.replace(/[^0-9.]/g, '').slice(0, 6);
  setFutureBooking(inputValue);
  localStorage.setItem('FutureBooking', inputValue);
};

const selectFutureBooking = (selectedOption) => {
  const value = selectedOption === "Days" ? "326" : "328";
  setFutureBookingType(value);
  localStorage.setItem('FutureBookingType', value);
  setFutureBookingTypeEdit(selectedOption);
  localStorage.setItem('FutureBookingTypeEdit', selectedOption);
};
let slotsinput = {  
  "doctorid": "",
  "durationofcall": DurationofCalls,
  "durationofcall_cd": DurationofCallsType,
  "minimumnotice": MinimumNotice,
  "minimumnotice_cd": MinimumNoticeType,
  "beforecallbuffer": BeforeCallBuffer,
  "aftercallbuffer": AfterCallBuffer,
  "beforecallbuffer_cd": BeforeCallBufferType,
  "aftercallbuffer_cd": AfterCallBufferType,
  "islimitfuturebooking": FutureBookingsLimit,
  "bookinupto": FutureBooking,
  "bookinupto_cd": FutureBookingType
}


const downloadBadge = async() => {
  showLoader();
  toPng(currentBadge.current).then((dataUrl) => {
      const link = document.createElement("a");
          if(currentBadge.current.id == "badge1"){
            link.download = "share_badge_1.png";
          }else{
            link.download = "share_badge_2.png";
          }
      link.href = dataUrl;
      link.click();
    })
    .catch((err) => {
      console.log(err);
    });
    dismissLoader();
};


const shareBadgeCall = async () => {
  showLoader();
  try {
    const dataUrl = await toPng(badgeBox1.current);

    // Download the image
    const link = document.createElement("a");
    link.download = "share_badge_1.png";
    link.href = dataUrl;
   // link.click();

    // Convert dataUrl to a file
    const file = dataURLtoFile(dataUrl, "share_badge_1.png");
    // Upload the file
    const resultUrlOfBadge1 = await uploadImageInProfile(file, file.name);

    const dataUrl2 = await toPng(badgeBox2.current);

   // link.click();

    // Convert dataUrl to a file
    const file2 = dataURLtoFile(dataUrl2, "share_badge_2.png");
    // Upload the file
    const resultUrlOfBadge2 = await uploadImageInProfile(file, file.name);

    let inputForAddDoctorBadge= {
      "badges": [
        {
          "badgeurl": resultUrlOfBadge1.name
        },
        {
          "badgeurl": resultUrlOfBadge2.name
        }
      ]
    }
    const finalResult = await addDoctorBadge(inputForAddDoctorBadge);
    if(finalResult.output.result == "success"){
      showSuccessToast("Shared Successfully")
      shareBadge.current.closeModal();
    }else{
      showErrorToast("Something went wrong!")
    }
  } catch (err) {
    console.log(err);
  }
  dismissLoader();
};

// Helper function to convert data URL to file
const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};


const handleScheduleSubmit = (newActiveComponent) => {
  if(newActiveComponent === "updatecompleted"){
    getDoctorProfile();
    editAvailability.current.closeModal();
  }
};
// useEffect(() => {
//   // Set the initial state based on the API response
//   setclinicVisitCheckbox(AvailabilityData && AvailabilityData.doctortimingsetup && AvailabilityData.doctortimingsetup.isclinicalvisit);
//   setDoctorVisitCheckbox(AvailabilityData && AvailabilityData.doctortimingsetup && AvailabilityData.doctortimingsetup.isvideocall);
// }, []);
const checkedservice = (e, type) => {
  const checked = e.target.checked;
  if (type === "clinicvisit") {
    setclinicVisitCheckbox(checked ? 1 : 0);
  } else if (type === "videocall") {
    setDoctorVisitCheckbox(checked ? 1 : 0);
  }
};
let input = {"doctorid":"","isvideocall":doctorvisit,"isclinicalvisit":clinicvisit,}
const submitEditAvailabilty = async (tab) =>{
  showLoader()
  if(tab === "service"){
    try {
      var result = await SubmitServicetype(input);
      if (result) {
        setActiveTab("slot")
      }
      getDoctorProfile()
      dismissLoader();
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
  else if(tab === "slot"){
    showLoader();
    try {
      var result = await SubmitServicetype(slotsinput);
      if (result) {
        setActiveTab("clinic")
      }
      getDoctorProfile()
      dismissLoader();
    } catch (error) {
      console.log(error, "Something went wrong in doctor profile")
    }
  }
}


const formatSize = (size) => {
  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};
const [ textAreaText , setTextAreaText] = useState("")
const setBadgeTextFunction = (e)=>{
  setBadgeText(e.target.value)
  setTextAreaText(e.target.value)
  setTextareaHeight(e.target.value ? `${e.target.scrollHeight}px` : "auto");
};
const [ placeholderText , setPlaceholderText] = useState("Click anywhere to start typing.")
const onFocusFunc = (e)=>{
 setPlaceholderText("")
};
const onBlurFunc = (e)=>{
  setPlaceholderText("Click anywhere to start typing.")
 };
const openShareBadgeModal = ()=>{
  setBadgeText("")
  
  setTextAreaText("")
  setLines([])
  setAIPromptArray([])
  setTextareaHeight("auto")
  shareBadge.current.openModal();
}
const [ lines , setLines ] = useState([]);
const [ updateText , setUpdateText] = useState(false)
const [AIPromptArray, setAIPromptArray] = useState([]);

// useEffect(()=>{

//   const text = badgeText;
//   if(badgeText){
//     const textWithLineBreaks = badgeText.replace(/(?<!\bDr|\bMr|\bMrs|\bMs|\bJr|\bSr|\d)\.\s/g, '.\n');
//     setLines(textWithLineBreaks.split('\n'));
//   }
// },[updateText])
const setLinesFunction = (text)=>{
  if(text){
    const textWithLineBreaks = text.replace(/(?<!\bDr|\bMr|\bMrs|\bMs|\bJr|\bSr|\d)\.\s/g, '.\n');
    setLines(textWithLineBreaks.split('\n'));
  }
}
const setTextFromPropmts = (objectText) =>{
  showLoader();
  setTimeout(() => {
    
  setLinesFunction(objectText)
  dismissLoader()
  }, 700);
}
const useSameText = () =>{
  if(textAreaText){
    
  showLoader();
  setTimeout(() => {
    
  setLinesFunction(textAreaText)
  dismissLoader()
  }, 700);
  
}
}
  const regenerateFunction = async ()=>{
    showLoader()
    let inputToRegenerate = {
      "name": personalData && personalData.firstname + " " + personalData.lastname,
      "speciality": personalData.specality,
      "qualification": personalData.qualification,
      "text":badgeText
    }
    try {
      const result = await reGenerateBdgeTextCall(inputToRegenerate)
      if(result.badge_content){
     // setBadgeText(result.badge_content)
      setLinesFunction(result.badge_content)
    }else{
      console.log(" someting went wrong")
    }
    } catch (error) {
      console.log(error , " error in regenerate call")
    }
    dismissLoader()
  }

  const rewriteWithAI = async (prompt)=>{
    showLoader()
    let inputToRegenerate = {
      "name": personalData && personalData.firstname + " " + personalData.lastname,
      "speciality": personalData.specality,
      "qualification": personalData.qualification,
      "query":prompt
    }
    try {
      const result = await badgeAIPromptCall(inputToRegenerate)
    if(result){
      
      if(result.generated_prompt){
        setAIPromptArray(result.generated_prompt)
    }
    
  }else{
    console.log(" someting went wrong")
  }
    } catch (error) {
      console.log(error , " error in regenerate call")
    }
    dismissLoader()
  }

  //Settings
    const [ROSSettings, setROSSettings] = useState(false)
    const [VitalsSettings, setVitalsSettings] = useState(false)
    const [PhysicalExamSettings, setPhysicalExamSettings] = useState(false)
    const [DoctorAssesmentSettings, setDoctorAssesmentSettings] = useState(false)
    const [ROSSettingsstate, setROSSettingsstate] = useState("")
    const [VitalsSettingsstate, setVitalsSettingsstate] = useState("")
    const [PhysicalExamSettingsstate, setPhysicalExamSettingsstate] = useState("")
    const [DoctorAssesmentSettingsstate, setDoctorAssesmentSettingsstate] = useState("")
    const [selectedValuesSettings, setSelectedValuesSettings] = useState([]);
    const [settings, setSettingsData] = useState('');
    useEffect(() => {
      if (settings) {
        if (settings && settings.ros == 1) {
          setROSSettings(true)
          setROSSettingsstate(settings.ros)
        } else if (settings.ros == 0) {
          setROSSettings(false)
          setROSSettingsstate(settings.ros)
        }
        if (settings && settings.vitals == 1) {
          setVitalsSettings(true)
          setVitalsSettingsstate(settings.vitals)
        } else if (settings.vitals == 0) {
          setVitalsSettings(false)
          setVitalsSettingsstate(settings.vitals)
        }
        if (settings && settings.physicalexam == 1) {
          setPhysicalExamSettings(true)
          setPhysicalExamSettingsstate(settings.physicalexam)
        } else if (settings.physicalexam == 0) {
          setPhysicalExamSettings(false)
          setPhysicalExamSettingsstate(settings.physicalexam)
        }
        if (settings && settings.assessment == 1) {
          setDoctorAssesmentSettings(true)
          setDoctorAssesmentSettingsstate(settings.assessment)
        } else if (settings.assessment == 0) {
          setDoctorAssesmentSettings(false)
          setDoctorAssesmentSettingsstate(settings.assessment)
        }
      }
    }, [settings]);

  useEffect(() => {
    const updatedSelectedValues = [];
    if (ROSSettings) updatedSelectedValues.push('ros');
    if (VitalsSettings) updatedSelectedValues.push('vitals');
    if (PhysicalExamSettings) updatedSelectedValues.push('physicalexam');
    if (DoctorAssesmentSettings) updatedSelectedValues.push('doctorassessment');
    setSelectedValuesSettings(updatedSelectedValues);
  }, [ROSSettings, VitalsSettings, PhysicalExamSettings, DoctorAssesmentSettings]);
  const handleROSSetting = () => {
    setROSSettings(!ROSSettings);
    if (ROSSettings) {
      setROSSettingsstate("0")
    } else {
      setROSSettingsstate("1")
    }
  };
  const handleVitalsSettings = () => {
    setVitalsSettings(!VitalsSettings);
    if (VitalsSettings) {
      setVitalsSettingsstate("0")
    } else {
      setVitalsSettingsstate("1")
    }
  };
  const handlePESettings = () => {
    setPhysicalExamSettings(!PhysicalExamSettings);
    if (PhysicalExamSettings) {
      setPhysicalExamSettingsstate("0")
    } else {
      setPhysicalExamSettingsstate("1")
    }
  };
  const handleDASettings = () => {
    setDoctorAssesmentSettings(!DoctorAssesmentSettings);
    if (DoctorAssesmentSettings) {
      setDoctorAssesmentSettingsstate("0")
    } else {
      setDoctorAssesmentSettingsstate("1")
    }
  };
  let Settingsinput = {
    "doctorid": 1,
    "chiefcomplaint": 1,
    "hpi": 1,
    "orders": 1,
    "ros": ROSSettingsstate,
    "vitals": VitalsSettingsstate,
    "physicalexam": PhysicalExamSettingsstate,
    "assessment": DoctorAssesmentSettingsstate,   
}
const setSettings = () => {
  const setSettingsCall = async () => {
    try {
      showLoader();
      const result = await addUpdateSettings(Settingsinput);
      if (result.output) {
        setRefresh(!refresh)
        showSuccessToast("Updated Settings Successfully.")
      } else {
        showErrorToast("Something went wrong!")
      }
    } catch (error) {
      console.log(error, "error in updating patient profile")

    }
    dismissLoader();
  }
  setSettingsCall();
}
const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];


const [selectedConditions, setSelectedConditions] = useState([]);
const [searchValue, setSearchValue] = useState('');
const handleCheckboxChangeForEdit = (selectedCondition) => {
  setSelectedConditions((prevSelectedConditions) => {
    const isAlreadySelected = prevSelectedConditions.some(
      (item) => item.id === selectedCondition.id
    );

    if (isAlreadySelected) {
      // If the condition is already selected, remove it
      return prevSelectedConditions.filter(
        (item) => item.id !== selectedCondition.id
      );
    } else {
      // If the condition is not selected, add it
      return [
        ...prevSelectedConditions,
        {
          id: selectedCondition.id,
          label: selectedCondition.label,
        },
      ];
    }
  });
};


// Add branch functional code start ---
const [firstname, setfirstname] = useState(null);
const [lastname, setlastname] = useState(null);
const [gender, setGender] = useState(null);
const [dobBranch, setDobBranch] = useState(null);

const [email, setEmail] = useState(null);
const [password, setPassword] = useState(null);
const [mobileno, setmobileno] = useState(null);

const [countryid, setcountryid] = useState(null);
const [countrycode, setcountrycode] = useState(null);

const [branchName, setBranchName] = useState(null);
const [branchAddress1, setBranchAddress1] = useState(null);
const [branchAddress2, setBranchAddress2] = useState(null);
const [branchCity, setBranchCity] = useState(null);
const [branchState, setBranchState] = useState(null);
const [branchPinCode, setBranchPinCode] = useState(null);
const [branchCountry, setBranchCountry] = useState(null);

const [genderId, setGenderId] = useState(null)
const [branchid, setbranchid] = useState(null)


function clearState (){
  setfirstname(null)
        setlastname(null)
        setEmail(null)
        setPassword(null)
        setGender(null)
          setDobBranch(null)
        setmobileno(null)
        setBranchName(null)
        setBranchAddress1(null)
        setBranchAddress2(null)
        setBranchCity(null)
        setBranchState(null)
        setBranchPinCode(null)
        setBranchCountry(null)
        setbranchid(null)
}
const handleDateChange = (date) => {
  setDobBranch(date);
};
const selectGender = (selectedOption) => {
  if (selectedOption == "Male") {
    setGender("Male")
    setGenderId("2")
  } else if (selectedOption == "Female") {
    setGender("Female")
    setGenderId("1")
  } else if (selectedOption == "Others") {
    setGender("Others")
    setGenderId("3")
  }
};
const handleMobileChange = (e) => {
  var countryCode = localStorage.getItem("countrycode")
  var countryId = localStorage.getItem("countryid")
  
  setcountryid(countryCode)
  setcountrycode(countryId)

  setmobileno(e.target.value.replace(/[^0-9]/g, "").slice(0, 11));

}

const openAddBranchModal = () =>{
  clearState();
  addBranch.current.openRight()
}

async function addBranchFunction (){
  if(!firstname){
    showErrorToast("Please Enter First Name!");
    return false;
  }else if(!email){
    showErrorToast("Please enter Email!");
    return false;
  }else if(!password){
    showErrorToast("Please enter Password!");
    return false;
  }else if(!gender){
    showErrorToast("Please enter Gender!");
    return false;
  }else if(!mobileno){
    showErrorToast("Please enter Phone No.!");
    return false;
  }else if(!branchName){
    showErrorToast("Please enter Branch Name!");
    return false;
  }else if(!branchAddress1){
    showErrorToast("Please enter Branch Address 1!");
    return false;
  }else if(!branchCity){
    showErrorToast("Please enter Branch City!");
    return false;
  }else if(!branchState){
    showErrorToast("Please enter Branch State!");
    return false;
  }else if(!branchPinCode){
    showErrorToast("Please enter Branch Pin code!");
    return false;
  }else if(!branchCountry){
    showErrorToast("Please enter Branch Country!");
    return false;
  }
  showLoader();
  let addBranchInput = {
   
    "firstname": firstname,
    "lastname": lastname,
    "gender": genderId,
    "dob": dobBranch,
    "email": email,
    "password": password,
    "mobileno": mobileno,
    "countryid": countryid,
    "countrycode": countrycode,
    "branchname": branchName,
    "address1": branchAddress1,
      "address2":branchAddress2,
      "pincode": branchPinCode,
      "city": branchCity,
      "state" : branchState,
      "country" : branchCountry,
  "roleid":1,
    "loginid": "",


    "latitude" : "",
    "longitude":"",

    
    // "fullname": "henr s",
    // "age": null,
    // "doctorphoto": null,
    // "idtoken": null,
    // "type": null,
    // "id": null,
    // "isdoctor": 0,
    // "issuperuser": null,
    // "roleid": 6306,
    // "usertypeid": 1,
  }
  try {
    if(dobBranch){
      const formattedDate = format(dobBranch, "yyyy-MM-dd");
      addBranchInput.dob = formattedDate;
    }
    let result = await addClinic(addBranchInput);
    console.log(result , " result of add branch.")
    if(result.output.result == "success"){
      showSuccessToast("Branch added Successfully!")
      setRefreshPageData(!refreshPageData)
      addBranch.current.closeRight();
    }else if(result.output.message == "doctor user already exists with this email/mobile number"){
      showErrorToast("Doctor user already exists with this email/mobile number!")
    }else{
      showErrorToast("Something went Wrong!")
    }
  } catch (error) {
    console.log(error ,  " error in creating branch")
  }

console.log(input , " add branch input")
  
dismissLoader();
}
async function editBranchFunction (){
  if(!firstname){
    showErrorToast("Please Enter First Name!");
    return false;
  }else if(!email){
    showErrorToast("Please enter Email!");
    return false;
  }
  // else if(!password){
  //   showErrorToast("Please enter Password!");
  //   return false;
  // }
  else if(!gender){
    showErrorToast("Please enter Gender!");
    return false;
  }else if(!mobileno){
    showErrorToast("Please enter Phone No.!");
    return false;
  }else if(!branchName){
    showErrorToast("Please enter Branch Name!");
    return false;
  }else if(!branchAddress1){
    showErrorToast("Please enter Branch Address 1!");
    return false;
  }else if(!branchCity){
    showErrorToast("Please enter Branch City!");
    return false;
  }else if(!branchState){
    showErrorToast("Please enter Branch State!");
    return false;
  }else if(!branchPinCode){
    showErrorToast("Please enter Branch Pin code!");
    return false;
  }else if(!branchCountry){
    showErrorToast("Please enter Branch Country!");
    return false;
  }
  showLoader();
  let editBranchInput = {
    "branchid":branchid,
    "firstname": firstname,
    "lastname": lastname,
    "gender": genderId,
    "dob": dobBranch,
    "email": email,
    "password": password,
    "mobileno": mobileno,
    "countryid": countryid,
    "countrycode": countrycode,
    "branchname": branchName,
    "address1": branchAddress1,
      "address2":branchAddress2,
      "pincode": branchPinCode,
      "city": branchCity,
      "state" : branchState,
      "country" : branchCountry,
  "roleid":1,
    "loginid": "",


    "latitude" : "",
    "longitude":"",

    
    // "fullname": "henr s",
    // "age": null,
    // "doctorphoto": null,
    // "idtoken": null,
    // "type": null,
    // "id": null,
    // "isdoctor": 0,
    // "issuperuser": null,
    // "roleid": 6306,
    // "usertypeid": 1,
  }
  try {
    if(dobBranch){
      const formattedDate = format(dobBranch, "yyyy-MM-dd");
      editBranchInput.dob = formattedDate;
    }
    let result = await updateClinic(editBranchInput);

    if(result.output.result == "Success"){
      showSuccessToast("Branch updated Successfully!")
      setRefreshPageData(!refreshPageData)
      addBranch.current.closeRight();
      editBranch.current.closeRight();
    }else if(result.output.message == "doctor user already exists with this email/mobile number"){
      showErrorToast("Doctor user already exists with this email/mobile number!")
    }else{
      showErrorToast("Something went Wrong!")
    }
  } catch (error) {
    console.log(error ,  " error in creating branch")
  }

// console.log(input , " add branch input")
  
dismissLoader();
}
    function openEditBranch (item){
      setbranchid(item.branchid)
        setfirstname(item.firstname)
        setlastname(item.lastname)
        setEmail(item.email)
        setPassword(item.password)
        setGender(item.gender)
        if(item.dob){
          let DobDefault = formatDate_DD_MM_YYYY(item.dob) 
          setDobBranch(DobDefault)
        }
        setmobileno(item.mobileno)
        setBranchName(item.clinicname)
        setBranchAddress1(item.address)
        setBranchAddress2(item.address2)
        setBranchCity(item.city)
        setBranchState(item.state)
        setBranchPinCode(item.postalcode)
        setBranchCountry(item.country)
  editBranch.current.openRight()
    }

// ------------------ Partner Logo ------------------------
//Partner Logo Functionality
const [showPartnerLogoCropper, setShowPartnerLogoCropper] = useState(false);
  const [selectedPartnerLogo, setSelectedPartnerLogo] = useState('');
const [partnerLogoUrl, setPartnerLogoUrl] = useState("/assets/imgs/no-image-icon.png");
const [partnerLogo, setPartnerLogo] = useState("/assets/imgs/no-image-icon.png");

const changePartnerLogo = () => {
  if (inputPartnerLogoRef.current) {
    inputPartnerLogoRef.current.click();
  }
};

const handlePartnerLogoSelection = (e) => {
  e.preventDefault();
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/jpeg", "image/jpg","image/webp"];
    if (!allowedTypes.includes(file.type)) {

      showErrorToast("Invalid file type. Please select a PNG, JPG or JPEG file.");
      return false;
    }

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedPartnerLogo(reader.result);
      setShowPartnerLogoCropper(true);
      editPartnerLogo.current.openModal();
    };
    reader.readAsDataURL(file);
    e.target.value = '';
  }
};

const handlePartnerLogoCropComplete = (croppedArea, croppedAreaPixels) => {
  setCroppedAreaPixels(croppedAreaPixels);
};

const handlePartnerLogoCrop = async () => {
  showLoader()
  try {
    const croppedLogo = await getCroppedImg(selectedPartnerLogo, croppedAreaPixels);
    if (!croppedLogo) {
      console.error('Cropped image is null or undefined');
      return;
    }
    const croppedUrl = URL.createObjectURL(croppedLogo);
    setPartnerLogoUrl(croppedUrl);

    const result = await uploadPartnerImageInProfile(croppedLogo, 'cropped.jpg');
    const srcInResult = result.name;
    setPartnerLogo(result.name)
    setEditedPartnerLogo(result.name)
    // setPersonalData((prevData) => ({
    //   ...prevData,
    //   // hospitallogourl: srcInResult,
    //   partnerlogo: srcInResult,
    // }));
    setResultPartner((prevData) => ({
      ...prevData,
      // hospitallogourl: srcInResult,
      partnerlogo: srcInResult,
    }));

    const imageUpload = await updatePartnerLogoOnChange({
      doctorid,
      partnerlogo: srcInResult,
      // profilepictureurl: srcInResult,
    });

    if (imageUpload.output.success === 1) {
      // window.location.reload();
      editPartnerLogo.current.closeModal(); // Close the modal after cropping
    }

    setShowPartnerLogoCropper(false);
  } catch (error) {
    console.error('Error handling cropped image:', error);
  }
  dismissLoader()
};

// -------------------Add Partner------------------------
const [partnerName , setPartnerName] = useState(null)

const FetchClinicsList = async ()=>{
  showLoader()
  try {
    var result = await FetchDoctorClinics({
    //  "type":"getbranch",
     "type":"getbranchforpartner",
    //  "loginid": getLoginID()
    });
    // console.log('FetchClinics: ', result)
    // if (result != null) {
    if (result && result.length > 0) {
      setClinicBranchList(result)
      const clinicsResult = result.map((item) => item.clinicname)
      // console.log('clincinResult', clinicsResult)
      setClinicList(clinicsResult)
    }
    dismissLoader();
  } catch (error) {
    console.log(error, "Something went wrong in doctor profile")
  }
}
// Function to handle clinic selection
const handleClinicSelect = (selectedClinicName) => {
  setClinics(selectedClinicName)
  setEditedClinics(selectedClinicName)
  const selectedClinic = clinicBranchList.find(
    (clinic) => clinic.clinicname === selectedClinicName
  );
  if (selectedClinic) {
    // console.log('selectedClinciBranchId: ', selectedClinic.branchid)
    setPartnerBranchId(selectedClinic.branchid); 
    setPartnerEditedBranchId(selectedClinic.branchid)
  }
};
// const handleClinicChange = (value) => {
//   setClinics(value);
// };

const openAddPartnerModal = () =>{
  setPartnerName('')
  // setPartnerBranchId('')
  setPartnerLogo('')
  setClinics('')
  FetchClinicsList();
  addPartner.current.openModal()
}

const handlePartnerChanges = () => {
  // if(!partnerName){
  //   showErrorToast("Partner Name required!")
  //   return;
  // } else if(!partnerLogo){
  //   showErrorToast("Please select any logo!")
  //   return;
  // } else 
  if(partnerBranchId == null){
    showErrorToast('Please select Branch List!')
    return;
  }
  saveClinicData(clinicInput)
};
let clinicInput = {
  'branchid': partnerBranchId,
  'loginid': getLoginID(),
  'partnername': partnerName,
  'partnerlogo': partnerLogo,
}
const saveClinicData = async () => {
  try {
    var result = await saveClinicChanges(clinicInput);
    // console.log("Address Input: ", AddressInput)
    // console.log(result, 'output')

    if (result && result.output.result === 'success') {
      showSuccessToast("Partner Details Added successfully");
      addPartner.current.closeModal();
      getDoctorProfile();
    }

  } catch (error) {
    console.log(error, "Something went wrong in doctor profile")
  }
}
// -------------------Edit Partner------------------------
const [editedPartnerName , setEditedPartnerName] = useState(null)
const [editedClinics , setEditedClinics] = useState(null)
const [editedPartnerLogo , setEditedPartnerLogo] = useState(null)
const [uniqueId , setUniqueId] = useState(null)
const [partnerEditedBranchId , setPartnerEditedBranchId] = useState(null)

const openEditPartnerModal = (step) =>{
  // console.log('step: ', step)
  setUniqueId(step.id)
  setEditedPartnerName(step.partnername)
  setEditedClinics(step.branchname)
  setEditedPartnerLogo(step.partnerlogo)
  setPartnerEditedBranchId(step.branchid)

  FetchClinicsList();
  editPartner.current.openModal()
}

const handlePartnerEditedChanges = () => {
  // if(!address1){
  //   showErrorToast("Address is required!")
  //   return;
  // }
  // if(!editedPartnerName){
  //   showErrorToast("Partner Name required!")
  //   return;
  // } else if(!editedPartnerLogo){
  //   showErrorToast("Please select any logo!")
  //   return;
  // } else
   if(!partnerEditedBranchId){
    showErrorToast('Please select Branch List!')
    return;
  }
  saveClinicEditedData(clinicEditedInput)
};
let clinicEditedInput = {
  'branchid': partnerEditedBranchId,
  'loginid': getLoginID(),
  'id': uniqueId,
  'partnername': editedPartnerName,
  'partnerlogo': editedPartnerLogo,
}
const saveClinicEditedData = async () => {
  try {
    var result = await saveClinicEditedChanges(clinicEditedInput);
    // console.log("Address Input: ", AddressInput)
    // console.log(result, 'output')

    if (result && result.output.result === 'success') {
      showSuccessToast("Partner Details updated successfully");
      editPartner.current.closeModal();
      getDoctorProfile();
    }

  } catch (error) {
    console.log(error, "Something went wrong in doctor profile")
  }
}

// ------------------Delete Partner -----------------------
const openDeletePartnerModal = (step) =>{
  // console.log('step: ', step)
  setUniqueId(step.id)
  setPartnerName(step.partnername)
  deletePartner.current.openModal(); 
}

const deletePartnerInput = {
  'doctorid': getLoginID(),
  'id': uniqueId,
}
const deletePartnerFromList = async () => {
  try{
    const result = await saveClinicDeletedChanges(deletePartnerInput);

    if(result.output.message === 'Successfully Deleted'){
      showSuccessToast('Partner Deleted Successfully');
      deletePartner.current.closeModal();
      getDoctorProfile()
    }
  } catch(error) {
    console.log(error, "Something went wrong in doctor profile")
  }
}
  return (
    <>
    <style>{swiperDocCSS}</style>
    {screenType == 'web' ? (
      <Box w="100%">

        {isLoading && <Loader />}
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          <Box w="100%">
            <SecondaryNav customHeader="Profile" />

            {/* Main container start */}
            <Flex w="calc(100vw - 6rem)">
              <Box
                p={{sm: "1.5rem 5rem", xl: "1.5rem 8.25rem"}}
                h="calc(100vh - 73px)"
                overflow="hidden auto"
                w="75%"
                ref={mainContainerRef}
              >
                {/* Left area start */}
                <Box w="100%" display="flex" flexDir="column" gap="1.5rem">
                  {/* Doctor Badge start */}
                  <Box
                    w="100%"
                    border="1px solid #E6E7E9"
                    borderRadius="0.75rem"
                    bg={`url('/assets/imgs/gradient-circle.png'),  linear-gradient(120deg, rgba(255,255,255,1) 42%, rgba(20,197,133,1) 50%, rgba(28,78,197,1) 73%)`}
                    bgPos="right"
                    bgRepeat="no-repeat"
                    bgSize="cover"
                  >
                    <Flex alignItems="center" justifyContent="space-between" w="100%">
                      <Box display="flex" gap="0.8rem" w="70%" p="2.5rem" pl={{md: "1.25rem", xl: "2.5rem"}} pr="0" bg={COLORS.GRADIENT_WHITE}>

                      <Box>

                        <Box w="5rem" h="5rem" bg="#D2DCF3" borderRadius="full" overflow="hidden">
                        
                          <Image
                            src={personalData.profilepictureurl ? personalData.imagepath + personalData.profilepictureurl : "/assets/imgs/no-image.png"}
                            w="100%"
                            h="100%"
                            objectFit="cover"
                          />

                        </Box>
                        <Box
                          as="Button"
                          pos="relative"
                          bottom="30px"
                          left="50px"
                          bg="white"
                          p="6px"
                          border="1px solid #E6E7E9"
                          borderRadius="full"
                          onClick={changeImage}
                        >
                          <input
                            type="file"
                            ref={inputRef}
                            style={{ display: 'none' }}
                            onChange={handleImageSelection}
                          />
                          <Image w="11px" h="auto" src='/assets/imgs/edit.png' />
                        </Box>
                      </Box>


                        <Box display="flex" flexDir="column">
                          <Box
                            display="flex"
                            justifyContent="start"
                            alignItems="center"
                            mb="0.5rem"
                            gap="0.5rem"
                          >
                            <Text
                              fontSize={{md: "1rem", lg: "1.25rem"}}
                              display="inline-block"
                              fontWeight="600"
                              color="black"
                            >
                              {personalData.firstname} {personalData.lastname}
                            </Text>
                            <Image src="/assets/svgs/verified.svg" />
                          </Box>
                          <Text
                            fontSize=".87rem"
                            fontWeight="500"
                            color="black"
                          >
                            {personalData.email}
                          </Text>
                        </Box>
                      </Box>

                      <Box p="2.5rem" pl="0" display="flex" justifyContent="right" w="28%">
                      {isDoctor === 1 && (
                        <Box
                          as="button"
                          p="0.75rem 1.25rem"
                          pr={{md: "0.75rem", lg: "1.25rem"}}
                          border="1px solid #E6E7E9"
                          borderRadius=".625rem"
                          bg="white"
                          onClick={openShareBadgeModal}
                          boxShadow="0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                        >
                          <Text fontSize=".87rem" fontWeight="600">
                            Share badge
                          </Text>
                        </Box>
                      )}
                      </Box>
                    </Flex>
                  </Box>   
                  {/* Doctor Badge end */}
                  {/* Personal details start */}
                  <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="personalSection"
                    ref={sectionRefs.personalSection}
                  >
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                          <Td
                            // p="1.5rem"
                            colSpan={3}
                            fontSize="0.75rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            letterSpacing="0.10rem"
                          >
                            <Box  display="flex" w="100%" justifyContent="space-between" alignItems="center" >
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Personal Details
                      </Text>
                         <PrimaryButton onClick={() => {openUpdatePasswordModal()}} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Reset Password" />
                    </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Full Name
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {personalData.firstname} {personalData.lastname}
                          </Td>
                          <Td colSpan={1}>
                            <Box
                              as="button"
                              w="100%"
                              onClick={() => {
                                handleNameEdit(personalData.firstname, personalData.lastname)
                              }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Gender
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {/* {personalData.gender == "1" ? "Female" : personalData.gender == "3" ? "Other" : "Male"} */}
                            {personalData.gender == "1" ? "Female" : personalData.gender == "3" ? "Other" : personalData.gender == '2' ? "Male" : ""}
                          </Td>
                          <Td colSpan={1}>
                            <Box
                              as="button"
                              w="100%"
                              onClick={opengendermodel}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Date of birth
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {/* {
                              personalData.dob && personalData.dob ?
                                personalData.dob
                                :
                                '-'
                            } */}
                            
                            {personalData.dob ? formatDate(personalData.dob) : ('-')}
                          </Td>
                          <Td colSpan={1}>
                            <Box
                              as="button"
                              w="100%"
                              onClick={() => {
                                handleDOBEdit(personalData)
                              }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Phone number
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {`${personalData.countrycode && personalData.countrycode ? (personalData.countrycode): ('')}${"  "}${personalData.mobileno}`}
                          </Td>
                          <Td colSpan={1}>
                            <Box
                              as="button"
                              w="100%"
                              onClick={() => {
                                handleMobileEdit(personalData)
                              }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Email Address
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {/* abraarpark@apollo.co */}
                            {personalData.email}
                          </Td>
                          <Td colSpan={1}>
                            {/* <Box as="button" w="100%">
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box> */}
                          </Td>
                        </Tr>
                        {/* <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Hospital Address
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600" maxW="15rem" isTruncated>
                            {addressData.address}
                          </Td>
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%" 
                            onClick={() => {handleAddressEdit(addressData.address)
                              }}>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr> */}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  {/* Personal details end */}

                  {/* Address details start */}
                  <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="addressSection"
                    ref={sectionRefs.addressSection}
                  >
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                          <Td
                            p="1.5rem"
                            colSpan={1}
                            fontSize="0.75rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            letterSpacing="0.10rem"
                          >
                            Address details
                          </Td>

                          <Td
                            colSpan={2}
                          >
                            {/* here */}
                            <input
                            type="file"
                            ref={inputLogoRef}
                            style={{ display: 'none' }}
                            onChange={handleLogoSelection}
                            />
                            <Box display='flex' flexDir='row' justifyContent='end' alignItems='center' gap='10px'>
                              {isGlobalAdmin == 1 &&
                              <PrimaryButton onClick={openAddBranchModal} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                         buttonText="Add Branch" />
                          }
                              {isSuperUser == 1 &&
                              <PrimaryButton onClick={openAddPartnerModal} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                              buttonText="Add Partner" /> 
                          }
                              <Box w="2rem" h="2rem" bg="#D2DCF3" borderRadius="full"    overflow="hidden">                   
                                <Image
                                  src={personalData.hospitallogourl ? personalData.imagepath +   personalData.hospitallogourl : "/assets/imgs/no-image.png"}
                                  w="100%"
                                  h="100%"
                                  objectFit="cover"
                                />
                              </Box>  
                              <Text 
                                fontSize="0.875rem" 
                                fontWeight="700"
                                textDecor="underline"
                                onClick={changeLogo}
                                cursor='pointer'
                              >
                                Upload hospital logo
                              </Text>
                            </Box>
                          </Td>

                          {/* <Td>   
                          <Box w="2rem" h="2rem" bg="#D2DCF3" borderRadius="full" overflow="hidden">                   
                          <Image
                            src={personalData.profilepictureurl ? personalData.imagepath + personalData.profilepictureurl : "/assets/imgs/no-image.png"}
                            w="100%"
                            h="100%"
                            objectFit="cover"
                          />
                        </Box>
                          </Td> */}
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Hospital/Clinic
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {
                              personalData.hospitalname
                            }
                          </Td>
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%" 
                            onClick={() => {handleHospitalEdit(personalData.hospitalname)
                              }}>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Address
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600" maxW="12rem" whiteSpace='wrap'>
                          {addressData.address1 || addressData.address2 || addressData.city || addressData.state || addressData.pincode || addressData.country ? (
                                             <>
                          {addressData.address1 && `${addressData.address1}, `}
                          {addressData.address2 && `${addressData.address2}, `}
                          {addressData.city && `${addressData.city}, `}
                          {addressData.state && `${addressData.state}, `}
                          {addressData.pincode && `${addressData.pincode}, `}
                          {addressData.country && `${addressData.country} `}
                          </>
                                           ) : '-' }

                            {/* 101 Pine Street Block C, Floor 3 Metropolis 83712 New York USA */}
                            
                          </Td>
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%" 
                            onClick={() => {handleAddressEdit(addressData)
                              }}>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                  {/* Address details end */}

                  {/* Branche Start */}
                  {isGlobalAdmin === 1 && (
                  <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="professionalSection"
                    ref={sectionRefs.professionalSection}
                  >
                    <Table variant="simple">
                      {/* <Tbody>
                        <Tr>
                          <Td
                            p="1.5rem"
                            colSpan={3}
                            fontSize="0.75rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            letterSpacing="0.10rem"
                          >
                            Branch details
                          </Td>
                        </Tr>
                        <Box w='100%'>
                          {resultBranch.length > 0 ? resultBranch.map((item, index)=>(
                            <>
                             <Tr>
                          <Td colSpan={3} fontSize="0.75rem" fontWeight="500">
                          Branch Name
                          </Td>
                          <Td colSpan={3} fontSize="0.875rem" fontWeight="600">
                          {item.clinicname}
                          </Td>
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%"
                            onClick={() => {openEditBranch(item)
                            }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Branch Address
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600" maxW="15rem" isTruncated>
                          {item.address1 || item.address2 || item.city || item.state || item.pincode || item.country ? (
                                             <>
                          {item.address1 && `${item.address1}, `}
                          {item.address2 && `${item.address2}, `}
                          {item.city && `${item.city}, `}
                          {item.state && `${item.state}, `}
                          {item.pincode && `${item.pincode}, `}
                          {item.country && `${item.country} `}
                          </>
                                           ) : '-' }
                          </Td>
                        </Tr></>
                          )):(
                            <Box p="1.25rem 1.5rem">
                            <p> No Branches found!</p>
                          </Box>
                          )}
                       
                        </Box>
                      </Tbody> */}

{/* {resultBranch && resultBranch.length > 0 ? ( */}
                        <SimpleTable
                          isDataPresent={true}
                          tableHeader={{ p: "1rem 1.5rem", bg: "#FAFAFA" }}
                          headers={TableHeaderBranch}
                          tableBody={
                            <>
                              { resultBranch.length > 0 ? resultBranch.map((item, index) => (
                                <Tr key={index}>
                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="600">
                                      {item.clinicname?item.clinicname:"-"}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                    {item.address1 || item.address2 || item.city || item.state || item.pincode || item.country ? (
                                             <>
                          {item.address1 && `${item.address1}, `}
                          {item.address2 && `${item.address2}, `}
                          {item.city && `${item.city}, `}
                          {item.state && `${item.state}, `}
                          {item.pincode && `${item.pincode}, `}
                          {item.country && `${item.country} `}
                          </>
                                           ) : '-' }
                                    </Text>
                                  </Td>

                                  <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%"
                            onClick={() => {openEditBranch(item)
                            }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>



                                </Tr>
                              )):(
                                <Tr p="1.25rem 1.5rem" w='100%'>
                                  <Td colSpan={3}>
                                <Text textAlign='center' fontSize='14px'> No Branches found!</Text>
                                  </Td>
                              </Tr>
                              )}
                            </>
                          }
                        />
                      {/* ) : (
                       null
                      )} */}


                    </Table>
                  </TableContainer>  
                  )}
                  {/* branch end */}

                  {/* Partner Section Start */}
                  {isSuperUser === 1 && (
                  <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="professionalSection"
                    ref={sectionRefs.professionalSection}
                  >
                    <Table variant="simple">
                        <SimpleTable
                          isDataPresent={true}
                          tableHeader={{ p: "1rem 1.5rem", bg: "#FAFAFA" }}
                          headers={TableHeaderPartner}
                          tableBody={
                            <>
                              { resultPartner.length > 0 ? resultPartner.map((item, index) => (
                                <Tr key={index}>

                                  <Td>
                                    <Box display='flex' alignItems='center' gap='15px'>
{item.partnerlogo && (

                                  <Box w="2rem" h="2rem" bg="#D2DCF3" borderRadius="full" overflow="hidden">
                                    <Image
                                  src={item.partnerlogo ? item.imagepath +   item.partnerlogo : "/assets/imgs/no-image.png"}
                                  w="100%"
                                  h="100%"
                                  objectFit="cover"
                                />
                                </Box>
)}
                                    <Text fontSize=".875rem" fontWeight="600">
                                      {item.partnername?item.partnername:"-"}
                                    </Text>

                                    </Box>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="600">
                                      {item.branchname?item.branchname:"-"}
                                    </Text>
                                  </Td>

                                  {/* <Td>
                                    <Box w="2rem" h="2rem" bg="#D2DCF3" borderRadius="full" overflow="hidden" >
                                    <Image
                                  src={item.partnerlogo ? item.imagepath +   item.partnerlogo : "/assets/imgs/no-image.png"}
                                  w="100%"
                                  h="100%"
                                  objectFit="cover"
                                />
                                </Box>
                                  </Td> */}

                                  <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%"
                            display='flex'
                            justifyContent='space-around'
                            alignItems='center'
                            // onClick={() => {openEditBranch(item)
                            // onClick={() => {openEditPartnerModal(item)
                            // }}
                            >
                              <Box>
                              <Image
                                float="right"
                                src="/assets/svgs/delete.svg"
                                h='15px'
                                w='10px'
                                onClick={() => {openDeletePartnerModal(item)
                                }}
                              />
                              </Box>
                              <Box>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                                onClick={() => {openEditPartnerModal(item)
                                }}
                                // h='15px'
                                // w='10px'
                              />
                              </Box>
                            </Box>
                          </Td>



                                </Tr>
                              )):(
                                <Tr p="1.25rem 1.5rem" w='100%'>
                                  <Td colSpan={3}>
                                <Text textAlign='center' fontSize='14px'> No Partners found!</Text>
                                  </Td>
                              </Tr>
                              )}
                            </>
                          }
                        />
                      {/* ) : (
                       null
                      )} */}


                    </Table>
                  </TableContainer>  
                  )}
                  {/* Partner Section end */}

                  {/* Professional details start */}
                  {isDoctor === 1 && (
                  <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="professionalSection"
                    ref={sectionRefs.professionalSection}
                  >
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                          <Td
                            p="1.5rem"
                            colSpan={3}
                            fontSize="0.75rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            letterSpacing="0.10rem"
                          >
                            Professional details
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Degree
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {/* MBBS, MD (PGI), DNB, FICOG */}
                            {
                              sanitizeDegreeName(personalData.qualification)
                            }
                          </Td>
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%"
                            onClick={() => {handleDegreeEdit(personalData.qualification)
                            }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Specialization
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600" maxW="15rem" isTruncated>
                            {specialityData && specialityData.length > 0 ? (
                              specialityData.map((speciality, index) => (
                                // speciality.specialityname && speciality.specialityname != null && speciality.othervalue &&(

                                <span key={index}>
                                  {/* Check if the specialtyid is 16, show the custom name */}
                                  {speciality.specialtyid === 16 ? (
                                    editedSpecialityName
                                    // speciality.othervalue
                                  ) : Array.isArray(speciality.specialityname) ? (
                                    speciality.specialityname.join(", ")
                                  ) : (
                                    speciality.specialityname
                                  )}
                                  {index < specialityData.length - 1 && ', '}
                                </span>
                                // )
                              ))
                            ) : (
                              // personalData.specality ? personalData.specality : '-'
                              "-"
                            )}
                          </Td>
                          {/* Do not delete this */}
                          {/* <Td fontSize="0.875rem" fontWeight="600">
                            {AvailabilityData && AvailabilityData.doctorspeciality ? (
                                AvailabilityData.doctorspeciality.map((speciality, index) => (
                                  <span key={index}>
                                    {Array.isArray(speciality.specialityname) ? (
                                      speciality.specialityname.join(", ")
                                    ) : (
                                      speciality.specialityname
                                    )}
                                    {index < AvailabilityData.doctorspeciality.length - 1 && ', '}
                                  </span>
                                ))
                              ) : (
                                personalData.specality ? personalData.specality : '-'
                              )}
                          </Td> */}
                          {/* <Td fontSize="0.875rem" fontWeight="600">
                            {
                              // personalData.specality ?
                              //   personalData.specality
                              //   :
                              //   '-'
                              personalData.specality
                            }
                          </Td> */}
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%"
                            onClick={() => handleSpecialityEdit()}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Years of experience
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {/* 12 */}
                            {
                              // personalData.yearsofexperience ?
                              //   personalData.yearsofexperience
                              //   :
                              //   '-'
                              personalData.yearsofexperience
                            }
                          </Td>
                          <Td colSpan={1}>
                            <Box 
                            as="button" 
                            w="100%"
                            onClick={() => {handleExperienceEdit(personalData.yearsofexperience)
                            }}
                            >
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                              />
                            </Box>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>  
                  )}
                  {/* Professional details end */}

                  {/* Documents start */}
                  {isDoctor === 1 && (
                  <Box border="1px solid #E6E7E9" borderRadius="10px" id="documentSection" ref={sectionRefs.documentSection}>
                    <HStack
                      justifyContent="space-between"
                      p="1.5rem"
                      borderBottom="1px solid #E6E7E9"
                    
                    >
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        DOCUMENTS
                      </Text>
                      <Box
                        as="button"
                        fontSize="0.875rem"
                        fontWeight="700"
                        textDecor="underline"
                        onClick={openUploadDocumentModal}
                      >
                        Upload document
                      </Box>
                    </HStack>
                    <Flex
                      flexWrap="wrap"
                      spacing={4}
                      p="1.25rem 1.5rem"
                      gap="1rem"
                    >
                      {documentData && documentData.length > 0 ? (
                        documentData.map((doc, index) => (
                          <Flex
                            key={index}
                            bg="white"
                            flexDir="column"
                            w="calc(50% - 0.5rem)"
                            p="16px"
                            border="1px solid #E6E7E9"
                            borderRadius="12px"
                          >

                            <Box display="flex" gap="15px" w="100%">
                              <Box as="a" cursor="pointer"  boxSize="2.5rem" 
                              // onClick={()=>downloadDocument(doc.imagepath + doc.documenturl, getFilenameExtension(doc.documenturl))}>
                              onClick={()=>downloadDocument(doc.imagepath + doc.documenturl, "Document")}>
                              {/* <Image src={getIconForExtension(doc.documenturl)} /> */}
                              <Image src={getIconForExtension(getFilenameExtension(doc.documenturl))} />
                              </Box>
                              <Box w="100%">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  h="auto"
                                  w="100%"
                                >
                                  <Box as="a" cursor="pointer" w="8rem" h="auto" onClick={()=>downloadDocument(doc.imagepath + doc.documenturl, getFilenameExtension(doc.documenturl))}>
                                    <Text fontSize="14px" fontWeight="600" className="">
                                      {/* {} */}
                                      {/* {`${doc.documenturl} `} */}
                                      {`${doc.documenttype}.${getFilenameExtension(doc.documenturl)}`}
                                      {/* {doc.imagepath} */}
                                    </Text>
                                    <Text fontSize="12px" fontWeight="500" className="" color='#384052'>
                                      {doc.documentsize!= null ? formatSize(doc.documentsize): ''}
                                    </Text>
                                  </Box>
                                  <Box as="button"
                                  onClick={()=>handleDeleteDocument(doc.id)}>
                                    <Image
                                      w="16px"
                                      h="18px"
                                      src="/assets/svgs/delete.svg"
                                    />
                                  </Box>
                                </Box>

                              </Box>
                            </Box>
                          </Flex>
                        ))
                      ) : (
                        <p>
                          No Documents found
                        </p>

                      )}

                    </Flex>
                  </Box>        
                  )}
                  {/* Documents end */}
                  
                   {/* {/ Availability start /} */}
                   {isDoctor === 1 && (            
              <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="availSection"
                    ref={sectionRefs.availSection}
                  >
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                        <Box p="1rem" px="1.5rem" display="flex" w="100%" justifyContent="space-between" alignItems="center" borderBottom="1px solid #E6E7E9">
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Availability
                      </Text>
                      {AvailabilityData && AvailabilityData.availtimings == null && 
                      <PrimaryButton onClick={()=>editavailability("add")} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Add availability" />
                      }
                    </Box>
                        
                        </Tr>
                       
                        {AvailabilityData && AvailabilityData.availtimings && AvailabilityData.availtimings.availablity ? (
                          <>
                            <Tr>
                            <Td fontSize="0.75rem" fontWeight="500">
                                  {Object.values(
                                    AvailabilityData.availtimings.availablity.clinicalvisits.reduce((acc, item) => {
                                      const day = item.day.charAt(0).toUpperCase() + item.day.slice(1).toLowerCase(); // Capitalize the day

                                      // Initialize the day if it hasn't been encountered before
                                      if (!acc[day]) {
                                        acc[day] = {
                                          day: day,
                                          isavailable: item.isavailable,
                                          timings: []
                                        };
                                      }

                                      // Add available timings to the day's timings array, or '-' if unavailable
                                      if (item.isavailable === 1 && item.timings && item.timings.length > 0) {
                                        item.timings.forEach(timing => {
                                          acc[day].timings.push({
                                            fromtime: timing.fromtime,
                                            totime: timing.totime
                                          });
                                        });
                                      } else {
                                        // If isavailable is 0, push a '-' for unavailable timings
                                        acc[day].timings.push({
                                          fromtime: '-',
                                          totime: '-'
                                        });
                                      }

                                      return acc;
                                    }, {})
                                  )
                                    // Sort the days in the correct order: Monday to Sunday
                                    .sort((a, b) => dayOrder.indexOf(a.day.toLowerCase()) - dayOrder.indexOf(b.day.toLowerCase()))
                                    // Sort timings by 'fromtime'
                                    .map(item => {
                                      // item.timings.sort((a, b) => {
                                        item.timings = item.timings
                                  .filter(timing => timing && timing.fromtime) // Filter out any null or undefined timings
                                  .sort((a, b) => {
                                        const [aHour, aMin] = a.fromtime.split('-')[0].split(':').map(Number);
                                        const [bHour, bMin] = b.fromtime.split('-')[0].split(':').map(Number);
                                        return aHour !== bHour ? aHour - bHour : aMin - bMin;
                                      });
                                      return item;
                                    })
                                    // Map through each day's data and render it
                                    .map((item, index) => (
                                      <Flex key={index} direction="row" alignItems="center">
                                        {/* Show day once, and only display timings beside it */}
                                        <Text fontSize="0.75rem" w="45%" fontWeight="600" color="#061027">
                                          {item.day}
                                        </Text>
                                        <Box w='30%' display='flex' gap='0.5rem'>
                                          <Image src="/assets/svgs/hospital-active.svg" />
                                          <Image src="/assets/svgs/videocam-active.svg" />
                                        </Box>
                                        {/* Show timings beside the day */}
                                        <Flex direction="column">
                                          {item.timings.map((timing, timingIndex) => (
                                            <Text key={`${index}-${timingIndex}`} fontSize="0.75rem" w="11rem" fontWeight="500" color="#061027">
                                              {timing.fromtime} - {timing.totime}
                                            </Text>
                                          ))}
                                        </Flex>
                                      </Flex>
                                    ))}
                                </Td>

                                {/* <Td fontSize="0.875rem" fontWeight="600">
                                  <Box display="flex" gap="0.5rem">
                                    <Image src="/assets/svgs/hospital-active.svg" />
                                    <Image src="/assets/svgs/videocam-active.svg" />
                                    <Text fontSize="14px" fontWeight="600" color="#061027">
                                      {Object.values(
                                        AvailabilityData.availtimings.availablity.clinicalvisits.reduce((acc, item) => {
                                          const day = item.day.charAt(0).toUpperCase() + item.day.slice(1).toLowerCase(); // Capitalize the day

                                          // Initialize the day if it hasn't been encountered before
                                          if (!acc[day]) {
                                            acc[day] = {
                                              day: day,
                                              timings: []
                                            };
                                          }

                                          // Add available timings to the day's timings array
                                          if (item.timings && item.timings.length > 0) {
                                            item.timings.forEach(timing => {
                                              acc[day].timings.push({
                                                fromtime: timing.fromtime,
                                                totime: timing.totime
                                              });
                                            });
                                          } else {
                                            acc[day].timings.push({
                                              fromtime: '-',
                                              totime: '-'
                                            });
                                          }

                                          return acc;
                                        }, {})
                                      )
                                        // Sort the days in the correct order: Monday to Sunday
                                        .sort((a, b) => dayOrder.indexOf(a.day.toLowerCase()) - dayOrder.indexOf(b.day.toLowerCase()))
                                        // Map through each day's data and render it
                                        .map((item, index) => (
                                          <Flex key={index} direction="column">
                                            {item.timings.map((timing, timingIndex) => (
                                              <Text key={`${index}-${timingIndex}`} fontSize="0.75rem" w="11rem" fontWeight="500" color="#061027">
                                                {timing.fromtime} - {timing.totime}
                                              </Text>
                                            ))}
                                          </Flex>
                                        ))}
                                    </Text>
                                  </Box>
                                </Td> */}
                              <Td colSpan={1}>
                                <Box as="button" w="100%" onClick={editavailability}>
                                  <Image
                                    float="right"
                                    src="/assets/imgs/right-icon.png"
                                  />
                                </Box>
                              </Td>
                            </Tr>
                            
                            <Tr>
                            <Td fontSize="0.75rem" fontWeight="500">
                              {Object.values(
                                AvailabilityData.availtimings.availablity.videovisit.reduce((acc, item) => {
                                  const day = item.day.charAt(0).toUpperCase() + item.day.slice(1).toLowerCase(); // Capitalize the day

                                  // Initialize the day if it hasn't been encountered before
                                  if (!acc[day]) {
                                    acc[day] = {
                                      day: day,
                                      isavailable: item.isavailable,
                                      timings: []
                                    };
                                  }

                                  // Add available timings to the day's timings array, or '-' if unavailable
                                  if (item.isavailable === 1 && item.timings && item.timings.length > 0) {
                                    item.timings.forEach(timing => {
                                      acc[day].timings.push({
                                        fromtime: timing.fromtime,
                                        totime: timing.totime
                                      });
                                    });
                                  } else {
                                    // If isavailable is 0, push a '-' for unavailable timings
                                    acc[day].timings.push({
                                      fromtime: '-',
                                      totime: '-'
                                    });
                                  }

                                  return acc;
                                }, {})
                              )
                                // Sort the days in the correct order: Monday to Sunday
                                .sort((a, b) => dayOrder.indexOf(a.day.toLowerCase()) - dayOrder.indexOf(b.day.toLowerCase()))
                                // Sort timings by 'fromtime'
                                .map(item => {
                                  // item.timings.sort((a, b) => {  existing code
                                  // added code
                                  item.timings = item.timings
                            .filter(timing => timing && timing.fromtime)
                            .sort((a, b) => {
                                    const [aHour, aMin] = a.fromtime.split('-')[0].split(':').map(Number);
                                    const [bHour, bMin] = b.fromtime.split('-')[0].split(':').map(Number);
                                    return aHour !== bHour ? aHour - bHour : aMin - bMin;
                                  });
                                  return item;
                                })
                                // Map through each day's data and render it
                                .map((item, index) => (
                                  <Flex key={index} direction="row" alignItems="center">
                                    {/* Show day once, and only display timings beside it */}
                                    <Text fontSize="0.75rem" w="45%" fontWeight="600" color="#061027">
                                      {item.day}
                                    </Text>
                                    <Box w='30%' display='flex' gap='0.5rem'>
                                      <Image src="/assets/svgs/hospital-in-active.svg" />
                                      <Image src="/assets/svgs/videocam-in-active.svg" />
                                    </Box>
                                    {/* Show timings beside the day */}
                                    <Flex direction="column">
                                      {item.timings.map((timing, timingIndex) => (
                                        <Text key={`${index}-${timingIndex}`} fontSize="0.75rem" w="11rem" fontWeight="500" color="#061027">
                                          {timing.fromtime} - {timing.totime}
                                        </Text>
                                      ))}
                                    </Flex>
                                  </Flex>
                                ))}
                            </Td>

                              {/* <Td fontSize="0.875rem" fontWeight="600">
                                <Box display="flex" gap="0.5rem">
                                  <Image src="/assets/svgs/hospital-in-active.svg" />
                                  <Image src="/assets/svgs/videocam-in-active.svg" />
                                  <Text fontSize="14px" fontWeight="600" color="#061027">
                                    {AvailabilityData.availtimings.availablity.videovisit.reduce((acc, item) => {
                                      if (item.timings && item.timings.length > 0) {
                                        item.timings.forEach(timing => {
                                          const existingIndex = acc.findIndex(
                                            elem =>
                                              elem.fromtime === timing.fromtime &&
                                              elem.totime === timing.totime
                                          );
                                            acc.push({
                                              fromtime: timing.fromtime,
                                              totime: timing.totime,
                                              // days: [item.day],
                                              days: [{ day: item.day, isavailable: item.isavailable }],
                                            });
                                        });
                                      } else{
                                        acc.push({
                                          fromtime: '-',
                                          totime: '-',
                                          days: [{ day: item.day, isavailable: item.isavailable }],
                                        });
                                      }
                                      return acc;
                                    }, []).map((item, index) => (
                                      item.days.map((dayInfo, dayIndex) => (
                                        <Text key={`${index}-${dayIndex}`} fontSize="0.75rem" fontWeight="600" color="#061027">
                                          {dayInfo.isavailable === 0 ? '-' : `${item.fromtime} - ${item.totime}`}
                                        </Text>
                                      ))
                                    ))}
                                  </Text>
                                </Box>
                              </Td> */}
                              <Td colSpan={1}>
                                <Box as="button" w="100%" onClick={editavailability}>
                                  <Image
                                    float="right"
                                    src="/assets/imgs/right-icon.png"
                                  />
                                </Box>
                              </Td>
                            </Tr>
                          </>
                          ) : (
                            <Tr>
                              <Td colSpan={3} textAlign="center">No records available</Td>
                            </Tr>
                          )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  )}
                  {/* {/ Availability end /} */}
                  
                  {/* Alerts start */}
                  {isDoctor === 1 && (
                  <Box border="1px solid #E6E7E9" borderRadius="10px" id="alertSection" ref={sectionRefs.alertSection}>
                    <Box p="1rem" px="1.5rem" display="flex" w="100%" justifyContent="space-between" alignItems="center" borderBottom="1px solid #E6E7E9">
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Alerts
                      </Text>
                      <PrimaryButton onClick={setAlert} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Set Alerts" />
                    </Box>
                    <Flex gap="1rem">
                      <Card p="1.25rem" w="100%" shadow="none">
                        <CardHeader p="0" pb="0.875rem">
                          <Flex>
                            <Text fontSize="1rem" fontWeight="600">
                              Appointment booked
                            </Text>
                            <Spacer />
                            <Switch colorScheme="green" size="md"
                              isChecked={appointmentAlertCheck}
                              onChange={handleReminderRadio} />
                          </Flex>
                        </CardHeader>
                        <CardBody bg="#F9FAFB" borderRadius="0.75rem">
                          <CheckboxGroup
                            colorScheme="green" value={selectedValuesAppoitments}
                          >
                            <Stack spacing={3} direction="column">
                              <Checkbox
                                sx={checkboxLabelStyles} value='notification'
                                onChange={handleAppointmentNotif}
                              >
                                Notification
                              </Checkbox>
                              <Checkbox sx={checkboxLabelStyles} value="sms"
                                onChange={handleAppointmentSMS}>
                                SMS
                              </Checkbox>
                              <Checkbox sx={checkboxLabelStyles} value="email"
                                onChange={handleAppointmentEmail}>
                                Email
                              </Checkbox>
                            </Stack>
                          </CheckboxGroup>
                          <DropdownInput
                            inputStyleCss={{ maxW: "50%", mt: "1rem" }}
                            variant="fullSide"
                            dropDownPlace="Remind before"
                            options={["10 mins", "20 mins", "30 mins"]}

                            defaultOption={remindBeforeAppointmentsState}
                            onClick={selectRemindBeforeAppointments}
                          />
                        </CardBody>
                        <Divider my="1rem" opacity="0.2" />
                        <Flex>
                          <Text fontSize="16px" fontWeight="600">
                            Messages
                          </Text>
                          <Spacer />
                          <Switch colorScheme="green" size="md"
                            isChecked={messageAlertCheck}
                            onChange={handleReminderRadio2} />
                        </Flex>
                      </Card>
                    </Flex>
                  </Box> 
                  )}
                  {/* Alerts end */}
                  {/* Settings start */}
                  {isDoctor === 1 && (
                  <Box border="1px solid #E6E7E9" borderRadius="10px" id="settingsSections" ref={sectionRefs.settingsSection}>
                    <Box p="1rem" px="1.5rem" display="flex" w="100%" justifyContent="space-between" alignItems="center" borderBottom="1px solid #E6E7E9">
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Settings
                      </Text>
                      <PrimaryButton onClick={setSettings} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Set Settings" />
                    </Box>
                    <Flex gap="1rem">
                    <Card p="1.25rem" w="100%" shadow="none">
                <CardBody pt="0">
                  <CheckboxGroup colorScheme='green'
                    value={selectedValuesSettings}
                  >
                    <Stack spacing={3} direction="column">
                      <Checkbox
                        isChecked={true}
                        disabled
                        colorScheme='green'
                        sx={checkboxLabelStyles}>Chief complaint</Checkbox>
                      <Checkbox
                      isChecked={true}
                      disabled
                        sx={checkboxLabelStyles}>HPI</Checkbox>
                        <Checkbox
                        isChecked={true}
                        disabled
                        sx={checkboxLabelStyles}>Orders</Checkbox>
                      <Checkbox
                        onChange={handleROSSetting}
                        sx={checkboxLabelStyles} value='ros'>ROS</Checkbox>
                         <Checkbox
                        onChange={handleVitalsSettings}
                        sx={checkboxLabelStyles} value='vitals'>Vitals</Checkbox>
                         <Checkbox
                        onChange={handlePESettings}
                        sx={checkboxLabelStyles} value='physicalexam'>Physical Exam</Checkbox>
                        <Checkbox
                        onChange={handleDASettings}
                        sx={checkboxLabelStyles} value='doctorassessment'>Doctor Assessment</Checkbox>
                        
                    </Stack>
                  </CheckboxGroup>
                </CardBody>
              </Card>
                    </Flex>
                  </Box> 
                  )}
                  {/* Settings end */}


                   {/* Fee structure settings start */}
                   {isDoctor === 1 && (
                  <TableContainer
                    border="1px solid #E6E7E9"
                    borderRadius="10px"
                    w="100%"
                    id="feeStructureSection"
                    ref={sectionRefs.feeStructureSection}
                  >
                    <Table variant="simple">
                      <Tbody>
                        <Tr>
                          <Td
                            colSpan={3}
                            fontSize="0.75rem"
                            fontWeight="600"
                            textTransform="uppercase"
                            letterSpacing="0.10rem"
                          >
                            <Box  display="flex" w="100%" justifyContent="space-between" alignItems="center" >
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Fee Structure
                      </Text>
                      {/* <Box  display="flex" justifyContent="space-around" width="290px"> */}
                      {/* <PrimaryButton onClick={() => {openCurrencyModal()}} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Change Currency" /> */}
                         <PrimaryButton onClick={() => {openFeeStructureModal()}} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Update Fee" 
                        isDisabled={isSuperUser !== 1 ? true : false}
                        />
                        </Box>
                    {/* </Box> */}
                          </Td>
                         
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                          In-Person Consultation Fee
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {feeStructureArray.length > 0 ? (feeStructureArray.find(feeObj => feeObj.paymenttypeid === 4)?.fee ? `${selectedCurrency} ${formatToDecimal(feeStructureArray.find(feeObj => feeObj.paymenttypeid === 4).fee)}` : "-") : "-"}
                          </Td>
                          <Td colSpan={1}>
                            {isSuperUser == 1 && (
                            <Box 
                            as="button" 
                            w="100%" 
                            onClick={() => {openPersonConsultationFeeModal()}}>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                                />
                            </Box>
                              ) }
                          </Td>

                         
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                          {/* Appointment Fee */}
                          Online Consultation
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600">
                            {feeStructureArray.length > 0 ? (feeStructureArray.find(feeObj => feeObj.paymenttypeid === 2)?.fee ? `$${formatToDecimal(feeStructureArray.find(feeObj => feeObj.paymenttypeid === 2).fee)}` : "-") : "-"}
                          </Td>
                          <Td colSpan={1}>
                            {isSuperUser == 1 && (
                            <Box 
                            as="button" 
                            w="100%" 
                            onClick={() => {openOnlineConsultationFeeModal()}}>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                                />
                            </Box>
                              ) }
                          </Td>
                         
                        </Tr>
                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Remote Chat Fee
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600" maxW="15rem" isTruncated>
                          {feeStructureArray.length > 0 
  ? (feeStructureArray.find(feeObj => feeObj.paymenttypeid === 3)?.fee 
      ? `$${formatToDecimal(feeStructureArray.find(feeObj => feeObj.paymenttypeid === 3).fee)}`
      : "-")
  : "-"
}
  </Td>
  <Td colSpan={1}>
    {isSuperUser == 1 && (
                            <Box 
                            as="button" 
                            w="100%" 
                            onClick={() => {openRemoteChatFeeModal()}}>
                              <Image
                                float="right"
                                src="/assets/imgs/right-icon.png"
                                />
                            </Box>
                              ) }
                          </Td>
                        
                         
                        </Tr>

                        <Tr>
                          <Td fontSize="0.75rem" fontWeight="500">
                            Currency
                          </Td>
                          <Td fontSize="0.875rem" fontWeight="600" maxW="15rem" isTruncated>
                       {selectedCurrency}
  </Td>
  <Td textAlign='right'>
  <PrimaryButton  onClick={() => {openCurrencyModal()}} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                        buttonText="Change Currency" 
                        isDisabled={isSuperUser!== 1 ? true : false}
                        />
  </Td>
                        
                         
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>  
                  )}
                  {/* Fee Structure Settings  end */}


                 

                  {/* Subscription start */}
                  {isDoctor === 1 && usertypeid != 3 && (
                  <Box border="1px solid #E6E7E9" borderRadius="10px" id="subscriptionSection" ref={sectionRefs.subscriptionSection}>
                    <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Subscription
                      </Text>
                    </Box>
                    {
                      subscriptionData && subscriptionData.length > 0 ? (
                        subscriptionData.map((elem) => (
                          <Flex
                            flexDir="column"
                            gap="20px"
                            // backgroundImage={COLORS.GOLD_REWARD}
                            // backgroundImage={COLORS.BRONZE_REWARD}
                            // backgroundImage={COLORS.SILVER_REWARD}
                            backgroundImage={COLORS.PLATINUM_REWARD}
                            w="100%"
                          >
                            <Flex w="100%" flexDir="column" >
                              {/* Current plan and Joining date start */}
                              <Flex w="100%" p="1.25rem 1.5rem">
                                {/* Current plan start */}
                                <Card
                                  w="100%"
                                  border="1px solid #E6E7E9"
                                  borderRadius="12px"
                                  boxShadow="0px 8px 20px 0px #E6E7E966"
                                >
                                  <Flex
                                    flexDir="column"
                                    p="2rem"
                                    justifyContent="end"
                                    h="100%"
                                  >
                                    <Flex
                                      justifyContent="space-between"
                                      w="100%"
                                      alignItems="center"
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="0.5rem"
                                      >
                                        <Text fontSize="24px" fontWeight="600">
                                          {/* Premium */}
                                          {elem.subscriptionname}
                                        </Text>
                                        <Text
                                          fontSize="10px"
                                          fontWeight="700"
                                          border="1px solid #A1E8CE"
                                          textAlign="center"
                                          borderRadius="full"
                                          p="5px 10px"
                                          color="#0C7650"
                                        >
                                          CURRENT PLAN
                                        </Text>
                                      </Box>

                                      <Box display="flex" gap="0.87rem">
                                      {elem.subscriptionname !== 'Basic' && (
                      <PrimaryButton
                        isDisabled={elem.cancelplan == 1 ? true : false}
                        buttonText="Cancel"
                        variant="grayBtnFull"
                        onClick={() => cancelCurrentPlan(elem.subscriptionenddate)}
                        btnStyle={{ color: "#AA2F27" }}
                      />
                    )}
                    {elem.subscriptionname !== 'Premium' && (
                                        <PrimaryButton
                                          buttonText="Upgrade"
                                          onClick={openUpgradeSubscriptionModal}
                                          // isDisabled={elem.subscriptionname === "Premium"}
                                        />
                    )}
                                      </Box>
                                    </Flex>
                                    {elem.subscriptionname !== 'Basic' ? (

                                    <Text
                                      color="#384052"
                                      fontSize="1rem"
                                      fontWeight="500"
                                    >
                                      {/* $9/month{" "} */}
                                      {/* {"$" + elem.subscriptionprice + "/ month "} */}
                                      {/* {`${elem.subscriptionprice? "$"+elem.subscriptionprice+".00":"$0.00"} ${elem.duration} `} */}
                                      {`${elem.subscriptionprice ? "$" + (elem.subscriptionprice.toString().includes('.') ? elem.subscriptionprice : elem.subscriptionprice + ".00") : "$0.00"} ${elem.duration} `}

                                      <Box
                                        as="span"
                                        pl="5px"
                                        fontSize="0.5rem"
                                        fontWeight="500"
                                      >
                                        *Billed anually
                                      </Box>
                                    </Text>
                                    ): (
                                      <Text
                                      color="#384052"
                                      fontSize="1rem"
                                      fontWeight="500"
                                      >
                                        *Free
                                      </Text>
                                    )}
                                  </Flex>
                                </Card>
                                {/* Current plan end */}
                              </Flex>

                              <Box
                                bg="white"
                                borderTop="1px solid #E6E7E9"
                                borderBottom="1px solid #E6E7E9"
                                display="flex"
                                p="1.25rem 1.5rem"
                              >
                                <Box w="50%">
                                  <Text fontSize="0.87rem" fontWeight="500">
                                    Member since:
                                  </Text>
                                </Box>
                                <Box w="50%">
                                  <Text fontSize="0.87rem" fontWeight="600">
                                    {/* 2nd Jan, 2024 */}
                                    {elem.subscriptionstartdate}
                                  </Text>
                                </Box>
                              </Box>

                            {elem.subscriptionname !== 'Basic' && (

                              <Box
                                bg="white"
                                borderTop="1px solid #E6E7E9"
                                borderBottom="1px solid #E6E7E9"
                                display="flex"
                                p="1.25rem 1.5rem"
                              >
                                <Box w="50%">
                                  <Text fontSize="0.87rem" fontWeight="500">
                                    Renews on:
                                  </Text>
                                </Box>
                                <Box w="50%">
                                  <Text fontSize="0.87rem" fontWeight="600">
                                    {/* 2nd Jan, 2024 */}
                                    {elem.renewaldate}
                                  </Text>
                                </Box>
                              </Box>
                            )}

                              <Box
                                bg="white"
                                borderTop="1px solid #E6E7E9"
                                borderBottom="1px solid #E6E7E9"
                                display="flex"
                                p="1.25rem 1.5rem"
                              >
                                <Box w="50%">
                                  <Text fontSize="0.87rem" fontWeight="500">
                                    Included in your plan:
                                  </Text>
                                </Box>
                                <Box
                                  w="50%"
                                  display="flex"
                                  flexDir="column"
                                  gap="0.6rem"
                                >
                                  {elem.subscriptiondescription && elem.subscriptiondescription.length > 0 ? (
                                    elem.subscriptiondescription.map((item, index) => (
                                      <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        gap="0.5rem"
                                      >
                                        <Image
                                          w="0.75rem"
                                          h="auto"
                                          src="/assets/imgs/check-black.png"
                                        />
                                        <Text fontSize="0.87rem" fontWeight="600">
                                          {item.name}

                                        </Text>
                                      </Box>

                                    ))
                                  ) : (
                                    <p>Not Found...</p>
                                  )
                                  }
                                </Box>
                              </Box>

                            </Flex>
                          </Flex>
                        ))
                      ) : (
                        <Box p="1.25rem 1.5rem">
                          <p> No Subscription found</p>
                        </Box>
                      )
                    }

                  </Box>        
                  )}
                  {/* Subscription end */}

                  {/* Invoices start */}
                  {isDoctor === 1 && usertypeid != 3 && (
                  <Box border="1px solid #E6E7E9" borderRadius="10px" id="invoicesSection" ref={sectionRefs.invoicesSection}>
                    <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        textTransform="uppercase"
                        letterSpacing="0.10rem"
                      >
                        Invoices
                      </Text>
                    </Box>
                    {invoiceData && invoiceData.length > 0 ? (
                        <SimpleTable
                          isDataPresent={true}
                          tableHeader={{ p: "1rem 1.5rem", bg: "#FAFAFA" }}
                          headers={TableHeader}
                          tableBody={
                            <>
                              {invoiceData.map((item, index) => (
                                <Tr key={index}>
                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="600">
                                      {item.paymenttype?item.paymenttype:"-"}
                                      {/* {item.imagepath} */}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                      {formatDate(item.createddate)}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Text fontSize=".875rem" fontWeight="500">
                                      {item.amount? "$"+item.amount:"$0.00"}
                                    </Text>
                                  </Td>

                                  {/* <Td colSpan={1}>
                                    <Box
                                      as="button"
                                      justifyContent="flex-end"
                                      w="100%"
                                      display="flex"
                                      gap="0.85rem"
                                    >
                                      <Text
                                        fontSize=".875rem"
                                        textDecor="underline"
                                        fontWeight="700"
                                        onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")}
                                      >
                                        View
                                      </Text>
                                      <Image src="/assets/svgs/pdf-outline.svg" />
                                    </Box>
                                  </Td> */}
                        <Td colSpan={1}>
                                    <Box
                                      as="button"
                                      justifyContent="flex-end"
                                      w="100%"
                                      display="flex"
                                      gap="0.85rem"
                                    >
                                      <Text
                                        fontSize=".875rem"
                                        textDecor="underline"
                                        fontWeight="700"
                                        // onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")}
                                        onClick={() => {window.open(item.imagepath + item.invoiceurl, '_blank')}}
                                      >
                                        View
                                      </Text>
                                      <Image src="/assets/svgs/pdf-outline.svg"
                                      onClick={()=>{
                                        downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")
                                        showSuccessToast(`${item.paymenttype} Invoice added to downloads.`)
                                        }}  />
                                    </Box>
                                      {/* <Image src="/assets/svgs/download-white.svg" /> */}
                                    
                                  </Td>



                                </Tr>
                              ))}
                            </>
                          }
                        />
                      ) : (
                        <Box p="1.25rem 1.5rem">
                          <p>No Invoices found</p>
                        </Box>
                      )}
                  </Box>     
                  )}
                  {/* Invoices end */}

                  {/* Footer start */}
                  {isDoctor === 1 && (
                    <Footer />
                  )}
                  {/* Footer end */}
                </Box>
                {/* Left area end */}
              </Box>
              {/* Right area start */}
              <Box w="25%" h='calc(100vh - 75px)' overflow='hidden auto' borderLeft="1px solid #E6E7E9">
              {/* {isDoctor && isDoctor == 1 && ( */}
                <VisibleSidebar navItems={navItems} onNavItemClick={handleScrollToSection} activeSection={activeSection} isSetting={true} isdoctor={isDoctor}/>    
              {/* )} */}
              </Box>
              {/* Right area end */}
            </Flex>
           
            {/* Main container end */}
          </Box>
        </Flex>
      </Box>
      ) : (
      //Mobile responsive
      <Box h="calc(100vh - 120px)" overflow="hidden auto">
      {isLoading && <Loader />}
      <MobileNavbar />
      <MobileSecondaryNav
        header="Profile"
        handlebackarrow={()=> navigate('/profile')}
      />
      <Box w="100%" display="flex" p="1rem" flexDir="column" gap="1.5rem">
          {/* Doctor Badge start */}
          <Box
            w="100%"
            border="1px solid #E6E7E9"
            borderRadius="0.75rem"
            bg={`linear-gradient(180deg, rgba(255,255,255,1) 38%, rgba(20,197,133,1) 66%, rgba(28,78,197,1) 155%),url('/assets/imgs/gradient-circle.png')`}
            bgPos="center"
            bgRepeat="no-repeat"
            bgSize="cover"
          >
            <Flex alignItems="center" flexDir="column" justifyContent="space-between" w="100%">
              <Box display="flex" gap="0.8rem" w="100%" p="1rem" borderRadius="0.75rem">

                <Box boxSize="5rem" bg="#D2DCF3" borderRadius="full" overflow="hidden">
                <Image
                  src={personalData.profilepictureurl ? personalData.imagepath + personalData.profilepictureurl : "/assets/imgs/no-image.png"}
                  boxSize="100%"
                  objectFit="cover"
                />

                <Box
                  as="Button"
                  pos="relative"
                  bottom="30px"
                  left="50px"
                  bg="white"
                  zIndex="99"
                  p="6px"
                  border="1px solid #E6E7E9"
                  borderRadius="full"
                  onClick={changeImage}
                >
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageSelection}
                  />
                  <Image w="11px" h="auto" src='/assets/imgs/edit.png' />
                </Box>
                </Box>


                <Box display="flex" flexDir="column">
                  <Box
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    // mb="0.5rem"
                    gap="0.5rem"
                  >
                    <Text
                      fontSize="1.25rem"
                      display="inline-block"
                      fontWeight="600"
                      color="black"
                      lineHeight="normal"
                    >
                      {personalData.firstname} {personalData.lastname}
                    </Text>
                    <Image src="/assets/svgs/verified.svg" />
                  </Box>
                  <Text
                    fontSize=".87rem"
                    fontWeight="500"
                    color="black"
                  >
                    {personalData.email}
                  </Text>
                </Box>
              </Box>

              <Box p="1rem" display="flex" w="100%">

                <Box
                  as="button"
                  p="0.75rem 1.25rem"
                  border="1px solid #E6E7E9"
                  borderRadius=".625rem"
                  bg="white"
                  w="100%"
                  onClick={openShareBadgeModal}
                  boxShadow="0px 0px 0px 3px #E6E7E94D, 0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                >
                  <Text fontSize=".87rem" fontWeight="600">
                    Share badge
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
          {/* Doctor Badge end */}
          {/* Personal details start */}
          <TableContainer
            border="1px solid #E6E7E9"
            borderRadius="10px"
            w="100%"
            id="personalSection"
            ref={sectionRefs.personalSection}
          >
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td
                    p="1.5rem"
                    colSpan={3}
                    fontSize="0.75rem"
                    fontWeight="600"
                    textTransform="uppercase"
                    letterSpacing="0.10rem"
                  >
                    Personal details
                  </Td>
                </Tr>
                <Tr onClick={() => {
                        handleNameEdit(personalData.firstname, personalData.lastname)
                      }}>
                  <Td fontSize="0.75rem" w="100px" fontWeight="500">
                    Full Name
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {personalData.firstname} {personalData.lastname}
                  </Td>
                </Tr>
                <Tr onClick={opengendermodel}>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Gender
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {/* {personalData.gender == "1" ? "Female" : personalData.gender == "3" ? "Other" : "Male"} */}
                    {personalData.gender == "1" ? "Female" : personalData.gender == "3" ? "Other" : personalData.gender == '2' ? "Male" : ""}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Date of birth
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {/* {
                      personalData.dob && personalData.dob ?
                        personalData.dob
                        :
                        '-'
                    } */}
                    
                    {formatDate(personalData.dob)}
                  </Td>
                  <Td colSpan={1}>
                    <Box
                      as="button"
                      w="100%"
                      // onClick={() => verifyOtp.current.openModal()}
                    >
                      <Image
                        float="right"
                        src="/assets/imgs/right-icon.png"
                      />
                    </Box>
                  </Td>
                </Tr>
                <Tr onClick={() => {
                        handleMobileEdit(personalData)
                      }}>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Phone number
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {`${personalData.countrycode && personalData.countrycode ? (personalData.countrycode): ('+1')}${"  "}${personalData.mobileno}`}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Email Address
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {/* abraarpark@apollo.co */}
                    {personalData.email}
                  </Td>
                  <Td colSpan={1}>
                    <Box as="button" w="100%">
                      <Image
                        float="right"
                        src="/assets/imgs/right-icon.png"
                      />
                    </Box>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          {/* Personal details end */}

          {/* Professional details start */}
          <TableContainer
            border="1px solid #E6E7E9"
            borderRadius="10px"
            w="100%"
            id="professionalSection"
            ref={sectionRefs.professionalSection}
          >
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td
                    p="1.5rem"
                    colSpan={3}
                    fontSize="0.75rem"
                    fontWeight="600"
                    textTransform="uppercase"
                    letterSpacing="0.10rem"
                  >
                    Professional details
                  </Td>
                </Tr>
                <Tr onClick={() => {handleHospitalEdit(personalData.hospitalname)
                      }}>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Hospital/Clinic
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {/* Apollo hospital */}
                    {
                      // personalData.hospitalname ?
                      //   personalData.hospitalname
                      //   :
                      //   '-'
                      personalData.hospitalname
                    }
                  </Td>
                  
                </Tr>
                <Tr onClick={() => {handleDegreeEdit(personalData.qualification)
                    }}>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Degree
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {/* MBBS, MD (PGI), DNB, FICOG */}
                    {
                      // personalData.qualification ?
                      //   personalData.qualification
                      //   :
                      //   '-'
                      personalData.qualification
                    }
                  </Td>
                </Tr>
                <Tr onClick={() => {handleSpecialityEdit(personalData.specality)
                    }}>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Specialization
                  </Td>
                  {/* Do not delete this */}
                  {/* <Td fontSize="0.875rem" fontWeight="600">
                    {AvailabilityData && AvailabilityData.doctorspeciality ? (
                        AvailabilityData.doctorspeciality.map((speciality, index) => (
                          <span key={index}>
                            {Array.isArray(speciality.specialityname) ? (
                              speciality.specialityname.join(", ")
                            ) : (
                              speciality.specialityname
                            )}
                            {index < AvailabilityData.doctorspeciality.length - 1 && ', '}
                          </span>
                        ))
                      ) : (
                        personalData.specality ? personalData.specality : '-'
                      )}
                  </Td> */}
                  <Td fontSize="0.875rem" fontWeight="600">
                    {
                      // personalData.specality ?
                      //   personalData.specality
                      //   :
                      //   '-'
                      personalData.specality
                    }
                  </Td>
                </Tr>
                <Tr  onClick={() => {handleExperienceEdit(personalData.yearsofexperience)
                    }}>
                  <Td fontSize="0.75rem" fontWeight="500">
                    Years of experience
                  </Td>
                  <Td fontSize="0.875rem" fontWeight="600">
                    {/* 12 */}
                    {
                      // personalData.yearsofexperience ?
                      //   personalData.yearsofexperience
                      //   :
                      //   '-'
                      personalData.yearsofexperience
                    }
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          {/* Professional details end */}

          {/* Documents start */}
          <Box border="1px solid #E6E7E9" borderRadius="10px" id="documentSection" ref={sectionRefs.documentSection}>
            <HStack
              justifyContent="space-between"
              p="1.5rem"
              borderBottom="1px solid #E6E7E9"
            
            >
              <Text
                fontSize="0.75rem"
                fontWeight="600"
                textTransform="uppercase"
                letterSpacing="0.10rem"
              >
                DOCUMENTS
              </Text>
              <Box
                as="button"
                fontSize="0.875rem"
                fontWeight="700"
                textDecor="underline"
                onClick={openUploadDocumentModal}
              >
                Upload document
              </Box>
            </HStack>
            <Flex
              flexWrap="wrap"
              spacing={4}
              p="1.25rem 1.5rem"
              gap="1rem"
            >
              {documentData && documentData.length > 0 ? (
                documentData.map((doc, index) => (
                  <Flex
                    key={index}
                    bg="white"
                    flexDir="column"
                    w="100%"
                    p="16px"
                    border="1px solid #E6E7E9"
                    borderRadius="12px"
                  >

                    <Box display="flex" gap="15px" w="100%">
                      <Box as="a" cursor="pointer"  boxSize="2.5rem" 
                      // onClick={()=>downloadDocument(doc.imagepath + doc.documenturl, getFilenameExtension(doc.documenturl))}>
                      onClick={()=>downloadDocument(doc.imagepath + doc.documenturl, "Document")}>
                      {/* <Image src={getIconForExtension(doc.documenturl)} /> */}
                      <Image src={getIconForExtension(getFilenameExtension(doc.documenturl))} />
                      </Box>
                      <Box w="100%">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          h="auto"
                          w="100%"
                        >
                          <Box as="a" cursor="pointer" w="8rem" h="auto" onClick={()=>downloadDocument(doc.imagepath + doc.documenturl, getFilenameExtension(doc.documenturl))}>
                            <Text fontSize="14px" fontWeight="600" className="">
                              {/* {} */}
                              {/* {`${doc.documenturl} `} */}
                              {`${doc.documenttype}.${getFilenameExtension(doc.documenturl)}`}
                              {/* {doc.imagepath} */}
                            </Text>
                            <Text fontSize="12px" fontWeight="500" className="" color='#384052'>
                              {formatSize(doc.documentsize)}
                            </Text>
                          </Box>
                          <Box as="button"
                          onClick={()=>handleDeleteDocument(doc.id)}>
                            <Image
                              w="16px"
                              h="18px"
                              src="/assets/svgs/delete.svg"
                            />
                          </Box>
                        </Box>

                      </Box>
                    </Box>
                  </Flex>
                ))
              ) : (
                <p>
                  No Documents found
                </p>

              )}

            </Flex>
          </Box>
          {/* Documents end */}
          
            {/* {/ Availability start /} */}
            <TableContainer
  border="1px solid #E6E7E9"
  borderRadius="10px"
  w="100%"
  id="availSection"
  ref={sectionRefs.availSection}
>
  <Table variant="simple">
    <Tbody>
      <Tr>
        <Td
          p="1.5rem"
          colSpan={3}
          fontSize="0.75rem"
          fontWeight="600"
          textTransform="uppercase"
          letterSpacing="0.10rem"
        >
          Availability
        </Td>
        {/* <PrimaryButton onClick={editavailability} btnStyle={{ w: "fit-content" }} variant="mdBtn"
          buttonText="Add" /> */}
      </Tr>

      {AvailabilityData && AvailabilityData.availtimings && AvailabilityData.availtimings.availablity ? (
        <>
          <Tr>
            <Td fontSize="0.75rem" fontWeight="500">
              {AvailabilityData.availtimings.availablity.clinicalvisits.reduce((acc, item) => {
                if (item.timings && item.timings.length > 0) {
                  item.timings.forEach(timing => {
                    if (timing.fromtime && timing.totime) {
                      const existingIndex = acc.findIndex(
                        elem =>
                          elem.fromtime === timing.fromtime &&
                          elem.totime === timing.totime
                      );

                      if (existingIndex !== -1) {
                        acc[existingIndex].days.push({ day: item.day, isavailable: item.isavailable });
                      } else {
                        acc.push({
                          fromtime: timing.fromtime,
                          totime: timing.totime,
                          days: [{ day: item.day, isavailable: item.isavailable }],
                        });
                      }
                    }
                  });
                } else {
                  acc.push({
                    fromtime: '-',
                    totime: '-',
                    days: [{ day: item.day, isavailable: item.isavailable }],
                  });
                }
                return acc;
              }, []).map((item, index) => (
                item.days.map((dayInfo, dayIndex) => (
                  <Text key={`${index}-${dayIndex}`} fontSize="0.75rem" w="11rem" fontWeight="600" color="#061027">
                    {dayInfo.isavailable === 0 ? `${dayInfo.day}` : `${dayInfo.day}`}
                  </Text>
                ))
              ))}
            </Td>
            <Td fontSize="0.875rem" fontWeight="600">
              <Box display="flex" gap="0.5rem">
                <Image src="/assets/svgs/hospital-active.svg" />
                <Image src="/assets/svgs/videocam-active.svg" />
                <Text fontSize="14px" fontWeight="600" color="#061027">
                  {AvailabilityData.availtimings.availablity.clinicalvisits.reduce((acc, item) => {
                    if (item.timings && item.timings.length > 0) {
                      item.timings.forEach(timing => {
                        if (timing.fromtime && timing.totime) {
                          const existingIndex = acc.findIndex(
                            elem =>
                              elem.fromtime === timing.fromtime &&
                              elem.totime === timing.totime
                          );

                          if (existingIndex !== -1) {
                            acc[existingIndex].days.push({ day: item.day, isavailable: item.isavailable });
                          } else {
                            acc.push({
                              fromtime: timing.fromtime,
                              totime: timing.totime,
                              days: [{ day: item.day, isavailable: item.isavailable }],
                            });
                          }
                        }
                      });
                    } else {
                      acc.push({
                        fromtime: '-',
                        totime: '-',
                        days: [{ day: item.day, isavailable: item.isavailable }],
                      });
                    }
                    return acc;
                  }, []).map((item, index) => (
                    item.days.map((dayInfo, dayIndex) => (
                      <Text key={`${index}-${dayIndex}`} fontSize="0.75rem" fontWeight="600" color="#061027">
                        {dayInfo.isavailable === 0 ? '-' : `${item.fromtime} - ${item.totime}`}
                      </Text>
                    ))
                  ))}
                </Text>
              </Box>
            </Td>
            <Td colSpan={1}>
              <Box as="button" w="100%" onClick={editavailability}>
                <Image
                  float="right"
                  src="/assets/imgs/right-icon.png"
                />
              </Box>
            </Td>
          </Tr>

          <Tr>
            <Td fontSize="0.75rem" fontWeight="500">
              {AvailabilityData.availtimings.availablity.videovisit.reduce((acc, item) => {
                if (item.timings && item.timings.length > 0) {
                  item.timings.forEach(timing => {
                    if (timing.fromtime && timing.totime) {
                      const existingIndex = acc.findIndex(
                        elem =>
                          elem.fromtime === timing.fromtime &&
                          elem.totime === timing.totime
                      );

                      if (existingIndex !== -1) {
                        acc[existingIndex].days.push({ day: item.day, isavailable: item.isavailable });
                      } else {
                        acc.push({
                          fromtime: timing.fromtime,
                          totime: timing.totime,
                          days: [{ day: item.day, isavailable: item.isavailable }],
                        });
                      }
                    }
                  });
                } else {
                  acc.push({
                    fromtime: '-',
                    totime: '-',
                    days: [{ day: item.day, isavailable: item.isavailable }],
                  });
                }
                return acc;
              }, []).map((item, index) => (
                item.days.map((dayInfo, dayIndex) => (
                  <Text key={`${index}-${dayIndex}`} fontSize="0.75rem" w="11rem" fontWeight="600" color="#061027">
                    {dayInfo.isavailable === 0 ? `${dayInfo.day}` : `${dayInfo.day}`}
                  </Text>
                  ))
              ))}
            </Td>
            <Td fontSize="0.875rem" fontWeight="600">
              <Box display="flex" gap="0.5rem">
                <Image src="/assets/svgs/hospital-in-active.svg" />
                <Image src="/assets/svgs/videocam-in-active.svg" />
                <Text fontSize="14px" fontWeight="600" color="#061027">
                  {AvailabilityData.availtimings.availablity.videovisit.reduce((acc, item) => {
                    if (item.timings && item.timings.length > 0) {
                      item.timings.forEach(timing => {
                        if (timing.fromtime && timing.totime) {
                          const existingIndex = acc.findIndex(
                            elem =>
                              elem.fromtime === timing.fromtime &&
                              elem.totime === timing.totime
                          );

                          if (existingIndex !== -1) {
                            acc[existingIndex].days.push({ day: item.day, isavailable: item.isavailable });
                          } else {
                            acc.push({
                              fromtime: timing.fromtime,
                              totime: timing.totime,
                              days: [{ day: item.day, isavailable: item.isavailable }],
                            });
                          }
                        }
                      });
                    } else {
                      acc.push({
                        fromtime: '-',
                        totime: '-',
                        days: [{ day: item.day, isavailable: item.isavailable }],
                      });
                    }
                    return acc;
                  }, []).map((item, index) => (
                    item.days.map((dayInfo, dayIndex) => (
                      <Text key={`${index}-${dayIndex}`} fontSize="0.75rem" fontWeight="600" color="#061027">
                        {dayInfo.isavailable === 0 ? '-' : `${item.fromtime} - ${item.totime}`}
                      </Text>
                    ))
                  ))}
                </Text>
              </Box>
            </Td>
            <Td colSpan={1}>
              <Box as="button" w="100%" onClick={editavailability}>
                <Image
                  float="right"
                  src="/assets/imgs/right-icon.png"
                />
              </Box>
            </Td>
          </Tr>
        </>
      ) : (
        <Tr>
          <Td colSpan={3} textAlign="center">No records available</Td>
        </Tr>
      )}
    </Tbody>
  </Table>
</TableContainer>
          {/* {/ Availability end /} */}

          
          
          {/* Alerts start */}
          <Box border="1px solid #E6E7E9" borderRadius="10px" id="alertSection" ref={sectionRefs.alertSection}>
            <Box p="1rem" px="1.5rem" display="flex" w="100%" justifyContent="space-between" alignItems="center" borderBottom="1px solid #E6E7E9">
              <Text
                fontSize="0.75rem"
                fontWeight="600"
                textTransform="uppercase"
                letterSpacing="0.10rem"
              >
                Alerts
              </Text>
              <PrimaryButton onClick={setAlert} btnStyle={{ w: "fit-content" }} variant="mdBtn"
                buttonText="Set Alerts" />
            </Box>
            <Flex gap="1rem">
              <Card p="1.25rem" w="100%" shadow="none">
                <CardHeader p="0" pb="0.875rem">
                  <Flex>
                    <Text fontSize="1rem" fontWeight="600">
                      Appointment booked
                    </Text>
                    <Spacer />
                    <Switch colorScheme="green" size="md"
                      isChecked={appointmentAlertCheck}
                      onChange={handleReminderRadio} />
                  </Flex>
                </CardHeader>
                <CardBody bg="#F9FAFB" borderRadius="0.75rem">
                  <CheckboxGroup
                    colorScheme="green" value={selectedValuesAppoitments}
                  >
                    <Stack spacing={3} direction="column">
                      <Checkbox
                        sx={checkboxLabelStyles} value='notification'
                        onChange={handleAppointmentNotif}
                      >
                        Notification
                      </Checkbox>
                      <Checkbox sx={checkboxLabelStyles} value="sms"
                        onChange={handleAppointmentSMS}>
                        SMS
                      </Checkbox>
                      <Checkbox sx={checkboxLabelStyles} value="email"
                        onChange={handleAppointmentEmail}>
                        Email
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  <DropdownInput
                    inputStyleCss={{mt: "1rem" }}
                    variant="fullSide"
                    dropDownPlace="Remind before"
                    options={["10 mins", "20 mins", "30 mins"]}

                    defaultOption={remindBeforeAppointmentsState}
                    onClick={selectRemindBeforeAppointments}
                  />
                </CardBody>
                <Divider my="1rem" opacity="0.2" />
                <Flex>
                  <Text fontSize="16px" fontWeight="600">
                    Messages
                  </Text>
                  <Spacer />
                  <Switch colorScheme="green" size="md"
                    isChecked={messageAlertCheck}
                    onChange={handleReminderRadio2} />
                </Flex>
              </Card>
            </Flex>
          </Box>
          {/* Alerts end */}

          

          {/* Subscription start */}
          <Box border="1px solid #E6E7E9" borderRadius="10px" id="subscriptionSection" ref={sectionRefs.subscriptionSection}>
            <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
              <Text
                fontSize="0.75rem"
                fontWeight="600"
                textTransform="uppercase"
                letterSpacing="0.10rem"
              >
                Subscription
              </Text>
            </Box>
            {
              subscriptionData && subscriptionData.length > 0 ? (
                subscriptionData.map((elem) => (
                  <Flex
                    flexDir="column"
                    gap="20px"
                    // backgroundImage={COLORS.GOLD_REWARD}
                    // backgroundImage={COLORS.BRONZE_REWARD}
                    // backgroundImage={COLORS.SILVER_REWARD}
                    backgroundImage={COLORS.PLATINUM_REWARD}
                    w="100%"
                  >
                    <Flex w="100%" flexDir="column" >
                      {/* Current plan and Joining date start */}
                      <Flex w="100%" p="1.25rem 1.5rem">
                        {/* Current plan start */}
                        <Card
                          w="100%"
                          border="1px solid #E6E7E9"
                          borderRadius="12px"
                          boxShadow="0px 8px 20px 0px #E6E7E966"
                        >
                          <Flex
                            flexDir="column"
                            p="1rem"
                            gap="1rem"
                            justifyContent="end"
                            h="100%"
                          >
                            <Flex
                              justifyContent="space-between"
                              w="100%"
                              alignItems="center"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="0.5rem"
                              >
                                <Text fontSize="24px" fontWeight="600">
                                  {/* Premium */}
                                  {elem.subscriptionname}
                                </Text>
                                <Text
                                  fontSize="10px"
                                  fontWeight="700"
                                  border="1px solid #A1E8CE"
                                  textAlign="center"
                                  borderRadius="full"
                                  p="5px 10px"
                                  color="#0C7650"
                                >
                                  CURRENT PLAN
                                </Text>
                              </Box>
                            </Flex>
                            <Text
                              color="#384052"
                              fontSize="1rem"
                              fontWeight="500"
                            >
                              {/* $9/month{" "} */}
                              {/* {"$" + elem.subscriptionprice + "/ month "} */}
                              {`${elem.subscriptionprice? "$"+elem.subscriptionprice+".00":"$0.00"} ${elem.duration} `}
                              <Box
                                as="span"
                                pl="5px"
                                fontSize="0.5rem"
                                fontWeight="500"
                              >
                                *Billed anually
                              </Box>
                            </Text>
                          <Box display="flex" gap="0.87rem">
                            {elem.subscriptionname !== 'Basic' && (
                              <PrimaryButton
                                isDisabled={elem.cancelplan == 1 ? true : false}
                                buttonText="Cancel"
                                variant="grayBtnFull"
                                onClick={() => cancelCurrentPlan(elem.subscriptionenddate)}
                                btnStyle={{ color: "#AA2F27" }}
                              />
                            )}
                              <PrimaryButton
                                buttonText="Upgrade"
                                onClick={openUpgradeSubscriptionModal}
                              />
                          </Box>
                          </Flex>
                        </Card>
                        {/* Current plan end */}
                      </Flex>

                      <Box
                        bg="white"
                        borderTop="1px solid #E6E7E9"
                        borderBottom="1px solid #E6E7E9"
                        display="flex"
                        p="1.25rem 1.5rem"
                      >
                        <Box w="50%">
                          <Text fontSize="0.87rem" fontWeight="500">
                            Member since:
                          </Text>
                        </Box>
                        <Box w="50%">
                          <Text fontSize="0.87rem" fontWeight="600">
                            {/* 2nd Jan, 2024 */}
                            {elem.subscriptionstartdate}
                          </Text>
                        </Box>
                      </Box>

                      <Box
                        bg="white"
                        borderTop="1px solid #E6E7E9"
                        borderBottom="1px solid #E6E7E9"
                        display="flex"
                        p="1.25rem 1.5rem"
                      >
                        <Box w="50%">
                          <Text fontSize="0.87rem" fontWeight="500">
                            Renews on:
                          </Text>
                        </Box>
                        <Box w="50%">
                          <Text fontSize="0.87rem" fontWeight="600">
                            {/* 2nd Jan, 2024 */}
                            {elem.renewaldate}
                          </Text>
                        </Box>
                      </Box>

                      <Box
                        bg="white"
                        borderTop="1px solid #E6E7E9"
                        borderBottom="1px solid #E6E7E9"
                        display="flex"
                        p="1.25rem 1.5rem"
                      >
                        <Box w="50%">
                          <Text fontSize="0.87rem" fontWeight="500">
                            Included in your plan:
                          </Text>
                        </Box>
                        <Box
                          w="50%"
                          display="flex"
                          flexDir="column"
                          gap="0.6rem"
                        >
                          {elem.subscriptiondescription && elem.subscriptiondescription.length > 0 ? (
                            elem.subscriptiondescription.map((item, index) => (
                              <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                gap="0.5rem"
                              >
                                <Image
                                  w="0.75rem"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="0.87rem" fontWeight="600">
                                  {item.name}

                                </Text>
                              </Box>

                            ))
                          ) : (
                            <p>Not Found...</p>
                          )
                          }
                        </Box>
                      </Box>

                    </Flex>
                  </Flex>
                ))
              ) : (
                <Box p="1.25rem 1.5rem">
                  <p> No Subscription found</p>
                </Box>
              )
            }

          </Box>
          {/* Subscription end */}

          {/* Invoices start */}
          <Box border="1px solid #E6E7E9" borderRadius="10px" id="invoicesSection" ref={sectionRefs.invoicesSection}>
            <Box p="1.5rem" borderBottom="1px solid #E6E7E9">
              <Text
                fontSize="0.75rem"
                fontWeight="600"
                textTransform="uppercase"
                letterSpacing="0.10rem"
              >
                Invoices
              </Text>
            </Box>
            {invoiceData && invoiceData.length > 0 ? (
                <SimpleTable
                  isDataPresent={true}
                  tableHeader={{ p: "1rem 1.5rem", bg: "#FAFAFA" }}
                  headers={TableHeader}
                  tableBody={
                    <>
                      {invoiceData.map((item, index) => (
                        <Tr key={index}>
                          <Td>
                            <Text fontSize=".875rem" fontWeight="600">
                              {item.paymenttype?item.paymenttype:"-"}
                              {/* {item.imagepath} */}
                            </Text>
                          </Td>

                          <Td>
                            <Text fontSize=".875rem" fontWeight="500">
                              {formatDate(item.createddate)}
                            </Text>
                          </Td>

                          <Td>
                            <Text fontSize=".875rem" fontWeight="500">
                              {item.amount? "$"+item.amount+".00":"$0.00"}
                            </Text>
                          </Td>

                          {/* <Td colSpan={1}>
                            <Box
                              as="button"
                              justifyContent="flex-end"
                              w="100%"
                              display="flex"
                              gap="0.85rem"
                            >
                              <Text
                                fontSize=".875rem"
                                textDecor="underline"
                                fontWeight="700"
                                onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")}
                              >
                                View
                              </Text>
                              <Image src="/assets/svgs/pdf-outline.svg" />
                            </Box>
                          </Td> */}
                        <Td colSpan={1}>
                            <Box
                              as="button"
                              justifyContent="flex-end"
                              w="100%"
                              display="flex"
                              gap="0.85rem"
                            >
                              <Text
                                fontSize=".875rem"
                                textDecor="underline"
                                fontWeight="700"
                                // onClick={()=>downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")}
                                onClick={() => {window.open(item.imagepath + item.invoiceurl, '_blank')}}
                              >
                                View
                              </Text>
                              <Image src="/assets/svgs/pdf-outline.svg"
                              onClick={()=>{
                                downloadDocument(item.imagepath + item.invoiceurl, "Invoice.pdf")
                                showSuccessToast(`${item.paymenttype} Invoice added to downloads.`)
                                }}  />
                            </Box>
                              {/* <Image src="/assets/svgs/download-white.svg" /> */}
                            
                          </Td>



                        </Tr>
                      ))}
                    </>
                  }
                />
              ) : (
                <Box p="1.25rem 1.5rem">
                  <p>No Invoices found</p>
                </Box>
              )}
          </Box>
          {/* Invoices end */}

          {/* Footer start */}
          <Footer />
          {/* Footer end */}
        </Box>
      </Box>
    )}



              <ModalRight
    ref={addConditionModalInsideEdit}
    modalWidth="360px"
    header="Update Degree"
    body={
      <>
        <SearchBar passInputPlace="Search Degree" onChange={(e) => setSearchValue(e.target.value)} />
        <RadioGroup>
          <Stack direction="column" gap="0">
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid #e2e8f0"
            >
            <Box display="flex" justifyContent="space-between" w="100%">
<Stack direction="column" w="100%" gap="0">
{conditionsList && conditionsList.length > 0 ? (
<>
  {/* Filter and display conditions */}
  {conditionsList.filter((condition) =>
        condition.label.toLowerCase().includes(searchValue.toLowerCase())
      ).length === 0 ? (
        <Text fontSize="16px" color="gray.500" p="15px">
          No Degree found!
        </Text>
      ) : (
        conditionsList
          .filter((condition) =>
            condition.label.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((condition) => (
            <label key={condition.id}>
              <Box
                display="flex"
                justifyContent="space-between"
                borderBottom="1px solid #e2e8f0"
                p="15px"
                pl="5px"
                minH="50px"
                width="100%"
                sx={{
                  _hover: { border: "2px solid #384052", borderRadius: "10px" },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Text fontSize="16px" lineHeight="normal" fontWeight="600">
                    {condition.label}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="end">
                  <Checkbox
                    colorScheme="green"
                    sx={{
                      ".chakra-checkbox__control": {
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                      },
                    }}
                    onChange={() => handleCheckboxChangeForEdit(condition)}
                    isChecked={selectedConditions.some(
                      (item) => item.label === condition.label
                    )}
                  ></Checkbox>
                </Box>
              </Box>
            </label>
          ))
      )}

  {/* Display "No matching conditions found" only if no conditions were found in any category */}
  {/* {conditionsList.every((category) =>
    category.data.every((condition) =>
      !condition.condition.toLowerCase().includes(searchValue.toLowerCase())
    )
  ) && (
    <Box p="15px" fontSize="16px" fontWeight="600" textAlign="center">
    No matching conditions found!
  </Box>
  )} */}
</>
) : (
<p>Loading...</p>
)}
<Divider/>
    <br/>
    {/* <Heading as="h2" fontSize="12px" pl="5px" textTransform='uppercase' fontWeight="600" my="10px">
              Other Condition
            </Heading>
  <PrimaryInput
    inputPlace="Condition"
    variant="bothSide"
          onChange={handleCondition} inputValue={typedCondition}
          />
          <br/> */}
</Stack>
</Box>

            </Box>
          </Stack>
        </RadioGroup>
        
            
      </>
      
    }
    footerActions={<>
      <Box
        w="100%"
        display="flex"
        flexDirection="row"
        gap="15px">

        <PrimaryButton
          buttonText="Update Degree"
          isDisabled={false}
            onClick={handleDegreeChanges}
        />
      </Box>
    </>}
  />
           




      <DynamicModal
        ref={editName}
        modalHeader="Edit name"
        modalBody={
          <>
            <Box>
              {/* <PrimaryInput inputPlace="First name" defaultValue={personalData.firstname} />
              <PrimaryInput variant="downSide" inputPlace="Last name" defaultValue={personalData.lastname} /> */}

              <PrimaryInput
                inputPlace="First name*"
                defaultValue={personalData.firstname}
                inputValue={editedFirstName}
                onChange={firstNameChange}
              />
              <PrimaryInput
                variant='downSide'
                inputPlace="Last name"
                defaultValue={personalData.lastname}
                inputValue={editedLastName}
                onChange={lastNameChange}
              />
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Name" onClick={handleNameChanges}/>
          </>
        }
      />

      <DynamicModal
        ref={editGender}
        modalHeader="Edit gender"
        modalBody={
          <>
            <Box>
              <RadioGroup defaultValue={selectedGender} onChange={genderChange}>
                <Box
                  as="label"
                  display="flex"
                  justifyContent="space-between"
                  py="1rem"
                  pt="0"
                  onClick={() => genderChange('2')}
                >
                  <Text>Male</Text>
                  <Radio colorScheme="green" value="2" />
                </Box>
                <Box
                  as="label"
                  borderTop="1px solid #E2E4E9"
                  borderBottom="1px solid #E2E4E9"
                  display="flex"
                  justifyContent="space-between"
                  py="1rem"
                  onClick={() => genderChange('1')}
                >
                  <Text>Female</Text>
                  <Radio colorScheme="green" value="1" />
                </Box>
                <Box
                  as="label"
                  display="flex"
                  justifyContent="space-between"
                  py="1rem"
                  pb="0"
                  onClick={() => genderChange('3')}
                >
                  <Text>Others</Text>
                  <Radio colorScheme="green" value="3" />
                </Box>
              </RadioGroup>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Gender" onClick={handleGenderChanges} />
          </>
        }
      />

      <DynamicModal
        ref={upgradePlan}
        modalMaxWidth={true}
        modalWidth="auto"
        modalHeader="Upgrade plan"
        modalBody={
          <>
            <Box display="flex" flexWrap={{base: "wrap", lg: "nowrap"}} gap="1rem">
              
            {currentPlanId != 3 &&
            subscriptionPlans && subscriptionPlans[2] &&
              <Card
                className="border-pro-plan"
                mb="20px"
                boxShadow="0px 8px 24px 0px #B4B4B452"
              >
                <CardHeader
                  backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                  borderRadius="12px"
                >
                  <Flex flexDir="column" justifyContent="end" h="100%">
                    <Flex
                      justifyContent="space-between"
                      w="100%"
                      alignItems="center"
                    >
                      <Text fontSize="24px" fontWeight="600">
                      {subscriptionPlans[2].subscriptionname}
                      </Text>
                    </Flex>
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                    {subscriptionPlans[2].subscriptionpriceunit == "USD" ? "$" : ""} {subscriptionPlans[2].subscriptionprice.toString().includes('.') ? subscriptionPlans[2].subscriptionprice : subscriptionPlans[2].subscriptionprice}/{subscriptionPlans[2].duration == "Monthly" ? "month" : ""}
                            <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                        *Billed anually
                      </Box>
                    </Text>
                  </Flex>
                  <Divider mt="15px" />
                </CardHeader>

                <CardBody py="0">
                  <Flex flexDir="column" gap="10px">
                  {subscriptionPlans && subscriptionPlans[2] && subscriptionPlans[2].subscriptiondescription.map(item => (
                              <Box key={item.name} display="flex" gap="10px" alignItems="center">
                                <Image
                                  w="12px"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="14px" fontWeight="700">
                                  {item.name}
                                </Text>
                              </Box>
                            ))}
                  </Flex>
                </CardBody>

                <CardFooter>
                  <PrimaryButton buttonText="Upgrade" btnStyle={{ m: "0" }} 
                   onClick={()=>handleChangePlan(3, subscriptionPlans[2].subscriptionprice)}/>
                </CardFooter>
              </Card>
        }

        
            {currentPlanId != 2 &&
            subscriptionPlans && subscriptionPlans[1] &&
              <Card
                className="border-pro-plan"
                mb="20px"
                boxShadow="0px 8px 24px 0px #B4B4B452"
                w={{base: "100%", lg: "unset"}}
              >
                <CardHeader
                  backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                  borderRadius="12px"
                >
                  <Flex flexDir="column" justifyContent="end" h="100%">
                    <Flex
                      justifyContent="space-between"
                      w="100%"
                      alignItems="center"
                    >
                      <Text fontSize="24px" fontWeight="600">
                      {subscriptionPlans[1].subscriptionname}
                      </Text>
                    </Flex>
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                    {subscriptionPlans[1].subscriptionpriceunit == "USD" ? "$" : ""} {subscriptionPlans[1].subscriptionprice}/{subscriptionPlans[1].duration == "Monthly" ? "month" : ""}
                            <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                        *Billed anually
                      </Box>
                    </Text>
                  </Flex>
                  <Divider mt="15px" />
                </CardHeader>

                <CardBody py="0">
                  <Flex flexDir="column" gap="10px">
                  {subscriptionPlans && subscriptionPlans[1] && subscriptionPlans[1].subscriptiondescription.map(item => (
                              <Box key={item.name} display="flex" gap="10px" alignItems="center">
                                <Image
                                  w="12px"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="14px" fontWeight="700">
                                  {item.name}
                                </Text>
                              </Box>
                            ))}
                  </Flex>
                </CardBody>

                <CardFooter>
                  <PrimaryButton 
                  buttonText={currentPlanId == "1" ? "Upgrade" :"Downgrade"} btnStyle={{ m: "0" }}
                  
                  variant={currentPlanId == "1" ? "" :"grayBtnFull"} 
                   onClick={()=>handleChangePlan(2, subscriptionPlans[1].subscriptionprice)} />
                </CardFooter>
              </Card>
        }


            {currentPlanId != 1 &&
            subscriptionPlans && subscriptionPlans[0] &&
              <Card
                // className="border-pro-plan"
                // mb="20px"
                // boxShadow="0px 8px 24px 0px #B4B4B452"
                mb="20px"
                boxShadow="0px 8px 24px 0px #B4B4B452"
                borderRadius="0.75rem"
                border="1px solid #E2E4E9"
                w={{base: "100%", lg: "unset"}}
              >
                <CardHeader
                 // backgroundImage="linear-gradient(180deg, #72DCB6 -675%, rgba(114, 220, 182, 0) 100%)"
                  borderRadius="12px"
                >
                  <Flex flexDir="column" justifyContent="end" h="100%">
                    <Flex
                      justifyContent="space-between"
                      w="100%"
                      alignItems="center"
                    >
                      <Text fontSize="24px" fontWeight="600">
                      {subscriptionPlans[0].subscriptionname}
                      </Text>
                    </Flex>
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                    {subscriptionPlans[0].subscriptionpriceunit == "USD" ? "$" : ""} {subscriptionPlans[0].subscriptionprice}/{subscriptionPlans[0].duration == "Monthly" ? "month" : ""}
                            <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                        *Billed anually
                      </Box>
                    </Text>
                  </Flex>
                  <Divider mt="15px" />
                </CardHeader>

                <CardBody py="0">
                  <Flex flexDir="column" gap="10px">
                  {subscriptionPlans && subscriptionPlans[0] && subscriptionPlans[0].subscriptiondescription.map(item => (
                              <Box key={item.name} display="flex" gap="10px" alignItems="center">
                                <Image
                                  w="12px"
                                  h="auto"
                                  src="/assets/imgs/check-black.png"
                                />
                                <Text fontSize="14px" fontWeight="700">
                                  {item.name}
                                </Text>
                              </Box>
                            ))}
                  </Flex>
                </CardBody>

                <CardFooter>
                  <PrimaryButton
                    buttonText="Downgrade"
                    variant="grayBtnFull"
                    btnStyle={{ m: "0" }}
                    onClick={()=>handleChangePlan(1)}
                  />
                </CardFooter>
              </Card>
        
        }
            
            {/* {subscriptionPlans && subscriptionPlans[1] &&
              <Card
                mb="20px"
                boxShadow="0px 8px 24px 0px #B4B4B452"
                borderRadius="0.75rem"
                border="1px solid #E2E4E9"
              >
                <CardHeader borderRadius="12px">
                  <Flex flexDir="column" justifyContent="end" h="100%">
                    <Flex
                      justifyContent="space-between"
                      w="100%"
                      alignItems="center"
                    >
                      <Text fontSize="24px" fontWeight="600">
                        Pro
                      </Text>
                    </Flex>
                    <Text color="#384052" fontSize="20px" fontWeight="500">
                      $19/month
                      <Box as="span" pl="5px" fontSize="8px" fontWeight="500">
                        *Billed anually
                      </Box>
                    </Text>
                  </Flex>
                  <Divider mt="15px" />
                </CardHeader>

                <CardBody py="0">
                  <Flex flexDir="column" gap="10px">
                    <Box display="flex" gap="10px" alignItems="center">
                      <Image
                        w="12px"
                        h="auto"
                        src="/assets/imgs/check-black.png"
                      />
                      <Text fontSize="14px" fontWeight="700">
                        Unlimited{" "}
                        <Box as="span" fontWeight="500">
                          appointments per month
                        </Box>
                      </Text>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center">
                      <Image
                        w="12px"
                        h="auto"
                        src="/assets/imgs/check-black.png"
                      />
                      <Text fontSize="14px" fontWeight="700">
                        500{" "}
                        <Box as="span" fontWeight="500">
                          500 credits for AI assist
                        </Box>
                      </Text>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center">
                      <Image
                        w="12px"
                        h="auto"
                        src="/assets/imgs/check-black.png"
                      />
                      <Text fontSize="14px" fontWeight="500">
                        Reports
                      </Text>
                    </Box>
                    <Box display="flex" gap="10px" alignItems="center">
                      <Image
                        w="12px"
                        h="auto"
                        src="/assets/imgs/check-black.png"
                      />
                      <Text fontSize="14px" fontWeight="500">
                        Add appointments manually
                      </Text>
                    </Box>
                  </Flex>
                </CardBody>

                <CardFooter>
                  <PrimaryButton
                    buttonText="Downgrade"
                    variant="grayBtnFull"
                    btnStyle={{ m: "0" }}
                  />
                </CardFooter>
              </Card>
        } */}
        
            </Box>
          </>
        }
        hasFooter={false}
      />

      <DynamicModal
        ref={cancelPlan}
        modalHeader="Confirm Cancellation"
        modalBody={
          <>
            <Box>
              <Text fontSize="0.87rem" mb="1rem" fontWeight="400">
                If you confirm and end your subscription now, you can still access it until <Text as="span" fontSize="0.9rem" fontWeight="600">{formatDate(PlanEndDate)}</Text>.
              </Text>

              {/* <Flex flexDir="column" gap="0.55rem">
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="700">
                    Unlimited{" "}
                    <Box as="span" fontWeight="500">
                      appointments per month
                    </Box>
                  </Text>
                </Box>
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="700">
                    500{" "}
                    <Box as="span" fontWeight="500">
                      500 credits for AI assist
                    </Box>
                  </Text>
                </Box>
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="500">
                    Reports
                  </Text>
                </Box>
                <Box display="flex" gap="0.55rem" alignItems="center">
                  <Image w="12px" h="auto" src="/assets/imgs/check-black.png" />
                  <Text fontSize="14px" fontWeight="500">
                    Add appointments manually
                  </Text>
                </Box>
              </Flex> */}
            </Box>
          </>
        }
        modalFooterBtn={
          <>
           <Box display="flex" gap="1rem" w="100%">
              <PrimaryButton onClick={closepopupcancelplan} variant="grayBtnFull" buttonText="Not now" />
              <PrimaryButton onClick={cancelactivePlan} variant="deleteBtn" buttonText="Confirm" />
           </Box>
          </>
        }
      />

      <DynamicModal
        ref={editPhone}
        modalHeader="Edit phone number"
        modalBody={
          <>
            <Box>
              <PrimaryInputDropdown
                variant="roundSide"
                defaultValue={personalData.mobileno}
                value={editedMobileNo}
                onChange={mobileChange}
                // onKeyPress={handleMobileKeyPress}
                inputValue={editedMobileNo}
                
                defaultCountryCode={defaultCountryId}
              />
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Mobile" onClick={handleMobileChanges} />
          </>
        }
      />


<DynamicModal
        ref={editDOB}
        modalHeader="Edit Date of Birth"
        modalBody={
          <>
            <Box>
                <DatePicker
                  disableFutureDates={true}
                  placeholderProp="Date of Birth"
                  leftPos={{ left: "5px" }}
                  datePickerInputStyle={{ borderRadius: "0!important" }}
                  onDateChange={(e) => handleDobChange(e)}
                  defaultDate={dob}
                />
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Date of Birth" onClick={updateDobCall} />
          </>
        }
      />


<DynamicModal
        ref={editAddress}
        modalHeader="Edit Hospital Address"
        modalBody={
          <>
            <Box>

              {/* <PrimaryInput
                inputPlace="Hospital Address*"
                defaultValue={addressData.address}
                value={editedHospitalAddress}
                onChange={AddressChange}
              /> */}
              {/* <Textarea placeholder='Enter your Address*'
                                    onChange={AddressChange}
                                    // value={editedHospitalAddress}
                                    defaultValue={addressData.address}
                                    sx={{h: "150px", _hover: { outline: "none", } }}>
                                </Textarea> */}
                                <PrimaryInput
                inputPlace="Address 1*"
                defaultValue={addressData.address1}
                inputValue={address1}
                onChange={(e)=> setAddress1(e.target.value)}
                maxLength={50}
              />
                <PrimaryInput
                variant="bothSide"
                inputPlace="Address 2"
                defaultValue={addressData.address2}
                inputValue={address2}
                onChange={(e)=> setAddress2(e.target.value)}
                maxLength={50}
              />
              
                <PrimaryInput
                variant="bothSide"
                inputPlace="City"
                defaultValue={addressData.city}
                inputValue={city}
                onChange={(e)=> setCity(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
                 <PrimaryInput
                variant="bothSide"
                inputPlace="State"
                defaultValue={addressData.state}
                inputValue={state}
                onChange={(e)=> setState(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
                <PrimaryInput
                variant="bothSide"
                inputPlace="Pin Code"
                defaultValue={addressData.pincode}
                inputValue={pincode}
                onChange={(e)=> setPincode(handleNumericDotAndSpace(e.target.value))}
                maxLength={6}
              />
               <PrimaryInput
                variant="bothSide"
                inputPlace="Country"
                defaultValue={addressData.country}
                inputValue={country}
                onChange={(e)=> setCountry(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
 {/* <PrimaryInput
                variant="bothSide"
                inputPlace="Latitude"
                defaultValue={addressData.latitude}
                inputValue={latitude}
                onChange={(e)=> setLatitude(handleNumericDotAndSpace(e.target.value))}
                maxLength={50}
              /> <PrimaryInput
              variant="downSide"
              inputPlace="Longitude"
              defaultValue={addressData.longitude}
              inputValue={longitude}
              onChange={(e)=> setLongitude(handleNumericDotAndSpace(e.target.value))}
              maxLength={50}
            /> */}
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Address" onClick={handleAddressChanges} />
          </>
        }
      />

<ModalRight
          ref={addBranch}
          header="Add Branch"
          modalWidth={{ base: "100%", lg: "calc(100vw - 40%)" }}
          body={
            <>
              <Box display='flex' gap='1rem' w="100%" py="1rem">
                <Box w='100%'>
                <Text mb='1rem' fontSize='1rem' fontWeight='600'>Admin details</Text>
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter First Name*" variant="leftSide"
                    inputValue={firstname} 
                    onChange={(e) => setfirstname(handleAlphaDotAndSpace(e.target.value))}
                     />
  
                  <PrimaryInput
                    inputPlace="Enter Last Name"
                    variant="rightSide"
                    inputValue={lastname} 
                    onChange={(e) => setlastname(handleAlphaDotAndSpace(e.target.value))}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter Email*" variant="bothSide"
                    inputValue={email} onChange={(e) => setEmail(e.target.value)} 
                  />
                </Box>
                <Box w="100%" display="flex">
                  {/* <PrimaryInput inputPlace="Password*" variant="bothSide"
                    inputValue={password} onChange={(e) => setPassword(e.target.value)} 
                  /> */}
                      <PasswordInput
                  passInputPlace="Enter Your Password*"                 
                  variant="bothSide"
                  onChange={(e) => setPassword(e.target.value)}
                />
                </Box>
                
                <Box w="100%" display="flex">
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Gender*"
                    options={["Male", "Female", "Others"]}
                    readOnly={true}
                    id="gender"
                     onClick={selectGender}
                    defaultOption={gender}
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                  />
                </Box>
                

                <Box w="100%" display="flex">
                  <DatePicker
                      disableFutureDates={true}
                      placeholderProp="Date of Birth*"
                      leftPos={{ left: "5px" }}
                      datePickerInputStyle={{ borderRadius: "0!important" }}
                      onDateChange={(e) => handleDateChange(e)}
                      defaultDate={dobBranch}
                    />
                </Box>
                <Box w="100%" display="flex">
                      <PrimaryInputDropdown
                         onChange={(e) => handleMobileChange(e)}
                         inputValue={mobileno}
                        id="mobile" variant="downSide" 
                        placeholderProp="Phone number*"
                      />
  
                </Box>
                </Box>
                
                <Center height='auto'>
                  <Divider orientation='vertical' />
                </Center>

                <Box w='100%'>
                <Text mb='1rem' fontSize='1rem' fontWeight='600'>Branch details</Text>
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Branch Name*" variant=""
                    inputValue={branchName} onChange={(e) => setBranchName(e.target.value)} 
                  />
                </Box>


                <Box w="100%" display="flex">
                <PrimaryInput
                inputPlace="Address 1*"
                variant='bothSide'
                // defaultValue={addressData.address1}
                inputValue={branchAddress1}
                onChange={(e)=> setBranchAddress1(e.target.value)}
                maxLength={50}
              />
              
              </Box>

              
              <Box w="100%" display="flex">
                <PrimaryInput
                variant="bothSide"
                inputPlace="Address 2"
                // defaultValue={addressData.address2}
                inputValue={branchAddress2}
                onChange={(e)=> setBranchAddress2(e.target.value)}
                maxLength={50}
              />
              
              </Box>
              
              
              <Box w="100%" display="flex">
                <PrimaryInput
                variant="bothSide"
                inputPlace="City*"
                // defaultValue={addressData.city}
                inputValue={branchCity}
                onChange={(e)=> setBranchCity(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
              
              </Box>

              
              <Box w="100%" display="flex">
                 <PrimaryInput
                variant="bothSide"
                inputPlace="State*"
                // defaultValue={addressData.state}
                inputValue={branchState}
                onChange={(e)=> setBranchState(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
              <PrimaryInput
                variant="bothSide"
                inputPlace="Pin Code*"
                // defaultValue={addressData.pincode}
                inputValue={branchPinCode}
                onChange={(e)=> setBranchPinCode(handleNumericDotAndSpace(e.target.value))}
                maxLength={6}
              />
              
              </Box>

              
             
              
              <Box w="100%" display="flex">
               <PrimaryInput
                variant="downSide"
                inputPlace="Country*"
                // defaultValue={addressData.country}
                inputValue={branchCountry}
                onChange={(e)=> setBranchCountry(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
              
              </Box>
              </Box>
                {/* <Box w="100%" display="flex">
                  <PasswordInput
                    passInputPlace="Enter Password*"
                    variant="downSide"
                    inputValue={password} onChange={(e) => setPassword(e.target.value)}
                  />
                </Box> */}
  
              </Box>
              
              <Box w='100%'>
                {/* <DropdownInput
                    variant="fullSide"
                    dropDownPlace="Role*"
                    // defaultOption={roleList && roleList.find(item => item.id == roleIdFromUsersPage)?.rolename}
                    // options={roleList && roleList.map(item => item.rolename)} 
                    readOnly={true}
                    id="role"
                    //  onClick={selectRole}
                    menuList={{pos: "absolute", right: "-45px", minW: "300px"}}
                  /> */}
              </Box>
              {/* <Box display="flex" gap="30px" my="15px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                //  defaultChecked={isDoctor === true}
                  onChange={isDoctorFunction}
                >
                  Doctor
                </Checkbox>
                </Box> */}
            </>
          }
          footerActions={
            <>
              <Flex gap="1.5rem" w="100%">
                {/* <PrimaryButton
                  buttonText="Cancel"
                  variant="grayBtnFull"
                  btnStyle={{ color: "#AA2F27" }}
                  // onClick={closeAddModal}
                /> */}
                <PrimaryButton buttonText="Add Branch"
                 onClick={addBranchFunction} 
                 />
              </Flex>
            </>
          }
        />
<ModalRight
          ref={editBranch}
          header="Edit Branch"
          modalWidth={{ base: "100%", lg: "calc(100vw - 70%)" }}
          body={
            <>
              <Box w="100%" py="1rem">
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter First Name*" variant="leftSide"
                    inputValue={firstname} defaultValue={firstname}
                    onChange={(e) => setfirstname(handleAlphaDotAndSpace(e.target.value))}
                     />
  
                  <PrimaryInput
                    inputPlace="Enter Last Name"
                    variant="rightSide"
                    inputValue={lastname} 
                    defaultValue={lastname}
                    onChange={(e) => setlastname(handleAlphaDotAndSpace(e.target.value))}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Enter Email*" variant="bothSide"
                    inputValue={email} defaultValue={email} onChange={(e) => setEmail(e.target.value)} 
                  />
                </Box>
                {/* <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Password*" variant="bothSide"
                    inputValue={password} defaultValue={password} onChange={(e) => setPassword(e.target.value)} 
                  />
                </Box> */}
                
                <Box w="100%" display="flex">
                  <DropdownInput
                    variant="bothSide"
                    dropDownPlace="Gender*"
                    options={["Male", "Female", "Others"]}
                    readOnly={true}
                    id="gender"
                     onClick={selectGender}
                    defaultOption={gender}
                    menuList={{pos: "absolute", right: "-45px", minW: "115px"}}
                  />


                  <DatePicker
                    disableFutureDates={true}
                    placeholderProp="Date of Birth*"
                    leftPos={{ left: "5px" }}
                    datePickerInputStyle={{ borderRadius: "0!important" }}
                     onDateChange={(e) => handleDateChange(e)}
                    defaultDate={dobBranch}
                  />
                </Box>
                
                <Box w="100%" display="flex">
                      <PrimaryInputDropdown
                         onChange={(e) => handleMobileChange(e)}
                         inputValue={mobileno}
                         defaultValue={mobileno}
                        id="mobile" variant="downSide" 
                        placeholderProp="Phone number*"
                      />
  
                </Box>
                <Box w="100%" display="flex">
                  <PrimaryInput inputPlace="Branch Name*" variant="bothSide"
                    inputValue={branchName} defaultValue={branchName} onChange={(e) => setBranchName(e.target.value)} 
                  />
                </Box>


                <Box w="100%" display="flex">
                <PrimaryInput
                inputPlace="Address 1*"
                defaultValue={branchAddress1}
                inputValue={branchAddress1}
                onChange={(e)=> setBranchAddress1(e.target.value)}
                maxLength={50}
              />
              
              </Box>

              
              <Box w="100%" display="flex">
                <PrimaryInput
                variant="bothSide"
                inputPlace="Address 2"
                defaultValue={branchAddress2}
                inputValue={branchAddress2}
                onChange={(e)=> setBranchAddress2(e.target.value)}
                maxLength={50}
              />
              
              </Box>
              
              
              <Box w="100%" display="flex">
                <PrimaryInput
                variant="bothSide"
                inputPlace="City*"
                defaultValue={branchCity}
                inputValue={branchCity}
                onChange={(e)=> setBranchCity(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
              
              </Box>

              
              <Box w="100%" display="flex">
                 <PrimaryInput
                variant="bothSide"
                inputPlace="State*"
                defaultValue={branchState}
                inputValue={branchState}
                onChange={(e)=> setBranchState(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
              
              </Box>

              
              <Box w="100%" display="flex">
                <PrimaryInput
                variant="bothSide"
                inputPlace="Pin Code*"
                defaultValue={branchPinCode}
                inputValue={branchPinCode}
                onChange={(e)=> setBranchPinCode(handleNumericDotAndSpace(e.target.value))}
                maxLength={6}
              />
              
              </Box>

              
              <Box w="100%" display="flex">
               <PrimaryInput
                variant="downSide"
                inputPlace="Country*"
                defaultValue={branchCountry}
                inputValue={branchCountry}
                onChange={(e)=> setBranchCountry(handleAlphaDotAndSpace(e.target.value))}
                maxLength={50}
              />
              
              </Box>

                {/* <Box w="100%" display="flex">
                  <PasswordInput
                    passInputPlace="Enter Password*"
                    variant="downSide"
                    inputValue={password} onChange={(e) => setPassword(e.target.value)}
                  />
                </Box> */}
  
              </Box>
              
              <Box w='100%'>
                {/* <DropdownInput
                    variant="fullSide"
                    dropDownPlace="Role*"
                    // defaultOption={roleList && roleList.find(item => item.id == roleIdFromUsersPage)?.rolename}
                    // options={roleList && roleList.map(item => item.rolename)} 
                    readOnly={true}
                    id="role"
                    //  onClick={selectRole}
                    menuList={{pos: "absolute", right: "-45px", minW: "300px"}}
                  /> */}
              </Box>
              {/* <Box display="flex" gap="30px" my="15px">
                <Checkbox
                  colorScheme="green"
                  sx={{
                    ".chakra-checkbox__control": {
                      width: "20px",
                      height: "20px",
                      borderRadius: "5px",
                    },
                  }}
                //  defaultChecked={isDoctor === true}
                  onChange={isDoctorFunction}
                >
                  Doctor
                </Checkbox>
                </Box> */}
            </>
          }
          footerActions={
            <>
              <Flex gap="1.5rem" w="100%">
                {/* <PrimaryButton
                  buttonText="Cancel"
                  variant="grayBtnFull"
                  btnStyle={{ color: "#AA2F27" }}
                  // onClick={closeAddModal}
                /> */}
                <PrimaryButton buttonText="Edit Branch"
                 onClick={editBranchFunction} 
                 />
              </Flex>
            </>
          }
        />

<DynamicModal
        ref={addPartner}
        modalHeader="Add Partner"
        modalBody={
          <>
            <Box>
              <DropdownInput
                variant="topSide"
                // dropDownPlace="Select Branch List"
                dropDownPlace={<span>Select Branch List <span style={{color: 'red'}}>*</span></span>}
                options={clinicList}
                readOnly={true}
                id="clinic"
                // onClick={handleClinicChange} 
                onClick={handleClinicSelect} 
                defaultOption={clinics}
                menuList={{ pos: "absolute", right: "-45px", minW: "215px" }}
                />
            <PrimaryInput
                variant='bothSide'
                inputPlace="Partner Name"
                // inputPlace={<span>Partner Name <span style={{color: 'red'}}>*</span></span>}
                inputValue={partnerName}
                onChange={(e)=> setPartnerName(e.target.value)}
                maxLength={50}
              /> 

              <Box display='flex' gap='10px' h='100%' mt='1rem' alignItems='center'>
                {
                  partnerLogo && (

                <Box height='100%' w='35%'>
                <Image
                                  // src={personalData.hospitallogourl ? personalData.imagepath +   personalData.hospitallogourl : "/assets/imgs/no-image.png"}
                                  src={partnerLogo!=null ? personalData.imagepath +   partnerLogo : "/assets/imgs/no-image.png"}
                                  boxSize='90px'
                                  borderRadius='10px'
                                  objectFit="cover"
                                />
                </Box>
                  )
                }
              
              <Flex flexDir="column" gap="15px" w="100%">
                  <Input
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    hidden
                    id="uploadPartnerLogo"
                    // onChange={handleLogoSelection}
                    ref={inputPartnerLogoRef}
                    onChange={handlePartnerLogoSelection}
                    // onChange={(e) => handleUploadDocument(e)}
                  />
                  <Box
                    as="button"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    p="20px"
                    gap="8px"
                    border="1px dashed #CDCFD4"
                    borderRadius="12px"
                    // onClick={handleUploadDegree}
                    // onClick={changeLogo}
                    onClick={changePartnerLogo}
                  >
                    <Image
                      w="20px"
                      m="auto"
                      mb="10px"
                      h="auto"
                      src="/assets/svgs/file-upload.svg"
                    />
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Upload logo here
                    </Text>
                  </Box>
                </Flex>
              </Box>
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Add Partner"
            onClick={handlePartnerChanges} 
            />
          </>
        }
      />

<DynamicModal
        ref={editPartner}
        modalHeader="Edit Partner"
        modalBody={
          <>
            <Box>
              <DropdownInput
                variant="topSide"
                // dropDownPlace="Select Branch List"
                dropDownPlace={<span>Select Branch List <span style={{color: 'red'}}>*</span></span>}
                options={clinicList}
                readOnly={true}
                id="clinic"
                // onClick={handleClinicChange} 
                onClick={handleClinicSelect} 
                defaultOption={editedClinics}
                menuList={{ pos: "absolute", right: "-45px", minW: "215px" }}
                />
            <PrimaryInput
                variant='bothSide'
                inputPlace="Partner Name"
                // inputPlace={<span>Partner Name <span style={{color: 'red'}}>*</span></span>}
                defaultValue={editedPartnerName}
                inputValue={editedPartnerName}
                onChange={(e)=> setEditedPartnerName(e.target.value)}
                maxLength={50}
              /> 

              <Box display='flex' gap='10px' h='100%' mt='1rem' alignItems='center'>
                {
                  editedPartnerLogo && (

                <Box height='100%' w='35%' >
                <Image
                                  // src={editedPartnerLogo ? editedPartnerLogo : "/assets/imgs/no-image.png"}
                                  src={editedPartnerLogo!=null ? personalData.imagepath + editedPartnerLogo : "/assets/imgs/no-image.png"}
                                  // src={resultPartner.partnerlogo ? resultPartner.imagepath +   resultPartner.partnerlogo : "/assets/imgs/no-image.png"}
                                  boxSize='90px'
                                  borderRadius='10px'
                                  objectFit="cover"
                                />
                </Box>
                  )
                }
              
              <Flex flexDir="column" gap="15px" w="100%">
                  <Input
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    hidden
                    id="uploadPartnerLogo"
                    // onChange={handleLogoSelection}
                    ref={inputPartnerLogoRef}
                    onChange={handlePartnerLogoSelection}
                    // onChange={(e) => handleUploadDocument(e)}
                  />
                  <Box
                    as="button"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    p="20px"
                    gap="8px"
                    border="1px dashed #CDCFD4"
                    borderRadius="12px"
                    // onClick={handleUploadDegree}
                    // onClick={changeLogo}
                    onClick={changePartnerLogo}
                  >
                    <Image
                      w="20px"
                      m="auto"
                      mb="10px"
                      h="auto"
                      src="/assets/svgs/file-upload.svg"
                    />
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Edit logo here
                    </Text>
                  </Box>
                </Flex>
              </Box>
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Edit Partner"
            onClick={handlePartnerEditedChanges} 
            />
          </>
        }
      />

<DynamicModal
        ref={deletePartner}
        modalHeader="Delete Partner"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete Partner "{partnerName}" ?
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePartnerFromList}
            />
          </>
        }
      />

      <DynamicModal
        ref={verifyOtp}
        hasBackBtn={true}
        withBackHeader="Verify OTP"
        modalBody={
          <>
            <Box>
              <Box mb="20px">
                <Text fontSize="14px" textAlign="center" fontWeight="400">
                  Enter the OTP that was sent to your <br /> phone number +1 647
                  523-7878
                </Text>
              </Box>

              <HStack justifyContent="space-between" w="100%">
                <PinInput id="pin1" placeholder="-" size="lg">
                  <PinInputField
                    sx={{ width: "4.5rem", height: "4.5rem", fontSize: "2xl" }}
                  />
                  <PinInputField
                    sx={{ width: "4.5rem", height: "4.5rem", fontSize: "2xl" }}
                  />
                  <PinInputField
                    sx={{ width: "4.5rem", height: "4.5rem", fontSize: "2xl" }}
                  />
                  <PinInputField
                    sx={{ width: "4.5rem", height: "4.5rem", fontSize: "2xl" }}
                  />
                </PinInput>
              </HStack>

              <Box
                as="button"
                textAlign="center"
                w="100%"
                mt="1rem"
                sx={{
                  bg: "none",
                  color: "#6A707D",
                  fontSize: "12px",
                  fontWeight: "600",
                  _hover: { bg: "none" },
                }}
              >
                Resend OTP in 0:43
              </Box>
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Confirm" />
          </>
        }
      />

      <DynamicModal
        ref={uploadDoc}
        modalHeader="Upload documents"
        modalBody={
          <>
            <Box display="flex" flexDir="column" gap="1rem">
              <DropdownInput
                variant="fullSide"
                // dropDownPlace="Document type"
                dropDownPlace={<span>Document type <span style={{color: 'red'}}>*</span></span>}
                options={["Degree", "License", "Certificate"]}
                onClick={handleDocumentType}
              />
              {/* file upload section start */}
              {degreeUploadStage == "1" &&
                <Flex flexDir="column" gap="15px" w="100%">
                  <Input
                    type="file"
                    accept=".pdf,.png,.jpg"
                    hidden
                    id="uploadDegree"

                    onChange={(e) => handleUploadDocument(e)}
                  />
                  <Box
                    as="button"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    p="20px"
                    gap="8px"
                    border="1px dashed #CDCFD4"
                    borderRadius="12px"
                    onClick={handleUploadDegree}
                  >
                    <Image
                      w="20px"
                      m="auto"
                      mb="10px"
                      h="auto"
                      src="/assets/svgs/file-upload.svg"
                    />
                    <Text fontSize="14px" color="#163E9E" fontWeight="600">
                      Drop files here
                    </Text>
                    <Text fontWeight="400" fontSize="12px" color="#6A707D">
                      PDF, PNG, JPG (max. 5mb)
                    </Text>

                  </Box>
                </Flex>
              }
              {/* file upload section end */}

              {/* uploading section start */}

              {degreeUploadStage == "2" &&
                <Flex flexDir="column" gap="15px" w="100%">
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border="1px solid #E6E7E9" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(degreeDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                          <Text fontSize="14px" fontWeight="500">{degreeDocName}</Text>
                          <Box as="button"
                            onClick={() => handleDeleteUploadedDocument()}>
                            <Image w="13px" h="13px" src="/assets/imgs/close.png" /></Box>
                        </Box>
                        <Box display="flex" gap="3px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{degreeDocSize} of 120 KB</Text>
                          <Image w="20px" h="auto" src="/assets/svgs/file-upload-spinner.svg" />
                          <Text fontSize="12px" fontWeight="400" color="#384052">Uploading...</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box h="6px" mt="15px" w="100%" borderRadius="full" bg="#E6E7E9">
                      <Box bg="#1C4EC5" w={degreeUploadLoadWidth} h="100%" borderRadius="full"></Box>
                    </Box>
                  </Flex>


                </Flex>
              }
              {/* uploading section end */}

              {/* uploaded section start */}
              {degreeUploadStage == "3" &&
                <Flex flexDir="column" gap="15px" w="100%">
                  <Flex bg="white" flexDir="column" w="100%" p="16px" border={errorStyleForDegreeUpload == false ? "1px solid #E6E7E9" : "2px solid #CA3A31"} borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                    <Image boxSize="2.5rem" src={getIconForExtension(degreeDocName)} />
                      <Box w="100%">
                        <Box display="flex" justifyContent="space-between" h="auto" w="100%"
                          onClick={() => handleDeleteUploadedDocument()}>
                          <Text fontSize="14px" fontWeight="500">{degreeDocName}</Text>
                          <Box as="button"><Image w="16px" h="18px" src="/assets/imgs/delete.png" /></Box>
                        </Box>
                        <Box display="flex" gap="5px">
                          <Text fontSize="12px" fontWeight="400" color="#6A707D">{degreeDocSize} KB</Text>
                          {!errorStyleForDegreeUpload ? (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-success-icon-green.svg" />

                              <Text fontSize="12px" fontWeight="400" color="#384052">Uploaded</Text>
                            </>
                          ) : (
                            <>
                              <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                              <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </>

                          )}
                        </Box>
                      </Box>
                    </Box>
                    {errorStyleForDegreeUpload &&
                      <>
                        <Box as="button" pt="10px"
                          onClick={handleUploadDegree}>
                          <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline" >Try again</Text>

                        </Box>
                        <Input
                          type="file"
                          accept=".pdf,.png,.jpg"
                          hidden
                          id="uploadDegree"

                          onChange={(e) => handleUploadDocument(e)}
                        />
                      </>
                    }
                  </Flex>


                  {/* <Flex bg="white" flexDir="column" w="100%" p="16px" border="2px solid #CA3A31" borderRadius="12px">
                    <Box display="flex" gap="15px" w="100%">
                        <Image src="/assets/svgs/pdf-icon.svg" />
                        <Box w="100%">
                            <Box display="flex" justifyContent="space-between" h="auto" w="100%">
                                <Text fontSize="14px" fontWeight="500">degree_A0587.pdf</Text>
                                <Box as="button"><Image w="16px" h="18px" src="/assets/imgs/delete.png" /></Box>
                            </Box>
                            <Box display="flex" gap="5px">
                                <Text fontSize="12px" fontWeight="400" color="#6A707D">120 KB</Text>
                                <Image w="16px" h="auto" ml="5px" src="/assets/svgs/uploaded-failed-icon-red.svg" />
                                <Text fontSize="12px" fontWeight="400" color="#384052">Failed</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box as="button" pt="10px">
                        <Text fontSize="14px" textAlign="left" fontWeight="700" textDecor="underline">Try again</Text>
                    </Box>

                </Flex> */}
                </Flex>
              }
              {/* uploaded section end */}

            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Save changes" onClick={documentUploadCall} />
          </>
        }
      />
       {clientSecret && stripePromise && (
                <Elements key={clientSecret} stripe={stripePromise} options={{clientSecret }}>
                  <CheckoutForm planid={planid} clientSecret={clientSecret && clientSecret} />
                </Elements>
        )}
         {MiniSideBarUpdated && <MiniSideBar/>}

        
    {/* Edit Availability */}
    <DynamicModal
        ref={editAvailability}
        modalWidth="56rem"
        modalBodyStyle={{p: "0"}}
        modalHeader="Edit availability"
        modalBody={
          <>
            <Box w="100%">
              <Box w="100%" borderBottom="1px solid #E4E4E7" p="1rem 1.5rem" display="flex" gap="1rem">
                <Box as="button" fontSize="0.875rem" fontWeight="600" onClick={()=> setActiveTab("service")} color={activeTab == "service" ? "#010308" : "#898D97"}>Service type</Box>
                <Box as="button" fontSize="0.875rem" fontWeight="600" onClick={()=> setActiveTab("slot")} color={activeTab == "slot"? "#010308" : "#898D97"}>Slot setup</Box>
                <Box as="button" fontSize="0.875rem" fontWeight="600" onClick={()=> submitEditAvailabilty("slot")} color={activeTab == "clinic"? "#010308" : "#898D97"}>Availability</Box>
                {/* <Box as="button" fontSize="0.875rem" fontWeight="600" onClick={()=> setActiveTab("video")} color={activeTab == "video"? "#010308" : "#898D97"}>Video call hours</Box> */}
                
              </Box>

              <Box w="100%" p="1.25rem">
                {activeTab == "service" &&(
                  <Box w="100%">
                  <Box mb="1rem" display="flex" gap="1rem" flexDir="column">
                      <Box as="label" cursor='pointer' htmlFor="clinicVisit" p="1.25rem" border="1px solid #E4E4E7" borderRadius="0.75rem" display="flex" flexDir="column" gap="0.5rem"
                      >
                          <Box display="flex" justifyContent="space-between" w="100%">
                              <Image src="/assets/svgs/hospital-black.svg" />
                              <Checkbox id="clinicVisit" colorScheme="green" isChecked={clinicvisit === 1} 
                              onChange={(e)=> checkedservice(e, "clinicvisit")}
                                />
                          </Box>

                          <Box>
                              <Text fontSize="0.875rem" fontWeight="600" color="#010308">Clinic visit</Text>
                              <Text fontSize="0.75rem" fontWeight="400" color="#384052">Meet your patients face-to-face at your clinic or hospital.</Text>
                          </Box>
                      </Box>

                      <Box as="label" cursor='pointer' htmlFor="videoCall" p="1.25rem" border="1px solid #E4E4E7" borderRadius="0.75rem" display="flex" flexDir="column" gap="0.5rem">
                          <Box display="flex" justifyContent="space-between" w="100%">
                              <Image src="/assets/svgs/videocam.svg" />
                              <Checkbox id="videoCall" colorScheme="green" isChecked={doctorvisit === 1} onChange={(e)=>checkedservice(e,"videocall")}/>
                          </Box>

                          <Box>
                              <Text fontSize="0.875rem" fontWeight="600" color="#010308">Video call</Text>
                              <Text fontSize="0.75rem" fontWeight="400" color="#384052">Engage with your patients through scheduled video calls using Zoom.</Text>
                          </Box>
                      </Box>
                  </Box>
                </Box>
                )}

                {activeTab == "clinic" &&(
                <Box w="100%" display="flex" gap="1rem">
                      <Box width="100%">
                      <ScheduleComponent AvailabilityData={AvailabilityData} onSubmit={handleScheduleSubmit} clinicVisitCheckbox={clinicvisit} doctorVisitCheckbox={doctorvisit} IsAdd={IsAddClicked}/>
                      </Box>
                </Box>
                )}

                {/* {activeTab == "video" || activeComponent == "videotab" &&(
                <Box w="100%">
                      <Box display="flex" flexDir="column" gap="1rem">
                      <ScheduleComponent AvailabilityData={AvailabilityData} type={"video"} onSubmit={handleScheduleSubmit}/>
                      </Box>
                </Box>
                )} */}

                {activeTab == "slot" && (
                  <Flex w="100%" flexDir="column" gap="1rem">
                  <Box w="100%">
                  <Box w="100%" display="flex">
                        <PrimaryInput variant="leftSide" inputPlace="Duration of calls"
                         inputValue={DurationofCalls} 
                         onChange={handleChangeDuratonofcalls}
                        />
                        <DropdownInput
                            inputStyleCss={{w:"30%"}}
                            dropDownPlace="Range"
                            // inputPlace="Range"
                            // isLabel={false}
                             defaultOption={DurationofCallsTypeedit}
                            variant="rightSide"
                            options={["Mins", "Hours"]} 
                             onClick={selectDurationofcallstype}
                          />
                    </Box>
                    <Box w="100%" display="flex">
                        <PrimaryInput variant="leftDown" inputPlace="Minimum notice"
                        inputValue={MinimumNotice} 
                        onChange={handleChangeMinimumnotice}
                        //inputValue={minimumnotice}
                        />
                        <DropdownInput
                            inputStyleCss={{w:"30%"}}
                            //isLabel={false}
                            dropDownPlace="Range"
                            dropPlaceStyle={{top: "5px"}}
                            defaultOption={MinimumNoticeTypeedit}
                            variant="rightDown"
                            options={["Mins", "Hours"]} 
                            onClick={selectMinimumNoticeType}/>
                    </Box>
                  </Box>
  
                  <Box w="100%">
                  <Box w="100%" display="flex">
                        <PrimaryInput variant="leftSide" inputPlace="Before call buffer"
                         inputValue={BeforeCallBuffer} 
                         onChange={handleChangeBeforecallbuffer}
                        />
                        <DropdownInput
                            inputStyleCss={{w:"30%"}}
                            // isLabel={false}
                            dropDownPlace="Range"
                             defaultOption={BeforeCallBufferTypeedit}
                            variant="rightSide"
                            options={["Mins", "Hours"]} 
                             onClick={selectBeforeCallBuffer}
                          />
                    </Box>
                    <Box w="100%" display="flex">
                        <PrimaryInput variant="leftDown" inputPlace="After call buffer" 
                         inputValue={AfterCallBuffer}
                       onChange={handleChangeaftercallbuffer}
                        />
                        <DropdownInput
                            inputStyleCss={{w:"30%"}}
                            // isLabel={false}
                            dropDownPlace="Range"
                             defaultOption={AfterCallBufferTypeedit}
                            variant="rightDown"
                            options={["Mins", "Hours"]} 
                             onClick={selectAfterCalBuffer}
                          />
                    </Box>
                  </Box>
                  <Box borderRadius="0.75rem" border="1px solid #E6E7E9">
                    <Box w="100%" display="flex" justifyContent="space-between" alignItems="center" p="1rem 1.5rem"  borderBottom="1px solid #E6E7E9">
                        <Text fontSize="0.75rem" fontWeight="600">LIMIT FUTURE BOOKING</Text>
                        <Switch size="lg" colorScheme="green" isChecked={FutureBookingsLimit} onChange={(e) => switchclicked(e)}/>
                    </Box>
                    {SwitchChecked &&
                    <Box p="1.5rem" w="100%" display="flex">
                    <PrimaryInput variant="fullLeft" inputPlace="Upto"
                    inputValue={FutureBooking} 
                    onChange={handleChangefuturbookingsupto}
                    //inputValue={futurebookingsupto}
                    />
                    <DropdownInput
                        dropDownPlace="Range"
                        dropPlaceStyle={{top: "5px"}}
                        variant="fullRight"
                        options={["Days", "Months"]}
                        defaultOption={FutureBookingTypeedit}
                        onClick={selectFutureBooking}
                    />
                    </Box>
                    }
                    
                </Box>
                
                </Flex>
                )}
              </Box>
            </Box>
          </>
        }
        hasFooter={activeTab == "clinic" || activeTab == "video" ? false: true}
        modalFooterBtn={
          <>
          
            <PrimaryButton buttonText="Save changes" onClick={()=>submitEditAvailabilty(activeTab)}/>
          
          </>
        }
      />

    {/* Share badge start */}
    <DynamicModal
      ref={shareBadge}
      modalWidth={{base: "100%", lg: "55rem"}}
      // modalHeight="calc(100% - 10%)"
      modalHeader="Share badge"
      modalBody={
        <>
          <Flex w="100%" gap="1rem" flexWrap={{base: "wrap", lg: "nowrap"}} h="100%">
            {/* Left area start */}
            <Box w={{base: "100%", lg: "50%"}} h="100%">
              <Swiper
                onSwiper={handleDoc}
                slidesPerView={1}
                ref={swiperDocCard}
                style={{ height: '80%' }}
              >
                {/* Doctor profile start */}
                <SwiperSlide>

                <Box
                
                  ref={badgeBox1}
                  // mb="2rem"
                  bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                  boxShadow="0px 8px 24px 0px #B4B4B452"
                  borderRadius="0.75rem"
                  bgPos="inherit"
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  id="badge1"
                >
                  <Box
                    bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                    boxShadow="0px 8px 24px 0px #B4B4B452"
                    borderRadius="0.75rem"
                    bgPos="inherit"
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    h="80%"
                    display="flex"
                    // flexDirection="column"
                  ><Box display="flex" justifyContent="space-between" w="100%" bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.9976365546218487) 75%)">
                    <Box w='75%'>
                    <Box
                      // bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.9976365546218487) 75%)"
                      flex="1"
                      // p="0.5rem"
                      p="1.5rem 1rem"
                    >

                      <Image
                        w="4.6rem"
                        h="4.6rem"
                        borderRadius="full"
                        bg="#D2DCF3"
                        src={personalData.profilepictureurl ? personalData.imagepath + personalData.profilepictureurl : "/assets/imgs/no-image.png"}
                         
                      />
                      <Text
                        fontSize="1.25rem"
                        display="inline-block"
                        fontWeight="600"
                        color="black"
                      >
                        {personalData.firstname ? personalData.firstname : " " }
                        {personalData.lastname ? personalData.lastname : "" }
                      </Text>
                      <Text fontSize="0.875rem" fontWeight="500" color="black">
                        {/* MBBS, MD (PGI) | Obstetrics & Gynaecology */}
                        
                        {personalData.qualification ? personalData.qualification + " | " : ""}
                        {personalData.specialistname ? personalData.specialistname : ""}
                      </Text>
                    </Box>
                    </Box>
                    <Box>
                    <Box>
                        <Image mt="1rem" mr="1rem" src="/assets/svgs/midoc-logo-subtitle.svg" />
                      </Box>
                      </Box>
                    </Box>
                    </Box>
                  <Box display="flex" flexDir="column" gap="0.75rem" p="1.5rem 1rem" bg="white" borderTop="1px solid #E6E7E9">
                    <Box display="flex" gap="0.5rem" justifyContent="flex-start" alignItems="center">
                      <Box w="1.25rem" h="auto">
                        <Image src="/assets/imgs/call.png" />
                      </Box>
                      <Text fontSize="0.875rem" fontWeight="500" color="#061027">
                        {personalData.countrycode ? personalData.countrycode : ""} {personalData.mobileno ? personalData.mobileno : "-"}</Text>

                    </Box>
                    <Box
                      display="flex"
                      flexDir="column"
                      gap="0.75rem"
                      // p="1.5rem 1rem"
                      bg="white"
                      // borderTop="1px solid #E6E7E9"
                      flex="1"
                    >
                      <Box display="flex" gap="0.5rem" justifyContent="flex-start" alignItems="center">
                        <Box w="1.25rem" h="auto">
                          <Image src="/assets/imgs/location_on.png" />
                        </Box>
                      <Text fontSize="0.875rem" fontWeight="500" color="#061027">
                      {personalData.hospitalname ? personalData.hospitalname : "-"}
                      </Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap="0.5rem" justifyContent="flex-start" alignItems="start">
                      <Box w="1.25rem" h="auto">
                        <Image src="/assets/imgs/timing.png" />
                      </Box>
                      <Box display="flex" flexDir="column" >
                        {/* <Text fontSize="0.875rem" fontWeight="500" color="#061027">Mon, Tue, Wed  (7:00 AM - 8:00 PM)</Text>
                        <Text fontSize="0.875rem" fontWeight="500" color="#061027">Thu, Fri  (7:30 AM - 1:30 PM)</Text> */}
                        {/* {videoVisits.map((item, index)=>(
    <Text key={index} fontSize="0.875rem" fontWeight="500" color="#061027">{item.day}  ({item.timings[0].fromtime} - {item.timings[0].totime})</Text>
  ))} */}
   {groupedVisits.length > 0 ? (groupedVisits.map((group, index) => (
        <Text key={index} fontSize="0.875rem" fontWeight="500" color="#061027">
          {group.days.join(', ')} ({group.time.fromtime} - {group.time.totime})
        </Text>
         ))):(
          <Text  fontSize="0.875rem" fontWeight="500" color="#061027">
          Not Available
        </Text>
        )}
                     

                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" flexDir="column" mt="auto" gap="0.75rem" p="1rem 1rem" bg="white" flex="1">
                      {/* <Box>
                        <Image src="/assets/svgs/quote.svg" />
                      </Box> */}
                      <Box>
                        {/* <Box as="p" fontSize="1rem" fontWeight="500">
                          {badgeText}
                        </Box> */}
                         <Text>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </Text>
                      </Box>
                    </Box>
                    <Box as="p" bg="#121316" fontSize="0.875rem" fontWeight="600" textAlign="center" p="0.75rem 0.625rem" color='white' borderRadius="0 0 0.75rem 0.75rem">
                      Schedule a visit with me on midoc!
                    </Box>
                </Box>
                </SwiperSlide>
                {/* Doctor profile end */}

                {/* Quote card start */}
                {/* <SwiperSlide>

                <Box
                  // mb="2rem"
                  bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                  boxShadow="0px 8px 24px 0px #B4B4B452"
                  borderRadius="0.75rem"
                  bgPos="inherit"
                  bgRepeat="no-repeat"
                  bgSize="cover"
                  ref={badgeBox2}
                >

                  <Box
                    bg={`url('/assets/imgs/share-badge-card-bg.png')`}
                    boxShadow="0px 8px 24px 0px #B4B4B452"
                    borderRadius="0.75rem"
                    bgPos="inherit"
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    h="80%"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box
                      bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.9976365546218487) 75%)"
                      flex="1" minH="200px"></Box>
                    <Box display="flex" flexDir="column" mt="auto" gap="0.75rem" p="1rem 1rem" bg="white" flex="1">
                      <Box>
                        <Image src="/assets/svgs/quote.svg" />
                      </Box>
                      <Box>
                        <Box as="p" fontSize="1rem" fontWeight="500">
                          Did you know? By the third trimester, a baby can recognize their mother’s voice and even react to sounds like music or laughter!
                        </Box>
                      </Box>
                    </Box>
                    <Box as="p" bg="#121316" fontSize="0.875rem" fontWeight="600" textAlign="center" p="0.75rem 0.625rem" color='white' borderRadius="0 0 0.75rem 0.75rem">
                      Schedule a visit with me on midoc!
                    </Box>
                  </Box>
                </Box>
                </SwiperSlide> */}
                {/* Quote card end */}
              </Swiper>
              {/* <Box w="100%">
                <Box display="flex" w="30%" mx="auto" my="0.75rem" justifyContent="space-between" alignItems="center">
                  <Box
                    as="button"
                    onClick={() => handleDoc("prev")}
                  >
                    <Image w="6px" src="/assets/imgs/left-icon.png" />
                  </Box>
                  <Text fontSize="0.75rem" fontWeight="500" color="#384052">Page {page} of 2</Text>
                  <Box
                    as="button"
                    onClick={() => handleDoc("next")}
                  >
                    <Image w="6px" src="/assets/imgs/right-icon.png" />
                  </Box>
                </Box>
              </Box> */}
            </Box>
            {/* Left area end */}

            {/* Right area start */}
            <Box w={{base: "100%", lg: "50%"}} display='flex' flexDir='column' justifyContent='space-between'>
            <Box w="100%">
            <Box p="1.5rem" minH="280px" bg="#FCFCFD" border="1px solid #E6E7E9" borderRadius="1rem 1rem 0 0">
              <Textarea 
                border="none"
                // minH="250px"
                resize="none"
                p="0.5rem"
                pl="0"
                minH="auto"
                borderRadius="1rem 1rem 0 0"
                sx={{
                  _hover: { outline: "none !important", border: "none !important", borderColor: "transparent !important" },
                  _focus: { outline: "none !important", border: "none !important", boxShadow: "none !important", borderColor: "transparent !important" },
                  _active: { outline: "none !important", border: "none !important", borderColor: "transparent !important" },
                }}
                h={textareaHeight}
                _placeholder={{textAlign:'center', fontSize: '12px'}}
                placeholder={placeholderText}
                onFocus={onFocusFunc}
                onBlur={onBlurFunc}
               // value={badgeText}
                maxH="300px"
                onChange={(e)=>setBadgeTextFunction(e)}
              />
                  {/* {!badgeText && 
                <Text
                position="absolute"
                left="65%"
                top="22%"
                fontSize="12px"
                color="gray.500"
                // display={{base: "none", lg: "block"}}
                onClick={hideText}
                >
                Click anywhere to start typing.
              </Text>
              } */}
              <Box display="flex">
              <Box as="button" mt="0.5rem" mx="0.5rem" display="flex" justifyContent="center" alignItems="center" w="max-content" bg="transparent" 
              border="1px solid #E6E7E9" borderRadius="full" p="0.5rem 0.75rem" gap="0.5rem"
              onClick={regenerateFunction}
              >
                <Image boxSize="0.625rem" src="/assets/svgs/generate.svg" />
                <Text fontSize="0.75rem" fontWeight="600" color="#384052">Regenerate</Text>
              </Box>
                <Box as="button" mt="0.5rem" mx="0.5rem" display="flex" justifyContent="center" alignItems="center" w="max-content" bg="transparent" 
              border="1px solid #E6E7E9" borderRadius="full" p="0.5rem 0.75rem" gap="0.5rem"
              onClick={useSameText} disabled={textAreaText ? false : true}
              >
                {/* <Image boxSize="0.625rem" src="/assets/svgs/generate.svg" /> */}
                <Text fontSize="0.75rem" fontWeight="600" color={ textAreaText ? "#384052" : "#c5c5c5"}>Use this text</Text>
              </Box>
              </Box>
          <Box display="flex" flexDirection="column" gap="0.5rem" mt="0.5rem">
              {AIPromptArray.map((step, index) => (
              <>
                      

                      
                <Box
                    // key={index}
                      as="button"
                      bg="white"
                      p="8px 10px"
                      display="flex"
                      alignItems="center"
                      w="100%"
                      border="1px solid #E6E7E9"
                      borderRadius="full"
                      boxShadow="0px 8px 20px 0px #E6E7E966"
                      onClick={() => setTextFromPropmts(step.value)}
                    >
                      <Image src="/assets/imgs/spark.png" mr="8px" />
                      <Text fontSize="11px" fontWeight="600" color="#1C4EC5" 
                      textAlign="left"
                      >
                         {step.value ? step.value : "-"}
                      </Text>
                    </Box>
              </>
                    
                    ))}
                    </Box>
            </Box>
            
              {/* <Box
                tabIndex="-1"
                w="100%"
                h="65%"
                bg="#FCFCFD"
                border="1px solid #E6E7E9"
                borderRadius="1rem 1rem 0 0"
              >
                <Box
                  p="1.5rem"
                  bg="#FCFCFD"
                  borderRadius="1rem"
                  h="100%"
                  maxH="300px"
                  overflowY="auto"
                  userSelect="text"
                  w="100%"
                  whiteSpace="pre-wrap"
                  wordBreak="break-word"
                  role="textbox"
                  contentEditable
                  spellCheck="true"
                  aria-label="Type a message"
                  tabIndex={10}
                  display="flex"
                  flexDirection="column"
                  _focus={{ boxShadow: "none", outline: "none" }}
                >
                  <Box
                    as="p"
                    m={0}
                    flex="1"
                    w="100%"
                    textIndent="0px"
                    color="#061027"
                    fontSize="0.875rem"
                    fontWeight="500"
                  >
                    <br />
                    <Box as="button" mt="0.5rem" display="flex" justifyContent="center" alignItems="center" w="max-content" bg="transparent" border="1px solid #E6E7E9" borderRadius="full" p="0.5rem 0.75rem" gap="0.5rem">
                      <Image boxSize="0.625rem" src="/assets/svgs/generate.svg" />
                      <Text fontSize="0.75rem" fontWeight="600" color="#384052">Regenerate</Text>
                    </Box>
                  </Box>
                  <Text mx="auto" w="100%" textAlign="center" fontSize="0.75rem" fontWeight="500" color="#898D97">Click on the text to edit</Text>
                </Box>
              </Box> */}
              <Box p="1.5rem" bg="#CDCFD426" overflow='hidden' borderRadius="0 0 1rem 1rem">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  w="100%"
                  mb="0.75rem"
                >
                  <Text fontSize="0.875rem" fontWeight="500" color="#384052">
                    Rewrite with AI
                  </Text>
                  <Box display="flex" gap="0.65rem">
                    <Box
                      as="button"
                      onClick={() => handleAiPrompt("prev")}
                    >
                      <Image w="6px" src="/assets/imgs/left-icon.png" />
                    </Box>

                    <Box
                      as="button"
                      onClick={() => handleAiPrompt("next")}
                    >
                      <Image w="6px" src="/assets/imgs/right-icon.png" />
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" gap="0.5rem" w="fit-content" overflow="hidden">
                  <Swiper
                    onSwiper={handleAiPrompt}
                    ref={swiperAiPrompt}
                    slidesPerView={2}
                    style={{ height: '80%' }}
                    className="AiPrompt"
                  >
                    <SwiperSlide>
                    <Box
                      as="button"
                      bg="white"
                      p="0.5rem 0.625rem"
                      display="flex"
                      alignItems="center"
                      w="max-content"
                      border="0.0625rem solid #E6E7E9"
                      borderRadius="full"
                      boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                      // onClick={() => AIChatModel.current.openRight()}
                      
                      onClick={() => rewriteWithAI("This day in medical history")}
                    >
                      <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        color="#1C4EC5"
                      >
                        This day in medical history
                      </Text>
                    </Box>
                    </SwiperSlide>

                    <SwiperSlide>
                    <Box
                      as="button"
                      bg="white"
                      p="0.5rem 0.625rem"
                      display="flex"
                      alignItems="center"
                      w="max-content"
                      border="0.0625rem solid #E6E7E9"
                      borderRadius="full"
                      boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                       onClick={() => rewriteWithAI("Medical Fun Fact")}
                    >
                      <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        color="#1C4EC5"
                      >
                        Medical Fun Fact
                      </Text>
                    </Box>
                    </SwiperSlide>

                    <SwiperSlide>
                    <Box
                      as="button"
                      bg="white"
                      p="0.5rem 0.625rem"
                      display="flex"
                      alignItems="center"
                      w="max-content"
                      border="0.0625rem solid #E6E7E9"
                      borderRadius="full"
                      boxShadow="0rem 0.5rem 1.25rem 0rem #E6E7E966"
                      // onClick={() => AIChatModel.current.openRight()}
                      
                      onClick={() => rewriteWithAI("Daily Health tip")}
                    >
                      <Image src="/assets/imgs/spark.png" mr="0.5rem" />
                      <Text
                        fontSize="0.75rem"
                        fontWeight="600"
                        color="#1C4EC5"
                      >
                        Daily Health tip
                      </Text>
                    </Box>
                    </SwiperSlide>

                  </Swiper>
                </Box>
              </Box>
              </Box>
              <Box w="100%">
              <PrimaryButton buttonText="Download badge" 
              // onClick={()=> downlaodBadge.current.openModal()} 
               onClick={downloadBadge} 
               mt='1rem'
              />
              </Box>
              </Box>
              

              {/* Right area end */}
            </Flex>
            
          </>
        }
        hasFooter={false}
        modalFooterBtn={
        <>
            <Box w="45%" mx="auto">
              {/* <PrimaryButton buttonText="Share badge" 
              // onClick={()=> downlaodBadge.current.openModal()} 
               onClick={shareBadgeCall} 
              /> */}
            </Box>
            <Box w="45%" mx="auto">
              <PrimaryButton buttonText="Download badge" 
              // onClick={()=> downlaodBadge.current.openModal()} 
               onClick={downloadBadge} 
              />
              </Box>
        </>
      }
     
    />
    {/* Share badge end */}



    {/* -------------------------------- Edit Professional Details -------------------- */}
    <DynamicModal
        ref={editHospital}
        modalHeader="Edit Hospital name"
        modalBody={
          <>
            <Box>

              <PrimaryInput
                inputPlace="Hospital name*"
                defaultValue={personalData.hospitalname}
                value={editedHospitalName}
                onChange={hospitalNameChange}
                inputValue={editedHospitalName}
              />
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Hospital Name" onClick={handleHospitalChanges} />
          </>
        }
      />


    <DynamicModal
        ref={editDegree}
        modalHeader="Edit Degree name"
        modalBody={
          <>
            <Box>

              {/* <PrimaryInput
                // inputPlace="Degree name"
                inputPlace={
                  <>
                    Degree name <Box as="span" color="red">*</Box>
                  </>
                }
                defaultValue={personalData.qualification}
                value={editedDegreeName}
                onChange={degreeNameChange}
              /> */}
               <SearchableDropdown 
                  options={degreeOptions}
                  labelKey="label" // Specify key for display label
                  idKey="id" // Specify key for unique identifier
                  placeholder={
                    <>
                      Degree name <Box as="span" color="red">*</Box>
                    </>
                  }
                  defaultValue={personalData.qualification}
                            onClick={handleDegreeSelect}
                />
               {/* <DropdownInput
                      variant="bothSide"
                      defaultOption={personalData.qualification}
                      dropDownPlace={
                        <>
                          Degree name <Box as="span" color="red">*</Box>
                        </>
                      }
                      options={degreeOptions}
                      onClick={handleDegreeSelect}
                    /> */}
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Degree Name" onClick={handleDegreeChanges} />
          </>
        }
      />


    <DynamicModal
        ref={editSpecialization}
        modalHeader="Edit Specialization"
        modalWidth="42rem"
        modalBody={
          <>
            <Box>

              {/* <PrimaryInput
                inputPlace="Speciality"
                defaultValue={personalData.specality}
                value={editedSpecialityName}
                onChange={specialityNameChange}
              /> */}

<VStack spacing={0} w="100%">
              <Box as="p" fontSize="22px" fontWeight="600">
                Select your specialization
              </Box>
                <Box w="100%">
                    <Box w="100%" px="30px" py="10px" mb="20px">
                    <Box w="100%" mb="10px">
                    <Grid templateColumns={{ base: "repeat(auto-fill, minmax(180px, 1fr))", md: "repeat(4, 1fr)" }} gap="20px" alignItems="center">
                    {specialityList && specialityList.map((profile, index) => (
                        <>
                        <Box display="flex" flexDir="column" alignItems="center">
                            <Box
                            key={index}
                            as="button"
                            // border="2px solid #0C7650"
                            w="7.5rem"
                            h="3.75rem"
                            borderRadius="8px"
                            display="flex"
                            flexDirection="column"
                            bg="#FCFCFD"
                            lineHeight="normal"
                            alignItems="center"
                            justifyContent="center"
                            // onClick={()=> handleselection(profile.id)}
                            border={selectedOptions.includes(profile.id) ? "2px solid #0C7650" : "none"}
                            onClick={() => handleOptionToggle(profile.id, profile.specialityname)}
                            boxShadow="0px 1px 2px 0px #C1C2C680, 0px -8px 12px 0px #D8D8D833 inset"
                            >
                            <Image src={profile.url? profile.imagepath+profile.url : "/assets/imgs/cardiology-final.png"} />
                            </Box>
                            <Box as="p" color="black" mt="8px" textAlign="center" fontSize="14px" whiteSpace="nowrap" fontWeight="500">
                                {profile.specialityname}
                            </Box>
                        </Box>
                        </>
                    ))}
                    </Grid>
                    </Box>
                    {
                      showCustomField && (
                      <Box>
                        <PrimaryInput
                          inputPlace="Edit Other Speciality"
                          defaultValue={editedSpecialityName}
                          inputValue={editedSpecialityName}
                          onChange={specialityNameChange}

                        />
                      </Box>
                      )
                    }
                </Box>
              </Box>
              {/* <Box w="100%">
                <PrimaryButton buttonText="Continue" id="submitButton" 
                 onClick={SubmitSpeciality}
                 />
              </Box> */}
            </VStack>
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Specialization" onClick={handleSpecialityChanges} />
            {/* <PrimaryButton buttonText="Update Specialization" onClick={handleSpecialityChanges} /> */}
          </>
        }
      />


    <DynamicModal
        ref={editCustomSpeciality}
        modalHeader="Edit Specialization"
        modalWidth="42rem"
        modalBody={
          <>
            <Box>

              <PrimaryInput
                inputPlace="Speciality"
                // defaultValue={personalData.specality}
                // defaultValue={editedSpecialityName}
                value={editedSpecialityName}
                onChange={specialityNameChange}
              />
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Specialization" onClick={handleCustomSpecialitySubmit} />
            {/* <PrimaryButton buttonText="Update Specialization" onClick={handleSpecialityChanges} /> */}
          </>
        }
      />
 <DynamicModal
        ref={feeStructureModalRef}
        modalHeader="Edit Fee Structure"
        modalBody={
          <>
            <Box>

              {/* <PrimaryInput
              variant="topSide"
                inputPlace="Appointment Fee*"
               defaultValue={onlineConsulationFee}
                inputValue={onlineConsulationFee}
                onChange={(e)=>handleFeeChange(e , "Appointment")}
              /> */}
               <InputGroup>
    <InputLeftAddon height="48px" textAlign="center" width="70px">{selectedCurrency}</InputLeftAddon>
    {/* <Input  inputPlace='Appointment Fee*' value={personConsultationFee} defaultValue={personConsultationFee} onChange={(e)=>handleFeeChange(e , "Person")} /> */}
    <PrimaryInput
                        type="number"
                        // inputStyleCss={{w:"50%"}}
                        variant={"bothSide"}
                        inputPlace="In-Person Consultation Fee"
                        inputValue={personConsultationFee}
                        onChange={(e) => handleFeeChange(e, "Person")}
                      />
  </InputGroup>

               <InputGroup>
    <InputLeftAddon height="48px" textAlign="center" width="70px">$</InputLeftAddon>
    {/* <Input  placeholder='Appointment Fee*' value={onlineConsulationFee} defaultValue={onlineConsulationFee} onChange={(e)=>handleFeeChange(e , "Appointment")} /> */}
    <PrimaryInput
                        type="number"
                        // inputStyleCss={{w:"50%"}}
                        variant={"bothSide"}
                        inputPlace="Online Consultation Fee"
                        inputValue={onlineConsulationFee}
                        onChange={(e) => handleFeeChange(e, "Appointment")}
                      />
  </InputGroup>

  <InputGroup>
    <InputLeftAddon height="48px" textAlign="center" width="70px">$</InputLeftAddon>
    {/* <Input  placeholder='Chat Fee*' value={remoteChatFee} defaultValue={remoteChatFee} onChange={(e)=>handleFeeChange(e , "Chat")} /> */}
    <PrimaryInput
                        type="number"
                        // inputStyleCss={{w:"50%"}}
                        variant={"bothSide"}
                        inputPlace="Remote Chat Fee"
                        inputValue={remoteChatFee}
                        onChange={(e) => handleFeeChange(e, "Chat")}
                      />
  </InputGroup>
{/* 
<PrimaryInput

              variant="downSide"
                inputPlace="Chat Fee*"
                 defaultValue={remoteChatFee}
                inputValue={remoteChatFee}
                onChange={(e)=>handleFeeChange(e , "Chat")}
                // maxLength={6}
              /> */}
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Fee" onClick={()=>fee_Currency_UpdateFunc("Fee Structure")} />
          </>
        }
      />

<DynamicModal
        ref={currencyModalRef}
        modalHeader="Edit Currency"
        modalBody={
          <>
            <Box>

{/* <DropdownInput
                            inputStyleCss={{ mt: "1rem" }}
                            variant="fullSide"
                            dropDownPlace="Currency"
                            options={currencyOptions}

                            defaultOption={selectedCurrency}
                            onClick={(value)=> setSelectedCurrency(value)}
                          menuList={{ pos: "relative", right: "78%", minW: "200px"}}
                          /> */}
                          <SearchableDropdown 
                  options={currencyOptions}
                  labelKey="name" // Specify key for display label
                  idKey="id" // Specify key for unique identifier
                  placeholder="Select a Currency"
                  defaultValue={selectedCurrency}
                            onClick={(value)=> setSelectedCurrency(value.name)}
                />
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Currency" onClick={()=>fee_Currency_UpdateFunc("Currency")} />
          </>
        }
      />


    <DynamicModal
        ref={editYrsofexperience}
        modalHeader="Edit Years of Experience"
        modalBody={
          <>
            <Box>

              {/* <PrimaryInput
                // inputPlace="Experience"
                inputPlace={
                  <>
                    Experience <Box as="span" color="red">*</Box>
                  </>
                }
                defaultValue={personalData.yearsofexperience}
                inputValue={editedExperience}
                onChange={experienceChange}
              /> */}
                <DropdownInput
                      variant="bothSide"
                      defaultOption={personalData.yearsofexperience}
                      dropDownPlace={
                        <>
                          Experience <Box as="span" color="red">*</Box>
                        </>
                      }
                      options={experienceInYears}
                      onClick={handleExpSelect}
                    />
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Experience" onClick={handleExperienceChanges} />
          </>
        }
      />
      <DynamicModal
        ref={editPhoto}
        modalHeader="Upload Photo"
        modalBody={
          <>
            {showCropper && selectedImage &&  (
              <Box display="flex" justifyContent="center" alignItems="center" h='400px' w='400px'>
              <ImageCropper
                src={selectedImage}
                onCropComplete={handleCropComplete}
              />
              </Box>
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton 
            buttonText="Upload" 
            mr={3} 
            onClick={() => {
              handleCrop();
              editPhoto.current.closeModal();
            }} 
            />
            <PrimaryButton buttonText="Cancel" onClick={() => editPhoto.current.closeModal() } />
          </>
        }
        size="lg"
      />

      <DynamicModal
        ref={editLogo}
        modalHeader="Upload Logo"
        modalBody={
          <>
            {showLogoCropper && selectedLogo &&  (
              <Box display="flex" justifyContent="center" alignItems="center" h='400px' w='400px'>
              <ImageCropper
                src={selectedLogo}
                onCropComplete={handleLogoCropComplete}
              />
              </Box>
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton 
            buttonText="Upload" 
            mr={3} 
            onClick={() => {
              handleLogoCrop();
              editLogo.current.closeModal();
            }} 
            />
            <PrimaryButton buttonText="Cancel" onClick={() => editLogo.current.closeModal() } />
          </>
        }
        size="lg"
      />

      <DynamicModal
        ref={editPartnerLogo}
        modalHeader="Upload Partner Logo"
        modalBody={
          <>
            {showPartnerLogoCropper && selectedPartnerLogo &&  (
              <Box display="flex" justifyContent="center" alignItems="center" h='400px' w='400px'>
              <ImageCropper
                src={selectedPartnerLogo}
                onCropComplete={handlePartnerLogoCropComplete}
              />
              </Box>
            )}
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton 
            buttonText="Upload" 
            mr={3} 
            onClick={() => {
              handlePartnerLogoCrop();
              editPartnerLogo.current.closeModal();
            }} 
            />
            <PrimaryButton buttonText="Cancel" onClick={() => editPartnerLogo.current.closeModal() } />
          </>
        }
        size="lg"
      />

<DynamicModal
        ref={changePassword}
        modalHeader="Change Password"
        modalBody={
          <>
            <Box>

            <PasswordInput
              passInputPlace="Enter Old Password*"                 
              variant="fullRound"
              mb='1.5rem'
              onChange={(e) => handleChangeOldPassword(e)}
              // onKeyPress={handleEmailpassKeyPress}
            />
            <PasswordInput
              passInputPlace="Enter New Password*"                 
              variant="topSide"
              onChange={(e) => handleChangeNewPassword(e)}
              // onKeyPress={handleEmailpassKeyPress}
            />
            <PasswordInput
              passInputPlace="Confirm New Password*"                 
              variant="downSide"
              onChange={(e) => handleChangeConfirmPassword(e)}
              // onKeyPress={handleEmailpassKeyPress}
            />
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Password" onClick={handlePasswordChanges} />
          </>
        }
      />

<DynamicModal
        ref={personConsultationFeeModalRef}
        modalHeader="Edit In-Person Consultation Fee"
        modalBody={
          <>
            <Box>
               <InputGroup>
    <InputLeftAddon>{selectedCurrency}</InputLeftAddon>
    <Input  placeholder='In-Person Consultation Fee*' value={personConsultationFee} defaultValue={personConsultationFee} onChange={(e)=>handleFeeChange(e , "Person")} />
  </InputGroup>
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Fee" onClick={()=>personConsulationFee_Currency_UpdateFunc("Fee Structure")} />
          </>
        }
      />
<DynamicModal
        ref={onlineConsultationFeeModalRef}
        modalHeader="Edit Online Consultation Fee"
        modalBody={
          <>
            <Box>
               <InputGroup>
    <InputLeftAddon>$</InputLeftAddon>
    <Input  placeholder='Online Consultation Fee*' value={onlineConsulationFee} defaultValue={onlineConsulationFee} onChange={(e)=>handleFeeChange(e , "Appointment")} />
  </InputGroup>
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Fee" onClick={()=>onlineConsulationFee_Currency_UpdateFunc("Fee Structure")} />
          </>
        }
      />
<DynamicModal
        ref={remoteChatFeeModalRef}
        modalHeader="Edit Remote Chat Fee"
        modalBody={
          <>
            <Box>
            <InputGroup>
    <InputLeftAddon>$</InputLeftAddon>
    <Input  placeholder='Chat Fee*' value={remoteChatFee} defaultValue={remoteChatFee} onChange={(e)=>handleFeeChange(e , "Chat")} />
  </InputGroup>
              
            </Box>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton buttonText="Update Fee" onClick={()=>remoteChatFee_Currency_UpdateFunc("Fee Structure")} />
          </>
        }
      />
      

    </>
  );
};

export default DoctorProfileIndex;
