import React, { useRef, useState,useEffect, useLayoutEffect } from "react";
import MiniSideBar from "../../components/mini-sidebar";
import {
  Box,
  Card,
  CardFooter,
  Center,
  Circle,
  Divider,
  Flex,
  Image,
  InputGroup,
  Menu,
  MenuButton,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  MenuList,
  MenuItem,
  InputRightElement,
  Input,
  Td,
  Tr,
  Step,
  StepIndicator,
  StepTitle,
  StepDescription,
  Stepper,
  StepSeparator,
  UnorderedList,
  ListItem,
  CardBody,
  SimpleGrid,
} from "@chakra-ui/react";
import SecondaryNav from "../../components/seconday-nav";
import PrimaryButton from "../../components/primary-button";
import GrayCard from "../../components/card";
import SearchBar from "../../components/search-bar";
import TypingLoader from "../../components/typing-loader";
import {COLORS} from "../../components/styles/colors";
import ToggleTab from "../../components/accordion";
import DynamicModal from "../../components/dynamic-modal";
import ModalRight from "../../components/modal-right";
import { Link, NavLink, useLocation } from "react-router-dom";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import SimpleTable from "../../components/simple-table";
import moment from "moment";

import {
    BrowserRouter as Router,
    generatePath,
    Routes,
    Route,
    useNavigate,
    useParams,
} from "react-router-dom";
import { FetchFilteredPrescriptionsList, FetchPrescriptionViewDetails } from "../../middleware/services/prescriptions";
import { downloadDocument, formatDate_Do_MMM_YYYY, formatDate_YYYY_MM_DD, getLoginID, useScreenType } from "../../auth";
import DoctorCard from "../../components/doctor-card";
import MobileNavbar from "../../components/mobile-navbar";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { PrimaryInput, Toaster } from "../../components";
import DatePicker from "../../components/date-picker";
import { format } from "date-fns";


const PrescriptionViewIndex = () => {
  var [doctorid, setDoctorid] = useState(getLoginID());
  var [patientid, setPatientID] = useState("");
  var [data, setData] = useState(null);
  var [prescriptionViewdata, setPrescriptionViewData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  const screenType = useScreenType()
  const { showSuccessToast, showErrorToast } = Toaster();

  let itemPerPageCommonValue = 48;

  const [name, setName] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  let [pagesize, setPagesize] = useState(itemPerPageCommonValue);
  let [pageno, setPageno] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [showFilterCount, setShowFilterCount] = useState(false);


  // states for item count display
  let [countTo, setCountTo] = useState(itemPerPageCommonValue);
  let [countFrom, setCountFrom] = useState(1);
  let [totalExceedsPageCount, setTotalExceedsPageCount] = useState(false);

  const [ toDateMinMax , setToDateMinMax] = useState("")
  const [ fromDateMinMax , setFromDateMinMax] = useState("")
  
  // useEffect(() => {
  //   fetchPrescriptionsView();       
  // }, []);
  // const fetchPrescriptionsView = async () => {
  //   showLoader()
  //   try {
  //     const result = await FetchPrescriptionViewDetails({
  //       doctorid,
  //     });
  //     if (result != null) {
  //       setPrescriptionViewData(result);
  //     } else {
  //       // setPatientData("");
  //       dismissLoader()
  //     }
  //     dismissLoader()
  //   } catch (error) {
  //     console.error("Error in home:", error);
  //   }
  // };

  const handleChangeName = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^A-Za-z0-9\s@.]/g, '').slice(0, 50);
    event.target.value = inputValue;
    setName(event.target.value);
  };


  const handleFromDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    // console.log('formattedDAte: ', formattedDate)
    setFromDate(formattedDate);

    setFromDateMinMax(selectedDate);
  };

  const handleToDate = (selectedDate) => {
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    setToDate(formattedDate);

    setToDateMinMax(selectedDate);
  };

  // ----------------------------------------------------------
  // Pagination & Page Reload

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // ApplyFunction();

  };

  // var inputForFilterDetails = {
  //   "doctorid": getLoginID(),
  //   "searchtext": name,
  //   // "appointmentdate": formatDate_YYYY_MM_DD(visitDate),
  //   "startdate": formatDate_YYYY_MM_DD(fromDate),
  //   "enddate": formatDate_YYYY_MM_DD(toDate),
  //   "pageno":currentPage,
  //   pagesize
  // }

  // const ApplyFunction = async () => {
  //   if(!name && !fromDate && !toDate){
  //     showErrorToast('Please enter the inputs to filter.')
  //     return;
  //   }
  //   showLoader();
  //   try {
  //     setPageno(1);
  //     setPagesize(itemPerPageCommonValue);
  //     setCountFrom(1);
  //     setCountTo(itemPerPageCommonValue);
  //     setCurrentPage(1)

  //       const result = await FetchFilteredPrescriptionsList(inputForFilterDetails);
  //       // console.log(result, "APPly function result")
  //       if(result?.data){
  //         setPrescriptionViewData(result.data);
  //         // setNoData(result.data.length === 0);
  //         let count = result?.count;
  //         let pageno = result?.pageno;
  //         let pagesize = result?.pagesize;

  //         let totalPagesCalc = count / pagesize;
  //         let totalPagesCount = Math.ceil(totalPagesCalc);
  //         setTotalPages(totalPagesCount)
          
  //         if (count < itemPerPageCommonValue) {
  //           setCountTo(count);
  //           setTotalExceedsPageCount(true);
  //         }else if(count > itemPerPageCommonValue && pageno == 1) {
  //           setCountTo(itemPerPageCommonValue);
  //           setTotalExceedsPageCount(false);
  //         }
  //       }else{
  //         setPrescriptionViewData([])
  //       }
  //     } catch (error) {
  //       console.error('Error in ApplyFunction:', error);
  //     }
  //     dismissLoader();
  // };

  useEffect(() => {
    var input = {
      "doctorid": getLoginID(),
      "pageno": 1,
      pagesize
    }
    var getPrescriptionsList = async () => {
      showLoader();
      try{
        var result = await FetchPrescriptionViewDetails(input);

        if(result?.data){
          setPrescriptionViewData(result.data);
          // setNoData(result.data.length === 0);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);

          setTotalPages(totalPagesCount);

          setTotalItemCount(count)
          if(result && result.count  < itemPerPageCommonValue){
            setCountTo(result.count)
            setTotalExceedsPageCount(true)
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }

        } 
      } catch (error) {
        console.error("Error in home:", error);
      }
      dismissLoader();
    };

    getPrescriptionsList();
  }, [refresh])

  const getFilteredResults = () => {
    // if(!name && !email && !specialization && (!status || status== '')){
    //       showErrorToast('Please enter the inputs to filter.')
    //       return;
    //     }
    const tableContainer = document.querySelector('.tableContainer');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
    showLoader();
    var input = {
      "doctorid": getLoginID(),
        "searchtext": name,
        "startdate": formatDate_YYYY_MM_DD(fromDate),
    "enddate": formatDate_YYYY_MM_DD(toDate), 
        "pageno": pageno,
        pagesize
    };
    var getPrescriptionsList = async () => {
      try {
        var result = await FetchFilteredPrescriptionsList(input);

        if (result?.data) {
          setPrescriptionViewData(result.data);
          // setNoData(result.data.length === 0);
          let count = result?.count;
          let pageno = result?.pageno;
          let pagesize = result?.pagesize;
          setTotalItemCount(count)
          let totalPagesCalc = count / pagesize;
          let totalPagesCount = Math.ceil(totalPagesCalc);
          // alert(totalPages)
          setTotalPages(totalPagesCount);
          // setFilteredData(result.data);

          setTotalItemCount(result.count)
          // if(result && result.count  < itemPerPageCommonValue){
          //   setCountTo(result.count)
          //   setTotalExceedsPageCount(true)
          // }
          if (count < itemPerPageCommonValue) {
            setCountTo(count);
            setTotalExceedsPageCount(true);
          }else if(count > itemPerPageCommonValue && pageno == 1) {
            setCountTo(itemPerPageCommonValue);
            setTotalExceedsPageCount(false);
          }
        } else{
          setPrescriptionViewData([])
        }
      } catch (error) {
        console.log(error, "error in getDoctorsList");
      }
      dismissLoader();
    };

    getPrescriptionsList();
  };

  const handleApplyFilters = () => {
    // if(!patientName && !email && !mobile && (!plan || plan== '')){
    //   showErrorToast('Please enter the inputs to filter.')
    //   return;
    // }
    setShowFilterCount(true);
    setPageno(1)
    setCurrentPage(1)
    getFilteredResults();
  };

  const resetFields = () => {
    setName('')
    setFromDate('')
    setToDate('')
    setFromDateMinMax(null)
    setToDateMinMax(null)
    setPageno(1);
    // setPagesize(30);
    //clear states for correct item count
    setPagesize(itemPerPageCommonValue);
    setCountFrom(1)
    setCountTo(itemPerPageCommonValue)
    setCurrentPage(1)
    setShowFilterCount(false);

    setRefresh(!refresh);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // console.log('Enter key pressed');
      setPageno(1);
      setPagesize(itemPerPageCommonValue);
      setCountFrom(1);
      setCountTo(itemPerPageCommonValue);
      setCurrentPage(1)

      handleApplyFilters();
    }
  };

  useEffect(()=>{
    if(pageno){
      
       getFilteredResults();
       }
  },[pageno])

  const handleNextClick = () => {
    const prescriptionContainer = document.querySelector('.prescriptionContainer');
    if (prescriptionContainer) {
      prescriptionContainer.scrollTop = 0;
    }
    let page = pageno +1 ;
  //  alert(page)
    setPageno(page);
    setCurrentPage(currentPage + 1);
   // getFilteredResults();
   
   // item count logic for next
   if(countTo + pagesize < totalItemCount){
    setCountTo(countTo + pagesize)
   }else{
     setCountTo(totalItemCount)
   }
   
   setCountFrom(countFrom + pagesize)
  };
  const handlePrevClick = () => {
    const prescriptionContainer = document.querySelector('.prescriptionContainer');
    if (prescriptionContainer) {
      prescriptionContainer.scrollTop = 0;
    }
    let page = pageno -1 ;
    //alert(page)
    setPageno(page);
   // setPageno(pageno - 1);
    setCurrentPage(currentPage - 1);
   // getFilteredResults();
   // item count logic for previous
   setCurrentPage(currentPage - 1);
   if(countTo == totalItemCount){
        //setCountTo(Math.floor(totalItemCount / 10) * 10)
      let updatedCountUnroundedValue  = totalItemCount - pagesize;
     setCountTo(Math.ceil(updatedCountUnroundedValue / itemPerPageCommonValue ) * itemPerPageCommonValue )

   }else{
     setCountTo(countTo - pagesize)
   }
   
   setCountFrom(countFrom - pagesize)
  };
  return (
    <>
    {isLoading && <Loader />}
    {screenType == 'web' ? (
      <Box w="100%">
        <Flex>
          <Box w="6rem">
            <MiniSideBar />
          </Box>
          {/* {isLoading && <Loader />} */}
          <Box w="100%">
            <SecondaryNav customHeader="Prescription View" />
            <Box gap='10px' p='2rem' w='calc(100vw - 6.5rem)' borderBottom="1px solid #E6E7E9" display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
              <PrimaryInput
                variant="fullRound"
                title="Search"
                inputPlace="Search"
                id="yourName"
                onKeyDown={handleKeyDown}
                onChange={handleChangeName}
                inputValue={name}
              />

              <Box w='800px'>
              <DatePicker
                  // defaultDate={procedureDate}
                  onDateChange={(selectedDateValue) =>
                    handleFromDate(selectedDateValue)
                  }
                  placeholderProp="From Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px" }}
                  value={fromDate} 
                  clearValue={true}

                  CUSTOM_DISABLE={true}
                  disableFutureDates={true}
                  maxDate={toDateMinMax}
                />
              </Box>
                <Box w='800px'>
              <DatePicker
                  onDateChange={(selectedDateValue) =>
                    handleToDate(selectedDateValue)
                  }
                  placeholderProp="To Date"
                  datePickerInputStyle={{ borderRadius: "10px 10px 10px 10px " }}
                  value={toDate} 
                  clearValue={true}
                  
                  CUSTOM_DISABLE={true}
                  maxDate={new Date().toISOString()} 
                  minDate={fromDateMinMax}

                />
                </Box>

                <Box gap='10px' pl='2rem' w='70vw' display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
                <PrimaryButton buttonText="Filter" id="filterButton"
                 onClick={handleApplyFilters}  
                 />
                <PrimaryButton buttonText="Reset" id="resetButton" variant="grayBtnFull" btnStyle={{ color: "#AA2F27" }} 
                onClick={resetFields} 
                />
              </Box>

            </Box>

            <Box w='100%' p="1.5rem 2rem" maxH="calc(100vh - 260px)" overflowY="auto" className="prescriptionContainer">
            <SimpleGrid columns={{ base: 1, lg:prescriptionViewdata && prescriptionViewdata.length== 0? 1 : 3 , xl:prescriptionViewdata && prescriptionViewdata.length== 0? 1 : 4 }} spacing="1rem">
            {prescriptionViewdata && prescriptionViewdata.length> 0 ? (
              prescriptionViewdata.map((item , index)=>(
                <DoctorCard
                key={index}
                hasFooter={false}
                cardBody={
                  <>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      w="100%"
                      alignItems="flex-start"
                    >
                    
                      <Box display="flex" gap="0.75rem">
                      <Image
                                        borderRadius="full"
                                        w="2.75rem"
                                        h="2.75rem"
                                        // src={item.patientimage? item.imagepath+item.patientimage:"/assets/imgs/no-image.png"}
                                        src={item.patientimage ? 
                                          (item.patientimage.startsWith('http') ? item.patientimage : item.imagepath + item.patientimage) 
                                          : '/assets/imgs/no-image.png'}
                                      />
                        <Box p="10px">
                          {/* <Text
                          className="textOverflow" w="167px"
                            fontSize="0.625rem"
                            fontWeight="500"
                            color="#384052"
                            title={item.specialization}
                          >
                            {item.specialization}
                          </Text> */}
                          
                          <Text
                            fontSize="0.875rem"
                            fontWeight="600"
                            color="#061027"
                            title={item.patientname}
                          >
                            {item.patientname}
                          </Text>
                        </Box>
                      </Box>
                      <Text
                        fontSize="0.75rem"
                        fontWeight="500"
                        color="#384052"
                      >
                        {formatDate_Do_MMM_YYYY(item.prescribeddate)}
                      </Text>
                    </Box>

                    <Divider my="0.75rem" />
                    <Flex gap="8px" align="center">
                    <Text fontSize="12px" fontWeight="500">
                      {item.bookingid ? "#"+item.bookingid : "-"}
                    </Text>
                  </Flex>

                    <Box w="100%" display='flex' gap='10px'>
                      <Box
                        as="button"
                        onClick={()=>window.open(item.imagepath + item.documents)}
                        w="50%"
                        border="1px solid #E6E7E9"
                        p="10px"
                        borderRadius="10px"
                        mt="15px"
                        height="50px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <Box
                          display="flex"
                          fontSize="12px"
                          flexDirection="column"
                          justifyContent="center"
                          fontWeight="500"
                        >
                        Preview
                        
                        </Box>
                        {/* <Box borderRadius="full" p="10px">
                          <Image w="8px" src="/assets/imgs/right-icon.png" />
                        </Box> */}
                      </Box>
                      <Box
                        as="button"
                        onClick={()=>downloadDocument(item.imagepath + item.documents, "Order")}

                        w="50%"
                        border="1px solid #E6E7E9"
                        p="10px"
                        borderRadius="10px"
                        mt="15px"
                        height="50px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <Box
                          display="flex"
                          fontSize="12px"
                          flexDirection="column"
                          justifyContent="center"
                          fontWeight="500"
                        >
                        Download
                        
                        </Box>
                        {/* <Box borderRadius="full" p="10px">
                          <Image w="8px" src="/assets/imgs/right-icon.png" />
                        </Box> */}
                      </Box>
                    </Box>
                  </>
                }
              />
              ))
            ) : (
                  <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" height="calc(100vh - 300px)" width="100%">
                    <Image boxSize="64px" opacity="0.5" src="/assets/svgs/prescriptions.svg" />
                    <Text>No Prescriptions</Text>
                  </Box>
                )}
          </SimpleGrid>
          {/* {prescriptionViewdata == null && (
            <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' height='100vh' width='100%'>
            <Image boxSize='64px' opacity='0.5' src='/assets/svgs/prescriptions.svg'/>
            <Text>No Prescriptions</Text>
          </Box>
          )} */}
          {/* Pagination Section */}
            </Box>
          {prescriptionViewdata && prescriptionViewdata.length > 0 && (
                <Box w="100%" display="flex" boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px" justifyContent="space-between" alignItems="center" p='1rem' pl='2rem' pos='fixed' bottom='0' bg='white'>
                    <Box 
                         visibility={(totalItemCount < countTo && !totalExceedsPageCount)  ? "hidden" : "visible"}
                         >
                            <Text>Showing  <b>{countFrom}</b> to <b>{countTo}</b>  of {totalItemCount} results</Text>
                            </Box>
                            <Box  
                          gap="2.5rem"
                           display="flex"
                          justifyContent="space-between"
                          alignItems="center" 
                          mr='120px'>
                  <Box visibility={currentPage === 1 ? 'hidden' : 'visible'} currentPage={currentPage} onPageChange={handlePageChange}>
                    
                    <Box as="button" onClick={handlePrevClick} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                      Previous
                    </Box>
                  </Box>
                  <Text textAlign="center" fontSize=".875rem" fontWeight="600">
                    Page {currentPage} of {totalPages}
                  </Text>
                  {(totalItemCount >= countTo && !totalExceedsPageCount)  && 
                  <Box 
                  // visibility={currentPage === totalPages ? 'hidden' : 'visible'}
                  >
                                     
                    <Box as="button" onClick={handleNextClick} p="0.5rem 0.75rem" border="0.0625rem solid #E6E7E9" borderRadius="0.75rem" fontSize="1rem" fontWeight="500">
                      Next
                    </Box>
                    </Box>
                  }
                  </Box>
                </Box>
              )}
          </Box>
        </Flex>
      </Box>
      ) : (

        <Box w="100%">
          <MobileNavbar />
          <MobileSecondaryNav
            header="Prescriptions"
            handlebackarrow={false}
          />
          <Box w='100%' p="1rem" h="calc(100vh - 200px)" overflow="hidden auto">
          <SimpleGrid columns={1} spacing="1rem">
          {prescriptionViewdata && prescriptionViewdata.length> 0 && (
            prescriptionViewdata.map((item , index)=>(
              <DoctorCard
              key={index}
              hasFooter={false}
              cardBody={
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    w="100%"
                    alignItems="flex-start"
                  >
                  
                    <Box display="flex" gap="0.75rem">
                    <Image
                                      borderRadius="full"
                                      w="2.75rem"
                                      h="2.75rem"
                                      src={item.patientimage? item.imagepath+item.patientimage:"/assets/imgs/no-image.png"}
                                    />
                      <Box p="10px">
                        {/* <Text
                        className="textOverflow" w="167px"
                          fontSize="0.625rem"
                          fontWeight="500"
                          color="#384052"
                          title={item.specialization}
                        >
                          {item.specialization}
                        </Text> */}
                        
                        <Text
                          fontSize="0.875rem"
                          fontWeight="600"
                          color="#061027"
                          title={item.patientname}
                        >
                          {item.patientname}
                        </Text>
                      </Box>
                    </Box>
                    <Text
                      fontSize="0.75rem"
                      fontWeight="500"
                      color="#384052"
                    >
                      {formatDate_Do_MMM_YYYY(item.prescribeddate)}
                    </Text>
                  </Box>

                  <Divider my="0.75rem" />
                  <Flex gap="8px" align="center">
                  <Text fontSize="12px" fontWeight="500">
                    {item.bookingid ? "#"+item.bookingid : "-"}
                  </Text>
                </Flex>

                  <Box w="100%">
                    <Box
                      as="button"
                      onClick={()=>downloadDocument(item.imagepath + item.documents, "Order")}

                      w="100%"
                      border="1px solid #E6E7E9"
                      p="10px"
                      borderRadius="10px"
                      mt="15px"
                      height="50px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <Box
                        display="flex"
                        fontSize="12px"
                        flexDirection="column"
                        justifyContent="center"
                        fontWeight="500"
                      >
                      Download
                      
                      </Box>
                      {/* <Box borderRadius="full" p="10px">
                        <Image w="8px" src="/assets/imgs/right-icon.png" />
                      </Box> */}
                    </Box>
                  </Box>
                </>
              }
            />
            ))
          )
        }     
          </SimpleGrid>
          {prescriptionViewdata == null && (
            <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' height='100vh' width='100%'>
            <Image boxSize='64px' opacity='0.5' src='/assets/svgs/prescriptions.svg'/>
            <Text>No Prescriptions</Text>
          </Box>
          )}
            </Box>
        </Box>
      )}
    </>
  )
}

export default PrescriptionViewIndex;
