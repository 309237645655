import formAPIModelinvestigation from "../../messages/investigations/investigations";
import processAllergyData from "./allergy";
import processDemographicsData from "./demographic";
import processFamilyHistoryData from "./family-history";
import processInvestigationsData from "./investigations";
import processLabTestsData from "./lab-tests";
import processImagingTestsData from "./lab-tests/imaging-reports";
import processMedicalHistoryData from "./medical-history";
import processMedicationsData from "./medications";
import processPhysicalExamData from "./physical-exam";
import { mergeOrders } from "./prescriptions";
import processPastVisitsData from "./previous-visits";
import processROSData from "./ros";
import processSocialHistoryData from "./social-history";
import processSurgicalHistoryData from "./surgical-history";
import processTreatmentPlanData from "./treatment-plan";
import processVaccinationsData from "./vaccinations";
import processVitalsData from "./vitals";

function formApiModalPatientHealthDetailsForDoctor(input) {
  const formattedInput = {
    demographics: processDemographicsData(input.demographics),
    chiefcomplaint: input.chiefcomplaint, // needs formatting
    historyofpresentillness: input.historyofpresentillness,
    physicalexam: processPhysicalExamData(input.physicalexam) || [],
    treatmentplan: processTreatmentPlanData(input.treatmentplan) || [],
    medicalhistory: processMedicalHistoryData(input.medicalhistory) || [],
    surgicalhistory: processSurgicalHistoryData(input.surgicalhistory) || [],
    familyhistory: processFamilyHistoryData(input.familyhistory) || [],
    socialhistory: processSocialHistoryData(input.socialhistory) || [],
    medications: processMedicationsData(input.medications) || [], // grouped by dates
    medicationForOverview: input.medications || [], // not grouped by dates
    vitals: processVitalsData(input.vitals) || [],
    vaccinations: processVaccinationsData(input.vaccinations) || [],
    reviewofsystem: processROSData(input.reviewofsystem) || [],
    allergy: processAllergyData(input.allergy) || [],
    investigations: processInvestigationsData(input.investigations) || [],
    overviewinvestigations:
      input.investigations !== null
        ? formAPIModelinvestigation(input.investigations)
        : [],
    labs: processLabTestsData(input.labs) || [],
    imaging: processImagingTestsData(input.imaging) || [], // needs formatting
    treatmentteam: input.treatmentteam || [], // needs formatting
    patientinfo: input.patientinfo, // needs formatting
    Previousvisits: processPastVisitsData(input.Previousvisits) || [], // needs formatting
    doctorsassessment: input.doctorsassessment || [],
    orderprocedure: input.orderprocedure || [],
    orderimaging: input.orderimaging || [],
    orderlab: input.orderlab || [],
    orderprescription: input.orderprescription,
    progressnotes: input.progressnotes,
    recommendedtests:
      mergeOrders(input.orderprocedure, input.orderlab, input.orderimaging) ||
      [],
    visitformdata: input?.visitformdata,
  };

  // console.log("check", formattedInput.familyhistory);

  return formattedInput;
}

function formApiModalPatientPreviousVisitDetailForDoctor(input, visitDate) {
  // console.log("Date Recieved: ", visitDate);

  const formattedInput = {
    demographics: processDemographicsData(input.demographics),
    chiefcomplaint: input.chiefcomplaint, // needs formatting
    historyofpresentillness: input.historyofpresentillness,
    physicalexam: processPhysicalExamData(input.physicalexam) || [],
    treatmentplan: processTreatmentPlanData(input.treatmentplan) || [],
    medicalhistory: processMedicalHistoryData(input.medicalhistory) || [],
    surgicalhistory: processSurgicalHistoryData(input.surgicalhistory) || [],
    familyhistory: processFamilyHistoryData(input.familyhistory) || [],
    socialhistory: processSocialHistoryData(input.socialhistory) || [],
    medications: processMedicationsData(input.medications) || [], // grouped by dates
    medicationForOverview: input.medications || [], // not grouped by dates
    vitals: processVitalsData(input.vitals) || [],
    vaccinations: processVaccinationsData(input.vaccinations) || [],
    reviewofsystem: processROSData(input.reviewofsystem) || [],
    allergy: processAllergyData(input.allergy) || [],
    investigations: processInvestigationsData(input.investigations) || [],
    overviewinvestigations:
      input.investigations !== null
        ? formAPIModelinvestigation(input.investigations)
        : [],
    labs: processLabTestsData(input.labs) || [],
    imaging: processImagingTestsData(input.imaging) || [],
    treatmentteam: input.treatmentteam || [], // needs formatting
    patientinfo: input.patientinfo, // needs formatting
    doctorsassessment: input.doctorsassessment || [],
    orderprocedure: input.orderprocedure || [],
    orderimaging: input.orderimaging || [],
    orderlab: input.orderlab || [],
    orderprescription: input.orderprescription,
    recommendedtests:
      mergeOrders(input.orderprocedure, input.orderlab, input.orderimaging) ||
      [],
    // Previousvisits: processPastVisitsData(input.Previousvisits), // needs formatting
    visitformdata: input?.visitformdata,
  };

  // console.log("check", formattedInput.familyhistory);
  console.log("check", formattedInput);
  return formattedInput;
}

export {
  formApiModalPatientHealthDetailsForDoctor,
  formApiModalPatientPreviousVisitDetailForDoctor,
};
