import {
  Box,
  Divider,
  Image,
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FetchLabTestDetails } from "../../middleware/services/messages";
import moment from "moment";
import { actionAccessMain, downloadDocument, getPatientId } from "../../auth";
import useLoader from "../../components/loader/loaderstates";
import Loader from "../../components/loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import MobileSecondaryNav from "../../components/mobile-secondary-navbar";
import { PrimaryButton, Toaster } from "../../components";
import DynamicModal from "../../components/dynamic-modal";
import { DeletePatientLabReport } from "../../middleware/services/messages/uploaddocument";

const LabTests = ({id, backFunction}) => {
  const location = useLocation();
  const [patientid, setPatientid] = useState("");
  const [labTestData, setLabTestData] = useState(null);
  const { isLoading, showLoader, dismissLoader } = useLoader();
  
  const { showSuccessToast, showErrorToast } = Toaster();

  const [actionAccessPatients , setActionAccessPatients] = useState(null);
  
  useEffect(() => {
    
    setActionAccessPatients(actionAccessMain(4))
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(currentUrl.split('?') [1]);
    const searchParamsPage = new URLSearchParams(currentUrl.split('&') [1]);
    const id = searchParams.get('id');
    const page = searchParamsPage.get('page');
    if(page == 'labTest'){
      navigate("/patientprofile?id="+ id + "&page=" + 'overview')
    }
    setPatientid(id);
    fetchlabtestdata(id)
    
  }, []);
  
  const fetchlabtestdata = async (id) => {
    showLoader()
        try {
          const result = await FetchLabTestDetails({
            patientid: id,
          });

          if (result != null) {
            console.log(result)
            setLabTestData(result);
          } else {
            setLabTestData([])
          }
          dismissLoader()
      } catch (error) {
          console.error("Error in home:", error);
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return "-";
        return moment(dateString).format("Do MMM, YYYY");
      };

      // console.log(patientid, "labTest IDDDDD")
      // console.log(labTestData, "labTest Dataaaaaaa")

      const getIconForExtension = (filename) => {
        if (filename && typeof filename === 'string') {
        const basename = filename.substring(filename.lastIndexOf('/') + 1);
        const extension = basename.slice(basename.lastIndexOf('.') + 1).toLowerCase();
        
        switch (extension) {
          case 'png':
            return '/assets/imgs/png-icon.png';
          case 'jpg':
            return '/assets/imgs/jpg-icon.png';
          case 'jpeg':
            return '/assets/imgs/jpg-icon.png';
          default:
            return '/assets/svgs/pdf-icon.svg'; 
        }
      }
      } 

      const fileInputRef = useRef(null);

      var navigate = useNavigate();

      const handleimageselection = async (e) => {
        e.preventDefault();
        
        
        localStorage.setItem("totalDocumentsSelected", 1); 
        localStorage.setItem("remainingDocuments", 1); 

        const file = e.target.files[0];
        showLoader();
        
        const currentUrl = window.location.href
        const searchParams = new URLSearchParams(currentUrl.split('?')[1]);
        const patientid = searchParams.get('id');

        navigate("/uploaddocument", { state: { file , patientid} });
        dismissLoader();
      };
      const openUploadLabs = () => {
        fileInputRef.current.click(); 
      }


    
      const dynamicModal = React.useRef();
      const [reportName, setReportName] = useState("");
      const [reportid, setReportid] = useState([]);
      const openDeletePopup = (id, reportname, tab) => {
        // const ids = Array.isArray(reportidArray) ? reportidArray : [reportidArray];
        
        setReportid(id);
    
        // setReportid(reportidArray);
        setReportName(reportname);
        dynamicModal.current.openModal();
      };

      const deletePatientReports = async () => {
        showLoader();
        try {
          // const reportIds = Array.isArray(reportid) ? reportid : [reportid];
          // for (const id of reportIds) {
            const inputForDelete = {
                patientid,
              reportid: [{ "id": reportid }],
            };
    
            var result = await DeletePatientLabReport(inputForDelete);
       
            if(result.output.message == "Successfully Deleted"){
              showSuccessToast("Deleted Report Successfully!");
              let id = getPatientId();
              fetchlabtestdata(id)   
              dismissLoader();
            }
          // }
        } catch (error) {
          console.error(`Error deleting report with reportid: ${reportid}`, error);
        }
        // showSuccessToast("Deleted Reports Successfully")
        dismissLoader();
        dynamicModal.current.closeModal();
      }


  return (
    <>
    {isLoading && <Loader />}
    <Box  display="flex" justifyContent="center" w="100%"  flexDir="column">
    <Box  pos='sticky' p='1rem 1.5rem' alignItems='center' top='0' zIndex='999' bg='white' borderBottom='1px solid #E6E7E9' display="flex" justifyContent="space-between" mb="10px">
    <Text fontWeight='600'>Lab Tests</Text>
    <PrimaryButton
            fontSize="14px"
            variant="mdBtn"
            buttonText="Upload report"
            btnIcon="/assets/imgs/add.png"
            onClick={openUploadLabs}
            
            isDisabled={actionAccessPatients?.create ? false : true}
          />
           <input
        type='file'
        id='file-upload'
        onChange={handleimageselection}
        ref={fileInputRef} // Set the ref for the file input
        accept="image/jpeg,image/jpg,image/png,image/tiff,application/pdf"
        style={{ display: 'none' }} // Hide the file input
      /> 
          
      </Box>
        
      <Box w="100%" display={{base: "none", lg: "block"}}>
        <Stepper
          orientation="vertical"
          height="100%"
          size="xs"
          colorScheme="#CDCFD4"
          gap="0"
          padding='1.5rem'
          w="100%"
          h='calc(100vh - 140px)'
          overflow='hidden auto'
        >
           
          {Array.isArray(labTestData)  && labTestData.length>0 ? (
              labTestData.map((lab,index) => (
            <Step w="100%">
              <StepIndicator color="#E6E7E9" />
              <Box flexShrink="0" w="95%">
                <StepTitle
                  fontSize="0.75rem"
                  fontWeight="500"
                  color="#384052"
                  mb="0.5rem"
                  key={index}
                >
                  {/* 10th Jan, 2024 */}
                  {formatDate(lab.verifieddate)}
                </StepTitle>
                <StepDescription
                  bg="white"
                  w="100%"
                  border="1px solid #E6E7E9"
                  borderRadius="0.65rem"
                  p="1rem"
                  mb="1.5rem"
                  boxShadow="0px 8px 20px 0px #E6E7E966"
                >
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb="0.5rem"
                      >
                        <Text fontSize="0.875rem" color="#061027">
                          {/* Heart failure with preserved ejection fraction,
                          unspecified HF chronicity (CMS/HCC) */}
                          {lab.reportname}
                        </Text>
                        

                <Box display='flex'  gap='0.5rem' mt='1rem' justifyContent='flex-end'>
                              
                              <Box
                                as="button"
                                border="1px solid #E6E7E9"
                                borderRadius="full"
                                w="36px"
                                h="auto"
                                p="10px"
                                onClick={() =>
                                  openDeletePopup(
                                    lab.reportid,
                                    lab.reportname,
                                    "labs"
                                  )
                                }
                                disabled={actionAccessPatients?.delete ? false : true}
                                opacity={actionAccessPatients?.delete ? 1 : 0.5}
                              >
                                <Image w="13px" src="/assets/imgs/delete.png" />
                              </Box>
                            </Box> 
                      </Box>
                      <Box
                        bg="#F9FAFB"
                        borderLeft="2px solid #14C585"
                        p="0.5rem"
                      >
                        <Text
                          fontSize="0.875rem"
                          fontWeight="500"
                          color="#384052"
                        >
                          {/* Plan: Basic metabolic panel, Magnesium */}
                          {lab.reportdescription}
                        </Text>
                      </Box>

                      <Divider my="1rem" />
                      <Box display="flex" gap="0.75rem">
                      {lab.documenturl != null ? (
                      <>
                        <Box
                          display="flex"
                          gap="0.5rem"
                          p="0.5rem 1rem"
                          bg="white"
                          border="1px solid #E6E7E9"
                          // borderRadius="0.65rem"
                          cursor='pointer'
                          // onClick={()=>downloadDocument(lab.imagepath + lab.documenturl, "report")}
                          onClick={() => downloadDocument(`${lab.imagepath}${lab.documenturl}`, `report.${lab.documenturl.split('.').pop()}`)}
                          borderRadius= '10px 0 0 10px'
                          >
                          <Image
                            w="1.125rem"
                            h="auto"
                            // src="/assets/svgs/pdf-icon.svg"
                            src={getIconForExtension(lab.documenturl)}
                          />
                          <Text fontSize="0.75rem" fontWeight="600">
                            {/* Prescription.pdf */}
                            {/* {`${lab.imagepath}${lab.documenturl}`} */}
                            {`Document- ${index + 1}`}
                          </Text>
                        </Box>

                        <Box
                            display="flex"
                            gap="0.5rem"
                            p="0.5rem 0.5rem"
                            bg="white"
                            border="1px solid #E6E7E9"
                            // borderRadius="0.65rem"
                            cursor='pointer'
                            // onClick={() => {window.open(
                            //   lab.imagepath + lab.documenturl, '_blank')}
                            // }
                            onClick={() => {
                              const previewUrl = `${lab.imagepath}${lab.documenturl}`;
                              // console.log("Preview URL:", previewUrl);
                              window.open(previewUrl, '_blank');
                            }}
                            position= 'relative'
                            left= '-13px'
                            alignItems='center'
                            borderRadius= '0 10px 10px 0'
                            >
                              <Image
                              w="1.125rem"
                              // h="auto"
                              src="/assets/imgs/show-pass.png"
                              />
                          </Box>
                      </>   
                      ) : ('')}
                      </Box>
                    </Box>

                </StepDescription>
              </Box>

              
              <StepSeparator colorScheme="red" />
              </Step>
            ))

          ) : (
            <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            gap="10px"
                            alignItems="center"
                            h="calc(100vh - 100px)"
                            w="100%"
                          >
                            <Box textAlign="left" fontSize="16px" fontWeight="600">
                              <Image
                                w="26px"
                                opacity="0.5"
                                src="/assets/imgs/biotech.png"
                              />
                            </Box>
                            <Text textAlign="left" fontSize="12px" fontWeight="500">
                              No Data available
                            </Text>
                          </Box>
          )}
        </Stepper>
      </Box>
        
      </Box>


      <DynamicModal
        ref={dynamicModal}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete  <strong>"{reportName}"</strong>
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePatientReports}
            />
          </>
        }
      />



      
<DynamicModal
        ref={dynamicModal}
        modalHeader="Delete report?"
        modalBody={
          <>
            <Text fontSize="14px" fontWeight="600">
              Are you sure you want to delete  <strong>"{reportName}"</strong>
            </Text>
          </>
        }
        modalFooterBtn={
          <>
            <PrimaryButton
              variant="deleteBtn"
              buttonText="Delete"
              onClick={deletePatientReports}
            />
          </>
        }
      />



      {/* Mobile responsive */}
      <Box w="100%" display={{base: "block", lg: "none"}}>
        <MobileSecondaryNav
          header="Lab test"
          handlebackarrow={backFunction}
        />

        <Box h="calc(100vh - 120px)" overflow='hidden auto' p="1rem">
          <Stepper
            orientation="vertical"
            height="100%"
            size="xs"
            colorScheme="#CDCFD4"
            gap="0"
            w="100%"
          >
            {labTestData && labTestData.length>0 ? (
                labTestData.map((lab,index) => (
              <Step w="100%">
                <StepIndicator color="#E6E7E9" />
                <Box flexShrink="0" w="95%">
                  <StepTitle
                    fontSize="0.75rem"
                    fontWeight="500"
                    color="#384052"
                    mb="0.5rem"
                    key={index}
                  >
                    {/* 10th Jan, 2024 */}
                    {formatDate(lab.verifieddate)}
                  </StepTitle>
                  <StepDescription
                    bg="white"
                    w="100%"
                    border="1px solid #E6E7E9"
                    borderRadius="0.65rem"
                    p="1rem"
                    mb="1.5rem"
                    boxShadow="0px 8px 20px 0px #E6E7E966"
                  >
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          pb="0.5rem"
                        >
                          <Text fontSize="0.875rem" color="#061027">
                            {/* Heart failure with preserved ejection fraction,
                            unspecified HF chronicity (CMS/HCC) */}
                            {lab.reportname}
                          </Text>
                        </Box>
                        <Box
                          bg="#F9FAFB"
                          borderLeft="2px solid #14C585"
                          p="0.5rem"
                        >
                          <Text
                            fontSize="0.875rem"
                            fontWeight="500"
                            color="#384052"
                          >
                            {/* Plan: Basic metabolic panel, Magnesium */}
                            {lab.reportdescription}
                          </Text>
                        </Box>

                        <Divider my="1rem" />
                        <Box display="flex" gap="0.75rem">
                          <Box
                            display="flex"
                            gap="0.5rem"
                            p="0.5rem 1rem"
                            bg="white"
                            border="1px solid #E6E7E9"
                            borderRadius="0.65rem"
                            cursor='pointer'
                            onClick={()=>downloadDocument(lab.imagepath + lab.documenturl, "report")}>
                            <Image
                              w="1.125rem"
                              h="auto"
                              // src="/assets/svgs/pdf-icon.svg"
                              src={getIconForExtension(lab.documenturl)}
                            />
                            <Text fontSize="0.75rem" fontWeight="600">
                              {/* Prescription.pdf */}
                              {/* {`${lab.imagepath}${lab.documenturl}`} */}
                              {`Document- ${index + 1}`}
                            </Text>
                          </Box>
                        </Box>
                      </Box>

                  </StepDescription>
                </Box>

                
                <StepSeparator colorScheme="red" />
                </Step>
              ))

            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="10px"
                alignItems="center"
                h="calc(100vh - 100px)"
                w="100%"
              >
                <Box textAlign="left" fontSize="16px" fontWeight="600">
                  <Image
                    w="26px"
                    opacity="0.5"
                    src="/assets/imgs/biotech.png"
                  />
                </Box>
                <Text textAlign="left" fontSize="12px" fontWeight="500">
                  No Data available
                </Text>
              </Box>
            )}
          </Stepper>
        </Box>
      </Box>
    </>
  );
};

export default LabTests;
