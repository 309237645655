import  FetchData, { } from '../../client';

import {getBranchId, getLoginID}  from '../../../auth';

import formapimodalpatientsPagination from '../../domains/patientsview';
import formapimodalpatientsview from '../../domains/patientsview/patientViews';

  const FetchPatientViewDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientlistbyappointment", inputData);
      //console.log(result, "Patient View Result")
      if (result.output) {
        var finalOutput = formapimodalpatientsPagination(result.output)
        }

        //console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchFilteredPatientsList = async (inputData) => {
    try{
        // inputData.loginid = getLoginID();
         inputData.branchid = getBranchId();
        const result = await FetchData('midoc_getpatientlistbyappointment', inputData);
        // console.log(result, "Filtered Patients View Result");

        if(result.output.data){
            var data = formapimodalpatientsview(result.output.data);

            let count = result?.output?.count;
            let pageno = result?.output?.pageno;
            let pagesize = result?.output?.pagesize;
            var finalOutput = {
                data,
                count,
                pageno,
                pagesize
            }
            console.log(finalOutput, "final OUtuput");
            return finalOutput;
        }

    } catch(error) {
        console.error('Error fetching data in forms.js:', error);
        throw error;
    }
}

// Schedule Appointment
const ScheduleAppointmentByDoctor = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_bookappointment", inputData);
    // console.log("api", result);

    return result;
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

// Reshedule Appointment
const ResheduleAppointmentByDoctor = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_doctorupdateappointment", inputData);
    // console.log("api", result);

    return result;
  } catch (error) {
    console.error("Error fetching data in visits.js:", error);
    throw error;
  }
};

const FetchDoctorSlots = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_getdoctorslot", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error fetching data for doctor slots:", error);
    throw error;
  }
};

// Edit Patient Profile
// const FetchPatientEditDetails = async (inputData) => {
//   try {
//     inputData.doctorid = getLoginID();
//     const result = await FetchData("midoc_updatepatientdetailfromdoctor", inputData);
//     // console.log(result,"db response")
//     if (result.output.data) {
//     var finalOutput = formapimodalpatienthealt(result.output.data)
//     }
//     // console.log(finalOutput, "finalllOuTput")
//     return finalOutput;

//   } catch (error) {
//     console.error('Error fetching data in forms.js:', error);
//     throw error;
//   }
// };

const UpdatePatientProfile = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    //inputData.doctorid = getLoginID();
    const result = await FetchData("midoc_updatepatientdetailfromdoctor", inputData);
    // console.log(inputData);
    // console.log(result, "db response");
    return result;
  } catch (error) {
    console.error("Error saving data for updating the profile:", error);
    throw error;
  }
};

  

export {FetchPatientViewDetails, FetchFilteredPatientsList, ScheduleAppointmentByDoctor, ResheduleAppointmentByDoctor, FetchDoctorSlots, UpdatePatientProfile};
