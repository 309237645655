export default function formAPIMedicinesView({
    id,
    doctorid,
    medicineid,
    medicinename,
    medicinetype,
    dosage,
    dosageunit,
    medicationstartdate,
    medicationenddate,
    frequency,
    morning,
    afternoon,
    evening,
    beforefood,
    afterfood,
    prescribedby,
    prescribedhospitalname,
    iscurrentmedicine,
    medicationstatus_cd,
    medicineunit,
    night,
    chemicalcomposition,
    benefit,
    sideeffects,
    modifieddate,

    // new states of medicine input start ||----
    routeofadministration,
    timingofinjection,
    quantitytobeapplied,
    areaofapplication,
    diluent,
    infusionduration,
    additives,
    duration,
    rateofinfusionvalue,
    rateofinfusionunit,

    sos,
    custommessage
     // new states of medicine input End ----||
    
  }) {
   
  
    return Object.freeze({
        id,
    doctorid,
    medicineid,
    medicinename,
    medicinetype,
    dosage,
    dosageunit,
    medicationstartdate,
    medicationenddate,
    frequency,
    morning,
    afternoon,
    evening,
    beforefood,
    afterfood,
    prescribedby,
    prescribedhospitalname,
    iscurrentmedicine,
    medicationstatus_cd,
    medicineunit,
    night,
    chemicalcomposition,
    benefit,
    sideeffects,
    modifieddate,

     // new states of medicine input start ||----
    routeofadministration,
    timingofinjection,
    quantitytobeapplied,
    areaofapplication,
    diluent,
    infusionduration,
    additives,
    duration,
    rateofinfusionvalue,
    rateofinfusionunit,

    sos,
    custommessage
     // new states of medicine input End ----||
    
    });
  }
  