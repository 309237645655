import  FetchData, { } from '../../client';
import formapimodalpatienthealt from '../../domains/messages/index';
import {getBranchId, getLoginID}  from '../../../auth';
import formapimodalpatients from "../../domains/doctorpatients/patients"
import formapimodalchat from "../../domains/chat/chats"
import formapimodalchatmedia from '../../domains/messages/chatmedia';
import formAPIreaction from '../../domains/messages/allergies/reactions';
import formAPIModelPreviousvisits from '../../domains/messages/previousvisits/previousvistis';
import formAPIModelvitals from '../../domains/messages/vitals/vitals';
import formAPIModelpastsurgicalhistory from '../../domains/messages/pastsurgicalhistory/pastsurgicalhistories';
import formAPIModelpastmedicalhistory from '../../domains/messages/pastmedicalhistory/pastmedicalhistories';
import formAPIModelfamilyhistory from '../../domains/messages/familyhistory/familyhistories';
import formAPIModelsocialhistory from '../../domains/messages/socialhistory/socialhistories';
import formAPIModelphysicalexam from '../../domains/messages/physicalexam/physicalexams';
// import formAPIModeltreatmentplan from '../../domains/messages/treatmentplan/treatmentplans';
import formAPIModeldoctorassessment from '../../domains/messages/doctorassessment/doctorassessements';
import formAPIModelreviewofsystem from '../../domains/messages/reviewofsystem/reviewofsystems';
import formAPIModelvaccinations from '../../domains/messages/vaccination';
import formAPIModelinvestigation from '../../domains/messages/investigations/investigations';
import formAPIModellabs from '../../domains/messages/labs/labs';
// import formAPItreatmentplan from '../../domains/messages/treatmentplan/treatmentplan';
import formAPIModelchiefcomplaint from '../../domains/messages/chiefcomplaint/chiefcomplaints';
import formAPIModelhistoryofpresentillness from '../../domains/messages/historyofpresentillness/historyofpresentillnesses';
import formAPIOrdersIndex from '../../domains/messages/messageorders';
import formAPIModelimaging from '../../domains/messages/imaging/imagings';
// import formAPIchiefcomplaint from '../../domains/messages/chiefcomplaint/chiefcomplaint';

//reac the file

const FetchPatientDetails = async (inputData) => {
  try {
    inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getpatienthealthdetailfordoctor", inputData);
    // console.log(result,"db response")
    if (result.output.data) {
    var finalOutput = formapimodalpatienthealt(result.output.data)
    }
    // console.log(finalOutput, "finalllOuTput")
    return finalOutput;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
const FetchPatients = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getchatpatientlistfordoctor", inputData);
      // console.log(result,"db response patients")
      if (result.output.data) {
      var finalOutput = formapimodalpatients(result.output.data)
      }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchChatData = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getchatbydoctor", inputData);
      //console.log(result,"db response chats")
      if (result.output.data) {
      var finalOutput = formapimodalchat(result.output.data)
      }
      if(result.output.appointmentdetails){
        var chatheaderoutput = result.output.appointmentdetails[0];
      }
      if(result.output){
      var chatenablesettings = result.output.chatenabledetail;
      }
      return {finalOutput,chatheaderoutput,chatenablesettings};
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const AddMessage = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_addchat", inputData);
      //console.log(result,"db response add chat")
      
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  const FetchPatientChatMedia = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getchatmediabydoctor", inputData);
      if (result.output.patientdata) {
        var finalOutput = formapimodalchatmedia(result.output.patientdata)
        }
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };




  const FetchAllergyDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientallergy", inputData);
      // console.log(result, "Allergy Result")
      if (result.output.data) {
        var finalOutput = formAPIreaction(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchVisitDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofilevisit", inputData);
      // console.log(result, "Visit Result")
      if (result.output.data) {
        var finalOutput = formAPIModelPreviousvisits(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchChiefComplaint = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientchiefcomplaints", inputData);
      //console.log(result, "ChiefComplaint Result")
      if (result.output.data) {
        var finalOutput = formAPIModelchiefcomplaint(result.output.data)
        }

        //console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchHistoryOfPresentIllness = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatienthpi", inputData);
      // console.log(result, "HistoryPresentIllness Result")
      if (result.output.data) {
        var finalOutput = formAPIModelhistoryofpresentillness(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchOrderDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofileorder", inputData);
      // console.log(result, "Orders Result")
      if (result.output) {
        var finalOutput = formAPIOrdersIndex(result.output)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchVitalsDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofilevitals", inputData);
      // console.log(result, "Vitals Result")
      if (result.output.data) {
        var finalOutput = formAPIModelvitals(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchMedicalHistoryDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getmedicalcondition", inputData);
      // console.log(result, "Medical History Result")
      if (result.output.data) {
        var finalOutput = formAPIModelpastmedicalhistory(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const addMedicalConditions = async (inputData) => {
    try {
      
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_addmedicalcondition", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  export const EditCondition = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_updatemedicalcondition", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  
  export const deleteMedicalConditions = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_deletemedicalcondition", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in delete medical conditions data:', error);
      throw error;
    }
  };

  const FetchSurgicalHistoryDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprocedure", inputData);
      // console.log(result, "Surgical History Result")
      if (result.output.data) {
        var finalOutput = formAPIModelpastsurgicalhistory(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const addSurgeries = async (inputData) => {
    try {
      
    inputData.branchid = getBranchId();
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
          const result = await FetchData("midoc_addpatientprocedure", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  
export const editSurgeries = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    // inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_updatepatientprocedure", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete medical conditions data:', error);
    throw error;
  }
};
export const deleteSurgeries = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    // inputData.patientid = getLoginInfo();
    // inputData.activeuserid = getUserID();
        const result = await FetchData("midoc_deletepatientprocedure", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete medical conditions data:', error);
    throw error;
  }
};
  const updateCondition = async (inputData) => {
    try {
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_updatevisitbydoctor", inputData);
      // console.log(result, "updated");
      return result;
    } catch (error) {
      console.error("Error in editCondition:", error);
      throw error;
    }
  };

  const FetchFamilyHistoryDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientfamilylist", inputData);
      // console.log(result, "Family History Result")
      if (result.output.data) {
        var finalOutput = formAPIModelfamilyhistory(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const AddFamilyHistory = async (inputData) => {
    try {
      
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
          const result = await FetchData("midoc_addpatientfamily", inputData);
      //console.log(result,"Family add db response")
      // if (result.output.data) {
      // var finalOutput = formAPIModelFamilyHistory(result.output.data)
      // console.log(finalOutput)
      // }
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const EditFamilyHistory = async (inputData) => {
    try {
      
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
          const result = await FetchData("midoc_updatepatientfamily", inputData);
      console.log(result,"Family add db response")
      // if (result.output.data) {
      // var finalOutput = formAPIModelFamilyHistory(result.output.data)
      // console.log(finalOutput)
      // }
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const DeleteFamilyHistory = async (inputData) => {
    try {
      
      // inputData.patientid = getLoginInfo();
      // inputData.activeuserid = getUserID();
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
          //console.log(inputData)
      const result = await FetchData("midoc_deletepatientfamily", inputData);
      //console.log(result,"Family add db response")
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchSocialHistoryDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientsocialhistory", inputData);
      // console.log(result, "Social History Result")
      if (result.output.data) {
        var finalOutput = formAPIModelsocialhistory(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchPhysicalExamDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofilephysicalexam", inputData);
      // console.log(result, "Physical Exam Result")
      if (result.output.data) {
        var finalOutput = formAPIModelphysicalexam(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  // const FetchTreatmentPlanDetails = async (inputData) => {
  //   try {
  //     inputData.doctorid = getLoginID();
      // inputData.branchid = getBranchId();
  //     const result = await FetchData("midoc_getpatientprofiletreatmentplan", inputData);
  //     // console.log(result, "Treatment Plan Result")
  //     if (result.output.data) {
  //       // var finalOutput = formAPIModeltreatmentplan(result.output.data)
  //       var finalOutput = formAPItreatmentplan(result.output.data)
  //       }

  //       // console.log(finalOutput, "finalOUTPUT")
  //     return finalOutput;
  
  //   } catch (error) {
  //     console.error('Error fetching data in forms.js:', error);
  //     throw error;
  //   }
  // };

  const FetchDoctorAssessmentDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofiletreatmentplan", inputData);
      // console.log(result, "Doctor Assesment Result")
      if (result.output.data) {
        var finalOutput = formAPIModeldoctorassessment(result.output.data)
        // var finalOutput = formAPIdoctorassessment(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchReviewSystemsDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofilereviewofsystems", inputData);
      // console.log(result, "Review Systems Result")
      if (result.output.data) {
        var finalOutput = formAPIModelreviewofsystem(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchVaccinationDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientvaccination", inputData);
      // console.log(result, "Vaccination Result")
      if (result.output.data) {
        var finalOutput = formAPIModelvaccinations(result.output.data)
        }

        // console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchInvestigationDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofileinvestigation", inputData);
      // console.log(result, "Investigation Result")
      if (result.output.data) {
        var finalOutput = formAPIModelinvestigation(result.output.data)
        }

      //   console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchLabTestDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofilelabtest", inputData);
      // console.log(result, "Lab Test Result")
      if (result.output.data) {
        var finalOutput = formAPIModellabs(result.output.data)
        }

      //   console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const FetchImagingDetails = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getpatientprofileimagingtest", inputData);
      // console.log(result, "Imaging Result")
      if (result.output.data) {
        var finalOutput = formAPIModelimaging(result.output.data)
        }

      //   console.log(finalOutput, "finalOUTPUT")
      return finalOutput;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };

  const updatechatmessageread = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_updatechat", inputData);

      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  
  const requestPayment = async (inputData) => {
    try {
      inputData.doctorid = getLoginID();
      inputData.branchid = getBranchId();
      const result = await FetchData("midoc_enablechat", inputData);

      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };


  export const getmedicalcondition = async (inputData) => {
    try {
      
    inputData.branchid = getBranchId();
      const result = await FetchData("midoc_getdefaultdata", inputData);
  
      return result;
  
    } catch (error) {
      console.error('Error fetching data in forms.js:', error);
      throw error;
    }
  };
  export const addAllergies = async (inputData) => {
    try {
      
  
      inputData.branchid = getBranchId();
          const result = await FetchData("midoc_addpatientallergy", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };

  export const editAllergies = async (inputData) => {
    try {
      
    inputData.branchid = getBranchId();
          const result = await FetchData("midoc_updatepatientallergy", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error fetching medical conditions data:', error);
      throw error;
    }
  };
  
  export const deleteAllergies = async (inputData) => {
    try {
      
    inputData.branchid = getBranchId();
          const result = await FetchData("midoc_deletepatientallergy", inputData);
      
      return result;
  
    } catch (error) {
      console.error('Error in deletepatientallergy :', error);
      throw error;
    }
  };


  
  export const getVaccineList = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdefaultdata", inputData);

    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};


export const addVaccination = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
        const result = await FetchData("midoc_addpatientvaccination", inputData);
    
    return result;

  } catch (error) {
    console.error('Error fetching medical conditions data:', error);
    throw error;
  }
};


export const deleteVacinations = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
        const result = await FetchData("midoc_deletepatientvaccination", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete Vacinations data:', error);
    throw error;
  }
};


export const editVaccinations = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
        const result = await FetchData("midoc_updatepatientvaccination", inputData);
    
    return result;

  } catch (error) {
    console.error('Error in delete vacinations data:', error);
    throw error;
  }
};



export const GetSocialHistoryTypes = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    //console.log(result,"db res type social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export const AddSocialHistory = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_addpatientsocialhistory", inputData);
    //console.log(result,"db res add social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export const UpdateSocialHistory = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_updatepatientsocialhistory", inputData);
    //console.log(result,"db res edit social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export const DeleteSocialHistory = async (inputData) => {
  try {
    inputData.branchid = getBranchId();
    
    const result = await FetchData("midoc_deletepatientsocialhistory", inputData);
    //console.log(result,"db res add social")
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export const getrelations = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

export const getmartialstatus = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    const result = await FetchData("midoc_getdefaultdata", inputData);
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};

const AddVitalData = async (inputData) => {
  try {
    
    inputData.branchid = getBranchId();
    //console.log(inputData,"vitals add db inputData")
    const result = await FetchData("midoc_addpatientvitals", inputData);
    //console.log(result,"vitals add db res")
    // if (result.output.data) {
    // var finalOutput = formapimodalvitals(result.output.data)
    // }
    return result;

  } catch (error) {
    console.error('Error fetching data in forms.js:', error);
    throw error;
  }
};
export {AddVitalData,FetchPatientDetails, FetchPatients, FetchChatData, AddMessage, FetchPatientChatMedia, FetchAllergyDetails, FetchVisitDetails, FetchChiefComplaint, FetchHistoryOfPresentIllness, FetchOrderDetails, FetchVitalsDetails, FetchMedicalHistoryDetails, FetchSurgicalHistoryDetails, updateCondition, FetchFamilyHistoryDetails, FetchSocialHistoryDetails, FetchPhysicalExamDetails, FetchDoctorAssessmentDetails, FetchReviewSystemsDetails, FetchVaccinationDetails, FetchInvestigationDetails, FetchLabTestDetails, FetchImagingDetails, updatechatmessageread,requestPayment };

